import type {PageMigrator} from '../../dataMigrationRunner'
import {migratePage} from './duplicateAndCrossPageRefFixer2'

const name = 'duplicateAndCrossPageRefFixer'
const version = 8

export const duplicateAndCrossPageRefFixer: PageMigrator = {
    migratePage,
    name,
    version,
    experimentalVersions: [{version: 9, experiment: 'dm_fixCrossPagePropsAndDuplicateOwnedBehaviors'}],
    fixerRequiresReruns: true
}
