import type {
    SuperGetEvent,
    SuperHasEvent,
    SuperRemoveEvent,
    SuperSetEvent
} from '@wix/document-manager-extensions/src/extensions/dataAccess/hooks'
import type {PS} from '@wix/document-services-types'

export const privateServicesHas = (ps: PS, {pointer}: SuperHasEvent) => {
    return ps.dal.isExist(pointer)
}

export const privateServicesGet = (ps: PS, {pointer}: SuperGetEvent) => {
    return ps.dal.get(pointer)
}

export const privateServicesSet = (ps: PS, {pointer, value}: SuperSetEvent) => {
    ps.dal.set(pointer, value)
    return true
}

export const privateServicesRemove = (ps: PS, {pointer}: SuperRemoveEvent) => {
    ps.dal.remove(pointer)
    return true
}
