import _ from 'lodash'
import structure from '../../structure/structure'
import dataModel from '../../dataModel/dataModel'
import type {Pointer, PS} from '@wix/document-services-types'

function beforeUpdateCheckboxData(ps: PS, compPointer: Pointer, updatedDataItem) {
    if (_.isEmpty(updatedDataItem.label) || !_.isEmpty(dataModel.getDataItem(ps, compPointer).label)) {
        return
    }
    const checkboxStructure = ps.dal.get(compPointer)
    const compProperties = dataModel.getPropertiesItem(ps, compPointer)
    const buttonSize = _.clamp(checkboxStructure.layout.height, 12, 100)
    dataModel.setPropertiesItem(ps, compPointer, _.assign(compProperties, {buttonSize}))
    structure.updateCompLayoutAndAdjustLayout(ps, compPointer, {width: buttonSize + 200}, true)
}

function afterUpdateCheckboxData(ps: PS, compPointer: Pointer, beforeDataItem) {
    if (!_.isEmpty(dataModel.getDataItem(ps, compPointer).label) || _.isEmpty(beforeDataItem.label)) {
        return
    }
    const compProperties = dataModel.getPropertiesItem(ps, compPointer)
    const buttonSize = compProperties.buttonSize || 18
    structure.updateCompLayoutAndAdjustLayout(ps, compPointer, {width: buttonSize, height: buttonSize}, true)
}

export default {
    beforeUpdateCheckboxData,
    afterUpdateCheckboxData
}
