import {
    CoreLogger,
    CreateExtArgs,
    DAL,
    DalValue,
    DalValueChangeCallback,
    Extension,
    ExtensionAPI,
    logDalValueChanges,
    pointerUtils
} from '@wix/document-manager-core'
import type {
    Experiment,
    FixerActions,
    FixerCategoryToFixerVersion,
    FixerCategoryToFixerVersioningConfig,
    FixerNameToVersion,
    Pointer,
    RunningExperiments
} from '@wix/document-services-types'
import {deepClone} from '@wix/wix-immutable-proxy'
import _ from 'lodash'
import {COMP_DATA_QUERY_KEYS_WITH_STYLE, DATA_TYPES, VIEW_MODES} from '../../constants/constants'
import type {DataModelExtensionAPI} from '../dataModel/dataModel'
import {trimLongReports} from './fixerModsReporting'
import {FixerContext, NonLinearVersionError, NonRegisteredExperimentError} from './fixerVersionErrors'
import {FixerVersioningConfig, decodeFixerVersioning, encodeFixerVersioning} from '@wix/document-manager-utils'

const {getPointer} = pointerUtils

export enum FixerCategory {
    VIEWER = 'viewer_fixer',
    MIGRATOR = 'migration_fixer',
    DS = 'ds_fixer'
}

export interface ExperimentWithVersions extends Experiment {
    version: number
}
export interface ExperimentalVersion {
    version: number
    experiment: string
}

const fixerVersionsNamespace = DATA_TYPES.fixerVersions

const logFixers = typeof localStorage !== 'undefined' && localStorage.getItem('dm-log-fixers') === 'true'

const reportFixerActions =
    (logger: CoreLogger) =>
    (category: FixerCategory, fixerChangesOnReruns: FixerActions): void => {
        if (_.isEmpty(fixerChangesOnReruns)) {
            return
        }
        const report = {
            tags: {
                category,
                ..._.mapValues(fixerChangesOnReruns, 'ver')
            },
            extras: {
                fixerChangesOnReruns: JSON.stringify(trimLongReports(fixerChangesOnReruns))
            }
        }
        if (logFixers) {
            console.log(category, fixerChangesOnReruns)
        }
        logger.interactionStarted('fixer_version_mods', report)
    }

export interface DataFixerVersioningApi extends ExtensionAPI {
    dataFixerVersioning: {
        decorateExperimentWithVersions(
            experimentInstance: Experiment,
            context: FixerContext,
            version: number,
            experimentalVersions?: ExperimentalVersion[]
        ): ExperimentWithVersions
        executeFixerAndSetModifications(
            fixerExecutor: Function,
            pageId: string,
            fixerName: string,
            fixerVersion: number
        ): FixerActions
        hasFixerRunOnCurrentVersion(
            pageId: string,
            category: FixerCategory,
            fixerName: string,
            fixerVersion: number,
            fixerVersioningConfig: FixerVersioningConfig
        ): boolean
        updatePageVersionData(
            pageId: string,
            versionData: FixerCategoryToFixerVersion,
            fixerCategoryToFixerVersioningConfig: FixerCategoryToFixerVersioningConfig
        ): void
        reportFixerActions(category: FixerCategory, fixerChangesOnReruns: any): void
    }
}

const getFixerVersionsQuery = (dal: DAL, pageId: string) => {
    const fixerVersionsQueryPointer = getPointer(pageId, VIEW_MODES.DESKTOP, {
        innerPath: [COMP_DATA_QUERY_KEYS_WITH_STYLE.fixerVersions]
    })
    return dal.get(fixerVersionsQueryPointer)
}

const hasFixerRunOnCurrentVersion =
    (dal: DAL, extensionAPI: ExtensionAPI) =>
    (
        pageId: string,
        category: FixerCategory,
        fixerName: string,
        fixerVersion: number,
        fixerVersioningConfig: FixerVersioningConfig
    ): boolean => {
        const {dataModel} = extensionAPI as DataModelExtensionAPI
        const fixerVersionsQuery = getFixerVersionsQuery(dal, pageId)
        const pageFixerVersions = dataModel.getItem(fixerVersionsQuery, fixerVersionsNamespace, pageId)
        let allFixersVersions = pageFixerVersions?.[category]
        if (_.isString(allFixersVersions)) {
            allFixersVersions = decodeFixerVersioning(allFixersVersions, fixerVersioningConfig.fixers)
        }
        const lastVersionRun = allFixersVersions?.[fixerName]
        return lastVersionRun === fixerVersion
    }

const getCurrentDecodedFixerVersioning = (
    dal: DAL,
    currentFixerVersionsData: FixerVersioningData,
    fixerCategoriesToBeUpdated: string[],
    fixerCategoryToFixerVersioningConfig: FixerCategoryToFixerVersioningConfig
): FixerVersioningData => {
    if (_.isEmpty(currentFixerVersionsData)) {
        return {}
    }
    const currentData = deepClone(currentFixerVersionsData)

    fixerCategoriesToBeUpdated.forEach(fixerCategory => {
        const currentFixerVersioning = currentData[fixerCategory]
        if (_.isString(currentFixerVersioning)) {
            if (!fixerCategoryToFixerVersioningConfig[fixerCategory]) {
                throw new Error(`The fixer versioning config for ${fixerCategory} is missing`)
            }
            currentData[fixerCategory] = decodeFixerVersioning(
                currentFixerVersioning,
                fixerCategoryToFixerVersioningConfig[fixerCategory].fixers
            )
        }
    })
    return currentData
}

const encodeFixerVersioningByCategories = (
    fixerCategoriesToBeUpdated: string[],
    versionData: FixerCategoryToFixerVersion,
    fixerCategoryToFixerVersioningConfig: FixerCategoryToFixerVersioningConfig
) => {
    const newVersionData: Record<string, FixerNameToVersion | string> = Object.assign({}, versionData)
    fixerCategoriesToBeUpdated.forEach(fixerCategory => {
        const newFixerVersioning = newVersionData[fixerCategory]
        if (!_.isString(newFixerVersioning)) {
            if (!fixerCategoryToFixerVersioningConfig[fixerCategory]) {
                throw new Error(`The fixer versioning config for ${fixerCategory} is missing`)
            }
            newVersionData[fixerCategory] = encodeFixerVersioning(
                newFixerVersioning,
                fixerCategoryToFixerVersioningConfig[fixerCategory]
            )
        }
    })
    return newVersionData
}

const isAllCurrentDataAlreadyEncoded = (
    dal: DAL,
    currentData: FixerVersioningData,
    fixerCategoriesToBeUpdated: string[]
) => {
    return fixerCategoriesToBeUpdated.every(fixerCategory => {
        const currentFixerVersioning = currentData[fixerCategory]
        if (_.isString(currentFixerVersioning)) {
            return true
        }
        return false
    })
}

type FixerVersioningData = Record<string, FixerNameToVersion | string>

const updatePageVersionData =
    (dal: DAL, extensionAPI: ExtensionAPI, experimentInstance: Experiment) =>
    (
        pageId: string,
        versionData: FixerCategoryToFixerVersion,
        fixerCategoryToFixerVersioningConfig: FixerCategoryToFixerVersioningConfig
    ): void => {
        const isVersionDataEmpty = Object.values(versionData).every(_.isEmpty)
        if (isVersionDataEmpty && !experimentInstance.isOpen('dm_encodeFixerVersioning')) {
            return
        }
        let newVersionData: FixerVersioningData = Object.assign({}, versionData)
        const fixerCategoriesToBeUpdated = Object.keys(newVersionData)
        const pagePointer = getPointer(pageId, VIEW_MODES.DESKTOP)
        const {dataModel} = extensionAPI as DataModelExtensionAPI
        const fixerVersionsQuery = getFixerVersionsQuery(dal, pageId)
        if (fixerVersionsQuery) {
            const fixerVersionsDataPointer = getPointer(fixerVersionsQuery, fixerVersionsNamespace)
            const currentFixerVersionsData: FixerVersioningData = dal.get(fixerVersionsDataPointer)
            if (
                isVersionDataEmpty &&
                (_.isEmpty(currentFixerVersionsData) ||
                    isAllCurrentDataAlreadyEncoded(dal, currentFixerVersionsData, fixerCategoriesToBeUpdated))
            ) {
                return
            }
            const currentDataDecoded = getCurrentDecodedFixerVersioning(
                dal,
                currentFixerVersionsData,
                fixerCategoriesToBeUpdated,
                fixerCategoryToFixerVersioningConfig
            )
            newVersionData = isVersionDataEmpty ? currentDataDecoded : _.merge(currentDataDecoded, versionData)
        }

        if (Object.values(newVersionData).every(_.isEmpty)) {
            return
        }

        if (experimentInstance.isOpen('dm_encodeFixerVersioning')) {
            newVersionData = encodeFixerVersioningByCategories(
                fixerCategoriesToBeUpdated,
                newVersionData as FixerCategoryToFixerVersion,
                fixerCategoryToFixerVersioningConfig
            )
        }

        dataModel.components.addItem(pagePointer, fixerVersionsNamespace, {
            metaData: {pageId},
            type: 'FixerVersions',
            ...newVersionData
        })
    }

export enum ExperimentStatus {
    new = 'New',
    skipped = 'Skipped',
    old = 'Old'
}

const decorateExperimentWithVersions =
    (logger: CoreLogger) =>
    (
        experimentInstance: Experiment,
        context: FixerContext,
        baseVersion: number,
        experimentalVersions?: ExperimentalVersion[]
    ): ExperimentWithVersions => {
        if (experimentalVersions === undefined) {
            experimentalVersions = []
        }
        experimentalVersions.sort((a, b) => a.version - b.version)
        const runningExperiments: RunningExperiments = {}
        let versionMissed = false
        let linearVersioningIsBroken = false

        experimentalVersions.forEach(experimentalVersion => {
            if (!versionMissed && experimentInstance.isOpen(experimentalVersion.experiment)) {
                runningExperiments[experimentalVersion.experiment] = ExperimentStatus.new
                baseVersion = experimentalVersion.version
            } else if (versionMissed && experimentInstance.isOpen(experimentalVersion.experiment)) {
                runningExperiments[experimentalVersion.experiment] = ExperimentStatus.skipped
                linearVersioningIsBroken = true
            } else {
                runningExperiments[experimentalVersion.experiment] = ExperimentStatus.old
                versionMissed = true
            }
        })

        if (linearVersioningIsBroken) {
            logger.captureError(new NonLinearVersionError(runningExperiments, context))
        }

        const isOpen = (name: string) => {
            if (!runningExperiments[name]) {
                logger.captureError(new NonRegisteredExperimentError(name, context))
            }
            return runningExperiments[name] === ExperimentStatus.new
        }

        return {
            getRunningExperiments: (): RunningExperiments => runningExperiments,
            getValue: (name: string): string => (isOpen(name) ? ExperimentStatus.new : ''),
            isMultiValueExperimentOpen: (): boolean => false,
            isOpen,
            version: baseVersion
        }
    }

const executeFixerAndSetModifications =
    (dal: DAL, experimentEnabled: boolean) =>
    (fixerExecutor: Function, pageId: string, fixerName: string, fixerVersion: number): FixerActions => {
        if (!experimentEnabled) {
            fixerExecutor()
            return {}
        }
        const modifiedPaths: string[] = []

        const changeCallback: DalValueChangeCallback = (pointer: Pointer, oldValue: DalValue, newValue: DalValue) => {
            // this check ensures that deletion of a non existing data item in the dal won't be flagged as a fixer change
            if (oldValue !== undefined || newValue !== undefined) {
                modifiedPaths.push(`${pointer.type}/${pointer.id}`)
                if (logFixers) {
                    logDalValueChanges(pointer, oldValue, newValue, `"${fixerName}" `)
                }
            }
        }

        dal.registrar.registerForChangesCallback(changeCallback)

        try {
            fixerExecutor()
        } finally {
            dal.registrar.unregisterForChangesCallback(changeCallback)
        }

        if (modifiedPaths.length) {
            return {
                [fixerName]: {
                    ver: fixerVersion,
                    [pageId]: modifiedPaths
                }
            }
        }
        return {}
    }

const createExtensionAPI = ({dal, extensionAPI, coreConfig}: CreateExtArgs): DataFixerVersioningApi => {
    const enableDataCollection =
        !coreConfig.dontCollectFixerVersionData &&
        coreConfig.experimentInstance.isOpen('dm_fixerVersioningDataCollection')

    return {
        dataFixerVersioning: {
            decorateExperimentWithVersions: decorateExperimentWithVersions(coreConfig.logger),
            executeFixerAndSetModifications: executeFixerAndSetModifications(dal, enableDataCollection),
            hasFixerRunOnCurrentVersion: hasFixerRunOnCurrentVersion(dal, extensionAPI),
            updatePageVersionData: updatePageVersionData(dal, extensionAPI, coreConfig.experimentInstance),
            reportFixerActions: enableDataCollection ? reportFixerActions(coreConfig.logger) : _.noop
        }
    }
}

const createExtension = (): Extension => ({
    name: 'dataFixerVersioning',
    createExtensionAPI
})

export {createExtension}
