import {getPageStructure} from './utils/pageUtils'
import {getMockMetaSiteData} from './utils/documentServicesModelUtils'
import {
    getMockClientSpecMap,
    getMockMultilingualInfo,
    getMockPagesPlatformApplication,
    getMockSiteInfo,
    getMockSitePropertiesInfo,
    getMockSiteMetaData
} from './utils/rendererModelUtils'

const PAGE_STRUCTURE = getPageStructure('c1dmp', 'mobile.core.components.Page', {
    layout: {
        width: 1440,
        height: 500,
        x: 0,
        y: 0,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false
    }
})

const MOBILE_PAGE_STRUCTURE = getPageStructure('c1dmp', 'mobile.core.components.Page', {
    layout: {
        width: 20,
        height: 20,
        x: 0,
        y: 0,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false
    }
})

const PAGE_DATA = {
    type: 'PageData',
    id: 'c1dmp',
    title: 'HOME'
}

const PAGE_PROPS = {
    type: 'PageProp',
    id: 'c1dmp',
    title: 'HOME'
}

const RENDERER_MODEL = {
    geo: 'ISR',
    languageCode: 'en',
    locale: 'he-IL',
    userId: '84770f67-ecbd-44b6-b35a-584f2dc15af1',
    metaSiteId: 'a573279f-ae6f-46d1-8556-7c93ae9b2c84',
    pagesPlatformApplications: getMockPagesPlatformApplication(),
    clientSpecMap: getMockClientSpecMap(),
    wixCodeModel: {},
    multilingualInfo: getMockMultilingualInfo(),
    passwordProtectedPages: ['fff'],
    sitePropertiesInfo: getMockSitePropertiesInfo(),
    premiumFeatures: ['feature-1'],
    routers: {},
    siteInfo: getMockSiteInfo(),
    siteMetaData: getMockSiteMetaData()
}

const DOCUMENT_SERVICES_MODEL = {
    siteName: '-start-from-scratch',
    publicUrl: 'https://talm61.wixsite.com/',
    isPublished: false,
    isDraft: true,
    hasSites: false,
    version: 1,
    revision: 1,
    customHeadTags: '',
    metaSiteData: getMockMetaSiteData()
}

const DEFAULT_PLATFORM_DS_MODEL = {
    appManifest: {},
    appPublicApiName: {}
}

const ML_SEPARTOR = '^'

const RENDER_FLAGS_DEFAULT = {
    isZoomAllowed: false,
    allowSiteOverflow: false
}

const MODES_DEFAULT = {
    c1dmp: {
        'comp-kfdszq1y-mode-kfdszq3x1': {
            modeType: 'HOVER'
        }
    },
    masterPage: {}
}

const PAGES_DEFAULT = {
    c1dmp: {
        data: {
            document_data: {
                x: {}
            }
        },
        pageUriSEO: 'blank-1',
        structure: {
            DESKTOP: {},
            MOBILE: {}
        },
        title: 'mock'
    },
    masterPage: {}
}

const TEMPLATE_CONTAINER_ID = 'comp-templateContainer'
const REF_COMP_ID = 'comp-internalRef1'
const OUTER_REF_COMP_ID = 'comp-internalRef1'
const referredCompId = `${OUTER_REF_COMP_ID}_r_${REF_COMP_ID}_r_${TEMPLATE_CONTAINER_ID}`
const refComp = `${OUTER_REF_COMP_ID}_r_${REF_COMP_ID}`
const DISPLAYED_CONNECTION_OVERRIDE = {
    [REF_COMP_ID]: [
        {
            id: 'comp-internalRef1-connection',
            items: [{type: 'WixCodeConnectionItem', role: 'x'}],
            type: 'ConnectionList'
        }
    ]
}

const GHOST_REF_COMPS = {
    [refComp]: [referredCompId]
}

const REMOTE_DATA_REF = {
    [REF_COMP_ID]: [
        {
            id: 'innerRefId_r_innerInnerRefId_r_referredComp-dataQuery',
            type: 'data'
        },
        {
            id: 'innerRefId_r_referredComp-connectionsQuery',
            type: 'connections'
        },
        {
            id: 'innerRefId_r_referredComp-dataQuery',
            type: 'data'
        },
        {
            id: 'innerRefId_r_referredComp-propertyQuery',
            type: 'props'
        }
    ]
}

const DEFAULT_MOCK_HOST = 'sh0801.wix.com'

const REPEATER_DELIMITER = '__'

const REF_DELIMITER = '_r_'

export {
    PAGE_STRUCTURE,
    MOBILE_PAGE_STRUCTURE,
    PAGE_DATA,
    PAGE_PROPS,
    RENDERER_MODEL,
    DOCUMENT_SERVICES_MODEL,
    DEFAULT_PLATFORM_DS_MODEL,
    ML_SEPARTOR,
    RENDER_FLAGS_DEFAULT,
    MODES_DEFAULT,
    PAGES_DEFAULT,
    DISPLAYED_CONNECTION_OVERRIDE,
    GHOST_REF_COMPS,
    REF_COMP_ID,
    refComp,
    REMOTE_DATA_REF,
    DEFAULT_MOCK_HOST,
    REPEATER_DELIMITER,
    REF_DELIMITER
}
