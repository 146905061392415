const _ = require('lodash')

/**
 * @param {object} rules
 * @param {*?} value
 * @param {number|string|null?} key
 * @param {[]|object|null?} [parentNode]
 * @param {[]|object|null?} [ctx]
 * @return {object}
 */
const walker = (rules, value, key, parentNode, ctx) => {
    if (_.isPlainObject(value)) {
        _.forOwn(value, (item, property) => {
            walker(rules, item, property, value, ctx)
        })
    }
    if (_.isArray(value)) {
        _.forEach(value, (item, property) => {
            walker(rules, item, property, value, ctx)
        })
    }
    if (key && rules[key]) {
        rules[key](value, key, parentNode, ctx)
    }
    return value
}

/**
 * @param {object} rules
 * @param {object} obj
 * @param {object} [ctx]
 * @return {object}
 */
const walk = (rules, obj, ctx) => walker(rules, obj, null, null, ctx)

module.exports = {
    walk
}
