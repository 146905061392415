function getColorValueFromColorsMap(colorsMap: Record<string, string>, colorClassName: string) {
    const colorNumber = colorClassName?.split('_')[1]
    return colorsMap[colorNumber] || colorClassName
}

/**
 *  Get the color value by theme
 * @param {Object} theme - current theme
 * @param {string} colorClassName
 *  @returns {String} color rgb/rgba value
 */
function getColorValue(theme, colorClassName: string): string {
    return getColorValueFromColorsMap(theme.color, colorClassName)
}

export default {
    getColorValue
}
