import _ from 'lodash'
import type {
    CreateExtArgs,
    CreateExtension,
    DAL,
    DeepFunctionMap,
    Extension,
    ExtensionAPI
} from '@wix/document-manager-core'
import {addComponent, addPage, replaceComponent} from './json/import'
import {EVENTS} from './json/constants'
import type {ComponentExport, CompRef, RawComponentExport, WMLFiles} from '@wix/document-services-types'
import type {SiteExport} from './utils'
import {exportSite, importSite} from './site'
import {convertWmlToRawComponentExport, convertJsonToRawComponentExport} from './formatConverter'
import {wml} from '@wix/document-management-wml-converter'
import {json} from '@wix/document-management-json-converter'
import {exportComponent} from './json/export'

export interface ImportExportApi extends DeepFunctionMap {
    components: {
        replace(componentToReplace: CompRef, componentExport: RawComponentExport): void
        add(containerPointer: CompRef, componentExport: RawComponentExport): CompRef
    }
    pages: {
        replace(pageToReplace: CompRef, pageExport: RawComponentExport): void
        add(pageExport: RawComponentExport): CompRef
    }
    json: {
        convertJsonToRawComponentExport(componentExport: ComponentExport): Promise<RawComponentExport>
        exportComponent(componentPointer: CompRef): ComponentExport
        exportPage(pagePointer: CompRef): ComponentExport
    }
    wml: {
        convertWmlToRawComponentExport(wmlFiles: WMLFiles): Promise<RawComponentExport>
        exportComponent(componentPointer: CompRef): WMLFiles
        exportPage(pagePointer: CompRef): WMLFiles
    }
    sites: {
        export(): SiteExport
        import(data: SiteExport): void
    }
}

export interface ImportExportExtensionApi extends ExtensionAPI {
    importExport: ImportExportApi
}

const wrapMethodsWithCustomGetterDisabled = (value: any, dal: DAL): any => {
    if (_.isPlainObject(value)) {
        return _.mapValues(value, prop => wrapMethodsWithCustomGetterDisabled(prop, dal))
    }

    return dal.withoutCustomGetters(value)
}

const createExtension: CreateExtension = (): Extension => {
    const createExtensionAPI = ({
        extensionAPI,
        pointers,
        dal,
        coreConfig,
        eventEmitter
    }: CreateExtArgs): ImportExportExtensionApi => ({
        importExport: {
            components: {
                replace: (componentToReplace: CompRef, rawComponentExport: RawComponentExport): void =>
                    replaceComponent(
                        rawComponentExport,
                        componentToReplace,
                        dal,
                        pointers,
                        extensionAPI,
                        coreConfig,
                        eventEmitter
                    ),
                add: (containerPointer: CompRef, rawComponentExport: RawComponentExport): CompRef =>
                    addComponent(
                        rawComponentExport,
                        containerPointer,
                        dal,
                        pointers,
                        extensionAPI,
                        coreConfig,
                        eventEmitter
                    )
            },
            pages: {
                replace: (pageToReplace: CompRef, rawPageExport: RawComponentExport): void =>
                    replaceComponent(
                        rawPageExport,
                        pageToReplace,
                        dal,
                        pointers,
                        extensionAPI,
                        coreConfig,
                        eventEmitter
                    ),
                add: (rawPageExport: RawComponentExport): CompRef =>
                    addPage(rawPageExport, dal, pointers, extensionAPI, coreConfig, eventEmitter)
            },
            json: {
                convertJsonToRawComponentExport,
                exportComponent: (componentPointer: CompRef): ComponentExport =>
                    json.pack(exportComponent(componentPointer, dal, pointers, extensionAPI, coreConfig)),
                exportPage: (pagePointer: CompRef): ComponentExport =>
                    json.pack(exportComponent(pagePointer, dal, pointers, extensionAPI, coreConfig))
            },
            wml: {
                convertWmlToRawComponentExport,
                exportComponent: (componentPointer: CompRef): WMLFiles =>
                    wml.pack(exportComponent(componentPointer, dal, pointers, extensionAPI, coreConfig)),
                exportPage: (pagePointer: CompRef): WMLFiles =>
                    wml.pack(exportComponent(pagePointer, dal, pointers, extensionAPI, coreConfig))
            },
            sites: {
                export: (): SiteExport => exportSite(extensionAPI, dal, pointers),
                import: (data: SiteExport): void => importSite(data, extensionAPI, dal, pointers)
            }
        }
    })

    return {
        name: 'importExport',
        createExtensionAPI: (args: CreateExtArgs): ImportExportExtensionApi =>
            wrapMethodsWithCustomGetterDisabled(createExtensionAPI(args), args.dal),
        EVENTS
    }
}

export {createExtension}
