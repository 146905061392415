import type {StyleRef} from '@wix/document-services-types'

const STYLABLE_SKIN_NAME = 'wixui.skins.Skinless'

const getComponentStylableName = (compType: string) => compType.split('.').pop()

const createEmptyStylableStylesheet = (compType: string) => {
    const compName = getComponentStylableName(compType)
    return `:import {
    -st-from: 'wix-ui-santa/index.st.css';
    -st-named: ${compName};
}
.root{
    -st-extends: ${compName};
}`
}

export const createEmptyStylableStyleItem = (componentType: string): Partial<StyleRef> => ({
    skin: STYLABLE_SKIN_NAME,
    style: {
        properties: {
            '$st-css': createEmptyStylableStylesheet(componentType)
        }
    } as any
})
