import type {PublicMethodUtils} from '@wix/document-services-types'
import {appStudio, utils} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            appStudio: {
                getEntityByPage: publicMethodUtils.defineGetter(appStudio.getEntityByPage),
                displayGettingStartedView: publicMethodUtils.defineDataManipulationAction(
                    appStudio.displayGettingStartedView,
                    {
                        waitingForTransition: true,
                        noBatchingAfter: true,
                        isUpdatingAnchors: utils.YES
                    }
                ),
                getBuildStatusById: publicMethodUtils.defineGetter(appStudio.getBuildStatusById),
                updateBlocksPreviewData: publicMethodUtils.defineDataManipulationAction(
                    appStudio.updateBlocksPreviewData
                ),
                getLastBuildId: publicMethodUtils.defineGetter(appStudio.getLastBuildId),
                getDevSiteAppDefId: publicMethodUtils.defineGetter(appStudio.getDevSiteAppDefId),
                getMetaData: publicMethodUtils.defineGetter(appStudio.getAppStudioMetaData),
                updateMetaData: publicMethodUtils.defineDataManipulationAction(appStudio.updateAppStudioMetaData),
                saveAppMetadata: publicMethodUtils.defineAction(appStudio.saveAppMetadata),
                isBobApp: publicMethodUtils.defineAction(appStudio.isBobApp),
                fetchAppData: publicMethodUtils.defineAction(appStudio.fetchAppData),
                dependencies: {
                    hasDependency: publicMethodUtils.defineGetter(appStudio.dependencies.hasDependency),
                    addDependency: publicMethodUtils.defineDataManipulationAction(appStudio.dependencies.addDependency),
                    removeDependency: publicMethodUtils.defineDataManipulationAction(
                        appStudio.dependencies.removeDependency
                    ),
                    setDependencies: publicMethodUtils.defineDataManipulationAction(
                        appStudio.dependencies.setDependencies
                    ),
                    getAllDependencies: publicMethodUtils.defineGetter(appStudio.dependencies.getAllDependencies)
                },
                definitions: {
                    getAll: publicMethodUtils.defineGetter(appStudio.definitions.getAllCustomDefinitions),
                    getAllSerialized: publicMethodUtils.defineGetter(
                        appStudio.definitions.getAllSerializedCustomDefinitions
                    ),
                    get: publicMethodUtils.defineGetter(appStudio.definitions.getSerializedCustomDefinition),
                    set: publicMethodUtils.defineDataManipulationAction(appStudio.definitions.setCustomDefinition),
                    remove: publicMethodUtils.defineDataManipulationAction(
                        appStudio.definitions.removeCustomDefinition
                    ),
                    getAllDefinitionUsages: publicMethodUtils.defineGetter(appStudio.definitions.getAllDefinitionUsages)
                },
                getUpdatedWidgetPropertiesDefaults: publicMethodUtils.defineGetter(
                    appStudio.getUpdatedWidgetPropertiesDefaults
                ),
                preBuild: publicMethodUtils.defineDataManipulationAction(appStudio.preBuild),
                build: publicMethodUtils.defineDataManipulationAction(appStudio.build),
                buildWithOptions: publicMethodUtils.defineDataManipulationAction(appStudio.buildWithOptions),
                setWixDataToken: publicMethodUtils.defineDataManipulationAction(appStudio.setWixDataToken),
                setDraftAppName: publicMethodUtils.defineDataManipulationAction(appStudio.setDraftAppName),
                setAppStudioModel: publicMethodUtils.defineDataManipulationAction(appStudio.setAppStudioModel),
                appName: {
                    set: publicMethodUtils.defineDataManipulationAction(appStudio.appName.set),
                    get: publicMethodUtils.defineGetter(appStudio.appName.get),
                    clear: publicMethodUtils.defineDataManipulationAction(appStudio.appName.clear),
                    validate: publicMethodUtils.defineGetter(appStudio.appName.validate),
                    getUsedAppNames: publicMethodUtils.defineGetter(appStudio.appName.getUsedAppNames),
                    ERRORS: appStudio.appName.ERRORS
                },
                appDescription: {
                    set: publicMethodUtils.defineDataManipulationAction(appStudio.appDescription.set),
                    get: publicMethodUtils.defineGetter(appStudio.appDescription.get),
                    clear: publicMethodUtils.defineDataManipulationAction(appStudio.appDescription.clear)
                },
                ssrCache: {
                    set: publicMethodUtils.defineDataManipulationAction(appStudio.ssrCache.set),
                    get: publicMethodUtils.defineGetter(appStudio.ssrCache.get)
                },
                panels: {
                    create: publicMethodUtils.defineDataManipulationAction(appStudio.panels.createPanel, {
                        getReturnValue: appStudio.getNewDataItemPointer
                    }),
                    generateNewName: publicMethodUtils.defineGetter(appStudio.panels.generateNewPanelName),
                    display: publicMethodUtils.defineDataManipulationAction(appStudio.panels.displayPanel, {
                        waitingForTransition: true,
                        noBatchingAfter: true,
                        isUpdatingAnchors: utils.YES
                    }),
                    remove: publicMethodUtils.defineDataManipulationAction(appStudio.panels.removePanel),
                    rename: publicMethodUtils.defineDataManipulationAction(appStudio.panels.renamePanel),
                    duplicate: publicMethodUtils.defineDataManipulationAction(appStudio.panels.duplicatePanel, {
                        getReturnValue: appStudio.getNewDataItemPointer
                    }),
                    getAll: publicMethodUtils.defineGetter(appStudio.panels.getAllPanels),
                    getPointerByRootCompId: publicMethodUtils.defineGetter(
                        appStudio.panels.getPanelPointerByRootCompId
                    ),
                    setHelpId: publicMethodUtils.defineDataManipulationAction(appStudio.panels.setHelpId),
                    setTitle: publicMethodUtils.defineDataManipulationAction(appStudio.panels.setTitle),
                    setHeight: publicMethodUtils.defineDataManipulationAction(appStudio.panels.setHeight),
                    validateTitle: publicMethodUtils.defineGetter(appStudio.panels.validateTitle),
                    validateHelpId: publicMethodUtils.defineGetter(appStudio.panels.validateHelpId),
                    validatePanelName: publicMethodUtils.defineGetter(appStudio.panels.validatePanelName),
                    getRootCompIdByPointer: publicMethodUtils.defineGetter(
                        appStudio.panels.getRootCompIdByPanelPointer
                    ),
                    getCount: publicMethodUtils.defineGetter(appStudio.panels.getCount),
                    movePanel: publicMethodUtils.defineDataManipulationAction(appStudio.panels.movePanel)
                },
                dashboardPages: {
                    create: publicMethodUtils.defineDataManipulationAction(appStudio.dashboards.createDashboard, {
                        getReturnValue: appStudio.getNewDataItemPointer
                    }),
                    display: publicMethodUtils.defineDataManipulationAction(appStudio.dashboards.displayDashboard, {
                        waitingForTransition: true,
                        noBatchingAfter: true,
                        isUpdatingAnchors: utils.YES
                    }),
                    remove: publicMethodUtils.defineDataManipulationAction(appStudio.dashboards.removeDashboard),
                    getAll: publicMethodUtils.defineGetter(appStudio.dashboards.getAllDashboards),
                    isDashboardPage: publicMethodUtils.defineGetter(appStudio.dashboards.isDashboardPage),
                    getByRootCompId: publicMethodUtils.defineGetter(appStudio.dashboards.getDashboardByRootCompId)
                },
                getRootAppWidgetByPage: publicMethodUtils.defineGetter(appStudio.getRootAppWidgetByPage),
                isBlocksComponentPage: publicMethodUtils.defineGetter(appStudio.isBlocksComponentPage),
                widgets: {
                    getCount: publicMethodUtils.defineGetter(appStudio.widgets.getCount),
                    create: publicMethodUtils.defineDataManipulationAction(appStudio.widgets.createWidget, {
                        getReturnValue: appStudio.getNewDataId
                    }),
                    /**
                     * duplicates the widget including it's variations
                     * @member documentServices.appStudio.widgets
                     * @param {Pointer} widgetPointer a reference to the widget
                     * @param {Function} callback a function called with the new widget pointer
                     * @param {object} options optional params like widgetName (string)
                     *
                     * @example
                     * const widgetPointer = {type: "data", id: "dataItem-jnk7t9gu"}
                     * documentServices.appStudio.widget.duplicate(widgetPointer)
                     */
                    duplicate: publicMethodUtils.defineDataManipulationAction(appStudio.widgets.duplicateWidget),
                    /**
                     * Update widget config
                     * @member documentServices.appStudio.widgets
                     * @param {AbstractComponent} controllerRef - the controller of the widget
                     * @param {Object} config - the settings of the controller. Possible config values: structureVersion, devCenterWidgetId, variationPageId
                     * @example
                     * const config = {structureVersion:  '0.1.0'}
                     * const controllerRef = {id: 'comp-kw3icp61', type: 'DESKTOP'}
                     * documentServices.appStudio.widgets.updateConfig(controllerRef, config)
                     */
                    updateConfig: publicMethodUtils.defineDataManipulationAction(appStudio.widgets.updateConfig),
                    getAll: publicMethodUtils.defineGetter(appStudio.widgets.getAllWidgets),
                    setDefaultSize: publicMethodUtils.defineDataManipulationAction(appStudio.widgets.setDefaultSize),
                    getDefaultSize: publicMethodUtils.defineGetter(appStudio.widgets.getDefaultSize),
                    getWidgetPlugin: publicMethodUtils.defineGetter(appStudio.widgets.getWidgetPlugin),
                    getContainingWidgetsMap: publicMethodUtils.defineGetter(appStudio.widgets.getContainingWidgetsMap),
                    /**
                     * Get first level of widgets contained in widget pointer
                     * @param widgetPointer - Containing widget
                     * @return array of compRefs all of type  wysiwyg.viewer.components.RefComponent
                     */
                    getFirstLevelInnerWidgets: publicMethodUtils.defineGetter(
                        appStudio.widgets.getFirstLevelRefChildren
                    ),
                    /**
                     * Get all first level innerWidgets pointing to a specific widget or variation
                     * @param widgetPointer - master widget or variation to search for
                     * @param widgetPageId - the master widget pageId
                     * @param possibleContainingWidgets - all widgets that might contain the master widget, no need to pass variations here
                     * @return array of compRefs all of type  wysiwyg.viewer.components.RefComponent pointing to the master widget/variation that were found in possibleContainingWidgets or in their variations
                     */
                    getWidgetInstances: publicMethodUtils.defineGetter(appStudio.widgets.getWidgetInstances),
                    getAllSerialized: publicMethodUtils.defineGetter(appStudio.widgets.getAllSerializedWidgets),
                    getRootWidgetByPage: publicMethodUtils.deprecate(
                        publicMethodUtils.defineGetter(appStudio.getRootAppWidgetByPage),
                        'appStudio.widgets.getRootWidgetByPage is deprecated. Use appStudio.getRootAppWidgetByPage instead'
                    ),
                    getSerializedWidgetStructure: publicMethodUtils.defineGetter(
                        appStudio.widgets.getSerializedWidgetStructure
                    ),
                    functions: {
                        set: publicMethodUtils.defineDataManipulationAction(appStudio.widgets.setWidgetApiPart, {
                            getReturnValue: () => 'functions'
                        }),
                        get: publicMethodUtils.defineGetter(appStudio.widgets.getWidgetFunctions)
                    },
                    propertiesSchemas: {
                        set: publicMethodUtils.defineDataManipulationAction(appStudio.widgets.setWidgetApiPart, {
                            getReturnValue: () => 'propertiesSchemas'
                        }),
                        get: publicMethodUtils.defineGetter(appStudio.widgets.getWidgetPropertiesSchema)
                    },
                    events: {
                        set: publicMethodUtils.defineDataManipulationAction(appStudio.widgets.setWidgetApiPart, {
                            getReturnValue: () => 'events'
                        }),
                        get: publicMethodUtils.defineGetter(appStudio.widgets.getWidgetEvents)
                    },
                    name: {
                        set: publicMethodUtils.defineDataManipulationAction(appStudio.widgets.setWidgetName),
                        get: publicMethodUtils.defineGetter(appStudio.widgets.getWidgetName)
                    },
                    display: publicMethodUtils.defineDataManipulationAction(appStudio.widgets.displayWidget, {
                        waitingForTransition: true,
                        noBatchingAfter: true,
                        isUpdatingAnchors: utils.YES
                    }),
                    getPanels: publicMethodUtils.defineGetter(appStudio.widgets.getWidgetPanels),
                    remove: publicMethodUtils.defineDataManipulationAction(appStudio.widgets.removeWidget),
                    getRootCompId: publicMethodUtils.defineGetter(appStudio.widgets.getRootCompIdByPointer),
                    getByRootCompId: publicMethodUtils.defineGetter(appStudio.widgets.getWidgetByRootCompId),
                    getPanelsCount: publicMethodUtils.defineGetter(appStudio.widgets.getPanelsCount),
                    get: {
                        byId: publicMethodUtils.defineGetter(appStudio.widgets.getWidgetById)
                    },
                    notifyPropertiesUpdateToWorker: publicMethodUtils.defineDataManipulationAction(
                        appStudio.widgets.notifyPropertiesUpdateToWorker
                    ),
                    variations: {
                        /**
                         * Adds a new variation to an existing widget
                         * @member documentServices.appStudio.widgets.variations
                         * @param {Pointer} widgetPointer a reference to the widget the variation will be associated with
                         * @param {object} [initialRootStructure] optional component structure to add to the new variation page
                         * @param {string} [duplicatePageId] optional pageId do copy the variation page from
                         * @returns {Pointer} the variation pointer
                         *
                         * @example
                         * const widgetPointer = {type: "data", id: "dataItem-jnk7t9gu"}
                         * documentServices.appStudio.widget.variations.create(widgetPointer)
                         * documentServices.appStudio.widget.variations.create(widgetPointer, {
                         *   "componentType": "wysiwyg.viewer.components.MediaBox",
                         *   "components": [],
                         *   "style": "mc1",
                         *   "type": "Container",
                         *   "layout": {
                         *    "x": 0,
                         *    "y": 0,
                         *    "width": 600,
                         *    "height": 400
                         *  }
                         * })
                         * documentServices.appStudio.widget.variations.create(widgetPointer, null, 'rjvq0')
                         */
                        create: publicMethodUtils.defineDataManipulationAction(
                            appStudio.widgets.variations.createVariation,
                            {
                                getReturnValue: appStudio.getNewDataItemPointer
                            }
                        ),
                        name: {
                            /**
                             * get the name of the variation
                             * @member documentServices.appStudio.widgets.variations.name
                             * @param {Pointer} variationPointer a reference to the variation
                             * @returns {string} name the new variation name
                             *
                             * @example
                             * const variationPointer = {type: "data", id: "dataItem-fje0dg"}
                             * const name = documentServices.appStudio.widget.variations.name.get(variationPointer)
                             */
                            get: publicMethodUtils.defineGetter(appStudio.widgets.variations.getVariationName),
                            /**
                             * change the name of the variation
                             * @member documentServices.appStudio.widgets.variations.name
                             * @param {Pointer} variationPointer a reference to the variation
                             * @param {Pointer} widgetPointer a reference to the widget the variation is associated with
                             * @returns {string} name the new variation name
                             *
                             * @example
                             * const variationPointer = {type: "data", id: "dataItem-fje0dg"}
                             * const widgetPointer = {type: "data", id: "dataItem-jnk7t9gu"}
                             * documentServices.appStudio.widget.variations.name.set(variationPointer, widgetPointer, 'newName')
                             */
                            set: publicMethodUtils.defineDataManipulationAction(
                                appStudio.widgets.variations.setVariationName
                            )
                        },
                        /**
                         * deletes the variation
                         * @member documentServices.appStudio.widgets.variations
                         * @param {Pointer} variationPointer a reference to the variation
                         * @param {Pointer} widgetPointer a reference to the widget the variation is associated with
                         *
                         * @example
                         * const variationPointer = {type: "data", id: "dataItem-fje0dg"}
                         * const widgetPointer = {type: "data", id: "dataItem-jnk7t9gu"}
                         * documentServices.appStudio.widget.variations.remove(variationPointer, widgetPointer)
                         */
                        remove: publicMethodUtils.defineDataManipulationAction(
                            appStudio.widgets.variations.removeVariation
                        ),
                        /**
                         * returns true if the page is the rootCompId of a variation
                         * @member documentServices.appStudio.widgets.variations
                         * @param {string} pageId
                         * @returns {boolean} isVariationPage true if the page is the rootCompId of a variation
                         *
                         * @example
                         * documentServices.appStudio.widget.variations.isVariationPage('c1dmp')
                         */
                        isVariationPage: publicMethodUtils.defineGetter(appStudio.widgets.variations.isVariationPage),
                        /**
                         * returns an array of variations associated with the passed widget
                         *
                         * @member documentServices.appStudio.widgets.variations
                         * @param {Pointer} widgetPointer the widget the variations are associated with
                         * @returns {array} widgetVariations An array of variation data {pointer, name}
                         *
                         * @example
                         * const widgetPointer = {type: "data", id: "dataItem-jnk7t9gu"}
                         * documentServices.appStudio.widget.variations.getWidgetVariations(widgetPointer)
                         */
                        getWidgetVariations: publicMethodUtils.defineGetter(
                            appStudio.widgets.variations.getWidgetVariations
                        ),
                        /**
                         * returns the variation pointer associated with the passed pageId
                         *
                         * @member documentServices.appStudio.widgets.variations
                         * @param {string} pageId
                         * @returns {Pointer} variationPointer An array of variation data items
                         *
                         * @example
                         * const variationPointer = documentServices.appStudio.widget.variations.getByRootCompId('c1dmp')
                         */
                        getByRootCompId: publicMethodUtils.defineGetter(
                            appStudio.widgets.variations.getVariationByRootCompId
                        )
                    },
                    presets: {
                        create: publicMethodUtils.defineDataManipulationAction(appStudio.widgets.presets.createPreset, {
                            getReturnValue: appStudio.getNewDataItemPointer
                        }),
                        remove: publicMethodUtils.defineDataManipulationAction(appStudio.widgets.presets.removePreset),
                        display: publicMethodUtils.defineDataManipulationAction(
                            appStudio.widgets.presets.displayPreset
                        ),
                        getWidgetPresets: publicMethodUtils.defineGetter(appStudio.widgets.presets.getWidgetPresets),
                        getPresetVariantId: publicMethodUtils.defineGetter(
                            appStudio.widgets.presets.getPresetVariantId
                        ),
                        getPresetVariantPointer: publicMethodUtils.defineGetter(
                            appStudio.widgets.presets.getPresetVariantPointer
                        ),
                        name: {
                            get: publicMethodUtils.defineGetter(appStudio.widgets.presets.getPresetName),
                            set: publicMethodUtils.defineDataManipulationAction(appStudio.widgets.presets.setPresetName)
                        },
                        defaultSize: {
                            get: publicMethodUtils.defineGetter(appStudio.widgets.presets.getPresetDefaultSize),
                            set: publicMethodUtils.defineDataManipulationAction(
                                appStudio.widgets.presets.setPresetDefaultSize
                            )
                        },
                        duplicate: publicMethodUtils.defineDataManipulationAction(
                            appStudio.widgets.presets.duplicatePreset,
                            {
                                getReturnValue: appStudio.getNewDataItemPointer
                            }
                        ),
                        movePreset: publicMethodUtils.defineDataManipulationAction(appStudio.widgets.presets.movePreset)
                    },
                    getAppWidgetRefFromPointer: publicMethodUtils.defineGetter(appStudio.getAppWidgetRefFromPointer),
                    moveWidget: publicMethodUtils.defineDataManipulationAction(appStudio.widgets.moveWidget)
                },
                codePackages: {
                    add: publicMethodUtils.defineDataManipulationAction(appStudio.codePackages.addCodePackagesData),
                    set: publicMethodUtils.defineDataManipulationAction(appStudio.codePackages.setCodePackagesData),
                    get: publicMethodUtils.defineGetter(appStudio.codePackages.getCodePackagesData),
                    remove: publicMethodUtils.defineDataManipulationAction(
                        appStudio.codePackages.removeCodePackagesData
                    )
                },
                getAppType: publicMethodUtils.defineGetter(appStudio.getAppType)
            }
        },
        initMethod: appStudio.initialize
    }
}
