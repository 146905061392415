'use strict'
const _ = require('lodash')
const {styleItemSelectorType} = require('../parseStylableStyleItems')

function isThemeFont(fontValue) {
    return typeof fontValue === 'string' && /^font_\d+$/.test(fontValue)
}

/**
 *  When font is theme-based, CSS rule should have this declaration:
 * .selector { -st-mixin: font_1 }
 * @param {import('../index').Property} property - set of CSS key/value pairs for certain selector
 * @returns {boolean}
 */
const isFontMixin = property =>
    property.prop === '-st-mixin' && typeof property.value === 'string' && property.value.includes('font_')

const FONT_PROPERTIES = ['font-style', 'font-variant', 'font-weight', 'font-size', 'font-family']
const hasFontProperties = rule => {
    return rule.some(({prop}) => FONT_PROPERTIES.includes(prop))
}

const camelize = s => s.replace(/-./g, x => x[1].toUpperCase())

const fixLineHeightForFontShorthand = lineHeight => (/\d+(\.\d+)?em/.test(lineHeight) ? lineHeight : '1.4em')

const supportedFontWeightValues = ['normal', 'bold', 'bolder', 'lighter']

const getFontWeightAsKeyword = numberOrKeyword => {
    if (supportedFontWeightValues.includes(numberOrKeyword)) {
        return numberOrKeyword
    }
    const numericValue = parseInt(numberOrKeyword, 10)
    return numericValue >= 700 ? 'bold' : 'normal'
}

/**
 * @param {import('../index').FontObjectParts} fontProperties
 * @returns {import('../index').FontObjectParts}
 */
const formatPropertiesValues = fontProperties => {
    const fontPropTransformMap = {
        fontWeight: getFontWeightAsKeyword
    }
    return Object.entries(fontProperties).reduce((acc, [prop, value]) => {
        const newValue = fontPropTransformMap[prop] ? fontPropTransformMap[prop](value) : value
        return {
            ...acc,
            [prop]: newValue
        }
    }, {})
}

/**
 * @param {import('../index').Property[]} rule
 * @returns {import('../index').FontPropertiesOverride}
 */
const extractFontPropsOverrideFromRule = rule => {
    const applicableOverrides = rule.reduce((overrides, decl) => {
        const {prop, value} = decl

        // Font overrides written above the -st-mixin are overridden by it
        if (prop === '-st-mixin') {
            return {}
        }

        if (!FONT_PROPERTIES.includes(prop)) {
            return overrides
        }

        const formattedKey = camelize(prop)

        return {...overrides, [formattedKey]: value}
    }, {})
    return formatPropertiesValues(applicableOverrides)
}

/**
 * turns font object into single font shorthand value (compatible with Thunderbolt validation)
 * @param {import('../index').FontObject} fontObject
 * @returns {string}
 */
function stringifyFont(fontObject) {
    const {fontStyle, fontVariant, fontWeight, fontSize, fontSizeUnit, lineHeight, fontFamily} = fontObject
    const optionalLineHeight = lineHeight ? `/${lineHeight}` : ''
    const font = [fontStyle, fontVariant, fontWeight, `${fontSize}${fontSizeUnit}${optionalLineHeight}`, fontFamily]
        .filter(Boolean)
        .join(' ')
    return font
}

/**
 *
 * @param {import('../index').Property[]} properties
 * @returns {import('../index').FontPropertiesOverride}
 */
const extractOverrides = properties => {
    return properties.reduce((acc, prop) => {
        if (!_.isEmpty(prop.override)) {
            return {
                ...acc,
                [prop.prop]: prop.override
            }
        }
        return acc
    }, {})
}

/**
 * returns complete font object; missing properties are filled with fallback values
 * @param {import('../index').FontObjectParts} fontProps
 * @returns {import('../index').FontObject}
 */
const getFontPropertiesWithFallbacks = fontProps => {
    const {
        fontStyle = 'normal',
        fontVariant = 'normal',
        fontWeight = 'normal',
        fontSize = '16px',
        lineHeight = '1.4em',
        fontFamily = 'sans-serif'
    } = fontProps
    const sizeValue = fontSize.replace(/[a-z|%]+/, '') || '16'
    const sizeUnit = fontSize.replace(sizeValue, '') || 'px'

    return {
        fontStyle,
        fontVariant,
        fontWeight,
        fontSize: sizeValue,
        fontSizeUnit: sizeUnit,
        lineHeight: fixLineHeightForFontShorthand(lineHeight),
        fontFamily
    }
}

/**
 * removes font-related properties (font-size, font-weight etc.)
 * @param {import('../index').Property[]} rule
 * @returns {import('../index').Property[]}
 */
const filterOutFontProperties = rule => {
    return rule.filter(({prop}) => ![...FONT_PROPERTIES, '-st-mixin'].includes(prop))
}

/**
 * @param {object} params
 * @param {string} params.value
 * @param {import('../index').FontObjectParts} [params.valueRaw]
 * @param {import('../index').FontPropertiesOverride} [params.override]
 * @returns {import('../index').Property}
 */
const createFontProperty = ({value, valueRaw, override}) => {
    return {
        prop: 'font',
        cssProp: 'font',
        value,
        ...(override && {override}),
        ...(valueRaw && {valueRaw})
    }
}

/**
 *
 * @param {import('../index').Property[]} properties
 * @returns {import('../index').FontObjectParts}
 */
function pickOwnFontProperties(properties) {
    const cssFontProps = ['font-style', 'font-variant', 'font-weight', 'font-size', 'line-height', 'font-family']
    return cssFontProps.reduce((acc, key) => {
        const value = _.get(
            properties.find(({prop}) => prop === key),
            'value'
        )
        const isPropDefined = value !== undefined
        return {
            ...acc,
            ...(isPropDefined && {[camelize(key)]: value})
        }
    }, {})
}

/**
 *
 * @param {import('../index').Property[]} properties
 * @returns {import('../index').Property[]}
 */
function transformFontValues(properties) {
    const fontMixinProperty = properties.find(isFontMixin)
    if (fontMixinProperty) {
        const override = extractFontPropsOverrideFromRule(properties)

        return [
            ...filterOutFontProperties(properties),
            createFontProperty({
                value: fontMixinProperty.value,
                override
            })
        ]
    } else if (!hasFontProperties(properties)) {
        return properties
    }
    const ownFontProperties = pickOwnFontProperties(properties)
    const fontWithFallbacks = getFontPropertiesWithFallbacks(ownFontProperties)

    return [
        ...filterOutFontProperties(properties),
        createFontProperty({
            value: stringifyFont(fontWithFallbacks),
            valueRaw: ownFontProperties // this value will be used for state inheritance, so it's important to only keep selector's own properties without fallback values
        })
    ]
}

function hasOverriddenProperties(source, objectWithOverrides) {
    return !Object.entries(objectWithOverrides).every(([property, val]) => source[property] === val)
}

function isFontProperty({prop}) {
    return prop === 'font'
}

/**
 * @param {import('../index').Property[]} properties
 * @returns {import('../index').Property[]}
 */
function removeFontProperty(properties) {
    return properties.filter(({prop}) => prop !== 'font')
}

/**
 * @param {import('../index').Property[]} properties
 * @param {import('../index').Property} [optionalNewFont]
 * @returns {import('../index').Property[]}
 */
function replaceFontProperty(properties, optionalNewFont) {
    return [...removeFontProperty(properties), ...(optionalNewFont ? [optionalNewFont] : [])]
}

/**
 *
 * @param {import('../index').FontObjectParts} fontProperties
 * @returns {import('../index').FontPropertiesOverride}
 */
function createOverrideWithInheritance(fontProperties) {
    const overrideKeys = ['fontStyle', 'fontWeight', 'fontVariant', 'fontSize', 'fontFamily']
    const override = overrideKeys.reduce((acc, nextKey) => {
        const value = fontProperties[nextKey]
        if (value === undefined) {
            return acc
        }
        return {
            ...acc,
            [nextKey]: value
        }
    }, {})
    return formatPropertiesValues(override)
}
/**
 *
 * @param {import('../index').Property[]} stateProps
 * @param {import('../index').Property[]} rootProps
 * @returns {import('../index').Property[]}
 */
function inheritFontFromRoot(stateProps, rootProps) {
    const stateFont = stateProps.find(isFontProperty)
    const rootFont = rootProps.find(isFontProperty)
    const shouldNotInherit = !stateFont || !rootFont || isThemeFont(stateFont.value)
    if (shouldNotInherit) {
        return stateProps
    }
    const optionalNewFont = getStateFontAfterInheritance(rootFont, stateFont)
    return replaceFontProperty(stateProps, optionalNewFont)
}

/**
 * @param {import('../index').Property} rootFont
 * @param {import('../index').Property} stateFont
 * @returns {import('../index').Property}
 */
function getStateFontAfterInheritance(rootFont, stateFont) {
    if (isThemeFont(rootFont.value)) {
        const stateFontOverride = stateFont.valueRaw
        const hasOverridesOverRoot = hasOverriddenProperties(rootFont.override, stateFontOverride)
        if (hasOverridesOverRoot) {
            const override = createOverrideWithInheritance({
                ...rootFont.override,
                ...stateFontOverride
            })
            return createFontProperty({
                value: rootFont.value,
                override
            })
        }
    } else if (hasOverriddenProperties(rootFont.valueRaw, stateFont.valueRaw)) {
        const fontParts = {...rootFont.valueRaw, ...stateFont.valueRaw}
        return createFontProperty({
            value: stringifyFont(getFontPropertiesWithFallbacks(fontParts)),
            valueRaw: fontParts
        })
    }
    return null
}

/**
 * @param {Array<import('../index').ParsedStyleItem>} styleItems
 * @returns {Array<import('../index').ParsedStyleItem>}
 */
function migrateFonts(styleItems) {
    return styleItems.map(styleItem => {
        const {parsedStyle} = styleItem

        const updatedParsedStyle = Object.entries(parsedStyle).reduce(
            (acc, [selector, {properties, mobileProperties}]) => {
                const updatedProperties = transformFontValues(properties)
                const updatedMobileProperties = transformFontValues(mobileProperties)
                return {
                    ...acc,
                    [selector]: {
                        ...acc[selector],
                        properties: updatedProperties,
                        mobileProperties: updatedMobileProperties,
                        propertiesOverride: extractOverrides(updatedProperties),
                        mobilePropertiesOverride: extractOverrides(updatedMobileProperties)
                    }
                }
            },
            parsedStyle
        )
        const parsedStyleWithStateInheritance = Object.entries(updatedParsedStyle).reduce(
            (acc, [selector, {properties, mobileProperties, metadata}]) => {
                const isStateStyle = metadata.type === styleItemSelectorType.STATE
                const rootSelectorStyle = updatedParsedStyle[metadata.root]
                if (!isStateStyle || !rootSelectorStyle) {
                    return acc
                }
                const updatedProperties = inheritFontFromRoot(properties, rootSelectorStyle.properties)
                const updatedMobileProperties = inheritFontFromRoot(
                    mobileProperties,
                    rootSelectorStyle.mobileProperties
                )
                return {
                    ...acc,
                    [selector]: {
                        ...acc[selector],
                        properties: updatedProperties,
                        mobileProperties: updatedMobileProperties,
                        propertiesOverride: extractOverrides(updatedProperties),
                        mobilePropertiesOverride: extractOverrides(updatedMobileProperties)
                    }
                }
            },
            updatedParsedStyle
        )

        return {
            ...styleItem,
            parsedStyle: parsedStyleWithStateInheritance
        }
    })
}

module.exports = migrateFonts
