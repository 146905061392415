import type {Pointer, CompRef, PS} from '@wix/document-services-types'
import variantsUtils from '../variants/variantsUtils'
import constants from '../constants/constants'
import dsUtils from '../utils/utils'

const {DATA_TYPES} = constants

const ERRORS = {
    designOverVariants: (componentPointer: Pointer) =>
        `design over variants should not be used, please contact DM group, component pointer: ${componentPointer}`,
    designOverVariantsWithModes: (componentPointer: Pointer) =>
        `variant designs should not be used in modes, component pointer: ${componentPointer}`
}

export const get_COMPONENTS_TYPES_WHITE_LIST_FOR_DESIGN_IN_VARIANTS = () => {
    return {
        'responsive.components.Section': true,
        'responsive.components.HeaderSection': true,
        'responsive.components.FooterSection': true,
        'wysiwyg.viewer.components.StripColumnsContainer': true,
        'wysiwyg.viewer.components.ClassicSection': true
    }
}

export const isCompTypeIsValidForDesignInVariants = (compType: string) =>
    get_COMPONENTS_TYPES_WHITE_LIST_FOR_DESIGN_IN_VARIANTS()[compType]

export const shouldUseDesignInVariants = (ps: PS, componentPointer: CompRef) => {
    const shouldConsiderVariants = variantsUtils.shouldConsiderVariants(ps, componentPointer, DATA_TYPES.design)
    const actualComponentPointer = dsUtils.replaceRuntimeRefWithOriginal(ps, componentPointer)
    const componentType = dsUtils.getComponentType(ps, actualComponentPointer)
    if (shouldConsiderVariants && !isCompTypeIsValidForDesignInVariants(componentType)) {
        throw new Error(ERRORS.designOverVariants(componentPointer))
    }

    return shouldConsiderVariants
}
