import type {RendererModelFromServer} from '@wix/document-services-types'
import {createGlobalConfig, panoramaClientFactory, PanoramaPlatform} from '@wix/panorama-client-web'
import type {PanoramaClientFactory} from '@wix/panorama-client'

export type PanoramaFactory = Omit<PanoramaClientFactory<any>, 'use'>
const defaultConfig = createGlobalConfig()
export const createPanoramaFactory = (
    rendererModel: RendererModelFromServer,
    config = defaultConfig
): PanoramaFactory => {
    return panoramaClientFactory({
        baseParams: {
            platform: PanoramaPlatform.Viewer,
            fullArtifactId: 'com.wixpress.html-client.document-management',
            componentId: 'document-management',
            uuid: rendererModel.siteOwnerCoBranding?.accountOwnerId ?? rendererModel.userId,
            msid: rendererModel.metaSiteId
        }
    }).withGlobalConfig(config)
}
