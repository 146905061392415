import _ from 'lodash'
import type {Pointer, ExternalsBuilderMap, ExternalsMap} from '@wix/document-services-types'
import {COMP_DATA_QUERY_KEYS_WITH_STYLE, DATA_TYPES, EDITOR_DATA_TYPES} from '../../../constants/constants'
import type {DalValue, ExtensionAPI} from '@wix/document-manager-core'
import {
    DATASET_APPLICATION_ID,
    DATASET_COMPONENT_TYPE,
    DATASET_CONTROLLER_TYPE,
    type DetailedValue
} from '@wix/import-export-utils'

export interface ExternalsHandler {
    pack(
        ref: DetailedValue,
        externals: ExternalsBuilderMap,
        refPointer: Pointer,
        item: DalValue,
        extensionAPI: ExtensionAPI
    ): void
    unpack(ref: DetailedValue, externals: ExternalsMap, newId: string | string[], item: DalValue): void
}

export const UNNECESSARY_PROPS = ['parent', 'layout', 'metaData', 'mobileMetaData']
export const STRUCTURE_PROPERTIES_TO_KEEP = [
    ...UNNECESSARY_PROPS,
    ..._.values(COMP_DATA_QUERY_KEYS_WITH_STYLE),
    'components',
    'id'
]

const EXCLUDED_VIEWER_DATA_TYPES = [DATA_TYPES.variants, DATA_TYPES.mobileHints, DATA_TYPES.fixerVersions]
export const EXCLUDED_NAMESPACES = new Set([...EXCLUDED_VIEWER_DATA_TYPES, ..._.values(EDITOR_DATA_TYPES)])
export const INCLUDED_NAMESPACES = _.omitBy(COMP_DATA_QUERY_KEYS_WITH_STYLE, (query: string, namespace: string) =>
    EXCLUDED_NAMESPACES.has(namespace)
)

export const DEFAULT_BREAKPOINTS_MAX_RANGE = 2 ** 31 - 1
export const DEFAULT_BREAKPOINTS_MIN_RANGE = 320

export const EVENTS = {
    IMPORT_EXPORT: {
        AFTER_IMPORT: 'IMPORT_EXPORT_AFTER_IMPORT'
    }
}

export const isDatasetAppController = (
    componentType: string,
    applicationId: string,
    controllerType: string
): boolean => {
    return (
        componentType === DATASET_COMPONENT_TYPE &&
        applicationId === DATASET_APPLICATION_ID &&
        controllerType === DATASET_CONTROLLER_TYPE
    )
}
