function getPackagePath(packageName: string): [string, string] {
    return ['wixapps', packageName]
}

function getPackageDescriptorPath(packageName: string): string[] {
    return getPackagePath(packageName).concat(['descriptor'])
}

export default {
    getPackageDescriptorPath
}
