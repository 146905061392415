export default {
    MARK_NAMES: [
        '',
        'Init',
        'visible',
        'interactive',
        'main-r executed',
        'utils loaded',
        'packages loaded',
        'data prepared',
        'rendered',
        '',
        '',
        '',
        'Partially visible',
        '',
        'complete'
    ],

    MEASURE_NAMES: {
        0: 'network',
        /**
         * @enum {string} Duration of beat 1 to 4 : from HTML receive start until main-r finished
         */
        4: 'main-r executed',
        /**
         * @enum {string} Duration of beat 4 to 5 : some JS packages downloaded and initialized
         */
        5: 'warmup loaded',
        /**
         * @enum {string} Duration of beat 5 to 6 : rest of JS packages downloaded and initialized
         */
        6: 'packages loaded',
        /**
         * @enum {string} Duration of beat 6 to 7 : load site data (which should be cached already), fix and prepare
         */
        7: 'data prepared',
        /**
         * @enum {string} Duration of beat 7 to 8 : react render (different in Code)
         */
        8: 'render',
        /**
         * @enum {string} Duration of beat 8 to 3 : re-layout and waiting for browser to layout
         */
        finish: 'layout',
        /**
         * @enum {string} Duration of beat 3 to 14 : progressively reveal (render) components below the fold
         */
        14: 'progressive reveal',
        /**
         * @enum {string} Duration until 16 : TPAs ready
         */
        16: 'TPAs'
    },

    RENDER_PREPARE: 'prepare',
    /**
     * @enum {string} Duration of render happened again, e.g. blog
     */
    RE_RENDER: 'reRender',
    /**
     * @enum {string} Duration of re-layout (which can happen multiple times)
     */
    RE_LAYOUT: 'reLayout',

    SAVE: {
        SAVE_DATA_FIXER: 'save-data-fixer'
    },

    AUTOSAVE: {
        PRESAVE_OPERATIONS: 'autosave-presave-operations',
        SEND_AJAX: 'autosave-send-ajax',
        CALC_DIFF: 'autosave-calc-diff',
        LOAD_AJAX: 'autosave-load-ajax',
        LOAD_APPLY: 'autosave-load-apply'
    },
    /**
     * @enum {string} Duration of all init methods of documentServices
     */
    DS_INIT: 'documentServices initMethod',
    PAGE: {
        INITIALIZE: 'initializePage'
    },
    TPA: {
        INITIALIZE: 'tpa.initialize'
    },
    MOBILE: {
        /**
         * @enum {string} Duration of full conversion of one page
         */
        CONVERT_PAGE: 'convertPage',
        /**
         * @enum {string} Duration of partial conversion of all pages
         */
        MERGE_ALL_PAGES: 'mergeAllPages',
        /**
         * @enum {string} Duration of conversion on load with autosave
         */
        CONVERSION_ON_LOAD_AUTOSAVE: 'conversionOnLoadAutosave'
    }
}
