import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import siteMetadataAPI from '../../siteMetadata/siteMetadata'

function getSiteMetaData(ps: PS) {
    return siteMetadataAPI.getProperty(ps, siteMetadataAPI.PROPERTY_NAMES.SITE_META_DATA)
}

function fixSiteMetaData(ps: PS, siteMetaData) {
    const mobileMetaData = _.omit(siteMetaData, ['preloader', 'contactInfo', 'quickActions']) || {}
    const preloader = _.clone(_.get(siteMetaData, 'preloader')) || {}
    const contactInfo = _.clone(_.get(siteMetaData, 'contactInfo')) || {}
    const quickActions = _.clone(_.get(siteMetaData, 'quickActions')) || {}
    ps.extensionAPI.schemaAPI.addDefaultsAndValidate('MobileMetaData', mobileMetaData, 'data')
    ps.extensionAPI.schemaAPI.addDefaultsAndValidate('Preloader', preloader, 'data')
    ps.extensionAPI.schemaAPI.addDefaultsAndValidate('ContactInfo', contactInfo, 'data')
    ps.extensionAPI.schemaAPI.addDefaultsAndValidate('QuickActions', quickActions, 'data')
    return _.assign(mobileMetaData, {preloader, contactInfo, quickActions})
}

function setSiteMetaData(ps: PS, siteMetaData) {
    siteMetadataAPI.setProperty(ps, siteMetadataAPI.PROPERTY_NAMES.SITE_META_DATA, fixSiteMetaData(ps, siteMetaData))
}

export default {
    setSiteMetaData,
    getSiteMetaData,
    fixSiteMetaData
}
