import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'

function matrixGalleryRelativeToScreenPlugin(siteAPI, layout, compProperties, compPointer: Pointer) {
    const measureMap = siteAPI.getSiteMeasureMap()
    const itemsContainerHeight = _.get(measureMap.custom, [compPointer.id, 'itemsContainer', 'height'])
    const showMoreHeight = _.get(measureMap.custom, [compPointer.id, 'showMore', 'height'])
    return itemsContainerHeight && showMoreHeight
        ? {
              height: itemsContainerHeight + showMoreHeight
          }
        : {}
}

export default matrixGalleryRelativeToScreenPlugin
