import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import skinExports from '@wix/santa-ds-libs/src/skinExports'
import dataModel from '../../dataModel/dataModel'
import theme from '../../theme/theme'
import dsUtils from '../../utils/utils'

function beforeAdd(ps, compToAddPointer, containerPointer, compDefinitionPrototype) {
    const shouldFixItemHeight =
        !_.get(compDefinitionPrototype, 'props.menuItemHeight') && _.get(compDefinitionPrototype, 'layout.height')

    if (shouldFixItemHeight) {
        const {height} = compDefinitionPrototype.layout

        const menuItems = dataModel.getDataItemById(
            ps,
            dsUtils.stripHashIfExists(compDefinitionPrototype.data.menuRef)
        ).items
        const itemsCount = _.filter(menuItems, 'isVisible').length

        const isStyleInDocument = _.isString(compDefinitionPrototype.style)
        const pageId = ps.pointers.components.getPageOfComponent(containerPointer).id
        let style = isStyleInDocument
            ? theme.styles.get(ps, compDefinitionPrototype.style, pageId)
            : compDefinitionPrototype.style
        if (isStyleInDocument && !style) {
            theme.styles.createDefaultThemeStyle(
                ps,
                compDefinitionPrototype.componentType,
                compDefinitionPrototype.style
            )
            style = theme.styles.get(ps, compDefinitionPrototype.style, pageId)
        }
        const separatorHeight = parseInt(style.style.properties.sepw || style.style.properties.separatorHeight || 0, 10)
        const currentSkinExports = skinExports[compDefinitionPrototype.skin]

        const newItemHeight = santaCoreUtils.verticalMenuCalculations.getItemHeight(
            height,
            separatorHeight,
            itemsCount,
            currentSkinExports
        )

        compDefinitionPrototype.props.menuItemHeight = newItemHeight
    }
}

export default {
    beforeAdd
}
