const postcss = require('postcss')

const MOBILE_VARIANT_QUERY = 'value(mobileMediaQuery)'

const PROPS_TO_IGNORE = ['-st-from', '-st-extends', '-st-named']

const styleItemSelectorType = {
    ROOT: 'root',
    STATE: 'state'
}

function getRuleProperties(rule, propertiesMapping = {}) {
    /** @type {Array<import('./index').Property>} */
    let ruleProperties = []

    function isDuplicatedProperty(propertyName) {
        return ruleProperties.some(property => property.prop === propertyName)
    }

    function filterDuplicatedProperties(propertyName) {
        return ruleProperties.filter(property => property.prop !== propertyName)
    }

    rule.walkDecls(decl => {
        if (PROPS_TO_IGNORE.includes(decl.prop)) {
            return
        }

        const mappedPropName = propertiesMapping[decl.prop]
        const propertyName = mappedPropName || decl.prop

        if (isDuplicatedProperty(propertyName)) {
            ruleProperties = filterDuplicatedProperties(propertyName)
        }

        ruleProperties.push({
            cssProp: decl.prop,
            prop: propertyName,
            value: decl.value
        })
    })

    return ruleProperties
}

/**
 * @param {string} root
 * @returns {import('./index').ParsedStyleItem['parsedStyle'][0]}
 */
function initiateStyleObject(root) {
    return {
        properties: [],
        propertiesOverride: {},
        mobilePropertiesOverride: {},
        mobileProperties: [],
        metadata: {
            type: root ? styleItemSelectorType.STATE : styleItemSelectorType.ROOT,
            root: root ?? null
        }
    }
}

/**
 * @param {import('./index').ParsedStyleItem} parsedStyleItem
 */

function inheritDesktopPropertiesInMobileProperties(parsedStyleItem) {
    const hasMobileStyles = Object.values(parsedStyleItem.parsedStyle).some(style => style.mobileProperties.length > 0)
    Object.values(parsedStyleItem.parsedStyle).forEach(style => {
        if (hasMobileStyles || parsedStyleItem?.metadata?.isMobileOnly) {
            const isMobilePropExists = property =>
                style.mobileProperties.some(mobileProperty => mobileProperty.prop === property.prop)
            style.mobileProperties = [
                ...style.properties.filter(property => !isMobilePropExists(property)),
                ...style.mobileProperties
            ]
        }
    })
}

/**
 *
 * @param {Array<import('./index').StyleItemWithMetadata>} styleItems
 * @param {object} options
 * @param {import('./index').SelectorsMapping} options.selectorsMappings
 * @returns {Array<import('./index').ParsedStyleItem>}
 */
function parseStylableStyleItems(styleItems, options) {
    return styleItems.map(styleItem => {
        /** @type {import('./index').ParsedStyleItem} */
        const parsedStyleItem = {...styleItem, parsedStyle: {}}

        const ast = postcss.parse(parsedStyleItem.style.properties['$st-css'])

        ast.walkRules(rule => {
            const isMediaQuery = rule.parent.type === 'atrule'
            // @ts-ignore
            const isMobileVariantRule = isMediaQuery && rule.parent.params === MOBILE_VARIANT_QUERY
            /**
             * "atrule" refers to mediaQuery. Relevant properties:
             * name - what follows @
             * params - the value, that follows name,
             * for example, for query "media(mobileVariant) {}"
             * name would contain "media" and params "mobileVariant"
             */

            const isUnknownMediaQuery = isMediaQuery && !isMobileVariantRule
            const selectorOptions = options?.selectorsMappings?.[rule.selector]

            if (isUnknownMediaQuery || !selectorOptions) {
                return
            }

            const {name, root, properties} = selectorOptions

            if (!parsedStyleItem.parsedStyle[name]) {
                parsedStyleItem.parsedStyle[name] = initiateStyleObject(root)
            }

            const ruleProperties = getRuleProperties(rule, properties)

            /** @param {import('./index').Property} property */
            function isPropertyAlreadyExists(property) {
                return ruleProperties.some(ruleProperty => ruleProperty.prop === property.prop)
            }

            function getPropertiesWithoutOverrides(propertiesType) {
                return parsedStyleItem.parsedStyle[name][propertiesType].filter(
                    property => !isPropertyAlreadyExists(property)
                )
            }

            if (isMobileVariantRule) {
                parsedStyleItem.parsedStyle[name].mobileProperties = [
                    ...getPropertiesWithoutOverrides('mobileProperties'),
                    ...ruleProperties
                ]
            } else {
                parsedStyleItem.parsedStyle[name].properties = [
                    ...getPropertiesWithoutOverrides('properties'),
                    ...ruleProperties
                ]
            }
        })

        inheritDesktopPropertiesInMobileProperties(parsedStyleItem)

        return parsedStyleItem
    })
}

module.exports = {
    parseStylableStyleItems,
    styleItemSelectorType,
    MOBILE_VARIANT_QUERY,
    PROPS_TO_IGNORE
}
