'use strict'
const _ = require('lodash')
const {convertToMesh} = require('@wix/document-manager-utils')
const {stripHashIfExists} = require('../helpers/dataUtils')

const DESKTOP = 'DESKTOP'
const MOBILE = 'MOBILE'
const VARIANTS_DATA_NAMESPACE = 'variants_data'
const LAYOUT_DATA_NAMESPACE = 'layout_data'
const PROPS_DATA_NAMESPACE = 'component_properties'
const TRANSFORMATIONS_DATA_NAMESPACE = 'transformations_data'
const MOBILE_VARIANT_ID = 'MOBILE-VARIANT'
const TRANSFORM_DATA = 'TransformData'
const VARIANT_RELATION = 'VariantRelation'
const {
    newSchemaService: {staticInstance: schemaService}
} = require('@wix/document-services-json-schemas')

const BASE_PROPS_SCHEMA_TYPE = 'DefaultProperties'
const PAGE_TYPES = ['Document', 'Page']
const notConvertedTypes = new Set(['wysiwyg.viewer.components.PageGroup'])

function getChildren(structure, viewMode) {
    if (structure.type === 'Document' && viewMode !== MOBILE) {
        return structure.children
    }
    if (PAGE_TYPES.includes(structure.type) && viewMode === MOBILE) {
        return structure.mobileComponents
    }
    return structure.components
}

function getAllComponents(structure, viewMode) {
    const stack = [structure]
    const componentsMap = {}

    while (stack.length > 0) {
        const currentStructure = stack.pop()
        componentsMap[currentStructure.id] = currentStructure
        const children = getChildren(currentStructure, viewMode)

        _.forEach(children, child => {
            child.parent = currentStructure.id
            stack.push(child)
        })
    }

    return componentsMap
}

function addVariantsDataIfNotExists(pageJson) {
    if (!pageJson.data[VARIANTS_DATA_NAMESPACE]) {
        pageJson.data[VARIANTS_DATA_NAMESPACE] = {}
    }
}

function addTransformationsData(pageJson) {
    if (!pageJson.data[TRANSFORMATIONS_DATA_NAMESPACE]) {
        pageJson.data[TRANSFORMATIONS_DATA_NAMESPACE] = {}
    }
}

function createPropsBuilder(compType) {
    const compDefinition = schemaService.getDefinition(compType) || {}
    const propsType = (compDefinition.propertyTypes || [])[0] || BASE_PROPS_SCHEMA_TYPE
    return schemaService.createItemAccordingToSchema(propsType, 'props')
}

const getStructureVariant = (comp, pageJson, viewMode = DESKTOP) => {
    return {
        props: {
            ...pageJson.data[PROPS_DATA_NAMESPACE][stripHashIfExists(comp.propertyQuery)]
        },
        layout: {...comp.layout},
        parent: comp.parent,
        components: _.map(getChildren(comp, viewMode), 'id'),
        transformations: comp.transformationQuery
            ? prepareTransformations(pageJson, comp.transformationQuery)
            : undefined
    }
}

const getStructureLayout = (componentId, {allComponents, pageJson}) => {
    const {structure} = pageJson
    const desktop = allComponents[DESKTOP][componentId]
    const mobile = allComponents[MOBILE][componentId]
    const structureToCheck = desktop ? desktop : mobile
    const structureLayout = {
        componentType: structureToCheck.componentType,
        pageId: structure.id,
        componentId,
        type: structureToCheck.type
    }
    if (desktop && desktop.layout) {
        structureLayout.desktop = getStructureVariant(desktop, pageJson)
    }
    if (mobile && mobile.layout) {
        structureLayout.mobile = getStructureVariant(mobile, pageJson, MOBILE)
    }
    return structureLayout
}

const generateLayoutId = uniqueIdGenerator => uniqueIdGenerator.getUniqueId('layout', '-', {bucket: 'layout'})

const generatePropsId = uniqueIdGenerator => uniqueIdGenerator.getUniqueId('propItem', '-', {bucket: 'props'})

const generateTransformationsId = uniqueIdGenerator =>
    uniqueIdGenerator.getUniqueId('transformations', '-', {bucket: 'transformations'})

const createPropsAndAddToPage =
    propsBuilder =>
    ({pageJson, uniqueIdGenerator}, propsData, shouldSplit) => {
        propsData.id = propsData.id || generatePropsId(uniqueIdGenerator)
        let propItemId

        if (shouldSplit && !pageJson.data[PROPS_DATA_NAMESPACE][propsData.id]) {
            propItemId = propsData.id
            pageJson.data[PROPS_DATA_NAMESPACE][propsData.id] = _.omit(propsData, ['fontScale'])
        }

        const mobilePropItemId = `${shouldSplit ? 'mobile_' : ''}${propsData.id}`
        pageJson.data[PROPS_DATA_NAMESPACE][mobilePropItemId] = propsBuilder(mobilePropItemId, propsData)

        return {propItemId, mobilePropItemId}
    }
const createPropsItem = createPropsAndAddToPage((id, propsData) => ({...propsData, id}))

const createLayoutAndAddToPage =
    layoutBuilder =>
    ({pageJson, uniqueIdGenerator}, layoutData) => {
        const id = generateLayoutId(uniqueIdGenerator)
        const layout = layoutBuilder(id, layoutData)
        pageJson.data[LAYOUT_DATA_NAMESPACE][id] = layout
        return layout
    }
const createLayoutRefArray = createLayoutAndAddToPage(id => ({
    id,
    type: 'RefArray',
    values: []
}))
const createLayoutItem = createLayoutAndAddToPage((id, layoutData) => ({
    id,
    ...layoutData
}))
const createMobileLayoutRelation = createLayoutAndAddToPage((id, relationData) => {
    return {
        id,
        type: 'VariantRelation',
        variants: [`#${MOBILE_VARIANT_ID}`],
        ...relationData
    }
})

const addLayoutToComponent = ({allComponents}, componentId, layoutId) => {
    const desktopComponent = allComponents[DESKTOP][componentId]
    if (desktopComponent) {
        desktopComponent.layoutQuery = `#${layoutId}`
    }
    const mobileComponent = allComponents[MOBILE][componentId]
    if (mobileComponent) {
        mobileComponent.layoutQuery = `#${layoutId}`
    }
}

const addPropsToComponent = ({allComponents}, componentId, propsId, mobilePropsId) => {
    const desktopComponent = allComponents[DESKTOP][componentId]

    if (propsId && desktopComponent) {
        desktopComponent.propertyQuery = propsId
    }

    const mobileComponent = allComponents[MOBILE][componentId]

    if (mobileComponent) {
        mobileComponent.propertyQuery = mobilePropsId
    }
}

const updateProps = (conversionResult, componentId, conversionContext) => {
    if (conversionResult.mobile.props) {
        const {allComponents} = conversionContext
        const mobileComponent = allComponents[MOBILE][componentId]
        const desktopComponent = allComponents[DESKTOP][componentId]
        const isSplit =
            !desktopComponent || (mobileComponent && mobileComponent.propertyQuery !== desktopComponent.propertyQuery)
        const {propItemId, mobilePropItemId} = createPropsItem(
            conversionContext,
            conversionResult.mobile.props,
            !isSplit
        )

        addPropsToComponent(conversionContext, componentId, propItemId, mobilePropItemId)
    }
}

const prepareTransformations = (pageJson, transformationQuery) => {
    const transformationsRefArray = _.cloneDeep(
        pageJson.data[TRANSFORMATIONS_DATA_NAMESPACE]?.[stripHashIfExists(transformationQuery)]
    )

    transformationsRefArray.transformationsObjects = {}
    const {values, transformationsObjects} = transformationsRefArray

    for (let i = 0; i < values.length; i++) {
        const transformationsId = stripHashIfExists(values[i])
        const transformationsObj = _.cloneDeep(pageJson.data[TRANSFORMATIONS_DATA_NAMESPACE][transformationsId])

        if (transformationsObj.type === TRANSFORM_DATA) {
            transformationsObjects[transformationsId] = transformationsObj
        } else if (transformationsObj.type === VARIANT_RELATION) {
            const variantRelationTransformationId = stripHashIfExists(transformationsObj.to)
            transformationsObj.to = _.cloneDeep(
                pageJson.data[TRANSFORMATIONS_DATA_NAMESPACE][variantRelationTransformationId]
            )
            transformationsObjects[transformationsId] = transformationsObj
        }
    }

    return transformationsRefArray
}

const addTransformationsToComponent = (desktopComponent, mobileComponent, refArrayId) => {
    if (desktopComponent) {
        desktopComponent.transformationQuery = `#${refArrayId}`
    }
    if (mobileComponent) {
        mobileComponent.transformationQuery = `#${refArrayId}`
    }
}

const createTransformationsItem =
    transformationsBuilder =>
    ({pageJson, uniqueIdGenerator}, transformationsData) => {
        const id = transformationsData?.id || generateTransformationsId(uniqueIdGenerator)
        const transformations = transformationsBuilder(id, transformationsData)
        _.set(pageJson, ['data', TRANSFORMATIONS_DATA_NAMESPACE, id], transformations)
        return transformations
    }
const createTransformationsRefArray = createTransformationsItem(id => ({
    id,
    type: 'RefArray',
    values: []
}))
const createTransformationsDataItem = createTransformationsItem((id, transformationsData) => ({
    id,
    ...transformationsData
}))
const createMobileTransformationsRelation = createTransformationsItem((id, relationData) => {
    return {
        id,
        type: 'VariantRelation',
        variants: [`#${MOBILE_VARIANT_ID}`],
        ...relationData
    }
})

const createTransformations = (conversionResult, componentId, conversionContext) => {
    const refArray = createTransformationsRefArray(conversionContext)

    if (conversionResult.default?.transformations?.default) {
        const defaultTransformations = createTransformationsDataItem(
            conversionContext,
            conversionResult.default.transformations.default
        )
        refArray.values.push(`#${defaultTransformations.id}`)
    }

    if (conversionResult.mobile?.transformations?.default) {
        const mobileTransformations = createTransformationsDataItem(
            conversionContext,
            conversionResult.mobile.transformations.default
        )
        const mobileRelation = createMobileTransformationsRelation(conversionContext, {
            to: `#${mobileTransformations.id}`,
            from: `#${componentId}`
        })
        refArray.values.push(`#${mobileRelation.id}`)
    }

    return refArray.id
}

const createTransformationsMobileVariant = (
    conversionContext,
    transformationsData,
    componentId,
    refArray,
    transformationsVariants
) => {
    const mobileTransformationsItem = createTransformationsDataItem(conversionContext, transformationsData)
    const mobileRelation = createMobileTransformationsRelation(conversionContext, {
        to: `#${mobileTransformationsItem.id}`,
        from: `#${componentId}`
    })
    if (transformationsVariants) {
        mobileRelation.variants = [...mobileRelation.variants, ...transformationsVariants]
    }
    refArray.values.push(`#${mobileRelation.id}`)

    return mobileRelation
}

const overrideTransformations = (conversionResult, componentId, conversionContext) => {
    const {pageJson, allComponents} = conversionContext

    const desktopComponent = allComponents[DESKTOP][componentId]
    const mobileComponent = allComponents[MOBILE][componentId]

    const refArray =
        pageJson.data[TRANSFORMATIONS_DATA_NAMESPACE][
            desktopComponent?.transformationQuery ?? mobileComponent?.transformationQuery
        ]

    if (conversionResult.default?.transformations) {
        const transformations = Object.values(conversionResult.default.transformations)
        for (let i = 0; i < transformations.length; i++) {
            if (transformations[i].type === TRANSFORM_DATA) {
                pageJson.data[TRANSFORMATIONS_DATA_NAMESPACE][transformations[i].id] = transformations[i]
            } else if (transformations[i].type === VARIANT_RELATION) {
                pageJson.data[TRANSFORMATIONS_DATA_NAMESPACE][transformations[i].to.id] = transformations[i].to
            }
        }
    }

    if (conversionResult.mobile?.transformations) {
        const transformations = Object.values(conversionResult.mobile.transformations)
        for (let i = 0; i < transformations.length; i++) {
            delete transformations[i].id
            if (transformations[i].type === TRANSFORM_DATA) {
                createTransformationsMobileVariant(conversionContext, transformations[i], componentId, refArray)
            } else if (transformations[i].type === VARIANT_RELATION) {
                delete transformations[i].to.id
                createTransformationsMobileVariant(
                    conversionContext,
                    transformations[i].to,
                    componentId,
                    refArray,
                    transformations[i].variants
                )
            }
        }
    }

    return refArray.id
}

const updateTransformations = (conversionResult, componentId, conversionContext) => {
    if (!conversionResult.default.transformations && !conversionResult.mobile.transformations) return

    const {allComponents} = conversionContext

    const desktopComponent = allComponents[DESKTOP][componentId]
    const mobileComponent = allComponents[MOBILE][componentId]

    let refArrayId
    if (!desktopComponent?.transformationQuery && !mobileComponent?.transformationQuery) {
        refArrayId = createTransformations(conversionResult, componentId, conversionContext)
    } else {
        refArrayId = overrideTransformations(conversionResult, componentId, conversionContext)
    }

    addTransformationsToComponent(desktopComponent, mobileComponent, refArrayId)
}

const writeMeshLayout = (conversionResult, componentId, conversionContext) => {
    const refArray = createLayoutRefArray(conversionContext)
    if (conversionResult.default.layout) {
        const defaultLayout = createLayoutItem(conversionContext, conversionResult.default.layout)
        refArray.values.push(`#${defaultLayout.id}`)
    }

    if (conversionResult.mobile.layout) {
        const mobileLayout = createLayoutItem(conversionContext, conversionResult.mobile.layout)
        const mobileRelation = createMobileLayoutRelation(conversionContext, {
            to: `#${mobileLayout.id}`,
            from: `#${componentId}`
        })
        refArray.values.push(`#${mobileRelation.id}`)
    }

    addLayoutToComponent(conversionContext, componentId, refArray.id)
}

const write = (convertedLayouts, conversionContext) => {
    _.forEach(convertedLayouts, (conversionResult, componentId) => {
        updateProps(conversionResult, componentId, conversionContext)
        updateTransformations(conversionResult, componentId, conversionContext)
        writeMeshLayout(conversionResult, componentId, conversionContext)
    })
}

const convertToMeshLayout = conversionContext => {
    const {allComponents} = conversionContext
    const uniqueComponentsIds = _.union(Object.keys(allComponents[DESKTOP]), Object.keys(allComponents[MOBILE]))
    const structureToConvertToMesh = _(uniqueComponentsIds)
        .keyBy()
        .mapValues(componentId => getStructureLayout(componentId, conversionContext))
        .pickBy(structure => !notConvertedTypes.has(structure.componentType))
        .value()

    const convertedToMeshStructure = {}
    // @ts-ignore
    convertToMesh(structureToConvertToMesh, convertedToMeshStructure, {
        createPropertiesItemByType: createPropsBuilder
    })
    write(convertedToMeshStructure, conversionContext)
}

const createZeroLayout = () => ({x: 0, y: 0, height: 0, width: 0})
function convertPageCompsToZeroLayout({allComponents}) {
    _.forEach(allComponents, componentsInViewMode => {
        _.forEach(componentsInViewMode, component => {
            component.layout = createZeroLayout()
        })
    })
}

const clearPropsAndLayout = pageJson => {
    pageJson.data[LAYOUT_DATA_NAMESPACE] = {}
    pageJson.data[PROPS_DATA_NAMESPACE] = pageJson.data[PROPS_DATA_NAMESPACE] || {}
}

const prepareContext = (pageJson, magicObject) => {
    const conversionContext = {
        allComponents: {
            [DESKTOP]: getAllComponents(pageJson.structure, DESKTOP),
            [MOBILE]: getAllComponents(pageJson.structure, MOBILE)
        },
        pageJson,
        uniqueIdGenerator: magicObject.dataFixerUtils.uniqueIdGenerator
    }
    return conversionContext
}

const shouldRunConversion = (magicObject, originalPageJson) => {
    return (
        magicObject.isViewerMode &&
        magicObject.isExperimentOpen('dm_meshLayout') &&
        (!originalPageJson.data[LAYOUT_DATA_NAMESPACE] ||
            Object.keys(originalPageJson.data[LAYOUT_DATA_NAMESPACE]).length === 0)
    )
}

module.exports = {
    name: 'meshDataFixer',
    version: 0,
    experimentalVersions: [
        {version: 1, experiment: 'dm_meshLayout'},
        {version: 2, experiment: 'dm_avoidZeroLayout'}
    ],
    exec(pageJson, pageIdsArray, magicObject) {
        if (!shouldRunConversion(magicObject, pageJson)) {
            return
        }

        clearPropsAndLayout(pageJson)
        addVariantsDataIfNotExists(pageJson)
        addTransformationsData(pageJson)
        const conversionContext = prepareContext(pageJson, magicObject)
        convertToMeshLayout(conversionContext)
        if (!magicObject.isExperimentOpen('dm_avoidZeroLayout')) {
            convertPageCompsToZeroLayout(conversionContext)
        }
    }
}
