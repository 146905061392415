import {CreateExtArgs, Extension, ExtensionAPI, pointerUtils} from '@wix/document-manager-core'
const {getPointer} = pointerUtils
export type Callback = () => void

// TODO: move to viewer-extension and get pointers from viewer-manager-adapter directly
const pointerType = 'runtime'

const viewerRuntimeType = 'viewerRuntime'

const createExtension = (): Extension => {
    const createExtensionAPI = ({dal, pointers}: CreateExtArgs): ExtensionAPI => {
        const setMobileView = (value: any): void => {
            const wantedIsMobilePointer = pointers.runtime.getWantedIsMobileView()
            dal.set(wantedIsMobilePointer, value)
        }

        const navigateToPage = (navInfo: any): void => {
            const wantedNavInfoPointer = pointers.runtime.getWantedNavInfo()
            dal.set(wantedNavInfoPointer, navInfo)
            dal.touch(wantedNavInfoPointer)
        }

        const setErrorPagesPopUp = (callback: Callback): void => {
            const errorPagesPopupPointer = pointers.runtime.getErrorPagesPopUp()
            dal.set(errorPagesPopupPointer, callback)
        }

        return {
            siteAPI: {
                setMobileView,
                navigateToPage,
                updatePageNavInfo: navigateToPage,
                setErrorPagesPopUp
            }
        }
    }

    const createPointersMethods = () => {
        const getTextRuntimeOverallBorders = () => getPointer('runtimeOverallBorders', pointerType)
        const getErrorPagesPopUp = () => getPointer('errorPagesPopUp', viewerRuntimeType)

        return {
            general: {
                getTextRuntimeOverallBorders
            },
            runtime: {
                getTextRuntimeOverallBorders,
                getErrorPagesPopUp
            }
        }
    }

    return {
        name: 'viewerRuntime',
        createPointersMethods,
        createExtensionAPI
    }
}

export {createExtension}
