import type {Pointer, PS} from '@wix/document-services-types'
import type {DalPointers} from '../types'
import {deepClone} from '@wix/wix-immutable-proxy'

const addAppDefinitionIdToTPAPages = (dalPointers: DalPointers | PS, pageComponentPointer: Pointer) => {
    const {dal, pointers} = dalPointers
    const pageDataPointer = pointers.data.getDataItemFromMaster(pageComponentPointer.id) //I do not want to work with serialized data
    const pageDataFromDal = dal.get(pageDataPointer)
    if (pageDataFromDal.tpaApplicationId) {
        const pageData = deepClone(pageDataFromDal)
        const appData = dal.get(pointers.rendererModel.getClientSpecMapEntry(pageData.tpaApplicationId))
        if (appData) {
            pageData.appDefinitionId = appData.appDefinitionId
            dal.set(pageDataPointer, pageData)
        }
    }
}
export {addAppDefinitionIdToTPAPages}
