import type {AnyRendererModel, RendererModelBuilderForHost} from '../host'

export class DefaultRendererModelBuilderForHost implements RendererModelBuilderForHost {
    private readonly rendererModelPromise: Promise<AnyRendererModel>

    constructor(rendererModel: AnyRendererModel) {
        this.rendererModelPromise = Promise.resolve(rendererModel)
    }
    getRendererModel(): Promise<AnyRendererModel> {
        return this.rendererModelPromise
    }
}
