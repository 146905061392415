import type {CompVariantPointer, PossibleViewModes, PS} from '@wix/document-services-types'
import _ from 'lodash'
import documentModeInfo from './documentModeInfo'
import constants from '../constants/constants'
import hooks from '../hooks/hooks'
import * as santaCoreUtils from '@wix/santa-core-utils'
import experiment from 'experiment-amd'
import mobileUtil from '../mobileUtilities/mobileUtilities'
import {ReportableError} from '@wix/document-manager-utils'
const {getSupportedViewModes} = mobileUtil
import variants from '../variants/variants'
import {MASTER_PAGE_ID} from '@wix/document-manager-extensions/dist/src/constants/constants'

const getSetViewModeInteractionParams = (ps: PS, viewMode: PossibleViewModes) => ({working_mode_to: viewMode})

/**
 * Sets the document's view mode (mobile or desktop)- sets the view that's rendered, regardless of the displaying device
 *  @param ps
 *  @param viewMode - MOBILE or DESKTOP
 */
function setViewMode(ps: PS, viewMode: PossibleViewModes) {
    const possibleViewModesValues = getSupportedViewModes(ps)

    const isValidViewMode = _.includes(possibleViewModesValues, viewMode)
    if (!isValidViewMode) {
        throw new Error(`view mode not valid. valid viewModes are: ${possibleViewModesValues}`)
    }
    if (documentModeInfo.getViewMode(ps) !== viewMode) {
        const isMobile = viewMode === constants.VIEW_MODES.MOBILE
        if (isMobile) {
            mobileUtil.setMobileViewMode(ps, hooks)
        }
        ps.siteAPI.setMobileView(isMobile)
        hooks.executeHook(hooks.HOOKS.SWITCH_VIEW_MODE.AFTER, null, [ps])
    }
}

const setDeviceType = (ps: PS, viewMode: PossibleViewModes) => {
    const mobileVariant = variants.getMobileVariant(ps)
    if (viewMode === constants.VIEW_MODES.MOBILE) {
        variants.enable(ps, mobileVariant)
    } else {
        variants.disable(ps, mobileVariant)
    }
}

const getDeviceType = (ps: PS): PossibleViewModes => {
    const mobileVariant = variants.getMobileVariant(ps)
    const ptr = ps.pointers.getPointer(MASTER_PAGE_ID, constants.VIEW_MODES.DESKTOP, {
        variants: [mobileVariant]
    }) as CompVariantPointer

    return variants.getComponentEnabledVariants(ps, ptr) ? constants.VIEW_MODES.MOBILE : constants.VIEW_MODES.DESKTOP
}

function setRenderFlag(ps: PS, flagName: string, value) {
    const renderFlagPointer = ps.pointers.general.getRenderFlag(flagName)
    ps.dal.set(renderFlagPointer, value)
}

function getRenderFlag(ps: PS, flagName: string) {
    const renderFlagPointer = ps.pointers.general.getRenderFlag(flagName)
    return ps.dal.get(renderFlagPointer)
}

function enablePageProtection(ps: PS, allowed) {
    setRenderFlag(ps, 'isPageProtectionEnabled', allowed)
}

function allowPlaying(ps: PS, allowed) {
    setRenderFlag(ps, 'isPlayingAllowed', allowed)
}

function allowZoom(ps: PS, allowed) {
    setRenderFlag(ps, 'isZoomAllowed', allowed)
}

function enableTransFormScaleOnSite(ps: PS, transformScale) {
    setRenderFlag(ps, 'siteTransformScale', transformScale)
}

function allowSocialInteraction(ps: PS, allowed) {
    setRenderFlag(ps, 'isSocialInteractionAllowed', allowed)
}

function allowExternalNavigation(ps: PS, allowed) {
    setRenderFlag(ps, 'isExternalNavigationAllowed', allowed)
}

function allowRenderFixedPositionContainer(ps: PS, allowed) {
    setRenderFlag(ps, 'renderFixedPositionContainers', allowed)
}

function allowRenderFixedPositionBackgrounds(ps: PS, allowed) {
    setRenderFlag(ps, 'renderFixedPositionBackgrounds', allowed)
}

//This api changes the DEFAULT behavior of rendering sticky for all components
function setRenderSticky(ps: PS, value) {
    setRenderFlag(ps, 'renderSticky', value)
}

//This api defines specific components that we want to render sticky even though the default may be false
const setCompsToRenderSticky = (ps: PS, compIds: string[], shouldRenderSticky: boolean) => {
    const compsToRenderSticky = getRenderFlag(ps, 'compsToRenderSticky') ?? {}
    if (shouldRenderSticky) {
        _.forEach(compIds, compId => {
            compsToRenderSticky[compId] = true
        })
    } else {
        _.forEach(compIds, compId => {
            delete compsToRenderSticky[compId]
        })
    }
    setRenderFlag(ps, 'compsToRenderSticky', compsToRenderSticky)
}

const getCompsToRenderSticky = (ps: PS): string[] => {
    const compsToRenderSticky = getRenderFlag(ps, 'compsToRenderSticky') ?? {}
    return _.keys(compsToRenderSticky)
}

function setShouldKeepTPAComps(ps: PS, value) {
    setRenderFlag(ps, 'shouldKeepTPAComps', value)
}

function setRenderScrollSnap(ps: PS, value) {
    setRenderFlag(ps, 'renderScrollSnap', value)
}

/**
 * Set whether back to top button is visible in the viewer
 * @param {ps} ps
 * @param {boolean} allowed
 */
function allowBackToTopButton(ps: PS, allowed: boolean) {
    setRenderFlag(ps, 'isBackToTopButtonAllowed', allowed)
}

function allowSlideShowGalleryClick(ps: PS, allowed: boolean) {
    setRenderFlag(ps, 'isSlideShowGalleryClickAllowed', allowed)
}

function allowShouldUpdateJsonFromMeasureMap(ps: PS, allowed: boolean) {
    setRenderFlag(ps, 'shouldUpdateJsonFromMeasureMap', allowed)
}

function allowTinyMenuOpening(ps: PS, allowed: boolean) {
    setRenderFlag(ps, 'isTinyMenuOpenAllowed', allowed)
}

function enableResetGalleryToOriginalState(ps: PS, allowed: boolean) {
    setRenderFlag(ps, 'shouldResetGalleryToOriginalState', allowed)
}

function enableResetComponent(ps: PS, allowed: boolean) {
    setRenderFlag(ps, 'shouldResetComponent', allowed)
}

function allowSiteMembersDialogsOpening(ps: PS, allowed: boolean) {
    setRenderFlag(ps, 'isSiteMembersDialogsOpenAllowed', allowed)
}

function allowShowingFixedComponents(ps: PS, allowed: boolean) {
    setRenderFlag(ps, 'allowShowingFixedComponents', allowed)
}

function allowShowingHiddenComponents(ps: PS, allowed: boolean) {
    setRenderFlag(ps, 'showHiddenComponents', allowed)
}

function isShowingFixedComponentsEnabled(ps: PS) {
    return getRenderFlag(ps, 'allowShowingFixedComponents')
}

function isHiddenComponentsEnabled(ps: PS) {
    return getRenderFlag(ps, 'showHiddenComponents')
}

function isFetchDynamicPageInnerRoutesEnabled(ps: PS) {
    return getRenderFlag(ps, 'shouldFetchDynamicPageInnerRoutes')
}

function isControllersVisibilityEnabled(ps: PS) {
    return getRenderFlag(ps, 'showControllers')
}

function setComponentViewMode(ps: PS, viewMode: string) {
    hooks.executeHook(hooks.HOOKS.CHANGE_COMPONENT_VIEW_MODE.BEFORE, 'componentViewMode', [ps, viewMode])
    setRenderFlag(ps, 'componentViewMode', viewMode)
    hooks.executeHook(hooks.HOOKS.CHANGE_COMPONENT_VIEW_MODE.AFTER, 'componentViewMode', [ps, viewMode])
}

function showControllers(ps: PS, allowed: boolean) {
    setRenderFlag(ps, 'showControllers', allowed)
}

/**
 * Set whether wix ads are visible in the viewer
 */
function allowWixAds(ps: PS, allowed: boolean) {
    setRenderFlag(ps, 'isWixAdsAllowed', allowed)
}

function resetBehaviorsRegistration(ps: PS, reset: boolean) {
    if (reset) {
        ps.siteAPI.resetBehaviorsRegistration()
    }
}

function allowActionByName(ps: PS, actionName: string, isAllowed: boolean) {
    validateBooleanParam(isAllowed)

    if (isAllowed) {
        ps.siteAPI.enableAction(actionName)
    } else {
        ps.siteAPI.disableAction(actionName)
    }
}

function allowPageTransitions(ps: PS, isAllowed: boolean) {
    allowActionByName(ps, 'pageTransition', isAllowed)
}

function enableComponentTransitions(ps: PS, isEnabled: boolean) {
    setRenderFlag(ps, 'isComponentTransitionAllowed', isEnabled)
}

function enableRenderTPAsIframe(ps: PS, isEnabled: boolean) {
    setRenderFlag(ps, 'shouldRenderTPAsIframe', isEnabled)
}

function enableStubifyComponents(ps: PS, isEnabled: boolean) {
    setRenderFlag(ps, 'shouldStubifyComponents', isEnabled)
}

function enableFetchDynamicPageInnerRoutes(ps: PS, isEnabled: boolean) {
    setRenderFlag(ps, 'shouldFetchDynamicPageInnerRoutes', isEnabled)
}

function validateBooleanParam(param: boolean) {
    if (typeof param !== 'boolean') {
        throw new Error(`param ${param} is not valid. Should be boolean`)
    }
}

function setShouldKeepChildrenInPlace(ps: PS, val) {
    setRenderFlag(ps, 'enforceShouldKeepChildrenInPlace', val)
}

function enablePageAutoGrowForDetachedComponents(ps: PS, val) {
    setRenderFlag(ps, 'enablePageAutoGrowForDetachedComponents', !!val)
}

function setPreserveCompLayoutOnReparent(ps: PS, val) {
    setRenderFlag(ps, 'preserveCompLayoutOnReparent', val)
}

function getExtraSiteHeight(ps: PS) {
    return getRenderFlag(ps, 'extraSiteHeight')
}

function setExtraSiteHeight(ps: PS, extraSiteHeight) {
    setRenderFlag(ps, 'extraSiteHeight', extraSiteHeight)
}

function allowSiteOverflow(ps: PS, shouldAllowSiteOverflow) {
    setRenderFlag(ps, 'allowSiteOverflow', shouldAllowSiteOverflow)
}

function checkComponentsHiddenProperty(ps: PS, compIds) {
    const currentCompIds = getRenderFlag(ps, 'ignoreComponentsHiddenProperty') || []
    return santaCoreUtils.objectUtils.isDifferent(compIds, currentCompIds)
}

function ignoreComponentsHiddenProperty(ps: PS, compIds) {
    if (checkComponentsHiddenProperty(ps, compIds)) {
        setRenderFlag(ps, 'ignoreComponentsHiddenProperty', compIds)
    }
}

function checkComponentsCollapsedProperty(ps: PS, compIds) {
    const currentCompIds = getRenderFlag(ps, 'ignoreComponentsCollapsedProperty') || []
    return santaCoreUtils.objectUtils.isDifferent(compIds, currentCompIds)
}

function ignoreComponentsCollapsedProperty(ps: PS, compIds) {
    if (checkComponentsCollapsedProperty(ps, compIds)) {
        setRenderFlag(ps, 'ignoreComponentsCollapsedProperty', compIds)
    }
}

function allowWixCodeInitialization(ps: PS, allowed) {
    setRenderFlag(ps, 'initWixCode', allowed)
}

function setComponentPreviewState(ps: PS, compId: string, state) {
    const previewStateMap = getRenderFlag(ps, 'componentPreviewStates') || {}
    previewStateMap[compId] = state
    setRenderFlag(ps, 'componentPreviewStates', previewStateMap)
}

function getComponentPreviewState(ps: PS) {
    return getRenderFlag(ps, 'componentPreviewStates')
}

function setOnboardingViewportMode(ps: PS, mode) {
    setRenderFlag(ps, 'onboardingViewportMode', mode)
}

function setComponentVisibility(ps: PS, compId: string, state) {
    const map = getRenderFlag(ps, 'componentVisibility') || {}
    map[compId] = state
    setRenderFlag(ps, 'componentVisibility', map)
}

function isContactFormMigrationMode() {
    return experiment.isOpen('sv_contactFormFinalMigrationEditor')
}

function updateAnimationsViewMode(ps: PS, viewMode: PossibleViewModes) {
    const {animations} = ps.siteDataAPI.siteData
    animations.updateViewMode(viewMode)
}

function enableNativeComponentsForPreview(ps: PS, isPreview: boolean) {
    if (experiment.isOpen('sv_editorNativeComponents')) {
        setRenderFlag(ps, 'shouldModifyTpaStructure', isPreview)
    }
}

function getComponentViewMode(ps: PS) {
    return getRenderFlag(ps, 'componentViewMode')
}

const getImageTargetScale = (ps: PS) => getRenderFlag(ps, 'imagesTargetScale') ?? 1

const validateNumberInRange = (number: number, min: number, max: number) => {
    if (!_.isNumber(number) || number < min || number > max) {
        throw new ReportableError({
            errorType: 'INVALID_NUMBER',
            message: 'The given value is not number or out of range',
            extras: {number, min, max}
        })
    }
}

const setImageTargetScale = (ps: PS, scale: number) => {
    validateNumberInRange(scale, 0, 1)
    setRenderFlag(ps, 'imagesTargetScale', scale)
}

const getTransparentComponentsOverlay = (ps: PS) => getRenderFlag(ps, 'transparentComponentsOverlay')

const setTransparentComponentsOverlay = (ps: PS, threshold: number) => {
    if (threshold !== -1) {
        validateNumberInRange(threshold, 0, 1)
    }

    setRenderFlag(ps, 'transparentComponentsOverlay', threshold)
}

/**
 * @class documentServices.documentMode
 */

export default {
    getCompsToRenderSticky,
    setCompsToRenderSticky,
    getTransparentComponentsOverlay,
    setTransparentComponentsOverlay,
    getImageTargetScale,
    setImageTargetScale,
    isMobileView: documentModeInfo.isMobileView,
    getViewMode: documentModeInfo.getViewMode,
    setViewMode,
    getSetViewModeInteractionParams,
    runtime: {
        /**
         * Resets all dynamic data
         */
        reset: _.noop
    },
    /**
     * Set whether component is visible
     * @param {string} component id
     * @param {boolean} if the component is hidden
     */
    setComponentVisibility,
    /**
     * Retrieves the component view mode, which can be either "preview" or "editor".
     */
    getComponentViewMode,
    /**
     * Set whether component view mode
     * @param {string} view mode
     */
    setComponentViewMode,
    /**
     * Set whether playing of galleries, audio players and videos is allowed in the viewer
     * @param {boolean} allowed
     */
    enablePlaying: allowPlaying,
    /**
     * Set whether page protection / site members pop ups are allowed in the viewer
     * @param {boolean} allowed
     */
    enablePageProtection,
    /**
     * Set whether media zoom is opened in the viewer
     * @param {boolean} allowed
     */
    enableZoom: allowZoom,
    /**
     * Set whether media zoom is opened in the viewer
     * @param {boolean} allowed
     */
    enableTransFormScaleOnSite,

    getSiteTransformScale(ps: PS) {
        const renderFlagPointer = ps.pointers.general.getRenderFlag('siteTransformScale')
        return ps.dal.get(renderFlagPointer)
    },
    /**
     * Set whether social components interact with 3rd party api's in the viewer
     * @param {boolean} allowed
     */
    enableSocialInteraction: allowSocialInteraction,
    /**
     * Set whether navigation to external urls is allowed in the viewer
     * @param {boolean} allowed
     */
    enableExternalNavigation: allowExternalNavigation,
    enableBackToTopButton: allowBackToTopButton,
    /**
     * Set whether wix ads are visible
     * @param {boolean} allowed
     */
    enableWixAds: allowWixAds,
    /**
     * Set whether clicking on slide show gallery works in the viewer
     * @param {boolean} allowed
     */
    enableSlideShowGalleryClick: allowSlideShowGalleryClick,

    enableShouldUpdateJsonFromMeasureMap: allowShouldUpdateJsonFromMeasureMap,
    /**
     * Set whether tiny menu can be opened
     * @param {boolean} allowed
     */
    enableTinyMenuOpening: allowTinyMenuOpening,
    enableResetGalleryToOriginalState,
    enableResetComponent,
    /**
     * Set whether site members dialogs can be opened
     * @param {boolean} allowed
     */
    allowSiteMembersDialogsOpening,
    /**
     * Reset all registered behaviors
     * @param {boolean} reset if not 'true' do nothing
     */
    resetBehaviorsRegistration,
    /**
     * Disable or Enable triggers of an action
     * @param {String} actionName
     */
    enableAction: allowActionByName,
    /**
     * Set whether page transitions are working when navigating between pages in the viewer
     * @param {boolean} isAllowed
     */
    enablePageTransitions: allowPageTransitions,
    /**
     * Set whether animation transitions on components are enabled. i.e. boxSlideShow
     * @param {boolean} isAllowed
     */
    enableComponentTransitions,
    /**
     * Set whether TPAs internal iFrames should be rendered
     * @param {boolean} isAllowed
     */
    enableRenderTPAsIframe,
    enableStubifyComponents,
    enableFetchDynamicPageInnerRoutes,
    /**
     * Disable or enable fixed position on containers
     * @param {boolean} isAllowed
     */
    enableRenderFixedPositionContainers: allowRenderFixedPositionContainer,
    /**
     * set the viewport mode for onboarding workaround,
     * the mode value will indicate from where to return the viewport height.
     * onboarding editor implementation bug .... should be fixed by onboarding
     */
    setOnboardingViewportMode,
    enableRenderFixedPositionBackgrounds: allowRenderFixedPositionBackgrounds,
    getExtraSiteHeight,
    setExtraSiteHeight,
    allowSiteOverflow,
    allowShowingFixedComponents,
    showHiddenComponents: allowShowingHiddenComponents,
    isHiddenComponentsEnabled,
    isFetchDynamicPageInnerRoutesEnabled,
    isShowingFixedComponentsEnabled,
    ignoreComponentsHiddenProperty,
    ignoreComponentsCollapsedProperty,
    setComponentPreviewState,
    getComponentPreviewState,
    showControllers,
    isControllersVisibilityEnabled,
    allowWixCodeInitialization,
    isContactFormMigrationMode,
    setShouldKeepChildrenInPlace,
    enablePageAutoGrowForDetachedComponents,
    setPreserveCompLayoutOnReparent,
    updateAnimationsViewMode,
    enableNativeComponentsForPreview,
    setRenderScrollSnap,
    setRenderSticky,
    setShouldKeepTPAComps,
    setDeviceType,
    getDeviceType
}
