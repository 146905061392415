import _ from 'lodash'
import dsUtils from '../utils/utils'
import dataIds from '../dataModel/dataIds'
import variants from '../variants/variants'
import dataModel from '../dataModel/dataModel'
import constants from '../constants/constants'
import variantsUtils from '../variants/variantsUtils'
import {pointerUtils} from '@wix/document-manager-core'
import reactionsUtils from '../reactions/reactionsUtils'
import {dataUtils} from '@wix/document-manager-extensions'
import dataSerialization from '../dataModel/dataSerialization'
import type {
    CompRef,
    PS,
    Pointer,
    ComponentEffect,
    CompVariantPointer,
    BaseAnimationOptions
} from '@wix/document-services-types'

const {DATA_TYPES, MASTER_PAGE_ID, VIEW_MODES} = constants
const {createInnerRef} = dataUtils
const EFFECTS = DATA_TYPES.effects
const {stripHashIfExists} = dsUtils
const {getInnerPointer} = pointerUtils

const getEffectToAddPointer = () => ({
    id: dataIds.generateNewId(EFFECTS),
    type: EFFECTS
})

const convertValueWithKeyframeEffect = (keyframeEffectPointer: Pointer, optionsData: BaseAnimationOptions) => {
    return {
        ...optionsData,
        keyframeEffect: createInnerRef(keyframeEffectPointer.id)
    }
}

const getConvertedValueIfNeeded = (componentEffect: ComponentEffect) => {
    const {value} = componentEffect
    return value.keyframeEffect ? convertValueWithKeyframeEffect(value.keyframeEffect, value) : value
}

const addEffect = (
    ps: PS,
    effectToAddPointer: Pointer,
    componentPointer: CompRef,
    componentEffect: ComponentEffect
) => {
    const pagePointer = ps.pointers.components.getPageOfComponent(componentPointer)
    const pageId = pagePointer?.id

    //create effect
    const valueData = getConvertedValueIfNeeded(componentEffect)
    const refArray = dataModel.refArray.create(ps, [valueData])
    const effectId = dataSerialization.addSerializedItemToPage(
        ps,
        pageId,
        {
            name: componentEffect.name,
            type: componentEffect.type,
            value: refArray
        },
        effectToAddPointer.id,
        EFFECTS
    )

    const effectsListPointer = dataModel.getComponentDataPointerByType(ps, componentPointer, EFFECTS)
    if (!effectsListPointer) {
        //create effects list and link comp to it
        const effectsListId = dataModel.addDeserializedItemToPage(ps, pageId, EFFECTS, {
            type: 'EffectsList',
            values: [createInnerRef(effectId)]
        })

        ps.extensionAPI.dataModel.components.linkComponentToItemByTypeDesktopAndMobile(
            componentPointer,
            effectsListId,
            EFFECTS
        )
    } else {
        //add effect to existing effects list of the component
        const effectsListValues = ps.dal.get(effectsListPointer).values
        ps.dal.set(getInnerPointer(effectsListPointer, ['values']), [...effectsListValues, createInnerRef(effectId)])
    }

    if (!usesNewAnimations(ps)) {
        dataModel.updateDataItem(ps, {id: MASTER_PAGE_ID, type: VIEW_MODES.DESKTOP}, {usesNewAnimations: true})
    }
}

const getEffect = (ps: PS, componentPointer: CompVariantPointer, effectPointer: Pointer): ComponentEffect => {
    const effectDataItem = ps.dal.get(effectPointer)
    const effectPointerWithVariants = componentPointer.variants
        ? variants.getPointerWithVariants(ps, effectPointer, componentPointer.variants)
        : effectPointer
    const effectValueDataItem = variantsUtils.getDataConsideringVariants(
        ps,
        effectPointerWithVariants,
        'value',
        EFFECTS
    )

    const result = {
        name: effectDataItem.name,
        type: effectDataItem.type,
        value: effectValueDataItem
    }

    if (effectValueDataItem?.keyframeEffect) {
        const keyframeEffectPointer = ps.pointers.data.getKeyframeEffectDataItem(
            stripHashIfExists(effectValueDataItem.keyframeEffect)
        )
        return {
            ...result,
            value: {...effectValueDataItem, keyframeEffect: keyframeEffectPointer}
        }
    }

    return result
}

const updateEffectData = (
    ps: PS,
    componentPointer: CompVariantPointer,
    effectPointer: Pointer,
    componentEffect: ComponentEffect
) => {
    const currentEffect = ps.dal.get(effectPointer)
    if (componentEffect.type && currentEffect.type !== componentEffect.type) {
        throw ps.extensionAPI.effects.createEffectsError('Effect type cannot be changed')
    }

    ps.dal.set({...effectPointer, innerPath: ['name']}, componentEffect.name)
    const effectPointerWithVariants = componentPointer.variants
        ? variants.getPointerWithVariants(ps, effectPointer, componentPointer.variants)
        : effectPointer
    const valueData = getConvertedValueIfNeeded(componentEffect)
    variantsUtils.updateDataConsideringVariants(ps, effectPointerWithVariants, 'value', valueData, EFFECTS)
}

const removeEffect = (ps: PS, componentPointer: CompVariantPointer, effectPointer: Pointer) => {
    if (_.isEmpty(componentPointer.variants)) {
        const reactions = reactionsUtils.getAllReactionsTo(ps, effectPointer)
        if (!_.isEmpty(reactions)) {
            throw ps.extensionAPI.effects.createEffectsError(
                `Effect cannot be removed as it is referenced by the following reaction: ${JSON.stringify(
                    reactions
                )}. Please remove the reaction first`
            )
        }
        //remove all the values of the effect
        variantsUtils.removeDataConsideringVariants(ps, effectPointer, 'value', EFFECTS)

        //remove effect from effects list
        const effectListPointer = dataModel.getComponentDataPointerByType(ps, componentPointer, EFFECTS)
        const effectListDataItem = ps.dal.get(effectListPointer)
        ps.dal.set(effectListPointer, {
            ...effectListDataItem,
            values: _.without(effectListDataItem.values, `#${effectPointer.id}`)
        })
        //remove the effect
        ps.dal.remove(effectPointer)
    } else {
        const effectPointerWithVariants = variants.getPointerWithVariants(ps, effectPointer, componentPointer.variants)
        variantsUtils.removeDataConsideringVariants(ps, effectPointerWithVariants, 'value', EFFECTS)
    }
}

const getComponentEffectList = (ps: PS, componentPointer: CompRef): Pointer[] => {
    return ps.extensionAPI.effects.list(componentPointer)
}

const usesNewAnimations = (ps: PS): boolean => {
    return ps.extensionAPI.effects.usesNewAnimations()
}

export default {
    addEffect,
    getEffect,
    removeEffect,
    updateEffectData,
    usesNewAnimations,
    getEffectToAddPointer,
    getComponentEffectList
}
