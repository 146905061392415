'use strict'

const coreUtils = require('@wix/santa-core-utils')
const {getThemeData} = require('../helpers/themeDataUtils')

const enrichThemeDataWithTextThemes = themeData => {
    const {font, textTheme} = themeData

    if (!textTheme || !textTheme.length) {
        themeData.textTheme = font.map(fontString => coreUtils.fonts.fontStringToTextTheme(fontString))
    }
}

module.exports = {
    name: 'textThemesDataFixer',
    version: 1,
    exec(pageJson) {
        if (pageJson.structure.type === 'Document') {
            const themeData = getThemeData(pageJson)
            if (themeData) {
                enrichThemeDataWithTextThemes(themeData)
            }
        }
        return pageJson
    },
    fixerRequiresReruns: true
}
