import {
    CreateExtArgs,
    DocumentDataTypes,
    Extension,
    ExtensionAPI,
    pointerUtils,
    CreateExtensionArgument,
    DmApis
} from '@wix/document-manager-core'
import type {Pointer, ClientSpecMap, WixCodeModel} from '@wix/document-services-types'
import type {SnapshotExtApi} from './snapshots'
import _ from 'lodash'
import type {RMApi} from './rendererModel'
import * as constants from '../constants/constants'
import {guidUtils} from '@wix/santa-core-utils'

const {getGUID: generateGUID} = guidUtils
const {getPointer} = pointerUtils
const pointerType = 'wixCode'
const pointerUndoableType = 'wixCodeUndoable'
const waitForApprovalPointerType = 'wixCodeWaitForApproval'
const isWixCodeProvisionedKey = 'isWixCodeProvisionedKey'
const isWixCodeModelExistKey = 'isWixCodeModelExistKey'
const hasSiteExtensionKey = 'hasSiteExtensionKey'

const rootId = pointerType
const UNDOABLE_BASE_PATH = ['undoable']
const UNDOABLE_MODIFIED_FILE_CONTENTS = UNDOABLE_BASE_PATH.concat('modifiedFileContents')
const ISOLATED_GRID_APP = 'ISOLATED_GRID_APP'
const WIX_CODE_SESSION_ID = 'wixCodeSessionId'

const createPointersMethods = () => {
    const getRoot = () => getPointer(rootId, pointerType)
    const getUndoableRoot = () => getPointer(rootId, pointerUndoableType, {innerPath: UNDOABLE_BASE_PATH})
    const getUndoableModifiedFileContentMap = () =>
        getPointer(rootId, pointerUndoableType, {innerPath: UNDOABLE_MODIFIED_FILE_CONTENTS})
    const getUndoableModifiedFileContent = (filePathId: string[]) =>
        getPointer(rootId, pointerUndoableType, {innerPath: UNDOABLE_MODIFIED_FILE_CONTENTS.concat(filePathId)})
    const getIsolatedGridApp = () => getPointer(ISOLATED_GRID_APP, pointerType)
    //Supporting set by path as a result of returned data from the server. Should be removed once we change this methodology
    const getUndoablePointerByPath = (path: string[]) => getPointer(rootId, pointerType, {innerPath: path})
    const getWixCodeSessionId = () => getPointer(rootId, pointerType, {innerPath: WIX_CODE_SESSION_ID})

    return {
        wixCode: {
            getRoot,
            getUndoableRoot,
            getUndoableModifiedFileContentMap,
            getUndoableModifiedFileContent,
            getUndoablePointerByPath,
            getIsolatedGridApp,
            getWixCodeSessionId
        }
    }
}

const getDocumentDataTypes = (): DocumentDataTypes => ({
    [pointerType]: {},
    [pointerUndoableType]: {},
    [waitForApprovalPointerType]: {}
})
type WixCodePreProvisionListener = () => void

export interface WixCodeExtensionAPI extends ExtensionAPI {
    wixCode: {
        subscribeToWixCodeProvision(listener: WixCodePreProvisionListener): void
        updateWixCodeLocalProvisionState(isProvisioned: boolean): void
        updateWixCodeModelLocalState(hasWixCodeModel: boolean): void
        updateSiteExtensionState(hasSiteExtension: boolean): void
        setIsolatedGridAppId(gridAppId: string): void
        getIsolatedGridAppId(): string | undefined
        getEditedGridAppId(): string | undefined
        getOpenGridAppId(): string | undefined
        setOpenWixCodeAppId(gridAppId: string): void
        getRevisionGridAppId(): string | undefined
        setRevisionGridAppId(gridAppId: string): void
        getWixCodeModel(): WixCodeModel
        syncGridAppToViewer(): void
        isProvisioned(): boolean
        generateWixCodeSessionId(): void
        getWixCodeSessionId(): string
    }
    wixDataSchemas: {
        setLastModifiedVersion(fileId: string, version: Number): void
        getLastModifiedVersion(fileId: string): Number
    }
    siteAPI: {
        wasCodeAppIdValueChangedSinceLastSnapshot(tag: string, pointer: Pointer): boolean
    }
}

const createWixDataSchemasAPI = () => {
    const lastModifiedSchemaVersion = new Map()
    return {
        setLastModifiedVersion: (fileId: string, version: Number) => lastModifiedSchemaVersion.set(fileId, version),
        getLastModifiedVersion: (fileId: string) => lastModifiedSchemaVersion.get(fileId)
    }
}

const createExtension = ({logger}: CreateExtensionArgument): Extension => {
    const localChangesMap = new Map()
    const wixCodePreProvisionListeners: WixCodePreProvisionListener[] = []
    const notifyWixCodeProvisioned = () => {
        wixCodePreProvisionListeners.forEach(listener => {
            try {
                listener()
            } catch (e) {
                console.error(e)
                logger.captureError(e as Error, {tags: {wixCodeExtensionProvisionCallbackFailed: true}})
            }
        })
    }

    const triggerWixCodeProvisionedPostActions = (
        isWixCodeProvisioned: boolean,
        hasSiteExtension: boolean,
        hasGridAppId: boolean
    ) => {
        if (!isWixCodeProvisioned && hasSiteExtension && hasGridAppId) {
            localChangesMap.set(isWixCodeProvisionedKey, true)
            notifyWixCodeProvisioned()
        }
    }

    const onClientSpecMapUpdated = (clientSpecMap: ClientSpecMap) => {
        const isWixCodeProvisioned = localChangesMap.get(isWixCodeProvisionedKey)
        const wixCodeSiteExtension = _.find(clientSpecMap, {type: 'siteextension'})
        localChangesMap.set(hasSiteExtensionKey, !!wixCodeSiteExtension)
        const hasGridAppId = localChangesMap.get(isWixCodeModelExistKey)
        triggerWixCodeProvisionedPostActions(isWixCodeProvisioned, !!wixCodeSiteExtension, hasGridAppId)
    }

    const onWixCodeModelUpdated = (wixCodeModel: WixCodeModel) => {
        const isWixCodeProvisioned = localChangesMap.get(isWixCodeProvisionedKey)
        const hasGridAppId = !!_.get(wixCodeModel, ['appData', 'codeAppId'])
        localChangesMap.set(isWixCodeModelExistKey, hasGridAppId)
        const hasSiteExtensionInCsm = localChangesMap.get(hasSiteExtensionKey)
        triggerWixCodeProvisionedPostActions(isWixCodeProvisioned, hasSiteExtensionInCsm, hasGridAppId)
    }

    const updateWixCodeLocalProvisionState = (isWixCodeProvisioned: boolean) =>
        localChangesMap.set(isWixCodeProvisionedKey, isWixCodeProvisioned)
    const updateWixCodeModelLocalState = (isWixCodeModelExist: boolean) =>
        localChangesMap.set(isWixCodeModelExistKey, isWixCodeModelExist)
    const updateSiteExtensionState = (hasSiteExtension: boolean) =>
        localChangesMap.set(hasSiteExtensionKey, hasSiteExtension)

    const createExtensionAPI = ({extensionAPI, dal, pointers}: CreateExtArgs): WixCodeExtensionAPI => {
        const getEditedGridAppId = () => {
            if (dal.has(pointers.wixCode.getIsolatedGridApp())) {
                return dal.get(pointers.wixCode.getIsolatedGridApp())
            }
            if (dal.has(pointers.wixCode.getOpenWixCodeAppId())) {
                return dal.get(pointers.wixCode.getOpenWixCodeAppId())
            }
            return dal.get(pointers.wixCode.getRevisionGridAppId())
        }
        const hasWixCodeSpec = () => {
            const clientSpecMap = dal.get(pointers.rendererModel.getClientSpecMap())
            return !_.isEmpty(_.find(clientSpecMap, {type: 'siteextension'}))
        }
        const hasGridApp = () => !!getEditedGridAppId()

        const isProvisioned = () => hasWixCodeSpec() && hasGridApp()

        const generateWixCodeSessionId = () => {
            dal.set(pointers.wixCode.getWixCodeSessionId(), generateGUID())
        }
        return {
            wixCode: {
                subscribeToWixCodeProvision: (listener: WixCodePreProvisionListener) => {
                    if (_.isFunction(listener)) {
                        wixCodePreProvisionListeners.push(listener)
                    }
                },
                generateWixCodeSessionId,
                getWixCodeSessionId: () => dal.get(pointers.wixCode.getWixCodeSessionId()),
                updateWixCodeLocalProvisionState,
                updateWixCodeModelLocalState,
                updateSiteExtensionState,
                isProvisioned,
                getEditedGridAppId,
                getIsolatedGridAppId: () => dal.get(pointers.wixCode.getIsolatedGridApp()),
                setIsolatedGridAppId: (gridAppId: string) => dal.set(pointers.wixCode.getIsolatedGridApp(), gridAppId),
                getOpenGridAppId: () => dal.get(pointers.wixCode.getOpenWixCodeAppId()),
                setOpenWixCodeAppId: (gridAppId: string) => dal.set(pointers.wixCode.getOpenWixCodeAppId(), gridAppId),
                getRevisionGridAppId: () => dal.get(pointers.wixCode.getRevisionGridAppId()),
                setRevisionGridAppId: (gridAppId: string) =>
                    dal.set(pointers.wixCode.getRevisionGridAppId(), gridAppId),
                getWixCodeModel: () => dal.get(pointers.wixCode.getWixCodeModel()),
                syncGridAppToViewer: () => {
                    dal.touch(pointers.wixCode.getOpenWixCodeAppId())
                }
            },
            wixDataSchemas: createWixDataSchemasAPI(),
            siteAPI: {
                wasCodeAppIdValueChangedSinceLastSnapshot: (tag: string, pointer: Pointer) => {
                    const {snapshots} = extensionAPI as SnapshotExtApi
                    const previousSnapshot = snapshots.getLastSnapshotByTagName(tag)

                    if (!previousSnapshot) {
                        return false
                    }

                    const currentSnapshot = snapshots.getCurrentSnapshot()

                    const prevVal = previousSnapshot.getValue(pointer)
                    const currentVal = currentSnapshot.getValue(pointer)

                    return prevVal !== currentVal
                }
            }
        }
    }

    const recoverFromHavingOpenGridAppIdInTheRevisionGridAppPointer = ({extensionAPI, pointers, dal}: DmApis) => {
        // in the past, on load, we used to set the revision grid app pointer with the open grid app id
        // some sites have this saved in csave transactions
        // this code sets back the revision grid app pointer to have the value of the revision grid app id
        const {snapshots} = extensionAPI as SnapshotExtApi
        const {wixCode} = extensionAPI as WixCodeExtensionAPI
        const openGridApp = wixCode.getOpenGridAppId()
        const currentRevisionGridApp = wixCode.getRevisionGridAppId()
        if (openGridApp && openGridApp === currentRevisionGridApp) {
            const beforeCsaveSnapshot = snapshots.getLastSnapshotByTagName(constants.SNAPSHOTS.BEFORE_AUTOSAVE_APPLY)
            if (!beforeCsaveSnapshot) {
                return
            }
            const revisionGridAppPointer = pointers.wixCode.getRevisionGridAppId()
            const previousRevisionGridApp = beforeCsaveSnapshot.getValue(revisionGridAppPointer)
            if (previousRevisionGridApp !== currentRevisionGridApp) {
                dal.set(revisionGridAppPointer, previousRevisionGridApp)
            }
        }
    }
    const initialize = async (dmApis: DmApis) => {
        const {rendererModel} = dmApis.extensionAPI as RMApi
        rendererModel.registerToClientSpecMapUpdate(onClientSpecMapUpdated)
        rendererModel.registerToWixCodeModelUpdate(onWixCodeModelUpdated)
        recoverFromHavingOpenGridAppIdInTheRevisionGridAppPointer(dmApis)
    }

    return {
        name: 'wixCode',
        createPointersMethods,
        initialize,
        createExtensionAPI,
        getDocumentDataTypes
    }
}

export {createExtension}
