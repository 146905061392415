import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import Color from 'color'
import constants from '../../../constants/constants'
import type {DsFixer} from '../../dsDataFixers'

const valueToHex = (colorString: string) => {
    if (colorString.indexOf('#') !== 0) {
        const rgbString = colorString.indexOf('r') === 0 ? colorString : `rgba(${colorString})`
        const colorObj: any = new Color(rgbString)
        return colorObj.hexString()
    }
    return colorString
}

const fixer: DsFixer = {
    exec(ps: PS) {
        const THEME_DATA_ID = 'THEME_DATA'
        const type = 'color'
        const theme = ps.pointers.data.getThemeItem(THEME_DATA_ID, constants.MASTER_PAGE_ID)
        const pointer = ps.pointers.getInnerPointer(theme, type)
        const values = ps.dal.full.get(pointer)
        const result = _.map(values, valueToHex)
        if (!_.isEqual(result, values)) {
            ps.dal.full.set(pointer, result)
        }
    },
    name: 'rgbaSystemColorsFixer',
    version: 1
}
export default fixer
