import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import constants from '../constants/constants'
import dsUtils from '../utils/utils'

function getImageOrCropAspectRatio(compData, compMobileProps) {
    let aspectRatio = compData.width / compData.height
    if (!_.isEmpty(compData.crop) && _.isEmpty(compMobileProps.overrideCrop)) {
        aspectRatio = compData.crop.width / compData.crop.height
    } else if (!_.isEmpty(_.get(compMobileProps, 'overrideCrop'))) {
        aspectRatio = compMobileProps.overrideCrop.width / compMobileProps.overrideCrop.height
    }
    return aspectRatio
}

export default {
    /**
     * Get aspect ratio based on original image size or saved crop size
     * @param ps
     * @param compPtr
     * @returns {number}
     */
    getImageAspectRatio(ps: PS, compPtr: Pointer) {
        const compStructure = ps.dal.get(compPtr)
        const page = ps.pointers.components.getPageOfComponent(compPtr)

        const compDataPointer = ps.pointers.data.getDataItem(
            dsUtils.stripHashIfExists(compStructure.dataQuery),
            page.id
        )
        const compData = ps.dal.get(compDataPointer)

        let compMobileProps = {}
        if (compPtr.type === constants.VIEW_MODES.MOBILE) {
            const compMobilePropsPointer = ps.pointers.data.getPropertyItem(
                dsUtils.stripHashIfExists(compStructure.propertyQuery),
                page.id
            )
            compMobileProps = ps.dal.get(compMobilePropsPointer)
        }

        return getImageOrCropAspectRatio(compData, compMobileProps)
    },
    /**
     * Get aspect ratio based on current image (or any other component) size in its saved layout
     * @param ps
     * @param compPtr
     * @returns {number}
     */
    getStageAspectRatio(ps: PS, compPtr: Pointer): number {
        const compStructure = ps.dal.get(compPtr)
        const {width, height} = compStructure.layout
        return width / height
    }
}
