import type {PS} from '@wix/document-services-types'
import {guidUtils} from '@wix/santa-core-utils'

export default {
    getStyleIdToAdd(
        ps?: PS,
        componentReference?,
        optionalSkinName?: string,
        optionalStyleProperties?,
        optionalStyleId?: string
    ) {
        return optionalStyleId ?? guidUtils.getUniqueId('style', '-', {bucket: 'styleIds'})
    }
}
