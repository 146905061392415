/*
 the whitelist is based on a generated report summing up server errors,
 it's odd structure is kept so it could be easliy updated
*/
import _ from 'lodash'

const dataWhitelistRadicalBase: Record<string, Record<string, string[]>> = {
    document_data: {
        /* Image: [
                'linkType',
                'target',
                'href'
            ],*/
        'dsgnsys.WbuButton': ['debug']
    },
    connections_data: {
        ConnectionList: [
            //'items[].controllerId',
            //'items[].isPrimary',
            //'items[].config'
        ]
    }
}

const dataWhitelistConservativeBase: Record<string, Record<string, string[]>> = {
    component_properties: {
        AccordionProperties: ['stretch', 'zIndex', 'displayMode', 'onClickBehavior', 'effectName'],
        AddressInputProperties: ['margin'],
        ButtonProperties: ['displayMode', 'verticalMargin', 'horizontalMargin', 'placement'],
        CaptchaProperties: [
            'captchaType',
            //'isHidden',
            'required'
        ],
        CheckboxGroupProperties: ['placeholder'],
        CheckboxProperties: ['checkedByDefault', 'textAlignment', 'placeholder', 'textPadding'],
        CollageProperties: ['zIndex'],
        ColumnProperties: [
            'columnsMargin',
            'frameMargin',
            'rowMargin',
            'siteMargin',
            'displayMode',
            //'mediaBackgroundPadding',
            'mediaBackgroundPadding.undefined',
            'fullWidth'
            //'null expected'
        ],
        ComboBoxInputProperties: ['spacing'],
        DefaultProperties: ['itemsAlignment', 'columnsMargin', 'siteMargin', 'frameMargin', 'rowMargin'],
        DocumentMediaProperties: ['height'],
        ExpandableMenuProperties: ['menuItemHeightPx', 'originalHeightPx'],
        HoneycombProperties: [
            //'expandEnabled'
            'textColor',
            'alphaBackgroundMouseoverColor',
            'alphaTextBackgroundColor',
            'descriptionColor',
            'textBackgroundColor',
            'alphaDescriptionColor',
            'alphaHolesColor',
            'holesColor',
            'alphaTextColor',
            'backgroundMouseoverColor'
        ],
        HorizontalMenuProperties: [
            'displayMode',
            'itemsAlignment'
            //'autoTextDirection'
        ],
        ImpressProperties: ['borderWidth'],
        LoginSocialBarProperties: ['itemsAlignment'],
        MasonryProperties: [
            'showNavigation',
            'borderWidth',
            'stretch',
            'zIndex',
            'numOfColumns',
            'displayMode',
            'onClickBehavior',
            'effectName'
        ],
        MatrixGalleryProperties: [
            'stretch',
            'zIndex',
            'displayMode',
            'onClickBehavior',
            'effectName',
            'transDuration',
            'topToTop'
        ],
        MediaPlayerProperties: ['volume'],
        MusicPlayerProperties: ['showCoverImage', 'showTimestamp'],
        PageProperties: [
            'displayMode',
            'horizontalMargin',
            'labelMargin',
            'labelPadding',
            'placement',
            'verticalMargin',
            'columnsMargin',
            'siteMargin',
            'frameMargin',
            'rowMargin'
        ],
        ProGalleryProperties: [
            'options.toggleFullscreen',
            'options.controllers_layoutParams_info_masterInfo',
            'controllers_layoutParams_external_info_placement',
            'controllers_layoutParams_info_width__pixel'
        ],
        PageLink: ['isPopup'],
        PaginatedGridGalleryProperties: ['itemHeight', 'stretch', 'zIndex', 'displayMode', 'onClickBehavior'],
        RadioGroupProperties: ['placeholder'],
        RatingsInputProperties: ['ratingAlignment', 'ratingPosition'],
        RefComponentProperties: ['verticalText'],
        SlideShowGalleryProperties: [
            'maxRows',
            'displayMode',
            'onClickBehavior',
            'zIndex',
            'effectName',
            'numCols',
            'textMode'
        ],
        SliderGalleryProperties: [
            'onClickBehavior',
            'stretch',
            'zIndex',
            'displayMode',
            'showNavigation',
            'alignText',
            'effectName'
            //'autoplay',
            //'autoplayInterval',
            //'showAutoplay'
        ],
        StripContainerSlideShowProperties: ['verticalMargin', 'placement', 'horizontalMargin'],
        StripColumnsContainerProperties: ['displayMode', 'orientation'],
        StripShowcaseProperties: ['zIndex'],
        StripSlideshowProperties: ['zIndex'],
        StylableButtonProperties: ['displayMode'],
        ThumbnailsProperties: ['stretch', 'zIndex', 'displayMode', 'onClickBehaviour'],
        TimePickerProperties: [
            //'margin'
        ],
        TPAGluedProperties: ['displayMode'],
        TextAreaInputProperties: ['spacing'],
        TextInputProperties: ['spacing'],
        VectorImageProperties: ['onClickBehavior', 'zIndex'],
        VerticalMenuProperties: ['ifRef', 'moreButtonLabel'],
        VideoPlayerProperties: ['displayMode', 'effectName', 'onClickBehavior', 'zIndex'],
        VideoProperties: ['displayMode', 'effectName', 'onClickBehavior', 'zIndex'],
        WPhotoProperties: ['alignType', 'zIndex', 'overrideColor', 'fullWidth'],
        WRichTextProperties: ['displayMode', 'required', 'labelMargin', 'labelPadding'],
        StylableHorizontalMenuProperties: ['forceMegaMenuOpen'],
        SvgShapeProperties: ['displayMode'],
        FiveGridLineProperties: ['displayMode'],
        PayPalButtonProperties: [
            'origin',
            'selectedComponent',
            'style',
            'panelName',
            'useLastPanelPosition',
            'compType'
        ],
        CardsLayoutProperties: ['displayMode'],
        PopupContainerProperties: ['displayMode', 'columnsMargin', 'siteMargin', 'frameMargin', 'rowMargin'],
        ClassicSectionProperties: ['overrideAlignment'],
        BgImageStripUnifiedProperties: ['displayMode']
    },
    design_data: {
        BackgroundMedia: [
            //' this is a valid regex error $.color'
        ],
        Image: [
            //'name'
        ],
        MediaContainerDesignData: ['colorOverlay', 'cssStyle.themeMappings', 'panelExtensions'],
        MediaControlsDesignData: ['charas'],
        MediaContainerWithDividers: ['charas'],
        ShapeDivider: ['opcity'],
        MediaPlayerDesignData: ['dataChangeBehaviors'],
        TextFxMask: ['charas'],
        VectorImageDesignData: [
            'charas',
            //'shapeStyle.enableStroke',
            //'shapeStyle.opacity',
            //'shapeStyle.stroke',
            //'shapeStyle.strokeOpacity',
            //'shapeStyle.strokeWidth',
            'shapeStyle.undefined'
        ],
        WixVideo: ['upscaleMethod', 'focalPoint']
    },
    transitions_data: {
        TransitionData: ['name']
    },
    document_data: {
        AddressInput: [
            //' only one instance $.filter, null found object expected',
            //' only one instance $.sorting, null found object expected'
        ],
        Anchor: ['link'],
        AnchorLink: ['target', 'componentId', 'routerId', 'y', 'displayName', 'selection'],
        AppController: ['scopedData', 'tpaData', 'referenceId', 'text'],
        AudioPlayer: ['showTooltip'],
        BackgroundMedia: [
            //'color',
            //'noColor] ('
        ],
        BasicMenuItem: ['isSectionLink', 'order', 'symbol'],
        Captcha: ['label'],
        CheckboxGroup: ['placeholder'],
        CheckboxInput: ['scopedData', 'placeholder'],
        CustomElement: ['lastUpdated', 'ref', 'role', 'toggleRerender'],
        CustomMenuDataRef: ['link', 'referenceId', 'role', 'scopedData', 'name'],
        DynamicContactForm: ['dynamicFields[].isDisplayed'],
        DynamicPageLink: ['anchorName', 'pageId', 'target', 'url'],
        ExternalLink: ['pageId'],
        GeoMapLocation: ['addressInfo'],
        HtmlComponent: ['scopedData'],
        Image: [
            'adaptiveVideo',
            'autoplay',
            'crop.source',
            'duration',
            'fps',
            'generatedPosters',
            'hasAudio',
            'loop',
            'mute',
            'media',
            'playbackSpeed',
            'posterImageRef',
            'preload',
            'qualities',
            'quality',
            'referenceId',
            'videoId',
            'linkType',
            'target',
            'href',
            'original',
            //'crop',
            //'icon',
            'text'
            //'null expected, leftover garbage from analyzer'
        ],
        ImageList: ['referenceId', 'scopedData'],
        ImageX: [
            'image.creator',
            'image.fileInput',
            'image.fileName',
            'image.fileOutput',
            'image.fileSize',
            'image.fileUrl',
            'image.fullTitle',
            'image.id',
            'image.labels',
            'image.mediaType',
            'image.reference',
            'image.sourceName',
            'image.tags',
            'image.tagsEn',
            'image.title',
            'image.transcodingStatus',
            'image.vendor',
            //'scopedData[].crop.svgId'
            'image.url',
            'image.relativeUri'
        ],
        LinkableButton: ['name', 'scopedData', 'tpaData', 'referenceId', 'text'],
        LoginSocialBar: ['role'],
        Page: ['_bitField', 'referenceId', 'repeaterPage', 'tpaData'],
        PageLink: ['anchorDataId', 'anchorName', 'innerRoute', 'routerId', 'url', 'isPopup'],
        Repeater: ['scopedData'],
        RichText: ['linkList'],
        SearchBox: ['suggestions'],
        SelectableList: ['designableList', 'scopedData', 'style', 'data'],
        StripColumnsContainer: ['referenceId'],
        StyledText: [
            'alt',
            'defaultStyle',
            'description',
            'height',
            'label',
            'name',
            'referenceId',
            'scopedData',
            'svgId',
            'title',
            'tpaData',
            'uri',
            'usedFonts',
            'width',
            'textType',
            'value',
            'maxLength',
            'placeholder',
            'original',
            'overrides' // @see {https://jira.wixpress.com/browse/WEED-29357}
        ],
        TPAWidget: [
            //'applicationId',
            'name',
            'scopedData'
        ],
        TextInput: [
            'crmLabel',
            'text'
            //'step'
        ],
        TextAreaInput: ['text'],
        VectorImage: ['scopedData', 'text', 'referenceId'],
        VideoPlayer: ['referenceId', 'title'],
        WhatsAppLink: ['target'],
        WixVideo: [
            'description',
            'height',
            'focalPoint',
            'uri',
            'width',
            'qualities[].duration',
            'qualities[].status',
            'qualities[].tag',
            'qualities[].secure',
            'qualities[].video_bitrate',
            'qualities[].key',
            'qualities[].fps',
            'qualities[].display_aspect_ratio',
            'qualities[].audio_bitrate'
        ],
        WidgetDescriptor: ['properties'],
        WidgetRef: ['applicationId'],
        ContainerData: ['name', 'referenceId'],
        'dsgnsys.WbuButton': ['debug'],
        ClassicSection: ['referenceId']
    },
    mobile_hints: {
        MobileHints: ['displayedCompId', 'props', 'recommendedComponentsOrder']
    },
    theme_data: {
        ComponentStyle: [
            'breakpoint',
            'mobileHints',
            'values',
            'style.style',
            'style.compId',
            'style.pageId',
            'style.componentClassName'
        ],
        TopLevelStyle: ['breakpoint']
    },
    variants_data: {
        BreakpointRange: ['current', 'defaultRange', 'device', 'pointer', 'symbol', 'miniSymbol']
    },
    layout_data: {
        SingleLayoutData: [
            'breakpoint',
            'breakpointId',
            'componentLayout.breakpoint',
            'containerLayout.breakpoint',
            'itemLayout.breakpoint'
        ],
        GridItemLayout: ['breakpoint'],
        ComponentLayout: ['breakpoint'],
        GridContainerLayout: ['breakpoint'],
        FlexContainerLayout: ['breakpoint'],
        StackItemLayout: ['breakpoint']
    }
}

const propertiesList: string[] = [
    'AbstractGalleryProperties',
    'AccordionProperties',
    'AddressInputProperties',
    'AppPartProperties',
    'AreaTooltipProperties',
    'BackOfficeTextProperties',
    'BandcampPlayerProperties',
    'BgImageStripProperties',
    'BgImageStripUnifiedProperties',
    'BoxSlideShowProperties',
    'BoxSlideShowSlideProperties',
    'ButtonProperties',
    'CaptchaProperties',
    'CardsLayoutProperties',
    'CheckboxGroupProperties',
    'CheckboxProperties',
    'CollageProperties',
    'ColumnProperties',
    'ComboBoxInputProperties',
    'ContactFormProperties',
    'CustomProperties',
    'DatePickerProperties',
    'DefaultComponentProperties',
    'DisableableProperties',
    'DocumentMediaProperties',
    'EbayItemsBySellerProperties',
    'ExpandableMenuProperties',
    'FacebookLikeBoxProperties',
    'FileUploaderProperties',
    'FiveGridLineProperties',
    'FlashComponentProperties',
    'FreestyleProperties',
    'GoogleMapProperties',
    'GridProperties',
    'HoneycombProperties',
    'HorizontalMenuProperties',
    'HoverBoxProperties',
    'ImageButtonProperties',
    'ImpressProperties',
    'InputProperties',
    'ItunesButtonProperties',
    'LanguageSelectorProperties',
    'LineShareButtonProperties',
    'LinkBarProperties',
    'LoginSocialBarProperties',
    'MasonryProperties',
    'MatrixGalleryProperties',
    'MediaBoxProperties',
    'MediaControlsProperties',
    'MediaOverlayControlsProperties',
    'MediaPlayerProperties',
    'MusicPlayerProperties',
    'OnlineClockProperties',
    'PackagePickerProperties',
    'PageGroupProperties',
    'PageProperties',
    'PaginatedGridGalleryProperties',
    'PaginationProperties',
    'PayPalButtonProperties',
    'PhotoProperties',
    'PinterestPinItProperties',
    'PinterestPinProperties',
    'PopoverProperties',
    'PopupContainerProperties',
    'ProgressBarProperties',
    'QuickActionBarProperties',
    'RadioButtonProperties',
    'RadioGroupProperties',
    'RatingsDisplayProperties',
    'RatingsInputProperties',
    'RichTextBoxProperties',
    'RssButtonProperties',
    'SearchBoxProperties',
    'SelectionTagsListProperties',
    'SignatureInputProperties',
    'SingleAudioPlayerProperties',
    'SiteButtonProperties',
    'SkypeCallButtonProperties',
    'SlideShowGalleryProperties',
    'SliderGalleryProperties',
    'SliderProperties',
    'SpotifyFollowProperties',
    'SpotifyPlayerProperties',
    'StateBoxFormStateProperties',
    'StateBoxProperties',
    'StateBoxStateProperties',
    'StripColumnsContainerProperties',
    'StripContainerSlideShowProperties',
    'StripContainerSlideShowSlideProperties',
    'StripShowcaseProperties',
    'StripSlideshowProperties',
    'StylableButtonProperties',
    'StylableHorizontalMenuProperties',
    'SubscribeFormProperties',
    'SvgShapeProperties',
    'TPAGluedProperties',
    'TagsProperties',
    'TextAreaInputProperties',
    'TextBaseInputProperties',
    'TextInputProperties',
    'ThumbnailsProperties',
    'TimePickerProperties',
    'TinyMenuProperties',
    'ToggleSwitchProperties',
    'TwitterFeedProperties',
    'TwitterTimelineProperties',
    'VKShareProperties',
    'VectorImageProperties',
    'VerticalAnchorsMenuProperties',
    'VerticalLayoutProperties',
    'VerticalMenuProperties',
    'VideoBackgroundProperties',
    'VideoPlayerProperties',
    'VideoProperties',
    'WFacebookCommentProperties',
    'WFacebookLikeProperties',
    'WGooglePlusOneProperties',
    'WPhotoProperties',
    'WRichTextProperties',
    'WTwitterFollowProperties',
    'WTwitterTweetProperties',
    'WeatherProperties',
    'YouTubeSubscribeButtonProperties'
]

export const concatMerge = (objValue: any, srcValue: any) => {
    if (_.isArray(objValue) && _.isArray(srcValue)) {
        return _(objValue).concat(srcValue).uniq().value()
    }
    return undefined
}

function generatePropertiesPresetMetadataRemovals() {
    const propertiesWhiteList = {}
    _.forEach(propertiesList, key => {
        propertiesWhiteList[key] = ['metaData.isHidden', 'metaData.isPreset', 'metaData.isAutoGenerated']
    })
    return propertiesWhiteList
}

function enrichWhitelistWithProperties(aWhitelist: Record<string, Record<string, string[]>>) {
    _.mergeWith(aWhitelist.component_properties, generatePropertiesPresetMetadataRemovals(), concatMerge)
    return aWhitelist
}

function realignWhitelist(aWhitelist: Record<string, Record<string, string[]>>) {
    const reportNamespaceAdjustments = {
        'namespace documents': 'component_properties',
        'namespace designNodes': 'design_data',
        'namespace dataNodes': 'document_data'
    }

    const adjustedWhitelistNamespaces = _(aWhitelist)
        .pick(_.keys(reportNamespaceAdjustments))
        .mapKeys((v, k) => reportNamespaceAdjustments[k])
        .value()

    const whitelistWithAdjustedNamespace = _.mergeWith(
        adjustedWhitelistNamespaces,
        _.omit(aWhitelist, _.keys(reportNamespaceAdjustments)),
        concatMerge
    )
    return whitelistWithAdjustedNamespace
}

const nonDataWhitelistFieldsRadical: Record<string, Record<string, string[]>> = {}

const nonDataWhitelistFieldsConservative: Record<string, Record<string, string[]>> = {
    structure: {
        Page: ['mobileMetaData', 'mobileComponents', 'componentTypeForBI', 'tpaComps'],
        Document: ['layout.height', 'layout.width', 'layout.x', 'mobileMetaData'],
        Component: [
            'behaviors',
            'compPtr',
            'components',
            'config',
            'conversionData',
            'label',
            'previousStyleId',
            'originCompId',
            '_dataId',
            '_isTPACopied',
            'subRole',
            'mobileStructure',
            'translations',
            'componentTypeForBI',
            'slGeneratedId',
            'dataId',
            'templateId',
            'modes',
            'templateCompId',
            'link',
            'componentRef',
            'layout.anchors[].originalValue',
            'layout.anchors[].topToTop',
            'stateRefs',
            'default', // {@see https://jira.wixpress.com/browse/WEED-29273}
            'siteId', // {@see https://jira.wixpress.com/browse/WEED-28013}
            'propId' // {@see https://jira.wixpress.com/browse/WEED-28168}
        ],
        Container: [
            '_dataId',
            'compPtr',
            'originCompId',
            'previousStyleId',
            'mobileStructure',
            'behaviors',
            'templateId',
            'originInstanceId',
            '_isTPACopied',
            'connectionConfig',
            'label',
            'templateCompId',
            'conversionData',
            'layout.anchors[].originalValue',
            'layout.anchors[].topToTop',
            'subRole' // {@see https://jira.wixpress.com/browse/FSM-7082}
        ],
        RepeaterContainer: ['_dataId', 'previousStyleId', 'originCompId', 'mobileStructure', 'templateId'],
        RefComponent: ['_dataId', 'originCompId', 'previousStyleId', 'mobileStructure']
    }
}

const conservativeDataWhitelist = realignWhitelist(enrichWhitelistWithProperties(dataWhitelistConservativeBase))
const radicalDataWhitelist = _.mergeWith(dataWhitelistRadicalBase, conservativeDataWhitelist, concatMerge)

const fullRadicalWhiteList = _.mergeWith(
    _.mergeWith(nonDataWhitelistFieldsRadical, nonDataWhitelistFieldsConservative, concatMerge),
    radicalDataWhitelist,
    concatMerge
)
const fullConservativeWhiteList = _.mergeWith(
    nonDataWhitelistFieldsConservative,
    conservativeDataWhitelist,
    concatMerge
)

export const whitelist = {
    radical: fullRadicalWhiteList,
    conservative: fullConservativeWhiteList
}
