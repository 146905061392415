import type {SnapshotDal} from '@wix/document-manager-core'
import _ from 'lodash'

function replaceWixCodeAppId(pagesPlatformApplications, currentSnapshotDal: SnapshotDal) {
    const clientSpecMap = currentSnapshotDal.getValue({type: 'rendererModel', id: 'clientSpecMap'})
    // @ts-expect-error
    const wixCodeAppId = _.chain(clientSpecMap).find({type: 'siteextension'}).get('appDefinitionId').value()
    pagesPlatformApplications[wixCodeAppId] = _.assign({}, pagesPlatformApplications.wixCode)
    delete pagesPlatformApplications.wixCode
}

/**
 * @exports utils/saveDataFixer/plugins/pagesPlatformApplicationsDataFixer
 * @type {{exec: function}}
 */
export default {
    exec(dataToSave: any, {currentSnapshotDal}: {currentSnapshotDal: SnapshotDal}) {
        const {pagesPlatformApplications} = dataToSave
        if (pagesPlatformApplications) {
            replaceWixCodeAppId(pagesPlatformApplications, currentSnapshotDal)
            dataToSave.pagesPlatformApplications = _.transform(
                pagesPlatformApplications,
                (result, pageIds, appId) => {
                    _.forOwn(pageIds, (value, pageId) => {
                        result[pageId] = result[pageId] || []
                        result[pageId].push(appId)
                    })
                },
                {}
            )
        }
    }
}
