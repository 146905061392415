import _ from 'lodash'
import constants from '../constants/constants'
const {COMP_DATA_QUERY_KEYS, SERIALIZED_DATA_KEYS, SERIALIZED_SCOPED_DATA_KEYS, VARIANTS} = constants

const removeBreakpointsIfCompHasVariants = comp => {
    const hasBreakpoints = _.some(comp?.variants, x => x?.type === VARIANTS.TYPES.BREAKPOINTS)
    if (hasBreakpoints) {
        delete comp?.breakpoints
    }
}

const removeMetadataFromSerializedItem = item => {
    if (!_.isPlainObject(item)) {
        return
    }
    delete item.metaData
    _.forOwn(item, val => {
        if (_.isArray(val)) {
            _.forEach(val, removeMetadataFromSerializedItem)
        } else {
            removeMetadataFromSerializedItem(val)
        }
    })
}

const removeMetadata = componentDefinition => {
    delete componentDefinition.metaData
    _.forEach(SERIALIZED_DATA_KEYS, key => {
        if (componentDefinition[key]) {
            removeMetadataFromSerializedItem(componentDefinition[key])
        }
    })
    _.forEach(SERIALIZED_SCOPED_DATA_KEYS, key => {
        if (componentDefinition[key]) {
            removeMetadataFromSerializedItem(componentDefinition[key])
        }
    })
}
function sanitizeSerializedComponent(componentDefinition) {
    delete componentDefinition.activeVariants
    delete componentDefinition.referredVariants
    delete componentDefinition.siteWidth

    _.forEach(COMP_DATA_QUERY_KEYS, compDataQueryKey => {
        delete componentDefinition[compDataQueryKey]
    })

    removeMetadata(componentDefinition)
    if (componentDefinition.mobileStructure) {
        removeMetadata(componentDefinition.mobileStructure)
    }
    removeBreakpointsIfCompHasVariants(componentDefinition)
}

export default sanitizeSerializedComponent
