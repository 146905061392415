import type {PublicMethodUtils} from '@wix/document-services-types'
import {stylableEditorAPI} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    const {
        getStylableEditorInstance,
        update,
        createEmptyStyle,
        removeRefIfEmptyStyle,
        setQuickChange,
        revertQuickChange,
        forceState,
        revertForceState,
        mergeStylesheets
    } = stylableEditorAPI

    return {
        methods: {
            components: {
                stylable: {
                    update: publicMethodUtils.defineDataManipulationAction(update),
                    createEmptyStyle: publicMethodUtils.defineDataManipulationAction(createEmptyStyle),
                    removeRefIfEmptyStyle: publicMethodUtils.defineDataManipulationAction(removeRefIfEmptyStyle),
                    getEditorInstance: publicMethodUtils.defineGetter(getStylableEditorInstance),
                    quickChange: publicMethodUtils.defineAction(setQuickChange),
                    revertQuickChange: publicMethodUtils.defineAction(revertQuickChange),
                    forceState: publicMethodUtils.defineAction(forceState),
                    revertForceState: publicMethodUtils.defineAction(revertForceState),
                    mergeStylesheets: publicMethodUtils.defineGetter(mergeStylesheets)
                }
            }
        }
    }
}
