import {
    constants as extensionsConstants,
    DataExtensionAPI,
    DataModelExtensionAPI
} from '@wix/document-manager-extensions'
import type {AppDataToDelete, AppDefinitionId, CompRef, PS} from '@wix/document-services-types'
import _ from 'lodash'
import routers from '../routers/routers'
import page from '../page/page'
import component from '../component/component'
import clientSpecMapService from '../tpa/services/clientSpecMapService'
import menuAPI from '../menu/menuAPI'
import pagesGroup from '../pagesGroup/pagesGroup'
import connections from '../connections/connections'
import menuUtils from '../menu/menuUtils'
import workerService from './services/workerService'
import platform from './platform'
import appComponents from './appComponents'
import wixCode from '../wixCode/wixCode'
import constants from './common/constants'
import permissionsUtils from '../tpa/utils/permissionsUtils'
import platformStateService from './services/platformStateService'
import installedTpaAppsOnSiteService from '../tpa/services/installedTpaAppsOnSiteService'
import tpaDataService from '../tpa/services/tpaDataService'
import tpaNotifyDeleteService from '../tpa/services/tpaNotifyDeleteService'

const excludeApps = ['dataBinding', 'wix-code']

async function uninstallApp(ps: PS, appDefinitionId: AppDefinitionId, onSuccess = _.noop, onError = _.noop) {
    try {
        const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
        await deletePlatformPart(ps, appData)
        await platform.remove(ps, appDefinitionId)
        onSuccess(appDefinitionId)
    } catch (e: any) {
        let customErrorMessage = `Uninstall App Failed.\nOriginal error: ${e?.message ?? e}.`
        if (e instanceof Error) {
            customErrorMessage += `\nOriginal stack trace: ${e.stack}`
        }
        onError(new Error(customErrorMessage))
    }
}

async function uninstall(
    ps: PS,
    appDeletionData: AppDataToDelete[],
    appDefIds,
    onSuccess = _.noop,
    onError = _.noop,
    progressCallback = _.noop
) {
    const promises = []
    for (const data of appDeletionData) {
        _.forEach(data.appDefIdsToDelete, (appDefinitionId: AppDefinitionId) => {
            promises.push(uninstallApp(ps, appDefinitionId, progressCallback, onError))
        })
        promises.push(tpaDataService.runGarbageCollection(ps, data.appDefIdsToDelete))
    }

    promises.push(ps.setOperationsQueue.waitForChangesAppliedAsync())
    Promise.all(promises).then(() => onSuccess(), onError) // eslint-disable-line promise/prefer-await-to-then
}

async function deletePlatformPart(ps: PS, appData) {
    const {appDefinitionId} = appData
    convertTpaPageLinks(ps, appDefinitionId)
    deletePagesGroup(ps, appDefinitionId)
    deleteRouters(ps, appDefinitionId)
    deletePages(ps, appDefinitionId)
    deleteComponents(ps, appDefinitionId)
    deleteMenus(ps, appDefinitionId)

    const shouldAvoidRevoking = await permissionsUtils.shouldAvoidRevoking(
        {ps},
        {intent: constants.Intents.USER_ACTION}
    )
    if (!shouldAvoidRevoking) {
        platformStateService.setAppPendingAction(ps, appDefinitionId, constants.APP_ACTION_TYPES.REMOVE)
    }

    await deleteCodePackages(ps, appData)
}

async function deleteCodePackages(ps: PS, appData) {
    if (appComponents.hasCodePackage(appData)) {
        return wixCode.codePackages.uninstallCodeReusePkg(ps, appData.appDefinitionId)
    }
}

function deleteMenus(ps: PS, appDefinitionId: AppDefinitionId) {
    const appMenus = menuUtils.getMenusByFilter(ps, {appDefinitionId})
    _.forEach(appMenus, menu => menuAPI.remove(ps, menu.id))
}

function deletePages(ps: PS, appDefinitionId: AppDefinitionId) {
    const managePages = getManagePages(ps, appDefinitionId)
    const hiddenPages = getHiddenSectionPages(ps, appDefinitionId)
    const popupPages = getManagePopupPages(ps, appDefinitionId)

    const allAppPages = new Set([...managePages, ...hiddenPages, ...popupPages])
    allAppPages.forEach(pageId => {
        const pagePointer = page.getPage(ps, pageId)
        if (ps.dal.isExist(pagePointer)) {
            page.remove(ps, pagePointer.id)
        }
    })
}
function getManagePopupPages(ps: PS, appDefinitionId: AppDefinitionId) {
    const popupPagesData = page.popupPages
        .getDataList(ps)
        .filter(
            pageData => pageData.managingAppDefId === appDefinitionId || pageData.appDefinitionId === appDefinitionId
        )
    return popupPagesData.map(pageData => pageData.id)
}

function getManagePages(ps: PS, appDefinitionId: AppDefinitionId) {
    const pages = page
        .getPagesDataItems(ps)
        .filter(
            pageData => pageData.managingAppDefId === appDefinitionId || pageData.appDefinitionId === appDefinitionId
        )
    return pages.map(pageData => pageData.id)
}

function deleteRouters(ps: PS, appDefinitionId: AppDefinitionId) {
    const appRouters = _.filter(routers.get.all(ps), router => router.appDefinitionId === appDefinitionId)

    _.forEach(appRouters, routerRef => {
        const routerPointer = routers.getRouterRef.byPrefix(ps, routerRef.prefix)
        _.forEach(routerRef.pages, routerPage => {
            if (ps.dal.isExist(page.getPage(ps, routerPage))) {
                routers.pages.removePageFromRouter(ps, routerPointer, routerPage)
                page.remove(ps, routerPage)
            }
        })
        routers.remove(ps, routerPointer)
    })
}

function deletePagesGroup(ps: PS, appDefinitionId: AppDefinitionId) {
    const pageGroup = pagesGroup.getPagesGroupByAppDefId(ps, appDefinitionId)
    pagesGroup.removePagesGroup(ps, pageGroup)
}

function deleteComponents(ps: PS, appDefinitionId: AppDefinitionId) {
    const allComps: Set<CompRef> = new Set()
    const allComponentPointers = ps.extensionAPI.components.getAllComponentPointers()

    const controlledComponents = _.filter(allComponentPointers, compPointer => {
        const compData = component.data.get(ps, compPointer)
        const appDefId = platform.getAppDefIdFromData(compData)
        return appDefId === appDefinitionId && !ps.pointers.structure.isPage(compPointer)
    })

    _.forEach(controlledComponents, comp => {
        const controllerConnections = connections.getControllerConnectionsByAncestor(ps, comp)
        _.forEach(controllerConnections, componentRef => {
            allComps.add(componentRef)
        })
        allComps.add(comp)
    })

    allComps.forEach(componentRef => {
        if (ps.dal.isExist(componentRef)) {
            component.remove(ps, componentRef)
        }
    })
}

// TODO replace when https://jira.wixpress.com/browse/DM-9314 is done
function convertTpaPageLinks(ps: PS, appDefinitionId: string) {
    const {PAGE_SCHEMA, MULTILINGUAL_TYPES} = extensionsConstants
    ;[PAGE_SCHEMA.data, MULTILINGUAL_TYPES.multilingualTranslations].forEach(namespace => {
        const tpaPageLinks = (ps.extensionAPI as DataExtensionAPI).data.query(
            namespace,
            null,
            _.matches({type: 'TpaPageLink', appDefinitionId})
        )
        _.forEach(tpaPageLinks, (tpaPageLink, id) => {
            const {pageId: pageWhereTheLinkResides} = tpaPageLink.metaData
            const pageLink = (ps.extensionAPI as DataModelExtensionAPI).dataModel.createDataItemByType(
                'PageLink',
                _.pick(tpaPageLink, ['id', 'pageId'])
            )
            ps.dal.set(ps.pointers.data.getItem(namespace, id, pageWhereTheLinkResides), pageLink)
        })
    })
}

const getAppPagesToDelete = function (ps: PS, appDefinitionId: AppDefinitionId, onError) {
    try {
        const appsPagesToDelete: any = tpaNotifyDeleteService.notifyAppsToDelete(ps, appDefinitionId, true)
        return appsPagesToDelete
    } catch (e) {
        onError(e)
    }
}

const getHiddenSectionPages = function (ps: PS, appDefinitionId: AppDefinitionId) {
    const hiddenPages = installedTpaAppsOnSiteService.getHiddenSections(ps, appDefinitionId) ?? []
    return hiddenPages.map(hiddenPage => hiddenPage.pageId) as string[]
}

// Take note: appDeleteData is essentially an out param, the function mutates it.
const getAppsPagesToDelete = function (ps: PS, appDeleteData, appsData, appDefinitionId: AppDefinitionId, onError) {
    const appPagesToDelete = getAppPagesToDelete(ps, appDefinitionId, onError)
    if (appPagesToDelete) {
        appDeleteData.push(appPagesToDelete)
    }
}

const _notifyBeforeApplicationDelete = async function (
    ps: PS,
    appDefId: AppDefinitionId[] | AppDefinitionId,
    onError?
) {
    const appDefIds = _.castArray(appDefId)
    const appDeleteData: AppDataToDelete[] = []
    const appsData = appDefIds
        .map(appDefinitionId => clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId))
        .filter(data => data)

    for (const {appDefinitionId} of appsData) {
        if (_.includes(excludeApps, appDefinitionId)) {
            throw new Error(`It is not allowed to delete (${appDefId}) app.`)
        }
        if (
            clientSpecMapService.hasEditorPlatformPart(
                clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
            )
        ) {
            try {
                await workerService.notifyBeforeRemoveApp(ps, appDefinitionId)
            } catch (e) {
                throw new Error('Uninstall App Failed.')
            }
        }
        // When "notifyBeforeApplicationDelete" is called as async, the onError is undefined
        const errorCallback = onError ?? _.noop
        getAppsPagesToDelete(ps, appDeleteData, appsData, appDefinitionId, errorCallback)
    }
    return appDeleteData
}

// This is the same as the original `notifyBeforeApplicationDelete` except it doesn't call `ps.setOperationsQueue.asyncPreDataManipulationComplete`
// It's incorrect to call asyncPreDataManipulationComplete from anywhere except the public API, so
// this enables using the functionality from other places in the system
const notifyBeforeApplicationDeleteInternal = async (ps: PS, appDefId: AppDefinitionId[] | AppDefinitionId) => {
    return await _notifyBeforeApplicationDelete(ps, appDefId, (e: any) => {
        throw e
    })
}

// TODO: Refactor to avoid the anti-pattern of using callbacks and promises at the same time
const notifyBeforeApplicationDelete = async function (
    ps: PS,
    appDefId: AppDefinitionId[] | AppDefinitionId,
    onSuccess?,
    onError?
) {
    const appDeleteData = await _notifyBeforeApplicationDelete(ps, appDefId, onError)
    ps.setOperationsQueue.asyncPreDataManipulationComplete(appDeleteData)
    onSuccess?.()
}

export default {
    uninstall,
    notifyBeforeApplicationDelete,
    notifyBeforeApplicationDeleteInternal
}
