import * as santaCoreUtils from '@wix/santa-core-utils'
import dsCompRegistrar from '../dsCompRegistrar/dsCompRegistrar'
import type {PS} from '@wix/document-services-types'

const loadExternalComponentsFromEditorElements = async (ps: PS) => {
    await ps.extensionAPI.componentsRegistry.waitRegistryLoaded()
    const components = ps.extensionAPI.componentsRegistry.getComponents()

    Object.entries(components).forEach(([componentType, componentModel]) => {
        dsCompRegistrar.registerComponent(ps, {componentType, ...(componentModel as object)})
    })
}

const loadExternalComponentsForDS = async (ps: PS) => {
    try {
        await loadExternalComponentsFromEditorElements(ps)
    } catch (err) {
        santaCoreUtils.log.error('Could not load external components from external component registry', err)
    }
}

export default {
    loadExternalComponentsForDS
}
