import type {CoreLogger, Getter, SetterSet} from '@wix/document-manager-core'
import type {Experiment, Pointer, Pointers} from '@wix/document-services-types'
import _ from 'lodash'
import * as dataTransformer from './transformers/dataTransformer'
import * as structureTransformer from './transformers/structureTransformer'
import * as pageTransformer from './transformers/pageTransformer'
import * as multilingualTranslationsTransformer from './transformers/multilingualTranslationsTransformer'
import {constants} from '@wix/document-manager-extensions'

const {DATA_TYPES, VIEW_MODES, DM_POINTER_TYPES, MULTILINGUAL_TYPES} = constants

const nullTransformer = (): any => null

const setTransformersMap = {
    ..._.mapValues(VIEW_MODES, () => structureTransformer.transformSet),
    ..._.mapValues(_.mapKeys(DATA_TYPES, _.identity), () => dataTransformer.transformSet),
    [DM_POINTER_TYPES.pageDM]: pageTransformer.transformSet,
    [MULTILINGUAL_TYPES.multilingualTranslations]: multilingualTranslationsTransformer.transformSet
}

const getTransformersMap = {
    [DATA_TYPES.data]: dataTransformer.transformGet
}

const removeTransformersMap = {
    ..._.mapValues(VIEW_MODES, () => structureTransformer.transformRemove),
    [DM_POINTER_TYPES.pageDM]: nullTransformer
}

/**
 *  returns an updated value after the transformation
 * @param pointer
 * @param value
 * @param get
 * @param set
 * @param pointers
 * @returns {*}
 */
const transformSet = (
    pointer: Pointer,
    value: any,
    get: Getter,
    set: SetterSet,
    pointers: Pointers,
    experimentInstance: Experiment,
    logger: CoreLogger
) => {
    const transformer = setTransformersMap[pointer.type]
    return transformer ? transformer(pointer, value, get, set, pointers, experimentInstance, logger) : {value, pointer}
}

/**
 *  returns an updated value after the transformation
 * @param pointer
 * @param get
 * @param pointers
 * @returns {*}
 */
const transformGet = (pointer: Pointer, get: Getter, pointers: Pointers) => {
    const transformer = getTransformersMap[pointer.type]
    return transformer ? transformer(pointer, get, pointers) : pointer
}

/**
 *  returns an updated value after the transformation
 * @param pointer
 * @param removeFromFull
 * @returns {*}
 */
const transformRemove = (pointer: Pointer, removeFromFull: boolean) => {
    const transformer = removeTransformersMap[pointer.type]
    return transformer ? transformer(pointer, removeFromFull) : pointer
}

export {transformRemove, transformSet, transformGet}
