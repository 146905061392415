import type {IConnectionItem, Pointer, PS} from '@wix/document-services-types'
import * as mobileCore from '@wix/mobile-conversion'
import _ from 'lodash'
import constants from '../../constants/constants'
import dataModel from '../../dataModel/dataModel'
import hooks from '../../hooks/hooks'
import theme from '../../theme/theme'

const REF_FOR_LOGIN_SOCIAL_BAR = 'loginSocialBarRef'
const LOGIN_SOCIAL_BAR = 'wysiwyg.viewer.components.LoginSocialBar'
const TINY_MENU_DATA_SCHEMA = 'TinyMenu'
const TINY_MENU_DATA_ITEM_ID = 'TINY_MENU'

const isLoginSocialBar = (ps: PS, compPointer: Pointer) =>
    ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'componentType')) === LOGIN_SOCIAL_BAR

function createTinyMenuData(ps: PS, tinyMenuPointer: Pointer) {
    const tinyMenuDataPointer = ps.pointers.data.getDataItemFromMaster('TINY_MENU')

    if (!ps.dal.isExist(tinyMenuDataPointer)) {
        const dataQueryPointer = ps.pointers.getInnerPointer(tinyMenuPointer, 'dataQuery')
        ps.dal.set(dataQueryPointer, `#${TINY_MENU_DATA_ITEM_ID}`)
        const emptyTinyMenuDataItem = ps.extensionAPI.schemaAPI.createItemAccordingToSchema(
            TINY_MENU_DATA_SCHEMA,
            constants.DATA_TYPES.data
        )
        const tinyMenuData = _.assign({id: TINY_MENU_DATA_ITEM_ID}, emptyTinyMenuDataItem)
        dataModel.setDataItemByPointer(ps, tinyMenuDataPointer, tinyMenuData, 'data')
    }
}

function createTinyMenuConnectionDataItem(ps: PS, tinyMenuPointer: Pointer) {
    const connectionQueryPointer = ps.pointers.getInnerPointer(tinyMenuPointer, 'connectionQuery')
    if (!ps.dal.isExist(connectionQueryPointer)) {
        ps.dal.set(connectionQueryPointer, 'TINY_MENU_CONNECTION')
        dataModel.updateConnectionsItem(ps, tinyMenuPointer, [])
    }
}

function getFirstLoginSocialBar(ps: PS) {
    const desktopHeaderPointer = ps.pointers.components.getHeader(constants.VIEW_MODES.DESKTOP)
    const headerChildren = ps.pointers.components.getChildrenRecursively(desktopHeaderPointer)
    const desktopPointer = ps.pointers.components.getPage(constants.MASTER_PAGE_ID, constants.VIEW_MODES.DESKTOP)
    const desktopPageChildren = ps.pointers.components.getChildrenRecursively(desktopPointer)
    //we specifically want to search header first, then masterPage
    const children = _.unionBy(headerChildren, desktopPageChildren, (compPointer: any) => compPointer.id)
    return _.find(children, childPointer => isLoginSocialBar(ps, childPointer))
}

function getTinyMenuOnlyConnections(ps: PS, tinyMenuPointer: Pointer): IConnectionItem[] {
    const tinyMenuConnections = dataModel.getConnectionsItem(ps, tinyMenuPointer)
    const platformConnections = dataModel.getPlatformAppConnections(ps, tinyMenuPointer)
    return _.reject(tinyMenuConnections, ({type, role}) =>
        _.find(platformConnections, {type, role})
    ) as IConnectionItem[]
}

function connectToLoginSocialBar(
    ps: PS,
    tinyMenuPointer: Pointer,
    loginSocialBar: Pointer,
    loginSocialBarQuery: Pointer
) {
    const componentDataQuery = ps.dal.get(ps.pointers.getInnerPointer(loginSocialBar, 'dataQuery'))
    ps.dal.set(loginSocialBarQuery, componentDataQuery)
    const loginSocialBarConnections = dataModel.getPlatformAppConnections(ps, loginSocialBar)
    const tinyMenuOnlyConnections = getTinyMenuOnlyConnections(ps, tinyMenuPointer)
    const updatedConnections = _.union(tinyMenuOnlyConnections, loginSocialBarConnections)
    dataModel.updateConnectionsItem(ps, tinyMenuPointer, updatedConnections)
}

function disconnectFromLoginSocialBar(ps: PS, loginSocialBarQuery: Pointer, tinyMenuPointer: Pointer) {
    if (ps.dal.isExist(loginSocialBarQuery)) {
        ps.dal.remove(loginSocialBarQuery)
        const tinyMenuOnlyConnections = getTinyMenuOnlyConnections(ps, tinyMenuPointer)
        dataModel.updateConnectionsItem(ps, tinyMenuPointer, tinyMenuOnlyConnections)
    }
}

function applyDataToTinyMenu(ps: PS) {
    const pagePointer = ps.pointers.components.getPage(constants.MASTER_PAGE_ID, constants.VIEW_MODES.MOBILE)
    const tinyMenuPointer = ps.pointers.components.getComponent(constants.MOBILE_ONLY_COMPONENTS.TINY_MENU, pagePointer)
    if (!tinyMenuPointer || !ps.dal.isExist(tinyMenuPointer)) {
        return
    }

    createTinyMenuData(ps, tinyMenuPointer)
    createTinyMenuConnectionDataItem(ps, tinyMenuPointer)
    const loginSocialBar = getFirstLoginSocialBar(ps)
    const loginSocialBarQuery = ps.pointers.getInnerPointer(
        ps.pointers.data.getDataItemFromMaster('TINY_MENU'),
        REF_FOR_LOGIN_SOCIAL_BAR
    )

    if (loginSocialBar) {
        connectToLoginSocialBar(ps, tinyMenuPointer, loginSocialBar, loginSocialBarQuery)
    } else {
        disconnectFromLoginSocialBar(ps, loginSocialBarQuery, tinyMenuPointer)
    }
}

function addAdditionalStyles(ps: PS) {
    _.forOwn(mobileCore.mobileOnlyComponents.getAdditionalStyles(), (styleProps, styleId) => {
        if (theme.styles.get(ps, styleId)) {
            return
        }
        const style = _.assign(
            {
                type: 'TopLevelStyle',
                styleType: 'system',
                id: styleId,
                metaData: {isHidden: false, isPreset: false}
            },
            styleProps
        )
        theme.styles.createAndAddStyleItem(ps, style, styleId)
    })
}

hooks.registerHook(
    hooks.HOOKS.REMOVE.BEFORE,
    deleteLoginSocialBarRefFromTinyMenu,
    'wysiwyg.viewer.components.LoginSocialBar'
)

function deleteLoginSocialBarRefFromTinyMenu(ps: PS) {
    const pagePointer = ps.pointers.components.getPage(constants.MASTER_PAGE_ID, constants.VIEW_MODES.MOBILE)
    const tinyMenuPointer = ps.pointers.components.getComponent(constants.MOBILE_ONLY_COMPONENTS.TINY_MENU, pagePointer)
    if (!tinyMenuPointer || !ps.dal.isExist(tinyMenuPointer)) {
        return
    }
    const loginSocialBarQuery = ps.pointers.getInnerPointer(
        ps.pointers.data.getDataItemFromMaster('TINY_MENU'),
        REF_FOR_LOGIN_SOCIAL_BAR
    )
    disconnectFromLoginSocialBar(ps, loginSocialBarQuery, tinyMenuPointer)
}

export default {
    applyDataToTinyMenu,
    addAdditionalStyles
}
