import type {RjsConfig} from './get-rjs-config'
import {reduceQueryToObject} from './reduceQueryToObject'

export class PackagesUtil {
    constructor(private packagesStructure: string[], private query: string, private bundles: string[] | null = []) {}

    /**
     * changes the config to load packages correctly, accounting the query
     */
    buildConfig(config: RjsConfig) {
        const queryParamsObject = reduceQueryToObject(this.query.replace(/^\?/, ''))
        let debug = (decodeURIComponent(queryParamsObject.debug) || '').split(',').filter(Boolean)

        if (debug.indexOf('all') !== -1) {
            const debuggableExternals = Object.keys(config.paths!).filter(path => (config.paths![path] as any)?.source)
            debug = this.packagesStructure.concat(debuggableExternals)
        }

        const isInDebug = (i: string) => debug.indexOf(i) !== -1

        //config.paths:
        Object.keys(config.paths!)
            .filter(k => typeof config.paths![k] === 'object' && !Array.isArray(config.paths![k]))
            .forEach(k => {
                config.paths![k] = config.paths![k]![isInDebug(k) ? 'source' : 'min']
            })

        //config.bundles:
        config.bundles = config.bundles || {}

        this.packagesStructure
            .filter(p => !isInDebug(p))
            .filter(p => this.bundles!.indexOf(p) === -1)
            .forEach(pkg => {
                config.bundles![pkg] = pkg
                config.paths![pkg] = `dist/packages-bin/${pkg}/${pkg}.min`
            })

        if (this.bundles && this.bundles.length > 0) {
            const bundleName = 'first-load'
            config.bundles[bundleName] = this.bundles
            config.paths![bundleName] = `dist/packages-bin/${bundleName}/${bundleName}.min`
        }

        //config.packages:
        config.packages = config.packages || []
        const projectPackages = this.packagesStructure.filter(isInDebug).map(name => ({
            name,
            location: `packages/${name}/src/main`,
            main: name
        }))
        config.packages = config.packages.concat(projectPackages)

        return config
    }
}
