import type {Pointer} from '@wix/document-services-types'

class PointerMap<Value> {
    private innerMap: Map<string, Value> = new Map<string, Value>()

    private toKey(pointer: Pointer): string {
        return `${pointer.type}.${pointer.id}`
    }

    private apply<R>(pointer: Pointer, cb: (key: string) => R) {
        const key: string = this.toKey(pointer)

        return cb(key)
    }

    set(pointer: Pointer, value: Value): void {
        this.apply<any>(pointer, key => this.innerMap.set(key, value))
    }

    has(pointer: Pointer): boolean {
        return this.apply<boolean>(pointer, key => this.innerMap.has(key))
    }

    get(pointer: Pointer): Value | undefined {
        return this.apply<Value | undefined>(pointer, key => this.innerMap.get(key))
    }

    delete(pointer: Pointer): boolean {
        return this.apply<boolean>(pointer, key => this.innerMap.delete(key))
    }
}

export {PointerMap}
