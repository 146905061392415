import type {PS} from '@wix/document-services-types'
import appPart2ComponentHooks from './appPart2ComponentHooks'
import pathUtils from './pathUtils'

function initialize(ps: PS) {
    pathUtils.initBasePaths(ps)
    appPart2ComponentHooks.registerHooks()
}

export default {
    initialize
}
