import _ from 'lodash'
import undoRedoUtils from '../utils/undoRedo'
import wixCodeMonitoring from './wixCodeMonitoringWrapper'
import constants from '../utils/constants'
import type {CodeSavedChanges, Pointer, PS} from '@wix/document-services-types'

function readPointerIfExists(ps: PS, pointer: Pointer, defaultValue) {
    return ps.dal.isExist(pointer) ? ps.dal.get(pointer) : defaultValue
}

function writeFile(ps: PS, filePath: string, newContent: string) {
    if (_.isUndefined(newContent)) {
        const traceEnd = wixCodeMonitoring.trace(ps, {action: 'filesDAL.writeFile', message: {filePath}})
        traceEnd({message: new Error('about to set file content to undefined'), level: wixCodeMonitoring.levels.ERROR})
    }

    ps.extensionAPI.wixCodeFileSystem.writeFile(filePath, newContent)
}

function loadFileContent(ps: PS, filePath: string[] | string, content) {
    if (_.isUndefined(content)) {
        const traceEnd = wixCodeMonitoring.trace(ps, {action: 'filesDAL.loadFileContent', message: {filePath}})
        traceEnd({message: new Error('about to set file content to undefined'), level: wixCodeMonitoring.levels.ERROR})
    }
    ps.extensionAPI.wixCodeFileSystem.loadFileContent(filePath, content)
}
function readFile(ps: PS, filePath: string) {
    return ps.extensionAPI.wixCodeFileSystem.readFile(filePath)
}

function getSourceFilePath(ps: PS, filePath: string) {
    return ps.extensionAPI.wixCodeFileSystem.getSourceFilePath(filePath)
}

function markFileForDuplication(ps: PS, sourceFilePath: string, targetFilePath: string) {
    ps.extensionAPI.wixCodeFileSystem.markFileForDuplication(sourceFilePath, targetFilePath)
}

function updateDuplicates(ps: PS, filePath: string, content: string) {
    ps.extensionAPI.wixCodeFileSystem.updateDuplicates(filePath, content)
}

function moveFile(ps: PS, currentFilePath: string, targetFilePath: string) {
    ps.extensionAPI.wixCodeFileSystem.moveFile(currentFilePath, targetFilePath)
}

function deleteFile(ps: PS, filePath: string) {
    ps.extensionAPI.wixCodeFileSystem.deleteFile(filePath)
}

function deleteFolder(ps: PS, folderPath: string) {
    ps.extensionAPI.wixCodeFileSystem.deleteFolder(folderPath)
}

function isFileExists(ps: PS, filePath: string) {
    return ps.extensionAPI.wixCodeFileSystem.isFileExists(filePath)
}

function isFileReadable(ps: PS, filePath: string) {
    return ps.extensionAPI.wixCodeFileSystem.isFileReadable(filePath)
}

function isChildrenExists(ps: PS, parentFolder: string) {
    return ps.extensionAPI.wixCodeFileSystem.isChildrenExists(parentFolder)
}

function getChildren(ps: PS, parentFolder: string) {
    return ps.extensionAPI.wixCodeFileSystem.getChildren(parentFolder) ?? null
}

function addChild(ps: PS, parentFolderPath: string, itemDescriptor) {
    ps.extensionAPI.wixCodeFileSystem.addChild(parentFolderPath, itemDescriptor)
}

function removeChild(ps: PS, parentFolderPath: string, itemDescriptor) {
    ps.extensionAPI.wixCodeFileSystem.removeChild(parentFolderPath, itemDescriptor)
}

function hasKnownChild(ps: PS, parentFolderPath: string, itemDescriptor) {
    return ps.extensionAPI.wixCodeFileSystem.hasKnownChild(parentFolderPath, itemDescriptor)
}

function loadChildren(ps: PS, parentFolderPath: string, loadedChildren) {
    ps.extensionAPI.wixCodeFileSystem.loadChildren(parentFolderPath, loadedChildren)
}

function areChildrenLoaded(ps: PS, folderPath: string) {
    return !!ps.extensionAPI.wixCodeFileSystem.areChildrenLoaded(folderPath)
}

function _getFromSnapshot(snapshot, path: string[]) {
    const pathToGet = path.slice(-1)
    const value = snapshot?.getIn(pathToGet)
    return value ? value.toJS() : null
}

function _getModifiedFileContents(loadedContents, modifiedContents, undoableModifiedContents) {
    return _.mapValues(modifiedContents, function (value, filePath) {
        return undoRedoUtils.isUndoableFile(filePath)
            ? undoRedoUtils.assembleUndoableFile(
                  filePath,
                  loadedContents[filePath],
                  modifiedContents[filePath],
                  undoableModifiedContents[filePath]
              )
            : modifiedContents[filePath]
    })
}

function _getModifiedFileContentsInSnapshot(snapshot) {
    if (!snapshot) {
        return null
    }
    const loadedContents = _getFromSnapshot(snapshot, constants.paths.LOADED_FILE_CONTENTS)
    const modifiedContents = _getFromSnapshot(snapshot, constants.paths.MODIFIED_FILE_CONTENTS)
    const undoableModifiedContentsById = _getFromSnapshot(snapshot, constants.paths.UNDOABLE_MODIFIED_FILE_CONTENTS)
    const filePathToIdMap = _getFromSnapshot(snapshot, constants.paths.FILE_PATH_TO_ID_MAP)
    const undoableModifiedContents = undoRedoUtils.getUndoableContentByPath(
        undoableModifiedContentsById,
        filePathToIdMap
    )
    return _getModifiedFileContents(loadedContents, modifiedContents, undoableModifiedContents)
}

function _getModifiedFileContentsFromDal(ps: PS) {
    const loadedContents = ps.extensionAPI.wixCodeFileSystem.getLoadedFileContentMap() ?? {}
    const modifiedContents = ps.extensionAPI.wixCodeFileSystem.getModifiedFileContentMap() ?? {}
    const filePathToIdMap = ps.extensionAPI.wixCodeFileSystem.getFilePathToIdMap() ?? {}
    const undoableModifiedContentsById = readPointerIfExists(
        ps,
        ps.pointers.wixCode.getUndoableModifiedFileContentMap(),
        {}
    )
    const undoableModifiedContents = undoRedoUtils.getUndoableContentByPath(
        undoableModifiedContentsById,
        filePathToIdMap
    )
    return _getModifiedFileContents(loadedContents, modifiedContents, undoableModifiedContents)
}
function _getToSave(prevModifiedFiles, currentModifiedFiles) {
    return _.transform(
        currentModifiedFiles,
        function (acc, content, fileId) {
            const prevContent = _.get(prevModifiedFiles, fileId)
            if (content !== prevContent) {
                acc.push({fileId, content})
            }
        },
        []
    )
}

function _getToCopy(prevDuplicatesMap, currentDuplicatesMap) {
    return _.transform(
        currentDuplicatesMap,
        function (acc, srcFileId, destFileId) {
            if (_.get(prevDuplicatesMap, destFileId)) {
                return // saved in earlier run
            }
            // calculate original source
            let fileId = srcFileId
            while (currentDuplicatesMap[fileId]) {
                fileId = currentDuplicatesMap[fileId]
            }
            acc.push({destFileId, srcFileId: fileId})
        },
        []
    )
}

function getChangesBetweenSnapshots(lastSnapshot, currentSnapshot) {
    const modifiedFilesInLastSnapshot = _getModifiedFileContentsInSnapshot(lastSnapshot)
    const modifiedFilesInCurrentSnapshot = _getModifiedFileContentsInSnapshot(currentSnapshot)

    const prevDuplicatesMap = lastSnapshot ? _getFromSnapshot(lastSnapshot, constants.paths.DUPLICATED_FILES_INFO) : {}
    const currentDuplicatesMap = _getFromSnapshot(currentSnapshot, constants.paths.DUPLICATED_FILES_INFO) ?? {}

    const directoryFlagByDeletedPath =
        _getFromSnapshot(currentSnapshot, constants.paths.DIRECTORY_FLAG_BY_DELETED_PATH) ?? {}

    return {
        toSave: _getToSave(modifiedFilesInLastSnapshot, modifiedFilesInCurrentSnapshot),
        toCopy: _getToCopy(prevDuplicatesMap, currentDuplicatesMap),
        toDelete: directoryFlagByDeletedPath
    }
}

function getChangesBetweenLastSnapshotAndCurrentStateTakenFromPS(lastSnapshot, ps: PS): CodeSavedChanges {
    const modifiedFilesInLastSnapshot = _getModifiedFileContentsInSnapshot(lastSnapshot)
    const modifiedFilesInCurrentSnapshot = _getModifiedFileContentsFromDal(ps)

    const prevDuplicatesMap = lastSnapshot ? _getFromSnapshot(lastSnapshot, constants.paths.DUPLICATED_FILES_INFO) : {}
    const currentDuplicatesMap = ps.extensionAPI.wixCodeFileSystem.getDuplicatesMap()

    const directoryFlagByDeletedPath = ps.extensionAPI.wixCodeFileSystem.getDirectoryFlagByDeletedPathMap() ?? {}

    return {
        toSave: _getToSave(modifiedFilesInLastSnapshot, modifiedFilesInCurrentSnapshot),
        toCopy: _getToCopy(prevDuplicatesMap, currentDuplicatesMap),
        toDelete: directoryFlagByDeletedPath
    }
}

function getChanges(ps: PS) {
    const lastSnapshot = ps.extensionAPI.wixCodeFileSystem.snapshots.getLastSnapshot()
    return getChangesBetweenLastSnapshotAndCurrentStateTakenFromPS(lastSnapshot, ps)
}

function isChangesEmpty(changes) {
    return _.isEmpty(changes.toSave) && _.isEmpty(changes.toCopy) && _.isEmpty(changes.toDelete)
}

//currently only velo server can know if the open gridapp has changes from last revision. So we assume there are changes, until a better solution is provided
const doesGridAppHaveChanges = (ps: PS) =>
    Boolean(ps.extensionAPI.wixCode.isProvisioned() && ps.extensionAPI.wixCode.getOpenGridAppId())

function clearCache(ps: PS, paths: string[]) {
    paths.forEach(path => {
        deleteFile(ps, path)
        deleteFolder(ps, path)
    })
}

export default {
    writeFile,
    loadFileContent,
    readFile,
    getSourceFilePath,
    markFileForDuplication,
    updateDuplicates,
    moveFile,
    deleteFile,
    deleteFolder,
    isFileReadable,
    isFileExists,
    addChild,
    removeChild,
    hasKnownChild,
    isChildrenExists,
    areChildrenLoaded,
    getChildren,
    loadChildren,

    doesGridAppHaveChanges,
    getChanges,
    getChangesBetweenSnapshots,
    isChangesEmpty,

    clearCache
}
