const _ = require('lodash')
const featuresData = require('../dist/featuresData.json')
const CONSTANTS = require('../dist/constants.json')

const namespaceConfigs = require('../dist/namespaceConfigs.json')

const clientStyleToServerStyle = CONSTANTS.NAMESPACE_MAPPING
const serverStyleToClientStyle = _.invert(clientStyleToServerStyle)

const featureNamespaces = _.keys(featuresData)
const defaultNamespaceConfig = Object.freeze({
    supportOverrides: false,
    supportsVariants: false,
    isPublicViewerData: false,
    hasFeaturePublicAPI: false
})

// Not the same as OVERRIDE_NAMESPACES
const NAMESPACES_WITH_OVERRIDES = _(namespaceConfigs).pickBy({supportOverrides: true}).mapValues('namespace').value()

/**
 * @param {string} ns
 */
const getNamespaceConfig = ns => _.defaults(namespaceConfigs[ns], defaultNamespaceConfig)
const getNamespaces = () => _.keys(namespaceConfigs)

/**
 * @param {string} ns
 * @param {string} flag
 */
const getNamespaceFlag = (ns, flag) => {
    return namespaceConfigs[ns][flag]
}

/**
 * @param {string} ns
 * @param {string} flag
 */
const validateNamespaceFlag = (ns, flag) => {
    if (!getNamespaceFlag(ns, flag)) {
        throw new Error(`${ns} namespace should have ${flag}`)
    }
}

/** @param {string} name */
const convertNamespaceFromServerStyle = name => serverStyleToClientStyle[name] ?? name
/** @param {string} name */
const convertNamespaceToServerStyle = name => clientStyleToServerStyle[name] ?? name

module.exports = {
    ...CONSTANTS,
    featureNamespaces,
    featuresData,
    getNamespaceConfig,
    getNamespaces,
    validateNamespaceFlag,
    getNamespaceFlag,
    convertNamespaceFromServerStyle,
    convertNamespaceToServerStyle,
    NAMESPACES_WITH_OVERRIDES
}
