import type {AiToolResponse} from '@wix/document-manager-extensions'
import type {PS} from '@wix/document-services-types'
import {registerTool} from '../ai/ai'
import {themeColorPickerTool} from './themeColorPickerTool'
import {addLinkToComponentTool} from './addLinkToComponentTool'
import {getSitePagesTool} from './sitePagesTool'
import {modifyElementsAttributesTool} from './modifyElementsAttributesTool'
import {getElementPropertiesSchemaTool} from './getElementPropertiesSchemaTool'

export interface ToolDefinition {
    name: string
    func(ps: PS, params: any): void
    validationFunc(ps: PS, params: any): {success: boolean; validationMessage?: string}
    responseFunc(ps: PS, params: any): any
}

const aiTools = [
    themeColorPickerTool,
    addLinkToComponentTool,
    getSitePagesTool,
    modifyElementsAttributesTool,
    getElementPropertiesSchemaTool
]

const registerQueueTool = (ps: PS, toolDefinition: ToolDefinition) => {
    const {name, func, validationFunc, responseFunc} = toolDefinition

    const queueFunction = async (params: any): Promise<AiToolResponse> => {
        const {success, validationMessage} = validationFunc(ps, params)
        if (!success) {
            return {content: validationMessage, success: false}
        }
        const wrapperFunc = () => func(ps, params)
        ps.setOperationsQueue.runSetOperation(wrapperFunc, [params], {})
        await ps.setOperationsQueue.waitForChangesAppliedAsync()
        return {content: responseFunc(ps, params), success: true}
    }

    registerTool(ps, {name, func: queueFunction})
}

export const initAITools = (ps: PS) => {
    aiTools.forEach(tool => registerQueueTool(ps, tool))
}
