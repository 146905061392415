import type {PS} from '@wix/document-services-types'
import _ from 'lodash'

// TODO: unite with appPart2DataRequirementsChecker

// error messages
const ERROR_MISSING_TYPE_ID = 'Must provide a type ID'
const ERROR_MISSING_PART_ID = 'Must provide a part ID'
const ERROR_MISSING_VIEW_ID = 'Must provide a view ID'
const ERROR_MISSING_DATA_SELECTOR_ID = 'Must provide a data selector ID'

// site data paths
const BASE_WIXAPPS_PATH = ['wixapps']
const BASE_APPBUILDER_PATH = BASE_WIXAPPS_PATH.concat('appbuilder')
const BASE_REPO_PATH = BASE_APPBUILDER_PATH.concat('descriptor')

const BASE_DATA_PATHS = {
    VIEWS: BASE_REPO_PATH.concat('views'),
    TYPES: BASE_REPO_PATH.concat('types'),
    PARTS: BASE_REPO_PATH.concat('parts'),
    DATA_SELECTORS: BASE_REPO_PATH.concat('dataSelectors'),
    PAGES: BASE_REPO_PATH.concat('pages'),
    ITEMS: BASE_APPBUILDER_PATH.concat('items'),
    DELETED_ITEMS: BASE_APPBUILDER_PATH.concat('deletedItems')
}

function throwIfUndefined(param: any, errorMessage: string) {
    if (!param) {
        throw new Error(errorMessage)
    }
}

export default {
    initBasePaths(ps: PS) {
        const pathsToSet = [BASE_WIXAPPS_PATH, BASE_APPBUILDER_PATH, BASE_REPO_PATH].concat(_.values(BASE_DATA_PATHS))

        _.forEach(pathsToSet, function (path) {
            if (!ps.wixappsDAL.isPathExist(path)) {
                // DAL does not support deep merge
                ps.wixappsDAL.setByPath(path, {})
            }
        })
    },

    /**
     * @param {string} [typeId]
     * @returns {string[]}
     */
    getBaseItemsPath(typeId?: string) {
        return typeId ? BASE_DATA_PATHS.ITEMS.concat(typeId) : BASE_DATA_PATHS.ITEMS
    },

    getBaseTypesPath() {
        return BASE_DATA_PATHS.TYPES
    },

    getTypePath(typeId: string) {
        throwIfUndefined(typeId, ERROR_MISSING_TYPE_ID)
        return this.getBaseTypesPath().concat(typeId)
    },

    getBasePartsPath() {
        return BASE_DATA_PATHS.PARTS
    },

    getPartPath(partId: string) {
        throwIfUndefined(partId, ERROR_MISSING_PART_ID)
        return this.getBasePartsPath().concat(partId)
    },

    getBaseViewsPath() {
        return BASE_DATA_PATHS.VIEWS
    },

    getViewPath(viewId: string) {
        throwIfUndefined(viewId, ERROR_MISSING_VIEW_ID)
        return this.getBaseViewsPath().concat(viewId)
    },

    getBaseDataSelectorsPath() {
        return BASE_DATA_PATHS.DATA_SELECTORS
    },

    getDataSelectorPath(dataSelectorId: string) {
        throwIfUndefined(dataSelectorId, ERROR_MISSING_DATA_SELECTOR_ID)
        return this.getBaseDataSelectorsPath().concat(dataSelectorId)
    },

    getRepoPath() {
        return BASE_REPO_PATH
    }
}
