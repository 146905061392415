const resizableSides = []
export default {
    minimalChildrenNumber: 2,
    resizableSides,
    canBeFixedPosition: false,
    mobileConversionConfig: {
        category: 'boxContainer',
        filterChildrenWhenHidden: true,
        isTightContainer: true,
        nestOverlayingSiblings: false,
        marginX: 0
    }
}
