import type {PossibleViewModes, PS, ScopeMetaData, ScopePointer} from '@wix/document-services-types'

const loadMetaData = async (ps: PS) => ps.extensionAPI.scopesMetaData.load()
const getMetaData = (ps: PS, scopePointer: ScopePointer, viewMode?: PossibleViewModes): ScopeMetaData =>
    ps.extensionAPI.scopesMetaData.get(scopePointer, viewMode)

export default {
    loadMetaData,
    getMetaData
}
