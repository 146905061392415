import type {DocumentManager} from '@wix/document-manager-core'
import type {
    AppsInstallStateExtAPI,
    RMApi,
    PageExtensionAPI,
    PlatformExtensionAP
} from '@wix/document-manager-extensions'
import type {AppsInstallStateMap, ClientSpecMapEntry} from '@wix/document-services-types'
import _ from 'lodash'
import type {MasterPageMigrator} from '../dataMigrationRunner'

const migrateMasterPage = (documentManager: DocumentManager) => {
    const {extensionAPI} = documentManager
    const {page} = extensionAPI as PageExtensionAPI

    if (page.isPartiallyLoaded()) {
        return
    }

    const {rendererModel} = extensionAPI as RMApi
    const {platform} = extensionAPI as PlatformExtensionAP
    const {appsInstallState} = extensionAPI as AppsInstallStateExtAPI

    const clientSpecMap = rendererModel.getClientSpecMap()
    const appsWithComps = platform.getAllAppsInstalledOnPages()

    const newStateMap = _.reduce(
        clientSpecMap,
        (stateMap: AppsInstallStateMap, app: ClientSpecMapEntry) => {
            const appInstallState = appsInstallState.getInstallStateByAppData(app, appsWithComps)
            if (appInstallState) {
                const appDefinitionId = _.get(app, ['appDefinitionId'])
                stateMap[appDefinitionId] = appInstallState
            }
            return stateMap
        },
        {}
    )

    appsInstallState.addStateToDataModel(newStateMap)
}

const disableVersioning = (documentManager: DocumentManager) =>
    (documentManager.extensionAPI as PageExtensionAPI).page.isPartiallyLoaded()

const name = 'persistAppsInstallState'
const version = 4
const disableFixerAfterFirstRun = true

export const persistAppsInstallState: MasterPageMigrator = {
    migrateMasterPage,
    name,
    version,
    disableVersioning,
    disableFixerAfterFirstRun
}
