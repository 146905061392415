import type {
    CreateExtensionArgument,
    DalValue,
    DmApis,
    Extension,
    ExtensionAPI,
    ValidatorResult,
    WhitelistCheckResult
} from '@wix/document-manager-core'
import type {Experiment, Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import type {PageAPI, PageExtensionAPI} from './page'
import type {ParsedSchemaValidationErrorMessage} from './schema/schema'
export type GetErrorContext = () => any
type WhitelistCheckWithContext = (
    pointer: Pointer,
    value: DalValue,
    validatorResult: ValidatorResult,
    errorContext: Record<string, any>,
    origin: string,
    experimentInstance: Experiment
) => WhitelistCheckResult
export const NOT_LISTED = 'NOT_LISTED'
export const WARNING_ONLY = 'WARNING_ONLY'
const shouldValidateTheRefForPartialPageLoading = (pageApi: PageAPI, value: any, path: string[]) =>
    pageApi.isPartiallyLoaded() && _.includes(path, 'pageBackgrounds')

const createWhitelists = (extensionAPI: ExtensionAPI): Record<string, WhitelistCheckWithContext> => {
    return {
        missingReferenceError: (pointer, value, validatorResult) => {
            const {path} = validatorResult.extras!
            const {page: pageApi} = extensionAPI as PageExtensionAPI
            if (shouldValidateTheRefForPartialPageLoading(pageApi, value, path)) {
                /*handle case in partial page loading where we load just master page but page data contains bg for non loaded page (page background in on the page)*/
                return {issue: 'DM-6005', isWhiteListed: true}
            }

            return {issue: NOT_LISTED, isWhiteListed: false}
        },
        DuplicateReferenceError: pointer => {
            const {type: namespace} = pointer
            if (namespace === 'MOBILE') {
                /* https://jira.wixpress.com/browse/DM-9143  - old issue was DM-5614 and is kept as the tag for bi consistency */
                return {issue: 'DM-5614', isWhiteListed: true}
            }

            return {issue: NOT_LISTED, isWhiteListed: false}
        },
        wrongPageRefError: pointer => {
            if (pointer.type === 'multilingualTranslations') {
                /* https://jira.wixpress.com/browse/DM-9144  - old issue was DM-6052 and is kept as the tag for bi consistency */
                return {issue: 'DM-6052', isWhiteListed: true}
            }

            return {issue: NOT_LISTED, isWhiteListed: false}
        },
        schemaValidationError: (pointer, value, validatorResult, errorContext, dsOrigin, experimentInstance) => {
            if (value.type === 'SingleLayoutData' && !experimentInstance.isOpen('dm_noLayoutValidationWhitelist')) {
                const validationMessages = JSON.parse(validatorResult.message) as ParsedSchemaValidationErrorMessage
                // mark as not listed if not in known conditions
                const isWhiteListed = _.every(validationMessages.errors, message => {
                    const validSubpropertyValidations = ['width', 'height']
                    const isCorrespondingProperty = ['componentLayout', 'containerLayout', 'itemLayout'].some(
                        property => message.dataPath.includes(property)
                    )
                    const failedToMatchAnyOf = message.message === 'should match some schema in anyOf'
                    const isLegitSubPropertyFailure = validSubpropertyValidations.some(prop =>
                        message.dataPath.includes(prop)
                    )

                    return !isLegitSubPropertyFailure && (isCorrespondingProperty || failedToMatchAnyOf)
                })

                // @see {@link https://jira.wixpress.com/browse/DM-7151}
                return {issue: 'DM-7151', isWhiteListed}
            }

            return {issue: NOT_LISTED, isWhiteListed: false}
        }
    }
}

export interface ValidationWhitelistExtensionAPI extends ExtensionAPI {
    validationWhitelist: {
        registerContextProvider(name: string, contextProvider: GetErrorContext): void
    }
}

const createExtension = ({dsConfig, experimentInstance}: CreateExtensionArgument): Extension => {
    const contextProviders: Record<string, GetErrorContext> = {}
    const createExtensionAPI = (): ValidationWhitelistExtensionAPI => {
        const registerContextProvider = (name: string, contextProvider: GetErrorContext) => {
            contextProviders[name] = contextProvider
        }
        return {
            validationWhitelist: {
                registerContextProvider
            }
        }
    }

    const initialize = async ({dal, extensionAPI}: DmApis) => {
        if (experimentInstance.isOpen('dm_disableValidationWhitelist')) {
            // This experiment is for the sake of tests only
            // Tests should always fail for strict-mode violations
            return
        }
        const whitelists: Record<string, WhitelistCheckWithContext> = createWhitelists(extensionAPI)
        dal.registrar.registerValidationWhitelistCheck(
            (pointer: Pointer, value: DalValue, validatorResult: ValidatorResult) => {
                if (dsConfig.neverFailStrictValidations) {
                    // added to exclude onboarding from strict-mode
                    return {issue: WARNING_ONLY, isWhiteListed: true}
                } else if (whitelists[validatorResult.type]) {
                    const errorContext = _.mapValues(contextProviders, fn => fn())
                    return whitelists[validatorResult.type](
                        pointer,
                        value,
                        validatorResult,
                        errorContext,
                        dsConfig.origin,
                        experimentInstance
                    )
                }
                return {issue: NOT_LISTED, isWhiteListed: false}
            }
        )
    }

    return {
        name: 'validationWhitelist',
        createExtensionAPI,
        initialize
    }
}

export {createExtension}
