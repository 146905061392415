import type {PublicMethodUtils} from '@wix/document-services-types'
import {mobileActions} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            mobile: {
                migration: {
                    clearMobileReferences: publicMethodUtils.defineDataManipulationAction(
                        mobileActions.clearMobileReferences
                    )
                }
            }
        }
    }
}
