import type {DalPointers} from '../../../../types'
import type {CompStructure} from '@wix/document-services-types'
import _ from 'lodash'
import {CONTAINER_LAYOUT_TYPES, ITEM_LAYOUT_TYPES, COMPONENT_LAYOUT_TYPES} from '../defaultResponsiveLayoutDefinitions'

const getTpaWidgetDefaultLayout = ({dal, pointers}: DalPointers, compDefinition: CompStructure) => {
    const {appDefinitionId, widgetId} = compDefinition?.data ?? {}
    const csmEntry = dal.get(pointers.rendererModel.getClientSpecMapEntryByAppDefId(appDefinitionId))
    const widgetData = _.get(csmEntry, ['widgets', widgetId])
    if (widgetData?.defaultWidth && widgetData.defaultHeight) {
        return {
            COMPONENT_LAYOUT_TYPES: {
                type: 'ComponentLayout',
                width: {type: 'px', value: widgetData.defaultWidth},
                height: {type: 'px', value: widgetData.defaultHeight}
            },
            CONTAINER_LAYOUT_TYPES: CONTAINER_LAYOUT_TYPES.NONE,
            ITEM_LAYOUT_TYPES: ITEM_LAYOUT_TYPES.CENTER
        }
    }
    return {
        COMPONENT_LAYOUT_TYPES: COMPONENT_LAYOUT_TYPES.MIN_HEIGHT,
        CONTAINER_LAYOUT_TYPES: CONTAINER_LAYOUT_TYPES.NONE,
        ITEM_LAYOUT_TYPES: ITEM_LAYOUT_TYPES.CENTER
    }
}
export const tpaWidgetDefaultResponsiveLayout = {
    getLayout: getTpaWidgetDefaultLayout
}
