import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import dataManipulation from './dataManipulation'

/**
 * Enum for Favicon possible errors
 * @enum {string} documentServices.favicon.ERRORS
 * @readonly
 */
const ERRORS = {
    FAVICON_IS_NOT_STRING: 'FAVICON_IS_NOT_STRING'
}

function setFavicon(ps: PS, faviconUri: string) {
    const result = validateFaviconUri(faviconUri)
    if (!result.success) {
        throw new Error((result as ValidateFaviconUriFailureResult).errorCode)
    }
    dataManipulation.setProperty(ps, dataManipulation.PROPERTY_NAMES.FAVICON, faviconUri || '')
}

function getFavicon(ps: PS): string {
    return dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.FAVICON)
}

export interface ValidateFaviconUriSuccessResult {
    success: true
}

export interface ValidateFaviconUriFailureResult {
    success: false
    errorCode: string
}

export type ValidateFaviconUriResult = ValidateFaviconUriSuccessResult | ValidateFaviconUriFailureResult

function validateFaviconUri(faviconUri: string): ValidateFaviconUriResult {
    if (!_.isString(faviconUri)) {
        return {success: false, errorCode: ERRORS.FAVICON_IS_NOT_STRING}
    }
    return {success: true}
}

/** @class documentServices.favicon */
export default {
    /**
     * Retrieves the site's current Favicon
     *
     * @returns {string} the site's current Favicon
     */
    get: getFavicon,

    /**
     * Sets the site's Favicon
     *
     * @param {string} faviconUri the URI of the requested Favicon
     */
    set: setFavicon,

    ERRORS
}
