'use strict'

const _ = require('lodash')
const coreUtils = require('@wix/santa-core-utils')
const behaviorsMigrationHelper = require('../helpers/behaviorsMigrationHelper')

const BEHAVIOR_QUERY = 'behaviorQuery'
const SCREEN_IN = ['screenIn']
const SCREEN_IN_AND_BG_SCRUB = ['screenIn', 'bgScrub']
const STRIP_COLUMNS_CONTAINER_COMP_TYPE = 'wysiwyg.viewer.components.StripColumnsContainer'

const getAllStripsWithBehaviors = (structure, isMobile) => {
    return coreUtils.dataUtils.getAllCompsInStructure(
        structure,
        isMobile,
        comp => comp.componentType === STRIP_COLUMNS_CONTAINER_COMP_TYPE && comp.behaviorQuery
    )
}

const getAllStripsWithScreenInBehaviors = structure => {
    const desktopComponents = getAllStripsWithBehaviors(structure, false)
    const mobileComponents = getAllStripsWithBehaviors(structure, true)
    return {
        desktopComponents,
        mobileComponents
    }
}

const getParsedItems = behaviorItem => JSON.parse(behaviorItem.items)

const doesBehaviorHaveTheGivenActions = (behaviorItem, actionsToCheck) => {
    const parsedItems = getParsedItems(behaviorItem)
    return parsedItems.some(({action}) => actionsToCheck.includes(action))
}

const removeOrCleanStripBehavior = (behaviorsData, desktopStrip, mobileStrip) => {
    const behaviorItem = behaviorsData[desktopStrip.behaviorQuery]
    const parsedItems = getParsedItems(behaviorItem)
    const itemsWithoutScreenIn = parsedItems.filter(({action}) => action !== 'screenIn')
    behaviorItem.items = JSON.stringify(itemsWithoutScreenIn)
    if (itemsWithoutScreenIn.length === 0) {
        delete behaviorsData[desktopStrip.behaviorQuery]
        delete desktopStrip.behaviorQuery
        if (mobileStrip) {
            delete mobileStrip.behaviorQuery
        }
    }
}

const addScreenInBehaviorToFirstColumn = (
    behaviorsData,
    uniqueIdGenerator,
    behaviorItem,
    desktopFirstColumn,
    mobileFirstColumn
) => {
    const parsedItems = getParsedItems(behaviorItem)
    const parsedScreenInItem = parsedItems.find(({action}) => action === 'screenIn')
    if (!desktopFirstColumn.behaviorQuery) {
        const behaviorItemToAdd = behaviorsMigrationHelper.createBehaviorsDataItem(
            JSON.stringify([parsedScreenInItem]),
            uniqueIdGenerator
        )
        behaviorsData[behaviorItemToAdd.id] = behaviorItemToAdd
        desktopFirstColumn[BEHAVIOR_QUERY] = behaviorItemToAdd.id
        if (mobileFirstColumn) {
            mobileFirstColumn[BEHAVIOR_QUERY] = behaviorItemToAdd.id
        }
        return
    }

    const columnBehaviorItem = behaviorsData[desktopFirstColumn.behaviorQuery]
    if (!doesBehaviorHaveTheGivenActions(columnBehaviorItem, SCREEN_IN_AND_BG_SCRUB)) {
        const parsedStripBehaviorItems = getParsedItems(columnBehaviorItem).push(parsedScreenInItem)
        columnBehaviorItem.items = JSON.stringify(parsedStripBehaviorItems)
    }
}

const fixPage = (structure, behaviorsData, uniqueIdGenerator) => {
    const {desktopComponents, mobileComponents} = getAllStripsWithScreenInBehaviors(structure)
    _.forEach(desktopComponents, desktopStrip => {
        const stripBehaviorItem = behaviorsData[desktopStrip.behaviorQuery]
        const desktopFirstColumn = desktopStrip.components[0]
        const mobileStrip = mobileComponents[desktopStrip.id]
        let mobileFirstColumn
        if (mobileStrip) {
            mobileFirstColumn = mobileStrip.components[0]
        }

        // screenIn action should move from strip to column only if either the strip is hidden on mobile or
        // if it exists both on desktop & mobile & first column in each has the same id, so we do not create
        // split in behaviorQuery
        const canMoveBehaviorFromStripToColumn =
            (desktopFirstColumn && !mobileStrip && !mobileFirstColumn) ||
            (desktopFirstColumn && mobileStrip && mobileFirstColumn && desktopFirstColumn.id === mobileFirstColumn.id)

        if (doesBehaviorHaveTheGivenActions(stripBehaviorItem, SCREEN_IN)) {
            if (canMoveBehaviorFromStripToColumn) {
                addScreenInBehaviorToFirstColumn(
                    behaviorsData,
                    uniqueIdGenerator,
                    stripBehaviorItem,
                    desktopFirstColumn,
                    mobileFirstColumn
                )
            }
            removeOrCleanStripBehavior(behaviorsData, desktopStrip, mobileStrip)
        }
    })
}

module.exports = {
    name: 'stripColumnsContainerScreenInBehaviorsFixer',
    version: 1,
    exec(pageJson, pageIdsArray, magicObject) {
        const {data, structure} = pageJson
        const {uniqueIdGenerator} = magicObject.dataFixerUtils
        fixPage(structure, data.behaviors_data, uniqueIdGenerator)
    },
    fixerRequiresReruns: true
}
