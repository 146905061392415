import urlUtils from './urlUtils'
import {fontsMetaData} from '@wix/thunderbolt-fonts'
import media from './media'
import {
    FONT_GROUPS,
    GOOGLE_FONT_SERVICE_URL,
    GOOGLE_FONT_URL,
    HELVETICA_FALLBACK,
    LANGUAGES_CSS_RELATIVE_PATH,
    LANG_TO_EXAMPLE_KEY,
    LONG_UPLOADED_FONT_PREFIX,
    POSSIBLE_CHARACTERS_SETS,
    TEXT_THEMES_MAP_KEY,
    UPLOADED_FONT_MATCH,
    UPLOADED_FONT_TEST
} from './fontUtils.consts'
import type {
    CharSet,
    FontsMetaData,
    FontsByLang,
    FontsDropDownByGroup,
    FontsDropDownItem,
    FontObj,
    TextTheme,
    ThemeColors,
    ThemeData,
    ThemeFonts,
    FontMetaData,
    WixStaticFontUrls,
    FontForListMetaData,
    TextThemesObj,
    TextThemesList
} from './fontUtils.types'
import type {ServiceTopology} from '@wix/document-services-types'
const {joinURL} = urlUtils

export function parseFontStr(fontStr: string): FontObj {
    const split = fontStr.split(' ')
    const sizeSplit = split[3] ? split[3].split('/') : []
    return {
        style: split[0] as FontObj['style'],
        variant: split[1] as FontObj['variant'],
        weight: split[2] as FontObj['weight'],
        size: sizeSplit[0],
        lineHeight: sizeSplit[1],
        family: split[4].replace(/\+/g, ' '),
        color: split[5],
        bold: split[2] === 'bold' || parseInt(split[2], 10) >= 700,
        italic: split[0] === 'italic'
    }
}

export function fromTextThemeToFontStyle(textTheme: TextTheme): FontObj {
    return {
        style: textTheme.fontStyle,
        variant: textTheme.fontVariant,
        weight: textTheme.fontWeight,
        size: textTheme.fontSize,
        lineHeight: textTheme.lineHeight,
        letterSpacing: textTheme.letterSpacing,
        family: textTheme.fontFamily.replace(/[+]/g, ' '),
        color: textTheme.color,
        bold: ['bold', 'bolder'].includes(textTheme.fontWeight),
        italic: textTheme.fontStyle === 'italic'
    }
}

export function getWixStaticsFontsLinks(serviceTopology: ServiceTopology, documentType: string) {
    const result: WixStaticFontUrls = {
        languages: media.getMediaUrl(serviceTopology, joinURL(LANGUAGES_CSS_RELATIVE_PATH, 'languages.css'), 'dist'),
        wixmadefor: media.getMediaUrl(serviceTopology, 'user-site-fonts/v27/wixMadefor.css', 'dist')
    }
    if (documentType === 'WixSite') {
        result.helveticas = media.getMediaUrl(serviceTopology, 'user-site-fonts/v29/helvetica.css')
    }

    return result
}

export function getFontsServiceUrlWithParams(fontNamesObject, documentType: string, characterSets: CharSet[]) {
    const fontsFamiliesArray = Array.isArray(fontNamesObject) ? fontNamesObject : Object.keys(fontNamesObject)
    const query = getFontsQuery(fontsFamiliesArray, documentType, characterSets)
    if (query) {
        return GOOGLE_FONT_SERVICE_URL + query
    }
    return ''
}

// TODO: April 2024 - Tombigel:
// This is an old version of "getFontsServiceUrlWithParams" using the real googleapi url and I'm sure we shouldn't
// but I can't be responsible for every bad piece of code related to fonts and too many externals are using it.
// Will have to get back to it later.
export function getFontsUrlWithParams(
    fontNamesObject: string[] | Record<string, any>,
    documentType: string,
    characterSets: CharSet[]
) {
    const fontsFamiliesArray = Array.isArray(fontNamesObject) ? fontNamesObject : Object.keys(fontNamesObject)
    const query = getFontsQuery(fontsFamiliesArray, documentType, characterSets)
    if (query) {
        return GOOGLE_FONT_URL + query
    }

    return ''
}

export function getFontsQuery(fontsFamiliesArray: string[], documentType: string, characterSets: CharSet[]) {
    const permissions = getFontsPermissions(documentType)
    const fontQuery = fontsFamiliesArray
        .map(function (fontFamily) {
            const font: FontMetaData = fontsMetaData[fontFamily]
            if (font?.cdnName && permissions.includes(font.permissions)) {
                return `${font.cdnName}:n,b,i,bi`
            }
            return null
        })
        .filter(Boolean)
        .join('|')

    if (fontQuery === '') {
        return null
    }

    if (characterSets) {
        return `${fontQuery}&subset=${characterSets.join(',')}`
    }
    return fontQuery
}

export function getFontsPermissions(documentType: string) {
    const permissions = ['all', 'legacy']
    if (documentType === 'WixSite') {
        permissions.push('studio')
    }
    return permissions
}

export function getWixStoredFontsCssUrlsWithParams(baseUrl: string, characterSets: CharSet[], altBaseUrl?: string) {
    if (altBaseUrl && /localhost|127.0.0.\d/.test(baseUrl)) {
        baseUrl = altBaseUrl
    }
    baseUrl = baseUrl.replace(/^http:/, '')
    const fontsCssBaseUrl = `${baseUrl.replace(/\/$/, '')}/static/css/user-site-fonts/`
    return characterSets.map(cs => `${fontsCssBaseUrl + (cs as string)}.css`)
}

export function parseStyleFont(fontStyleName: string, themeFonts: Record<string, string>, themeColors: ThemeColors) {
    if (themeFonts[fontStyleName]) {
        const fontObject = parseStringFont(themeFonts[fontStyleName])
        return parseThemeFontColor(fontObject, themeColors)
    }
    return parseStringFont(fontStyleName)
}

export function parseStyleTextTheme(
    fontStyleName: string,
    textThemes: TextThemesObj | TextTheme,
    themeColors: ThemeColors
) {
    const textTheme = (textThemes[fontStyleName] || textThemes) as TextTheme

    const fontObj = fromTextThemeToFontStyle(textTheme)

    fontObj.fontWithFallbacks = getFontFamilyWithFallbacks(fontObj.family)
    fontObj.cssColor = textThemeToColor(textTheme, themeColors)

    return fontObj
}

export function parseStringFont(fontValue: string) {
    const fontObject = parseFontStr(fontValue)
    fontObject.fontWithFallbacks = getFontFamilyWithFallbacks(fontObject.family)
    return fontObject
}

export function getFontFamilyWithFallbacks(fontName: string) {
    const cleanFontName = fontName.replace(/\+/g, ' ').toLowerCase()
    const font: FontMetaData = fontsMetaData[cleanFontName]
    const fontFamily = font?.fontFamily
    let fallbacks: string

    if (font) {
        fallbacks = fontFamily
        if (font.fallbacks !== '') {
            fallbacks += `,${font.fallbacks}`
        }
        fallbacks += `,${font.genericFamily}`
    } else {
        fallbacks = fontName
    }

    return formatFallbackList(fallbacks)
}

export function formatFallbackList(fallbacks: string) {
    //surround fonts with quotes if font name contains spaces or non-latin chars
    return fallbacks
        .split(',')
        .map(font => font.replace(/.*[^\w\d\-].*/, '"$&"'))
        .join(',')
}

export function parseThemeFontColor(fontObject: FontObj, themeColors: ThemeColors) {
    const fontColor = fontObject.color?.match(/{([^}]+)}/)
    if (themeColors && fontColor && themeColors[fontColor[1]]) {
        fontObject.cssColor = themeColors[fontColor[1]]
    } else {
        fontObject.cssColor = fontObject.color
    }
    return fontObject
}

export function getFontFamily(fontStr: string) {
    return fontStr.split(' ')[4]
}

export function fontToCSSWithColor(font: string, themeColors: ThemeColors) {
    const fontVal = getFontVal(font)
    const fontCss = getFontCSSFromFontString(fontVal)
    const colorCss = getColorCSSFromFontString(fontVal, themeColors)

    return fontCss + colorCss
}

/**
 * @param themeFonts: {font_0: 'fontString', font_1: 'fontString'}
 * @param themeColors
 * @returns ".font_0: {"font: 'normal normal normal 45px/1.4em Open+Sans; color: #123456;} "
 */
export function getThemeFontsCss(themeFonts: ThemeFonts, themeColors: ThemeColors) {
    let result = ''
    const themeFontsTuple: [number | string, string][] = Array.isArray(themeFonts)
        ? themeFonts.map((val, key) => [key, val])
        : Object.entries(themeFonts)
    for (const [fontIndex, fontString] of themeFontsTuple) {
        result += `.font_${fontIndex} {font: ${fontToCSSWithColor(fontString, themeColors)}} \n`
    }

    return result
}

export function getTextThemesMap(textThemes: TextThemesObj | TextThemesList) {
    const textThemesMap: Record<string, TextTheme> = {}
    const textThemesTuple: [string | number, TextTheme][] = Array.isArray(textThemes)
        ? textThemes.map((val, key) => [key, val])
        : Object.entries(textThemes)
    textThemesTuple.forEach(([index, textTheme]) => {
        const key = `${TEXT_THEMES_MAP_KEY}_${index}`
        textThemesMap[key] = textTheme
    })

    return textThemesMap
}

export function textThemeToCSSLetterSpacing(textTheme: TextTheme) {
    const {letterSpacing} = textTheme

    return letterSpacing ? `letter-spacing: ${letterSpacing};` : ''
}

export function textThemeToFont(textTheme: TextTheme) {
    const DEFAULT_VALUE = 'normal'
    const {fontSize, fontFamily, lineHeight, fontStyle, fontWeight, fontVariant} = textTheme
    const fontSizeAndLineHeight = lineHeight ? `${fontSize}/${lineHeight}` : fontSize
    return [
        fontStyle || DEFAULT_VALUE,
        fontVariant || DEFAULT_VALUE,
        fontWeight || DEFAULT_VALUE,
        fontSizeAndLineHeight,
        fontFamily
    ].join(' ')
}

export function textThemeToCSSFont(textTheme: TextTheme) {
    const fontFamily = getFullFontFamily(textTheme.fontFamily)
    textTheme = Object.assign({}, textTheme, {fontFamily})

    const fontString = textThemeToFont(textTheme)

    return `font: ${fontString};`
}

export function textThemeToColor(textTheme: TextTheme, themeColors: ThemeColors) {
    let {color} = textTheme
    const colorParts = color.match(/{color_(\d+)}/)

    if (colorParts) {
        const colorIndexInTheme = colorParts[1]
        if (Array.isArray(themeColors)) {
            color = themeColors[colorIndexInTheme]
        } else {
            const themeColorsValues = Object.values(themeColors)
            color = themeColorsValues[colorIndexInTheme]
        }
    }

    return color.indexOf('#') === 0 ? color : `rgba(${color})`
}

export function textThemeToCSSColor(textTheme: TextTheme, themeColors: ThemeColors) {
    if (!textTheme.color) {
        return ''
    }

    const color = textThemeToColor(textTheme, themeColors)

    return `color: ${color};`
}

export function textThemeToCSS(textTheme: TextTheme, themeColors: ThemeColors) {
    const font = textThemeToCSSFont(textTheme)
    const color = textThemeToCSSColor(textTheme, themeColors)
    const letterSpacing = textThemeToCSSLetterSpacing(textTheme)

    return [font, color, letterSpacing].join(' ')
}

export function getTextThemesCss(textThemes: TextThemesObj | TextThemesList, themeColors: ThemeColors) {
    const textThemesMap = getTextThemesMap(textThemes)
    let result = ''

    for (const [className, textTheme] of Object.entries(textThemesMap)) {
        const css = textThemeToCSS(textTheme, themeColors)
        result += `.${className} {${css}}\n`
    }

    return result
}

export function textThemeToFontString(textTheme: TextTheme) {
    const {color} = textTheme
    const font = textThemeToFont(textTheme)

    return [font, color].join(' ')
}

export function fontStringToTextTheme(fontString: string): TextTheme {
    const [fontStyle, fontVariant, fontWeight, fontSizeLineHeight, fontFamily, color] = fontString.split(' ')
    const [fontSize, lineHeight] = fontSizeLineHeight.split('/')
    return {
        fontStyle: fontStyle as TextTheme['fontStyle'],
        fontWeight: fontWeight as TextTheme['fontWeight'],
        fontVariant: fontVariant as TextTheme['fontVariant'],
        fontFamily,
        fontSize,
        lineHeight,
        color,
        letterSpacing: '0em'
    }
}

export function getFontVal(fontString: string, themeData?: ThemeData) {
    if (fontString.startsWith('font_')) {
        const fontParts = fontString.split('font_')
        if (fontParts.length === 2) {
            return themeData?.font[fontParts[1]] || ''
        }
    }
    return fontString
}

export function getFontsByPermissionToFontsList(documentType: string) {
    const permissions = getFontsPermissions(documentType)
    const langs = Object.entries<FontForListMetaData>(fontsMetaData).reduce(
        (res: Record<string, FontForListMetaData[]>, [key, value]) => {
            const fontCharacterSets = value.characterSets as CharSet[]
            if (permissions.includes(value.permissions)) {
                value.cssFontFamily = getFontFamilyWithFallbacks(key)
                fontCharacterSets.forEach(function (charSet: CharSet) {
                    if (!res[charSet]) {
                        res[charSet] = []
                    }
                    res[charSet].push(value)
                })
            }
            return res
        },
        {}
    )

    Object.keys(langs).forEach(charSet => {
        langs[charSet].sort((a, b) => a.displayName.localeCompare(b.displayName))
    })
    return langs
}

export function getCurrentSelectablefontsWithParams(documentType: string, characterSets: CharSet[]): FontsByLang[] {
    const languagesFontsLists = getFontsByPermissionToFontsList(documentType)
    return POSSIBLE_CHARACTERS_SETS.filter(lang => characterSets.includes(lang as CharSet)).map(lang => ({
        lang,
        fonts: languagesFontsLists[lang]
    }))
}

/**
 * Gets font items for the font family selector drop down
 * @param fontsByLang - getCurrentSelectablefontsWithParams output
 * @returns Font items in font family selector drop down format
 */
export function getFontsDropDownItems(fontsByLang: FontsByLang[]) {
    const fontItems: FontsDropDownByGroup[] = []

    fontsByLang.forEach(({fonts, lang}) => {
        const items: FontsDropDownItem[] = []

        fonts.forEach(font => {
            if (font.permissions === 'legacy') {
                return
            }

            items.push({
                label: font.displayName,
                value: font.fontFamily,
                example: LANG_TO_EXAMPLE_KEY[lang],
                cssFontFamily: `${font.cssFontFamily},${HELVETICA_FALLBACK}`
            })
        })

        fontItems.push({
            groupName: FONT_GROUPS[lang],
            items
        })
    })

    return fontItems
}

export function getFontFallback(fontFamily: string) {
    const cleanFontName = fontFamily.replace(/\+/g, ' ').toLowerCase()
    const fontMeta = fontsMetaData[cleanFontName]
    if (fontMeta) {
        let fallback = fontMeta.fallbacks
        if (fallback) {
            fallback += ','
        }
        fallback += fontMeta.genericFamily
        return fallback
    }

    return ''
}

export function getFontCSSFromFontString(fontVal: string) {
    const font = fontVal.replace(/#.*/, '').replace(/\{color_\d+\}/, '')
    const fontFamily = getFontFamily(font)
    const fullFontFamily = getFullFontFamily(fontFamily)
    const childFont = font.replace(fontFamily, fullFontFamily)
    return `${childFont};`
}

export function getFullFontFamily(fontFamily: string) {
    let fullFontFamily = fontFamily
    const fallback = getFontFallback(fontFamily)
    if (fallback) {
        fullFontFamily = `${fullFontFamily},${fallback}`
    }

    //surround fonts with quotes if font name contains spaces or non-latin chars
    fullFontFamily = fullFontFamily.replace(
        /[^,]*[^\w,\d\-][^,]*/g,
        fontFamilyStr => `'${fontFamilyStr.replace(/\+/g, ' ')}'`
    )
    return fullFontFamily
}

export function getColorCSSFromFontString(fullFontString: string, themeColors: ThemeColors) {
    const colorParts = fullFontString.match(/{color_(\d+)}/)
    if (!colorParts) {
        const colorFromFontString = fullFontString.match(/#[A-Z0-9]{3,6}/)
        if (colorFromFontString) {
            return `color:${colorFromFontString[0]};`
        }
        return ''
    }
    const colorIndexInTheme = colorParts[1]
    const colorFromTheme = themeColors[colorIndexInTheme]
    if (colorFromTheme.indexOf('#') === 0) {
        return `color:${colorFromTheme};`
    }
    return `color:rgba(${colorFromTheme});`
}

export function getFontFamilyPermissions(fontFamily: string) {
    const fontDef = Object.values<FontMetaData>(fontsMetaData).find(font => font.fontFamily === fontFamily)
    return fontDef?.permissions
}

export function getFontsMetaData(): FontsMetaData {
    return fontsMetaData
}

export function collectFontsFromTextDataArray(textHtml: string, {onlyUploaded = false} = {}) {
    if (!textHtml) {
        return []
    }

    if (!window?.DOMParser) {
        return []
    }

    if (onlyUploaded) {
        return textHtml.match(UPLOADED_FONT_MATCH)
    }

    const doc = new window.DOMParser().parseFromString(textHtml, 'text/html')
    return Array.from(doc.querySelectorAll('*'))
        .map(el => (el as HTMLElement)?.style?.['font-family'])
        .filter(Boolean)
        .map(s => s.trim())
}

export function isUploadedFontFamily(fontFamilyStr: string) {
    if (!fontFamilyStr) {
        return false
    }

    const fontName = fontFamilyStr.split(',')[0]
    return UPLOADED_FONT_TEST.test(fontName)
}

export function getUploadedId(uploadedFonStr: string) {
    return isUploadedFontFamily(uploadedFonStr) ? uploadedFonStr.replace(LONG_UPLOADED_FONT_PREFIX, '').trim() : null
}

export function getUploadedFontFaceStyles(fontFamiliesArr: string[], mediaRootUrl: string) {
    const fixedMediaRootUrl = fixMediaRootUrl(mediaRootUrl)
    return fontFamiliesArr
        .filter(isUploadedFontFamily)
        .reduce((accm: string, font: string) => accm + getUploadFontFace(font, fixedMediaRootUrl), '')
}

export function fixMediaRootUrl(mediaRootUrl: string) {
    return mediaRootUrl.startsWith('http://') ? mediaRootUrl.replace('http://', 'https://') : mediaRootUrl
}

export function getUploadFontFace(font: string, mediaRootUrl: string) {
    const fontName = font.split(',')[0]
    const fontId = getUploadedId(fontName)
    const shortFontName = getShortUploadedFontFamily(fontName)
    const fontFaceString = `@font-face \{
font-family: ${shortFontName};
src: url("${mediaRootUrl}ufonts/${fontId}/woff2/file.woff2") format("woff2"),
url("${mediaRootUrl}ufonts/${fontId}/woff/file.woff") format("woff"),
url("${mediaRootUrl}ufonts/${fontId}/ttf/file.ttf") format("ttf");
}
`
    return fontFaceString
}

export function getShortUploadedFontFamily(font: string) {
    return `wf_${/^wfont_[0-9a-f]{6}_([0-9a-f]{25})[0-9a-f]{7}/.exec(font)[1]}`
}

export function getMetadata(fontNames: string[]) {
    return fontNames.map(fontName => getFontsMetaData()[fontName]).filter(Boolean)
}

export function getFontFamilyByStyleId(generalThemeData: ThemeData, stylesId: string) {
    const fontStyles = generalThemeData.font
    const fontIndex = parseInt(stylesId.substring(stylesId.indexOf('_') + 1), 10)
    const fontStyleString = fontStyles[fontIndex]
    let fontFamily = ''
    if (fontStyleString) {
        fontFamily = parseFontStr(fontStyleString).family.toLowerCase()
    }
    return fontFamily
}

export function getUploadedFontValue(fontId: string) {
    const fontName = `${LONG_UPLOADED_FONT_PREFIX + fontId}`
    const fontShortName = getShortUploadedFontFamily(fontName)
    return [fontName, fontShortName].join(',')
}

export function getWixStoredFontsCssUrls(serviceTopology: ServiceTopology, documentType: string) {
    const fontUrls = getWixStaticsFontsLinks(serviceTopology, documentType)
    return Object.values(fontUrls)
}

export function getCssUrls(documentType: string, serviceTopology: ServiceTopology) {
    const googleFonts = getFontsServiceUrlWithParams(fontsMetaData, documentType, POSSIBLE_CHARACTERS_SETS)
    const wixStaticsFontsLinks = getWixStaticsFontsLinks(serviceTopology, documentType)
    return {googleFonts, ...wixStaticsFontsLinks}
}
