import _ from 'lodash'
import metaDataUtils from '../metaDataUtils'
import {getMeasureMapValue} from '../../utils/utils'
import type {CompRef} from '@wix/document-services-types'

export default {
    styleCanBeApplied: false,
    layoutLimits(ps, compPointer) {
        return _.pick(getMeasureMapValue(ps, compPointer as CompRef, 'custom', {}), ['minHeight', 'minWidth'])
    },
    containableByStructure: metaDataUtils.notContainableByPopup,
    mobileConversionConfig: {
        desktopOnly: true
    }
}
