import {
    CreateExtArgs,
    CreateExtensionArgument,
    DAL,
    DalValue,
    DmApis,
    DocumentDataTypes,
    Extension,
    ExtensionAPI,
    pointerUtils,
    ValidateValue,
    ValidatorMap
} from '@wix/document-manager-core'
import {ReportableError} from '@wix/document-manager-utils'
import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import {DATA_TYPES, MULTILINGUAL_TYPES} from '../constants/constants'
import {getLanguageFromKey, getTranslationInfoFromKey, getTranslationItemKey} from '../utils/translationUtils'
import type {RelationshipsAPI} from './relationships'
import {pointerType as rendererModelPointerType} from './rendererModel'

const {getPointer, getInnerPointer} = pointerUtils
const ALL_TRANSLATIONS = 'ALL_TRANSLATIONS'
const NO_MATCH: string[] = []
const TRANSLATION_ITEM = 'TRANSLATION_ITEM'
const TRANSLATION_LANGUAGE = 'TRANSLATION_LANGUAGE'

const translationDataItem = (pageId: string, lang: string, itemId: string) =>
    getPointer(getTranslationItemKey(lang, itemId), MULTILINGUAL_TYPES.multilingualTranslations, {pageId})

const createPointersMethods = () => {
    const multilingualInfo = () =>
        getPointer('sitePropertiesInfo', rendererModelPointerType, {innerPath: ['multilingualInfo']})
    const getMultilingualInnerPointer = (key: string) => getInnerPointer(multilingualInfo(), [key])
    const originalLanguage = () => getMultilingualInnerPointer('originalLanguage')
    const translationLanguages = () => getMultilingualInnerPointer('translationLanguages')
    const getTranslation = (pointer: Pointer, language: string) =>
        translationDataItem(pointer.pageId!, language, pointer.id)

    const getOriginal = (pointer: Pointer) => {
        if (pointer.type === MULTILINGUAL_TYPES.multilingualTranslations) {
            const [, id] = getTranslationInfoFromKey(pointer.id)
            return getPointer(id, DATA_TYPES.data, pointer)
        }
        return pointer
    }

    return {
        multilingual: {
            originalLanguage,
            translationLanguages,
            multilingualInfo
        },
        multilingualTranslations: {
            translationDataItem,
            getTranslation,
            getOriginal
        }
    }
}

const getTranslationsById = (dal: DAL, dataId: string) => {
    const itemTranslationKey = dal.queryFilterGetters[TRANSLATION_ITEM](dataId)
    return dal.getIndexPointers(itemTranslationKey, MULTILINGUAL_TYPES.multilingualTranslations)
}

const createExtensionAPI = ({dal, extensionAPI}: CreateExtArgs): MultilingualExtensionAPI => {
    const {relationships} = extensionAPI as RelationshipsAPI

    const removeTranslationByCompRef = (componentPointer: Pointer, languageCode?: string) => {
        const component = dal.get(componentPointer)
        if (!component?.dataQuery) {
            return
        }
        const dataItemId = relationships.getIdFromRef(component.dataQuery)
        if (!dal.has(getPointer(dataItemId, DATA_TYPES.data))) {
            return
        }
        const itemsToRemove = languageCode
            ? [translationDataItem(component.metaData.pageId, languageCode, dataItemId)]
            : getTranslationsById(dal, dataItemId)

        itemsToRemove.forEach(pointer => dal.remove(pointer))
    }

    const getLanguagesForItem = (dataId: string) =>
        getTranslationsById(dal, dataId).map(pointer => getLanguageFromKey(pointer.id))

    const getAllTranslationLanguages = () => {
        return dal.getIndexKeys(TRANSLATION_LANGUAGE)
    }

    const removeLanguage = (languageCode: string) => {
        const languageFilter = dal.queryFilterGetters[TRANSLATION_LANGUAGE](languageCode)
        const translationIds = dal.queryKeys(MULTILINGUAL_TYPES.multilingualTranslations, languageFilter)
        _.forEach(translationIds, (key: string) => {
            dal.remove(getPointer(key, MULTILINGUAL_TYPES.multilingualTranslations))
        })
    }

    return {
        multilingualTranslations: {
            removeAll: () => getAllTranslationLanguages().forEach(removeLanguage),
            remove: removeLanguage,
            removeByComponentRef: removeTranslationByCompRef,
            getTranslationsById: (dataId: string) => getTranslationsById(dal, dataId),
            getLanguagesForItem,
            getAllTranslationLanguages
        }
    }
}

export interface MultilingualTranslationsAPI extends ExtensionAPI {
    removeAll(): void
    remove(languageCode: string): void
    removeByComponentRef(pointer: Pointer, languageCode?: string): void
    getTranslationsById(dataId: string): Pointer[]
    getLanguagesForItem(dataId: string): string[]
    getAllTranslationLanguages(): string[]
}

export interface MultilingualExtensionAPI extends ExtensionAPI {
    multilingualTranslations: MultilingualTranslationsAPI
}

const getDocumentDataTypes = (): DocumentDataTypes => _.mapValues(MULTILINGUAL_TYPES, () => ({hasSignature: true}))

const createExtension = ({logger}: CreateExtensionArgument): Extension => {
    const createPostSetOperation =
        ({dal}: DmApis) =>
        ({type: namespace, id}: Pointer, value: DalValue) => {
            if (!value && namespace === 'data') {
                _.forEach(getTranslationsById(dal, id), pointer => dal.remove(pointer))
            }
        }

    const createFilters = () => ({
        [TRANSLATION_ITEM]: (namespace: string, value: any): string[] => {
            if (namespace !== MULTILINGUAL_TYPES.multilingualTranslations || !value) {
                return NO_MATCH
            }
            if (value.id) {
                return [value.id, ALL_TRANSLATIONS]
            }

            // There should never be a translation without id
            // This is how little faith i have in humanity
            logger.captureError(
                new ReportableError({
                    errorType: 'translationWithoutId',
                    message: 'indexed ML translation without id',
                    extras: {
                        value
                    }
                })
            )

            return [ALL_TRANSLATIONS]
        },
        [TRANSLATION_LANGUAGE]: (namespace: string, value: any, id: string): string[] => {
            if (namespace !== MULTILINGUAL_TYPES.multilingualTranslations || !value) {
                return NO_MATCH
            }

            return [getLanguageFromKey(id)]
        }
    })

    const createMultilingualReferenceValidators = ({dal}: DmApis): Record<string, ValidateValue> => {
        return {
            validateTranslationToData: (pointer: Pointer, value: DalValue) => {
                const namespace = pointer.type
                if (!value || namespace !== MULTILINGUAL_TYPES.multilingualTranslations) {
                    return
                }

                const originalType = dal.get(getPointer(value.id, DATA_TYPES.data, {innerPath: ['type']}))
                if (value.type !== originalType) {
                    const [lang, id] = getTranslationInfoFromKey(pointer.id)
                    return [
                        {
                            shouldFail: true,
                            type: 'translationMismatchError',
                            message: `${originalType} and ${value.type} mismatch types.`,
                            extras: {
                                pageId: value.metaData?.pageId,
                                lang,
                                id
                            }
                        }
                    ]
                }
            },
            validateDataToTranslation: (pointer: Pointer, value: DalValue) => {
                const namespace = pointer.type
                if (!value || namespace !== DATA_TYPES.data) {
                    return
                }

                const translations = getTranslationsById(dal, value.id)
                for (const ptr of translations) {
                    const translation = dal.get(ptr)
                    if (translation) {
                        if (translation.type !== value.type) {
                            const [lang, id] = getTranslationInfoFromKey(pointer.id)
                            return [
                                {
                                    shouldFail: true,
                                    type: 'existingTranslationMismatchError',
                                    message: `${value.type} and ${translation.type} mismatch types.`,
                                    extras: {
                                        pageId: value.metaData?.pageId,
                                        lang,
                                        id
                                    }
                                }
                            ]
                        }
                        // Single translation is enough
                        return
                    }
                }
            }
        }
    }

    const createValidator = (apis: DmApis) => {
        const validators: ValidatorMap = {}

        const referenceValidators = createMultilingualReferenceValidators(apis)
        _.assign(validators, referenceValidators)

        return validators
    }

    return {
        name: 'multilingual',
        createPointersMethods,
        getDocumentDataTypes,
        createExtensionAPI,
        createFilters,
        createPostSetOperation,
        createValidator
    }
}

export {createExtension}
