import {CreateExtArgs, Extension, ExtensionAPI, pointerUtils} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import {APP_WIDGET_TYPE, CONTROLLER_DATA, CONTROLLER_TYPE} from '../constants/constants'
import {getReferredCompId} from '../utils/refStructureUtils'
import {getComponentType} from '../utils/dalUtils'
import type {DataModelExtensionAPI} from './dataModel/dataModel'
import type {TPAExtensionAPI} from './tpa'
import {deepClone} from '@wix/wix-immutable-proxy'

export interface AppControllerAPI extends ExtensionAPI {
    appController: {
        getNicknameFromStageData(compRef: Pointer): string
        verifyController(controllerRef: Pointer): void
        isOOIController(componentType: string): boolean
        getControllerStageData(controllerId: string, controllerType: string, applicationId: string): Record<string, any>
        getState(controllerId: string): Record<string, any>
        getControllerTypeAndApplicationId(controllerRef: Pointer): Record<string, string>
        getControllerStageDataByControllerRef(controllerRef: Pointer): Record<string, any>
        getControllerStageDataByStateRefAndType(
            stateRef: Pointer,
            controllerType: string,
            appDefId: string
        ): Record<string, any>
        hasAppManifest(applicationId: string): boolean
    }
}

const createExtension = (): Extension => {
    const createExtensionAPI = ({dal, pointers, extensionAPI}: CreateExtArgs): AppControllerAPI => {
        const hasAppManifest = (applicationId: string) => {
            const appManifestPointer = pointers.platform.getAppManifestPointer(applicationId)
            return appManifestPointer ? dal.has(appManifestPointer) : false
        }

        const getDisabledActionsConfig = () => {
            const behavior = {
                duplicatable: false,
                canReparent: false,
                rotatable: false,
                pinnable: false,
                resizable: false,
                toggleShowOnAllPagesEnabled: false
            }
            return {
                behavior,
                connections: {
                    '*': {
                        behavior
                    }
                }
            }
        }

        const getStatePointer = (controllerId: string) => {
            const appStatePointer = pointers.platform.getAppStatePointer()

            return pointerUtils.getInnerPointer(appStatePointer, [controllerId])
        }

        const getState = (controllerId: string) => {
            const statePointer = getStatePointer(controllerId)

            const controllerState = dal.get(statePointer)

            if (controllerState) {
                return controllerState
            }

            const templateControllerId = getReferredCompId(controllerId)

            const templateControllerStatePointer = getStatePointer(templateControllerId)

            return templateControllerId
                ? dal.get(templateControllerStatePointer) || CONTROLLER_DATA.DEFAULT_STATE
                : CONTROLLER_DATA.DEFAULT_STATE
        }

        const isOOIController = (componentType: string) => {
            const {tpa} = extensionAPI as TPAExtensionAPI
            return tpa.isTpaByCompType(componentType)
        }
        const verifyController = (controllerRef: Pointer) => {
            const componentType = getComponentType(dal, controllerRef)
            if (
                componentType !== CONTROLLER_TYPE &&
                componentType !== APP_WIDGET_TYPE &&
                !isOOIController(componentType)
            ) {
                throw new Error('controllerRef component type is invalid - should be an AppController or AppWidget')
            }
        }

        const getControllerDataItem = (controllerRef: Pointer) => {
            verifyController(controllerRef)
            const {dataModel} = extensionAPI as DataModelExtensionAPI
            return dataModel.components.getItem(controllerRef, 'data')
        }
        const getControllerTypeAndApplicationId = (controllerRef: Pointer) => {
            const {applicationId, controllerType, widgetId, appDefinitionId} =
                getControllerDataItem(controllerRef) || {}
            const ooiController = isOOIController(getComponentType(dal, controllerRef))
            return {
                controllerType: ooiController ? widgetId : controllerType,
                applicationId: ooiController ? appDefinitionId : applicationId
            }
        }

        const getControllerStageDataByStateAndType = (
            controllerState: string,
            controllerType: string,
            applicationId: string
        ) => {
            const applicationHasAppManifest = hasAppManifest(applicationId)
            if (!applicationHasAppManifest) {
                return getDisabledActionsConfig()
            }

            const setDefaultConnectionIntoConnections = (state: Record<string, any>) => {
                const stateConnections = _.get(state, CONTROLLER_DATA.CONNECTIONS)
                const stateDefaultConnection = _.get(stateConnections, CONTROLLER_DATA.WILDCARD_ROLE)
                const setDefaultConnection = (connection: Record<string, any>) =>
                    _.defaultsDeep({}, connection, stateDefaultConnection)
                if (stateDefaultConnection) {
                    _.set(state, CONTROLLER_DATA.CONNECTIONS, _.mapValues(stateConnections, setDefaultConnection))
                }
                return state
            }

            const nonDefaultStateWithDefaults = (state: Record<string, any>, defaultState: Record<string, any>) => {
                const stateConnections = _.get(state, CONTROLLER_DATA.CONNECTIONS)
                const defaultStateConnections = _.get(defaultState, CONTROLLER_DATA.CONNECTIONS)
                const setDefaultsIntoOverrides = (connection: Record<string, any>, connectionKey: string) =>
                    _.defaultsDeep({}, connection, defaultStateConnections[connectionKey])
                if (stateConnections && defaultStateConnections) {
                    const stateConnectionsWithDefaults = _.mapValues(stateConnections, setDefaultsIntoOverrides)
                    _.set(
                        state,
                        CONTROLLER_DATA.CONNECTIONS,
                        _.defaultsDeep(stateConnectionsWithDefaults, defaultStateConnections)
                    )
                }
                return _.defaultsDeep({}, state, defaultState)
            }

            const controllerStageDataPointer = pointers.platform.getControllerStageDataPointer(
                applicationId,
                controllerType,
                controllerState
            )
            const stateStageData = setDefaultConnectionIntoConnections(deepClone(dal.get(controllerStageDataPointer)))

            if (controllerState === CONTROLLER_DATA.DEFAULT_STATE) {
                return stateStageData
            }

            const defaultStageDataPointer = pointers.platform.getControllerStageDataPointer(
                applicationId,
                controllerType,
                CONTROLLER_DATA.DEFAULT_STATE
            )
            const defaultStageData = setDefaultConnectionIntoConnections(deepClone(dal.get(defaultStageDataPointer)))

            return nonDefaultStateWithDefaults(stateStageData, defaultStageData)
        }

        const getControllerStageData = (controllerId: string, controllerType: string, applicationId: string) => {
            const state = getState(controllerId)

            return getControllerStageDataByStateAndType(state, controllerType, applicationId)
        }

        const getControllerStageDataByStateRefAndType = (
            stateRef: Pointer,
            controllerType: string,
            applicationId: string
        ) => {
            const state = dal.get(stateRef)

            return getControllerStageDataByStateAndType(state, controllerType, applicationId)
        }

        const getControllerStageDataByControllerRef = (controllerRef: Pointer) => {
            if (!controllerRef) {
                return
            }
            const {applicationId, controllerType} = getControllerTypeAndApplicationId(controllerRef)
            return getControllerStageData(controllerRef.id, controllerType, applicationId)
        }

        const getNicknameFromStageData = (compRef: Pointer) => {
            const nickname = _.get(getControllerStageDataByControllerRef(compRef), ['nickname'])
            return _.camelCase(nickname)
        }

        return {
            appController: {
                getNicknameFromStageData,
                verifyController,
                isOOIController,
                getControllerStageData,
                getState,
                getControllerTypeAndApplicationId,
                getControllerStageDataByControllerRef,
                getControllerStageDataByStateRefAndType,
                hasAppManifest
            }
        }
    }

    return {
        name: 'appController',
        dependencies: new Set(['dataModel']),
        createExtensionAPI
    }
}

export {createExtension}
