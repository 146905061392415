const STATE_BOX = 'wysiwyg.viewer.components.StateBox'

export default {
    mobileConversionConfig: {
        nestOverlayingSiblings: false,
        stackLayout: true,
        structuralItem: true
    },
    allowedParentTypes: [STATE_BOX]
}
