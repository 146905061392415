import _ from 'lodash'
import dsUtils from '../utils/utils'
import appStudioDataModel from './appStudioDataModel'
import type {
    BlocksDashboard,
    BlocksDashboardData,
    DashboardPointer,
    Callback,
    Callback1,
    PS,
    CompRef
} from '@wix/document-services-types'
import page from '../page/page'
import dashboardConfig from './dashboardConfigs/dashboardConfig'
import component from '../component/component'
import features from '../features/features'
import {dashboardInitialCode} from './dashboardConfigs/initialDashboardCode'
import fileSystemAPI from '../wixCode/services/fileSystemAPI'
import {getShallowAppStudioData} from './getAppStudioData'
import {getDataPointerById, getShallowDataById} from './blocksDataModel'
import type {DataModelExtensionAPI} from '@wix/document-manager-extensions'

const DASHBOARD_TYPE = 'DashboardDescriptor'
const DASHBOARD_CONTROLLER_TYPE = 'dashboard-controller'

function getDashboardInfo(ps: PS, dashboardId: string): BlocksDashboard {
    const dashboardData: BlocksDashboardData = getShallowDataById(ps, dashboardId)

    return {
        id: dashboardData.id,
        rootCompId: dashboardData.rootCompId,
        pointer: getDataPointerById(ps, dashboardId)
    }
}

function getAllDashboards(ps: PS): BlocksDashboard[] {
    const dashboardIds = getShallowAppStudioData(ps).dashboards ?? []

    return dashboardIds.map(dashboardId => getDashboardInfo(ps, dashboardId))
}

function createBlankDashboardData(ps: PS, dashboardPointer: DashboardPointer, pageRef: CompRef): BlocksDashboardData {
    const {dataModel} = ps.extensionAPI as DataModelExtensionAPI
    const dashboardData = dataModel.createDataItemByType<BlocksDashboardData>(DASHBOARD_TYPE)
    dashboardData.id = dashboardPointer.id
    dashboardData.rootCompId = `#${pageRef.id}`

    return dashboardData
}

function addDashboardToAppStudioModel(ps: PS, dashboardData: BlocksDashboardData) {
    const appStudioData = appStudioDataModel.getAppStudioData(ps)
    if (!appStudioData.dashboards) {
        appStudioData.dashboards = []
    }
    appStudioData.dashboards.push(dashboardData)
    appStudioDataModel.updateAppStudioOnMasterPage(ps, appStudioData)
}

function createDashboardDescriptor(ps: PS, dashboardPointer: DashboardPointer, pageRef: CompRef) {
    const dashboardData = createBlankDashboardData(ps, dashboardPointer, pageRef)
    addDashboardToAppStudioModel(ps, dashboardData)
}

function removeDashboardDescriptor(ps: PS, dashboardPointer: DashboardPointer) {
    const appStudioData = appStudioDataModel.getAppStudioData(ps)
    const dashboards = appStudioData?.dashboards ?? []

    appStudioData.dashboards = dashboards.filter(
        dashboardData => dsUtils.stripHashIfExists(dashboardData.id) !== dashboardPointer.id
    )
    appStudioDataModel.updateAppStudioOnMasterPage(ps, appStudioData)
}

function setDashboardIntent(ps: PS, pageRef: CompRef) {
    features.updateFeatureData(ps, pageRef, 'intent', {intent: 'dashboardPage', type: 'Intent'})
}

function createDashboardPage(ps: PS, dashboardName: string, pageRef: CompRef) {
    page.add(ps, pageRef, dashboardName, dashboardConfig.getPageStructure())
    setDashboardIntent(ps, pageRef)
    const stageContainerRef = component.getComponentToAddRef(ps, pageRef)

    const dashboardStructure = dashboardConfig.getDashboardStructure()
    const wrappedDashboardStructure = _.defaultsDeep(
        {components: [dashboardStructure]},
        dashboardConfig.appWidgetStructure
    )

    component.add(ps, stageContainerRef, pageRef, wrappedDashboardStructure)

    setInitialAppWidgetData(ps, pageRef)
}

function setInitialAppWidgetData(ps: PS, pageRef: CompRef) {
    const rootAppWidgetRef = appStudioDataModel.getRootAppWidgetByPage(ps, pageRef)

    const dashboardId = pageRef.id

    const settings = {
        dashboardId
    }

    const data = {
        controllerType: DASHBOARD_CONTROLLER_TYPE,
        settings: JSON.stringify(settings)
    }

    component.data.update(ps, rootAppWidgetRef, data, true)
}

async function createDashboard(ps: PS, dashboardPointer: DashboardPointer, callback?: Callback1<DashboardPointer>) {
    const dashboardName = 'Dashboard'
    const newPageRef = page.getPageIdToAdd(ps)
    createDashboardDescriptor(ps, dashboardPointer, newPageRef)
    createDashboardPage(ps, dashboardName, newPageRef)
    await setInitialDashboardCode(ps, newPageRef)

    if (callback) {
        callback(dashboardPointer)
    }
}

// TODO consider extracting to wixCode
async function setPageCode(ps: PS, pageId: string, code: string) {
    const fileLocation = fileSystemAPI.getRoots().pages.location
    const fileName = `${fileLocation}${pageId}.js`
    const fileDescriptor = fileSystemAPI.getVirtualDescriptor(ps, fileName, false)
    await fileSystemAPI.writeFile(ps, fileDescriptor, code)
}

async function setInitialDashboardCode(ps: PS, pageRef: CompRef) {
    await setPageCode(ps, pageRef.id, dashboardInitialCode)
}

function displayDashboard(ps: PS, dashboardPointer: DashboardPointer, callback: Callback) {
    const rootCompId = appStudioDataModel.getRootCompIdByPointer(ps, dashboardPointer)
    page.navigateTo(ps, rootCompId, callback)
}

function removeDashboard(ps: PS, dashboardPointer: DashboardPointer, callback?: Callback1<PS>) {
    const rootCompId = appStudioDataModel.getRootCompIdByPointer(ps, dashboardPointer)
    removeDashboardDescriptor(ps, dashboardPointer)
    page.remove(ps, rootCompId, callback)
}

function getDashboardByRootCompId(ps: PS, rootCompId: string) {
    const dashboard = getAllDashboards(ps).find(({rootCompId: dashboardId}) => _.trim(dashboardId, '#') === rootCompId)
    return dashboard?.pointer
}

export default {
    createDashboard,
    displayDashboard,
    removeDashboard,
    getAllDashboards,
    getDashboardByRootCompId
}
