import _ from 'lodash'
import schemaUtils from './schemaUtils'
import {ReportableError} from '@wix/document-manager-utils'

function isUndoableFile(filePath: string) {
    return schemaUtils.isSchemaFile(filePath)
}

function assembleUndoableFile(filePath: string, loadedContent, modifiedContent, undoableModifiedContent) {
    if (_.isUndefined(loadedContent)) {
        throw new ReportableError({
            message: `Cannot read undoable file which has not been loaded [${filePath}]`,
            errorType: 'undoableFileNotLoaded',
            tags: {}
        })
    }
    if (schemaUtils.isSchemaFile(filePath)) {
        return schemaUtils.assembleSchema(loadedContent, modifiedContent, undoableModifiedContent)
    }
}
function getUndoableContentByPath(undoableContentByIdMap, undoablePathIdMap) {
    return _.mapValues(undoablePathIdMap, id => undoableContentByIdMap[id])
}

export default {
    isUndoableFile,
    assembleUndoableFile,
    getUndoableContentByPath
}
