import type {PublicMethodUtils} from '@wix/document-services-types'
import {account} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            account: {
                hasSites: publicMethodUtils.defineGetter(account.hasSites),
                isCoBranded: publicMethodUtils.defineGetter(account.isCoBranded),
                canUserUpgrade: publicMethodUtils.defineGetter(account.canUserUpgrade),
                getCoBrandedType: publicMethodUtils.defineGetter(account.getCoBrandedType)
            }
        }
    }
}
