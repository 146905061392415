import _ from 'lodash'
import appStudioPresets from './appStudioPresets'
import appStudioDataModel from './appStudioDataModel'
import componentDetectorAPI from '../componentDetectorAPI/componentDetectorAPI'
import type {Pointer, PS} from '@wix/document-services-types'
import features from '../features/features'
import variants from '../variants/variants'
import constants from '../constants/constants'
import dsUtils from '../utils/utils'
const getOriginalPageIdFromIdMap = (ps: PS, newPageId: string, oldToNewIdMap) => {
    const isPage = (compId: string) => {
        const compRef = componentDetectorAPI.getComponentById(ps, compId)
        return compRef && ps.pointers.components.isPage(compRef)
    }
    return _.findKey(oldToNewIdMap, (newId, oldId) => newId === newPageId && isPage(oldId))
}

const createPresetsForDuplicatedWidget = (ps: PS, pagePointer: Pointer, pageDefinition, optionalCustomId, mappers) => {
    const originalPageId = getOriginalPageIdFromIdMap(ps, pagePointer.id, mappers?.oldToNewIdMap)
    const originalWidget = originalPageId && appStudioDataModel.findWidgetByPageId(ps, originalPageId)

    if (originalWidget) {
        const originalPresets = appStudioPresets.getWidgetPresets(ps, originalWidget.pointer)
        const newPresets = _.map(originalPresets, originalPreset => {
            const originalVariantId = appStudioPresets.getPresetVariantId(ps, originalPreset.pointer)
            const newVariantId = mappers.oldToNewIdMap[originalVariantId]

            const newPresetPointer = appStudioDataModel.getNewDataItemPointer(ps)
            const newPresetData = appStudioPresets.createDuplicatedPresetData(
                ps,
                newPresetPointer,
                originalPreset.pointer,
                originalPreset.name,
                newVariantId
            )
            appStudioDataModel.setData(ps, newPresetPointer, newPresetData)

            return newPresetPointer
        })
        if (!_.isEmpty(newPresets)) {
            const widgetPointer = _.get(appStudioDataModel.findWidgetByPageId(ps, pagePointer.id), 'pointer')
            appStudioPresets.addPresetsToWidgetDataItem(ps, newPresets, widgetPointer)
        }
    }
}
const setScopedPresetsForInnerWidget = (
    ps: PS,
    compToAddPointer: Pointer,
    clonedSerializedComp,
    customId,
    mappers,
    containerPointer
) => {
    const presetIdToDisplay = clonedSerializedComp?.custom?.presetIdToDisplay
    if (!presetIdToDisplay) {
        return
    }

    const destinationPageId = ps.pointers.full.components.getPageOfComponent(containerPointer).id
    const destinationWidgetPointer =
        destinationPageId && appStudioDataModel.findWidgetByPageId(ps, destinationPageId).pointer
    const destinationWidgetPresets = appStudioPresets.getWidgetPresets(ps, destinationWidgetPointer)

    destinationWidgetPresets
        .map(({pointer}) =>
            variants.getPointerWithVariants(
                ps,
                compToAddPointer,
                appStudioPresets.getPresetVariantPointer(ps, pointer, destinationWidgetPointer)
            )
        )
        .forEach(compVariantPointer => {
            features.updateFeatureData(ps, compVariantPointer, 'presets', {
                type: constants.PRESETS.PRESET_DATA_TYPE,
                style: dsUtils.stripHashIfExists(presetIdToDisplay),
                layout: dsUtils.stripHashIfExists(presetIdToDisplay)
            })
        })
}

export default {
    createPresetsForDuplicatedWidget,
    setScopedPresetsForInnerWidget
}
