import linkRenderer from './linkRenderer'
import {warmupMenuUtils} from '../../warmupUtils/index'
function getSiteMenuWithoutRenderedLinks(siteData, includeHiddenItems: boolean) {
    return warmupMenuUtils.getSiteMenu(siteData, true, {}, includeHiddenItems, linkRenderer)
}

export default {
    getDropDownWidthIfOk: warmupMenuUtils.getDropDownWidthIfOk,
    getMaxWidth: warmupMenuUtils.getMaxWidth,
    getMinWidth: warmupMenuUtils.getMinWidth,
    removeAllElementsWithWidthZero: warmupMenuUtils.removeAllElementsWithWidthZero,
    getSiteMenuWithoutRenderedLinks,
    getActiveAnchorInPage: warmupMenuUtils.getActiveAnchorInPage,
    shouldHighlightAnchorInPage: warmupMenuUtils.shouldHighlightAnchorInPage,
    // @ts-ignore
    convertOldMenuToNewMenu: warmupMenuUtils.convertOldMenuToNewMenu
}
