import {computeHidePage} from './installedTpaAppsUtils'
import {TPA_CONSTANTS} from '@wix/document-manager-utils'
import type {
    EditorClientSpecMapEntry,
    Pointer,
    TPAWidget,
    Layout,
    CompStructure,
    StyleRefOrStyleRefs
} from '@wix/document-services-types'
import type {PageExtensionAPI, PartialPageDefinition, PageAPI} from '../page'
import type {AddPageWithTpaSectionOptions} from '../tpa'
import type {DefaultDefinitionsAPI} from '../defaultDefinitions/defaultDefinitions'
import {getDefaultPageStyle} from '../defaultDefinitions/defaultBlankPageDefinition'
import type {ExtensionAPI} from '@wix/document-manager-core'
import type {DataModelExtensionAPI} from '../dataModel/dataModel'
import type {ThemeExtAPI} from '../theme/theme'
import {DATA_TYPES, MASTER_PAGE_ID} from '../../constants/constants'
import _ from 'lodash'
interface PageStructureGenerationOptions {
    pageUriSEO: string
    hidePage?: boolean
    indexable?: boolean
    tpaPageId: string | undefined
    managingAppDefId?: string
    requireLogin?: boolean
    title: string | undefined
    landingPageParams: {
        desktop: boolean
        mobile: boolean
    }
    skin?: string
}
const getPageUriSEO = (extensionAPI: ExtensionAPI, pageId: string, name: string) => {
    const {page} = extensionAPI as PageExtensionAPI
    const invalidUrlCharacters = /[^A-Za-z0-9-]/g
    return page.getValidPageUriSEO(pageId, name.replace(invalidUrlCharacters, '-').toLowerCase() || 'blank')
}
export const getPageBackgrounds = (extensionAPI: ExtensionAPI, sectionData: TPAWidget) => {
    const {getBlankBackground} = extensionAPI.defaultDefinitions as DefaultDefinitionsAPI
    const {page} = extensionAPI as PageExtensionAPI
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    if (_.get(sectionData, ['appPage', 'hideFromMenu'])) {
        return getBlankBackground()
    }
    const pageId = page.getMainPageId()
    if ((extensionAPI.page as PageAPI).hasPageBeenLoaded(pageId)) {
        const pageData = dataModel.getItem(pageId, 'data', page.getMainPageId())
        return _.get(pageData, ['pageBackgrounds'])
    }
    return getBlankBackground()
}

const getSectionLayout = (layout?: Layout) => {
    layout = layout || ({} as Layout)
    return {
        x: layout.x || 0,
        y: layout.y || 0,
        width: layout.width || 980,
        height: layout.height || 500
    }
}

const addResponsiveStyleParam = (styleRef: Record<string, any>) => {
    return {
        ...styleRef,
        style: {
            ...styleRef.style,
            properties: {
                ...styleRef?.style?.properties,
                param_boolean_responsive: 'true'
            },
            propertiesSource: {
                ...styleRef?.style?.propertiesSource,
                param_boolean_responsive: 'value'
            }
        }
    }
}

const getExpandedStyle = (extensionAPI: ExtensionAPI, componentType: string, styleId: string) => {
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const {theme} = extensionAPI as ThemeExtAPI
    theme.ensureDefaultStyleItemExists(componentType, styleId)
    const styleObject = dataModel.getItem(styleId, DATA_TYPES.theme, MASTER_PAGE_ID)
    if (styleObject.styleType === 'system') {
        return {
            ..._.omit(styleObject, 'id'),
            styleType: 'custom',
            type: 'ComponentStyle'
        }
    }
    return styleObject
}

export const getSectionStructure = (
    extensionAPI: ExtensionAPI,
    isResponsive: boolean,
    sectionPointer: Pointer,
    widgetId: string,
    appData: EditorClientSpecMapEntry,
    sectionData: TPAWidget,
    tpaSectionCompType: string,
    tpaSectionDataType: string,
    options: AddPageWithTpaSectionOptions = {}
) => {
    const sectionDefinition: CompStructure = {
        componentType: options.tpaSectionCompType ?? tpaSectionCompType ?? TPA_CONSTANTS.COMP_TYPES.TPA_SECTION,
        type: 'Container',
        id: sectionPointer.id,
        styleId: options.styleId ?? TPA_CONSTANTS.STYLE.TPA_SECTION,
        skin: TPA_CONSTANTS.SKINS.TPA_SECTION,
        layout: getSectionLayout(options?.layout),
        data: {
            appDefinitionId: appData.appDefinitionId,
            type: options.tpaSectionDataType ?? tpaSectionDataType ?? TPA_CONSTANTS.DATA_TYPE.TPA_SECTION,
            applicationId: `${appData.applicationId}`,
            metaData: {
                isPreset: true,
                schemaVersion: '1.0',
                isHidden: false
            },
            widgetId
        }
    }
    if (isResponsive) {
        sectionDefinition.style = addResponsiveStyleParam(
            getExpandedStyle(extensionAPI, sectionDefinition.componentType, sectionDefinition.styleId!)
        ) as StyleRefOrStyleRefs
        delete sectionDefinition.styleId
    }
    if (_.get(sectionData, ['appPage', 'fullPage']) || _.get(sectionData, ['componentFields', 'fullPageDesktopOnly'])) {
        const docked = {top: {px: 0}, right: {px: 0}, bottom: {px: 0}, left: {px: 0}}
        _.assign(sectionDefinition.layout, {fixedPosition: true, docked})
    } else if (sectionData!.canBeStretched && sectionData!.shouldBeStretchedByDefault) {
        _.assign(sectionDefinition.layout, {docked: {left: {vw: 0}, right: {vw: 0}}})
    }

    if (options.shouldAddMobileStructure) {
        //DEFAULT LAYOUT FOR MOBILE SINCE NO MOBILE ALGO WHEN UPDATING PUBLIC REVISION
        sectionDefinition.mobileStructure = {
            layout: {
                fixedPosition: false,
                scale: 1,
                rotationInDegrees: 0,
                height: 5,
                width: 320,
                x: 0,
                y: 0
            }
        }
    }
    return sectionDefinition
}

const getSectionAppPageFrom = (
    extensionAPI: ExtensionAPI,
    pageId: string,
    appData: EditorClientSpecMapEntry,
    sectionData: TPAWidget,
    isMultiSection: boolean,
    title?: string
) => {
    let data: any = {
        name: appData.appDefinitionName
    }
    const widgetName = title || sectionData.appPage.name

    if (isMultiSection) {
        data.pageUriSEO = getPageUriSEO(extensionAPI, pageId, widgetName)
        return _.assign(data, sectionData.appPage)
    }
    if (sectionData?.appPage?.name && !sectionData.appPage.hidden) {
        data = _.clone(sectionData.appPage)
        data.name =
            title ||
            // sectionsTranslatedPageTitlesCache.getTitle(appData.appDefinitionId, widget.widgetId) ||
            data.name
        data.pageUriSEO = getPageUriSEO(extensionAPI, pageId, widgetName)
        data = _.merge(data, sectionData)
    }
    return data
}

export const getSectionPageStructure = (
    extensionAPI: ExtensionAPI,
    isResponsive: boolean,
    sectionData: TPAWidget,
    applicationId: number,
    appDefinitionId: string,
    {
        pageUriSEO,
        hidePage,
        indexable,
        tpaPageId,
        managingAppDefId,
        landingPageParams,
        requireLogin,
        title,
        skin
    }: PageStructureGenerationOptions
) => {
    const appId = applicationId
    const defSize = {
        w: 980,
        h: 500
    }
    const defaultSkin = isResponsive ? 'wysiwyg.viewer.skins.page.TransparentPageSkin' : 'skins.core.InlineSkin'
    let pageStructure: PartialPageDefinition = {
        componentType: 'mobile.core.components.Page',
        type: 'Page',
        styleId: 'p2',
        skin: skin ? skin : defaultSkin,
        layout: {
            x: 0,
            y: 0,
            width: defSize.w,
            height: defSize.h,
            anchors: []
        },
        data: {
            type: 'Page',
            metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false
            } as any,
            hideTitle: true,
            icon: '',
            descriptionSEO: '',
            metaKeywordsSEO: '',
            pageTitleSEO: '',
            pageUriSEO,
            hidePage: _.isBoolean(hidePage) ? hidePage : undefined,
            mobileHidePage: null,
            underConstruction: false,
            tpaApplicationId: appId,
            appDefinitionId,
            indexable: _.isBoolean(indexable) ? indexable : true,
            tpaPageId: tpaPageId as string,
            managingAppDefId,
            title,
            pageBackgrounds: getPageBackgrounds(extensionAPI, sectionData),
            isLandingPage: _.isBoolean(landingPageParams.desktop) ? landingPageParams.desktop : false,
            isMobileLandingPage: _.isBoolean(landingPageParams.mobile) ? landingPageParams.mobile : false
        },
        components: [],
        mobileComponents: []
    }
    if (requireLogin) {
        pageStructure!.data!.pageSecurity = {
            requireLogin: true,
            dialogLanguage: 'en'
        }
    }

    if (isResponsive) {
        pageStructure = _.omit(pageStructure, 'styleId')
        pageStructure.style = getDefaultPageStyle()
    }

    return pageStructure
}

export const generatePageAndTpaSectionStructure = (
    extensionAPI: ExtensionAPI,
    isResponsive: boolean,
    pageToAddPointer: Pointer,
    sectionPointer: Pointer,
    widgetId: string,
    csmEntry: EditorClientSpecMapEntry,
    isMultiSection: boolean,
    tpaSectionCompType: string,
    tpaSectionDataType: string,
    addOptions: AddPageWithTpaSectionOptions
) => {
    const pageId = pageToAddPointer.id
    const sectionData = _.find(csmEntry.widgets, {widgetId})

    const title = _.get(sectionData, ['title'], '')
    const appPageData = getSectionAppPageFrom(extensionAPI, pageId, csmEntry, sectionData!, isMultiSection, title)
    const options = {
        pageUriSEO: appPageData.pageUriSEO,
        hidePage: computeHidePage(sectionData!, addOptions.isHidden, isMultiSection),
        indexable: appPageData.indexable,
        tpaPageId: appPageData.id,
        managingAppDefId: addOptions.managingAppDefId,
        landingPageParams: {
            desktop: appPageData.fullPage || _.get(sectionData, ['componentFields', 'fullPageDesktopOnly']),
            mobile: appPageData.landingPageInMobile
        },
        requireLogin: addOptions.requireLogin,
        title: appPageData.name
    }
    let pageStructure = getSectionPageStructure(
        extensionAPI,
        isResponsive,
        sectionData!,
        csmEntry.applicationId,
        csmEntry.appDefinitionId,
        options
    )

    const sectionStructure = getSectionStructure(
        extensionAPI,
        isResponsive,
        sectionPointer,
        widgetId,
        csmEntry,
        sectionData!,
        tpaSectionCompType,
        tpaSectionDataType,
        addOptions
    )

    if (_.get(options, ['managingAppDefId'])) {
        pageStructure = _.defaultsDeep({}, pageStructure, {
            data: {managingAppDefId: options.managingAppDefId}
        })
    }
    return {
        pageStructure,
        sectionStructure
    }
}
