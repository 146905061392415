import _ from 'lodash'
import PropTypes from 'prop-types'
import systemStyle from './systemStyles.json'

const MINI_SITE_CONTAINER_ID_PREFIX = 'miniSiteContainer_'

const MINI_SITE_CLASS = 'mini-site-container-css-class'

const miniSiteSelector = `.${MINI_SITE_CLASS}`

const zeroMinWidth = 'min-width: 0px !important;'

const css = `
    ${miniSiteSelector} [id$="__more__"]: {
        position: absolute;
        visibility: hidden;
    }

    ${miniSiteSelector} footer {
        ${zeroMinWidth}
    }

    ${miniSiteSelector} header {
        ${zeroMinWidth}
        margin-top: 0px !important;
    }
`

const addCssNode = _.once((context, cssContent) => {
    const doc = context.document
    const node = doc.createElement('style')
    node.type = 'text/css'
    node.innerHTML = cssContent
    doc.head.appendChild(node)
})

const mapFromPairs = (collection: any, func: any) => _(collection).map(func).fromPairs().value()

const isPlainObject = (obj: any) => _.isObject(obj) && !_.isFunction(obj) && !_.isArrayLike(obj)

const extractRefs = (data: any) => {
    const refs = {}
    _.forEach(data, (v, k) => {
        if (isPlainObject(v) && k !== 'metaData') {
            refs[k] = v
        }
    })

    const newData = _.omit(data, Object.keys(refs))
    const newRefs = {}

    _.forEach(refs, (v, k) => {
        const newKey = _.uniqueId('dataItem-')
        newData[k] = `#${newKey}`
        newRefs[newKey] = refs[k]
    })

    return {refs: newRefs, data: newData}
}

const unResolveDocumentDataRefs = (docData: any) => {
    const newData = {}
    _.forEach(docData, (v, k) => {
        if (isPlainObject(v)) {
            const {refs, data} = extractRefs(v)
            newData[k] = data
            _.assign(newData, refs)
        } else {
            newData[k] = v
        }
    })
    return newData
}

const convertData = ({previewData, compClasses, themeData, containerId}: any) => {
    const {comps} = previewData
    const compIds = comps.map((comp: any) => comp.compFullStructure.id)

    const container = {
        id: containerId,
        componentType: 'wysiwyg.viewer.components.Group',
        layout: {
            x: 198,
            y: 88.5,
            fixedPosition: false,
            width: 0,
            height: 0,
            scale: 1,
            rotationInDegrees: 0
        },
        type: 'Container',
        components: compIds,
        skin: 'wysiwyg.viewer.components.GroupSkin',
        activeModes: {}
    }

    const structure = mapFromPairs(comps, (comp: any) => [comp.compFullStructure.id, comp.compFullStructure])
    structure[containerId] = container
    const componentStyles = mapFromPairs(comps, (comp: any) => [comp.styleId, comp.customStyle])
    const customComponentStyles = _.pickBy(componentStyles, style => !_.has(themeData, style.id))

    const baseDocData = unResolveDocumentDataRefs(previewData.data)
    const additionalDocData = _.mapKeys(previewData.additionalMatserPageData, 'id')
    const documentData = {
        ...baseDocData,
        ...additionalDocData
    }

    const theme_data = {
        ...systemStyle,
        ...themeData,
        ...customComponentStyles
    }

    const component_properties = {
        ...previewData.props,
        // Emulate the vertical menu fixer functionality
        // If this constant will not suffice for the mini sites, we might have to perform the actual
        // calculation that the fixer performs.
        // Specifically, the calculation in question is this line from
        // `@wix/wix-santa/packages/dataFixer/src/main/plugins/verticalMenuFixer.js`:
        // const newItemHeight = coreUtils.verticalMenuCalculations.getItemHeight(comp.layout.height, separator, menuItemsCount, currentSkinExports);
        verticalDefaultProp: {
            menuItemHeight: 49
        }
    }

    return {
        structure,
        data: {
            theme_data,
            document_data: documentData,
            component_properties
        },
        compClasses,
        rootCompIds: [containerId],
        rootStyleIds: [] as any[]
    }
}

const getElement = (id: string) => window.top?.document.getElementById(id)
const getHeight = (id: string) => getElement(id)?.offsetHeight
const getTop = (id: string) => getElement(id)?.offsetTop
const scrollHeight = (id: string) => getElement(id)?.scrollHeight

const replaceThemeColor = (color: string, categoryId: string, replaceColorFunction: (a: string, b: string) => any) => {
    const hexColor = replaceColorFunction(color, categoryId)

    if (hexColor?.wasReplaced) {
        return hexColor.value
    }

    return color
}

const adjustColorsInThemeData = ({original, replaceColorFunction, categoryId}: any) => {
    const theme = original.THEME_DATA
    const newColors = theme.color.map((color: any) => replaceThemeColor(color, categoryId, replaceColorFunction))

    return {
        ...original,
        THEME_DATA: {
            ...theme,
            color: newColors
        }
    }
}

const createMiniSitePreview = ({hostReact, themeData, serviceTopology, getViewerFragment}: any): any => {
    const containerId = _.uniqueId(MINI_SITE_CONTAINER_ID_PREFIX)
    addCssNode(window.top, css)

    class Preview extends hostReact.Component {
        constructor(props: any) {
            super(props)
            this.state = {
                tbFragment: null,
                ready: false
            }
        }

        buildProps(rawData: any) {
            const adjustedThemeData = adjustColorsInThemeData({
                original: themeData,
                replaceColorFunction: this.props.getColorToReplaceIfNeeded,
                categoryId: rawData.categoryId
            })

            const dataProps = convertData({
                previewData: rawData,
                compClasses: this.state.compClasses,
                themeData: adjustedThemeData,
                containerId
            })

            const compIds = rawData.comps.map((comp: any) => comp.compFullStructure.id)
            const onReady = () => {
                const container = getElement(containerId)
                if (!container) {
                    return
                }
                container.className += ` ${MINI_SITE_CLASS}`

                // performMenuLayouts(rawData, containerId)

                if (this.props.onSiteReady) {
                    this.props.onSiteReady(scrollHeight(containerId), {
                        top: mapFromPairs(compIds, (id: string) => [id, getTop(id)]),
                        height: mapFromPairs(compIds, (id: string) => [id, getHeight(id)])
                    })
                }
                this.setState({ready: true})
            }

            return {
                ...dataProps,
                extraEventsHandlers: this.props.extraEventsHandlers,
                rendererModel: {
                    runningExperiments: {}
                },
                onReady
            }
        }

        componentDidMount() {
            const rawData = this.props.preview()
            const props = this.buildProps(rawData)
            getViewerFragment(hostReact, serviceTopology, props)
                .then((tbFragment: any) => {
                    this.setState({tbFragment})
                })
                .catch((e: any) => {
                    console.error(`Error when trying to load tbFragment ${e}`)
                })
        }

        render() {
            const rawData = this.props.preview()
            const Loader = hostReact.createElement('div', {height: rawData.totalSectionHeight}, 'Loading...')
            const TBFragmentContainer = hostReact.createElement(
                'div',
                this.state.ready ? {} : {style: {visibility: 'hidden'}},
                this.state.tbFragment
            )

            return this.state.tbFragment ? TBFragmentContainer : Loader
        }
    }

    Preview.propTypes = {
        preview: PropTypes.func.isRequired
    }

    return Preview
}

export {convertData, createMiniSitePreview}
