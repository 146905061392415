import type {Extension, ExtensionAPI, CreateExtArgs} from '@wix/document-manager-core'
import type {SerializedCompStructure} from '@wix/document-services-types'

export interface SerializedStructureAPI {
    getComponentType(compStructure: SerializedCompStructure): string
    getComponentId(compStructure: SerializedCompStructure): string | undefined
    setComponentId(compStructure: SerializedCompStructure, id: string): void
    getComponentByNamespace(compStructure: SerializedCompStructure, namespace: string): any
    getChildren(compStructure: SerializedCompStructure): SerializedCompStructure[] | undefined
}
export type SerializedStructureExtensionAPI = ExtensionAPI & {
    serializedStructure: SerializedStructureAPI
}
const createExtension = (): Extension => {
    const createExtensionAPI = ({}: CreateExtArgs): SerializedStructureExtensionAPI => {
        const getComponentType = (compStructure: SerializedCompStructure) => compStructure.componentType
        const getComponentId = (compStructure: SerializedCompStructure) => compStructure.id
        const setComponentId = (compStructure: SerializedCompStructure, id: string) => (compStructure.id = id)

        const getChildren = (compStructure: SerializedCompStructure) => compStructure.components

        const getComponentByNamespace = (compStructure: SerializedCompStructure, namespace: string) =>
            compStructure[namespace]
        return {
            serializedStructure: {
                getComponentType,
                getComponentId,
                setComponentId,
                getComponentByNamespace,
                getChildren
            }
        }
    }

    return {
        name: 'serializedStructure',
        createExtensionAPI,
        dependencies: new Set([])
    }
}

export {createExtension}
