const KEYS = {
    main: 'dsMainInit',
    op: 'dsPatch'
}

export default {
    createTags: (description: string, extraTags: Record<string, string> = {}) => ({
        tags: {
            [KEYS.main]: description,
            ...extraTags
        }
    }),
    descriptions: {
        CORRUPTED_DOCUMENT: 'corruptedDocument',
        VALIDATION_FAIL: 'validationFailed',
        PATCH_FAIL: 'patchApplicationFailed',
        AUTOSAVE_PAYLOAD_FAIL: 'fetchAutoSavePayloadFailed'
    },
    keys: KEYS
}
