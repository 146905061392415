// export type {
//     CreateTransactionRequest,
//     CreateTransactionResponse,
//     Transaction,
//     PendingTransaction,
//     GetTransactionsResponse,
//     DeleteTransactionsResponse
// } from '@wix/ambassador-editor-document-store/types'
import type {Action} from './serverProtocolTypes'

export * from './serverProtocolTypes'

export interface CreateRevisionReq {
    readonly viewerName: string
    readonly initiator: string
    readonly editorVersion: string
    readonly dsOrigin: string
    readonly editorSessionId: string
    readonly metaSiteActions: {
        actions: {}[]
        maybeBranchId?: string
    }
    readonly branchId?: string
    readonly siteName?: string
    readonly lastTransactionId?: string
    readonly initiatorOrigin?: string
    readonly presaveOperations?: any[]
    readonly closedWixCodeAppId?: string
    readonly suppressUnappliedTransactions?: boolean
}

export interface CreateRevisionRes {
    readonly actions: Action[]
    readonly siteRevision: {
        revision: number
        version: number
    }
}
