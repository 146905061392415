import type {PublicMethodUtils} from '@wix/document-services-types'
import {siteMembers} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            siteMembers: {
                setAutoApproval: publicMethodUtils.defineAction(siteMembers.setAutoApproval),
                isAutoApproval: publicMethodUtils.defineGetter(siteMembers.isAutoApproval),
                setLoginDialogFirst: publicMethodUtils.defineAction(siteMembers.setLoginDialogFirst),
                isLoginDialogFirst: publicMethodUtils.defineGetter(siteMembers.isLoginDialogFirst),
                setSocialLoginVendorStatus: publicMethodUtils.defineAction(siteMembers.setSocialLoginVendorStatus),
                isSocialLoginEnabled: publicMethodUtils.defineGetter(siteMembers.isSocialLoginEnabled),
                setPrivacyNoteType: publicMethodUtils.defineDataManipulationAction(siteMembers.setPrivacyNoteType),
                getPrivacyNoteType: publicMethodUtils.defineGetter(siteMembers.getPrivacyNoteType),
                setJoinCommunityStatus: publicMethodUtils.defineAction(siteMembers.setJoinCommunityStatus),
                isJoinCommunityEnabled: publicMethodUtils.defineGetter(siteMembers.isJoinCommunityEnabled),
                setBackendLoginOnlyStatus: publicMethodUtils.defineAction(siteMembers.setBackendLoginOnlyStatus),
                isBackendLoginOnlyEnabled: publicMethodUtils.defineGetter(siteMembers.isBackendLoginOnlyEnabled),
                isSignupPoliciesFieldEnabled: publicMethodUtils.defineGetter(siteMembers.isSignupPoliciesFieldEnabled),
                setSignupPoliciesFieldStatus: publicMethodUtils.defineAction(siteMembers.setSignupPoliciesFieldStatus),
                showLoginDialog: publicMethodUtils.defineAction(siteMembers.showLoginDialog),
                showSignUpDialog: publicMethodUtils.defineAction(siteMembers.showSignUpDialog),
                hideAuthDialog: publicMethodUtils.defineAction(siteMembers.hideAuthDialog),
                getSignupPoliciesLink: publicMethodUtils.defineGetter(siteMembers.getSignupPoliciesLink),
                setSignupPoliciesLink: publicMethodUtils.defineAction(siteMembers.setSignupPoliciesLink),
                getCustomSignupPageId: publicMethodUtils.defineGetter(siteMembers.getCustomSignupPageId),
                setCustomSignupPageId: publicMethodUtils.defineAction(siteMembers.setCustomSignupPageId),
                getCustomSignInPageId: publicMethodUtils.defineGetter(siteMembers.getCustomSignInPageId),
                setCustomSignInPageId: publicMethodUtils.defineAction(siteMembers.setCustomSignInPageId),
                getCustomNoPermissionsPageId: publicMethodUtils.defineGetter(siteMembers.getCustomNoPermissionsPageId),
                setCustomNoPermissionsPageId: publicMethodUtils.defineAction(siteMembers.setCustomNoPermissionsPageId)
            }
        }
    }
}
