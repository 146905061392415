import _ from 'lodash'
import structure from '../../structure/structure'
import component from '../../component/component'
import appStudioDataModel from '../../appStudio/appStudioDataModel'
import mobileUtil from '../../mobileUtilities/mobileUtilities'

function copyRootComponentDockedLayout(ps, pagesPointers) {
    _.forEach(
        _.filter(pagesPointers, ({id}) => appStudioDataModel.isWidgetPage(ps, id)),
        pagePointer => {
            const desktopRootComp = _.head(
                component.getChildren(ps, ps.pointers.components.getDesktopPointer(pagePointer))
            )
            if (!desktopRootComp) {
                return
            }

            const desktopDockData = structure.getDock(ps, desktopRootComp)

            if (!_.isEmpty(desktopDockData)) {
                mobileUtil.syncDockData(ps, desktopRootComp, desktopDockData, structure)
            }
        }
    )
}

export default {
    copyRootComponentDockedLayout
}
