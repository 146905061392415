import _ from 'lodash'
import DALFactory from './DALFactory'

function isWixAdsAllowed(siteData) {
    const displayedJsonDal = DALFactory.getInstance(siteData)
    return displayedJsonDal.getByPath(['renderFlags', 'isWixAdsAllowed'])
}

function isHeaderFixedPosition(siteData) {
    const viewMode = siteData.getViewMode()
    const pointers = DALFactory.getPointersInstance(siteData)
    const masterPagePointer = pointers.components.getPage(siteData.MASTER_PAGE_ID, viewMode)
    const headerPointer = pointers.components.getComponent('SITE_HEADER', masterPagePointer)
    const fixedPositionPointer = pointers.getInnerPointer(headerPointer, ['layout', 'fixedPosition'])
    return DALFactory.getInstance(siteData).get(fixedPositionPointer)
}

// SVG Setters
// SVG Getters
function getMapFromPageUriSeoToPageId(siteData) {
    const displayedJsonDal = DALFactory.getInstance(siteData)
    return displayedJsonDal.getByPath(['mapFromPageUriSeoToPageId'])
}
function getRendererModel(siteData, prop, noClone?) {
    const displayedJsonDal = DALFactory.getInstance(siteData)
    if (prop) {
        return displayedJsonDal.getByPath(['rendererModel', prop], false, noClone)
    }
    return displayedJsonDal.getByPath(['rendererModel'], false, noClone)
}

function getAllPageIds(siteData) {
    const displayedJsonDal = DALFactory.getInstance(siteData)
    const masterPageDataPath = ['pagesData', 'masterPage', 'data', 'document_data']
    const masterPageDataKeys = displayedJsonDal.getKeysByPath(masterPageDataPath)

    return _.filter(masterPageDataKeys, potentialPageId => {
        // TODO: can this be replaced with a dal pointer instead of getByPath ?
        const itemType = displayedJsonDal.getByPath(masterPageDataPath.concat([potentialPageId, 'type']))
        return itemType === 'Page' || itemType === 'AppPage'
    })
}

export default {
    isWixAdsAllowed,
    isHeaderFixedPosition,
    getMapFromPageUriSeoToPageId,
    getRendererModel,
    getAllPageIds
}
