import type {PS} from '@wix/document-services-types'
import accessibilityReorder from './plugins/accessibilityReorder'
import calculateSiteMetaDataRenderHints from './plugins/calculateSiteMetaDataRenderHints'
import markDataAsPresetOperation from './plugins/markDataAsPresetOperation'
import meshLayoutReadyPreSaveOperation from './plugins/meshLayoutReadyPreSaveOperation'
import mobilePreSaveOperation from './plugins/mobilePreSaveOperation'
import mobileUtils from '../../mobileUtilities/mobileUtilities'
const {isMobileSupported} = mobileUtils

interface PresaveOp {
    name: string
    op(ps: PS): void
}

const save: PresaveOp[] = [
    {name: 'mobilePreSaveOperation', op: mobilePreSaveOperation},
    {name: 'accessibilityReorder', op: accessibilityReorder},
    {name: 'calculateSiteMetaDataRenderHints', op: calculateSiteMetaDataRenderHints},
    {name: 'meshLayoutReadyPreSaveOperation', op: meshLayoutReadyPreSaveOperation}
]

const saveResponsive: PresaveOp[] = [{name: 'calculateSiteMetaDataRenderHints', op: calculateSiteMetaDataRenderHints}]

const saveAsTemplate: PresaveOp[] = [{name: 'markDataAsPresetOperation', op: markDataAsPresetOperation}]

const runSingle = async (ps: PS, preSaveOp: (ps: PS) => void, name: string) => {
    await preSaveOp(ps)
    ps.dal.commitTransaction(name)
}

const runMultiple = (preSaveOps: PresaveOp[]) => async (ps: PS) => {
    for (const {name, op} of preSaveOps) {
        await runSingle(ps, op, name)
    }
}

export default {
    save: (ps: PS) => {
        const runPreSaveOps = isMobileSupported(ps) ? runMultiple(save) : runMultiple(saveResponsive)
        return runPreSaveOps(ps)
    },
    saveAsTemplate: runMultiple(saveAsTemplate)
}
