const pointerType = 'customElementsPointer'
import {pointerUtils} from '@wix/document-manager-core'
const cacheKillersStorePath = 'cacheKillers'

const {getPointer} = pointerUtils

const createPointersMethods = () => ({
    customElements: {
        getCacheKillerCounter: (fileId: string) => getPointer(cacheKillersStorePath, pointerType, {innerPath: [fileId]})
    }
})

export {createPointersMethods}
