export const systemStyles = {
    'skins.core.InlineSkin': {
        properties: {
            'alpha-bg': '0',
            bg: 'color_11'
        },
        propertiesSource: {
            'alpha-bg': 'value',
            bg: 'theme'
        }
    }
}
