function buildList(data: any) {
    const result = data.map(buildValue)

    return {
        property_val_list: {
            value: result
        }
    }
}

function buildMap(data: any) {
    const result: Record<string, any> = {}

    for (const key of Object.keys(data)) {
        result[key] = buildValue(data[key])
    }

    return {
        property_val_map: {
            map_val: result
        }
    }
}

function buildPrimitive(data: any) {
    let key
    if (typeof data === 'boolean') {
        key = 'bool_val'
    } else if (typeof data === 'string') {
        key = 'str_val'
    } else if (typeof data === 'number') {
        if (Number.isNaN(data)) {
            key = 'long_val'
        } else if (!Number.isNaN(data)) {
            key = 'double_val'
        }
        // eslint-disable-next-line lodash/prefer-is-nil
    } else if (data === null || data === undefined) {
        key = null
    } else {
        const errorMessage = 'Invalid primitive value'
        throw Error(errorMessage)
    }

    return key
        ? {
              [key]: data
          }
        : {}
}

function buildValue(data: any) {
    let value

    if (Array.isArray(data)) {
        value = buildList(data)
    } else if (typeof data === 'object' && data !== null) {
        value = buildMap(data)
    } else {
        value = buildPrimitive(data)
    }

    return value
}

export function composeProtoInput(data: any) {
    return buildValue(data)
}

function isList(data: any) {
    return Boolean(data?.propertyValList?.value || data?.property_val_list?.value)
}

function isMap(data: any) {
    return Boolean(data?.propertyValMap?.mapVal || data?.property_val_map?.map_val)
}

function parseList(data: any) {
    const list = data.propertyValList?.value ?? data.property_val_list.value

    return list.map(parseValue)
}

function parseMap(data: any) {
    const map = data.propertyValMap?.mapVal ?? data.property_val_map.map_val
    const result: Record<string, any> = {}

    for (const key of Object.keys(map)) {
        result[key] = parseValue(map[key])
    }

    return result
}

function parsePrimitive(data: any) {
    const [value] = Object.values(data)
    return value
}

function parseValue(data: any) {
    let result

    if (isMap(data)) {
        result = parseMap(data)
    } else if (isList(data)) {
        result = parseList(data)
    } else {
        result = parsePrimitive(data)
    }

    return result
}

export function parseProtoOutput(data: any) {
    return parseValue(data)
}
