import {createHookDefinition} from '../../utils/hooksUtils'
import type {HookEventData} from '../hooks/hooks'
import type {Pointer} from '@wix/document-services-types'

export interface BeforeSetScopedValueData {
    compRef: Pointer
    itemType: string
    pageId: string
    relId: string
    refArrayId: string
}

export interface BeforeSetNonScopedValueData {
    compRef: Pointer
    itemType: string
    pageId: string
    newDefaultValueId: string
    refArrayId: string
}

export interface BeforeSetOverrideData {}

export interface BeforeSetOverrideEvent extends BeforeSetOverrideData, HookEventData<'before_set_override'> {}
export interface BeforeSetScopedValueEvent extends BeforeSetScopedValueData, HookEventData<'before_set_scoped_value'> {}
export interface BeforeSetNonScopedValueEvent
    extends BeforeSetNonScopedValueData,
        HookEventData<'before_set_non_scoped_value'> {}

export const VARIANT_HOOKS = {
    SET_OVERRIDE: {
        BEFORE: createHookDefinition<'before_set_override', BeforeSetOverrideData>('before_set_override')
    },
    SET_SCOPED_VALUE: {
        BEFORE: createHookDefinition<'before_set_scoped_value', BeforeSetScopedValueData>('before_set_scoped_value')
    },
    SET_NON_SCOPED_VALUE: {
        BEFORE: createHookDefinition<'before_set_non_scoped_value', BeforeSetNonScopedValueData>(
            'before_set_non_scoped_value'
        )
    }
}
