import * as santaCoreUtils from '@wix/santa-core-utils'
import type {PS, Pointer} from '@wix/document-services-types'

/**
 * @param {PS} ps
 * @returns {Pointer}
 */
function getAccessibilitySettingsDataPointer(ps: PS): Pointer {
    const masterPageDataPointer = ps.pointers.data.getDataItemFromMaster(santaCoreUtils.siteConstants.MASTER_PAGE_ID)
    return ps.pointers.getInnerPointer(masterPageDataPointer, 'accessibilitySettings')
}

export default {
    getAccessibilitySettingsDataPointer
}
