import {MockCSChannel, MockCSDuplexer} from '../csave/mockCSChannel'

export type EventCB = (payload?: any) => Promise<void>

export class MockDistributorChannel extends MockCSChannel {
    distributeMessage(payloads?: any) {
        this.events.messageDistribute({payloads})
    }
}

export class MockDistributorDuplexer extends MockCSDuplexer {
    createChannel() {
        return new MockDistributorChannel()
    }
    distributeMessage(payload?: any) {
        const channel = this._channel! as MockDistributorChannel
        channel.distributeMessage(payload)
    }
}
