import type {
    ComponentStageData,
    MobileAlgoPlugin,
    MobileAlgoPluginInitializationArgs,
    PluginContext,
    PluginHeuristicsRegistry,
    StructureStageData
} from '../../types'
import {createGroupingMap} from './aiGrouping/aiGrouping'
import type {OrderApiPlugin, SendRequest} from './types'
import {CTX_GROUPING_RESULT, CTX_SERVER} from './constants'

export const createPlugin = (pluginInitVals: MobileAlgoPluginInitializationArgs): MobileAlgoPlugin => {
    const {stages} = pluginInitVals
    const applyOrder = ({componentStructure}: ComponentStageData, ctx: PluginContext) => {
        if (componentStructure?.layouts?.itemLayout?.type === 'MeshItemLayout') {
            const order = ctx.get(CTX_GROUPING_RESULT)
            const {id} = componentStructure
            if (order[id!]) {
                //        (componentStructure.layouts.itemLayout).gridArea.rowStart = order[id!]
                //      componentStructure.layouts.itemLayout.gridArea.rowEnd = order[id!] + 1
            }
        }
    }

    const createApi = (context: PluginContext): OrderApiPlugin => {
        return {
            registerServer: (server: SendRequest) => {
                context.set(CTX_SERVER, server)
            }
        }
    }
    const createMap = async (data: StructureStageData, ctx: PluginContext) => {
        await createGroupingMap(data, ctx, pluginInitVals)
    }

    const register = (registry: PluginHeuristicsRegistry) => {
        registry.register(stages.SETUP, createMap)
        registry.register(stages.LOCATION, applyOrder)
    }

    return {register, createApi, name: 'order', dependencies: ['loadRefs']}
}
