import {pointerUtils} from '@wix/document-manager-core'
const ghostStructurePointer = 'ghostStructure'
const ghostControllersPointer = 'ghostControllers'

const {getPointer} = pointerUtils

const createPointersMethods = () => {
    const getGhostStructure = () => getPointer(ghostStructurePointer, ghostStructurePointer)
    const getGhostControllers = () => getPointer(ghostControllersPointer, ghostControllersPointer)
    return {
        general: {
            getGhostStructure,
            getGhostControllers
        }
    }
}

export {createPointersMethods}
