import {DAL, DocumentManager, pointerUtils} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'

const {getInnerPointer} = pointerUtils
export interface ReferrerGroup {
    key: string
    pageId: string
    referrers: Pointer[]
}

const pageIdPath = ['metaData', 'pageId']
const getPageId = (dal: DAL, pointer: Pointer) => dal.get(getInnerPointer(pointer, pageIdPath))

const getGroupKey = (documentManager: DocumentManager, pageId: string, referrer: Pointer): string => {
    if (referrer.type === 'DESKTOP' || referrer.type === 'MOBILE') {
        return `component-${pageId}-${referrer.id}`
    }
    if (referrer.type === 'multilingualTranslations' || referrer.type === 'data') {
        const {id} = documentManager.pointers.multilingualTranslations.getOriginal(referrer)
        return `data-${pageId}-${id}`
    }
    return `single-${pageId}-${referrer.id}`
}

export const createReferrerGroups = (documentManager: DocumentManager, referrers: Pointer[]): ReferrerGroup[] => {
    const groups = referrers.reduce((res, referrer) => {
        const pageId = getPageId(documentManager.dal, referrer)
        const groupKey = getGroupKey(documentManager, pageId, referrer)
        if (!res[groupKey]) {
            res[groupKey] = {key: groupKey, pageId, referrers: []}
        }
        res[groupKey].referrers.push(referrer)
        return res
    }, {})
    return Object.values(groups)
}
