import _ from 'lodash'
import type {DAL, ExtensionAPI} from '@wix/document-manager-core'
import type {
    CompRef,
    Component,
    Pointers,
    RawComponentExportStructure,
    ExternalsBuilderMap,
    BreakpointsMap,
    ExternalRefsMap,
    AppControllersMap,
    RawComponentExport,
    RawPageSettings,
    SchemaService
} from '@wix/document-services-types'
import {deepClone} from '@wix/wix-immutable-proxy'
import type {DataModelExtensionAPI} from '../../../dataModel/dataModel'
import {COMP_DATA_QUERY_KEYS_WITH_STYLE, DATA_TYPES} from '../../../../constants/constants'
import {EXCLUDED_NAMESPACES, UNNECESSARY_PROPS, isDatasetAppController} from '../constants'
import {resolveOverrides} from './utils/overrides'
import {resolveDataItem} from './utils/dataItems'
import {isSystemDataItem} from '../utils'

const getComponents = (rootPointer: CompRef, pointers: Pointers): CompRef[] =>
    [rootPointer, ...pointers.structure.getChildrenRecursively(rootPointer)] as CompRef[]

const restructureComponent = (component: Component): RawComponentExportStructure => ({structure: component, data: {}})

const sanitizeComponent = (component: RawComponentExportStructure) => {
    for (const property of UNNECESSARY_PROPS) {
        delete component.structure[property]
    }
}

const resolveDataItems = (
    component: RawComponentExportStructure,
    componentPointer: CompRef,
    pagePointer: CompRef,
    pointers: Pointers,
    extensionAPI: ExtensionAPI,
    schemaService: SchemaService,
    externals?: ExternalsBuilderMap
) => {
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    _.forOwn(COMP_DATA_QUERY_KEYS_WITH_STYLE, (query: string, namespace: string) => {
        const componentQuery = component.structure[query]
        if (!component.structure[query]) {
            return
        }

        if (isSystemDataItem(componentQuery, namespace, extensionAPI)) {
            return
        }

        delete component.structure[query]

        if (EXCLUDED_NAMESPACES.has(namespace)) {
            return
        }

        const itemPointer = dataModel.components.getItemPointer(componentPointer, namespace)!
        component.data[namespace] = resolveDataItem(
            itemPointer,
            pagePointer,
            pointers,
            extensionAPI,
            schemaService,
            externals
        )
    })
}

const serializeComponent = (
    componentPointer: CompRef,
    pagePointer: CompRef,
    dal: DAL,
    pointers: Pointers,
    extensionAPI: ExtensionAPI,
    schemaService: SchemaService,
    externals?: ExternalsBuilderMap
): RawComponentExportStructure => {
    const componentStructure = deepClone(dal.get(componentPointer))
    const rawComponent = restructureComponent(componentStructure)
    sanitizeComponent(rawComponent)
    resolveDataItems(rawComponent, componentPointer, pagePointer, pointers, extensionAPI, schemaService, externals)
    resolveOverrides(rawComponent, componentPointer, pagePointer, pointers, extensionAPI, schemaService, externals)

    return rawComponent
}

const serializeComponents = (
    components: CompRef[],
    pagePointer: CompRef,
    externals: ExternalsBuilderMap,
    dal: DAL,
    pointers: Pointers,
    extensionAPI: ExtensionAPI,
    schemaService: SchemaService
): Record<string, RawComponentExportStructure> => {
    const rawComponents = components.map((componentPointer: CompRef) =>
        serializeComponent(componentPointer, pagePointer, dal, pointers, extensionAPI, schemaService, externals)
    )

    return _.keyBy(rawComponents, comp => comp.structure.id)
}

const getBreakpoints = (pagePointer: CompRef, extensionAPI: ExtensionAPI): BreakpointsMap => {
    const pageVariants = (extensionAPI as DataModelExtensionAPI).dataModel.components.getItem(
        pagePointer,
        DATA_TYPES.variants
    )

    return _.reduce(
        pageVariants?.values,
        (acc, bpRange) => {
            acc[bpRange.id] = _.pick(bpRange, ['min', 'max', 'canvasSize'])
            return acc
        },
        {}
    )
}

const getExternalRefs = (
    externals: ExternalsBuilderMap,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    breakpoints: BreakpointsMap
): ExternalRefsMap => {
    // const breakpointIds = new Set(Object.keys(breakpoints))
    const externalRefs = {}
    for (const ref of externals.nonOwnedRefs) {
        // TODO: DM-9711
        if (!externals.internalIds.has(ref) /* && !breakpointIds.has(ref)*/) {
            externalRefs[ref] = ref
        }
    }

    return externalRefs
}

const getInternalAppControllers = (
    components: Record<string, RawComponentExportStructure>,
    rootCompId: string
): AppControllersMap => {
    const children: string[] | undefined = components[rootCompId].structure.components
    if (_.isUndefined(children) || _.isEmpty(children)) {
        return {}
    }

    const appControllers: AppControllersMap = {}
    const childrenWithoutDatasets = []
    for (const comp of children) {
        Object.assign(appControllers, getInternalAppControllers(components, comp))
        const component = components[comp]
        if (
            !isDatasetAppController(
                component.structure.componentType,
                component.data.data?.applicationId,
                component.data.data?.controllerType
            )
        ) {
            childrenWithoutDatasets.push(comp)
        } else {
            appControllers[component.data.data.id] = {
                parent: rootCompId,
                fields: {},
                appController: component,
                disconnect: false
            }

            delete components[comp]
        }
    }
    components[rootCompId].structure.components = childrenWithoutDatasets

    return appControllers
}

const sanitizeAppControllers = (appControllers: AppControllersMap): void => {
    const unnecessaryFields = ['layout', 'style']

    for (const {appController} of Object.values(appControllers)) {
        for (const field of unnecessaryFields) {
            delete appController.data[field]
        }
    }
}
const getAppControllers = (
    components: Record<string, RawComponentExportStructure>,
    rootPointer: CompRef,
    pagePointer: CompRef,
    externals: ExternalsBuilderMap,
    dal: DAL,
    pointers: Pointers,
    extensionAPI: ExtensionAPI,
    schemaService: SchemaService
): AppControllersMap => {
    const appControllers: AppControllersMap = getInternalAppControllers(components, rootPointer.id)

    for (const [dataId, {compPointer, fields}] of Object.entries(externals.appControllers)) {
        if (!appControllers[dataId]) {
            const appController = serializeComponent(
                compPointer,
                pagePointer,
                dal,
                pointers,
                extensionAPI,
                schemaService
            )
            appControllers[dataId] = {externalRef: dataId, fields, appController, disconnect: false}
        }

        appControllers[dataId].fields = fields
    }

    sanitizeAppControllers(appControllers)

    return appControllers
}
export const exportRawComponent = (
    rootPointer: CompRef,
    dal: DAL,
    pointers: Pointers,
    extensionAPI: ExtensionAPI,
    schemaService: SchemaService
): RawComponentExport => {
    const pagePointer = pointers.structure.getPageOfComponent(rootPointer) as CompRef
    const components: CompRef[] = getComponents(rootPointer, pointers)

    const externals: ExternalsBuilderMap = {
        internalIds: new Set<string>(components.map(({id}) => id)),
        nonOwnedRefs: new Set<string>(),
        appControllers: {}
    }

    const rawComponents: Record<string, RawComponentExportStructure> = serializeComponents(
        components,
        pagePointer,
        externals,
        dal,
        pointers,
        extensionAPI,
        schemaService
    )

    const breakpoints: BreakpointsMap = getBreakpoints(pagePointer, extensionAPI)
    const pageSettings: RawPageSettings = {breakpoints}

    const externalRefs: ExternalRefsMap = getExternalRefs(externals, breakpoints)
    const appControllers: AppControllersMap = getAppControllers(
        rawComponents,
        rootPointer,
        pagePointer,
        externals,
        dal,
        pointers,
        extensionAPI,
        schemaService
    )

    return {
        rootComponent: rootPointer.id,
        components: _.keyBy(rawComponents, 'structure.id'),
        page: pageSettings,
        externalRefs,
        appControllers
    }
}
