import {CSaveApi, PUBLISH_HOOKS} from '@wix/document-manager-extensions'
import {classic, onBoarding, ReportableError} from '@wix/document-manager-utils'
import type {BIEvt, BIParams, LastSaveInfo, PS, Tags} from '@wix/document-services-types'
import * as santaCoreUtils from '@wix/santa-core-utils'
import experiment from 'experiment-amd'
import _ from 'lodash'
import bi from '../bi/bi'
import biErrors from '../bi/errors'
import constants from '../constants/constants'
import hooks from '../hooks/hooks'
import generalInfo from '../siteMetadata/generalInfo'
import type {SaveTaskRegistry, SaveTaskRegistryResult} from './lib/registry'
import saveTaskRunner from './lib/saveRunner'
import saveState from './lib/saveState'
import monitoring from './monitoring'
import preSaveOperations from './preSaveOperations/preSaveOperations'
import saveErrors, {convertToDSError, DSError, getErrorTypeAndMessage} from './saveErrors'
import {TaskQueue} from './TaskQueue'
import type {PublishAllOptions} from './saveTasks/saveDocument'
import {eventHooks} from '../hooks/eventHooks/eventHooks'

export interface SaveOptions extends PublishAllOptions {
    isPublish?: boolean
    isSilent?: boolean
    onBoarding?: boolean
    trigger?: string
    editorOrigin?: string
    fullPayload?: boolean
    viewerName?: string
    settleInServer?: boolean
    extraPayload?: any
    initiatorOrigin?: string
}

/**
 * callback executed upon success
 */
export type OnSuccess = () => void

/**
 * callback executed upon error
 */
export type OnError = (p: DSError) => void

export interface BICallbacks {
    event(reportDef: BIEvt, params?: BIParams): void
    error(reportDef: BIEvt, params?: BIParams): void
}

const reportError = (onError: OnError, error: any) => {
    const cb = onError ? onError : console.log
    cb(error)
}

const saveDisabled = (onError: OnError) => {
    reportError(onError, saveErrors.SAVE_DISABLED_IN_DOCUMENT_SERVICES)
}

function migrateFromOnBoardingIfNeeded(ps: PS, flag: boolean) {
    if (ps.config.origin === classic) {
        generalInfo.setUseOnBoarding(ps, flag)
    }
}

function setPublishSaveFlag(ps: PS) {
    const publishSaveInnerPointer = ps.pointers.general.getPublishSaveInnerPointer()
    ps.dal.set(publishSaveInnerPointer, true)
}

function setSilentSaveFlag(ps: PS) {
    const silentSaveInnerPointer = ps.pointers.general.getSilentSaveInnerPointer()
    ps.dal.set(silentSaveInnerPointer, true)
}

function getBiCallbacks(ps: PS): BICallbacks {
    return {
        event: _.partial(bi.event, ps),
        error: _.partial(bi.error, ps)
    }
}

export interface InitParams {
    disableSave?: boolean
    firstSaveExtraPayload?: any
    settleInServer?: boolean
}

function hasPermission(ps: PS, permission: string) {
    const permissions = generalInfo.getUserPermissions(ps)
    return _.includes(permissions, permission)
}

/**
 * Returns true if the user is allowed to publish according to server, false otherwise
 */
function canUserPublish(ps: PS): boolean {
    if (!saveState.isEnabled(ps)) {
        return false
    }
    const isOwnerAndPermitted = generalInfo.isOwner(ps) && !experiment.isOpen('dm_publishByPermissionsNotOwner')

    if (isOwnerAndPermitted) {
        return true
    }

    return hasPermission(ps, constants.PERMISSIONS.PUBLISH)
}

const hasSavePermissions = (ps: PS) => hasPermission(ps, constants.PERMISSIONS.SAVE)

function removeSaveFlags(ps: PS) {
    const publishSaveInnerPointer = ps.pointers.general.getPublishSaveInnerPointer()
    const silentSaveInnerPointer = ps.pointers.general.getSilentSaveInnerPointer()
    if (ps.dal.get(publishSaveInnerPointer)) {
        ps.dal.remove(publishSaveInnerPointer)
    } else if (ps.dal.get(silentSaveInnerPointer)) {
        ps.dal.remove(silentSaveInnerPointer)
    }
}

const runPresaveOperations = async (
    ps: PS,
    isFullSave: boolean,
    options: SaveOptions,
    tags: Record<string, string | boolean>
) => {
    const {PRESAVE_OPERATIONS} = constants.INTERACTIONS.SAVE
    try {
        ps.extensionAPI.logger.interactionStarted(PRESAVE_OPERATIONS)
        ps.extensionAPI.logger.breadcrumb(`preSaveOperation on save started.`)
        await preSaveOperations.save(ps)
        ps.extensionAPI.logger.interactionEnded(PRESAVE_OPERATIONS)
    } catch (e: any) {
        ps.extensionAPI.logger.breadcrumb('preSaveOperation on save failed.')
        bi.error(ps, biErrors.SAVE_FAILED_DUE_TO_PRE_SAVE_OPERATION, {stack: e.stack})
        ps.extensionAPI.logger.captureError(e, {
            tags: {preSaveOperationError: true, isFullSave, ...tags}
        })
        santaCoreUtils.log.error(
            'Save has failed due to a preSaveOperation, and no request has been sent to the server - please see the failure details below:',
            e
        )
        ps.dal.dropUncommittedTransaction(e.message)
        throw saveErrors.normalizeError({preSaveOperation: e})
    }
}

const checkForPermissions = (ps: PS) => {
    const hasSave = hasSavePermissions(ps)
    if (!hasSave) {
        throw saveErrors.USER_NOT_AUTHORIZED_FOR_SITE
    }
}

const getSaveOptions = (ps: PS, options?: SaveOptions) => ({
    settleInServer: ps.runtimeConfig.settleInServer,
    viewerName: ps.runtimeConfig.viewerName,
    editorOrigin: ps.runtimeConfig.editorType ?? ps.runtimeConfig.origin,
    initiatorOrigin: _.get(options, 'origin', '')
})

const getEditorOriginSaveOption = (ps: PS) => ({editorOrigin: ps.runtimeConfig.origin})

export default (saveTaskRegistry: SaveTaskRegistry) => {
    const asyncSave = async (ps: PS, isFullSave: boolean, options: SaveOptions) => {
        monitoring.start(monitoring.SAVE, options as Tags)
        const isSaveFromDraft = generalInfo.isDraft(ps)
        const usingCSave = (ps.extensionAPI as CSaveApi).continuousSave.isCSaveOpen()
        const usingCEdit = (ps.extensionAPI as CSaveApi).continuousSave.isCEditOpen()
        const tags = {usingCSave, usingCEdit, isSaveFromDraft}
        const isTemplate = ps.extensionAPI.siteAPI.isTemplate()
        if (!isTemplate) {
            checkForPermissions(ps)
        }

        migrateFromOnBoardingIfNeeded(ps, false)

        await runPresaveOperations(ps, isFullSave, options, tags)

        ps.extensionAPI.logger.breadcrumb(`preSaveOperation on save finished.`)
        const biCallbacks = getBiCallbacks(ps)

        if (options) {
            if (options.isPublish) {
                setPublishSaveFlag(ps)
            } else if (options.isSilent) {
                setSilentSaveFlag(ps)
            }
            if (!_.isUndefined(options.onBoarding)) {
                migrateFromOnBoardingIfNeeded(ps, options.onBoarding)
            }
        }
        saveState.setSaveProgress(ps, true)

        const handleSaveValidationError = (e: DSError) => {
            const isADI = ps.config.origin === onBoarding
            if (isADI) {
                ps.extensionAPI.logger.captureError(
                    new ReportableError({
                        message: 'Site validation error - ADI',
                        errorType: 'siteValidationErrorADI',
                        tags: {adiSaveError: isADI, ...tags},
                        extras: {errorDescription: e}
                    })
                )
            }
            hooks.executeHook(hooks.HOOKS.SAVE.VALIDATION_ERROR, null!, [ps])
        }

        const saveErrorHandler = (e: string | DSError) => {
            const err = convertToDSError(e)
            const {message, errorType} = getErrorTypeAndMessage(err)
            monitoring.error(new ReportableError({message, errorType, tags}), {errorDescription: err as any})
            saveState.setSaveProgress(ps, false)
            removeSaveFlags(ps)
            if (saveErrors.isSaveValidationError(err)) {
                handleSaveValidationError(err)
            }
            throw saveErrors.normalizeError(err)
        }

        if (isFullSave) {
            await runFullSaveTasks(ps, options, biCallbacks).catch(saveErrorHandler)
        } else {
            await runPartialSaveTasks(ps, options, biCallbacks).catch(saveErrorHandler)
        }
        monitoring.end(monitoring.SAVE, options as Tags)
        removeSaveFlags(ps)
        saveState.setSaveProgress(ps, false)
        hooks.executeHook(hooks.HOOKS.SAVE.SITE_SAVED, null!, [ps, isSaveFromDraft])
    }

    const asyncSaveWrapper = async (
        ps: PS,
        isFullSave: boolean,
        options: SaveOptions,
        onSuccess: OnSuccess,
        onError: OnError
    ) => {
        try {
            await asyncSave(ps, isFullSave, options)
            onSuccess()
        } catch (e) {
            const {errorType, message} = getErrorTypeAndMessage(e)
            ps.extensionAPI.logger.captureError(
                new ReportableError({
                    errorType,
                    message,
                    tags: {saveFailure: 'asyncSaveWrapper'},
                    extras: {errorDescription: e}
                })
            )
            onError(e)
        }
    }

    const save = async (
        ps: PS,
        onSuccess: OnSuccess,
        onError: OnError,
        isFullSave: boolean,
        options: SaveOptions
    ): Promise<void> => {
        await asyncSaveWrapper(ps, isFullSave, options, onSuccess, onError)
    }

    const runPresaveTasks = async (ps: PS, options: SaveOptions): Promise<void> => {
        if (options?.trigger !== 'CSAVE_NON_RECOVERABLE_ERROR') {
            try {
                ps.extensionAPI.logger.interactionStarted('presave_forceSaveAndWaitForResult')
                await ps.extensionAPI.continuousSave.forceSaveAndWaitForResult()
                ps.extensionAPI.logger.interactionEnded('presave_forceSaveAndWaitForResult')
            } catch (e) {
                throw saveErrors.createPresaveError(ps, e)
            }
        }
    }

    const runPartialSaveTasks = async (ps: PS, options: SaveOptions, biCallbacks: BICallbacks): Promise<void> => {
        await saveTaskRunner.runFunctionInSaveQueue(() => runPresaveTasks(ps, options))
        ps.extensionAPI.continuousSave.disableSaveDuringRequiredAndPrimary(true)
        try {
            await saveTaskRunner.promises.runPartialSaveTasks(
                saveTaskRegistry.getSaveTasksConfig(ps),
                ps,
                biCallbacks,
                getSaveOptions(ps, options)
            )
        } finally {
            ps.extensionAPI.continuousSave.disableSaveDuringRequiredAndPrimary(false)
        }
    }

    const runFullSaveTasks = async (ps: PS, options: SaveOptions, biCallbacks: BICallbacks) => {
        await saveTaskRunner.promises.runFullSaveTasks(
            saveTaskRegistry.getSaveTasksConfig(ps),
            ps,
            biCallbacks,
            getSaveOptions(ps, options)
        )
    }

    const autosave = (ps: PS, onSuccess: OnSuccess = _.noop) => {
        //this method is deprecated
        onSuccess()
    }

    const saveAsTemplateAsync = async (ps: PS) => {
        try {
            await preSaveOperations.saveAsTemplate(ps)
        } catch (e: any) {
            throw saveErrors.normalizeError({preSaveAsTemplateOperation: e})
        }
        await saveAPI.promises.save(ps, false)
        await saveAPI.promises.publish(ps)
        await saveTaskRunner.promises.runSaveAsTemplate(
            saveTaskRegistry.getSaveTasksConfig(ps),
            ps,
            getBiCallbacks(ps),
            getEditorOriginSaveOption(ps)
        )
        await ps.extensionAPI.rendererModel.refreshClientSpecMap()
    }

    const saveAsTemplate = (ps: PS, onSuccess: OnSuccess, onError: OnError) => {
        // eslint-disable-next-line promise/prefer-await-to-then
        saveAsTemplateAsync(ps).then(onSuccess, onError)
    }

    const shouldSaveBeforePublish = (saveConfig: SaveTaskRegistryResult, ps: PS) =>
        saveTaskRunner.shouldSaveBeforePublish(saveConfig, ps)

    const publishAsync = async (ps: PS, options: SaveOptions) => {
        if (ps.config.allowOnlyPublishRC && !options?.publishRC) {
            throw new ReportableError({
                message: 'Only publish RC is allowed',
                errorType: 'ONLY_PUBLISH_RC_ALLOWED',
                extras: {options}
            })
        }

        const saveTasksConfig = saveTaskRegistry.getSaveTasksConfig(ps)
        const innerOptions = getSaveOptions(ps)
        const extendedOptions = _.assign({}, innerOptions, options)
        try {
            if (shouldSaveBeforePublish(saveTasksConfig, ps)) {
                ps.extensionAPI.logger.breadcrumb('shouldSaveBeforePublish - saving before publishing')
                ps.extensionAPI.logger.interactionStarted(constants.INTERACTIONS.SAVE_BEFORE_PUBLISH)
                await saveAPI.promises.saveWithoutQueue(ps, false, options)
                ps.extensionAPI.logger.interactionEnded(constants.INTERACTIONS.SAVE_BEFORE_PUBLISH)
            } else {
                ps.extensionAPI.logger.breadcrumb('shouldSaveBeforePublish - no need to save, only publishing')
            }
            saveState.setPublishProgress(ps, true)
            hooks.executeHook(hooks.HOOKS.PUBLISH.BEFORE, null!, [ps])
            await saveTaskRunner.promises.runPublishTasks(saveTasksConfig, ps, getBiCallbacks(ps), extendedOptions)
        } finally {
            saveState.setPublishProgress(ps, false)
        }
    }

    const publish = (ps: PS, onSuccess: OnSuccess, onError: OnError, options: SaveOptions) => {
        publishAsync(ps, options).then(onSuccess, onError) // eslint-disable-line promise/prefer-await-to-then
    }

    const registerToDeploymentStatus = (ps: PS, callback) => {
        eventHooks(ps).registerHook(PUBLISH_HOOKS.PUBLISH.STATUS.id, callback)
    }

    const canSave = (ps: PS) => saveState.isSaveAllowed(ps)

    const asyncF = (f: Function, ps: PS, ...rest: any[]) =>
        new Promise<void>((resolve, reject) => {
            f(ps, resolve, reject, ...rest)
        })

    const queue: TaskQueue = new TaskQueue()

    const ifSaveAllowed =
        (f: Function, shouldQueue: boolean = false) =>
        (ps: PS, onSuccess: OnSuccess, onError: OnError, ...rest: any[]): void => {
            if (canSave(ps)) {
                if (shouldQueue) {
                    queue.run(async () => {
                        await asyncF(f, ps, ...rest).then(onSuccess, onError)
                    })
                    return
                }
                return f(ps, onSuccess, onError, ...rest)
            }
            if (!saveState.isEnabled(ps)) {
                return saveDisabled(onError)
            }
        }

    const initMethod = (
        ps: PS,
        {disableSave = false, firstSaveExtraPayload = null, settleInServer = true}: InitParams = {
            disableSave: false,
            firstSaveExtraPayload: null,
            settleInServer: true
        }
    ) => {
        saveState.setSaveAllowed(ps, !disableSave)
        if (firstSaveExtraPayload) {
            ps.runtimeConfig.firstSaveExtraPayload = firstSaveExtraPayload
        }
        ps.runtimeConfig.settleInServer = settleInServer
    }

    const saveIf = ifSaveAllowed(save, true)

    const getLastSaveInfo = async (ps: PS): Promise<LastSaveInfo> => {
        const date = await (ps.extensionAPI as CSaveApi).continuousSave.getLastSaveDate()
        return {date}
    }

    const saveAPI = {
        initMethod,
        /**
         * performs a partial save
         * @param onSuccess
         * @param onError
         * @param isFullSave whether to perform a full save or not
         * @param options
         * @param privateServices
         */
        save: saveIf,

        saveWithoutQueue: ifSaveAllowed(save),
        /**
         * @deprecated
         * performs an autosave.
         * You should call autosave from the public autosave endpoint, which validates that you can actually autosave
         * @param privateServices
         * @param onSuccess
         * @param onError
         */
        autosave: ifSaveAllowed(autosave),

        saveAsTemplate: ifSaveAllowed(saveAsTemplate),

        /**
         * publishes the site
         * @param privateServices
         * @param onSuccess
         * @param onError
         * @param options
         */
        publish: ifSaveAllowed(publish, true),

        registerToDeploymentStatus,

        canUserPublish,

        saveState,

        getLastSaveInfo,

        promises: {
            /**
             * performs a partial save
             * @param ps
             * @param [isFullSave] whether to perform a full save or not
             * @param [options]
             */
            save: (ps: PS, isFullSave: boolean = false, options?: SaveOptions) =>
                new Promise<void>((resolve, reject) => {
                    saveAPI.save(ps, resolve, reject, isFullSave, options)
                }),
            /**
             * performs a partial save
             * @param ps
             * @param [isFullSave] whether to perform a full save or not
             * @param [options]
             */
            saveWithoutQueue: (ps: PS, isFullSave: boolean = false, options?: SaveOptions) =>
                new Promise<void>((resolve, reject) => {
                    saveAPI.saveWithoutQueue(ps, resolve, reject, isFullSave, options)
                }),

            /**
             * performs an autosave.
             * You should call autosave from the public autosave endpoint, which validates that you can actually autosave
             */
            autosave: (ps: PS) =>
                new Promise<void>((resolve, reject) => {
                    saveAPI.autosave(ps, resolve, reject)
                }),

            /**
             * publishes the site
             */
            publish: (ps: PS, options?: SaveOptions) =>
                new Promise<void>((resolve, reject) => {
                    saveAPI.publish(ps, resolve, reject, options)
                }),

            saveAsTemplate: (ps: PS) =>
                new Promise<void>((resolve, reject) => {
                    saveAPI.saveAsTemplate(ps, resolve, reject)
                })
        }
    }

    return saveAPI
}
