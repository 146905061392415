import type {PS} from '@wix/document-services-types'
import * as utils from './utils'

const INTERACTIONS = {
    RUN_MERGE_ON_ALL_PAGES: 'mobile-algorithm-run-merge-on-all-pages',
    RUN_MERGE_ON_ONE_PAGE: 'mobile-algorithm-run-merge-on-one-page',
    RUN_OPTIMIZE_LAYOUT_ON_ALL_PAGES: 'mobile-algorithm-run-optimize-on-all-pages',
    RUN_OPTIMIZE_LAYOUT_ON_ONE_PAGE: 'mobile-algorithm-run-optimize-on-one-page'
}

const getInteractionName = (ps: PS, defaultInteractionName: string) => {
    const improvedMergeFlow = utils.shouldEnableImprovedMergeFlow(ps)

    if (improvedMergeFlow) {
        return `${defaultInteractionName}-new-improved`
    }
}
const logInteractionStart = (ps: PS, interactionName: string) => {
    ps.extensionAPI.logger.interactionStarted(getInteractionName(ps, interactionName))
}

const logInteractionEnd = (ps: PS, interactionName: string) => {
    ps.extensionAPI.logger.interactionEnded(getInteractionName(ps, interactionName))
}

export default {
    logInteractionStart,
    logInteractionEnd,
    INTERACTIONS
}
