import type {DalPointers} from '../../../types'
import type {CreateExtArgs} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import type {AppControllerAPI} from '../../appController'
import type {DataModelExtensionAPI} from '../../dataModel/dataModel'

export const getControllerNickname = ({extensionAPI}: CreateExtArgs, controllerRef: Pointer) => {
    const {appController} = extensionAPI as AppControllerAPI
    const nickName = appController.getNicknameFromStageData(controllerRef)
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const {controllerType} = dataModel.components.getItem(controllerRef, 'data') || {}
    return nickName || controllerType
}
export const getComponentNicknameByType = ({dal}: DalPointers, componentType: string) => {
    const baseNicknameFromDefinitionsMap = _.get(dal.schema.getComponentDefinition(componentType), ['nickname'])

    if (baseNicknameFromDefinitionsMap) {
        return baseNicknameFromDefinitionsMap
    }
    return _.camelCase(_.last(componentType.split('.')))
}
export const getComponentNickname = (dalPointers: DalPointers, compPointer: Pointer) => {
    const compData = dalPointers.dal.get(compPointer)
    const componentType = _.get(compData, ['componentType'])
    if (!componentType) {
        return ''
    }

    return getComponentNicknameByType(dalPointers, componentType)
}
