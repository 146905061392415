import allComponentsDefinitionsMap from '../../dist/originalSchemas/componentsDefinitionsMap.json'
import allNewSchemas from '../../dist/allNewSchemas.json'
import * as schemaArrangement from '../schemaArrangement'

const schemas = schemaArrangement.arrangeByDataTypeAndConvertSchemaNamesToOldStyleFileNames(allNewSchemas)

export default {
    ...schemas,
    allComponentsDefinitionsMap,
    componentsDefinitionsMap: allComponentsDefinitionsMap
}
