import type {CompRef, PS} from '@wix/document-services-types'
import _ from 'lodash'
import refComponentUtils from '../refComponent/refComponentUtils'
import appStudioWidgets from '../appStudioWidgets/appStudioWidgets'
import componentCode from '../component/componentCode'

/**
 * Returns ref nickname taken from its connection override(appWidget nickname),
 * in case it's not rendered and getChildren returns empty
 * @param ps
 * @param compRef of type wysiwyg.viewer.components.RefComponent
 * @return {*}
 */
const getRefNickname = (ps: PS, compRef: CompRef) => {
    const [connectionItem] = appStudioWidgets.getPrimaryConnectionItems(ps, compRef)
    return _.get(connectionItem, 'role')
}

const getNickname = (ps: PS, compRef: CompRef) => {
    const isRefComp = refComponentUtils.isRefHost(ps, compRef)
    return isRefComp ? getRefNickname(ps, compRef) : componentCode.getNickname(ps, compRef)
}

export default {
    get: getNickname
}
