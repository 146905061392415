import type {AppStudioModel} from '@wix/ambassador-wix-blocks-service/types'

import type {
    BaseDsData,
    BehaviorsList,
    Callback,
    Callback1,
    Callback2,
    CompRef,
    CompStructure,
    ConnectionList,
    LayoutRefs,
    Pointer,
    PS,
    StyleRefOrStyleRefs,
    UnitSize,
    ValueOf,
    WidgetAPIEvent,
    WidgetKind
} from '.'

export interface Presets {
    layout: string
    style: string
    type: string
}

export const DependencyDataType = {
    ProvisionOnlyInstallation: 'PROVISION_ONLY_INSTALLATION',
    NotifyUserOnDependency: 'NOTIFY_USER_ON_DEPENDENCY',
    IsBundle: 'IS_BUNDLE'
} as const

export type DependencyData = ValueOf<typeof DependencyDataType>
export type BlocksAppStudioModel = AppStudioModel
export interface DependencyInfo {
    dependencyData?: DependencyData[]
}
export interface BlocksAppDependency extends DependencyInfo {
    appDefId: string
}

export interface CompStructureOld extends BaseDsData {
    layoutResponsive?: LayoutRefs
    layout?: {}
    data?: any
    componentType: string
    components?: CompStructureOld[]
    skin?: string
    activeModes?: any
    props?: any
    dataQuery?: any
    design?: any
    mobileStructure?: any
    originCompId?: string
    parent?: string
    style?: StyleRefOrStyleRefs | string
    scopedStyles?: {
        [breakpointId: string]: StyleRefOrStyleRefs
    }
    behaviors?: BehaviorsList
    connections?: ConnectionList
    presets?: Presets
    scopedPresets?: {
        [presetId: string]: Presets
    }
}
export interface SerializedWidget {
    devCenterWidgetId: string
    kind: string
    name: string
    panels: object
    presets: PresetData[]
    rootCompId: string
    variations: any
    widgetApi: {
        functions: any[]
        propertiesSchemas: any[]
        events: any[]
    }
}

export interface PresetData {
    defaultSize: {
        height: UnitSize
        width: UnitSize
    }
    name: string
    presetId: string
}

export interface WidgetPointer {
    readonly id: string
}

export interface PresetPointer {
    readonly id: string
}

export interface AppStudioPreset {
    name: string
    pointer: PresetPointer
}

export interface PanelPointer {
    readonly id: string
}

export type DashboardPointer = Pointer

export interface BlocksDashboardData {
    id: string
    rootCompId: string
}

export interface BlocksDashboard extends BlocksDashboardData {
    pointer: DashboardPointer
}

export interface AppStudioPanel {
    name: string
    title: string
    helpId: string
    rootCompId: string
}

export interface ExtendedAppStudioPanel extends AppStudioPanel {
    pointer: Pointer
}

export interface AppStudioWidget {
    name: string
    pointer: WidgetPointer
}

export interface ContainingWidgetsMap {
    [widgetId: string]: string[]
}

export const validationErrorTypes = {
    INVALID_CHARS: 'INVALID_CHARS',
    EMPTY_NAME: 'EMPTY_NAME',
    INVALID_LENGTH: 'INVALID_LENGTH',
    ONLY_SPACES: 'ONLY_SPACES',
    ALREADY_EXISTS: 'ALREADY_EXISTS'
}

export const enum AppTypeE {
    WIDGETS = 'WIDGETS',
    CODE_PACKAGE = 'CODE_PACKAGE'
}

export type ValidationErrorTypes =
    | 'INVALID_CHARS'
    | 'EMPTY_NAME'
    | 'INVALID_LENGTH'
    | 'ONLY_SPACES'
    | 'NAME_ALREADY_EXISTS'
    | 'NOT_GUID'

export interface ValidationResult {
    success: boolean
    errorCode?: ValidationErrorTypes
}
export interface Dimensions {
    width?: number
    height?: number
}

export interface PresetDimensions {
    width?: UnitSize
    height?: UnitSize
}

export interface CreateWidgetOptions {
    widgetKind: WidgetKind
    widgetName?: string
    initialWidgetRootStructure?: CompStructure
    pluginDescriptor?: WidgetPluginDescriptor
    presetDescriptors?: PresetDescriptor[]
}

export interface PresetDescriptor {
    variantId: string
    name: string
}

export interface CodePackageData {
    displayName: string
    name: string
    description?: string
}

export const BuildTypes = {
    Minor: 'MINOR',
    Major: 'MAJOR',
    Test: 'TEST'
} as const
export type BuildType = ValueOf<typeof BuildTypes>

export type BlocksEntity = 'NONE' | 'WIDGET' | 'CONFIGURATION' | 'DASHBOARD' | 'PANEL'

export const AsyncBuildStatusE = {
    Started: 'STARTED',
    InProgress: 'IN_PROGRESS',
    Done: 'DONE',
    UserCancelled: 'USER_CANCELLED',
    Failed: 'FAILED',
    TimedOut: 'TIMED_OUT',
    ManuallyCanceled: 'MANUALLY_CANCELLED'
} as const

export type AsyncBuildStatus = ValueOf<typeof AsyncBuildStatusE>

export interface ApplicationErrorData {
    buildId: string
    serviceName?: string
    serviceMethod?: string
    cause?: {
        details?: {
            validationError: {
                fieldViolations: {
                    field: string
                    description: string
                    data: {errorMessage: string}
                }[]
            }
        }
    }
}
export const AsyncBuildErrorCodes = {
    BUILD_RECORD_NOT_FOUND: 'BUILD_RECORD_NOT_FOUND',
    UNAUTHORIZED_FOR_APP: 'UNAUTHORIZED_FOR_APP',
    MISSING_EXPERIMENT: 'MISSING_EXPERIMENT',
    NO_META_SITE_FOR_APP: 'NO_META_SITE_FOR_APP',
    META_SITE_NOT_IN_CORRECT_NAMESPACE: 'META_SITE_NOT_IN_CORRECT_NAMESPACE',
    APP_MISSING_BLOCKS_COMPONENT: 'APP_MISSING_BLOCKS_COMPONENT',
    BUILD_ALREADY_IN_PROGRESS: 'BUILD_ALREADY_IN_PROGRESS',
    NEW_DEPENDENCY_ON_MINOR_BUILD: 'NEW_DEPENDENCY_ON_MINOR_BUILD',
    MISSING_VARIATION_PAGE: 'MISSING_VARIATION_PAGE',
    MISSING_WIDGET_PAGE: 'MISSING_WIDGET_PAGE',
    EXTERNAL_SERVICE_ERROR: 'EXTERNAL_SERVICE_ERROR',
    UNAVAILABLE_CODE_PACKAGE_IMPORT_NAME: 'UNAVAILABLE_CODE_PACKAGE_IMPORT_NAME',
    MISSING_MASTER_PAGE_IN_SITE_METADATA: 'MISSING_MASTER_PAGE_IN_SITE_METADATA',
    FAILED_FETCHING_MASTER_PAGE_JSON_FILE: 'FAILED_FETCHING_MASTER_PAGE_JSON_FILE',
    FAIL_RESPONSE_FETCHING_MASTER_PAGE_JSON_FILE: 'FAIL_RESPONSE_FETCHING_MASTER_PAGE_JSON_FILE',
    TOO_MANY_REQUESTS: 'TOO_MANY_REQUESTS',
    FAILED_UPDATING_BUILD_FAILURE: 'FAILED_UPDATING_BUILD_FAILURE',
    PANEL_PUBLIC_URL_NOT_FOUND: 'PANEL_PUBLIC_URL_NOT_FOUND',
    BREAKING_CHANGE_ON_MINOR_BUILD: 'BREAKING_CHANGE_ON_MINOR_BUILD'
} as const
export type AsyncBuildErrorCode = ValueOf<typeof AsyncBuildErrorCodes>

export interface ApplicationError {
    code: AsyncBuildErrorCode
    description: string
    data: ApplicationErrorData
}

export interface AsyncBuildRecord {
    id: string
    appDefId: string
    dateCreated: string
    dateUpdated: string
    status: AsyncBuildStatus
    widgetManifestPathMap: {[key: string]: string}
    totalSteps: number
    currentStep: number
    stepsDescription: string[]
    appVersionType: BuildType
    version?: string
    revision?: string
    devCenterVersion?: string
    clonedGridAppId?: string
    errorDetails?: {applicationError: ApplicationError}
}

interface BlocksPreviewData {
    bobAppDefId?: string
    bobViewerScriptGA?: string
}

interface AppStudioBuildOptions {
    onSuccess(): void
    onError(error?: unknown): void
    versionType: string
    appName: string
    blocksVersion: string
    appDefId: string
    isAsyncBuild: boolean
    releaseNotes?: string
    publishRc?: boolean
    packageImportName?: string
}

export interface PlatformComponentData {
    compId: string
    compType: 'PLATFORM'
    compData: {
        platformComponentData: {
            baseUrls: object
            editorTranslationUrl: string
            viewerScriptUrl: string
            editorScriptUrl: string
        }
    }
}
export interface StudioWidgetComponentData {
    compId: string
    compType: 'STUDIO_WIDGET'
    compName: string
    compData: {
        studioWidgetComponentData: {
            blocksVersion: string
            essential: boolean
            pageJsonFilename: string
            studioWidgetId: string
            variations: object
        }
    }
}
export interface StudioComponentData {
    compId: string
    compType: 'STUDIO'
    compData: {
        studioComponentData: {
            baseUrls: {
                siteAssets: string
            }
            editorScriptUrl: string
            enableInEditor: boolean
            platformOnly: boolean
            routerServiceUrl: string
            siteHeaderUrl: string
            viewerScriptUrl: string
            viewerVerboseScriptUrl: string
            wixCodeGridId: string
            wixCodeInstanceId: string
            hideInAddPanel: boolean
        }
    }
}

export type DevCenterCompData = PlatformComponentData | StudioWidgetComponentData | StudioComponentData

export interface DevCenterAppData {
    appId: string
    components: DevCenterCompData[]
    name: string
    iconUrl: string
    latestVersion: string
    isPublished: boolean
    isWixApp: boolean
    dateCreated: string
    appStatus: string
    isEditable: boolean
    hideFromManagedApps: boolean
    isStandalone: boolean
    isCoreApp: boolean
}
interface WidgetPluginPlacement {
    appDefinitionId: string
    widgetId: string
    slotId: string
}

export interface WidgetPluginDescriptor {
    placements: WidgetPluginPlacement[]
}

export interface NewPresetOptions {
    callback?(presetPointer: PresetPointer): any
}
interface VariantPointer {
    id: never
    pageId: never
    type: never
}
export interface AppStudioObject {
    getEntityByPage(pageRef: Pointer): BlocksEntity
    displayGettingStartedView(callback?: Callback): void
    fetchAppData(appDefinitionId: string): Promise<DevCenterAppData>
    buildWithOptions(options: AppStudioBuildOptions): Promise<void>
    /** @deprecated Use buildWithOptions.  */
    build(
        onSuccess: () => void,
        onError: (error?: unknown) => void,
        versionType: string,
        appName: string,
        blocksVersion: string,
        appDefId: string,
        isAsyncBuild: boolean,
        releaseNotes?: string,
        publishRc?: boolean
    ): Promise<void>
    getBuildStatusById(buildId: string, appDefinitionId: string): Promise<AsyncBuildRecord>
    updateBlocksPreviewData(blocksPreviewData: BlocksPreviewData): void
    getLastBuildId(): string | undefined
    isBobApp(appDefinitionId: string): Promise<boolean>
    definitions: {
        getAllSerialized(): object[]
    }
    saveAppMetadata(): Promise<void>
    getDevSiteAppDefId(): string
    setDraftAppName(appName: string): void
    setAppStudioModel(appStudioModel: BlocksAppStudioModel): void
    appName: {
        get(): string
        set(appName: string): void
        clear(): void
        validate(appName: string): ValidationResult
        getUsedAppNames(onSuccess?: (appNames: string[]) => void, onError?: () => void): string[]
    }
    appDescription: {
        get(): string
        set(appDescription: string): void
        clear(): void
    }
    panels: {
        create(widgetPointer: WidgetPointer, name: string): PanelPointer
        duplicate(pointerToCopy: PanelPointer, widgetPointer: WidgetPointer): PanelPointer
        rename(pointer: PanelPointer, widgetPointer: WidgetPointer, name: string): void
        remove(pointer: PanelPointer, widgetPointer: WidgetPointer, callback: Function): void
        generateNewName(prefix: string, divider: string): string
        display(pointer: PanelPointer, callback: Function): void
        getAll(): ExtendedAppStudioPanel[]
        getPointerByRootCompId(rootCompId: string): PanelPointer
        getRootCompIdByPointer(panelPointer: PanelPointer): string
        setHelpId(pointer: PanelPointer, id: string): void
        setTitle(pointer: PanelPointer, id: string): void
        setHeight(pointer: PanelPointer, height: number): void
        validateTitle(title: string): ValidationResult
        validateHelpId(helpId: string): ValidationResult
        validatePanelName(name: string, widgetPointer: WidgetPointer): ValidationResult
        getCount(): number
        movePanel(widgetPointer: WidgetPointer, fromIndex: number, toIndex: number): void
    }
    ssrCache: {
        get(): boolean
        set(flag: boolean): void
    }
    dashboardPages: {
        create(callback?: Callback1<DashboardPointer>): DashboardPointer
        display(dashboardPointer: DashboardPointer, callback?: Callback): void
        remove(dashboardPointer: DashboardPointer, callback?: Callback1<PS>): void
        getAll(): BlocksDashboard[]
        isDashboardPage(pageRef: Pointer): boolean
        getByRootCompId(rootCompId: string): DashboardPointer
    }
    dependencies: {
        addDependency(appDefinitionId: string, dependencyInfo?: DependencyInfo): void
        removeDependency(appDefinitionId: string): void
        hasDependency(appDefinitionId: string): boolean
        setDependencies(dependencies: BlocksAppDependency[]): void
        getAllDependencies(): BlocksAppDependency[]
    }
    getRootAppWidgetByPage(pageRef: CompRef): CompRef | undefined
    isBlocksComponentPage(pageRef: Pointer): boolean
    widgets: {
        events: {
            set(widgetPointer: Pointer, events: WidgetAPIEvent[]): void
            get(pointer: WidgetPointer): WidgetAPIEvent[]
        }
        getCount(): number
        getPanels(widgetPointer: WidgetPointer): PanelPointer[]
        create(options?: CreateWidgetOptions, callback?: Callback1<Pointer>): string
        display(widgetPointer?: WidgetPointer, callback?: Function, presetPointer?: PresetPointer): void
        duplicate(
            widgetPointer: WidgetPointer,
            callback?: Callback2<Pointer, Map<string, string>>,
            options?: {widgetName: string}
        ): void
        remove(widgetPointer: WidgetPointer, callback?: Function): void
        getRootWidgetByPage(pageRef: CompRef): CompRef | undefined
        getAllSerialized(): SerializedWidget[]
        getAll(): AppStudioWidget[]
        getByRootCompId(rootCompId: string): WidgetPointer
        getRootCompId(widgetPointer: WidgetPointer): string
        getContainingWidgetsMap(): ContainingWidgetsMap
        getWidgetPlugin(widgetPointer: WidgetPointer): WidgetPluginDescriptor
        name: {
            get(widgetPointer: WidgetPointer): string
            set(widgetPointer: WidgetPointer, newName: string): void
        }
        propertiesSchemas: {
            set(pointer: WidgetPointer, propertiesSchemas: Record<string, any>): void
            get(pointer: WidgetPointer): Record<string, any>
        }
        setDefaultSize(widgetPointer: WidgetPointer, size: Dimensions, callback?: () => void): void
        getDefaultSize(widgetPointer: WidgetPointer): Dimensions | null
        presets: {
            getWidgetPresets(widgetPointer: WidgetPointer): AppStudioPreset[]
            create(widgetPointer: WidgetPointer, options?: NewPresetOptions): PresetPointer
            display(presetPointer: PresetPointer, widgetPointer: WidgetPointer): void
            name: {
                set(presetPointer: PresetPointer, name: string): void
                get(presetPointer: PresetPointer): string
            }
            defaultSize: {
                set(presetPointer: PresetPointer, defaultSize: PresetDimensions): void
                get(presetPointer: PresetPointer): PresetDimensions
            }
            remove(presetPointer: PresetPointer, widgetPointer: WidgetPointer): void
            getPresetVariantId(presetPointer: PresetPointer): string
            duplicate(
                originalPresetPointer: PresetPointer,
                widgetPointer: WidgetPointer,
                options: NewPresetOptions
            ): PresetPointer
            getPresetVariantPointer(presetPointer: PresetPointer, widgetPointer: WidgetPointer): VariantPointer
            movePreset(widgetPointer: WidgetPointer, fromIndex: number, toIndex: number): void
        }
        getAppWidgetRefFromPointer(pointer: WidgetPointer): CompRef
        moveWidget(fromIndex: number, toIndex: number): void
        getPanelsCount(widgetPointer: WidgetPointer): number
    }
    codePackages: {
        add(codePackageData: CodePackageData[]): void
        set(codePackageData: CodePackageData[]): void
        get(): CodePackageData[]
        remove(packageName: string): void
    }
    getAppType(): AppTypeE
}
