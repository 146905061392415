import type {Pointer, Pointers} from '@wix/document-services-types'
import type {CoreLogger, DAL, DeepFunctionMap} from '@wix/document-manager-core'
import type {MultilingualExtensionAPI, MultilingualTranslationsAPI} from '../multilingual'
import type {DataModelAPI, DataModelExtensionAPI} from '../dataModel/dataModel'
import _ from 'lodash'
import type {RelationshipsAPI} from '../relationships'
import {addPrefix, removePrefix} from '@wix/document-manager-utils'
import {DATA_TYPES_VALUES_WITH_HASH} from '../../constants/constants'

const MASTER_PAGE_ID = 'masterPage'
const DATA_TYPE = 'data'
const REF_PREFIX = '#'

export const BASIC_MENU_ITEM_TYPE = 'BasicMenuItem'
export const CUSTOM_MENU_TYPE = 'CustomMenu'

const PAGE_LINK_TYPE = 'PageLink'

interface TranslationItem {
    id: string
    label: string
    items: string[]
    link: string
    isVisible: true
    isVisibleMobile: true
    type: string
}

type TranslationsMap = Record<string, TranslationItem>
type LanguageData = Record<string, TranslationsMap>

const cleanRef = (ref: string) => removePrefix(ref, REF_PREFIX)
const createRef = (ref: string, namespace: string) =>
    DATA_TYPES_VALUES_WITH_HASH[namespace] ? addPrefix(ref, REF_PREFIX) : ref

function getItemFromDalInLanguage(pointers: Pointers, lang: string, id: string, dal: DAL) {
    const translatePointer = pointers.multilingualTranslations.translationDataItem(MASTER_PAGE_ID, lang, id)
    return dal.get(translatePointer) ?? dal.get({type: DATA_TYPE, id})
}

function getFlatItems(data: any) {
    const flattenItems = (items: any[]): any[] => {
        return items.reduce((acc, item) => {
            return [...acc, item, ...(item?.items ? flattenItems(item?.items) : [])]
        }, [])
    }

    return flattenItems(data?.items)
}

function createLangPagesMapper(
    multilingualTranslations: MultilingualTranslationsAPI,
    dataModel: DataModelAPI,
    langCodes: string[],
    menuId: string
): Record<string, Record<string, TranslationItem[]>> {
    const pageLinkToTranslationMap = {}

    langCodes.forEach((languageCode: string) => {
        const langCustomMainMenuData = dataModel.getItem(menuId, DATA_TYPE, MASTER_PAGE_ID, languageCode, false)
        const flatItems = langCustomMainMenuData ? getFlatItems(langCustomMainMenuData) : []

        flatItems
            .filter(item => item?.link && item.link.type === PAGE_LINK_TYPE)
            .forEach(item => {
                const {pageId} = item.link
                const translation = pageLinkToTranslationMap[languageCode] || {}
                if (!translation[pageId]) {
                    translation[pageId] = []
                }
                translation[pageId].push(item)
                pageLinkToTranslationMap[languageCode] = translation
            })
    })

    return pageLinkToTranslationMap
}

function createOldItemIdToNewItemIdMapper(
    dataModel: DataModelAPI,
    langMap: LanguageData,
    logger: CoreLogger,
    menuId: string
): Record<string, string> {
    const updatedItems = {}

    const dataMainMenu = dataModel.getItem(menuId, DATA_TYPE, MASTER_PAGE_ID)
    const flatItems = getFlatItems(dataMainMenu)

    flatItems
        .filter(item => item?.link && item.link.type === PAGE_LINK_TYPE)
        .forEach(dataMainLangItem => {
            const {id} = dataMainLangItem
            const {pageId} = dataMainLangItem.link

            _.forEach(langMap, lang => {
                const langItem = lang[pageId]
                if (langItem) {
                    const translatedId = langItem.id as unknown as string
                    if (id !== translatedId) {
                        updatedItems[translatedId] = id
                    }
                } else {
                    const langCode = _.keys(langMap)
                    logger.interactionStarted(
                        `the page id: ${pageId} don't exists in the CUSTOM_MAIN_MENU of this lang: ${langCode}`
                    )
                }
            })
        })

    return updatedItems
}

function updateRefsWithNewItemId(
    updatedItems: Record<string, string>,
    relationships: {
        getReferencesToPointer(pointer: Pointer, namespace?: string): Pointer[]
    },
    dal: DAL,
    logger: CoreLogger
) {
    _.forEach(updatedItems, (newItemId, oldItemId) => {
        const reference = relationships.getReferencesToPointer({type: DATA_TYPE, id: oldItemId})
        reference.forEach(ownerPointer => {
            const owner = _.cloneDeep(dal.get(ownerPointer))
            const list = _.get(owner, ['items'], [])
            const updatedList = _.map(list, ref =>
                cleanRef(ref) === oldItemId ? createRef(newItemId, DATA_TYPE) : ref
            )
            _.set(owner, ['items'], updatedList)
            dal.set(ownerPointer, owner)
            logger.interactionStarted(
                `set new ref in this pointer ${ownerPointer.id}, old items list: ${list}, new list: ${updatedList}`
            )
        })
    })
}

function removeDeprecatedItemId(updatedItems: Record<string, string>, dal: DAL, logger: CoreLogger) {
    _.forEach(updatedItems, (newItemId: string, oldItemId: string) => {
        dal.remove({id: oldItemId, type: DATA_TYPE})
        logger.interactionStarted(`remove this pointer from DAL ${oldItemId}`)
    })
}

function createNewTranslatedItem(
    updatedItems: Record<string, string>,
    langMap: LanguageData,
    pointers: Pointers,
    dal: DAL,
    logger: CoreLogger
) {
    _.forEach(langMap, (pages, lang) => {
        _.forEach(updatedItems, (newItemId, oldItemId) => {
            const oldItem = _.find(
                _.values(pages) as TranslationItem[],
                (obj: TranslationItem) => obj.id === oldItemId
            ) as TranslationItem | undefined
            if (oldItem) {
                const newPointer = pointers.multilingualTranslations.translationDataItem(
                    MASTER_PAGE_ID,
                    lang,
                    newItemId as string
                )
                const multilingualOldUnresolvedItem = getItemFromDalInLanguage(pointers, lang, oldItem.id, dal)
                const dataUnresolvedItem = dal.get({type: DATA_TYPE, id: newItemId})
                dal.set(newPointer, {...multilingualOldUnresolvedItem, id: newItemId, link: dataUnresolvedItem.link})
                logger.interactionStarted(
                    `set new translated pointer ${newPointer.id} that replace this data id ${oldItem?.id}`
                )
            }
        })
    })
}

export function validateDataToUpdate(
    updatedItems: Record<string, string>,
    relationships: {
        getReferencesToPointer(pointer: Pointer, namespace?: string): Pointer[]
    },
    dal: DAL,
    logger: CoreLogger
) {
    _.forEach(updatedItems, (newItemId, oldItemId) => {
        const reference = relationships.getReferencesToPointer({type: DATA_TYPE, id: oldItemId})
        const nonPageRef = reference.find(ownerPointer => {
            const owner = _.cloneDeep(dal.get(ownerPointer))
            return owner.type !== BASIC_MENU_ITEM_TYPE && owner.type !== CUSTOM_MENU_TYPE
        })
        if (nonPageRef) {
            delete updatedItems[oldItemId]
            logger.interactionStarted(
                `Item: ${oldItemId} has reference: ${nonPageRef.id} that is not from type ${BASIC_MENU_ITEM_TYPE} / ${CUSTOM_MENU_TYPE}.`
            )
        }
    })
}

function createFilteredLangMap(
    langMap: Record<string, Record<string, TranslationItem[]>>,
    logger: CoreLogger
): LanguageData {
    const filteredLangMap: LanguageData = {}
    _.forEach(langMap, (lang, langKey) => {
        filteredLangMap[langKey] = {}
        _.forEach(lang, (pages, pageKey) => {
            if (pages.length > 1) {
                logger.interactionStarted(`In lang: ${langKey} the page: ${pageKey} has several different links.`)
            } else {
                filteredLangMap[langKey][pageKey] = pages[0] as TranslationItem
            }
        })
    })
    return filteredLangMap
}

export function fixItemsWithDifferentIdAndSamePageId(
    pointers: Pointers,
    dal: DAL,
    extensionAPI: DeepFunctionMap,
    menuId: string,
    logger: CoreLogger
) {
    const {multilingualTranslations} = extensionAPI as MultilingualExtensionAPI
    const {relationships} = extensionAPI as RelationshipsAPI
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const langCodes = multilingualTranslations.getAllTranslationLanguages()
    const langMap: Record<string, Record<string, TranslationItem[]>> = createLangPagesMapper(
        multilingualTranslations,
        dataModel,
        langCodes,
        menuId
    )
    const langMapWithOneLinkOnlyForPage: LanguageData = createFilteredLangMap(langMap, logger)
    const updatedItems: Record<string, string> = createOldItemIdToNewItemIdMapper(
        dataModel,
        langMapWithOneLinkOnlyForPage,
        logger,
        menuId
    )
    if (!_.isEmpty(updatedItems)) {
        validateDataToUpdate(updatedItems, relationships, dal, logger)
        createNewTranslatedItem(updatedItems, langMapWithOneLinkOnlyForPage, pointers, dal, logger)
        updateRefsWithNewItemId(updatedItems, relationships, dal, logger)
        removeDeprecatedItemId(updatedItems, dal, logger)
    }
}
