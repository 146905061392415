import type {PlainObject} from '@wix/document-services-types'
import _ from 'lodash'

type ExportedComponent = PlainObject | undefined

interface GroupPropertiesResult {
    removed: string[]
    added: string[]
    stayed: string[]
}

interface CompareResult<T> {
    result: T
    isEqual: boolean
}

const groupProperties = (oldValue: PlainObject, newValue: PlainObject): GroupPropertiesResult => {
    const removed = []
    const added = []
    const stayed = []

    const oldValueKeys = new Set(Object.keys(oldValue))
    const newValueKeys = new Set(Object.keys(newValue))

    for (const oldValueKey of oldValueKeys) {
        if (newValueKeys.has(oldValueKey)) {
            stayed.push(oldValueKey)
        } else {
            removed.push(oldValueKey)
        }
    }

    for (const newValueKey of newValueKeys) {
        if (!oldValueKeys.has(newValueKey)) {
            added.push(newValueKey)
        }
    }

    return {
        removed,
        added,
        stayed
    }
}

function compareProperties(oldValue: PlainObject, newValue: PlainObject): CompareResult<any> {
    if (typeof newValue !== typeof oldValue) {
        return {result: newValue, isEqual: false}
    }

    if (_.isPlainObject(newValue)) {
        return compareObjects(oldValue, newValue)
    }

    const isEqual = _.isEqual(newValue, oldValue)
    return {result: newValue, isEqual}
}

function compareObjects(oldValue: PlainObject, newValue: PlainObject): CompareResult<PlainObject> {
    const result = {}
    let isEqual = true
    const {added, removed, stayed} = groupProperties(oldValue, newValue)

    for (const addedProperty of added) {
        isEqual = false
        result[`🟢 ${addedProperty}`] = newValue[addedProperty]
    }

    for (const removedProperty of removed) {
        isEqual = false
        result[`🔴 ${removedProperty}`] = oldValue[removedProperty]
    }

    for (const stayedProperty of stayed) {
        const {result: value, isEqual: isPropEqual} = compareProperties(
            oldValue[stayedProperty],
            newValue[stayedProperty]
        )

        let key = stayedProperty
        if (isPropEqual === false) {
            isEqual = false
            key = `🟠 ${stayedProperty}`
        }

        result[key] = value
    }

    return {result, isEqual}
}

export const getComponentsTracingValueLog = (
    oldValue: ExportedComponent,
    newValue: ExportedComponent
): ExportedComponent => {
    if (newValue === undefined) {
        return undefined
    }

    if (oldValue === undefined) {
        return newValue
    }

    return compareObjects(oldValue, newValue).result
}
