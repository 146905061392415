import type {CompRef, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import metaDataUtils from '../metaDataUtils'

function isCompTypeAllowedInHoverbox(ps, compType: string) {
    return (
        metaDataUtils.isContainerSuitableForNonRenderingState(compType) ||
        (!ps.extensionAPI.componentDefinition.isContainer(compType) &&
            metaDataUtils.isComponentSuitableForNonRenderingState(compType))
    )
}

function allChildrenAllowedInHoverBox(ps: PS, potentialChild: CompRef) {
    const recusiveChildrenPointers = ps.pointers.components.getChildrenRecursively(potentialChild)
    return _.every(recusiveChildrenPointers.concat(potentialChild), function (componentPointer) {
        const compType = metaDataUtils.getComponentType(ps, componentPointer)
        return isCompTypeAllowedInHoverbox(ps, compType)
    })
}

function allChildrenStructureAllowedInHoverbox(ps, potentialChildStructure) {
    if (!potentialChildStructure.components) {
        return isCompTypeAllowedInHoverbox(ps, potentialChildStructure.componentType)
    }

    const childrenTypes = metaDataUtils.getChildrenTypesDeep([potentialChildStructure])
    return _.every(childrenTypes, _.partial(isCompTypeAllowedInHoverbox, ps))
}

export default {
    canContainByStructure(ps: PS, compPointer: Pointer, potentialChildStructure) {
        return allChildrenStructureAllowedInHoverbox(ps, potentialChildStructure)
    },

    canContain(ps: PS, componentPointer: Pointer, potentialChild) {
        return allChildrenAllowedInHoverBox(ps, potentialChild)
    },

    mobileConversionConfig: {
        nestOverlayingSiblings: false,
        createMobilePresets: false,
        filterChildrenWhenHidden: true
    }
}
