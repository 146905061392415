import _ from 'lodash'
import {guidUtils} from '@wix/santa-core-utils'
import type {DAL} from '@wix/document-manager-core'
import type {Pointers} from '@wix/document-services-types'
const SCHEMAS_FOLDER = '.schemas'
const isSchemaFile = (filePath: string) => _.startsWith(filePath, `${SCHEMAS_FOLDER}/`)
const isUndoableFile = (filePath: string) => isSchemaFile(filePath)

function isUndoableField(fieldDef: any) {
    return fieldDef.type === 'pagelink' && fieldDef.plugin === 'dataBinding'
}

function assembleFieldsInSameOrder(nonUndoableFields: any, undoableFields: any) {
    const undoableFieldNamesToKeep = _.keys(undoableFields)
    const assembledFields = _.assign({}, nonUndoableFields, undoableFields)
    return _.omitBy(assembledFields, function (fieldDef, fieldName) {
        return isUndoableField(fieldDef) && !_.includes(undoableFieldNamesToKeep, fieldName)
    })
}

function assembleSchema(loadedContent: string, modifiedContent: string, undoableModifiedContent: string) {
    const parsedLoadedContent = JSON.parse(loadedContent || '{}')
    const nonUndoableSource = modifiedContent ? JSON.parse(modifiedContent) : parsedLoadedContent
    const undoableSource = undoableModifiedContent ? JSON.parse(undoableModifiedContent) : parsedLoadedContent
    const undoableFields = _.pickBy(undoableSource.fields, isUndoableField)

    return JSON.stringify(
        _.assign({}, nonUndoableSource, {
            fields: assembleFieldsInSameOrder(nonUndoableSource.fields, undoableFields)
        })
    )
}

const assembleUndoableFile = (
    filePath: string,
    loadedContent: string,
    modifiedContent: string,
    undoableModifiedContent: string
) => {
    if (isSchemaFile(filePath)) {
        return assembleSchema(loadedContent, modifiedContent, undoableModifiedContent)
    }
}

const generateUndoableId = (filePath: string) => guidUtils.getUniqueId(filePath, '-')
const setUndoableContent = (filesDal: any, dal: DAL, pointers: Pointers, filePath: string, content: string) => {
    let filePathId = filesDal.filePathToIdMap.get(filePath)
    if (!filePathId) {
        filePathId = generateUndoableId(filePath)
        filesDal.filePathToIdMap.set(filePath, filePathId)
    }

    const undoableContentPointer = pointers.wixCode.getUndoableModifiedFileContent(filePathId)
    dal.set(undoableContentPointer, content)
}

const getUndoableContent = (filesDal: any, dal: DAL, pointers: Pointers, filePath: string) => {
    const filePathId = filesDal.filePathToIdMap.get(filePath)
    if (!filePathId) {
        return null
    }
    const undoableContentPointer = pointers.wixCode.getUndoableModifiedFileContent(filePathId)
    return dal.get(undoableContentPointer)
}

const getUndoableContentByPath = (
    undoableContentByIdMap: Record<string, any>,
    undoablePathIdMap: Record<string, any>
) => {
    return _.mapValues(undoablePathIdMap, id => undoableContentByIdMap[id])
}

export {isUndoableFile, assembleUndoableFile, setUndoableContent, getUndoableContent, getUndoableContentByPath}
