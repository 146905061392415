import type {AppDefinitionId, ApplicationId, PS} from '@wix/document-services-types'
import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import {tpa, TpaUrlBuilder} from '@wix/santa-ds-libs'
import clientSpecMapService from './clientSpecMapService'
import siteMetadata from '../../siteMetadata/siteMetadata'
import component from '../../component/component'
import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'
import generalInfo from '../../siteMetadata/generalInfo'
import language from '../../siteMetadata/language'
import getEditorSdkUrl from '../../platform/core/getEditorSdkUrl'

const maxUrlLength = 2083

const getMultilingualParams = function (ps: PS) {
    const currentLanguageCode = language.current.get(ps)
    const originalLanguage = language.original.get(ps)
    const translationLanguages = language.getFull(ps)
    const currency = generalInfo.getCurrency(ps)
    const timeZone = generalInfo.getTimeZone(ps)
    let dateNumberFormat: string, isPrimaryLanguage: string
    if (currentLanguageCode) {
        if (_.get(originalLanguage, 'languageCode') === currentLanguageCode) {
            dateNumberFormat = originalLanguage.locale
            isPrimaryLanguage = 'true'
        } else {
            dateNumberFormat = _.get(_.find(translationLanguages, {languageCode: currentLanguageCode}), 'locale')
            isPrimaryLanguage = 'false'
        }
    }

    return {
        lang: currentLanguageCode,
        dateNumberFormat,
        isPrimaryLanguage,
        currency,
        timeZone
    }
}

function getSdkVersion(ps: PS) {
    const sdkAbsoluteURL = getEditorSdkUrl(ps)

    let sdkNumericVersion: string

    const serviceTopology = ps.dal.get(ps.pointers.general.getServiceTopology())

    if (sdkAbsoluteURL.startsWith(serviceTopology.scriptsDomainUrl)) {
        /*
            application could already baked semver-ish editorSDK version into the panel's url
            while not being able to handle a url-ish one (e.g. `&sdkVersion=https://localhost:3000/lib/editorSDK`)

            https://wix.slack.com/archives/CQGJP31CM/p1617619878102500

            because of that, we try to use numeric `editorSDK` version when possible,
            so panel will work properly for users and fail for developers when they'll use url-ish `&sdkVersion`

            when it will fail for developer, application needs to be tweaked to use `@wix/platform-editor-sdk/lib/loader`
            (instead of hard-coded `&sdkVersion` handling). contact #editor-platform-dev in case you need help with that
        */

        sdkNumericVersion = sdkAbsoluteURL.match(
            // original regex with named groups, that not yet supported by all browsers:
            // /\/js-platform-editor-sdk\/(?<version>\d+\.\d+\.\d+)\/lib\//i
            /\/js-platform-editor-sdk\/(\d+\.\d+\.\d+)\/lib\//i
        )?.[1]
    }

    return sdkNumericVersion || sdkAbsoluteURL
}

const getCSSPerBreakpointParamFromOptions = (options?: Record<string, any>) => {
    return options?.cssPerBreakpoint
        ? {
              cssPerBreakpoint: true
          }
        : {}
}

const getSettingsUrlByAppDefId = function (
    ps: PS,
    appDefId: AppDefinitionId,
    widgetId: string,
    origCompId: string,
    options?
) {
    const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefId)
    return getSettingsUrl_internal(ps, appData, widgetId, origCompId, options)
}

const getSettingsUrl_publicApi = function (
    ps: PS,
    applicationId: ApplicationId,
    widgetId: string,
    origCompId: string,
    options?
) {
    const appData = clientSpecMapService.getAppData(ps, applicationId)
    return getSettingsUrl_internal(ps, appData, widgetId, origCompId, options)
}

const getSettingsUrl_internal = function (ps: PS, appData, widgetId: string, origCompId: string, options?) {
    const isNewVersion = _.get(options, 'isNewVersion', false)
    const currentUrl = ps.siteAPI.getCurrentUrl()
    let baseEndPoint = appData.settingsUrl
    const widgetData = appData.widgets?.[widgetId]
    const overrideUrl = tpa.common.utils.getTpaOverrideMap(currentUrl, 'tpaSettingsUrlOverride')[widgetId]
    if (overrideUrl) {
        baseEndPoint = overrideUrl
    } else if (widgetData) {
        const widgetSettings = _.get(widgetData, 'settings')
        baseEndPoint = (isNewVersion ? _.get(widgetSettings, 'urlV2') : _.get(widgetSettings, 'url')) || baseEndPoint
    }
    const settingsWidth = _.get(options, 'width') ? options.width : appData.settingsWidth
    const locale = siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.LANGUAGE_CODE) || 'en'
    const multilingualParams = getMultilingualParams(ps)
    const debuggingParams = tpa.common.utils.getDebuggingParamsFromUrl(currentUrl)
    const isMobileForTpa =
        _.get(ps.siteAPI.getTpaCompPreviewData('sharedQueryParams'), 'deviceTypeForTPA', 'desktop') === 'mobile'
    const compData = component.data.get(ps, componentDetectorAPI.getComponentById(ps, origCompId))
    const cssPerBreakpoint = getCSSPerBreakpointParamFromOptions(options)

    let urlBuilder = new tpa.common.TpaUrlBuilder(baseEndPoint)
        .addInstance(appData.instance)
        .addLocale(locale)
        .addOrigCompId(origCompId)
        .addWidth(_.toString(settingsWidth))
        .addEndpointType('settings')
        .addViewMode('editor')
        .addCompId('tpaSettings')
        .addMultipleQueryParams(debuggingParams)
        .addQueryParamsFromFunc(addBiToUrlIfPossible, _.get(options, 'bi'))
        .addExternalId(_.get(compData, 'referenceId'))
        .addLang(multilingualParams.lang)
        .addDateNumberFormat(multilingualParams.dateNumberFormat)
        .addIsPrimaryLanguage(multilingualParams.isPrimaryLanguage)
        .addCurrency(multilingualParams.currency)
        .addTimeZone(multilingualParams.timeZone)
        .addOrigin(ps.runtimeConfig.origin)
        .addQueryParam('applicationId', String(appData.applicationId))
        .addQueryParam('appDefinitionId', appData.appDefinitionId)
        .addMultipleQueryParams(cssPerBreakpoint)
    if (ps.siteAPI.isMobileView() || isMobileForTpa) {
        urlBuilder = urlBuilder.addDeviceType('mobile')
    }

    const sdkVersion = getSdkVersion(ps)
    urlBuilder.addQueryParam('sdkVersion', sdkVersion)

    const artifactOverrides = santaCoreUtils.urlUtils.getArtifactOverrides(currentUrl.query)
    _.forEach(artifactOverrides, (value, key) => {
        urlBuilder.addQueryParam(key, value)
    })

    const commonConfig = ps.dal.get(ps.pointers.general.getCommonConfig())
    urlBuilder = urlBuilder.addCommonConfig(commonConfig)

    return urlBuilder.build()
}

const getSettingsModalUrl = function (ps: PS, urlParams, compId: string) {
    if (urlParams.url) {
        const appData = clientSpecMapService.getAppData(ps, urlParams.applicationId)
        const local = siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.LANGUAGE_CODE) || 'en'

        const currentUrl = ps.siteAPI.getCurrentUrl()
        const baseUrl = tpa.common.utils.appendProtocolToUrlIfNeeded(urlParams.url, currentUrl)
        const debuggingParams = tpa.common.utils.getDebuggingParamsFromUrl(currentUrl)
        const multilingualParams = getMultilingualParams(ps)
        const deviceTypeForTPA = _.get(
            ps.siteAPI.getTpaCompPreviewData('sharedQueryParams'),
            'deviceTypeForTPA',
            'desktop'
        )
        const deviceType = urlParams.deviceType === 'mobile' ? 'mobile' : deviceTypeForTPA
        const compData = component.data.get(ps, componentDetectorAPI.getComponentById(ps, urlParams.origCompId))
        const urlBuilder = new tpa.common.TpaUrlBuilder(baseUrl)
            .addLocale(local)
            .addOrigCompId(urlParams.origCompId)
            .addCompId(compId)
            .addDeviceType(deviceType)
            .addViewMode('editor')
            .addInstance(appData.instance)
            .addOrigin(urlParams.origin)
            .addMultipleQueryParams(debuggingParams)
            .addQueryParamsFromFunc(addBiToUrlIfPossible, _.get(urlParams, 'bi'))
            .setHash(urlParams.hash)
            .addExternalId(_.get(compData, 'referenceId'))
            .addLang(multilingualParams.lang)
            .addDateNumberFormat(multilingualParams.dateNumberFormat)
            .addIsPrimaryLanguage(multilingualParams.isPrimaryLanguage)
            .addCurrency(multilingualParams.currency)
            .addTimeZone(multilingualParams.timeZone)
            .addQueryParam('applicationId', String(appData.applicationId))
            .addQueryParam('appDefinitionId', appData.appDefinitionId)

        const sdkVersion = getSdkVersion(ps)
        urlBuilder.url.query.sdkVersion = sdkVersion

        const artifactOverrides = santaCoreUtils.urlUtils.getArtifactOverrides(currentUrl.query)
        _.forEach(artifactOverrides, (value, key) => {
            urlBuilder.addQueryParam(key, value)
        })

        return urlBuilder.build()
    }

    return null
}

const getSettingsModalParams = function (ps: PS, urlParams, panelParams) {
    const compId = santaCoreUtils.guidUtils.getUniqueId(undefined, undefined)
    panelParams.compId = compId
    panelParams.url = getSettingsModalUrl(ps, urlParams, compId)
    panelParams.title = panelParams.title || ''
    return panelParams
}

const addBiToUrlIfPossible = function (tpaUrlBuilder: TpaUrlBuilder, bi) {
    if (_.isObject(bi)) {
        try {
            bi = JSON.stringify(bi)
            const currentUrl = tpaUrlBuilder.build()
            if (currentUrl.length < maxUrlLength - bi.length) {
                tpaUrlBuilder.addCbi(bi)
            }
        } catch (e) {
            santaCoreUtils.log.error('could not add CBI param to URL:', e)
        }
    }
}

export default {
    getSettingsModalParams,
    getSettingsUrl_publicApi,
    getSettingsUrlByAppDefId,
    getSettingsModalUrl
}
