import type {PS} from '@wix/document-services-types'
import metaDataUtils from '../metaDataUtils'
import constants from '../../constants/constants'
import imageAPI from '../../media/imageAPI'

export default {
    rotatable: true,
    styleCanBeApplied: true,
    resizableSides(ps: PS, compPtr) {
        const comp = ps.dal.get(compPtr)
        const page = ps.pointers.components.getPageOfComponent(compPtr)
        if (metaDataUtils.isMaintainWPhotoAspectRatio(ps, comp, page.id)) {
            return []
        }
        return [
            constants.RESIZE_SIDES.TOP,
            constants.RESIZE_SIDES.LEFT,
            constants.RESIZE_SIDES.BOTTOM,
            constants.RESIZE_SIDES.RIGHT
        ]
    },
    layoutLimits(ps: PS, compPtr) {
        const comp = ps.dal.get(compPtr)
        const page = ps.pointers.components.getPageOfComponent(compPtr)
        const aspectRatio = metaDataUtils.isMaintainWPhotoAspectRatio(ps, comp, page.id)
            ? imageAPI.getStageAspectRatio(ps, compPtr)
            : null

        return {
            aspectRatio
        }
    },
    mobileConversionConfig: {
        isSuitableForProportionGrouping: true,
        category: 'photo',
        preserveAspectRatio(ps, comp, pageId) {
            return metaDataUtils.isMaintainWPhotoAspectRatio(ps, comp, pageId)
        }
    }
}
