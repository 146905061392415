import fullFunctionalityConfig from './fullFunctionalityConfig'
import responsiveConfig from './responsiveConfig'
import type {DSConfig} from '@wix/document-services-types'

const createFeedbackConfig = (base: Omit<DSConfig, 'origin'>): DSConfig => ({
    ...base,
    modulesList: base.modulesList,
    cedit: false,
    continuousSave: true,
    disableSave: true,
    disableAutoSave: true,
    isReadOnly: true,
    autosaveRestore: 'false',
    origin: 'debugRevision'
})

const responsive = createFeedbackConfig(responsiveConfig)
const classic = createFeedbackConfig(fullFunctionalityConfig)

export {responsive, classic}
