import {createHookDefinition} from '../../utils/hooksUtils'
import type {Pointer} from '@wix/document-services-types'
import type {HookEventData} from '../hooks/hooks'
import type {DalValue} from '@wix/document-manager-core'

export interface SuperGet {
    pointer: Pointer
}
export interface SuperHas {
    pointer: Pointer
}

export interface SuperSet {
    pointer: Pointer
    value: DalValue
}

export interface SuperRemove {
    pointer: Pointer
}

export interface SuperGetEvent extends SuperGet, HookEventData<'data_access_super_get'> {}
export interface SuperSetEvent extends SuperSet, HookEventData<'data_access_super_set'> {}
export interface SuperHasEvent extends SuperHas, HookEventData<'data_access_super_has'> {}
export interface SuperRemoveEvent extends SuperHas, HookEventData<'data_access_super_remove'> {}

export const DATA_ACCESS_HOOKS = {
    SUPER: {
        GET: createHookDefinition<'data_access_super_get', SuperGet>('data_access_super_get'),
        SET: createHookDefinition<'data_access_super_set', SuperSet>('data_access_super_set'),
        HAS: createHookDefinition<'data_access_super_has', SuperHas>('data_access_super_has'),
        REMOVE: createHookDefinition<'data_access_super_remove', SuperRemove>('data_access_super_remove')
    }
}
