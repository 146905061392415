import {
    CreateExtArgs,
    CreateExtensionArgument,
    DAL,
    PDAL,
    DalItem,
    DalValue,
    DmApis,
    DocumentDataTypes,
    Extension,
    ExtensionAPI,
    IndexKey,
    Namespace,
    PointerMethods,
    pointerUtils,
    SnapshotDal,
    ValidateValue
} from '@wix/document-manager-core'
import {updatePageDataItem, getValidPageUriSEO, isDuplicatePageUriSeo} from './page/pageData'
import type {
    CompLayout,
    Component,
    CompStructure,
    MetaData,
    PagesData,
    Pointer,
    PossibleViewModes,
    ResolvedReference,
    DeepStructure
} from '@wix/document-services-types'
import _ from 'lodash'
import reject from 'lodash/fp/reject'
import update from 'lodash/fp/update'
import {
    COMP_DATA_QUERY_KEYS_WITH_STYLE,
    DATA_TYPES,
    PAGE_DATA_TYPES,
    DM_POINTER_TYPES,
    MASTER_PAGE_ID,
    PAGE_SCHEMA,
    PAGE_TYPES,
    VIEW_MODES
} from '../constants/constants'
import {
    getPagePartKey,
    pageGetterFromFull,
    getPageDeepStructure,
    getDeepStructureForPageComponent
} from '../utils/pageUtils'
import {createDefaultMetaData, generateUniqueIdByType} from '../utils/dataUtils'
import {getTranslationInfoFromKey} from '../utils/translationUtils'
import type {RelationshipsAPI} from './relationships'
import type {MultilingualTranslationsAPI} from './multilingual'
import type {DefaultDefinitionsAPI} from './defaultDefinitions/defaultDefinitions'
import type {ComponentsAPI} from './components'
import type {SnapshotExtApi} from './snapshots'
import {constants, DataModelExtensionAPI, PageModel, SiteStructureAPI} from '..'
import type {DataModelAPI} from './dataModel/dataModel'
import {stripHashIfExists} from '../utils/refArrayUtils'
import type {RoutersAPI} from './routers'
import {isBlocksEditor} from '../utils/appUtils'
import {isPopup as isPagePopup} from './page/popupUtils'
import {guidUtils} from '@wix/santa-core-utils'
import type {MobileHintsAPI} from './mobileHints'
import {patchPageTranslations} from './page/language'
import {deepClone} from '@wix/wix-immutable-proxy'
import {ReportableError} from '@wix/document-manager-utils'
import {
    CONTAINER_LAYOUT_TYPES,
    ITEM_LAYOUT_TYPES,
    COMPONENT_LAYOUT_TYPES
} from './defaultDefinitions/layoutForComps/defaultResponsiveLayoutDefinitions'

export const PAGE_EVENTS = {
    PAGE: {
        DATA_UPDATE: 'PAGE_DATA_UPDATE',
        DATA_UPDATE_BEFORE: 'PAGE_DATA_UPDATE_BEFORE',
        PAGE_DATA_ADDED: 'PAGE_DATA_ADDED',
        PAGE_COMPONENT_ADDED: 'PAGE_COMPONENT_ADDED'
    }
}
export interface PartialPageDefinition {
    layout?: Partial<CompLayout>
    data: any
    styleId?: string
    style?: any
    componentType: string
    skin?: string
    type: string
    components: []

    mobileComponents?: []
}

const {getPointer, getInnerPointer} = pointerUtils
const pagePointerType = DM_POINTER_TYPES.pageDM
const deletedPagesMapPointerType = DM_POINTER_TYPES.deletedPagesMap
const ALL_PAGES_INDEX_ID = 'all'
const MASTER_PAGE_TYPE = 'Document'
const includeMasterPageFilterId = 'includeMasterPage'
const excludeMasterPageFilterId = 'excludeMasterPage'
const NON_PAGE_IDS = ['SITE_STRUCTURE']
const PAGE_TYPES_NO_MASTERPAGE = ['Page', 'AppPage']
const SCHEMA_LOOKUP_INDEX = 'schemaLookupIndex'
const NO_MATCH: string[] = []
export interface PageDataLanguages {
    pageId: string
    languageCode: string
}

const getIdsOfAllPages = (dal: DAL, includeMasterPage: boolean) => {
    const filterId = includeMasterPage ? includeMasterPageFilterId : excludeMasterPageFilterId
    const pages = dal.query(DATA_TYPES.data, dal.queryFilterGetters.getPageFilter(filterId))
    return _.map(pages, 'id')
}

const getAllCompsOnPage = (dal: DAL, pageId: string, viewMode: PossibleViewModes = 'DESKTOP') => {
    const indexKey = dal.queryFilterGetters.getPageCompFilter(pageId)
    return _.cloneDeep(dal.getIndexed(indexKey)[viewMode]) || {}
}

const isExistsBuilder = (dal: DAL) => (pageId: string) => {
    const data = dal.get(getPointer(pageId, DATA_TYPES.data))
    return data && ['Page', 'AppPage', 'Document'].includes(data.type)
}

const getPageAndSchemaIndex = (schemaTypeOrComponentType: string, namespace: string, pageId: string) =>
    `${namespace}^${schemaTypeOrComponentType}^${pageId}`

const createPointersMethods = ({dal}: DmApis): PointerMethods => {
    const getAllPagesIds = (includeMasterPage: boolean): string[] => getIdsOfAllPages(dal, includeMasterPage)
    const isExists = isExistsBuilder(dal)
    const getPageData = (pageId: string) =>
        isExists(pageId) ? getPointer(getPagePartKey(pageId, DATA_TYPES.data), pagePointerType) : null
    const getPageDesignData = (pageId: string) =>
        isExists(pageId) ? getPointer(getPagePartKey(pageId, DATA_TYPES.design), pagePointerType) : null
    const getPageProperties = (pageId: string) =>
        isExists(pageId) ? getPointer(getPagePartKey(pageId, DATA_TYPES.prop), pagePointerType) : null
    const getPageBehaviorsData = (pageId: string) =>
        isExists(pageId) ? getPointer(getPagePartKey(pageId, DATA_TYPES.behaviors), pagePointerType) : null
    const getPageBreakpointsData = (pageId: string) =>
        isExists(pageId) ? getPointer(getPagePartKey(pageId, DATA_TYPES.breakpoints), pagePointerType) : null
    const getPageTranslations = (pageId: string) =>
        isExists(pageId) ? getPointer(getPagePartKey(pageId, PAGE_SCHEMA.translations), pagePointerType) : null
    const getComponentsMapPointer = (pageId: string, viewMode: string) =>
        isExists(pageId) ? getPointer(getPagePartKey(pageId, viewMode), pagePointerType) : null

    const isPointerPageType = (pointer: Pointer) => pointer && pointer.type === pagePointerType
    const getNewPagePointer = (pageId: string) => getPointer(pageId, pagePointerType)
    const getPagePointer = (pageId: string) => (isExists(pageId) ? getNewPagePointer(pageId) : null)
    const getNonDeletedPagesPointers = (includeMaster: boolean) =>
        _.map(getAllPagesIds(includeMaster), pageId => getPointer(pageId, pagePointerType))
    const getDeletedPagesMapPointer = () => getPointer('deletedPagesMap', deletedPagesMapPointerType)
    const getInnerBackgroundRefPointer = (pageDataPointer: Pointer, viewMode: string) =>
        getInnerPointer(pageDataPointer, ['pageBackgrounds', viewMode, 'ref'])
    const getPointersByPageAndSchemaType = (schemaTypeOrComponentType: string, namespace: string, pageId: string) => {
        const indexKey: IndexKey = dal.queryFilterGetters[SCHEMA_LOOKUP_INDEX](
            getPageAndSchemaIndex(schemaTypeOrComponentType, namespace, pageId)
        )
        return dal.getIndexPointers(indexKey, namespace)
    }
    const getFixerVersionsQueryPointer = (pageId: string, viewMode: string = VIEW_MODES.DESKTOP) =>
        getPointer(pageId, viewMode, {innerPath: [COMP_DATA_QUERY_KEYS_WITH_STYLE.fixerVersions]})
    const getFixerVersionsPointer = (fixerVersionsQuery: string) =>
        getPointer(fixerVersionsQuery, DATA_TYPES.fixerVersions)
    const getHeaderPointer = () =>
        _.head(getPointersByPageAndSchemaType('responsive.components.HeaderSection', 'DESKTOP', 'masterPage'))
    const getFooterPointer = () =>
        _.head(getPointersByPageAndSchemaType('responsive.components.FooterSection', 'DESKTOP', 'masterPage'))
    const getMenuContainerPointer = () =>
        _.head(getPointersByPageAndSchemaType('wysiwyg.viewer.components.MenuContainer', 'DESKTOP', 'masterPage'))
    const page = {
        getPageData,
        getPageDesignData,
        getPageProperties,
        isPointerPageType,
        getNewPagePointer,
        getPagePointer,
        getPageTranslations,
        getPageBehaviorsData,
        getPageBreakpointsData,
        isExists,
        getNonDeletedPagesPointers,
        getComponentsMapPointer,
        getDeletedPagesMapPointer,
        getInnerBackgroundRefPointer,
        getPointersByPageAndSchemaType,
        getFixerVersionsQueryPointer,
        getFixerVersionsPointer,
        getHeaderPointer,
        getFooterPointer,
        getMenuContainerPointer
    }

    return {
        // @ts-expect-error
        page,
        general: {
            getDeletedPagesMapPointer
        }
    }
}

const createGetters = (dmApis: DmApis) => ({
    [pagePointerType]: (dal: PDAL, pointer: Pointer) => pageGetterFromFull(dmApis, pointer)
})

const getDocumentDataTypes = (): DocumentDataTypes => ({
    [pagePointerType]: {},
    [deletedPagesMapPointerType]: {}
})

const initialState = {
    [deletedPagesMapPointerType]: {
        [deletedPagesMapPointerType]: {}
    }
}

export type IsChangedFunc = (item: DalValue, namespace: string, fromSnapshot: SnapshotDal) => boolean

const createExtensionAPI = (extArgs: CreateExtArgs): PageExtensionAPI => {
    const {dal, pointers, extensionAPI, eventEmitter} = extArgs
    const defaultDefinitions = () => extensionAPI.defaultDefinitions as DefaultDefinitionsAPI
    const componentsAPI = () => extensionAPI.components as ComponentsAPI
    const {snapshots} = extensionAPI as SnapshotExtApi
    const isExists = isExistsBuilder(dal)
    const hasPageBeenLoaded = (pageId: string) => dal.has(pointers.structure.getPage(pageId, 'DESKTOP'))

    const getTranslatedDataItem = (id: string, pageId: string, languageCode?: string) => {
        const originalItemPointer = pointers.data.getDataItem(id, pageId)
        return pointers.data.getTranslatedData(originalItemPointer, languageCode)
    }
    const getTranslatedDataItemFromMaster = (id: string, languageCode?: string) =>
        getTranslatedDataItem(id, MASTER_PAGE_ID, languageCode)
    /**
     * This function also notifies the PAGE_DATA_ADDED event, which adds a menu item if needed
     * @param pagePointer
     * @param data
     * @param shouldAddMenuItem
     * @param languageCode
     */
    const addNewPageData = (
        pagePointer: Pointer,
        data: Record<string, any>,
        shouldAddMenuItem: boolean,
        languageCode?: string
    ) => {
        updatePageDataItem(extArgs, pagePointer, data, languageCode)
        //This function supports being used before or after the page component itself exists (we have both cases)
        if (dal.has(pagePointer)) {
            const {dataModel} = extensionAPI as DataModelExtensionAPI
            dataModel.linkDataToItemByType(pagePointer, pagePointer.id, DATA_TYPES.data)
        }
        eventEmitter.emit(PAGE_EVENTS.PAGE.PAGE_DATA_ADDED, pagePointer, shouldAddMenuItem)
    }
    const updatePageData = (
        pagePointer: Pointer,
        data: Record<string, any>,
        applyChangeToAllLanguages = false,
        languageCode?: string
    ) => {
        eventEmitter.emit(PAGE_EVENTS.PAGE.DATA_UPDATE_BEFORE, pagePointer, data)
        updatePageDataItem(extArgs, pagePointer, data, languageCode)
        eventEmitter.emit(PAGE_EVENTS.PAGE.DATA_UPDATE, pagePointer.id, data, languageCode, applyChangeToAllLanguages)
    }

    const getPageData = (pageId: string, languageCode?: string) => {
        return (extensionAPI as DataModelExtensionAPI).dataModel.getItem(
            pageId,
            DATA_TYPES.data,
            'masterPage',
            languageCode
        )
    }

    const pickFromPageData = (pageId: string, properties: string[], languageCode?: string) => {
        const dataItem = dal.get(getTranslatedDataItemFromMaster(pageId, languageCode))
        return dataItem ? _.pick(dataItem, properties) : dataItem
    }

    const buildDataPropertyGetter =
        (path: string | string[]) =>
        (pageId: string, options: PageOptions = {}) => {
            const pageDataItemPointer = getTranslatedDataItemFromMaster(pageId, options.languageCode)

            return dal.get(getInnerPointer(pageDataItemPointer, ([] as string[]).concat(path)))
        }

    const buildDataPropertyGetterWithoutML = (path: string | string[]) => (pageId: string) => {
        const pageDataItemPointer = pointers.data.getDataItemFromMaster(pageId)

        return dal.get(getInnerPointer(pageDataItemPointer, ([] as string[]).concat(path)))
    }
    const buildDataPropertySetter =
        (path: string | string[]) =>
        (pageId: string, value: any, options: PageOptions = {}) => {
            if (!isExists(pageId)) {
                throw new Error(`Can not set data for page '${pageId}' which doesn't exist`)
            }

            const pageDataItemPointer = getTranslatedDataItemFromMaster(pageId, options.languageCode)

            const innerDataPointer = getInnerPointer(pageDataItemPointer, ([] as string[]).concat(path))
            dal.set(innerDataPointer, value)
        }

    const buildDataRefPropertySetter =
        (path: string | string[]) =>
        (pageId: string, value: any, options: PageOptions = {}) => {
            if (!isExists(pageId)) {
                throw new Error(`Can not set data for page '${pageId}' which doesn't exist`)
            }

            if (!value) {
                buildDataPropertySetter(path)(pageId, undefined, options)
                return
            }

            const id = generateUniqueIdByType(DATA_TYPES.data, pageId, dal, pointers)
            const translatedItemPointer = getTranslatedDataItem(id, pageId, options.languageCode)
            const metaData = createDefaultMetaData({pageId})

            const dataItemValue = {id: translatedItemPointer.id, ...value, metaData}
            dal.set(translatedItemPointer, dataItemValue)
            buildDataPropertySetter(path)(pageId, `#${translatedItemPointer.id}`, options)
        }

    const buildDataRefPropertyGetter =
        (path: string | string[]) =>
        (pageId: string, options: PageOptions = {}) => {
            const refId = buildDataPropertyGetter(path)(pageId, options)
            const {relationships} = extensionAPI as RelationshipsAPI

            if (refId) {
                const refIdWithoutTranslation = _.last(
                    getTranslationInfoFromKey(relationships.getIdFromRef(refId))
                ) as string
                const translatedItemPointer = getTranslatedDataItemFromMaster(
                    refIdWithoutTranslation,
                    options.languageCode
                )
                return dal.get(translatedItemPointer)
            }

            return refId
        }

    const getHomepageId = () =>
        dal.get(getInnerPointer(pointers.data.getDataItemFromMaster(MASTER_PAGE_ID), ['mainPageId']))

    const getAllPagesIds = (includeMasterPage: boolean): string[] => getIdsOfAllPages(dal, includeMasterPage)

    const getMainPageId = () => {
        const {relationships} = extensionAPI as RelationshipsAPI
        const masterPageDataPointer = pointers.data.getDataItemFromMaster('masterPage')
        const masterPageData = dal.get(masterPageDataPointer)
        const {mainPage} = masterPageData
        return masterPageData.mainPageId || _.isString(mainPage) ? relationships.getIdFromRef(mainPage) : mainPage?.id
    }

    const setMainPageId = (pageId: string) => {
        if (!pointers.page.isExists(pageId)) {
            throw new Error(`pageId ${pageId} does not exist. Can not set as home page.`)
        }
        if (isPagePopup(extArgs, pageId)) {
            throw new Error("Can't set popup page as home page.")
        }

        const targetPageData = dal.get(pointers.data.getDataItem(pageId))

        if (targetPageData.parentPageId) {
            throw Error('Cannot set a page which is a child of another page as home page')
        }

        const siteStructureDataPointer = pointers.data.getDataItemFromMaster(constants.MASTER_PAGE_ID)
        const homePagePointer = getInnerPointer(siteStructureDataPointer, 'mainPage')
        const homePageIdPointer = getInnerPointer(siteStructureDataPointer, 'mainPageId')

        dal.set(homePagePointer, `#${pageId}`)
        dal.set(homePageIdPointer, pageId)
    }

    const getPageUrl = (pageId?: string, baseUrl?: string): string => {
        const actualBaseUrl = baseUrl || dal.get(pointers.documentServicesModel.getPublicUrl())
        const mainPageId = getHomepageId()

        if (!pageId || pageId === mainPageId) {
            return actualBaseUrl
        }

        const pageFullPath = (extensionAPI as PageExtensionAPI).page.hierarchy.getFullPath(pageId)
        const appendix = actualBaseUrl.endsWith('/') ? pageFullPath : `/${pageFullPath}`

        //Assuming urlFormat is always slash at this point
        return `${actualBaseUrl}${appendix}`
    }

    const getAllPagesIndexId = () => dal.queryFilterGetters.getPageCompFilter(ALL_PAGES_INDEX_ID)

    const getPageIndexId = (pageId: string | null) =>
        pageId ? dal.queryFilterGetters.getPageCompFilter(pageId) : getAllPagesIndexId()

    const pageTypes = ['Page', 'Document']
    const isPageByStructureType = (value: DalItem) => pageTypes.includes(value.type!)
    const removeMobileStructure = (pageId: string) => {
        const indexKey = dal.queryFilterGetters.getPageCompFilter(pageId)
        _.forEach(dal.query('MOBILE', indexKey), (value, id) => {
            if (!isPageByStructureType(value)) {
                dal.remove(getPointer(id, 'MOBILE'))
            }
        })
    }

    const getPageDataTranslations = () => {
        const multilingualAPI = extensionAPI.multilingualTranslations as MultilingualTranslationsAPI
        const pageIds = getAllPagesIds(false)
        const translationPointers = _.flatMap(pageIds, multilingualAPI.getTranslationsById)
        return _.map(translationPointers, ({id}) => {
            const [languageCode, pageId] = getTranslationInfoFromKey(id)
            return {languageCode, pageId}
        })
    }

    const isPartiallyLoaded = () => {
        const pageIds = getAllPagesIds(false)

        for (const pageId of pageIds) {
            if (!hasPageBeenLoaded(pageId)) {
                return true
            }
        }

        return false
    }

    const getLoadedPages = () => getAllPagesIds(true).filter(hasPageBeenLoaded)

    const addHeaderRefComponent = (pagePointer: Pointer): Pointer | undefined => {
        const headerSectionPointer = pointers.page.getHeaderPointer()
        if (!headerSectionPointer) {
            return
        }
        return componentsAPI().addComponent(
            pagePointer,
            defaultDefinitions().getDefaultInternalRef(headerSectionPointer)
        )
    }
    const addMenuContainerComponent = (pagePointer: Pointer): Pointer | undefined => {
        const menuContainerPointer = pointers.page.getMenuContainerPointer()
        if (!menuContainerPointer) {
            return
        }
        const defaultDef: any = defaultDefinitions().getDefaultInternalRef(menuContainerPointer)
        defaultDef.layouts = {
            componentLayout: deepClone(COMPONENT_LAYOUT_TYPES.HEIGHT_WIDTH_AUTO),
            containerLayout: deepClone(CONTAINER_LAYOUT_TYPES.FRACTION),
            itemLayout: deepClone(ITEM_LAYOUT_TYPES.FIXED_ITEM_LAYOUT)
        }
        return componentsAPI().addComponent(pagePointer, defaultDef)
    }

    const addFooterRefComponent = (pagePointer: Pointer): Pointer | undefined => {
        const footerSectionPointer = pointers.page.getFooterPointer()
        if (!footerSectionPointer) {
            return
        }
        return componentsAPI().addComponent(
            pagePointer,
            defaultDefinitions().getDefaultInternalRef(footerSectionPointer)
        )
    }

    const generateNewPagePointer = (viewMode: PossibleViewModes = 'DESKTOP', pageIdToCreate?: string): Pointer => {
        const allPageIds = getAllPagesIds(true)
        const deletedPagesMapPointer = pointers.general.getDeletedPagesMapPointer()
        const deletePages = dal.get(deletedPagesMapPointer)
        const usedPageIds = allPageIds.concat(_.keys(deletePages))
        const newPageId = pageIdToCreate ? pageIdToCreate : guidUtils.generateNewPageId(usedPageIds)

        return pointers.structure.getNewPage(newPageId, viewMode)
    }
    const isLandingPageData = (pageData: PagesData) => pageData.isLandingPage || pageData.isMobileLandingPage
    const addPageWithDefinition = (
        pageStructureDefinition: PartialPageDefinition,
        viewMode: PossibleViewModes = 'DESKTOP',
        pagePointer?: Pointer
    ): Pointer => {
        if (!pageStructureDefinition.componentType) {
            throw new Error('must contain page component type')
        }

        const isResponsive = dal.get(pointers.rendererModel.isResponsive())
        const pageDefinition = defaultDefinitions().createPageDefinition(pageStructureDefinition)
        const pageToAddPointer = pagePointer ?? generateNewPagePointer(viewMode)
        componentsAPI().setComponent(pageDefinition, pageToAddPointer.id, pageToAddPointer)
        if (isResponsive) {
            componentsAPI().addLayoutsAsResponsiveLayout(pageToAddPointer, pageDefinition.layouts)
            componentsAPI().addBreakpointVariants(pageToAddPointer, pageDefinition.breakpointVariants)
            if (pageDefinition.style) {
                componentsAPI().addStyles(pageToAddPointer, pageDefinition.style as object)
            }
        }
        addNewPageData(pageToAddPointer, pageDefinition.data, true)
        if (isResponsive) {
            if (!isLandingPageData(pageDefinition.data)) {
                addHeaderRefComponent(pageToAddPointer)
            }
            componentsAPI().addComponent(pageToAddPointer, defaultDefinitions().getResponsiveSectionDefaultStructure())
            if (!isLandingPageData(pageDefinition.data)) {
                addFooterRefComponent(pageToAddPointer)
            }
            addMenuContainerComponent(pageToAddPointer)
        }
        const {mobileHints} = extensionAPI as MobileHintsAPI
        mobileHints.addMobileHintsItem(pageToAddPointer.id)
        eventEmitter.emit(PAGE_EVENTS.PAGE.PAGE_COMPONENT_ADDED, pageToAddPointer)
        return pageToAddPointer
    }

    const addPage = (
        pageData: PagesData,
        viewMode: PossibleViewModes = constants.VIEW_MODES.DESKTOP,
        pagePointer?: Pointer
    ): Pointer => {
        const pageStructureDefinition = {
            componentType: 'mobile.core.components.Page',
            data: pageData
        } as PartialPageDefinition
        return addPageWithDefinition(pageStructureDefinition, viewMode, pagePointer)
    }

    const getOwnersOfItemsBasedOnQueryName = (queryName: string, pageId: string, viewMode: string): Pointer[] => {
        const {relationships} = extensionAPI as RelationshipsAPI
        const pageCompFilter = dal.queryFilterGetters.getPageCompFilter(pageId)
        const allQueryNameItemsInPage = dal.queryKeys(queryName, pageCompFilter)
        return _.flatMap(allQueryNameItemsInPage, id =>
            relationships.getOwningReferencesToPointer(getPointer(id, queryName), viewMode)
        )
    }

    const NAMESPACES_FOR_PAGES = _.keys(PAGE_DATA_TYPES).concat(_.values(VIEW_MODES))

    const getChangedPagesSinceLastSnapshot = (
        tag: string,
        namespacesToConsider = NAMESPACES_FOR_PAGES,
        isChangedFunc?: IsChangedFunc
    ) => {
        const fromSnapshot = snapshots.getLastSnapshotByTagName(tag) ?? snapshots.getInitialSnapshot()
        const diff = snapshots.getChangesFromSnapshot?.(fromSnapshot)
        const pageIdSet: Set<string> = new Set<string>()
        const addToChangedPages = (item: DalValue) => {
            const pageId = item.metaData?.pageId
            if (pageId) {
                pageIdSet.add(pageId)
            }
        }

        for (const type of namespacesToConsider) {
            const typeValues = diff?.[type]
            if (typeValues) {
                for (const id of Object.keys(typeValues)) {
                    const item = typeValues[id]
                    const wasDeleted = item === undefined
                    if (wasDeleted) {
                        const prevItem = fromSnapshot.getValue({type, id})
                        addToChangedPages(prevItem)
                    } else if (isChangedFunc) {
                        const isChanged = isChangedFunc(item, type, fromSnapshot)
                        if (isChanged) {
                            addToChangedPages(item)
                        }
                    } else {
                        addToChangedPages(item)
                    }
                }
            }
        }
        return [...pageIdSet.values()]
    }

    const getNonDeletedChangedPagePointersSinceLastSnapshot = (
        tag: string,
        namespacesToConsider?: string[],
        isChangedFunc?: IsChangedFunc
    ) => {
        const nonDeletedPagePointers = pointers.page.getNonDeletedPagesPointers(true)
        const changedPageIds = getChangedPagesSinceLastSnapshot(tag, namespacesToConsider, isChangedFunc)
        return nonDeletedPagePointers.filter(pointer => changedPageIds.includes(pointer.id))
    }

    const touchHomePageToPreventConcurrentHomepageDeletion = (): void => {
        // Create a conflict between page deletion and setting the home page
        // See https://jira.wixpress.com/browse/DM-4220
        // This may be removed if and when the deletion of dal items takes part in the conflict resolution mechanism
        // of the concurrent editing effort
        const homePagePointer = pointers.data.getDataItem('masterPage')
        dal.touch(homePagePointer)
    }

    const removePageFromPagesGroup = (pageId: string): void => {
        const collectionPtr = pointers.data.getDataItem('PAGES_GROUP_COLLECTION', 'masterPage')
        if (!collectionPtr) {
            return
        }
        const groupsPtr = getInnerPointer(collectionPtr, 'groups')
        const groupIds: string[] = dal.get(groupsPtr) ?? []
        const groupPointers = groupIds.map(id => pointers.data.getDataItem(stripHashIfExists(id), 'masterPage'))
        const isPageId = (s: string): boolean => stripHashIfExists(s) === stripHashIfExists(pageId)
        groupPointers.forEach(ptr => {
            dal.modify(ptr, update(['pages'], reject(isPageId)))
        })
    }

    const markPageAsDeleted = (pageId: string): void => {
        const deletedPagesMapPointer = pointers.general.getDeletedPagesMapPointer()
        const pageMap = dal.get(deletedPagesMapPointer) ?? {}
        dal.set(deletedPagesMapPointer, {
            ...pageMap,
            [pageId]: true
        })
    }

    const isPlatformPage = (pageId: string): boolean => {
        const pageData = getPageData(pageId)
        return !!(pageData.managingAppDefId || pageData.tpaApplicationId)
    }

    const canPageBeParent = (pageId: string, parentPageId: string): any => {
        let mutableParentId = parentPageId
        const pageData = dal.get(getPointer(pageId, DATA_TYPES.data))
        const {isPopup, pageUriSEO, tpaApplicationId, managingAppDefId} = pageData
        let currentParentData = dal.get(getPointer(mutableParentId, DATA_TYPES.data))
        const ancestorsList = new Set<string>()

        if (parentPageId && (isPlatformPage(pageId) || isPlatformPage(parentPageId))) {
            return [
                {
                    message: `Cannot set ${pageId} as a child of ${parentPageId} since it is a TPA page`,
                    type: 'tpaPageCannotBeChild',
                    shouldFail: true,
                    extras: {
                        currentParent: mutableParentId,
                        pageId,
                        tpaApplicationId,
                        managingAppDefId
                    }
                }
            ]
        }

        while (currentParentData) {
            const parentPointer = getPointer(mutableParentId, DATA_TYPES.data)

            if (ancestorsList.has(pageId)) {
                return [
                    {
                        message: `Cannot set ${pageId} as a child of ${currentParentData.parentPageId} since it will create a loop`,
                        type: 'parentChildLoopError',
                        shouldFail: true,
                        extras: {
                            currentParent: mutableParentId,
                            pageId
                        }
                    }
                ]
            }

            ancestorsList.add(currentParentData.id)
            mutableParentId = currentParentData.parentPageId
            currentParentData = dal.get(parentPointer)
        }

        const homepageId = dal.get(getInnerPointer(pointers.data.getDataItemFromMaster(MASTER_PAGE_ID), ['mainPageId']))

        if (parentPageId && pageId === homepageId) {
            return [
                {
                    message: `Cannot set ${pageId} as a child of ${parentPageId} since it is the homepage`,
                    type: 'homepageAsChildError',
                    shouldFail: true,
                    extras: {
                        parentPageId,
                        pageId
                    }
                }
            ]
        }

        const popupError = [
            {
                message: `Popup pages cannot be parents nor children of another page`,
                type: 'popupAsStaticUrlError',
                shouldFail: true,
                extras: {
                    parentPageId,
                    pageId
                }
            }
        ]

        if (isPopup && parentPageId) {
            return popupError
        }

        const parentPointer = getPointer(parentPageId, DATA_TYPES.data)
        const parentData = dal.get(parentPointer)
        if (parentData?.isPopup) {
            return popupError
        }

        const isDynamicChild = parentPageId && (extensionAPI.routers as RoutersAPI['routers'])?.isDynamicPage(pageId)
        const isDynamicParent =
            parentPageId && (extensionAPI.routers as RoutersAPI['routers'])?.isDynamicPage(parentPageId)
        if (isDynamicChild || isDynamicParent) {
            return [
                {
                    message: `Dynamic pages cannot have a static URL`,
                    type: 'staticUrlOnDynamicPageError',
                    shouldFail: true,
                    extras: {
                        pageId
                    }
                }
            ]
        }

        const custom404PageUriSeo = 'error404'
        const isCustom404Child = parentPageId && pageUriSEO === custom404PageUriSeo
        const isCustom404Parent = parentPageId && parentData.pageUriSEO === custom404PageUriSeo

        if (isCustom404Child || isCustom404Parent) {
            return [
                {
                    message: `Custom 404 pages cannot have a static URL`,
                    type: 'staticUrlOnCustom404PageError',
                    shouldFail: true,
                    extras: {
                        pageId
                    }
                }
            ]
        }
    }

    const isValidParentForPage = (pointer: Pointer, value: DalValue): any => {
        if (
            pointer.type !== 'data' ||
            !value ||
            !PAGE_TYPES_NO_MASTERPAGE.includes(value.type) ||
            !value.parentPageId
        ) {
            return false
        }

        return canPageBeParent(pointer.id, stripHashIfExists(value.parentPageId))
    }

    const removePage = (pageId: string): void => {
        const pageComponentPointer = pointers.structure.getPage(pageId, constants.VIEW_MODES.DESKTOP)
        removePageFromPagesGroup(pageId)
        ;(extensionAPI as SiteStructureAPI).siteStructure.removePageFromChildren(pageId)
        touchHomePageToPreventConcurrentHomepageDeletion()
        const fixerVersions = (extensionAPI.dataModel as unknown as DataModelAPI).components.getItem(
            pageComponentPointer,
            DATA_TYPES.fixerVersions
        )
        if (fixerVersions) {
            dal.remove(getPointer(fixerVersions.id, DATA_TYPES.fixerVersions))
        }
        const pageData = dal.get(getPointer(pageId, DATA_TYPES.data))
        if (pageData?.parentPageId) {
            patchPageTranslations(extArgs, pageId, {parentPageId: null})
        }
        ;(extensionAPI.components as ComponentsAPI).removeComponent(pageComponentPointer)
        ;(extensionAPI.routers as RoutersAPI['routers']).removePageFromRoutersConfigMap(pageId)
        markPageAsDeleted(pageId)
    }
    const isPage = (value: any) => isPageByStructureType(value)

    const getParentPage = (pageId: string): string | undefined => {
        return buildDataPropertyGetter('parentPageId')(pageId)
    }

    const getFullPath = (pageId: string) => {
        const path = []

        let pageDataFromDal: PageModel | undefined

        do {
            const parentPageId = stripHashIfExists(pageDataFromDal?.parentPageId ?? pageId)
            const pageDataPointer = pointers.getPointer(parentPageId, 'data')
            if (!isExists(pageDataPointer.id)) {
                throw new ReportableError({
                    message: `Cannot retrieve full path for page ${pageId} since the page ${parentPageId} does not exist`,
                    errorType: 'dsGetFullPath',
                    extras: {
                        pageId,
                        ...(pageDataFromDal && {parentPageId})
                    }
                })
            }
            pageDataFromDal = dal.get(pageDataPointer) as PageModel

            path.push(pageDataFromDal.pageUriSEO)
        } while (pageDataFromDal.parentPageId)

        return path.reverse().join('/')
    }

    return {
        page: {
            // getters
            getAdvancedSeoData: buildDataPropertyGetter('advancedSeoData'),
            getDescriptionSEO: buildDataPropertyGetter('descriptionSEO'),
            getPageTitleSEO: buildDataPropertyGetter('pageTitleSEO'),
            // wrapped to avoid adding unexpected possibility to get multilingual for this
            getIndexable: (pageId: string) => buildDataPropertyGetter('indexable')(pageId),
            getMetaKeywordsSEO: buildDataPropertyGetter('metaKeywordsSEO'),
            getPageUriSEO: buildDataPropertyGetter('pageUriSEO'),
            getOgImageRef: buildDataRefPropertyGetter('ogImageRef'),
            getIsLandingPage: buildDataPropertyGetter('isLandingPage'),
            getIsMobileLandingPage: buildDataPropertyGetter('isMobileLandingPage'),
            // setters
            setAdvancedSeoData: buildDataPropertySetter('advancedSeoData'),
            setDescriptionSEO: buildDataPropertySetter('descriptionSEO'),
            setPageTitleSEO: buildDataPropertySetter('pageTitleSEO'),
            setOgImageRef: buildDataRefPropertySetter('ogImageRef'),
            // wrapped to avoid adding unexpected possibility to set multilingual for this
            setIndexable: (pageId: string, value: boolean) => buildDataPropertySetter('indexable')(pageId, value),
            setMetaKeywordsSEO: buildDataPropertySetter('metaKeywordsSEO'),
            setPageUriSEO: buildDataPropertySetter('pageUriSEO'),
            getPageTitle: buildDataPropertyGetter('title'),
            getParentPageId: buildDataPropertyGetterWithoutML('parentPageId'),
            getMobileHidePage: buildDataPropertyGetter('mobileHidePage'),
            getAllPagesIds,
            getMainPageId,
            setMainPageId,
            getAllPagesIndexId,
            getPageIndexId,
            removeMobileStructure,
            isPartiallyLoaded,
            getLoadedPages,
            hasPageBeenLoaded,
            getAllCompsOnPage: (pageId: string, viewMode: PossibleViewModes) =>
                getAllCompsOnPage(dal, pageId, viewMode),
            addPage,
            addPageWithDefinition,
            generateNewPagePointer,
            data: {
                update: updatePageData,
                get: getPageData,
                pick: pickFromPageData,
                add: addNewPageData
            },
            hierarchy: {
                getParentPage,
                isValidParentForPage,
                getFullPath
            },
            getOwnersOfItemsBasedOnQueryName,
            getChangedPagesSinceLastSnapshot,
            getNonDeletedChangedPagePointersSinceLastSnapshot,
            removePage,
            isPage,
            getValidPageUriSEO: (pageId: string, initialPageUriSEO: string, languageCode?: string) =>
                getValidPageUriSEO(extArgs, pageId, initialPageUriSEO, languageCode),
            isPlatformPage,
            isDuplicatePageUriSeo: (excludePageId: string, pageUriSEO: string, languageCode?: string) =>
                isDuplicatePageUriSeo(extArgs, excludePageId, pageUriSEO, languageCode),
            getPageAsJson: (pageId: string) =>
                pageGetterFromFull(extArgs, getPointer(pageId, DM_POINTER_TYPES.pageDM), {convertToAbsolute: true}),
            getDeepStructureForPageInViewMode: (pageId: string, viewMode: PossibleViewModes) =>
                getDeepStructureForPageComponent(extArgs, pageId, viewMode, {convertToAbsolute: true})
        },
        siteAPI: {
            getPageUrl,
            getAllPagesIds,
            isPageContainsComponentType: (pageId, componentType) => {
                const pageCompFilter = dal.queryFilterGetters.getPageCompFilter(pageId)
                return !!dal.find(DATA_TYPES.data, pageCompFilter, value => value.type === componentType)
            },
            getPageDataTranslations,
            getDeepPageStructure: pageId => getPageDeepStructure(extArgs, pageId)
        }
    }
}
const isPage = (type: string | undefined) => (type ? !!PAGE_TYPES[type] : false)

const createFilters = () => ({
    getPageFilter: (namespace: string, value: DalItem): string[] => {
        const type = value?.type
        const id = value?.id ?? ''
        if (!type || namespace !== 'data' || NON_PAGE_IDS.includes(id)) {
            return NO_MATCH
        }

        if (isPage(type)) {
            return [includeMasterPageFilterId, excludeMasterPageFilterId]
        }
        if (type === MASTER_PAGE_TYPE) {
            return [includeMasterPageFilterId]
        }

        return NO_MATCH
    },
    getPageCompFilter: (namespace: string, value: any): string[] => {
        const pageId = value?.metaData?.pageId
        return pageId ? [pageId, ALL_PAGES_INDEX_ID] : NO_MATCH
    },
    [SCHEMA_LOOKUP_INDEX]: (namespace: Namespace, value: DalValue): string[] => {
        if (!value?.type || !value.metaData?.pageId) {
            return NO_MATCH
        }
        if (value?.componentType) {
            return [getPageAndSchemaIndex(value.componentType, namespace, value.metaData.pageId)]
        }
        return [getPageAndSchemaIndex(value.type, namespace, value.metaData?.pageId)]
    }
})

export interface PageOptions {
    languageCode?: string
}

export interface OgImage {
    width: number
    height: number
    uri: string
    type?: 'Image'
    alt?: string
    title?: string
    link?: string
    originalImageDataRef?: string
    metaData?: MetaData
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type PageAPI = {
    getAdvancedSeoData(pageId: string, options?: PageOptions): string
    getDescriptionSEO(pageId: string, options?: PageOptions): string
    getPageTitleSEO(pageId: string, options?: PageOptions): string
    getIndexable(pageId: string): boolean
    getMetaKeywordsSEO(pageId: string, options?: PageOptions): string
    getPageUriSEO(pageId: string, options?: PageOptions): string
    setAdvancedSeoData(pageId: string, value: string, options?: PageOptions): void
    setDescriptionSEO(pageId: string, value: string, options?: PageOptions): void
    setPageTitleSEO(pageId: string, value: string, options?: PageOptions): void
    setIndexable(pageId: string, value: boolean): void
    setMetaKeywordsSEO(pageId: string, value: string, options?: PageOptions): void
    setPageUriSEO(pageId: string, value: string, options?: PageOptions): void
    getAllPagesIds(includeMasterPage: boolean): string[]
    getOgImageRef(pageId: string, options?: PageOptions): OgImage
    setOgImageRef(pageId: string, value: OgImage, options?: PageOptions): void
    getIsLandingPage(pageId: string, options?: PageOptions): boolean | undefined
    getIsMobileLandingPage(pageId: string, options?: PageOptions): boolean | undefined
    getPageTitle(pageId: string, options?: PageOptions): string
    getParentPageId(pageId: string): string
    getMobileHidePage(pageId: string, options?: PageOptions): boolean
    getMainPageId(): string
    setMainPageId(pageId: string): void
    getAllPagesIndexId(): IndexKey
    getPageIndexId(pageId: string | null): IndexKey
    getAllCompsOnPage(pageId: string, viewMode: PossibleViewModes): Record<string, Component>
    removeMobileStructure(pageId: string): void
    isPartiallyLoaded(): boolean
    hasPageBeenLoaded(pageId: string): boolean
    getLoadedPages(): string[]
    addPage(pageData: PagesData, viewMode?: PossibleViewModes, pagePointer?: Pointer): Pointer
    addPageWithDefinition(
        pageStructureDefinition: CompStructure,
        viewMode?: PossibleViewModes,
        pagePointer?: Pointer
    ): Pointer
    generateNewPagePointer(viewMode?: PossibleViewModes, pageIdToCreate?: string): Pointer
    getOwnersOfItemsBasedOnQueryName(queryName: string, pageId: string, viewMode: string): Pointer[]
    getChangedPagesSinceLastSnapshot(
        tag: string,
        namespacesToConsider?: string[],
        isChangedFunc?: IsChangedFunc
    ): string[]
    getNonDeletedChangedPagePointersSinceLastSnapshot(
        tag: string,
        namespacesToConsider?: string[],
        isChangedFunc?: IsChangedFunc
    ): Pointer[]
    removePage(pageId: string): void
    isPage(value: any): boolean
    getValidPageUriSEO(pageId: string, initialPageUriSEO: string, languageCode?: string): string
    isPlatformPage(pageId: string): boolean
    isDuplicatePageUriSeo(excludePageId: string, pageUriSEO: string, languageCode?: string): boolean
    getPageAsJson(pageId: string): any
    getDeepStructureForPageInViewMode(pageId: string, viewMode: PossibleViewModes): DeepStructure | undefined
    data: {
        add(pagePointer: Pointer, data: Record<string, any>, shouldAddMenuItem?: boolean, language?: string): void
        update(
            pagePointer: Pointer,
            data: Record<string, any>,
            applyChangeToAllLanguages?: boolean,
            language?: string
        ): void
        get(pageId: string, languageCode?: string): Record<string, any>
        // This API was added to explicitly get properties from page data
        // so that the implementation can be performant by not resolving references or creating large objects
        pick(pageId: string, propeties: string[], languageCode?: string): Record<string, any>
    }
    hierarchy: {
        getParentPage(pageId: string): string | undefined
        isValidParentForPage(pointer: Pointer, value: DalValue): any
        getFullPath(pageId: string): string | undefined
    }
}

export interface PageExtensionAPI extends ExtensionAPI {
    page: PageAPI
    siteAPI: {
        getPageUrl(pageId?: string, baseUrl?: string): string
        getAllPagesIds(includeMasterPage: boolean): string[]
        isPageContainsComponentType(pageId: string, componentType: string): boolean
        getPageDataTranslations(): PageDataLanguages[]
        getDeepPageStructure(pageId: string): any
    }
}

const createExtension = (arg?: CreateExtensionArgument): Extension => {
    const createValidator = (apis: DmApis): Record<string, ValidateValue> => {
        const {dal, extensionAPI} = apis

        return {
            validatePageId: (pointer, value) => {
                const {id, type: dalValueType, metaData} = value ?? {}
                const {type: pointerType} = pointer
                if (pointerType === DATA_TYPES.data && isPage(dalValueType) && metaData.pageId !== MASTER_PAGE_ID) {
                    return [
                        {
                            message: `metadata.pageId of page ${id} should be masterPage and not ${metaData.pageId}`,
                            type: 'invalidMetadataPageId',
                            shouldFail: true,
                            extras: {id}
                        }
                    ]
                }
            },
            validateReverseSamePageRef: (pointer: Pointer, value: DalValue) => {
                const {relationships} = extensionAPI as RelationshipsAPI
                const pageId = value?.metaData?.pageId
                if (!pageId) {
                    return undefined
                }

                const referrers: Pointer[] = relationships.getReferencesToPointer(pointer)
                return _.compact(
                    referrers.map((parentRef: Pointer) => {
                        const parentValue = dal.get(parentRef)
                        const parentPageId = parentValue.metaData?.pageId
                        if (!parentPageId || parentPageId === pageId) {
                            return undefined
                        }
                        if (pageId === 'masterPage') {
                            return undefined
                        }
                        if (isPage(parentValue.type) && pageId === parentValue.id) {
                            // in case of page data, allow the reference to be on the page
                            return undefined
                        }
                        // In the blocks editor, when creating and editing widgets, refComponents are created that reference components in other pages.
                        // This is a valid flow in their widget creation process, so we exclude this rule for them.
                        if (
                            isBlocksEditor(arg) &&
                            parentValue.type === 'InternalRef' &&
                            parentValue.rootCompId === value.id
                        ) {
                            return undefined
                        }

                        return {
                            shouldFail: true,
                            type: 'ReverseSamePageRefError',
                            message: `${JSON.stringify(pointer)} in page "${pageId}" is referenced by ${JSON.stringify(
                                parentRef
                            )} in "${parentPageId}"`,
                            extras: {
                                from: parentRef,
                                to: pointer,
                                fromPageId: parentPageId,
                                toPageId: pageId,
                                parentValue,
                                value
                            }
                        }
                    })
                )
            },
            validateUniquePageUri: (pointer: Pointer, value: DalValue) => {
                if (pointer.type !== 'data' || !value || value.type !== 'Page') {
                    return undefined
                }

                const uri = value.pageUriSEO
                const allPageIds = getIdsOfAllPages(dal, false)
                const duplicate = _.find(allPageIds, pageId => {
                    if (pageId === pointer.id) {
                        return false
                    }
                    const pagePointer = getPointer(pageId, DATA_TYPES.data)
                    const pageData = dal.get(pagePointer)

                    return pageData.pageUriSEO === uri
                })

                if (duplicate) {
                    return [
                        {
                            message: `URI ${uri} is duplicate between ${pointer.id} and ${duplicate}`,
                            type: 'duplicatePageUriError',
                            shouldFail: true,
                            extras: {
                                uri,
                                duplicate
                            }
                        }
                    ]
                }
            },
            validateStaticPageUrl: (pointer: Pointer, value: DalValue) => {
                return (extensionAPI as PageExtensionAPI).page.hierarchy.isValidParentForPage(pointer, value)
            }
        }
    }

    const validateSamePageRef = (
        pointer: Pointer,
        value: DalItem,
        referred: DalItem,
        reference: ResolvedReference,
        shouldFail: boolean
    ) => {
        const originPageId = value.metaData?.pageId
        if (!originPageId) {
            return undefined
        }

        const referredPageId = referred.metaData?.pageId
        if (referredPageId === originPageId || referredPageId === 'masterPage') {
            return undefined
        }

        if (isPage(value.type) && referredPageId === value.id) {
            // in case of page data, allow the reference to be on the page
            return undefined
        }

        // In the blocks editor, when creating and editing widgets, refComponents are created that reference components in other pages.
        // This is a valid flow in their widget creation process, so we exclude this rule for them.
        if (isBlocksEditor(arg)) {
            if (value.type === 'InternalRef' && value.rootCompId === reference.id) {
                return undefined
            }
        }

        return [
            {
                shouldFail,
                type: 'wrongPageRefError',
                message: `${value.id} on page ${originPageId} referenced ${reference.id} on page ${referredPageId}`,
                extras: {
                    referred,
                    reference
                }
            }
        ]
    }

    const initialize = async ({extensionAPI}: DmApis) => {
        const {relationships} = extensionAPI as RelationshipsAPI
        relationships.registerCustomRefValidation((...args) => validateSamePageRef(...args, true))
    }

    const createPublicAPI = ({extensionAPI}: DmApis) => {
        const {page, siteAPI} = extensionAPI as PageExtensionAPI
        return {
            pages: {
                hierarchy: {
                    getFullPath: page.hierarchy.getFullPath
                },
                getPageUrl: siteAPI.getPageUrl
            }
        }
    }

    return {
        EVENTS: PAGE_EVENTS,
        name: 'page',
        dependencies: new Set(['serviceTopology', 'data', 'relationships', 'multilingual', 'documentServicesModel']),
        createExtensionAPI,
        createPointersMethods,
        getDocumentDataTypes,
        createGetters,
        createFilters,
        initialState,
        createValidator,
        initialize,
        createPublicAPI
    }
}

export {createExtension}
