import _ from 'lodash'
import warmupUtilsLib from '@wix/santa-core-utils'

const SESSION_TS_KEY = 'beatSessionTs'
const SESSION_ID_KEY = 'beatSessionId'
const SESSION_TTL_MS = 1000 * 60 * 30 // 30 minutes

function isExpired() {
    let res = false
    const now = Date.now()
    const session = get()

    if (isNaN(session.ts) || now - session.ts > SESSION_TTL_MS) {
        res = true
    }

    return res
}

function init() {
    try {
        const ts = Date.now()
        const id = warmupUtilsLib.guidUtils.getGUID()

        // @ts-ignore
        window.localStorage.setItem(SESSION_TS_KEY, ts)
        window.localStorage.setItem(SESSION_ID_KEY, id)

        return {ts, id}
    } catch (e) {
        return {ts: null, id: null}
    }
}

function extend() {
    try {
        const ts = Date.now()
        // @ts-ignore
        window.localStorage.setItem(SESSION_TS_KEY, ts)
        return ts
    } catch (e) {
        return null
    }
}

/**
 *  returns current session
 */
function get() {
    try {
        return {
            isNew: false,
            ts: parseInt(window.localStorage.getItem(SESSION_TS_KEY), 10),
            id: window.localStorage.getItem(SESSION_ID_KEY)
        }
    } catch (e) {
        return {isNew: false, ts: null, id: null}
    }
}

/**
 *  tracks a 30 minutes session and extends it whenever this function is called
 */
function track() {
    let isNew = false

    if (isExpired()) {
        init()
        isNew = true
    }

    extend()

    return _.assign(get(), {isNew})
}

export default {
    SESSION_TS_KEY,
    SESSION_ID_KEY,
    SESSION_TTL_MS,
    get,
    track
}
