const _ = require('lodash')
const dataUtils = require('../helpers/dataUtils')
const coreUtils = require('@wix/santa-core-utils')
const effectsUtils = require('../helpers/effectsUtils')
const {
    DATA_TYPES,
    NAMESPACE_MAPPING,
    COMP_DATA_QUERY_KEYS
} = require('@wix/document-services-json-schemas/dist/constants.json')
const MOBILE = 'MOBILE'
const DESKTOP = 'DESKTOP'
const TRIGGERS_TYPE = 'Triggers'
const REF_ARRAY_TYPE = 'RefArray'
const REACTIONS_TYPE = 'Reactions'
const EFFECTS_LIST_TYPE = 'EffectsList'
const PRESET_VARIANT_TYPE = 'Preset'
const MOBILE_VARIANT = 'MOBILE-VARIANT'
const BLOCKS_APP_DEF_ID = '46b2ad43-5720-41d2-8436-2058979cb53f'

const {
    createItemWithValues,
    createNamedEffectItem,
    createVariantRelation,
    createPlayReactionItem,
    createTimeAnimationItem,
    createTimeAnimationOptionsItem,
    createViewportEnterVariantItem,
    createEmptyTimeAnimationOptionsItem
} = effectsUtils

const getComponentsWithBehaviors = (structure, isMobile) => {
    return coreUtils.dataUtils.getAllCompsInStructure(structure, isMobile, comp => comp.behaviorQuery)
}

const getAllComponentsWithBehaviors = structure => {
    const desktopComponents = getComponentsWithBehaviors(structure, false)
    const mobileComponents = getComponentsWithBehaviors(structure, true)
    const desktopCompsIds = _.map(desktopComponents, 'id')
    const mobileCompsIds = _.map(mobileComponents, 'id')
    const allUniqueCompsWithBehavior = {...mobileComponents, ...desktopComponents}
    const mobileOnlyComponentsIds = _.difference(mobileCompsIds, desktopCompsIds)

    return {
        desktopComponents,
        mobileComponents,
        allUniqueCompsWithBehavior,
        mobileOnlyComponentsIds
    }
}

const addQueriesOnCompStructure = (structure, queriesAndIdsToSet) => {
    if (structure) {
        _.forEach(queriesAndIdsToSet, (key, value) => {
            structure[value] = key
        })
    }
}

const deleteBehaviorQuery = compStructure => {
    if (compStructure) {
        delete compStructure.behaviorQuery
    }
}

const migratePage = (structure, data, uniqueIdGenerator, clientSpecMap) => {
    data[NAMESPACE_MAPPING.effects] = data[NAMESPACE_MAPPING.effects] || {}
    data[NAMESPACE_MAPPING.variants] = data[NAMESPACE_MAPPING.variants] || {}
    data[NAMESPACE_MAPPING.triggers] = data[NAMESPACE_MAPPING.triggers] || {}
    data[NAMESPACE_MAPPING.reactions] = data[NAMESPACE_MAPPING.reactions] || {}

    const shouldWriteOnlyDefault = () => {
        const doesPageHaveResponsiveSection = _.some(
            structure.components,
            ({componentType}) => componentType === 'responsive.components.Section'
        )
        const isThereBreakpointDataOrRange = _.some(data[NAMESPACE_MAPPING.variants], ({type}) =>
            ['BreakpointsData', 'BreakpointRange'].includes(type)
        )

        return !!structure.breakpointVariantsQuery || doesPageHaveResponsiveSection || isThereBreakpointDataOrRange
    }

    const shouldWriteDefaultToAllPresets = () => {
        return (
            _.some(clientSpecMap, {appDefinitionId: BLOCKS_APP_DEF_ID}) &&
            _.some(data[NAMESPACE_MAPPING.variants], ({type}) => type === 'Preset')
        )
    }

    const {desktopComponents, mobileComponents, allUniqueCompsWithBehavior, mobileOnlyComponentsIds} =
        getAllComponentsWithBehaviors(structure)

    const getId = namespace => uniqueIdGenerator.getUniqueId(namespace, '-')

    const getOrCreateViewportEnterVariantAndTrigger = (componentId, triggersQuery) => {
        let viewportEnterVariantItem = _.find(data[NAMESPACE_MAPPING.variants], {
            trigger: 'viewport-enter',
            type: 'Trigger',
            componentId
        })

        if (!viewportEnterVariantItem) {
            viewportEnterVariantItem = createViewportEnterVariantItem(getId(DATA_TYPES.variants), componentId)
            data[NAMESPACE_MAPPING.variants][viewportEnterVariantItem.id] = viewportEnterVariantItem
        }

        if (triggersQuery) {
            data[NAMESPACE_MAPPING.triggers][triggersQuery] = dataUtils.updateItemWithValues(
                data[NAMESPACE_MAPPING.triggers][triggersQuery],
                [viewportEnterVariantItem.id]
            )

            return {viewportEnterVariantItem}
        }

        const triggerItem = effectsUtils.createItemWithValues(getId(DATA_TYPES.triggers), TRIGGERS_TYPE, [
            viewportEnterVariantItem.id
        ])
        data[NAMESPACE_MAPPING.triggers][triggerItem.id] = triggerItem

        return {viewportEnterVariantItem, triggerItem}
    }

    const setNamedEffect = behavior => {
        const namedEffectItem = createNamedEffectItem(getId(DATA_TYPES.effects), behavior)
        data[NAMESPACE_MAPPING.effects][namedEffectItem.id] = namedEffectItem
        const timeAnimationOptionsItem = createTimeAnimationOptionsItem(
            getId(DATA_TYPES.effects),
            behavior,
            namedEffectItem.id
        )
        data[NAMESPACE_MAPPING.effects][timeAnimationOptionsItem.id] = timeAnimationOptionsItem

        return timeAnimationOptionsItem
    }

    const setEffectsList = timeAnimationOptionsId => {
        const refArrayItem = createItemWithValues(getId(DATA_TYPES.effects), REF_ARRAY_TYPE, [timeAnimationOptionsId])
        data[NAMESPACE_MAPPING.effects][refArrayItem.id] = refArrayItem

        const timeAnimationItem = createTimeAnimationItem(getId(DATA_TYPES.effects), refArrayItem.id)
        data[NAMESPACE_MAPPING.effects][timeAnimationItem.id] = timeAnimationItem

        const effectsListItem = createItemWithValues(getId(DATA_TYPES.effects), EFFECTS_LIST_TYPE, [
            timeAnimationItem.id
        ])
        data[NAMESPACE_MAPPING.effects][effectsListItem.id] = effectsListItem

        return {timeAnimationItem, refArrayItem, effectsListItem}
    }

    const setEffectInVariant = (timeAnimationItemId, refArrayItem, isNamedEffect, behavior, variantId) => {
        const timeAnimationOptionsItem = isNamedEffect
            ? setNamedEffect(behavior)
            : createEmptyTimeAnimationOptionsItem(getId(DATA_TYPES.effects))
        data[NAMESPACE_MAPPING.effects][timeAnimationOptionsItem.id] = timeAnimationOptionsItem
        const variantRelationItem = createVariantRelation(
            getId(DATA_TYPES.effects),
            [variantId],
            timeAnimationOptionsItem.id,
            timeAnimationItemId
        )
        data[NAMESPACE_MAPPING.effects][variantRelationItem.id] = variantRelationItem
        data[NAMESPACE_MAPPING.effects][refArrayItem.id] = dataUtils.updateItemWithValues(
            data[NAMESPACE_MAPPING.effects][refArrayItem.id],
            [variantRelationItem.id]
        )
    }

    const setEffectInMobileVariant = (timeAnimationItemId, refArrayItem, isNamedEffect, behavior) =>
        setEffectInVariant(timeAnimationItemId, refArrayItem, isNamedEffect, behavior, MOBILE_VARIANT)

    const addReactionData = (componentId, effectId, variants, isDisabledReaction = false) => {
        const values = []
        if (!isDisabledReaction) {
            const playReactionItem = createPlayReactionItem(getId(DATA_TYPES.reactions), effectId)
            data[NAMESPACE_MAPPING.reactions][playReactionItem.id] = playReactionItem
            values.push(playReactionItem.id)
        }

        const reactionsListItem = createItemWithValues(getId(DATA_TYPES.reactions), REACTIONS_TYPE, values)
        data[NAMESPACE_MAPPING.reactions][reactionsListItem.id] = reactionsListItem

        const variantRelationItem = createVariantRelation(
            getId(DATA_TYPES.reactions),
            variants,
            reactionsListItem.id,
            componentId
        )
        data[NAMESPACE_MAPPING.reactions][variantRelationItem.id] = variantRelationItem

        return variantRelationItem.id
    }

    const addOrUpdateReactionRefArray = (variantRelationsIds, reactionsQuery) => {
        if (reactionsQuery) {
            data[NAMESPACE_MAPPING.reactions][reactionsQuery] = dataUtils.updateItemWithValues(
                data[NAMESPACE_MAPPING.reactions][reactionsQuery],
                variantRelationsIds
            )

            return reactionsQuery
        }

        const reactionsRefArrayItem = createItemWithValues(
            getId(DATA_TYPES.reactions),
            REF_ARRAY_TYPE,
            variantRelationsIds
        )
        data[NAMESPACE_MAPPING.reactions][reactionsRefArrayItem.id] = reactionsRefArrayItem

        return reactionsRefArrayItem.id
    }

    const addNamedInDefaultOnly = (compStructure, behavior) => {
        const componentId = compStructure.id
        const {triggersQuery, reactionsQuery} = compStructure
        const timeAnimationOptionsId = setNamedEffect(behavior).id
        const {timeAnimationItem, effectsListItem} = setEffectsList(timeAnimationOptionsId)
        const {viewportEnterVariantItem, triggerItem} = getOrCreateViewportEnterVariantAndTrigger(
            componentId,
            triggersQuery
        )
        const playReactionVariantRelationId = addReactionData(componentId, timeAnimationItem.id, [
            viewportEnterVariantItem.id
        ])
        const reactionRefArrayId = addOrUpdateReactionRefArray([playReactionVariantRelationId], reactionsQuery)

        return {
            [COMP_DATA_QUERY_KEYS.effects]: effectsListItem.id,
            ...(triggerItem && {[COMP_DATA_QUERY_KEYS.triggers]: triggerItem.id}),
            ...(reactionRefArrayId && {[COMP_DATA_QUERY_KEYS.reactions]: reactionRefArrayId})
        }
    }

    const addNamedInDefaultAndInMobile = (compStructure, behavior, mobileBehaviorOverride) => {
        const componentId = compStructure.id
        const {triggersQuery, reactionsQuery} = compStructure
        const timeAnimationOptionsId = setNamedEffect(behavior).id
        const {timeAnimationItem, refArrayItem, effectsListItem} = setEffectsList(timeAnimationOptionsId)
        const {viewportEnterVariantItem, triggerItem} = getOrCreateViewportEnterVariantAndTrigger(
            componentId,
            triggersQuery
        )
        setEffectInMobileVariant(timeAnimationItem.id, refArrayItem, true, mobileBehaviorOverride || behavior)
        const playReactionVariantRelationId = addReactionData(componentId, timeAnimationItem.id, [
            viewportEnterVariantItem.id
        ])
        const mobilePlayReactionVariantRelationId = addReactionData(componentId, timeAnimationItem.id, [
            viewportEnterVariantItem.id,
            MOBILE_VARIANT
        ])
        const reactionRefArrayId = addOrUpdateReactionRefArray(
            [playReactionVariantRelationId, mobilePlayReactionVariantRelationId],
            reactionsQuery
        )

        return {
            [COMP_DATA_QUERY_KEYS.effects]: effectsListItem.id,
            ...(triggerItem && {[COMP_DATA_QUERY_KEYS.triggers]: triggerItem.id}),
            ...(reactionRefArrayId && {[COMP_DATA_QUERY_KEYS.reactions]: reactionRefArrayId})
        }
    }

    const addNamedInDefaultAndEmptyInMobile = (compStructure, behavior) => {
        const componentId = compStructure.id
        const {triggersQuery, reactionsQuery} = compStructure
        const timeAnimationOptionsId = setNamedEffect(behavior).id
        const {timeAnimationItem, refArrayItem, effectsListItem} = setEffectsList(timeAnimationOptionsId)
        const {viewportEnterVariantItem, triggerItem} = getOrCreateViewportEnterVariantAndTrigger(
            componentId,
            triggersQuery
        )
        setEffectInMobileVariant(timeAnimationItem.id, refArrayItem, false)
        const playReactionVariantRelationId = addReactionData(componentId, timeAnimationItem.id, [
            viewportEnterVariantItem.id
        ])
        const disabledReactionVariantRelationId = addReactionData(
            componentId,
            timeAnimationItem.id,
            [viewportEnterVariantItem.id, MOBILE_VARIANT],
            true
        )
        const reactionRefArrayId = addOrUpdateReactionRefArray(
            [playReactionVariantRelationId, disabledReactionVariantRelationId],
            reactionsQuery
        )

        return {
            [COMP_DATA_QUERY_KEYS.effects]: effectsListItem.id,
            ...(triggerItem && {[COMP_DATA_QUERY_KEYS.triggers]: triggerItem.id}),
            ...(reactionRefArrayId && {[COMP_DATA_QUERY_KEYS.reactions]: reactionRefArrayId})
        }
    }

    const addEmptyInDefaultAndNamedInVariants = (compStructure, behavior, variantIds) => {
        const componentId = compStructure.id
        const defaultEmptyTimeAnimationOptionsItem = createEmptyTimeAnimationOptionsItem(getId(DATA_TYPES.effects))
        data[NAMESPACE_MAPPING.effects][defaultEmptyTimeAnimationOptionsItem.id] = defaultEmptyTimeAnimationOptionsItem
        const {timeAnimationItem, refArrayItem, effectsListItem} = setEffectsList(
            defaultEmptyTimeAnimationOptionsItem.id
        )
        const {triggersQuery, reactionsQuery} = compStructure
        const {viewportEnterVariantItem, triggerItem} = getOrCreateViewportEnterVariantAndTrigger(
            componentId,
            triggersQuery
        )
        let reactionRefArrayId

        for (let i = 0; i < variantIds.length; i++) {
            const variantId = variantIds[i]
            setEffectInVariant(timeAnimationItem.id, refArrayItem, true, behavior, variantId)
            const playReactionVariantRelationId = addReactionData(componentId, timeAnimationItem.id, [
                viewportEnterVariantItem.id,
                variantId
            ])
            reactionRefArrayId = addOrUpdateReactionRefArray(
                [playReactionVariantRelationId],
                reactionsQuery ?? reactionRefArrayId
            )
        }
        return {
            [COMP_DATA_QUERY_KEYS.effects]: effectsListItem.id,
            ...(triggerItem && {[COMP_DATA_QUERY_KEYS.triggers]: triggerItem.id}),
            ...(reactionRefArrayId && {[COMP_DATA_QUERY_KEYS.reactions]: reactionRefArrayId})
        }
    }

    const addEmptyInDefaultAndNamedInMobile = (compStructure, behavior) =>
        addEmptyInDefaultAndNamedInVariants(compStructure, behavior, [MOBILE_VARIANT])

    const collectPagePresetVariantsIds = () =>
        Object.keys(_.get(data, [NAMESPACE_MAPPING.variants])).filter(variantId => {
            const {componentId, type} = _.get(data, [NAMESPACE_MAPPING.variants, variantId])
            return type === PRESET_VARIANT_TYPE && componentId === structure.id
        })

    const shouldWriteToPresets = shouldWriteDefaultToAllPresets()
    const shouldWriteToDefault = shouldWriteOnlyDefault()

    _.forEach(allUniqueCompsWithBehavior, compStructure => {
        const {behaviorQuery} = compStructure
        let queriesToIdsMap
        const behaviorObject = data.behaviors_data[behaviorQuery]
        if (!behaviorObject) {
            deleteBehaviorQuery(desktopComponents[compStructure.id])
            deleteBehaviorQuery(mobileComponents[compStructure.id])
            return
        }
        const behaviorItems = JSON.parse(behaviorObject.items)

        if (!_.isArray(behaviorItems)) {
            return
        }

        const screenInItems = behaviorItems.filter(item => item.action === 'screenIn')

        if (_.isEmpty(screenInItems)) {
            return
        }

        if (shouldWriteToPresets) {
            const behaviorItem = _.find(screenInItems, {viewMode: DESKTOP}) ?? screenInItems[0]
            const presetVariantsIds = collectPagePresetVariantsIds()
            queriesToIdsMap = addEmptyInDefaultAndNamedInVariants(compStructure, behaviorItem, presetVariantsIds)
        } else if (shouldWriteToDefault) {
            const desktopBehavior = _.find(screenInItems, {viewMode: DESKTOP}) ?? screenInItems[0]
            queriesToIdsMap = addNamedInDefaultOnly(compStructure, desktopBehavior)
        } else if (screenInItems.length === 1) {
            //means that either it is a mobileOnly comp or it has animation in one viewMode only
            const behavior = screenInItems[0]
            if (behavior.viewMode === MOBILE) {
                //add empty effect for default and namedEffect for mobile variant
                queriesToIdsMap = addEmptyInDefaultAndNamedInMobile(compStructure, behavior)
            } else if (behavior.viewMode === undefined) {
                //if comp is mobileOnly then default should be empty effect and namedEffect should be set on the mobile variant
                if (_.includes(mobileOnlyComponentsIds, compStructure.id)) {
                    queriesToIdsMap = addEmptyInDefaultAndNamedInMobile(compStructure, behavior)
                } else {
                    //set same effect on both desktop and mobile variant
                    queriesToIdsMap = addNamedInDefaultAndInMobile(compStructure, behavior)
                }
            } else if (behavior.viewMode === DESKTOP) {
                queriesToIdsMap = addNamedInDefaultAndEmptyInMobile(compStructure, behavior)
            }
        } else if (screenInItems.length > 1) {
            const desktopBehavior = _.find(screenInItems, {viewMode: DESKTOP}) ?? screenInItems[0]
            const mobileBehavior = _.find(screenInItems, {viewMode: MOBILE}) ?? screenInItems[0]
            queriesToIdsMap = addNamedInDefaultAndInMobile(compStructure, desktopBehavior, mobileBehavior)
        }

        addQueriesOnCompStructure(desktopComponents[compStructure.id], queriesToIdsMap)
        addQueriesOnCompStructure(mobileComponents[compStructure.id], queriesToIdsMap)
        const itemsWithoutScreenIn = behaviorItems.filter(item => item.action !== 'screenIn')
        if (_.isEmpty(itemsWithoutScreenIn)) {
            delete data.behaviors_data[compStructure.behaviorQuery]
            deleteBehaviorQuery(desktopComponents[compStructure.id])
            deleteBehaviorQuery(mobileComponents[compStructure.id])
        } else {
            _.set(data, ['behaviors_data', compStructure.behaviorQuery, 'items'], JSON.stringify(itemsWithoutScreenIn))
        }
    })
}

module.exports = {
    name: 'migrateScreenInBehaviorsToEntranceEffects',
    version: 0,
    experimentalVersions: [{version: 1, experiment: 'dm_screenInBehaviorsToEntranceEffectsFixer'}],
    exec(pageJson, pageIdsArray, magicObject) {
        const {clientSpecMap} = magicObject
        const {uniqueIdGenerator} = magicObject.dataFixerUtils
        const pageBehaviorsData = pageJson.data.behaviors_data
        const pageEffectsData = pageJson.data.effects

        if (
            !magicObject.isExperimentOpen('dm_screenInBehaviorsToEntranceEffectsFixer') ||
            _.isEmpty(pageBehaviorsData) ||
            !_.isEmpty(pageEffectsData)
        ) {
            return
        }

        const {structure, data} = pageJson
        if (structure.id === 'masterPage') {
            data[NAMESPACE_MAPPING.data].masterPage.usesNewAnimations = true
        }
        migratePage(structure, data, uniqueIdGenerator, clientSpecMap)
    },
    fixerRequiresReruns: true
}
