import type {CoreLogger, LoggerDriver} from '@wix/document-manager-core'
import {ReportableError} from '@wix/document-manager-utils'
import {
    InvalidLastTransactionIdError,
    RepeatingLastTransactionIdError,
    TransactionAlreadyApproveError
} from './csaveErrors'
import {Long, isLong, longGt} from './long'

export class DuplexerOrderCheck {
    constructor(private lastId: Long, private logger: CoreLogger) {}

    check(transactionId: Long | null, correlationId: string, extras: Record<string, any>) {
        if (isLong(transactionId)) {
            if (!longGt(transactionId, this.lastId)) {
                this.logger.captureError(
                    new ReportableError({
                        message: `WARN duplexer order mismatch ${this.lastId} > ${transactionId}`,
                        errorType: 'duplexerOrderMismatch',
                        tags: {
                            csave: true,
                            csaveOp: 'duplexerOrderTest',
                            extras: {...extras, lastTxId: this.lastId, transactionId, correlationId}
                        }
                    })
                )
            } else {
                this.lastId = transactionId
            }
        }
    }
}

export const reportTransactionId = (
    logger: CoreLogger,
    log: LoggerDriver,
    transactionId?: Long,
    correlationId?: string,
    last?: Long
) => {
    if (!last) {
        console.log(`InvalidLastTransactionIdError last=${last}`)
    } else if (!transactionId || !isLong(transactionId) || transactionId === '0') {
        log.info(`InvalidLastTransactionIdError correlationId=${correlationId} transactionId=${transactionId}`)
        console.log(`InvalidLastTransactionIdError correlationId=${correlationId} transactionId=${transactionId}`)
        logger.captureError(new InvalidLastTransactionIdError(transactionId!))
    } else if (!longGt(transactionId!, last!)) {
        log.info(`TransactionAlreadyApproveError correlationId=${correlationId} transactionId=${transactionId}`)
        logger.captureError(
            new TransactionAlreadyApproveError({
                correlationId: `${correlationId}`,
                transactionId,
                lastTxId: last,
                source: 'duplexerOrderCheck'
            })
        )
    }
    if (transactionId === last) {
        logger.captureError(new RepeatingLastTransactionIdError(correlationId!, last))
    }
}
