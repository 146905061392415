import type {DalValue} from '../store'
import {debug} from '../../utils/debug'
import type {NullUndefined} from '../../types'

const log = debug('csave')

export const isConflicting = (
    newValue: DalValue,
    presentValue: DalValue,
    basedOnSignature: NullUndefined<string>
): boolean => {
    if (!newValue) {
        return false
    }

    const currentSig = presentValue?.metaData?.sig

    if (basedOnSignature === undefined && currentSig === undefined) {
        // signatures were not set for this object
        return false
    }

    if (presentValue) {
        const presentSignature = currentSig ?? null
        if (basedOnSignature !== presentSignature) {
            log.info('isConflicting', basedOnSignature, presentSignature)
        }
        return basedOnSignature !== presentSignature
    }

    // basedOnSignature is null when the previous value existed but had no signature (REPLACE or REMOVE instead of ADD)
    // If present value is missing and basedOnSignature is not undefined (including null) this is a conflict
    if (basedOnSignature !== undefined) {
        log.info('isConflicting', basedOnSignature)
    }
    return basedOnSignature !== undefined
}
