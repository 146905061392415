import {Extension, PointerMethods, pointerUtils, DocumentDataTypes} from '@wix/document-manager-core'
const {getPointer} = pointerUtils

const pointerType = 'mobile'

const createExtension = (): Extension => {
    const getDocumentDataTypes = (): DocumentDataTypes => ({[pointerType]: {}})

    const createPointersMethods = (): PointerMethods => {
        const getMobileStructuresPointer = () => getPointer('mobileStructures', pointerType)
        return {
            general: {
                getMobileStructuresPointer
            }
        }
    }

    return {
        name: 'mobile',
        createPointersMethods,
        getDocumentDataTypes
    }
}

export {createExtension}
