import {
    CreateExtArgs,
    CreateExtensionArgument,
    debug,
    DmApis,
    Extension,
    ExtensionAPI
} from '@wix/document-manager-core'
import _ from 'lodash'
import {EVENTS} from '../undoRedo'
import {CS_EVENTS, CSaveApi} from './continuousSave'

const log = debug('csave')

const DEBOUNCE_WAIT = 2000

export interface CSaveEventsApi extends ExtensionAPI {
    csaveEvents: {
        listenForCSaves(): void
        setCsaveDebounceWait(wait: number): void
    }
}

class CSaveEventsExtension implements Extension {
    readonly name: string = 'continuousSaveEvents'
    readonly dependencies: ReadonlySet<string> = new Set(['continuousSave'])
    private listeningForCSaves: boolean = false

    createExtensionAPI({eventEmitter, extensionAPI}: CreateExtArgs): ExtensionAPI {
        let debounceCSave = _.noop
        const {continuousSave} = extensionAPI as CSaveApi

        const csave = () => {
            if (continuousSave.shouldSave()) {
                continuousSave.save().catch(e => {
                    log.error('save events save', e)
                })
            }
        }

        const setCsaveDebounceWait = (wait: number) => {
            if (wait === 0) {
                debounceCSave = csave
            } else {
                debounceCSave = _.debounce(csave, wait)
            }
        }

        setCsaveDebounceWait(DEBOUNCE_WAIT)

        const listenForCSaves = () => {
            if (this.listeningForCSaves) {
                return
            }
            eventEmitter.addListener(CS_EVENTS.CSAVE.DO_CSAVE, () => {
                debounceCSave()
            })
            eventEmitter.addListener(EVENTS.UNDO_REDO.REDO, () => {
                debounceCSave()
            })
            eventEmitter.addListener(EVENTS.UNDO_REDO.UNDO, () => {
                debounceCSave()
            })
            this.listeningForCSaves = true
        }

        eventEmitter.addListener(CS_EVENTS.CSAVE.SITE_SAVED, async (isFirstSave: boolean) => {
            if (isFirstSave) {
                log.info('FIRST SAVE - start to listen for csave events')
                listenForCSaves()
            }
        })

        return {
            csaveEvents: {
                setCsaveDebounceWait,
                listenForCSaves
            }
        }
    }

    async initialize({extensionAPI}: DmApis): Promise<void> {
        const {csaveEvents} = extensionAPI as CSaveEventsApi
        csaveEvents.listenForCSaves()
    }
}

export const createExtension = ({dsConfig, environmentContext}: CreateExtensionArgument): Extension => {
    if (dsConfig.continuousSave) {
        if (!environmentContext?.serverFacade) {
            throw new Error('Illegal attempt to register CSave without providing server facade implementation')
        }
        return new CSaveEventsExtension()
    }
    return {
        name: 'continuousSaveEvents'
    }
}
