/**
 * Efficiently compares two objects that can be represented in JSON format.
 * Returns true if the objects are identical, else returns false
 * @param a
 * @param b
 * @returns {boolean}
 */
export function deepCompare(a: any, b: any): boolean {
    if (a === null || typeof a !== 'object') {
        return a === b
    }
    if (Array.isArray(a)) {
        if (!Array.isArray(b) || a.length !== b.length) {
            return false
        }
        for (let i = 0; i < a.length; ++i) {
            if (!deepCompare(a[i], b[i])) {
                return false
            }
        }
    } else {
        if (b === null || typeof b !== 'object' || Array.isArray(b)) {
            return false
        }
        let aKeyCount = 0
        // eslint-disable-next-line guard-for-in
        for (const key in a) {
            ++aKeyCount
            if (!b.hasOwnProperty(key) || !deepCompare(a[key], b[key])) {
                return false
            }
        }
        if (Object.keys(b).length !== aKeyCount) {
            return false
        }
    }
    return true
}
