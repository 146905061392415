import type {PS} from '@wix/document-services-types'
import * as santaCoreUtils from '@wix/santa-core-utils'
import generalInfo from '../../siteMetadata/generalInfo'
import type {DsFixer} from '../dsDataFixers'

function getSiteMembersSettingsPointer(ps: PS) {
    const siteStructureDataPointer = ps.pointers.data.getDataItemFromMaster(santaCoreUtils.siteConstants.MASTER_PAGE_ID)
    return ps.pointers.getInnerPointer(siteStructureDataPointer, 'smSettings')
}

function setSmSettingsData(ps: PS, data, force = false) {
    const siteMembersSettingsPointer = getSiteMembersSettingsPointer(ps)
    if (ps.dal.isExist(siteMembersSettingsPointer)) {
        if (force) {
            ps.dal.merge(siteMembersSettingsPointer, data)
        }
    } else {
        ps.dal.set(siteMembersSettingsPointer, data)
    }
}

function exec(ps: PS) {
    setSmSettingsData(
        ps,
        {
            socialLoginGoogleEnabled: true,
            socialLoginFacebookEnabled: true
        },
        generalInfo.isDraft(ps)
    )
}

const fixer: DsFixer = {
    exec,
    name: 'siteMembersDataFixer',
    version: 1,
    fixerRequiresReruns: true
}
export default fixer
