import constants from '../constants/constants'
import dataModel from '../dataModel/dataModel'
import type {Pointer, PS} from '@wix/document-services-types'

const LEGACY_FULL_WIDTH_CONTAINERS = {
    'wysiwyg.viewer.components.WSiteStructure': true,
    'wysiwyg.viewer.components.FooterContainer': true,
    'wysiwyg.viewer.components.HeaderContainer': true,
    'mobile.core.components.Page': true,
    'core.components.Page': true,
    'wixapps.integration.components.AppPage': true,
    'wysiwyg.viewer.components.ScreenWidthContainer': true,
    'wysiwyg.viewer.components.StripContainer': true,
    'wysiwyg.viewer.components.StripContainerSlideShow': true,
    'wysiwyg.viewer.components.StripContainerSlideShowSlide': true,
    'wysiwyg.viewer.components.StripColumnsContainer'(ps: PS) {
        return ps.runtimeConfig.viewerName !== 'tb'
    },
    'wysiwyg.viewer.components.ClassicSection': true,
    'wysiwyg.viewer.components.Column'(ps: PS, compPtr: Pointer) {
        if (ps.pointers.components.getViewMode(compPtr) === constants.VIEW_MODES.MOBILE) {
            return true
        }
        const isSingleColumn = ps.pointers.components.getSiblings(compPtr).length === 0

        const columnsContainer = ps.pointers.components.getParent(compPtr)
        const columnsContainerProperties = dataModel.getPropertiesItem(ps, columnsContainer)

        return isSingleColumn && columnsContainerProperties.fullWidth
    }
}

export default LEGACY_FULL_WIDTH_CONTAINERS
