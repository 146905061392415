import type {CreateExtArgs} from '@wix/document-manager-core'
import type {ExternalComponentsReadOnlyDriver, Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import type {DataModelExtensionAPI} from '../dataModel/dataModel'
import {DATA_TYPES, METADATA_TYPES} from '../../constants/constants'
import {ReportableError} from '@wix/document-manager-utils'
import type {MetadataMap} from './metadataMap'
import type {ComponentsExtensionAPI} from '../components'
import {getComponentType} from '../../utils/dalUtils'

export const EXTERNAL_META_DATA_ALLOWED_TYPES = [
    METADATA_TYPES.DISABLEABLE,
    METADATA_TYPES.ROTATABLE,
    METADATA_TYPES.MOVE_DIRECTIONS,
    METADATA_TYPES.RESIZABLE_SIDES,
    METADATA_TYPES.FIXED_POSITION,
    METADATA_TYPES.LAYOUT_LIMITS,
    METADATA_TYPES.MOBILE_CONVERSION_CONFIG,
    METADATA_TYPES.DEFAULT_MOBILE_PROPERTIES,
    METADATA_TYPES.IS_REPEATABLE,
    METADATA_TYPES.STYLE_CAN_BE_APPLIED,
    METADATA_TYPES.CAN_BE_STRETCHED,
    METADATA_TYPES.ALLOWED_CHILD_TYPES,
    METADATA_TYPES.ALLOWED_PARENT_TYPES,
    METADATA_TYPES.ENFORCE_CONTAINER_CHILD_LIMITS_BY_WIDTH,
    METADATA_TYPES.ENFORCE_CONTAINER_CHILD_LIMITS_BY_HEIGHT,
    METADATA_TYPES.IS_PUBLIC_CONTAINER,
    METADATA_TYPES.A11Y_CONFIGURABLE,
    METADATA_TYPES.WIDTH_AUTO,
    METADATA_TYPES.HEIGHT_AUTO,
    METADATA_TYPES.CAN_CONNECT_TO_CODE,
    METADATA_TYPES.CAN_CONTAIN,
    METADATA_TYPES.IS_CONTAIN_CHECK_RECURSIVE
]

export const isExternalMetaData = (metaDataMap: MetadataMap, componentType: string) => {
    return metaDataMap?.[componentType]?.isExternal ?? false
}

export const isExternalMetaDataField = (metaDataMap: MetadataMap, componentType: string, metaDataKey: string) => {
    return isExternalMetaData(metaDataMap, componentType) && metaDataKey in metaDataMap[componentType]
}

export const createExternalCompDriver = (
    {pointers, extensionAPI, dal}: CreateExtArgs,
    compPointer: Pointer
): ExternalComponentsReadOnlyDriver => {
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const {components} = extensionAPI as ComponentsExtensionAPI
    return {
        layout: {
            get: () => components.getComponentLayout(compPointer)
        },
        data: {
            get: () => dataModel.components.getItem(compPointer, DATA_TYPES.data)
        },
        properties: {
            get: () => dataModel.components.getItem(compPointer, DATA_TYPES.prop)
        },
        design: {
            get: () => dataModel.components.getItem(compPointer, DATA_TYPES.design)
        },
        isMobile: () => pointers.structure.isMobile(compPointer),
        getChildrenRecursively: (pointer: Pointer) => pointers.structure.getChildrenRecursively(pointer),
        getComponentType: (pointer: Pointer) => getComponentType(dal, pointer)
    }
}

export const informIllegalMetadataTypes = (
    {coreConfig}: CreateExtArgs,
    componentType: string,
    illegalKeys: string[],
    metaData: any
) => {
    const message = `Unable to use illegal meta data key(s) "${illegalKeys.join(
        ', '
    )}" for component "${componentType}"`

    coreConfig.logger.interactionEnded('illegalMetaDataKey', {
        message,
        tags: {
            metaDataRegistration: true
        },
        extras: {
            illegalKeys,
            metaDataToRegister: metaData
        }
    })
}

export const informUnableToRegisterAlreadyRegisteredComponent = (
    {coreConfig}: CreateExtArgs,
    componentType: string
) => {
    coreConfig.logger.captureError(
        new ReportableError({
            errorType: 'duplicateMetaDataRegistration',
            message: `Unable to register a component metaData for type "${componentType}" that is already registered`,
            tags: {
                metaDataRegistration: true,
                failedMetaDataRegistration: true
            },
            extras: {
                componentType
            }
        })
    )
}

export const filterAndFlagExternalMetadata = (metaData: any) => {
    const filtered = _.pick(metaData, EXTERNAL_META_DATA_ALLOWED_TYPES)
    return {
        ...filtered,
        isExternal: true
    }
}
