export const colorThemes = {
    emerald_twilight: {
        color_0: '#6DC82C',
        color_1: '#1281F4',
        color_2: '#D95493',
        color_3: '#728028',
        color_4: '#A6F4AB',
        color_5: '#89D423',
        color_6: '#A7D77D',
        color_7: '#272348',
        color_8: '#971FE4',
        color_9: '#22557B',
        color_10: '#83A7E8',
        color_11: '#C97888',
        color_12: '#96FCA4',
        color_13: '#957122',
        color_14: '#ED5CCC',
        color_15: '#B3C63B',
        color_16: '#D0089E',
        color_17: '#43A03C',
        color_18: '#1F2880',
        color_19: '#55B778',
        color_20: '#B88B07',
        color_21: '#DF9A5A',
        color_22: '#85A221',
        color_23: '#FF216D',
        color_24: '#959DE5',
        color_25: '#9424F2',
        color_26: '#FB9994',
        color_27: '#A2C2AB',
        color_28: '#5E94D9',
        color_29: '#1BEC3F',
        color_30: '#8EC502',
        color_31: '#B4C876',
        color_32: '#22C694',
        color_33: '#A53B03',
        color_34: '#B30483',
        color_35: '#58647F',
        color_36: '#AEA8B2',
        color_37: '#C59792',
        color_38: '#53272F',
        color_39: '#922678',
        color_40: '#6D11CC',
        color_41: '#4D5D7F',
        color_42: '#DBCCC0',
        color_43: '#39F8EF',
        color_44: '#CAE19F',
        color_45: '#E9E593',
        color_46: '#6ABF5E',
        color_47: '#BFF4A9',
        color_48: '#1F7949',
        color_49: '#30A678',
        color_50: '#BD295E',
        color_51: '#7C9488',
        color_52: '#60B235',
        color_53: '#5930A6',
        color_54: '#EF916C',
        color_55: '#4E0DA9',
        color_56: '#D4DD4A',
        color_57: '#3BC770',
        color_58: '#97DC2C',
        color_59: '#12226D',
        color_60: '#51D80B',
        color_61: '#612473',
        color_62: '#9CC82E',
        color_63: '#1E9E5C',
        color_64: '#D96EB6',
        color_65: '#DFC519'
    },
    sunset_serenade: {
        color_0: '#65ABD7',
        color_1: '#FA44AA',
        color_2: '#183777',
        color_3: '#DB3138',
        color_4: '#79D586',
        color_5: '#EF9CE7',
        color_6: '#D199F3',
        color_7: '#A53253',
        color_8: '#4C9D12',
        color_9: '#065B63',
        color_10: '#A4D7E1',
        color_11: '#EB3D13',
        color_12: '#9876C0',
        color_13: '#5AAFC7',
        color_14: '#842898',
        color_15: '#9C8517',
        color_16: '#A20C59',
        color_17: '#8C0DBB',
        color_18: '#E86FD2',
        color_19: '#4612C5',
        color_20: '#A68DF3',
        color_21: '#95763F',
        color_22: '#4C81A3',
        color_23: '#EAD1F0',
        color_24: '#677CA5',
        color_25: '#C36A69',
        color_26: '#D5E1CF',
        color_27: '#B9169C',
        color_28: '#A96BFC',
        color_29: '#CAABFD',
        color_30: '#E2CC31',
        color_31: '#BCD91A',
        color_32: '#0A64A9',
        color_33: '#E3004B',
        color_34: '#EB7A8E',
        color_35: '#D647A6',
        color_36: '#29B3E8',
        color_37: '#254B14',
        color_38: '#178C97',
        color_39: '#E7ABE5',
        color_40: '#DB0B85',
        color_41: '#98647D',
        color_42: '#3DA7E3',
        color_43: '#B34589',
        color_44: '#9BDC4E',
        color_45: '#381373',
        color_46: '#404B83',
        color_47: '#40684E',
        color_48: '#857939',
        color_49: '#DF56DE',
        color_50: '#93488E',
        color_51: '#2A762D',
        color_52: '#D88772',
        color_53: '#72B798',
        color_54: '#B92A93',
        color_55: '#31BB2D',
        color_56: '#2B8C6A',
        color_57: '#3DCC00',
        color_58: '#BFDA71',
        color_59: '#2C0F7F',
        color_60: '#E8BEE7',
        color_61: '#015696',
        color_62: '#BB3DA6',
        color_63: '#5B55F2',
        color_64: '#5EAA0E',
        color_65: '#609E4B'
    },
    oceanic_dreams: {
        color_0: '#8B89FF',
        color_1: '#37C0B1',
        color_2: '#02365E',
        color_3: '#D3517A',
        color_4: '#394E51',
        color_5: '#ED421A',
        color_6: '#982FDD',
        color_7: '#62A164',
        color_8: '#64B443',
        color_9: '#51C69C',
        color_10: '#0599BF',
        color_11: '#24C119',
        color_12: '#8C9EE3',
        color_13: '#B3A5B2',
        color_14: '#617C79',
        color_15: '#4E6FC3',
        color_16: '#9DC06A',
        color_17: '#1FDCDC',
        color_18: '#FD1ABE',
        color_19: '#393ED6',
        color_20: '#DD208D',
        color_21: '#CA75CA',
        color_22: '#C77E05',
        color_23: '#F9E375',
        color_24: '#1E4EBA',
        color_25: '#C5764B',
        color_26: '#AE0403',
        color_27: '#49ABA3',
        color_28: '#A39EE3',
        color_29: '#5C941F',
        color_30: '#D39572',
        color_31: '#496D79',
        color_32: '#299AA1',
        color_33: '#AE1C59',
        color_34: '#FE300B',
        color_35: '#54A2DE',
        color_36: '#F8D4AF',
        color_37: '#106309',
        color_38: '#B80119',
        color_39: '#65547A',
        color_40: '#155871',
        color_41: '#AEBFE2',
        color_42: '#2389A4',
        color_43: '#A9B66A',
        color_44: '#3E5A2C',
        color_45: '#37CE84',
        color_46: '#CC0E07',
        color_47: '#660793',
        color_48: '#FC79C6',
        color_49: '#A20488',
        color_50: '#FF2CE9',
        color_51: '#99C7BC',
        color_52: '#50C8EF',
        color_53: '#26FA5B',
        color_54: '#8E1A08',
        color_55: '#270C29',
        color_56: '#22A1E6',
        color_57: '#04E7FF',
        color_58: '#607A3A',
        color_59: '#4329DD',
        color_60: '#D5D84F',
        color_61: '#12572B',
        color_62: '#04F808',
        color_63: '#105489',
        color_64: '#14FD5C',
        color_65: '#F0B023'
    }
}
