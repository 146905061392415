import type {PS} from '@wix/document-services-types'
import * as santaCoreUtils from '@wix/santa-core-utils'
import pageData from '../page/pageData'
import {getDeepDataById, getShallowDataById} from './blocksDataModel'
import experiment from 'experiment-amd'

function getShallowMasterPageData(ps: PS) {
    return getShallowDataById(ps, santaCoreUtils.siteConstants.MASTER_PAGE_ID)
}

export function getShallowAppStudioData(ps: PS) {
    const masterPageShallowData = getShallowMasterPageData(ps)
    return getShallowDataById(ps, masterPageShallowData.appStudioData)
}

/**
 * Returns full appStudioData from masterPage.
 * Use wisely and with caution since it returns deep object.
 * This may cause performance issues.
 * Prefer `getShallowAppStudioData`
 * @param ps
 */
function getAppStudioData(ps: PS) {
    if (experiment.isOpen('dm_optimizeAppStudioModel')) {
        const masterPageShallowData = getShallowMasterPageData(ps)
        const appStudioData = getDeepDataById(ps, masterPageShallowData.appStudioData)

        if (!appStudioData) {
            return undefined
        }

        if (!appStudioData.dependencies) {
            return {
                ...appStudioData,
                dependencies: []
            }
        }

        return appStudioData
    }

    const masterPageData = pageData.getPageData(ps, santaCoreUtils.siteConstants.MASTER_PAGE_ID, true) || {}

    if (!masterPageData.appStudioData) {
        return undefined
    }

    if (!masterPageData.appStudioData.dependencies) {
        return {
            ...masterPageData.appStudioData,
            dependencies: []
        }
    }

    return masterPageData.appStudioData
}

export default getAppStudioData
