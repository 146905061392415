import _ from 'lodash'
import * as platformAPI from '@wix/platform-editor-sdk/lib/platform-api.min'

const getFunctionsForObj = function (path: string[], api) {
    return _.reduce(
        _.get(api, path),
        (result, value, key) => {
            const newPath = path.concat([key])
            if (_.isFunction(value) || !_.isObject(value)) {
                result[newPath.join('.')] = value
                return result
            }

            return _.merge(result, getFunctionsForObj(newPath, api))
        },
        {}
    )
}

function bindApiMethods(dataStructure, boundArgs) {
    if (_.isFunction(dataStructure)) {
        return _.partial(dataStructure, boundArgs)
    }
    if (_.isObject(dataStructure)) {
        return _.mapValues(dataStructure, childData => bindApiMethods(childData, boundArgs))
    }
    return dataStructure
}

const getAPIForSDK = function (apiToMerge) {
    const dsAPI = bindApiMethods({document: platformAPI}, window.documentServices)
    apiToMerge = apiToMerge || {}
    _.merge(dsAPI.document, apiToMerge)
    return _(dsAPI)
        .merge(apiToMerge)
        .merge(apiToMerge.editor)
        .reduce((result, value, key, api) => _.assign(result, getFunctionsForObj([key], api)), {})
}

export default {
    getAPIForSDK
}
