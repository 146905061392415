import {
    createStore,
    type CreateExtArgs,
    DmApis,
    DalValueChangeCallback,
    DalValue,
    ExtensionAPI,
    DmStore,
    DAL,
    CreateExtension,
    DeepFunctionMap
} from '@wix/document-manager-core'
import type {ImportExportExtensionApi} from './import-export/importExportExtension'
import type {
    CompRef,
    ComponentTraceLog,
    GetArgumentsType,
    GetReturnType,
    Pointer,
    PublicMethodDefinition
} from '@wix/document-services-types'
import type {RelationshipsAPI} from './relationships'
import {VIEW_MODES} from '../constants/constants'
import {getComponentsTracingValueLog} from '../utils/debugUtils'

const WRAPPED_BY_DEBUGGER_SYMBOL = Symbol('wrappedByDebugger')

const createExtension: CreateExtension = () => {
    let isRegisterNewComponents = false
    let hasChangesRegistered: boolean
    let registryStore = createStore()
    let registeredComponentsCount = 0
    let stack: string | undefined
    let exportComponentFunction: ExportComponentFunction | undefined

    const registerCustomComponentExport = (exportComponentFunc: ExportComponentFunction) => {
        exportComponentFunction = exportComponentFunc
    }

    const exportComponent = (componentPointer: CompRef, extensionAPI: ExtensionAPI) => {
        const {importExport} = extensionAPI as ImportExportExtensionApi
        const exportCompFunc = exportComponentFunction ?? importExport.json.exportComponent
        return exportCompFunc(componentPointer)
    }

    const registerComponent =
        (dal: DAL, extensionAPI: ExtensionAPI, isNewComponent = false) =>
        (componentPointer: CompRef) => {
            if (registryStore.has(componentPointer) || !dal.has(componentPointer)) return
            registryStore.set(componentPointer, {
                changed: false,
                currentState: isNewComponent ? undefined : exportComponent(componentPointer, extensionAPI),
                logs: []
            })
            registeredComponentsCount++
        }

    const registerNewComponents = () => {
        isRegisterNewComponents = true
    }

    const stopRegisteringNewComponents = () => {
        isRegisterNewComponents = false
    }

    const unregisterComponent = (componentPointer: CompRef) => {
        if (!registryStore.has(componentPointer)) return
        registryStore.deleteKey(componentPointer)
        registeredComponentsCount--
    }

    const unregisterAllComponents = () => {
        registryStore = createStore()
        registeredComponentsCount = 0
    }

    const getRegistryState = () => ({isRegisterNewComponents, registry: registryStore})

    const wrapMethodWithDebugger =
        (extensionAPI: ExtensionAPI) =>
        <Method extends PublicMethodDefinition['method']>(
            method: Method,
            methodPath: string
        ): Method | Wrapped<Method> => {
            if (!isRegisterNewComponents && registeredComponentsCount === 0) return method

            stack = new Error().stack

            if (method[WRAPPED_BY_DEBUGGER_SYMBOL]) return method

            const newMethod: Wrapped<Method> = (...args) => {
                hasChangesRegistered = false
                const result = method(...args)

                if (!isRegisterNewComponents && !hasChangesRegistered) return result

                const timestamp = window.performance.now()
                const changesStore = registryStore.filter((_, {changed}) => changed)

                changesStore.forEach((componentPointer, previousValue) => {
                    const currentState = exportComponent(componentPointer as CompRef, extensionAPI)
                    registryStore.set(componentPointer, {
                        changed: false,
                        currentState,
                        logs: previousValue.logs.concat({
                            args,
                            methodPath,
                            timestamp,
                            stack,
                            value: getComponentsTracingValueLog(previousValue.currentState, currentState)
                        })
                    })
                })

                return result
            }
            newMethod[WRAPPED_BY_DEBUGGER_SYMBOL] = true

            return newMethod
        }

    const getLogs = (componentPointer: CompRef): ComponentTraceLog[] => registryStore.get(componentPointer)?.logs ?? []

    const clearLogs = (componentPointer?: CompRef) => {
        if (componentPointer) {
            registryStore.set(componentPointer, {...registryStore.get(componentPointer), logs: []})
            return
        }
        registryStore.forEach((pointer, value) => {
            registryStore.set(pointer, {...value, logs: []})
        })
    }

    const createExtensionAPI = ({dal, extensionAPI}: CreateExtArgs): DebugExtensionAPI => {
        return {
            debug: {
                registerCustomComponentExport,
                registerComponent: registerComponent(dal, extensionAPI),
                registerNewComponents,
                stopRegisteringNewComponents,
                unregisterComponent,
                unregisterAllComponents,
                getRegistryState,
                wrapMethodWithDebugger: wrapMethodWithDebugger(extensionAPI as ImportExportExtensionApi),
                getLogs,
                clearLogs
            }
        }
    }

    const createChangesListener =
        ({dal, extensionAPI}: DmApis): DalValueChangeCallback =>
        (pointer: Pointer, oldValue: DalValue) => {
            if (registeredComponentsCount === 0 && !isRegisterNewComponents) return

            const {importExport, relationships} = extensionAPI as ImportExportExtensionApi & RelationshipsAPI

            if (isRegisterNewComponents && oldValue === undefined && VIEW_MODES[pointer.type]) {
                registerComponent(dal, importExport, true)(pointer as CompRef)
            }

            const componentPointers = VIEW_MODES[pointer.type]
                ? [pointer]
                : relationships.getOwningComponentsToPointer(pointer)

            componentPointers
                .filter(componentPointer => registryStore.has(componentPointer))
                .forEach(componentPointer => {
                    registryStore.set(componentPointer, {
                        ...registryStore.get(componentPointer),
                        changed: true
                    })
                    hasChangesRegistered = true
                })
        }

    const createPublicAPI = ({extensionAPI}: DmApis) => {
        const {debug} = extensionAPI as DebugExtensionAPI
        return {
            debug: {
                components: {
                    trace: {
                        register: debug.registerComponent,
                        unregister: debug.unregisterComponent,
                        unregisterAll: debug.unregisterAllComponents,
                        registerNewComponents: debug.registerNewComponents,
                        stopRegisteringNewComponents: debug.stopRegisteringNewComponents,
                        get: debug.getLogs,
                        clear: debug.clearLogs
                    }
                }
            }
        }
    }

    return {
        name: 'debug',
        createExtensionAPI,
        createChangesListener,
        createPublicAPI
    }
}

export {createExtension}

type ExportComponentFunction = (componentPointer: CompRef) => any

interface Wrapped<T> {
    (...args: GetArgumentsType<T>): GetReturnType<T>
}

export interface DebugAPI extends DeepFunctionMap {
    registerCustomComponentExport(exportComponentFunc: ExportComponentFunction): void
    registerComponent(componentPointer: CompRef): void
    registerNewComponents(): void
    stopRegisteringNewComponents(): void
    unregisterComponent(componentPointer: CompRef): void
    unregisterAllComponents(): void
    getRegistryState(): {
        isRegisterNewComponents: boolean
        registry: DmStore
    }
    wrapMethodWithDebugger<Method extends PublicMethodDefinition['method']>(
        method: Method,
        methodPath: string
    ): Method | Wrapped<Method>
    getLogs(componentPointer: CompRef): ComponentTraceLog[]
    clearLogs(componentPointer?: CompRef): void
}

export interface DebugExtensionAPI extends ExtensionAPI {
    debug: DebugAPI
}
