export function addExperimentsFromQuery(runningExperiments: any, queryUtil: any, project?: string, urlQueries?: any) {
    const projValuePairRegex = new RegExp(`^(${project}:)(.*)|(.*)$`)

    const experiments = {}
    Object.keys(runningExperiments).forEach(key => {
        experiments[key] = runningExperiments[key]
    })

    const setPair = ([key, value]: [string, string]) => {
        experiments[key] = value
    }
    const getValueIfProj = (str: string) => str.replace(projValuePairRegex, '$2')

    const filterParamsForCurrProj = (paramValue: string) =>
        paramValue.split(';').map(getValueIfProj).filter(Boolean).join(',')

    const setParamValues = (param: string, value: string) => {
        let paramValue = queryUtil.getParameterByName(param, urlQueries)
        if (paramValue.indexOf(':') !== -1) {
            // Should be deprecated
            paramValue = filterParamsForCurrProj(paramValue)
        }
        paramValue
            .split(',')
            .filter(Boolean)
            .map((exp: string) => [exp, value])
            .forEach(setPair)
    }

    const setProjParamExperiments = () => {
        queryUtil
            .getParameterByName(`${project}exp`, urlQueries)
            .split(',')
            .filter(Boolean)
            .map((exp: string) => exp.split(':'))
            .map(([key, value]: [string, string]) => [key, value || 'new'])
            .forEach(setPair)
    }

    //////////////////

    setParamValues('experiments', 'new')
    setParamValues('experimentsoff', 'old')
    setProjParamExperiments()

    return experiments
}
