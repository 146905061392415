/// <reference path="../../../node_modules/@types/lodash/index.d.ts" />
'use strict'

const _ = require('lodash')
const runOnAllCompsRecursively = require('../helpers/runOnAllCompsRecursively')

const MOBILE_MASTER_PAGE_VALID_COMPS = [
    'wysiwyg.viewer.components.HeaderContainer',
    'wysiwyg.viewer.components.SiteRegionContainer',
    'wysiwyg.viewer.components.PagesContainer',
    'wysiwyg.viewer.components.FooterContainer',
    'platform.components.AppController',
    'wysiwyg.viewer.components.tpapps.TPAGluedWidget',
    'wysiwyg.viewer.components.QuickActionBar',
    'wysiwyg.common.components.backtotopbutton.viewer.BackToTopButton',
    'wysiwyg.viewer.components.BackToTopButton',
    'wysiwyg.viewer.components.MenuContainer'
]

const hasInvalidSOAPInMobile = (/** @type {any} */ masterPageJson) => {
    const mobileMasterPageComps = _.get(masterPageJson, ['structure', 'mobileComponents'])

    return _.some(
        mobileMasterPageComps,
        ({componentType}) => !_.includes(MOBILE_MASTER_PAGE_VALID_COMPS, componentType)
    )
}

const isDesktopSectionsLayoutMigrated = (/** @type {any} */ masterPageJson) =>
    getLayoutSettings(masterPageJson).useDesktopSectionsLayout

const getLayoutSettings = (/** @type {any} */ masterPageJson) =>
    _.get(masterPageJson, ['data', 'document_data', 'masterPage', 'layoutSettings'], {})

const setLayoutMechanism = (/** @type {any} */ masterPageJson, /** @type {string} */ mechanism) =>
    _.set(masterPageJson, ['data', 'document_data', 'masterPage', 'layoutSettings', 'mechanism'], mechanism)
const getLayoutMechanism = (/** @type {any} */ masterPageJson) =>
    _.get(masterPageJson, ['data', 'document_data', 'masterPage', 'layoutSettings', 'mechanism'], 'anchors')

const getSoapCompsAroundPagesContainerFlag = (/** @type {any} */ masterPageJson) =>
    _.get(
        masterPageJson,
        ['data', 'document_data', 'masterPage', 'layoutSettings', 'soapCompsAroundPagesContainer'],
        false
    )
// @ts-ignore
const setSoapCompsAroundPagesContainerFlag = (/** @type {any} */ masterPageJson, /** @type {boolean} */ value) =>
    _.set(
        masterPageJson,
        ['data', 'document_data', 'masterPage', 'layoutSettings', 'soapCompsAroundPagesContainer'],
        value
    )

const isDesktopMasterPageReadyToMesh = (/** @type {any} */ masterPageJson) =>
    isDesktopSectionsLayoutMigrated(masterPageJson)
const isMobileMasterPageReadyToMesh = (/** @type {any} */ masterPageJson) => !hasInvalidSOAPInMobile(masterPageJson)

const rejectMeshIfNeeded = (/** @type {any} */ masterPageJson) => {
    if (
        getLayoutMechanism(masterPageJson) === 'mesh' &&
        (!isDesktopMasterPageReadyToMesh(masterPageJson) || !isMobileMasterPageReadyToMesh(masterPageJson))
    ) {
        setLayoutMechanism(masterPageJson, 'anchors')
    }
}

const SOSP_COMP_TYPE = 'wysiwyg.viewer.components.SiteRegionContainer'

const MASTER_PAGES_SECTIONS = {
    MOBILE: [
        'wysiwyg.viewer.components.HeaderContainer',
        'wysiwyg.viewer.components.SiteRegionContainer',
        'wysiwyg.viewer.components.PagesContainer',
        'wysiwyg.viewer.components.FooterContainer'
    ],
    DESKTOP: [
        'wysiwyg.viewer.components.HeaderContainer',
        'wysiwyg.viewer.components.PagesContainer',
        'wysiwyg.viewer.components.FooterContainer'
    ]
}

const getMasterPageSectionsTypes = (/** @type {any} */ isMobile) =>
    MASTER_PAGES_SECTIONS[isMobile ? 'MOBILE' : 'DESKTOP']

const isMasterPageSection = (/** @type {any} */ isMobile, /** @type {{ componentType: any; }} */ compStructure) =>
    _.includes(getMasterPageSectionsTypes(isMobile), compStructure.componentType)
const isFixedPosition = (/** @type {any} */ compStructure) => _.get(compStructure, ['layout', 'fixedPosition'], false)
const isPagesContainer = (/** @type {{ id: string; }} */ compStructure) => compStructure.id === 'PAGES_CONTAINER'
const getPagesContainerIndex = (/** @type {Array.<{ id: string; }>} */ components) =>
    _.findIndex(components, isPagesContainer)

const getMasterPageChildren = (/** @type {any} */ masterPageJson, /** @type {boolean} */ isMobile) =>
    isMobile
        ? _.get(masterPageJson, ['structure', 'mobileComponents'])
        : _.get(masterPageJson, ['structure', 'children'])

const findNext = (
    /** @type {_.List<any>} */ arr,
    /** @type {_.ListIterateeCustom<any, boolean>} */ predicate,
    /** @type {number} */ index
) => _.findIndex(arr, predicate, index + 1)

const notRelatedToPagesContainerGroup = (
    /** @type {{ componentType: any; }} */ comp,
    /** @type {boolean} */ isMobile
) => isMasterPageSection(isMobile, comp) || isFixedPosition(comp)

const getPagesContainerRangeEnd = (/** @type {string | _.List<any>} */ masterPageChildren) => {
    // @ts-ignore
    const pagesContainerIndex = getPagesContainerIndex(masterPageChildren)
    const lastChildIndex = masterPageChildren.length - 1

    if (pagesContainerIndex === lastChildIndex) {
        return pagesContainerIndex
    }

    const firstSectionFromRight = findNext(
        masterPageChildren,
        comp => notRelatedToPagesContainerGroup(comp, false),
        pagesContainerIndex
    )

    return firstSectionFromRight === -1 ? lastChildIndex : firstSectionFromRight - 1
}

const moveToIndex = (/** @type {any[]} */ arr, /** @type {number} */ from, /** @type {number} */ to) =>
    arr.splice(to, 0, ...arr.splice(from, 1))

const fixSospBeneathPage = (/** @type {any} */ masterPageJson) => {
    if (getSoapCompsAroundPagesContainerFlag(masterPageJson) === true) {
        const masterPageDesktopChildren = getMasterPageChildren(masterPageJson, false)
        if (_.head(masterPageDesktopChildren).componentType === SOSP_COMP_TYPE) {
            const newSospIndex = getPagesContainerRangeEnd(masterPageDesktopChildren)
            moveToIndex(masterPageDesktopChildren, 0, newSospIndex)
            setSoapCompsAroundPagesContainerFlag(masterPageJson, false)
        }
    }
}

/**
 * @param {{ SITE_STRUCTURE?: any; masterPage: any; }} masterPageDocumentData
 */
function hasValidMasterPageData(masterPageDocumentData) {
    return masterPageDocumentData.masterPage && masterPageDocumentData.masterPage.id === 'masterPage'
}

/**
 * @param {{ SITE_STRUCTURE: any; masterPage: { id?: any; }; }} masterPageDocumentData
 */
function fixMasterPage(masterPageDocumentData) {
    if (!hasValidMasterPageData(masterPageDocumentData)) {
        if (masterPageDocumentData.SITE_STRUCTURE) {
            masterPageDocumentData.masterPage = masterPageDocumentData.SITE_STRUCTURE
        } else {
            masterPageDocumentData.masterPage = masterPageDocumentData.masterPage || {}
        }
        masterPageDocumentData.masterPage.id = 'masterPage'
    }
    delete masterPageDocumentData.SITE_STRUCTURE
}

/**
 * @param {any} comp
 */
function hasCorruptedWidth(comp) {
    return _.get(comp, 'layout.width') === 0
}

/**
 * @param {any} comp
 */
function hasCorruptedHeight(comp) {
    return _.get(comp, 'layout.height') === 0
}

/**
 * @param {any} comp
 */
function setDefaultSizesForCorruptedComponents(comp) {
    if (hasCorruptedWidth(comp)) {
        _.set(comp, 'layout.width', 100)
    }
    if (hasCorruptedHeight(comp)) {
        _.set(comp, 'layout.height', 100)
    }
}

/**
 * @param {any} children
 */
function fixAnchorDistances(children) {
    _.forEach(children, child => {
        _.forEach(_.get(child, 'layout.anchors'), anchor => {
            switch (child.id) {
                case 'SITE_FOOTER':
                    if (anchor.type === 'BOTTOM_PARENT') {
                        anchor.distance = 0
                    }
                    break

                case 'PAGES_CONTAINER':
                    if (anchor.type === 'BOTTOM_TOP' && anchor.locked && anchor.distance >= 70) {
                        anchor.originalValue = 0
                        anchor.locked = false
                    }
                    break
            }
        })
    })
}

/**
 * @param {any} masterPageChildren
 * @param {number} siteWidthForHeaderAndFooter
 */
function fixCorruptedMasterPageComponentLayouts(masterPageChildren, siteWidthForHeaderAndFooter) {
    let hasCorruption = false

    _.forEach(['SITE_HEADER', 'SITE_FOOTER'], id => {
        const comp = _.find(masterPageChildren, {id})
        if (hasCorruptedWidth(comp)) {
            hasCorruption = true
        }
        if (hasCorruptedHeight(comp)) {
            _.set(comp, 'layout.height', 100)
            hasCorruption = true
        }

        _.set(comp, 'layout.width', siteWidthForHeaderAndFooter)
    })
    if (hasCorruption) {
        const rootCompsOnMasterPage = _.reject(masterPageChildren, {id: 'PAGES_CONTAINER'})
        runOnAllCompsRecursively(rootCompsOnMasterPage, [setDefaultSizesForCorruptedComponents])
    }
}

/**
 * @param {any} masterPageJson
 * @param {{ behaviorQuery: any; }} mobileHeader
 */
function headerHasScrubBehavior(masterPageJson, mobileHeader) {
    if (!mobileHeader.behaviorQuery) {
        return false
    }
    const behavior = _.get(masterPageJson, ['data', 'behaviors_data', mobileHeader.behaviorQuery])
    try {
        const behaviorItems = JSON.parse(behavior.items)
        return _.some(behaviorItems, {type: 'scrub', name: 'ScrubAnimation'})
    } catch (e) {
        return false
    }
}

/**
 * @param {any} mobileMasterPageComps
 * @param {any} masterPageJson
 */
function fixMobileFixedPositionSiteSegments(mobileMasterPageComps, masterPageJson) {
    const mobileHeader = _.find(mobileMasterPageComps, {id: 'SITE_HEADER'})
    if (!mobileHeader) {
        return
    }
    if (headerHasScrubBehavior(masterPageJson, mobileHeader)) {
        _.set(mobileHeader, ['layout', 'fixedPosition'], true)
    }

    const mobileFooter = _.find(mobileMasterPageComps, {id: 'SITE_FOOTER'})
    _.set(mobileFooter, ['layout', 'fixedPosition'], false)
}

/**
 * @param {{ structure: any; }} masterPageJson
 */
function fixMasterPageStructure(masterPageJson) {
    const masterPageStructure = masterPageJson.structure
    const desktopMasterPageComps = masterPageStructure.children
    const mobileMasterPageComps = masterPageStructure.mobileComponents
    const siteWidthForHeaderAndFooter = _.get(
        masterPageJson,
        'data.document_data.masterPage.renderModifiers.siteWidth',
        980
    )
    fixMissingMasterPagePartsIfNeeded(desktopMasterPageComps)
    fixCorruptedMasterPageComponentLayouts(desktopMasterPageComps, siteWidthForHeaderAndFooter)
    fixMissingMasterPagePartsIfNeeded(mobileMasterPageComps)
    fixCorruptedMasterPageComponentLayouts(mobileMasterPageComps, 320)
    fixMobileFixedPositionSiteSegments(mobileMasterPageComps, masterPageJson)
    fixAnchorDistances(mobileMasterPageComps)
}

const mandatoryMasterPageParts = [
    {componentType: 'wysiwyg.viewer.components.PagesContainer', id: 'PAGES_CONTAINER'},
    {componentType: 'wysiwyg.viewer.components.FooterContainer', id: 'SITE_FOOTER'},
    {componentType: 'wysiwyg.viewer.components.HeaderContainer', id: 'SITE_HEADER'}
]

/**
 * @param {any} comps
 */
function fixMissingMasterPagePartsIfNeeded(comps) {
    _.forEach(mandatoryMasterPageParts, mandatoryPart => {
        const partByID = _.find(comps, {id: mandatoryPart.id})
        if (!partByID) {
            const partByType = _.find(comps, {componentType: mandatoryPart.componentType})
            if (partByType) {
                partByType.id = mandatoryPart.id
            }
        }
    })
}

/**
 * @param {{ y: any; x: any; height: any; width: any; }} layout
 * @param {{ isExperimentOpen: (arg0: string) => any; }} magicObject
 */
function fixMasterPageLayout(layout, magicObject) {
    // Fixing after BOLT-2670 where the masterPage layout was set with default values where it should remain empty, at least for Bolt
    if (layout) {
        //layout.y not removed since it causes a loop with pageTopFixer
        if (!magicObject.isExperimentOpen('dm_stopMasterpageFixerLoop')) {
            delete layout.y
        }
        //we can probably stop removing since we are no longer on bolt
        delete layout.x
        //these were previously removed (not just bolt) due to some other old bug
        delete layout.height
        delete layout.width
    }
}

/**
 * @param {{ structure: { propertyQuery: any; }; data: { component_properties: { [x: string]: any; }; }; }} pageJson
 */
function removeProperties(pageJson) {
    const {propertyQuery} = pageJson.structure
    if (propertyQuery) {
        delete pageJson.structure.propertyQuery
        delete pageJson.data.component_properties[propertyQuery]
    }
}

/**
 * @exports utils/dataFixer/plugins/masterPageFixer
 * @type {{exec: function}}
 */
module.exports = {
    name: 'masterPageFixer',
    version: 1,
    experimentalVersions: [{version: 2, experiment: 'dm_stopMasterpageFixerLoop'}],
    // @ts-ignore
    exec(pageJson, pageIdsArray, magicObject) {
        if (pageJson.structure && pageJson.structure.type === 'Document') {
            pageJson.structure.id = 'masterPage'
            pageJson.structure.dataQuery = '#masterPage'
            pageJson.structure.componentType = 'mobile.core.components.MasterPage'
            fixMasterPage(pageJson.data.document_data)
            removeProperties(pageJson)
            fixMasterPageLayout(pageJson.structure.layout, magicObject)
            fixMasterPageStructure(pageJson)
            rejectMeshIfNeeded(pageJson)
            fixSospBeneathPage(pageJson)
        }
        return pageJson
    },
    fixerRequiresReruns: true
}
