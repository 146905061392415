import {
    CreateExtArgs,
    Extension,
    ExtensionAPI,
    IndexKey,
    pointerUtils,
    DmApis,
    DAL,
    DalValue
} from '@wix/document-manager-core'
import type {
    CompRef,
    CompVariantPointer,
    NamespaceOverrideFilter,
    Pointer,
    RemoveOverridesOptions
} from '@wix/document-services-types'
import _ from 'lodash'
import {constants, inflationUtils} from '..'
import {COMP_DATA_QUERY_KEYS, MASTER_PAGE_ID, VARIANTABLE_DATA_TYPES} from '../constants/constants'
import {
    getPointerWithoutFallbacksFromPointer,
    RefDelimiter,
    extractBaseComponentByNamespace,
    extractBaseComponentId
} from '../utils/refStructureUtils'
import {EVENTS as COMPONENT_EVENTS} from './components'
import type {DataModelExtensionAPI} from './dataModel/dataModel'
import {createItemGetter, type DataExtensionAPI} from './data'
import {namespaceMapping} from '@wix/document-services-json-schemas'
import {MOBILE_SPLITTABLE_TYPE} from '../utils/inflationUtils'
import {ReportableError} from '@wix/document-manager-utils'
import type {VariantsExtensionAPI} from './variants/variants'

const {NAMESPACES_WITH_OVERRIDES} = namespaceMapping
const {getRepeatedItemPointerIfNeeded} = pointerUtils
const {DATA_TYPES, VIEW_MODES} = constants

const OVERRIDE_HOST_INDEX = 'OVERRIDE_HOST_INDEX'
const OVERRIDE_TARGET_INDEX = 'OVERRIDE_TARGET_INDEX'

const NO_MATCH: string[] = []
export interface RefOverridesAPI {
    removeOverrides(compPointer: CompRef, options?: RemoveOverridesOptions): void
    getOverrideId(refCompId: string, compId: string): string
    getOverride(refCompId: string, innerComponentIds: string[], namespace: string, options?: GetSetOverrideOptions): any
    setOverride(
        refCompId: string,
        innerComponentIds: string[],
        namespace: string,
        item: any,
        options?: GetSetOverrideOptions
    ): void
    removeOverride(
        refCompId: string,
        innerComponentIds: string[],
        namespace: string,
        options?: GetSetOverrideOptions
    ): void
}

export type RefOverridesExtensionAPI = ExtensionAPI & {
    refOverrides: RefOverridesAPI
}

export interface GetSetOverrideOptions {
    mobileSplitItem?: boolean
}

const createExtension = (): Extension => {
    const getOverridesByType = (dal: DAL, dataType: string, queryFilter: IndexKey) => {
        const overrides = dal.query(dataType, queryFilter)
        return _(overrides)
            .values()
            .map(({id, metaData}) => createItemGetter(dataType)(id, _.get(metaData, ['pageId'], MASTER_PAGE_ID)))
            .value()
    }
    const getOverridesFilter = (dal: DAL, compPtr: Pointer) =>
        dal.queryFilterGetters[OVERRIDE_HOST_INDEX](getRepeatedItemPointerIfNeeded(compPtr).id)
    const getAllOverrides = (dal: DAL, queryFilter: IndexKey) => {
        return _.reduce(
            NAMESPACES_WITH_OVERRIDES,
            (result, dataType) => _.concat(result, getOverridesByType(dal, dataType, queryFilter)),
            [] as Pointer[]
        )
    }

    const getOverridesTargetingComp = (dal: DAL, compPtr: Pointer, namespace: string) => {
        const queryFilter = dal.queryFilterGetters[OVERRIDE_TARGET_INDEX](compPtr.id)
        return dal.getIndexPointers(queryFilter, namespace)
    }

    const getAllOverrideHosts = (dal: DAL) => {
        return dal.getIndexKeys(OVERRIDE_HOST_INDEX)
    }

    const createExtensionAPI = ({
        pointers,
        eventEmitter,
        extensionAPI,
        dal
    }: CreateExtArgs): RefOverridesExtensionAPI => {
        const {variants} = extensionAPI as VariantsExtensionAPI
        const {data: dataApi} = extensionAPI as DataExtensionAPI
        const {dataModel} = extensionAPI as DataModelExtensionAPI

        const shouldExcludeOverrideByNamespace = (filter: NamespaceOverrideFilter | undefined, itemType: string) =>
            filter === '*' || !!filter?.has(itemType)

        const shouldExcludeOverride = (
            overridePointer: Pointer,
            isMobile: boolean,
            options: RemoveOverridesOptions
        ): boolean => {
            const {exclusions, inclusions, removeMobilePropsOnly} = options
            const namespaceFilter = inclusions || exclusions
            const {type} = overridePointer

            if (removeMobilePropsOnly && isMobile) {
                return type !== DATA_TYPES.prop
            }
            const itemType = dal.getWithPath(overridePointer, 'type')
            const shouldExclude = shouldExcludeOverrideByNamespace(namespaceFilter?.[type], itemType)

            return exclusions ? shouldExclude : !shouldExclude
        }

        const getOverrides = (compPointer: CompRef, options: RemoveOverridesOptions = {}): Pointer[] | undefined => {
            const {exclusions, inclusions, removeMobilePropsOnly} = options
            if (exclusions && inclusions) {
                throw new ReportableError({
                    errorType: 'BothExclusionsAndInclusionsProvidedToRemoveOverrides',
                    message: 'Cannot provide both exclusions and inclusions to removeOverrides options.'
                })
            }

            const overrides = getAllOverrides(dal, getOverridesFilter(dal, compPointer))
            const isMobile = pointers.structure.isMobile(compPointer)
            if (exclusions || inclusions || (removeMobilePropsOnly && isMobile)) {
                return overrides.filter(override => !shouldExcludeOverride(override, isMobile, options))
            }

            return overrides
        }

        const removeOverrides = (compPointer: CompRef | CompVariantPointer, options?: RemoveOverridesOptions) => {
            const overrides = getOverrides(compPointer, options)

            if (!overrides) {
                return
            }

            for (const override of overrides) {
                if (override.type in VARIANTABLE_DATA_TYPES && dataApi.refArray.isRefArray(dal.get(override))) {
                    const remoteCompPointerWithLocalOverrides = pointerUtils.getPointerFromPointer(
                        extractBaseComponentId(override),
                        compPointer
                    )
                    variants.removeRefArrayDataConsideringVariants(
                        remoteCompPointerWithLocalOverrides,
                        override.type,
                        override
                    )
                } else {
                    dataModel.removeItemRecursively(override)
                }
            }
        }

        const getOverrideId = (refCompId: string, namespace: string): string =>
            `${refCompId}-${COMP_DATA_QUERY_KEYS[namespace]}`

        const validateGetSetOverrideCall = (namespace: string, isMobileSpecific: boolean) => {
            if (!MOBILE_SPLITTABLE_TYPE[namespace] && isMobileSpecific) {
                throw new ReportableError({
                    errorType: 'AttemptedToGetOrSetMobileSpecificOverrideOnNonMobileSplittableNamespace',
                    message: `Cannot get or set mobile specific override on non mobile splittable namespace: ${namespace}`
                })
            }
        }

        const getOverridePointer = (
            refCompId: string,
            innerComponentIds: string[],
            namespace: string,
            options: GetSetOverrideOptions = {}
        ): Pointer => {
            validateGetSetOverrideCall(namespace, options.mobileSplitItem ?? false)

            const referredId = inflationUtils.createReferredId(refCompId, innerComponentIds)
            const referredPointer = pointerUtils.getPointer(
                referredId,
                options.mobileSplitItem ? VIEW_MODES.MOBILE : VIEW_MODES.DESKTOP
            )

            const overrideId = inflationUtils.getItemQueryId(referredPointer, namespace)

            return pointerUtils.getPointer(overrideId, namespace)
        }

        const getOverride = (
            refCompId: string,
            innerComponentIds: string[],
            namespace: string,
            options: GetSetOverrideOptions = {}
        ) => {
            const refCompPointer = pointerUtils.getPointer(refCompId, VIEW_MODES.DESKTOP)
            const overridePointer = getOverridePointer(refCompId, innerComponentIds, namespace, options)
            return dataModel.getItem(
                overridePointer.id,
                namespace,
                pointers.structure.getPageOfComponent(refCompPointer).id
            )
        }

        const setOverride = (
            refCompId: string,
            innerComponentIds: string[],
            namespace: string,
            item: any,
            options: GetSetOverrideOptions = {}
        ) => {
            const refCompPointer = pointerUtils.getPointer(refCompId, VIEW_MODES.DESKTOP)

            const overridePointer = getOverridePointer(refCompId, innerComponentIds, namespace, options)
            dataModel.addItem(
                item,
                namespace,
                pointers.structure.getPageOfComponent(refCompPointer).id,
                overridePointer.id
            )
        }

        const removeOverride = (
            refCompId: string,
            innerComponentIds: string[],
            namespace: string,
            options: GetSetOverrideOptions = {}
        ) => {
            const overridePointer = getOverridePointer(refCompId, innerComponentIds, namespace, options)
            dataModel.removeItemRecursively(overridePointer)
        }

        eventEmitter.addListener(COMPONENT_EVENTS.COMPONENTS.BEFORE_REMOVE, (compRef: CompRef) =>
            removeOverrides(compRef)
        )
        return {
            refOverrides: {
                removeOverrides,
                getOverrideId,
                getOverride,
                setOverride,
                removeOverride
            }
        }
    }
    const getReferredStructurePointers = (dal: DAL): any => ({
        getPointerWithoutFallbacks: getPointerWithoutFallbacksFromPointer,
        getAllOverrides: (compPtr: Pointer) => getAllOverrides(dal, getOverridesFilter(dal, compPtr)),
        getOverridesByType: (compPtr: Pointer, dataType: string) =>
            getOverridesByType(dal, dataType, getOverridesFilter(dal, compPtr)),
        getConnectionOverrides: (compPtr: Pointer) =>
            getOverridesByType(dal, NAMESPACES_WITH_OVERRIDES.connections, getOverridesFilter(dal, compPtr)),
        getOverridesTargetingComp: (compPtr: Pointer, namespace: string) =>
            getOverridesTargetingComp(dal, compPtr, namespace),
        getAllOverrideHosts: () => getAllOverrideHosts(dal)
    })
    const createPointersMethods = ({dal}: DmApis) => ({
        referredStructure: getReferredStructurePointers(dal),
        full: {
            referredStructure: getReferredStructurePointers(dal)
        }
    })

    const createFilters = () => {
        //e.g. 'comp1_r_comp2_r_prop' -> ['comp1', 'comp1_r_comp2']
        const getAllCompIdsFromOverrideId = (overrideId: string) => {
            const refDelimCompIds = overrideId.split(RefDelimiter).slice(0, -1)

            return refDelimCompIds.reduce((result: string[], current: string) => {
                const previousId = result[result.length - 1]
                const compId = previousId ? `${previousId}${RefDelimiter}${current}` : current
                result.push(compId)

                return result
            }, [])
        }

        return {
            [OVERRIDE_HOST_INDEX]: (namespace: string, value: DalValue, id: string): string[] => {
                if (value && NAMESPACES_WITH_OVERRIDES[namespace] && id.includes(RefDelimiter)) {
                    return getAllCompIdsFromOverrideId(id)
                }
                return NO_MATCH
            },
            [OVERRIDE_TARGET_INDEX]: (namespace: string, value: DalValue, id: string): string[] => {
                if (value && NAMESPACES_WITH_OVERRIDES[namespace] && id.includes(RefDelimiter)) {
                    return extractBaseComponentByNamespace(id, namespace).split(RefDelimiter).slice(1)
                }
                return NO_MATCH
            }
        }
    }

    const createPublicAPI = ({extensionAPI}: DmApis) => {
        const {refOverrides} = extensionAPI as RefOverridesExtensionAPI
        return {
            refOverrides: {
                getOverride: refOverrides.getOverride,
                setOverride: refOverrides.setOverride,
                removeOverride: refOverrides.removeOverride
            }
        }
    }

    return {
        name: 'refOverrides',
        dependencies: new Set(['structure', 'dataModel', 'data', 'variants']),
        createExtensionAPI,
        createPointersMethods,
        createFilters,
        createPublicAPI
    }
}

export {createExtension}
