import type {PS} from '@wix/document-services-types'

const setResponsive = (ps: PS, isResponsive: boolean) => {
    const isResponsivePointer = ps.pointers.rendererModel.isResponsive()
    ps.dal.full.set(isResponsivePointer, isResponsive)
}

export default {
    setResponsive
}
