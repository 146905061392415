// import _ from 'lodash'

// if (typeof WeakMap !== 'undefined') {
//     return WeakMap // eslint-disable-line no-undef
// }

// let counter = 0
//
// function SiteDataPrivatesPolyfill() {
//     this.name = `_privates_${counter}`
//     counter++
// }
//
// SiteDataPrivatesPolyfill.prototype.set = function (key, value) {
//     key[this.name] = value
// }
//
// SiteDataPrivatesPolyfill.prototype.get = function (key) {
//     return key[this.name]
// }
//
// SiteDataPrivatesPolyfill.prototype.delete = function (key) {
//     delete key[this.name]
// }
//
// SiteDataPrivatesPolyfill.prototype.has = function (key) {
//     return _.has(key, this.name)
// }

export default WeakMap
