import type {PS} from '@wix/document-services-types'
import _ from 'lodash'

const PointerOperation = {
    GET: 'get',
    SET: 'set'
} as const
const EMPTY_TRANSLATION_DATA = {
    data: {
        document_data: {}
    }
}

//backwards compatibility of API. See the test for more info
const getCurrentLanguageCode = (ps: PS): string | null =>
    ps.dal.get(ps.pointers.multilingual.currentLanguageCode()) || null

const getTranslationLanguages = (ps: PS) => ps.dal.get(ps.pointers.multilingual.translationLanguages()) ?? []

const generatePageTranslationsData = (ps: PS) =>
    _.reduce(
        getTranslationLanguages(ps),
        (acc, {languageCode}) => {
            acc[languageCode] = _.cloneDeep(EMPTY_TRANSLATION_DATA)
            return acc
        },
        {}
    )

const setCurrentLanguage = (ps: PS, languageCode: string) => {
    const {dal, pointers} = ps

    if (getCurrentLanguageCode(ps) === languageCode) {
        return
    }

    const currentLanguagePointer = pointers.multilingual.currentLanguageCode()
    dal.set(currentLanguagePointer, languageCode)
    dal.touch(currentLanguagePointer)
}

export default {
    PointerOperation,
    EMPTY_TRANSLATION_DATA,
    getCurrentLanguageCode,
    setCurrentLanguage,
    generatePageTranslationsData
}
