import _ from 'lodash'
import type {WidgetInfo} from './appStudioDataModel'

function isNamePrefixWithNumber(name: string, prefix: string) {
    const r = new RegExp(`^${prefix}[0-9]*$`)
    return r.test(name)
}

function generateName(existingNames: WidgetInfo[] = [], prefix?: string) {
    const allNumbersSorted = _(existingNames)
        .map('name')
        .filter(name => isNamePrefixWithNumber(name, prefix))
        .map(name => _.replace(name, prefix, ''))
        .map(Number)
        .sortBy()
        .value()

    const maxValue = _.last(allNumbersSorted) ?? 0

    return prefix + (maxValue + 1)
}

export default {
    generateName
}
