import type {PublicMethodUtils} from '@wix/document-services-types'

export default function (publicMethodUtils: PublicMethodUtils) {
    const {
        extensionPublicAPI: {immediate}
    } = publicMethodUtils

    return {
        methods: {
            miniSite: {
                loadCSM: immediate('miniSite.loadCSM')
            }
        }
    }
}
