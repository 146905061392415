import {Extension, PointerMethods, pointerUtils} from '@wix/document-manager-core'

const {getPointer} = pointerUtils
const pointerType = 'contactFormsMetaData'

const createPointersMethods = (): PointerMethods => {
    const getContactFormsMetaData = () => getPointer(pointerType, pointerType)

    return {
        general: {
            getContactFormsMetaData
        }
    }
}

const initialState = {
    [pointerType]: {
        [pointerType]: {}
    }
}

const createExtension = (): Extension => ({
    name: 'contactFormsMetaData',
    createPointersMethods,
    initialState
})

export {createExtension}
