import type {PS} from '@wix/document-services-types'
import appControllerData from '../appControllerData/appControllerData'

const updateControllerConfig = (ps: PS, controllerRef, config) => {
    const currentConfig = appControllerData.getSettings(ps, controllerRef)
    appControllerData.setSettings(ps, controllerRef, {...currentConfig, ...config}, true)
}

export default {
    update: updateControllerConfig
}
