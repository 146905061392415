import type {PS, Size} from '@wix/document-services-types'

/**
 * returns the name of the viewer used
 */
const getViewerName = (ps: PS): string => ps.runtimeConfig.viewerName

/**
 * returns the version of the viewer used
 */
const getViewerVersion = (ps: PS): string => ps.runtimeConfig.viewerVersion

/**
 * returns whether this document is responsive
 */
const isResponsiveDocument = (ps: PS): boolean => {
    const isResponsivePointer = ps.pointers.general.isResponsive()
    return !!ps.dal.full.get(isResponsivePointer)
}

/**
 * returns screen resolution
 */
function getScreenResolution(ps: PS): Size {
    const {
        width: {device: deviceWidth},
        height: {device: deviceHeight}
    } = ps.siteAPI.getSiteMeasureMap()
    return {height: deviceHeight, width: deviceWidth}
}

export default {
    isResponsiveDocument,
    getViewerName,
    getViewerVersion,
    getScreenResolution
}
