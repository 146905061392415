import pMap from 'p-map'
import type {FetchFn} from '@wix/document-manager-extensions/src/utils/fetchUtils'
import type {PageListWithMasterAndMainPage, PageList, RMPage} from '@wix/document-services-types'
import type {FetchPagesFacade} from '../types'
import {ReportableError} from '@wix/document-manager-utils'
export type PageLoadedCallback = (pageJson: any, pageId: string) => void

type AnyPageList = PageList | PageListWithMasterAndMainPage
export class DefaultFetchPagesFacade implements FetchPagesFacade {
    fetchPages(fetchFn: FetchFn, pageList: AnyPageList, concurrency: number = 100): Promise<Record<string, any>> {
        return fetchPages(fetchFn, pageList, concurrency)
    }
}

// todo: PageList is not actually a page list.. its a piece of metadata containing info of how to fetch a site pages
async function fetchPages(
    fetchFn: FetchFn,
    pageList: AnyPageList,
    concurrency: number = 100,
    options?: RequestInit
): Promise<Record<string, any>> {
    const urlFallbacksTemplates = pageList.topology.map(
        urlDescriptor => `${urlDescriptor.baseUrl}${urlDescriptor.parts}`
    )
    const entries = await fetchPageEntries(fetchFn, pageList, urlFallbacksTemplates, concurrency, options)
    return Object.fromEntries(entries.map(({pageId, page}) => [pageId, page]))
}
type PageFetchDetails = Pick<RMPage, 'pageId' | 'pageJsonFileName'>
async function fetchPageEntries(
    fetchFn: FetchFn,
    pageList: AnyPageList,
    urlFallbacksTemplates: string[],
    concurrency: number,
    options?: RequestInit
) {
    let allPages = pageList.pages as PageFetchDetails[]
    if ('masterPageJsonFileName' in pageList) {
        allPages = [...pageList.pages, {pageId: 'masterPage', pageJsonFileName: pageList.masterPageJsonFileName}]
    }
    return await pMap(
        allPages,
        ({pageJsonFileName, pageId}) =>
            fetchPageJson(fetchFn, urlFallbacksTemplates, pageId, pageJsonFileName, options),
        {concurrency}
    )
}

async function fetchPageJson(
    fetchFn: FetchFn,
    urlFallbacksTemplates: string[],
    pageId: string,
    pageJsonFileName: string,
    options?: RequestInit
): Promise<any> {
    for (const urlTemplate of urlFallbacksTemplates) {
        const url = urlTemplate.replace('{filename}', pageJsonFileName)
        try {
            const response = await fetchFn(url, options)
            if (!response) {
                console.warn(`HTTP: failed to fetch page ${url}`)
                continue
            }
            if (!response.ok) {
                console.warn(`HTTP: failed to fetch page ${url} - ${response.status}: ${response.statusText}`)
                continue
            }
            const page = await response.json()
            return {pageId, page}
        } catch (e) {
            console.error(`HTTP: failed to fetch ${url}`, e)
        }
    }
    throw new ReportableError({
        message: `Failed to fetch page using all fallback URLs`,
        errorType: 'failedToFetchPages',
        extras: {pageId, pageJsonFileName}
    })
}

export {fetchPages}
