import type {Getter, SetterSet} from '@wix/document-manager-core'
import type {Pointer, Pointers} from '@wix/document-services-types'
import _ from 'lodash'
import {addPageIdFromPointer} from './transformerUtils'

const solidifyTranslationPointer = (
    oldPointer: Pointer,
    pointers: Pointers,
    pageId: string,
    languageCode: string,
    pointerId: string
) => _.assign({}, oldPointer, pointers.multilingualTranslations.translationDataItem(pageId, languageCode, pointerId))

const isUpdatingPrimaryLanguage = (currentLangCode: string, getter: Getter, pointers: Pointers) => {
    if (currentLangCode) {
        const originalLanguage = getter(pointers.multilingual.originalLanguage())
        const originalLanguageCode = originalLanguage?.languageCode
        if (originalLanguageCode) {
            return originalLanguageCode === currentLangCode
        }
    }
    return true
}

let currentLanguageCodePointer: Pointer

const getCurrentLanguageCode = (pointers: Pointers, getter: Getter) => {
    currentLanguageCodePointer = currentLanguageCodePointer || pointers.multilingual.currentLanguageCode()
    return getter(currentLanguageCodePointer)
}

const pagePath = ['metaData', 'pageId']

const transformMultilingualData = (pointer: Pointer, getter: Getter, pointers: Pointers, value?: any) => {
    if (pointer.type !== 'data' || pointer.id === 'masterPage') {
        return pointer
    }

    const {useLanguage} = pointer
    const currentLangCode = useLanguage ?? getCurrentLanguageCode(pointers, getter)

    if (isUpdatingPrimaryLanguage(currentLangCode, getter, pointers)) {
        return pointer
    }

    const pageId = pointer.pageId ?? _.get(value, pagePath) ?? _.get(getter(pointer), pagePath)
    if (!pageId) {
        return pointer
    }

    const translationPointer = pointers.multilingualTranslations.translationDataItem(
        pageId,
        currentLangCode,
        pointer.id
    )
    const translation = getter(translationPointer)
    if (!value) {
        return translation
            ? solidifyTranslationPointer(pointer, pointers, pageId, currentLangCode, pointer.id)
            : pointer
    }

    const mainLanguageDataItemValue = getter(pointer)
    return !mainLanguageDataItemValue
        ? pointer
        : solidifyTranslationPointer(pointer, pointers, pageId, currentLangCode, pointer.id)
}

const transformSet = (pointer: Pointer, value: any, getter: Getter, set: SetterSet, pointers: Pointers) => {
    const valueWithPageId = addPageIdFromPointer(pointer, value)
    const translatedPointer = transformMultilingualData(pointer, getter, pointers, valueWithPageId)
    return {value: valueWithPageId, pointer: translatedPointer}
}

const transformGet = (pointer: Pointer, getter: Getter, pointers: Pointers) =>
    transformMultilingualData(pointer, getter, pointers)

export {transformSet, transformGet}
