export const COMPONENTS_NOT_SUITABLE_FOR_NON_RENDERING_STATE = [
    'wysiwyg.viewer.components.BoxSlideShowSlide',
    'wysiwyg.viewer.components.BoxSlideShow',
    'wysiwyg.viewer.components.StateBoxState',
    'wysiwyg.viewer.components.StateBoxFormState',
    'wysiwyg.viewer.components.StateBox',
    'wysiwyg.viewer.components.StateStripState',
    'wysiwyg.viewer.components.StateStrip',
    'wysiwyg.viewer.components.StripContainerSlideShowSlide',
    'wysiwyg.viewer.components.StripContainerSlideShow',
    'wysiwyg.viewer.components.StripColumnsContainer',
    'wysiwyg.viewer.components.HoverBox',
    'wysiwyg.common.components.anchor.viewer.Anchor',
    'wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu',
    'wysiwyg.viewer.components.tpapps.TPAWidget',
    'wysiwyg.viewer.components.tpapps.TPASection',
    'wysiwyg.viewer.components.tpapps.TPAGluedWidget',
    'wysiwyg.viewer.components.tpapps.TPAMultiSection',
    'wixapps.integration.components.AppPart',
    'wixapps.integration.components.AppPart2'
]

export const CONTAINERS_SUITABLE_FOR_NON_RENDERING_STATE = ['wysiwyg.viewer.components.FormContainer']
