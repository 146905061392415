import _ from 'lodash'
import {CreateExtArgs, Extension, ExtensionAPI, pointerUtils} from '@wix/document-manager-core'
import {APP_STATUS, DATA_TYPES, MASTER_PAGE_ID} from '../constants/constants'
import type {
    AppData,
    AppDefinitionId,
    AppInstallState,
    ApplicationId,
    AppsInstallStateMap,
    ClientSpecMap,
    ClientSpecMapEntry,
    EditorClientSpecMapEntry
} from '@wix/document-services-types'

import {deepClone} from '@wix/wix-immutable-proxy'
import * as tpaUtils from './tpa/installedTpaAppsUtils'
import type {DataModelExtensionAPI} from './dataModel/dataModel'
import type {SchemaExtensionAPI} from './schema/schema'

const {getPointer, getInnerPointer} = pointerUtils

const {INSTALLED, REMOVED} = APP_STATUS

export interface AppsInstallStateAPI extends ExtensionAPI {
    isAppInstalled(appDefId: AppDefinitionId): boolean
    isAppRemoved(appDefId: AppDefinitionId): boolean
    setAppInstalled(appDefId: AppDefinitionId, state?: AppInstallState): void
    setAppUninstalled(appDefId: AppDefinitionId): void
    updateAppVersion(appDefId: AppDefinitionId, version: string): void
    getAppInstallState(appDefId: AppDefinitionId): AppInstallState | undefined
    getAllAppsInstallStatus(): AppsInstallStateMap
    getInstallStateByAppData(app: ClientSpecMapEntry, appsWithComps?: Set<AppDefinitionId>): AppInstallState | undefined
    setAppInstallStateByAppData(app: EditorClientSpecMapEntry): void
    reportStateDifferenceByActions(
        clientSpecMap: ClientSpecMap,
        actions: {type: string; applicationId: ApplicationId}[],
        source: string
    ): void
    addStateToDataModel(newStateMap: AppsInstallStateMap): void
}
export type AppsInstallStateExtensionAPI = ExtensionAPI & {
    appsInstallState: AppsInstallStateAPI
}
const APPS_INSTALL_STATE_ID = 'APPS_INSTALL_STATE'

const getAppsInstallStatePointer = () => getPointer(APPS_INSTALL_STATE_ID, DATA_TYPES.data)

const getStateMapPointer = () => getInnerPointer(getAppsInstallStatePointer(), ['stateMap'])

const createExtension = (): Extension => {
    const createExtensionAPI = (createExtArgs: CreateExtArgs): AppsInstallStateExtAPI => {
        const {coreConfig, dal, pointers, extensionAPI} = createExtArgs
        const {dataModel} = extensionAPI as DataModelExtensionAPI
        const {schemaAPI} = extensionAPI as SchemaExtensionAPI

        const addStateToDataModel = (newStateMap: AppsInstallStateMap) => {
            const masterPage = dataModel.getItem(MASTER_PAGE_ID, DATA_TYPES.data, MASTER_PAGE_ID)
            const dataItem = schemaAPI.createItemAccordingToSchema('AppsInstallState', DATA_TYPES.data, {
                stateMap: newStateMap,
                id: APPS_INSTALL_STATE_ID
            })
            if (masterPage?.appsInstallState?.id && !masterPage.appsInstallState.id.includes(APPS_INSTALL_STATE_ID)) {
                dal.remove(getPointer(masterPage.appsInstallState.id.replace(/^#/, ''), DATA_TYPES.data))
                dal.remove(getPointer(MASTER_PAGE_ID, DATA_TYPES.data, {innerPath: 'appsInstallState'}))
            }
            masterPage.appsInstallState = dataItem
            dataModel.addItemWithRefReuse(masterPage, DATA_TYPES.data, MASTER_PAGE_ID, MASTER_PAGE_ID)
        }

        const getStateMap = (): AppsInstallStateMap => {
            const stateMapPointer = getStateMapPointer()
            let stateMap = dal.get(stateMapPointer)
            if (!stateMap) {
                stateMap = {}
                addStateToDataModel({})
            }
            return stateMap
        }

        const setStateMap = (newStateMap: AppsInstallStateMap): void => {
            const stateMapPointer = getStateMapPointer()
            dal.set(stateMapPointer, newStateMap)
        }

        const getState = (appDefId: AppDefinitionId): AppInstallState | undefined => {
            if (!appDefId) {
                return
            }
            const stateMap = getStateMap()
            return stateMap[appDefId]
        }

        const setState = (appDefId: AppDefinitionId, newState: AppInstallState): void => {
            if (newState.version) {
                newState.version = String(newState.version)
            }
            const stateMap = deepClone(getStateMap())
            stateMap[appDefId] = newState
            setStateMap(stateMap)
        }

        const getAppInstallStatus = (appDefId: AppDefinitionId): string | undefined => {
            const state = getState(appDefId)
            return state?.status
        }

        const setAppInstallState = (appDefId: AppDefinitionId, newState: AppInstallState) => {
            if (!appDefId) {
                return
            }
            const oldState = getState(appDefId)
            const state = _.merge({}, oldState, newState)
            setState(appDefId, state)
        }

        const isAppInstalled = (appDefId: AppDefinitionId) => {
            if (!appDefId) {
                return false
            }
            const state = getState(appDefId)
            return state?.status === INSTALLED
        }

        const isAppRemoved = (appDefId: AppDefinitionId) => {
            if (!appDefId) {
                return false
            }
            const state = getState(appDefId)
            return state?.status === REMOVED
        }

        const getAppInstallStateDifference = (appData: any, isAppActive: boolean) => {
            if (!appData) {
                return
            }
            const appStatus = getAppInstallStatus(appData.appDefinitionId)
            const isAppStatusActive = appStatus === INSTALLED
            if (isAppActive !== isAppStatusActive) {
                return {old: isAppActive, new: appStatus}
            }
        }

        const getAppsInstallStateDifference = (
            clientSpecMap: ClientSpecMap,
            actions: {type: string; applicationId: ApplicationId}[]
        ) => {
            return _.reduce(
                actions,
                (
                    result: Record<AppDefinitionId, {old: any; new: any}>,
                    action: {type: string; applicationId: ApplicationId}
                ) => {
                    if (['remove', 'add'].includes(action.type)) {
                        const appData: any = clientSpecMap[action.applicationId]
                        const difference = getAppInstallStateDifference(appData, action.type === 'add')
                        if (difference) {
                            result[appData.appDefinitionId] = difference
                        }
                    }
                    return result
                },
                {}
            )
        }

        const reportStateDifferenceByActions = (
            clientSpecMap: ClientSpecMap,
            actions: {type: string; applicationId: ApplicationId}[],
            source: string
        ) => {
            const appDefIdsWithDifference = getAppsInstallStateDifference(clientSpecMap, actions)
            if (_.isEmpty(appDefIdsWithDifference)) {
                return
            }
            coreConfig.logger.interactionStarted('compareInstallStatus', {
                extras: {source, appDefIds: appDefIdsWithDifference},
                tags: {valuesEqual: false}
            })
        }

        const getInstallStateByAppData = (app: ClientSpecMapEntry, appsWithComps: Set<AppDefinitionId> = new Set()) => {
            const routerConfigMap = dal.get(pointers.routers.getRoutersConfigMapPointer())
            const appDefinitionId = _.get(app, ['appDefinitionId'])
            if (appDefinitionId && tpaUtils.isEditorOrHybridApp(app)) {
                const hasCompsOnPage = appsWithComps.has(appDefinitionId)
                const hasRouter = tpaUtils.appHasRouter(app, routerConfigMap)
                const isAppExcludedFromAutoRevoke = tpaUtils.isAppExcludedFromAutoRevoke(app)
                const isAppRevoked = tpaUtils.isAppRevoked(app)
                const isStudioApp = tpaUtils.isStudioApp(app)
                const installedWithoutComps =
                    !isAppRevoked &&
                    (isStudioApp || tpaUtils.isAppPlatformEditorOnly(app) || isAppExcludedFromAutoRevoke)
                const isAppPending = tpaUtils.isPendingAppData(app as AppData)
                const isInstalled = !isAppPending && (hasCompsOnPage || installedWithoutComps || hasRouter)
                const version = tpaUtils.getAppVersion(app)

                return {
                    status: isInstalled ? INSTALLED : REMOVED,
                    version
                }
            }
        }

        const setAppInstallStateByAppData = (app: EditorClientSpecMapEntry) => {
            const appsInstallStateAPI = (extensionAPI as AppsInstallStateExtensionAPI).appsInstallState
            if (tpaUtils.isAppRevoked(app)) {
                appsInstallStateAPI.setAppUninstalled(app.appDefinitionId)
            } else if (!appsInstallStateAPI.isAppInstalled(app.appDefinitionId)) {
                appsInstallStateAPI.setAppInstalled(app.appDefinitionId, {version: tpaUtils.getAppVersion(app)})
            }
        }

        return {
            appsInstallState: {
                isAppInstalled: (appDefId: AppDefinitionId) => isAppInstalled(appDefId),
                isAppRemoved: (appDefId: AppDefinitionId) => isAppRemoved(appDefId),
                setAppInstalled: (appDefId: AppDefinitionId, {version}: AppInstallState = {}) =>
                    setAppInstallState(appDefId, {status: INSTALLED, version}),
                setAppUninstalled: (appDefId: AppDefinitionId) => setAppInstallState(appDefId, {status: REMOVED}),
                updateAppVersion: (appDefId: AppDefinitionId, version: string) =>
                    setAppInstallState(appDefId, {version}),
                getAppInstallState: getState,
                getAllAppsInstallStatus: getStateMap,
                getInstallStateByAppData,
                reportStateDifferenceByActions: (
                    clientSpecMap: ClientSpecMap,
                    actions: {type: string; applicationId: ApplicationId}[],
                    source: string
                ) => reportStateDifferenceByActions(clientSpecMap, actions, source),
                setAppInstallStateByAppData,
                addStateToDataModel
            }
        }
    }

    return {
        name: 'appsInstallState',
        createExtensionAPI,
        dependencies: new Set(['dataModel'])
    }
}

export interface AppsInstallStateExtAPI extends ExtensionAPI {
    appsInstallState: AppsInstallStateAPI
}

export {createExtension, APPS_INSTALL_STATE_ID}
