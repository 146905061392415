import type {PS} from '@wix/document-services-types'
import meshLayoutReadyUtils from './utils/meshLayoutReadyUtils'
import type {DsFixer} from '../dsDataFixers'

const fixer: DsFixer = {
    exec: (ps: PS) => meshLayoutReadyUtils.meshLayoutReadySiteDataFixer(ps),
    name: 'meshLayoutReadySiteDataFixer',
    version: 1,
    fixerRequiresReruns: true
}
export default fixer
