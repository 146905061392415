import type {CreateExtArgs} from '@wix/document-manager-core'
import type {CompStructure, Pointer} from '@wix/document-services-types'
import type {DataModelExtensionAPI} from '../../dataModel/dataModel'
import {DATA_TYPES} from '../../../constants/constants'

const isCompFullWidth = ({extensionAPI}: CreateExtArgs, compPointer: Pointer) => {
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    return !!dataModel.components.getItem(compPointer, DATA_TYPES.prop)?.fitToScreenWidth
}

export const collageMetaData = {
    isRepeatable: false,
    fullWidthByStructure: ({}: CreateExtArgs, compStructure: CompStructure) => !!compStructure.props.fitToScreenWidth,
    fullWidth: isCompFullWidth
}
