import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import pathUtils from '../utils/pathUtils'

const dataSelectorsTranslationMap = {
    'IB.ManualSelectedList': 'ManualSelectedList'
}
function getDataSelector(ps: PS, dataSelectorId: string) {
    return ps.wixappsDAL.getByPath(pathUtils.getDataSelectorPath(dataSelectorId))
}

/**
 * Get a data selector definition by its ID
 * @param {ps} ps Private Services
 * @param {string} dataSelectorId
 * @returns {Object} The requested data selector definition
 */
function getSelector(ps: PS, dataSelectorId: string) {
    const dataSelector = getDataSelector(ps, dataSelectorId)
    if (dataSelector) {
        const returnValue = _.omit(dataSelector, ['dataProviderId', 'forType', 'logicalTypeName'])
        returnValue.type = dataSelectorsTranslationMap[dataSelector.logicalTypeName]
        return returnValue
    }
}

export default {
    getSelector
}
