import _ from 'lodash'
import objectUtils from '../../../coreUtils/core/objectUtils'
import pathValidation from '../core/pathValidationUtil'

const {cloneDeep} = objectUtils

function fixIndex(dataArray, index) {
    if (_.isUndefined(index)) {
        index = dataArray.length
    } else if (index > dataArray.length || index < 0) {
        throw new Error('Index out of bound')
    } else if (!_.isNumber(index)) {
        throw new Error('index arguments should be a number')
    }
    return index
}

function isPathExist(json, path, shouldValidatePathList) {
    return pathValidation.validatePathExist(json, path, shouldValidatePathList)
}

function removeValueInPath(json, path) {
    const clonedPath = [].concat(path)
    const keyOrIndexToDelete = clonedPath.pop()
    const parentValue = _.get(json, clonedPath, json)

    if (_.isArray(parentValue)) {
        parentValue.splice(keyOrIndexToDelete, 1)
    } else {
        delete parentValue[keyOrIndexToDelete]
    }
}

function mergeByPath(pathToObject, obj) {
    const value = this.getByPath(pathToObject)
    const mergedData = _.assign({}, value, obj)
    this.setByPath(pathToObject, mergedData)
}

function pushByPath(index, dataArray, item, pathToArray) {
    if (index === 0) {
        dataArray.unshift(item)
    } else {
        dataArray.splice(index, 0, item)
    }

    this.setByPath(pathToArray, dataArray)
}

function getKeysByPath(jsonData, path) {
    const valueInPath = _.get(jsonData, path)
    if (!_.isPlainObject(valueInPath)) {
        throw new Error("Can not get keys of an element that isn't a plain object")
    }
    return _.keys(valueInPath)
}

class DisplayedJsonDal {
    pointersCache: any
    jsonData: any
    constructor(jsonData, pointersCache) {
        this.pointersCache = pointersCache
        this.jsonData = jsonData
        this.jsonData.pagesDataRaw = {}
    }

    get(pointer, untracked, noClone) {
        const path = this.pointersCache.getPath(pointer)
        // @ts-ignore
        return this.getByPath(path, untracked, noClone)
    }

    getByPath(path) {
        if (path) {
            return cloneDeep(_.get(this.jsonData, path))
        }
    }

    set(pointer, data) {
        const path = this.pointersCache.getPath(pointer, true)
        this.setByPath(path, data)
    }

    setByPath(path, data) {
        if (!_.isArray(path)) {
            throw new Error(`path type is not an array - ${path}`)
        }
        _.set(this.jsonData, path, cloneDeep(data))
        this.pointersCache.resetValidations()
    }

    isExist(pointer) {
        const path = this.pointersCache.getPath(pointer)
        return !!path
    }

    isPathExist(path) {
        return isPathExist(this.jsonData, path, true)
    }

    merge(pointer, obj) {
        if (this.isExist(pointer)) {
            const pointerPath = this.pointersCache.getPath(pointer)
            this.mergeByPath(pointerPath, obj)
        }
    }

    /**
     * shallow merge
     * @param pathToObject
     * @param obj
     */
    mergeByPath(pathToObject, obj) {
        if (_.isPlainObject(obj)) {
            if (isPathExist(this.jsonData, pathToObject, true)) {
                mergeByPath.call(this, pathToObject, obj)
                this.pointersCache.resetValidations()
            }
        } else {
            throw new Error(`${obj} is not an object`)
        }
    }

    push(pointerToArray, item, pointerToPush, index) {
        if (!this.isExist(pointerToArray)) {
            throw new Error(`${JSON.stringify(pointerToArray)} path does not exist`)
        }

        const arrayPath = this.pointersCache.getPath(pointerToArray)
        this.pushByPath(arrayPath, item, index)

        // if (pointerToPush) {
        //     const pathToNewItem = arrayPath.concat(index);
        //     this.pointersCache.setPath(pointerToPush, pathToNewItem);
        // }
    }

    pushByPath(pathToArray, item, index) {
        const dataArray = this.getByPath(pathToArray)

        index = fixIndex(dataArray, index)

        pushByPath.call(this, index, dataArray, item, pathToArray)
        this.pointersCache.resetValidations()
    }

    remove(pointer) {
        const path = this.pointersCache.getPath(pointer)
        if (path) {
            const isComponentPointer =
                _.isEmpty(pointer.innerPath) && (pointer.type === 'DESKTOP' || pointer.type === 'MOBILE')
            if (isComponentPointer) {
                const parentId = this.getByPath(path.concat('parent'))
                if (parentId) {
                    const parentComponentsPath = path.slice(0, 4).concat(parentId, 'components')
                    const siblings = this.getByPath(parentComponentsPath)
                    this.setByPath(parentComponentsPath, _.without(siblings, pointer.id))
                } else {
                    const compsMap = path.slice(0, 4)
                    this.setByPath(compsMap, {})
                }

                const components = this.getByPath(path.concat('components'))
                _.forEach(components, compId => {
                    const childPointer = this.pointersCache.getPointer(compId, pointer.type)
                    if (childPointer) {
                        this.remove(childPointer)
                    }
                })
            }
            this.removeByPath(path)
        } else {
            throw new Error(`${pointer} pointer does not exist`)
        }
    }

    removeByPath(path) {
        if (!path) {
            throw new Error('path is not valid')
        }
        removeValueInPath(this.jsonData, path)
        this.pointersCache.resetValidations()
    }
    getKeysByPath(path) {
        pathValidation.validatePath(path, this.jsonData)
        return getKeysByPath(this.jsonData, path)
    }
}

class DisplayedJsonDalMobx extends DisplayedJsonDal {
    private mobxDataHandlers: any
    constructor(jsonData, pointersCache, mobxDataHandlers) {
        super(jsonData, pointersCache)
        this.mobxDataHandlers = mobxDataHandlers
    }

    // @ts-ignore
    getByPath(path, untracked, noClone) {
        if (path) {
            return this.mobxDataHandlers.getByPath(this.jsonData, path, !!untracked, !!noClone)
        }
    }

    setByPath(path, data) {
        if (!_.isArray(path)) {
            throw new Error(`path type is not an array - ${path}`)
        }
        this.mobxDataHandlers.setByPath(this.jsonData, path, data)
        this.pointersCache.resetValidations()
    }

    isPathExist(path) {
        this.mobxDataHandlers.getObservableByPath(this.jsonData, path)
        return super.isPathExist(path)
    }

    /**
     * shallow merge
     * @param pathToObject
     * @param obj
     */
    mergeByPath(pathToObject, obj) {
        if (_.isPlainObject(obj)) {
            if (isPathExist(this.jsonData, pathToObject, true)) {
                this.mobxDataHandlers.mergeByPath(this.jsonData, pathToObject, obj)
                this.pointersCache.resetValidations()
            }
        } else {
            throw new Error(`${obj} is not an object`)
        }
    }

    pushByPath(pathToArray, item, index) {
        const dataArray = this.mobxDataHandlers.getObservableByPath(this.jsonData, pathToArray)

        index = fixIndex(dataArray, index)

        this.mobxDataHandlers.pushByPath(this.jsonData, index, dataArray, item, pathToArray)
        this.pointersCache.resetValidations()
    }

    removeByPath(path) {
        if (!path) {
            throw new Error('path is not valid')
        }
        this.mobxDataHandlers.removeByPath(this.jsonData, path)
        this.pointersCache.resetValidations()
    }

    // @ts-ignore
    getKeysByPath(path, untracked) {
        pathValidation.validatePath(path, this.jsonData)
        return this.mobxDataHandlers.getKeysByPath(this.jsonData, path, untracked)
    }
}

export default function (jsonData, pointersCache, mobxDataHandlers) {
    return mobxDataHandlers
        ? new DisplayedJsonDalMobx(jsonData, pointersCache, mobxDataHandlers)
        : new DisplayedJsonDal(jsonData, pointersCache)
}
