import type {PublicMethodUtils} from '@wix/document-services-types'

export default function (publicMethodUtils: PublicMethodUtils) {
    const {
        extensionPublicAPI: {dataManipulation, getter}
    } = publicMethodUtils

    return {
        methods: {
            components: {
                responsiveLayout: {
                    reorderGridPageSection: dataManipulation('components.responsiveLayout.reorderGridPageSection'),
                    reorderGridPageSections: dataManipulation('components.responsiveLayout.reorderGridPageSections'),
                    isGridPageChildrenOrderValid: getter('components.responsiveLayout.isGridPageChildrenOrderValid')
                }
            }
        }
    }
}
