import {getComponentNickname} from '../utils/nicknameUtils'
import type {CreateExtArgs} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import type {DataModelExtensionAPI} from '../../dataModel/dataModel'
import _ from 'lodash'
import type {AppControllerAPI} from '../../appController'

const getWidgetData = ({pointers, dal}: CreateExtArgs, compData: Record<string, any>) => {
    if (!_.has(compData, ['applicationId'])) {
        return {}
    }
    const clientSpecMapPtr = pointers.general.getClientSpecMap()
    const csm = dal.get(clientSpecMapPtr)
    const appData = _.get(csm, compData.applicationId)
    const result = _.get(appData, ['widgets', compData.widgetId])
    return _.cloneDeep(result)
}

export const tpaSectionMetaData = {
    nickname: (createExtArgs: CreateExtArgs, compPointer: Pointer) => {
        const {extensionAPI} = createExtArgs
        const {appController} = extensionAPI as AppControllerAPI
        const nicknameFromStageData = appController.getNicknameFromStageData(compPointer)
        const {dal, pointers} = createExtArgs
        if (nicknameFromStageData) {
            return nicknameFromStageData
        }
        const {dataModel} = extensionAPI as DataModelExtensionAPI
        const compData = dataModel.components.getItem(compPointer, 'data')
        const widgetData = getWidgetData(createExtArgs, compData)
        if (widgetData) {
            const appPageId = _.get(widgetData, ['appPage', 'id'])
            return _.camelCase(appPageId)
        }

        return getComponentNickname({dal, pointers}, compPointer)
    },
    isRepeatable: false,
    isPublicContainer: false
}
