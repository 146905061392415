import type {
    LoadAndStoreComponentStageData,
    MobileAlgoPlugin,
    MobileAlgoPluginInitializationArgs,
    PluginHeuristicsRegistry
} from '../../types'
import type {VariantsExtensionAPI} from '../../../variants/variants'

export const createPlugin = ({
    stages,
    extensionAPI,
    pointers
}: MobileAlgoPluginInitializationArgs): MobileAlgoPlugin => {
    const name = 'storeLayout'
    const variantsApi = () => (extensionAPI as VariantsExtensionAPI).variants

    const storeLayoutToMobile = ({componentStructure, variants}: LoadAndStoreComponentStageData) => {
        if (componentStructure.layouts) {
            const compPointer = pointers.getPointer(componentStructure.id!, 'DESKTOP')
            variantsApi().updateComponentDataConsideringVariants(
                {...compPointer, variants},
                componentStructure.layouts,
                'layout'
            )
        }
    }
    const register = (registry: PluginHeuristicsRegistry) => {
        registry.register(stages.STORE, storeLayoutToMobile)
    }
    return {register, name, dependencies: ['loadRefs']}
}
