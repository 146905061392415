/**
 * @param {string} selector
 * @param {Array<import('./index').Property>} properties
 */
function flattenProperties(selector, properties = []) {
    return properties.reduce((acc, property) => {
        return {
            ...acc,
            [property.absolutePropName ? property.absolutePropName : `${selector}-${property.prop}`]: property.value
        }
    }, {})
}

/**
 * @param {import('./index').ParsedStyleItem['parsedStyle'][0]['propertiesOverride']} propertiesOverride
 */
function flattenPropertiesOverride(selector, propertiesOverride = {}) {
    return Object.entries(propertiesOverride).reduce((flattenedPropertiesOverride, [prop, override]) => {
        return {
            ...flattenedPropertiesOverride,
            [`${selector}-${prop}`]: override
        }
    }, {})
}

/**
 * @param {string} selector
 * @param {import('./index').ParsedStyleItem['parsedStyle'][0]['propertiesSource']} propertiesSource
 */
function flattenPropertiesSource(selector, propertiesSource = {}) {
    return Object.entries(propertiesSource).reduce((flattenedPropertiesSource, [prop, source]) => {
        return {
            ...flattenedPropertiesSource,
            [prop.includes(selector) ? prop : `${selector}-${prop}`]: source
        }
    }, {})
}

/**
 * @param {import('./index').ParsedStyleItem['parsedStyle']} parsedStyle
 */
function flattenParsedStyle(parsedStyle) {
    return Object.entries(parsedStyle).reduce(
        (
            flattenedParsedStyle,
            [selector, {mobileProperties, properties, mobilePropertiesOverride, propertiesOverride, propertiesSource}]
        ) => {
            return {
                properties: {
                    ...flattenedParsedStyle.properties,
                    ...flattenProperties(selector, properties)
                },
                mobileProperties: {
                    ...flattenedParsedStyle.mobileProperties,
                    ...flattenProperties(selector, mobileProperties)
                },
                propertiesOverride: {
                    ...flattenedParsedStyle.propertiesOverride,
                    ...flattenPropertiesOverride(selector, propertiesOverride)
                },
                mobilePropertiesOverride: {
                    ...flattenedParsedStyle.mobilePropertiesOverride,
                    ...flattenPropertiesOverride(selector, mobilePropertiesOverride)
                },
                propertiesSource: {
                    ...flattenedParsedStyle.propertiesSource,
                    ...flattenPropertiesSource(selector, propertiesSource)
                }
            }
        },
        {
            properties: {},
            mobileProperties: {},
            propertiesOverride: {},
            mobilePropertiesOverride: {},
            propertiesSource: {}
        }
    )
}

/**
 *
 * @param {import('./index').ParsedStyleItem} migratedStyleItem
 * @returns {import('./index').FlattenedMigratedStyleItem}
 */
function getFlattenedMigratedStyle(migratedStyleItem) {
    return {
        ...migratedStyleItem,
        ...flattenParsedStyle(migratedStyleItem.parsedStyle)
    }
}

/**
 * @param {boolean} isMobile
 * @param {import('./index').ParsedStyleItem} migratedStyleItem
 * @returns {import('./index').StyleItemWithStyleProperties['style']}
 */
function migratedStyleToStyleProperties(migratedStyleItem, isMobile = false) {
    const flattenStyleItem = getFlattenedMigratedStyle(migratedStyleItem)

    return {
        properties: isMobile ? flattenStyleItem.mobileProperties : flattenStyleItem.properties,
        propertiesOverride: isMobile ? flattenStyleItem.mobilePropertiesOverride : flattenStyleItem.propertiesOverride,
        groups: {},
        propertiesSource: flattenStyleItem.propertiesSource
    }
}

module.exports = {
    migratedStyleToStyleProperties
}
