import type {DocumentManager} from '@wix/document-manager-core'
import type {DataModelExtensionAPI} from '@wix/document-manager-extensions/src/extensions/dataModel/dataModel'
import type {Experiment} from '@wix/document-services-types'
import type {MasterPageMigrator} from '../dataMigrationRunner'

const MASTER_PAGE_ID = 'masterPage'
const EFFECTS_NAMESPACE = 'effects'
const keyframeEffectListPointer = {id: 'KEYFRAME_EFFECT_LIST', type: EFFECTS_NAMESPACE}

const migrateMasterPage = (documentManager: DocumentManager, experimentInstance: Experiment) => {
    const {dal, extensionAPI} = documentManager
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const doesKeyframeEffectListExist = dal.has(keyframeEffectListPointer)
    if (doesKeyframeEffectListExist || !experimentInstance.isOpen('dm_addKeyframeEffectListToMasterPage')) {
        return
    }
    dataModel.addItem(
        {
            type: 'KeyframeEffectList',
            values: []
        },
        EFFECTS_NAMESPACE,
        MASTER_PAGE_ID,
        'KEYFRAME_EFFECT_LIST'
    )
}

const name = 'addKeyframeEffectListToMasterPage'
const version = 0
const experimentalVersions = [{experiment: 'dm_addKeyframeEffectListToMasterPage', version: 1}]

export const addKeyframeEffectListToMasterPage: MasterPageMigrator = {
    migrateMasterPage,
    name,
    version,
    experimentalVersions
}
