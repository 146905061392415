/**
 * Created by alexandreroitman on 24/01/2016.
 */
import _ from 'lodash'
import structure from '../structure/structure'
import componentsMetaData from '../componentsMetaData/componentsMetaData'
import constants from '../constants/constants'
import type {Pointer, PS} from '@wix/document-services-types'

const {COMP_MATCH_SIZE_OPTIONS, COMP_ALIGNMENT_OPTIONS, COMP_DISTRIBUTION_OPTIONS} = constants

const filterComponentsByAction = {
    [COMP_MATCH_SIZE_OPTIONS.WIDTH]: filterStretchedComponents,
    [COMP_MATCH_SIZE_OPTIONS.BOTH]: filterStretchedComponents,

    [COMP_ALIGNMENT_OPTIONS.LEFT]: filterForAlignment,
    [COMP_ALIGNMENT_OPTIONS.RIGHT]: filterForAlignment,
    [COMP_ALIGNMENT_OPTIONS.CENTER]: filterForAlignment,
    [COMP_ALIGNMENT_OPTIONS.TOP]: filterForAlignment,
    [COMP_ALIGNMENT_OPTIONS.BOTTOM]: filterForAlignment,
    [COMP_ALIGNMENT_OPTIONS.MIDDLE]: filterForAlignment,

    [COMP_DISTRIBUTION_OPTIONS.HORIZONTAL]: filterStretchedComponents,
    [COMP_DISTRIBUTION_OPTIONS.BOTH]: filterStretchedComponents
} as const

function filterStretchedComponents(ps: PS, compPointerArray: Pointer[]) {
    return _.filter(compPointerArray, compPointer => !structure.isHorizontallyStretchedToScreen(ps, compPointer))
}

function filterComponentsForHorizontalAlignment(ps: PS, compPointerArray: Pointer[]) {
    if (_.size(compPointerArray) === 1) {
        return _.filter(compPointerArray, isAlignableToParent.bind(this, ps))
    }

    let compsToAlign = filterStretchedComponents(ps, compPointerArray)

    if (compsToAlign.length === 1) {
        compsToAlign = []
    }

    return compsToAlign
}

function filterForAlignment(ps: PS, compPointerArray: Pointer[], direction: string) {
    if (isHorizontalAxis(direction)) {
        return filterComponentsForHorizontalAlignment(ps, compPointerArray)
    }
    return compPointerArray
}

function getFilteredComponentsToApplyAction(ps: PS, compPointerArray: Pointer[], action: string) {
    if (_.isFunction(filterComponentsByAction[action])) {
        return filterComponentsByAction[action](ps, compPointerArray, action)
    }
    return compPointerArray
}

function isHorizontalAxis(direction: string) {
    return _.includes(
        [
            COMP_ALIGNMENT_OPTIONS.LEFT,
            COMP_ALIGNMENT_OPTIONS.RIGHT,
            COMP_ALIGNMENT_OPTIONS.CENTER,
            COMP_DISTRIBUTION_OPTIONS.HORIZONTAL,
            COMP_DISTRIBUTION_OPTIONS.BOTH,
            COMP_MATCH_SIZE_OPTIONS.WIDTH,
            COMP_MATCH_SIZE_OPTIONS.BOTH
        ],
        direction
    )
}

function isAlignableToParent(ps: PS, compPointer: Pointer) {
    return componentsMetaData.public.isAlignable(ps, compPointer)
}

export default {
    canAlign(ps: PS, compPointers: Pointer[], direction: string) {
        if (!direction || !_.size(compPointers)) {
            return false
        }
        const compsToBeAligned = getFilteredComponentsToApplyAction(ps, compPointers, direction)
        return _.size(compsToBeAligned) > 0
    },
    canDistribute(ps: PS, compPointers: Pointer[], distribution: string) {
        return distribution && _.size(getFilteredComponentsToApplyAction(ps, compPointers, distribution)) > 1
    },
    canMatchSize(ps: PS, compPointers: Pointer[], dimension: string) {
        return dimension && _.size(getFilteredComponentsToApplyAction(ps, compPointers, dimension)) > 1
    },

    isHorizontalAxis,
    getFilteredComponentsToApplyAction
}
