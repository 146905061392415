import type {Callback, Callback1, Pointer, PS, CompRef} from '@wix/document-services-types'
import _ from 'lodash'
import appStudioWidgets from '../appStudioWidgets/appStudioWidgets'
import experiment from 'experiment-amd'
import component from '../component/component'
import page from '../page/page'
import pageData from '../page/pageData'
import dsUtils from '../utils/utils'
import appStudioBobApp from './appStudioBobApp'
import appBuilderPlatformApp from './appBuilderPlatformApp'
import appStudioDataModel from './appStudioDataModel'
import nameGenerator from './nameGenerator'
import panelConfig from './panelConfigs/panelConfig'
import controllerConfig from './controllerConfig'
import features from '../features/features'
import {ReportableError} from '@wix/document-manager-utils'
import type {PageExtensionAPI} from '@wix/document-manager-extensions'

const NEW_PANEL_NAME_PREFIX = 'Panel'
const PANEL_TYPE = 'PanelDescriptor'
const PANEL_KIND = {
    SETTINGS: 'settings'
}

const PANEL_TITLE = 'Panel Title'
const PANEL_ERRORS = {
    EMPTY_NAME: 'EMPTY_NAME',
    NAME_ALREADY_EXISTS: 'NAME_ALREADY_EXISTS',
    INVALID_NAME: 'INVALID_NAME',
    INVALID_LENGTH: 'INVALID_LENGTH',
    ROOT_COMP_ID_DOES_NOT_EXIST: 'appStudio.panels: root comp id does not exists',
    INVALID_POINTER: 'appStudio.panels: provided panel pointer does not exists'
}

const PANEL_TITLE_ERRORS = {
    EMPTY_NAME: 'EMPTY_NAME',
    INVALID_LENGTH: 'INVALID_LENGTH'
}

const PANEL_HELP_ID_ERRORS = {
    NOT_GUID: 'NOT_GUID'
}

const PANEL_HEIGHT_ERRORS = {
    OUT_OF_RANGE: 'OUT_OF_RANGE'
}

const DEFAULT_PANEL_HEIGHT = 243

const PANEL_PROPS_DUPLICATION_BLOCK_LIST = ['id', 'name', 'rootCompId', 'metaData', 'pageUrl']

function generateNewPanelName(ps: PS, prefix = NEW_PANEL_NAME_PREFIX, divider = ' ') {
    return nameGenerator.generateName(appStudioDataModel.getAllPanels(ps), `${prefix}${divider}`)
}

function validatePanelName(ps: PS, name: string, widgetPointer: Pointer) {
    if (_.isEmpty(name)) {
        return {success: false, errorCode: PANEL_ERRORS.EMPTY_NAME}
    }

    if (_.size(name) > 100) {
        return {success: false, errorCode: PANEL_ERRORS.INVALID_LENGTH}
    }

    if (_.includes(_.map(appStudioDataModel.getWidgetPanelsData(ps, widgetPointer), 'name'), name)) {
        return {success: false, errorCode: PANEL_ERRORS.NAME_ALREADY_EXISTS}
    }

    return {success: true}
}

function updateNameData(ps: PS, dataPointer: Pointer, name: string) {
    const panelData = appStudioDataModel.getData(ps, dataPointer)
    panelData.name = name
    appStudioDataModel.setData(ps, dataPointer, panelData)
}

function setPageTitle(ps: PS, pageId: string, pageTitle: string) {
    const panelPageData = pageData.getPageData(ps, pageId)
    panelPageData.title = pageTitle
    pageData.setPageData(ps, pageId, panelPageData)
}

function syncPanelNameWithPage(ps: PS, panelPointer: Pointer, panelName: string) {
    const pageId = appStudioDataModel.getRootCompIdByPointer(ps, panelPointer)
    setPageTitle(ps, pageId, panelName)
}

function createBlankPanelData(ps: PS, panelPointer: Pointer, name: string, newPageRef: Pointer) {
    const panel = ps.extensionAPI.dataModel.createDataItemByType(PANEL_TYPE)
    panel.id = panelPointer.id
    panel.name = name
    panel.title = PANEL_TITLE
    panel.kind = PANEL_KIND.SETTINGS
    panel.rootCompId = `#${_.get(newPageRef, 'id')}`
    panel.height = DEFAULT_PANEL_HEIGHT

    return panel
}

function addPanelToAppStudio(ps: PS, panelPointer: Pointer, panelData) {
    appStudioDataModel.setData(ps, panelPointer, panelData)
}

function createPanelPageWithStructure(
    ps: PS,
    panelName: string,
    pageRef: CompRef,
    serializePanelStructure: Record<string, any> = panelConfig.getPanelStructure()
) {
    page.add(ps, pageRef, panelName, panelConfig.getPageStructure())

    features.updateFeatureData(ps, pageRef, 'intent', {intent: 'panelPage', type: 'Intent'})

    const stageContainerRef = component.getComponentToAddRef(ps, pageRef)

    const wrappedPanelStructure = _.defaultsDeep(
        {components: [serializePanelStructure]},
        panelConfig.appWidgetStructure
    )

    component.add(ps, stageContainerRef, pageRef, wrappedPanelStructure)
}

function assignPanelToWidget(ps: PS, widgetPointer: Pointer, panelPointer: Pointer) {
    const widgetData = appStudioDataModel.getData(ps, widgetPointer)
    const panelId = `#${panelPointer.id}`

    if (!widgetData.panels.includes(`#${panelPointer.id}`)) {
        widgetData.panels = [...widgetData.panels, panelId]
    }

    appStudioDataModel.setWidgetData(ps, widgetPointer, widgetData)
}

function createPanel(
    ps: PS,
    panelPointer: Pointer,
    widgetPointer: Pointer,
    name: string,
    serializePanelStructure?: Record<string, any>
) {
    const panelName = name || generateNewPanelName(ps)
    const {success, errorCode} = validatePanelName(ps, panelName, widgetPointer)

    if (!success) {
        throw new Error(errorCode)
    }

    const newPageRef = page.getPageIdToAdd(ps)
    const newPanel = createBlankPanelData(ps, panelPointer, panelName, newPageRef)
    addPanelToAppStudio(ps, panelPointer, newPanel)

    createPanelPageWithStructure(ps, panelName, newPageRef, serializePanelStructure)

    assignPanelUrl(ps, newPageRef, panelPointer)
    assignPanelToWidget(ps, widgetPointer, panelPointer)

    const rootAppWidget = appStudioDataModel.getRootAppWidgetByPage(ps, newPageRef)
    appStudioWidgets.setInitialAppWidgetData(ps, rootAppWidget, newPageRef.id)
}

function assignPanelUrl(ps: PS, pageRef: Pointer, panelPointer: Pointer) {
    const pageUrl = (ps.extensionAPI as PageExtensionAPI).page.getPageUriSEO(pageRef.pageId)

    const panelData = appStudioDataModel.getData(ps, panelPointer)
    panelData.pageUrl = pageUrl
    appStudioDataModel.setData(ps, panelPointer, panelData)
}

function displayPanel(ps: PS, panelPointer: Pointer, callback: Callback) {
    const rootCompId = appStudioDataModel.getRootCompIdByPointer(ps, panelPointer)

    if (!rootCompId) {
        throw new Error(PANEL_ERRORS.ROOT_COMP_ID_DOES_NOT_EXIST)
    }

    page.navigateTo(ps, rootCompId, callback)
}

function removePanel(ps: PS, panelPointer: Pointer, widgetPointer: Pointer, callback?: Callback1<any>) {
    const rootCompId = appStudioDataModel.getRootCompIdByPointer(ps, panelPointer)
    removePanelFromWidget(ps, panelPointer.id, widgetPointer)
    page.remove(ps, rootCompId, callback)
}

function removePanelFromWidget(ps: PS, panelId: string, widgetPointer: Pointer) {
    const widgetData = appStudioDataModel.getData(ps, widgetPointer)
    const panels = widgetData.panels.filter(panel => dsUtils.stripHashIfExists(panel) !== panelId)

    if (panels.length !== widgetData.panels.length) {
        widgetData.panels = panels
        appStudioDataModel.setWidgetData(ps, widgetPointer, widgetData)
    }
}

function getPanelPointerByRootCompId(ps: PS, rootCompId: string) {
    const appStudioData = appStudioDataModel.getAppStudioData(ps) || {}
    const panelData = _(appStudioData.widgets)
        .map(widget => widget.panels)
        .flatten()
        .find(currentPanel => dsUtils.stripHashIfExists(currentPanel?.rootCompId) === rootCompId)

    return panelData && ps.pointers.data.getDataItemFromMaster(panelData.id)
}

function getRootCompIdByPanelPointer(ps: PS, panelPointer: Pointer) {
    return appStudioDataModel.getRootCompIdByPointer(ps, panelPointer)
}

function duplicatePanel(ps: PS, newPointer: Pointer, panelPointer: Pointer, widgetPointer: Pointer) {
    const panelToCopy = appStudioDataModel.getData(ps, panelPointer)

    if (!panelToCopy) {
        throw new Error(PANEL_ERRORS.INVALID_POINTER)
    }

    const newPanelName = generateNewPanelName(ps, panelToCopy.name)
    const newPageRef = page.getPageIdToAdd(ps)
    const blankPanelData = createBlankPanelData(ps, newPointer, newPanelName, newPageRef)

    const duplicatedPanel = _.assign(blankPanelData, _.omit(panelToCopy, PANEL_PROPS_DUPLICATION_BLOCK_LIST))

    addPanelToAppStudio(ps, newPointer, duplicatedPanel)
    assignPanelToWidget(ps, widgetPointer, newPointer)

    page.duplicate(ps, newPageRef, dsUtils.stripHashIfExists(panelToCopy.rootCompId))
    syncPanelNameWithPage(ps, newPointer, newPanelName)

    assignPanelUrl(ps, newPageRef, newPointer)

    const rootAppWidget = appStudioDataModel.getRootAppWidgetByPage(ps, newPageRef)
    appStudioWidgets.setInitialAppWidgetData(ps, rootAppWidget, newPageRef.id)
}

function renamePanel(ps: PS, panelPointer: Pointer, widgetPointer: Pointer, name: string) {
    validatePanelName(ps, name, widgetPointer)
    updateNameData(ps, panelPointer, name)
    syncPanelNameWithPage(ps, panelPointer, name)
    appBuilderPlatformApp.updateApp(ps)
}

function validateHelpId(helpId: string) {
    if (!_.isEmpty(helpId) && !dsUtils.isGuid(helpId)) {
        return {success: false, errorCode: PANEL_HELP_ID_ERRORS.NOT_GUID}
    }
    return {success: true}
}

function setHelpId(ps: PS, panelPointer: Pointer, helpId: string) {
    const validationResult = validateHelpId(helpId)
    if (!validationResult.success) {
        throw new Error(`appStudio.panels.helpid: ${validationResult.errorCode}`)
    }
    const panelData = appStudioDataModel.getData(ps, panelPointer)
    panelData.helpId = helpId
    appStudioDataModel.setData(ps, panelPointer, panelData)
}

function validateTitle(panelTitle: string) {
    if (_.isEmpty(panelTitle)) {
        return {success: false, errorCode: PANEL_TITLE_ERRORS.EMPTY_NAME}
    }

    if (_.size(panelTitle) > 100) {
        return {success: false, errorCode: PANEL_TITLE_ERRORS.INVALID_LENGTH}
    }

    return {success: true}
}

function setTitle(ps: PS, panelPointer: Pointer, title: string) {
    const validationResult = validateTitle(title)
    if (!validationResult.success) {
        throw new Error(`appStudio.panels.title: ${validationResult.errorCode}`)
    }

    const panelData = appStudioDataModel.getData(ps, panelPointer)
    panelData.title = title
    appStudioDataModel.setData(ps, panelPointer, panelData)
}

function validateHeight(height: number) {
    if (height < 0) {
        return {success: false, errorCode: PANEL_HEIGHT_ERRORS.OUT_OF_RANGE}
    }
    return {success: true}
}

function setHeight(ps: PS, panelPointer: Pointer, height: number) {
    const validationResult = validateHeight(height)
    if (!validationResult.success) {
        throw new Error(`appStudio.panels.height: ${validationResult.errorCode}`)
    }

    const panelData = appStudioDataModel.getData(ps, panelPointer)
    panelData.height = height
    appStudioDataModel.setData(ps, panelPointer, panelData)
}

function updatePanelsRootData(ps: PS, options?) {
    const panelsBobData = experiment.isOpen('dm_doNotFetchAppDataInBlocksPreBuild')
        ? {
              isBobApp: ps.dal.get(ps.pointers.general.getAppStudioModelPointer()).bobData,
              artifactId: extractArtifactId(ps.dal.get(ps.pointers.general.getAppStudioModelPointer()).bobData)
          }
        : {
              isBobApp: appStudioBobApp.isBobAppData(options.appData),
              artifactId: getArtifactId(options.appData)
          }

    const allPanels = appStudioDataModel.getAllPanels(ps)
    allPanels.forEach(panel =>
        updatePanelControllerConfig(ps, panel, {
            isPanelController: true,
            artifactId: panelsBobData.isBobApp ? panelsBobData.artifactId : undefined
        })
    )
}

function extractArtifactId(bobData) {
    return _.values(bobData.widgetToRelatedArtifact)[0]
}
function getArtifactId(appData) {
    const mainStudioWidget = appData.components.find(
        comp => comp.compType === 'STUDIO_WIDGET' && getStudioWidgetRelatedArtifact(comp)
    )

    if (!mainStudioWidget) {
        return
    }

    return getStudioWidgetRelatedArtifact(mainStudioWidget)
}

function getStudioWidgetRelatedArtifact(studioWidgetComponent) {
    return studioWidgetComponent?.compData?.studioWidgetComponentData?.relatedArtifact
}

function updatePanelControllerConfig(ps: PS, panel, config) {
    const pageId = appStudioDataModel.getRootCompIdByPointer(ps, panel.pointer)
    const pagePointer = page.getPage(ps, pageId)
    const rootAppWidget = appStudioDataModel.getRootAppWidgetByPage(ps, pagePointer)

    if (rootAppWidget) {
        controllerConfig.update(ps, rootAppWidget, config)
    } else {
        ps.extensionAPI.logger.captureError(
            new ReportableError({
                message: 'Could not update controller config for panel - root app widget not found',
                errorType: 'noAppWidgetForPanel',
                tags: {blocks: true},
                extras: {panelPageId: pageId}
            })
        )
    }
}

const movePanel = (ps: PS, widgetPointer: Pointer, fromIndex: number, toIndex: number) => {
    appStudioDataModel.moveWidgetEntity(ps, widgetPointer, 'panels', fromIndex, toIndex)
}

export default {
    generateNewPanelName,
    createPanel,
    removePanel,
    duplicatePanel,
    renamePanel,
    displayPanel,
    getPanelPointerByRootCompId,
    getRootCompIdByPanelPointer,
    setHelpId,
    setTitle,
    setHeight,
    validateTitle: (ps: PS, title: string) => validateTitle(title),
    validateHelpId: (ps: PS, helpId: string) => validateHelpId(helpId),
    validatePanelName,
    updatePanelsRootData,
    movePanel
}
