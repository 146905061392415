'use strict'

const _ = require('lodash')

function fixProperties(properties) {
    _.forEach(properties, property => {
        if (property.type === 'WPhotoProperties' && property.effectName) {
            if (!property.filterEffect && property.effectName !== 'none') {
                property.filterEffect = {
                    effectType: property.effectName
                }
            }
            delete property.effectName
        }
    })
}

module.exports = {
    name: 'filterEffectPropertyFixer',
    version: 1,
    exec(pageJson) {
        const properties = pageJson.data.component_properties
        if (!_.isEmpty(properties)) {
            fixProperties(properties)
        }
    },
    fixerRequiresReruns: true
}
