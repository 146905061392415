export default {
    SCROLL: 'SCROLL',
    PAGE_NAVIGATION: 'PAGE_NAVIGATION',
    PAGE_NAVIGATION_IN: 'PAGE_NAVIGATION_IN',
    PAGE_NAVIGATION_OUT: 'PAGE_NAVIGATION_OUT',
    PAGE_NAVIGATION_CHANGE: 'PAGE_NAVIGATION_CHANGE',
    STATE_CHANGED: 'STATE_CHANGED',
    SESSION_CHANGED: 'SESSION_CHANGED',
    INSTANCE_CHANGED: 'INSTANCE_CHANGED',
    CONSENT_POLICY_UPDATE: 'CONSENT_POLICY_UPDATE',
    COMMON_CONFIG_UPDATE: 'COMMON_CONFIG_UPDATE',
    MEMBER_DETAILS_UPDATED: 'MEMBER_DETAILS_UPDATED',
    SITE_METADATA_CHANGED: 'SITE_METADATA_CHANGED',
    KEY_DOWN: 'KEY_DOWN',
    KEY_UP: 'KEY_UP'
}
