import type {Layout, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import component from '../../component/component'
import layouters from '../../layouters/layouters'
import layoutHooksUtils, {LayoutCallback} from './utils/layoutHooksUtils'

export default (ps: PS, compPointer: Pointer, slideShowNewLayout: Layout, updateCompLayoutCallback: LayoutCallback) => {
    if (!_.isFunction(updateCompLayoutCallback)) {
        return
    }

    const slideShowCurrentLayout = component.layout.get(ps, compPointer)
    if (layoutHooksUtils.isCompResizing(slideShowCurrentLayout, slideShowNewLayout)) {
        //no need for slides changes on position change
        const boxSlides = layouters.getNonMasterChildren(ps, compPointer)
        const slideNewLayout = _.pick(slideShowNewLayout, ['width', 'height'])
        // @ts-expect-error
        _.forEach(boxSlides, slide => updateCompLayoutCallback(ps, slide, slideNewLayout))
    }
}
