import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'
import dataModel from '../../dataModel/dataModel'
import type {DsFixer} from '../dsDataFixers'

function replaceFullScreenModeToDock(ps: PS, compPointer: Pointer, compProperties) {
    const layoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
    const layout = ps.dal.full.get(layoutPointer)
    layout.docked = {left: {vw: 0}, right: {vw: 0}}
    ps.dal.full.set(layoutPointer, layout)

    compProperties.fullScreenModeOn = false
    dataModel.updatePropertiesItem(ps, compPointer, compProperties)
}

const fixer: DsFixer = {
    exec(ps: PS) {
        const allFiveGridLinesPointersInSite = componentDetectorAPI.getComponentByType(
            ps,
            'wysiwyg.viewer.components.FiveGridLine'
        )
        _.forEach(allFiveGridLinesPointersInSite, compPointer => {
            const compProperties = dataModel.getPropertiesItem(ps, compPointer)
            const isFullModeOn = compProperties?.fullScreenModeOn
            if (isFullModeOn) {
                replaceFullScreenModeToDock(ps, compPointer, compProperties)
            }
        })
    },
    name: 'fiveGridLineFullWidthFixer',
    version: 1
}
export default fixer
