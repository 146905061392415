import type {PS, WixCodeFileDescriptor} from '@wix/document-services-types'
import constants from '../utils/constants'
import _ from 'lodash'
import type {CodeAppInfo} from '../utils/codeAppInfo'
import type {DistributorExtensionAPI} from '@wix/document-manager-extensions/src/extensions/distributor/distributor'
import type {WixCodeFileStateChange} from '@wix/document-manager-extensions/src/extensions/distributor/messages'
import type {WixCodeExtensionAPI} from '@wix/document-manager-extensions'

const PUBLIC_FOLDER_NAME = 'public'
const BACKEND_FOLDER_NAME = 'backend'
const SCHEMAS_FOLDER_NAME = '.schemas'
const STYLES_FOLDER_NAME = 'styles'
const COMPONENTS_FOLDER_NAME = 'components'
const PATH_SEPARATOR = '/'
const LONG_LOCATION_FORMAT_REGEX = /^\/file\/[^\/]+\/(.+)$/

export interface WixCodeFile {
    fileId: string
    content: string
}
export interface FileSystemItem {
    name: string
    directory: boolean
    location: string
    content?: string
}

export interface IFileSystemService {
    createFolder(ps: PS, folderName: string, parentFolder: WixCodeFileDescriptor): Promise<WixCodeFileDescriptor>
    deleteItem(
        extensionAPI: PS['extensionAPI'],
        codeAppInfo: CodeAppInfo,
        itemToDelete: WixCodeFileDescriptor
    ): Promise<WixCodeFileDescriptor>
    copy(
        extensionAPI: PS['extensionAPI'],
        codeAppInfo: CodeAppInfo,
        itemToCopy: WixCodeFileDescriptor,
        targetFolder: WixCodeFileDescriptor,
        newName?: string
    ): Promise<WixCodeFileDescriptor>
    move(
        ps: PS,
        itemToMove: WixCodeFileDescriptor,
        targetFolder: WixCodeFileDescriptor,
        newName?: string
    ): Promise<WixCodeFileDescriptor>
    getChildren(ps: PS, parentFolder: WixCodeFileDescriptor): Promise<FileSystemItem[]>
    readAllFiles(ps: PS): Promise<{path: string; content?: string}[]>
    readFile(ps: PS, file: WixCodeFileDescriptor): Promise<string>
    writeFile(
        extensionAPI: PS['extensionAPI'],
        codeAppInfo: CodeAppInfo,
        file: WixCodeFileDescriptor,
        content?: string
    ): Promise<WixCodeFileDescriptor>
    bulkWrite(extensionAPI: PS['extensionAPI'], codeAppInfo: CodeAppInfo, files: WixCodeFile[]): Promise<void>
}

export function getRoots() {
    return {
        schemas: getVirtualDescriptor(SCHEMAS_FOLDER_NAME, true),
        public: getVirtualDescriptor(PUBLIC_FOLDER_NAME, true),
        pages: getVirtualDescriptor(`${PUBLIC_FOLDER_NAME}/${constants.PAGES_ROOT}`, true),
        backend: getVirtualDescriptor(BACKEND_FOLDER_NAME, true),
        styles: getVirtualDescriptor(STYLES_FOLDER_NAME, true),
        components: getVirtualDescriptor(COMPONENTS_FOLDER_NAME, true)
    }
}

export function getVirtualDescriptor(givenPath: string, isDirectory: boolean): WixCodeFileDescriptor {
    const path = stripLastPathSeparatorIfExists(givenPath)
    return {
        virtual: true,
        localTimeStamp: 0,
        eTag: '"virtual"',
        name: path.split(PATH_SEPARATOR).pop(),
        length: 0,
        directory: Boolean(isDirectory),
        location: path + (isDirectory ? PATH_SEPARATOR : ''),
        attributes: {
            readOnly: false
        }
    }
}

export function stripLastPathSeparatorIfExists(relativeLocation: string) {
    return relativeLocation.replace(new RegExp(`${PATH_SEPARATOR}*$`), '')
}

export function notifyLocalPathsChanged(
    extensionsAPI: PS['extensionAPI'],
    fileAndFolderPathsThatHaveChanged: string[]
) {
    const {wixCode} = extensionsAPI as WixCodeExtensionAPI
    if (!wixCode.getIsolatedGridAppId()) {
        const {distributor} = extensionsAPI as DistributorExtensionAPI
        const changedPaths = fileAndFolderPathsThatHaveChanged.map(ensureShortLocationFormat)
        distributor.distributeMessage<WixCodeFileStateChange>('wixCodeFileStateChange', {
            changedPaths
        })
    }
}

export function parentPath(childPath: string) {
    return (
        stripLastPathSeparatorIfExists(childPath).split(PATH_SEPARATOR).slice(0, -1).join(PATH_SEPARATOR) +
        PATH_SEPARATOR
    )
}

export function ensureLongLocationFormat(location: string, codeAppInfo) {
    const result = location.match(LONG_LOCATION_FORMAT_REGEX)
    return result ? location : `/file/${codeAppInfo.appId}/${location}`
}

export function ensureShortLocationFormat(location: string) {
    const result = location.match(LONG_LOCATION_FORMAT_REGEX)
    return result && result.length > 1 ? result[1] : location
}

export function ensureDescriptorWithShortLocationFormat(descriptor: WixCodeFileDescriptor): WixCodeFileDescriptor {
    return _.assign({}, descriptor, {location: ensureShortLocationFormat(descriptor.location)})
}

export function ensureDescriptorWithoutTrailingSlash(descriptor: WixCodeFileDescriptor): WixCodeFileDescriptor {
    return _.assign({}, descriptor, {location: stripLastPathSeparatorIfExists(descriptor.location)})
}

export function isValidName(str: string) {
    return _.isString(str) && str.length > 0
}

export function isValidContent(content: string) {
    return _.isString(content)
}
