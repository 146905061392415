import {CoreLogger, DalValue, Namespace, Null, pointerUtils, SnapshotDal} from '@wix/document-manager-core'
import type {Experiment} from '@wix/document-services-types'
import _ from 'lodash'
import type {Namespaces} from '../../types'
import type {SafeRemovalError, SafeRemovalResult, SchemaAPI} from '../schema/schema'
import {isNamespaceFragmentRelated, removeExclusions} from './csaveDataRules'
import {Action, ActionOperation, ActionTransientData} from './serverProtocol'
import {ReportableError} from '@wix/document-manager-utils'
import type {RelationshipsAPI} from '../relationships'
import {DistributorAPI, pendingMessagesPointerType} from '../distributor/distributor'
import type {DistributeMessage} from '../distributor/messages'

const {getPointer, getSignaturePointer} = pointerUtils

const getOp = (value: any, oldValue: any): ActionOperation => {
    if (oldValue === undefined) {
        return ActionOperation.ADD
    }
    if (value === undefined) {
        return ActionOperation.REMOVE
    }
    return ActionOperation.REPLACE
}

const getOldValue = (prev: Null<SnapshotDal>, namespace: string, id: string) => prev?.getValue({type: namespace, id})

const getPageId = (val: any) => _.get(val, ['metaData', 'pageId'])

export interface ConvertResult {
    result?: Action[]
    error?: SafeRemovalError
}

function removeWhitelistedPropsAndValidate(
    filtered: Namespaces,
    schemaService: SchemaAPI,
    logger: CoreLogger
): SafeRemovalResult {
    const conservativeRemoval = true
    const removeWhitelistedPropertiesSafelyResult: Record<
        string,
        Record<string, any>
    > = schemaService.removeWhitelistedPropertiesSafely(filtered, conservativeRemoval)
    let hadError = false
    _.forEach(removeWhitelistedPropertiesSafelyResult, (namespaceData, namespaceName) => {
        _.forEach(namespaceData, data => {
            if (!data) {
                return
            }
            if (schemaService.hasNamespace(namespaceName)) {
                try {
                    schemaService.validate(data.type, data, namespaceName)
                } catch (error: any) {
                    hadError = true
                    _.forEach(error.errors, validationError => {
                        logger.captureError(
                            new ReportableError({
                                message: validationError.message,
                                errorType: 'PostWhitelistError',
                                tags: {},
                                extras: {
                                    type: data.type,
                                    validationError,
                                    data
                                }
                            })
                        )
                    })
                }
            }
        })
    })
    if (hadError) {
        return removeProps(filtered, false, schemaService, logger)
    }
    return {result: removeWhitelistedPropertiesSafelyResult, error: null}
}

function removeProps(
    filtered: Namespaces,
    whitelistRemovalExperiment: boolean,
    schemaService: SchemaAPI,
    logger: CoreLogger
): SafeRemovalResult {
    if (whitelistRemovalExperiment) {
        return removeWhitelistedPropsAndValidate(filtered, schemaService, logger)
    }

    const removeAdditionalPropertiesSafelyResult = schemaService.removeAdditionalPropertiesSafely(filtered)

    return _.pick(removeAdditionalPropertiesSafelyResult, ['error', 'result'])
}

const getActionTransientData = (
    relationships: RelationshipsAPI,
    id: string,
    namespace: Namespace
): ActionTransientData => {
    const dalItemPointer = getPointer(id, namespace)
    const owningComp = relationships.relationships.getOwningComponentToPointer(dalItemPointer)
    return {
        owningComponentId: owningComp?.id
    }
}
const extractPendingMessages = (data: Namespaces) => {
    const pendingMessagesData = data[pendingMessagesPointerType]
    if (!pendingMessagesData) {
        return []
    }
    return _(pendingMessagesData)
        .values()
        .compact()
        .map(p => ({message: p?.message, options: p?.options}))
        .value()
}
const handlePendingDistributedMessage = (distributor: DistributorAPI, data: Namespaces, correlationId: string) => {
    const pendingMessages = extractPendingMessages(data)
    _.forEach(pendingMessages, ({message}) =>
        distributor.attachMessageToTransactionApproval(message as unknown as DistributeMessage, correlationId)
    )
}

export const convertData = (
    prev: Null<SnapshotDal>,
    current: SnapshotDal,
    logger: CoreLogger,
    experimentInstance: Experiment,
    schemaService: SchemaAPI,
    relationships: RelationshipsAPI,
    distributor: DistributorAPI
): ConvertResult => {
    const data: Namespaces = current.diff(prev) as Namespaces
    handlePendingDistributedMessage(distributor, data, current.id)
    let filtered = removeExclusions(data)
    if (!experimentInstance.isOpen('dm_clientSideFragments')) {
        filtered = _.omitBy(filtered, (_value, key) => isNamespaceFragmentRelated(key))
    }
    const filteredKeys = Object.keys(filtered)
    const removedNamespaces = _.difference(Object.keys(data), filteredKeys)
    logger.interactionStarted('removeExclusions', {
        tags: {id: current.id},
        extras: {
            removedNamespaces
        }
    })

    const isWhitelistRemoval = experimentInstance.isOpen('dm_useWhitelistRemovalForCsave')
    const removalResult = removeProps(filtered, isWhitelistRemoval, schemaService, logger)
    if (removalResult.error) {
        return {error: removalResult.error}
    }

    const newData: Action[] = _.flatMap(removalResult.result, (v: Record<string, DalValue>, namespace: string) =>
        _.flatMap(v, (value: DalValue, id: string) => {
            const oldValue = getOldValue(prev, namespace, id)
            const oldPage = getPageId(oldValue)
            const newPage = getPageId(value)
            const previousPageId = oldPage === newPage ? undefined : oldPage
            const op = getOp(value, oldValue)
            const basedOnSignaturePointer = getSignaturePointer(getPointer(id, namespace))
            const basedOnSignature = prev?.getValue(basedOnSignaturePointer)

            return {
                op,
                id,
                namespace,
                value: value ?? null,
                previousPageId,
                basedOnSignature,
                transientData: getActionTransientData(relationships, id, namespace)
            }
        })
    )

    if (!newData.length) {
        logger.interactionStarted('emptyTransaction', {
            tags: {id: current.id},
            extras: {
                filteredKeys,
                removedNamespaces,
                isWhitelistRemoval
            }
        })
    }

    return {result: newData}
}

const convertNamespaceFromServerStyle = (schemaAPI: SchemaAPI, name?: string): string | undefined =>
    name ? schemaAPI.convertNamespaceFromServerStyle(name) : name

const convertActionNamespaceFromServerStyle = (schemaAPI: SchemaAPI, action: Action): Action => ({
    ...action,
    namespace: convertNamespaceFromServerStyle(schemaAPI, action.namespace)
})

export const convertActionsNamespacesFromServerStyle = (schemaAPI: SchemaAPI, actions: Action[]): Action[] =>
    actions.map(action => convertActionNamespaceFromServerStyle(schemaAPI, action))
