import type {DocumentManager} from '@wix/document-manager-core'
import type {TPAExtensionAPI} from '@wix/document-manager-extensions/src/extensions/tpa'
import type {MasterPageMigrator} from '../dataMigrationRunner'

const migrateMasterPage = (documentManager: DocumentManager) => {
    ;(documentManager.extensionAPI as TPAExtensionAPI).tpa.fixers.removeItemsWithEmptyCompId()
}

const name = 'removeBrokenTpaSharedState'
const version = 1

export const removeBrokenTpaSharedState: MasterPageMigrator = {
    migrateMasterPage,
    name,
    version
}
