import type {Pointer} from '@wix/document-services-types'
import * as constants from '../../constants/constants'
import type {PageExtensionAPI} from '../page'
import type {InitializeExtArgs} from '@wix/document-manager-core'
import type {DataModelExtensionAPI} from '../dataModel/dataModel'
const {
    DATA_TYPES,
    MASTER_PAGE_ID,
    ATOMIC_SCOPES: {TYPE, URI_PREFIX}
} = constants

const getAtomicScopePointer = ({pointers}: InitializeExtArgs, id: string) =>
    pointers.data.getItem(DATA_TYPES.atomicScopes, id, MASTER_PAGE_ID)

const addAtomicScope = (extArgs: InitializeExtArgs, id: string) => {
    const data = {type: TYPE, id}
    const {dataModel} = extArgs.extensionAPI as DataModelExtensionAPI
    dataModel.addItem(data, DATA_TYPES.atomicScopes, MASTER_PAGE_ID)
}

const removeAtomicScope = (extArgs: InitializeExtArgs, id: string) => {
    const {dal} = extArgs
    const pointer = getAtomicScopePointer(extArgs, id)
    dal.remove(pointer)
}

const changeAtomicScope = (extArgs: InitializeExtArgs, oldId: string, newId: string) => {
    removeAtomicScope(extArgs, oldId)
    addAtomicScope(extArgs, newId)
}

const getPageUriAtomicScopeId = ({extensionAPI}: InitializeExtArgs, pageId: string) => {
    const {page} = extensionAPI as PageExtensionAPI
    const pageUriSEO = page.getPageUriSEO(pageId)
    return `${URI_PREFIX}-${pageUriSEO}`
}

const getRouterAtomicScopeId = ({dal}: InitializeExtArgs, routerPointer: Pointer) => {
    const routerData = dal.get(routerPointer)
    const {prefix} = routerData
    return `${URI_PREFIX}-${prefix}`
}

export {
    addAtomicScope,
    removeAtomicScope,
    changeAtomicScope,
    getAtomicScopePointer,
    getPageUriAtomicScopeId,
    getRouterAtomicScopeId
}
