import type {PS} from '@wix/document-services-types'
import dataModel from '../../../dataModel/dataModel'
import textStats from '../textStats'

const getProps = (ps: PS, component, pageId: string) => {
    const propsPointer = ps.pointers.data.getPropertyItem(component.propertyQuery, pageId)
    return dataModel.getPropertiesItemById(ps, propsPointer.id, pageId) || {}
}

const getWRichTextConversionData = (ps: PS, component, pageId: string) => {
    const {verticalText} = getProps(ps, component, pageId)

    const conversionData: any = {
        isVerticalText: verticalText
    }
    if (verticalText) {
        const {approximateMobileHeight, approximateMobileWidth} = textStats.getTextsData(
            ps,
            component,
            pageId,
            verticalText
        )
        conversionData.mobileHints = {
            recommendedHeight: approximateMobileHeight,
            recommendedWidth: approximateMobileWidth
        }
    }
    return conversionData
}

export default {
    getWRichTextConversionData
}
