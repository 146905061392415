import type {
    DeepPageStructure,
    DeepStructure,
    Pointer,
    PossibleViewModes,
    DeepStructureOptions
} from '@wix/document-services-types'
import _ from 'lodash'
import {DATA_TYPES, MULTILINGUAL_TYPES, PAGE_DATA_TYPES, PAGE_SCHEMA, VIEW_MODES} from '../constants/constants'
import {getDeepStructureForComp} from './structureUtils'
import {getTranslationsByPage} from './translationUtils'
import type {MeshLayoutExtApi} from '../extensions/meshLayout/meshLayout'
import {deepClone} from '@wix/wix-immutable-proxy'
import type {DAL, DmApis} from '@wix/document-manager-core'

const PAGE_PART_SEPARATOR = '|'

interface GetterOptions extends DeepStructureOptions {
    isFull?: boolean
}

const getPageFlatStructure = (dal: DAL, pageFilter: any) => {
    const desktopComps = dal.query(VIEW_MODES.DESKTOP, pageFilter)
    const mobileComps = dal.query(VIEW_MODES.MOBILE, pageFilter)

    return {
        DESKTOP: desktopComps,
        MOBILE: mobileComps
    }
}

const getDeepStructureForPageComponent = (
    dsApis: DmApis,
    pageId: string,
    viewMode: PossibleViewModes,
    {convertToAbsolute}: DeepStructureOptions
): DeepStructure | undefined => {
    const {dal, extensionAPI} = dsApis
    const pageFilter = dal.queryFilterGetters.getPageCompFilter(pageId)
    const components = deepClone(dal.query(viewMode, pageFilter))
    if (!components[pageId]) {
        return undefined
    }

    if (convertToAbsolute) {
        const {meshLayout} = extensionAPI as MeshLayoutExtApi
        meshLayout.convertToStructureLayout(components, {isMobile: viewMode === VIEW_MODES.MOBILE})
    }

    return getDeepStructureForComp(pageId, components)
}

const getPageDeepStructure = (
    dsApis: DmApis,
    pageId: string,
    options: DeepStructureOptions = {}
): DeepPageStructure => {
    const desktop = getDeepStructureForPageComponent(dsApis, pageId, VIEW_MODES.DESKTOP, options)
    const mobile = getDeepStructureForPageComponent(dsApis, pageId, VIEW_MODES.MOBILE, options)
    const fullPage: DeepPageStructure = {...desktop!, mobileComponents: mobile?.components ?? []}
    if (pageId === 'masterPage') {
        fullPage.children = fullPage.components
        delete fullPage.components
    }
    return fullPage
}

const getPageStructure = (dsApis: DmApis, pageFilter: any, pageId: string, options: GetterOptions) =>
    options.isFull ? getPageDeepStructure(dsApis, pageId, options) : getPageFlatStructure(dsApis.dal, pageFilter)

const getPageDataType = (dal: DAL, pageFilter: any, type: string) => dal.query(type, pageFilter)

const getPageData = (dal: DAL, pageFilter: any) =>
    _.reduce(
        PAGE_DATA_TYPES,
        (res, val, key) => {
            res[val] = getPageDataType(dal, pageFilter, key)
            return res
        },
        {}
    )

const getPageTranslations = (dal: DAL, pageFilter: any, pageId: string) =>
    _.get(getTranslationsByPage(dal.query(MULTILINGUAL_TYPES.multilingualTranslations, pageFilter))[pageId], [
        'translations'
    ]) || {}

const pageGetter = (dsApis: DmApis, pointer: Pointer, options: GetterOptions) => {
    const {dal} = dsApis
    const [pageId, part] = pointer.id.split(PAGE_PART_SEPARATOR)
    const pageFilter = dal.queryFilterGetters.getPageCompFilter(pageId)

    if (part) {
        // optimization to only get part of the page
        if (part === PAGE_SCHEMA.translations) {
            return getPageTranslations(dal, pageFilter, pageId)
        }

        return getPageDataType(dal, pageFilter, part)
    }

    // Getting a full page
    const pageData = dal.get({id: pageId, type: DATA_TYPES.data})
    return {
        [PAGE_SCHEMA.pageUriSEO]: _.get(pageData, PAGE_SCHEMA.pageUriSEO),
        [PAGE_SCHEMA.title]: _.get(pageData, PAGE_SCHEMA.title),
        [PAGE_SCHEMA.data]: getPageData(dal, pageFilter),
        [PAGE_SCHEMA.structure]: getPageStructure(dsApis, pageFilter, pageId, options),
        [PAGE_SCHEMA.translations]: getPageTranslations(dal, pageFilter, pageId)
    }
}

const pageGetterFromFull = (dmApis: DmApis, pointer: Pointer, options: DeepStructureOptions = {}) =>
    pageGetter(dmApis, pointer, {...options, isFull: true})

const pageGetterFromDisplayed = (dmApis: DmApis, pointer: Pointer, options: DeepStructureOptions = {}) =>
    pageGetter(dmApis, pointer, {...options, isFull: false})

const getPagePartKey = (pageId: string, part: string) => `${pageId}${PAGE_PART_SEPARATOR}${part}`

export {
    getPageDeepStructure,
    getPagePartKey,
    pageGetterFromDisplayed,
    pageGetterFromFull,
    getDeepStructureForPageComponent
}
