import _ from 'lodash'
import classicsPathUtils from './classicsPathUtils'
import type {PS} from '@wix/document-services-types'

/**
 * @param {ps} ps Private Services
 * @param {string} packageName
 * @returns {object} the json describing the package.
 */
function getDescriptor(ps: PS, packageName: string): object {
    return ps.wixappsDAL.getByPath(classicsPathUtils.getPackageDescriptorPath(packageName))
}

function getPackageName(ps: PS, applicationId: number): string {
    const clientSpecMapPointer = ps.pointers.general.getClientSpecMap()
    const packageNamePointer = ps.pointers.getInnerPointer(clientSpecMapPointer, [applicationId as any, 'packageName'])
    return ps.dal.get(packageNamePointer)
}

function getAppPartRole(ps: PS, packageName: string, appPartName: string) {
    const part = getAppPartDefinition(ps, packageName, appPartName)
    return _.get(part, ['role'])
}

function getAppPartDefinition(ps: PS, packageName: string, appPartName: string) {
    const descriptor = getDescriptor(ps, packageName)
    const parts = _.get(descriptor, ['parts'])
    return _.find(parts, {id: appPartName})
}

export default {
    getPackageName,
    getAppPartRole,
    getAppPartDefinition
}
