import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import {coreUtils} from '@wix/santa-ds-libs'
const {urlUtils} = coreUtils

const PAGES_ROOT = 'pages'
const JS_SUFFIX = '.js'
const SOURCE_MAP_SUFFIX = `${JS_SUFFIX}.map`

function getViewMode(ps: PS) {
    return ps.dal.get({type: 'rendererModel', id: 'previewMode'}) ? 'preview' : 'site'
}

function getServiceTopology(ps: PS) {
    const serviceTopologyPointer = ps.pointers.serviceTopology.getServiceTopology()
    return ps.dal.get(serviceTopologyPointer)
}

function computeCacheKiller(ps: PS, bundleId: string) {
    const bundleCacheKiller = ps.extensionAPI.wixCodeFileSystem.getBundleCacheKiller(bundleId)
    const defaultCacheKiller = ps.extensionAPI.wixCodeFileSystem.getGlobalBundleCacheKiller()

    return bundleCacheKiller || defaultCacheKiller
}

function getProtocol() {
    return 'https:'
}

function getEditorUserCodeBaseUrl(ps: PS, wixCodeSpec) {
    const serviceTopology = getServiceTopology(ps)
    return urlUtils.joinURL(
        `${getProtocol()}//${wixCodeSpec.instanceId}.dev.${serviceTopology.wixCloudBaseDomain}`,
        PAGES_ROOT
    )
}

function isUserCodeUrl(ps: PS, url: string, wixCodeSpec) {
    const baseUrl = getEditorUserCodeBaseUrl(ps, wixCodeSpec)
    return _.startsWith(url, baseUrl)
}

function getModuleNameByUserCodeUrl(userCodeUrl: string) {
    const urlWithoutJsSuffix = userCodeUrl.substring(0, userCodeUrl.indexOf(JS_SUFFIX))
    return urlWithoutJsSuffix.substring(urlWithoutJsSuffix.lastIndexOf('/') + 1)
}

function getSourceMapUrl(ps: PS, userCodeUrl: string, gridAppId: string, wixCodeSpec): string {
    const moduleName = getModuleNameByUserCodeUrl(userCodeUrl)
    const baseUrl = `//${wixCodeSpec.instanceId}.dev.wix-code.com/preview`
    const sourceMapUrl = urlUtils.joinURL(baseUrl, PAGES_ROOT, moduleName + SOURCE_MAP_SUFFIX)
    const killer = computeCacheKiller(ps, moduleName)
    // eslint-disable-next-line lodash/prefer-invoke-map
    const q = _([
        ['use-globals-object', 'true'],
        ['module-name', moduleName],
        ['viewMode', getViewMode(ps)],
        ['instance', wixCodeSpec.instance],
        ['gridAppId', gridAppId],
        ['use_core_ng', 'false'],
        ['cacheKiller', killer]
    ])
        .map(x => x.join('='))
        .join('&')
    return `${sourceMapUrl}?${q}`
}

function getEditorUserCodeUrl(ps: PS, scriptName: string, moduleName: string, wixCodeModel, wixCodeSpec) {
    const baseUrl = getEditorUserCodeBaseUrl(ps, wixCodeSpec)
    const userCodeUrl = urlUtils.joinURL(baseUrl, scriptName)

    const queryParams = {
        viewMode: getViewMode(ps),
        instance: wixCodeSpec.instance,
        gridAppId: wixCodeModel.appData.codeAppId,
        cacheKiller: computeCacheKiller(ps, moduleName)
    }

    queryParams['use-globals-object'] = 'true'
    const queryParamsStr = _(queryParams)
        .mapValues((value, key) => `${key}=${value}`)
        .values()
        .sort()
        .join('&')

    // NOTE: This URL has to EXACTLY match the one generated in Bolt, so that code prefetching in preview will be effective
    return `${userCodeUrl}?${queryParamsStr}`
}

function getViewerUserCodeUrl(ps: PS, scriptName: string, wixCodeModel, wixCodeSpec) {
    const platformVersion = 'v2'
    const serviceTopology = getServiceTopology(ps)
    const baseUrl = `${getProtocol()}//${wixCodeSpec.instanceId}.static.pub.${serviceTopology.wixCloudBaseDomain}`

    return urlUtils.joinURL(
        baseUrl,
        'static',
        platformVersion,
        wixCodeModel.appData.codeAppId, // which is actually the gridAppId...
        wixCodeSpec.instanceId,
        PAGES_ROOT,
        scriptName
    )
}

function getUserCodeUrl(ps: PS, scriptName: string, moduleName: string, wixCodeModel, wixCodeSpec) {
    return getViewMode(ps) === 'site'
        ? getViewerUserCodeUrl(ps, scriptName, wixCodeModel, wixCodeSpec)
        : getEditorUserCodeUrl(ps, scriptName, moduleName, wixCodeModel, wixCodeSpec)
}
function filePathAffectsBundles(path: string) {
    return _.startsWith(path, 'public/') || _.startsWith(path, 'backend/')
}

function filePathAffectsSingleBundle(path: string) {
    return _.startsWith(path, 'public/pages/')
}

function bundleIdFromFilePath(path: string) {
    if (!filePathAffectsSingleBundle(path)) {
        throw new Error(`The path "${path}" does not belong to a single bundle`)
    }
    return path.split('public/pages/')[1].split('.')[0]
}

export default {
    filePathAffectsBundles,
    filePathAffectsSingleBundle,
    bundleIdFromFilePath,
    isUserCodeUrl,
    getSourceMapUrl,
    getUserCodeUrl
}
