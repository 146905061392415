import type {PublicMethodUtils} from '@wix/document-services-types'
import {layouters, utils} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    const {deprecate} = publicMethodUtils
    return {
        methods: {
            layouters: {
                getMasterChildren: publicMethodUtils.defineGetter(layouters.getMasterChildren),
                getNonMasterChildren: publicMethodUtils.defineGetter(layouters.getNonMasterChildren),
                isMasterChild: publicMethodUtils.defineGetter(layouters.isMasterChild),
                canBeMasterChild: publicMethodUtils.defineGetter(layouters.canBeMasterChild),
                getParentCompWithOverflowHidden: publicMethodUtils.defineGetter(
                    layouters.getParentCompWithOverflowHidden
                ),
                toggleMasterChild: deprecate(
                    publicMethodUtils.defineDataManipulationAction(layouters.toggleMasterChild, {
                        isUpdatingAnchors: utils.YES
                    }),
                    'This method is deprecated'
                )
            }
        }
    }
}
