import type {Pointer, PS} from '@wix/document-services-types'
const getAtomicScopePointer = (ps: PS, id: string) => ps.extensionAPI.atomicScopes.getAtomicScopePointer(id)
const addAtomicScope = (ps: PS, id: string) => ps.extensionAPI.atomicScopes.addAtomicScope(id)

const removeAtomicScope = (ps: PS, id: string) => ps.extensionAPI.atomicScopes.removeAtomicScope(id)

const changeAtomicScope = (ps: PS, oldId: string, newId: string) =>
    ps.extensionAPI.atomicScopes.getPageUriAtomicScopeId(oldId, newId)

const getPageUriAtomicScopeId = (ps: PS, pageId: string) => ps.extensionAPI.atomicScopes.getPageUriAtomicScopeId(pageId)

const getRouterAtomicScopeId = (ps: PS, routerPointer: Pointer) =>
    ps.extensionAPI.atomicScopes.getRouterAtomicScopeId(routerPointer)

export default {
    addAtomicScope,
    removeAtomicScope,
    changeAtomicScope,
    getAtomicScopePointer,
    getPageUriAtomicScopeId,
    getRouterAtomicScopeId
}
