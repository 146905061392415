/* eslint-disable promise/prefer-await-to-then */
import type {SnapshotDal} from '@wix/document-manager-core'
import {getReportableFromError} from '@wix/document-manager-utils'
import type {
    AppDefinitionId,
    AppsInstallStateMap,
    Callback,
    Callback1,
    ClientSpecMap,
    PS
} from '@wix/document-services-types'
import experiment from 'experiment-amd'
import _ from 'lodash'
import dsConstants from '../../constants/constants'
import editorServerFacade from '../../editorServerFacade/editorServerFacade'
import constants from '../../platform/common/constants'
import platform from '../../platform/platform'
import platformStateService from '../../platform/services/platformStateService'
import clientSpecMapMetaData from '../../siteMetadata/clientSpecMap'
import callbackUtils from '../../utils/callbackUtils'
import {contextAdapter} from '../../utils/contextAdapter'
import {getMetaSiteId} from '../../utils/dalUtil'
import * as visitableData from '../../utils/visitableData'
import provisionUtils from '../utils/provisionUtils'
import queue from '../utils/queue'
import clientSpecMapService from './clientSpecMapService'
import installedTpaAppsOnSiteService from './installedTpaAppsOnSiteService'
import pendingAppsService from './pendingAppsService'

const {getOnSuccessWithFallback} = callbackUtils
const {createQueue} = queue

export type AppsState =
    | AppsInstallStateMap
    | {[appDefinitionId: AppDefinitionId]: {pendingAction?: string; unused?: boolean}}

const context = {
    save: 'save',
    load: 'load',
    firstSave: 'firstSave'
} as const

const getSettleActionsForFullSave = (
    {currentPagesVisitable, clientSpecMap, routersConfigMap, semanticAppVersions, appsState, appsInstallState},
    shouldAvoidRevoking = false
) => {
    const appsInstallationState = experiment.isOpen('dm_removePendingActions') ? appsInstallState : appsState
    const filteredClientSpecMap = clientSpecMapService.filterApps(clientSpecMap)
    const actions = getSettleActionsFromAllPages(
        currentPagesVisitable,
        filteredClientSpecMap,
        routersConfigMap,
        semanticAppVersions,
        appsInstallationState,
        shouldAvoidRevoking
    )
    contextAdapter.utils.fedopsLogger.interactionStarted(
        dsConstants.PLATFORM_INTERACTIONS.SETTLE_ACTIONS,
        getInteractionsParams(actions)
    )
    return actions
}
const generateUniqueApplicationIdForAllApps = (apps, clientSpecMap) => {
    const uniqueAppIds = []
    _.times(apps.length, () => {
        let newApplicationId = getNewApplicationIdStartingFrom1k(clientSpecMap)
        while (_.includes(uniqueAppIds, newApplicationId)) {
            newApplicationId = getNewApplicationIdStartingFrom1k(clientSpecMap)
        }
        uniqueAppIds.push(newApplicationId)
    })
    return uniqueAppIds
}
const getProvisionAction = (
    clientSpecMap,
    appDefinitionId: AppDefinitionId,
    version: string,
    sourceTemplateId: string,
    applicationId: number
) => {
    const existingApp = _.find(clientSpecMap, {appDefinitionId})

    if (sourceTemplateId) {
        return {
            appDefId: appDefinitionId,
            appVersion: version,
            sourceTemplateId,
            type: 'provisionFromSourceTemplate'
        }
    }
    return {
        appDefId: appDefinitionId,
        applicationId: existingApp?.applicationId || applicationId || getNewApplicationIdStartingFrom1k(clientSpecMap),
        appVersion: version,
        type: 'provision'
    }
}

const getProvisionActions = (clientSpecMap: ClientSpecMap, apps: App[] /** {appDefinitionId, version(optional)} */) => {
    const uniqueAppIds = generateUniqueApplicationIdForAllApps(apps, clientSpecMap)
    return apps.map((app, index) =>
        getProvisionAction(clientSpecMap, app.appDefinitionId, app.version, app.sourceTemplateId, uniqueAppIds[index])
    )
}
const postSettleActions = (ps: PS, data) => {
    if (!_.isNil(ps)) {
        ps.extensionAPI.rendererModel.askRemoteEditorsToRefreshClientSpecMap()

        if (data?.actions) {
            _.forEach(data.actions, ({appDefId, type}) => {
                platformStateService.clearAppPendingAction(ps, appDefId)

                if (type === 'provision' || type === 'add') {
                    platformStateService.setAppAsUsed(ps, appDefId)
                }
            })
        }
    }
}

const makeSettleRequestWithPS = (
    ps: PS,
    urlData,
    data,
    saveContext: string,
    onSuccess: Callback1<any>,
    onError: Callback1<any>
) => {
    editorServerFacade.sendWithPs(
        ps,
        editorServerFacade.ENDPOINTS.SETTLE_AND_CONDUCT,
        {settle: data},
        getOnSuccessWithFallback('makeSettleRequestWithPS', onError, response => {
            postSettleActions(ps, data)
            const platformAppsExperiments = {}
            _.forEach(response.experimentsPerAppDefId, (val, key) =>
                _.set(platformAppsExperiments, key, val.appExperiments)
            )
            onSuccess({
                clientSpecMap: response.clientSpecMap,
                platformAppsExperiments,
                blocksExperiments: response.blocksExperiments?.appExperiments || {}
            })
        }),
        error => onError(error)
    )
}

const makeSettleRequestWithPSAsync = (ps: PS, urlData, data, saveContext: string) =>
    new Promise((resolve, reject) => {
        makeSettleRequestWithPS(ps, urlData, data, saveContext, resolve, reject)
    })

const settleQueue = createQueue(makeSettleRequestWithPSAsync)
const queueMakingSettleRequestWithPSAsync = settleQueue.run

const makeSettleRequestWithImmutableSnapshot = (
    immutableSnapshot: SnapshotDal,
    data,
    onSuccess: Callback1<any>,
    onError: Callback1<any>
) => {
    editorServerFacade.sendWithSnapshotDal(
        immutableSnapshot,
        editorServerFacade.ENDPOINTS.SETTLE,
        data,
        getOnSuccessWithFallback('makeSettleRequestWithImmutableSnapshot', onError, response => {
            onSuccess({...response, payload: {clientSpecMap: response.payload}})
        }),
        (error: any) => onError(error)
    )
}

export interface App {
    appDefinitionId: AppDefinitionId
    sourceTemplateId?: string
    version?: string
    appVersion?: string
}
const provision = (ps: PS, apps: App[], onSuccess: Function, onError: (e: any) => void) => {
    if (_.isEmpty(apps)) {
        onError('provision must be called with at least one app')
        return
    }

    const branchId = ps.extensionAPI.siteAPI.getBranchId()
    const clientSpecMap = clientSpecMapMetaData.getAppsData(ps)
    const actions = getProvisionActions(clientSpecMap, apps)
    const data = _.omitBy(
        {
            actions,
            siteRevision: ps.extensionAPI.siteAPI.getSiteRevision(),
            maybeBranchId: branchId
        },
        _.isNil
    )

    const urlData = {
        appStoreUrl: ps.dal.get(ps.pointers.getInnerPointer(ps.pointers.general.getServiceTopology(), 'appStoreUrl')),
        metaSiteId: getMetaSiteId(ps),
        editorSessionId: ps.siteAPI.getEditorSessionId()
    }

    queueMakingSettleRequestWithPSAsync(ps, urlData, data, context.firstSave)
        .then(
            getOnSuccessWithFallback('appStoreService provision', onError, response => {
                platform.addPlatformAppsExperiments(ps, response.platformAppsExperiments)
                platform.addBlocksExperiments(ps, response.blocksExperiments)
                onSuccess(response)
            })
        )
        .catch(onError)
}

const provisionAsync = (ps: PS, apps: App[]): Promise<any> =>
    new Promise<any>((resolve, reject) => {
        provision(ps, apps, resolve, reject)
    })

const update = async (ps: PS, apps: App[]) => {
    if (_.isEmpty(apps)) {
        throw new Error('update must be called with at least one app')
    }

    const branchId = ps.extensionAPI.siteAPI.getBranchId()
    const clientSpecMap = clientSpecMapMetaData.getAppsData(ps)

    _.forEach(apps, app => ps.extensionAPI.appsInstallState.updateAppVersion(app.appDefinitionId, app.appVersion))

    const data = _.omitBy(
        {
            actions: apps.map(app => {
                const appData = _.find(clientSpecMap, {appDefinitionId: app.appDefinitionId})

                return {
                    type: 'update',
                    appDefId: appData.appDefinitionId,
                    applicationId: appData.applicationId,
                    instanceId: appData.instanceId,
                    appVersion: app.appVersion
                }
            }),
            siteRevision: ps.extensionAPI.siteAPI.getSiteRevision(),
            maybeBranchId: branchId
        },
        _.isNil
    )

    const urlData = {
        appStoreUrl: ps.dal.get(ps.pointers.getInnerPointer(ps.pointers.general.getServiceTopology(), 'appStoreUrl')),
        metaSiteId: getMetaSiteId(ps),
        editorSessionId: ps.siteAPI.getEditorSessionId()
    }

    return queueMakingSettleRequestWithPSAsync(ps, urlData, data, context.firstSave).then(response => {
        return response.clientSpecMap
    })
}

const settleOnFirstSave = function (
    appServiceData,
    revision,
    urlData,
    onSuccess: Callback1<any>,
    onError: Callback1<any>
) {
    const {branchId} = appServiceData

    const data = _.omitBy(
        {
            actions: getSettleActionsForFullSave(appServiceData, false),
            siteRevision: revision,
            maybeBranchId: branchId
        },
        _.isNil
    )

    queueMakingSettleRequestWithPSAsync(null, urlData, data, context.firstSave).then(onSuccess, onError)
}

const settleOnFirstSaveWithImmutableSnapshot = function (
    currentImmutableSnapshot: SnapshotDal,
    appServiceData,
    revision: string,
    urlData,
    onSuccess: Callback1<any>,
    onError: Callback1<any>
) {
    const {branchId} = appServiceData

    const data = _.omitBy(
        {
            actions: getSettleActionsForFullSave(appServiceData, false),
            siteRevision: revision,
            maybeBranchId: branchId
        },
        _.isNil
    )

    makeSettleRequestWithImmutableSnapshot(currentImmutableSnapshot, data, onSuccess, onError)
}

const getInteractionsParams = actions => ({
    paramsOverrides: {actions: _.map(actions, _.partialRight(_.pick, ['appDefId', 'type']))}
})

const getSettleActionsForSave = (
    {
        clientSpecMap,
        isMasterPageUpdated,
        updatedPagesVisitable,
        currentPagesVisitable,
        lastPagesVisitable,
        deletedPagesVisitable,
        semanticAppVerions,
        routerConfigMap,
        appsState,
        appsInstallState
    },
    shouldAvoidRevoking?
) => {
    const appsInstallationState = experiment.isOpen('dm_removePendingActions') ? appsInstallState : appsState
    const filteredClientSpecMap = clientSpecMapService.filterApps(clientSpecMap)
    const isPlatformOnlyAppUninstalled = installedTpaAppsOnSiteService.isPlatformOnlyAppUninstalled(
        updatedPagesVisitable,
        clientSpecMap,
        routerConfigMap,
        appsInstallationState
    )
    const shouldTriggerFullSave = _.some(clientSpecMap, {shouldTriggerFullSave: true})
    const wereTpaCompsUninstalled = installedTpaAppsOnSiteService.areTpaCompsWereUnInstalled(
        lastPagesVisitable,
        updatedPagesVisitable,
        deletedPagesVisitable,
        clientSpecMap,
        isMasterPageUpdated,
        appsInstallationState
    )
    const shouldGetDataFromAllPages = wereTpaCompsUninstalled || shouldTriggerFullSave || isPlatformOnlyAppUninstalled
    const actions = shouldGetDataFromAllPages
        ? getSettleActionsFromAllPages(
              currentPagesVisitable,
              filteredClientSpecMap,
              routerConfigMap,
              semanticAppVerions,
              appsInstallationState,
              shouldAvoidRevoking
          )
        : getSettleActionsFromUpdatedPages(
              updatedPagesVisitable,
              filteredClientSpecMap,
              routerConfigMap,
              semanticAppVerions,
              appsInstallationState
          )
    contextAdapter.utils.fedopsLogger.interactionStarted(
        dsConstants.PLATFORM_INTERACTIONS.SETTLE_ACTIONS,
        getInteractionsParams(actions)
    )
    return actions
}

const settleOnSaveWithImmutableSnapshot = function (
    currentImmutableSnapshot: SnapshotDal,
    appServiceData,
    revision: string | number,
    urlData,
    shouldAvoidRevoking,
    onSuccess: Callback1<any>,
    onError: Callback1<any>
) {
    const {branchId} = appServiceData

    const data = _.omitBy(
        {
            actions: getSettleActionsForSave(appServiceData, shouldAvoidRevoking),
            siteRevision: revision,
            maybeBranchId: branchId
        },
        _.isNil
    )

    if (!_.isEmpty(data.actions)) {
        makeSettleRequestWithImmutableSnapshot(currentImmutableSnapshot, data, onSuccess, onError)
    } else {
        onSuccess(undefined)
    }
}

const getProvisionActionsForAllPages = (clientSpecMap: ClientSpecMap, semanticAppVersions = {}) => {
    return getProvisionActions(
        clientSpecMap,
        _.map(_.values(installedTpaAppsOnSiteService.getAppsToProvision(clientSpecMap)), ({appDefinitionId}) => ({
            appDefinitionId,
            version: semanticAppVersions[appDefinitionId]
        }))
    )
}

const getAppsToAddFromUpdatedPages = (
    appsToGrant,
    appIdsInstalledOnUpdatedPages,
    clientSpecMap: ClientSpecMap,
    semanticAppVersions = {},
    appsToProvision = []
) => {
    return _(appsToGrant)
        .filter(
            appData =>
                (_.includes(appIdsInstalledOnUpdatedPages, appData.appDefinitionId) ||
                    clientSpecMapService.isDemoAppAfterProvision(appData)) ??
                clientSpecMapService.hasEditorPlatformPart(appData)
        )
        .reject(({appDefinitionId}) => _.some(appsToProvision, {appDefId: appDefinitionId}))
        .map(({appDefinitionId, applicationId, instanceId, version, appFields}) => {
            const appVersion = appFields?.installedVersion || semanticAppVersions[appDefinitionId] || version
            return _.omitBy(
                {
                    type: 'add',
                    appDefId: appDefinitionId,
                    applicationId: _.toString(applicationId),
                    instanceId,
                    appVersion
                },
                _.isUndefined
            )
        })
        .value()
}

const getSettleActionsFromUpdatedPages = (
    updatedPagesVisitable,
    clientSpecMap: ClientSpecMap,
    routerConfigMap,
    semanticAppVerions,
    appsState
) => {
    const installedAppIds = installedTpaAppsOnSiteService.getAllAppIdsInstalledOnPages(
        updatedPagesVisitable,
        clientSpecMap,
        routerConfigMap
    )
    const appsToGrant = _.concat(
        _.filter(clientSpecMap, 'notProvisioned' as any),
        pendingAppsService.getAppsToAdd(),
        installedTpaAppsOnSiteService.getAppsToGrantPermissions(clientSpecMap, installedAppIds, appsState)
    )
    const appsToProvision = getProvisionActionsForAllPages(clientSpecMap, semanticAppVerions)
    const appsToAdd = getAppsToAddFromUpdatedPages(
        appsToGrant,
        installedAppIds,
        clientSpecMap,
        semanticAppVerions,
        appsToProvision
    )
    const appsToDismiss = getAppsToDismiss()
    const appsToUpdate = getAppsToUpdate(clientSpecMap, appsToGrant, null, appsState)

    return _.concat(appsToAdd, appsToProvision, appsToDismiss, appsToUpdate)
}

const settleOnLoad = function (ps: PS, shouldAvoidRevoking?: boolean, callback?: Callback) {
    const onSuccess = getOnSuccessWithFallback('settleOnLoad', callback, function (response) {
        if (response) {
            platform.addPlatformAppsExperiments(ps, response.platformAppsExperiments)
            platform.addBlocksExperiments(ps, response.blocksExperiments)
            _.forEach(response.clientSpecMap, appData => clientSpecMapService.registerAppData(ps, appData))
        }
        if (response?.success) {
            _.forEach(response.payload.clientSpecMap, appData => clientSpecMapService.registerAppData(ps, appData))
        }
        if (callback) {
            callback()
        }
    })

    const visitableDataAllPages = visitableData.createFromPrivateServices(ps)
    const clientSpecMap = clientSpecMapService.filterApps(ps.dal.get(ps.pointers.general.getClientSpecMap()))
    const routerConfigMap = ps.dal.get(ps.pointers.routers.getRoutersConfigMapPointer())
    const siteRevision = ps.extensionAPI.siteAPI.getSiteRevision()
    const urlData = {
        appStoreUrl: ps.dal.get(ps.pointers.getInnerPointer(ps.pointers.general.getServiceTopology(), 'appStoreUrl')),
        metaSiteId: getMetaSiteId(ps),
        editorSessionId: ps.siteAPI.getEditorSessionId()
    }
    settleImplOnLoad(
        ps,
        visitableDataAllPages,
        clientSpecMap,
        routerConfigMap,
        siteRevision,
        urlData,
        shouldAvoidRevoking,
        onSuccess
    )
}

const getAppsToUpdate = (
    clientSpecMap: ClientSpecMap,
    appsToGrant: any[] = [],
    appsToRevoke: any[] = [],
    appsState: Record<string, any> = {}
) =>
    _(clientSpecMap)
        .filter(({appDefinitionId}: any) => {
            const appState: any = _.get(appsState, appDefinitionId, {})
            return (
                appState.pendingAction === constants.APP_ACTION_TYPES.UPDATE &&
                !_.some(appsToGrant, {appDefinitionId}) &&
                !_.some(appsToRevoke, {appDefinitionId})
            )
        })
        .map((clientSpec: any) => ({
            type: 'update',
            appDefId: clientSpec.appDefinitionId,
            applicationId: _.toString(clientSpec.applicationId),
            instanceId: clientSpec.instanceId,
            appVersion: clientSpec.version
        }))
        .value()

const getAppsToAddFromAllPages = (
    appsToGrant,
    appsToRevoke,
    clientSpecMap: ClientSpecMap,
    semanticAppVersions = {},
    appsToProvision = []
) =>
    _(appsToGrant)
        .reject(
            ({appDefinitionId}) =>
                _.some(appsToRevoke, {appDefinitionId}) || _.some(appsToProvision, {appDefId: appDefinitionId})
        )
        .map(({appDefinitionId, applicationId, instanceId, version, appFields}) => {
            const appVersion = appFields?.installedVersion || semanticAppVersions[appDefinitionId] || version
            return _.omitBy(
                {
                    type: 'add',
                    appDefId: appDefinitionId,
                    applicationId: _.toString(applicationId),
                    instanceId,
                    appVersion
                },
                _.isUndefined
            )
        })
        .value()

const getAppsToRemove = appsToRevoke =>
    _.map(appsToRevoke, ({applicationId}) => ({
        type: 'remove',
        applicationId: _.toString(applicationId)
    }))

const getAppsToDismiss = () =>
    _.map(pendingAppsService.getAppsToDismiss(), appDefId => ({
        type: 'dismiss',
        appDefId
    }))

const settleImplOnLoad = (
    ps: PS,
    visitableDataAllPages,
    clientSpecMap: ClientSpecMap,
    routerConfigMap,
    siteRevision: string,
    urlData,
    shouldAvoidRevoking,
    callback
) => {
    const appsInstalledOnSite: AppDefinitionId[] = installedTpaAppsOnSiteService.getAllAppIdsInstalledOnPages(
        visitableDataAllPages,
        clientSpecMap,
        routerConfigMap
    )
    const branchId = ps.extensionAPI.siteAPI.getBranchId()
    const {grant: appsToGrant, revoke: appsToRevoke} =
        installedTpaAppsOnSiteService.getAppsToGrantAndRevokedFromInstalledApps(
            clientSpecMap,
            visitableDataAllPages,
            appsInstalledOnSite,
            {excludeHybrid: true, shouldAvoidRevoking},
            {},
            // @ts-expect-error
            experiment.isOpen('dm_removePendingActions')
                ? ps.extensionAPI.appsInstallState.getAllAppsInstallStatus()
                : platformStateService.getAppsState(ps)
        )

    const appsToAdd = getAppsToAddFromAllPages(appsToGrant, appsToRevoke, clientSpecMap)
    const appsToRemove = getAppsToRemove(appsToRevoke)
    const appsToDismiss = getAppsToDismiss()

    const appsToUpdate = getAppsToUpdate(clientSpecMap, appsToGrant, appsToRevoke, {})

    const data = _.omitBy(
        {
            actions: _.concat(appsToAdd, appsToRemove, appsToDismiss, appsToUpdate),
            siteRevision,
            maybeBranchId: branchId
        },
        _.isNil
    )

    if (!_.isEmpty(data.actions)) {
        ps.extensionAPI.appsInstallState.reportStateDifferenceByActions(clientSpecMap, data.actions, 'settleOnLoad')
        queueMakingSettleRequestWithPSAsync(ps, urlData, data, context.load)
            .then(callback)
            .catch(e => {
                ps.extensionAPI.logger.captureError(
                    getReportableFromError(e, {
                        errorType: 'settleImplOnLoadError',
                        message: `Failed settleImplOnLoad`,
                        tags: {
                            provisioningOnSuccess: true
                        }
                    })
                )

                console.error(e)
                callback()
            })
    } else {
        callback()
    }
}

const getSettleActionsFromAllPages = (
    currentPagesVisitable,
    clientSpecMap: ClientSpecMap,
    routerConfigMap,
    semanticAppVersions,
    appsState: AppsState,
    shouldAvoidRevoking
) => {
    const appsToGrantAndRevoke = installedTpaAppsOnSiteService.getAppsToGrantAndRevoke(
        clientSpecMap,
        currentPagesVisitable,
        {shouldAvoidRevoking},
        routerConfigMap,
        appsState
    )
    const appsToGrant = _.concat(
        _.filter(clientSpecMap, 'notProvisioned' as any),
        pendingAppsService.getAppsToAdd(),
        appsToGrantAndRevoke.grant
    )
    const appsToRevoke = appsToGrantAndRevoke.revoke

    const appsToProvision = getProvisionActionsForAllPages(clientSpecMap, semanticAppVersions)
    const appsToAdd = getAppsToAddFromAllPages(
        appsToGrant,
        appsToRevoke,
        clientSpecMap,
        semanticAppVersions,
        appsToProvision
    )
    const appsToRemove = getAppsToRemove(appsToRevoke)
    const appsToDismiss = getAppsToDismiss()
    const appsToUpdate = getAppsToUpdate(clientSpecMap, appsToGrant, appsToRevoke, appsState)

    return _.concat(appsToAdd, appsToProvision, appsToRemove, appsToDismiss, appsToUpdate)
}

const getNewApplicationIdStartingFrom1k = function (clientSpecMap: ClientSpecMap): number {
    const currentLargestId = clientSpecMapService.getLargestApplicationId(clientSpecMap)
    const newGeneratedApplicationId = provisionUtils.generateAppFlowsLargestAppId(currentLargestId)
    return newGeneratedApplicationId
}

const settleOnLoadAsync = (ps: PS, shouldAvoidRevoking?: boolean) =>
    new Promise<void>(res => settleOnLoad(ps, shouldAvoidRevoking, res))

export default {
    provision,
    provisionAsync,
    update,
    settleOnSaveWithImmutableSnapshot,
    settleOnFirstSave,
    settleOnFirstSaveWithImmutableSnapshot,
    getSettleActionsForFullSave,
    getSettleActionsForSave,
    settleOnLoad,
    settleOnLoadAsync,
    waitForSettleQueue: settleQueue.wait
}
