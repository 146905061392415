import type {DAL} from '@wix/document-manager-core'
import {Transaction, ActionOperation} from '@wix/document-manager-extensions'

export class Httm {
    private currentTransactionIndex: number
    constructor(private dal: DAL, private transactions: Transaction[]) {
        this.currentTransactionIndex = -1
    }

    showStatus(): void {
        const {length} = this.transactions
        if (this.currentTransactionIndex < 0) {
            console.log(`Not started. ${length}/${length} remaining`)
        } else if (this.currentTransactionIndex >= length) {
            console.log('Done.')
        } else {
            console.log(`Just applied transaction ${this.currentTransactionIndex + 1} of ${length}`)
        }
    }

    advance(): void {
        this._advance()
        this.showStatus()
    }

    _advance(): void {
        this.currentTransactionIndex++
        if (this.currentTransactionIndex >= this.transactions.length) {
            return
        }
        for (const action of this.transactions[this.currentTransactionIndex]!.actions!) {
            const ptr = {id: action.id!, type: action.namespace!}
            if (action.op === ActionOperation.REMOVE) {
                this.dal.remove(ptr)
            } else {
                this.dal.set(ptr, action.value)
            }
        }
        this.dal.commitTransaction('httm')
    }
}
