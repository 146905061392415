import type {BehaviorDef, Point, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import actionsAndBehaviors from '../../../actionsAndBehaviors/actionsAndBehaviors'
import component from '../../../component/component'
import constants from '../../../constants/constants'
import mobileConversionFacade from '../../mobileConversionFacade'
import conversionSettingsBuilder from '../conversionSettings'
import mergeAggregator from '../mergeAggregator'
import mobileHints from '../mobileHints'
import structuresComparer from '../structuresComparer'
import expandableMenuDefaultPreset from './expandableMenuDefaultPreset'
import menuContainerDefaultPreset from './menuContainerDefaultPreset'
import menuContainerToggleDefaultPreset from './menuContainerToggleDefaultPreset'

const get = (ps: PS) => {
    const masterPagePointer = ps.pointers.components.getMasterPage(constants.VIEW_MODES.MOBILE)
    const menuContainerPointer = ps.pointers.components.getComponent(
        constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER,
        masterPagePointer
    )
    return ps.dal.isExist(menuContainerPointer) ? menuContainerPointer : null
}

const getToggle = (ps: PS) => {
    const masterPagePointer = ps.pointers.components.getMasterPage(constants.VIEW_MODES.MOBILE)
    const menuContainerTogglePointer = ps.pointers.components.getComponent(
        constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER_TOGGLE,
        masterPagePointer
    )
    return ps.dal.isExist(menuContainerTogglePointer) ? menuContainerTogglePointer : null
}

const isMenuContainerExist = (ps: PS) => {
    const masterPagePointer = ps.pointers.components.getMasterPage(constants.VIEW_MODES.MOBILE)
    const menuContainerPointer = ps.pointers.components.getComponent(
        constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER,
        masterPagePointer
    )
    return ps.dal.isExist(menuContainerPointer)
}

const isMenuToggleExist = (ps: PS) => {
    const masterPagePointer = ps.pointers.components.getMasterPage(constants.VIEW_MODES.MOBILE)
    const menuContainerPointer = ps.pointers.components.getComponent(
        constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER_TOGGLE,
        masterPagePointer
    )
    return ps.dal.isExist(menuContainerPointer)
}

const isExpandableMenuExist = (ps: PS) => {
    const masterPagePointer = ps.pointers.components.getMasterPage(constants.VIEW_MODES.MOBILE)
    const expandableMenuPointer = ps.pointers.components.getComponent(
        constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER_EXPANDABLE_MENU,
        masterPagePointer
    )
    return ps.dal.isExist(expandableMenuPointer)
}

const addMenuContainerToggle = (ps: PS, layoutOverrides?: Point) => {
    if (!isMenuContainerExist(ps)) {
        throw new Error('cannot add menu container toggle without menu instance')
    }

    if (isMenuToggleExist(ps)) {
        return
    }

    const headerPointer = ps.pointers.components.getHeader(constants.VIEW_MODES.MOBILE)
    const compToAddRef = component.getComponentToAddRef(
        ps,
        headerPointer,
        null,
        constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER_TOGGLE
    )
    _.assign(menuContainerToggleDefaultPreset.layout, layoutOverrides)
    component.add(ps, compToAddRef, headerPointer, menuContainerToggleDefaultPreset)

    addToggleVisibilityBehaviorToToggle(ps, get(ps), compToAddRef)
}

const addToggleVisibilityBehaviorToToggle = (
    ps: PS,
    menuContainerPointer: Pointer,
    menuContainerTogglePointer: Pointer
) =>
    actionsAndBehaviors.updateBehavior(
        ps,
        menuContainerTogglePointer,
        {name: 'click', type: 'comp'},
        menuContainerPointer,
        {
            name: 'toggle',
            type: 'comp'
        } as BehaviorDef
    )

const handleHiddenComponents = (ps: PS) => {
    const masterPageDesktopPointer = ps.pointers.components.getMasterPage(constants.VIEW_MODES.DESKTOP)
    const componentsToUnhide = _(
        structuresComparer.getHiddenAndShownComponents(ps, constants.MASTER_PAGE_ID).hiddenComponents
    )
        .map(compId => ps.pointers.components.getComponent(compId, masterPageDesktopPointer))
        .filter(compPointer => {
            const componentTypePointer = ps.pointers.getInnerPointer(compPointer, 'componentType')
            if (!ps.dal.isExist(componentTypePointer)) {
                return false
            }

            const compType = ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'componentType'))
            return _.includes(
                ['wysiwyg.viewer.components.LoginSocialBar', 'wysiwyg.viewer.components.LanguageSelector'],
                compType
            )
        })
        .value()

    componentsToUnhide.forEach(compPointer => mobileHints.updateProperty(ps, {hidden: false}, compPointer))

    const needToRunMobileConversion = componentsToUnhide.length > 0 && ps.siteAPI.isMobileView()
    if (needToRunMobileConversion) {
        const {desktop, mobile} = mergeAggregator.getPage(ps, constants.MASTER_PAGE_ID)
        const settings = conversionSettingsBuilder.getConversionSettings(ps, {
            conversionType: 'MERGE_UNHIDE'
        })
        mobileConversionFacade.runMobileMergeOnPage(ps, desktop, mobile, settings)
    }
}

const removeTinyMenuIfNeeded = (ps: PS) => {
    const headerPointer = ps.pointers.components.getHeader(constants.VIEW_MODES.MOBILE)
    const tinyMenuPointer = ps.pointers.components.getComponent(
        constants.MOBILE_ONLY_COMPONENTS.TINY_MENU,
        headerPointer
    )
    const tinyMenuLayoutPointer = ps.pointers.getInnerPointer(tinyMenuPointer, 'layout')
    const tinyMenuLayout = ps.dal.get(tinyMenuLayoutPointer)

    if (ps.dal.isExist(tinyMenuPointer)) {
        component.deleteComponent(ps, tinyMenuPointer)
        return _.pick(tinyMenuLayout, ['x', 'y'])
    }
}

const addExpandableMenu = (ps: PS) => {
    if (!isMenuContainerExist(ps)) {
        throw new Error('cannot add menu container toggle without menu instance')
    }

    if (isExpandableMenuExist(ps)) {
        return
    }

    const menuContainerRef = get(ps)
    const expandableMenuRef = component.getComponentToAddRef(
        ps,
        menuContainerRef,
        null,
        constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER_EXPANDABLE_MENU
    )
    component.add(ps, expandableMenuRef, menuContainerRef, expandableMenuDefaultPreset)
}

const addMenuContainerIfNeeded = (ps: PS, tinyMenuPosition: Point) => {
    const masterPagePointer = ps.pointers.components.getMasterPage(constants.VIEW_MODES.MOBILE)
    const mobileHeaderPointer = ps.pointers.components.getComponent(constants.COMP_IDS.HEADER, masterPagePointer)
    const menuContainerPointer = ps.pointers.components.getComponent(
        constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER,
        mobileHeaderPointer
    )

    if (ps.dal.isExist(menuContainerPointer)) {
        return
    }

    const menuContainerRef = component.getComponentToAddRef(
        ps,
        mobileHeaderPointer,
        null,
        constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER
    )
    component.add(ps, menuContainerRef, mobileHeaderPointer, menuContainerDefaultPreset)

    addExpandableMenu(ps)
    addMenuContainerToggle(ps, tinyMenuPosition)
    handleHiddenComponents(ps)
}

const upgrade = (ps: PS) => {
    const tinyMenuPosition = removeTinyMenuIfNeeded(ps)
    addMenuContainerIfNeeded(ps, tinyMenuPosition)
}

export default {
    get,
    isExist: isMenuContainerExist,
    upgrade,
    toggle: {
        get: getToggle,
        isExist: isMenuToggleExist,
        add: addMenuContainerToggle
    },
    expandableMenu: {
        isExist: isExpandableMenuExist,
        add: addExpandableMenu
    }
}
