import _ from 'lodash'
import type {ViewerAPI} from '@wix/viewer-manager-interface'
import {isSameViewMode} from './structureUtils'

const getRenderFlags = (getRenderFlagsVM: Function) => getRenderFlagsVM()

const getRealTimeConfig = (viewer: ViewerAPI, id: string) => {
    if (_.startsWith(id, 'compsToHide')) {
        const idSplit = id.split('_')
        const compId = idSplit.slice(2).join('_')
        const viewMode = idSplit[1]
        return viewer.realTimeConfig.getIsCompHidden(compId, viewMode)
    }
    switch (id) {
        case 'compsToShowOnTop':
            return viewer.realTimeConfig.getCompsToShowOnTop()
        case 'compsToShowWithOpacity':
            return viewer.realTimeConfig.getCompsToShowWithOpacity()
        case 'hideTextComponent':
            return viewer.realTimeConfig.getHiddenTextComponent()
        default:
            console.warn('id not supported', id)
    }
}

const getRuntime = (viewer: ViewerAPI, id: string) => {
    switch (id) {
        case 'wantedNavInfo':
            return viewer.runtime.getWantedNavInfo()
        case 'wantedIsMobileView':
            return viewer.runtime.getWantedIsMobileView()
        default:
            console.warn('id not supported', id)
    }
}

const getActiveModes = (viewer: ViewerAPI) => viewer.modes.getAllActiveModes()

const getStructure = (viewer: ViewerAPI, id: string, type?: string) => {
    if (!isSameViewMode(viewer, type)) {
        return undefined
    }

    return viewer.structure.getStructure(id)
}

const getCacheKillerCounter = (viewer: ViewerAPI, id: string, innerPath?: string[]) =>
    viewer.customElements.getCacheKillerCounter(innerPath![0])

const getFromRendererModel = (viewer: ViewerAPI, id: string) => {
    switch (id) {
        case 'clientSpecMap':
            return viewer.rendererModel.getClientSpecMap()
        case 'wixCodeModel':
            return viewer.rendererModel.getWixCodeModel()
        case 'pagesPlatformApplications':
            return viewer.rendererModel.getPagesPlatformApplications()
        case 'passwordProtectedPages':
            return viewer.rendererModel.getPasswordProtectedPages()
        case 'sitePropertiesInfo':
            return viewer.rendererModel.getSitePropertiesInfo()
        case 'metaSiteId':
            return viewer.rendererModel.getMetaSiteId()
        case 'premiumFeatures':
            return viewer.rendererModel.getPremiumFeatures()
        case 'routers':
            return viewer.rendererModel.getRouters()
        case 'siteMetaData':
            return viewer.rendererModel.getSiteMetaData()
        case 'siteInfo':
            return viewer.rendererModel.getSiteInfo()
        default:
            console.warn('id not supported', id)
    }
}

const getFromDocumentServicesModel = (viewer: ViewerAPI, id: string) => {
    switch (id) {
        case 'version':
            return viewer.documentServicesModel.getVersion()
        case 'siteName':
            return viewer.documentServicesModel.getSiteName()
        case 'revision':
            return viewer.documentServicesModel.getRevision()
        case 'publicUrl':
            return viewer.documentServicesModel.getPublicUrl()
        case 'isPublished':
            return viewer.documentServicesModel.getIsPublished()
        case 'customHeadTags':
            return viewer.documentServicesModel.getCustomHeadTags()
        default:
            console.warn('id not supported', id)
    }
}

export {
    getRenderFlags,
    getRealTimeConfig,
    getRuntime,
    getActiveModes,
    getStructure,
    getCacheKillerCounter,
    getFromRendererModel,
    getFromDocumentServicesModel
}
