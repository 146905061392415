import type {ValueOf} from './basicTypes'
import type {PS} from './ps'

export const ENFORCE_TYPES = {
    NONE: 'NONE',
    INNER: 'INNER',
    FULL: 'FULL',
    DONT_CARE: 'DONT_CARE',
    GENERATE_AND_ENFORCE: 'GENERATE_AND_ENFORCE'
} as const

export type EnforceType = ValueOf<typeof ENFORCE_TYPES>

export interface SetOpParams {
    enforceType?: EnforceType
    asyncPreDataManipulation?: Function | null
    isAsyncOperation?: boolean | ((a: any, b: any) => boolean)
    methodName?: string
    noBatchingAfter?: boolean
    noBatching?: boolean
    currentContext?: any
    waitingForTransition?: boolean
    transaction?: true
    isUpdatingData?: boolean
    forceAnchorsGeneration?: boolean
}

export type CurrentContext = any
export type GetCurrentContext = () => CurrentContext

export interface SetOperationsQueue {
    asyncPreDataManipulationComplete<T>(data?: T | null | undefined, error?: Error | null | undefined): void
    executeAfterCurrentOperationDone(cb: Callback): void
    flushQueueAndExecute(cb: Callback): void
    registerToErrorThrownForContext(appDefId: string, cb: Callback): void
    runInContext(ps: PS, context: any, dataManipulationWrapper: Function): void
    runSetOperation(setOperation: Function, args?: any[], operationParams?: SetOpParams): number
    unRegisterToErrorThrownForContext(appDefId: string, cb: Callback): void
    waitForChangesApplied(cb: Callback): void
    withWaitForChangesDisabled<T>(action: () => Promise<T>): Promise<T>
    withCommitsAndSavesDisabled<T>(action: () => Promise<T>): Promise<T>
    noRenders<T>(action: () => Promise<T>): Promise<T>
    registerToWaitForChangesAppliedInTransaction(promise: Promise<void>): void
    executeOperationCallbacksInTransaction(): void

    // adapter SOQ
    dispose(): void
    isRunningSetOperation(): boolean
    registerToErrorThrown(callback: SoqErrorCallback): void
    registerToSetOperationDone(handle: number, callback: Callback1<Error | undefined>): void
    registerToSiteChanged(callback: Callback1<any>): void
    registerToNextSiteChanged(callback: Callback1<any>): void
    runImmediateSetOperation(setOperation: Function, ...args: any[]): any
    triggerSiteUpdated(): void
    unRegisterAll(): void
    unRegisterFromErrorThrown(callback: SoqErrorCallback): void
    getCurrentContext(): GetCurrentContext
    getCallsContext(): string[]
    addCallContext(interaction: string): void
    removeCallContext(interaction: string): void
    getCurrentBatchSummary(): object
    useAnimationFrameToDefer(): void
    useSimpleTimeoutToDefer(timeToWait: number): void
    waitForChangesAppliedAsync(): Promise<void>
}
export interface SetOperationsQueuePublic
    extends Pick<
        SetOperationsQueue,
        | 'registerToErrorThrown'
        | 'registerToErrorThrownForContext'
        | 'unRegisterToErrorThrownForContext'
        | 'unRegisterFromErrorThrown'
        | 'registerToSiteChanged'
        | 'runInContext'
        | 'waitForChangesAppliedAsync'
        | 'addCallContext'
        | 'removeCallContext'
    > {
    waitForChangesApplied(callback: Callback, onlyChangesAlreadyRegistered: boolean): void
    forSiteChanged(onlyChangesAlreadyRegistered: boolean): Promise<void>
    useSimpleTimeoutToDeferQueue(timeToWait: number): void
    useAnimationFrameToDeferQueue(): void
}

export type Callback = () => void
export type Callback1<T> = (a: T) => void
export type Callback2<A, B> = (a: A, b: B) => void
export type Callback3<A, B, C> = (a: A, b: B, c: C) => void
export type Callback4<A, B, C, D> = (a: A, b: B, c: C, d: D) => void
export type Callback5<A, B, C, D, E> = (a: A, b: B, c: C, d: D, e: E) => void

export interface SoqErrorDetails {
    readonly error: Error
    readonly handle: number
    readonly methodName: string | undefined
    readonly appDefinitionId: string | undefined
}

export type SoqErrorCallback = (details: SoqErrorDetails) => void
