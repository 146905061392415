const STYLE_TYPE = 'custom'
const STYLE_ITEM_TYPE = 'ComponentStyle'

/**
 * @param {object} styles
 * @param {object} styleItem
 * @param {string} styleItem.id
 * @param {string} styleItem.skin
 * @param {string} styleItem.componentClassName
 * @param {object} styleItem.style
 * @param {string} [styleItem.styleType]
 * @param {string} [styleItem.type]
 */
function updateStyleItem(
    styles,
    {
        id,
        skin,
        componentClassName,
        style: {properties = {}, propertiesSource = {}, propertiesOverride = {}, groups = {}},
        styleType,
        type
    }
) {
    styles[id] = {
        componentClassName,
        skin,
        id,
        styleType: styleType || STYLE_TYPE,
        type: type || STYLE_ITEM_TYPE,
        style: {
            properties,
            propertiesSource,
            propertiesOverride,
            groups
        }
    }
}

module.exports = updateStyleItem
