import {pointerUtils} from '@wix/document-manager-core'
const pointerType = 'displayedOnlyComponents'

const {getPointer} = pointerUtils

const createPointersMethods = () => {
    const getComponentTemplateId = (compId: string) => getPointer(compId, pointerType)

    return {
        displayedOnlyComponents: {
            getComponentTemplateId
        }
    }
}

export {createPointersMethods}
