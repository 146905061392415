import coreUtilsLib from '@wix/santa-core-utils'
import _ from 'lodash'

const sanitizeWhatsAppPhoneNumber = (str = '') => str.replace(new RegExp('[+| |-]', 'g'), '')
function getEmailLink(linkData) {
    let emailLink = `mailto:${linkData.recipient?.trim() || ''}`
    const linkParamsArr = []

    if (linkData.subject) {
        linkParamsArr.push(`subject=${linkData.subject}`)
    }

    if (linkData.body) {
        linkParamsArr.push(`body=${linkData.body}`)
    }

    if (linkParamsArr.length > 0) {
        emailLink += `?${linkParamsArr.join('&')}`
    }
    return emailLink
}

function getDocumentLink(linkData, siteData) {
    const {docId} = linkData
    const isPDF = /\.pdf$/i.test(docId)

    let href = buildDocumentLink(linkData, siteData)

    // ?dn=document+name.ext -- will cause link to download the file with the specified name
    // for pdf, we want it to open in browser and not download. The browser attaches a download button to it anyway.
    // for non pdf, we want the link to download the file for the user
    if (isPDF) {
        if (linkData.indexable === true) {
            href += '?index=true'
        }
    } else {
        href += `?${coreUtilsLib.urlUtils.toQueryString({dn: linkData.name})}`
    }
    return href
}

function buildDocumentLink(linkData, siteData) {
    const userFilesBaseDomain = siteData.serviceTopology.userFileDomainUrl
    const metaSiteId = siteData.getMetaSiteId()
    let {docId} = linkData
    const isDocIdWithoutFolderPrefix = !docId.includes('/')
    const isDocIdStartWithSlash = _.startsWith(docId, '/')
    // Backward compatibility for old url format when the folder name was part of serviceTopology.staticDocsUrl
    // and not part of the docId per file
    if (isDocIdWithoutFolderPrefix) {
        docId = `ugd/${docId}`
    }

    // BOLT-1912 very old (pre-2013) files were imported in 2013 with excessive slash
    if (isDocIdStartWithSlash) {
        return `https://${metaSiteId}.${userFilesBaseDomain}${docId}`
    }
    return `https://${metaSiteId}.${userFilesBaseDomain}/${docId}`
}

function getPhoneLink(linkData) {
    return `tel:${linkData.phoneNumber}`
}

function getWhatsAppLink(linkData) {
    const phoneNumber = sanitizeWhatsAppPhoneNumber(linkData.phoneNumber)
    const WHATSAPP_API_URL = 'https://api.whatsapp.com/send?phone='
    return `${WHATSAPP_API_URL}${phoneNumber}`
}
function navigateToPage(
    siteAPI,
    pageId: string,
    allPageIds,
    noPageTransition,
    anchorDataId: string,
    onNavigationComplete,
    onError
) {
    if (_.includes(allPageIds, pageId)) {
        siteAPI.registerNavigationComplete(onNavigationComplete)
        const navInfo: Record<string, any> = {pageId}
        if (noPageTransition) {
            navInfo.transition = 'none'
        }
        if (anchorDataId) {
            navInfo.anchorData = anchorDataId
        }
        siteAPI.navigateToPage(navInfo)
    } else if (onError) {
        onError({
            error: {
                message: `Page id "${pageId}" was not found.`
            }
        })
    }
}
export default {
    getEmailLink,
    getDocumentLink,
    getPhoneLink,
    getWhatsAppLink,
    navigateToPage
}
