'use strict'

const _ = require('lodash')

function isStripContainer(component) {
    return component.componentType === 'wysiwyg.viewer.components.StripContainer'
}

function hasDesignQuery(component) {
    return !_.isUndefined(component.designQuery)
}

function createMediaContainerDesignDataItem(comp, designData, uniqueIdGenerator) {
    const designId = uniqueIdGenerator.getUniqueId('dataItem', '-')
    designData[designId] = {
        id: designId,
        type: 'MediaContainerDesignData',
        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false}
    }
    comp.designQuery = `#${designId}`
    return designId
}

function moveDataItem(source, target, itemId) {
    target[itemId] = source[itemId]
    delete source[itemId]
    return target[itemId]
}

function moveBackgroundData(sourceId, targetId, source, target) {
    const componentData = source[sourceId]
    if (!_.isUndefined(componentData.background)) {
        const backgroundMediaId = componentData.background.slice(1)
        const backgroundMedia = moveDataItem(source, target, backgroundMediaId)

        if (!_.isUndefined(backgroundMedia.mediaRef)) {
            const innerMediaId = backgroundMedia.mediaRef.slice(1)
            const innerMedia = moveDataItem(source, target, innerMediaId)
            if (innerMedia.type === 'WixVideo') {
                const posterImageId = innerMedia.posterImageRef.slice(1)
                moveDataItem(source, target, posterImageId)
            }
            if (!_.isUndefined(innerMedia.originalImageDataRef)) {
                const orgImage = innerMedia.originalImageDataRef.slice(1)
                moveDataItem(source, target, orgImage)
            }
            if (!_.isUndefined(innerMedia.link)) {
                delete innerMedia.link
            }
        }
        if (!_.isUndefined(backgroundMedia.imageOverlay)) {
            const imageOverlayId = backgroundMedia.imageOverlay.slice(1)
            moveDataItem(source, target, imageOverlayId)
        }

        delete componentData.background
        target[targetId].background = `#${backgroundMediaId}`
        return backgroundMediaId
    }
}

function migrateToDesignData(
    documentData,
    designData,
    dataIdToDesignId,
    dataIdToBgMediaId,
    stripContainer,
    uniqueIdGenerator
) {
    const dataId = stripContainer.dataQuery.slice(1)
    const designId = createMediaContainerDesignDataItem(stripContainer, designData, uniqueIdGenerator)
    dataIdToDesignId[dataId] = designId
    const bgMediaId = moveBackgroundData(dataId, designId, documentData, designData)
    if (!_.isUndefined(bgMediaId)) {
        dataIdToBgMediaId[dataId] = bgMediaId
    }
}

function migrateToDesignDataMobile(designData, dataIdToDesignId, dataIdToBgMediaId, stripContainer) {
    const dataId = stripContainer.dataQuery.slice(1)
    stripContainer.designQuery = `#${dataIdToDesignId[dataId]}`
}

function fixStripContainers(components, mobileComponents, documentData, designData, uniqueIdGenerator) {
    const dataIdToBgMediaId = {}
    const dataIdToDesignId = {}
    const stripContainers = _.filter(components, isStripContainer)

    _(stripContainers).reject(hasDesignQuery).forEach(
        _.partial(
            migrateToDesignData,
            documentData,
            designData,
            dataIdToDesignId,
            dataIdToBgMediaId,
            // @ts-ignore
            _,
            uniqueIdGenerator
        )
    )

    _(mobileComponents)
        .filter(component => isStripContainer(component) && !hasDesignQuery(component))
        .forEach(_.partial(migrateToDesignDataMobile, designData, dataIdToDesignId, dataIdToBgMediaId))
}

function getChildren(structure) {
    if (!_.isUndefined(structure.components)) {
        return structure.components
    } else if (!_.isUndefined(structure.children)) {
        return structure.children
    }
    return []
}

function flatComponents(componentsTree) {
    const stack = _.clone(componentsTree) || []
    const flat = []
    while (stack.length > 0) {
        const comp = stack.pop()
        flat.push(comp)
        const children = getChildren(comp)
        //eslint-disable-next-line lodash/prefer-map
        _.forEach(children, child => {
            stack.push(child)
        })
    }
    return flat
}

function getComponents(pageJson) {
    return flatComponents(getChildren(pageJson.structure))
}

function getMobileComponents(pageJson) {
    return flatComponents(pageJson.structure.mobileComponents)
}

module.exports = {
    name: 'designDataFixer',
    version: 1,
    exec(pageJson, pageIdsArray, magicObject) {
        const components = getComponents(pageJson)
        const mobileComponents = getMobileComponents(pageJson)
        const {uniqueIdGenerator} = magicObject.dataFixerUtils

        pageJson.data.design_data = pageJson.data.design_data || {}
        fixStripContainers(
            components,
            mobileComponents,
            pageJson.data.document_data,
            pageJson.data.design_data,
            uniqueIdGenerator
        )
    }
}
