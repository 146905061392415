import type {CreateExtArgs, Extension, ExtensionAPI} from '@wix/document-manager-core'

const createExtensionAPI = ({dal}: CreateExtArgs): ExtensionAPI => ({
    siteAPI: {
        forceUpdate: () => {
            dal.commitTransaction('lifecycleExt')
        }
    }
})

const createExtension = (): Extension => ({
    name: 'lifecycle',
    createExtensionAPI
})

export interface Lifecycle {
    forceUpdate(): void
}

export type LifecycleApi = ExtensionAPI & {siteAPI: Lifecycle}

export {createExtension}
