import _ from 'lodash'
import * as conversionUtils from '../conversionUtils'
import {conversionConfig} from '../conversionConfig'
import type {ConversionSettings, DeepStructure, Layout} from '../../types'
import * as conversionDataUtils from './conversionDataUtils'
import {isMobileOnlyFixedComponentId} from '../mobileOnlyComponents/mobileOnlyComponentsUtils'

export const getBlockIdsFromConversionData = (component): string[] => _.get(component, ['conversionData', 'blockIds'], [])

export const getBlocksLayoutFromConversionData = (component): Layout[] => _.get(component, ['conversionData', 'blockLayout'], [])

export const setBlockLayoutData = (layout: Layout, key, value): void => {
    _.set(layout, [key], value)
}

export const getBlockComponentType = (component: DeepStructure, indexOfblock: number) => {
    const blockComponentsType = _.get(component, ['conversionData', 'blockComponentTypes'], [])
    return blockComponentsType[indexOfblock][0]
}

export const getBlocksLayoutData = (layout: Layout, key: keyof Layout): number | undefined => layout && layout[key]

export const removeBlocksFromConversionData = (component, i) => {
    component.conversionData.blockIds.splice(i, 1)
    component.conversionData.blockLayout.splice(i, 1)
    component.conversionData.blockComponentTypes.splice(i, 1)
}

export const insertBlockBetweenExisting = (container: DeepStructure, indextToIsert: number, componentToAdd: DeepStructure | undefined) => {
    if (!componentToAdd) return

    const {id, layout, componentType} = componentToAdd

    container.conversionData.blockIds.splice(indextToIsert, 0, [id])
    container.conversionData.blockLayout.splice(indextToIsert, 0, layout)
    container.conversionData.blockComponentTypes.splice(indextToIsert, 0, [componentType])
}

export const setBlocksToConversionData = (
    component: DeepStructure,
    blockIds: string[][] = [],
    blockLayout: Layout[] = [],
    blockComponentTypes: string[][] = []
) => {
    component.conversionData.blockIds = blockIds
    component.conversionData.blockLayout = blockLayout
    component.conversionData.blockComponentTypes = blockComponentTypes
}

export const addMissedBlocksDataIfNeeded = container => {
    _.defaults(container.conversionData, {
        blockIds: [],
        blockLayout: [],
        blockComponentTypes: []
    })
}

export const findBlockNumberOfComponent = (componentId: string, container: DeepStructure): number => {
    const blockIds: string[] = getBlockIdsFromConversionData(container)
    return _.findIndex(blockIds, block => _.includes(block, componentId))
}

export const shouldBeTightAccordingToParent = (component: DeepStructure) => _.get(component, ['conversionData', 'hasTightMarginBetweenChildren'], false)

export const shouldBeTightWithPreviousSibling = (component: DeepStructure) => _.get(component, ['conversionData', 'tightWithPreviousSibling'], false)

export const getYGapsBetweenBlocks = (comp: DeepStructure, enableImprovedMergeFlow = false) => {
    const shouldIgnoreGapsForContainers = enableImprovedMergeFlow && conversionUtils.isContainerComponent(comp)
    if (
        shouldIgnoreGapsForContainers ||
        conversionUtils.isClassicSectionComponent(comp) ||
        shouldBeTightAccordingToParent(comp) ||
        shouldBeTightWithPreviousSibling(comp)
    ) {
        return 0
    }

    return conversionConfig.COMPONENT_MOBILE_MARGIN_Y
}

export const calculateYForComponents = (component: DeepStructure, blockLayout: Layout[], blockIndexOfComponent: number) => {
    const margin = getYGapsBetweenBlocks(component)
    return blockLayout[blockIndexOfComponent].y + blockLayout[blockIndexOfComponent].height + margin
}

export const calculateYFromBlockLayout = (
    blockNumberOfPreviousComponent: number,
    container: DeepStructure,
    componentToAdd: DeepStructure,
    settings: ConversionSettings
): number => {
    const blockLayout = getBlocksLayoutFromConversionData(container)
    /** It means component inserted as first in container */
    if (blockNumberOfPreviousComponent < 0) {
        let shouldUseComponentMargins
        let shouldAddRootContainerMargins

        if (settings.enableImprovedMergeFlow) {
            shouldUseComponentMargins = !conversionUtils.shouldStretchToScreenWidth(componentToAdd) && !conversionUtils.shouldStretchToScreenWidth(container)
            shouldAddRootContainerMargins = !conversionUtils.hasTightTopMargin(container) && container.layout.y >= 0
        } else {
            shouldUseComponentMargins = !conversionUtils.shouldStretchToScreenWidth(componentToAdd)
            shouldAddRootContainerMargins = container.layout.y > 0
        }

        if (shouldUseComponentMargins) {
            return getYGapsBetweenBlocks(componentToAdd)
        }

        return shouldAddRootContainerMargins ? conversionConfig.ROOT_COMPONENT_MARGIN_Y : 0
    }

    const y = calculateYForComponents(componentToAdd, blockLayout, blockNumberOfPreviousComponent)
    return y
}

export const haveSufficientYOverlap = (comp1, comp2) => {
    const yOverlap = conversionUtils.getYOverlap(comp1, comp2)
    const minHeight = Math.min(comp1.layout.height, comp2.layout.height)
    const sufficientOverlap = yOverlap / minHeight > conversionConfig.OVERLAP_TO_MIN_WIDTH_RATIO_THRESHOLD

    return sufficientOverlap
}

export const compareByRows = (comp1: DeepStructure, comp2: DeepStructure): number => {
    if (!haveSufficientYOverlap(comp1, comp2) && comp1.layout.y !== comp2.layout.y) {
        return comp1.layout.y < comp2.layout.y ? -1 : 1
    }

    const isComp1ScreenWidth = conversionUtils.isScreenWidthComponent(comp1)
    const isComp2ScreenWidth = conversionUtils.isScreenWidthComponent(comp2)

    if (isComp1ScreenWidth) {
        return isComp2ScreenWidth ? 0 : -1
    }

    if (isComp2ScreenWidth) {
        return 1
    }

    if (comp1.layout.x < comp2.layout.x) {
        return -1
    }

    if (comp1.layout.x > comp2.layout.x) {
        return 1
    }

    return 0
}

export const addCurrentBlockData = (component: DeepStructure, currentBlockIds): void => {
    const blockComps = conversionUtils.getComponentsByIds(component, currentBlockIds)
    const blockLayout = conversionUtils.getSnugLayout(blockComps)
    const blockTypes = blockComps.map(comp => comp.componentType)

    component.conversionData.blockLayout.push(blockLayout)
    component.conversionData.blockIds.push(currentBlockIds)
    component.conversionData.blockComponentTypes.push(blockTypes)
}

export const identifyBlocks = (component: DeepStructure, children: DeepStructure[], settings?: ConversionSettings): void => {
    conversionDataUtils.createConversionDataIfNotExists(component)

    setBlocksToConversionData(component as DeepStructure, [], [], [])

    const childrenClone = <DeepStructure[]>_.cloneDeep(children)
    childrenClone.sort(compareByRows)

    let currentBlockIds = []
    let firstIndexInCurrentBlock = 0

    _.forEach(childrenClone, (child, i) => {
        currentBlockIds.push(child.id)

        if (settings?.enableImprovedMergeFlow && isMobileOnlyFixedComponentId(child.id)) {
            return
        }

        if (i === childrenClone.length - 1) {
            addCurrentBlockData(component, currentBlockIds)
            return
        }

        const otherBlockMembers = _.slice(childrenClone, firstIndexInCurrentBlock, i + 1)
        const haveSufficientYOverlapWithOtherBlockMember = _.some(otherBlockMembers, sibling => haveSufficientYOverlap(sibling, childrenClone[i + 1]))

        if (!haveSufficientYOverlapWithOtherBlockMember) {
            addCurrentBlockData(component, currentBlockIds)
            currentBlockIds = []
            firstIndexInCurrentBlock = i + 1
        }
    })
}

export const identifyBlocksRecursively = (component: DeepStructure, settings?: ConversionSettings) => {
    const children = conversionUtils.getChildren(component)

    if (!children) {
        return
    }

    identifyBlocks(component, children, settings)

    _.forEach(children, (child: DeepStructure) => identifyBlocksRecursively(child, settings))
}
