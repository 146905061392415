import _ from 'lodash'
import type {Pointer, PS, WidgetPluginDescriptor} from '@wix/document-services-types'

import appStudioDataModel from './appStudioDataModel'

function getWidgetPlugin(ps: PS, widgetPointer: Pointer) {
    const widgetData = appStudioDataModel.getData(ps, widgetPointer) || {}
    return widgetData.plugin
}

function updateWidgetPlugin(ps: PS, widgetPointer: Pointer, pluginDescriptor: WidgetPluginDescriptor) {
    const widgetData = appStudioDataModel.getData(ps, widgetPointer)

    if (!widgetData?.plugin) {
        throw new Error('appStudio.plugins: there is no widget plugin to update')
    }

    const newPluginDescriptor = _.assign({}, widgetData.plugin, pluginDescriptor)
    widgetData.plugin = newPluginDescriptor
    appStudioDataModel.setWidgetData(ps, widgetPointer, widgetData)
}

function createWidgetPlugin(ps: PS, widgetPointer: Pointer, pluginDescriptor: WidgetPluginDescriptor) {
    const widgetData = appStudioDataModel.getData(ps, widgetPointer)

    if (widgetData.plugin) {
        throw new Error('appStudio.plugins: plugin is already exist')
    }

    if (widgetData) {
        widgetData.plugin = pluginDescriptor
        appStudioDataModel.setWidgetData(ps, widgetPointer, widgetData)
    }
}

export default {
    createWidgetPlugin,
    updateWidgetPlugin,
    getWidgetPlugin
}
