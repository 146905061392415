import type {PublicMethodUtils} from '@wix/document-services-types'
import {externalComponents} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            externalComponents: {
                setUserConsent: publicMethodUtils.defineAction(externalComponents.setUserConsent),
                getUserConsent: publicMethodUtils.defineGetter(externalComponents.getUserConsent),
                showLoader: publicMethodUtils.defineAction(externalComponents.showLoader)
            }
        }
    }
}
