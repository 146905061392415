import allComponentsDefinitionsMap from '../../dist/originalSchemas/componentsDefinitionsMap.json'
import allFullyRestrictedSchemas from '../../dist/allFullyRestrictedSchemas.json'
import * as schemaArrangement from '../schemaArrangement'

const schemas = schemaArrangement.arrangeByDataTypeAndConvertSchemaNamesToOldStyleFileNames(allFullyRestrictedSchemas)

export default {
    ...schemas,
    allComponentsDefinitionsMap,
    componentsDefinitionsMap: allComponentsDefinitionsMap
}
