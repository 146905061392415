const _ = require('lodash')

const TARGET = Symbol('TARGET')

const isObject = obj => typeof obj === 'object' && obj !== null

const unwrap = obj => (isObject(obj) ? obj[TARGET] || obj : obj)

// The cache is used to ensure that a reference compare will return true.
// Without the cache, proxy.a !== proxy.a. With the cache, proxy.a === proxy.a
class ProxyHandler {
    constructor(cache, callbackFn, path) {
        this.callbackFn = callbackFn
        this.path = path
        this.cache = cache
    }

    get(target, key) {
        if (key === TARGET) {
            return target
        }
        const obj = target[key]
        if (this.cache.has(obj)) {
            return this.cache.get(obj)
        }
        if (isObject(obj)) {
            const proxy = new Proxy(obj, new ProxyHandler(this.cache, this.callbackFn, this.path.concat(key)))
            this.cache.set(obj, proxy)
            return proxy
        }
        return obj
    }

    set(target, key, value) {
        const oldValue = target[key]
        const unproxiedObject = unwrap(value)
        if (unproxiedObject !== oldValue) {
            this.cache.delete(oldValue)
            target[key] = unproxiedObject
            if (!_.isEqual(oldValue, unproxiedObject)) {
                this.callbackFn(this.path.concat(key), oldValue, unproxiedObject)
            }
        }
        return true
    }

    deleteProperty(target, key) {
        if (key in target) {
            const oldValue = target[key]
            delete target[key]
            this.cache.delete(oldValue)
            this.callbackFn(this.path.concat(key), oldValue, undefined)
        }
        return true
    }
}

const changeDetectorProxy = {
    create: (obj, callbackFn) => {
        if (isObject(obj)) {
            // if not already a proxy
            if (!obj[TARGET]) {
                const cache = new WeakMap()
                return new Proxy(obj, new ProxyHandler(cache, callbackFn, []))
            }
        }
        return obj
    },

    unwrap,

    isProxy: obj => !!(isObject(obj) && obj[TARGET])
}

module.exports = changeDetectorProxy
