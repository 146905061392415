import type {Pointer, PS} from '@wix/document-services-types'
import metaDataUtils from '../../componentsMetaData/metaDataUtils'

const STATE_BOX = 'wysiwyg.viewer.components.StateBox'

function canBeStretched(ps: PS, metaDataValue, compPointer: Pointer) {
    const parentPointer = ps.pointers.components.getParent(compPointer)
    if (parentPointer && metaDataUtils.getComponentType(ps, parentPointer) === STATE_BOX) {
        return false
    }
    return metaDataValue
}

export default {
    canBeStretched
}
