import _ from 'lodash'
import {ReportableError} from '@wix/document-manager-utils'
import type {Pointer, PS} from '@wix/document-services-types'

/**
 *
 * @param {ps} ps
 * @param {boolean} useOriginal
 * @returns {string} languageCode of current or original language
 */
function getLanguageByUseOriginal(ps: PS, useOriginal: boolean): string {
    return useOriginal
        ? _.get(ps.dal.get(ps.pointers.multilingual.originalLanguage()), ['languageCode'])
        : ps.dal.get(ps.pointers.multilingual.currentLanguageCode())
}

function getNonTranslatablePointer(ps: PS, pointer: Pointer) {
    const originalLanguage = getLanguageByUseOriginal(ps, true)
    return {...pointer, useLanguage: originalLanguage}
}

const isMultilingual = (ps: PS) => {
    const currentLang = ps.dal.get(ps.pointers.multilingual.currentLanguageCode())
    if (!currentLang) {
        return false
    }

    const originalLang = ps.dal.get(ps.pointers.multilingual.originalLanguage())?.languageCode
    if (!originalLang) {
        const error = new ReportableError({
            errorType: 'originalLanguageUndefined',
            message: 'Original language is undefined'
        })
        ps.extensionAPI.logger.captureError(error)
        throw error
    }

    return currentLang !== originalLang
}

export default {
    getLanguageByUseOriginal,
    getNonTranslatablePointer,
    isMultilingual
}
