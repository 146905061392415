import type {PublicMethodUtils} from '@wix/document-services-types'
import {wix2x, responsiveLayout, breakpoints} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            responsive: {
                migration: {
                    /**
                     * @param {boolean} isResponsive - sets the isResponsive flag of the site
                     */
                    setResponsive: publicMethodUtils.defineDataManipulationAction(wix2x.setResponsive),
                    responsiveLayout: {
                        update: publicMethodUtils.defineDataManipulationAction(responsiveLayout.update)
                    },
                    breakpoints: {
                        update: publicMethodUtils.defineDataManipulationAction(breakpoints.update)
                    }
                }
            }
        }
    }
}
