import _ from 'lodash'
import type {Experiment, RunningExperiments} from '@wix/document-services-types'
import {CoreConfig, createDMCore} from '../src/documentManagerCore'
import {newSchemaService} from '@wix/document-services-json-schemas'
import {createTestExperimentInstance, getDefaultExperiments} from './testkit/experiments'
import {createTestLogger} from './testkit/testLogger'
import {createTestDal, defaultType, dalUtils} from './testkit/testDal'
import type {CoreLogger} from '../src/index'

interface CoreArgs {
    experimentInstance?: Experiment
    logger?: CoreLogger
    experimentOverrides?: RunningExperiments
    coreConfigOverrides?: Partial<CoreConfig>
}

const createTestCore = ({experimentInstance, logger, experimentOverrides, coreConfigOverrides}: CoreArgs = {}) => {
    const experiment = experimentInstance ?? createTestExperimentInstance(experimentOverrides)
    return createDMCore(
        _.merge(
            {
                experimentInstance: experiment,
                logger: logger ?? createTestLogger(),
                schemaService: newSchemaService.staticInstance,
                signatureSeed: 'sig',
                transactionActionsLimit: 500,
                minNumOfPagesToCalculateRenderHints: 0
            },
            coreConfigOverrides
        )
    )
}

export {
    defaultType,
    dalUtils,
    createTestDal,
    createTestCore,
    createTestExperimentInstance,
    createTestLogger,
    getDefaultExperiments
}
