import _ from 'lodash'
import type {ViewerAPI} from '@wix/viewer-manager-interface'
import {isSameViewMode} from './structureUtils'

const setRenderFlags = (setRenderFlag: Function, getRenderFlags: Function, id: string, value: any) => {
    const existingRenderFlags = getRenderFlags()
    const setSingleFlag = (flagName: string, flagValue: string) => {
        if (!_.isEqual(_.get(existingRenderFlags, flagName), flagValue)) {
            setRenderFlag(flagName, flagValue)
        }
    }
    _.forEach(value, (flagValue, flagName) => setSingleFlag(flagName, flagValue))
}

const setSingleRenderFlag = (
    setRenderFlag: Function,
    getRenderFlags: Function,
    id: string,
    innerPath: string[],
    value: any
) => {
    const existingRenderFlags = getRenderFlags() ?? {}
    const flagName = innerPath[0]
    if (!_.isEqual(existingRenderFlags[flagName], value)) {
        setRenderFlag(flagName, value)
    }
}

const setRealTimeConfig = (viewer: ViewerAPI, id: string, value: any) => {
    if (_.startsWith(id, 'compsToHide')) {
        const idSplit = id.split('_')
        const compId = idSplit.slice(2).join('_')
        const viewMode = idSplit[1]
        return viewer.realTimeConfig.setHiddenComp(compId, viewMode, value)
    }
    switch (id) {
        case 'compsToShowOnTop':
            return viewer.realTimeConfig.setCompsToShowOnTop(value)
        case 'compsToShowWithOpacity':
            return viewer.realTimeConfig.setCompsToShowWithOpacity(value)
        case 'hideTextComponent':
            return viewer.realTimeConfig.setHideTextComponent(value)
    }
}

const setStructure = (viewer: ViewerAPI, id: string, value: any, type: string) => {
    if (viewer.viewMode.isDuringViewModeSwitch() || isSameViewMode(viewer, type)) {
        viewer.structure.updateStructure(id, value)
    }
}

const updateRendererModel = (viewer: ViewerAPI, id: string, value: any) => {
    switch (id) {
        case 'clientSpecMap':
            return viewer.rendererModel.updateClientSpecMap(value)
        case 'wixCodeModel':
            return viewer.rendererModel.updateWixCodeModel(value)
        case 'pagesPlatformApplications':
            return viewer.rendererModel.updatePagesPlatformApplications(value)
        case 'passwordProtectedPages':
            return viewer.rendererModel.updatePasswordProtectedPages(value)
        case 'sitePropertiesInfo':
            return viewer.rendererModel.updateSitePropertiesInfo(value)
        case 'metaSiteId':
            return viewer.rendererModel.updateMetaSiteId(value)
        case 'premiumFeatures':
            return viewer.rendererModel.updatePremiumFeatures(value)
        case 'urlMappings':
            return viewer.rendererModel.updateUrlMappings(value)
        case 'routers':
            return viewer.rendererModel.updateRouters(value)
        case 'siteMetaData':
            return viewer.rendererModel.updateSiteMetaData(value)
        case 'siteInfo':
            return viewer.rendererModel.updateSiteInfo(value)
        case 'platformAppsExperiments':
            return viewer.rendererModel.updatePlatformAppsExperiments(value)
        case 'blocksExperiments':
            return viewer.rendererModel.updateBlocksExperiments(value)
    }
}

const updateDocumentServicesModel = (viewer: ViewerAPI, id: string, value: any) => {
    switch (id) {
        case 'version':
            return viewer.documentServicesModel.updateVersion(value)
        case 'siteName':
            return viewer.documentServicesModel.updateSiteName(value)
        case 'revision':
            return viewer.documentServicesModel.updateRevision(value)
        case 'publicUrl':
            return viewer.documentServicesModel.updatePublicUrl(value)
        case 'isPublished':
            return viewer.documentServicesModel.updateIsPublished(value)
        case 'customHeadTags':
            return viewer.documentServicesModel.updateCustomHeadTags(value)
    }
}

const setRuntime = (viewer: ViewerAPI, id: string, value: any) => {
    switch (id) {
        case 'wantedNavInfo':
            return viewer.runtime.setWantedNavInfo(value)
        case 'wantedIsMobileView':
            return viewer.runtime.setWantedIsMobileView(value)
    }
}

const setCurrentLanguage = (viewer: ViewerAPI, id: string, value: any) => viewer.multiLingual.setCurrentLanguage(value)
const setGhostStructure = (viewer: ViewerAPI, id: string, value: any) => viewer.ghostables.setGhostStructure(value)
const setGhostControllers = (viewer: ViewerAPI, id: string, value: any) => viewer.ghostables.setGhostControllers(value)
const setBlocksPreviewData = (viewer: ViewerAPI, id: string, value: any) => viewer.blocks.setBlocksPreviewData(value)
const setCacheKillerCounter = (viewer: ViewerAPI, id: string, innerPath: string[], value: any) =>
    viewer.customElements.setCacheKillerCounter(innerPath[0], value)

export {
    setRenderFlags,
    setSingleRenderFlag,
    setRealTimeConfig,
    setRuntime,
    setStructure,
    setCurrentLanguage,
    setGhostStructure,
    setGhostControllers,
    setBlocksPreviewData,
    setCacheKillerCounter,
    updateRendererModel,
    updateDocumentServicesModel
}
