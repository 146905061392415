import _ from 'lodash'
import {warmupUtils, utils, coreUtils} from '@wix/santa-ds-libs'
const {urlUtils} = coreUtils
import {requestsUtil} from '@wix/santa-core-utils'
import clientSpecMap from '../../siteMetadata/clientSpecMap'
import platformConstants from '../../platform/common/constants'
import appStudioDependencies from '../appStudioDependencies'
import codeAppInfoUtils from '../../wixCode/utils/codeAppInfo'
import type {PS} from '@wix/document-services-types'

const {ajaxLibrary} = warmupUtils
const {cookieUtils} = utils

const baseUrl = '_api/wix-blocks-service/api/v1'

interface WidgetPageData {
    name: string
    id: string
}

async function unDraftSite(
    ps: PS,
    {metaSiteId, appName}: {metaSiteId: string; appName: string}
): Promise<{appDefId: string}> {
    const {instance} = clientSpecMap.getAppData(ps, platformConstants.APPS.META_SITE.applicationId)

    try {
        const appDefId = urlUtils.getParameterByName('appDefinitionId') || undefined
        const response = await requestsUtil.fetchJson(`/${baseUrl}/sites/${metaSiteId}/undraft`, {
            credentials: 'include',
            method: 'POST',
            headers: new Headers({
                Authorization: instance,
                'X-XSRF-TOKEN': cookieUtils.getCookie('XSRF-TOKEN'),
                'Content-Type': 'application/json;charset=utf-8',
                Accept: 'application/json'
            }),
            body: JSON.stringify({
                appName,
                appDefId
            })
        })
        return {appDefId: response.appDefId}
    } catch (e: any) {
        if (!e) {
            throw new Error('Cannot unDraft site with unknown reason')
        }
        if (e.headers?.get('Content-Type').startsWith('application/json')) {
            const body = await e.json()
            const message = _.get(body, 'error.message', body.message || '')
            throw new Error(`Cannot unDraft site: status ${e.status}, ${message}`)
        }
        const message = 'status' in e ? `status ${e.status}` : e.message || e
        throw new Error(`Cannot unDraft site: ${message}`)
    }
}
async function preBuild(ps: PS, appDefId: string, documentWidgets?: WidgetPageData[]): Promise<any> {
    const {instance} = clientSpecMap.getAppData(ps, platformConstants.APPS.META_SITE.applicationId)

    return new Promise((resolve, reject) => {
        ajaxLibrary.ajax({
            type: 'POST',
            url: `/${baseUrl}/apps/${appDefId}/pre-build`,
            headers: getRequestHeaders(ps, instance),
            dataType: 'json',
            xhrFields: {
                withCredentials: true
            },
            data: {documentWidgets, appDefId},
            success(response) {
                resolve(response)
            },
            error: e => {
                if (!e) {
                    reject(new Error('Cannot preBuild app with unknown reason'))
                }

                const message = 'status' in e ? `status ${e.status}` : e.message || e
                reject(new Error(`Cannot preBuild app: ${message}`))
            }
        })
    })
}

function getRequestHeaders(ps: PS, instance: string) {
    return {
        'X-Wix-Editor-Version': 'new',
        'X-Wix-DS-Origin': ps.config.origin,
        'X-Editor-Session-Id': ps.siteAPI.getEditorSessionId(),
        Authorization: instance,
        'X-XSRF-TOKEN': cookieUtils.getCookie('XSRF-TOKEN')
    }
}

function getBlocksServerBaseUrl(ps: PS) {
    const blocksServerBaseUrl = ps.dal.get(
        ps.pointers.getInnerPointer(ps.pointers.general.getServiceTopology(), 'editorRootUrl')
    )
    return `${blocksServerBaseUrl}${baseUrl}`
}

function getBuildUrl(ps: PS, versionType: string, appDefId: string, isAsyncBuild: boolean) {
    const blocksServerBaseUrl = getBlocksServerBaseUrl(ps)
    const buildUrlPath = `${blocksServerBaseUrl}/apps/${appDefId}`

    if (isAsyncBuild) {
        return `${buildUrlPath}/async-build`
    }

    return `${buildUrlPath}/build`
}

function updateLastBuildId(ps: PS, response) {
    const pointer = ps.pointers.appBuilder.getLastBuildIdPointer()
    ps.dal.set(pointer, response.buildRecord?.id)
}

function isSuccessResponse(ps: PS, response, isAsyncBuild: boolean) {
    return isAsyncBuild ? Boolean(response.buildRecord?.status) : Boolean(response.version && response.revision)
}

function getAsyncBuildPayload({
    appMarketingName,
    blocksVersion,
    widgetsMap,
    codePackage,
    versionType,
    dependencies,
    releaseNotes,
    publishRc
}) {
    return {
        appMarketingName,
        blocksVersion,
        components: {
            widgetsMap,
            codePackage
        },
        versionType: versionType.toUpperCase(),
        dependencies,
        releaseNotes,
        publishRc
    }
}

function getBuildPayload({appMarketingName, blocksVersion, widgetsMap, versionType, withVersionType, publishRc}) {
    return {
        appMarketingName,
        blocksVersion,
        widgetsMap,
        skipDataFor: [],
        versionType: withVersionType ? versionType.toUpperCase() : undefined,
        publishRc
    }
}

function getSignedInstance(ps: PS) {
    const codeAppInfo = codeAppInfoUtils.getCodeAppInfoFromPS(ps)
    return codeAppInfo.signedInstance
}

function getErrorData({response, getBuildError}) {
    const plainBuildError = getBuildError({
        errorCode: response.status,
        errorDescription: response.responseText
    })

    try {
        const data = JSON.parse(response.response)
        return data.errorCode
            ? getBuildError({
                  errorCode: data.errorCode,
                  errorDescription: data.errorDescription,
                  applicationError: data.details?.applicationError
              })
            : plainBuildError
    } catch {
        return plainBuildError
    }
}

function performGetBuildById(ps: PS, buildId: string, appDefinitionId: string, getBuildError) {
    return new Promise((resolve, reject) => {
        const blocksServerBaseUrl = getBlocksServerBaseUrl(ps)
        const url = `${blocksServerBaseUrl}/apps/${appDefinitionId}/builds/${buildId}`
        const instance = getSignedInstance(ps)

        ajaxLibrary.ajax({
            type: 'GET',
            url,
            headers: getRequestHeaders(ps, instance),
            dataType: 'json',
            xhrFields: {
                withCredentials: true
            },
            success(response) {
                return response.buildRecord?.status
                    ? resolve(response.buildRecord)
                    : reject(
                          getErrorData({
                              response,
                              getBuildError
                          })
                      )
            },
            error: response =>
                reject(
                    getErrorData({
                        response,
                        getBuildError
                    })
                )
        })
    })
}

function postBuildRequest(
    ps: PS,
    {
        widgetsMap,
        appMarketingName,
        blocksVersion,
        versionType,
        codePackage,
        appDefId,
        isAsyncBuild,
        releaseNotes,
        publishRc
    },
    onSuccess,
    onError,
    getBuildError
) {
    const instance = getSignedInstance(ps)
    const buildUrl = getBuildUrl(ps, versionType, appDefId, isAsyncBuild)

    const payloadData = isAsyncBuild
        ? getAsyncBuildPayload({
              appMarketingName,
              blocksVersion,
              widgetsMap,
              codePackage,
              versionType,
              dependencies: appStudioDependencies.getAllDependencies(ps),
              releaseNotes,
              publishRc
          })
        : getBuildPayload({
              appMarketingName,
              blocksVersion,
              widgetsMap,
              versionType,
              withVersionType: true,
              publishRc
          })

    ajaxLibrary.ajax({
        type: 'POST',
        url: buildUrl,
        headers: getRequestHeaders(ps, instance),
        dataType: 'json',
        xhrFields: {
            withCredentials: true
        },
        data: payloadData,
        success(response) {
            if (isAsyncBuild) {
                updateLastBuildId(ps, response)
            }

            return isSuccessResponse(ps, response, isAsyncBuild)
                ? onSuccess()
                : onError(
                      getErrorData({
                          response,
                          getBuildError
                      })
                  )
        },
        error: response =>
            onError(
                getErrorData({
                    response,
                    getBuildError
                })
            )
    })
}

export default {
    unDraftSite,
    preBuild,
    performGetBuildById,
    postBuildRequest
}
