import _ from 'lodash'
import {siteConstants} from '@wix/santa-core-utils'
import {siteDataUtils} from '../../warmupUtils/index'

const SCROLL_PAGE_TOP_Y_POS = -99999
const SCROLL_PAGE_TOP_Y_POS_WITH_OFFSET = 0

function toNumber(measure: string) {
    const parsedMeasure = parseInt(measure, 10)
    return isNaN(parsedMeasure) ? 0 : parsedMeasure
}

function getWixTopAdsHeight(measureMap) {
    return toNumber(measureMap.height.WIX_ADStop)
}

function calcYOffset(anchorCompData, siteData) {
    let yOffset = 0
    const {measureMap} = siteData

    if (measureMap && anchorCompData) {
        const anchorCompId = anchorCompData.compId
        const isHeaderFixed = siteDataUtils.isHeaderFixedPosition(siteData)
        const topFromMeasureMap = measureMap.top[anchorCompId]
        const absoluteTopFromMeasureMap = measureMap.absoluteTop[anchorCompId]

        const compTop = isHeaderFixed ? topFromMeasureMap : absoluteTopFromMeasureMap
        const wixADSOffset = !siteData.isMobileView() || isHeaderFixed ? 0 : getWixTopAdsHeight(measureMap)
        yOffset = _.isFinite(compTop) ? compTop + wixADSOffset : 0
    }

    return yOffset
}
function calcAnchorPosition(anchorQuery: string, siteData) {
    let anchorYOffset = 0

    if (anchorQuery === siteConstants.PAGE_ANCHORS.TOP_ANCHOR_ID) {
        anchorYOffset = SCROLL_PAGE_TOP_Y_POS_WITH_OFFSET
    } else if (anchorQuery === siteConstants.PAGE_ANCHORS.BOTTOM_ANCHOR_ID) {
        anchorYOffset = toNumber(_.get(siteData.measureMap, ['height', 'masterPage']))
    } else if (anchorQuery) {
        const anchorCompData = siteData.getDataByQuery(anchorQuery, siteData.getPrimaryPageId())
        anchorYOffset = calcYOffset(anchorCompData, siteData)
    }

    return {
        x: 0,
        y: anchorYOffset,
        anchorQuery
    }
}

function getPageAnchors(siteData, pageId: string, pageTopLabel?: string) {
    const pageData = siteData.getPageDataMap(pageId, siteData.dataTypes.DATA)
    if (!pageData) {
        return []
    }

    const topAnchorData = getPageTopAnchor(siteData.getPrimaryPageId(), pageTopLabel!)
    const pageComponents = siteData.getPageData(pageId, ['structure', siteData.getViewMode()])

    const pageAnchors = _(pageData)
        .filter({type: 'Anchor'})
        .sortBy(pageAnchor => _.get(pageComponents, [pageAnchor.compId, 'layout', 'y']))
        .value()

    return [topAnchorData, ...pageAnchors]
}

function getPageTopAnchor(pageId: string, pageTopLabel: string) {
    return {
        compId: siteConstants.PAGE_ANCHORS.TOP_ANCHOR_COMP_ID,
        id: siteConstants.PAGE_ANCHORS.TOP_ANCHOR_ID,
        name: pageTopLabel || '',
        type: 'Anchor',
        pageId
    }
}
function getSortedAnchorsByMeasureMapY(siteData, anchorsToSort) {
    return _(anchorsToSort)
        .map(function (anchor) {
            const position = calcAnchorPosition(anchor.id, siteData)
            const {id, compId} = anchor
            return _.assign(position, {id, compId})
        })
        .sortBy('y')
        .value()
}
function isSpecialAnchor(anchorDataId) {
    return (
        anchorDataId === siteConstants.PAGE_ANCHORS.BOTTOM_ANCHOR_ID ||
        anchorDataId === siteConstants.PAGE_ANCHORS.TOP_ANCHOR_ID
    )
}

export default {
    /**
     * calc the anchor position by the given anchor data
     * @param anchorQuery
     * @param siteData
     * @returns {{x: number, y: *}}
     */
    getPageTopAnchor,
    getPageAnchors,
    isSpecialAnchor,
    getSortedAnchorsByY: getSortedAnchorsByMeasureMapY,
    SCROLL_PAGE_TOP_Y_POS
}
