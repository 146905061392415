const {markPropertyToRemove} = require('./utils')

const sides = ['padding-left', 'padding-right', 'padding-top', 'padding-bottom']
const paddingPropertiesToRemove = ['padding', ...sides.map(markPropertyToRemove)]

function paddingToProperty(padding) {
    const paddingProperties = []
    Object.entries(padding).forEach(([side, value]) => {
        paddingProperties.push({
            prop: `padding-${side}`,
            cssProp: `padding-${side}`,
            value
        })
    })

    return paddingProperties
}

function getPaddingValues(padding) {
    const paddingValues = padding.value.split(' ')
    const paddingLength = paddingValues.length

    if (paddingLength === 1) {
        const [paddingValue] = paddingValues
        return {
            left: paddingValue,
            right: paddingValue,
            top: paddingValue,
            bottom: paddingValue
        }
    }

    if (paddingLength === 2) {
        const [paddingTopAndBottom, paddingLeftAndRight] = paddingValues
        return {
            left: paddingLeftAndRight,
            right: paddingLeftAndRight,
            top: paddingTopAndBottom,
            bottom: paddingTopAndBottom
        }
    }

    if (paddingLength === 3) {
        const [paddingTop, paddingLeftAndRight, paddingBottom] = paddingValues
        return {
            left: paddingLeftAndRight,
            right: paddingLeftAndRight,
            top: paddingTop,
            bottom: paddingBottom
        }
    }

    const [paddingTop, paddingRight, paddingBottom, paddingLeft] = paddingValues

    return {
        left: paddingLeft,
        right: paddingRight,
        top: paddingTop,
        bottom: paddingBottom
    }
}

function handlePaddingProperty(property, paddingSides) {
    if (property.cssProp === 'padding') {
        const {left, right, top, bottom} = getPaddingValues(property)
        paddingSides.left = left
        paddingSides.right = right
        paddingSides.top = top
        paddingSides.bottom = bottom
    }

    if (sides.includes(property.cssProp)) {
        const side = property.cssProp.replace('padding-', '')
        property.cssProp = markPropertyToRemove(property.cssProp)
        paddingSides[side] = property.value
    }
}

function getUpdatedProperties(properties, paddingSides) {
    return [
        ...properties.filter(property => !paddingPropertiesToRemove.includes(property.cssProp)),
        ...paddingToProperty(paddingSides)
    ]
}
/**
 * @param {Array<import('../index').ParsedStyleItem>} styleItems
 * @returns {Array<import('../index').ParsedStyleItem>}
 */
function migratePadding(styleItems) {
    return styleItems.map(styleItem => {
        const {parsedStyle} = styleItem
        const updatedParsedStyle = Object.entries(parsedStyle).reduce(
            (acc, [selector, {properties, mobileProperties}]) => {
                const paddingSides = {}
                const mobilePaddingSides = {}

                properties.forEach(property => {
                    handlePaddingProperty(property, paddingSides)
                })

                mobileProperties.forEach(property => {
                    handlePaddingProperty(property, mobilePaddingSides)
                })

                const updatedProperties = getUpdatedProperties(properties, paddingSides)
                const updatedMobileProperties = getUpdatedProperties(mobileProperties, mobilePaddingSides)

                return {
                    ...acc,
                    [selector]: {
                        ...acc[selector],
                        properties: updatedProperties,
                        mobileProperties: updatedMobileProperties
                    }
                }
            },
            parsedStyle
        )

        return {
            ...styleItem,
            parsedStyle: updatedParsedStyle
        }
    })
}

module.exports = migratePadding
