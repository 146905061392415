import type {Extension, ExtensionAPI, CoreLogger} from '@wix/document-manager-core'
import _ from 'lodash'

const MAX_POST_LAYOUT_OPERATIONS = 20

interface CircuitUpdateInfo {
    latelyCalls: number
    compData: any
    layoutUpdates: any[]
}
type CircuitUpdatesMap = Record<string, CircuitUpdateInfo>

const createExtension = (): Extension => {
    let postLayoutCount = 0
    let circuitUpdatesMap: CircuitUpdatesMap = {}

    const updateCircuitsMap = (updatedComps: Record<string, any>) =>
        _.forEach(updatedComps, (compData, compId) => {
            circuitUpdatesMap[compId] =
                circuitUpdatesMap[compId] ??
                ({
                    latelyCalls: 0,
                    layoutUpdates: []
                } as unknown as CircuitUpdateInfo)
            circuitUpdatesMap[compId].latelyCalls++
            circuitUpdatesMap[compId].compData = compData
            circuitUpdatesMap[compId].layoutUpdates.push(compData.newLayout)
        })

    const collectLayoutChangesForReport = (layoutUpdates: any[]) => {
        const firstUpdatedLayout = _.head(layoutUpdates) || {}
        const lastUpdatedLayout = _.last(layoutUpdates) || {}
        const changedDimensions = _(lastUpdatedLayout)
            .pickBy((value: number, key: string) => value !== firstUpdatedLayout[key])
            .keys()
            .value()
        // transform into {x: [...], y: [...], height: [...], width: [...]}
        const layoutChanges = _(changedDimensions)
            .keyBy(_.identity)
            .mapValues(dimension => _.transform(layoutUpdates, (acc: any[], layout) => acc.push(layout[dimension]), []))
            .value()
        return layoutChanges
    }

    const reportCircuitUpdates = (logger: CoreLogger) =>
        _.forEach(circuitUpdatesMap, ({latelyCalls, compData, layoutUpdates}: CircuitUpdateInfo, compId: string) => {
            // report if needed
            if (latelyCalls >= 18) {
                const layoutChanges = collectLayoutChangesForReport(layoutUpdates)
                logger.interactionStarted('CIRCUIT_COMPONENT_UPDATES_ALERT', {
                    extras: {compData, layoutChanges, compId}
                })
            }
        })

    const createExtensionAPI = (): LayoutCBApi => ({
        layoutCircuitBreaker: {
            resetPostLayoutCount: () => {
                postLayoutCount = 0
                circuitUpdatesMap = {}
            },
            increasePostLayoutCount: (updatedComps: Record<string, any>) => {
                postLayoutCount++
                updateCircuitsMap(updatedComps)
            },
            isPostLayoutOperationAllowed: () => postLayoutCount < MAX_POST_LAYOUT_OPERATIONS,
            getMaxPostLayoutOperationsAllowed: () => MAX_POST_LAYOUT_OPERATIONS,
            reportCircuitUpdates
        }
    })

    return {
        name: 'layoutCircuitBreaker',
        createExtensionAPI
    }
}

export interface LayoutCB {
    resetPostLayoutCount(): void
    increasePostLayoutCount(updatedComps: Record<string, any>): void
    isPostLayoutOperationAllowed(): boolean
    getMaxPostLayoutOperationsAllowed(): number
    reportCircuitUpdates(logger: CoreLogger): void
}

export type LayoutCBApi = ExtensionAPI & {layoutCircuitBreaker: LayoutCB}

export {createExtension}
