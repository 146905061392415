import _ from 'lodash'
import type {DSConfig} from '@wix/document-services-types'

const featureConfigs = {
    miniSite: {
        extensionsList: ['miniSite'],
        modulesList: ['miniSitePublicAPI']
    }
}

const extendWithFeatures = (config: DSConfig, features?: string[]) => {
    if (!features) {
        return config
    }
    const featuresToEnable = {config, ..._.pick(featureConfigs, features)}
    const modulesList = _(featuresToEnable).flatMap('modulesList').uniq().value()
    const extensionsList = _(featuresToEnable).flatMap('extensionsList').uniq().value()

    return {
        ...config,
        modulesList,
        extensionsList
    }
}
export {extendWithFeatures}
