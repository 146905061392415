import _ from 'lodash'
import clientSpecMapUtils from '../utils/clientSpecMapUtils'
import constants from '../utils/constants'
import wixCodeMonitoring from '../utils/wixCodeMonitoringReporter'
import type {PS} from '@wix/document-services-types'
import {getMetaSiteId} from '../../utils/dalUtil'

const BASE_URL_KEY = 'wixCodeMonitoringHubUrl'

const psGetter = (ps: PS) => ({
    getBaseUrl() {
        const pointer = ps.pointers.general.getServiceTopology()
        return ps.dal.get(ps.pointers.getInnerPointer(pointer, BASE_URL_KEY))
    },
    getAppId() {
        return ps.extensionAPI.wixCode.getEditedGridAppId()
    },
    getMetaSiteId() {
        return getMetaSiteId(ps)
    },
    getInstanceId() {
        const wixCodeApp = clientSpecMapUtils.getExistingWixCodeAppFromPS(ps)
        return wixCodeApp ? wixCodeApp.instanceId : ''
    }
})

const extensionAPIGetter = extensionAPI => ({
    getBaseUrl() {
        return extensionAPI.serviceTopology.getServiceRoot([BASE_URL_KEY])
    },
    getAppId() {
        return extensionAPI.wixCode.getEditedGridAppId()
    },
    getMetaSiteId() {
        return extensionAPI.rendererModel.getMetaSiteId()
    },
    getInstanceId() {
        const specMap = extensionAPI.rendererModel.getClientSpecMap()
        const wixCodeApp = _.find(specMap, {type: constants.WIX_CODE_SPEC_TYPE})
        return wixCodeApp ? wixCodeApp.instanceId : ''
    }
})

function getExtendedParams(getter, params) {
    const addedParams = {
        appId: getter.getAppId() || '',
        params: {
            metaSiteId: getter.getMetaSiteId(),
            instanceId: getter.getInstanceId()
        }
    }

    return _.merge({}, addedParams, params)
}

function trace(getter, params) {
    const extendedParams = getExtendedParams(getter, params)
    const baseUrl = getter.getBaseUrl()

    return wixCodeMonitoring.trace(extendedParams, baseUrl)
}

function traceWithPS(ps: PS, params?) {
    return trace(psGetter(ps), params)
}

function traceWithExtensionAPI(extensionAPI, params?) {
    return trace(extensionAPIGetter(extensionAPI), params)
}

export default {
    levels: wixCodeMonitoring.levels,
    trace: traceWithPS,
    traceWithExtensionAPI
}
