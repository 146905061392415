import type {AbsoluteLayout, CompStructure, DSDalBase, Modes, Pointer, Pointers, PS} from '@wix/document-services-types'
import _ from 'lodash'
import documentModeInfo from '../documentMode/documentModeInfo'
import {coreUtils} from '@wix/santa-ds-libs'
const {modesUtils, objectUtils} = coreUtils
import {siteConstants} from '@wix/santa-core-utils'

const MODES_WITH_MANUAL_OVERRIDES: Record<string, boolean> = {}
MODES_WITH_MANUAL_OVERRIDES[siteConstants.COMP_MODES_TYPES.SHOW_ON_SOME_PAGES] = true

function getMasterPageModeDefinitions(ps: PS): any[] {
    const viewMode = documentModeInfo.getViewMode(ps)
    const masterPagePointer = ps.pointers.components.getMasterPage(viewMode)
    const modesPointer = ps.pointers.componentStructure.getModes(masterPagePointer)
    if (!modesPointer) {
        return []
    }
    return _.get(ps.dal.get(modesPointer), 'definitions', [])
}

function isModeOverrideAffective(supportedModeIds: Record<string, string>, override: Record<string, string>) {
    return _.every(override.modeIds, modeId => !!supportedModeIds[modeId])
}

function getCompDefinitions(pointers: Pointers, full: DSDalBase, compPointer: Pointer) {
    const definitionsPointer = pointers.componentStructure.getModesDefinitions(compPointer)
    return full.get(definitionsPointer)
}

function getAllModeIdsOfAncestors(
    pointers: Pointers,
    full: DSDalBase,
    currentAncestorPointer: Pointer
): Record<string, string> {
    let modeIdsInAncestors: Record<string, string> = {}
    while (currentAncestorPointer !== null) {
        const modeDefinitions = getCompDefinitions(pointers, full, currentAncestorPointer)
        const modeIdsOfComp: string[] = _.map(modeDefinitions, 'modeId')
        modeIdsInAncestors = _.merge(modeIdsInAncestors, _.zipObject(modeIdsOfComp, modeIdsOfComp))
        currentAncestorPointer = pointers.components.getParent(currentAncestorPointer)
    }
    return modeIdsInAncestors
}

function getRootActiveModesSet(pointers: Pointers, displayedJsonDal: DSDalBase, rootId: string) {
    const activeModesPointer = pointers.general.getActiveModes()
    const activeModes = displayedJsonDal.get(activeModesPointer)
    if (rootId && activeModes[rootId]) {
        return _.omitBy(activeModes[rootId], val => !val)
    }
    return {}
}

function removeUnusedOverridesInComponentTree(
    pointers: Pointers,
    full: DSDalBase,
    fullCompStructure: CompStructure,
    newParentPointer: Pointer
) {
    const res = objectUtils.cloneDeep(fullCompStructure)
    let modeIds = getAllModeIdsOfAncestors(pointers, full, newParentPointer)

    let compsToScan = [res]
    while (!_.isEmpty(compsToScan)) {
        const comp = compsToScan.pop()
        const modes = _.get(comp, 'modes.definitions')
        const modeIdsInComp = _.map(modes, 'modeId')
        modeIds = _.merge(modeIds, _.zipObject(modeIdsInComp, modeIdsInComp))

        const overrides = _.get(comp, 'modes.overrides')
        if (overrides) {
            const affectiveOverrides = _.filter(overrides, _.partial(isModeOverrideAffective, modeIds))
            _.set(comp, 'modes.overrides', affectiveOverrides)
            if (_.isEmpty(affectiveOverrides)) {
                delete comp.modes.isHiddenByModes
            }
        }

        compsToScan = compsToScan.concat(comp.components || [])
    }

    return res
}

function getSOSPModes(ps: PS) {
    const masterPageModes = getMasterPageModeDefinitions(ps)
    return _.filter(masterPageModes, {type: siteConstants.COMP_MODES_TYPES.SHOW_ON_SOME_PAGES})
}

function getSospModeByPagesGroup(ps: PS, pagesGroupPointer: Pointer) {
    if (!ps.dal.isExist(pagesGroupPointer)) {
        return undefined
    }
    const sospModes = getSOSPModes(ps)
    return _.find(sospModes, ['settings.pagesGroupId', `#${pagesGroupPointer.id}`])
}

function createEmptyModesObject(): Modes {
    return {
        isHiddenByModes: false,
        definitions: [],
        overrides: []
    }
}

function getFirstAncestorWithModesToGenerateOverrides(
    pointers: Pointers,
    full: DSDalBase,
    displayedJsonDal: DSDalBase,
    compPointer: Pointer,
    isSelfIncluded: boolean
) {
    if (!compPointer) {
        return null
    }

    let ancestorPointer = isSelfIncluded ? compPointer : pointers.full.components.getParent(compPointer)
    while (ancestorPointer) {
        if (!_.isEmpty(getComponentModesToGenerateOverrides(pointers, full, displayedJsonDal, ancestorPointer))) {
            return ancestorPointer
        }
        ancestorPointer = pointers.components.getParent(ancestorPointer)
    }

    return null
}

function getFirstAncestorModeToGenerateOverrides(
    pointers: Pointers,
    full: DSDalBase,
    displayedJsonDal: DSDalBase,
    compPointer: Pointer,
    isSelfIncluded?: boolean
) {
    const ancestorWithActiveModes = getFirstAncestorWithModesToGenerateOverrides(
        pointers,
        full,
        displayedJsonDal,
        compPointer,
        isSelfIncluded
    )
    if (ancestorWithActiveModes) {
        return _.head(getComponentModesToGenerateOverrides(pointers, full, displayedJsonDal, ancestorWithActiveModes))
    }
    return null
}

function createModesObjectForCompAddedInMode(activeModes: string[], existingDefaultLayout: AbsoluteLayout) {
    const modes = createEmptyModesObject()
    modes.isHiddenByModes = true
    modes.overrides.push({
        modeIds: activeModes,
        isHiddenByModes: false,
        layout: existingDefaultLayout
    })
    return modes
}

function getComponentModesToGenerateOverrides(
    pointers: Pointers,
    full: DSDalBase,
    displayedJsonDal: DSDalBase,
    compPointer: Pointer
) {
    const containingRootPointer = pointers.components.getPageOfComponent(compPointer)
    if (containingRootPointer) {
        const rootActiveModes = getRootActiveModesSet(pointers, displayedJsonDal, containingRootPointer.id)
        const compModesDefPointer = pointers.componentStructure.getModesDefinitions(compPointer)

        const modesToGenerateOverrides = _.reject(
            full.get(compModesDefPointer),
            modeDef => MODES_WITH_MANUAL_OVERRIDES[modeDef.type]
        )

        return _.keys(modesUtils.getActiveComponentModeIds(rootActiveModes, modesToGenerateOverrides))
    }

    return []
}

function adjustModesToNewContainer(
    pointers: Pointers,
    full: DSDalBase,
    displayedJsonDal: DSDalBase,
    newContainerPointer: Pointer,
    compStructure: CompStructure
) {
    compStructure = removeUnusedOverridesInComponentTree(pointers, full, compStructure, newContainerPointer)
    const ancestorActiveMode =
        getFirstAncestorModeToGenerateOverrides(pointers, full, displayedJsonDal, newContainerPointer) ??
        _.head(getComponentModesToGenerateOverrides(pointers, full, displayedJsonDal, newContainerPointer))

    if (ancestorActiveMode) {
        const initializedModes = createModesObjectForCompAddedInMode(
            [ancestorActiveMode],
            compStructure.layout as AbsoluteLayout
        )
        if (compStructure.modes) {
            compStructure.modes.isHiddenByModes = true
            compStructure.modes.overrides = compStructure.modes.overrides || []
            const matchingOverride = _.find(compStructure.modes.overrides, override =>
                _.isEqual(override.modeIds, [ancestorActiveMode])
            )
            if (matchingOverride) {
                matchingOverride.isHiddenByModes = false
            } else {
                compStructure.modes.overrides.push(initializedModes.overrides[0])
            }
        } else {
            compStructure.modes = initializedModes
        }
    }

    return compStructure
}

export default {
    adjustModesToNewContainer,
    getSospModeByPagesGroup,
    getSOSPModes
}
