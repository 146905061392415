import type {CreateExtArgs, CreateExtensionArgument, DmApis, Extension, ExtensionAPI} from '@wix/document-manager-core'
import _ from 'lodash'
import {ReportableError} from '@wix/document-manager-utils'
import {createPinToStickyPrivateAPI} from './pinToSticky/createPinToStickyPrivateAPI'

export type ResponsiveFixersAPI = ExtensionAPI & {responsiveFixers: {pinToSticky: {migratePage(pageId: string): void}}}

const createExtension = ({}: CreateExtensionArgument): Extension => {
    const createExtensionAPI = ({coreConfig, extensionAPI, pointers, dal}: CreateExtArgs): ResponsiveFixersAPI => {
        const {logger} = coreConfig
        return {
            responsiveFixers: {
                pinToSticky: {
                    migratePage: (pageId: string) => {
                        try {
                            const pinToStickyAPI = createPinToStickyPrivateAPI(extensionAPI, pointers, dal)
                            const pagePointer = pointers.getPointer(pageId, 'DESKTOP')
                            const pageBreakpoints = pinToStickyAPI.getSortedPageBreakpoints(pagePointer.id)
                            const allPinnedSectionsInPage = pinToStickyAPI.getAllPinnedSectionsInPage(pagePointer)

                            if (
                                _.isEmpty(allPinnedSectionsInPage) ||
                                pinToStickyAPI.hasMissingTrackInPageGrid(pagePointer)
                            ) {
                                return
                            }

                            for (const pinnedSection of allPinnedSectionsInPage) {
                                const sectionPosition = pinToStickyAPI.getPinnedSectionPosition(pinnedSection)

                                const shouldMigrateToSticky = pinToStickyAPI.shouldMigrateToSticky(
                                    pinnedSection,
                                    pagePointer,
                                    sectionPosition
                                )

                                pinToStickyAPI.countPinToStickyOverrides(
                                    pagePointer,
                                    sectionPosition,
                                    shouldMigrateToSticky
                                )

                                // actual migration
                                pinToStickyAPI.setComponentLayoutToSection(
                                    shouldMigrateToSticky,
                                    pinnedSection,
                                    pageBreakpoints
                                )
                                pinToStickyAPI.migrateSectionToPinnedOrSticky(
                                    pinnedSection,
                                    pagePointer,
                                    pageBreakpoints,
                                    sectionPosition,
                                    shouldMigrateToSticky
                                )
                            }
                            //after migration
                            pinToStickyAPI.updatePinToStickyNotifications()
                        } catch (e: any) {
                            logger.captureError(
                                new ReportableError({
                                    errorType: 'pinToStickyMigrationError',
                                    message: e.message,
                                    extras: {pageId}
                                })
                            )
                            throw e
                        }
                    }
                }
            }
        }
    }

    const createPublicAPI = ({extensionAPI}: DmApis) => {
        const {responsiveFixers} = extensionAPI as ResponsiveFixersAPI
        return {
            responsiveFixers
        }
    }

    return {
        name: 'responsiveFixers',
        createExtensionAPI,
        createPublicAPI
    }
}

export {createExtension}
