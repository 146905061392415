import _ from 'lodash'
import type {DalJsNamespace, DalJsStore} from '@wix/document-manager-core'
import type {CompStructure} from '@wix/document-services-types'

const getColorChar = (index: number) => String.fromCodePoint(0x1f7e0 + index)
class InternalRefContext {
    private refIndex: number
    private map: Record<string, number>
    constructor() {
        this.refIndex = 0
        this.map = {}
    }

    set(rootCompId: string) {
        return getColorChar((this.map[rootCompId] = this.map[rootCompId] ?? this.refIndex++))
    }

    get(rootCompId: string) {
        return getColorChar(this.map[rootCompId])
    }

    has(rootCompId: string) {
        return this.map.hasOwnProperty(rootCompId)
    }
}

class PageOrder {
    private visited: Record<string, number>
    private order: CompStructure[] = []
    constructor() {
        this.visited = {}
        this.order = []
    }

    build(structureNS: DalJsNamespace, id: string, depth: number = 0) {
        const component = structureNS[id]
        if (!component || this.visited.hasOwnProperty(id)) {
            return
        }

        this.order.push(component as CompStructure)
        this.visited[component.id!] = depth
        if (component.components) {
            component.components.forEach((childId: string) => this.build(structureNS, childId, depth + 1))
        }
    }

    getOrder() {
        return this.order
    }

    getDepth(id: string) {
        return this.visited[id]
    }
}

const createSubscriptIndex = (index: number, digits: number = 2) =>
    Number(index)
        .toLocaleString('en-US', {minimumIntegerDigits: digits, useGrouping: false})
        .split('')
        .map(s => String.fromCharCode(0x2080 + Number(s)))
        .join('')

const processPage = (store: DalJsStore, refCtx: InternalRefContext, structureNS: string, pageId: string) => {
    const {data} = store
    const structure = store[structureNS]
    const pageOrder = new PageOrder()
    pageOrder.build(structure, pageId)

    const componentsOrder = pageOrder.getOrder()
    const prefixLength = String(componentsOrder.length).length
    return _.reduce(
        componentsOrder,
        (result, component, index) => {
            const id = component.id!
            const dataQuery = component.dataQuery ?? ''
            const compData = data[dataQuery.replace('#', '')]!
            let displayedId = `${createSubscriptIndex(index, prefixLength)}\u00A0${'|\u00A0'.repeat(
                pageOrder.getDepth(id)
            )}${id}`
            if (_.get(compData, ['type']) === 'InternalRef') {
                displayedId += refCtx.set(compData.rootCompId)
            }
            if (refCtx.has(id)) {
                displayedId += refCtx.get(id)
            }

            //Make 'type' first key of the object
            result[displayedId] = _.assign(
                {componentType: component.componentType},
                _.omit(component, ['componentType'])
            )
            return result
        },
        {}
    )
}

const processStructure = (store: DalJsStore, refCtx: InternalRefContext, structureNS: string) => {
    const pagesResult = _(store[structureNS])
        .pickBy(component => component?.type === 'Page')
        .mapValues((page, pageId) => processPage(store, refCtx, structureNS, pageId))
        .value()

    pagesResult.masterPage = processPage(store, refCtx, structureNS, 'masterPage')
    return pagesResult
}

export const organise = (store: DalJsStore) => {
    const refCtx = new InternalRefContext()
    const friendlyDesktop = processStructure(store, refCtx, 'DESKTOP')
    const friendlyMobile = processStructure(store, refCtx, 'MOBILE')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {DESKTOP, MOBILE, ...otherNamespaces} = store
    return {
        DESKTOP: friendlyDesktop,
        MOBILE: friendlyMobile,
        ...otherNamespaces
    }
}
