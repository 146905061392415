export const sdkTypeByViewerType = {
    'wysiwyg.viewer.components.SiteButton': 'Button',
    'wysiwyg.viewer.components.PopupCloseTextButton': 'Button',
    'wysiwyg.viewer.components.PopupCloseIconButton': 'Button',
    'wysiwyg.viewer.components.ClassicSection': 'Section',
    'wysiwyg.common.components.imagebutton.viewer.ImageButton': 'Button',
    'wysiwyg.viewer.components.WPhoto': 'Image',
    'wixui.ImageX': 'Image',
    'wysiwyg.viewer.components.FooterContainer': 'Footer',
    'wysiwyg.viewer.components.HeaderContainer': 'Header',
    'wysiwyg.viewer.components.WRichText': 'Text',
    'mobile.core.components.Page': 'Page',
    'wysiwyg.viewer.components.inputs.TextInput': 'TextInput',
    'wysiwyg.viewer.components.inputs.TextAreaInput': 'TextBox',
    'wysiwyg.viewer.components.inputs.DatePicker': 'DatePicker',
    'wysiwyg.viewer.components.BoxSlideShow': 'Slideshow',
    'wysiwyg.viewer.components.StripContainerSlideShow': 'Slideshow',
    'wysiwyg.viewer.components.StateBox': 'MultiStateBox',
    'wysiwyg.viewer.components.StateStrip': 'MultiStateBox',
    'wysiwyg.viewer.components.SlideShowGallery': 'Gallery',
    'wysiwyg.viewer.components.SliderGallery': 'Gallery',
    'wysiwyg.viewer.components.MatrixGallery': 'Gallery',
    'wysiwyg.viewer.components.PaginatedGridGallery': 'Gallery',
    'mobile.core.components.Container': 'Box',
    'wysiwyg.viewer.components.Group': 'Box',
    'wysiwyg.viewer.components.FormContainer': 'Form',
    'platform.components.AppController': 'AppController',
    'wysiwyg.viewer.components.svgshape.SvgShape': 'VectorImage',
    'wysiwyg.viewer.components.VectorImage': 'VectorImage',
    'wysiwyg.viewer.components.inputs.Checkbox': 'Checkbox',
    'wixui.RichTextBox': 'RichTextBox',
    'wixui.ToggleSwitch': 'Switch',
    'wixui.AddressInput': 'AddressInput',
    'wysiwyg.viewer.components.inputs.RadioGroup': 'RadioButtonGroup',
    'wysiwyg.viewer.components.inputs.CheckboxGroup': 'CheckboxGroup',
    'wysiwyg.viewer.components.BoxSlideShowSlide': 'Slide',
    'wysiwyg.viewer.components.StripContainerSlideShowSlide': 'Slide',
    'wysiwyg.viewer.components.StateBoxState': 'State',
    'wysiwyg.viewer.components.StateBoxFormState': 'State',
    'wysiwyg.viewer.components.StateStripState': 'State',
    'wysiwyg.viewer.components.inputs.ComboBoxInput': 'Dropdown',
    'wysiwyg.viewer.components.Column': 'Column',
    'wysiwyg.viewer.components.StripColumnsContainer': 'ColumnStrip',
    'wysiwyg.viewer.components.Grid': 'Table',
    'wysiwyg.viewer.components.inputs.FileUploader': 'UploadButton',
    'wysiwyg.common.components.anchor.viewer.Anchor': 'Anchor',
    'wysiwyg.viewer.components.tpapps.TPASection': 'IFrame',
    'wysiwyg.viewer.components.tpapps.TPAMultiSection': 'IFrame',
    'wysiwyg.viewer.components.tpapps.TPAGluedWidget': 'IFrame',
    'wysiwyg.viewer.components.HtmlComponent': 'HtmlComponent',
    'wysiwyg.viewer.components.GoogleMap': 'GoogleMap',
    'wysiwyg.viewer.components.Video': 'Video',
    'wysiwyg.viewer.components.HoverBox': 'HoverBox',
    'wysiwyg.viewer.components.Popover': 'Popover',
    'wysiwyg.viewer.components.MenuContainer': 'MenuContainer',
    'tpa.viewer.components.Freestyle': 'Gallery',
    'tpa.viewer.components.Honeycomb': 'Gallery',
    'tpa.viewer.components.Accordion': 'Gallery',
    'wysiwyg.viewer.components.tpapps.TPA3DCarousel': 'Gallery',
    'wysiwyg.viewer.components.tpapps.TPA3DGallery': 'Gallery',
    'tpa.viewer.components.Impress': 'Gallery',
    'tpa.viewer.components.Masonry': 'Gallery',
    'tpa.viewer.components.Collage': 'Gallery',
    'tpa.viewer.components.StripSlideshow': 'Gallery',
    'tpa.viewer.components.StripShowcase': 'Gallery',
    'tpa.viewer.components.Thumbnails': 'Gallery',
    'wysiwyg.viewer.components.tpapps.TPAWidget': 'IFrame',
    'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu': 'Menu',
    'wysiwyg.viewer.components.menus.DropDownMenu': 'Menu',
    'wysiwyg.viewer.components.LoginSocialBar': 'AccountNavBar',
    'wysiwyg.viewer.components.Repeater': 'Repeater',
    'wysiwyg.viewer.components.MediaContainer': 'Container',
    'wysiwyg.viewer.components.RichContainer': 'RichContainer',
    'wysiwyg.viewer.components.mobile.TinyMenu': 'MobileMenu',
    'wysiwyg.viewer.components.QuickActionBar': 'QuickActionBar',
    'wixui.MusicPlayer': 'AudioPlayer',
    'wixui.BarChart': 'BarChart',
    'wixui.VideoPlayer': 'VideoPlayer',
    'wixui.Pagination': 'Pagination',
    'wixui.Tags': 'Tags',
    'wixui.Slider': 'Slider',
    'wixui.Captcha': 'Captcha',
    'wixui.TimePicker': 'TimePicker',
    'wixui.RatingsDisplay': 'RatingsDisplay',
    'wixui.RatingsInput': 'RatingsInput',
    'wixui.SearchBox': 'SearchBox',
    'platform.components.AppWidget': 'AppWidget',
    'wysiwyg.viewer.components.tpapps.TPAWidgetNative': 'Native',
    'wysiwyg.viewer.components.MediaBox': 'MediaBox',
    'wysiwyg.viewer.components.MediaPlayer': 'VideoBox',
    'wysiwyg.viewer.components.MediaPlayerControls': 'VideoBoxMute',
    'wysiwyg.viewer.components.MediaPlayerOverlayControls': 'VideoBoxBigPlay',
    'wixui.ProgressBar': 'ProgressBar',
    'wixui.SignatureInput': 'SignatureInput',
    'wixui.CustomElementComponent': 'CustomElement',
    'wixui.StylableButton': 'Button',
    'wixui.SelectionTagsList': 'SelectionTags',
    'wixui.StylableHorizontalMenu': 'Menu',
    'wixui.MultiStateBox': 'MultiStateBox',
    'wixui.ProGallery': 'Gallery',
    'wixui.CollapsibleText': 'CollapsibleText',
    'wixui.RangeSlider': 'RangeSlider',
    'wixui.Breadcrumbs': 'Breadcrumbs',
    'wixui.LottieEmbed': 'LottieEmbed',
    'wixui.SlotsPlaceholder': 'SlotsPlaceholder',
    'wixui.ExternalComponent': 'ReactComponent'
}

export const sdkTypeByWidgetId = {
    '142bb34d-3439-576a-7118-683e690a1e0d': 'Gallery',
    'aa86b56d-3c49-44fd-9976-963695e7815d': 'RichContent',
    '1440e92d-47d8-69be-ade7-e6de40127106': 'WixEvents',
    '371ee199-389c-4a93-849e-e35b8a15b7ca': 'WixFormsV2'
}
export const defaultSdkType = 'HiddenCollapsedElement'
const getSdkType = (viewerType, tpaWidgetId) => {
    return sdkTypeByWidgetId[tpaWidgetId] || sdkTypeByViewerType[viewerType] || defaultSdkType
}

export const getFullSdkType = (viewerType, tpaWidgetId) => {
    return `$w.${getSdkType(viewerType, tpaWidgetId)}`
}
