import type {SiteAPI} from '@wix/document-services-types'
import _ from 'lodash'
import screenWidthPlugin from './screenWidthPlugin'

const HORIZONTAL_LINE_MIN_HEIGHT = 25

function pluginFn(siteAPI: SiteAPI, compLayoutRelativeToStructure, compProperties) {
    const pluginResult: any = {}

    if (compLayoutRelativeToStructure.height < HORIZONTAL_LINE_MIN_HEIGHT) {
        pluginResult.y = compLayoutRelativeToStructure.y - HORIZONTAL_LINE_MIN_HEIGHT / 2 // eslint-disable-line no-mixed-operators
        pluginResult.height = HORIZONTAL_LINE_MIN_HEIGHT
    }

    if (compProperties?.fullScreenModeOn) {
        _.merge(pluginResult, screenWidthPlugin(siteAPI, compLayoutRelativeToStructure))
    }

    return pluginResult
}

export default pluginFn
