import type {PublicMethodUtils} from '@wix/document-services-types'
import {documentMode, constants} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    const wrapWithDisableLogging =
        (api: Function) =>
        (func: Function, options: any = {}) =>
            api(func, {disableLogInteraction: true, ...options})

    const {
        defineGetter,
        actions: {immediate, dataManipulation}
    } = publicMethodUtils

    const dontCare = wrapWithDisableLogging(publicMethodUtils.actions.dontCare)
    const enforcingOnly = wrapWithDisableLogging(publicMethodUtils.actions.enforcingOnly)

    return {
        methods: {
            documentMode: {
                runtime: {
                    reset: dontCare(documentMode.runtime.reset)
                },
                enablePlaying: dontCare(documentMode.enablePlaying),
                enablePageProtection: dontCare(documentMode.enablePageProtection),
                enableZoom: dontCare(documentMode.enableZoom),
                enableSocialInteraction: dontCare(documentMode.enableSocialInteraction),
                enableExternalNavigation: dontCare(documentMode.enableExternalNavigation),
                enableTinyMenuOpening: dontCare(documentMode.enableTinyMenuOpening),
                enableBackToTopButton: dontCare(documentMode.enableBackToTopButton),
                toggleWixAds: dontCare(documentMode.enableWixAds),
                enableSlideShowGalleryClick: dontCare(documentMode.enableSlideShowGalleryClick),
                enableAction: dontCare(documentMode.enableAction),
                getSiteTransformScale: defineGetter(documentMode.getSiteTransformScale),
                enableTransFormScaleOnSite: dontCare(documentMode.enableTransFormScaleOnSite),
                resetBehaviors: immediate(documentMode.resetBehaviorsRegistration),
                enablePageTransitions: immediate(documentMode.enablePageTransitions),
                enableComponentTransitions: immediate(documentMode.enableComponentTransitions),
                enableRenderTPAsIframe: dontCare(documentMode.enableRenderTPAsIframe),
                enableRenderFixedPositionContainers: dontCare(documentMode.enableRenderFixedPositionContainers),
                enableRenderFixedPositionBackgrounds: dontCare(documentMode.enableRenderFixedPositionBackgrounds),
                enableSiteMembersDialogsOpening: dontCare(documentMode.allowSiteMembersDialogsOpening),
                enableResetGalleryToOriginalState: dontCare(documentMode.enableResetGalleryToOriginalState),
                enableResetComponent: dontCare(documentMode.enableResetComponent),
                getExtraSiteHeight: defineGetter(documentMode.getExtraSiteHeight),
                setExtraSiteHeight: dontCare(documentMode.setExtraSiteHeight),
                enableShouldUpdateJsonFromMeasureMap: dontCare(documentMode.enableShouldUpdateJsonFromMeasureMap),
                allowSiteOverflow: dontCare(documentMode.allowSiteOverflow),
                setComponentVisibility: dontCare(documentMode.setComponentVisibility),
                getComponentViewMode: defineGetter(documentMode.getComponentViewMode),
                setComponentViewMode: dontCare(documentMode.setComponentViewMode),
                allowShowingFixedComponents: dontCare(documentMode.allowShowingFixedComponents),
                setShouldKeepChildrenInPlace: dontCare(documentMode.setShouldKeepChildrenInPlace),
                enablePageAutoGrowForDetachedComponents: dontCare(documentMode.enablePageAutoGrowForDetachedComponents),
                setPreserveCompLayoutOnReparent: dontCare(documentMode.setPreserveCompLayoutOnReparent),
                showHiddenComponents: dontCare(documentMode.showHiddenComponents),
                isHiddenComponentsEnabled: defineGetter(documentMode.isHiddenComponentsEnabled),
                isFetchDynamicPageInnerRoutesEnabled: defineGetter(documentMode.isFetchDynamicPageInnerRoutesEnabled),
                isShowingFixedComponentsEnabled: defineGetter(documentMode.isShowingFixedComponentsEnabled),
                setComponentPreviewState: dontCare(documentMode.setComponentPreviewState),
                getComponentPreviewState: defineGetter(documentMode.getComponentPreviewState),
                ignoreComponentsHiddenProperty: dontCare(documentMode.ignoreComponentsHiddenProperty),
                ignoreComponentsCollapsedProperty: dontCare(documentMode.ignoreComponentsCollapsedProperty),
                allowWixCodeInitialization: dontCare(documentMode.allowWixCodeInitialization),
                showControllers: dontCare(documentMode.showControllers),
                isControllersVisibilityEnabled: defineGetter(documentMode.isControllersVisibilityEnabled),
                onboardingViewportMode: dontCare(documentMode.setOnboardingViewportMode),
                isContactFormMigrationMode: defineGetter(documentMode.isContactFormMigrationMode),
                updateAnimationsViewMode: dontCare(documentMode.updateAnimationsViewMode),
                getImageTargetScale: defineGetter(documentMode.getImageTargetScale),
                setImageTargetScale: dontCare(documentMode.setImageTargetScale),
                enableNativeComponentsForPreview: dontCare(documentMode.enableNativeComponentsForPreview),
                setRenderScrollSnap: dontCare(documentMode.setRenderScrollSnap),
                setRenderSticky: dontCare(documentMode.setRenderSticky),
                getCompsToRenderSticky: defineGetter(documentMode.getCompsToRenderSticky),
                setCompsToRenderSticky: dontCare(documentMode.setCompsToRenderSticky),
                getTransparentComponentsOverlay: defineGetter(documentMode.getTransparentComponentsOverlay),
                setTransparentComponentsOverlay: dontCare(documentMode.setTransparentComponentsOverlay)
            },

            deviceType: {
                set: dataManipulation(documentMode.setDeviceType),
                get: defineGetter(documentMode.getDeviceType)
            },
            /**
             * @class documentServices.viewMode
             */
            viewMode: {
                /**
                 * Sets the document's view mode (mobile or desktop)- sets the view that's rendered, regardless of the displaying device
                 *  @param {String} viewMode - MOBILE or DESKTOP
                 */
                set: enforcingOnly(documentMode.setViewMode, {
                    noBatching: true,
                    /**
                     * @param {ps} ps
                     * @param {string} viewMode
                     * @return {{working_mode_to: string}}
                     */
                    getInteractionParams: documentMode.getSetViewModeInteractionParams
                }),
                /**
                 *  Returns document's view mode (mobile or desktop)- i.e the view that's rendered, regardless of the displaying device
                 *  @returns {string} specifying MOBILE or DESKTOP
                 */
                get: defineGetter(documentMode.getViewMode),
                VIEW_MODES: constants.VIEW_MODES
            }
        }
    }
}
