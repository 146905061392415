import type {PS} from '@wix/document-services-types'

const SEO_ERRORS = {
    SE_ENABLE_INDEX_PARAM_IS_NOT_BOOLEN: 'SE_ENABLE_INDEX_PARAM_IS_NOT_BOOLEN',
    TEXT_IS_NOT_STRING: 'TEXT_IS_NOT_STRING',
    TEXT_TOO_LONG: 'TEXT_TOO_LONG',
    TEXT_INVALID_CHARS: 'TEXT_INVALID_CHARS',
    KEYWORDS_INVALID_CHARS: 'KEYWORDS_INVALID_CHARS',
    METATAGS_INVALID_FORMAT: 'METATAGS_INVALID_FORMAT',
    METATAGS_INVALID_CHARS: 'METATAGS_INVALID_CHARS',
    METATAGS_SERVER_INVALID_CODE: 'METATAGS_SERVER_INVALID_CODE',
    METATAGS_SERVER_INVALID_TAG: 'METATAGS_SERVER_INVALID_TAG',
    REDIRECT_INVALID_CHARS: 'REDIRECT_INVALID_CHARS',
    REDIRECT_URI_MAPPING_IS_NOT_OBJECT: 'REDIRECT_URI_MAPPING_IS_NOT_OBJECT',
    REDIRECT_FROM_URIS_IS_NOT_ARRAY: 'REDIRECT_FROM_URIS_IS_NOT_ARRAY',
    REDIRECT_MAPPING_INVALID_FORMAT: 'REDIRECT_MAPPING_INVALID_FORMAT',
    REDIRECT_MAPPING_URIS_NOT_STRING: 'REDIRECT_MAPPING_URIS_NOT_STRING',
    SERVER_VALIDATION_TIMEOUT: 'SERVER_VALIDATION_TIMEOUT'
}

const seoExtensionWrapper = {
    ERRORS: SEO_ERRORS,
    indexing: {
        enable: (ps: PS, isEnabled) => ps.extensionAPI.seo.indexing.enable(isEnabled),
        isEnabled: (ps: PS) => ps.extensionAPI.seo.indexing.isEnabled
    },
    title: {
        set: (ps: PS, title) => ps.extensionAPI.seo.title.set(title),
        get: ps => ps.extensionAPI.seo.title.get(),
        validate: (ps: PS, title) => ps.extensionAPI.seo.title.validate(title)
    },
    description: {
        set: (ps: PS, description) => ps.extensionAPI.seo.description.set(description),
        get: (ps: PS) => ps.extensionAPI.seo.description.get(),
        validate: (ps: PS, description) => ps.extensionAPI.seo.description.validate(description)
    },
    keywords: {
        set: (ps: PS, keywords) => ps.extensionAPI.seo.keywords.set(keywords),
        get: (ps: PS) => ps.extensionAPI.seo.keywords.get(),
        validate: (ps: PS, keywords) => ps.extensionAPI.seo.keywords.validate(keywords)
    },
    headTags: {
        set: (ps: PS, metaTags, onSuccess, onError) => ps.extensionAPI.seo.headTags.set(metaTags, onSuccess, onError),
        get: (ps: PS) => ps.extensionAPI.seo.headTags.get(),
        validate: (ps: PS, metaTags, onSuccess, onError) =>
            ps.extensionAPI.seo.headTags.validate(metaTags, onSuccess, onError),
        clientSideValidation: (ps: PS, metaTags, onSuccess, onError) =>
            ps.extensionAPI.seo.headTags.clientSideValidation(metaTags, onSuccess, onError)
    },
    redirectUrls: {
        update: (ps: PS, redirectUrls) => ps.extensionAPI.seo.redirectUrls.update(redirectUrls),
        remove: (ps: PS, redirectUrls) => ps.extensionAPI.seo.redirectUrls.remove(redirectUrls),
        get: (ps: PS) => ps.extensionAPI.seo.redirectUrls.get(),
        validate: (ps: PS, redirectUrls) => ps.extensionAPI.seo.redirectUrls.validate(redirectUrls)
    }
}

export default seoExtensionWrapper
