import type {PagesData, PagesStructure, SingleLayoutData, StyleRefOrStyleRefs} from '@wix/document-services-types'

const getDefaultPageLayouts = (): Partial<SingleLayoutData> => ({
    type: 'SingleLayoutData',
    componentLayout: {
        type: 'ComponentLayout',
        height: {
            type: 'auto'
        }
    },
    containerLayout: {},
    itemLayout: {}
})

const getDefaultPageBreakpoints = () =>
    ({
        type: 'BreakpointsData',
        values: [
            {
                type: 'BreakpointRange',
                id: '',
                min: 320,
                max: 1000
            },
            {
                type: 'BreakpointRange',
                id: '',
                min: 320,
                max: 750
            }
        ]
    } satisfies PagesStructure['breakpoints'])

const getDefaultPageStyle = (): StyleRefOrStyleRefs => ({
    type: 'ComponentStyle',
    style: {
        properties: {
            'alpha-bg': '1',
            bg: 'color_11'
        },
        propertiesSource: {
            'alpha-bg': 'value',
            bg: 'value'
        },
        groups: {}
    },
    componentClassName: '',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.page.ResponsivePageWithColorBG'
})

const getDefaultPageData = (): Partial<PagesData> => ({
    type: 'Page',
    metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: ''
    },
    title: 'Blank',
    hideTitle: true,
    icon: '',
    descriptionSEO: '',
    metaKeywordsSEO: '',
    pageTitleSEO: '',
    pageUriSEO: '',
    hidePage: false,
    underConstruction: false,
    pageBackgrounds: undefined
})

const getDefaultPageLayout = () => ({
    x: 0,
    y: 0,
    width: 980,
    height: 500,
    anchors: []
})

const getDefaultResponsivePageSkin = () => 'wysiwyg.viewer.skins.page.TransparentPageSkin'

const getDefaultClassicPageSkin = () => 'skins.core.InlineSkin'

const getDefaultPageBasicDefinition = (): Partial<PagesStructure> => ({
    componentType: 'mobile.core.components.Page',
    type: 'Page',
    id: 'mainPage'
})

const getDefaultResponsiveBlankPageDefinition = (): Partial<PagesStructure> => ({
    ...getDefaultPageBasicDefinition(),
    skin: getDefaultResponsivePageSkin(),
    components: [],
    layouts: getDefaultPageLayouts(),
    style: getDefaultPageStyle(),
    breakpoints: getDefaultPageBreakpoints(),
    layout: getDefaultPageLayout(),
    data: getDefaultPageData()
})

const getDefaultClassicBlankPageDefinition = () => ({
    ...getDefaultPageBasicDefinition(),
    styleId: 'p2',
    skin: getDefaultClassicPageSkin(),
    components: [],
    layout: getDefaultPageLayout(),
    data: getDefaultPageData()
})

export {getDefaultResponsiveBlankPageDefinition, getDefaultClassicBlankPageDefinition, getDefaultPageStyle}
