import _ from 'lodash'

export const capitalize = (string: string, lowerCaseElse: boolean) => {
    return _.upperFirst(lowerCaseElse ? string.toLowerCase() : string)
}

export const startsWith = (string: string, searchString: string, ignoreCase: boolean): boolean => {
    if (!string) {
        return false
    }
    if (ignoreCase) {
        return startsWith(string.toLowerCase(), searchString.toLowerCase(), false)
    }
    return _.startsWith(string, searchString)
}

export const endsWith = (string: string, stringEnd: string, ignoreCase: boolean): boolean => {
    if (!string) {
        return false
    }
    if (ignoreCase) {
        return endsWith(string.toLowerCase(), stringEnd.toLowerCase(), false)
    }
    return _.endsWith(string, stringEnd)
}

export const isNullOrEmpty = (string: string) => {
    return !string?.trim()
}

export const isTrue = (string: string) => {
    return string === 'true'
}
