import type {PS} from '@wix/document-services-types'
import textThemes from '../textThemes/textThemes'
import _ from 'lodash'
import {coreUtils} from '@wix/santa-ds-libs'

const {fontUtils} = coreUtils
const {fontStringToTextTheme, textThemeToFontString} = fontUtils

function setToTextTheme(ps: PS, index: number, val: string) {
    const textTheme = fontStringToTextTheme(val)
    textThemes.set(ps, index, textTheme)
}

function getFromTextTheme(ps: PS, index: number) {
    const textTheme = textThemes.get(ps, index)
    if (!textTheme) {
        return
    }
    return textThemeToFontString(textTheme)
}

function getAllFromTextTheme(ps: PS) {
    const allTextThemes = textThemes.getAll(ps)
    return _.map(allTextThemes, textThemeToFontString)
}

export default {
    set: setToTextTheme,
    get: getFromTextTheme,
    getAll: getAllFromTextTheme
}
