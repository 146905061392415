import _ from 'lodash'
import type {DeepStructure, StructureHandler, SetCompProperty} from '../../types'
import {getChildren} from '../conversionUtils'
import {conversionUtils} from '../mobileCore'

export class StructureManager implements StructureHandler {
    public static create(mobileStructure) {
        const instance = new StructureManager()
        instance.initialize(component => _.set(component, ['conversionData', 'structureManager'], instance))
        instance.addToStructure(undefined, mobileStructure)
        return instance
    }

    private readonly parentsMap: Record<string, string>
    private setToComponent: SetCompProperty

    constructor() {
        this.parentsMap = {}
    }
    initialize(setToComponent: SetCompProperty) {
        this.setToComponent = setToComponent
    }
    addToStructure(container: DeepStructure | undefined, component: DeepStructure) {
        this.setToComponent(component)
        this.parentsMap[component.id] = container?.id
        _.forEach(getChildren(component), child => this.addToStructure(component, child))
    }

    cleanComponent(component: DeepStructure) {
        const [realChildren, duplicateChildren] = _.partition(getChildren(component), child => this.parentsMap[child.id] === component.id)
        conversionUtils.removeChildrenFrom(component, duplicateChildren)
        _.forEach(realChildren, child => this.cleanComponent(child))
    }

    getRealChildren(component: DeepStructure) {
        const children = getChildren(component)
        _.forEach(children, child => this.cleanComponent(child))
        return children
    }
}
