import type {FlatStructureMap, DeepStructure, CompRef} from '@wix/document-services-types'
import _ from 'lodash'
import type {DalItem} from '@wix/document-manager-core'
import {PAGE_TYPES} from '../constants/constants'

const isPage = (item?: DalItem) => _.includes(PAGE_TYPES, item?.type)

const KEYS_TO_REMOVE_FROM_DEEP_STRUCTURE = ['metaData', 'parent']
const getDeepStructureForComp = (compId: string, flatStructure: FlatStructureMap): DeepStructure => {
    const comp = flatStructure[compId]
    if (!comp.components) {
        return _.omit(comp, KEYS_TO_REMOVE_FROM_DEEP_STRUCTURE) as unknown as DeepStructure
    }
    return {
        ..._.omit(comp, KEYS_TO_REMOVE_FROM_DEEP_STRUCTURE),
        components: _.map(comp.components, id => getDeepStructureForComp(id as string, flatStructure))
    } as DeepStructure
}

const getChildrenRecursivelyRightLeftRootImpl = (
    getChildPointers: (p: CompRef) => CompRef[],
    pointer: CompRef,
    result: CompRef[]
) => {
    const children = getChildPointers(pointer)
    if (Array.isArray(children)) {
        for (const p of children.reverse()) {
            getChildrenRecursivelyRightLeftRootImpl(getChildPointers, p, result)
        }
    }
    result.push(pointer)
}

const getChildrenRecursivelyRightLeftRoot = (getChildPointers: (p: CompRef) => CompRef[], pointer: CompRef) => {
    const result: CompRef[] = []
    getChildrenRecursivelyRightLeftRootImpl(getChildPointers, pointer, result)
    return result
}

export {getChildrenRecursivelyRightLeftRoot, getDeepStructureForComp, isPage}
