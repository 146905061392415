import type {CreateExtArgs} from '@wix/document-manager-core'
import {defaultIsFullWidth, defaultIsFullWidthByStructure, isComponentAContainer} from './utils/componentUtils'
import {getComponentNickname} from './utils/nicknameUtils'
import type {CompStructure} from '@wix/document-services-types'

export const DEFAULTS = {
    canConnectToCode: () => true,
    nickname: getComponentNickname,
    shouldAutoSetNickname: true,
    isRepeatable: true,
    isContainCheckRecursive: true,
    enforceContainerChildLimitsByWidth: true,
    enforceContainerChildLimitsByHeight: true,
    container: isComponentAContainer,
    canContain: isComponentAContainer,
    isPublicContainer: isComponentAContainer,
    fullWidthByStructure: ({}: CreateExtArgs, compStructure: CompStructure) =>
        defaultIsFullWidthByStructure(compStructure),
    fullWidth: defaultIsFullWidth,
    isRepeater: false
}
