import type {PublicMethodUtils} from '@wix/document-services-types'
import {pagesGroup} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            pagesGroup: {
                addPageToPagesGroup: publicMethodUtils.defineDataManipulationAction(pagesGroup.addPageToPagesGroup),
                create: publicMethodUtils.defineDataManipulationAction(pagesGroup.createPagesGroup, {
                    getReturnValue: pagesGroup.getNewPagesGroupId
                }),
                getAll: publicMethodUtils.defineGetter(pagesGroup.getAll),
                getByAppId: publicMethodUtils.defineGetter(pagesGroup.getPagesGroupByAppId),
                getById: publicMethodUtils.defineGetter(pagesGroup.getPagesGroupById),
                getByGroupName: publicMethodUtils.defineGetter(pagesGroup.getPagesGroupByGroupName),
                getComponentPagesGroup: publicMethodUtils.defineGetter(pagesGroup.getComponentPagesGroup),
                remove: publicMethodUtils.defineDataManipulationAction(pagesGroup.removePagesGroup),
                removePageFromPagesGroup: publicMethodUtils.defineDataManipulationAction(
                    pagesGroup.removePageFromPagesGroup
                ),
                serialize: publicMethodUtils.defineGetter(pagesGroup.serializePagesGroup)
            }
        }
    }
}
