import _ from 'lodash'
import vectorImageMetaData from './vectorImageMetaData'

export default _.defaults(
    {
        hiddenable: false,
        moveDirections: []
    },
    vectorImageMetaData
)
