import type {PS} from '@wix/document-services-types'
import consts from '../../constants/constants'
import siteGapMap from '../../structure/siteGapMap'
import layoutSettingsUtils from '../../structure/utils/layoutSettingsUtils'

export default {
    anchors: {
        to: {allow: true, allowBottomBottom: false, lock: consts.ANCHORS.LOCK_CONDITION.THRESHOLD},
        from: {allow: true, lock: consts.ANCHORS.LOCK_CONDITION.ALWAYS}
    },
    resizableSides: [],
    moveDirections(ps: PS) {
        const masterPageLayoutSettings = layoutSettingsUtils.getLayoutSettings(ps)
        if (masterPageLayoutSettings.useDesktopSectionsLayout) {
            return []
        }
        const initialSiteGapMap = siteGapMap.getInitialGapMap(ps)
        return initialSiteGapMap.abovePagesContainer >= consts.SITE_SEGMENTS_GAP_THRESHOLD
            ? [consts.MOVE_DIRECTIONS.VERTICAL]
            : []
    },
    removable: false,
    duplicatable: false,
    containable: false,
    fullWidth: false,
    hiddenable: false,
    collapsible: false,
    mobileConversionConfig: {
        marginX: 20,
        siteSegmentRole: 'PAGES_CONTAINER',
        category: 'siteSegments'
    }
}
