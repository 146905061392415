import {ReportableError} from '@wix/document-manager-utils'
import type {CompLayout, Layout, Pointer, PS, MeshData, Size} from '@wix/document-services-types'
import {dockUtils, layoutUtils} from '@wix/santa-core-utils'
import {coreUtils, warmupUtils} from '@wix/santa-ds-libs'
import experiment from 'experiment-amd'
import _ from 'lodash'

const {boundingLayoutUtils} = coreUtils

function getBoundingLayout(ps: PS, layout) {
    return boundingLayoutUtils.getBoundingLayout(layout)
}

function getComponentLayout(ps: PS, compPointer: Pointer) {
    if (!compPointer) {
        return null
    }
    const layout = ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'layout'))
    if (!layout) {
        return null
    }
    if (layout.docked) {
        _.assign(layout, getPositionAndSize(ps, compPointer))
        delete layout.docked
    }

    return _.merge(layout, {bounding: getBoundingLayout(ps, layout)})
}

/**
 * This function is important for 2 reasons:
 * 1. We don't want to recursively get the width up the chain (i.e. our parent is docked left+right)
 * 2. We can't get our own width, since during batched updates in DS, the current value in the measureMap might not be correct (our JSON updated during batch but relayout is only at end of the batch), but the parent's value will still be correct
 * @param ps
 * @param compPointer
 * @param compLayout
 * @returns {Number} Width of the parent, as it is currently rendered
 */
function getParentWidth(ps: PS, compPointer: Pointer, compLayout): number {
    if (compLayout.fixedPosition || dockUtils.isHorizontalDockToScreen(compLayout)) {
        return ps.siteAPI.getSiteMeasureMap().clientWidth
    }
    const parentPointer = ps.pointers.components.getParent(compPointer)
    const measureMap = ps.siteAPI.getSiteMeasureMap()
    return measureMap.width[parentPointer.id]
}

function getParentDimensions(ps: PS, compPointer: Pointer): Size {
    const parentPointer = ps.pointers.components.getParent(compPointer)
    const measureMap = ps.siteAPI.getSiteMeasureMap()
    return {
        width: measureMap.width[parentPointer.id],
        height: measureMap.height[parentPointer.id]
    }
}

/**
 * This function is important for 2 reasons:
 * 1. We don't want to recursively get the height up the chain (i.e. our parent is docked top+bottom)
 * 2. We can't get our own height, since during batched updates in DS, the current value in the measureMap might not be correct (our JSON updated during batch but relayout is only at end of the batch), but the parent's value will still be correct
 *
 * @param ps
 * @param compPointer
 * @param compLayout
 * @returns {Number} Height of the parent, as it is currently rendered
 */
function getParentHeight(ps: PS, compPointer: Pointer, compLayout): number {
    if (compLayout.fixedPosition) {
        return ps.siteAPI.getSiteMeasureMap().clientHeight
    }
    const parentPointer = ps.pointers.components.getParent(compPointer)
    const measureMap = ps.siteAPI.getSiteMeasureMap()
    return measureMap.height[parentPointer.id]
}

/**
 * @param currentPositionAndSize
 * @param newPositionAndSize
 * @returns the difference between the current PositionAndSize and the new one
 */
function getPositionAndSizeDiff(currentPositionAndSize: MeshData, newPositionAndSize: MeshData): MeshData {
    return _.mapValues(currentPositionAndSize, (value, key) =>
        _.isFinite(newPositionAndSize[key]) ? newPositionAndSize[key] - value : 0
    )
}

function isPctUnits(units: string) {
    return _.includes(['vw', 'pct'], units)
}

function applyDiffToUnitsData(dockData, diffInPx, parentInPx) {
    const dockUnits = _.keys(dockData)

    if (_.includes(dockUnits, 'px')) {
        dockData.px += diffInPx
    } else {
        const percentUnits = _.find(dockUnits, isPctUnits)
        dockData[percentUnits] += (diffInPx / parentInPx) * 100
        dockData[percentUnits] = parseFloat(dockData[percentUnits].toFixed(2))
    }
}

function updateHorizontalLayoutForDocked(compLayout, positionAndSizeDiff, parentWidth: number) {
    const {docked} = compLayout

    if (docked.left && positionAndSizeDiff.x) {
        applyDiffToUnitsData(docked.left, positionAndSizeDiff.x, parentWidth)
    }

    if (docked.right) {
        const rightDiff = (positionAndSizeDiff.x + positionAndSizeDiff.width) * -1
        if (rightDiff) {
            applyDiffToUnitsData(docked.right, rightDiff, parentWidth)
        }
    }

    if (docked.hCenter) {
        const centerDiff = positionAndSizeDiff.width / 2 + positionAndSizeDiff.x // eslint-disable-line no-mixed-operators
        if (centerDiff) {
            applyDiffToUnitsData(docked.hCenter, centerDiff, parentWidth)
        }
    }

    if (!(docked.left && docked.right)) {
        //if not horizontally stretched
        compLayout.width += positionAndSizeDiff.width
    }
}

function updateVerticalLayoutForDocked(compLayout: CompLayout, positionAndSizeDiff: MeshData, parentHeight: number) {
    const {docked} = compLayout

    if (docked.top && positionAndSizeDiff.y) {
        applyDiffToUnitsData(docked.top, positionAndSizeDiff.y, parentHeight)
    }

    if (docked.bottom) {
        const diffBottom = (positionAndSizeDiff.y + positionAndSizeDiff.height) * -1
        if (diffBottom) {
            applyDiffToUnitsData(docked.bottom, diffBottom, parentHeight)
        }
    }

    if (docked.vCenter) {
        const centerDiff = positionAndSizeDiff.height / 2 + positionAndSizeDiff.y // eslint-disable-line no-mixed-operators
        if (centerDiff) {
            applyDiffToUnitsData(docked.vCenter, centerDiff, parentHeight)
        }
    }

    if (!(docked.top && docked.bottom)) {
        compLayout.height += positionAndSizeDiff.height
    }
}

function applyPositionAndSizeOnCurrentLayoutSchema(ps: PS, compPointer: Pointer, positionAndSize: Partial<MeshData>) {
    return componentLayout(ps, compPointer, positionAndSize as MeshData)
        .updateVerticalLayout()
        .updateHorizontalLayout()
        .value()
}

function componentLayout(ps: PS, compPointer: Pointer, positionAndSizeChanges: MeshData) {
    return new ComponentLayoutBuilder(ps, compPointer, positionAndSizeChanges)
}

class ComponentLayoutBuilder {
    private newPositionAndSize: MeshData
    private readonly positionAndSizeDiff: MeshData
    private readonly compLayout: Layout

    constructor(private ps: PS, private compPointer: Pointer, positionAndSizeChanges: MeshData) {
        const currentPositionAndSize = getPositionAndSize(ps, compPointer)
        this.newPositionAndSize = _.assign({}, currentPositionAndSize, positionAndSizeChanges)
        this.positionAndSizeDiff = getPositionAndSizeDiff(currentPositionAndSize, positionAndSizeChanges)
        this.compLayout = ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'layout'))
    }

    value() {
        return this.compLayout
    }

    updateVerticalLayout() {
        if (this.positionAndSizeDiff.y === 0 && this.positionAndSizeDiff.height === 0) {
            return this
        }

        if (
            layoutUtils.isVerticallyDocked(this.compLayout) &&
            !layoutUtils.isVerticallyStretchedToScreen(this.compLayout)
        ) {
            const parentHeight = getParentHeight(this.ps, this.compPointer, this.compLayout)
            updateVerticalLayoutForDocked(this.compLayout, this.positionAndSizeDiff, parentHeight)
        } else {
            this.compLayout.y += this.positionAndSizeDiff.y
            this.compLayout.height += this.positionAndSizeDiff.height
        }

        return this
    }

    updateHorizontalLayout() {
        if (this.positionAndSizeDiff.x === 0 && this.positionAndSizeDiff.width === 0) {
            return this
        }

        if (layoutUtils.isHorizontallyDocked(this.compLayout)) {
            const parentWidth = getParentWidth(this.ps, this.compPointer, this.compLayout)
            updateHorizontalLayoutForDocked(this.compLayout, this.positionAndSizeDiff, parentWidth)
        } else {
            this.compLayout.x += this.positionAndSizeDiff.x
            this.compLayout.width += this.positionAndSizeDiff.width
        }

        return this
    }

    keepAspectRatioIfNeeded() {
        // BUG
        if (layoutUtils.isAspectRatioOn(this.compLayout)) {
            // @ts-expect-error BUG
            this.compLayout.aspectRatio = layoutUtils.calcAspectRatio(
                this.newPositionAndSize.width,
                this.newPositionAndSize.height
            )
        }

        return this
    }
}

/**
 *
 * @param ps
 * @param compPointer
 * @param [compLayout]
 * @returns the rendered position and size of the component, in pixels
 */
function getPositionAndSize(ps: PS, compPointer: Pointer, compLayout?: Layout): MeshData {
    const layout = compLayout || ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'layout'))
    if (!layout.docked) {
        return _.pick(layout, ['x', 'y', 'width', 'height'])
    }

    const parentDimensions = getParentDimensions(ps, compPointer)
    const screenSize = ps.siteAPI.getScreenSize()

    const measureMap = ps.siteAPI.getSiteMeasureMap()
    const rootPointer = ps.pointers.components.getPageOfComponent(compPointer)
    const siteWidth = layoutUtils.getRootWidth(measureMap, rootPointer.id, ps.siteAPI.getSiteWidth())

    const siteX = ps.siteAPI.getSiteX()
    const rootLeft = layoutUtils.getRootLeft(measureMap, rootPointer.id, siteX)

    const positionAndSize = warmupUtils.positionAndSize.getPositionAndSize(
        layout,
        parentDimensions,
        screenSize,
        siteWidth,
        rootLeft
    )
    if (experiment.isOpen('dm_sendIllegalPositionAndSize') && positionAndSize.width < 0) {
        ps.extensionAPI.logger.captureError(
            new ReportableError({
                errorType: 'IllegalPositionAndSize',
                message: 'Incorrect position and size',
                extras: {
                    compPointer,
                    positionAndSize,
                    siteWidth,
                    parentDimensions,
                    screenSize,
                    docked: layout.docked,
                    componentType: ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'componentType'))
                }
            })
        )
    }

    return positionAndSize
}

export default {
    applyPositionAndSizeOnCurrentLayoutSchema,
    getBoundingLayout,
    getComponentLayout,
    getPositionAndSize
}
