import constants from '../../constants/constants'

export default {
    mobileConversionConfig: {
        category: 'graphic',
        isSuitableForProportionGrouping: true,
        keepIfVerticalDivider: true
    },
    rotatable: true,
    styleCanBeApplied: true,
    resizableSides: [constants.RESIZE_SIDES.TOP, constants.RESIZE_SIDES.BOTTOM],
    widthAuto: true
}
