import {pointerUtils} from '@wix/document-manager-core'
import {scopesUtils, hooks} from '@wix/document-manager-extensions'
const {buildOldInflatedId} = scopesUtils
import type {CompStructure, Pointer, PS, ScopePointer, YesNoDC, CompRef} from '@wix/document-services-types'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'
import _ from 'lodash'
import component from '../component/component'
import componentDetectorAPI from '../componentDetectorAPI/componentDetectorAPI'
import resolveAdditionalComponentsRegistrar from '../componentDetectorAPI/resolveAdditionalComponentsRegistrar'
import dataModel from '../dataModel/dataModel'
import documentModeInfo from '../documentMode/documentModeInfo'
import mobileUtil from '../mobileUtilities/mobileUtilities'
import dsUtils from '../utils/utils'
import openCloseRefUtils from './openCloseRefUtils'
import refComponentUtils from './refComponentUtils'
import {eventHooks, registerHookWithPs} from '../hooks/eventHooks/eventHooks'
const {DATA_MODEL_HOOKS, VARIANT_HOOKS} = hooks
import referredComponentsHooks from '../hooks/componentHooks/referredComponentsHooks'
import experiment from 'experiment-amd'

const {getUniqueRefId, getReferredCompId, isRefPointer} = displayedOnlyStructureUtil
const REF_COMPONENT_TYPE = 'wysiwyg.viewer.components.RefComponent'
const REF_TYPES = {
    INTERNAL: 'InternalRef',
    WIDGET: 'WidgetRef'
}

const getUniqueRefCompPointer = (ps: PS, refHostPtr: Pointer, masterCompPtr: Pointer): CompRef => {
    const viewMode = ps.pointers.components.getViewMode(refHostPtr)
    const uniqueRefCompId = getUniqueRefId(refHostPtr.id, masterCompPtr.id)
    return ps.pointers.components.getUnattached(uniqueRefCompId, viewMode)
}

const getRefHostCompPointer = (ps: PS, referredCompPtr: Pointer): Pointer | undefined => {
    const viewMode = ps.pointers.components.getViewMode(referredCompPtr)
    const refHostScopePointer = ps.extensionAPI.scopes.getScopeWithPredicate(
        referredCompPtr,
        (scope: ScopePointer) =>
            scope && refComponentUtils.isRefHost(ps, ps.extensionAPI.scopes.getScopeOwner(scope, viewMode))
    )

    if (!refHostScopePointer) {
        const refHostIdByInflatedId = displayedOnlyStructureUtil.getRefHostCompId(referredCompPtr.id)
        return !refHostIdByInflatedId || experiment.isOpen('specs.thunderbolt.doNotInflateSharedParts')
            ? undefined
            : pointerUtils.getPointer(refHostIdByInflatedId, viewMode)
    }

    const refHostId = buildOldInflatedId(refHostScopePointer, ps.config.enableRepeatersInScopes)

    return pointerUtils.getPointer(refHostId, viewMode)
}

const unGhostifyComponent = (ps: PS, compPointer: Pointer) => {
    const referredComponent = getRefHostCompPointer(ps, compPointer)?.id
    const ghostRefComps = ps.pointers.referredStructure.getGhostRefComponents(referredComponent)
    const propertyQuery = _.get(ghostRefComps, [compPointer.id, 'propertyQuery'])
    if (propertyQuery) {
        const propertyItem = ps.dal.get(ps.pointers.data.getPropertyItem(propertyQuery))
        const newPropertyItem = _.assign({}, propertyItem, {ghost: undefined})
        dataModel.updatePropertiesItem(ps, compPointer, newPropertyItem)
        mobileUtil.updateMobilePropertyIfNeeded(ps, compPointer, newPropertyItem, dataModel.updatePropertiesItem)
    } else {
        refComponentUtils.removePropertyOverride(ps, compPointer)
        mobileUtil.removeMobilePropertyOverrideIfNeeded(ps, compPointer, refComponentUtils.removePropertyOverride)
    }
}

const getRootRefHostCompPointer = (ps: PS, referredCompPtr: Pointer): Pointer => {
    const viewMode = ps.pointers.components.getViewMode(referredCompPtr)
    const refHostScopePointer = ps.extensionAPI.scopes.getRootScopeWithPredicate(
        referredCompPtr,
        (scope: ScopePointer) =>
            scope && refComponentUtils.isRefHost(ps, ps.extensionAPI.scopes.getScopeOwner(scope, viewMode))
    )

    if (!refHostScopePointer) {
        const refHostIdByInflatedId = displayedOnlyStructureUtil.getRootRefHostCompId(referredCompPtr.id)
        return !refHostIdByInflatedId || experiment.isOpen('specs.thunderbolt.doNotInflateSharedParts')
            ? undefined
            : pointerUtils.getPointer(refHostIdByInflatedId, viewMode)
    }

    const refHostId = buildOldInflatedId(refHostScopePointer, ps.config.enableRepeatersInScopes)

    return pointerUtils.getPointer(refHostId, viewMode)
}

const getTemplateCompPointer = (ps: PS, referredCompPtr: Pointer): Pointer | undefined => {
    const viewMode = ps.pointers.components.getViewMode(referredCompPtr)
    const templateCompId = getReferredCompId(referredCompPtr.id)

    return templateCompId ? ps.pointers.components.getUnattached(templateCompId, viewMode) : undefined
}

const isReferredComponent = (ps: PS, compPtr: Pointer) => isRefPointer(compPtr)

const shouldUpdateAnchors = (ps: PS, compPtr: Pointer): YesNoDC =>
    isReferredComponent(ps, compPtr) ? dsUtils.NO : dsUtils.YES

/**
 * @param ps
 * @param compPtr
 * @returns {Object}
 * return a map of the referred component to the original component structure
 */
const getGhostRefComponents = (ps: PS, compPtr: Pointer) =>
    ps.pointers.referredStructure.getGhostRefComponents(compPtr.id)

const getComponentConnections = (ps: PS, compDefinition: CompStructure, compId: string) => {
    if (compDefinition.componentType === REF_COMPONENT_TYPE) {
        const [overriddenConnectionsItem] = ps.pointers.referredStructure.getDisplayedConnectionOverrides(
            compDefinition.id
        )
        return overriddenConnectionsItem.items
    }

    const compPointer = componentDetectorAPI.getComponentById(ps, compId)
    const pagePointers = ps.pointers.components.getPageOfComponent(compPointer)
    const connectionQuery = dsUtils.stripHashIfExists(compDefinition.connectionQuery)

    return dataModel.getConnectionItemsByQuery(ps, connectionQuery, pagePointers)
}

/**
 * @param ps
 * @param refHostPointer
 * @returns {Object}
 * return a map of the referred component to the original component primary connection
 */
const getAllGhostRefComponentsPrimaryConnection = (ps: PS, refHostPointer: Pointer) => {
    const templatePointer = experiment.isOpen('dm_fixGetAllGhostForWidgetInRepeater')
        ? pointerUtils.getRepeatedItemPointerIfNeeded(refHostPointer)
        : refHostPointer
    const ghostRefComps = getGhostRefComponents(ps, templatePointer)
    return _.mapValues(ghostRefComps, (comp, key) => {
        const connections = getComponentConnections(ps, comp as any, key)
        return _.find(connections, connectionItem => connectionItem.isPrimary)
    })
}

const removeUnusedOverrides = (ps: PS, refComponentPtr: Pointer) => {
    const compType = component.getType(ps, refComponentPtr)
    if (compType === REF_COMPONENT_TYPE) {
        const allOverrides = ps.pointers.referredStructure.getAllOverrides(refComponentPtr)
        const refChildComps = componentDetectorAPI.getComponentsUnderAncestor(ps, refComponentPtr)
        const templateCompIds = _.map(refChildComps, 'id')
        const unusedOverrides = _.filter(allOverrides, override => {
            const templateId = refComponentUtils.extractBaseComponentId(override)
            return !templateCompIds.includes(templateId)
        })
        dataModel.removeItems(ps, unusedOverrides)
    }
}

const init = (privateServices: PS) => {
    // register for resolving additional components for refComponent when getting
    // on methods that allow fetching additional components per comp type, resolve refComponent inner components
    resolveAdditionalComponentsRegistrar.registerAdditionalComponentsResolver(REF_COMPONENT_TYPE, (ps, compPtr) => {
        const rootChild = _.head(ps.pointers.components.getChildren(compPtr))
        return rootChild
            ? _.concat(ps.pointers.full.components.getChildrenRecursivelyRightLeftRootIncludingRoot(rootChild), compPtr)
            : []
    })

    eventHooks(privateServices).registerHook(
        DATA_MODEL_HOOKS.NAMESPACE_ITEM.GET_QUERY_ID.id,
        referredComponentsHooks.getItemQueryId
    )

    registerHookWithPs(privateServices, VARIANT_HOOKS.SET_OVERRIDE.BEFORE.id, referredComponentsHooks.beforeSetOverride)
    registerHookWithPs(
        privateServices,
        VARIANT_HOOKS.SET_SCOPED_VALUE.BEFORE.id,
        referredComponentsHooks.beforeSetRefArray
    )
    registerHookWithPs(
        privateServices,
        VARIANT_HOOKS.SET_NON_SCOPED_VALUE.BEFORE.id,
        referredComponentsHooks.beforeSetNonScopedRefArray
    )
}

const getChildrenOfNotInflatedRefComponent = (ps: PS, refComp: Pointer) => {
    const {rootCompId} = dataModel.getDataItem(ps, refComp)
    const rootCompPtr = ps.pointers.components.getUnattached(rootCompId, documentModeInfo.getViewMode(ps))
    return _.concat(rootCompPtr, componentDetectorAPI.getComponentsUnderAncestor(ps, rootCompPtr))
}

function getComponentsUnderNotInflatedRefComponentWithInflatedIDs(ps: PS, compRef: Pointer): Pointer[] | Pointer {
    if (component.getType(ps, compRef) === 'wysiwyg.viewer.components.RefComponent') {
        const directChildren = getChildrenOfNotInflatedRefComponent(ps, compRef)

        const allChildren = _.reduce(
            directChildren,
            (acc, child) => {
                const childrenOfChildren = getComponentsUnderNotInflatedRefComponentWithInflatedIDs(ps, child)
                return _.concat(acc, childrenOfChildren)
            },
            directChildren
        )

        return _.map(allChildren, child => getUniqueRefCompPointer(ps, compRef, child))
    }

    return compRef
}

function isSharedBlock(ps: PS, compPointer: Pointer): boolean {
    const data = dataModel.getDataItem(ps, compPointer)

    if (data && data.type === REF_TYPES.INTERNAL) {
        return !data.pageId || data.pageId === 'masterPage'
    }

    return false
}

function isPartOfSharedBlock(ps: PS, compPointer: Pointer): boolean {
    const refHost = getRefHostCompPointer(ps, compPointer)
    if (refHost && isSharedBlock(ps, refHost)) {
        return true
    }
    return false
}

const getReferredComponents = (ps: PS, compRef: Pointer, pageRef?: Pointer) => {
    const referredComponentsIds = ps.pointers.referredStructure.getInternallyReferredComponents(compRef)
    let referredComponentsRefs = _.map(referredComponentsIds, compId =>
        componentDetectorAPI.getComponentById(ps, compId)
    )
    if (pageRef) {
        referredComponentsRefs = _.filter(referredComponentsRefs, compPointer =>
            _.isEqual(ps.pointers.full.components.getPageOfComponent(compPointer), pageRef)
        )
    }
    return referredComponentsRefs
}

export default {
    init,
    isPartOfSharedBlock,
    shouldUpdateAnchors,
    getUniqueRefCompPointer,
    getRefHostCompPointer,
    getRootRefHostCompPointer,
    getTemplateCompPointer,
    isReferredComponent,
    getGhostRefComponents,
    getComponentsUnderNotInflatedRefComponentWithInflatedIDs,
    hasOverridesToBeRemoved: refComponentUtils.hasOverridesToBeRemoved,
    removeOverrides: refComponentUtils.removeOverrides,
    getAllOverrides: refComponentUtils.getOverriddenData,
    removeUnusedOverrides,
    getComponentToCreateRef: refComponentUtils.getComponentToCreateRef,
    unGhostifyComponent,
    generateRefComponentStructure: openCloseRefUtils.generateRefComponentStructure,
    getAllGhostRefComponentsPrimaryConnection,
    getReferredComponents
}
