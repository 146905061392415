import type {CharSet} from './fontUtils.types'

// TODO: April 2024 - Tombigel:
// I need to use this to avoid [no-google-fonts] rule by ci police.
// I hope in the next itteration to know for sure I can remove it altogether.
// See comment on "getFontsUrlWithParams" in fontUtils.ts
const GOOGLE_FONT_URL_PREFIX = '//fonts'
export const GOOGLE_FONT_URL = `${GOOGLE_FONT_URL_PREFIX}.googleapis.com/css?family=`
export const GOOGLE_FONT_SERVICE_URL = '//static.parastorage.com/tag-bundler/api/v1/fonts-cache/googleAPI/css?family='
export const UPLOADED_FONT_MASK = 'wfont_[0-9a-f]{6}_[0-9a-f]{32}'
export const SHORTY_FONT_MASK = 'wf_[0-9a-f]{25}'
export const UPLOADED_FONT_TEST = new RegExp(`^${UPLOADED_FONT_MASK}`)
export const UPLOADED_FONT_MATCH = new RegExp(`${UPLOADED_FONT_MASK}|${SHORTY_FONT_MASK}`, 'g')
export const TEXT_THEMES_MAP_KEY = 'font'
export const LONG_UPLOADED_FONT_PREFIX = 'wfont_'
export const POSSIBLE_CHARACTERS_SETS: CharSet[] = [
    'latin-ext',
    'cyrillic',
    'japanese',
    'korean',
    'arabic',
    'hebrew',
    'latin'
]
export const FONT_GROUPS: Record<CharSet | 'my-uploads', string> = {
    'my-uploads': 'text_editor_font_dropdown_my_fonts',
    latin: 'text_editor_font_dropdown_all_fonts',
    'latin-ext': 'add_languages_eastern_european',
    cyrillic: 'add_languages_cyrillic',
    japanese: 'add_languages_japanese',
    korean: 'add_languages_korean',
    arabic: 'add_languages_arabic',
    hebrew: 'add_languages_hebrew'
}
export const LANG_TO_EXAMPLE_KEY: Record<CharSet, string> = {
    latin: '',
    'latin-ext': 'text_editor_font_example_latin_ext_constant',
    cyrillic: 'text_editor_font_example_cyrillic_constant',
    japanese: 'text_editor_text_editor_font_example_japanese_constant',
    korean: 'text_editor_font_example_korean_constant',
    arabic: 'text_editor_font_example_arabic_constant',
    hebrew: 'text_editor_font_example_hebrew_constant'
}
export const HELVETICA_FALLBACK =
    'HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif'
export const LANGUAGES_CSS_RELATIVE_PATH = 'user-site-fonts/v31'
