import type {PublicMethodUtils} from '@wix/document-services-types'
import {
    visualFocusData,
    autoDomReorderData,
    componentA11yAPI,
    skipToContentData
} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            components: {
                getA11ySchema: publicMethodUtils.defineGetter(componentA11yAPI.getA11ySchema)
            },
            accessibility: {
                visualFocus: {
                    get: publicMethodUtils.defineGetter(visualFocusData.get),
                    set: publicMethodUtils.defineDataManipulationAction(visualFocusData.set)
                },
                autoDomReorder: {
                    get: publicMethodUtils.defineGetter(autoDomReorderData.get),
                    set: publicMethodUtils.defineDataManipulationAction(autoDomReorderData.set)
                },
                skipToContent: {
                    get: publicMethodUtils.defineGetter(skipToContentData.get),
                    set: publicMethodUtils.defineDataManipulationAction(skipToContentData.set)
                }
            }
        }
    }
}
