import type {PublicMethodUtils} from '@wix/document-services-types'
import {snapshotAPI} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            snapshot: {
                getSnapshot: publicMethodUtils.defineGetter(snapshotAPI.getSnapshot)
            }
        }
    }
}
