import _ from 'lodash'
import experiment from 'experiment-amd'
import bi from '../../bi/bi'
import originService from './originService'
import biEvents from '../../bi/events.json'
import type {PS} from '@wix/document-services-types'

const METHODS_WITH_BAD_PERFORMANCE_APIS = new Set([
    'document.controllers.listAllControllers',
    'document.controllers.listConnectableControllers',
    'document.components.getAllComponents',
    'document.components.refComponents.getAllAppRefComponents',
    'document.components.refComponents.getCollapsedRefComponents',
    'document.components.getChildren',
    'document.components.getAncestors',
    'document.pages.popupPages.addConnected',
    'document.pages.getHomePage'
])

function getMessageData(ps: PS, message) {
    const callPath = message.call.split('.')
    return {
        module: callPath.slice(0, -1).join('.'),
        function: _.last(callPath),
        arguments: message.args.map(JSON.stringify).join(','),
        pageId: ps.siteAPI.getPrimaryPageId()
    }
}

function reportAPICallBI(ps: PS, message) {
    if (experiment.isOpen('dm_platformSdkApiCallBI')) {
        bi.event(ps, biEvents.PLATFORM_API_CALL, {
            app_name: message.args[0].appDefinitionId,
            ...getMessageData(ps, message)
        })
    }
}

function reportAPICallBIHybridModeMethods(ps: PS, message) {
    if (experiment.isOpen('dm_platformSdkHybridApiCallBI')) {
        if (METHODS_WITH_BAD_PERFORMANCE_APIS.has(message.call)) {
            bi.event(ps, biEvents.PLATFORM_API_CALL_HYBRID_METHODS, {
                appDefinitionId: message.args[0]?.value?.appDefinitionId,
                ...getMessageData(ps, message)
            })
        }
    }
}

function reportAPICallDuration(ps: PS, message) {
    if (experiment.isOpen('dm_platformSdkApiCallDuration')) {
        const MIN_MS_VAULE_TO_REPORT = 2
        const editorType = originService.getEditorType()
        const appDefinitionId = message.args[0]?.value?.appDefinitionId
        const duration = Math.round(performance.now() - message.onApiCallResult)
        if (duration < MIN_MS_VAULE_TO_REPORT) {
            return
        }
        const {module, pageId, function: func} = getMessageData(ps, message)
        const data = {
            editorType,
            appDefinitionId,
            duration,
            module,
            function: func,
            pageId
        }
        bi.event(ps, biEvents.PLATFORM_API_CALL_DURATION, data)
    }
}

function reportAPICallFedOps(ps: PS, message) {
    if (experiment.isOpen('dm_platformSdkApiCallBI')) {
        const appDefinitionId = _.get(message, ['args', 0, 'value', 'appDefinitionId'])

        /*
           "interactionEnded" BI event is in use here only as a "container" to track which EditorSDK methods are called.
           "interactionStarted" event was added just for consistency because interaction should be described by a pair of these events
         */
        ps.extensionAPI.logger.interactionStarted(message.call, {extras: {appDefinitionId}})
        ps.extensionAPI.logger.interactionEnded(message.call, {extras: {appDefinitionId}})
    }
}

export default {
    reportAPICallBI,
    reportAPICallBIHybridModeMethods,
    reportAPICallDuration,
    reportAPICallFedOps
}
