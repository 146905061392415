export default {
    names: new Set<string>([
        'metaSiteId',
        'ReactSource',
        'BoltSource',
        'SantaVersions',
        'baseVersion',
        'isqa',
        'editorSessionId',
        'layoutMechanism',
        'experiments',
        'experimentsoff',
        'viewerexp',
        'suppressbi',
        'WixCodeRuntimeSource',
        'sampleratio',
        'hot',
        'dsQTrace',
        'autosaveRestore',
        'disableSave',
        'debugApp',
        'closeSectionGaps',
        'editorSdkSource',
        'editorPlatformAppSources',
        'viewerPlatformAppSources',
        'PlatformContainerSource',
        'PlatformContainerHttp',
        'esi',
        'configName',
        'wixCodeForceKibanaReport',
        'isEdited',
        'isSantaEditor',
        'dsOrigin',
        'controllersUrlOverride',
        'overridePlatformBaseUrls',
        'viewerPlatformOverrides',
        'tpaWidgetUrlOverride',
        'tpaMobileUrlOverride',
        'tpaSettingsUrlOverride',
        'widgetsUrlOverride',
        'debug',
        'petri_ovr',
        'iswixsite',
        'showMobileView',
        'localServerPort',
        'shouldSkipLocalModeEncoding',
        'lang',
        'currency',
        'firstSaveExtraPayload',
        'languages',
        'forceBolt',
        'ssrDebug',
        'wixAdsOverlay',
        'carmiDebug',
        'ssrIndicator',
        'ssrwarmuponly',
        'ssrprewarmuponly',
        'ssrTestAppSourceUrl',
        'siteRevision',
        'ds',
        'boltStage',
        'boltTag',
        'inBizMgr'
    ]),
    matchers: [/\S+-override$/]
}
