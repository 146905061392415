import type {PS} from '@wix/document-services-types'
import dataManipulation from './dataManipulation'

function getFeatures(ps: PS) {
    return dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.PREMIUM_FEATURES)
}

function setFeatures(ps: PS, features) {
    dataManipulation.setProperty(ps, dataManipulation.PROPERTY_NAMES.PREMIUM_FEATURES, features)
}

/** @class documentServices.premiumFeatures */
export default {
    /**
     * Retrieves the site's premium features
     *
     * @returns {Array.<string>} the site's premium features
     */
    getFeatures,
    /**
     * Set the site's premium features
     *
     * @param {Array.<string>} premiumFeatures the site's premium features
     */
    setFeatures
}
