// In case we're not running in the browser
const win = (typeof window !== 'undefined' ? window : {}) as Window

type Callback = (...args: any[]) => void

const reqAF = (callback: Callback) => setTimeout(callback, 1000 / 60)

const raf = win.requestAnimationFrame ?? reqAF

const caf = win.cancelAnimationFrame ?? clearTimeout

export default {
    request(callback: Callback): number {
        return raf.call(win, callback)
    },
    cancel(handle: number): void {
        caf.call(win, handle)
    }
}
