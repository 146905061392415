import constants from '../constants/constants'
import type {PS} from '@wix/document-services-types'

const isBolt = (ps: PS) => ps.runtimeConfig.viewerName === constants.VIEWER_NAMES.BOLT
const isThunderbolt = (ps: PS) => ps.runtimeConfig.viewerName === constants.VIEWER_NAMES.THUNDERBOLT

export default {
    isBolt,
    isThunderbolt
}
