import type {PublicMethodUtils} from '@wix/document-services-types'
import {
    smartBoxes,
    multiComponentsUtils,
    multiComponentsUtilsValidations,
    grouping,
    groupingUtils,
    utils
} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            components: {
                layout: {
                    getSnugPositionAndSize: publicMethodUtils.defineGetter(multiComponentsUtils.getSnugPositionAndSize),
                    getSnugLayout: publicMethodUtils.defineGetter(multiComponentsUtils.getSnugLayout),
                    getSnugLayoutRelativeToScreen: publicMethodUtils.defineGetter(
                        multiComponentsUtils.getSnugLayoutRelativeToScreen
                    ),
                    getSnugLayoutRelativeToScreenConsideringScroll: publicMethodUtils.defineGetter(
                        multiComponentsUtils.getSnugLayoutRelativeToScreenConsideringScroll
                    ),
                    getSnugLayoutRelativeToStructure: publicMethodUtils.defineGetter(
                        multiComponentsUtils.getSnugLayoutRelativeToStructure
                    )
                },
                alignment: {
                    alignComponents: publicMethodUtils.defineDataManipulationAction(multiComponentsUtils.align, {
                        isUpdatingAnchors: utils.YES
                    }),
                    alignInContainer: publicMethodUtils.defineDataManipulationAction(smartBoxes.alignInContainer, {
                        isUpdatingAnchors: utils.YES
                    }),
                    canAlign: publicMethodUtils.defineGetter(multiComponentsUtilsValidations.canAlign)
                },
                multiComponents: {
                    distribute: publicMethodUtils.defineDataManipulationAction(multiComponentsUtils.distribute, {
                        isUpdatingAnchors: utils.YES
                    }),
                    canDistribute: publicMethodUtils.defineGetter(multiComponentsUtilsValidations.canDistribute),
                    matchSize: publicMethodUtils.defineDataManipulationAction(multiComponentsUtils.matchSize, {
                        isUpdatingAnchors: utils.YES
                    }),
                    canMatchSize: publicMethodUtils.defineGetter(multiComponentsUtilsValidations.canMatchSize)
                },
                groupComponents: publicMethodUtils.defineDataManipulationAction(grouping.groupComponents, {
                    isUpdatingAnchors: utils.YES,
                    getReturnValue: grouping.genGroupPointer
                }),
                addToGroup: publicMethodUtils.defineDataManipulationAction(grouping.addToGroup, {
                    isUpdatingAnchors: utils.YES
                }),
                ungroup: publicMethodUtils.defineDataManipulationAction(grouping.ungroup, {
                    isUpdatingAnchors: utils.YES,
                    getReturnValue: grouping.getUngroupedComponents
                }),
                is: {
                    group: publicMethodUtils.defineGetter(groupingUtils.isGroup),
                    groupedComponent: publicMethodUtils.defineGetter(groupingUtils.isGroupedComponent),
                    topMost: publicMethodUtils.defineGetter(multiComponentsUtils.isTopMost),
                    leftMost: publicMethodUtils.defineGetter(multiComponentsUtils.isLeftMost)
                }
            }
        },
        initMethod: grouping.initialize
    }
}
