import type {Experiment, Pointers, SchemaService, UndoRedoConfig} from '@wix/document-services-types'
import {EventEmitter} from 'events'
import _ from 'lodash'
import {createDal, CustomGetter, DAL} from './dal/documentManagerDal'
import {createStore} from './dal/store'
import type {Events} from './eventEmitter'
import type {
    CoreLogger,
    DmApis,
    DocumentDataTypes,
    Extension,
    ExtensionAPI,
    ExtensionPublicAPI,
    PostSetOperation
} from './types'
import {getPointer} from './utils/pointerUtils'

const assignWithoutDuplicates = (target: Record<string, any>, sources: Record<string, any>, type: string) => {
    _.forOwn(sources, (value, key) => {
        if (target[key]) {
            throw new Error(`Trying to add an existing "${type}" with key "${key}"`)
        }

        target[key] = value
    })
}

export interface CoreConfig {
    experimentInstance: Experiment
    logger: CoreLogger
    tracking?: Function
    schemaService: SchemaService
    transactionActionsLimit: number
    signatureSeed?: string
    undoRedoConfig?: UndoRedoConfig
    disableCommonConfig?: boolean
    dontCollectFixerVersionData?: boolean
    supportsUsingPresetVariants?: boolean
    minNumOfPagesToCalculateRenderHints: number
    useJitFriendlyDal?: boolean
}

export interface ModelsInitializationType {
    rendererModel: any
    documentServicesModel: any
    serviceTopology: any
}
export interface DocumentManager {
    dal: DAL
    pointers: Pointers
    extensionAPI: ExtensionAPI
    publicAPI: ExtensionPublicAPI
    EVENTS: Record<string, any>
    eventEmitter: EventEmitter
    events: Events
    registerExtension(ext: Extension): void
    initialize(): Promise<void>
    initializeChannelSubscriptions(): Promise<void>
    initializeModels(initData: ModelsInitializationType): void
    experimentInstance: Experiment
    logger: CoreLogger
    schemaService: any
    config: CoreConfig
}

export const createDMCore = (coreConfig: CoreConfig): DocumentManager => {
    const {experimentInstance, logger, schemaService} = coreConfig
    const extensions: Extension[] = []
    const postSetOperations: PostSetOperation[] = []
    const customGetters: Record<string, CustomGetter> = {}
    const dmTypes: DocumentDataTypes = {}
    const pointers: Pointers = {} as Pointers
    const extensionAPI: ExtensionAPI = {}
    const publicAPI: ExtensionPublicAPI = {}
    const eventEmitter: EventEmitter = new EventEmitter()
    const EVENTS = {}
    const events: Events = {
        emitter: eventEmitter
    }
    const tracking = coreConfig.tracking ?? (async (name: string, fn: Function) => await fn())
    const dal: DAL = createDal({coreConfig, postSetOperations, dmTypes, customGetters, eventEmitter})
    const dmApis: DmApis = {coreConfig, dal, pointers, extensionAPI, EVENTS}

    const validate = (extension: Extension): void => {
        if (extension.name) {
            if (_(extensions).map('name').includes(extension.name)) {
                throw new Error(`extension name should be unique ${extension.name}`)
            }
        } else {
            throw new Error('extension should have a name')
        }
    }

    const registerExtension = (extension: Extension): void => {
        validate(extension)
        extensions.push(extension)

        assignWithoutDuplicates(customGetters, _.invoke(extension, ['createGetters'], dmApis), 'customGetter')
        _.merge(dmTypes, _.invoke(extension, ['getDocumentDataTypes'], dmApis))
        _.merge(pointers, _.invoke(extension, ['createPointersMethods'], dmApis))
        _.merge(extensionAPI, _.invoke(extension, ['createExtensionAPI'], {...dmApis, eventEmitter}))
        _.merge(publicAPI, _.invoke(extension, ['createPublicAPI'], {...dmApis, eventEmitter}))
        _.merge(EVENTS, extension.EVENTS)
        _.merge(events, extension.EVENTS)

        const filters = _.invoke(extension, ['createFilters'], dmApis)
        _.forEach(filters, (filter, name) => dal.registrar.registerFilter(name, filter))

        const validators = _.invoke(extension, ['createValidator'], dmApis)
        _.forEach(validators, (validator, name) => dal.registrar.registerValidator(name, validator))

        const rebaseValidators = _.invoke(extension, ['createRebaseValidator'], dmApis)
        _.forEach(rebaseValidators, (validator, name) => dal.registrar.registerRebaseValidator(name, validator))

        const postTransactionOperations = _.invoke(extension, ['createPostTransactionOperations'], dmApis)
        _.forEach(postTransactionOperations, (op, name) => dal.registrar.registerPostTransactionOperation(name, op))

        const changesListener = _.invoke(extension, ['createChangesListener'], dmApis)
        if (changesListener) {
            dal.registrar.registerForChangesCallback(changesListener)
        }

        if (extension.createPostSetOperation) {
            postSetOperations.push(extension.createPostSetOperation(dmApis))
        }

        if (extension.initialState) {
            const initialTypeState = createStore()
            _.forEach(extension.initialState, (state, type) => {
                _.forEach(state, (value, key) => {
                    initialTypeState.set(getPointer(key, type), value)
                })
            })
            dal.mergeToApprovedStore(initialTypeState, extension.name)
        }
    }
    const invokeAsyncOnExtensionsAndTrack = (fnName: keyof Extension, ...args: any[]): any[] =>
        _(extensions)
            .filter(fnName)
            .map((ex: Extension) => tracking(`${fnName}-${ex.name}`, () => _.invoke(ex, fnName, ...args)))
            .value()

    const invokeOnExtensions = (fnName: keyof Extension, ...args: any[]): any[] =>
        _(extensions)
            .filter(fnName)
            .map((ex: Extension) => _.invoke(ex, fnName, ...args))
            .value()

    const initialize = async () => {
        await Promise.all(invokeAsyncOnExtensionsAndTrack('initialize', {...dmApis, eventEmitter}))
    }

    const initializeChannelSubscriptions = async () => {
        await Promise.all(invokeAsyncOnExtensionsAndTrack('initializeChannelSubscriptions', {...dmApis, eventEmitter}))
    }
    const initializeModels = (initModelsData: ModelsInitializationType) => {
        const initialModelsState = createStore()
        invokeOnExtensions('initializeModels', initialModelsState, initModelsData, {...dmApis, eventEmitter})
        dal.mergeToApprovedStore(initialModelsState, 'model-initializer')
    }

    return {
        registerExtension,
        initialize,
        initializeModels,
        initializeChannelSubscriptions,
        extensionAPI,
        publicAPI,
        dal,
        eventEmitter,
        EVENTS,
        events,
        pointers,
        experimentInstance,
        logger,
        schemaService,
        config: coreConfig
    }
}
