import hooks from './hooks'
import repeaterMetaDataHooks from './metaDataHooks/repeaterMetaDataHooks'
import stateBoxMetaDataHooks from './metaDataHooks/stateBoxMetaDataHooks'
import referredComponentMetaDataHooks from './metaDataHooks/referredComponentMetaDataHooks'
import appWidgetMetaDataHooks from './metaDataHooks/appWidgetMetaDataHooks'

hooks.registerHook(hooks.HOOKS.METADATA.MOVE_DIRECTIONS, repeaterMetaDataHooks.setMovableSidesForRepeaterItems)
hooks.registerHook(hooks.HOOKS.METADATA.REMOVABLE, repeaterMetaDataHooks.disableRemovalForRepeaterItems)
hooks.registerHook(hooks.HOOKS.METADATA.DUPLICATABLE, repeaterMetaDataHooks.disableDuplicationOfRepeaterItem)
hooks.registerHook(hooks.HOOKS.METADATA.RESIZABLE_SIDES, repeaterMetaDataHooks.setResizableSidesForRepeaterItems)

hooks.registerHook(hooks.HOOKS.METADATA.CAN_BE_STRETCHED, stateBoxMetaDataHooks.canBeStretched)

const APP_WIDGET_COMP_TYPE = 'platform.components.AppWidget'
hooks.registerHook(hooks.HOOKS.METADATA.ROTATABLE, appWidgetMetaDataHooks.rotatable, APP_WIDGET_COMP_TYPE)
hooks.registerHook(hooks.HOOKS.METADATA.RESIZABLE_SIDES, appWidgetMetaDataHooks.resizableSides, APP_WIDGET_COMP_TYPE)
hooks.registerHook(hooks.HOOKS.METADATA.LAYOUT_LIMITS, appWidgetMetaDataHooks.layoutLimits, APP_WIDGET_COMP_TYPE)
hooks.registerHook(hooks.HOOKS.METADATA.CONTAINABLE, appWidgetMetaDataHooks.containable)

//Handle referred components
hooks.registerHook(hooks.HOOKS.METADATA.MOVE_DIRECTIONS, referredComponentMetaDataHooks.disableForAllButRoot)
hooks.registerHook(hooks.HOOKS.METADATA.DUPLICATABLE, referredComponentMetaDataHooks.disableForAllButRoot)
hooks.registerHook(hooks.HOOKS.METADATA.CAN_REPARENT, referredComponentMetaDataHooks.disableForAllButRoot)
hooks.registerHook(hooks.HOOKS.METADATA.FIXED_POSITION, referredComponentMetaDataHooks.disableForAllButRoot)
hooks.registerHook(hooks.HOOKS.METADATA.CONTAINABLE, referredComponentMetaDataHooks.disableForAll)
hooks.registerHook(hooks.HOOKS.METADATA.ROTATABLE, referredComponentMetaDataHooks.disableForAll)
hooks.registerHook(
    hooks.HOOKS.METADATA.RESIZABLE_SIDES,
    referredComponentMetaDataHooks.disableForAllButWidgetRootAndItsFirstChild
)
hooks.registerHook(
    hooks.HOOKS.METADATA.SHOULD_AUTO_SET_NICKNAME,
    referredComponentMetaDataHooks.disableForAllButWidgetRoot
)
hooks.registerHook(hooks.HOOKS.METADATA.HIDE_AS_GHOST, referredComponentMetaDataHooks.enableForAllButRoot)
hooks.registerHook(hooks.HOOKS.METADATA.REMOVABLE, referredComponentMetaDataHooks.isRemovable)
