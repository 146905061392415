// This configuration is used while DS over Bolt project is running, and not all APIs are functional.
// Should be removed once everything works
// App Studio Classic Editor config
import fullFunctionality from './fullFunctionalityConfig'

export default {
    ...fullFunctionality,
    modulesList: fullFunctionality.modulesList.concat(['appStudioPublicAPI']),
    disableAutoSave: true
}
