import _ from 'lodash'
/**
 * Stops traversal if the callback returns false.
 */
export function traverseViews(viewDef, callback) {
    let returnValue = callback(viewDef)
    if (returnValue !== false) {
        const childCompProps = ['items', 'cases', 'templates']
        _.forEach(childCompProps, function (prop) {
            if (viewDef?.comp?.[prop]) {
                _.forEach(viewDef.comp[prop], function (child) {
                    if (_.isArray(child)) {
                        _.forEach(child, function (arrItem) {
                            returnValue = traverseViews(arrItem, callback)
                            return returnValue
                        })
                    } else {
                        returnValue = traverseViews(child, callback)
                    }
                    return returnValue
                })
                return returnValue
            }
        })
    }
    return returnValue
}
