import dsUtils from '../utils/utils'
import mlUtils from '../utils/multilingual'
import dataModel from '../dataModel/dataModel'
import * as santaCoreUtils from '@wix/santa-core-utils'
import type {Pointer, PS} from '@wix/document-services-types'

const {DATA_TYPES} = santaCoreUtils.constants

export function getDataPointerById(ps: PS, dataId: string) {
    const pointer = ps.pointers.data.getDataItemFromMaster(dsUtils.stripHashIfExists(dataId))
    return mlUtils.getNonTranslatablePointer(ps, pointer)
}

export function getShallowDataById(ps: PS, dataId: string) {
    return ps.dal.get(getDataPointerById(ps, dataId))
}

export function getDeepData(ps, pointer: Pointer) {
    return dataModel.serializeDataItem(ps, DATA_TYPES.data, pointer, false, true)
}

export function getDeepDataById(ps, dataId: string) {
    return getDeepData(ps, getDataPointerById(ps, dataId))
}
