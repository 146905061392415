import type {AjaxOp} from '@wix/santa-ds-libs'
import _ from 'lodash'
import wixCodeUtils from '../utils/utils'
import wixCodeConstants from '../utils/constants'
import type {PS} from '@wix/document-services-types'

const wixCodeNewProvisionUrlTemplate = _.template(
    '<%= baseUrl %>/api/code/apps?msid=<%= metasiteId %>&ce=true<%= branchId ? `&branchId=${branchId}` : "" %>'
)

const wixCodeSaveUrlTemplate = _.template('<%= baseUrl %>/api/code/apps/<%= gridAppId %>/save?msid=<%= metasiteId %>')

const _createHeaders = ({
    signedInstance,
    accept,
    withAutorization = false
}: {
    signedInstance: string
    accept: string
    withAutorization?: boolean
}) =>
    _.pickBy({
        [withAutorization ? 'Authorization' : 'X-Wix-Si']: signedInstance,
        Accept: accept
    })

const _createRequest = ({
    url,
    data,
    signedInstance,
    accept,
    withAutorization = false
}: {
    url: string
    data
    signedInstance: string
    accept: string
    withAutorization?: boolean
}): AjaxOp =>
    _.assign({
        url,
        type: wixCodeUtils.requestTypes.POST,
        contentType: 'application/json',
        data,
        headers: _createHeaders({signedInstance, accept, withAutorization}),
        timeout: 15000,
        xhrFields: {
            withCredentials: true
        },
        crossDomain: true
    })

function getBaseUrlFromPS(ps: PS) {
    const pointer = ps.pointers.general.getServiceTopology()
    return ps.dal.get(ps.pointers.getInnerPointer(pointer, wixCodeConstants.WIX_CODE_SERVICE_URL_KEY))
}

const create = async ({
    baseUrl,
    metasiteId,
    signedInstance,
    branchId
}: {
    baseUrl: string
    metasiteId: string
    signedInstance: string
    branchId: string
}) => {
    const url = wixCodeNewProvisionUrlTemplate({baseUrl, metasiteId, branchId})
    // @ts-expect-error
    const request = _createRequest({url, data: {}, signedInstance})
    const response = await wixCodeUtils.sendRequestObj(request)
    return response.payload
}

const save = ({
    baseUrl,
    gridAppId,
    metasiteId,
    signedInstance
}: {
    baseUrl: string
    gridAppId: string
    metasiteId: string
    signedInstance: string
}) => {
    const url = wixCodeSaveUrlTemplate({baseUrl, gridAppId, metasiteId})
    // @ts-expect-error
    const request = _createRequest({url, data: {}, signedInstance})
    return wixCodeUtils.sendRequestObj(request)
}

export default {
    create,
    save,
    getBaseUrlFromPS
}
