import _ from 'lodash'

interface UrlParsed {
    path?: string
    pathname?: string
    protocol?: string
    hostname?: string
    search?: string
    port?: string
    host?: string
    hash?: string
    full?: string
    query?: Record<string, string>
}
interface Ret {
    path?: string
    protocol?: string
    hostname?: string
    search?: string
    port?: string
    host?: string
    hash?: string
    full?: string
    query?: any
}

const TEMPLATE_REG_EXP = /\${(.*?)}/g

function isTemplateValue(value: string) {
    return TEMPLATE_REG_EXP.test(value)
}

function addProtocolIfMissing(url: string, protocol: string) {
    const beginsWithProtocol = /^(ftps|ftp|http|https):.*$/.test(url)
    const beginsWithDoubleSlash = /^\/\//.test(url)

    if (beginsWithProtocol) {
        return url
    }

    protocol = protocol || 'https:'
    if (beginsWithDoubleSlash) {
        return protocol + url
    }

    return `${protocol}//${url}`
}

function toQueryString(jsonObj: any, convertBool?: boolean): string {
    return _.map(jsonObj, (value, key) => toQueryParam(key, value, convertBool))
        .sort()
        .join('&')
}

function toQueryParam(key: string, val: any, convertBool: boolean): string {
    key = encodeURIComponent(key)
    if (!val && val !== 0 && val !== false) {
        return key
    }
    key += '='
    if (_.isArray(val)) {
        return _.map(val, innerVal => key + encodeURIComponent(innerVal)).join('&')
    }
    if (convertBool && typeof val === 'boolean') {
        val = val ? '1' : '0'
    } else if (!isTemplateValue(val)) {
        val = encodeURIComponent(val)
    }
    return key + val
}

function baseUrl(url: string): string {
    const parsed = parseUrl(url)
    return `${parsed.protocol}//${parsed.host}`
}

function getPath(url: string): string {
    const parsed = parseUrl(url)
    return parsed.path
}

function getBaseUrlWithPath(urlObj: UrlParsed, pathPartsCount: number) {
    const path = urlObj.path || urlObj.pathname || ''
    const pathParts = path.split('/')
    const pathPartsToUse = _.compact(pathParts.slice(0, (pathPartsCount || 0) + 1))
    let url = `${urlObj.protocol}//${urlObj.hostname}`
    if (!_.isEmpty(pathPartsToUse)) {
        url += `/${pathPartsToUse.join('/')}`
    }
    return url
}

let uniqueCounter = 0

function cacheKiller() {
    return new Date().getTime().toString() + uniqueCounter++
}

let persistentCacheKillerValue = null

function persistentCacheKiller() {
    persistentCacheKillerValue = persistentCacheKillerValue || new Date().getTime().toString()
    return persistentCacheKillerValue
}

const urlRegex = /((https?\:)\/\/)?([^\?\:\/#]+)(\:([0-9]+))?(\/[^\?\#]*)?(\?([^#]*))?(#.*)?/i

function parseUrl(url: string): UrlParsed {
    if (!url) {
        return {}
    }

    const match = url.match(urlRegex)
    const port = match[5] || ''
    const search = match[8] ? `?${match[8]}` : ''
    const ret: Ret = {
        full: url,
        protocol: match[2] || 'http:',
        host: match[3] + (port ? `:${port}` : ''),
        hostname: match[3],
        port,
        path: match[6] || '/',
        search,
        hash: match[9] || ''
    }

    // fix empty hash
    if (ret.hash === '#' || ret.hash === '#!') {
        ret.hash = ''
    }

    ret.query = parseUrlParams(match[8])
    return ret
}

function safeDecodeURIComponent(encodedURIParam: string) {
    try {
        return decodeURIComponent(encodedURIParam)
    } catch (e) {
        return encodedURIParam
    }
}

function parseUrlParams(queryString: string): {[s: string]: string | string[]} {
    const regExp = /([^&=]+)=([^&]*)/g
    const query: {[s: string]: string | string[]} = {}
    let param: string[]

    while ((param = regExp.exec(queryString)) !== null) {
        const key = safeDecodeURIComponent(param[1])
        const val = safeDecodeURIComponent(param[2])
        if (!query[key]) {
            query[key] = val
        } else if (_.isArray(query[key])) {
            ;(query[key] as string[]).push(val)
        } else {
            query[key] = [query[key] as string, val]
        }
    }

    return query
}

function buildFullUrl(urlObj: UrlParsed, ignoreSearch: boolean) {
    let search = ignoreSearch ? null : urlObj.search
    if (search) {
        search = search.replace(/^[?]/, '')
    }

    let query = search || toQueryString(urlObj.query || {})

    if (query) {
        const queryPrefix = _.includes(urlObj.path, '?') ? '&' : '?'
        query = queryPrefix + query
    }

    return isLocalUrl(urlObj.full)
        ? urlObj.full
        : `${urlObj.protocol}//${urlObj.hostname}${urlObj.port ? `:${urlObj.port}` : ''}${urlObj.path}${query || ''}${
              urlObj.hash
          }`
}

function isLocalUrl(url: string) {
    return /(^data)|(^blob)/.test(url)
}

function setUrlParam(url: string, paramName: string, value: any): string {
    const urlParts = url.split('?')
    let paramList = []
    let replaced = false
    if (urlParts.length > 1) {
        paramList = urlParts[1].split('&')
        const paramIndex = _.findIndex(paramList, param => _.startsWith(param, `${paramName}=`))
        if (paramIndex !== -1) {
            paramList[paramIndex] = `${paramName}=${String(value)}`
            replaced = true
        }
    }

    if (!replaced) {
        paramList.push(`${paramName}=${String(value)}`)
    }

    urlParts[1] = paramList.join('&')
    url = urlParts.join('?')
    return url
}

function setUrlParams(url: string, params: any) {
    const urlObj = parseUrl(url)
    _.assign(urlObj.query, params)
    return buildFullUrl(urlObj, true)
}

function updateUrl(url: string) {
    if (window.history?.replaceState) {
        window.history.replaceState({}, '', url)
    } else {
        console.error('window.history is not supported in this OLD browser!') // eslint-disable-line no-console
        //Not supported only in IE9
    }
}

function simplifyUrl(url: string) {
    return url?.replace(/[?&#/]+$/, '').toLowerCase()
}

function isSameUrl(url1: string, url2: string) {
    return simplifyUrl(url1) === simplifyUrl(url2)
}

function joinURL(...segments: string[]) {
    let url = segments[0]
    for (let i = 1; i < segments.length; ++i) {
        url = `${url.replace(/\/$/, '')}/${segments[i].replace(/^\//, '')}`
    }
    return url
}

function isTrue(name: string, urlQueries: string): boolean {
    return getParameterByName(name, urlQueries) === 'true'
}

function origin(currentUrlObj: UrlParsed): string | undefined {
    if (currentUrlObj) {
        return `${currentUrlObj.protocol}//${currentUrlObj.hostname}${currentUrlObj.port}`
    } else if (typeof window !== 'undefined') {
        if (window.location.origin) {
            return window.location.origin
        }
        const port = window.location.port ? `:${window.location.port}` : ''
        return `${window.location.protocol}//${window.location.hostname}${port}`
    }
}

// https://yandex.ru/support/metrika/general/counter-webvisor.xml
function isHostnameYandexWebvisor(hostname: string) {
    return /^(.*\.)?(mtproxy|hghltd)\.yandex\.net$/.test(hostname)
}

function getParameterByName(name: string, urlQueries?: string): any {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`, 'i')
    const results = regex.exec(_.isUndefined(urlQueries) ? window.location.search : urlQueries)
    return results === null ? '' : safeDecodeURIComponent(results[1].replace(/\+/g, ' '))
}

const getOverridesMap = (overrideParam: string): {[s: string]: string} => {
    const map: {[s: string]: string} = {}
    const regex = /,?([^=]+)=([^=]+)(?=,|$)/g
    let match: string | string[]
    do {
        match = regex.exec(overrideParam)
        if (match && match.length > 2) {
            const key = match[1].trim()
            const value = match[2].trim()
            map[key] = value
        }
    } while (match)

    return map
}

const getArtifactOverrides = (queryParams: any): object | null =>
    _.reduce(
        queryParams,
        (acc, value, key) => {
            if (isArtifactOverrideParam(key)) {
                acc = acc || {}
                acc[key] = value
            }
            return acc
        },
        null
    )

function isArtifactOverrideParam(paramName: string) {
    return paramName.endsWith('-override')
}

export default {
    addProtocolIfMissing,
    toQueryString,
    toQueryParam,
    baseUrl,
    getPath,
    getBaseUrlWithPath,
    cacheKiller,
    persistentCacheKiller,
    setUrlParam,
    setUrlParams,
    parseUrl,
    updateUrl,
    buildFullUrl,
    parseUrlParams,
    isTrue,
    isSame: isSameUrl,
    joinURL,
    origin,
    getParameterByName,
    isHostnameYandexWebvisor,
    getOverridesMap,
    getArtifactOverrides
}
