import type {PageListWithMasterAndMainPage} from '@wix/document-services-types'

export const buildPageList = (
    pageList: PageListWithMasterAndMainPage,
    partialPages: string[]
): PageListWithMasterAndMainPage => {
    if (partialPages.length <= 0) {
        return pageList
    }

    const set = new Set(partialPages)
    return {
        ...pageList,
        pages: pageList.pages.filter(rmPage => set.has(rmPage.pageId))
    }
}
