import type {PS} from '@wix/document-services-types'
import consts from '../../constants/constants'
import documentModeInfo from '../../documentMode/documentModeInfo'
import metaDataUtils from '../metaDataUtils'

function isDuplicatable(ps: PS) {
    return !documentModeInfo.isMobileView(ps)
}

//TODO: handle single item page when we'll have it
export default {
    resizableSides: [consts.RESIZE_SIDES.LEFT, consts.RESIZE_SIDES.RIGHT],
    removable: true,
    duplicatable: isDuplicatable,
    crossSiteDuplicatable: isDuplicatable,
    containableByStructure: metaDataUtils.notContainableByPopup,
    usingLegacyAppPartSchema: true
}
