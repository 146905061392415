import type {PS} from '@wix/document-services-types'
import _ from 'lodash'

const startTrace = (ps: PS, {shouldLogConsoleTraces = false} = {shouldLogConsoleTraces: false}) => {
    ps.runtimeConfig.trace.isTracing = true
    ps.runtimeConfig.trace.shouldLogConsoleTraces = shouldLogConsoleTraces
}

const endTrace = (ps: PS) => {
    ps.runtimeConfig.trace.isTracing = false
}

const cleanTrace = (ps: PS) => {
    ps.runtimeConfig.trace.log = []
    ps.runtimeConfig.trace.readLog = []
}

const get = (ps: PS, read?: boolean) => (read ? ps.runtimeConfig.trace.readLog : ps.runtimeConfig.trace.log)

const isTracing = (ps: PS) => ps.runtimeConfig.trace.isTracing

const shouldLogTrace = (ps: PS, {methodName = ''}) => isTracing(ps) && !methodName.startsWith('debug.trace')

const logTrace = (ps: PS, type: string, logData: any) => {
    if (shouldLogTrace(ps, logData)) {
        const ts = window.performance ? window.performance.now() : _.now()
        ps.runtimeConfig.trace.log.push(_.merge({type, ts}, logData))
    }
}

const logReadTrace = (ps: PS, logData: any) => {
    if (shouldLogTrace(ps, logData)) {
        const ts = window.performance ? window.performance.now() : _.now()
        ps.runtimeConfig.trace.readLog.push(_.assign({type: 'Q OPERATION READ', ts}, logData))
    }
}

const shouldLogConsoleTrace = (ps: PS) => ps.runtimeConfig.trace.shouldLogConsoleTraces

/**
 *
 * @param {PS} ps
 * @return {Array} array of only the actions, without the rendering.
 */
const getActions = (ps: PS) => _.reject(get(ps), {type: 'VIEWER RENDERED FROM Q'})

/**
 *
 * @param {PS} ps
 * @return {Object.<string, object[]>} a map from <methodName, allMethodCallsData>
 */
const getCalledMethods = (ps: PS) => _.groupBy(getActions(ps).concat(get(ps, true)), 'methodName')

/**
 * @param {PS} ps
 */
const initialize = (ps: PS) => {
    const dsQTrace = ps.siteAPI.getQueryParam('dsQTrace')

    if (dsQTrace) {
        startTrace(ps)
    }
}

const upload = () => Promise.resolve()

export {
    initialize,
    startTrace,
    endTrace,
    getActions,
    getCalledMethods,
    get,
    logReadTrace,
    logTrace,
    shouldLogConsoleTrace,
    isTracing,
    cleanTrace,
    upload
}
