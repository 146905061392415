import type {DocumentServicesDal, Language, Pointer, Pointers, PS} from '@wix/document-services-types'
import _ from 'lodash'
import multilingual from '../multilingual/multilingual'
import coreMultilingual from '@wix/santa-multilingual/dist/languages'
import hooks from '../hooks/hooks'
import dsUtils from '../utils/utils'

interface TranslationLanguage {
    languageCode: string
}

const {languageStatus} = coreMultilingual
const getLanguageCode: (l: Language) => string = _.property('languageCode')

function _includesLanguage(languages: Language[], language: Language) {
    return _(languages).map(getLanguageCode).includes(getLanguageCode(language))
}

function getCurrentLanguageCode(ps: PS) {
    return multilingual.getCurrentLanguageCode(ps)
}

function getCurrentLanguageDefinition(ps: PS) {
    const languages = getAllLanguages(ps)
    const cur = getCurrentLanguageCode(ps)
    return languages.find(({languageCode}) => languageCode === cur)
}

function setCurrentLanguageCode(ps: PS, languageCode: string) {
    if (isSiteLanguage(ps, {languageCode} as Language)) {
        multilingual.setCurrentLanguage(ps, languageCode)
        hooks.executeHook(hooks.HOOKS.MULTILINGUAL.AFTER_CHANGE_LANGUAGE, null, [ps, languageCode])
    }
}

function init(ps: PS, {originalLanguage, translationLanguages}) {
    removeUnusedLanguages(ps, translationLanguages)
    ps.dal.set(ps.pointers.multilingual.originalLanguage(), originalLanguage)
    ps.dal.set(ps.pointers.multilingual.translationLanguages(), translationLanguages)
    setCurrentLanguageCode(ps, getLanguageCode(originalLanguage) || null)
}

function removeUnusedLanguages(ps: PS, translationLanguages: TranslationLanguage[]) {
    const existingLanguages = ps.extensionAPI.multilingualTranslations.getAllTranslationLanguages()
    const requiredLanguages = translationLanguages.map(lang => lang.languageCode)
    const languagesToRemove = _.difference(existingLanguages, requiredLanguages)
    languagesToRemove.forEach(ps.extensionAPI.multilingualTranslations.remove)
}

function initPageTranslations(ps: PS, language: Language) {
    const pageIds = ps.siteAPI.getAllPagesIds(true)
    pageIds.forEach(id => {
        const emptyTranslationData = {[getLanguageCode(language)]: _.cloneDeep(multilingual.EMPTY_TRANSLATION_DATA)}
        const translationsPointer = ps.pointers.page.getPageTranslations(id)
        const existingTranslationData = ps.dal.full.get(translationsPointer)
        const newTranslationData = _.assign(emptyTranslationData, existingTranslationData)
        ps.dal.full.set(translationsPointer, newTranslationData)
    })
}

function isMultilingualEnabled(ps: PS) {
    return !!multilingual.getCurrentLanguageCode(ps)
}

function getLanguagePublicAvailability(ps: PS, language: Language) {
    const publicLanguages = getSitePublicLanguages(ps)
    return _includesLanguage(publicLanguages, language)
}

function setLanguageStatus(ps: PS, language: Language, status: string) {
    const translationLanguages = getTranslationLanguages(ps)
    const isTranslationLanguage = _includesLanguage(translationLanguages, language)
    if (isTranslationLanguage) {
        const updatedLanguage = _.assign({}, language, {status})
        const updatedLanguages = translationLanguages.map(translationLanguage =>
            getLanguageCode(translationLanguage) === getLanguageCode(language) ? updatedLanguage : translationLanguage
        )
        _setTranslationLanguages(ps, updatedLanguages)

        if (status === languageStatus.DELETED && getCurrentLanguageCode(ps) === language.languageCode) {
            const originalLanguage = getOriginalLanguage(ps)
            setCurrentLanguageCode(ps, originalLanguage.languageCode)
        }
    }
}

function addTranslationLanguage(ps: PS, language: Language) {
    if (!isSiteLanguage(ps, language)) {
        const newTranslationLanguages = getTranslationLanguages(ps).concat(language)
        _setTranslationLanguages(ps, newTranslationLanguages)
        initPageTranslations(ps, language)
    }
}

function removeTranslationLanguage(ps: PS, language: Language) {
    const siteLanguages = getTranslationLanguages(ps)
    const languageQuery = {languageCode: getLanguageCode(language)}
    const targetLanguage = _.find(siteLanguages, languageQuery)

    if (targetLanguage) {
        const newTranslationLanguages = _.reject(siteLanguages, languageQuery)
        const newActiveTranslationLanguages = _.filter(newTranslationLanguages, {status: languageStatus.ACTIVE})

        if (_.isEmpty(newActiveTranslationLanguages)) {
            setLanguageStatus(ps, getOriginalLanguage(ps), languageStatus.ACTIVE)
        }
        const {dal, pointers} = ps
        dal.set(pointers.multilingual.translationLanguages(), newTranslationLanguages)
    }
}

function setOriginalLanguage(ps: PS, language: Language) {
    if (!isSiteLanguage(ps, language)) {
        const previousOriginalLanguageCode = getLanguageCode(getOriginalLanguage(ps))
        language.status = languageStatus.ACTIVE
        const {dal, pointers} = ps
        dal.set(pointers.multilingual.originalLanguage(), language)
        if (getCurrentLanguageCode(ps) === previousOriginalLanguageCode) {
            setCurrentLanguageCode(ps, getLanguageCode(language))
        }
    }
}

function getOriginalLanguage({dal, pointers}: {dal: DocumentServicesDal; pointers: Pointers}): Language {
    return dal.get(pointers.multilingual.originalLanguage())
}

function getTranslationLanguages({dal, pointers}: {dal: DocumentServicesDal; pointers: Pointers}) {
    return dal.get(pointers.multilingual.translationLanguages()) || []
}

const getTranslationLanguageCodes = (ps: PS) => getTranslationLanguages(ps).map(getLanguageCode)

function _setTranslationLanguages(
    {dal, pointers}: {dal: DocumentServicesDal; pointers: Pointers},
    translationLanguages: Language[]
) {
    return dal.set(pointers.multilingual.translationLanguages(), translationLanguages)
}

function getNonDeletedTranslationLanguages({
    dal,
    pointers
}: {
    dal: DocumentServicesDal
    pointers: Pointers
}): Language[] {
    return _.reject(getTranslationLanguages({dal, pointers}), {status: languageStatus.DELETED})
}

function getAllLanguages(ps: PS): Language[] {
    return _.concat(getOriginalLanguage(ps), getNonDeletedTranslationLanguages(ps))
}

function getSitePublicLanguages(ps: PS) {
    return _.filter(getAllLanguages(ps), {status: languageStatus.ACTIVE})
}

function isSiteLanguage(ps: PS, language: Language) {
    return _includesLanguage(getAllLanguages(ps), language)
}

function getComponentPageId(ps: PS, componentPointer: Pointer) {
    const originalPagePointer = ps.pointers.components.getPageOfComponent(componentPointer)
    return ps.dal.full.get(originalPagePointer).id
}

function getComponentDataId(ps: PS, componentPointer: Pointer) {
    const originalDataQueryPointer = ps.pointers.getInnerPointer(componentPointer, 'dataQuery')
    const dataQuery = ps.dal.full.get(originalDataQueryPointer)
    if (!_.isUndefined(dataQuery)) {
        return dsUtils.stripHashIfExists(dataQuery)
    }
    return null
}

function copyTranslationData(ps: PS, newPointer: Pointer, translationData, dataItemIdMap) {
    const compPageId = getComponentPageId(ps, newPointer)

    _.forEach(translationData, (dataItemMap, languageCode) => {
        _.forEach(dataItemMap, (dataItem, dataItemId) => {
            if (!dataItemIdMap[dataItemId]) {
                return
            }
            const newDataItem = _.cloneDeep(dataItem)

            // this differentiates between copy-paste operation and page duplication operation (DM-2023)
            if (!ps.pointers.components.isPage(newPointer)) {
                newDataItem.compId = newPointer.id
            }

            newDataItem.id = dataItemIdMap[dataItemId]

            const newDataItemPointer = ps.pointers.multilingualTranslations.translationDataItem(
                compPageId,
                languageCode,
                newDataItem.id
            )
            if (!ps.dal.full.isExist(newDataItemPointer)) {
                ps.dal.full.set(newDataItemPointer, newDataItem)
            }
        })
    })
}

function getComponentTranslationData(ps: PS, rootComponentPointer: Pointer) {
    const componentPageId = getComponentPageId(ps, rootComponentPointer)
    const translationsPointer = ps.pointers.page.getPageTranslations(componentPageId)
    const pageTranslationData = ps.dal.full.get(translationsPointer)
    const componentPointers = _.concat(
        rootComponentPointer,
        ps.pointers.components.getChildrenRecursively(rootComponentPointer)
    )
    return _.reduce(
        pageTranslationData,
        (componentTranslations, translation, languageCode) => {
            _.forEach(componentPointers, componentPointer => {
                const componentDataId = getComponentDataId(ps, componentPointer)
                if (!_.isNull(componentDataId)) {
                    const translationDataItemPointer = ps.pointers.multilingualTranslations.translationDataItem(
                        componentPageId,
                        languageCode,
                        componentDataId
                    )
                    if (ps.dal.full.isExist(translationDataItemPointer)) {
                        const translationDataItem = ps.dal.full.get(translationDataItemPointer)
                        _.merge(componentTranslations, {[languageCode]: {[componentDataId]: translationDataItem}})
                    }
                }
            })
            return componentTranslations
        },
        {}
    )
}

function patchTranslations(
    ps: PS,
    pageId: string,
    dataItemId: string,
    patch: ((translationDataItem: any) => any) | Record<string, any>
) {
    _.forEach(getTranslationLanguages(ps), ({languageCode}) => {
        const translationDataItemPointer = ps.pointers.multilingualTranslations.translationDataItem(
            pageId,
            languageCode,
            dataItemId
        )
        if (ps.dal.full.isExist(translationDataItemPointer)) {
            const translationDataItem = ps.dal.full.get(translationDataItemPointer)
            const updatedTranslationDataItem = _.isFunction(patch)
                ? patch(translationDataItem)
                : _.merge(translationDataItem, patch)
            ps.dal.full.set(translationDataItemPointer, updatedTranslationDataItem)
        }
    })
}

function patchPageTranslations(ps: PS, pageId: string, patch) {
    patchTranslations(ps, 'masterPage', pageId, patch)
}

function isCurrentLanguageSecondary(ps: PS) {
    return isMultilingualEnabled(ps) && getOriginalLanguage(ps).languageCode !== getCurrentLanguageCode(ps)
}

function patchDataItemTranslations(ps: PS, pageId: string, dataItemId: string, patch) {
    patchTranslations(ps, pageId, dataItemId, patch)
}

function copyDataItemTranslations(ps: PS, pageId: string, dataItemId: string, newPageId: string, newData) {
    _.forEach(getTranslationLanguages(ps), ({languageCode}) => {
        const translationPointer = ps.pointers.multilingualTranslations.translationDataItem(
            pageId,
            languageCode,
            dataItemId
        )

        if (ps.dal.full.isExist(translationPointer)) {
            const translationDataItem = ps.dal.full.get(translationPointer)
            const newDataItem = _.assign({}, _.cloneDeep(translationDataItem), newData)
            const newTranslationPointer = ps.pointers.multilingualTranslations.translationDataItem(
                newPageId,
                languageCode,
                newDataItem.id
            )
            ps.dal.full.set(newTranslationPointer, newDataItem)
        }
    })
}

/** @class documentServices.language */
export default {
    /** @class documentServices.language.multilingual */
    multilingual: {
        /**
         * Gets the enabled/disabled state of the multilingual feature.
         *
         * @returns {boolean} true if the multilingual feature is enabled, false otherwise.
         */
        isEnabled: isMultilingualEnabled
    },
    /** @class documentServices.language.current */
    current: {
        /**
         * Gets the current active language for the site.
         *
         * @returns {string} An ISO-639-3 language code of the site's current active language .
         */
        get: getCurrentLanguageCode,
        getDefinition: getCurrentLanguageDefinition,
        /**
         * Sets the current active language for the site.
         *
         * @param {string} languageCode An ISO-639-3 language code to be set as the active language for the site.
         */
        set: setCurrentLanguageCode
    },
    /** @class documentServices.language.original */
    original: {
        /**
         * Gets language code for the original (first edited) language for the site.
         *
         * @returns {string} An ISO-639-3 language code of the site's current active language .
         */
        get: getOriginalLanguage,
        /**
         * Sets language code for the original (first edited) language for the site.
         *
         * @param {LanguageDefinition} languageCode An ISO-639-3 language code to be set as the active language for the site.
         */
        set: setOriginalLanguage
    },
    /** @class documentServices.language.public */
    public: {
        /**
         * Gets the public availability status of a specific language on the site.
         *
         * @param {string} languageCode An ISO-639-3 language code of the language to query.
         * @returns {boolean} true if the language at hand is publicly available to site users, false otherwise.
         */
        get: getLanguagePublicAvailability
    },
    status: {
        /**
         * Set the status of language oneOf(ACTIVE, INACTIVE, DELETED)
         * @param {object} language
         * @param {string} status of of the statuses from description
         */
        set: setLanguageStatus
    },
    /**
     * Retrieves the list of available languages for the site
     *
     * @returns {Array.<object>}
     */
    get: getNonDeletedTranslationLanguages,
    /**
     * Retrieves the list of languages that have been added
     *
     * @returns {Array.<object>} An array of ISO-639-3 language codes.
     */
    getFull: getTranslationLanguages,
    getTranslationLanguageCodes,
    /**
     * Adds site support for a specific language
     *
     * @param {string} languageCode An ISO-639-3 language code of the language to be added to the site.
     */
    add: addTranslationLanguage,
    /**
     * Remove site support for a specific language
     *
     * @param {string} languageCode An ISO-639-3 language code of the language to be removed from the site.
     */
    remove: removeTranslationLanguage,
    init,
    copyTranslationData,
    patchPageTranslations,
    patchDataItemTranslations,
    copyDataItemTranslations,

    isCurrentLanguageSecondary,
    component: {
        getTranslations: getComponentTranslationData
    }
}
