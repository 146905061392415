import type {Pointer, PS} from '@wix/document-services-types'
import constants from '../../constants/constants'
import metaDataUtils from '../metaDataUtils'
import popupUtils from '../../page/popupUtils'

const isContainable = (ps: PS, compPointer: Pointer, containerPointer: Pointer) => {
    const containerCompType = metaDataUtils.getComponentType(ps, containerPointer)
    const isContainerPage = ps.extensionAPI.componentDefinition.isPage(containerCompType)
    return isContainerPage && !popupUtils.isPopup(ps, containerPointer.id)
}

export default {
    alignable: false,
    containable: isContainable,
    containableByStructure: isContainable,

    canBeFixedPosition: false,

    showMarginsIndicator: true,

    fullWidth: true,
    fullWidthByStructure: true,
    groupable: false,

    resizableSides: [constants.RESIZE_SIDES.BOTTOM],
    moveDirections: [constants.MOVE_DIRECTIONS.VERTICAL],

    layoutLimits: {
        minHeight: 40
    },

    mobileConversionConfig: {
        structuralItem: true,
        filterChildrenWhenHidden: true,
        stretchHorizontally: true,
        isTightContainer: false,
        minHeight: 10,
        category: 'sections',
        preserveAspectRatio: false,
        hideWhenEmptyBackgroundContainer: true,
        fixedSize(ps: PS, comp, pageId: string) {
            return metaDataUtils.getMobileFixedSizesForColumn(ps, comp, pageId)
        }
    }
}
