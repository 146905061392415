import fullFunctionalityConfig from './fullFunctionalityConfig'
import responsiveConfig from './responsiveConfig'
import type {DSConfig} from '@wix/document-services-types'

const createAnyIDEConfig = (base: DSConfig): DSConfig => ({
    ...base,
    cedit: false,
    disableSave: true,
    createRevision: false,
    autosaveRestore: 'false',
    disableAutoSave: true,
    continuousSave: false,
    isReadOnly: true,
    noUndo: true,
    origin: 'anyIDE'
})

const responsive = createAnyIDEConfig(responsiveConfig)
const classic = createAnyIDEConfig(fullFunctionalityConfig)

export {responsive, classic}
