import {dsQTrace} from '@wix/document-services'
import type {RuntimeConfig} from '@wix/document-services-types'

export class SetOperationsTrace {
    private readonly traceTimes: {
        start?: number
        last?: number
        total?: number
    }
    private readonly psForTrace: any

    constructor(runtimeConfig: RuntimeConfig) {
        this.psForTrace = {runtimeConfig}
        this.traceTimes = {}
        if (!dsQTrace.isTracing(this.psForTrace)) {
            return
        }
        const now = performance.now()
        this.traceTimes.start = now
        this.traceTimes.last = now
    }

    addTraceTime(name: string) {
        if (!dsQTrace.isTracing(this.psForTrace)) {
            return
        }
        const now = performance.now()
        this.traceTimes[name] = now - (this.traceTimes.last ?? 0)
        this.traceTimes.last = now
    }

    logTrace(items: number[]) {
        if (!dsQTrace.isTracing(this.psForTrace) || !this.traceTimes.start) {
            return
        }
        this.traceTimes.total = (this.traceTimes.last ?? 0) - this.traceTimes.start

        dsQTrace.logTrace(this.psForTrace, 'VIEWER RENDERED FROM Q', {items, traceTimes: this.traceTimes})

        if (dsQTrace.shouldLogConsoleTrace(this.psForTrace)) {
            if (console.trace) {
                console.trace()
            }
        }
    }
}
