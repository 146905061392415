import type {CompRef, PS, RawComponentExport, WMLFiles} from '@wix/document-services-types'

const convertWmlToRawComponentExport = async (
    ps: PS,
    componentToReplace: CompRef,
    wmlFiles: WMLFiles
): Promise<void> => {
    try {
        const componentExport: RawComponentExport =
            await ps.extensionAPI.importExport.wml.convertWmlToRawComponentExport(wmlFiles)
        ps.setOperationsQueue.asyncPreDataManipulationComplete(componentExport)
    } catch (e) {
        ps.setOperationsQueue.asyncPreDataManipulationComplete(null, e as Error)
    }
}

export default {
    components: {
        replace: (ps: PS, componentExport: RawComponentExport, componentToReplace: CompRef): CompRef =>
            ps.extensionAPI.importExport.components.replace(componentToReplace, componentExport)
    },
    pages: {
        replace: (ps: PS, pageExport: RawComponentExport, pageToReplace: CompRef): CompRef =>
            ps.extensionAPI.importExport.pages.replace(pageToReplace, pageExport)
    },
    wml: {
        convertWmlToRawComponentExport,
        exportComponent: (ps: PS, componentPointer: CompRef): WMLFiles =>
            ps.extensionAPI.importExport.wml.exportComponent(componentPointer),
        exportPage: (ps: PS, pagePointer: CompRef): WMLFiles => ps.extensionAPI.importExport.wml.exportPage(pagePointer)
    }
}
