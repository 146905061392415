import type {Callback2} from '@wix/document-services-types'
import _ from 'lodash'
import {contextAdapter} from '../utils/contextAdapter'
import hooks from './hooks'
import anchor from './componentHooks/anchor'
import svgShapeToVectorImageHook from './componentHooks/svgShapeToVectorImageHook'
import clipartHooks from './componentHooks/clipartHooks'
import slideShow from './componentHooks/slideShow'
import wRichTextHooks from './componentHooks/wRichTextHooks'
import tpaHooks from './componentHooks/tpaHooks'
import quickActionBarHooks from './componentHooks/quickActionBarHooks'
import nickname from './componentHooks/nickname'
import modesHooks from './componentHooks/modesHooks'
import slotsHooks from './componentHooks/slotsHooks'
import wPhoto from './componentHooks/wPhoto'
import mediaPlayer from './componentHooks/mediaPlayer'
import columns from './componentHooks/columns'
import stripColumnsContainer from './componentHooks/stripColumnsContainer'
import siteRegionContainer from './componentHooks/siteRegionContainer'
import wFacebookLike from './componentHooks/wFacebookLike'
import wFacebookComment from './componentHooks/wFacebookComment'
import pinterestPinIt from './componentHooks/pinterestPinIt'
import vkShareButton from './componentHooks/vkShareButton'
import facebookShare from './componentHooks/facebookShare'
import popupPageHooks from './componentHooks/popupPageHooks'
import fiveGridLineHooks from './componentHooks/fiveGridLineHooks'
import appControllerHooks from './componentHooks/appControllerHooks'
import pageHooks from './componentHooks/pageHooks'
import verticalMenu from './componentHooks/verticalMenu'
import siteBackgroundHooks from './componentHooks/siteBackgroundHooks'
import repeaterHooks from './componentHooks/repeaterHooks'
import siteHeaderHooks from './componentHooks/siteHeaderHooks'
import connectionsHooks from './componentHooks/connectionsHooks'
import contactFormHooks from './componentHooks/contactFormHooks'
import appWidgetHooks from './componentHooks/appWidgetHooks'
import componentPreviewStateHooks from './componentHooks/componentPreviewStateHooks'
import mobileComponentHooks from './componentHooks/mobileComponentHooks'
import sectionHooks from './componentHooks/sectionHooks'
import checkboxHooks from './componentHooks/checkboxHooks'
import widgetPageHooks from './componentHooks/widgetPageHooks'
import inputLabelHooks from './componentHooks/inputLabelHooks'
import masterPageHooks from './componentHooks/masterPageHooks'
import referredComponentsHooks from './componentHooks/referredComponentsHooks'
import externalComponentsHooks from './componentsExternalHooks/externalComponentsHooks'
import mobileUtil from '../mobileUtilities/mobileUtilities'
import matrixGallery from './componentHooks/matrixGallery'
import pinnedElement from './componentHooks/pinnedElement'
import tabsHooks from './componentHooks/tabs'
import reactionsUtils from '../reactions/reactionsUtils'
import {fixVariantsRelationsIfPossible} from '../variants/variantsReplacementUtils'
import {convertToMeshAfterAddHook, remeasureContainerHook} from './componentHooks/meshLayout'
import {convertCompToNewSiteWidthHook} from './layoutHooks/siteWidthMigration'

init()

function registerPlatformHooks() {
    hooks.registerHook(hooks.HOOKS.ADD.BEFORE, connectionsHooks.updateSerializedConnectionsData)
    hooks.registerHook(hooks.HOOKS.ADD_ROOT.AFTER, nickname.generateNicknamesForPageOfComponent)
    hooks.registerHook(
        hooks.HOOKS.ADD.AFTER,
        appControllerHooks.handleControllerAddition,
        'platform.components.AppController'
    )
    hooks.registerHook(hooks.HOOKS.ADD.AFTER, appWidgetHooks.handleAppWidgetAddition, 'platform.components.AppWidget')
    hooks.registerHook(hooks.HOOKS.CHANGE_PARENT.BEFORE, nickname.deleteNicknameFromComponentIfInvalid)
    hooks.registerHook(hooks.HOOKS.CHANGE_PARENT.BEFORE, connectionsHooks.removeInvalidConnections)
    hooks.registerHook(hooks.HOOKS.CHANGE_PARENT.AFTER, nickname.generateNicknamesForPageOfComponent)
    hooks.registerHook(
        hooks.HOOKS.CHANGE_PARENT.BEFORE,
        appControllerHooks.handleControllerParentChange,
        'platform.components.AppController'
    )
    hooks.registerHook(
        hooks.HOOKS.CHANGE_PARENT.BEFORE,
        appWidgetHooks.handleAppWidgetParentChange,
        'platform.components.AppWidget'
    )
    hooks.registerHook(hooks.HOOKS.CHANGE_PARENT.BEFORE, slotsHooks.cleanupCompsInSlots)
    hooks.registerHook(hooks.HOOKS.MOBILE_CONVERSION.AFTER, nickname.generateNicknamesForPages)
    hooks.registerHook(
        hooks.HOOKS.SERIALIZE.SET_CUSTOM,
        appControllerHooks.handleControllerSerialization,
        'platform.components.AppController'
    )
    hooks.registerHook(
        hooks.HOOKS.SERIALIZE.SET_CUSTOM,
        appWidgetHooks.handleAppWidgetSerialization,
        'platform.components.AppWidget'
    )
    hooks.registerHook(hooks.HOOKS.SERIALIZE.SET_CUSTOM, nickname.setOriginalContextToSerializedComponent)
    hooks.registerHook(hooks.HOOKS.REMOVE.BEFORE, slotsHooks.cleanupCompsInSlotsInRemoval)
    hooks.registerHook(
        hooks.HOOKS.REMOVE.BEFORE,
        appControllerHooks.handleControllerDeletion,
        'platform.components.AppController'
    )
    hooks.registerHook(
        hooks.HOOKS.REMOVE.BEFORE,
        appWidgetHooks.handleAppWidgetDeletion,
        'platform.components.AppWidget'
    )
    hooks.registerHook(hooks.HOOKS.ADD.BEFORE, appWidgetHooks.beforeAddHook, 'platform.components.AppWidget')
}

function registerRefComponentHooks() {
    hooks.registerHook(
        hooks.HOOKS.REMOVE_SCOPED_VALUE.BEFORE,
        referredComponentsHooks.beforeSetRefArrayOnRelationRemoval
    )
    hooks.registerHook(hooks.HOOKS.DESERIALIZE.BEFORE, referredComponentsHooks.beforeDeserialize)

    hooks.registerHook(
        hooks.HOOKS.SERIALIZE.SET_CUSTOM,
        referredComponentsHooks.setCustomSerializeData,
        'wysiwyg.viewer.components.RefComponent'
    )
    hooks.registerHook(
        hooks.HOOKS.ADD.BEFORE,
        referredComponentsHooks.beforeAddComponent,
        'wysiwyg.viewer.components.RefComponent'
    )
    hooks.registerHook(
        hooks.HOOKS.ADD.AFTER,
        referredComponentsHooks.afterAddComponent,
        'wysiwyg.viewer.components.RefComponent'
    )
    hooks.registerHook(
        hooks.HOOKS.REMOVE.BEFORE,
        referredComponentsHooks.saveOverrides,
        'wysiwyg.viewer.components.RefComponent'
    )
    hooks.registerHook(
        hooks.HOOKS.REMOVE.AFTER,
        referredComponentsHooks.removeAllOverrides,
        'wysiwyg.viewer.components.RefComponent'
    )
    hooks.registerHook(hooks.HOOKS.REMOVE.AFTER, referredComponentsHooks.removeInternalRefOverrides)
}

function inputsHooks() {
    // Checkbox
    hooks.registerHook(
        hooks.HOOKS.DATA.UPDATE_BEFORE,
        checkboxHooks.beforeUpdateCheckboxData,
        'wysiwyg.viewer.components.inputs.Checkbox'
    )
    hooks.registerHook(
        hooks.HOOKS.DATA.UPDATE_AFTER,
        checkboxHooks.afterUpdateCheckboxData,
        'wysiwyg.viewer.components.inputs.Checkbox'
    )

    // TextInput
    hooks.registerHook(
        hooks.HOOKS.LAYOUT.UPDATE_BEFORE,
        inputLabelHooks.beforeUpdateInputLabelLayout,
        'wysiwyg.viewer.components.inputs.TextInput'
    )
    hooks.registerHook(
        hooks.HOOKS.DATA.UPDATE_BEFORE,
        inputLabelHooks.beforeUpdateInputLabelData,
        'wysiwyg.viewer.components.inputs.TextInput'
    )

    // TextArea
    hooks.registerHook(
        hooks.HOOKS.LAYOUT.UPDATE_BEFORE,
        inputLabelHooks.beforeUpdateInputLabelLayout,
        'wysiwyg.viewer.components.inputs.TextAreaInput'
    )
    hooks.registerHook(
        hooks.HOOKS.DATA.UPDATE_BEFORE,
        inputLabelHooks.beforeUpdateInputLabelData,
        'wysiwyg.viewer.components.inputs.TextAreaInput'
    )

    // DatePickerHooks
    hooks.registerHook(
        hooks.HOOKS.LAYOUT.UPDATE_BEFORE,
        inputLabelHooks.beforeUpdateInputLabelLayout,
        'wysiwyg.viewer.components.inputs.DatePicker'
    )
    hooks.registerHook(
        hooks.HOOKS.DATA.UPDATE_BEFORE,
        inputLabelHooks.beforeUpdateInputLabelData,
        'wysiwyg.viewer.components.inputs.DatePicker'
    )

    // ComboBoxHooks
    hooks.registerHook(
        hooks.HOOKS.LAYOUT.UPDATE_BEFORE,
        inputLabelHooks.beforeUpdateInputLabelLayout,
        'wysiwyg.viewer.components.inputs.ComboBoxInput'
    )
    hooks.registerHook(
        hooks.HOOKS.DATA.UPDATE_BEFORE,
        inputLabelHooks.beforeUpdateInputLabelData,
        'wysiwyg.viewer.components.inputs.ComboBoxInput'
    )

    // FileUploaderHooks
    hooks.registerHook(
        hooks.HOOKS.LAYOUT.UPDATE_BEFORE,
        inputLabelHooks.beforeUpdateInputLabelLayout,
        'wysiwyg.viewer.components.inputs.FileUploader'
    )
    hooks.registerHook(
        hooks.HOOKS.DATA.UPDATE_BEFORE,
        inputLabelHooks.beforeUpdateInputLabelData,
        'wysiwyg.viewer.components.inputs.FileUploader'
    )

    hooks.registerHook(
        hooks.HOOKS.LAYOUT.UPDATE_BEFORE,
        inputLabelHooks.beforeUpdateInputLabelLayout,
        'wixui.TimePicker'
    )
    hooks.registerHook(hooks.HOOKS.DATA.UPDATE_BEFORE, inputLabelHooks.beforeUpdateInputLabelData, 'wixui.TimePicker')

    hooks.registerHook(
        hooks.HOOKS.LAYOUT.UPDATE_BEFORE,
        inputLabelHooks.beforeUpdateInputLabelLayout,
        'wixui.AddressInput'
    )
    hooks.registerHook(hooks.HOOKS.DATA.UPDATE_BEFORE, inputLabelHooks.beforeUpdateInputLabelData, 'wixui.AddressInput')
}

function init() {
    hooks.registerHook(
        hooks.HOOKS.DATA.UPDATE_BEFORE,
        wPhoto.removeMobileCropOverrideIfNeeded,
        'wysiwyg.viewer.components.WPhoto'
    )
    hooks.registerHook(hooks.HOOKS.ADD.BEFORE, anchor, 'wysiwyg.common.components.anchor.viewer.Anchor')

    hooks.registerHook(
        hooks.HOOKS.SERIALIZE.SET_CUSTOM,
        wRichTextHooks.setLinksCustomData,
        'wysiwyg.viewer.components.WRichText'
    )
    hooks.registerHook(
        hooks.HOOKS.DATA.UPDATE_BEFORE,
        wRichTextHooks.cleanUnusedLinks,
        'wysiwyg.viewer.components.WRichText'
    )
    hooks.registerHook(
        hooks.HOOKS.DATA.AFTER_ADD_REPEATED,
        wRichTextHooks.fixTextLinksAfterSetAddToRepeater,
        'wysiwyg.viewer.components.WRichText'
    )
    hooks.registerHook(hooks.HOOKS.DATA.AFTER_ADD_REPEATED, repeaterHooks.syncTranslationsAfterAdd)

    hooks.registerHook(hooks.HOOKS.ADD.AFTER, repeaterHooks.afterAdd, 'wysiwyg.viewer.components.Repeater')
    hooks.registerHook(
        hooks.HOOKS.SERIALIZE.CHILDREN_BEFORE,
        repeaterHooks.addRepeatedDataItems,
        'wysiwyg.viewer.components.Repeater'
    )
    hooks.registerHook(
        hooks.HOOKS.DATA.UPDATE_BEFORE,
        repeaterHooks.beforeUpdateRepeaterData,
        'wysiwyg.viewer.components.Repeater'
    )
    hooks.registerHook(
        hooks.HOOKS.DATA.UPDATE_AFTER,
        repeaterHooks.afterUpdateRepeaterData,
        'wysiwyg.viewer.components.Repeater'
    )

    hooks.registerHook(
        hooks.HOOKS.PROPERTIES.UPDATE_BEFORE,
        wRichTextHooks.handleVerticalTextPropertyChange,
        'wysiwyg.viewer.components.WRichText'
    )
    hooks.registerHook(
        hooks.HOOKS.ADD.AFTER,
        wRichTextHooks.duplicateLinksDataItems,
        'wysiwyg.viewer.components.WRichText'
    )
    hooks.registerHook(hooks.HOOKS.ADD_ROOT.AFTER, convertToMeshAfterAddHook)
    hooks.registerHook(hooks.HOOKS.ADD_ROOT.AFTER, remeasureContainerHook)

    hooks.registerHook(hooks.HOOKS.ADD.BEFORE, pinnedElement.unDockNonDirectPageChildren)

    hooks.registerHook(hooks.HOOKS.SERIALIZE.DATA_AFTER, reactionsUtils.serializeVariantsOnParentComponentIfNeeded)
    hooks.registerHook(hooks.HOOKS.TRIGGERS.REMOVE_BEFORE, reactionsUtils.removeReactionsByTrigger)
    hooks.registerHook(hooks.HOOKS.VARIANTS.CHANGE_PARENT_AFTER, reactionsUtils.removeReactionsIfNeeded)
    hooks.registerHook(hooks.HOOKS.VARIANTS.RELATION_REMOVAL_BEFORE, fixVariantsRelationsIfPossible)
    ;[
        'wysiwyg.viewer.components.StateBox',
        'wysiwyg.viewer.components.StateStrip',
        'wysiwyg.viewer.components.BoxSlideShow',
        'wysiwyg.viewer.components.StripContainerSlideShow'
    ].forEach(type => hooks.registerHook(hooks.HOOKS.ADD.BEFORE, slideShow.verifySlideShowStructureOnAdd, type))
    ;['wysiwyg.viewer.components.BoxSlideShow', 'wysiwyg.viewer.components.StateBox'].forEach(type =>
        hooks.registerHook(hooks.HOOKS.COMPONENT.STYLE_UPDATE_AFTER, slideShow.updateSlidesStyle, type)
    )
    ;[
        'wysiwyg.viewer.components.StateBoxState',
        'wysiwyg.viewer.components.Repeater',
        'wysiwyg.viewer.components.StateBoxFormState',
        'wysiwyg.viewer.components.StateStripState',
        'wysiwyg.viewer.components.BoxSlideShowSlide',
        'wysiwyg.viewer.components.StripContainerSlideShowSlide'
    ].forEach(type => hooks.registerHook(hooks.HOOKS.REMOVE.BEFORE, slideShow.verifySlideShowStructureOnDelete, type))

    hooks.registerHook(
        hooks.HOOKS.ADD.AFTER,
        fiveGridLineHooks.replaceFullScreenPropertyWithDocking,
        'wysiwyg.viewer.components.FiveGridLine'
    )
    // svg shape => vector image shape
    hooks.registerHook(
        hooks.HOOKS.ADD.BEFORE,
        svgShapeToVectorImageHook.beforeAdd,
        'wysiwyg.viewer.components.svgshape.SvgShape'
    )

    // clipart => wphoto
    hooks.registerHook(hooks.HOOKS.ADD.BEFORE, clipartHooks.beforeAdd, 'wysiwyg.viewer.components.ClipArt')
    // Media Player - Override media player id to child controls
    hooks.registerHook(
        hooks.HOOKS.ADD.AFTER,
        mediaPlayer.setPlayerIdAfterAddHook,
        'wysiwyg.viewer.components.MediaOverlayControls'
    )
    hooks.registerHook(
        hooks.HOOKS.ADD.AFTER,
        mediaPlayer.setPlayerIdAfterAddHook,
        'wysiwyg.viewer.components.MediaControls'
    )
    // Media Player - Fix layout on mobile conversion
    hooks.registerHook(hooks.HOOKS.MOBILE_CONVERSION.AFTER, mediaPlayer.layoutChildrenOnMobileConversion)

    // Media Player - flip border radius and box shadow
    hooks.registerHook(
        hooks.HOOKS.PROPERTIES.UPDATE_BEFORE,
        mediaPlayer.flipCssStyle,
        'wysiwyg.viewer.components.MediaPlayer'
    )

    // Widget Pages - Copy root docked layout
    hooks.registerHook(hooks.HOOKS.MOBILE_CONVERSION.AFTER, widgetPageHooks.copyRootComponentDockedLayout)

    //position the bottom control bar,  needed for cases that cssStyle.borderRadius(design data) changed
    hooks.registerHook(
        hooks.HOOKS.DESIGN.UPDATE_AFTER,
        mediaPlayer.layoutPlayerChildren,
        'wysiwyg.viewer.components.MediaPlayer'
    )
    // columns
    hooks.registerHook(hooks.HOOKS.REMOVE.BEFORE, columns.handleColumnDeletion, 'wysiwyg.viewer.components.Column')
    hooks.registerHook(
        hooks.HOOKS.REMOVE.AFTER,
        columns.deleteContainerIfHasNoMoreColumns,
        'wysiwyg.viewer.components.Column'
    )
    hooks.registerHook(hooks.HOOKS.ADD_ROOT.BEFORE, columns.handleSplitToColumns, 'wysiwyg.viewer.components.Column')

    hooks.registerHook(hooks.HOOKS.ADD_ROOT.AFTER, convertCompToNewSiteWidthHook)

    hooks.registerHook(
        hooks.HOOKS.PROPERTIES.UPDATE_BEFORE,
        columns.handleFullWidthPropertyChange,
        'wysiwyg.viewer.components.StripColumnsContainer'
    )

    hooks.registerHook(
        hooks.HOOKS.LAYOUT.UPDATE_BEFORE,
        siteHeaderHooks.handleHeaderLayoutChange,
        'wysiwyg.viewer.components.HeaderContainer'
    )

    hooks.registerHook(hooks.HOOKS.ADD.BEFORE, stripColumnsContainer, 'wysiwyg.viewer.components.StripColumnsContainer')
    hooks.registerHook(hooks.HOOKS.ADD.BEFORE, siteRegionContainer, 'wysiwyg.viewer.components.SiteRegionContainer')

    //menu
    hooks.registerHook(
        hooks.HOOKS.ADD.BEFORE,
        verticalMenu.beforeAdd,
        'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu'
    )

    //matrixGallery
    hooks.registerHook(hooks.HOOKS.ADD.BEFORE, matrixGallery.beforeAdd, 'wysiwyg.viewer.components.MatrixGallery')

    //social comps
    hooks.registerHook(hooks.HOOKS.ADD.AFTER, wFacebookLike, 'wysiwyg.viewer.components.WFacebookLike')
    hooks.registerHook(hooks.HOOKS.ADD.AFTER, wFacebookComment, 'wysiwyg.viewer.components.WFacebookComment')
    hooks.registerHook(
        hooks.HOOKS.ADD.AFTER,
        pinterestPinIt,
        'wysiwyg.common.components.pinterestpinit.viewer.PinterestPinIt'
    )
    hooks.registerHook(hooks.HOOKS.ADD.AFTER, vkShareButton, 'wysiwyg.viewer.components.VKShareButton')
    hooks.registerHook(hooks.HOOKS.ADD.AFTER, facebookShare, 'wysiwyg.viewer.components.FacebookShare')

    registerPlatformHooks()

    // popups
    hooks.registerHook(
        hooks.HOOKS.ADD_ROOT.GET_CONTAINER_TO_ADD_TO,
        popupPageHooks.getContainerToAddTo,
        'mobile.core.components.Page'
    )
    hooks.registerHook(hooks.HOOKS.REMOVE.AFTER, popupPageHooks.removeDeadBehaviors, 'mobile.core.components.Page')
    hooks.registerHook(
        hooks.HOOKS.ADD.AFTER,
        popupPageHooks.removeBehaviorsFromAddedPage,
        'mobile.core.components.Page'
    )

    //pages
    hooks.registerHook(
        hooks.HOOKS.ADD.AFTER,
        connectionsHooks.updateSerializedConnectionsDataForPage,
        'mobile.core.components.Page'
    )
    hooks.registerHook(
        hooks.HOOKS.DUPLICATE_ROOT.AFTER,
        pageHooks.fixDuplicatedPageCodeBehaviors,
        'mobile.core.components.Page'
    )
    hooks.registerHook(
        hooks.HOOKS.DUPLICATE_ROOT.AFTER,
        pageHooks.duplicatePageCodeIfRequired,
        'mobile.core.components.Page'
    )
    hooks.registerHook(hooks.HOOKS.ADD_PAGE.AFTER, mobileUtil.page.initMobileComponents)
    hooks.registerHook(hooks.HOOKS.REMOVE.AFTER, pageHooks.removePageFromPlatformApps, 'mobile.core.components.Page')
    hooks.registerHook(hooks.HOOKS.REMOVE.BEFORE, pageHooks.beforePageRemoveHook, 'mobile.core.components.Page')

    // section order
    hooks.registerHook(hooks.HOOKS.ADD_PAGE.AFTER, sectionHooks.afterAddPage)

    // site background
    hooks.registerHook(hooks.HOOKS.RELAYOUT_MOBILE_PAGE.AFTER, siteBackgroundHooks.setMobileMediaSizing)
    hooks.registerHook(hooks.HOOKS.ADD_PAGE.AFTER, siteBackgroundHooks.setMobileMediaSizingByPointer)
    hooks.registerHook(hooks.HOOKS.SITE_BACKGROUND_UPDATE.AFTER, siteBackgroundHooks.setMediaSizingForDevice)

    // contactForm DATA hook
    contactFormHooks.initContactFromHooks(hooks)

    //modes
    hooks.registerHook(hooks.HOOKS.MOBILE_CONVERSION.BEFORE, modesHooks.activatePageMobileHoverModes)

    //tpa
    hooks.registerHook(
        hooks.HOOKS.ADD.AFTER,
        tpaHooks.addedGluedWidget,
        'wysiwyg.viewer.components.tpapps.TPAGluedWidget'
    )
    hooks.registerHook(
        hooks.HOOKS.REMOVE.BEFORE,
        tpaHooks.willRemoveGluedWidget,
        'wysiwyg.viewer.components.tpapps.TPAGluedWidget'
    )
    hooks.registerHook(
        hooks.HOOKS.CONNECTION.AFTER_DISCONNECT,
        tpaHooks.executeDisconnectHandler,
        'wysiwyg.viewer.components.tpapps.TPAWidget'
    )

    //quickActionBar
    hooks.registerHook(
        hooks.HOOKS.DATA.UPDATE_BEFORE,
        quickActionBarHooks.enforceIconLimitations,
        'wysiwyg.viewer.components.QuickActionBarItem'
    )

    // Repeater
    hooks.registerHook(hooks.HOOKS.DATA.UPDATE_AFTER, repeaterHooks.syncRepeaterTemplateDataWithFirstItem)
    hooks.registerHook(hooks.HOOKS.DESIGN.UPDATE_AFTER, repeaterHooks.syncRepeaterTemplateDesignWithFirstItem)
    hooks.registerHook(hooks.HOOKS.REMOVE.BEFORE, repeaterHooks.beforeRemove)

    hooks.registerHook(
        hooks.HOOKS.REMOVE_COMP_MODE.BEFORE,
        masterPageHooks.removeOverridesPointingToMode,
        'wysiwyg.viewer.components.WSiteStructure'
    )

    // Tabs
    hooks.registerHook(hooks.HOOKS.PROPERTIES.UPDATE_BEFORE, tabsHooks.handleTabsPropertiesChange, 'wixui.Tabs')

    //general hooks
    hooks.registerHook(hooks.HOOKS.REMOVE.AFTER, componentPreviewStateHooks.removeComponentHook)
    //mobile hooks
    hooks.registerHook(hooks.HOOKS.REMOVE.AFTER, mobileComponentHooks.setMobileComponentAsDeadOnRemoval)
    hooks.registerHook(hooks.HOOKS.REMOVE.BEFORE, mobileComponentHooks.cleanupMobileOnlyChildren)
    hooks.registerHook(hooks.HOOKS.CHANGE_PARENT.AFTER, mobileComponentHooks.setMobileComponentAsDeadReparent)
    hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_AFTER, mobileComponentHooks.setMobileComponentAsTouched)
    hooks.registerHook(hooks.HOOKS.CHANGE_PARENT.AFTER, mobileComponentHooks.setMobileComponentAsTouched)
    hooks.registerHook(hooks.HOOKS.MOBILE_MERGE.BEFORE, mobileComponentHooks.cretatePresetForPage)

    // external components hooks
    hooks.registerExternalHook(hooks.HOOKS.PROPERTIES.UPDATE_BEFORE, externalComponentsHooks.beforeUpdateProperties)
    hooks.registerExternalHook(hooks.HOOKS.DATA.UPDATE_BEFORE, externalComponentsHooks.beforeUpdateData)
    hooks.registerExternalHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, externalComponentsHooks.beforeUpdateLayout)

    registerRefComponentHooks()

    inputsHooks()
}

const informExternalHookError = (hookName: string, componentType: string) =>
    contextAdapter.utils.fedopsLogger.captureError(
        new Error(`Could not register hook "${hookName}" for component "${componentType}"`),
        {
            tags: {
                hooksRegistration: true
            },
            extras: {
                componentType,
                hookName
            }
        }
    )

const registerExternalHook = (
    externalHookName: string,
    externalCallback: Callback2<any, any>,
    componentType: string
) => {
    const {hookName, hook} = externalComponentsHooks.createHookDefinition(externalHookName, externalCallback)
    if (hookName && hook) {
        hooks.registerExternalHook(hookName, hook, componentType)
    } else {
        informExternalHookError(hookName, componentType)
    }
}

const registerExternalHooks = (componentType: string, hooksToRegister) => {
    _.forEach(hooksToRegister, (callback, hookName) => registerExternalHook(hookName, callback, componentType))
}

export default {
    registerExternalHooks
}
