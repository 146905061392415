import type {PS} from '@wix/document-services-types'
import documentModeInfo from '../documentMode/documentModeInfo'
import structureUtils from './structureUtils'

interface Gap {
    aboveHeader: number
    abovePagesContainer: number
    aboveFooter: number
}

const siteGapMaps: Record<string, Gap> = {}

function getSiteGapMap(ps: PS): Gap {
    const viewMode = documentModeInfo.getViewMode(ps)

    const header = ps.pointers.components.getHeader(viewMode)
    const footer = ps.pointers.components.getFooter(viewMode)
    const pagesContainer = ps.pointers.components.getPagesContainer(viewMode)

    const headerLayout = structureUtils.getComponentLayout(ps, header)
    const pagesContainerLayout = structureUtils.getComponentLayout(ps, pagesContainer)
    const footerLayout = structureUtils.getComponentLayout(ps, footer)

    const headerBottom = headerLayout.y + headerLayout.height
    const pagesContainerBottom = pagesContainerLayout.y + pagesContainerLayout.height

    return {
        aboveHeader: headerLayout.y,
        abovePagesContainer: pagesContainerLayout.y - headerBottom,
        aboveFooter: footerLayout.y - pagesContainerBottom
    }
}

function createInitialGapMap(ps: PS): Gap {
    const siteId = ps.siteAPI.getSiteId()

    if (!siteGapMaps[siteId]) {
        siteGapMaps[siteId] = getSiteGapMap(ps)
    }

    return siteGapMaps[siteId]
}

function getInitialGapMap(ps: PS): Gap {
    return siteGapMaps[ps.siteAPI.getSiteId()]
}

export default {
    createInitialGapMap,
    getInitialGapMap
}
