import _ from 'lodash'
import * as dmCore from '@wix/document-manager-core'
import constants from '../constants/constants'
import dataModel from '../dataModel/dataModel'
import dataSerialization from '../dataModel/dataSerialization'
import relationsUtils from './relationsUtils'
import isSystemStyle from '../theme/isSystemStyle'
import mobileUtils from '../mobileUtilities/mobileUtilities'
import {asArray} from '@wix/document-manager-utils'
import dsUtils from '../utils/utils'
import * as jsonSchemas from '@wix/document-services-json-schemas'
import type {
    CompRef,
    CompVariantPointer,
    Pointer,
    PS,
    RemoveScopedAndNonScopedDataHandlers,
    VariantPointer
} from '@wix/document-services-types'
import {getIfExists} from '../utils/dalUtil'
import type {DalValue} from '@wix/document-manager-core'

const {
    namespaceMapping: {getNamespaceConfig}
} = jsonSchemas

const {DATA_TYPES, VARIANTS} = constants
const {pointerUtils} = dmCore

const validateNamespaceIsDefinedWithRelationalSplit = (namespace: string) => {
    const config = getNamespaceConfig(namespace)
    if (!config.isRelationalSplitFromQuery) {
        throw new Error(`${namespace} namespace should have isRelationalSplitFromQuery`)
    }
}

const getComponentVariantsData = (ps: PS, compPointerWithVariants, itemType) => {
    const pointerResolver = () => dataModel.getComponentDataPointerByType(ps, compPointerWithVariants, itemType)
    return getPointerVariantsData(ps, compPointerWithVariants, itemType, pointerResolver)
}

const getPointerVariantsData = (ps: PS, pointerWithVariants, itemType, refArrayOrValuePointerResolver) => {
    const refArrayOrValuePointer = refArrayOrValuePointerResolver()
    return {
        ...ps.extensionAPI.variants.getPointerVariantsData(pointerWithVariants, itemType, refArrayOrValuePointer),
        refArrayOrValuePointer
    }
}

const createCustomUpdater = (ps: PS) => {
    return (item: DalValue, ns: string, pageId: string, customId?: string): Pointer => {
        const id = dataSerialization.addSerializedItemToPage(ps, pageId, item, customId, ns)
        return pointerUtils.getPointer(id, ns)
    }
}

/**
 * update component data with 2 modes
 * 1. comp pointer with variants - it will create or override scoped value that referenced by the variants
 * 2. regular comp pointer - it will create or override non scoped value
 *
 * @param ps
 * @param {Pointer} compPointerWithVariants
 * @param {Object} valueToUpdate
 * @param {string} itemType
 */
const updateComponentDataConsideringVariants = (
    ps: PS,
    compPointerWithVariants: Pointer,
    valueToUpdate,
    itemType: string
) => {
    if (!compPointerWithVariants) return null
    const customUpdater = createCustomUpdater(ps)
    return ps.extensionAPI.variants.updateComponentDataConsideringVariants(
        compPointerWithVariants,
        valueToUpdate,
        itemType,
        customUpdater
    ).id
}

const updateDataConsideringVariants = (
    ps: PS,
    pointerWithVariants: VariantPointer,
    referenceName: string,
    valueToUpdate,
    namespace: string
) => {
    if (!pointerWithVariants) return null
    const customUpdater = createCustomUpdater(ps)
    const ptr = ps.extensionAPI.variants.updateDataConsideringVariants(
        pointerWithVariants,
        valueToUpdate,
        namespace,
        referenceName,
        customUpdater
    )
    return ptr.id
}

/**
 * get component data with 2 modes
 * 1. comp pointer with variants - it will get the scoped value that referenced by the variants
 * 2. regular comp pointer - it will get the non scoped value
 *
 * @param ps
 * @param {Pointer} compPointerWithVariants
 * @param {string} itemType
 * @param {boolean} deleteIds
 */
const getComponentDataConsideringVariants = (
    ps: PS,
    compPointerWithVariants: Pointer,
    itemType: string,
    deleteIds = false
) => {
    validateNamespaceIsDefinedWithRelationalSplit(itemType)
    const pointer = getComponentDataPointerConsideringVariants(ps, compPointerWithVariants, itemType)
    return pointer && dataModel.getDataByPointer(ps, itemType, pointer, deleteIds)
}

/**
 * get pointer data with 2 modes
 * 1. pointer with variants - it will get the scoped value that referenced by the variants
 * 2. regular pointer - it will get the non scoped value
 *
 * @param ps
 * @param {Pointer} pointerWithVariants
 * @param {string} referenceName
 * @param {string} namespace
 * @param {boolean} deleteIds
 */
const getDataConsideringVariants = (
    ps: PS,
    pointerWithVariants: Pointer,
    referenceName: string,
    namespace: string,
    deleteIds: boolean = false
) => {
    const pointer = getDataPointerConsideringVariants(ps, pointerWithVariants, referenceName, namespace)
    return pointer && dataModel.getDataByPointer(ps, namespace, pointer, deleteIds)
}

const getComponentDataPointerConsideringVariants = (ps: PS, compPointerWithVariants, itemType) => {
    if (!compPointerWithVariants) return null
    return ps.extensionAPI.variants.getComponentDataPointerConsideringVariants(compPointerWithVariants, itemType)
}

const getDataPointerConsideringVariants = (ps: PS, pointerWithVariants, referenceName, namespace) => {
    if (!pointerWithVariants) return null
    return ps.extensionAPI.variants.getDataPointerConsideringVariants(pointerWithVariants, namespace, referenceName)
}

/**
 * removing non scoped value
 * it will remove the whole ref array including relations
 *
 * @param {ps} ps
 * @param {RemoveScopedAndNonScopedDataHandlers} handlers
 */
const removeNonScoped = (ps: PS, handlers: RemoveScopedAndNonScopedDataHandlers) => {
    const {refArrayOrValuePointerResolver, removeRefArrayFromReferenceResolver} = handlers
    const refArrayOrValuePointer = refArrayOrValuePointerResolver()
    const refArrayOrValueData = getIfExists(ps, refArrayOrValuePointer, null)

    const isRefArray = dataModel.refArray.isRefArray(ps, refArrayOrValueData)
    if (isRefArray) {
        dataModel.removeItemRecursivelyByType(ps, refArrayOrValuePointer)
        removeRefArrayFromReferenceResolver()
    }
}

/**
 * remove value recursively the relation and scoped value
 * it will remove the ref array and the component ref to it if the ref array is empty after the removal
 *
 * @param {ps} ps
 * @param {Pointer} pointerWithVariants
 * @param {string} itemType
 * @param {RemoveScopedAndNonScopedDataHandlers} handlers
 */
const removeScoped = (
    ps: PS,
    pointerWithVariants: Pointer,
    itemType: string,
    handlers: RemoveScopedAndNonScopedDataHandlers
) => {
    const {refArrayOrValuePointerResolver} = handlers
    const {pageId, relationPointer, isRefArray} = getPointerVariantsData(
        ps,
        pointerWithVariants,
        itemType,
        refArrayOrValuePointerResolver
    )
    if (isRefArray && relationPointer) {
        relationsUtils.removeRelation(ps, relationPointer, itemType, pageId, handlers)
    }
}

/**
 * remove value recursively with 2 modes
 * 1. comp pointer with variants - it will remove relation and scoped value
 * 2. regular comp pointer - it will remove non scoped value
 * either way it will remove the ref array and the component ref to it if the ref array is empty after the removal
 *
 * @param ps
 * @param {Pointer} compPointerWithVariants
 * @param {string} itemType
 */
const removeComponentDataConsideringVariants = (ps: PS, compPointerWithVariants: Pointer, itemType: string) => {
    validateNamespaceIsDefinedWithRelationalSplit(itemType)
    const pointerResolver = () => dataModel.getComponentDataPointerByType(ps, compPointerWithVariants, itemType)
    const handlers = {
        refArrayOrValuePointerResolver: pointerResolver,
        removeRefArrayFromReferenceResolver: () =>
            dataModel.removeComponentDataByType(ps, compPointerWithVariants, itemType, true)
    }
    if (ps.pointers.components.isWithVariants(compPointerWithVariants)) {
        removeScoped(ps, compPointerWithVariants, itemType, handlers)
    } else {
        removeNonScoped(ps, handlers)
    }
}

/**
 * remove value recursively with 2 modes
 * 1. pointer with variants - it will remove relation and scoped value
 * 2. regular pointer - it will remove non scoped value
 * either way it will remove the ref array and the component ref to it if the ref array is empty after the removal
 *
 * @param ps
 * @param {Pointer} pointerWithVariants
 * @param {string} referenceName
 * @param {string} namespace
 */
const removeDataConsideringVariants = (
    ps: PS,
    pointerWithVariants: Pointer,
    referenceName: string,
    namespace: string
) => {
    if (!pointerWithVariants) return
    return ps.extensionAPI.variants.removeDataConsideringVariants(pointerWithVariants, namespace, referenceName)
}

/**
 * update current scoped data id to be a system style id
 */
const connectToThemeScoped = (ps: PS, compPointerWithVariants: CompRef, systemStyleId: string) => {
    const {pageId, refArrayOrValue, variants, relationPointer, isRefArray} = getComponentVariantsData(
        ps,
        compPointerWithVariants,
        DATA_TYPES.theme
    )

    if (!relationPointer) {
        const refArrayId = relationsUtils.addScopedValueToRelation(
            ps,
            compPointerWithVariants,
            systemStyleId,
            DATA_TYPES.theme,
            refArrayOrValue,
            variants,
            pageId
        )
        if (!isRefArray) {
            dataModel.linkComponentToItemByType(ps, compPointerWithVariants, refArrayId, DATA_TYPES.theme)
            mobileUtils.syncMobileAndDesktopByDataType(ps, compPointerWithVariants, DATA_TYPES.theme, refArrayId, true)
        }

        return
    }
    const scopedValuePointer = relationsUtils.getScopedValuePointerByVariants(
        ps,
        DATA_TYPES.theme,
        refArrayOrValue,
        variants,
        pageId,
        relationPointer
    )
    const isCustomStyle = scopedValuePointer && !isSystemStyle(ps, scopedValuePointer.id)
    if (isCustomStyle) {
        ps.dal.remove(scopedValuePointer)
    }

    ps.dal.set(ps.pointers.getInnerPointer(relationPointer, 'to'), `#${systemStyleId}`)
}

/**
 * update current non scoped data id to be a system style id
 *
 * @param ps
 * @param {Pointer} componentPointer
 * @param {string} systemStyleId
 */
const connectToThemeNonScoped = (ps: PS, componentPointer: Pointer, systemStyleId: string) => {
    const {pageId, refArrayOrValue, isRefArray, refArrayOrValuePointer} = getComponentVariantsData(
        ps,
        componentPointer,
        DATA_TYPES.theme
    )

    if (isRefArray) {
        const nonScopedValuePointer = relationsUtils.nonScopedValuePointer(
            ps,
            DATA_TYPES.theme,
            refArrayOrValue,
            pageId
        )
        const isCustomStyle = nonScopedValuePointer && !isSystemStyle(ps, nonScopedValuePointer.id)

        if (isCustomStyle) {
            ps.dal.remove(nonScopedValuePointer)
        }

        const currentValues = dataModel.refArray.extractValues(ps, refArrayOrValue)
        const currentValuesWithoutNonScopedValue = nonScopedValuePointer
            ? _.without(currentValues, `#${nonScopedValuePointer.id}`)
            : currentValues
        const newValues = [`#${systemStyleId}`, ...currentValuesWithoutNonScopedValue]
        ps.dal.set(ps.pointers.getInnerPointer(refArrayOrValuePointer, 'values'), newValues)
    }
}

const connectToThemeStyleConsideringVariants = (ps: PS, compPointerWithVariants, systemStyleId) => {
    if (!isSystemStyle(ps, systemStyleId)) {
        throw new Error('connectToThemeStyle called with custom style')
    }
    if (ps.pointers.components.isWithVariants(compPointerWithVariants)) {
        connectToThemeScoped(ps, compPointerWithVariants, systemStyleId)
    } else {
        connectToThemeNonScoped(ps, compPointerWithVariants, systemStyleId)
    }
}

/**
 * return true if pointer with variant or the component currently points to a refArray
 *
 * @param ps
 * @param {Pointer} componentPointer
 * @param {string} itemType
 * @returns {boolean}
 */
const shouldConsiderVariants = (ps: PS, componentPointer: Pointer, itemType: string): boolean => {
    const isWithVariants = ps.pointers.components.isWithVariants(componentPointer)
    const isCurrentDataRefArray = dataModel.isComponentPointsToRefArray(ps, componentPointer, itemType)
    return isWithVariants || isCurrentDataRefArray
}

/**
 * collect component's variants into variants input array
 *
 * @param {ps} ps privateServices
 * @param {Pointer} componentPointer
 */
const collectCompVariants = (ps: PS, componentPointer: Pointer) => {
    const variants = []
    const variantItems = ps.pointers.data.getVariantDataItemsByComponentId(componentPointer.id)
    _.forEach(variantItems, variant => {
        variants.push(variant.id)
        if (variant.type === constants.BREAKPOINTS_TYPES.DATA) {
            // @ts-expect-error
            const variantValuesWithNoHash = _.map(variant.values, value => dsUtils.stripHashIfExists(value))
            variants.push(...variantValuesWithNoHash)
        }
    })
    return variants
}

const getMasterPageVariants = (ps: PS, componentPointer: Pointer) => {
    const viewMode = ps.pointers.components.getViewMode(componentPointer)
    const masterPagePointer = ps.pointers.components.getPage('masterPage', viewMode)
    return collectCompVariants(ps, masterPagePointer)
}

/**
 * this function collect variants that are valid when performing repearent of component with variants
 * 1. collect all variants of new container and his ancestors + comp variants
 * 2. collect all variants from previous container and his ancestors + comp variants
 * 3. collect variants from masterPage, we don't need to remove relations that scoped to them
 * 4. filter the variants according to the relevant view port so that desktop related overrides won't remove when repearenting in mobile view
 * @param ps
 * @param componentPointer
 * @param newParentPointer
 * @param compChildren
 * @returns {string[]} validVariantIds
 */

const getValidVariantsForReparenting = (
    ps: PS,
    componentPointer: Pointer,
    newParentPointer: Pointer,
    compChildren
): string[] => {
    const isMobile = ps.pointers.components.isMobile(componentPointer)
    const shouldConsiderDesktopParentVariants =
        isMobile && !ps.extensionAPI.componentsMetadata.isMobileOnly(componentPointer)
    const newParentPointerOrExisting = newParentPointer ?? ps.pointers.components.getParent(componentPointer)
    const {getDesktopPointer, getMobilePointer} = ps.pointers.components

    const otherViewModeComp = isMobile ? getDesktopPointer(componentPointer) : getMobilePointer(componentPointer)
    const oldParentPointerOtherViewMode = ps.pointers.components.getParent(otherViewModeComp)

    const newParentVariantIds = collectAncestorsVariants(ps, newParentPointerOrExisting)
    const templateChildrenComponents = _.map(compChildren, pointerUtils.getRepeatedItemPointerIfNeeded)
    const componentsToCollectFrom = _(templateChildrenComponents).union(compChildren).uniqBy('id').value()
    const compAndChildrenVariantIds = _.map([...componentsToCollectFrom], comp => collectCompVariants(ps, comp))
    const masterPageVariantIds = getMasterPageVariants(ps, componentPointer)

    const oldParentVariantsIdsFromOtherViewMode = shouldConsiderDesktopParentVariants
        ? collectAncestorsVariants(ps, oldParentPointerOtherViewMode)
        : []

    return _([
        ...compAndChildrenVariantIds,
        ...newParentVariantIds,
        ...oldParentVariantsIdsFromOtherViewMode,
        ...masterPageVariantIds
    ])
        .flatten()
        .uniq()
        .value()
}

/**
 * this function removes variant overrides when reparenting a component with variant overrides
 * it deletes scoped data from the same viewMode that has variant that is not related anymore due to reparenting
 * @param {ps} ps
 * @param {Pointer} componentPointer
 * @param optionals
 */
function removeVariantsOverridesIfNeeded(
    ps: PS,
    componentPointer: Pointer,
    optionals: {newParentPointer?: CompRef; variantsReplacementOperations?: any[]} = {variantsReplacementOperations: []}
) {
    const {newParentPointer, variantsReplacementOperations} = optionals
    const isMobileOnly = !ps.dal.isExist(ps.pointers.components.getDesktopPointer(componentPointer))
    const compPointerIfItIsNotMobileOnly = !isMobileOnly
        ? ps.pointers.components.getDesktopPointer(componentPointer)
        : componentPointer
    const compChildren =
        ps.pointers.full.components.getChildrenRecursivelyRightLeftRootIncludingRoot(compPointerIfItIsNotMobileOnly)
    const validVariantIds = getValidVariantsForReparenting(ps, componentPointer, newParentPointer, compChildren)

    _.forEach(compChildren, compPointer => {
        _.forEach(VARIANTS.VALID_VARIANTS_DATA_TYPES, dataType =>
            relationsUtils.removeIllegalRelationsFromRefArray(
                ps,
                compPointer,
                newParentPointer,
                dataType,
                validVariantIds,
                variantsReplacementOperations
            )
        )
    })
}

/**
 * collect all variants of component and his ancestor returns all variants id's array
 *
 * @param {ps} ps privateServices
 * @param {Pointer} componentPointer
 * returns {string []} variants id's array
 */
const collectAncestorsVariants = (ps: PS, componentPointer: Pointer): string[] => {
    let compPointer = componentPointer ? pointerUtils.getRepeatedItemPointerIfNeeded(componentPointer) : undefined
    const variants = []

    while (compPointer) {
        const compVariants = collectCompVariants(ps, compPointer)
        variants.push(...compVariants)
        compPointer = ps.pointers.full.components.getParent(compPointer)
    }

    return variants
}

const getPointerWithVariants = (
    ps: PS,
    pointer: Pointer,
    variants: VariantPointer | VariantPointer[]
): CompVariantPointer => {
    if (!ps || !pointer || !variants) {
        throw new Error('invalid args')
    }
    const variantsArray = asArray(variants)
    return {...pointer, variants: _.unionWith(pointer.variants, variantsArray, _.isEqual)} as CompVariantPointer
}

export const getPointerWithoutVariants = (pointer: CompRef): CompRef => _.omit(pointer, 'variants')

export default {
    updateComponentDataConsideringVariants,
    connectToThemeStyleConsideringVariants,
    getComponentDataConsideringVariants,
    getComponentDataPointerConsideringVariants,
    removeComponentDataConsideringVariants,
    shouldConsiderVariants,
    removeVariantsOverridesIfNeeded,
    collectCompVariants,
    collectAncestorsVariants,
    getMasterPageVariants,
    updateDataConsideringVariants,
    removeDataConsideringVariants,
    getDataConsideringVariants,
    getPointerWithVariants,
    getPointerWithoutVariants,
    getDataPointerConsideringVariants
}
