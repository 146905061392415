import _ from 'lodash'
import * as conversionUtils from '../conversionUtils'
import {conversionConfig} from '../conversionConfig'
import {isModifiedComponent} from '../conversionUtils'
import type {IdToComponentMap, ConversionSettings, DeepStructure} from '../../types'

const setHeight = (component: DeepStructure, height) => {
    component.layout.height = height
}

const shouldSkipAdjustment = (component: DeepStructure, allModifiedComponents: IdToComponentMap) => {
    return (
        (isModifiedComponent(component) && !component.conversionData.isDirectParentOfMobileOnly) ||
        (allModifiedComponents.has(component.id) && !component.conversionData.isDirectParentOfMobileOnly)
    )
}

const getHorizontalMargin = (curComp: DeepStructure, container: DeepStructure): number => {
    if (_.get(curComp.conversionData, ['mobileHints', 'offsetOrigin']) === 'leftTop') {
        return _.get(curComp.conversionData, ['mobileHints', 'offsetX'], 0)
    }
    if (container.conversionData.isTransparentContainer && !conversionUtils.isColumnComponent(container)) {
        return 0
    }

    const compSpecificMarginX = container.conversionData.marginX
    if (_.isNumber(compSpecificMarginX)) {
        return compSpecificMarginX
    }
    return (
        (conversionUtils.shouldStretchToScreenWidth(container) ? conversionConfig.SITE_SEGMENT_PADDING_X : conversionConfig.COMPONENT_MOBILE_MARGIN_X) +
        _.get(container.conversionData, 'borderWidth', 0)
    )
}

const getOffsetX = (curComp, alignment, allowedWidth, marginRight, marginLeft, previousComponent): number => {
    if (_.get(curComp, ['conversionData', 'mobileHints', 'offsetOrigin']) === 'leftTop') {
        return _.get(previousComponent, ['layout', 'x'], 0) + _.get(curComp.conversionData, ['mobileHints', 'offsetX'], 0)
    }
    if (previousComponent && _.get(curComp, ['conversionData', 'mobileHints', 'offsetOrigin']) === 'rightTop') {
        return previousComponent.layout.x + previousComponent.layout.width + _.get(curComp.conversionData, ['mobileHints', 'offsetX'], 0)
    }
    if (previousComponent && _.get(curComp, ['conversionData', 'mobileHints', 'offsetOrigin']) === 'leftBottom') {
        return previousComponent.layout.x + _.get(curComp.conversionData, ['mobileHints', 'offsetX'], 0)
    }

    if (alignment === 'screen') {
        return 0
    }
    if (alignment === 'left') {
        return marginLeft
    }
    if (alignment === 'right') {
        return allowedWidth - curComp.layout.width - marginRight
    }
    return Math.round((allowedWidth - curComp.layout.width) / 2)
}

const getTopPadding = (parent: DeepStructure, targetParent: DeepStructure, firstComponent: DeepStructure) => {
    if (_.get(firstComponent, ['conversionData', 'mobileHints', 'offsetOrigin']) === 'leftTop') {
        return _.get(firstComponent.conversionData, ['mobileHints', 'offsetY'], 0)
    }

    if (parent.conversionData.hasTightYMargin || !firstComponent) {
        return 0
    }

    if (firstComponent.conversionData.isTransparentContainer) {
        const innerChildren = conversionUtils.getChildren(firstComponent)
        return getTopPadding(parent, targetParent, _.head(innerChildren))
    }
    /**
     * There should not be any gaps if first component is container
     */
    if (conversionUtils.shouldStretchToScreenWidth(firstComponent) && !firstComponent.layout.y) {
        return 0
    }
    const targetParentBorderSize = targetParent.conversionData.borderWidth || 0
    const paddingTop = conversionUtils.shouldStretchToScreenWidth(targetParent)
        ? conversionConfig.ROOT_COMPONENT_MARGIN_Y
        : conversionConfig.COMPONENT_MOBILE_MARGIN_Y

    return targetParentBorderSize + paddingTop
}

const getOrderedChildren = (container: DeepStructure): DeepStructure[] => {
    return _.sortBy(<DeepStructure[]>container.components, comp => (container.conversionData.componentsOrder || []).indexOf(comp.id))
}
/**
 * Rule how it apply sizes
 * - recomended - sizes from preset
 * - fixed - calculated size on DS
 * - calculated sizes
 * @param comp
 * @param allowedWidth
 * @param nettoAvailableSpace
 * @param isFullScreenWidthComp
 * @param settings
 * @returns
 */
const getCustomWidth = (
    comp: DeepStructure,
    allowedWidth: number,
    nettoAvailableSpace: number,
    isFullScreenWidthComp,
    settings?: ConversionSettings
): number | null => {
    const presetWidth = _.get(comp.conversionData, ['mobileHints', 'recommendedWidth'])
    let shouldApplyPresetWidth = !!presetWidth

    if (shouldApplyPresetWidth) {
        // Apply preset with only if it's not bigger than 320px
        shouldApplyPresetWidth = presetWidth <= conversionConfig.MOBILE_WIDTH
    }

    if (shouldApplyPresetWidth) {
        return presetWidth as number
    }

    const fixedWidth = _.get(comp.conversionData.fixedSize, 'width')

    if (conversionUtils.isGraphicComponent(comp) && fixedWidth) {
        return fixedWidth as number
    }

    if (isFullScreenWidthComp) {
        return allowedWidth
    }

    if (conversionUtils.isImageComponent(comp) && settings.imageScaleFactor !== 1) {
        return Math.min(nettoAvailableSpace, Math.round(comp.layout.width * settings.imageScaleFactor))
    }

    if (fixedWidth) {
        return Math.min(allowedWidth, +fixedWidth)
    }

    return null
}
/**
 * If component is container(strip/section) there should not be overlaps
 * @param DeepStructure component
 * @param number margin
 * @returns
 */
const getMarginForNextRowIfContainer = (component: DeepStructure, margin: number): number => {
    if (conversionUtils.isContainerComponent(component) || conversionUtils.isClassicSectionComponent(component)) {
        return margin > 0 ? margin : 0
    }
    return margin
}
/**
 * @public
 * @param DeepStructure previousComponent
 * @param DeepStructure component
 * @returns
 */
const getMarginForNextRow = (previousComponent: DeepStructure, component: DeepStructure, settings: ConversionSettings): number => {
    const offsetY = _.get(component, ['conversionData', 'mobileHints', 'offsetY'], 0)

    if (_.get(component, ['conversionData', 'mobileHints', 'offsetOrigin']) === 'leftBottom') {
        return offsetY
    }

    if (previousComponent && _.get(component, ['conversionData', 'mobileHints', 'offsetOrigin']) === 'rightTop') {
        const margin = offsetY - previousComponent.layout.height
        if (settings.enableImprovedMergeFlow) {
            return getMarginForNextRowIfContainer(component, margin)
        }
        return margin
    }

    if (previousComponent && _.get(component, ['conversionData', 'mobileHints', 'offsetOrigin']) === 'leftTop') {
        const margin = offsetY - previousComponent.layout.height
        if (settings.enableImprovedMergeFlow) {
            return getMarginForNextRowIfContainer(component, margin)
        }
        return margin
    }

    if (!component || component.conversionData?.tightWithPreviousSibling) {
        return 0
    }

    if (conversionUtils.isClassicSectionComponent(previousComponent) && conversionUtils.isClassicSectionComponent(component)) {
        return 0
    }

    if (conversionUtils.isColumnsContainerComponent(previousComponent) && conversionUtils.isColumnsContainerComponent(component)) {
        return 0
    }

    if (conversionUtils.isTextComponent(previousComponent) && !conversionUtils.isTextComponent(component)) {
        return conversionConfig.MARGIN_BETWEEN_TEXT_AND_NON_TEXT
    }

    return conversionConfig.COMPONENT_MOBILE_MARGIN_Y
}

const getScaleFactor = (comp, parentWidth) => (comp.layout.width > 0 ? parentWidth / comp.layout.width : 1)
const getRecommendedHeight = (component: DeepStructure): number => _.get(component.conversionData, ['mobileHints', 'recommendedHeight'])
const getRecommendedWidth = (component: DeepStructure): number => _.get(component.conversionData, ['mobileHints', 'recommendedWidth'])

export {
    getHorizontalMargin,
    getOrderedChildren,
    getTopPadding,
    getOffsetX,
    getMarginForNextRow,
    getCustomWidth,
    setHeight,
    shouldSkipAdjustment,
    getScaleFactor,
    getRecommendedHeight,
    getRecommendedWidth
}
