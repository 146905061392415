import type {ExtensionAPI} from '@wix/document-manager-core'
import type {
    Pointer,
    ConnectionList,
    CompRef,
    ControllerConnectionItem,
    AppControllerExternalRef,
    ControllerConnectionItemParsedConfig,
    ExternalsMap,
    ExternalsBuilderMap,
    AppControllerFieldsMap
} from '@wix/document-services-types'
import _ from 'lodash'
import type {RelationshipsAPI} from '../../../../relationships'
import type {StructureExtensionAPI} from '../../../../structure'
import {isDatasetAppController, type ExternalsHandler} from '../../constants'
import type {DetailedValue} from '@wix/import-export-utils'
import {defaultRefsHandler} from './default'
import type {AppControllerAPI} from '../../../../appController'

const addFieldNamesFromConfig = (connectionItem: ControllerConnectionItem, fields: AppControllerFieldsMap): void => {
    if (!connectionItem.config) return
    const config: ControllerConnectionItemParsedConfig = JSON.parse(connectionItem.config)
    const fieldNames: string[] = _.map(config?.properties, ({fieldName}) => fieldName)
    fieldNames.forEach((field: string) => (fields[field] = field))
}

const updateFieldNamesInConfig = (connectionItem: ControllerConnectionItem, fields: AppControllerFieldsMap): void => {
    if (!connectionItem.config) return
    const config: ControllerConnectionItemParsedConfig = JSON.parse(connectionItem.config)
    config.properties = _(config.properties)
        .pickBy(({fieldName}) => fields[fieldName])
        .mapValues(({fieldName}) => ({fieldName: fields[fieldName]}))
        .value()
    connectionItem.config = JSON.stringify(config)
}

const getReferencedItem = (item: ConnectionList, {path}: DetailedValue): ControllerConnectionItem => {
    return _.get(item, _.initial(path))
}

const removeReferencedItem = (item: ConnectionList, {path}: DetailedValue): void => {
    item.items.splice(+path[1], 1)
}

export const connectionListHandler: ExternalsHandler = {
    pack: (
        ref: DetailedValue,
        externals: ExternalsBuilderMap,
        refPointer: Pointer,
        item: ConnectionList,
        extensionAPI: ExtensionAPI
    ) => {
        const {relationships, components, appController} = extensionAPI as RelationshipsAPI &
            StructureExtensionAPI &
            AppControllerAPI
        if (!externals.appControllers[refPointer.id]) {
            const owningComponent = relationships.getOwningComponentToPointer(refPointer) as CompRef
            const componentType = components.getComponentType(owningComponent)
            const {applicationId, controllerType} = appController.getControllerTypeAndApplicationId(owningComponent)

            if (!isDatasetAppController(componentType, applicationId, controllerType)) {
                defaultRefsHandler.pack(ref, externals, refPointer, item, extensionAPI)
                return
            }
            externals.appControllers[refPointer.id] = {fields: {}, compPointer: owningComponent}
        }

        const {fields}: AppControllerExternalRef = externals.appControllers[refPointer.id]
        const connectionItem: ControllerConnectionItem = getReferencedItem(item, ref)
        addFieldNamesFromConfig(connectionItem, fields)
    },
    unpack: (ref: DetailedValue, externals: ExternalsMap, newId: string, item: ConnectionList) => {
        const appController = externals.appControllers[ref.value]
        if (!appController) {
            defaultRefsHandler.unpack(ref, externals, newId, item)
            return
        }

        const {fields, disconnect} = appController
        if (disconnect) {
            removeReferencedItem(item, ref)
            return
        }

        const connectionItem: ControllerConnectionItem = getReferencedItem(item, ref)
        connectionItem.controllerId = newId
        updateFieldNamesInConfig(connectionItem, fields)
    }
}
