import type {Callback, PS} from '@wix/document-services-types'
import _ from 'lodash'
import editorServerFacade from '../editorServerFacade/editorServerFacade'
import siteMetadata from './siteMetadata'
import bi from '../bi/bi'
import biErrors from '../bi/errors'

export type Password = {value: string; isHashed?: boolean} | string

function getPasswordProtectedPages(ps: PS) {
    return siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.PASSWORD_PROTECTED_PAGES) || []
}

function getSessionPages(ps: PS) {
    return siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.SESSION_PAGES_TO_HASH_PASSWORD) || {}
}

function setSessionPages(ps: PS, data) {
    return siteMetadata.setProperty(ps, siteMetadata.PROPERTY_NAMES.SESSION_PAGES_TO_HASH_PASSWORD, data)
}

function updatePasswordProtectedPages(ps: PS, pageId: string, hasPassword: boolean) {
    const currentProtectedPages = getPasswordProtectedPages(ps)
    const nextProtectedPages = hasPassword
        ? _.union(currentProtectedPages, [pageId])
        : _.difference(currentProtectedPages, [pageId])
    siteMetadata.setProperty(ps, siteMetadata.PROPERTY_NAMES.PASSWORD_PROTECTED_PAGES, nextProtectedPages)
}

function isPageProtected(ps: PS, pageId: string) {
    const passwordProtectedPages = getPasswordProtectedPages(ps)
    return _.includes(passwordProtectedPages, pageId)
}

function setPageToNoRestriction(ps: PS, pageId: string) {
    setPagePassword(ps, pageId, {value: null})
}

function setPagePassword(ps: PS, pageId: string, originalPassword: Password) {
    const password = _.isObject(originalPassword) ? originalPassword.value : originalPassword
    if (_.isNull(password)) {
        return _syncSiteMetadata(ps, pageId, null)
    }

    const wasPageProtectedBeforeAjaxRequest = isPageProtected(ps, pageId)
    updatePasswordProtectedPages(ps, pageId, true)

    const payload = {
        siteId: siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.SITE_ID),
        metaSiteId: siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.META_SITE_ID),
        pageId,
        password
    }
    sendRequest(
        ps,
        editorServerFacade.ENDPOINTS.PASSWORD_PROTECTION,
        pageId,
        wasPageProtectedBeforeAjaxRequest,
        payload
    )
}

function updatePasswordProtectedMaps(ps: PS, sessionPages, pageId: string, sourcePageId: string) {
    sessionPages[pageId] = sessionPages[sourcePageId]
    setSessionPages(ps, sessionPages)
    const currentProtectedPages = getPasswordProtectedPages(ps)
    const nextProtectedPages = _.union(currentProtectedPages, [pageId])
    siteMetadata.setProperty(ps, siteMetadata.PROPERTY_NAMES.PASSWORD_PROTECTED_PAGES, nextProtectedPages)
}

function duplicatePagePassword(
    ps: PS,
    pageId: string,
    sourcePageId: string,
    successCallback?: Callback,
    errorCallback?: Callback
) {
    if (!isPageProtected(ps, sourcePageId)) {
        if (errorCallback) {
            errorCallback()
        }
        return
    }
    const sessionPages = getSessionPages(ps)
    if (_.has(sessionPages, sourcePageId)) {
        updatePasswordProtectedMaps(ps, sessionPages, pageId, sourcePageId)
        if (successCallback) {
            successCallback()
        }
        return
    }
    const wasPageProtectedBeforeAjaxRequest = isPageProtected(ps, pageId)
    updatePasswordProtectedPages(ps, pageId, true)
    const payload = {
        siteId: siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.SITE_ID),
        metaSiteId: siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.META_SITE_ID),
        pageId,
        sourcePageId
    }
    sendRequest(
        ps,
        editorServerFacade.ENDPOINTS.PASSWORD_PROTECTION_DUPLICATE,
        pageId,
        wasPageProtectedBeforeAjaxRequest,
        payload,
        successCallback,
        errorCallback
    )
}

function sendRequest(
    ps: PS,
    url: string,
    pageId: string,
    wasPageProtectedBeforeAjaxRequest: boolean,
    payload,
    successCallback?: Callback,
    errorCallback?: Callback
) {
    editorServerFacade.sendWithPs(
        ps,
        url,
        payload,
        function success(data) {
            if (data.result) {
                _syncSiteMetadata(ps, pageId, data.result)
                if (successCallback) {
                    successCallback()
                }
            } else {
                _handleError(
                    ps,
                    pageId,
                    wasPageProtectedBeforeAjaxRequest,
                    data.errorCode,
                    data.errorDescription,
                    errorCallback
                )
            }
        },
        function error(jqXHR, textStatus) {
            _handleError(ps, pageId, wasPageProtectedBeforeAjaxRequest, -1, textStatus, errorCallback)
        }
    )
}

function _syncSiteMetadata(ps: PS, pageId: string, passwordToSet) {
    const sessionPages = getSessionPages(ps)
    sessionPages[pageId] = passwordToSet
    siteMetadata.setProperty(ps, siteMetadata.PROPERTY_NAMES.SESSION_PAGES_TO_HASH_PASSWORD, sessionPages)
    updatePasswordProtectedPages(ps, pageId, passwordToSet)
}

function _handleError(
    ps: PS,
    pageId: string,
    wasPageProtectedBeforeAjaxRequest: boolean,
    errorCode: number,
    errorDescription: string,
    errorCallback: Callback
) {
    _rollback(ps, pageId, wasPageProtectedBeforeAjaxRequest)
    bi.error(ps, biErrors.PASSWORD_PROTECTION_HASH_GENERATION_FAILED, {
        serverErrorCode: errorCode,
        errorDescription
    })
    if (errorCallback) {
        errorCallback()
    }
    throw new Error(
        `Remote error, cannot update password for page with id ${pageId}: ${errorDescription}. Error code: ${errorCode}`
    )
}

function _rollback(ps: PS, pageId: string, wasPageProtectedBeforeAjaxRequest: boolean) {
    updatePasswordProtectedPages(ps, pageId, wasPageProtectedBeforeAjaxRequest)
}

function isPagesProtectionOnServerOn() {
    return true
}

export default {
    getPasswordProtectedPages,
    isPageProtected,
    setPagePassword,
    duplicatePagePassword,
    setPageToNoRestriction,
    isPagesProtectionOnServerOn
}
