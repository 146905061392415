import baseConfig from './baseConfig'

const modulesList: string[] = []

const extensionsList: string[] = [...baseConfig.extensionsList]

export default {
    modulesList,
    extensionsList,
    shouldRender: true,
    isReadOnly: true,
    noUndo: false,
    runStylesGC: false,
    supportMobile: true,
    postUpdateOperation: true,
    disableCommonConfig: true,
    continuousSave: false,
    cedit: false,
    dontCollectFixerVersionData: true,
    disableCSaveValidationOnInitialization: true,
    keepMultiLingualModelsFromServer: true,
    skipFixers: true
}
