import _ from 'lodash'
import ebayItemsBySellerMetaData from './components/ebayItemsBySellerMetaData'
import verticalAnchorsMenuMetaData from './components/verticalAnchorsMenuMetaData'
import adminLoginButtonMetaData from './components/adminLoginButtonMetaData'
import bgImageStripMetaData from './components/bgImageStripMetaData'
import contactFormMetaData from './components/contactFormMetaData'
import facebookLikeBoxMetaData from './components/facebookLikeBoxMetaData'
import facebookLikeMetaData from './components/facebookLikeMetaData'
import facebookShareMetaData from './components/facebookShareMetaData'
import fiveGridLineMetaData from './components/fiveGridLineMetaData'
import flickrBadgeWidgetMetaData from './components/flickrBadgeWidgetMetaData'
import headerMetaData from './components/headerMetaData'
import footerMetaData from './components/footerMetaData'
import imageButtonMetaData from './components/imageButtonMetaData'
import itunesButtonMetaData from './components/itunesButtonMetaData'
import linkBarMetaData from './components/linkBarMetaData'
import loginSocialBarMetadata from './components/loginSocialBarMetadata'
import onlineClockMetaData from './components/onlineClockMetaData'
import pageGroupMetaData from './components/pageGroupMetaData'
import pageMetaData from './components/pageMetaData'
import groupMetaData from './components/groupMetaData'
import flashComponentMetaData from './components/flashComponentMetaData'
import pagesContainerMetaData from './components/pagesContainerMetaData'
import payPalButtonMetaData from './components/payPalButtonMetaData'
import pinItPinWidgetMetaData from './components/pinItPinWidgetMetaData'
import pinterestFollowMetaData from './components/pinterestFollowMetaData'
import pinterestPinItMetaData from './components/pinterestPinItMetaData'
import rssButtonMetaData from './components/rssButtonMetaData'
import screenWidthContainerMetaData from './components/screenWidthContainerMetaData'
import stripContainerMetaData from './components/stripContainerMetaData'
import singleAudioPlayerMetaData from './components/singleAudioPlayerMetaData'
import siteButtonMetaData from './components/siteButtonMetaData'
import siteSegmentContainerMetaData from './components/siteSegmentContainerMetaData'
import siteRegionContainerMetaData from './components/siteRegionContainerMetaData'
import siteStructureMetaData from './components/siteStructureMetaData'
import skypeCallButtonMetaData from './components/skypeCallButtonMetaData'
import slideShowGalleryMetaData from './components/slideShowGalleryMetaData'
import thumbnailsMetaData from './components/thumbnailsMetaData'
import accordionMetaData from './components/accordionMetaData'
import htmlComponentMetaData from './components/htmlComponentMetaData'
import googleMapMetaData from './components/googleMapMetaData'
import spotifyFollowMetaData from './components/spotifyFollowMetaData'
import subscribeFormMetaData from './components/subscribeFormMetaData'
import svgShapeMetaData from './components/svgShapeMetaData'
import vectorImageMetaData from './components/vectorImageMetaData'
import tinyMenuMetaData from './components/tinyMenuMetaData'
import backToTopButtonLegacyMetaData from './components/backToTopButtonLegacyMetaData'
import backToTopButtonMetaData from './components/backToTopButtonMetaData'
import verticalLineMetaData from './components/verticalLineMetaData'
import vKShareButtonMetaData from './components/vKShareButtonMetaData'
import weatherMetaData from './components/weatherMetaData'
import wFacebookCommentMetaData from './components/wFacebookCommentMetaData'
import disqusCommentsMetaData from './components/disqusCommentsMetaData'
import radioGroupMetaData from './components/radioGroupMetaData'
import checkboxGroupMetaData from './components/checkboxGroupMetaData'
import wGooglePlusOneMetaData from './components/wGooglePlusOneMetaData'
import wPhotoMetaData from './components/wPhotoMetaData'
import wTwitterFollowMetaData from './components/wTwitterFollowMetaData'
import wTwitterTweetMetaData from './components/wTwitterTweetMetaData'
import youTubeSubscribeButtonMetaData from './components/youTubeSubscribeButtonMetaData'
import tpaWidgetMetaData from './components/tpaWidgetMetaData'
import tpaGluedWidgetMetaData from './components/tpaGluedWidgetMetaData'
import tpaSectionMetaData from './components/tpaSectionMetaData'
import appPageMetaData from './components/appPageMetaData'
import appPartMetaData from './components/appPartMetaData'
import appPart2MetaData from './components/appPart2MetaData'
import anchorMetaData from './components/anchorMetaData'
import masonryMetaData from './components/masonryMetaData'
import freestyleMetaData from './components/freestyleMetaData'
import stripGallerySlideShowMetaData from './components/stripGallerySlideShowMetaData'
import stripShowCaseMetaData from './components/stripShowCaseMetaData'
import collageMetaData from './components/collageMetaData'
import threeDCarouselMetaData from './components/3DCarouselMetaData'
import videoMetaData from './components/videoMetaData'
import matrixGallery from './components/matrixGalleryMetaData'
import documentMedia from './components/documentMediaMetaData'
import spotifyPlayer from './components/spotifyPlayerMetaData'
import soundCloudMetaData from './components/soundCloudMetaData'
import wRichTextMetaData from './components/wRichTextMetaData'
import verticalMenuMetaData from './components/verticalMenuMetaData'
import expandableMenuMetaData from './components/expandableMenuMetaData'
import dropDownMenuMetaData from './components/dropDownMenuMetaData'
import sliderGalleryMetaData from './components/sliderGalleryMetaData'
import PaginatedGridGalleryMetaData from './components/PaginatedGridGalleryMetaData'
import containerMetaData from './components/containerMetaData'
import popupContainerMetaData from './components/popupContainerMetaData'
import audioPlayerMetaData from './components/audioPlayerMetaData'
import clipArtMetaData from './components/clipArtMetaData'
import boxSlideShowSlideMetaData from './components/boxSlideShowSlideMetaData'
import boxSlideShowMetaData from './components/boxSlideShowMetaData'
import stateBoxStateMetaData from './components/stateBoxStateMetaData'
import stateBoxFormStateMetaData from './components/stateBoxFormStateMetaData'
import stateBoxMetaData from './components/stateBoxMetaData'
import stateStripStateMetaData from './components/stateStripStateMetaData'
import stateStripMetaData from './components/stateStripMetaData'
import stripSlideShowSlideMetaData from './components/stripSlideShowSlideMetaData'
import quickActionBarMetaData from './components/quickActionBarMetaData'
import quickActionBarItemMetaData from './components/quickActionBarItemMetaData'
import stripSlideShowMetaData from './components/stripSlideShowMetaData'
import stripColumnsMetaData from './components/stripColumnsMetaData'
import textInputMetadata from './components/textInputMetadata'
import textAreaMetadata from './components/textAreaMetadata'
import fileUploaderMetaData from './components/fileUploaderMetaData'
import checkboxMetadata from './components/checkboxMetadata'
import datePickerMetadata from './components/datePickerMetadata'
import columnMetadata from './components/columnMetaData'
import mediaPlayerMetadata from './components/mediaPlayerMetaData'
import mediaControlsMetadata from './components/mediaControlsMetaData'
import mediaOverlayControlsMetadata from './components/mediaOverlayControlsMetaData'
import controllerMetaData from './components/controllerMetaData'
import wixappsOnlyMetaData from './components/wixappsOnlyMetaData'
import hoverboxMetaData from './components/hoverboxMetaData'
import popoverMetaData from './components/popoverMetaData'
import repeaterMetaData from './components/repeaterMetaData'
import mediaContainerMetaData from './components/mediaContainerMetaData'
import gridMetaData from './components/gridMetaData'
import languageSelectorMetaData from './components/languageSelectorMetaData'
import formContainerMetaData from './components/formContainerMetaData'
import appWidgetMetaData from './components/appWidgetMetaData'
import inlinePopupToggleMetaData from './components/inlinePopupToggleMetaData'
import menuToggleMetaData from './components/menuToggleMetaData'
import menuContainerMetaData from './components/menuContainerMetaData'
import sectionMetaData from './components/sectionMetaData'
import refComponentMetaData from './components/refComponentMetaData'
import classicSectionMetadata from './components/classicSectionMetadata'

// compType -> attributesMap -> attribute -> value/function
export default _.defaults({
    'wysiwyg.viewer.components.HeaderContainer': headerMetaData,
    'wysiwyg.viewer.components.EbayItemsBySeller': ebayItemsBySellerMetaData,
    'wysiwyg.viewer.components.FooterContainer': footerMetaData,
    'wysiwyg.viewer.components.AdminLoginButton': adminLoginButtonMetaData,
    'wysiwyg.viewer.components.BgImageStrip': bgImageStripMetaData,
    'wysiwyg.viewer.components.ContactForm': contactFormMetaData,
    'wysiwyg.viewer.components.DynamicContactForm': contactFormMetaData,
    'wysiwyg.common.components.facebooklikebox.viewer.FacebookLikeBox': facebookLikeBoxMetaData,
    'wysiwyg.viewer.components.WFacebookLike': facebookLikeMetaData,
    'wysiwyg.viewer.components.FacebookShare': facebookShareMetaData,
    'wysiwyg.viewer.components.FiveGridLine': fiveGridLineMetaData,
    'wysiwyg.viewer.components.FlickrBadgeWidget': flickrBadgeWidgetMetaData,
    'wysiwyg.common.components.imagebutton.viewer.ImageButton': imageButtonMetaData,
    'wysiwyg.viewer.components.ItunesButton': itunesButtonMetaData,
    'wysiwyg.viewer.components.LinkBar': linkBarMetaData,
    'wysiwyg.viewer.components.LoginSocialBar': loginSocialBarMetadata,
    'wysiwyg.common.components.onlineclock.viewer.OnlineClock': onlineClockMetaData,
    'wysiwyg.viewer.components.PageGroup': pageGroupMetaData,
    'mobile.core.components.Page': pageMetaData,
    'wysiwyg.viewer.components.Group': groupMetaData,
    'wysiwyg.viewer.components.FlashComponent': flashComponentMetaData,
    'wysiwyg.viewer.components.PagesContainer': pagesContainerMetaData,
    'wysiwyg.viewer.components.PayPalButton': payPalButtonMetaData,
    'wysiwyg.common.components.pinitpinwidget.viewer.PinItPinWidget': pinItPinWidgetMetaData,
    'wysiwyg.viewer.components.PinterestFollow': pinterestFollowMetaData,
    'wysiwyg.common.components.pinterestpinit.viewer.PinterestPinIt': pinterestPinItMetaData,
    'wysiwyg.common.components.rssbutton.viewer.RSSButton': rssButtonMetaData,
    'wysiwyg.viewer.components.ScreenWidthContainer': screenWidthContainerMetaData,
    'wysiwyg.viewer.components.StripContainer': stripContainerMetaData,
    'wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer': singleAudioPlayerMetaData,
    'wysiwyg.viewer.components.SiteButton': siteButtonMetaData,
    'wysiwyg.viewer.components.SiteSegmentContainer': siteSegmentContainerMetaData,
    'wysiwyg.viewer.components.SiteRegionContainer': siteRegionContainerMetaData,
    'wysiwyg.viewer.components.WSiteStructure': siteStructureMetaData,
    'wysiwyg.common.components.skypecallbutton.viewer.SkypeCallButton': skypeCallButtonMetaData,
    'wysiwyg.viewer.components.SlideShowGallery': slideShowGalleryMetaData,
    'tpa.viewer.components.Thumbnails': thumbnailsMetaData,
    'tpa.viewer.components.Accordion': accordionMetaData,
    'wysiwyg.viewer.components.HtmlComponent': htmlComponentMetaData,
    'wysiwyg.viewer.components.GoogleMap': googleMapMetaData,
    'wysiwyg.common.components.spotifyfollow.viewer.SpotifyFollow': spotifyFollowMetaData,
    'wysiwyg.common.components.subscribeform.viewer.SubscribeForm': subscribeFormMetaData,
    'wysiwyg.viewer.components.svgshape.SvgShape': svgShapeMetaData,
    'wysiwyg.viewer.components.VectorImage': vectorImageMetaData,
    'wysiwyg.viewer.components.mobile.TinyMenu': tinyMenuMetaData,
    'wysiwyg.common.components.backtotopbutton.viewer.BackToTopButton': backToTopButtonLegacyMetaData,
    'wysiwyg.viewer.components.BackToTopButton': backToTopButtonMetaData,
    'wysiwyg.viewer.components.VerticalLine': verticalLineMetaData,
    'wysiwyg.viewer.components.VKShareButton': vKShareButtonMetaData,
    'wysiwyg.common.components.weather.viewer.Weather': weatherMetaData,
    'wysiwyg.viewer.components.WFacebookComment': wFacebookCommentMetaData,
    'wysiwyg.common.components.disquscomments.viewer.DisqusComments': disqusCommentsMetaData,
    'wysiwyg.viewer.components.WGooglePlusOne': wGooglePlusOneMetaData,
    'wysiwyg.viewer.components.WPhoto': wPhotoMetaData,
    'wysiwyg.viewer.components.ClipArt': clipArtMetaData,
    'wysiwyg.viewer.components.WTwitterFollow': wTwitterFollowMetaData,
    'wysiwyg.viewer.components.WTwitterTweet': wTwitterTweetMetaData,
    'wysiwyg.common.components.youtubesubscribebutton.viewer.YouTubeSubscribeButton': youTubeSubscribeButtonMetaData,
    'wysiwyg.viewer.components.tpapps.TPAWidget': tpaWidgetMetaData,
    'tpa.viewer.components.tpapps.TPAWidget': tpaWidgetMetaData, // alias
    'wysiwyg.viewer.components.tpapps.TPASection': tpaSectionMetaData,
    'wysiwyg.viewer.components.tpapps.TPAGluedWidget': tpaGluedWidgetMetaData,
    'tpa.viewer.components.tpapps.TPAGluedWidget': tpaGluedWidgetMetaData, // alias
    'wysiwyg.viewer.components.tpapps.TPAMultiSection': tpaSectionMetaData,
    'wixapps.integration.components.AppPage': appPageMetaData,
    'wixapps.integration.components.AppPart': appPartMetaData,
    'wixapps.integration.components.AppPart2': appPart2MetaData,
    'wysiwyg.common.components.anchor.viewer.Anchor': anchorMetaData,
    'tpa.viewer.components.StripSlideshow': stripGallerySlideShowMetaData,
    'tpa.viewer.components.StripShowcase': stripShowCaseMetaData,
    'tpa.viewer.components.Collage': collageMetaData,
    'tpa.viewer.components.Masonry': masonryMetaData,
    'tpa.viewer.components.Freestyle': freestyleMetaData,
    'wysiwyg.viewer.components.tpapps.TPA3DCarousel': threeDCarouselMetaData,
    'wysiwyg.viewer.components.Video': videoMetaData,
    'wysiwyg.viewer.components.MatrixGallery': matrixGallery,
    'wysiwyg.viewer.components.documentmedia.DocumentMedia': documentMedia,
    'wysiwyg.common.components.spotifyplayer.viewer.SpotifyPlayer': spotifyPlayer,
    'wysiwyg.viewer.components.SoundCloudWidget': soundCloudMetaData,
    'wysiwyg.viewer.components.WRichText': wRichTextMetaData,
    'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu': verticalMenuMetaData,
    'wysiwyg.viewer.components.ExpandableMenu': expandableMenuMetaData,
    'wysiwyg.viewer.components.menus.DropDownMenu': dropDownMenuMetaData,
    'wysiwyg.viewer.components.SliderGallery': sliderGalleryMetaData,
    'wysiwyg.viewer.components.PaginatedGridGallery': PaginatedGridGalleryMetaData,
    'mobile.core.components.Container': containerMetaData,
    'wysiwyg.viewer.components.PopupContainer': popupContainerMetaData,
    'wysiwyg.viewer.components.PopupCloseTextButton': siteButtonMetaData,
    'wysiwyg.viewer.components.AudioPlayer': audioPlayerMetaData,
    'wysiwyg.viewer.components.BoxSlideShowSlide': boxSlideShowSlideMetaData,
    'wysiwyg.viewer.components.BoxSlideShow': boxSlideShowMetaData,
    'wysiwyg.viewer.components.StateBoxState': stateBoxStateMetaData,
    'wysiwyg.viewer.components.StateBoxFormState': stateBoxFormStateMetaData,
    'wysiwyg.viewer.components.StateBox': stateBoxMetaData,
    'wysiwyg.viewer.components.StateStripState': stateStripStateMetaData,
    'wysiwyg.viewer.components.StateStrip': stateStripMetaData,
    'wysiwyg.viewer.components.StripContainerSlideShowSlide': stripSlideShowSlideMetaData,
    'wysiwyg.viewer.components.QuickActionBar': quickActionBarMetaData,
    'wysiwyg.viewer.components.QuickActionBarItem': quickActionBarItemMetaData,
    'wysiwyg.viewer.components.StripContainerSlideShow': stripSlideShowMetaData,
    'wysiwyg.viewer.components.StripColumnsContainer': stripColumnsMetaData,
    'wysiwyg.viewer.components.ClassicSection': classicSectionMetadata,
    'wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu': verticalAnchorsMenuMetaData,
    'wysiwyg.viewer.components.inputs.TextInput': textInputMetadata,
    'wysiwyg.viewer.components.inputs.TextAreaInput': textAreaMetadata,
    'wysiwyg.viewer.components.inputs.FileUploader': fileUploaderMetaData,
    'wysiwyg.viewer.components.inputs.ComboBoxInput': textInputMetadata,
    'wysiwyg.viewer.components.inputs.Checkbox': checkboxMetadata,
    'wysiwyg.viewer.components.inputs.DatePicker': datePickerMetadata,
    'wysiwyg.viewer.components.Column': columnMetadata,
    'wysiwyg.viewer.components.MediaControls': mediaControlsMetadata,
    'wysiwyg.viewer.components.MediaOverlayControls': mediaOverlayControlsMetadata,
    'wysiwyg.viewer.components.MediaPlayer': mediaPlayerMetadata,
    'platform.components.AppController': controllerMetaData,
    'wysiwyg.viewer.components.ImageButtonWithText': wixappsOnlyMetaData,
    'wysiwyg.viewer.components.inputs.RadioButton': textInputMetadata,
    'wysiwyg.viewer.components.HoverBox': hoverboxMetaData,
    'wysiwyg.viewer.components.Popover': popoverMetaData,
    'wysiwyg.viewer.components.inputs.RadioGroup': radioGroupMetaData,
    'wysiwyg.viewer.components.Repeater': repeaterMetaData,
    'wysiwyg.viewer.components.MediaContainer': mediaContainerMetaData,
    'wysiwyg.viewer.components.Grid': gridMetaData,
    'wysiwyg.viewer.components.LanguageSelector': languageSelectorMetaData,
    'wysiwyg.viewer.components.FormContainer': formContainerMetaData,
    'wysiwyg.viewer.components.inputs.CheckboxGroup': checkboxGroupMetaData,
    'wysiwyg.viewer.components.InlinePopupToggle': inlinePopupToggleMetaData,
    'wysiwyg.viewer.components.MenuToggle': menuToggleMetaData,
    'wysiwyg.viewer.components.MenuContainer': menuContainerMetaData,
    'platform.components.AppWidget': appWidgetMetaData,
    'wysiwyg.viewer.components.RefComponent': refComponentMetaData,
    'responsive.components.Section': sectionMetaData,
    'responsive.components.HeaderSection': sectionMetaData,
    'responsive.components.FooterSection': sectionMetaData,
    'responsive.components.MembersAreaSection': sectionMetaData
})
