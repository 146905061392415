import type {Pointer, PS} from '@wix/document-services-types'
import componentStructureInfo from '../component/componentStructureInfo'

const GROUP_COMPONENT_TYPE = 'wysiwyg.viewer.components.Group'

function getGroupComponentType() {
    return GROUP_COMPONENT_TYPE
}

function isGroup(ps: PS, compPointer: Pointer) {
    return componentStructureInfo.getType(ps, compPointer) === GROUP_COMPONENT_TYPE
}

function isGroupedComponent(ps: PS, compPointer: Pointer) {
    const parentPointer = componentStructureInfo.getContainer(ps, compPointer)
    return isGroup(ps, parentPointer)
}

export default {
    isGroup,
    isGroupedComponent,
    getGroupComponentType
}
