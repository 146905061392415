// TODO: This code is for editor - so we'll fix these variables later
import type {Callback1} from '@wix/document-services-types'

const callbacks: Callback1<any>[] = []
let callId = 1

const init = function () {
    if (typeof window !== 'undefined') {
        window.addEventListener('message', receiver, false)
    }
}

const doPostMessage = function (msgType: string, params?, compId?: string, callback?: Callback1<any>) {
    const blob = getBlob(msgType, params, compId, callback)

    let target: {postMessage(message: string, targetOrigin: string): void} | undefined
    if (window.parent.postMessage) {
        target = window.parent
        // @ts-expect-error
    } else if (window.parent.document.postMessage) {
        target = window.parent.document as any
    }

    if (target && typeof target !== 'undefined') {
        target.postMessage(JSON.stringify(blob), '*')
    }
}

const getBlob = function (msgType: string, params, compId: string, onResponseCallback: Callback1<any>) {
    const blob = {
        intent: 'TPA_PREVIEW',
        callId: callId++,
        type: msgType,
        compId,
        data: params
    }

    if (onResponseCallback) {
        callbacks[blob.callId] = onResponseCallback
    }

    return blob
}

const receiver = function (event) {
    let data
    try {
        data = JSON.parse(event.data)
    } catch (e) {
        return
    }

    switch (data.intent) {
        case 'TPA_RESPONSE':
        case 'TPA_PREVIEW':
        case 'TPA_PREVIEW_RESPONSE':
            if (data.callId && callbacks[data.callId]) {
                callbacks[data.callId](data.res)
                delete callbacks[data.callId]
            }
            break
    }
}

const addCallback = function (cbId: number, callback: Callback1<any>) {
    callbacks[cbId] = callback
}

export default {
    addCallback,
    init,
    doPostMessage
}
