const _ = require('lodash')
const coreUtils = require('@wix/santa-core-utils')

/**
 *
 * @param {object} structure - page structure
 * @param {string} compType - component type to look for
 * @returns {{mobileComps: Array, desktopComps: Array, mobileOnlyComponentsIds: object, components: Record<string,import('./index').ComponentStructure>}} mobileComps, desktopComps, mobileOnlyComponentsIds, allUniqueComps
 * @description returns all components of a given type in the page structure
 */
function getAllComponentsOfType(structure, compType) {
    function getAllCompsInStructure(isMobile) {
        return coreUtils.dataUtils.getAllCompsInStructure(structure, isMobile, comp => comp.componentType === compType)
    }
    const mobileComps = getAllCompsInStructure(true)
    const desktopComps = getAllCompsInStructure(false)
    const mobileCompsIds = _.map(mobileComps, 'id')
    const desktopCompsIds = _.map(desktopComps, 'id')
    const mobileOnlyComponentsIds = _.difference(mobileCompsIds, desktopCompsIds)

    const components = {...mobileComps, ...desktopComps}

    return {mobileComps, desktopComps, mobileOnlyComponentsIds, components}
}

module.exports = getAllComponentsOfType
