/**
 *
 * @param {object} options
 * @param {string} options.propertiesType
 * @param {import('./index').ParsedStyleItem['parsedStyle'][0]} options.prevStyle
 * @param {import('./index').ParsedStyleItem['parsedStyle']} options.currentStyle
 * @param {string} options.selector
 * @returns
 */
function getProperties({propertiesType, prevStyle, currentStyle, selector}) {
    const currentSelectorProperties = currentStyle?.[selector]?.[propertiesType]
    const prevSelectorProperties = prevStyle[propertiesType]

    if (prevSelectorProperties && currentSelectorProperties) {
        const filteredProperties = prevSelectorProperties.filter(prevProperty => {
            return !currentSelectorProperties.some(property => prevProperty.prop === property.prop)
        })

        return [...filteredProperties, ...currentSelectorProperties]
    }

    return prevSelectorProperties || currentSelectorProperties
}

/**
 * @param {Array<import('./index').ParsedStyleItem>} styleItems
 * @returns {Array<import('./index').ParsedStyleItem>}
 */
function mergeStylesByBreakpoints(styleItems) {
    /** @type {import('./index').ParsedStyleItem['parsedStyle'] | null} */
    let prevBreakpointStyle = null

    styleItems.sort((a, b) => b.metadata.max - a.metadata.max)

    return styleItems.map(styleItem => {
        const {parsedStyle} = styleItem

        if (prevBreakpointStyle) {
            const mergedParsedStyle = Object.entries(prevBreakpointStyle).reduce(
                (mergedStyle, [prevSelector, prevStyle]) => {
                    return {
                        ...mergedStyle,
                        [prevSelector]: {
                            ...prevStyle,
                            properties: getProperties({
                                propertiesType: 'properties',
                                prevStyle,
                                currentStyle: parsedStyle,
                                selector: prevSelector
                            }),
                            mobileProperties: getProperties({
                                propertiesType: 'mobileProperties',
                                prevStyle,
                                currentStyle: parsedStyle,
                                selector: prevSelector
                            })
                        }
                    }
                },
                {}
            )

            prevBreakpointStyle = mergedParsedStyle

            return {
                ...styleItem,
                parsedStyle: mergedParsedStyle
            }
        }

        prevBreakpointStyle = parsedStyle
        return styleItem
    })
}

module.exports = mergeStylesByBreakpoints
