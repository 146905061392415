import type {PS} from '@wix/document-services-types'
import tpaPostMessageService, {TpaMsg} from './services/tpaPostMessageService'
import clientSpecMapService from './services/clientSpecMapService'
import experimentService from './services/experimentService'

const handlers = function (ps: PS, msg: TpaMsg, callback) {
    tpaPostMessageService.callHandler(ps, msg, callback)
}

const initEditor = function (ps: PS, isInDevMode: boolean, experiments?) {
    clientSpecMapService.setIsInDevMode(isInDevMode)
    experimentService.setExperiments(experiments)
}

export default {
    handlers,
    initEditor
}
