import type {DAL} from '@wix/document-manager-core'
import type {extensions} from '@wix/document-manager-extensions'
import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'

type Relationships = extensions.relationships.RelationshipsAPI['relationships']

interface Connection {
    from: Pointer
    to: Pointer
}

const pointerString = ({id, type}: Pointer): string => `"${id}\\n${type}"`

const connectionString = ({from, to}: Connection): string => `${pointerString(from)} -> ${pointerString(to)};`

const reverse = ({from, to}: Connection): Connection => ({from: to, to: from})

const collect = (
    pointer: Pointer,
    dal: DAL,
    relationships: Relationships,
    stopAtTop: boolean,
    collector: (p: Pointer) => Pointer[]
): Connection[] => {
    if ((pointer.type === 'DESKTOP' && stopAtTop) || !dal.get(pointer)) {
        return []
    }
    const directReferences = _.reject(collector(pointer), {type: 'MOBILE'})
    const directConnections: Connection[] = directReferences.map(p => ({from: pointer, to: p}))
    const indirectConnections: Connection[] = _.flatMap(directReferences, p =>
        collect(p, dal, relationships, true, collector)
    )
    return directConnections.concat(indirectConnections)
}

const getConnectionsUp = (pointer: Pointer, dal: DAL, relationships: Relationships): Connection[] =>
    collect(pointer, dal, relationships, false, p => relationships.getReferencesToPointer(p)).map(reverse)

const getConnectionsDown = (pointer: Pointer, dal: DAL, relationships: Relationships): Connection[] =>
    collect(pointer, dal, relationships, false, p => relationships.getReferredPointers(p, false))

const getConnections = (pointer: Pointer, dal: DAL, relationships: Relationships): Connection[] => {
    const down = getConnectionsDown(pointer, dal, relationships)
    const up = getConnectionsUp(pointer, dal, relationships)
    const all = down.concat(up)
    return _.uniqBy(all, connectionString)
}

const colorPointer = (pointer: Pointer, color: string): string =>
    `${pointerString(pointer)} [fillcolor = ${color}] [style = filled];`

const brokenPointers = (dal: DAL, connections: Connection[]): Pointer[] =>
    _(connections)
        .flatMap(({from, to}) => [from, to])
        .reject(p => dal.get(p))
        .value()

const colorText = (pointer: Pointer, connections: Connection[], dal: DAL): string => {
    const broken = brokenPointers(dal, connections)
    const colors = broken.map(p => colorPointer(p, 'red'))
    colors.push(colorPointer(pointer, 'green'))
    return colors.join('\n')
}

const createDotText = (pointer: Pointer, dal: DAL, relationships: Relationships): string => {
    const connections = getConnections(pointer, dal, relationships)
    const connectionsText = _(connections).map(connectionString).join('\n')
    const colors = colorText(pointer, connections, dal)
    return `digraph {\n${connectionsText}\n${colors}\n}`
}

const openGraphWindow = (pointer: Pointer, dal: DAL, relationships: Relationships): void => {
    const text = createDotText(pointer, dal, relationships)
    window.open(`https://ronyhe.github.io/dot/?${encodeURIComponent(text)}`, '_blank')
}

/** Return the data needed for the new graph tool
 * This is the store that the dal returned from getMergeStoreAsJson
 * But with references added explicitly to the metaData
 *
 * documentServicesModel and basedOnSignature are ignored
 */
const createGraphData = (dal: DAL, relationships: Relationships): any => {
    const store = dal._getMergedStoreAsJson()
    for (const ignore of ['documentServicesModel', 'basedOnSignature']) {
        delete store[ignore]
    }
    for (const namespace of Object.keys(store)) {
        for (const id of Object.keys(store[namespace])) {
            const value = store[namespace][id]!
            const pointer = {id, type: namespace}
            const pointsTo = relationships.getReferredPointers(pointer, false)
            store[namespace][id] = {
                ...value,
                metaData: {
                    ...value.metaData,
                    // @ts-ignore - `pointsTo` is, correctly, not in the type. So we ts-ignore
                    pointsTo
                }
            }
        }
    }
    return store
}

function downloadGraphDataForChad(dal: DAL, relationships: Relationships) {
    const data = createGraphData(dal, relationships)
    const a = document.createElement('a')
    const file = new Blob([JSON.stringify(data, null, 4)], {type: 'application/json'})
    a.href = URL.createObjectURL(file)
    a.download = 'graphData.json'
    a.click()
    a.remove()
}

export {downloadGraphDataForChad, openGraphWindow}
