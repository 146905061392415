/* eslint no-mixed-operators:0 */
import type {Layout, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import component from '../../component/component'
import documentModeInfo from '../../documentMode/documentModeInfo'

const headerCompLayout = function (placement, layout: Layout, headerLayout) {
    const compLayout = {
        y: headerLayout.height / 2 - layout.height / 2,
        width: layout.width,
        height: layout.height
    }
    switch (placement) {
        case 'LEFT':
            return _.merge(compLayout, {
                x: 0
            })
        case 'RIGHT':
            return _.merge(compLayout, {
                x: headerLayout.width - layout.width
            })
        //also CENTER
        default:
            return _.merge(compLayout, {
                x: headerLayout.width / 2 - layout.width / 2
            })
    }
}

const footerCompLayout = function (placement, layout: Layout, pageLayout: Layout, footerLayout: Layout) {
    const compLayout = {
        y: pageLayout.height + footerLayout.height / 2 - layout.height / 2,
        width: layout.width,
        height: layout.height
    }
    switch (placement) {
        case 'LEFT':
            return _.merge(compLayout, {
                x: 0
            })
        case 'RIGHT':
            return _.merge(compLayout, {
                x: footerLayout.width - layout.width
            })
        //also CENTER
        default:
            return _.merge(compLayout, {
                x: footerLayout.width / 2 - layout.width / 2
            })
    }
}

const getScreenYCenter = function (layout: Layout, pageLayout: Layout) {
    return (window.innerHeight - layout.height) / 2 + window.pageYOffset - pageLayout.y
}

const pageCompLayout = function (placement, layout: Layout, pageLayout: Layout) {
    const compLayout = {
        width: layout.width,
        height: layout.height
    }
    switch (placement) {
        case 'TOP_LEFT':
            return _.merge(compLayout, {
                x: 0,
                y: 0
            })
        case 'TOP_CENTER':
            return _.merge(compLayout, {
                x: pageLayout.width / 2 - layout.width / 2,
                y: 0
            })
        case 'TOP_RIGHT':
            return _.merge(compLayout, {
                x: pageLayout.width - layout.width,
                y: 0
            })
        case 'CENTER_LEFT':
            return _.merge(compLayout, {
                x: 0,
                y: getScreenYCenter(layout, pageLayout)
            })
        case 'CENTER_RIGHT':
            return _.merge(compLayout, {
                x: pageLayout.width - layout.width,
                y: getScreenYCenter(layout, pageLayout)
            })
        case 'BOTTOM_LEFT':
            return _.merge(compLayout, {
                x: 0,
                y: pageLayout.height - layout.height
            })
        case 'BOTTOM_CENTER':
            return _.merge(compLayout, {
                x: pageLayout.width / 2 - layout.width / 2,
                y: pageLayout.height - layout.height
            })
        case 'BOTTOM_RIGHT':
            return _.merge(compLayout, {
                x: pageLayout.width - layout.width,
                y: pageLayout.height - layout.height
            })
        //also center
        default:
            return _.merge(compLayout, {
                x: pageLayout.width / 2 - layout.width / 2,
                y: getScreenYCenter(layout, pageLayout)
            })
    }
}

const getCompLayoutFrom = function (ps: PS, layout, optionsLayout?, optionsContainer?: Pointer) {
    const doesOptionsLayoutHavePosition =
        optionsLayout && isValidPositionValue(optionsLayout.x) && isValidPositionValue(optionsLayout.y)
    if (doesOptionsLayoutHavePosition) {
        let {x, y} = optionsLayout
        if (!_.isNumber(x)) {
            x = parseInt(x, 10)
        }
        if (!_.isNumber(y)) {
            y = parseInt(y, 10)
        }
        return {
            width: optionsLayout.width,
            height: optionsLayout.height,
            x,
            y
        }
    }

    const viewMode = documentModeInfo.getViewMode(ps)

    const pageContainer = ps.pointers.components.getPagesContainer(viewMode)
    const pageLayout = component.layout.get(ps, pageContainer)
    optionsLayout = optionsLayout || {}
    layout.width = optionsLayout.width || layout.width
    layout.height = optionsLayout.height || layout.height

    if (layout.defaultPosition) {
        const {region} = layout.defaultPosition

        if (region === 'header') {
            const header = ps.pointers.components.getHeader(viewMode)
            const headerLayout = component.layout.get(ps, header)
            return headerCompLayout(layout.defaultPosition.placement, layout, headerLayout)
        }

        if (region === 'footer') {
            const footer = ps.pointers.components.getFooter(viewMode)
            const footerLayout = component.layout.get(ps, footer)
            return footerCompLayout(layout.defaultPosition.placement, layout, pageLayout, footerLayout)
        }

        const layoutAgainstPage = pageCompLayout(layout.defaultPosition.placement, layout, pageLayout)

        if (optionsContainer) {
            const containerLayout = component.layout.get(ps, optionsContainer)
            const y = layoutAgainstPage.y - containerLayout.y

            const layoutAgaintContainer = {
                ...layoutAgainstPage,
                // pageLayout means it's pagesContainer's layout
                // this ternary handles header case, when y becomes negative
                // relative to pagesContainer
                y: y < 0 ? y + pageLayout.y : y
            }
            return layoutAgaintContainer
        }

        //also pageContainer i.e., region is page
        return layoutAgainstPage
    }

    const pageX = pageLayout.width / 2 - layout.width / 2
    const pageY = getScreenYCenter(layout, pageLayout)

    if (optionsContainer) {
        const containerLayout = component.layout.get(ps, optionsContainer)
        const y = pageY - containerLayout.y

        return {
            width: layout.width,
            height: layout.height,
            x: pageX,
            // pageLayout means it's pagesContainer's layout
            // this ternary handles header case, when y becomes negative
            // relative to pagesContainer
            y: y < 0 ? y + pageLayout.y : y
        }
    }

    return {
        width: layout.width,
        height: layout.height,
        x: pageX,
        y: pageY
    }
}

const isValidPositionValue = (val: number) => val !== null && !isNaN(val)

export default {
    getCompLayoutFrom
}
