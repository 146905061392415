import {DocumentDataTypes, Extension, pointerUtils} from '@wix/document-manager-core'
const {getPointer} = pointerUtils
const pointerType = 'save'

const createPointersMethods = () => {
    const getPublishSaveInnerPointer = () => getPointer('publishSaveInitiator', pointerType)
    const getSilentSaveInnerPointer = () => getPointer('silentSaveInitiator', pointerType)
    const getMobileConversionHeuristicStrategy = () => getPointer('mobileConversionHeuristicStrategy', pointerType)
    const getOrphanPermanentDataNodes = () => getPointer('orphanPermanentDataNodes', pointerType)

    const saveInterface = {
        getPublishSaveInnerPointer,
        getSilentSaveInnerPointer,
        getMobileConversionHeuristicStrategy,
        getOrphanPermanentDataNodes
    }

    return {
        general: saveInterface,
        save: saveInterface
    }
}

const getDocumentDataTypes = (): DocumentDataTypes => ({[pointerType]: {}})

const initialState = {
    [pointerType]: {
        orphanPermanentDataNodes: [] as any[]
    }
}

/**
 * @returns {Extension}
 */
const createExtension = (): Extension => ({
    name: 'save',
    createPointersMethods,
    getDocumentDataTypes,
    initialState
})

export {createExtension}
