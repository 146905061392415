import type {FedopsLogger, RuntimeConfig} from '@wix/document-services-types'
import _ from 'lodash'
import type {CoreLogger} from '@wix/document-manager-core'
import {warmupUtils, utils, AjaxOp} from '@wix/santa-ds-libs'

const {cookieUtils} = utils
const {ajaxLibrary} = warmupUtils

export type DocumentServicesImplementation = typeof import('@wix/document-services-implementation')

const injectContextAdapter = (
    dsImplementation: DocumentServicesImplementation,
    logger: CoreLogger,
    runtimeConfig: RuntimeConfig
) => {
    const {isReadOnly} = runtimeConfig

    const sendHttpRequest = (
        url: string,
        type: string,
        jsonData: Record<string, any>,
        body: any,
        success: Function,
        error: Function
    ) => {
        const headers = _.get(jsonData, ['headers'], {})
        headers['X-XSRF-TOKEN'] = cookieUtils.getCookie('XSRF-TOKEN')
        if (isReadOnly && type !== 'GET') {
            const readOnlyErr = new Error("this operation isn't allowed while in read only mode")
            if (error) {
                return error(readOnlyErr)
            }
            throw readOnlyErr
        }
        ajaxLibrary.ajax(_.merge(jsonData, {url, type, headers, data: body, success, error}) as AjaxOp)
    }

    dsImplementation.contextAdapter.actions = {
        sendHttpRequest
    }
    dsImplementation.contextAdapter.documentServicesHandlersThatOverridesViewerHandlers = {
        smCurrentMember: true,
        refreshCurrentMember: true,
        getValue: true,
        getValues: true
    }

    dsImplementation.contextAdapter.viewerName = runtimeConfig.viewerName

    dsImplementation.contextAdapter.utils = {
        fedopsLogger: logger as unknown as FedopsLogger
    }
}

export {injectContextAdapter}
