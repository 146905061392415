import _ from 'lodash'
import pathUtils from './pathUtils'
import hooks from '../../hooks/hooks'
import lists from '../services/lists'
import type {CompRef, PS} from '@wix/document-services-types'

const APP_PART_2_COMP_TYPE = 'wixapps.integration.components.AppPart2'

function afterDeleteList(
    ps: PS,
    compRef: CompRef,
    deletingParent: any,
    removeArgs: any,
    deletedParentFromFull: any,
    dataItem: any
) {
    if (compRef.type === 'MOBILE') {
        return {success: true, description: 'Skipped deleting part views when deleting mobile component'}
    }

    const {appPartName} = dataItem
    const listDef = lists.getListDef(ps, appPartName)
    if (!listDef) {
        return {success: true, description: 'Missing Part definition: views were not deleted'}
    }

    const views = ps.wixappsDAL.getByPath(pathUtils.getBaseViewsPath())

    _(views)
        .pickBy({name: listDef.viewName})
        .forEach(function (view, viewKey) {
            ps.wixappsDAL.removeByPath(pathUtils.getViewPath(viewKey))
        })

    return {success: true, description: 'Deleted Part views'}
}

function registerHooks() {
    hooks.registerHook(hooks.HOOKS.REMOVE.AFTER, afterDeleteList, APP_PART_2_COMP_TYPE)
}

export default {
    registerHooks
}
