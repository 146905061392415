import _ from 'lodash'
import {ajaxLibrary, AjaxOp} from '@wix/santa-ds-libs'
import type {ImmutableSnapshot} from '../../types'
import fetchResponseErrorObject from './fetchResponseErrorObject'
import type {SnapshotDal} from '@wix/document-manager-core'

const requestTypes = {
    POST: 'POST',
    GET: 'GET',
    PUT: 'PUT',
    DELETE: 'DELETE'
}

function sendRequest(url: string, type: string, data) {
    const params: any = {
        type,
        url,
        dataType: 'json',
        contentType: 'application/json'
    }

    if (data) {
        params.data = JSON.stringify(data)
    }

    return sendRequestObj(params)
}

function sendRequestObj(request: AjaxOp): Promise<any> {
    return new Promise<any>(function (resolve, reject) {
        request.success = resolve
        request.error = function (xhr, errorType /*, error*/) {
            const error = fetchResponseErrorObject.build({
                errorType,
                xhr
            })
            // @ts-expect-error
            error.request = request

            reject(error)
        }

        if (request.data && !_.isString(request.data)) {
            request.data = JSON.stringify(request.data)
        }

        ajaxLibrary.ajax(request)
    })
}

/***
 * @description Extracts data from the snapshot in the given path.
 * @param snapshot The snapshot- ImmutableJS object
 * @param pathArray The path elements to traverse in order to get to the requested data
 * @param isCompound true if the expected data is not a simple type (this will call toJS() before returning the result)
 * @returns {any}
 */
function extractFromSnapshot(snapshot: ImmutableSnapshot, pathArray: string[], isCompound = false) {
    let ret = snapshot.getIn(pathArray)
    if (isCompound && ret) {
        ret = ret.toJS()
    }
    return ret
}

function extractFromSnapshotDal(snapshotDal: SnapshotDal, pathArray: string[]) {
    const [type, id, ...innerPath] = pathArray
    return snapshotDal.getValue({type, id, innerPath})
}

export default {
    sendRequest,
    sendRequestObj,
    extractFromSnapshot,
    extractFromSnapshotDal,
    requestTypes
}
