import type {PS} from '@wix/document-services-types'
import appStudioDataModel from './appStudioDataModel'

const setAppDescription = (ps: PS, description?: string) => {
    const appStudioData = appStudioDataModel.getAppStudioData(ps)
    appStudioData.description = description
    appStudioDataModel.updateAppStudioMetaData(ps, appStudioData)
}

const clearAppDescription = (ps: PS) => setAppDescription(ps)

const getAppDescription = (ps: PS) => appStudioDataModel.getAppStudioData(ps).description

export default {
    set: setAppDescription,
    get: getAppDescription,
    clear: clearAppDescription
}
