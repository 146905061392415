import errorObject from './errorObject'
import {ReportableError} from '@wix/document-manager-utils'

const errorTypes = {
    ABORT: 'abort',
    TIMEOUT: 'timeout',
    PARSE: 'parseerror'
} as const

function _buildTimeoutError() {
    const error = new ReportableError({
        message: 'Request timed out',
        errorType: 'FetchTimeoutError',
        tags: {
            source: 'fetchErrorRespondObject'
        }
    })
    error.name = 'RequestTimeoutError'
    return error
}

const isFailedFetch = (errorType: string, xhr) =>
    errorType === errorTypes.ABORT || (!errorType && xhr && (xhr.name === 'TypeError' || xhr.name === 'AbortError'))

const createError = ({errorType, xhr}: {errorType: string; xhr}) => {
    if (isFailedFetch(errorType, xhr)) {
        return errorObject.buildAbortedError()
    } else if (errorType === errorTypes.TIMEOUT) {
        return _buildTimeoutError()
    }

    return errorObject.buildServerError(xhr.status, xhr.response)
}

function build({errorType, xhr}: {errorType: string; xhr}) {
    // At this point "xhr" can unfortunately be either one of:
    // - zepto XHR object (maybe not anymore since Santa was killed?)
    // - fetch "Response" object (for any server errors in Bolt)
    // - Error object, if "fetch" call in Bolt threw an error (e.g. "TypeError: Failed to fetch")
    //
    // "errorType" is "undefined" in Bolt, and zepto-style string in Santa

    const error = createError({errorType, xhr})
    // @ts-expect-error
    error.xhr = xhr
    // @ts-expect-error
    error.errorCode = safeGetErrorCode(error)

    return error
}

function safeGetErrorCode(error) {
    try {
        const errorObj = JSON.parse(error.xhr.response)
        return errorObj.errorCode
    } catch (e) {
        return null
    }
}

function safeGetStatusCode(error) {
    try {
        return error.xhr.status
    } catch (e) {
        return null
    }
}

export default {
    build,
    errorTypes,
    safeGetErrorCode,
    safeGetStatusCode
}
