import _ from 'lodash'
import reordering from '../../../accessibility/reordering'
import type {Pointer, PS} from '@wix/document-services-types'
import type {DalValue, SnapshotDal} from '@wix/document-manager-core'
import autoDomReorderData from '../../../accessibility/autoDomReorderData'
import {constants, PageExtensionAPI} from '@wix/document-manager-extensions'

const {VIEW_MODES, DATA_TYPES} = constants
const REORDER_NAMESPACES = (Object.values(VIEW_MODES) as string[]).concat(DATA_TYPES.slots)

const isChanged = (item: DalValue, namespace: string, fromSnapshot: SnapshotDal) => {
    if (item?.type !== 'Page') {
        return true
    }

    const prevItem = fromSnapshot.getValue({type: namespace, id: item.id})
    const relevantProperties = ['layout', 'components']
    return !_.isEqual(_.pick(item, relevantProperties), _.pick(prevItem, relevantProperties))
}

export default async function (ps: PS) {
    const shouldReorderDOM = autoDomReorderData.get(ps)
    if (shouldReorderDOM) {
        const changedPagesIds = (ps.extensionAPI as PageExtensionAPI).page
            .getNonDeletedChangedPagePointersSinceLastSnapshot('reordering', REORDER_NAMESPACES, isChanged)
            .map((pointer: Pointer) => pointer.id)
        ps.dal.takeSnapshot('reordering')
        //TODO reordering receives onSuccess and onFailure sent functions are meaningless, need to change to BI event.
        await reordering.reorderComponents(ps, changedPagesIds)
    }
}
