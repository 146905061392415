import type {PS} from '@wix/document-services-types'
import actionsAndBehaviors from '../../actionsAndBehaviors/actionsAndBehaviors'
import documentInfo from '../../documentMode/documentModeInfo'
import page from '../../page/page'
import popupUtils from '../../page/popupUtils'

function getContainerToAddTo(ps: PS, containerPointer) {
    if (ps.pointers.components.isPage(containerPointer) && page.popupPages.isPopup(ps, containerPointer.id)) {
        return popupUtils.getPopupContainer(ps, containerPointer)
    }

    return containerPointer
}

function removeDeadBehaviors(ps: PS, popupPointer) {
    const isPopup = page.popupPages.isPopup(ps, popupPointer.id)
    if (!isPopup) {
        return
    }

    const viewMode = documentInfo.getViewMode(ps)
    const pagePointers = page.getPageIdList(ps, true).map(pageId => ps.pointers.components.getPage(pageId, viewMode))

    pagePointers.forEach(function (pagePointer) {
        actionsAndBehaviors.removeComponentsBehaviorsWithFilter(ps, pagePointer, {
            behavior: {targetId: popupPointer.id}
        })
    })
}

function removeBehaviorsFromAddedPage(ps: PS, pagePointer) {
    actionsAndBehaviors.removeComponentsBehaviorsWithFilter(ps, pagePointer, {behavior: {name: 'openPopup'}})
}

export default {
    getContainerToAddTo,
    removeDeadBehaviors,
    removeBehaviorsFromAddedPage
}
