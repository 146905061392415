import type {PublicMethodUtils} from '@wix/document-services-types'
import {partners} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            partners: {
                isPotentialPartnerAsync: publicMethodUtils.defineGetter(partners.isPotentialPartnerAsync)
            }
        },
        initMethod: partners.initState
    }
}
