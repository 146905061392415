import type {PublicMethodUtils} from '@wix/document-services-types'
import {slots} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            components: {
                slots: {
                    populate: publicMethodUtils.defineDataManipulationAction(slots.populate, {
                        getReturnValue: slots.getComponentToAddPointer
                    }),
                    moveToSlot: publicMethodUtils.defineDataManipulationAction(slots.moveToSlot),
                    remove: publicMethodUtils.defineDataManipulationAction(slots.remove),
                    getSlotNames: publicMethodUtils.defineGetter(slots.getSlotNames),
                    getSlotsData: publicMethodUtils.defineGetter(slots.getSlotsData),
                    getWidgetSlots: publicMethodUtils.defineGetter(slots.getWidgetSlots),
                    isChildOfSlottedComp: publicMethodUtils.defineGetter(slots.isChildOfSlottedComp)
                }
            }
        }
    }
}
