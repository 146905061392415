/**
 * Created by noamr on 26/04/2017.
 */
import _ from 'lodash'
import * as conversionUtils from '../conversionUtils'
import {conversionConfig} from '../conversionConfig'
import {createRescaleGroup} from '../virtualGroupHandler'
import type {ConversionSettings, DeepStructure} from '../../types'

export function analyzeProportionalGroups(page: DeepStructure, comps: DeepStructure[], settings: ConversionSettings): void {
    identifyOverlayingComponentsAsGroups(page, comps)
    replaceOverlayingGroupedComponentsToVirtualGroups(page, comps, settings)
    cleanUpVirtualGroupsIdentifiers(page, comps)
}

function isComponentSuitableForProportionGrouping(comp: DeepStructure): boolean {
    return (
        !conversionUtils.isRescaleVirtualGroup(comp) &&
        _.get(comp, ['conversionData', 'textLength'], 0) <= conversionConfig.TEXT_MAX_LENGTH_FOR_RESCALING &&
        _.get(comp, ['conversionData', 'isSuitableForProportionGrouping'], false) &&
        _.every(comp.components, isComponentSuitableForProportionGrouping)
    )
}

function identifyOverlayingComponentsAsGroups(parent, comps) {
    if (_.isEmpty(parent.components)) {
        return
    }
    parent.proportionGroups = parent.proportionGroups || []
    const groups = []
    const componentClone = _.cloneDeep(parent)
    const childrenClone = conversionUtils.getChildren(componentClone)

    while (childrenClone.length > 0) {
        let overlayingComponents = getCompsOverlayingWithComp(childrenClone[0], childrenClone)

        if (overlayingComponents.length === 0) {
            _.remove(conversionUtils.getChildren(componentClone), childrenClone[0])
            continue
        }

        conversionUtils.removeChildrenFrom(componentClone, overlayingComponents)
        overlayingComponents.forEach(comp => {
            const moreOverlayingComponents = getCompsOverlayingWithComp(comp, childrenClone)
            overlayingComponents = overlayingComponents.concat(moreOverlayingComponents)
            conversionUtils.removeChildrenFrom(componentClone, moreOverlayingComponents)
        })

        if (overlayingComponents.length > 1) {
            groups.push(_.map(overlayingComponents, 'id'))
        }
    }

    parent.proportionGroups = [...parent.proportionGroups, ...groups]
    _.forEach(comps, comp => identifyOverlayingComponentsAsGroups(comp, comp.components))
}

function replaceOverlayingGroupedComponentsToVirtualGroups(parent, comps, settings: ConversionSettings) {
    const findById = id => _.find(parent.components, {id})
    _.forEach(parent.proportionGroups, groupCompIds => {
        if (!_.every(groupCompIds, findById)) {
            return
        }

        const groupComps = <DeepStructure[]>conversionUtils.getComponentsByIds(parent, groupCompIds)
        createRescaleGroup(parent, groupComps, {rescaleMethod: 'proportional'}, settings)
    })

    _.forEach(comps, comp => replaceOverlayingGroupedComponentsToVirtualGroups(comp, comp.components, settings))
}

function getCompsOverlayingWithComp(comp, comps) {
    if (!isComponentSuitableForProportionGrouping(comp)) {
        return []
    }
    return _.filter(
        comps,
        (comp2: DeepStructure) => isComponentSuitableForProportionGrouping(comp2) && conversionUtils.haveSufficientOverlap(comp, comp2, 0.25)
    )
}

function cleanUpVirtualGroupsIdentifiers(parent, comps) {
    delete parent.proportionGroups
    _.forEach(comps, comp => cleanUpVirtualGroupsIdentifiers(comp, comp.components))
}
