import type {DalValue, DocumentManager} from '@wix/document-manager-core'
import _ from 'lodash'
import type {Pointer} from '@wix/document-services-types'
import {removePrefix} from '@wix/document-manager-utils'
import type {PageMigrator} from '../dataMigrationRunner'

type Resolver = (itemData: DalValue) => boolean
type Predicate = (itemData: DalValue) => boolean

interface RefConfig {
    namespace: string
    resolvers: Resolver[]
    predicate?: Predicate
}

const migratePage = ({dal, pointers, experimentInstance}: DocumentManager, pageId: string) => {
    const typesPredicate = (dataTypes: string[]) => (value: DalValue) =>
        dataTypes?.length ? dataTypes.includes(value.type) : true

    const hasModeOverrides = (value: DalValue) => value?.modes?.overrides?.length

    const copyFromOtherNamespaceIfMissing =
        (field: string, toNamespace: string, copyNamespace: string): Resolver =>
        (itemData: DalValue) => {
            if (itemData?.[field]) {
                const id = removePrefix(itemData[field], '#')
                const ptr = pointers.getPointer(id, toNamespace)
                if (!dal.has(ptr)) {
                    const copyItemData = dal.get({id: itemData.id, type: copyNamespace})
                    if (copyItemData?.[field]) {
                        itemData[field] = copyItemData[field]
                    } else {
                        delete itemData[field]
                    }
                    return true
                }
            }

            return false
        }

    const removeIfMissing =
        (field: string, toNamespace: string): Resolver =>
        (itemData: DalValue) => {
            if (itemData?.[field]) {
                const id = removePrefix(itemData[field], '#')
                const ptr = pointers.getPointer(id, toNamespace)
                if (!dal.has(ptr)) {
                    delete itemData[field]
                    return true
                }
            }

            return false
        }

    const removeFromArrayIfMissing =
        (field: string, toNamespace: string, deleteWhenEmpty: boolean): Resolver =>
        (itemData: DalValue) => {
            if (itemData?.[field] && Array.isArray(itemData?.[field])) {
                const startLength = itemData[field].length
                itemData[field] = itemData[field].filter((arrItem: string) => {
                    const id = removePrefix(arrItem, '#')
                    const ptr = pointers.getPointer(id, toNamespace)
                    return dal.has(ptr)
                })

                if (deleteWhenEmpty && !itemData[field].length) {
                    delete itemData[field]
                }
                if (!itemData[field] || startLength !== itemData[field].length) {
                    return true
                }
            }

            return false
        }

    const modeOverrideToOriginal =
        (field: string, toNamespace: string): Resolver =>
        (itemData: DalValue) => {
            let madeChanges = false

            itemData?.modes?.overrides.forEach((override: DalValue) => {
                if (override[field]) {
                    const id = removePrefix(override[field], '#')
                    const ptr = pointers.getPointer(id, toNamespace)
                    if (!dal.has(ptr) && id.includes('-')) {
                        override[field] = itemData[field]
                        madeChanges = true
                    }
                }
            })

            return madeChanges
        }

    const createSpecialCasesConfig = () => {
        const cfg = [
            {
                namespace: 'DESKTOP',
                resolvers: [
                    modeOverrideToOriginal('propertyQuery', 'props'),
                    modeOverrideToOriginal('styleId', 'style'),
                    modeOverrideToOriginal('designQuery', 'design')
                ],
                predicate: hasModeOverrides
            },
            {
                namespace: 'MOBILE',
                resolvers: [
                    copyFromOtherNamespaceIfMissing('dataQuery', 'data', 'DESKTOP'),
                    copyFromOtherNamespaceIfMissing('designQuery', 'design', 'DESKTOP'),
                    copyFromOtherNamespaceIfMissing('styleId', 'style', 'DESKTOP')
                ],
                predicate: typesPredicate(['Component'])
            },
            {
                namespace: 'design',
                resolvers: [
                    removeIfMissing('mediaRef', 'design'),
                    removeFromArrayIfMissing('colorLayers', 'design', true)
                ],
                predicate: typesPredicate(['BackgroundMedia'])
            }
        ]

        return cfg
    }
    const createCommonSchemaConfig = () => {
        const cfg = [
            {
                namespace: 'data',
                resolvers: [removeIfMissing('link', 'data')],
                predicate: typesPredicate(['LinkableButton', 'ImageX', 'Image'])
            },
            {
                namespace: 'design',
                resolvers: [removeIfMissing('background', 'design')],
                predicate: typesPredicate(['MediaContainerDesignData'])
            },
            {
                namespace: 'MOBILE',
                resolvers: [removeIfMissing('propertyQuery', 'props')],
                predicate: typesPredicate(['Component'])
            }
        ]

        return cfg
    }

    const createConfig = () => {
        return experimentInstance.isOpen('dm_brokenReferenceFixer')
            ? {refsToFix: createSpecialCasesConfig()}
            : {refsToFix: [...createSpecialCasesConfig(), ...createCommonSchemaConfig()]}
    }

    const touchedItems = new Map<Pointer, DalValue>()
    const getItem = (ptr: Pointer): DalValue => {
        return touchedItems.has(ptr) ? touchedItems.get(ptr) : _.cloneDeep(dal.get(ptr))
    }
    const fixRefs = ({namespace, resolvers, predicate}: RefConfig) => {
        pointers.data
            .getItemsWithPredicate(namespace, (data: DalValue) => (predicate ? predicate(data) : true), pageId)
            .forEach(ptr => {
                const itemData = getItem(ptr)
                let itemTouched = false
                resolvers.forEach(resolver => {
                    itemTouched = itemTouched || resolver(itemData)
                })
                if (itemTouched && !touchedItems.has(ptr)) {
                    touchedItems.set(ptr, itemData)
                }
            })
    }

    const {refsToFix} = createConfig()
    refsToFix.forEach(refConfig => fixRefs(refConfig))
    Array.from(touchedItems.keys()).forEach(ptr => {
        dal.set(ptr, getItem(ptr))
    })
}
const name = 'fixCommonMissingReferences'
const version = 4
const experimentalVersions = [{experiment: 'dm_brokenReferenceFixer', version: 5}]

export const fixCommonMissingReferences: PageMigrator = {
    migratePage,
    name,
    version,
    experimentalVersions,
    fixerRequiresReruns: true
}
