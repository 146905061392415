import serviceTopology from './serviceTopologyDataGetter'
import clientSpec from './clientSpecDataGetter'
import appStudioBundler from './appStudioBundlerDataGetter'
import universalEditorApp from './universalEditorAppDataGetter'
import appBuilderPreviewBundler from './appBuilderPreviewBundlerDataGetter'

export default {
    serviceTopology,
    clientSpec,
    appStudioBundler,
    universalEditorApp,
    appBuilderPreviewBundler
}
