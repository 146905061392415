import {platformApplicationUtils, experimentInst} from '@wix/document-manager-host-common'
import {addExperimentsFromQuery, getQueryUtils} from './main-r'

const {rendererModel} = window
const queryUtil = getQueryUtils(window)

const getManipulatedRendererModel = (): any => {
    const {runningExperiments} = rendererModel
    const runningExperimentsWithQueryOverrides = addExperimentsFromQuery(
        runningExperiments,
        queryUtil,
        'viewer',
        location.search
    )
    return platformApplicationUtils.manipulateRendererModel(rendererModel, runningExperimentsWithQueryOverrides)
}

const manipulatedRendererModel = getManipulatedRendererModel()
experimentInst.setContext({
    rendererModel: manipulatedRendererModel
})

export {experimentInst, rendererModel, manipulatedRendererModel, queryUtil}
