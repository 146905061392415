import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import isPageAnchorsRemoved from './isPageAnchorsRemoved'
import constants from '../../../constants/constants'
import biEvents from '../../../bi/events.json'
import layoutSettingsUtils from '../../../structure/utils/layoutSettingsUtils'

const biMigrationAbortReason = {
    TOO_MANY_PAGES: 1,
    HAS_JSON_ANCHORS: 2,
    MOBILE_INVALID_SOAP: 3,
    DESKTOP_SOAP_ORDER: 4
}

const VALIDATORS_TYPES = {
    NO_JSON_ANCHORS: 'noJsonAnchors',
    DESKTOP_SOAP_ORDER: 'desktopSOAPOrder',
    MOBILE_INVALID_SOAP: 'mobileInvalidSOAP'
}

const VALIDATORS_ORDER = [
    VALIDATORS_TYPES.NO_JSON_ANCHORS,
    VALIDATORS_TYPES.DESKTOP_SOAP_ORDER,
    VALIDATORS_TYPES.MOBILE_INVALID_SOAP
]

const VALIDATOR_TO_BI_ERROR_CODE = {
    [VALIDATORS_TYPES.NO_JSON_ANCHORS]: biMigrationAbortReason.HAS_JSON_ANCHORS,
    [VALIDATORS_TYPES.DESKTOP_SOAP_ORDER]: biMigrationAbortReason.DESKTOP_SOAP_ORDER,
    [VALIDATORS_TYPES.MOBILE_INVALID_SOAP]: biMigrationAbortReason.MOBILE_INVALID_SOAP
}

const MOBILE_MASTER_PAGE_VALID_COMPS = [
    'wysiwyg.viewer.components.HeaderContainer',
    'wysiwyg.viewer.components.SiteRegionContainer',
    'wysiwyg.viewer.components.PagesContainer',
    'wysiwyg.viewer.components.FooterContainer',
    'platform.components.AppController',
    'wysiwyg.viewer.components.tpapps.TPAGluedWidget',
    'wysiwyg.viewer.components.QuickActionBar',
    'wysiwyg.common.components.backtotopbutton.viewer.BackToTopButton',
    'wysiwyg.viewer.components.BackToTopButton',
    'wysiwyg.viewer.components.MenuContainer',
    'wysiwyg.viewer.components.MenuToggle'
]

const getLayoutSettingsFlag = (ps: PS, flagName) => {
    const layoutSettings = layoutSettingsUtils.getLayoutSettings(ps)
    return _.get(layoutSettings, flagName, false)
}

const allMasterPageChildrenAreValid = (ps: PS, masterPageMobileChildren) =>
    _.every(masterPageMobileChildren, childPointer => {
        const componentType = ps.dal.get(ps.pointers.getInnerPointer(childPointer, 'componentType'))

        return _.includes(MOBILE_MASTER_PAGE_VALID_COMPS, componentType)
    })

const validateNoInvalidSOAPInMobile = (ps: PS) => {
    const mobileMasterPage = ps.pointers.components.getMasterPage(constants.VIEW_MODES.MOBILE)
    const masterPageMobileChildren = ps.pointers.components.getChildren(mobileMasterPage)

    return allMasterPageChildrenAreValid(ps, masterPageMobileChildren)
}

const validateNoJsonAnchors = (ps: PS) => {
    const allPagePointers = ps.pointers.page.getNonDeletedPagesPointers(true)
    const desktopAndMobilePagePointers = _.flatMap(allPagePointers, ({id: pageId}) => [
        ps.pointers.full.components.getPage(pageId, constants.VIEW_MODES.DESKTOP),
        ps.pointers.full.components.getPage(pageId, constants.VIEW_MODES.MOBILE)
    ])
    return _.every(desktopAndMobilePagePointers, _.partial(isPageAnchorsRemoved, ps))
}

const validateDesktopSOAPOrder = (ps: PS) => getLayoutSettingsFlag(ps, 'soapCompsAroundPagesContainer')

const validators = {
    [VALIDATORS_TYPES.NO_JSON_ANCHORS]: validateNoJsonAnchors,
    [VALIDATORS_TYPES.DESKTOP_SOAP_ORDER]: validateDesktopSOAPOrder,
    [VALIDATORS_TYPES.MOBILE_INVALID_SOAP]: validateNoInvalidSOAPInMobile
}

const isSiteAlreadyFlaggedAsReadyForMesh = (ps: PS) => {
    const layoutSettings = layoutSettingsUtils.getLayoutSettings(ps)
    return _.get(layoutSettings, 'mechanism') === 'mesh'
}

const getMasterPageDataItemPointer = (ps: PS) =>
    ps.pointers.data.getDataItemFromMaster(santaCoreUtils.siteConstants.MASTER_PAGE_ID)

const getPagesCount = (ps: PS) => _.size(ps.pointers.page.getNonDeletedPagesPointers(true))

const updateLayoutMechanism = (ps: PS, mechanism) => {
    const masterPageDataItem = ps.dal.get(getMasterPageDataItemPointer(ps))
    ps.dal.set(
        getMasterPageDataItemPointer(ps),
        _.merge({}, masterPageDataItem, {
            layoutSettings: {mechanism}
        })
    )
}

const flagSiteAsReadyForMeshLayout = (ps: PS) => updateLayoutMechanism(ps, 'mesh')

const flagSiteAsNotReadyForMeshLayout = (ps: PS) => updateLayoutMechanism(ps, 'anchors')

const reportMeshMigrationStatus = (ps: PS, canUseMesh, pagesCount, biEvent, reasonid?: number) => {
    const params: any = {
        status: canUseMesh,
        pages_count: pagesCount
    }
    if (!canUseMesh) {
        params.reasonid = reasonid
    }
    ps.siteAPI.reportBI(biEvent, params)
}

const reportMigrationFailed = (ps: PS, pagesCount: number, biEvent, reasonId: number) =>
    reportMeshMigrationStatus(ps, false, pagesCount, biEvent, reasonId)

const reportMigrationPassed = (ps: PS, pagesCount: number, biEvent) =>
    reportMeshMigrationStatus(ps, true, pagesCount, biEvent)

const findFirstValidationFailIndex = (ps: PS) =>
    _.findIndex(VALIDATORS_ORDER, validatorKey => !validators[validatorKey](ps))

const getReasonIdFoFailingValidation = (validatorIndex: number) =>
    VALIDATOR_TO_BI_ERROR_CODE[VALIDATORS_ORDER[validatorIndex]]

const rejectMeshAndReport = (ps: PS, pagesCount: number, biEvent, reasonId: number) => {
    flagSiteAsNotReadyForMeshLayout(ps)
    reportMigrationFailed(ps, pagesCount, biEvent, reasonId)
}

const meshLayoutReadySiteDataFixer = biEvent => (ps: PS, onSuccess?, onReject?) => {
    if (isSiteAlreadyFlaggedAsReadyForMesh(ps)) {
        if (onSuccess) {
            onSuccess()
        }
        return
    }

    const pagesCount = getPagesCount(ps)
    // ps.extensionAPI.logger.interactionStarted(constants.INTERACTIONS.MESH_LAYOUT_READY_DATA_FIXER)
    const firstFailValidationIndex = findFirstValidationFailIndex(ps)
    const canUseMesh = firstFailValidationIndex === -1

    if (canUseMesh) {
        flagSiteAsReadyForMeshLayout(ps)
        reportMigrationPassed(ps, pagesCount, biEvent)
        if (onSuccess) {
            onSuccess()
        }
    } else {
        rejectMeshAndReport(ps, pagesCount, biEvent, getReasonIdFoFailingValidation(firstFailValidationIndex))
        if (onReject) {
            onReject(
                `cant use mesh, validation failed, failed on index: ${firstFailValidationIndex} - ${VALIDATORS_ORDER[firstFailValidationIndex]}`
            )
        }
    }
    // ps.extensionAPI.logger.interactionEnded(constants.INTERACTIONS.MESH_LAYOUT_READY_DATA_FIXER)
}

export default {
    meshLayoutReadySiteDataFixerAfterMeshWizard: meshLayoutReadySiteDataFixer(biEvents.MESH_MIGRATION_WIZARD),
    meshLayoutReadySiteDataFixer: meshLayoutReadySiteDataFixer(biEvents.MESH_MIGRATION),
    meshLayoutReadySiteDataPreSaveFixer: (ps: PS) => {
        meshLayoutReadySiteDataFixer(biEvents.MESH_MIGRATION_PRESAVE)(ps)
    }
}
