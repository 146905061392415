import type {IConnectionItem, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import dataModel from '../dataModel/dataModel'

function getConnections(ps: PS, compRef: Pointer): IConnectionItem[] {
    const connections = dataModel.getConnectionsItem(ps, compRef)
    return connections ?? []
}

function getPlatformAppConnections(ps: PS, compRef: Pointer): IConnectionItem[] {
    return _.reject(getConnections(ps, compRef), {type: 'WixCodeConnectionItem'})
}

function isCompConnectedToController(ps: PS, compRef: Pointer, controllerRef: Pointer) {
    return getConnections(ps, compRef).some(connection =>
        ps.pointers.components.isSameComponent((connection as any).controllerRef, controllerRef)
    )
}

function getPrimaryConnection(ps: PS, compRef: Pointer): IConnectionItem | undefined {
    return _.find(getPlatformAppConnections(ps, compRef), {isPrimary: true} as any)
}

function getConnectionsByPointer(ps: PS, connectionPtr: Pointer, pagePtr: Pointer) {
    return dataModel.getConnectionsItemByPointer(ps, connectionPtr, pagePtr)
}

export default {
    getConnections,
    getConnectionsByPointer,
    getPlatformAppConnections,
    isCompConnectedToController,
    getPrimaryConnection
}
