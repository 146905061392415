import type {CreateExtArgs, CreateExtensionArgument} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import {isBlocksEditor} from '../../../utils/appUtils'

export const headerMetadata = {
    canConnectToCode: (
        _createExtArgs: CreateExtArgs,
        _controllerRef: Pointer,
        createExtensionArgument?: CreateExtensionArgument
    ) => !isBlocksEditor(createExtensionArgument),
    enforceContainerChildLimitsByHeight: true,
    fullWidth: true
}
