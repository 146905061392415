import type {ActionsObject, BehaviorsObject} from './actionsAndBehaviors'
import type {AppStudioObject} from './appStudio'
import type {AppStudioWidgets} from './appStudioWidgets'
import type {
    BaseDsData,
    DsItem,
    ErrorCB,
    GetArgumentsType,
    KeyValueString,
    Pointer,
    RecursivePartial
} from './basicTypes'
import type {
    CompData,
    ComponentsObject,
    CompRef,
    GridItemRuntimeObject,
    MeasureRuntimeObject,
    PageRef,
    StackItemRuntimeObject,
    StyleRefOrStyleRefs,
    UpdateDetached
} from './componentsObject'
import type {BaseDataItem, StyleRef} from './data'
import type {KeyframeEffectData} from './effects'
import type {FontsObject} from './fontsObject'
import type {LanguageObject} from './languageObject'
import type {
    Calculation,
    ComponentLayout,
    FixedItemLayout,
    FlexItemLayout,
    GridContainerLayout,
    GridItemLayout,
    HorizontalAlignment,
    KeywordSize,
    MinMaxSize,
    StackItemLayout,
    SingleLayoutData,
    UnitSize
} from './layoutData'
import type {Link} from './link'
import type {MenuData, MenuItem, MenuObject} from './menuObject'
import type {PagesGroup} from './pagesGroup'
import type {PagesData, PagesObject, PagesStructure} from './pagesObject'
import type {PlatformObject} from './platformObject'
import type {PS} from './ps'
import type {RoutersDefinition, RoutersDefinitionWithId} from './rendererModel'
import type {AutosaveConfig, AutosaveConfigInfo, DocumentServiceErrorTypes} from './save'
import type {ScopesObject} from './scopesObject'
import type {Callback, SetOperationsQueuePublic} from './setOperationsQueue'
import type {AbsoluteLayout, ComponentDefinitionType} from './structure'
import type {ThemeObject} from './theme'
import type {TpaObject} from './tpa'
import type {TransformData} from './transformationTypes'
import type {ColorId, ColorPalette, ColorPreset, FontId, FontsMap, FontStringsMap, Point} from './types'
import type {BIObject} from './utils/bi'
import type {WixCodeObject} from './wixcode'
import type {VariantsObject} from './variants'
import type {NestedRecord} from './documentServicesImplementation'
import type {MiniSiteDSApi} from './miniSite'
import type {ComponentExport, ImportExportObject} from './importExport'
import type {AIObject} from './aiObject'
import type {MainMenuObject} from './mainMenuObject'
import type {PublicMethodDefinition} from './publicMethods'

export type SimpleSize = KeywordSize | UnitSize | Calculation
export type GridSize = MinMaxSize | SimpleSize

export type {ColorId, ColorPalette, ColorPreset, FontId, FontsMap, FontStringsMap}

export interface RenderPluginsObject {
    setBlockingLayer(style: import('react').CSSProperties | null): void
    setCompsToShowOnTop(comps: CompRef['id'][] | null): void
    hideComp(compRef: CompRef): void
    showComp(compRef: CompRef): void
    setPremiumNavigationHandler(handler: () => boolean): void
    setBlockingPopupLayer(layerStyle: any): void
    setErrorPagesPopUp(handler: (errorInfo: any) => void): void
    setPreviewTooltipCallback(handler: (compClientRect: any, value: any, props: any) => void): void
    setExtraSiteHeight(height: number): void
    allowSiteOverflow(shouldAllow: boolean): void
    setCompsToShowWithOpacity(compIds: string[] | null, opacity?: number): void
}

export enum SiteNameValidationErrors {
    SITE_NAME_IS_EMPTY = 'SITE_NAME_IS_EMPTY',
    SITE_NAME_IS_NOT_STRING = 'SITE_NAME_IS_NOT_STRING',
    SITE_NAME_INVALID_CHARS = 'SITE_NAME_INVALID_CHARS',
    SITE_NAME_ENDS_WITH_HYPHEN = 'SITE_NAME_ENDS_WITH_HYPHEN',
    SITE_NAME_TOO_SHORT = 'SITE_NAME_TOO_SHORT',
    SITE_NAME_TOO_LONG = 'SITE_NAME_TOO_LONG',
    SITE_NAME_ALREADY_EXISTS = 'SITE_NAME_ALREADY_EXISTS'
}

export interface SiteNameValidationResult<ErrorCodes = SiteNameValidationErrors> {
    success: boolean
    errorCode?: ErrorCodes
    extraInfo?: string // Sanitized site name
}

export interface SiteNameObject {
    set(siteName: string): void
    getUsed(): string[]
    get(): string
    validateAsync(name: string, onSuccess: Function, onError: Function): void
    validate(name: string): SiteNameValidationResult
    sanitize(name: string): string
    setAsync(name: string, onSuccess: Function, onError: Function, ops?: {validate: boolean}): void
    ERRORS: Record<SiteNameValidationErrors, SiteNameValidationErrors>
}

export interface UserInfoObject {
    name: string
    email: string
    isStudio: boolean
    accountId: string
    userId: string
}

interface UploadMedia {
    getSiteUploadToken(): string
    getUserUploadToken(): string
    getMediaAuthToken(): string
}

export interface GeneralInfoObject {
    media: UploadMedia
    urlFormat: {
        isSlash(): boolean
    }
    isTemplate(): boolean
    isDraft(ps?: PS): boolean
    isSiteFromOnBoarding(): boolean
    isSitePublished(): boolean
    getMetaSiteId(): string
    getSiteId(): string
    getUserPermissions(): string[]
    getPublicUrl(): string
    getUserInfo(): UserInfoObject
    getLanguage(): string
    setPublicUrl(url: string): void
    getSiteToken(): string
    getRevision(): number
    getBranchId(): string
    getSiteAssetsScriptVersion(scriptName: string): string
}

export interface RouterDynamicPage extends PagesData {
    id: string
    title: string
    managingAppDefId?: string
}

export interface ConnectRouterConfig {
    innerRoute?: string
}
export interface RouterDynamicPages {
    appDefinitionId: string
    appDefinitionName?: string
    prefix?: string
    group?: string
    pages: RouterDynamicPage[]
    pageVariations?: Record<string, string[]>
    hide: boolean
}

export interface RoutersPagesObject {
    add(
        routerPtr: Pointer,
        pageTitle: string,
        pageRoles: string[],
        pageStructureOverrides?: Partial<PagesStructure>
    ): PageRef
    connect(
        routerPtr: Pointer,
        pagePtr: Pointer,
        pageRoles: string | string[],
        connectRouterConfig?: ConnectRouterConfig
    ): void
    disconnect(): void
    removePageFromRouter(routerPtr: Pointer, pageId: string): void
    listConnectablePages(): void
    move(pagePtr: Pointer, originRouterPtr: Pointer, desRouterPtr: Pointer): void
    getDynamicPagesList(): RouterDynamicPages[]
    isConnectablePage(pageId: string): boolean
}

export interface CurrentRouteInfo {
    routerId?: string
    innerRoute?: string
    isDynamic: boolean
}

type ConcurrentRoutersInvalidationListener = (selectedRoute: string) => void

export interface ConcurrentEditingRoutesInvalidation {
    subscribeToRoutersInvalidation(listener: ConcurrentRoutersInvalidationListener): void
    notifyRoutersInvalidation(currentRouteInfo: CurrentRouteInfo, selectedNewRoute: string): void
}

export interface RouterUpdateData {
    prefix?: string
    config?: any
    hide?: boolean
}

export interface LivePreviewAPI {
    subscribeToRoutersInvalidation(callback: Callback): void
    invalidateRouters(currentRouteInfo: any, newRoute: any): void
}
export interface RoutersObject {
    readonly pages: RoutersPagesObject
    readonly livePreview: LivePreviewAPI
    add(definition: Omit<RoutersDefinition, 'pages'>): RouterRef
    get: {
        byId(routerId: string): RoutersDefinition
        byRef(routerRef: RouterRef): RoutersDefinitionWithId | undefined
        byApp(appDefId: string): RoutersDefinition[]
        all(): Record<string, RoutersDefinition>
    }
    concurrent: ConcurrentEditingRoutesInvalidation

    getRouterDataForPageIfExist(id: string): RoutersDefinition | null
    update(routerPtr: RouterRef, updateData: RouterUpdateData): void

    getRouterRef: {
        byPage(ref: CompRef): RouterRef
        byPrefix(prefix: string): RouterRef
    }
    add(productsRouter: {appDefinitionId: string; prefix: string; config: string}): RouterRef
    remove(routerPointer: Pointer): void
}

export interface RouterRef {
    id: string // "routers"
    innerPath: string[] // ["configMap", "routers-k7c0wgjj"]
    type: string //"rendererModel"
}

export interface UtilsObject {
    isSameRef(sourceCompRef: CompRef, targetCompRef: CompRef): boolean
    isSameComponent(compPointerA?: CompRef, compPointerB?: CompRef): boolean
    isSamePointer(compPointerA?: Pointer, compPointerB?: Pointer): boolean
    isPage(compRef: CompRef): boolean
    isSiteStructure(compRef: CompRef): boolean
    mesh: {
        absoluteToMesh(compRef: CompRef, newLayout: AbsoluteLayout): SingleLayoutData
        remeasureContainer(compRef: CompRef): void
        getMeasurements(compRef: CompRef): AbsoluteLayout
    }
}

export type PartialItem = Omit<Partial<MenuItem>, 'items'> & {items?: PartialItem[]}

export interface MultilingualDataObject {
    remove(languageCode: string, componentPointer: CompRef): void
    get(languageCode: string, componentPointer: CompRef): CompData
    update(languageCode: string, componentPointer: CompRef, data: CompData): void
}

export interface MultilingualMenuObject {
    get(languageCode: string, menuId: string): MenuData
    update(
        languageCode: string,
        menuId: string,
        menuData: Partial<Omit<MenuData, 'items'>> & {items?: PartialItem[]}
    ): void
    updateItem(languageCode: string, menuId: string, itemId: string, data: PartialItem): void
}

export interface MultilingualObject {
    languages: {
        remove(languageCode: string): void
        hasTranslations(languageCode: string): boolean
    }
    components: {
        data: MultilingualDataObject
    }
    menu: MultilingualMenuObject
}

export interface SiteDisplayNameObject {
    get(): string
}

export type RegisterToSiteChangedCallback = () => void

interface Trace {
    upload(): Promise<any>
    start(): void
    stop(): void
    get(read?: boolean): TraceLog[]
}

export interface ComponentTraceLog {
    args: GetArgumentsType<PublicMethodDefinition['method']>
    methodPath: string
    timestamp: number
    stack: string
    value: {
        oldValue: ComponentExport
        newValue: ComponentExport
    }
}

interface ComponentsTrace {
    trace: {
        register(componentPointer: CompRef): void
        unregister(componentPointer: CompRef): void
        unregisterAll(): void
        registerNewComponents(): void
        stopRegisteringNewComponents(): void
        get(componentPointer: CompRef): ComponentTraceLog[]
        clear(componentPointer?: CompRef): void
    }
}

export interface TraceLog {
    type: string
    ts: number
    handle: number
    methodName: string
}

export interface TransactionsObject {
    isWaitForApprovalAvailable(): boolean
    runAndWaitForApproval<T>(action: () => Promise<T>): Promise<T>
    run<T>(action: () => Promise<T>): Promise<T>
    runWithoutRendering<T>(action: () => Promise<T>): Promise<T>
    render(): Promise<void>
    isRunning(): boolean
    errors: {
        TransactionRejectionError: new (id: string) => Error
    }
}

interface AccessibilityObject {
    autoDomReorder: GetSet<boolean>
    skipToContent: GetSet<boolean>
    visualFocus: GetSet<boolean>
}

export interface Comment {
    pageId: string
    x: number
    y: number
    text: string
    email: string
    submitter: string
    face: string
}

export interface Feedback {
    comments: {
        create(pageId: string, x: number, y: number, commentText: string, email: string, face: string): Comment
        add(): void
        get(): void
        remove(): void
        markAsRead(): void
    }
    isSiteShared(): void
    markSiteAsShared(): void
}

interface Compatibility {}

interface BrowserThemeColor {
    get(): string
    set(color: string): void
    reset(): void
    isSet(): boolean
}

interface Partners {
    isPotentialPartnerAsync(): Promise<boolean>
}

interface InlinePopup {
    setAlignment(componentPointer: Pointer, alignment: string): void
}

export interface SaveOptions {
    isPublish?: boolean
    isSilent?: boolean
    onBoarding?: boolean
}

const enum MobileOnlyComps {
    TINY_MENU = 'TINY_MENU',
    MENU_AS_CONTAINER = 'MENU_AS_CONTAINER',
    MENU_AS_CONTAINER_TOGGLE = 'MENU_AS_CONTAINER_TOGGLE',
    BACK_TO_TOP_BUTTON = 'BACK_TO_TOP_BUTTON',
    MENU_AS_CONTAINER_EXPANDABLE_MENU = 'MENU_AS_CONTAINER_EXPANDABLE_MENU'
}

export interface MobileObject {
    enableOptimizedView(enabled: boolean): void
    isOptimized(): boolean
    reLayoutPage(pageId?: string): void
    mobileOnlyComponents: {
        enableBackToTopButton(enabled: boolean): void
        toggleBackToTopButton(): void
        isLegacyBackToTopButton(): boolean
        isMobileOnlyComponent(compId: string): boolean
        isNativeMobileOnlyComponent(compId: string): boolean
        isMobileOnlyComponentExistOnStructure(compId: string): boolean
        mobileOnlyComps: MobileOnlyComps
        getTinyMenuDefaultPosition(): {x: number; y: number}
    }
    hiddenComponents: {
        get(compId: string): string[]
        set(pageId: string, compIds: string[]): void
        hide(compRef: CompRef): void
        show(id: string, pageId: string): void
    }
    actionBar: {
        version: {
            upgrade(): void
        }
    }
}

export interface SeoObject {
    indexing: {
        enable(): void
        isEnabled: boolean
    }
    title: {
        set(v: string): void
        get(): string
        validate(): void
    }
    description: {
        set(v: string): void
        get(): string
        validate(): void
    }
    keywords: {
        set(v: string): void
        get(): string
        validate(): void
    }
    headTags: {
        set(v: string): void
        get(): string
        clientSideValidation(): void
        validate(): void
    }
    redirectUrls: {
        update(v: string): void
        get(): string
        remove(): void
        validate(): void
    }
    ERRORS: any
}

export interface WixappsObject {
    classics: {
        getAllAppPartComps(): unknown[]
    }
}

export interface EnvObject {
    viewer: {
        getViewerName(): string
        getViewerVersion(): string
    }
}

export interface ViewModeObject {
    get(): string
    set(viewMode: string): void
    VIEW_MODES: ViewModes
}

export interface SiteSegmentsObject {
    getFooter(): CompRef
    getHeader(): CompRef
    getSiteStructure(): CompRef
    getSiteStructureId(): string
    getPagesContainer(): CompRef
    isUsingSectionsLayout(): boolean
}

export interface SnapshotObject {
    getSnapshot(compRef: CompRef): string
}

export interface MediaObject {
    readonly vectorImage: VectorImageObject
}

export interface VectorImageObject {
    fetchSvg(svgId: string, cb: () => void): Promise<void>
    getSvgInfoFromCache(svgId: string): SvgInfo
}

export interface SvgInfo {
    svgType: SvgType
}

export type SvgType = 'shape' | 'color' | 'tint' | 'ugc'

export type ComponentViewMode = 'preview' | 'editor'

export interface DefaultComponentProperties {
    isHidden?: boolean
    isCollapsed?: boolean
    ghost?: string
    fontScale?: number
}

export interface DisabledComponentProperties extends DefaultComponentProperties {
    isDisabled?: boolean
}

interface FlipOrientationProperties {
    flip: 'none' | 'x' | 'y' | 'xy'
}

export type VectorImageProperties = {
    displayMode: 'legacyFit' | 'fit' | 'stretch'
} & FlipOrientationProperties

export interface ImageProperties {
    displayMode: 'fill' | 'fit_width' | 'fit' | 'stretch'
}

export interface SiteButtonProperties extends DisabledComponentProperties {
    align: HorizontalAlignment
    margin: number
}

export interface CardsLayoutProperties extends DefaultComponentProperties {
    alignment: 'left' | 'center' | 'right' | 'justify'
    gap?: {
        vertical?: number
        horizontal?: number
    }
    direction: 'rtl' | 'ltr'
}
export interface LinkBarProperties extends BaseDataItem<'LinkBarProperties'> {
    gallery: string
    iconSize: number
    spacing: number
    bgScale: number
    orientation: string
}

export interface BreakpointsDsData extends BaseDsData {
    pointers: Pointer[]
    values: DsBreakpoint[]
}

export interface DsBreakpoint extends BaseBreakpoint {}

export interface BaseBreakpoint extends BaseDsData {
    min: number
    max?: number
    type: string
    isMobile?: boolean
}

export interface BreakpointsObject {
    get(pageComp: CompRef): BreakpointsDsData
    update(pageComp: CompRef, values: BaseBreakpoint[]): void
    remove(compPointer: Pointer, breakpointRangePointer: Pointer): void
}

export interface AccountObject {
    hasSites(): boolean
    isCoBranded(): boolean
    canUserUpgrade(): boolean
    getCoBrandedType(): string
}

export interface ExternalComponentsObject {
    getUserConsent(): Promise<boolean>
    setUserConsent(consent: boolean): Promise<void>
    showLoader(show: boolean): void
}

export type SocialLoginVendor = 'google' | 'facebook'
export type SignupPolicy = 'termsOfUse' | 'privacyPolicy' | 'codeOfConduct'
export type SignupPrivacyNote = 'CHECKBOX' | 'NOTE'

export interface SiteMembersAPINamespace {
    setAutoApproval(isAutoApproval: boolean, onSuccess: Callback, onError: Callback): void
    isAutoApproval(): boolean
    setLoginDialogFirst(isLoginDialogFirst: boolean): void
    isLoginDialogFirst(): boolean
    setSocialLoginVendorStatus(vendor: SocialLoginVendor, enabled: boolean): void
    isSocialLoginEnabled(vendor: SocialLoginVendor): boolean
    getPrivacyNoteType(): SignupPrivacyNote
    setPrivacyNoteType(privacyNote: SignupPrivacyNote): void
    setJoinCommunityStatus(enabled: boolean): void
    isJoinCommunityEnabled(): boolean
    setBackendLoginOnlyStatus(shouldSupportBackendOnly: boolean, onSuccess: Callback, onError: Callback): void
    isBackendLoginOnlyEnabled(): boolean
    isSignupPoliciesFieldEnabled(policy: SignupPolicy): boolean
    setSignupPoliciesFieldStatus(policy: SignupPolicy, enabled: boolean): void
    showLoginDialog(): Promise<void>
    showSignUpDialog(): Promise<void>
    hideAuthDialog(): Promise<void>
    getSignupPoliciesLink(policy: SignupPolicy): Link
    setSignupPoliciesLink(policy: SignupPolicy, link: Link): void
    getCustomSignupPageId(): string
    setCustomSignupPageId(pageId: string): void
    getCustomSignInPageId(): string
    setCustomSignInPageId(pageId: string): void
    getCustomNoPermissionsPageId(): string
    setCustomNoPermissionsPageId(pageId: string): void
}

export interface DocumentModeObject {
    enablePlaying(value: boolean): void
    enableZoom(value: boolean): void
    enableSocialInteraction(value: boolean): void
    enableExternalNavigation(value: boolean): void
    enableSlideShowGalleryClick(value: boolean): void
    enableTinyMenuOpening(value: boolean): void
    enableRenderFixedPositionContainers(value: boolean): void
    enableRenderFixedPositionBackgrounds(value: boolean): void
    enableBackToTopButton(value: boolean): void
    enablePageProtection(value: boolean): void
    enableSiteMembersDialogsOpening(value: boolean): void
    enableResetGalleryToOriginalState(value: boolean): void
    enableResetComponent(value: boolean): void
    resetBehaviors(value: boolean): void
    enableAction(action: string, value: boolean): void
    enableShouldUpdateJsonFromMeasureMap(value: boolean): void
    allowSiteOverflow(value: boolean): void
    toggleWixAds(value: boolean): void
    getComponentViewMode(): ComponentViewMode
    setComponentViewMode(viewMode: ComponentViewMode): void
    enablePageTransitions(value: boolean): void
    showHiddenComponents(value: boolean): void
    setRenderSticky(value: boolean): void
    setCompsToRenderSticky(compIds: string[], shouldRenderSticky: boolean): void
    getTransparentComponentsOverlay(): number | undefined
    setTransparentComponentsOverlay(threshold: number): void
    getCompsToRenderSticky(): string[]
    enableNativeComponentsForPreview(isPreview: boolean): void
    allowShowingFixedComponents(shouldAllow: boolean): void
    setExtraSiteHeight(height: number): void
    getExtraSiteHeight(): number
    updateAnimationsViewMode(viewMode: string): void
    showControllers(shouldShow: boolean): void
    allowWixCodeInitialization(isPreview: boolean): void
    setComponentPreviewState(compId: string, previewState?: string): void
    getComponentPreviewState(): {[compId: string]: string}
    setImageTargetScale(imagesTargetScale: number): void
    getImageTargetScale(): number
    runtime: any

    ignoreComponentsHiddenProperty(strings: string[]): void
}

export interface SiteObject {
    isScrollingEnabled(): boolean
    animateScroll(x: number, y: number, duration: number, callback: Callback): void
    getScroll(): Point
    setScroll(x: number, y: number): void
    setScrollAndScale(
        x: number,
        y: number,
        scale: number,
        durationsSecs: number,
        transformOriginLeft?: number | string,
        onCompleteCallback?: () => void
    ): void
    setWidth(width: number): void
    getHeight(): number
    getWidth(): number
    getSiteX(): number
    setIsFluid(isFluid: boolean): void
    getIsFluid(): boolean
    qualifiesForMeshLayout(): boolean
    isWithStaticSharedPartsInflation(): boolean
}

export interface Diff {
    type: string
    values: KeyValueString
}

export interface OnChangeEvent {
    addListener(callBack: (args: Diff[]) => void): number
    removeListener(listenerId: number): void
}

export interface Event {
    onChange: OnChangeEvent
}

interface GetSet<T> {
    get(): T
    set(value: T): void
}

export interface HistoryObject {
    clear(): void
    add(label: string, params?: any): void
    undo(): string
    redo(): string
    canUndo(): boolean
    canRedo(): boolean
    getUndoLastSnapshotParams(): any
    getUndoLastSnapshotLabel(): string
    getRedoLastSnapshotParams(): any
    getRedoLastSnapshotLabel(): string
}

export interface HomePageObject {
    get(): string
    set(pageId: string): void
}

export interface ScrollAnchor extends BaseDsData {
    id: string
    type: 'AnchorInfo'
    name: string
    urlFragment?: string
}

export interface DataObject {
    get(compRef: CompRef): DsItem
    getRuntime(compRef: CompRef): any
    update(compRef: CompRef, updatedData: DsItem, useOriginalLanguage?: boolean): any
    remove(compRef: CompRef): void
    getById(dataItemId: string, pageId?: string, data?: any): any
    getSchema(id: string): any
    createItem(dataType: string, overrides?: Partial<DsItem>): any
    addLink(id: string, linkData?: Partial<Link>): string
    updateInLang(compPointer: CompRef, untranslatedDataItem: any, translationLanguageCode: string): void
}

interface Properties {
    getById(id: string): {
        align: string
        margin: string
        type: string
    }
    getSchema(id: string): any
}

export interface PropertiesObject {
    get<PropertyItem extends DefaultComponentProperties = DefaultComponentProperties>(ref: CompRef): PropertyItem
    update<PropertyItem extends DefaultComponentProperties = DefaultComponentProperties>(
        compRef: CompRef,
        UpdatedData: Partial<PropertyItem>
    ): void
    mobile: {
        fork(compRef: CompRef): void
        join(compRef: CompRef): void
        isForked(compRef: CompRef): boolean
    }
}

export interface DefinitionObject {
    type: ComponentDefinitionType
    nickname: string
    dataTypes?: string[]
    responsiveSkins?: string[]
    skins?: string[]
    styles: string[] | Record<string, string>
    propertyType?: string
    propertyTypes?: string[]
    slotsDataType?: string
    isStylableComp?: boolean
    labelDisplayName?: string
}

export type ComponentsDefinitionsMap = Record<string, DefinitionObject>

export interface ResponsiveLayoutObject {
    pin(compRef: CompRef, layoutData: RecursivePartial<SingleLayoutData>): void
    unpin(compRef: CompRef, containerRef: CompRef, layoutData: RecursivePartial<SingleLayoutData>): void
    get(compRef: CompRef): SingleLayoutData
    update(compRef: CompRef, layoutData: RecursivePartial<SingleLayoutData>): void
    remeasureAndUpdate(compRef: CompRef, layoutData: RecursivePartial<SingleLayoutData>): void
    measure(compRef: CompRef): ResponsiveMeasure | null
    getRuntimeProperty(compRef: CompRef, properties: string[]): {[key: string]: any} | undefined
    runtime: ResponsiveRuntimeObject
}

export interface TransitionDef {
    name: string
    duration: number
    delay?: number
    params?: any
}

export interface Animation extends TransitionDef {
    action: string
    targetId?: string
}

export interface StylableObject {
    update(compRef: CompRef, layoutData: StyleRefOrStyleRefs): void
    quickChange(compPointer: CompRef, selector: string, declarationMap: Record<string, string>): void
    revertQuickChange(compPointer: CompRef): void
    forceState(compPointer: CompRef, selector: string): void
    revertForceState(compPointer: CompRef): void
    getEditorInstance(): any
}

export interface KeyframeEffectObject {
    add(keyframeEffectData: KeyframeEffectData, keyframeEffectToAddRef?: Pointer): Pointer
    get(pointer: Pointer): DsItem
    list(): Pointer[]
    remove(pointer: Pointer): void
    update(pointer: Pointer, updatedKeyframeEffectData: KeyframeEffectData): void
}

export type TransformationOverrides = Pick<TransformData, 'scale' | 'rotate' | 'translate' | 'skew'>

export interface CssOverrides {
    [prop: string]: string
}

export interface CompCssOverrides {
    compPointer: Pointer
    cssOverrides: CssOverrides
}

export interface StylesOverrides {
    fontSize?: string
}

export interface ResponsiveRuntimeObject {
    gridItem: GridItemRuntimeObject
    measure: MeasureRuntimeObject
    stackItem: StackItemRuntimeObject
    updateDetachedLayout: UpdateDetached
    updateDetachedStyles(compRef: CompRef, styles: StylesOverrides): void
    detachLayout(compRef: CompRef): void
    reattachLayout(compRef: CompRef): void
    updateDetachedRotation(compRef: CompRef, rotationInDegrees: number): void
    updateDetachedTransformation(compRef: CompRef, transformationOverrides: TransformationOverrides): void
    detachComponents(componentsPointers: CompRef[]): void
    detachContainer(
        containerPointer: Pointer,
        containerOverrides: CssOverrides,
        childrenOverrides: CompCssOverrides[]
    ): void
}

export interface ResponsiveMeasure {
    boundingBox: {
        height: number
        width: number
        absoluteTop: number
        absoluteLeft: number
        offsetTop: number
        offsetLeft: number
    }
    containerMeasures: {
        gridColumnGap: string
        gridRowGap: string
        gridTemplateColumns: string
        gridTemplateRows: string
    }
}

export interface Feature extends Record<string, any> {
    type?: string
}

export interface Features {
    update(compPointer: CompRef, featureName: string, featureData: Feature): void
    get(compPointer: CompRef, featureName: string): Feature
    remove(compPointer: CompRef, featureName: string): void
}

export interface StyleObject {
    get(comp: CompRef): StyleRef
    update(comp: CompRef, style: StyleRef): void
}

export interface DesignObject {
    get(compRef: CompRef): any
    update(compRef: CompRef, data: any): any
}

type WaitForChangesAppliedCallback = () => void

export interface LayoutDataItem extends BaseDsData {
    type: string
    breakpoint?: string
}

export interface GridArea {
    rowStart: number
    columnStart: number
    rowEnd: number
    columnEnd: number
}

export type FlexWrap = 'nowrap' | 'wrap' | 'wrapReverse' | 'unset' | 'initial' | 'inherit'

export type FlexJustifyContent = 'start' | 'center' | 'end' | 'spaceBetween' | 'spaceAround' | 'spaceEvenly'

export type PremiumFeatures = 'HasDomain' | 'HasECommerce'

export interface PremiumFeaturesObject {
    get(): PremiumFeatures[]
}

export type AnyLayoutDataItem =
    | ComponentLayout
    | GridItemLayout
    | StackItemLayout
    | FixedItemLayout
    | FlexItemLayout
    | GridContainerLayout

export type Frequency = 'always' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly' | 'never'

export interface SitemapEntry {
    url: string
    lastModified: string
    changeFrequency: Frequency
    priority: number
    title: string
    pageName: string
}

export interface ViewModes {
    MOBILE: string
    DESKTOP: string
}

export type PossibleViewModes = keyof ViewModes

export interface AppController extends BaseDsData {
    applicationId: string
    name?: string
    controllerType: string
    settings?: string
    externalId?: string
    // [k: string]: unknown
}

export interface MobileConversionLayoutOptions {
    heuristicStrategy: 'default' | 'minimal' | 'appbuilder'
}

export interface MobileConversionObject {
    syncMobileSite(options?: any): void
    resetMobileLayoutOnAllPages(options: MobileConversionLayoutOptions): void
    runMobileMergeOnPageById(pageId: string, settings?: any): void
    runMobileMergeOnAllPagesWithoutLayoutOverwrite(): void
    resetModifiedByUserIndications(): void
}

type DeprecatedOldBadPerformanceApisComponentKeys =
    | 'getAllComponents'
    | 'getAllComponentsFromFull'
    | 'getChildren'
    | 'getChildrenFromFull'
    | 'getContainer'
    | 'getAncestors'
    | 'getRepeatedComponents'
    | 'isDescendantOfComp'
type DeprecatedOldBadPerformanceApisComponentGetKeys =
    | 'byXYRelativeToStructure'
    | 'byXYRelativeToScreen'
    | 'getComponentsAtXYConsideringFrame'
    | 'byType'
    | 'byAncestor'

interface DeprecatedOldBadPerformanceApis {
    components: Pick<ComponentsObject, DeprecatedOldBadPerformanceApisComponentKeys> & {
        get: Pick<ComponentsObject['get'], DeprecatedOldBadPerformanceApisComponentGetKeys>
        modes: {
            getFirstAncestorWithActiveModes(): unknown
        }
    }
    layouters: {
        getParentCompWithOverflowHidden(): unknown
    }
    mobile: {
        hiddenComponents: {
            get(): unknown
        }
    }
}

export interface LastSaveInfo {
    date?: Date
}

export interface DocumentServicesObject {
    history: HistoryObject
    readonly accessibility: AccessibilityObject
    readonly account: AccountObject
    readonly actions: ActionsObject
    readonly ai: AIObject
    readonly appStudio?: AppStudioObject
    readonly appStudioWidgets: AppStudioWidgets
    readonly behaviors: BehaviorsObject
    readonly bi: BIObject
    readonly breakpoints: BreakpointsObject
    readonly browserThemeColor: BrowserThemeColor
    readonly compatibility: Compatibility
    readonly components: ComponentsObject
    readonly data: DataObject
    readonly documentMode: DocumentModeObject
    readonly env: EnvObject
    readonly errors: DocumentServiceErrorTypes
    readonly externalComponents: ExternalComponentsObject
    readonly features: Features
    readonly feedback: Feedback
    readonly fonts: FontsObject
    readonly generalInfo: GeneralInfoObject
    readonly homePage: HomePageObject
    readonly miniSite: MiniSiteDSApi
    readonly inlinePopup: InlinePopup
    readonly language: LanguageObject
    readonly media: MediaObject
    readonly menu: MenuObject
    readonly mainMenu: MainMenuObject
    readonly mobile: MobileObject
    readonly mobileConversion: MobileConversionObject
    readonly multilingual: MultilingualObject
    readonly pages: PagesObject
    readonly pagesGroup: PagesGroup
    readonly partners: Partners
    readonly platform: PlatformObject
    readonly premiumFeatures: PremiumFeaturesObject
    readonly properties: Properties
    readonly renderPlugins: RenderPluginsObject
    readonly responsiveLayout: ResponsiveLayoutObject
    readonly routers: RoutersObject
    readonly seo: SeoObject
    readonly site: SiteObject
    readonly siteDisplayName: SiteDisplayNameObject
    readonly siteMembers: SiteMembersAPINamespace
    readonly siteName: SiteNameObject
    readonly siteSegments: SiteSegmentsObject
    readonly snapshot: SnapshotObject
    readonly theme: ThemeObject
    readonly tpa: TpaObject
    transactions: TransactionsObject
    readonly utils: UtilsObject
    readonly viewMode: ViewModeObject
    readonly wixCode: WixCodeObject
    readonly wixapps: WixappsObject
    readonly variants: VariantsObject
    readonly scopes: ScopesObject
    readonly importExport: ImportExportObject
    readonly deprecatedOldBadPerformanceApis: DeprecatedOldBadPerformanceApis
    readonly keyframeEffect: KeyframeEffectObject
    SOQ: SetOperationsQueuePublic
    promises: NestedRecord<Function>

    readonly debug: {
        readonly trace: Trace
        readonly components: ComponentsTrace
    }

    initAutosave(config: AutosaveConfig): void
    autosave(resolve: Callback, reject: ErrorCB): void
    save(onSuccess?: Callback, onError?: ErrorCB, isFullSave?: boolean, opt?: SaveOptions): void
    publish(onSuccess: Callback, onError: ErrorCB): void
    getAutoSaveInfo(): undefined | AutosaveConfigInfo
    canUserPublish(): boolean
    getLastSaveInfo(): Promise<LastSaveInfo>
    waitForChangesApplied(callback: WaitForChangesAppliedCallback, onlyChangesAlreadyRegistered?: boolean): void
    waitForChangesAppliedAsync(): Promise<void>
    registerToErrorThrown(cb: Callback): void
    registerToErrorThrownForContext(appDefId: string, cb: Callback): void
    unRegisterToErrorThrownForContext(appDefId: string, cb: Callback): void
    runInContext(appDefId: string, cb: Callback): any
    registerToSiteChanged(callback: RegisterToSiteChangedCallback): void
    getReadMethods?(): any
    getActions?(): any
    getConstants?(): any
}
