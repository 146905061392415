import {
    type Extension,
    type CreateExtArgs,
    type ExtensionAPI,
    pointerUtils,
    type DmApis
} from '@wix/document-manager-core'
import {DATA_TYPES, VIEW_MODES, COMP_DATA_QUERY_KEYS_WITH_STYLE} from '../constants/constants'
import {stripHashIfExists} from '../utils/refArrayUtils'
import type {Pointer} from '@wix/document-services-types'
import type {SchemaExtensionAPI} from './schema/schema'
import type {DataModelExtensionAPI} from './dataModel/dataModel'
import type {PageExtensionAPI} from './page'

const {getPointer} = pointerUtils
const MOBILE_HINTS = DATA_TYPES.mobileHints

export interface MobileHintsAPI extends ExtensionAPI {
    mobileHints: {
        addMobileHintsItem(pageId: string): void
        resetModifiedByUserIndications(): void
    }
}

const createExtension = (): Extension => {
    const createExtensionAPI = ({pointers, dal, extensionAPI}: CreateExtArgs): MobileHintsAPI => {
        const isMobileHintsInitializedForPage = (pageId: string) => {
            const pageComponentPointer = pointers.structure.getPage(pageId, VIEW_MODES.DESKTOP)
            const mobileHintsPointer = pointers.getInnerPointer(
                pageComponentPointer,
                COMP_DATA_QUERY_KEYS_WITH_STYLE.mobileHints
            )
            return !!dal.get(mobileHintsPointer)
        }

        const addMobileHintsItem = (componentPointer: Pointer, mobileHintsItem: Record<string, any> = {}) => {
            const {dataModel} = extensionAPI as DataModelExtensionAPI
            const {schemaAPI} = extensionAPI as SchemaExtensionAPI
            const mobileHintsPointer = pointers.getInnerPointer(componentPointer, 'mobileHintsQuery')
            const mobileDataQuery = dal.get(mobileHintsPointer)
            const mobileHintsId = stripHashIfExists(mobileDataQuery)
            const dataItemId = dataModel.generateUniqueIdByType(MOBILE_HINTS, componentPointer.id, dal, pointers)
            mobileHintsItem.id = dataItemId
            const dataItem = schemaAPI.createItemAccordingToSchema('MobileHints', MOBILE_HINTS, mobileHintsItem)
            dataModel.addItem(dataItem, MOBILE_HINTS, componentPointer.id, dataItemId)
            const compDataPointer = pointers.getInnerPointer(
                componentPointer,
                COMP_DATA_QUERY_KEYS_WITH_STYLE.mobileHints
            )
            dal.set(compDataPointer, `${dataItemId}`)
            return mobileHintsId
        }

        const addMobileHintsItemIfNotExit = (pageId: string) => {
            if (isMobileHintsInitializedForPage(pageId)) {
                return
            }
            const pageComponentPointer = pointers.structure.getPage(pageId, VIEW_MODES.DESKTOP)
            addMobileHintsItem(pageComponentPointer, {hidden: false})
        }

        const resetModifiedByUserIndications = () => {
            const {page} = extensionAPI as PageExtensionAPI
            const indexKey = page.getAllPagesIndexId()
            const idsToChange = dal.queryKeys(
                'mobileHints',
                indexKey,
                mobileHintsItem => !!mobileHintsItem?.modifiedByUser
            )
            for (const id of idsToChange) {
                dal.set(getPointer(id, 'mobileHints', {innerPath: 'modifiedByUser'}), false)
            }
        }

        return {
            mobileHints: {
                addMobileHintsItem: addMobileHintsItemIfNotExit,
                resetModifiedByUserIndications
            }
        }
    }

    const createPublicAPI = ({extensionAPI}: DmApis) => {
        const {mobileHints} = extensionAPI as MobileHintsAPI
        return {
            mobileHints
        }
    }

    return {
        name: 'mobileHints',
        dependencies: new Set(),
        createExtensionAPI,
        createPublicAPI
    }
}

export {createExtension}
