export default {
    resizableSides: [],
    moveDirections: [],
    fullWidth: true,
    containable: false,
    duplicatable: false,
    crossSiteDuplicatable: false,
    removable: false,
    mobileConversionConfig: {
        marginX: 20
    }
}
