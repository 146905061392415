import {customElements} from '@wix/document-services-implementation'

export default function () {
    const {initMethod} = customElements

    return {
        initMethod,
        methods: {}
    }
}
