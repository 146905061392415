import type {PublicMethodUtils} from '@wix/document-services-types'
import {componentDetectorAPI} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            /** @class documentServices.components*/
            components: {
                /**
                 * retrieves all component refs in the masterPage and the passed pageId, ordered by the dom.
                 * If no pageId is passed, all site component refs will be retrieved.
                 * @param {string} [pageId] - the pageId of the page for which to get all the components
                 * @returns {AbstractComponent[]} all the components in the masterPage and the passed pageId
                 */
                getAllComponents: publicMethodUtils.defineGetter(componentDetectorAPI.getAllComponents),
                lightGetAllComponents: publicMethodUtils.defineGetter(componentDetectorAPI.lightGetAllComponents),
                lightGetAllComponentsFromFull: publicMethodUtils.defineGetter(
                    componentDetectorAPI.lightGetAllComponentsFromFull
                ),
                getAllComponentsFromFull: publicMethodUtils.defineGetter(componentDetectorAPI.getAllComponentsFromFull),
                /** @class documentServices.components.get */
                get: {
                    byXYRelativeToStructure: publicMethodUtils.deprecate(
                        publicMethodUtils.defineGetter(componentDetectorAPI.getComponentsAtXYRelativeToStructure),
                        'Should be replaced with components.get.byXYFromDom'
                    ),
                    byXYRelativeToScreen: publicMethodUtils.deprecate(
                        publicMethodUtils.defineGetter(componentDetectorAPI.getComponentsAtXYRelativeToScreen),
                        'Should be replaced with components.get.byXYFromDom'
                    ),
                    /**
                     * @function
                     * @param {x} x coordinate
                     * @param {y} y coordinate
                     * @returns {Array<CompRef>} array of components under (x, y) coordinate
                     * @deprecated
                     */
                    getComponentsAtXYConsideringFrame: publicMethodUtils.deprecate(
                        publicMethodUtils.defineGetter(componentDetectorAPI.getComponentsAtXYConsideringFrame),
                        'Should be replaced with components.get.byXYFromDom'
                    ),
                    /**
                     * @function
                     * @param {x} x coordinate
                     * @param {y} y coordinate
                     * @returns {Array<CompRef>} array of components under (x, y) coordinate
                     */
                    byXYFromDom: publicMethodUtils.defineGetter(componentDetectorAPI.getComponentsAtXYFromDom),
                    /**
                     * Gets all the components of the given type/s in the entire site or descendants of the rootComp.
                     * @param {String|string[]} compType a single component type or an array of multiple component types
                     * @param {AbstractComponent} [rootComp] rootComp The root parent component ref that will be used for the search
                     * @returns {AbstractComponent[]} an array of component refs of the passed component type/s
                     */
                    byXYIncludingInnerElements: publicMethodUtils.defineGetter(
                        componentDetectorAPI.getItemsAtXYIncludingInnerElements
                    ),
                    byType: publicMethodUtils.defineGetter(componentDetectorAPI.getComponentByType),
                    /**
                     * Gets component by given id ,pageId (if pageId isn't passed takes the current pageId) and view mode (if view mode not passed takes the current view mode).
                     * @param {String} id component full id
                     * @param {String} [pageId] id of the page that the component is in
                     * @param {String} [viewMode] mobile/desktop
                     * @returns {AbstractComponent} a ref to the component with the passed id
                     */
                    byId: publicMethodUtils.defineGetter(componentDetectorAPI.getComponentById),
                    /**
                     * Retrieve all components under some ancestor
                     * @param {ps} privateServices
                     * @param {AbstractComponent} ancestor - comp pointer to ancestor in which to search
                     */
                    byAncestor: publicMethodUtils.defineGetter(componentDetectorAPI.getComponentsUnderAncestor)
                },
                isDescendantOfComp: publicMethodUtils.defineGetter(componentDetectorAPI.isDescendantOfComp)
            },
            /** @class documentServices.utils*/
            utils: {
                /**
                 * @returns {boolean} returns true if the two references are referencing the same component
                 * @param {AbstractComponent} refA
                 * @param {AbstractComponent} refB
                 */
                isSameRef: publicMethodUtils.deprecate(
                    publicMethodUtils.defineGetter(componentDetectorAPI.utils.isSameComponent),
                    'Please use components.utils.isSameComponent instead',
                    1
                ),
                isSameComponent: publicMethodUtils.defineGetter(componentDetectorAPI.utils.isSameComponent),
                isSamePointer: publicMethodUtils.defineGetter(componentDetectorAPI.utils.isSamePointer),
                /**
                 * @returns {boolean} returns true if the reference is to a page
                 * @param {AbstractComponent} ref
                 */
                isPage: publicMethodUtils.defineGetter(componentDetectorAPI.utils.isPage),
                /**
                 * @returns {boolean} returns true if the reference is to the site structure
                 * @param {AbstractComponent} ref
                 */
                isSiteStructure: publicMethodUtils.defineGetter(componentDetectorAPI.utils.isSiteStructure),
                getUniqueId: publicMethodUtils.defineGetter(componentDetectorAPI.utils.getUniqueId)
            }
        }
    }
}
