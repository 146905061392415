import _ from 'lodash'
import {getChildren} from './conversionUtils'
import type {DeepStructure} from '../types'
import {coreUtils} from '@wix/santa-ds-libs'

const DIVIDERS_ROTATION_ANGLE = 90

export function preprocess(component: DeepStructure, hideNonDividers: boolean): void {
    _.forEachRight(getChildren(component), (child: DeepStructure, childIndex: number) => {
        if (!isDividerTypeComponent(child)) {
            preprocess(child, hideNonDividers)
            return
        }
        if (isVerticalDivider(child) && !isRotationAngleAppliedToComponent(child)) {
            flipDivider(child)
            return
        }
        if (hideNonDividers) {
            hideNonDivider(component, childIndex)
        }
    })
}

export function postprocess(component: DeepStructure): void {
    _.forEach(getChildren(component), (child: DeepStructure) => {
        if (!isVerticalDivider(child)) {
            postprocess(child)
            return
        }
        if (isRotationAngleAppliedToComponent(child)) {
            child.layout.rotationInDegrees = -DIVIDERS_ROTATION_ANGLE
            _.assign(child.layout, coreUtils.boundingLayoutUtils.getBoundingLayout(child.layout))
            child.layout.rotationInDegrees = DIVIDERS_ROTATION_ANGLE
        }
    })
}

function hideNonDivider(parent: DeepStructure, index: number): void {
    const children = getChildren(parent)
    _.remove(parent.conversionData.componentsOrder, children[index].id)
    children.splice(index, 1)
}

function flipDivider(comp: DeepStructure): void {
    comp.layout.rotationInDegrees = DIVIDERS_ROTATION_ANGLE
    _.assign(comp.layout, coreUtils.boundingLayoutUtils.getBoundingLayout(comp.layout))
}

const isDividerTypeComponent = comp => _.get(comp, ['conversionData', 'keepIfVerticalDivider'], false)
const isVerticalDivider = comp => isDividerTypeComponent(comp) && _.get(comp, ['conversionData', 'isVerticalDivider'], false)
const isRotationAngleAppliedToComponent = comp => comp.layout.rotationInDegrees === DIVIDERS_ROTATION_ANGLE
