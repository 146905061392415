import {createHookDefinition} from '../../utils/hooksUtils'
import type {PublishPipelineMessage} from '../distributor/messages'
import type {HookEventData} from '../hooks/hooks'

export interface PublishPipelineEvent extends PublishPipelineMessage, HookEventData<'site_publish_status'> {}

export const PUBLISH_HOOKS = {
    PUBLISH: {
        STATUS: createHookDefinition<'site_publish_status', PublishPipelineMessage>('site_publish_status')
    }
}
