import _ from 'lodash'

const events = 'continuousSaveEvents'
const browserRendererModel = 'browserRendererModel'
const csave = 'continuousSave'
const clientCsaveExtensions: string[] = [browserRendererModel, csave, events, 'revisionManager']
const clientCsaveExtensionsWithoutBrowserRendererModel: string[] = _.without(
    clientCsaveExtensions,
    browserRendererModel
)
const serverCsaveExtensions: string[] = [csave, 'continuousSaveForServer']

export {clientCsaveExtensions, serverCsaveExtensions, clientCsaveExtensionsWithoutBrowserRendererModel}
