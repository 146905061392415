import _ from 'lodash'
import isSystemStyle from '../theme/isSystemStyle'

const PERSISTENT_DATA_IDS = ['MAIN_MENU', 'CUSTOM_MENUS', 'CUSTOM_MAIN_MENU', 'IMAGE_QUALITY']
const PERSISTENT_VALIDATIONS_BY_TYPE = {
    data: (ps, id: string) => _.includes(PERSISTENT_DATA_IDS, id),
    style: isSystemStyle
}

function isItemPersistent(ps, type: string, id: string) {
    return _.invoke(PERSISTENT_VALIDATIONS_BY_TYPE, type, ps, id)
}

export default {
    isItemPersistent,
    PERSISTENT_DATA_IDS
}
