import type {QueryParams, QueryParamValuePrimitive} from '@wix/document-manager-utils'
import _ from 'lodash'
import urlUtils from '../../../coreUtils/core/urlUtils'
import type {ParsedUrl} from '@wix/document-services-types'

export class TpaBaseUrlBuilder {
    url: Partial<ParsedUrl>

    constructor(baseUrl?: string) {
        this.url = urlUtils.parseUrl(baseUrl)
        this.url.query = this.url.query || {}
        this.url.search = null
        this.url.hash = this.url.hash || ''
    }

    addQueryParam(param: string, value: any) {
        const {query} = this.url

        if (value && !_.isEmpty(value)) {
            if (!query[param]) {
                query[param] = value
            } else if (_.isArray(query[param])) {
                ;(query[param] as QueryParamValuePrimitive[]).push(value)
            } else {
                query[param] = [query[param], value]
            }
        }

        return this
    }

    addMultipleQueryParams(queryParams: QueryParams) {
        if (queryParams && !_.isEmpty(queryParams)) {
            _.assign(this.url.query, queryParams)
        }
        return this
    }

    addQueryParamsFromFunc(funcThatAddsQueryParams, funcParams) {
        if (_.isFunction(funcThatAddsQueryParams)) {
            funcThatAddsQueryParams(...[this].concat(funcParams))
        }
        return this
    }

    setHash(hashValue: string) {
        if (_.isString(hashValue) && !_.isEmpty(hashValue)) {
            hashValue = hashValue.startsWith('#') ? hashValue : `#${hashValue}`
            this.url.hash = hashValue
        }
        return this
    }
    build() {
        return urlUtils.buildFullUrl(this.url, undefined)
    }
}
