import clientSpecMapUtils from '../utils/clientSpecMapUtils'
import wixCodeMonitoring from './wixCodeMonitoringWrapper'
import wixCodeServiceFacade from './wixCodeServiceFacade'
import hooks from '../../hooks/hooks'
import type {PS} from '@wix/document-services-types'
import {getMetaSiteId} from '../../utils/dalUtil'
import {HttpClient} from '@wix/http-client'
import {provisionApp} from '@wix/ambassador-velo-apps-v1-app/http'
import experiment from 'experiment-amd'
import _ from 'lodash'

function _updateWixCodeModel(ps: PS, gridAppId: string) {
    ps.extensionAPI.wixCode.setOpenWixCodeAppId(gridAppId)
    hooks.executeHook(hooks.HOOKS.AUTOSAVE.ACTION, null, [ps])
    return
}

async function _doProvision(ps: PS, existingWixCodeApp) {
    if (experiment.isOpen('dm_wixCodeNewProvision')) {
        const {editorRootUrl} = ps.dal.get(ps.pointers.general.getServiceTopology())

        const wixHttpClientInstance = new HttpClient({
            baseURL: editorRootUrl,
            getAppToken: ps.extensionAPI.siteAPI.getInstance
        })
        try {
            const res = await wixHttpClientInstance.request(provisionApp({}))
            const gridAppId = _.get(res, 'data.app.id', '')
            _updateWixCodeModel(ps, gridAppId)
            return
        } catch (error: any) {
            throw error.response.data
        }
    } else {
        const options = {
            baseUrl: wixCodeServiceFacade.getBaseUrlFromPS(ps),
            metasiteId: getMetaSiteId(ps),
            signedInstance: existingWixCodeApp ? existingWixCodeApp.instance : null,
            branchId: ps.extensionAPI.siteAPI.getBranchId()
        }
        const newAppResult = await wixCodeServiceFacade.create(options)
        _updateWixCodeModel(ps, newAppResult.gridAppId)
        return existingWixCodeApp
    }
}

async function provision(ps: PS) {
    const traceEnd = wixCodeMonitoring.trace(ps, {action: 'createApp'})
    const existingWixCodeApp = clientSpecMapUtils.getExistingWixCodeAppFromPS(ps)

    if (isProvisioned(ps)) {
        traceEnd({message: 'wix code is already provisioned'})
        return existingWixCodeApp
    }

    try {
        await _doProvision(ps, existingWixCodeApp)
        await ps.extensionAPI.rendererModel.refreshClientSpecMap()
        traceEnd()
        return clientSpecMapUtils.getExistingWixCodeAppFromPS(ps)
    } catch (error) {
        traceEnd({message: error, level: wixCodeMonitoring.levels.ERROR})
        throw error
    }
}

const isProvisioned = (ps: PS) => ps.extensionAPI.wixCode.isProvisioned()

export default {
    provision,
    isProvisioned
}
