import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import {contextAdapter} from '../utils/contextAdapter'
import partnersUtils from './partnersUtils'

let currentPartnersRequest = false

const isPotentialPartnerAsync = async (ps: PS) => {
    const isPotentialPartnerPointer = ps.pointers.partners.getIsPotentialPartnerPointer()
    const isPotentialPartnerVal = ps.dal.get(isPotentialPartnerPointer)
    //lazy fetching partners data
    if (!_.isBoolean(isPotentialPartnerVal)) {
        if (currentPartnersRequest) {
            return currentPartnersRequest
        }
        // @ts-expect-error BUG?
        currentPartnersRequest = partnersUtils.fetchAndWritePartnersData(ps, contextAdapter)
        return currentPartnersRequest
    }
    return !!isPotentialPartnerVal
}

export default {
    isPotentialPartnerAsync,
    initState: () => {
        currentPartnersRequest = false
    }
}
