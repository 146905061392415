import {createImmutableProxy} from '@wix/wix-immutable-proxy'
import {mediaControlsMetadata} from './components/mediaControlsMetadata'
import {mediaOverlayControlsMetadata} from './components/mediaOverlayControlsMetadata'
import {appWidgetMetaData} from './components/appWidgetMetaData'
import {backToTopButtonLegacyMetaData} from './components/backToTopButtonLegacyMetaData'
import {backToTopButtonMetaData} from './components/backToTopButtonMetaData'
import {controllerMetaData} from './components/controllerMetaData'
import {menuToggleMetaData} from './components/menuToggleMetaData'
import {quickActionBarItemMetaData} from './components/quickActionBarItemMetaData'
import {quickActionBarMetaData} from './components/quickActionBarMetaData'
import {tinyMenuMetaData} from './components/tinyMenuMetaData'
import {tpaSectionMetaData} from './components/tpaSectionMetaData'
import {tpaWidgetMetaData} from './components/tpaWidgetMetaData'
import {tpaGluedWidgetMetaData} from './components/tpaGluedWidgetMetaData'
import {refComponentMetaData} from './components/refComponentMetaData'
import {headerMetadata} from './components/headerMetaData'
import {footerMetadata} from './components/footerMetaData'
import {containerMetadata} from './components/containerMetaData'
import {pageMetadata} from './components/pageMetaData'
import {hamburgerOpenButtonMetadata} from './components/hamburgerOpenButtonMetadata'
import {adminLoginButtonMetaData} from './components/adminLoginButtonMetaData'
import {loginButtonMetaData} from './components/loginButtonMetaData'
import {facebookLikeBoxMetaData} from './components/facebookLikeBoxMetaData'
import {facebookLikeMetaData} from './components/facebookLikeMetaData'
import {flickrBadgeWidgetMetaData} from './components/flickrBadgeWidgetMetaData'
import {flashComponentMetaData} from './components/flashComponentMetaData'
import {pinItPinWidgetMetaData} from './components/pinItPinWidgetMetaData'
import {pinterestFollowMetaData} from './components/pinterestFollowMetaData'
import {pinterestPinItMetaData} from './components/pinterestPinItMetaData'
import {siteRegionContainerMetaData} from './components/siteRegionContainerMetaData'
import {thumbnailsMetaData} from './components/thumbnailsMetaData'
import {accordionMetaData} from './components/accordionMetaData'
import {htmlComponentMetaData} from './components/htmlComponentMetaData'
import {googleMapMetaData} from './components/googleMapMetaData'
import {spotifyFollowMetaData} from './components/spotifyFollowMetaData'
import {vKShareButtonMetaData} from './components/vKShareButtonMetaData'
import {wFacebookCommentMetaData} from './components/wFacebookCommentMetaData'
import {wGooglePlusOneMetaData} from './components/wGooglePlusOneMetaData'
import {wTwitterFollowMetaData} from './components/wTwitterFollowMetaData'
import {wTwitterTweetMetaData} from './components/wTwitterTweetMetaData'
import {youTubeSubscribeButtonMetaData} from './components/youTubeSubscribeButtonMetaData'
import {appPart2MetaData} from './components/appPart2MetaData'
import {stripShowCaseMetaData} from './components/stripShowCaseMetaData'
import {collageMetaData} from './components/collageMetaData'
import {masonryMetaData} from './components/masonryMetaData'
import {freestyleMetaData} from './components/freestyleMetaData'
import {videoMetaData} from './components/videoMetaData'
import {soundCloudMetaData} from './components/soundCloudMetaData'
import {verticalMenuMetaData} from './components/verticalMenuMetaData'
import {expandableMenuMetaData} from './components/expandableMenuMetaData'
import {dropDownMenuMetaData} from './components/dropDownMenuMetaData'
import {popupContainerMetaData} from './components/popupContainerMetaData'
import {boxSlideShowSlideMetaData} from './components/boxSlideShowSlideMetaData'
import {boxSlideShowMetaData} from './components/boxSlideShowMetaData'
import {stateBoxStateMetaData} from './components/stateBoxStateMetaData'
import {stateBoxFormStateMetaData} from './components/stateBoxFormStateMetaData'
import {stateBoxMetaData} from './components/stateBoxMetaData'
import {stateStripStateMetaData} from './components/stateStripStateMetaData'
import {stateStripMetaData} from './components/stateStripMetaData'
import {stripSlideShowSlideMetaData} from './components/stripSlideShowSlideMetaData'
import {stripSlideShowMetaData} from './components/stripSlideShowMetaData'
import {classicSectionMetadata} from './components/classicSectionMetadata'
import {verticalAnchorsMenuMetaData} from './components/verticalAnchorsMenuMetaData'
import {hoverboxMetaData} from './components/hoverboxMetaData'
import {popoverMetaData} from './components/popoverMetaData'
import {repeaterMetaData} from './components/repeaterMetaData'
import {columnMetadata} from './components/columnMetadata'
import {mediaPlayerMetadata} from './components/mediaPlayerMetadata'
import {threeDCarouselMetaData} from './components/3DCarouselMetaData'
import {spotifyPlayerMetadata} from './components/spotifyPlayerMetadata'
import {stripColumnsMetaData} from './components/stripColumnsMetaData'
import {anchorMetaData} from './components/anchorMetaData'
import {bgImageStripMetaData} from './components/bgImageStripMetaData'
import {fiveGridLineMetaData} from './components/fiveGridLineMetaData'
import {stripContainerMetaData} from './components/stripContainerMetaData'
import {stripGallerySlideShowMetaData} from './components/stripGallerySlideShowMetaData'
import {appPageMetaData} from './components/appPageMetaData'
import {pageGroupMetaData} from './components/pageGroupMetaData'
import {pagesContainerMetaData} from './components/pagesContainerMetaData'
import {screenWidthContainerMetaData} from './components/screenWidthContainerMetaData'
import {siteStructureMetaData} from './components/siteStructureMetaData'

export type MetadataMap = Record<string, Record<string, any>>

export const metadataMap: MetadataMap = createImmutableProxy({
    'wysiwyg.viewer.components.MediaControls': mediaControlsMetadata,
    'wysiwyg.viewer.components.MediaOverlayControls': mediaOverlayControlsMetadata,
    'platform.components.AppWidget': appWidgetMetaData,
    'wysiwyg.common.components.backtotopbutton.viewer.BackToTopButton': backToTopButtonLegacyMetaData,
    'wysiwyg.viewer.components.BackToTopButton': backToTopButtonMetaData,
    'platform.components.AppController': controllerMetaData,
    'wysiwyg.viewer.components.MenuToggle': menuToggleMetaData,
    'wysiwyg.viewer.components.QuickActionBarItem': quickActionBarItemMetaData,
    'wysiwyg.viewer.components.QuickActionBar': quickActionBarMetaData,
    'wysiwyg.viewer.components.mobile.TinyMenu': tinyMenuMetaData,
    'wysiwyg.viewer.components.tpapps.TPAGluedWidget': tpaGluedWidgetMetaData,
    'tpa.viewer.components.tpapps.TPAGluedWidget': tpaGluedWidgetMetaData,
    'wysiwyg.viewer.components.tpapps.TPAWidget': tpaWidgetMetaData,
    'tpa.viewer.components.tpapps.TPAWidget': tpaWidgetMetaData,
    'wysiwyg.viewer.components.tpapps.TPASection': tpaSectionMetaData,
    'wysiwyg.viewer.components.tpapps.TPAMultiSection': tpaSectionMetaData,
    'wysiwyg.viewer.components.RefComponent': refComponentMetaData,
    'wysiwyg.viewer.components.HeaderContainer': headerMetadata,
    'wysiwyg.viewer.components.FooterContainer': footerMetadata,
    'mobile.core.components.Container': containerMetadata,
    'mobile.core.components.Page': pageMetadata,
    'wixui.HamburgerOpenButton': hamburgerOpenButtonMetadata,
    'wysiwyg.viewer.components.AdminLoginButton': adminLoginButtonMetaData,
    'wysiwyg.viewer.components.LoginButton': loginButtonMetaData,
    'wysiwyg.common.components.facebooklikebox.viewer.FacebookLikeBox': facebookLikeBoxMetaData,
    'wysiwyg.viewer.components.WFacebookLike': facebookLikeMetaData,
    'wysiwyg.viewer.components.FlickrBadgeWidget': flickrBadgeWidgetMetaData,
    'wysiwyg.viewer.components.FlashComponent': flashComponentMetaData,
    'wysiwyg.common.components.pinitpinwidget.viewer.PinItPinWidget': pinItPinWidgetMetaData,
    'wysiwyg.viewer.components.PinterestFollow': pinterestFollowMetaData,
    'wysiwyg.common.components.pinterestpinit.viewer.PinterestPinIt': pinterestPinItMetaData,
    'wysiwyg.viewer.components.SiteRegionContainer': siteRegionContainerMetaData,
    'tpa.viewer.components.Thumbnails': thumbnailsMetaData,
    'tpa.viewer.components.Accordion': accordionMetaData,
    'wysiwyg.viewer.components.HtmlComponent': htmlComponentMetaData,
    'wysiwyg.viewer.components.GoogleMap': googleMapMetaData,
    'wysiwyg.common.components.spotifyfollow.viewer.SpotifyFollow': spotifyFollowMetaData,
    'wysiwyg.viewer.components.VKShareButton': vKShareButtonMetaData,
    'wysiwyg.viewer.components.WFacebookComment': wFacebookCommentMetaData,
    'wysiwyg.viewer.components.WGooglePlusOne': wGooglePlusOneMetaData,
    'wysiwyg.viewer.components.WTwitterFollow': wTwitterFollowMetaData,
    'wysiwyg.viewer.components.WTwitterTweet': wTwitterTweetMetaData,
    'wysiwyg.common.components.youtubesubscribebutton.viewer.YouTubeSubscribeButton': youTubeSubscribeButtonMetaData,
    'wixapps.integration.components.AppPart2': appPart2MetaData,
    'tpa.viewer.components.StripShowcase': stripShowCaseMetaData,
    'tpa.viewer.components.Collage': collageMetaData,
    'tpa.viewer.components.Masonry': masonryMetaData,
    'tpa.viewer.components.Freestyle': freestyleMetaData,
    'wysiwyg.viewer.components.Video': videoMetaData,
    'wysiwyg.viewer.components.SoundCloudWidget': soundCloudMetaData,
    'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu': verticalMenuMetaData,
    'wysiwyg.viewer.components.ExpandableMenu': expandableMenuMetaData,
    'wysiwyg.viewer.components.menus.DropDownMenu': dropDownMenuMetaData,
    'wysiwyg.viewer.components.PopupContainer': popupContainerMetaData,
    'wysiwyg.viewer.components.BoxSlideShowSlide': boxSlideShowSlideMetaData,
    'wysiwyg.viewer.components.BoxSlideShow': boxSlideShowMetaData,
    'wysiwyg.viewer.components.StateBoxState': stateBoxStateMetaData,
    'wysiwyg.viewer.components.StateBoxFormState': stateBoxFormStateMetaData,
    'wysiwyg.viewer.components.StateBox': stateBoxMetaData,
    'wysiwyg.viewer.components.StateStripState': stateStripStateMetaData,
    'wysiwyg.viewer.components.StateStrip': stateStripMetaData,
    'wysiwyg.viewer.components.StripContainerSlideShowSlide': stripSlideShowSlideMetaData,
    'wysiwyg.viewer.components.StripContainerSlideShow': stripSlideShowMetaData,
    'wysiwyg.viewer.components.ClassicSection': classicSectionMetadata,
    'wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu': verticalAnchorsMenuMetaData,
    'wysiwyg.viewer.components.HoverBox': hoverboxMetaData,
    'wysiwyg.viewer.components.Popover': popoverMetaData,
    'wysiwyg.viewer.components.Repeater': repeaterMetaData,
    'wysiwyg.viewer.components.Column': columnMetadata,
    'wysiwyg.viewer.components.MediaPlayer': mediaPlayerMetadata,
    'wysiwyg.viewer.components.tpapps.TPA3DCarousel': threeDCarouselMetaData,
    'wysiwyg.common.components.spotifyplayer.viewer.SpotifyPlayer': spotifyPlayerMetadata,
    'wysiwyg.viewer.components.StripColumnsContainer': stripColumnsMetaData,
    'wysiwyg.common.components.anchor.viewer.Anchor': anchorMetaData,
    'wysiwyg.viewer.components.BgImageStrip': bgImageStripMetaData,
    'wysiwyg.viewer.components.FiveGridLine': fiveGridLineMetaData,
    'wysiwyg.viewer.components.StripContainer': stripContainerMetaData,
    'tpa.viewer.components.StripSlideshow': stripGallerySlideShowMetaData,
    'wixapps.integration.components.AppPage': appPageMetaData,
    'wysiwyg.viewer.components.PageGroup': pageGroupMetaData,
    'wysiwyg.viewer.components.PagesContainer': pagesContainerMetaData,
    'wysiwyg.viewer.components.ScreenWidthContainer': screenWidthContainerMetaData,
    'wysiwyg.viewer.components.WSiteStructure': siteStructureMetaData
})
