import _ from 'lodash'
import {
    CreateExtArgs,
    CreateExtension,
    DalValue,
    DmStore,
    DocumentDataTypes,
    Extension,
    ExtensionAPI,
    ModelsInitializationType,
    PointerMethods,
    pointerUtils
} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'

const {getPointer} = pointerUtils

const pointerType = 'documentServicesModel'
const NO_MATCH: string[] = []
const INDEX_MATCH = [pointerType]

const getMetadataInnerPointer = (key: string): Pointer => getPointer('metaSiteData', pointerType, {innerPath: [key]})

const isDraftPointer = getPointer('isDraft', pointerType)
const versionPointer = getPointer('versionInfo', pointerType, {innerPath: ['version']})
const revisionPointer = getPointer('versionInfo', pointerType, {innerPath: ['revision']})
const userInfoPointer = getPointer('userInfo', pointerType)

const createSeoPointers = () => ({
    getIndexing: () => getMetadataInnerPointer('indexable'),
    getTitle: () => getMetadataInnerPointer('title'),
    getMetaTags: () => getMetadataInnerPointer('metaTags'),
    getRedirectUrls: () => getMetadataInnerPointer('externalUriMappings'),
    getThumbnail: () => getMetadataInnerPointer('thumbnail'),
    getSuppressTrackingCookies: () => getMetadataInnerPointer('suppressTrackingCookies'),
    getFavicon: () => getMetadataInnerPointer('favicon')
})

const createPointersMethods = (): PointerMethods => {
    const getEditorSessionId = () => getPointer('editorSessionId', pointerType)
    const getEnvSessionId = () => getPointer('envSessionId', pointerType)
    const getMediaUserUploadToken = () => getPointer('mediaManagerInfo', pointerType, {innerPath: ['userUploadToken']})
    const getMediaSiteUploadToken = () => getPointer('mediaManagerInfo', pointerType, {innerPath: ['siteUploadToken']})
    const isHttpsEnabled = () => getPointer('isHttpsEnabled', pointerType)
    const getUseOnboarding = () => getMetadataInnerPointer('useOnboarding')
    const getUsedMetaSiteNames = () => getPointer('usedMetaSiteNames', pointerType)
    const isPublished = () => getPointer('isPublished', pointerType)
    const hasSites = () => getPointer('hasSites', pointerType)
    const getPublicUrl = () => getPointer('publicUrl', pointerType)
    const getPermissionsInfo = () => getPointer('permissionsInfo', pointerType)
    const isOwner = () => getPointer('permissionsInfo', pointerType, {innerPath: ['isOwner']})
    const getPermissions = () => getPointer('permissionsInfo', pointerType, {innerPath: ['permissions']})
    const getSiteToken = () => getPointer('permissionsInfo', pointerType, {innerPath: ['siteToken']})
    const getUserInfo = () => getPointer('userInfo', pointerType)
    const getOriginalTemplateId = () => getPointer('originalTemplateId', pointerType)
    const getAutoSaveInnerPointer = (key: string) => getPointer('autoSaveInfo', pointerType, {innerPath: [key]})
    const getAutosaveInfo = () => getPointer('autoSaveInfo', pointerType)
    const getPendingApps = () => getPointer('pendingApps', pointerType)
    const getSiteName = () => getPointer('siteName', pointerType)
    const getCustomHeadTags = () => getPointer('customHeadTags', pointerType)
    const getMetaSiteData = () => getPointer('metaSiteData', pointerType)
    const getSiteRevision = () => revisionPointer
    const getSiteVersion = () => versionPointer
    const getIsStudioUser = () => getPointer('userInfo', pointerType, {innerPath: ['isStudio']})
    const getDockedRuntimeLayout = () => getPointer('dockedRuntimeLayout', pointerType)
    const getIsDraft = () => isDraftPointer
    const getBranchId = () => getPointer('branchId', pointerType)
    const getOpenWixCodeAppId = () => getPointer('openWixCodeAppId', pointerType)

    return {
        documentServicesModel: {
            getEditorSessionId,
            getEnvSessionId,
            getMediaUserUploadToken,
            getMediaSiteUploadToken,
            isHttpsEnabled,
            getUseOnboarding,
            getUsedMetaSiteNames,
            isPublished,
            hasSites,
            getPublicUrl,
            isOwner,
            getUserInfo,
            getIsDraft,
            getOriginalTemplateId,
            getPermissions,
            getSiteToken,
            getPendingApps,
            getSiteName,
            getCustomHeadTags,
            getMetaSiteData,
            getSiteVersion,
            getIsStudioUser,
            getDockedRuntimeLayout,
            getPermissionsInfo,
            getBranchId
        },
        general: {
            getIsDraft,
            getAutoSaveInnerPointer,
            getAutosaveInfo,
            getIsStudioUser,
            getPublicUrl,
            getDockedRuntimeLayout,
            getSiteVersion,
            hasSites
        },
        save: {
            getSiteRevision
        },
        autoSave: {
            getAutoSaveInnerPointer,
            getAutosaveInfo
        },
        seo: {
            ...createSeoPointers()
        },
        wixCode: {
            getOpenWixCodeAppId
        }
    }
}

const initialState = {
    [pointerType]: {
        dockedRuntimeLayout: {}
    }
}

const createExtensionAPI = ({dal, pointers}: CreateExtArgs): DocumentServicesModelExtApi => {
    const esiPointer = pointers.documentServicesModel.getEditorSessionId()
    return {
        siteAPI: {
            getPublicBaseUrl: () =>
                dal.get(pointers.documentServicesModel.isPublished())
                    ? dal.get(pointers.documentServicesModel.getPublicUrl())
                    : '',
            getLastTransactionId: () => dal.get(pointers.general.getAutoSaveInnerPointer('lastTransactionId')),
            getActionsCount: () => dal.get(pointers.general.getAutoSaveInnerPointer('actionsCount')),
            setActionsCount: (value: number) => {
                const autosaveCountPointer = pointers.general.getAutoSaveInnerPointer('actionsCount')
                dal.set(autosaveCountPointer, value)
            },
            getChangesApplied: () => dal.get(pointers.general.getAutoSaveInnerPointer('changesApplied')),
            getEditorSessionId: () => dal.get(esiPointer),
            getEnvSessionId: () => dal.get(pointers.documentServicesModel.getEnvSessionId()),
            forceUpdate: () => {
                dal.commitTransaction('documentServicesModelExt')
            },
            getIsDraft: () => dal.get(pointers.general.getIsDraft()),
            getAutosaveInfo: () => dal.get(pointers.autoSave.getAutosaveInfo()),
            getSiteRevision: () => dal.get(pointers.save.getSiteRevision()),
            getBranchId: () => dal.get(pointers.documentServicesModel.getBranchId()),
            setSiteRevision: (val: number) => {
                dal.set(pointers.save.getSiteRevision(), val)
            },
            setChangesApplied: (val: boolean) => {
                dal.set(pointers.general.getAutoSaveInnerPointer('changesApplied'), val)
            },
            getSiteVersion: () => dal.get(pointers.documentServicesModel.getSiteVersion()),
            setSiteVersion: (val: number) => {
                dal.set(pointers.documentServicesModel.getSiteVersion(), val)
            },
            getDocumentServicesModel: () =>
                _.map(
                    dal.query(pointerType, dal.queryFilterGetters.getDocumentServicesModelFilter(pointerType)),
                    (value, id) => ({
                        pointer: getPointer(id, pointerType),
                        value
                    })
                )
        }
    }
}

const createFilters = () => ({
    getDocumentServicesModelFilter: (namespace: string): string[] => {
        if (namespace === pointerType) {
            return INDEX_MATCH
        }
        return NO_MATCH
    }
})

export interface DocumentServicesModelExtApi extends ExtensionAPI {
    siteAPI: {
        getPublicBaseUrl(): string
        getLastTransactionId(): string
        getActionsCount(): number
        setActionsCount(value: number): void
        getChangesApplied(): boolean
        getEditorSessionId(): string
        getEnvSessionId(): string
        forceUpdate(): void
        getIsDraft(): boolean
        getAutosaveInfo(): {
            shouldAutoSave: boolean
            lastTransactionId?: string
        }
        setChangesApplied(val: boolean): void
        getBranchId(): string
        getSiteRevision(): number
        setSiteRevision(val: number): void
        getSiteVersion(): number
        setSiteVersion(val: number): void
        getDocumentServicesModel(): any
    }
}
const idsWithSignature = new Set(['metaSiteData'])
const initializeModels = (initialStore: DmStore, initialModels: ModelsInitializationType) => {
    const {documentServicesModel} = initialModels
    const {signatures = {}} = documentServicesModel

    for (const idWithSig of idsWithSignature) {
        if (!documentServicesModel[idWithSig] && signatures[`documentServicesModel.${idWithSig}`]) {
            documentServicesModel[idWithSig] = {}
        }
    }

    initialStore.set(getPointer('versionInfo', pointerType), {
        id: 'versionInfo',
        type: 'versionInfo',
        version: documentServicesModel?.version,
        revision: documentServicesModel?.revision
    })

    _(documentServicesModel)
        .omit(['version', 'revision', 'signatures'])
        .forOwn((value: DalValue, key) => {
            const itemPointer = getPointer(key, 'documentServicesModel')
            const signature = signatures[`documentServicesModel.${key}`]
            if (idsWithSignature.has(key) && signature) {
                const signedValue = {...value, metaData: _.clone(value.metaData)}
                _.setWith(signedValue, ['metaData', 'sig'], signature, Object)
                initialStore.set(itemPointer, signedValue)
            } else {
                initialStore.set(itemPointer, value)
            }
        })
}
const createExtension: CreateExtension = (): Extension => {
    const getDocumentDataTypes = (): DocumentDataTypes => ({
        [pointerType]: {idsWithSignature}
    })

    return {
        name: 'documentServicesModel',
        createPointersMethods,
        getDocumentDataTypes,
        initialState,
        initializeModels,
        createExtensionAPI,
        createFilters
    }
}

export {createExtension, isDraftPointer, versionPointer, revisionPointer, userInfoPointer}
