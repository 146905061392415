import type {RunningExperiments, Experiment} from '@wix/document-services-types'
import * as exp from './experimentUtils'

let context = {}

export const getRunningExperiments = (override: RunningExperiments) => exp.getRunningExperiments(override || context)
export const isOpen = (name: string, override?: RunningExperiments) => exp.isOpen(name, override ?? context)
export const getValue = (name: string, override: RunningExperiments) => exp.getValue(name, override || context)
export const isMultiValueExperimentOpen = (name: string, override: RunningExperiments) =>
    exp.isMultiValueExperimentOpen(name, override || context)
export const setContext = (newContext: any) => {
    context = newContext
}

export const experiment: Experiment = {
    getRunningExperiments,
    getValue,
    isMultiValueExperimentOpen,
    isOpen
}

export default experiment
