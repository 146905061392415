const pointerType = 'svgShapes'
import {pointerUtils} from '@wix/document-manager-core'

const {getPointer} = pointerUtils

const createPointersMethods = () => ({
    svgShapes: {
        getSvgShapePointer: (id: string) => getPointer(id, pointerType)
    }
})

export {createPointersMethods}
