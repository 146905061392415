import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import pathUtils from '../utils/pathUtils'
import types from './types'
import items from './items'
import selection from './selection'

const ERROR_LIST_DOES_NOT_EXIST = 'List does not exist'
const ERROR_DATA_SELECTOR_DOES_NOT_EXIST = 'Data selector does not exist'

function throwError(errorMessage: string) {
    throw new Error(errorMessage)
}

/**
 * Get a list definition
 * @param {ps} ps Private Services
 * @param {string} listId
 * @returns {Object} the request list part definition
 */
function getListDef(ps: PS, listId: string) {
    return ps.wixappsDAL.getByPath(pathUtils.getPartPath(listId))
}

/**
 * Get all items that belong to a given list
 * @param {ps} ps Private Services
 * @param {string} listId
 * @returns {Object|{}} map of items with their id as the key
 * @throws Throws an error if the list does not exist
 * @throws Throws an error if the list's data selector does not exist
 * @throws Throws an error if list is not manual
 */
function getItems(ps: PS, listId: string) {
    const list = getListDef(ps, listId) || throwError(ERROR_LIST_DOES_NOT_EXIST)
    const dataSelector = getSelector(ps, listId)
    if (!dataSelector) {
        throw new Error(ERROR_DATA_SELECTOR_DOES_NOT_EXIST)
    }
    if (dataSelector.type !== 'ManualSelectedList') {
        return _.values(items.getAllItemsOfType(ps, list.type))
    }
    return _(items.getAllItemsOfType(ps, list.type)).pick(dataSelector.itemIds).values().value()
}

/**
 * Get a list's type definition object
 * @param {ps} ps Private Services
 * @param {string} listId
 * @returns {Object} List's type definition
 * @throws Throws an error if the list does not exist
 */
function getType(ps: PS, listId: string) {
    const list = getListDef(ps, listId) || throwError(ERROR_LIST_DOES_NOT_EXIST)
    return types.getType(ps, list.type)
}

/**
 * Get a list's data selector object
 * @param {ps} ps Private Services
 * @param {string} listId
 * @returns {Object} List's data selector
 * @throws Throws an error if the list does not exist
 */
function getSelector(ps: PS, listId: string) {
    const list = getListDef(ps, listId) || throwError(ERROR_LIST_DOES_NOT_EXIST)
    return selection.getSelector(ps, list.dataSelector)
}

export default {
    getListDef,
    getItems,
    getType
}
