import type {PS, BaseFixer} from '@wix/document-services-types'
import _ from 'lodash'
import mobileUtil from '../mobileUtilities/mobileUtilities'
import constants from '../constants/constants'
import dsDataFixers from './dsDataFixers'
import experiment from 'experiment-amd'
import config from './config.json'
import type {FixerVersioningConfig} from '@wix/document-manager-utils'

const dsFixerVersioningConfig = _.pick(config, ['lastFixerIndex', 'fixers']) as FixerVersioningConfig

const masterPageId = 'masterPage'

function fix(ps: PS, dataFixers = dsDataFixers, fixerVersioningConfig = dsFixerVersioningConfig) {
    const dsDataFixersFedOpsTags = constants.INTERACTIONS.DOCUMENT_SERVICES_DATA_FIXERS

    ps.extensionAPI.logger.interactionStarted(dsDataFixersFedOpsTags.ALL)

    const shouldDisableFixersWithoutRerunFlag = experiment.isOpen('dm_onlyRerunWhenFixerRequiresReruns')
    const shouldRerunFixer = (fixer: BaseFixer) =>
        !fixer.disableFixerAfterFirstRun && (!shouldDisableFixersWithoutRerunFlag || fixer.fixerRequiresReruns)

    const dsFixersCategory = 'ds_fixer'
    const fixerChangesOnReruns = {}
    const fixerVersions = {}

    _.forEach(dataFixers, function (fixerData: any) {
        if (mobileUtil.isMobileSupported(ps) || !_.get(fixerData, ['config', 'fixMobileOnly'])) {
            const {name: fixerName, version: baseVersion, disableVersioning, experimentalVersions} = fixerData.fixer
            const fixerExperiment = ps.extensionAPI.dataFixerVersioning.decorateExperimentWithVersions(
                experiment,
                {fixerName},
                baseVersion,
                experimentalVersions
            )
            const {version: fixerVersion} = fixerExperiment
            if (
                ps.extensionAPI.dataFixerVersioning.hasFixerRunOnCurrentVersion(
                    masterPageId,
                    dsFixersCategory,
                    fixerName,
                    fixerVersion,
                    fixerVersioningConfig
                )
            ) {
                if (!shouldRerunFixer(fixerData.fixer)) {
                    return
                }
                const changes = ps.extensionAPI.dataFixerVersioning.executeFixerAndSetModifications(
                    fixerData.fixer.exec.bind(null, ps, fixerExperiment),
                    '_site_',
                    fixerName,
                    fixerVersion
                )
                _.merge(fixerChangesOnReruns, changes)
            } else {
                if (!disableVersioning) {
                    fixerVersions[fixerName] = fixerVersion
                }
                fixerData.fixer.exec(ps, fixerExperiment)
            }
        }
    })

    ps.extensionAPI.dataFixerVersioning.updatePageVersionData(
        masterPageId,
        {[dsFixersCategory]: fixerVersions},
        {[dsFixersCategory]: fixerVersioningConfig}
    )
    ps.extensionAPI.dataFixerVersioning.reportFixerActions(dsFixersCategory, fixerChangesOnReruns)

    ps.extensionAPI.logger.interactionEnded(dsDataFixersFedOpsTags.ALL)
}

export default {
    fix
}
