import type {CreateExtArgs, CreateExtensionArgument} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import {getComponentIncludingDisplayOnly} from '../../../utils/dalUtils'
import {COMP_TYPES} from '../../../constants/constants'
import {isBlocksEditor} from '../../../utils/appUtils'
import type {BlocksExtensionAPI} from '../../blocks/blocks'

export const containerMetadata = {
    canConnectToCode: (
        createExtArgs: CreateExtArgs,
        componentPointer: Pointer,
        createExtensionArgument?: CreateExtensionArgument
    ) => {
        if (!isBlocksEditor(createExtensionArgument)) {
            return true
        }

        const {dal, pointers} = createExtArgs
        const pagePointer = pointers.structure.getPageOfComponent(componentPointer)

        const {blocks} = createExtArgs.extensionAPI as BlocksExtensionAPI
        const entity = blocks.getEntityByPage(pagePointer)

        if (entity === 'PANEL') {
            return false
        }

        if (entity === 'WIDGET') {
            const parentPointer = pointers.structure.getParent(componentPointer)
            const parentComponent = parentPointer && getComponentIncludingDisplayOnly(dal, parentPointer)

            return parentComponent?.componentType !== COMP_TYPES.PAGE_TYPE
        }

        return true
    }
}
