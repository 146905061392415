import {colorThemes} from './colorThemes'
import type {ThemeAPI} from '@wix/document-manager-extensions'
import type {ToolDefinition} from './aiTools'
import type {PS} from '@wix/document-services-types'

const themeColorPicker = (ps: PS, params: any) => {
    const {colorTheme} = params
    const themeColors = colorThemes[colorTheme]
    const themeApi = ps.extensionAPI.theme as ThemeAPI
    themeApi.updateColors(themeColors)
}

const themeColorPickerValidation = (ps: PS, params: any) => {
    const {colorTheme} = params
    if (colorThemes[colorTheme]) {
        return {success: true}
    }
    return {
        success: false,
        validationMessage: `Did not find the color theme ${colorTheme}`
    }
}

const themeColorPickerResponse = (ps: PS, params: any) => `Changed color theme to ${params.colorTheme} successfully`

export const themeColorPickerTool: ToolDefinition = {
    name: 'themeColorPicker',
    func: themeColorPicker,
    validationFunc: themeColorPickerValidation,
    responseFunc: themeColorPickerResponse
}
