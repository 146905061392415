import type {PS} from '@wix/document-services-types'
import appStudioDataModel from './appStudioDataModel'

const CODE_PACKAGES_TYPE = 'CodePackagesDescriptor'

function createCodePackagesData(
    ps: PS,
    {displayName, name, description}: {displayName: string; name: string; description: string}
) {
    const codePackage = ps.extensionAPI.dataModel.createDataItemByType(CODE_PACKAGES_TYPE)
    codePackage.displayName = displayName
    codePackage.name = name
    codePackage.description = description

    return codePackage
}

function prepareCodePackagesData(ps: PS, codePackages) {
    return codePackages.map(codePackage => createCodePackagesData(ps, codePackage))
}

function getCodePackagesData(ps: PS) {
    const appStudioData = appStudioDataModel.getAppStudioData(ps)

    return appStudioData.codePackages
}

function addCodePackagesData(ps: PS, codePackages) {
    const appStudioData = appStudioDataModel.getAppStudioData(ps)
    const codePackagesData = prepareCodePackagesData(ps, codePackages)
    appStudioData.codePackages = appStudioData.codePackages
        ? [...appStudioData.codePackages, ...codePackagesData]
        : codePackagesData

    appStudioDataModel.updateAppStudioMetaData(ps, appStudioData)
}

function removeCodePackagesData(ps: PS, packageName: string) {
    const appStudioData = appStudioDataModel.getAppStudioData(ps)
    appStudioData.codePackages = appStudioData.codePackages.filter(codePackage => codePackage.name !== packageName)

    appStudioDataModel.updateAppStudioMetaData(ps, appStudioData)
}

function setCodePackagesData(ps: PS, codePackages) {
    const appStudioData = appStudioDataModel.getAppStudioData(ps)
    appStudioData.codePackages = prepareCodePackagesData(ps, codePackages)

    appStudioDataModel.updateAppStudioMetaData(ps, appStudioData)
}

export default {
    getCodePackagesData,
    addCodePackagesData,
    setCodePackagesData,
    removeCodePackagesData
}
