import {debug} from '@wix/document-manager-core'
import type {
    CreateTransactionRequest,
    CreateTransactionResponse,
    GetDocumentResponse,
    GetTransactionResponse,
    HttpServer
} from '@wix/document-manager-extensions'
import type {
    ContinuousSaveServer,
    SaveTransactionResponse
} from '@wix/document-manager-extensions/src/extensions/csave/csaveTypes'
import type {ViewsRESTRes, ViewsServer} from '@wix/document-manager-extensions/src/extensions/views/viewsTypes'
import type {
    CreateRevisionReq,
    CreateRevisionRes,
    GetTransactionsResponse,
    SaveRequest
} from '@wix/document-manager-extensions/src/types'
import type {DistributePayload} from '@wix/ambassador-document-management-document-store-v1-transaction/types'
import {getReportableFromError, HttpRequestError, Method, retryTaskAndReport} from '@wix/document-manager-utils'
import type {AdapterLogger} from '../adapter/adapterLogger'
import {fetchJsonWithAuthorization, Query, toQueryString} from '../utils/fetch'
import {AuthorizationStatusMap, InvalidAuthorizationMap} from './AuthorizationMap'
import {fetchClientSpecMap, META_SITE_APP_ID} from './clientSpecMap'
import {MAX_LONG, ServerFacade} from './serverFacade'
import type {Experiment, UserInfo} from '@wix/document-services-types'
import _ from 'lodash'

const log = debug('csave')
const getInstanceHint = (instance: string) => instance?.slice(0, 4) || 'missing'

/**
 * based on
 * https://github.com/wix-private/editor-server/blob/master/wix-html-server/editor-document-store/src/main/proto/com/wixpress/editor/api/document_store.proto
 */

export class ServerFacadeWithAuthorization
    extends ServerFacade
    implements ContinuousSaveServer, ViewsServer, HttpServer
{
    constructor(
        editorRootUrl: string,
        private logger: AdapterLogger,
        private experimentInstance: Experiment,
        private userInfo: UserInfo,
        private authorizationStatusMap: AuthorizationStatusMap = new InvalidAuthorizationMap()
    ) {
        super(editorRootUrl)
    }

    async deleteTransactions(instance: string, to: string = MAX_LONG): Promise<void> {
        // deleteTransaction must include body param to => the transactions up until this ID will be deleted
        const payload = {to}
        const result = await this.deleteJson(this.urls.transactions, payload)
        this.lastTransactionId = result.transactionId
        log.info('deleteTransactions done', result)
    }

    async createRevision(req: CreateRevisionReq): Promise<CreateRevisionRes> {
        log.info('createRevision')
        return await this.post(this.urls.createRevision, req)
    }

    async getTransactions(
        afterTransactionId: string,
        untilTransactionId: string,
        branchId: string
    ): Promise<GetTransactionsResponse> {
        const queryParams = {afterTransactionId, untilTransactionId, branchId}
        return this.getJsonWithParams(this.urls.transactions, queryParams)
    }

    async getViews(): Promise<ViewsRESTRes> {
        return await this.getJsonWithParams(this.urls.views, {})
    }

    async distributeMessage(payloads: DistributePayload[]): Promise<void> {
        return await this.post(this.urls.distributor, {payloads})
    }

    async getTransaction(transactionId: string, branchId?: string): Promise<GetTransactionResponse> {
        const params = {branchId}
        return this.getJsonWithParams(`${this.urls.transactions}/${transactionId}`, params)
    }

    async getStore(
        branchId: string,
        afterTransactionId: string,
        untilTransactionId?: string
    ): Promise<GetDocumentResponse> {
        const params = {afterTransactionId, untilTransactionId, branchId}
        return retryTaskAndReport({
            task: () => this.getJsonWithParams(this.urls.document, params),
            checkIfShouldRetry: (e: any) => {
                if (e?.status === 503) {
                    return {shouldRetry: true, reason: '503'}
                }
                return {shouldRetry: false}
            },
            interactionName: 'retry_get_store',
            maxRetries: 1,
            logger: this.logger
        })
    }

    /**
     * first createTransaction should be sent without lastTransactionId, the subsequent calls must include the previous tx id as lastTransactionId
     * PendingServerTransaction
     */
    async save(payload: CreateTransactionRequest): Promise<CreateTransactionResponse> {
        log.info('sending tx')
        const {correlationId} = payload
        this.logger.interactionStarted('save', {
            extras: {correlationId}
        })
        const response = await this.post<CreateTransactionResponse>(this.urls.save, payload)
        this.logger.interactionEnded('save', {
            extras: {correlationId, requestId: response?.requestId}
        })
        return response
    }

    async asyncSave(payload: SaveRequest): Promise<void> {
        log.info('sending pending tx')
        const correlationIds = payload.transactions?.map(tx => tx.correlationId)
        this.logger.interactionStarted('asyncSave', {
            extras: {correlationIds}
        })
        const response: any = await this.post<void>(this.urls.save2, payload)
        this.logger.interactionEnded('asyncSave', {
            extras: {correlationIds, requestId: response?.requestId}
        })
        return response
    }

    async post<T = any, R = any>(url: string, data?: R): Promise<T> {
        return this.fetch<T>(url, 'POST', data)
    }

    async deleteJson<T = any, R = any>(url: string, data?: R): Promise<T> {
        return this.fetch<T>(url, 'DELETE', data)
    }

    async refreshInstanceIfExpired(appId: string, source: string): Promise<AuthorizationStatusMap> {
        const auth = this.authorizationStatusMap.get(appId)
        this.logger.interactionStarted('refreshInstanceIfExpired', {
            tags: {source},
            extras: {
                isExpired: auth.isExpired(),
                authForApp: auth,
                appId
            }
        })
        if (auth.isExpired()) {
            const interactionName = 'authorizationRefresh'
            log.info('authorization instance is stale')
            const eventGuid = _.random(10000, true)
            this.logger.interactionStarted(interactionName, {
                eventGuid,
                tags: {source},
                extras: {
                    expiration: auth?.expirationDate,
                    hint: getInstanceHint(auth?.instance)
                }
            })
            await this.refreshClientSpecMap()
            const updatedAuth = this.authorizationStatusMap.get(appId)
            this.logger.interactionEnded(interactionName, {
                eventGuid,
                tags: {source},
                extras: {
                    expiration: updatedAuth?.expirationDate,
                    hint: getInstanceHint(updatedAuth?.instance)
                }
            })
        }
        this.logger.interactionEnded('refreshInstanceIfExpired', {
            tags: {source},
            extras: {}
        })
        return this.authorizationStatusMap
    }

    async refreshClientSpecMap() {
        this.authorizationStatusMap = await fetchClientSpecMap(
            fetch,
            this.authorizationStatusMap.get(META_SITE_APP_ID).metaSiteId,
            this.experimentInstance,
            this.logger
        )
    }

    async fetch<T = any, R = any>(url: string, method: Method, data?: R): Promise<T> {
        try {
            await this.refreshInstanceIfExpired(META_SITE_APP_ID, 'fetchWithAuthorization')
        } catch (e: any) {
            if (e instanceof HttpRequestError && [404, 403].includes(e.status)) {
                throw e
            }
            this.logger.captureError(
                getReportableFromError(e, {
                    errorType: 'fetchAuthRefreshError',
                    message: 'fetch failed',
                    tags: {op: 'ServerFacadeWithAuthorization.fetch'}
                })
            )
        }
        return fetchJsonWithAuthorization(
            url,
            this.authorizationStatusMap.getInstance(META_SITE_APP_ID),
            method,
            data,
            this.logger,
            this.experimentInstance,
            this.userInfo
        )
    }

    async getJsonWithParams<T = any>(url: string, queryParams: Query): Promise<T> {
        const queryString = toQueryString(queryParams)
        return this.fetch(`${url}?${queryString}`, 'GET')
    }

    async saveSyncWithMultipleTransactions(): Promise<SaveTransactionResponse> {
        throw new Error('not implemented')
    }
}
