import type {CreateExtArgs} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import type {DataModelExtensionAPI} from '../dataModel/dataModel'
import {DATA_TYPES} from '../../constants/constants'

export const LEGACY_FULL_WIDTH_CONTAINERS = {
    'wysiwyg.viewer.components.WSiteStructure': true,
    'wysiwyg.viewer.components.FooterContainer': true,
    'wysiwyg.viewer.components.HeaderContainer': true,
    'mobile.core.components.Page': true,
    'core.components.Page': true,
    'wixapps.integration.components.AppPage': true,
    'wysiwyg.viewer.components.ScreenWidthContainer': true,
    'wysiwyg.viewer.components.StripContainer': true,
    'wysiwyg.viewer.components.StripContainerSlideShow': true,
    'wysiwyg.viewer.components.StripContainerSlideShowSlide': true,
    'wysiwyg.viewer.components.ClassicSection': true,
    'wysiwyg.viewer.components.Column': ({pointers, extensionAPI}: CreateExtArgs, compPtr: Pointer) => {
        if (pointers.structure.isMobile(compPtr)) {
            return true
        }
        const {dataModel} = extensionAPI as DataModelExtensionAPI
        const isSingleColumn = pointers.structure.getSiblings(compPtr).length === 0

        const columnsContainer = pointers.structure.getParent(compPtr)
        const columnsContainerProperties = dataModel.components.getItem(columnsContainer!, DATA_TYPES.prop)

        return isSingleColumn && columnsContainerProperties.fullWidth
    }
}
