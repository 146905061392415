import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import jsonSchemaUtils from '@wix/wix-json-schema-utils'
import dsUtils from '../utils/utils'
import appStudioDataModel from './appStudioDataModel'
import appMetadata from './appMetadata'

const createPropertiesDescriptor = (ps: PS, propertiesSchemas) => {
    const customDefinitions = _.assign({}, ...appStudioDataModel.getAllSerializedCustomDefinitions(ps))
    const resolver = jsonSchemaUtils.createResolver(jsonSchemaUtils.baseDefinitions, customDefinitions)
    return (
        _(propertiesSchemas)
            .map(propSchema => resolver.resolve(propSchema.structure))
            // @ts-expect-error
            .thru(resolvedProperty => _.assign(...resolvedProperty))
            .toPairs()
            .map(([name, {default: defaultValue}]) => ({
                name,
                defaultValue
            }))
            .value()
    )
}

const createPublicDescriptor = (ps: PS, widgetPointer: Pointer) => {
    const widgetData = appStudioDataModel.getData(ps, widgetPointer)

    return {
        functions: _.map(widgetData.widgetApi.functions, ({name}) => ({name})),
        events: _.map(widgetData.widgetApi.events, ({name}) => ({name})),
        properties: createPropertiesDescriptor(ps, widgetData.widgetApi.propertiesSchemas),
        behaviors: appMetadata.getChildrenStaticEvents(ps, dsUtils.stripHashIfExists(widgetData.rootCompId))
    }
}

const createPublicDescriptorsMap = (ps: PS) => {
    const allWidgets = appStudioDataModel.getAllWidgets(ps)
    return _.reduce(
        allWidgets,
        (accumulator, {pointer}) => {
            const widgetRootCompId = appStudioDataModel.getRootCompIdByPointer(ps, pointer)
            accumulator[widgetRootCompId] = createPublicDescriptor(ps, pointer)
            return accumulator
        },
        {}
    )
}

export default {
    createPublicDescriptor,
    createPublicDescriptorsMap
}
