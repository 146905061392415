import errors from './bi/errors'
import events from './bi/events'
import supportedSiteEvents from './common/supportedSiteEvents'
import {TpaBaseUrlBuilder} from './common/TPABaseUrlBuilder'
import tpaMetaData from './common/tpaMetaData'
import {TpaUrlBuilder} from './common/TPAUrlBuilder'
export * as gluedWidgetMeasuringUtils from './utils/gluedWidgetMeasuringUtils'
import tpaUtils from './utils/tpaUtils'

export const common = {
    metaData: tpaMetaData,
    TpaUrlBuilder,
    TpaBaseUrlBuilder,
    utils: tpaUtils,
    supportedSiteEvents
}
export const bi = {
    errors,
    events
}
