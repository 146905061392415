import type {Layout, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import structure from '../../structure/structure'
import dsUtils from '../../utils/utils'
import type {LayoutCallback} from './utils/layoutHooksUtils'

const controlsBar = 'wysiwyg.viewer.components.MediaControls'
const bigPlay = 'wysiwyg.viewer.components.MediaOverlayControls'

/**
 * Max size of the bigPlay relative to the videoBox shortest width or height in percent
 * @type {number}
 */
const maxBigPlayPercentOfParent = 0.65

/**
 * the width of the mute button in px
 * TODO: It is not good practice for the value to be hardcoded here
 * @type {number}
 */
const controlsBarWidth = 51

/**
 * define the min max range values
 * @type {{TOP_LEFT: {min: number, max: number}, BOTTOM_RIGHT: {min: number, max: number}, TOP_RIGHT: {min: number, max: number}, BOTTOM_LEFT: {min: number, max: number}}}
 */
const rotationRange = {
    TOP_LEFT: {min: 135, max: 225},
    TOP_RIGHT: {min: 45, max: 135},
    BOTTOM_RIGHT: {min: 315, max: 45},
    BOTTOM_LEFT: {min: 225, max: 315}
}

/**
 * checks if target number is between min / max
 * @param {number} min
 * @param {number} max
 * @param {number} target
 * @returns {boolean}
 */
function isBetween(min: number, max: number, target: number): boolean {
    return target > min && target < max
}

/**
 * Get a map of pointer and layout of all videoBox children by componentType
 * @param {ps} ps
 * @param playerPointer
 * @returns {{compPointer: Pointer, compLayout: object}}
 */
function getPlayerChildrenMap(ps: PS, playerPointer: Pointer) {
    const children = ps.pointers.components.getChildren(playerPointer)
    const childrenMap = _.reduce(
        children,
        (acc, compPointer) => {
            const compType = ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'componentType'))
            const compLayout = ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'layout'))
            acc[compType] = {compPointer, compLayout}
            return acc
        },
        {} as Record<string, {compPointer: Pointer; compLayout: any}>
    )
    return childrenMap
}

/**
 * Get the pointer and layout of a player by an id in a component properties
 * @param {ps} ps
 * @param compPointer
 * @returns {{playerLayout: object, playerPointer: Pointer}}
 */
function getPlayerMapFromProperties(ps: PS, compPointer: Pointer) {
    const playerPointer = getPlayerPointerFromProperties(ps, compPointer)
    const playerLayout = ps.dal.get(ps.pointers.getInnerPointer(playerPointer, 'layout'))
    return {playerPointer, playerLayout}
}

/**
 * Get the pointer of a player by an id in a component properties
 * @param {ps} ps
 * @param compPointer
 * @returns {Pointer}
 */
function getPlayerPointerFromProperties(ps: PS, compPointer: Pointer) {
    const propertyQuery = ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'propertyQuery'))
    const pagePointer = ps.pointers.components.getPageOfComponent(compPointer)
    const {playerId} = ps.dal.get(
        ps.pointers.data.getPropertyItem(dsUtils.stripHashIfExists(propertyQuery), pagePointer.id)
    )
    const playerPointer = ps.pointers.components.getComponent(playerId, pagePointer)
    return playerPointer
}

/**
 * Return a new transformed layout object of the bigPlay
 * 1. Limit to max size
 * 2. Center
 * @param compLayout
 * @param playerLayout
 * @returns {object}
 */
function getNewBigPlayLayout(compLayout: Layout, playerLayout: Layout) {
    const newLayout = {...compLayout}

    const maxSize = Math.min(playerLayout.width, playerLayout.height) * maxBigPlayPercentOfParent

    if (newLayout.width > maxSize || newLayout.height > maxSize) {
        newLayout.width = maxSize
        newLayout.height = maxSize
    }
    newLayout.y = (playerLayout.height - newLayout.height) / 2
    newLayout.x = (playerLayout.width - newLayout.width) / 2
    newLayout.rotationInDegrees = playerLayout.rotationInDegrees ? 360 - playerLayout.rotationInDegrees : 0
    return newLayout
}

/**
 * Return a new transformed layout object of the controlsBar (Mute)
 * 1. Limit to size
 * 2. position relatively to rotation
 * @param compLayout
 * @param playerLayout
 * @returns {object}
 */
function getNewControlsBarLayout(compLayout: Layout, playerLayout: Layout /*, bigPlayLayout*/) {
    const newLayout = {...compLayout}
    const {rotationInDegrees, width: playerWidth, height: playerHeight} = playerLayout
    newLayout.width = controlsBarWidth
    //default to BOTTOM RIGHT
    newLayout.x = playerWidth - newLayout.width
    newLayout.y = playerHeight - newLayout.height

    if (isBetween(rotationRange.TOP_RIGHT.min, rotationRange.TOP_RIGHT.max, rotationInDegrees)) {
        newLayout.y = 0
    } else if (isBetween(rotationRange.TOP_LEFT.min, rotationRange.TOP_LEFT.max, rotationInDegrees)) {
        newLayout.x = 0
        newLayout.y = 0
    } else if (isBetween(rotationRange.BOTTOM_LEFT.min, rotationRange.BOTTOM_LEFT.max, rotationInDegrees)) {
        newLayout.x = 0
    }
    //flip the rotation
    newLayout.rotationInDegrees = playerLayout.rotationInDegrees ? 360 - playerLayout.rotationInDegrees : 0

    return newLayout
}

/**
 * Layout hook for videoBox to layout children on videoBox layout changes
 * @public
 * @param {ps} ps
 * @param playerPointer
 * @param newPlayerLayout
 */
function layoutPlayerChildren(
    ps: PS,
    playerPointer: Pointer,
    newPlayerLayout: Layout /*, updateCompLayoutCallbackForHooks, isTriggeredByHook, previousLayout*/
) {
    const childrenMap = getPlayerChildrenMap(ps, playerPointer)
    const bigPlayComp = _.get(childrenMap, bigPlay)
    const controlsBarComp = _.get(childrenMap, controlsBar)

    if (bigPlayComp) {
        const bigPlayLayout = getNewBigPlayLayout(bigPlayComp.compLayout, newPlayerLayout)
        structure.updateCompLayout(ps, bigPlayComp.compPointer, bigPlayLayout, true)
    }
    if (controlsBarComp) {
        const controlsBarLayout = getNewControlsBarLayout(
            controlsBarComp.compLayout,
            newPlayerLayout /*, bigPlayLayout*/
        )
        structure.updateCompLayout(ps, controlsBarComp.compPointer, controlsBarLayout, true)
    }
}

/**
 * Layout hook for bigPlay, also updates controlsBar position
 * Mutates passed compLayout
 * @public
 * @param {ps} ps
 * @param compPointer
 * @param compLayout
 * @param updateCompLayoutCallbackForHooks
 * @param isTriggeredByHook
 */
function positionBigPlay(
    ps: PS,
    compPointer: Pointer,
    compLayout: Layout,
    updateCompLayoutCallbackForHooks: LayoutCallback,
    isTriggeredByHook: boolean
) {
    if (isTriggeredByHook) {
        return
    }
    const {/*playerPointer, */ playerLayout} = getPlayerMapFromProperties(ps, compPointer)
    _.assign(compLayout, getNewBigPlayLayout(compLayout, playerLayout))

    // Update controls bar relative to big play
    // const childrenMap = getPlayerChildrenMap(ps, playerPointer)
    // const controlsBarLayout = getNewControlsBarLayout(childrenMap[controlsBar].compLayout, playerLayout, compLayout)
    // structure.updateCompLayout(ps, childrenMap[controlsBar].compPointer, controlsBarLayout, true)
}

/**
 * Layout hook for controlsBar (Mute)
 * Mutates passed compLayout
 * @param {ps} ps
 * @param compPointer
 * @param compLayout
 * @param updateCompLayoutCallbackForHooks
 * @param isTriggeredByHook
 */
function positionControlsBar(
    ps: PS,
    compPointer: Pointer,
    compLayout: Layout,
    updateCompLayoutCallbackForHooks: LayoutCallback,
    isTriggeredByHook: boolean
) {
    if (isTriggeredByHook) {
        return
    }
    const {/*playerPointer, */ playerLayout} = getPlayerMapFromProperties(ps, compPointer)
    // const childrenMap = getPlayerChildrenMap(ps, playerPointer)
    // const bigPlayLayout = childrenMap[bigPlay].compLayout
    _.assign(compLayout, getNewControlsBarLayout(compLayout, playerLayout /*, bigPlayLayout*/))
}

export default {
    layoutPlayerChildren,
    positionBigPlay,
    positionControlsBar
}
