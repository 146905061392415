import type {PublicMethodUtils} from '@wix/document-services-types'
import {fonts} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    const {defineGetter, deprecate} = publicMethodUtils

    return {
        methods: {
            fonts: {
                css: {
                    getUrls: defineGetter(fonts.css.getCssUrls)
                },
                getFontsOptions: deprecate(
                    defineGetter(fonts.getSiteFontsOptions),
                    'old function use getSiteFontsOptions instead'
                ),
                getSiteFontsOptions: defineGetter(fonts.getSiteFontsOptions),
                getAllFontsOptions: defineGetter(fonts.getAllFontsOptions)
            }
        }
    }
}
