import type {CompRef, PagesStructure, Pointer, PS} from '@wix/document-services-types'
import page from './page'
import componentAppDataClone from '../tpa/utils/componentAppDataClone'

const cloneTpaCompDataIfNeeded = async (
    ps: PS,
    pageComponentPointer: Pointer,
    pageTitle: string,
    serializedPage: PagesStructure
) => {
    let oldToNewMapId = {}
    if (serializedPage) {
        const compsWithApps = componentAppDataClone.getAllTpaWidgetsAndGenerateId(serializedPage)
        oldToNewMapId = await componentAppDataClone.cloneData(ps, compsWithApps)
    }
    ps.setOperationsQueue.asyncPreDataManipulationComplete(oldToNewMapId)
}

function addAndCloneTpaCompData(
    ps: PS,
    oldToNewMapId,
    pageComponentPointer: CompRef,
    pageTitle: string,
    serializedPage: PagesStructure,
    shouldAddMenuItem: boolean
) {
    page.addPageInternal(ps, pageComponentPointer, pageTitle, serializedPage, shouldAddMenuItem, oldToNewMapId)
}

export default {
    cloneTpaCompDataIfNeeded,
    addAndCloneTpaCompData
}
