import _ from 'lodash'
import {ensureContainerTightlyWrapsChildren, isSiteSegment, getChildren} from './conversionUtils'
import * as mobileOnlyComponents from './mobileOnlyComponents/mobileOnlyComponentsHandler'
import {rescaleComponent} from './structureConverter/structureConverter'
import {conversionConfig} from './conversionConfig'
import type {DeepStructure} from '../types'
import * as conversionUtils from './conversionUtils'

const {MOBILE_WIDTH} = conversionConfig

const CONSTANTS = {
    HEADER: 'SITE_HEADER',
    SUBHEADER: 'SUBHEADER',
    FOOTER: 'SITE_FOOTER',
    PAGES_CONTAINER: 'PAGES_CONTAINER',
    TINY_MENU: 'TINY_MENU'
}

const MASTER_PAGE_ORDER = [CONSTANTS.HEADER, CONSTANTS.SUBHEADER, CONSTANTS.PAGES_CONTAINER, CONSTANTS.FOOTER]

const getSegmentRole = (segment: DeepStructure) => _.get(segment, ['conversionData', 'siteSegmentRole'], '')

function fixSitePagesSegmentLayout(comps) {
    const pagesContainer = _.find(comps, {id: CONSTANTS.PAGES_CONTAINER})
    const sitePagesLayout = _.get(pagesContainer, ['components', 0, 'layout'])
    _.assign(sitePagesLayout, {width: MOBILE_WIDTH, x: 0})
}

function tightenMasterPageLayout(comps: DeepStructure[]): void {
    let y = 0
    _.forEach(MASTER_PAGE_ORDER, segmentRole => {
        const segment = _.find(comps, comp => getSegmentRole(comp) === segmentRole)
        if (!segment) {
            return
        }
        _.assign(segment.layout, {x: 0, width: MOBILE_WIDTH, y})
        y += segment.layout.height
    })
}

function fixSubheaderSegmentOrder(comps: DeepStructure[]): void {
    let headerIndex = _.findIndex(comps, comp => CONSTANTS.HEADER === _.get(comp, ['conversionData', 'siteSegmentRole']))
    const subheaderIndex = _.findIndex(comps, comp => CONSTANTS.SUBHEADER === _.get(comp, ['conversionData', 'siteSegmentRole']))
    const isSubheaderOrderCorrect = headerIndex === -1 || subheaderIndex === -1 || subheaderIndex === headerIndex + 1
    if (!isSubheaderOrderCorrect) {
        const subheader = comps.splice(subheaderIndex, 1)[0]
        headerIndex = _.findIndex(comps, comp => CONSTANTS.HEADER === _.get(comp, ['conversionData', 'siteSegmentRole']))
        comps.splice(headerIndex + 1, 0, subheader)
    }
}

export function addMasterPageMobileOnlyComponents(masterPage: DeepStructure): void {
    mobileOnlyComponents.addComponent(masterPage, CONSTANTS.TINY_MENU)
}

export function fixMasterPageLayoutAndOrder(comps: DeepStructure[]): void {
    fixSubheaderSegmentOrder(<DeepStructure[]>comps)
    fixMasterPageLayout(comps)
}

export function fixMasterPageLayout(comps: DeepStructure[]): void {
    fixSitePagesSegmentLayout(<DeepStructure[]>comps)
    tightenMasterPageLayout(<DeepStructure[]>comps)
}

export function convertSiteSegments(masterPage: DeepStructure): void {
    _(conversionUtils.getChildren(masterPage))
        .filter(isSiteSegment)
        .reject({id: CONSTANTS.PAGES_CONTAINER})
        .forEach(segment => {
            rescaleComponent(segment, MOBILE_WIDTH)
            const enforceShrinkEvenWithNoChildren = true
            ensureContainerTightlyWrapsChildren(segment, segment.components, enforceShrinkEvenWithNoChildren)
        })
}

export function convertMasterPage(masterPage: DeepStructure): void {
    convertSiteSegments(masterPage)
    fixMasterPageLayoutAndOrder(getChildren(masterPage))
    addMasterPageMobileOnlyComponents(masterPage)
}
