import {getReportableFromError} from '@wix/document-manager-utils'
import type {Callback1} from '@wix/document-services-types'
import {contextAdapter} from './contextAdapter'

const getOnSuccessWithFallback =
    (call: string, onError: Callback1<any>, onSuccess: (...args: any[]) => void) =>
    (...args: any[]) => {
        try {
            return onSuccess(...args)
        } catch (e) {
            contextAdapter.utils.fedopsLogger.captureError(
                getReportableFromError(e, {
                    errorType: 'onSuccessFailure',
                    message: `Failed processing onSuccess of a provisioning operation - ${call}`,
                    tags: {
                        successCallbackFailed: true,
                        call
                    }
                })
            )

            onError(e)

            throw e
        }
    }

export default {
    getOnSuccessWithFallback
}
