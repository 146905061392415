export default {
    canBeStretched: true,
    styleCanBeApplied: true,

    defaultMobileProperties(ps, comp, desktopProps) {
        return {
            margin: Math.min(20, desktopProps.margin),
            numCols: Math.min(2, desktopProps.numCols)
        }
    },

    mobileConversionConfig: {
        preserveAspectRatio: false
    }
}
