import type {Pointer, PS} from '@wix/document-services-types'
import * as santaCoreUtils from '@wix/santa-core-utils'
import _ from 'lodash'
import component from '../../component/component'
import appControllerData from '../../appControllerData/appControllerData'
import refComponentUtils from '../../refComponent/refComponentUtils'
import componentsMetaData from '../../componentsMetaData/componentsMetaData'
import type {ScopesExtensionAPI} from '@wix/document-manager-extensions/src/extensions/scopes'

const {isRefPointer: isReferredPointer} = santaCoreUtils.displayedOnlyStructureUtil
const {isRefHost: isRefComponent} = refComponentUtils

const isReferredRoot = (ps: PS, compPtr: Pointer) => {
    const parentPtr = component.getContainer(ps, compPtr)

    if (ps.config.enableScopes) {
        if (parentPtr) {
            return false
        }

        const {scopes} = ps.extensionAPI as ScopesExtensionAPI
        const scopePointer = scopes.extractScopeFromPointer(compPtr)
        const ownerPointer = scopePointer && scopes.getScopeOwner(scopePointer)

        return !isReferredPointer(ownerPointer)
    }

    return !isReferredPointer(parentPtr)
}

const isAppWidget = (ps: PS, compPtr: Pointer) => component.getType(ps, compPtr) === 'platform.components.AppWidget'

const isReferredRootAppWidget = (ps: PS, compPtr: Pointer) => isAppWidget(ps, compPtr) && isReferredRoot(ps, compPtr)

const disableForAllButRoot = (ps: PS, metaDataValue, compPtr: Pointer) =>
    isReferredPointer(compPtr) && !isReferredRoot(ps, compPtr) ? false : metaDataValue

const disableForAll = (ps: PS, metaDataValue, compPtr: Pointer) => (isReferredPointer(compPtr) ? false : metaDataValue)

const disableForAllButWidgetRoot = (ps: PS, metaDataValue, compPtr: Pointer) =>
    isReferredPointer(compPtr) && !isReferredRootAppWidget(ps, compPtr) ? false : metaDataValue

const disableForAllButWidgetRootAndItsFirstChild = (ps: PS, metaDataValue, compPtr: Pointer) => {
    if (!isReferredPointer(compPtr)) {
        return metaDataValue
    }

    if (isReferredRootAppWidget(ps, compPtr)) {
        return metaDataValue
    }

    const parent = component.getContainer(ps, compPtr)
    if (parent && isReferredRootAppWidget(ps, parent)) {
        return metaDataValue
    }

    return false
}

const enableForAllButRoot = (ps: PS, metaDataValue, compPtr: Pointer) =>
    isReferredPointer(compPtr) && !isReferredRoot(ps, compPtr) ? true : metaDataValue

function isRemovable(ps: PS, metaDataValue, compPointer: Pointer) {
    if (componentsMetaData.shouldRemoveAsGhost(ps, compPointer)) {
        const compForStageData = isRefComponent(ps, compPointer)
            ? component.getChildren(ps, compPointer)[0]
            : compPointer
        const componentStageData = appControllerData.getConnectedComponentStageData(ps, compForStageData)
        const isEssential = _.get(componentStageData, ['behavior', 'essential', 'enabled'], false)
        const preventHide = _.get(componentStageData, ['behavior', 'preventHide'], false)
        return metaDataValue && !isEssential && !preventHide
    }
    return metaDataValue
}

export default {
    disableForAllButWidgetRoot,
    disableForAllButWidgetRootAndItsFirstChild,
    disableForAllButRoot,
    disableForAll,
    enableForAllButRoot,
    isRemovable
}
