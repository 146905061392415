import type {GridItemLayout} from '@wix/editor-platform-sdk-types'
import type {Pointer} from '@wix/document-services-types'
import type {MappedLayoutComponent} from '../gridLayout'

const sortSections = (array: MappedLayoutComponent[], pointer?: Pointer): MappedLayoutComponent[] => {
    const clonedArray = array.slice()
    clonedArray.sort((a, b) => {
        /**
         * the product wants:
         * - left vertical section to always be first in the DOM
         * - right vertical section to always be last in the DOM
         *
         * these requirements comes from accessability, this is how screen readers "read" the screen
         *
         * given these constraints above, our sorting logic is left to right, top to bottom
         * so we first check the column to see who is positioned more left than the others
         * next we check who is higher/lower
         */
        const aGridArea = (a.itemLayout as GridItemLayout).gridArea
        const bGridArea = (b.itemLayout as GridItemLayout).gridArea

        // TODO : some day we will support RTL vs LTR, when this day comes the RTL implementation would be reverse order of the columndiff only
        const columnDiff = aGridArea.columnStart - bGridArea.columnStart
        const rowDiff = aGridArea.rowStart - bGridArea.rowStart

        if (columnDiff === 0) {
            if (rowDiff === 0) {
                // in case an explicit component was provided, we use it
                if (pointer && a.componentPointer.id === pointer.id) {
                    return -1
                }
            }

            return rowDiff
        }

        return columnDiff
    })

    return clonedArray
}

export const findChildPosition = (children: Pointer[], child: Pointer) => {
    const index = children.findIndex(item => {
        return item.id === child.id
    })

    if (index < 0) {
        throw new Error(`cant find component layout index for ${child.id},`)
    }

    return index
}

// note that this function changes the content of the array
export const putChildInPosition = (children: Pointer[], child: Pointer, position: number) => {
    children.splice(position, 0, child)
}

// this function reorders the entire page sections in the DOM according to the grid area
export const reorderSectionsByGridArea = (childrenWithLayout: MappedLayoutComponent[]): Pointer[] => {
    const sortedArray = sortSections(childrenWithLayout)
    return sortedArray.map(child => child.componentPointer)
}

// this function places a single section in the correct place in the DOM according to the position in the grid area
export const reorderSectionByGridArea = (
    childrenWithLayout: MappedLayoutComponent[],
    compPointer: Pointer
): Pointer[] => {
    const cloneArray = childrenWithLayout.slice()
    const layout = cloneArray.find(childLayout => childLayout.componentPointer.id === compPointer.id)

    if (!layout) {
        throw new Error(`cant find component layout for ${compPointer.id}`)
    }

    const sortedSections = sortSections(cloneArray, compPointer)
    const index = findChildPosition(
        sortedSections.map(x => x.componentPointer),
        compPointer
    )
    const result = cloneArray.map(child => child.componentPointer).filter(pointer => pointer.id !== compPointer.id)
    putChildInPosition(result, compPointer, index)

    return result
}
