import type {CompRef, Pointer, PS} from '@wix/document-services-types'
import * as santaCoreUtils from '@wix/santa-core-utils'
import {getMeasureMapValue} from '../../utils/utils'

export default {
    rotatable: true,
    a11yConfigurable: false,
    layoutLimits(ps: PS, compPointer: Pointer) {
        return {
            minWidth: getMeasureMapValue(
                ps,
                compPointer as CompRef,
                'minWidth',
                santaCoreUtils.siteConstants.COMP_SIZE.MIN_WIDTH
            )
        }
    }
}
