import _ from 'lodash'
import type {Null} from '../../types'
import type {SnapshotDal} from './snapshotDal'

export interface TagManager {
    addSnapshot(tag: string, snapshotDal: SnapshotDal): number
    getCount(tag: string): number
    getSnapshot(tag: string, index: number): SnapshotDal
    getLastSnapshot(tag: string): Null<SnapshotDal>
    removeLastSnapshot(tag: string): SnapshotDal | undefined
    remove(s: SnapshotDal): void
    _getAllTags(): Record<string, string[]>
}

const createTagManager = (): TagManager => {
    const tagLists = new Map<string, SnapshotDal[]>()

    const getList = (tag: string): SnapshotDal[] => {
        let list = tagLists.get(tag)
        if (!list) {
            list = []
            tagLists.set(tag, list)
        }
        return list
    }

    const addSnapshot = (tag: string, snapshotDal: SnapshotDal): number => {
        const list = getList(tag)
        list.push(snapshotDal)
        return list.length - 1
    }

    const getCount = (tag: string): number => {
        const list = tagLists.get(tag)
        return _.size(list)
    }

    const getSnapshot = (tag: string, index: number): SnapshotDal => {
        const list = getList(tag)
        if (index >= list.length) {
            throw new Error(`snapshot index out of bounds (${index} >= ${list.length}) for tag ${tag}.`)
        }
        return list[index]
    }

    const getLastSnapshot = (tag: string): Null<SnapshotDal> => {
        const list = tagLists.get(tag)
        return _.last(list) ?? null
    }

    const removeLastSnapshot = (tag: string) => {
        const list = getList(tag)
        return list.pop()
    }

    const remove = (s: SnapshotDal): void => {
        tagLists.forEach(snaps => {
            snaps.forEach((snap, i) => {
                if (snap === s) {
                    snaps[i] = s.getPreviousSnapshot()!
                }
            })
        })
    }

    const _getAllTags = () => {
        const allTags = {}
        tagLists.forEach((snapshots, tag) => {
            allTags[tag] = _(snapshots).takeRight(10).map('id').value()
        })
        return allTags
    }

    return {
        remove,
        addSnapshot,
        getCount,
        getSnapshot,
        getLastSnapshot,
        removeLastSnapshot,
        _getAllTags
    }
}

export {createTagManager}
