//created By NirM :)
import type {MasterPageComponent} from '@wix/document-services-types'
import _ from 'lodash'
import objectUtils from './objectUtils'
import jsonUpdaterRegistrar from './jsonUpdaterRegistrar'

function isCompDisplayed(nodeModes, overridingStructure) {
    let isDisplayed = true
    if (nodeModes) {
        const isDisplayedByDefault = !nodeModes.isHiddenByModes
        const isDisplayedOverridden = overridingStructure && !_.isUndefined(overridingStructure.isHiddenByModes)
        isDisplayed = isDisplayedOverridden ? !overridingStructure.isHiddenByModes : isDisplayedByDefault
    }
    return isDisplayed
}

function getRootActiveModesByPageId(activeModes: Record<string, string>, pageId: string) {
    activeModes = activeModes || {}
    return _.omitBy(activeModes[pageId], value => !value)
}

function isOverrideActive(override, pageActiveModes: Record<string, string>) {
    return !_.isEmpty(pageActiveModes) && _.every(override.modeIds, modeId => pageActiveModes[modeId])
}

function getOverridingStructure(overrides, pageActiveModes: Record<string, string>) {
    const activeOverridingStructure: Record<string, any> = _.transform(
        overrides,
        function (accumulator, override) {
            if (isOverrideActive(override, pageActiveModes)) {
                _.assign(accumulator, override)
            }
        },
        {}
    )
    delete activeOverridingStructure.modeIds
    return activeOverridingStructure
}

function getChildrenKey(compStructure: MasterPageComponent, isMobile: boolean) {
    const key = isMobile && compStructure.mobileComponents ? 'mobileComponents' : 'children'
    return compStructure[key] ? key : 'components'
}

function generateDisplayedStructureFromComponent(
    pageActiveModes: Record<string, string>,
    compStructure: MasterPageComponent,
    getNodeInfo?,
    pointersMapApi?,
    siteModel?
) {
    const desktopChildrenKey = getChildrenKey(compStructure, false)
    const mobileChildrenKey = getChildrenKey(compStructure, true)
    const {modes} = compStructure
    const overrides = _.get(compStructure, ['modes', 'overrides'])
    let overridingStructure = getOverridingStructure(overrides || [], pageActiveModes)

    if (!isCompDisplayed(modes, overridingStructure)) {
        return undefined
    }

    const displayedComp = objectUtils.cloneDeep(_.omit(compStructure, ['modes', desktopChildrenKey, mobileChildrenKey]))

    const updaterPlugins = jsonUpdaterRegistrar.getCompPlugin(compStructure.componentType)
    if (updaterPlugins && getNodeInfo) {
        compStructure = updaterPlugins(compStructure, getNodeInfo, pointersMapApi, siteModel)
        displayedComp.layout = compStructure.layout
        displayedComp.componentType = compStructure.componentType
        displayedComp.skin = compStructure.skin
    }

    if (modes) {
        if (modes.overrides) {
            overridingStructure = _.omit(overridingStructure, 'isHiddenByModes')
            _.assign(displayedComp, overridingStructure)
        }

        if (modes.definitions) {
            _.set(displayedComp, ['modes', 'definitions'], modes.definitions)
        }
    }

    generateDisplayedStructureChildren(
        displayedComp,
        desktopChildrenKey,
        pageActiveModes,
        compStructure,
        getNodeInfo,
        pointersMapApi,
        siteModel
    )
    if (desktopChildrenKey !== mobileChildrenKey) {
        generateDisplayedStructureChildren(
            displayedComp,
            mobileChildrenKey,
            pageActiveModes,
            compStructure,
            getNodeInfo,
            pointersMapApi,
            siteModel
        )
    }

    return displayedComp
}

function generateDisplayedStructureChildren(
    displayedComp,
    childrenKey,
    pageActiveModes: Record<string, string>,
    compStructure,
    getNodeInfo,
    pointersMapApi,
    siteModel
) {
    if (!_.isUndefined(compStructure[childrenKey])) {
        displayedComp[childrenKey] = _(compStructure[childrenKey])
            .map(childStructure =>
                generateDisplayedStructureFromComponent(
                    pageActiveModes,
                    childStructure,
                    getNodeInfo,
                    pointersMapApi,
                    siteModel
                )
            )
            .compact()
            .value()
    }
}

function generateDisplayedJsonFromPage(fullPage, activeModes, getNodeInfo, pointersMapApi, siteModel?) {
    const rootId = fullPage.structure.id
    const rootActiveModes = getRootActiveModesByPageId(activeModes, rootId)
    const displayedPage = objectUtils.cloneDeep(_.omit(fullPage, ['structure', 'data']))
    displayedPage.structure = generateDisplayedStructureFromComponent(
        rootActiveModes as any,
        fullPage.structure,
        getNodeInfo,
        pointersMapApi,
        siteModel
    )
    displayedPage.data = objectUtils.cloneDeep(fullPage.data)
    return displayedPage
}

function generateDisplayedFromPagesData(pagesData, activeModes, getNodeInfo, pointersMapApi) {
    activeModes = activeModes || {}
    const pagesDataToReturn = {}
    _.forEach(pagesData, (root, rootId) => {
        pagesDataToReturn[rootId] = generateDisplayedJsonFromPage(root, activeModes, getNodeInfo, pointersMapApi)
    })
    return pagesDataToReturn
}

function isPageJson(jsonFragment) {
    return !_.isUndefined(jsonFragment.structure)
}

function isMasterPageStructure(jsonFragment) {
    return _.get(jsonFragment, ['type']) === 'Document'
}

function isComponentJson(jsonFragment) {
    return !_.isUndefined(jsonFragment.componentType)
}

export interface DisplayedJson {
    structure?: any
    data?: any
}

export default {
    getDisplayedJson(
        fullJsonFragment,
        activeModes: Record<string, string>,
        rootId?: string,
        getNodeInfo?,
        pointersMapApi?,
        siteModel?
    ): DisplayedJson {
        let displayedJson: DisplayedJson = {}

        if (fullJsonFragment) {
            if (isComponentJson(fullJsonFragment) || isMasterPageStructure(fullJsonFragment)) {
                // page-component or component
                const rootActiveModes = getRootActiveModesByPageId(activeModes, rootId)
                displayedJson.structure = generateDisplayedStructureFromComponent(
                    rootActiveModes as any,
                    fullJsonFragment,
                    getNodeInfo,
                    pointersMapApi,
                    siteModel
                )
            } else if (isPageJson(fullJsonFragment)) {
                // page
                displayedJson = generateDisplayedJsonFromPage(
                    fullJsonFragment,
                    activeModes,
                    getNodeInfo,
                    pointersMapApi,
                    siteModel
                )
            } else {
                // pages data
                displayedJson = generateDisplayedFromPagesData(
                    fullJsonFragment,
                    activeModes,
                    getNodeInfo,
                    pointersMapApi
                )
            }
        }
        return displayedJson
    },

    applyModesOnSerializedStructure(fullSerializedJSON: MasterPageComponent, activeModes: Record<string, string>) {
        activeModes = activeModes || {}
        return generateDisplayedStructureFromComponent(activeModes, fullSerializedJSON)
    }
}
