import type {Data} from '@wix/document-services-types'
import _ from 'lodash'
import {VIEW_MODES, PAGE_DATA_TYPES, MODELS_TYPES, POINTER_TYPES, BY_REF_POINTER_TYPES} from './constants/constants'
import type {ViewerAPI} from '@wix/viewer-manager-interface'
import {
    getRenderFlags,
    getRealTimeConfig,
    getRuntime,
    getActiveModes,
    getStructure,
    getCacheKillerCounter,
    getFromRendererModel,
    getFromDocumentServicesModel
} from './customGetters'

const REF_SUFFIX = '-ref'

const getGetterForTypeFn = (viewer: ViewerAPI): Function => {
    const dataGetters = _.mapValues(PAGE_DATA_TYPES, type => _.partial(viewer.data.getData, type as keyof Data))
    const structureGetters = _.mapValues(VIEW_MODES, () => _.partial(getStructure, viewer))
    const refDataTypesGetters = _(PAGE_DATA_TYPES)
        .mapValues(type => _.partial(viewer.byRef.getData, type))
        .mapKeys((v, k) => k.concat(REF_SUFFIX))
        .value()

    const refStructureTypesGetters = _(VIEW_MODES)
        .mapValues(() => viewer.byRef.getStructure)
        .mapKeys((v, k) => k.concat(REF_SUFFIX))
        .value()
    const typesToGetterMap = _.assign(
        {
            [BY_REF_POINTER_TYPES.GHOST_REF_COMPS]: viewer.byRef.getHiddenCompsGroupedByRefComponent,
            [BY_REF_POINTER_TYPES.INTERNALLY_REFERRED_COMPS]: viewer.byRef.getInternallyReferredComps,
            [BY_REF_POINTER_TYPES.DISPLAYED_CONNECTIONS_OVERRIDES]: viewer.byRef.getDisplayedConnectionOverrides,
            [BY_REF_POINTER_TYPES.REMOTE_OVERRIDES]: viewer.byRef.getRemoteOverrides,
            [MODELS_TYPES.DOCUMENT_SERVICES_MODEL]: _.partial(getFromDocumentServicesModel, viewer),
            [MODELS_TYPES.RENDERER_MODEL]: _.partial(getFromRendererModel, viewer),
            [POINTER_TYPES.PAGES_PLATFORM_APPLICATIONS]: _.partial(getFromRendererModel, viewer),
            [POINTER_TYPES.PLATFORM]: viewer.platformDsModel.getPlatformDsModel,
            [POINTER_TYPES.MULTILINGUAL_TRANSLATIONS]: viewer.data.getTranslationDataItem,
            [POINTER_TYPES.WIX_CODE]: viewer.wixCode.getWixCodeDsModel,
            [POINTER_TYPES.SVG_SHAPES]: viewer.svg.getSvgShape,
            [POINTER_TYPES.RENDER_FLAGS]: _.partial(getRenderFlags, viewer.renderFlags.getRenderFlags),
            [POINTER_TYPES.ACTIVE_VARIANT]: viewer.variants.getActiveVariant,
            [POINTER_TYPES.RENDER_REAL_TIME_CONFIG_POINTER]: _.partial(getRealTimeConfig, viewer),
            [POINTER_TYPES.RUNTIME]: _.partial(getRuntime, viewer),
            [POINTER_TYPES.MULTILINGUAL]: viewer.multiLingual.getCurrentLanguage,
            [POINTER_TYPES.GHOST_STRUCTURE]: viewer.ghostables.getGhostStructure,
            [POINTER_TYPES.BLOCKS]: viewer.blocks.getBlocksPreviewData,
            [POINTER_TYPES.GHOST_CONTROLLERS]: viewer.ghostables.getGhostControllers,
            [POINTER_TYPES.ACTIVE_MODES]: _.partial(getActiveModes, viewer),
            [POINTER_TYPES.DISPLAYED_ONLY_COMPONENTS]: viewer.displayedOnlyComponents.getRepeaterToTemplateItem
        },
        dataGetters,
        structureGetters,
        refDataTypesGetters,
        refStructureTypesGetters
    )
    return (type: string) => typesToGetterMap[type]
}

const getGetterForInnerPath = (viewer: ViewerAPI): Function => {
    const specificInnerItemGetters = {
        [POINTER_TYPES.CUSTOM_ELEMENTS]: _.partial(getCacheKillerCounter, viewer)
    }
    return (type: string) => specificInnerItemGetters[type]
}

export {getGetterForTypeFn, getGetterForInnerPath}
