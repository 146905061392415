import {
    Extension,
    ExtensionAPI,
    CreateExtArgs,
    CreateExtensionArgument,
    PointerMethods,
    pointerUtils
} from '@wix/document-manager-core'
import _ from 'lodash'
import {getSiteAssetsBaseUrl} from '../utils/siteAssetsUtils'
import {VIEW_MODES} from '../constants/constants'
import type {DataModelExtensionAPI} from './dataModel/dataModel'
import type {Pointer} from '@wix/document-services-types'
import type {SchemaExtensionAPI} from './schema/schema'
import type {SlotDataType} from './slots'
import {ReportableError} from '@wix/document-manager-utils'
const {getPointer} = pointerUtils
export interface RemoteStructureMetaDataAPI extends ExtensionAPI {
    remoteStructureMetaData: {
        loadMetaData(appDefinitionId: string, widgetId: string, includeCompData?: boolean): Promise<void>
        loadRemoteRefComponentMetaData(refComponentId: string): void
        getComponentTypeFromRemoteStructure(pointer: Pointer): string
        getSlotTypeForRequestWithScope(slotCompId: string, scopedCompIds: string[]): SlotDataType
    }
}

/**
 * This is extension will use site assets client to fetch the widget meta data template
 */
const createExtension = ({environmentContext, experimentInstance}: CreateExtensionArgument): Extension => {
    const pointerType = 'remoteStructureMetaData'
    const createPointersMethods = (): PointerMethods => {
        const getAllRemoteStructureWidgetMetaData = (appDefinitionId: string) =>
            getPointer(pointerType, pointerType, {innerPath: [appDefinitionId]})
        const getRemoteStructureWidgetMetaData = (appDefinitionId: string, widgetId: string) =>
            getPointer(pointerType, pointerType, {innerPath: [appDefinitionId, widgetId]})

        return {
            remoteStructureMetaData: {
                getAllRemoteStructureWidgetMetaData,
                getRemoteStructureWidgetMetaData
            }
        }
    }
    const createExtensionAPI = ({pointers, dal, extensionAPI}: CreateExtArgs): RemoteStructureMetaDataAPI => {
        const loadMetaData = async (
            appDefinitionId: string,
            widgetId: string,
            includeCompData: boolean = false,
            csmEntry?: Record<string, any>
        ): Promise<void> => {
            const currentPointerVal = dal.get(
                pointers.remoteStructureMetaData.getRemoteStructureWidgetMetaData(appDefinitionId, widgetId)
            )
            if (currentPointerVal) {
                return
            }
            const appData = csmEntry ?? dal.get(pointers.rendererModel.getClientSpecMapEntryByAppDefId(appDefinitionId))
            if (!appData) {
                throw new ReportableError({
                    errorType: 'appNotProvisioned',
                    message: 'App is not provisioned'
                })
            }

            const siteAssetsUrl = _.get(appData.appFields, ['platform', 'baseUrls', 'siteAssets'])
            if (!siteAssetsUrl) {
                throw new Error(`no site assets url`)
            }
            const pageCompId = 'masterPage'
            const queryParams = siteAssetsUrl.split('?')[1]
            const urlParams = new URLSearchParams(queryParams)
            const siteId: string = urlParams.get('siteId')!
            const metaSiteId: string = urlParams.get('metaSiteId')!
            const siteRevision: number = parseInt(urlParams.get('siteRevision')!, 10)
            const module = 'remote-widget-metadata'
            const isEditorMode = true
            const isHttps = true
            const isUrlMigrated = true
            const quickActionsMenuEnabled = false
            const viewMode = 'desktop'
            const serviceTopology = dal.get(pointers.serviceTopology.getServiceTopology())
            const {scriptsVersionsMap} = serviceTopology
            const dataFixerVersion = scriptsVersionsMap['@wix/santa-data-fixer']
            const moduleVersion = scriptsVersionsMap['remote-widget-metadata']
            const {fetchFn} = environmentContext
            const fetchDevCenterWidgetIds = experimentInstance.isOpen('dm_fetchDevCenterWidgetIds')

            const urlQueryParams = _.mapValues(
                {
                    ck: 6,
                    isHttps,
                    isUrlMigrated,
                    metaSiteId,
                    quickActionsMenuEnabled,
                    siteId,
                    v: 3,
                    pageCompId,
                    module,
                    moduleVersion,
                    viewMode,
                    isEditorMode,
                    appDefinitionId,
                    siteRevision,
                    dfVersion: dataFixerVersion,
                    includeCompData,
                    fetchDevCenterWidgetIds
                },
                _.toString
            )
            const url = `${getSiteAssetsBaseUrl(serviceTopology)}/pages/remoteWidgetMetadata?${new URLSearchParams(
                urlQueryParams
            )}`
            const response = await fetchFn(url)
            if (!response.ok) {
                throw new Error(`failed to fetch remote structure meta data`)
            }
            const jsonData = await response.json()
            dal.set(pointers.remoteStructureMetaData.getAllRemoteStructureWidgetMetaData(appDefinitionId), jsonData)
        }
        const loadRemoteRefComponentMetaData = async (refComponentId: string) => {
            const {dataModel} = extensionAPI as DataModelExtensionAPI
            const {remoteStructureMetaData} = extensionAPI as RemoteStructureMetaDataAPI
            const outermostRefCompPointer = pointerUtils.getPointer(refComponentId, VIEW_MODES.DESKTOP)
            const componentData = dataModel.components.getItem(outermostRefCompPointer, 'data')
            const {appDefinitionId} = componentData
            await remoteStructureMetaData.loadMetaData(appDefinitionId, componentData.widgetId)
        }
        const getSlotForRequestWithScope = (slotCompId: string, scopedCompIds: string[]) => {
            const outermostRefCompPointer = pointerUtils.getPointer(scopedCompIds[0], VIEW_MODES.DESKTOP)
            const {dataModel} = extensionAPI as DataModelExtensionAPI
            const componentData = dataModel.components.getItem(outermostRefCompPointer, 'data')
            const {appDefinitionId} = componentData
            let innerMostWidgetId = componentData.widgetId
            const appRemoteStructureWidgetData = dal.get(
                pointers.remoteStructureMetaData.getAllRemoteStructureWidgetMetaData(appDefinitionId)
            )
            if (!appRemoteStructureWidgetData) {
                return undefined
            }
            // iterate widgets in widgets to find the innermost widget id
            for (const innerRefCompId of scopedCompIds.slice(1)) {
                const remoteStructureWidgetData = appRemoteStructureWidgetData[innerMostWidgetId]
                innerMostWidgetId = remoteStructureWidgetData.innerWidgets.find(
                    (innerWidget: any) => innerWidget.compId === innerRefCompId
                ).widgetId
            }

            return _.get(appRemoteStructureWidgetData, [innerMostWidgetId, 'components', slotCompId])
        }
        const getComponentTypeFromRemoteStructure = (pointer: Pointer) => {
            const slotsScope = pointer.id.split('_r_')
            const slotCompId = _.last(slotsScope)
            const scopedCompIds = _.dropRight(slotsScope)
            const {componentType} = getSlotForRequestWithScope(slotCompId!, scopedCompIds)
            return componentType
        }
        const getSlotTypeForRequestWithScope = (slotCompId: string, scopedCompIds: string[]) => {
            const {schemaAPI} = extensionAPI as SchemaExtensionAPI
            const {componentType} = getSlotForRequestWithScope(slotCompId, scopedCompIds)
            return _.get(schemaAPI.getDefinition(componentType), ['slotsDataType'])
        }
        return {
            remoteStructureMetaData: {
                loadMetaData,
                loadRemoteRefComponentMetaData,
                getComponentTypeFromRemoteStructure,
                getSlotTypeForRequestWithScope
            }
        }
    }

    return {
        name: 'remoteStructureMetaData',
        createExtensionAPI,
        createPointersMethods,
        dependencies: new Set()
    }
}

export {createExtension}
