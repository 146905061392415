'use strict'

const _ = require('lodash')
const {createHelperAndMigratePage} = require('../helpers/CompsMigrationHelper')

//at time of writing this fixer (version 1), StylableButtonProperties only has allOf(DisableableProperties), which are these:
const STYLABLE_BUTTON_PROPERTIES = ['isHidden', 'isCollapsed', 'ghost', 'isDisabled']

function fixStylableButtonProperties(pageJson, cache, mobileView, comp) {
    const properties = this.getComponentProperties(pageJson, comp)
    if (properties && properties.type !== 'StylableButtonProperties') {
        this.setComponentProperties(pageJson, comp, {
            type: 'StylableButtonProperties',
            ..._.pick(properties, STYLABLE_BUTTON_PROPERTIES)
        })
    }
}

const MIGRATION_MAP = {
    'wixui.StylableButton': fixStylableButtonProperties
}

/**
 * @exports utils/dataFixer/plugins/stylableButtonFixer
 * @type {{exec: function}}
 */

module.exports = {
    name: 'stylableButtonFixer',
    version: 1,
    exec: createHelperAndMigratePage(MIGRATION_MAP)
}
