import _ from 'lodash'
import type {BoltSiteAPI} from './types'

export const setScroll = (boltSiteAPI: BoltSiteAPI, x: number, y: number) => {
    if (boltSiteAPI.isPopupOpened()) {
        const popupRootDOMNode = window.document.getElementById('POPUPS_ROOT')

        // @ts-ignore
        popupRootDOMNode.scrollLeft = x
        // @ts-ignore
        popupRootDOMNode.scrollTop = y
    } else {
        window.scrollTo(x, y)
    }
}

export const getScroll = (boltSiteAPI: BoltSiteAPI, ignorePopups = false) => {
    if (boltSiteAPI.isPopupOpened() && !ignorePopups) {
        const popupRootDOMNode = window.document.getElementById('POPUPS_ROOT')

        return {
            x: _.get(popupRootDOMNode, ['scrollLeft'], 0),
            y: _.get(popupRootDOMNode, ['scrollTop'], 0)
        }
    }

    return {
        x: _.isFinite(window.pageXOffset) ? window.pageXOffset : window.scrollX,
        y: _.isFinite(window.pageYOffset) ? window.pageYOffset : window.scrollY
    }
}

export const setScrollAndScale = (
    boltSiteAPI: BoltSiteAPI,
    x: number,
    y: number,
    scale: number,
    duration: number,
    originLeft: number,
    callback: Function
) => {
    const animationContainers = ['#SITE_ROOT', '#POPUPS_WRAPPER']
    const animations = boltSiteAPI.getSiteAnimationsService()
    const callbacks = {onComplete: callback}
    const sequence = animations.sequence({callbacks})
    const left = !_.isUndefined(originLeft) ? originLeft.toString() : '50%'
    sequence
        .add(animations.animate('BaseNone', animationContainers, 0, 0, {transformOrigin: `${left} 0`}), 0)
        .add(
            animations.animate('BaseScale', animationContainers, duration, 0, {
                to: {scale},
                clearProps: scale === 1 ? 'transform, transform-origin' : ''
            }),
            0
        )
        .add(animations.animate('BaseScroll', window, duration, 0, {x, y}), 0)

    return sequence.get()
}

export const animateScroll = (boltSiteAPI: BoltSiteAPI, x: number, y: number, duration = 0, callback = _.noop) => {
    const animations = boltSiteAPI.getSiteAnimationsService()
    const callbacks = {onComplete: callback}
    const sequence = animations.sequence({callbacks})
    sequence.add(animations.animate('BaseScroll', window, duration, 0, {x, y}), 0)
    return sequence.get()
}
