export const WidgetInstallationTypes = {
    OPEN: 'open',
    CLOSED: 'closed'
}

export const ROLE_PATH = {
    ROOT: '_ROOT_',
    DELIMITER: '_ROLE_',
    REPEATED_COMP_DELIMITER: '_ITEM_'
}
export const LIVE_PREVIEW_REFRESH_SOURCE = 'AFTER_PROP_UPDATE'
