import constants from '../../constants/constants'
import metaDataUtils from '../metaDataUtils'

export default {
    resizableSides: [constants.RESIZE_SIDES.TOP, constants.RESIZE_SIDES.BOTTOM],
    moveDirections: [constants.MOVE_DIRECTIONS.VERTICAL],
    fullWidth: true,
    fullWidthByStructure: true,
    canBeFixedPosition: false,
    containableByStructure: metaDataUtils.containableByFullWidthPopup,
    mobileConversionConfig: {
        hideWhenEmptyBackgroundContainer: true,
        isScreenWidth: true,
        filterChildrenWhenHidden: true,
        minHeight: 200,
        preserveAspectRatio: false
    }
}
