import {scopesUtils} from '@wix/document-manager-extensions'
const {getInflatedPointer} = scopesUtils
import {ReportableError} from '@wix/document-manager-utils'
import {namespaceMapping} from '@wix/document-services-json-schemas'
import type {CompRef, PS} from '@wix/document-services-types'
import constants from '../constants/constants'
import dataModel from '../dataModel/dataModel'
import variantsUtils from '../variants/variantsUtils'
import {shouldUseDesignInVariants} from './designOverVariants'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'

const {DATA_TYPES} = constants
const SUPPORT_OVERRIDES_FLAG = 'supportOverrides'
const IS_RELATIONAL_SPLIT_FROM_QUERY_FLAG = 'isRelationalSplitFromQuery'
const NAMESPACES_NOT_SUPPORTING_REMOTE_OVERRIDES = new Set([DATA_TYPES.layout])

const update = (
    ps: PS,
    componentPointer: CompRef,
    valueToUpdate: any,
    namespace: string,
    useOriginalLanguage: boolean
) => {
    valueToUpdate = completeValueToUpdateWithTemplateIfNeeded(ps, componentPointer, namespace, valueToUpdate)
    if (namespace === DATA_TYPES.data) {
        return dataModel.updateDataItem(ps, componentPointer, valueToUpdate, useOriginalLanguage)
    }

    if (namespace === DATA_TYPES.design) {
        return dataModel.updateDesignItem(ps, componentPointer, valueToUpdate)
    }

    throw new ReportableError({
        message: `namespace ${namespace} does not support update or override`,
        errorType: 'updateOrOverrideNotSupported'
    })
}

const get = (
    ps: PS,
    componentPointer: CompRef,
    namespace: string,
    deleteId: boolean = false,
    useOriginalLanguage: boolean = false
) => {
    if (namespace === DATA_TYPES.data) {
        return dataModel.getDataItem(ps, componentPointer, deleteId, useOriginalLanguage)
    }

    throw new ReportableError({
        message: `namespace ${namespace} does not support get or override`,
        errorType: 'getOrOverrideNotSupported'
    })
}

const updateConsideringVariants = (ps: PS, componentPointer: CompRef, valueToUpdate: any, namespace: string) => {
    if (namespace === DATA_TYPES.design && !shouldUseDesignInVariants(ps, componentPointer)) {
        return dataModel.updateDesignItem(ps, componentPointer, valueToUpdate)
    }
    return variantsUtils.updateComponentDataConsideringVariants(ps, componentPointer, valueToUpdate, namespace)
}

const updateOverride = (
    ps: PS,
    compPointer: CompRef,
    valueToUpdate: any,
    namespace: string,
    useOriginalLanguage: boolean
) => {
    const scopedPointer = getInflatedPointer(compPointer, ps.config.enableRepeatersInScopes)
    namespaceMapping.validateNamespaceFlag(namespace, SUPPORT_OVERRIDES_FLAG)

    if (namespaceMapping.getNamespaceFlag(namespace, IS_RELATIONAL_SPLIT_FROM_QUERY_FLAG)) {
        return updateConsideringVariants(ps, scopedPointer as CompRef, valueToUpdate, namespace)
    }
    return update(ps, scopedPointer as CompRef, valueToUpdate, namespace, useOriginalLanguage)
}

function completeValueToUpdateWithTemplateIfNeeded(
    ps: PS,
    compPointer: CompRef,
    namespace: string,
    valueToUpdate: any
) {
    const dataPtr = dataModel.getComponentDataPointerByType(ps, compPointer, namespace)
    if (
        ps.dal.isExist(dataPtr) &&
        !ps.dal.full.isExist(ps.pointers.referredStructure.getPointerWithoutFallbacks(dataPtr))
    ) {
        const item = dataModel.getComponentDataItemByType(ps, compPointer, namespace, true)
        valueToUpdate = {...item, ...valueToUpdate}
    }
    return valueToUpdate
}

function validateSupportOfRemoteOverrides(ps: PS, compPointer: CompRef, namespace: string) {
    if (
        displayedOnlyStructureUtil.isRefPointer(compPointer) &&
        NAMESPACES_NOT_SUPPORTING_REMOTE_OVERRIDES.has(namespace)
    ) {
        throw new ReportableError({
            message: `namespace ${namespace} does not support override for remote component`,
            errorType: 'updateOrOverrideNotSupportedForRemoteComponent'
        })
    }
}

export const updateConsideringOverrides = (
    ps: PS,
    compPointer: CompRef,
    valueToUpdate: any,
    namespace: string,
    useOriginalLanguage: boolean = false
) => {
    validateSupportOfRemoteOverrides(ps, compPointer, namespace)
    if (compPointer.scope) {
        return updateOverride(ps, compPointer, valueToUpdate, namespace, useOriginalLanguage)
    }

    if (namespaceMapping.getNamespaceFlag(namespace, IS_RELATIONAL_SPLIT_FROM_QUERY_FLAG)) {
        return updateConsideringVariants(ps, compPointer, valueToUpdate, namespace)
    }

    return update(ps, compPointer, valueToUpdate, namespace, useOriginalLanguage)
}

const getOverride = (
    ps: PS,
    compPointer: CompRef,
    namespace: string,
    deleteId: boolean = false,
    useOriginalLanguage: boolean = false
) => {
    const scopedPointer = getInflatedPointer(compPointer, ps.config.enableRepeatersInScopes)
    return get(ps, scopedPointer as CompRef, namespace, deleteId, useOriginalLanguage)
}

export const getConsideringOverrides = (
    ps: PS,
    compPointer: CompRef,
    namespace: string,
    deleteId: boolean = false,
    useOriginalLanguage: boolean = false
) => {
    if (compPointer?.scope) {
        return getOverride(ps, compPointer, namespace, deleteId, useOriginalLanguage)
    }

    return get(ps, compPointer, namespace, deleteId, useOriginalLanguage)
}
