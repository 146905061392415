import type {CoreConfig, DAL, EventEmitter, ExtensionAPI} from '@wix/document-manager-core'
import type {CompRef, Pointers, RawComponentExport} from '@wix/document-services-types'
import {importRawComponent} from './raw/import'
import {EVENTS} from './constants'
import {addPlaceholderComponent, addPlaceholderPage} from './utils'
import {deepClone} from '@wix/wix-immutable-proxy'
import {reuseIds} from './reuseIds'

export const replaceComponent = (
    rawComponentExport: RawComponentExport,
    componentToReplace: CompRef,
    dal: DAL,
    pointers: Pointers,
    extensionAPI: ExtensionAPI,
    {schemaService}: CoreConfig,
    eventEmitter: EventEmitter
) => {
    const clonedExport = deepClone(rawComponentExport)
    reuseIds(clonedExport, extensionAPI, dal, schemaService, componentToReplace)
    importRawComponent(
        clonedExport.rootComponent,
        clonedExport,
        componentToReplace,
        dal,
        pointers,
        extensionAPI,
        schemaService
    )
    eventEmitter.emit(EVENTS.IMPORT_EXPORT.AFTER_IMPORT)
}

export const addComponent = (
    rawComponentExport: RawComponentExport,
    containerPointer: CompRef,
    dal: DAL,
    pointers: Pointers,
    extensionAPI: ExtensionAPI,
    coreConfig: CoreConfig,
    eventEmitter: EventEmitter
): CompRef => {
    const {rootComponent, externalRefs} = rawComponentExport
    externalRefs[rootComponent] ??= rootComponent
    const componentToReplace = addPlaceholderComponent(externalRefs[rootComponent], containerPointer, dal, pointers)
    replaceComponent(rawComponentExport, componentToReplace, dal, pointers, extensionAPI, coreConfig, eventEmitter)
    return componentToReplace
}

export const addPage = (
    rawPageExport: RawComponentExport,
    dal: DAL,
    pointers: Pointers,
    extensionAPI: ExtensionAPI,
    coreConfig: CoreConfig,
    eventEmitter: EventEmitter
): CompRef => {
    const {rootComponent, components, externalRefs} = rawPageExport
    const page = components[rootComponent]
    externalRefs[rootComponent] ??= rootComponent
    const pageToReplace = addPlaceholderPage(externalRefs[rootComponent], page, pointers, extensionAPI)
    replaceComponent(rawPageExport, pageToReplace, dal, pointers, extensionAPI, coreConfig, eventEmitter)
    return pageToReplace
}
