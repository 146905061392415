import _ from 'lodash'
import constants from '../constants/constants'
import dataModel from '../dataModel/dataModel'
import dataIds from '../dataModel/dataIds'
import relationsUtils from './relationsUtils'
import refComponent from '../refComponent/refComponent'
import variantsUtils from './variantsUtils'
import documentModeInfo from '../documentMode/documentModeInfo'
import type {CompRef, CompVariantPointer, Pointer, PS, VariantPointer} from '@wix/document-services-types'
import {asArray, ReportableError} from '@wix/document-manager-utils'
import {pointerUtils} from '@wix/document-manager-core'
import {refStructureUtils} from '@wix/document-manager-extensions'

const {DATA_TYPES, MASTER_PAGE_ID} = constants
const {
    VALID_VARIANTS_DATA_TYPES,
    TYPES,
    SINGLE_VARIANT_PER_COMP_TYPES,
    MOBILE_VARIANT_ID,
    VARIANTS_QUERY,
    USE_VARIANTS_LIST
} = constants.VARIANTS
const implicitApiAddTypes = [TYPES.STATE, TYPES.TRIGGER, TYPES.MOBILE]
const {VARIANTS_LIST} = constants.VARIANTS.TYPES

const getData = (ps, variantPointer) => dataModel.getDataByPointer(ps, DATA_TYPES.variants, variantPointer)

const getComponentVariantsDataByType = (ps: PS, compPointer: Pointer, variantType: string) => {
    const pagePointer = ps.pointers.components.getPageOfComponent(compPointer)
    const compPointerToGet = ps.pointers.full.components.getComponent(compPointer.id, pagePointer)
    return dataModel.getVariantsDataByVariantType(ps, variantType, {compPointer: compPointerToGet})
}

const getAllAffectingVariantsGroupedByVariantType = (ps: PS, componentPointer: Pointer) =>
    relationsUtils.getAllAffectingVariantsGroupedByVariantType(ps, componentPointer)

const getAllAffectingVariantsForPresets = (ps: PS, componentPointer: Pointer) =>
    relationsUtils.getAllAffectingVariantsForDataType(ps, componentPointer, 'presets')

const createVariantForComponent = (
    ps: PS,
    variantToAddRef,
    compPointer: Pointer,
    variantType: string,
    variantData?
) => {
    const notAllowedvariantTypes = implicitApiAddTypes

    if (notAllowedvariantTypes.includes(variantType)) {
        throw new Error(`Please use the public API to add variants of type ${variantType}`)
    }

    return createAnyVariantForComponent(ps, variantToAddRef, compPointer, variantType, variantData)
}

const createVariantsListForComponent = (
    ps: PS,
    currentId: string,
    compPointer: Pointer,
    variantsListType: string,
    variantsList: {values: object[]}
): string => {
    const variantsListId = currentId || dataIds.generateNewId(DATA_TYPES.variants)
    const componentPage = ps.pointers.full.components.getPageOfComponent(compPointer)
    const variantsListPointer = ps.pointers.data.getVariantsDataItem(variantsListId, componentPage.id)
    const values = variantsList?.values
    return dataModel.createVariantData(ps, variantsListPointer, variantsListType, {compPointer, values})
}

const addVariantToVariantsList = (ps: PS, compPointer: Pointer, variantPointer: Pointer) => {
    const variantsListId = dataModel.getComponentDataItemId(ps, compPointer, VARIANTS_QUERY)
    if (!variantsListId) {
        const compVariantsListId = createVariantsListForComponent(ps, variantsListId, compPointer, VARIANTS_LIST, {
            values: []
        })
        dataModel.linkComponentToItem(ps, compPointer, compVariantsListId, VARIANTS_QUERY)
    }

    const variantsListPointer = dataModel.getVariantsListItemPointer(ps, compPointer)
    const variantsList = ps.dal.get(variantsListPointer)
    variantsList.values.push(`#${variantPointer.id}`)
    ps.dal.set(variantsListPointer, variantsList)
}
const getAllOwnedVariantsGroupedByVariantType = (ps: PS, compPointer: Pointer) => {
    const variantsListPointer = dataModel.getVariantsListItemPointer(ps, compPointer)
    if (!variantsListPointer) {
        return {}
    }
    const variantsList = ps.extensionAPI.dataModel.getItem(
        variantsListPointer.id,
        variantsListPointer.type,
        variantsListPointer.pageId
    )
    const grouped = _.groupBy(variantsList?.values, value => value.type)
    const pointers = _.mapValues(grouped, arr => _.map(arr, variant => ({...variantsListPointer, id: variant.id})))
    return pointers
}

const removeVariantFromVariantsListIfNeeded = (ps: PS, compPointer: Pointer, variantPointer: Pointer) => {
    const variantsListPointer = dataModel.getVariantsListItemPointer(ps, compPointer)
    if (!variantsListPointer) {
        return
    }

    const currentVariantsList = variantsListPointer && ps.dal.get(variantsListPointer)
    currentVariantsList.values = currentVariantsList.values.filter((v: any) => v !== `#${variantPointer.id}`)
    ps.dal.set(variantsListPointer, currentVariantsList)
}
const createAnyVariantForComponent = (
    ps: PS,
    variantToAddRef,
    compPointer: Pointer,
    variantType: string,
    variantData?
) => {
    if (!ps || !variantType || !compPointer) {
        throw new Error('invalid args')
    }
    const pagePointer = ps.pointers.components.getPageOfComponent(compPointer)
    const compPointerToAdd = ps.pointers.full.components.getComponent(compPointer.id, pagePointer)

    if (variantToAddRef) {
        dataModel.createVariantData(ps, variantToAddRef, variantType, {compPointer: compPointerToAdd}, variantData)
    }

    if (USE_VARIANTS_LIST.has(variantType)) {
        addVariantToVariantsList(ps, compPointerToAdd, variantToAddRef)
    }
}

const getVariantToAddRef = (ps: PS, compPointer: Pointer, variantType: string) => {
    const pagePointer = ps.pointers.components.getPageOfComponent(compPointer)
    const compPointerToAdd = compPointer && ps.pointers.full.components.getComponent(compPointer.id, pagePointer)
    const compVariantsByType = getComponentVariantsDataByType(ps, compPointerToAdd, variantType)

    if (_.includes(SINGLE_VARIANT_PER_COMP_TYPES, variantType) && !_.isEmpty(compVariantsByType)) {
        return null
    }

    const variantId = dataIds.generateNewId(DATA_TYPES.variants)
    return ps.pointers.data.getVariantsDataItem(variantId, pagePointer.id)
}
const getVariantOwner = (ps: PS, variantPointer: Pointer): CompRef =>
    ps.extensionAPI.variants.getVariantOwner(variantPointer)

const isMobileVariant = (ps: PS, variantsPointers: VariantPointer[]) => {
    return variantsPointers.length === 1 && ps.pointers.isSamePointer(variantsPointers[0], getMobileVariantRef(ps))
}

const removeVariants = (ps: PS, variantsPointers: Pointer | Pointer[]) => {
    const variantsPointersArray = asArray(variantsPointers)

    if (isMobileVariant(ps, variantsPointersArray)) {
        throw new ReportableError({
            message: 'removing MOBILE-VARIANT is not allowed',
            errorType: 'variantValidation'
        })
    }

    relationsUtils.removeScopedValuesByVariants(ps, variantsPointersArray)

    // in case of multiple variants GC will remove variants when there is no relation referring to variants
    if (variantsPointersArray.length === 1) {
        const variantPointer = _.head(variantsPointersArray)
        const compPointer = getVariantOwner(ps, variantPointer)
        removeVariantFromVariantsListIfNeeded(ps, compPointer, variantPointer)
        ps.dal.remove(variantPointer)
    }
}

const getCompnentsWithOverridesGroupedByType = (
    ps: PS,
    variantsPointers: Pointer | Pointer[] = [],
    shouldMatchExactly: boolean = true
) => {
    if ((variantsPointers as Pointer[]).length < 1) {
        return {}
    }
    const variantsPointersArray = asArray(variantsPointers)
    const pageId = ps.pointers.data.getPageIdOfData(_.head(variantsPointersArray))

    const result = {}

    _.forEach(VALID_VARIANTS_DATA_TYPES, itemType => {
        const relationsPointersByItemType = relationsUtils
            .getRelationsByVariantsAndPredicate(ps, variantsPointersArray, itemType, shouldMatchExactly)
            .flat()
        const components = _.map(relationsPointersByItemType, relationPtr => {
            const relationData = ps.dal.get(relationPtr)
            return relationsUtils.getComponentFromRelation(ps, relationData, pageId)
        })
        if (components.length > 0) {
            result[itemType] = components
        }
    })

    return result
}

const getPointerWithoutVariants = (ps: PS, pointer: CompVariantPointer): CompRef =>
    variantsUtils.getPointerWithoutVariants(pointer)

const hasOverrides = (ps: PS, compPointerWithVariants: CompRef, shouldCheckChildren = true) => {
    if (!ps.pointers.components.isWithVariants(compPointerWithVariants)) {
        return false
    }
    const pagePointer = ps.pointers.full.components.getPageOfComponent(compPointerWithVariants)
    const compPointer = ps.pointers.full.components.getComponent(compPointerWithVariants.id, pagePointer)

    const rootCompId = compPointer.id
    const isRecursive =
        shouldCheckChildren &&
        !!_.find(
            compPointerWithVariants.variants,
            variantPointer => ps.dal.get(variantPointer).componentId === rootCompId
        )

    const componentsWithOverrides = getCompnentsWithOverridesGroupedByType(ps, compPointerWithVariants.variants)
    // @ts-expect-error
    const componentIdsThatMatchesVariants = _(componentsWithOverrides).values().flatten().map('id').uniq().value()

    const compHasOverrides = _compId => _.includes(componentIdsThatMatchesVariants, _compId)
    const descendantsHasOverrides =
        isRecursive && !_.isEmpty(componentIdsThatMatchesVariants)
            ? !!ps.pointers.components.findDescendant(compPointer, comp => compHasOverrides(comp.id))
            : false

    return compHasOverrides(rootCompId) || descendantsHasOverrides
}

const setToActiveVariantMap = (ps: PS, variantsPointers, isUnset?) => {
    const variantsPointersArray = _.isArray(variantsPointers) ? variantsPointers : [variantsPointers]
    const focusedPageId = ps.siteAPI.getFocusedRootId()
    const pagePointer = ps.pointers.components.getPage(focusedPageId, ps.siteAPI.getViewMode())

    variantsPointersArray.forEach(variantPointer => {
        const variantComponentId = ps.dal.get(variantPointer).componentId
        const valueToSet = isUnset ? undefined : variantPointer.id
        const compPointer = ps.pointers.full.components.getComponent(variantComponentId, pagePointer)
        const components = _.concat(
            ps.pointers.components.getAllDisplayedOnlyComponents(compPointer),
            refComponent.getReferredComponents(ps, compPointer)
        )
        components.forEach(component => {
            const compActiveVariantPointer = ps.pointers.activeVariants.getActiveVariant(component.id)
            ps.dal.set(compActiveVariantPointer, valueToSet)
        })
    })
}

const enable = (ps: PS, variantsPointers) => setToActiveVariantMap(ps, variantsPointers)

const getComponentEnabledVariants = (ps: PS, compPointerWithVariants: CompRef) => {
    return getActiveVariantForComponent(ps, compPointerWithVariants, false)
}

const getActiveVariantForComponent = (ps: PS, compPointerWithVariants: CompRef, noRefFallbacks: boolean) => {
    const activeVariantsPointer = ps.pointers.activeVariants.getActiveVariant(compPointerWithVariants.id)

    if (noRefFallbacks) {
        const noFallbackPointer = refStructureUtils.getPointerWithoutFallbacksFromPointer(activeVariantsPointer)
        return ps.dal.get(noFallbackPointer)
    }

    return ps.dal.get(activeVariantsPointer)
}

const setActiveVariantForComponent = (ps: PS, compPointer: CompRef, valueToSet: string) => {
    const compActiveVariantPointer = ps.pointers.activeVariants.getActiveVariant(compPointer.id)
    ps.dal.set(compActiveVariantPointer, valueToSet)
}

const enableForComponent = (ps: PS, variantOwnerCompPointer: CompRef, variantPointer: Pointer): void => {
    const variantComponent: CompRef = getVariantOwner(ps, variantPointer)
    const ownerComponent: CompRef = pointerUtils.getRepeatedItemPointerIfNeeded(variantOwnerCompPointer)

    const variantComponentTemplate = refComponent.getTemplateCompPointer(ps, variantComponent) ?? variantComponent
    const ownerComponentTemplate = refComponent.getTemplateCompPointer(ps, ownerComponent) ?? ownerComponent

    if (!ps.pointers.isSamePointer(variantComponentTemplate, ownerComponentTemplate)) {
        throw new ReportableError({
            message: 'cannot enable variant for non owning component',
            errorType: 'VARIANT_AND_COMPONENT_MISMATCH',
            extras: {compPointer: variantOwnerCompPointer, variantPointer}
        })
    }

    setActiveVariantForComponent(ps, variantOwnerCompPointer, variantPointer.id)
}

const disableForComponent = (ps: PS, variantOwnerCompPointer: CompRef, variantPointer: Pointer): void => {
    const enabledVariant = getActiveVariantForComponent(ps, variantOwnerCompPointer, true)
    if (enabledVariant !== variantPointer.id) {
        throw new ReportableError({
            message: 'variant is not enabled for this component',
            errorType: 'VARIANT_NOT_ENABLED',
            extras: {compPointer: variantOwnerCompPointer, variantPointer}
        })
    }

    setActiveVariantForComponent(ps, variantOwnerCompPointer, undefined)
}

const getMobileVariantRef = (ps: PS): Pointer => {
    return ps.pointers.data.getVariantsDataItem(MOBILE_VARIANT_ID, MASTER_PAGE_ID)
}

const getMobileVariant = (ps: PS): Pointer | null => {
    const mobileVariantPointer = getMobileVariantRef(ps)
    if (ps.dal.isExist(mobileVariantPointer)) {
        return mobileVariantPointer
    }
    return null
}

const createMobileVariant = (ps: PS, variantToAddRef: Pointer): Pointer => {
    const mobileVariant = getMobileVariant(ps)
    if (mobileVariant) {
        return mobileVariant
    }
    const masterPage = ps.pointers.components.getMasterPage(documentModeInfo.getViewMode(ps))
    const variantsListId = createVariantsListForComponent(ps, undefined, masterPage, TYPES.VARIANTS_LIST, {
        values: [{id: MOBILE_VARIANT_ID, type: TYPES.MOBILE, componentId: masterPage.id}]
    })

    dataModel.linkComponentToItem(ps, masterPage, variantsListId, VARIANTS_QUERY)
    return variantToAddRef
}

const disable = (ps: PS, variantsPointers) => setToActiveVariantMap(ps, variantsPointers, true)

const hasOverridesForNamespace = (ps: PS, compPointer: CompVariantPointer, namespace: string) =>
    ps.extensionAPI.variants.hasOverrides(compPointer, namespace)

const getRepeaterPatternType = () => TYPES.REPEATER_PATTERN
const getHoverType = () => TYPES.HOVER
const getMobileType = () => TYPES.MOBILE
const getPresetType = () => TYPES.PRESET
const getStateType = () => TYPES.STATE
const getTriggerType = () => TYPES.TRIGGER
const getBreakpointsDataType = () => TYPES.BREAKPOINTS

export default {
    getVariantToAddRef,
    getAllAffectingVariantsGroupedByVariantType,
    getAllOwnedVariantsGroupedByVariantType,
    getAllAffectingVariantsForPresets,
    create: createVariantForComponent,
    createInternal: createAnyVariantForComponent,
    getByComponentAndType: getComponentVariantsDataByType,
    createVariantsList: createVariantsListForComponent,
    removeVariantFromVariantsList: removeVariantFromVariantsListIfNeeded,
    addVariantToVariantsList,
    createMobileVariant,
    getMobileVariant,
    getMobileVariantRef,
    enable,
    getComponentEnabledVariants,
    disable,
    enableForComponent,
    disableForComponent,
    remove: removeVariants,
    getPointerWithVariants: variantsUtils.getPointerWithVariants,
    getPointerWithoutVariants,
    hasOverrides,
    getComponentsWithOverrides: getCompnentsWithOverridesGroupedByType,
    getHoverType,
    getPresetType,
    getMobileType,
    getStateType,
    getTriggerType,
    getRepeaterPatternType,
    getBreakpointsDataType,
    getData,
    getVariantOwner,
    hasOverridesForNamespace
}
