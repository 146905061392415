import wixCodeLifecycleService from '../../wixCode/services/wixCodeLifecycleService'
import component from '../../component/component'
import componentCode from '../../component/componentCode'
import type {CompRef, Pointer, PossibleViewModes, PS, SerializedCompStructure} from '@wix/document-services-types'
import constants from '../../constants/constants'
import _ from 'lodash'
import type {NicknamesAPI} from '@wix/document-manager-extensions/dist/src/extensions/nicknames'
import experiment from 'experiment-amd'

function generateNicknamesForPageOfComponent(ps: PS, compToAddPointer: Pointer) {
    if (wixCodeLifecycleService.isProvisioned(ps)) {
        const pagePointer = component.isPageComponent(ps, compToAddPointer)
            ? compToAddPointer
            : component.getPage(ps, compToAddPointer as CompRef)
        const viewMode = ps.pointers.components.getViewMode(pagePointer)
        componentCode.generateNicknamesForPages(ps, [pagePointer.id], viewMode)
    }
}

function generateNicknamesForComponents(ps: PS, compsToGenerateNickname: Pointer[], pagePointer: Pointer) {
    const viewMode: PossibleViewModes = ps.pointers.components.getViewMode(pagePointer)
    componentCode.generateNicknamesForComponents(
        ps,
        compsToGenerateNickname as CompRef[],
        pagePointer as CompRef,
        viewMode
    )
}

function generateNicknamesForPages(ps: PS, pages: Pointer[]) {
    if (wixCodeLifecycleService.isProvisioned(ps)) {
        componentCode.generateNicknamesForPages(
            ps,
            pages.map(page => page.id),
            constants.VIEW_MODES.MOBILE,
            experiment.isOpen('dm_optimizeNicknamesGenerationOnSave')
        )
    }
}

function deleteNicknameFromComponentIfInvalid(ps: PS, compPointer: Pointer, containerPointer: Pointer) {
    if (wixCodeLifecycleService.isProvisioned(ps) && component.getType(ps, compPointer) !== 'Page') {
        componentCode.removeNicknameFromComponentIfInvalid(ps, compPointer as CompRef, containerPointer as CompRef)
    }
}

function updateConnectionsIfNeeded(
    ps: PS,
    compPointer: Pointer,
    containerPointer: Pointer,
    compDefinition: SerializedCompStructure
) {
    if (compDefinition.type === 'Page' || !compDefinition.connections) return

    const connectionsItems = compDefinition.connections.items
    const pagePointer = ps.pointers.full.components.getPageOfComponent(containerPointer)

    if (wixCodeLifecycleService.isProvisioned(ps)) {
        componentCode.updateConnectionsIfNeeded(ps, compPointer as CompRef, containerPointer, compDefinition)
    } else {
        const wixCodeConnectionItem = _.find(connectionsItems, {type: 'WixCodeConnectionItem'})
        if (!wixCodeConnectionItem) return

        const nicknamesAPI = ps.extensionAPI.nicknames as NicknamesAPI
        if (nicknamesAPI.hasComponentWithThatNickname(pagePointer, wixCodeConnectionItem.role, compPointer)) {
            _.pull(connectionsItems, wixCodeConnectionItem)
            if (_.isEmpty(connectionsItems)) {
                delete compDefinition.connections
            }
        }
    }
}

function updateConnectionsRecursively(
    ps: PS,
    compPointer: Pointer,
    containerPointer: Pointer,
    compDefinitionPrototype: SerializedCompStructure
) {
    if (
        wixCodeLifecycleService.isProvisioned(ps) &&
        compDefinitionPrototype.type !== 'Page' &&
        compDefinitionPrototype.connections
    ) {
        componentCode.updateConnectionsRecursively(
            ps,
            compPointer as CompRef,
            containerPointer,
            compDefinitionPrototype
        )
    }
}

function updateNicknameContextByNewContainer(
    ps: PS,
    compPointer: Pointer,
    componentDefinition,
    newContainerPointer: Pointer
) {
    if (wixCodeLifecycleService.isProvisioned(ps) && componentDefinition.type !== 'Page') {
        componentCode.updateNicknameContextByNewContainer(
            ps,
            compPointer as CompRef,
            componentDefinition,
            newContainerPointer
        )
    }
}

function setOriginalContextToSerializedComponent(ps: PS, compPointer: Pointer, customStructureData) {
    componentCode.setOriginalContextToSerializedComponent(ps, compPointer as CompRef, customStructureData)
}

export default {
    generateNicknamesForPages,
    generateNicknamesForComponents,
    generateNicknamesForPageOfComponent,
    updateConnectionsIfNeeded,
    updateConnectionsRecursively,
    deleteNicknameFromComponentIfInvalid,
    updateNicknameContextByNewContainer,
    setOriginalContextToSerializedComponent
}
