import type {Layout, Pointer, PS} from '@wix/document-services-types'
import {warmupUtils} from '@wix/santa-ds-libs'
import componentStructureInfo from '../../component/componentStructureInfo'
const {compAlignmentUtils} = warmupUtils

export default function fixPopupContainerLayout(ps: PS, compPointer: Pointer, newLayout: Layout) {
    const layout: any = {
        width: newLayout.width,
        height: newLayout.height
    }
    const popupProps = componentStructureInfo.getPropertiesItem(ps, compPointer)
    layout.x = Math.round(
        compAlignmentUtils.getLeft(popupProps, layout.width, ps.siteAPI.getScreenWidth(), ps.siteAPI.getSiteWidth())
    )
    if (popupProps.alignmentType === 'fullWidth') {
        layout.x = Math.max(layout.x, 0)
    }
    const measureMap = ps.siteAPI.getSiteMeasureMap()
    layout.y = Math.round(compAlignmentUtils.getTop(popupProps, layout.height, measureMap.innerHeight.screen))

    return layout
}
