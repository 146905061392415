import type {SnapshotDal} from '@wix/document-manager-core'
import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import editorServerFacade from '../editorServerFacade/editorServerFacade'

function fetchSitePropertiesInfoWithPS(ps: PS, onSuccess, onError) {
    editorServerFacade.sendWithPs(
        ps,
        editorServerFacade.ENDPOINTS.SITE_PROPERTIES_INFO,
        {},
        response => {
            const {properties} = response
            if (properties) {
                const currencyPointer = ps.pointers.general.getCurrency()
                ps.dal.set(currencyPointer, properties.paymentCurrency)

                const timeZonePointer = ps.pointers.general.getTimeZone()
                ps.dal.set(timeZonePointer, properties.timeZone)

                const siteDisplayNamePointer = ps.pointers.rendererModel.getSiteDisplayName()
                ps.dal.set(siteDisplayNamePointer, properties.siteDisplayName)

                const RegionalLanguagePointer = ps.pointers.general.getRegionalLanguage()
                ps.dal.set(RegionalLanguagePointer, properties.language)
                _.attempt(ps.dal.commitTransaction)
                if (_.isFunction(onSuccess)) {
                    onSuccess()
                }
            } else if (_.isFunction(onError)) {
                onError()
            }
        },
        onError || _.noop
    )
}

const endPoint = editorServerFacade.ENDPOINTS.SITE_PROPERTIES_INFO

function fetchSitePropertiesInfoWithSnapshotDal(currentSnapshotDal: SnapshotDal, onSuccess, onError, editorOrigin?) {
    const onSuccessFn = response => onSuccess(response.properties)
    editorServerFacade.sendWithSnapshotDal(
        currentSnapshotDal,
        endPoint,
        {},
        onSuccessFn,
        onError || _.noop,
        editorOrigin
    )
}

/** @class documentServices.generalInfo */
export default {
    fetchSitePropertiesInfoWithPS,
    fetchSitePropertiesInfoWithSnapshotDal
}
