import type {CreateExtArgs, Extension, ExtensionAPI} from '@wix/document-manager-core'

export interface ValidationExtensionAPI extends ExtensionAPI {
    validation: {
        validateOpenTransactionAndReportOnly(tags?: Record<string, string>): void
    }
}

const createExtensionAPI = ({dal}: CreateExtArgs): ValidationExtensionAPI => {
    const validateOpenTransactionAndReportOnly = (tags?: Record<string, string>): void => {
        try {
            dal.validatePendingCommit(tags)
        } catch (e) {
            //Ignoring, reporting only
        }
    }

    return {
        validation: {
            validateOpenTransactionAndReportOnly
        }
    }
}

const createExtension = (): Extension => ({
    name: 'validation',
    createExtensionAPI
})

export {createExtension}
