import _ from 'lodash'
import type {LayoutDataItem, Pointer, Pointers} from '@wix/document-services-types'

import type {DataModelExtensionAPI} from '../dataModel/dataModel'
import {MASTER_PAGE_ID, VIEW_MODES} from '../../constants/constants'
import type {FeaturesExtensionAPI} from '../features/features'
import type {VariantsExtensionAPI} from '../variants/variants'
import type {StructureExtensionAPI} from '../structure'
import type {ExtensionAPI, DAL} from '@wix/document-manager-core'

type PinnedSectionPosition = 'top' | 'bottom' | 'left' | 'right'
type TrackType = 'rows' | 'columns'

const sectionTypes = [
    'responsive.components.HeaderSection',
    'responsive.components.FooterSection',
    'responsive.components.Section',
    'responsive.components.MembersAreaSection',
    'wysiwyg.viewer.components.tpapps.TPAMultiSection',
    'wysiwyg.viewer.components.tpapps.TPASection'
]

const masterPageDataPointer = {id: MASTER_PAGE_ID, type: VIEW_MODES.DESKTOP}

export const createPinToStickyPrivateAPI = (extensionAPI: ExtensionAPI, pointers: Pointers, dal: DAL) => {
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const {features} = extensionAPI as FeaturesExtensionAPI
    const {variants} = extensionAPI as VariantsExtensionAPI
    const {components} = extensionAPI as StructureExtensionAPI

    let countSectionsWithStickyOverrides = 0
    let countSectionsWithPinOverrides = 0
    let countGlobalVerticalSection = 0

    const isPinned = (compPointer: Pointer) => {
        const layout = variants.getComponentItemConsideringVariants(compPointer, 'layout')
        return layout?.itemLayout?.type === 'FixedItemLayout'
    }
    const getMasterComp = (compPointer: Pointer) => {
        const compData = dataModel.components.getItem(compPointer, 'data')
        if (compData) {
            const {rootCompId} = compData
            return dataModel.getItem(rootCompId, VIEW_MODES.DESKTOP, MASTER_PAGE_ID)
        }
    }

    const isRefOfSection = (refPointer: Pointer) => {
        const referredComponent = getMasterComp(refPointer)
        return !!referredComponent && sectionTypes.includes(referredComponent.componentType)
    }

    const getSectionsInPage = (pagePointer: Pointer) => {
        return components.getChildren(pagePointer).filter((pointer: Pointer) => {
            const componentType = components.getComponentType(pointer)
            return sectionTypes.includes(componentType)
        })
    }

    const getRefsOfSectionsInPage = (pagePointer: Pointer) => {
        return components.getChildren(pagePointer).filter((pointer: Pointer) => {
            const componentType = components.getComponentType(pointer)
            return componentType === 'wysiwyg.viewer.components.RefComponent' && isRefOfSection(pointer)
        })
    }

    const getAllRefsAndSectionPointersInPage = (pagePointer: Pointer) =>
        [...getSectionsInPage(pagePointer), ...getRefsOfSectionsInPage(pagePointer)].map(comp =>
            pagePointer.variants ? {...comp, variants: pagePointer.variants} : comp
        )

    const isHiddenConsideringVariants = (pointer: Pointer) => {
        const layout = variants.getComponentItemConsideringVariants(pointer, 'layout')
        return layout?.componentLayout?.hidden
    }

    const sortBreakpoints = (pageId: string, bp1: Pointer, bp2: Pointer) => {
        const bp1Range = dataModel.getItem(bp1.id, 'variants', pageId)
        const bp2Range = dataModel.getItem(bp2.id, 'variants', pageId)
        return bp2Range.max - bp1Range.max
    }

    const getSortedPageBreakpoints = (pageId: string) =>
        pointers.data
            .getVariantItemsWithPredicate(variant => variant.type === 'BreakpointRange', pageId)
            .sort((bp1, bp2) => sortBreakpoints(pageId, bp1, bp2))

    const getCompPointerForComponentLayout = (compPointer: Pointer) => {
        const referredComponent = getMasterComp(compPointer)
        return referredComponent
            ? {
                  id: referredComponent.id,
                  type: 'DESKTOP'
              }
            : compPointer
    }

    const isHorizontalSection = (section: Pointer) => {
        const layout = variants.getComponentItemConsideringVariants(section, 'layout')
        return !layout.componentLayout.width || layout.componentLayout.width.type === 'auto'
    }

    const getPinnedSectionPosition = (sectionPointer: Pointer): PinnedSectionPosition => {
        const sectionItemLayout = variants.getComponentItemConsideringVariants(sectionPointer, 'layout').itemLayout
        const isHorizontal = isHorizontalSection(getCompPointerForComponentLayout(sectionPointer))

        const alignProperty = isHorizontal ? 'alignSelf' : 'justifySelf'
        if (sectionItemLayout[alignProperty] === 'start') {
            return isHorizontal ? 'top' : 'left'
        } else if (sectionItemLayout[alignProperty] === 'end') {
            return isHorizontal ? 'bottom' : 'right'
        }
        throw new Error('section is not pinned')
    }

    const getSectionTrackTypeAndIndex = (
        pageLayout: any,
        sectionPosition: PinnedSectionPosition
    ): {trackType: TrackType; trackIndex: number} => {
        switch (sectionPosition) {
            case 'top':
                return {trackType: 'rows', trackIndex: 0}
            case 'bottom':
                return {trackType: 'rows', trackIndex: pageLayout.containerLayout.rows.length - 1}
            case 'left':
                return {trackType: 'columns', trackIndex: 0}
            case 'right':
                return {trackType: 'columns', trackIndex: pageLayout.containerLayout.columns.length - 1}
        }
    }

    const isEmptyLayoutDataItem = (item: LayoutDataItem) => {
        if (_.isEmpty(item)) {
            return true
        }
        return Object.keys(item).length === 1 && !!item.type
    }

    const hasLayoutOverrides = (pp: Pointer, layoutType: 'componentLayout' | 'containerLayout' | 'itemLayout') => {
        const layout = variants.getComponentItemConsideringVariants(pp, 'layout')
        return !isEmptyLayoutDataItem(layout?.[layoutType])
    }

    const isPinnedWithOverlap = (pagePointer: Pointer, sectionPosition: PinnedSectionPosition) => {
        if (_.isEqual(pagePointer, masterPageDataPointer)) {
            return false
        }
        const pageLayout = variants.getComponentItemConsideringVariants(pagePointer, 'layout')
        if (!hasLayoutOverrides(pagePointer, 'containerLayout')) {
            return false
        }

        const {trackType, trackIndex} = getSectionTrackTypeAndIndex(pageLayout, sectionPosition)
        return !(
            pageLayout.containerLayout[trackType][trackIndex].type === 'px' ||
            pageLayout.containerLayout[trackType][trackIndex].type === 'Calc'
        )
    }

    const shouldMigrateToSticky = (
        sectionPointer: Pointer,
        pagePointer: Pointer,
        sectionPosition: PinnedSectionPosition
    ) => {
        const pageBreakpoints = getSortedPageBreakpoints(pagePointer.id)
        const allSectionPointers = [
            sectionPointer,
            ...pageBreakpoints.map(breakpoint => ({...sectionPointer, variants: [breakpoint]}))
        ]
        let largestDisplayedPointer
        for (const pointer of allSectionPointers) {
            if (
                hasLayoutOverrides(pointer, 'componentLayout') &&
                !isHiddenConsideringVariants(pointer) &&
                !largestDisplayedPointer
            ) {
                largestDisplayedPointer = pointer
            }
        }

        if (!largestDisplayedPointer) {
            return false
        }
        return !isPinnedWithOverlap(
            largestDisplayedPointer.variants
                ? {...pagePointer, variants: largestDisplayedPointer.variants}
                : pagePointer,
            sectionPosition
        )
    }

    const isVerticalSection = (pageLayout: any, sectionLayout: any) => {
        const {gridArea} = sectionLayout.itemLayout
        if (sectionLayout?.itemLayout?.gridArea?.columnEnd - sectionLayout?.itemLayout?.gridArea?.columnStart === 1) {
            const pageColumn = pageLayout?.containerLayout?.columns[gridArea.columnStart - 1]
            return (
                pageColumn &&
                pageColumn.type !== 'fr' &&
                pageColumn.type !== 'MinMaxSize' &&
                pageColumn.type !== 'Repeat'
            )
        }
    }

    const shouldRemovePageGridTrack = (
        pagePointer: Pointer,
        sectionPosition: PinnedSectionPosition,
        shouldApplyStickyOnAllBreakpoints: boolean
    ) => {
        if (shouldApplyStickyOnAllBreakpoints) {
            return false
        }
        const allRefsAndSections = getAllRefsAndSectionPointersInPage(pagePointer)
        const pageLayout = variants.getComponentItemConsideringVariants(pagePointer, 'layout')
        if (!hasLayoutOverrides(pagePointer, 'containerLayout')) {
            return false
        }
        const {trackIndex, trackType} = getSectionTrackTypeAndIndex(pageLayout, sectionPosition)
        if (
            pageLayout.containerLayout[trackType][trackIndex].type === 'auto' ||
            pageLayout.containerLayout[trackType][trackIndex].type === 'MinMaxSize'
        ) {
            return false
        }
        return !allRefsAndSections.some(section => {
            const sectionLayout = variants.getComponentItemConsideringVariants(section, 'layout')
            return trackType === 'rows'
                ? !isVerticalSection(pageLayout, sectionLayout) &&
                      sectionLayout?.itemLayout?.gridArea?.rowStart === trackIndex + 1
                : isVerticalSection(pageLayout, sectionLayout) &&
                      sectionLayout?.itemLayout?.gridArea?.columnStart === trackIndex + 1
        })
    }

    const sectionHavePinBehaviorOverrides = (pagePointer: Pointer, sectionPosition: PinnedSectionPosition) => {
        const pageBreakpoints = getSortedPageBreakpoints(pagePointer.id)
        const allPagePointers = [
            pagePointer,
            ...pageBreakpoints.map(breakpoint => ({...pagePointer, variants: [breakpoint]}))
        ]

        const sectionWithOverrides = allPagePointers
            .filter(pp => hasLayoutOverrides(pp, 'containerLayout'))
            .map(pageInBreakpoint => isPinnedWithOverlap(pageInBreakpoint, sectionPosition))
        return !sectionWithOverrides.every(result => result === sectionWithOverrides[0])
    }

    const fixOtherSectionsGridAreasAfterDeleteTrack = (
        pagePointer: Pointer,
        pinnedSectionPosition: PinnedSectionPosition
    ) => {
        const allRefsAndSections = getAllRefsAndSectionPointersInPage(pagePointer)
        for (const sectionPointer of allRefsAndSections) {
            const sectionLayout = variants.getComponentItemConsideringVariants(sectionPointer, 'layout')
            const sectionGridArea = sectionLayout?.itemLayout?.gridArea
            if (sectionGridArea) {
                let newGridArea
                if (pinnedSectionPosition === 'top') {
                    newGridArea = {
                        ...sectionGridArea,
                        rowStart:
                            sectionGridArea.rowStart > 1 ? sectionGridArea.rowStart - 1 : sectionGridArea.rowStart,
                        rowEnd: sectionGridArea.rowEnd - 1
                    }
                }
                if (pinnedSectionPosition === 'left') {
                    newGridArea = {
                        ...sectionGridArea,
                        columnStart:
                            sectionGridArea.columnStart > 1
                                ? sectionGridArea.columnStart - 1
                                : sectionGridArea.columnStart,
                        columnEnd: sectionGridArea.columnEnd - 1
                    }
                }
                if (newGridArea) {
                    variants.updateComponentDataConsideringVariants(
                        sectionPointer,
                        {...sectionLayout, itemLayout: {...sectionLayout.itemLayout, gridArea: newGridArea}},
                        'layout'
                    )
                }
            }
        }
    }

    const setPinWithOverlap = (
        pagePointer: Pointer,
        sectionPosition: PinnedSectionPosition,
        removePinnedSectionTrack: boolean
    ) => {
        if (!removePinnedSectionTrack) {
            return
        }
        const pageLayout = variants.getComponentItemConsideringVariants(pagePointer, 'layout')

        if (!hasLayoutOverrides(pagePointer, 'containerLayout')) {
            return
        }
        const {trackType, trackIndex} = getSectionTrackTypeAndIndex(pageLayout, sectionPosition)
        const newPageLayout = {
            ...pageLayout,
            containerLayout: {
                ...pageLayout.containerLayout,
                [trackType]: [
                    ...pageLayout.containerLayout[trackType].slice(0, trackIndex),
                    ...pageLayout.containerLayout[trackType].slice(trackIndex + 1)
                ]
            }
        }
        variants.updateComponentDataConsideringVariants(pagePointer, newPageLayout, 'layout')
        fixOtherSectionsGridAreasAfterDeleteTrack(pagePointer, sectionPosition)
    }

    const getDirectionForStickyPosition = (sectionPosition: PinnedSectionPosition) =>
        sectionPosition === 'bottom' ? 'bottom' : 'top'

    const getGridAreaForStickyPosition = (pagePointer: Pointer, sectionPosition: PinnedSectionPosition) => {
        const pageLayout = variants.getComponentItemConsideringVariants(pagePointer, 'layout')
        const {trackType, trackIndex} = getSectionTrackTypeAndIndex(pageLayout, sectionPosition)
        if (trackType === 'columns') {
            const numberOfRowsInPage = pageLayout.containerLayout.rows.length
            return {
                columnStart: trackIndex + 1,
                columnEnd: trackIndex + 2,
                rowStart: 1,
                rowEnd: numberOfRowsInPage + 1
            }
        }
        // horizontal section
        const pageGridColumns = pageLayout.containerLayout.columns
        const sectionColumnStart = pageGridColumns.findIndex(
            (column: any) => column.type !== 'Repeat' && (column.type === 'MinMaxSize' || column.type === 'fr')
        )
        return {
            rowStart: trackIndex + 1,
            rowEnd: trackIndex + 2,
            columnStart: sectionColumnStart + 1,
            columnEnd: sectionColumnStart + 2
        }
    }

    const moveToFront = (sectionPointer: Pointer, pagePointer: Pointer) => {
        const pageInnerPointer = pointers.getInnerPointer(pagePointer, 'components')
        const pageChildrenIds = dal.get(pageInnerPointer)
        const sectionPointerIndex = pageChildrenIds.findIndex((id: string) => id === sectionPointer.id)
        const newPageChildrenIds = [
            ...pageChildrenIds.slice(0, sectionPointerIndex),
            ...pageChildrenIds.slice(sectionPointerIndex + 1),
            sectionPointer.id
        ]
        dal.set(pageInnerPointer, newPageChildrenIds)
    }

    const setStickyItemLayout = (
        sectionPointer: Pointer,
        pagePointer: Pointer,
        sectionPosition: PinnedSectionPosition
    ) => {
        const direction = getDirectionForStickyPosition(sectionPosition)
        const gridArea = getGridAreaForStickyPosition(pagePointer, sectionPosition)
        const sectionLayout = variants.getComponentItemConsideringVariants(sectionPointer, 'layout')
        if (_.isEmpty(sectionLayout?.itemLayout)) {
            return
        }
        const newItemLayout = {
            gridArea,
            position: 'sticky',
            top: direction === 'top' ? {type: 'percentage', value: 0} : {type: 'auto'},
            type: 'GridItemLayout',
            alignSelf: 'stretch',
            justifySelf: 'stretch'
        }
        const newItemLayoutIncludingBottom =
            direction === 'bottom' ? {...newItemLayout, bottom: {type: 'percentage', value: 0}} : newItemLayout
        return variants.updateComponentDataConsideringVariants(
            sectionPointer,
            {
                ...sectionLayout,
                itemLayout: newItemLayoutIncludingBottom
            },
            'layout'
        )
    }

    const getMasterSectionSortedBreakpoints = (sectionRef: Pointer) => {
        const breakpointSource = getCompPointerForComponentLayout(sectionRef)

        const masterPageBreakpointsVariants = pointers.data.getVariantItemsWithPredicate(
            variant => variant.type === 'BreakpointRange',
            masterPageDataPointer.id
        )

        const refBreakpoints = Object.values(pointers.data.getVariantDataItemsByComponentId(breakpointSource.id))
            .filter(x => x.type === 'BreakpointsData')
            .map((x: any) => x.values.map((p: string) => p.substring(1), 'variants', masterPageDataPointer.id))
            .flat()

        const reBreakpointsVariants = masterPageBreakpointsVariants.filter(bp => refBreakpoints.includes(bp.id))

        return reBreakpointsVariants.sort((bp1, bp2) => sortBreakpoints(masterPageDataPointer.id, bp1, bp2))
    }

    const setStickyComponentLayout = (allCompPointersToUpdate: Pointer[]) => {
        for (const pointer of allCompPointersToUpdate) {
            const layout = variants.getComponentItemConsideringVariants(pointer, 'layout')

            let newComponentLayout
            if (!hasLayoutOverrides(pointer, 'componentLayout')) {
                continue
            }
            if (!layout.componentLayout.width || layout.componentLayout.width.type === 'auto') {
                // horizontal section
                newComponentLayout = {
                    ...layout.componentLayout,
                    width: {type: 'auto'},
                    minHeight: layout.componentLayout.minHeight ?? {type: 'px', value: 0},
                    maxHeight: layout.componentLayout.maxHeight ?? {type: 'px', value: 99999}
                }
            } else {
                newComponentLayout = {
                    ...layout.componentLayout,
                    height: {type: 'vh', value: 100},
                    minWidth: layout.componentLayout.minWidth ?? {type: 'px', value: 0},
                    maxWidth: layout.componentLayout.maxWidth ?? {type: 'px', value: 99999}
                }
            }
            variants.updateComponentDataConsideringVariants(
                pointer,
                {
                    ...layout,
                    componentLayout: newComponentLayout
                },
                'layout'
            )
        }
    }

    const setStickyComponentLayoutToSectionRef = (sectionRefPointer: Pointer) => {
        const allBreakpoints = getMasterSectionSortedBreakpoints(sectionRefPointer)
        const CompPointerForComponentLayout = getCompPointerForComponentLayout(sectionRefPointer)
        const allCompPointersToUpdate = [
            CompPointerForComponentLayout,
            ...allBreakpoints.map(breakpoint => ({...CompPointerForComponentLayout, variants: [breakpoint]}))
        ]
        setStickyComponentLayout(allCompPointersToUpdate)
    }

    const setStickyPageContainerLayout = (
        pagePointer: Pointer,
        pinnedSectionTrackIndex: number,
        gridTrackType: TrackType
    ) => {
        const pageLayout = variants.getComponentItemConsideringVariants(pagePointer, 'layout')
        const newPageLayout = {
            ...pageLayout,
            containerLayout: {
                ...pageLayout.containerLayout,
                [gridTrackType]: [
                    ...pageLayout.containerLayout[gridTrackType].slice(0, pinnedSectionTrackIndex),
                    {type: 'auto'},
                    ...pageLayout.containerLayout[gridTrackType].slice(pinnedSectionTrackIndex + 1)
                ]
            }
        }
        variants.updateComponentDataConsideringVariants(pagePointer, newPageLayout, 'layout')
    }
    const updateHorizontalSectionColumnSpan = (
        pagePointer: Pointer,
        pinnedSectionPointer: Pointer,
        pinnedSectionPosition: PinnedSectionPosition
    ) => {
        const allOtherSectionPointersInPage = getAllRefsAndSectionPointersInPage(pagePointer).filter(
            section => !pointers.components.isSameComponent(section, pinnedSectionPointer)
        )
        const pagePointerLayout = variants.getComponentItemConsideringVariants(pagePointer, 'layout')
        for (const sectionPointer of allOtherSectionPointersInPage) {
            const sectionLayout = variants.getComponentItemConsideringVariants(sectionPointer, 'layout')
            const sectionGridArea = sectionLayout?.itemLayout?.gridArea
            if (pinnedSectionPosition === 'left') {
                if (sectionGridArea && sectionGridArea.columnStart === 1) {
                    variants.updateComponentDataConsideringVariants(
                        sectionPointer,
                        {
                            ...sectionLayout,
                            itemLayout: {
                                ...sectionLayout.itemLayout,
                                gridArea: {...sectionLayout.itemLayout.gridArea, columnStart: 2}
                            }
                        },
                        'layout'
                    )
                }
            }
            if (pinnedSectionPosition === 'right') {
                if (
                    sectionGridArea &&
                    sectionGridArea.columnEnd === pagePointerLayout.containerLayout.columns.length + 1
                ) {
                    variants.updateComponentDataConsideringVariants(
                        sectionPointer,
                        {
                            ...sectionLayout,
                            itemLayout: {
                                ...sectionLayout.itemLayout,
                                gridArea: {
                                    ...sectionLayout.itemLayout.gridArea,
                                    columnEnd: pagePointerLayout.containerLayout.columns.length
                                }
                            }
                        },
                        'layout'
                    )
                }
            }
        }
    }

    const setSticky = (sectionPointer: Pointer, pagePointer: Pointer, sectionPosition: PinnedSectionPosition) => {
        const pageLayout = variants.getComponentItemConsideringVariants(pagePointer, 'layout')
        if (_.isEmpty(pageLayout?.containerLayout)) {
            return
        }

        setStickyItemLayout(sectionPointer, pagePointer, sectionPosition)
        const {trackType, trackIndex} = getSectionTrackTypeAndIndex(pageLayout, sectionPosition)

        if (!isHiddenConsideringVariants(sectionPointer)) {
            setStickyPageContainerLayout(pagePointer, trackIndex, trackType)
        }

        if (sectionPosition === 'left' || sectionPosition === 'right') {
            updateHorizontalSectionColumnSpan(pagePointer, sectionPointer, sectionPosition)
        }
        moveToFront(sectionPointer, pagePointer)
    }

    const countPinToStickyOverrides = (
        pagePointer: Pointer,
        sectionPosition: PinnedSectionPosition,
        shouldApplyStickyOnAllBreakpoints: boolean
    ) => {
        const hasPinBehaviorOverride = sectionHavePinBehaviorOverrides(pagePointer, sectionPosition)

        if (hasPinBehaviorOverride) {
            if (shouldApplyStickyOnAllBreakpoints) {
                countSectionsWithStickyOverrides += 1
            } else {
                countSectionsWithPinOverrides += 1
            }
        }
    }

    const updatePinToStickyNotifications = () => {
        const notifications = features.component.get(masterPageDataPointer, 'notifications')
        const pinToStickyNotification = _.remove(notifications?.items, (item: any) => item.type === 'PinToSticky')[0]

        const updatedPinToStickyNotification = pinToStickyNotification
            ? {
                  ...pinToStickyNotification,
                  sticky: (pinToStickyNotification.sticky || 0) + countSectionsWithStickyOverrides,
                  pinned: (pinToStickyNotification.pinned || 0) + countSectionsWithPinOverrides,
                  masterVertical: (pinToStickyNotification.masterVertical || 0) + countGlobalVerticalSection
              }
            : {
                  type: 'PinToSticky',
                  sticky: countSectionsWithStickyOverrides,
                  pinned: countSectionsWithPinOverrides,
                  masterVertical: countGlobalVerticalSection
              }

        // Only update notification when at least one override scenario happened
        if (countSectionsWithStickyOverrides || countSectionsWithPinOverrides || countGlobalVerticalSection) {
            const updatedNotification = notifications?.items
                ? {
                      ...notifications,
                      items: [...notifications.items, updatedPinToStickyNotification]
                  }
                : {
                      type: 'Notifications',
                      items: [updatedPinToStickyNotification]
                  }
            dataModel.components.addItem(masterPageDataPointer, 'notifications', updatedNotification)
        }
    }

    const getComponentPointersWithBreakpointVariants = (componentPointer: Pointer, pageBreakpoints: Pointer[]) => {
        return [
            componentPointer,
            ...pageBreakpoints.map(breakpoint => ({
                ...componentPointer,
                variants: [breakpoint]
            }))
        ]
    }

    const getAllSectionsInPage = (pagePointer: Pointer) => {
        const sectionsInPage = getSectionsInPage(pagePointer)
        const refsOfSectionInPage = getRefsOfSectionsInPage(pagePointer)

        return [...sectionsInPage, ...refsOfSectionInPage]
    }

    const getAllPinnedSectionsInPage = (pagePointer: Pointer) => {
        return getAllSectionsInPage(pagePointer).filter(isPinned)
    }

    const isVerticalRefSection = (pinnedSection: Pointer) => {
        return !isHorizontalSection(getCompPointerForComponentLayout(pinnedSection))
    }

    const setComponentLayoutToSection = (
        shouldApplyStickyOnAllBreakpoints: boolean,
        pinnedSection: Pointer,
        pageBreakpoints: Pointer[]
    ) => {
        if (isRefOfSection(pinnedSection)) {
            if (isVerticalRefSection(pinnedSection)) {
                countGlobalVerticalSection++
            }
            setStickyComponentLayoutToSectionRef(pinnedSection)
        } else if (shouldApplyStickyOnAllBreakpoints) {
            const allCompPointersToUpdate = getComponentPointersWithBreakpointVariants(pinnedSection, pageBreakpoints)
            setStickyComponentLayout(allCompPointersToUpdate)
        }
    }

    const migrateSectionToPinnedOrSticky = (
        pinnedSection: Pointer,
        pagePointer: Pointer,
        pageBreakpoints: Pointer[],
        sectionPosition: PinnedSectionPosition,
        migrateToSticky: boolean
    ) => {
        const allPagePointers = getComponentPointersWithBreakpointVariants(pagePointer, pageBreakpoints)
        const shouldRemovePinnedSectionPageGridTrack = shouldRemovePageGridTrack(
            pagePointer,
            sectionPosition,
            migrateToSticky
        )
        for (const pagePointersConsideringVariants of allPagePointers) {
            const pinnedSectionPointerConsideringVariants = pagePointersConsideringVariants.variants
                ? {
                      ...pinnedSection,
                      variants: pagePointersConsideringVariants.variants
                  }
                : pinnedSection
            if (migrateToSticky) {
                setSticky(pinnedSectionPointerConsideringVariants, pagePointersConsideringVariants, sectionPosition)
            } else {
                setPinWithOverlap(
                    pagePointersConsideringVariants,
                    sectionPosition,
                    shouldRemovePinnedSectionPageGridTrack
                )
            }
        }
    }

    const hasMissingTrackInPageGrid = (pagePointer: Pointer) => {
        const allSections = getAllSectionsInPage(pagePointer)
        const pageLayout = variants.getComponentItemConsideringVariants(pagePointer, 'layout')
        if (!hasLayoutOverrides(pagePointer, 'containerLayout')) {
            return true
        }
        const horizontalSectionsCount = allSections
            .map(getCompPointerForComponentLayout)
            .filter(section => isHorizontalSection(section)).length
        const verticalSectionsCount = allSections.length - horizontalSectionsCount + 1

        const rows = pageLayout.containerLayout.rows.length
        const columns = pageLayout.containerLayout.columns.length
        return !(rows === horizontalSectionsCount && columns === verticalSectionsCount)
    }

    return {
        getAllPinnedSectionsInPage,
        getSortedPageBreakpoints,
        getComponentPointersWithBreakpointVariants,
        getPinnedSectionPosition,
        shouldMigrateToSticky,
        countPinToStickyOverrides,
        setComponentLayoutToSection,
        migrateSectionToPinnedOrSticky,
        updatePinToStickyNotifications,
        hasMissingTrackInPageGrid
    }
}
