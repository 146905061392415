import _ from 'lodash'

const MAX_CLONE_ITER = 5000000

function resolvePath(source: string | string[], path: string | string[]) {
    let ret = source
    for (let i = 0; i < path.length; i++) {
        const prop = path[i]
        if (_.has(ret, prop)) {
            ret = ret[prop]
        } else {
            return null
        }
    }
    return ret
}

function ensurePath(source: string | string[], path: string | string[]) {
    let ret = source
    for (let i = 0; i < path.length; i++) {
        const prop = path[i]
        if (!_.has(ret, prop)) {
            ret[prop] = {}
        }
        ret = ret[prop]
    }
}

function setInPath(source: string | string[], path: string | string[], value: string) {
    const scope = resolvePath(source, _.initial(path))
    if (scope !== null) {
        scope[_.last(path)] = value
    }
}

function filter(
    source: string | string[],
    predicate: (predicate: any) => any,
    canEnterSubtreePredicate: (expression: any) => boolean
) {
    canEnterSubtreePredicate = canEnterSubtreePredicate || (() => true)
    const ret = []

    function findInner(obj: string | string[]) {
        if (_.isNil(obj)) {
            return
        }

        if (predicate(obj)) {
            ret.push(obj)
        }

        if (!canEnterSubtreePredicate(obj)) {
            return
        }

        if (_.isPlainObject(obj) || _.isArray(obj)) {
            _.forEach(obj, function (value) {
                findInner(value)
            })
        }
    }

    findInner(source)
    return ret
}

function findPath(obj: {[x: string]: any}, predicate: (predicate: any) => any, path: string | string[]) {
    path = path || []

    if (predicate(obj)) {
        return path
    }

    let found = null
    if (_.isPlainObject(obj) || _.isArray(obj)) {
        _.forEach(obj, function (val, key) {
            found = findPath(obj[key], predicate, path.concat(key))
            if (found) {
                return false
            }
        })
    }

    return found
}

//copied lodash implementation
function cloneDate(date: string | number | Date) {
    return new Date(+date)
}

function cloneObject(object: object) {
    return _.isArray(object) ? [] : Object.create(Object.getPrototypeOf(object))
}

function cloneDeep(value: any, customizer?: <T>(input: T) => T): any {
    const hasCustomizer = _.isFunction(customizer)
    if (hasCustomizer) {
        const customizerResult = customizer(value)
        if (!_.isUndefined(customizerResult)) {
            return customizerResult
        }
    }

    if (!value || typeof value !== 'object') {
        return value
    }

    if (_.isDate(value)) {
        return cloneDate(value)
    }

    const result = cloneObject(value)
    const stack = [result, value]
    let curr
    let count = 0
    while ((curr = stack.pop())) {
        if (++count > MAX_CLONE_ITER) {
            throw new Error('cloneDeep too big')
        }

        const base = stack.pop()
        const keys = Object.keys(curr)
        for (let i = 0; i < keys.length; ++i) {
            const key = keys[i]
            const v = curr[key]

            if (hasCustomizer) {
                const customizerResult = customizer(v)
                if (!_.isUndefined(customizerResult)) {
                    base[key] = customizerResult
                    continue
                }
            }

            if (v && typeof v === 'object') {
                if (_.isDate(v)) {
                    base[key] = cloneDate(v)
                } else {
                    const newObj = cloneObject(v)
                    base[key] = newObj
                    stack.push(newObj, v)
                }
            } else {
                base[key] = v
            }
        }
    }
    return result
}

function isDifferent(a?: any, b?: any) {
    return !_.isEqual(a || null, b || null)
}

function cloneAndConditionalMergeOfFields(
    object: object,
    source: object,
    shouldTakeSourceValue: (
        objectField: string | number | boolean,
        sourceField: string | number | boolean,
        key: string
    ) => boolean
): object {
    if (!_.isObject(object)) {
        return object
    }
    const result = _.clone(object)

    _.forOwn(source, function (val, key) {
        if (typeof val !== 'object' && shouldTakeSourceValue(object[key], source[key], key)) {
            result[key] = source[key]
            return
        }
        if (!object[key]) {
            return
        }

        if (_.isPlainObject(val)) {
            result[key] = cloneAndConditionalMergeOfFields(object[key], val, shouldTakeSourceValue)
        } else if (_.isArray(val)) {
            result[key] = _.compact(
                _.map(val, function (item: object, index) {
                    if (!object[key][index]) {
                        return undefined
                    }
                    return cloneAndConditionalMergeOfFields(object[key][index], item, shouldTakeSourceValue)
                })
            )
            _.defaults(result[key], object[key])
        }
    })
    return result
}

function isObject(value: any) {
    return _.isObject(value) && !_.isArray(value)
}

function isSubset(object: object, partialObject: object): boolean {
    if (!object) {
        return false
    }
    let queue = _.toPairs(partialObject)
    for (let i = 0; i < queue.length; i++) {
        const key = queue[i][0]
        const value = queue[i][1]
        const valueToCompare = _.get(object, key)
        if (isObject(valueToCompare) && isObject(value) && !_.isEmpty(value)) {
            const nestedObj = _.mapKeys(value, (v, k) => `${key}.${k}`)
            queue = _.concat(queue, _.toPairs(nestedObj)) // add nested object properties to the queue
        } else if (!_.isEqual(valueToCompare, value)) {
            return false
        }
    }
    return true
}

export default {
    resolvePath,
    ensurePath,
    setInPath,
    filter,
    findPath,
    cloneDeep,
    isDifferent,
    isSubset,
    cloneAndConditionalMergeOfFields
}
