import {
    DocumentManager,
    EnvironmentContext,
    CoreLogger,
    store,
    documentManagerCoreTestkit
} from '@wix/document-manager-core'
const {createTestExperimentInstance, createTestLogger} = documentManagerCoreTestkit
import {constants, TestServer, SnapshotExtApi, testExports} from '@wix/document-manager-extensions'
const {savedBlankSite} = testExports
import type {
    DocumentServicesModel,
    RendererModel,
    RunningExperiments,
    ServiceTopology
} from '@wix/document-services-types'
import _ from 'lodash'
import {AnyPageMigrator, createDataMigrationRunner, SiteMigrator} from '../../src/dataMigration/dataMigrationRunner'
import {createHost} from '../../src/host'
import {initialize} from '../../src/initialize/mainInitialization'
import {newSchemaService} from '@wix/document-services-json-schemas'
import {DefaultRendererModelBuilderForHost} from '../../src'
import {createSignature, type FixerVersioningConfig} from '@wix/document-manager-utils'
import type {BootstrapConfig} from '../../src/types'

const {createStore} = store
const {SNAPSHOTS} = constants
const commonInitialization = (documentManager: DocumentManager) => {
    documentManager.dal.commitTransaction('test', true)
    const {snapshots} = documentManager.extensionAPI as SnapshotExtApi
    snapshots.takeSnapshot(SNAPSHOTS.DAL_INITIAL)
    snapshots.takeSnapshot(SNAPSHOTS.MOBILE_MERGE)
}

const createHostCommon = ({
    rendererModel,
    serviceTopology,
    documentServicesModel,
    config,
    initializationFunc,
    environmentContext = {},
    logger
}: any) => {
    config.dsOrigin = config.dsOrigin || 'DSFunctionalTestOrigin'
    config.signatureSeed = 'sig'

    const host = createHost({
        models: {serviceTopology, documentServicesModel},
        partialPages: [],
        config,
        experimentInstance: createTestExperimentInstance(rendererModel.runningExperiments),
        logger: logger ?? createTestLogger(),
        schemaService: newSchemaService.staticInstance,
        environmentContext,
        pageList: rendererModel.pageList,
        rendererModelBuilder: new DefaultRendererModelBuilderForHost(rendererModel),
        initFunc: initialize
    })

    const {documentManager} = host
    initializationFunc(documentManager)
    commonInitialization(documentManager)

    return host
}

const initializeWithSnapshot = (snapshot: Record<string, any>, documentManager: DocumentManager) => {
    const initialData = createStore()
    _(snapshot)
        .omit(['serviceTopology', 'documentServicesModel', 'rendererModel'])
        .forEach((typeMap, type) => {
            _.forEach(typeMap, (data, id) => {
                const pointer = documentManager.pointers.getPointer(id, type)
                initialData.set(pointer, data)
            })
        })
    documentManager.dal.mergeToApprovedStore(initialData, 'initializeWithSnapshot')
}

const getModelsAndQueryFromSnapshot = (
    snapshot: any,
    experiments?: RunningExperiments,
    configName = 'testFullFunctionality'
) => {
    const rendererModel = _.merge({}, snapshot.rendererModel, {
        urlFormatModel: {forbiddenPageUriSEOs: snapshot.rendererModel?.urlFormatModel?.forbiddenPageUriSEOs},
        runningExperiments: experiments
    })
    const documentServicesModel = _.merge({}, snapshot.documentServicesModel, {
        silentSaveInitiator: snapshot.save?.silentSaveInitiator,
        publishSaveInitiator: snapshot.save?.publishSaveInitiator
    })
    const serviceTopology = snapshot.serviceTopology?.serviceTopology ?? {}
    const config = {
        debug: 'all',
        dsOrigin: 'Editor1.4',
        editorSessionId: documentServicesModel.editorSessionId,
        esi: documentServicesModel.editorSessionId,
        isEdited: 'true',
        isSantaEditor: 'true',
        lang: 'en',
        metaSiteId: rendererModel.metaSiteId,
        configName,
        enableScopes: 'true'
    }
    return {
        rendererModel,
        documentServicesModel,
        serviceTopology,
        config
    }
}

export interface TestModels {
    rendererModel: RendererModel
    documentServicesModel: DocumentServicesModel
    serviceTopology: ServiceTopology
    config: BootstrapConfig
}

interface CreateHostOptions {
    experiments?: RunningExperiments
    overrides?: TestModels
    environmentContext?: Partial<EnvironmentContext>
    configName?: string
    logger?: CoreLogger
}
interface CreateHostOptionsWithSnapshotOverrides extends CreateHostOptions {
    snapshot?: any
}

const createHostFromSnapshot = (
    snapshot: any,
    {experiments, overrides, environmentContext = {}, configName, logger}: CreateHostOptions = {}
) => {
    if (!environmentContext?.serverFacade) {
        const serverFacade = new TestServer()
        _.set(environmentContext, ['serverFacade'], serverFacade)
    }
    const models = getModelsAndQueryFromSnapshot(snapshot, experiments, configName)
    const mergedModels = _.merge({}, models, overrides)
    const {serviceTopology, documentServicesModel, rendererModel, config} = mergedModels

    const initializationFunc = (documentManager: DocumentManager) => {
        documentManager.initializeModels(mergedModels)
        initializeWithSnapshot(snapshot, documentManager)
    }

    const host = createHostCommon({
        rendererModel,
        serviceTopology,
        documentServicesModel,
        config,
        initializationFunc,
        environmentContext,
        logger
    })

    return {host, config}
}

const createHostFromBlankSite = (options?: CreateHostOptionsWithSnapshotOverrides) => {
    const snapshotOverrides = options?.snapshot ?? {}
    const snapshot = _.merge({}, savedBlankSite, snapshotOverrides)
    return createHostFromSnapshot(snapshot, options)
}

const createFixerVersioningConfig = (fixerNames: string[]): FixerVersioningConfig => {
    const fixerVersioningConfig = {
        lastFixerIndex: fixerNames.length - 1,
        fixers: {}
    }
    fixerNames.forEach((fixerName, index) => {
        fixerVersioningConfig.fixers[fixerName] = createSignature(fixerName, index)
    })
    return fixerVersioningConfig
}

const createTestDataMigrationRunner = (pageMigrators: AnyPageMigrator[], runOncePerSite?: SiteMigrator[]) => {
    const allMigrators = [...pageMigrators, ...(runOncePerSite || [])]
    const fixerVersioningConfig = createFixerVersioningConfig(allMigrators.map(migrator => migrator.name))
    return createDataMigrationRunner(pageMigrators, runOncePerSite, fixerVersioningConfig)
}

export {
    createHostCommon,
    savedBlankSite,
    createHostFromSnapshot,
    createHostFromBlankSite,
    createTestDataMigrationRunner,
    createFixerVersioningConfig
}
