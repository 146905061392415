import _ from 'lodash'
import quickActionBarManager from '../../mobileConversion/mobileEditorSettings/quickActionBarManager'
import quickActions from '../../mobileConversion/mobileEditorSettings/quickActions'
import component from '../../component/component'
import constants from '../../tpa/constants'
import tpaEventHandlersService from '../../tpa/services/tpaEventHandlersService'

function siteContainsLegacyActionBar(ps) {
    return quickActions.isEnabled(ps)
}

function isChatTpa(ps, compPointer) {
    const compData = component.data.get(ps, compPointer)
    return (
        _.get(ps.dal.get(ps.pointers.general.getClientSpecMap()), [compData.applicationId, 'appDefinitionId']) ===
        constants.APP_DEF_ID.CHAT
    )
}

function addedGluedWidget(ps, compPointer) {
    const shouldUpgradeActionBar = isChatTpa(ps, compPointer) && !siteContainsLegacyActionBar(ps)
    if (shouldUpgradeActionBar) {
        quickActionBarManager.addQuickActionBar(ps)
    }
}

function willRemoveGluedWidget(ps, compPointer) {
    const shouldRevertToLegacyActionBar =
        isChatTpa(ps, compPointer) && _(quickActionBarManager.getActions(ps)).reject('isDynamic').isEmpty()
    if (shouldRevertToLegacyActionBar) {
        quickActionBarManager.removeQuickActionBar(ps)
    }
}

function executeDisconnectHandler(ps, compPointer) {
    tpaEventHandlersService.executeDisconnectHandler(compPointer.id)
}

export default {
    addedGluedWidget,
    willRemoveGluedWidget,
    executeDisconnectHandler
}
