import {ReportableError} from '@wix/document-manager-utils'
import type {MobileHints, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import componentsMetaData from '../../componentsMetaData/componentsMetaData'
import constants from '../../constants/constants'
import dataModel from '../../dataModel/dataModel'
import hooks from '../../hooks/hooks'

function getUnhiddenChildren(ps: PS, componentPointer: Pointer) {
    const children = ps.pointers.components.getChildren(componentPointer)
    return _.reject(children, function (childPointer) {
        return _.get(dataModel.getMobileHintsItem(ps, childPointer), 'hidden', false)
    })
}

const beforeUpdateValidators = [
    {
        error: 'Cannot unhide desktop only component',
        isValid(ps: PS, componentPointer: Pointer, mobileHints: MobileHints, pageId: string) {
            const explicitlyHidden = _.get(mobileHints, 'hidden') !== false
            return (
                explicitlyHidden ||
                !componentsMetaData.public.getMobileConversionConfigByName(
                    ps,
                    ps.dal.get(componentPointer),
                    'desktopOnly',
                    pageId
                )
            )
        }
    },
    {
        error: 'Cannot hide not hiddenable component',
        isValid(ps: PS, componentPointer: Pointer, mobileHints: MobileHints) {
            return !_.get(mobileHints, 'hidden', false) || componentsMetaData.public.isHiddenable(ps, componentPointer)
        }
    }
]
const finalStructureValidators = [
    {
        error: 'Unhidden container with children number less than allowed',
        isValid(ps: PS, componentPointer: Pointer, mobileHints: MobileHints) {
            const minimalChildrenNumber = componentsMetaData.public.getMinimalChildrenNumber(ps, componentPointer)
            if (minimalChildrenNumber === 0 || _.get(mobileHints, 'hidden', false)) {
                return true
            }
            const unhiddenChildren = getUnhiddenChildren(ps, componentPointer)
            return _.size(unhiddenChildren) >= minimalChildrenNumber
        }
    },
    {
        error: 'Unhidden structuralItem component with hidden parent',
        isValid(ps: PS, componentPointer: Pointer, mobileHints: MobileHints, pageId: string) {
            const structuralItem = componentsMetaData.public.getMobileConversionConfigByName(
                ps,
                ps.dal.get(componentPointer),
                'structuralItem',
                pageId
            )
            if (!structuralItem || _.get(mobileHints, 'hidden', false)) {
                return true
            }
            const parentPointer = ps.pointers.components.getParent(componentPointer)
            return !_.get(dataModel.getMobileHintsItem(ps, parentPointer), 'hidden', false)
        }
    }
]

function validateComponentMobileHints(ps: PS, componentPointer: Pointer, pageId: string) {
    const componentPageId =
        pageId ||
        _.get(
            ps.pointers.full.components.getPageOfComponent(componentPointer) ||
                ps.pointers.components.getPageOfComponent(componentPointer),
            'id',
            ''
        )
    const mobileHintsItem = dataModel.getMobileHintsItem(ps, componentPointer)
    runAllValidators(ps, componentPointer, mobileHintsItem, componentPageId)
    validateMobileHintsBySchema(ps, mobileHintsItem)
}

function validateMobileHintsBySchema(ps, mobileHints: MobileHints) {
    if (mobileHints) {
        ps.extensionAPI.schemaAPI.addDefaultsAndValidate('MobileHints', mobileHints, constants.DATA_TYPES.mobileHints)
    }
}

function runBeforeUpdateValidators(ps: PS, componentPointer: Pointer, mobileHintsItem: MobileHints, pageId: string) {
    runValidators(ps, componentPointer, mobileHintsItem, pageId, beforeUpdateValidators)
}

function runAllValidators(ps: PS, componentPointer: Pointer, mobileHintsItem: MobileHints, pageId: string) {
    const validators = _.union(beforeUpdateValidators, finalStructureValidators)
    runValidators(ps, componentPointer, mobileHintsItem, pageId, validators)
}

function runValidators(ps: PS, componentPointer: Pointer, mobileHintsItem: MobileHints, pageId: string, validators) {
    _.forEach(validators, function (validator) {
        if (!validator.isValid(ps, componentPointer, mobileHintsItem, pageId)) {
            throw new ReportableError({
                errorType: 'invalidMobileHints',
                message: validator.error
            })
        }
    })
}

function validateMobileHintsOnPage(ps: PS, pageId: string) {
    const validationErrors: {
        componentId: string
        componentType: string
        error: string
    }[] = []
    const pagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.DESKTOP)
    if (!pagePointer) {
        return []
    }
    const allComponentsOnPage = ps.pointers.components.getChildrenRecursivelyRightLeftRootIncludingRoot(pagePointer)
    _.forEach(allComponentsOnPage, function (componentPointer) {
        try {
            validateComponentMobileHints(ps, componentPointer, pageId)
        } catch (error: any) {
            validationErrors.push({
                componentId: componentPointer.id,
                componentType: ps.dal.get(ps.pointers.getInnerPointer(componentPointer, 'componentType')),
                error: error.message || error.toString()
            })
        }
    })
    return validationErrors
}

function validateMobileHintsOnPages(ps: PS, pageIds: string[]) {
    const validationErrors = {}
    _.forEach(pageIds, function (pageId) {
        const pageValidationResult = validateMobileHintsOnPage(ps, pageId)
        if (!_.isEmpty(pageValidationResult)) {
            _.set(validationErrors, pageId, pageValidationResult)
        }
    })
    if (!_.isEmpty(validationErrors)) {
        const e = new ReportableError({
            errorType: 'invalidMobileHints',
            message: 'invalid mobileHints are found',
            extras: validationErrors
        })
        // @ts-expect-error
        e.error = validationErrors
        throw e
    }
}

function initialize() {
    hooks.unregisterHooks([hooks.HOOKS.MOBILE_HINTS.UPDATE_BEFORE])
    hooks.registerHook(hooks.HOOKS.MOBILE_HINTS.UPDATE_BEFORE, runBeforeUpdateValidators)
}

const testAPI = {
    beforeUpdateValidators,
    finalStructureValidators
}

function validateAllMobileHints(ps: PS) {
    const pageIds = _.map(ps.pointers.page.getNonDeletedPagesPointers(true), 'id')
    try {
        validateMobileHintsOnPages(ps, pageIds)
    } catch (error) {
        return error
    }

    return null
}

export default {
    validateComponentMobileHints,
    validateMobileHintsBySchema,
    validateMobileHintsOnPages,
    validateAllMobileHints,
    initialize,
    testAPI
}
