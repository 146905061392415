import type {
    ComponentStageData,
    MobileAlgoPlugin,
    MobileAlgoPluginInitializationArgs,
    PluginHeuristicsRegistry
} from '../../types'

export const createPlugin = ({stages}: MobileAlgoPluginInitializationArgs): MobileAlgoPlugin => {
    // const defaultMargin = 10
    //const specificMargin: Record<string, number> = {'wixui.StylableButton': 20}
    const sizeAndCenter = ({componentStructure}: ComponentStageData) => {
        if (componentStructure.layouts?.itemLayout?.type === 'MeshItemLayout') {
            /* const margin = specificMargin[componentStructure.componentType] ?? defaultMargin
            componentStructure.layouts.itemLayout.left = {value: margin, type: 'px'}
            componentStructure.layouts.itemLayout.right = {value: margin, type: 'px'}
            if (componentStructure.layouts.componentLayout?.width) {
                delete componentStructure.layouts.componentLayout.width
            }
            if (componentStructure.layouts.itemLayout.margins.left) {
                delete componentStructure.layouts.itemLayout.margins.left
            }

            if (componentStructure.layouts.itemLayout.margins.right) {
                delete componentStructure.layouts.itemLayout.margins.left
            }*/
        }
    }
    const register = (registry: PluginHeuristicsRegistry) => {
        registry.register(stages.ADJUST, sizeAndCenter)
    }

    const name = 'sizeAndCenter'
    return {register, name, dependencies: ['loadRefs']}
}
