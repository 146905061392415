import type {PublicMethodUtils} from '@wix/document-services-types'
import {siteSegments, utils} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            /**
             * @class documentServices.siteSegments
             */
            siteSegments: {
                getGaps: publicMethodUtils.defineGetter(siteSegments.getGaps),
                updateGaps: publicMethodUtils.defineDataManipulationAction(siteSegments.updateGaps, {
                    isUpdatingAnchors: utils.NO
                }),
                isUsingSectionsLayout: publicMethodUtils.defineGetter(siteSegments.isUsingSectionsLayout),
                getFooter: publicMethodUtils.defineGetter(siteSegments.getFooter),
                getHeader: publicMethodUtils.defineGetter(siteSegments.getHeader),
                getPagesContainer: publicMethodUtils.defineGetter(siteSegments.getPagesContainer),
                getSiteStructure: publicMethodUtils.defineGetter(siteSegments.getSiteStructure),
                getSiteStructureId: publicMethodUtils.defineGetter(siteSegments.getSiteStructureId)
            }
        }
    }
}
