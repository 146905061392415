import type {
    CreateExtArgs,
    CreateExtensionArgument,
    DeepFunctionMap,
    DmApis,
    Extension,
    ExtensionAPI
} from '@wix/document-manager-core'
import type {CompRef, VariantPointer} from '@wix/document-services-types'

import type {
    BaseStageData,
    ComponentStageData,
    LoadAndStoreComponentStageData,
    MobileAlgoContext,
    Stage,
    StageHandler,
    StructureStageData,
    MobileAlgoPluginFactory,
    MobileAlgoPluginWithContext,
    MobileAlgoPluginInitializationArgs
} from './types'
import type {StructureExtensionAPI} from '../structure'
import type {DataModelExtensionAPI} from '../dataModel/dataModel'
import * as loadablePlugins from './plugins/plugins'
import {createStructureStage} from './stages/structureStage'
import {createComponentStage} from './stages/componentStage'
import {createLoadAndStoreComponentStage} from './stages/loadAndStoreComponentStage'
import {createPluginContextHelper} from './pluginLifeCycle/pluginContextHelper'
import {createPluginHeuristicsRegistry} from './pluginLifeCycle/pluginHeuristicsRegistry'
import {createPluginContext} from './pluginLifeCycle/pluginContext'

export interface MobileAlgoApi extends ExtensionAPI {
    locking: {
        lockComponent(compRef: CompRef): void
        isComponentLocked(compRef: CompRef): boolean
        unlockComponent(compRef: CompRef): void
    }
    context: {
        enable(ctx: MobileAlgoContext, plugins?: string[]): void
        disable(ctx: MobileAlgoContext, plugins?: string[]): void
        create(enabledPlugins?: string[]): MobileAlgoContext
    }
    plugins: {
        register(pluginFactory: MobileAlgoPluginFactory): void
        getApis(ctx: MobileAlgoContext): DeepFunctionMap
    }
    heuristics: HeuristicRegistry
    run(algoContainerPointer: CompRef, ctx: MobileAlgoContext, variants: VariantPointer[]): Promise<void>
}

export interface HeuristicRegistry extends ExtensionAPI {
    register<T extends BaseStageData>(stage: Stage<T>, handler: StageHandler<T>): void
    getStages(): Stages
}
export interface MobileAlgoExtensionApi extends ExtensionAPI {
    mobileAlgo: MobileAlgoApi
}

export interface Stages {
    LOAD: Stage<LoadAndStoreComponentStageData>
    SETUP: Stage<StructureStageData>
    POSITION: Stage<ComponentStageData>
    LOCATION: Stage<ComponentStageData>
    ADJUST: Stage<ComponentStageData>
    STORE: Stage<LoadAndStoreComponentStageData>
}

const createExtension = ({environmentContext, experimentInstance}: CreateExtensionArgument): Extension => {
    const bootPlugins: MobileAlgoPluginFactory[] = [
        loadablePlugins.order,
        loadablePlugins.loadRefs,
        loadablePlugins.storeLayout,
        loadablePlugins.sizeAndCenter,
        loadablePlugins.yMargins
    ]

    const createExtensionAPI = ({pointers, extensionAPI, dal}: CreateExtArgs): MobileAlgoExtensionApi => {
        const structureApi = () => extensionAPI as StructureExtensionAPI
        const dataModelApi = () => extensionAPI as DataModelExtensionAPI

        const stages: Stages = {
            LOAD: createLoadAndStoreComponentStage(),
            SETUP: createStructureStage(),
            POSITION: createComponentStage(),
            LOCATION: createComponentStage(),
            ADJUST: createComponentStage(),
            STORE: createLoadAndStoreComponentStage()
        }

        const pluginRegistry = new Map<string, MobileAlgoPluginWithContext>()

        const getStages = (): Stages => stages

        const filterPlugins = (plugins?: string[]): MobileAlgoPluginWithContext[] => {
            if (!plugins) {
                return Object.values(pluginRegistry)
            }

            return Object.values(pluginRegistry).filter(plugin => plugins.includes(plugin.name))
        }

        const disablePluginsForContext = (ctx: MobileAlgoContext, plugins?: string[]): void => {
            filterPlugins(plugins).forEach(plugin => {
                plugin.contextHelper.disable(ctx)
            })
        }
        const enablePluginsForContext = (ctx: MobileAlgoContext, plugins?: string[]) => {
            filterPlugins(plugins).forEach(plugin => {
                plugin.contextHelper.enable(ctx)
                if (plugin.dependencies) {
                    enablePluginsForContext(ctx, plugin.dependencies)
                }
            })
        }

        const createContext = (enabledPlugins?: string[]): MobileAlgoContext => {
            const ctx = {}
            if (enabledPlugins) {
                disablePluginsForContext(ctx)
                enablePluginsForContext(ctx, enabledPlugins)
            } else {
                enablePluginsForContext(ctx)
            }
            return ctx
        }

        const getPluginApis = (ctx: MobileAlgoContext): DeepFunctionMap => {
            const result: DeepFunctionMap = {}
            Object.values(pluginRegistry).forEach((plugin: MobileAlgoPluginWithContext) => {
                if (plugin.createApi) {
                    const pluginContext = createPluginContext(plugin.contextHelper, ctx)
                    result[plugin.name] = plugin.createApi(pluginContext)
                }
            })
            return result
        }

        const registerPlugin = (pluginFactory: MobileAlgoPluginFactory) => {
            const algoApi = (extensionAPI as MobileAlgoExtensionApi).mobileAlgo
            const initializeArgs: MobileAlgoPluginInitializationArgs = {
                experimentInstance,
                extensionAPI,
                dal,
                stages,
                pointers,
                environmentContext
            }
            const plugin = pluginFactory.createPlugin(initializeArgs)
            const contextHelper = createPluginContextHelper(plugin.name)
            const pluginWithContext = {...plugin, contextHelper}
            const heuristicsRegistry = createPluginHeuristicsRegistry(algoApi.heuristics, contextHelper)
            pluginRegistry[plugin.name] = pluginWithContext
            plugin.register(heuristicsRegistry)
        }

        const registerHeuristic = <T extends BaseStageData>(stage: Stage<T>, handler: StageHandler<T>) => {
            stage.register(handler)
        }

        const runMobileAlgo = async (
            algoContainerPointer: CompRef,
            ctx: MobileAlgoContext,
            variants: VariantPointer[]
        ) => {
            const structure = structureApi().components.getDeepStructure(algoContainerPointer, {
                convertToAbsolute: true,
                resolver: (compPointer, component) => {
                    component.mobileHints = dataModelApi().dataModel.components.getItem(compPointer, 'mobileHints')
                }
            })
            const pageId = pointers.structure.getPageOfComponent(algoContainerPointer).id
            for (const stage of Object.values(stages)) {
                const {run} = stage
                await run(ctx, structure, pageId, variants)
            }
        }

        const lockComponent = (compRef: CompRef): void => {
            const mobileHints = dataModelApi().dataModel.components.getItem(compRef, 'mobileHints') ?? {
                type: 'MobileHints'
            }
            mobileHints.isLocked = true
            dataModelApi().dataModel.components.addItem(compRef, 'mobileHints', mobileHints)
        }

        const unlockComponent = (compRef: CompRef): void => {
            const mobileHints = dataModelApi().dataModel.components.getItem(compRef, 'mobileHints') ?? {}
            mobileHints.isLocked = false
            dataModelApi().dataModel.components.addItem(compRef, 'mobileHints', mobileHints)
        }

        const isComponentLocked = (compRef: CompRef): boolean => {
            return !!dataModelApi().dataModel.components.getItem(compRef, 'mobileHints')?.isLocked
        }

        return {
            mobileAlgo: {
                locking: {
                    lockComponent,
                    unlockComponent,
                    isComponentLocked
                },
                plugins: {
                    register: registerPlugin,
                    getApis: getPluginApis
                },
                context: {
                    create: createContext,
                    enable: enablePluginsForContext,
                    disable: disablePluginsForContext
                },
                heuristics: {
                    getStages,
                    register: registerHeuristic
                },
                run: runMobileAlgo
            }
        }
    }

    return {
        name: 'mobileAlgo',
        initialize: async ({extensionAPI}: DmApis) => {
            const mobileAlgoApi = extensionAPI as MobileAlgoExtensionApi
            bootPlugins.forEach(pluginFactory => {
                mobileAlgoApi.mobileAlgo.plugins.register(pluginFactory)
            })
        },
        createExtensionAPI
    }
}

export {createExtension}
