import _ from 'lodash'

type Structure = any
function getChildrenData(structure: Structure, isMobile: boolean) {
    const children = isMobile ? structure.mobileComponents : structure.children
    return children || structure.components || []
}

function getChildrenKey(data: Structure, isMobile: boolean) {
    if (isMobile && data.mobileComponents) {
        return 'mobileComponents'
    }

    return data.children ? 'children' : 'components'
}

function isMobileStructureExist(masterPageData: {structure: Structure}) {
    return masterPageData.structure.mobileComponents && !_.isEmpty(masterPageData.structure.mobileComponents)
}

function findHierarchyInStructure(id: string, isMobile: boolean, structure: Structure) {
    if (structure.id === id) {
        return [structure]
    }
    const structureChildren = getChildrenData(structure, isMobile)
    if (_.isEmpty(structureChildren)) {
        return []
    }

    let ret = []
    _.forEach(structureChildren, child => {
        const childHierarchy = findHierarchyInStructure(id, isMobile, child)
        if (!_.isEmpty(childHierarchy)) {
            ret = [structure].concat(childHierarchy)
            return false
        }
    })

    return ret
}

function getAllCompsInStructure(
    compStructure: Structure,
    isMobile: boolean,
    filterFunc?: any,
    breakCondition?: boolean
): null | any {
    const queue = [[compStructure]]
    for (let i = 0; i < queue.length; i++) {
        const innerQueue = queue[i]
        for (let childIndex = 0; childIndex < innerQueue.length; childIndex++) {
            if (breakCondition && filterFunc(innerQueue[childIndex])) {
                return innerQueue[childIndex]
            }
            const childrenData = getChildrenData(innerQueue[childIndex], isMobile)
            if (childrenData.length) {
                queue.push(childrenData)
            }
        }
    }

    return breakCondition ? null : _(queue).flatten().filter(filterFunc).keyBy('id').value()
}

function findCompInStructure(compStructure: Structure, isMobile: boolean, predicate: any) {
    return getAllCompsInStructure(compStructure, isMobile, predicate, true)
}

export default {
    getChildrenData,
    getChildrenKey,
    isMobileStructureExist,
    findHierarchyInStructure,
    findCompInStructure,
    getAllCompsInStructure
}
