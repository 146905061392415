import type {PS} from '@wix/document-services-types'
import experiment from 'experiment-amd'
import _ from 'lodash'
import * as mobileConversionUtils from './utils'

const DEFAULT_CONVERSION_CONFIGURATION = {
    conversionType: 'FULL',
    heuristicStrategy: 'default'
}
const HEURISTIC_STRATEGIES_SETTINGS = {
    default: {
        detectSemanticGroups: true,
        fixMasterPageStructureOrder: false
    },
    appbuilder: {
        keepEmptyTextComponents: true,
        keepNotRecommendedMobileComponents: true,
        keepOccludedAndEmptyBackgrounds: true,
        keepOutOfScreenComponents: true,
        keepInvisibleComponents: true
    },
    onboarding: {
        detectSemanticGroups: false
    },
    accessibility: {
        keepNotRecommendedMobileComponents: true,
        detectSemanticGroups: false
    }
}
const CONVERSION_TYPES_SETTINGS = {
    FULL: {},
    MERGE_ALL_PAGES: {
        keepEmptyTextComponents: false,
        keepNotRecommendedMobileComponents: false,
        keepOccludedAndEmptyBackgrounds: true,
        keepOutOfScreenComponents: true,
        fixMasterPageStructureOrder: false
    },
    MERGE_UNHIDE: {
        keepEmptyTextComponents: true,
        keepNotRecommendedMobileComponents: true,
        keepOccludedAndEmptyBackgrounds: true,
        keepOutOfScreenComponents: true,
        keepInvisibleComponents: true,
        fixMasterPageStructureOrder: false
    },
    MERGE_PRESET: {
        keepEmptyTextComponents: true,
        keepNotRecommendedMobileComponents: true,
        keepOccludedAndEmptyBackgrounds: true,
        keepOutOfScreenComponents: true,
        fixMasterPageStructureOrder: false
    },
    MERGE_DISPLAY_MODE: {
        keepEmptyTextComponents: false,
        keepNotRecommendedMobileComponents: true,
        keepOccludedAndEmptyBackgrounds: true,
        keepOutOfScreenComponents: true,
        fixMasterPageStructureOrder: false
    }
}

function getConversionSettings(ps: PS, conversionConfiguration) {
    conversionConfiguration = _.defaults({}, conversionConfiguration, DEFAULT_CONVERSION_CONFIGURATION)
    const mobileAlgoImprovedConfig = mobileConversionUtils.shouldEnableImprovedMergeFlow(ps)
        ? {
              keepEmptyTextComponents: true,
              keepNotRecommendedMobileComponents: true
          }
        : {}

    const config = _.assign(
        {
            applySemanticRules: true,
            reparentMobileSOAP: true,
            keepAnchorsAsDirectChildOfPage: experiment.isOpen('dm_keepAnchorsAsDirectChildOfPage'),
            recalculateBlocksOnGroupingAndUngrouping: experiment.isOpen('dm_recalculateBlocksOnGroupingAndUngrouping'),
            fixTextScaleInMobileAlgo: experiment.isOpen('dm_fixTextScaleInMobileAlgo')
        },
        CONVERSION_TYPES_SETTINGS[conversionConfiguration.conversionType],
        HEURISTIC_STRATEGIES_SETTINGS[conversionConfiguration.heuristicStrategy],
        mobileAlgoImprovedConfig
    )

    return config
}

export default {getConversionSettings}
