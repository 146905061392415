import type {CoreLogger, DAL, DocumentManager} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import type {PageMigrator} from '../dataMigrationRunner'
const MULTILINGUAL_TRANSLATIONS = 'multilingualTranslations'

const removeTypeMismatches = (dal: DAL, translationPtr: Pointer, originalPtr: Pointer, logger: CoreLogger) => {
    const translation = dal.get(translationPtr)
    const original = dal.get(originalPtr)
    if (!original) {
        logger.interactionStarted('translation-without-original', {
            extras: {
                mlKey: translationPtr.id,
                type: translation.type
            }
        })
        return
    }
    if (original.type !== translation.type) {
        dal.remove(translationPtr)
        logger.interactionStarted('fixed-translation-mismatch', {
            extras: {
                mlKey: translationPtr.id,
                type: translation.type,
                originalType: original.type
            }
        })
    }
}

const migratePage = ({dal, pointers, logger}: DocumentManager, pageId: string) => {
    const pageKey = dal.queryFilterGetters.getPageCompFilter(pageId)
    const translations = dal.getIndexPointers(pageKey, MULTILINGUAL_TRANSLATIONS)
    translations.forEach(pointer =>
        removeTypeMismatches(dal, pointer, pointers.multilingualTranslations.getOriginal(pointer), logger)
    )
}

export const mlTypeMismatchFixer: PageMigrator = {
    migratePage,
    name: 'mlTypeMismatchFixer',
    version: 1
}
