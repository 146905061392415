import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import metaDataUtils from '../metaDataUtils'
import appControllerStageData from '../../appControllerData/appControllerStageData'
import dataModel from '../../dataModel/dataModel'
import experiment from 'experiment-amd'

const ALLOWED_CONTAINER_TYPES = [
    'core.components.Page',
    'mobile.core.components.Page',
    'wixapps.integration.components.AppPage',
    'responsive.components.Section',
    'responsive.components.HeaderSection',
    'responsive.components.FooterSection',
    'responsive.components.MembersAreaSection',
    'wysiwyg.viewer.components.PopupContainer',
    'wysiwyg.viewer.components.ClassicSection'
]

const CONTAINER_TYPES_ALLOWED_BY_EXPERIMENT = [
    'mobile.core.components.Container',
    'platform.components.AppWidget',
    'mobui.MobuiContainer'
]

function isContainableByRef(ps: PS, controllerRef: Pointer, containerPointer: Pointer) {
    const controllerData = dataModel.getDataItem(ps, controllerRef)
    const toggleShowOnAllPagesEnabled =
        getControllerStageData(ps, controllerRef.id, controllerData, 'enableShowOnAllPages') !== false &&
        getControllerStageData(ps, controllerRef.id, controllerData, 'toggleShowOnAllPagesEnabled') !== false
    return isContainable(ps, containerPointer, toggleShowOnAllPagesEnabled)
}

function isContainableByStructure(ps: PS, controllerStructure, containerPointer: Pointer) {
    return isContainable(ps, containerPointer, true)
}

function isContainable(ps: PS, containerPointer: Pointer, toggleShowOnAllPagesEnabled: boolean) {
    const potentialContainerCompType = metaDataUtils.getComponentType(ps, containerPointer)
    const showOnAllPages =
        toggleShowOnAllPagesEnabled && metaDataUtils.isHeaderOrFooterOrPageOrMasterPage(potentialContainerCompType)

    return (
        showOnAllPages ||
        _.includes(ALLOWED_CONTAINER_TYPES, potentialContainerCompType) ||
        metaDataUtils.isPopupPageOrPopupContainer(ps, containerPointer) ||
        isAllowedContainerTypeByExperiment(potentialContainerCompType)
    )
}

function isAllowedContainerTypeByExperiment(containerType: string) {
    if (!experiment.isOpen('dm_enableControllerForBlocksContainers')) {
        return false
    }

    return _.includes(CONTAINER_TYPES_ALLOWED_BY_EXPERIMENT, containerType)
}

function isRemovable(ps: PS, controllerRef: Pointer) {
    const controllerData = dataModel.getDataItem(ps, controllerRef)
    return getControllerStageData(ps, controllerRef.id, controllerData, 'removable') !== false
}

function isDuplicatable(ps: PS, controllerRef: Pointer) {
    const controllerData = dataModel.getDataItem(ps, controllerRef)
    return getControllerStageData(ps, controllerRef.id, controllerData, 'duplicatable') !== false
}

function getControllerStageData(ps: PS, controllerId: string, controllerData, path: string) {
    const controllerStageData = appControllerStageData.getControllerStageData(
        ps,
        controllerId,
        controllerData.controllerType,
        controllerData.applicationId
    )
    return _.get(controllerStageData, path)
}

function isVisible(ps: PS, controllerRef: Pointer) {
    const controllerData = dataModel.getDataItem(ps, controllerRef)
    const {applicationId, controllerType} = controllerData
    const appManifestPointer = ps.pointers.platform.getAppManifestPointer(controllerData.applicationId)
    const appManifestExists = ps.dal.isExist(appManifestPointer)
    const controllerStageData = appControllerStageData.getControllerStageData(
        ps,
        controllerRef.id,
        controllerType,
        applicationId
    )
    const showControllersPointer = ps.pointers.general.getRenderFlag('showControllers')
    const showControllers = ps.dal.get(showControllersPointer)
    const componentViewMode = ps.dal.get(ps.pointers.general.getRenderFlag('componentViewMode'))
    return (
        componentViewMode === 'editor' &&
        !ps.siteAPI.isMobileView() &&
        santaCoreUtils.appManifest.controllerStageData.isControllerVisible(
            controllerStageData,
            appManifestExists,
            showControllers
        )
    )
}

export default {
    containable: isContainableByRef,
    containableByStructure: isContainableByStructure,
    canContain: false,
    canContainByStructure: false,
    resizableSides: [],
    alignable: false,
    hiddenable: false,
    collapsible: false,
    fullWidth: false,
    fullWidthByStructure: false,
    canBeFixedPosition: true,
    groupable: false,
    mobileConversionConfig: {
        nonLayoutComponent: true
    },
    removable: isRemovable,
    duplicatable: isDuplicatable,
    crossSiteDuplicatable: false,
    isVisible
}
