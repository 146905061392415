import type {DalItem, ExtensionAPI} from '@wix/document-manager-core'
import {ReportableError} from '@wix/document-manager-utils'
import type {
    BehaviorsList,
    MenuData,
    MetaData,
    Pointer,
    Pointers,
    SingleLayoutData,
    WixCodeConnectionItem
} from '@wix/document-services-types'
import _ from 'lodash'
import unset from 'lodash/fp/unset'
import {COMP_DATA_QUERY_KEYS_WITH_STYLE, DATA_TYPES, MASTER_PAGE_ID, VIEW_MODES} from '../../constants/constants'
import {getIdFromRef} from '../../utils/dataUtils'
import {getBaseComponentIdFromRefferredId} from '../../utils/refStructureUtils'
import type {DataModelExtensionAPI} from '../dataModel/dataModel'
import type {NicknamesExtensionAPI} from '../nicknames'
import type {SchemaExtensionAPI} from '../schema/schema'
import type {ComponentsAPI as StructureAPI} from '../structure'

export type CompData = Record<string, any> & {
    type: string
    appDefinitionId?: string
    pageUriSEO?: string
    rootCompId?: string
}

export type Comp = Record<string, any> & {
    id: string
    componentType?: string
    type?: string
    comps?: Comp[]
}

export interface DataIn {
    type: 'Page'
    id: string
    title: string
    hideTitle: boolean
    icon: string
    descriptionSEO: string
    metaKeywordsSEO: string
    pageTitleSEO: string
    pageUriSEO: string
    hidePage: boolean
    isMobileLandingPage: boolean
    underConstruction: boolean
    tpaApplicationId: number
    pageSecurity: {
        requireLogin: boolean
    }
    isPopup: boolean
    indexable: boolean
    isLandingPage: boolean
    pageBackgrounds: {
        desktop: {
            custom: boolean
            ref: {
                type: 'BackgroundMedia'
                id: string
                color: string
                alignType: 'top'
                fittingType: 'fill'
                scrollType: 'fixed'
            }
            isPreset: boolean
        }
        mobile: {
            custom: boolean
            ref: {
                type: 'BackgroundMedia'
                id: 'customBgImg24ta'
                color: '{color_11}'
                alignType: 'top'
                fittingType: 'fill'
                scrollType: 'fixed'
                colorOverlay: ''
                colorOverlayOpacity: 0
            }
            isPreset: boolean
            mediaSizing: 'viewport'
        }
    }
    translationData: {
        uriSEOTranslated: boolean
    }
    ignoreBottomBottomAnchors: boolean
    ogImage: string
    scopedData?: ScopedDataIn[]
}

export interface ScopedDataCrop {
    x: string
    y: string
    width: string
    height: number
    type?: string
    id?: string
    metaData?: MetaData
    svgId?: string
    rotate?: number
    flip?: 'x' | 'y' | 'xy' | 'none'
}

export interface ScopedDataFocalPoint {
    x: number
    y: number
    type?: string
    id?: string
    metaData?: MetaData
}

export interface ScopedDataIn {
    breakpoint?: string
    displayMode?: 'fill' | 'fit' | 'fitWidth'
    scrollEffect?: 'parallax' | 'fixed' | 'zoomIn' | 'fadeIn' | 'none'
    clickAction?: 'none' | 'goToLink'
    crop?: ScopedDataCrop
    focalPoint?: ScopedDataFocalPoint
}
export interface Layout {
    width: number
    height: number
    x: number
    y: number
    scale: number
    rotationInDegrees: number
    fixedPosition: boolean
}

export interface RefArrayLayout {
    type: 'RefArray'
    id: string
    values: SingleLayoutData[]
}

export interface FlatPage {
    id: string
    type: 'Page'
    componentType: string
    skin: string
    nickname: string
    design: Record<string, any>
    layout: Layout | RefArrayLayout
    components: string[]
    behaviors: BehaviorsList
    variants: Record<string, any>
    data: DataIn
    mobileHints: Record<string, any>
    props: Record<string, any>
    style: Record<string, any>
}

export type CompOp = (comp: FlatComp) => FlatComp

export type FlatComp = Record<string, any> & {
    id: string
    componentType?: string
    nickname: string
    type?: string
    data: CompData
    layout?: Record<string, any>
    style?: Record<string, any>
    design?: Record<string, any>
    props?: Record<string, any>
}

export interface FlatPageExport {
    page: FlatPage
    components: Record<string, FlatComp>
}

export interface Page {
    type: 'Page'
    id: string
    title: string
    hideTitle: boolean
    icon: string
    descriptionSEO: string
    metaKeywordsSEO: string
    pageTitleSEO: string
    pageUriSEO: string
    hidePage: boolean
    isMobileLandingPage: boolean
    underConstruction: boolean
    tpaApplicationId: number
    pageSecurity: {
        requireLogin: boolean
    }
    isPopup: boolean
    indexable: boolean
    isLandingPage: boolean
    pageBackgrounds: {
        desktop: {
            custom: boolean
            ref: {
                type: 'BackgroundMedia'
                id: string
                color: string
                alignType: 'top'
                fittingType: 'fill'
                scrollType: 'fixed'
            }
            isPreset: boolean
        }
        mobile: {
            custom: boolean
            ref: {
                type: 'BackgroundMedia'
                id: 'customBgImg24ta'
                color: '{color_11}'
                alignType: 'top'
                fittingType: 'fill'
                scrollType: 'fixed'
                colorOverlay: ''
                colorOverlayOpacity: 0
            }
            isPreset: boolean
            mediaSizing: 'viewport'
        }
    }
    translationData: {
        uriSEOTranslated: boolean
    }
    ignoreBottomBottomAnchors: boolean
    ogImage: string
    skin: string
    nickname: string
    componentType: string
    comps: Comp[]
    components: string[]
    variants: Record<string, any>
}

export type CompToNSItem = Record<string, Record<string, any>>

export interface OtherData {
    style?: CompToNSItem
    design?: CompToNSItem
    behaviors?: CompToNSItem
    variants?: CompToNSItem
    mobileHints?: CompToNSItem
    props?: CompToNSItem
    data?: CompToNSItem
}

export interface CustomMenuExportData extends Omit<MenuData, 'id'> {}

export interface SiteSettings {
    mainPage: string
}

export interface SiteExport {
    pages: string[]
    appState: Record<string, string>
    menus: Record<string, CustomMenuExportData>
    settings: SiteSettings
}

export const errorTypes = {
    INVALID_PAGE_REFERENCE: 'InvalidPageReference',
    IMPORT_MENU_VALIDATION_ERROR: 'ImportMenuValidationError',
    INVALID_COMPONENT: 'InvalidComponentData',
    INVALID_PAGE: 'InvalidImportedPageData'
}

const DATA_TYPE_WITH_SCOPED_DATA = ['ImageX']

const _replaceAllStrings = <T>(obj: T, replacer: (s: string, key: string | null) => string, key: string | null): T => {
    if (_.isPlainObject(obj)) {
        return _.mapValues(obj as Record<string, any>, (v, k) => _replaceAllStrings(v, replacer, k)) as T
    }
    if (_.isArray(obj)) {
        return obj.map(v => _replaceAllStrings(v, replacer, null)) as T
    }
    if (_.isString(obj)) {
        return replacer(obj, key) as T
    }
    return obj
}

export const replaceAllStrings = <T>(obj: T, replacer: (s: string, key: string | null) => string): T => {
    return _replaceAllStrings(obj, replacer, null)
}

export const mapPageExport =
    (f: CompOp) =>
    ({page, components}: FlatPageExport): FlatPageExport => ({
        page: f(page) as FlatPage,
        components: _.mapValues(components, f)
    })

export const mapPageExportWithKeys =
    (f: (c: FlatComp, key: string) => FlatComp) =>
    ({page, components}: FlatPageExport, pageId: string): FlatPageExport => ({
        page: _.omit(f(page, pageId), ['parent']) as FlatPage,
        components: _.mapValues(components, f)
    })

const sanitizeTPAData: CompOp = unset(['data', 'applicationId'])

const normalizeConnections: CompOp = comp => {
    const items = _.get(comp, ['connections', 'items'])
    const overrideItems = _.get(comp, ['connections', 'refOverrides'])

    if (!items && !overrideItems) {
        return comp
    }

    const normalizedOverrides = _.flatMap(overrideItems ?? [], (item: DalItem) => {
        if (item.type === 'ConnectionList') {
            // we collect nickname in getNickname specifically, so we don't need to collect it here to avoid duplication
            return _.reject(item.items, (i: WixCodeConnectionItem) => i.type === 'WixCodeConnectionItem')
        }
        return item
    })

    return {
        ...comp,
        connections: [...(items ?? []), ...normalizedOverrides]
    }
}

export const compToPointer = (comp: Comp): Pointer => {
    if (!comp.id) {
        throw new ReportableError({
            errorType: errorTypes.INVALID_COMPONENT,
            message: 'Cannot create pointer without an id'
        })
    }
    return {id: comp.id, type: VIEW_MODES.DESKTOP}
}

export const getMinMaxFromBreakpointKey = (breakpointKey: string): {min: number; max: number} => {
    const [min, max] = breakpointKey.split('-')
    return {min: +min, max: +max}
}

const getExtendedProtoComp =
    (pointers: Pointers, extensionApi: ExtensionAPI) =>
    (comp: Comp): Comp => {
        const pointer = compToPointer(comp)
        const {getItem: getCompItem} = (extensionApi as DataModelExtensionAPI).dataModel.components
        const {removeWhitelistedProperties} = (extensionApi as SchemaExtensionAPI).schemaAPI

        const getMinMaxKeyIfNeeded = (breakpointRef: string, pageId: string): null | string => {
            const id = getIdFromRef(breakpointRef)
            const breakpointRange = (extensionApi as DataModelExtensionAPI).dataModel.getItem(
                id,
                DATA_TYPES.variants,
                pageId ?? MASTER_PAGE_ID
            )
            if (!breakpointRange || breakpointRange.type !== 'BreakpointRange') return null
            return `${breakpointRange.min}-${breakpointRange.max}`
        }

        const cleanSchemaRefs = (item: DalItem, ns: string): DalItem => {
            const _item = _.cloneDeep(item)
            if (!_item) {
                return _item
            }
            const references = (extensionApi as SchemaExtensionAPI).schemaAPI.getReferences(ns, _item)

            references.forEach(ref => {
                const {refInfo} = ref
                const reference = _.get(_item, refInfo.path)
                // removes # for referenced id
                const refId = getIdFromRef(reference)

                _.set(_item, refInfo.path, refId)
            })

            // dataModel.getItem returns extended data items with opened refs
            // go over the item object keys and clean the refs
            return _.mapValues(_item, childItem => {
                if (_.isPlainObject(childItem) && !_.isEmpty(childItem)) {
                    return cleanSchemaRefs(childItem, ns)
                }

                return childItem
            })
        }

        const items = _.mapValues(COMP_DATA_QUERY_KEYS_WITH_STYLE, (v, ns) => {
            const originalItem = getCompItem(pointer, ns)

            removeWhitelistedProperties(ns, originalItem, true)
            const item = cleanSchemaRefs(originalItem, ns)
            let refOverrides

            // replace breakpoint reference in scopedData to min-max key
            if (
                ns === DATA_TYPES.data &&
                item?.type &&
                item?.scopedData &&
                DATA_TYPE_WITH_SCOPED_DATA.includes(item?.type)
            ) {
                item.scopedData = _.map(item.scopedData, scopedData => {
                    if (scopedData?.breakpoint) {
                        return {
                            ...scopedData,
                            breakpoint: getMinMaxKeyIfNeeded(scopedData.breakpoint, comp.metaData?.pageId)
                        }
                    }
                    return scopedData
                })
            }

            if (item?.type === 'RefArray' && item?.values) {
                // replace breakpoint id reference with min-max key
                item.values = _.map(item.values, refItem => {
                    if (refItem?.type === 'VariantRelation' && refItem?.variants) {
                        refItem.variants = _.map(refItem.variants, variant => {
                            const key = getMinMaxKeyIfNeeded(variant, comp.metaData?.pageId)
                            // in case this is not breakpoint, leave as it was
                            if (!key) {
                                return variant
                            }

                            return key
                        })
                    }
                    return refItem
                })
            }

            if (comp.type === 'RefComponent') {
                const currentMapOverridesPointers = pointers.referredStructure.getOverridesByType(pointer, ns)
                const currentMapOverrides = currentMapOverridesPointers.map(overridePtr =>
                    (extensionApi as DataModelExtensionAPI).dataModel.getItem(
                        overridePtr.id,
                        ns,
                        overridePtr.pageId || comp.metaData?.pageId || 'masterPage'
                    )
                )

                if (currentMapOverrides?.length > 0) {
                    refOverrides = currentMapOverrides
                }
            }

            // we do not want to set refOverrides = undefined
            // there is a possibility that item has overrides but do not have data for itself
            return refOverrides ? {refOverrides, ...(item ?? {})} : item
        })

        return {
            ...comp,
            ...items,
            layout: items.layout ?? comp.layout,
            pageId: comp.metaData?.pageId
        }
    }

export const isPage =
    (pointers: Pointers) =>
    (comp: Comp): boolean =>
        !!pointers.structure.isPage(compToPointer(comp))

const getNickname = (pointers: Pointers, extensionApi: ExtensionAPI, comp: Comp): string | null => {
    if (isPage(pointers)(comp)) {
        return comp.data.pageUriSEO!
    }
    const pointer = compToPointer(comp)
    const nicknames = (extensionApi as NicknamesExtensionAPI).nicknames.getComponentNickname(pointer)
    let inflatedKey: string | undefined = pointer.id
    if (comp.type === 'RefComponent') {
        // getComponentNickname do not provide nickname for InternalRefs, since they technically do not have one
        // we take nickname from his root component instead
        if (comp.data.type === 'InternalRef') {
            // search by rootCompId and get his nickname
            const rootCompPointer = compToPointer({id: comp.data.rootCompId} as any)
            return getNickname(pointers, extensionApi, rootCompPointer as any)
        }
        inflatedKey = Object.keys(nicknames).find(key => getBaseComponentIdFromRefferredId(key) === pointer.id)
    }

    if (!inflatedKey) {
        return null
    }

    return nicknames?.[inflatedKey] ?? null
}

const insertNickname =
    (pointers: Pointers, extensionApi: ExtensionAPI) =>
    (comp: Comp): Comp => {
        const nick = getNickname(pointers, extensionApi, comp)

        if (nick) {
            return {...comp, nickname: nick}
        }

        return comp
    }

const getAllDesktopComps = (extensionApi: ExtensionAPI): Record<string, FlatComp> =>
    (extensionApi.components as StructureAPI).getAllDesktopComponents()
const normalizeComp = (extensionApi: ExtensionAPI, pointers: Pointers): ((comp: FlatComp) => FlatComp) =>
    _.flow([
        getExtendedProtoComp(pointers, extensionApi),
        // to here object, ConnectionsList, than we flatten it to contain only items (miss ref id here...)
        normalizeConnections,
        insertNickname(pointers, extensionApi),
        sanitizeTPAData
    ])

const getComps = (extensionApi: ExtensionAPI, pointers: Pointers, filter: (c: FlatComp) => boolean): FlatComp[] =>
    _(getAllDesktopComps(extensionApi)).values().filter(filter).map(normalizeComp(extensionApi, pointers)).value()

export const getCompsInPage = (pageId: string, extensionApi: ExtensionAPI, pointers: Pointers): FlatComp[] =>
    getComps(extensionApi, pointers, comp => comp.metaData.pageId === pageId)

export const collectIdsToPropertyMap = <T extends any[]>(comps: T, property: string): Record<string, string> =>
    _(comps)
        .map(c => [c.id, c[property]])
        .fromPairs()
        .omitBy(_.isNil)
        .value()

export const collectIdsToNicknamesMap = (comps: FlatComp[]): Record<string, string> =>
    collectIdsToPropertyMap<FlatComp[]>(comps, 'nickname')

const getAllPages = (extensionApi: ExtensionAPI, pointers: Pointers): FlatComp[] =>
    getComps(extensionApi, pointers, isPage(pointers))

export const getAllPageNicknames = (extensionApi: ExtensionAPI, pointers: Pointers): Record<string, string> =>
    collectIdsToNicknamesMap(getAllPages(extensionApi, pointers))
