import type {SaveTaskDefinition} from '../../saveAPI/lib/registry'

const TASK_NAME = 'reloadSiteProperyInfoAfterFirstSave'

const createTask = (): SaveTaskDefinition => {
    return {
        partialSave(lastSavedData, currentData, resolve) {
            resolve()
        },
        fullSave(lastSavedData, currentData, resolve) {
            resolve()
        },
        saveAsTemplate(lastSavedData, currentData, resolve) {
            resolve()
        },
        publish(currentData, extensionAPI, resolve) {
            resolve(undefined)
        },
        getTaskName() {
            return TASK_NAME
        },
        getSnapshotTags() {
            return ['primary']
        }
    }
}

export default () => createTask()
