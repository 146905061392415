import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import constants from '../constants/constants'
import namespaces from '../namespaces/namespaces'
import component from '../component/component'
import structure from '../structure/structure'
import hooks from '../hooks/hooks'
import refComponent from '../refComponent/refComponent'
import experiment from 'experiment-amd'
import type {CompRef, Pointer, PS} from '@wix/document-services-types'

const {getRootRefHostCompPointer} = refComponent
const SLOTS_NAMESPACE = constants.DATA_TYPES.slots
const {isRefPointer} = santaCoreUtils.displayedOnlyStructureUtil

/**
 * Gets the component definition for the comp pointer
 *
 * @param {ps} ps
 * @param {Pointer} compPointer
 * @returns {object} The component's definition
 */
const removeFromQuery = (ps: PS, slottedCompPointer: Pointer, slotName: string): void =>
    ps.extensionAPI.slots.removeFromQuery(slottedCompPointer, slotName)

const verifySlotName = (ps: PS, compWithSlot: Pointer, slotName: string) =>
    ps.extensionAPI.slots.verifySlotName(compWithSlot, slotName)

const getOwnerOfComponentInSlot = (ps: PS, slottedComponent: Pointer): CompRef =>
    (isRefPointer(slottedComponent) ? getRootRefHostCompPointer(ps, slottedComponent) : slottedComponent) as CompRef

/**
 * Verifies that we can populate a slot with a given comp definition
 *
 * @param {ps} ps
 * @param {String} slotName
 * @param {Pointer} slottedComponent
 */
const verifyCanPopulate = (ps: PS, slotName: string, slottedComponent: Pointer): void => {
    verifySlotName(ps, slottedComponent, slotName)

    const currentSlots = getSlotNames(ps, slottedComponent)
    if (currentSlots.includes(slotName)) {
        throw new Error(`Slot "${slotName}" already exists`)
    }
}

const findSlotByValue = (ps: PS, parentPointer: Pointer, compPointer: Pointer): string => {
    const slotsData = getSlotsData(ps, parentPointer)
    return _.keys(slotsData).find(key => slotsData[key].id === compPointer.id)
}

const removeFromSlot = (ps: PS, compToAddPointer: Pointer): void => {
    const parentPointer = ps.pointers.components.getParent(compToAddPointer)
    const originalSlotName = findSlotByValue(ps, parentPointer, compToAddPointer)

    if (originalSlotName) {
        removeFromQuery(ps, parentPointer, originalSlotName)
    }
}

const updateSlot = (ps: PS, slottedComponent: Pointer, slotName: string, compToAddPointer: Pointer): string => {
    verifyCanPopulate(ps, slotName, slottedComponent)
    const {slotsDataType} = ps.extensionAPI.slots.getCompDefinition(slottedComponent)
    const updatedData = namespaces.getNamespaceData(ps, slottedComponent, SLOTS_NAMESPACE)

    const pageId = ps.pointers.full.components.getPageOfComponent(compToAddPointer).id
    if (updatedData?.slots) {
        updatedData.slots[slotName] = compToAddPointer.id
        return namespaces.updateNamespaceData(ps, slottedComponent, SLOTS_NAMESPACE, updatedData, pageId)
    }
    return namespaces.updateNamespaceData(
        ps,
        slottedComponent,
        SLOTS_NAMESPACE,
        {
            type: slotsDataType,
            slots: {[slotName]: compToAddPointer.id}
        },
        pageId
    )
}

const getComponentToAddPointer = (ps: PS, componentWithSlots: Pointer): Pointer => {
    const ownerOfComponentInSlot = getOwnerOfComponentInSlot(ps, componentWithSlots)
    return component.getComponentToAddRef(ps, ownerOfComponentInSlot)
}

/**
 * Populates a specified `slotName` with the slot definition
 *
 * @param {ps} ps
 * @param {Pointer} componentToAddPointer The component to add to the slot
 * @param {Pointer} componentWithSlots The component that has the slots (should have a slotsDataType in the component definition)
 * @param {string} slotName The name of the slot to add to
 * @param {object} componentDefinition The component definition (similar as with ds.components.add())
 */
const populate = (
    ps: PS,
    componentToAddPointer: Pointer,
    componentWithSlots: Pointer,
    slotName: string,
    componentDefinition: any
): Pointer => {
    ps.extensionAPI.logger.interactionStarted('populate_slot', {
        extras: {componentDefinition}
    })
    const ownerOfComponentInSlot = getOwnerOfComponentInSlot(ps, componentWithSlots)
    component.addComponentInternal(ps, componentToAddPointer, ownerOfComponentInSlot, componentDefinition)
    const slotDataId = updateSlot(ps, componentWithSlots, slotName, componentToAddPointer)
    hooks.executeHook(hooks.HOOKS.SLOTS.AFTER_POPULATE, null, [
        ps,
        ownerOfComponentInSlot,
        componentWithSlots,
        componentToAddPointer,
        slotDataId
    ])
    ps.extensionAPI.logger.interactionEnded('populate_slot', {
        extras: {slotDataId, componentToAddPointer, componentDefinition: component.serialize(ps, componentToAddPointer)}
    })
    return componentToAddPointer
}

/**
 * Moves a component to another component's slot
 *
 * @param {ps} ps
 * @param {Pointer} componentWithSlots The component with slot
 * @param {string} slotName The slot name to move to.
 * @param {Pointer} compToMove The component to be placed in the slot
 */
const moveToSlot = (ps: PS, componentWithSlots: CompRef, slotName: string, compToMove: CompRef): CompRef => {
    if (compToMove.type === constants.VIEW_MODES.MOBILE) {
        throw new Error('Only DESKTOP components can move into or between slots')
    }

    const oldParent = ps.pointers.components.getParent(compToMove)
    const ownerOfComponentInSlot = getOwnerOfComponentInSlot(ps, componentWithSlots)

    removeFromSlot(ps, compToMove)
    updateSlot(ps, componentWithSlots, slotName, compToMove)
    structure.addCompToContainer(ps, compToMove, ownerOfComponentInSlot)

    hooks.executeHook(hooks.HOOKS.SLOTS.AFTER_MOVE, null, [ps, componentWithSlots, compToMove, oldParent])
    return compToMove
}

/**
 * Returns all the components' slot names
 */
const getSlotNames = (ps: PS, componentPointer: Pointer): string[] => _.keys(getSlotsData(ps, componentPointer))

/**
 * Removes a slot from a component
 */
const remove = (ps: PS, slottedCompPointer: Pointer, slotName: string): void => {
    removeInternal(ps, slottedCompPointer, slotName, false)
}

const removeInternal = (
    ps: PS,
    slottedCompPointer: Pointer,
    slotName: string,
    invokedFromHook: boolean = false
): void => {
    if (experiment.isOpen('dm_useSlotsExt')) {
        ps.extensionAPI.slots.remove(slottedCompPointer, slotName, invokedFromHook)
    } else {
        ps.extensionAPI.slots.verifySlotName(slottedCompPointer, slotName)
        const compPointer = _.get(getSlotsData(ps, slottedCompPointer), slotName)
        if (!compPointer) {
            return
        }

        if (ps.dal.full.isExist(compPointer) && !invokedFromHook) {
            component.remove(ps, compPointer)
        }

        removeFromQuery(ps, slottedCompPointer, slotName)
    }
}

/**
 * Retrieves the slots data of a given slotted component
 *
 * @param {ps} ps
 * @param {Pointer} slottedComponentPointer
 * @returns {object} Slot names and their component pointers
 */
const getSlotsData = (ps: PS, slottedComponentPointer: Pointer): any =>
    ps.extensionAPI.slots.getSlotsData(slottedComponentPointer)

const isChildOfSlottedComp = (ps: PS, compPointer: Pointer): boolean => {
    return ps.extensionAPI.slots.isChildOfSlottedComp(compPointer.id)
}
const getWidgetSlots = (ps: PS, widgetRef: Pointer) => ps.extensionAPI.slots.getWidgetSlots(widgetRef)

export default {
    getComponentToAddPointer,
    populate,
    moveToSlot,
    remove,
    removeInternal,
    getSlotNames,
    getSlotsData,
    isChildOfSlottedComp,
    getWidgetSlots
}
