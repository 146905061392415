import biErrors from './bi/errors'
import fedopsLogger from './logger/fedopsLogger'
import logger from './logger/logger'
import performance from './logger/performance'
import newrelic from './logger/newrelic'
import performanceMetrics from './logger/performanceMetrics'
import browsingSession from './logger/services/browsingSession'

export const loggingUtils = {
    bi: {
        errors: biErrors
    },
    fedopsLogger,
    logger,
    performance,
    newrelic,
    performanceMetrics,
    browsingSession
}
