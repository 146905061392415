import _ from 'lodash'
import * as analyzerUtils from './analyzerUtils'
import * as conversionUtils from '../conversionUtils'
import {conversionConfig} from '../conversionConfig'
import * as blocksUtils from '../utils/blocksUtils'
import type {ConversionSettings, DeepStructure, TextAlignment} from '../../types'

function analyzeYMargin(comps) {
    const siblingsCount = _.size(comps) - 1
    _.forEach(comps, comp => {
        const children = conversionUtils.getChildren(comp)
        comp.conversionData.hasTightYMargin =
            comp.conversionData.isTightContainer === true ||
            _.isEmpty(children) ||
            (comp.conversionData.isTransparentContainer === true && (siblingsCount === 0 || !conversionUtils.isColumnComponent(comp)))
    })
}

function analyzeTopMargin(parent: DeepStructure, children: DeepStructure[]): void {
    _.forEach(children, (comp, compIndex) => {
        if (comp.conversionData.isTransparentContainer) {
            comp = _.head(<DeepStructure[]>comp.components) || comp
        }
        const previousComp = children[compIndex - 1]
        const hasPrevious = Boolean(previousComp)
        const hasTightLayoutWithPrevious =
            hasPrevious && previousComp.layout.y + previousComp.layout.height === comp.layout.y && previousComp.layout.x === comp.layout.x

        const shouldBeTightAccordingToParent = blocksUtils.shouldBeTightAccordingToParent(parent as DeepStructure)

        comp.conversionData.tightWithPreviousSibling = shouldBeTightAccordingToParent || (hasPrevious && hasTightLayoutWithPrevious)
    })
}

function analyzeBottomMargin(parent: DeepStructure, children: DeepStructure[]): void {
    parent.conversionData.hasTightBottomMargin = _.some(children, child => {
        const comp =
            _.get(child, ['conversionData', 'isTransparentContainer']) && !_.isEmpty(child.components) ? <DeepStructure>_.head(child.components) : child

        return conversionUtils.shouldStretchToScreenWidth(comp) && comp.layout.y + comp.layout.height >= parent.layout.height
    })
}

function analyzeTextAlignments(parent: DeepStructure, children: DeepStructure[]): void {
    const textAlignments = _(children)
        .map(comp => {
            const alignments = comp.conversionData.textAlignments || []
            return _(alignments).uniq().size() > 1 ? 'mixed' : _.head(alignments)
        })
        .compact()
        .uniq()
        .value()
    if (_.size(textAlignments) === 1) {
        parent.conversionData.naturalAlignment = <TextAlignment>_.head(textAlignments)
    }
}

export function analyze(parent: DeepStructure, orderedComps: DeepStructure[], settings?: ConversionSettings): void {
    analyzeTopMargin(parent, orderedComps)
    analyzeBottomMargin(parent, orderedComps)
    analyzeYMargin(orderedComps)
    if (!settings?.enableImprovedMergeFlow) {
        analyzeTextAlignments(parent, orderedComps)
    }
}

const handleAppWidget = (comp: DeepStructure) => {
    _.assign(comp.conversionData, _.pick(_.get(comp, ['components', 0, 'conversionData'], {}), 'fixedSize'))
    _.assign(
        comp.conversionData.mobileHints,
        _.pick(_.get(comp, ['components', 0, 'conversionData', 'mobileHints'], {}), ['recommendedWidth', 'recommendedHeight'])
    )
}

const handleBoxContainer = (comp: DeepStructure) => {
    _.assign(comp.conversionData, {
        rescaleMethod: 'none',
        fixedSize: {width: comp.layout.width}
    })
}

export function analyzeGroupedComponents(
    comps: DeepStructure[],
    allowedWidth: number = conversionConfig.MOBILE_WIDTH,
    settings: ConversionSettings = {}
): void {
    const allowedWidthWithoutMargin = allowedWidth - 2 * conversionConfig.ROOT_COMPONENT_MARGIN_Y
    _.forEach(comps, comp => {
        if (conversionUtils.isAppWidgetComponent(comp)) {
            handleAppWidget(comp)
        }

        if (analyzerUtils.hasMobileHintsPresets(comp)) {
            return
        }

        if (conversionUtils.isBoxContainerComponent(comp)) {
            if (analyzerUtils.isSmallerThenAllowedWidth(comp, allowedWidthWithoutMargin)) {
                handleBoxContainer(comp)
            }
        }

        analyzeGroupedComponents(
            <DeepStructure[]>conversionUtils.getChildren(comp),
            conversionUtils.shouldStretchToScreenWidth(comp) ? allowedWidth : allowedWidthWithoutMargin,
            settings
        )
    })
}
