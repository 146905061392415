import _ from 'lodash'
import type {CreateExtArgs} from '@wix/document-manager-core'
import type {CompRef, Pointer} from '@wix/document-services-types'
import {getComponentType} from '../../../utils/dalUtils'
import {isComponentSuitableForNonRenderingState, isContainerSuitableForNonRenderingState} from '../utils/componentUtils'
import type {ComponentDefinitionExtensionAPI} from '../../componentDefinition'

const isCompTypeAllowedInHoverbox = (createExtArgs: CreateExtArgs, compType: string) => {
    const {componentDefinition} = createExtArgs.extensionAPI as ComponentDefinitionExtensionAPI
    return (
        isContainerSuitableForNonRenderingState(compType) ||
        (!componentDefinition.isContainer(compType) && isComponentSuitableForNonRenderingState(compType))
    )
}

const allChildrenAllowedInHoverBox = (createExtArgs: CreateExtArgs, potentialChild: CompRef) => {
    const recusiveChildrenPointers = createExtArgs.pointers.structure.getChildrenRecursively(potentialChild)
    return _.every(recusiveChildrenPointers.concat(potentialChild), function (componentPointer) {
        const compType = getComponentType(createExtArgs.dal, componentPointer)
        return isCompTypeAllowedInHoverbox(createExtArgs, compType)
    })
}

export const hoverboxMetaData = {
    isRepeatable: false,
    canContain: (createExtArgs: CreateExtArgs, componentPointer: Pointer, potentialChild: CompRef) =>
        allChildrenAllowedInHoverBox(createExtArgs, potentialChild)
}
