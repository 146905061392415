import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import dsUtils from '../utils/utils'
import componentDetectorAPI from '../componentDetectorAPI/componentDetectorAPI'
import constants from './common/constants'
import {ReportableError} from '@wix/document-manager-utils'

const sessionStateTypes = new Set(constants.SESSION_STATE_TYPES)

function updateSessionState(ps: PS, stateMap) {
    _.forEach(stateMap, (state, compId) => {
        const compRef = componentDetectorAPI.getComponentById(ps, compId)
        const compType = dsUtils.getComponentType(ps, compRef)
        if (!sessionStateTypes.has(compType)) {
            throw new Error(`Component of type ${compType} does not support session state`)
        }

        if (compId) {
            ps.dal.full.set(ps.pointers.getInnerPointer(ps.pointers.platform.getAppStatePointer(), [compId]), state)
        } else {
            ps.logger.captureError(
                new ReportableError({
                    errorType: 'SetStateError',
                    message: 'missing compId',
                    extras: {compRef, compType, state}
                })
            )
        }
    })
}

function getSessionState(ps: PS, compRef: Pointer) {
    return ps.dal.full.get(ps.pointers.getInnerPointer(ps.pointers.platform.getAppStatePointer(), [compRef.id]))
}

export default {
    getSessionState,
    updateSessionState
}
