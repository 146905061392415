export const loadScript = (src: string, name: string, async: boolean = false): Promise<unknown> =>
    new Promise<void>(resolve => {
        const element = document.createElement('script')
        element.async = async //make sure the scripts execute in the order they are added
        element.src = src
        element.onload = () => resolve()
        document.head.appendChild(element)
    }).catch(err => {
        console.log(`Failed to load script ${name}: ${err.message}`)
    })

export const loadStyle = (href: string, name: string): Promise<unknown> =>
    new Promise<void>(resolve => {
        const element = document.createElement('link')
        element.rel = 'stylesheet'
        element.href = href
        element.onload = () => resolve()
        document.head.appendChild(element)
    }).catch(err => {
        console.log(`Failed to load script ${name}: ${err.message}`)
    })
