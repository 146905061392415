import type {AppDefinitionId, CompRef, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import appControllerData from '../../../appControllerData/appControllerData'
import component from '../../../component/component'
import dataModel from '../../../dataModel/dataModel'
import platform from '../../../platform/platform'
import {removeIfExists} from '../../../utils/dalUtil'

function removePlatformApplicationFromPageIfLastController(ps: PS, controllerRef: Pointer) {
    const controllerPageRef = component.getPage(ps, controllerRef as CompRef)
    const controllerApplicationId = dataModel.getDataItem(ps, controllerRef).applicationId
    const appControllersInPage = ps.pointers.data.getDataItemsWithPredicate(
        {applicationId: controllerApplicationId},
        controllerPageRef.id
    )
    platform.updatePagePlatformApp(ps, controllerPageRef, controllerApplicationId, appControllersInPage.length > 1)
}

function removeControllerFromStateMap(ps: PS, controllerRef: Pointer) {
    const controllerStatePointer = ps.pointers.platform.getControllerStatePointer(controllerRef.id)
    removeIfExists(ps, controllerStatePointer)
}

function addControllerStateToSerializedStructure(ps: PS, controllerRef: Pointer, customStructureData) {
    const controllerState = ps.dal.get(ps.pointers.platform.getControllerStatePointer(controllerRef.id))
    if (typeof controllerState !== 'undefined') {
        customStructureData.state = controllerState
    }
}

function setControllerStateFromSerializedStructure(ps: PS, controllerRef: Pointer, controllerDefinition) {
    if (_.has(controllerDefinition, 'custom.state')) {
        appControllerData.setState(ps, _.set({}, _.get(controllerDefinition, 'custom.state'), [controllerRef]))
    }
}

function addPlatformAppToPageIfFirstController(
    ps: PS,
    controllerRef: Pointer,
    appDefinitionId: AppDefinitionId,
    controllerPageRef?: Pointer
) {
    controllerPageRef = controllerPageRef || component.getPage(ps, controllerRef as CompRef)
    platform.updatePagePlatformApp(ps, controllerPageRef, appDefinitionId, true)
}

export default {
    removePlatformApplicationFromPageIfLastController,
    removeControllerFromStateMap,
    addControllerStateToSerializedStructure,
    setControllerStateFromSerializedStructure,
    addPlatformAppToPageIfFirstController
}
