import type {ExtensionAPI} from '@wix/document-manager-core'
import type {QueryParams} from '@wix/document-manager-utils'
import type {AjaxOp} from '@wix/santa-ds-libs' // eslint-disable-next-line no-duplicate-imports
import {coreUtils} from '@wix/santa-ds-libs'
const {urlUtils} = coreUtils
import type {PS, WixCodeFileDescriptor} from '@wix/document-services-types'
import _ from 'lodash'
import wixCodeUtils from '../utils/utils'
import fileDescriptorUtils from '../utils/fileDescriptorUtils'
import errors from '../utils/errors'
import constants from '../utils/constants'
import {
    ensureDescriptorWithShortLocationFormat,
    ensureLongLocationFormat,
    getVirtualDescriptor,
    isValidContent,
    isValidName,
    notifyLocalPathsChanged,
    parentPath,
    WixCodeFile
} from './IFileSystemService'
import type {CodeAppInfo} from '../utils/codeAppInfo'

const CONTENT_TYPE_JSON = 'application/json;charset=UTF-8'
const CONTENT_TYPE_PLAIN_TEXT = 'text/plain;charset=UTF-8'

const PUBLIC_FOLDER_NAME = 'public'
const BACKEND_FOLDER_NAME = 'backend'
const SCHEMAS_FOLDER_NAME = '.schemas'
const STYLES_FOLDER_NAME = 'styles'
const COMPONENTS_FOLDER_NAME = 'components'
const PATH_SEPARATOR = '/'

function isValidPartialChange(partialChange) {
    // @ts-expect-error
    return _.isObject(partialChange) && partialChange.diff && _.isArray(partialChange.diff)
}

function buildParametersString(urlParams: QueryParams) {
    return urlParams ? `?${urlUtils.toQueryString(urlParams)}` : ''
}

function getBaseUrl(codeAppInfo) {
    return `${codeAppInfo.baseUrl}/v1`
}

function getFileUrl(codeAppInfo, location: string, urlParams: QueryParams) {
    return getBaseUrl(codeAppInfo) + ensureLongLocationFormat(location, codeAppInfo) + buildParametersString(urlParams)
}

function getBulkUrl(codeAppInfo, urlParams: QueryParams) {
    return `${codeAppInfo.baseUrl}/files/${codeAppInfo.appId}${buildParametersString(urlParams)}`
}

function sendRequest(request: AjaxOp, codeAppInfo) {
    request.headers = _.assign(
        {
            'X-Wix-Si': codeAppInfo.signedInstance
        },
        request.headers
    )

    _.assign(request, {
        xhrFields: {
            withCredentials: true
        },
        crossDomain: true
    })

    return wixCodeUtils.sendRequestObj(request)
}

function sendFileRequest(request: AjaxOp, codeAppInfo, location: string, additionalParams?: QueryParams) {
    request.url = getFileUrl(codeAppInfo, location, additionalParams)
    return sendRequest(request, codeAppInfo)
}

function sendGetBulkRequest(request: AjaxOp, codeAppInfo, additionalParams?: QueryParams) {
    request.url = getBulkUrl(codeAppInfo, additionalParams)
    return sendRequest(request, codeAppInfo)
}

async function doCreateFileOrFolder(
    codeAppInfo,
    itemName: string,
    parentFolder: WixCodeFileDescriptor,
    isDirectory: boolean
) {
    if (!fileDescriptorUtils.isFolder(parentFolder)) {
        throw new errors.ArgumentError(
            'parentFolder',
            'fileSystemService.doCreateFileOrFolder',
            parentFolder,
            'folder object'
        )
    }

    if (!isValidName(itemName)) {
        throw new errors.ArgumentError('itemName', 'fileSystemService.doCreateFileOrFolder', itemName)
    }

    const headers = {
        'X-Create-Options': 'no-overwrite'
    }
    const payload = {
        name: itemName,
        localTimeStamp: 0,
        directory: isDirectory
    }

    const request = {
        type: wixCodeUtils.requestTypes.POST,
        contentType: CONTENT_TYPE_JSON,
        headers,
        timeout: 15000,
        data: JSON.stringify(payload)
    }
    const response = await sendFileRequest(request, codeAppInfo, parentFolder.location)

    return ensureDescriptorWithShortLocationFormat(response)
}

async function doCopyOrMove(
    codeAppInfo,
    operation,
    fileSystemItem: WixCodeFileDescriptor,
    targetFolder: WixCodeFileDescriptor,
    newName: string
) {
    if (!fileDescriptorUtils.isFileSystemItem(fileSystemItem)) {
        throw new errors.ArgumentError('fileSystemItem', 'fileSystemService.doCopyOrMove', fileSystemItem)
    }
    if (!fileDescriptorUtils.isFolder(targetFolder)) {
        throw new errors.ArgumentError('targetFolder', 'fileSystemService.doCopyOrMove', targetFolder, 'folder object')
    }

    if (!newName) {
        const parts = fileSystemItem.location.split(PATH_SEPARATOR)
        //if we rename a folder - last "cell" in the parts array will be empty so we pop twice...
        newName = parts.pop() || parts.pop()
    }

    const headers = {
        'X-Create-Options': `no-overwrite,${operation}`
    }

    const payload = {
        name: newName,
        location: ensureLongLocationFormat(fileSystemItem.location, codeAppInfo) // TBD: change to short format after server changes its expectation
    }

    const request = {
        type: wixCodeUtils.requestTypes.POST,
        contentType: CONTENT_TYPE_JSON,
        headers,
        timeout: 15000,
        data: JSON.stringify(payload)
    }
    const descriptor = await sendFileRequest(request, codeAppInfo, targetFolder.location)
    return ensureDescriptorWithShortLocationFormat(descriptor)
}

async function createFolder(extensionsAPI, codeAppInfo, folderName: string, parentFolder: WixCodeFileDescriptor) {
    const serverResponse = await doCreateFileOrFolder(codeAppInfo, folderName, parentFolder, true)
    notifyLocalPathsChanged(extensionsAPI, [parentFolder.location])
    return serverResponse
}

async function deleteItem(extensionsAPI, codeAppInfo, itemToDelete: WixCodeFileDescriptor) {
    if (!fileDescriptorUtils.isFileSystemItem(itemToDelete)) {
        throw new errors.ArgumentError('location', 'fileSystemService.deleteItem', itemToDelete)
    }

    const request = {
        type: wixCodeUtils.requestTypes.DELETE,
        headers: {
            Accept: '*/*'
        },
        dataType: 'text', // makes zepto not evaluate our prefetched script
        timeout: 15000
    }
    const serverResponse = await sendFileRequest(request, codeAppInfo, itemToDelete.location)

    notifyLocalPathsChanged(extensionsAPI, [itemToDelete.location, parentPath(itemToDelete.location)])
    return serverResponse
}

async function copy(
    extensionsAPI,
    codeAppInfo,
    itemToCopy: WixCodeFileDescriptor,
    targetFolder: WixCodeFileDescriptor,
    newName?: string
) {
    const serverResponse = await doCopyOrMove(codeAppInfo, 'copy', itemToCopy, targetFolder, newName)
    notifyLocalPathsChanged(extensionsAPI, [targetFolder.location])
    return serverResponse
}

async function move(
    extensionsAPI,
    codeAppInfo,
    itemToMove: WixCodeFileDescriptor,
    targetFolder: WixCodeFileDescriptor,
    newName?: string
) {
    const serverResponse = await doCopyOrMove(codeAppInfo, 'move', itemToMove, targetFolder, newName)
    notifyLocalPathsChanged(extensionsAPI, [itemToMove.location, targetFolder.location])
    return serverResponse
}

async function getChildren(codeAppInfo, parentFolder: WixCodeFileDescriptor) {
    if (!fileDescriptorUtils.isFolder(parentFolder)) {
        throw new errors.ArgumentError('parentFolder', 'fileSystemService.getChildren', parentFolder, 'folder object')
    }
    const params = {depth: 1}
    const request = {type: wixCodeUtils.requestTypes.GET}
    const response: any = await sendFileRequest(request, codeAppInfo, parentFolder.location, params)
    return _.map(response.children || [], ensureDescriptorWithShortLocationFormat)
}

async function getMetadata(codeAppInfo, fileSystemItem: WixCodeFileDescriptor) {
    if (!fileDescriptorUtils.isFileSystemItem(fileSystemItem)) {
        throw new errors.ArgumentError(
            'fileSystemItem',
            'fileSystemService.getMetadata',
            fileSystemItem,
            'file system object'
        )
    }
    const params = {parts: 'meta'}
    const request = {type: wixCodeUtils.requestTypes.GET}
    const response = await sendFileRequest(request, codeAppInfo, fileSystemItem.location, params)
    return ensureDescriptorWithShortLocationFormat(response)
}

function readAllFiles(codeAppInfo) {
    const request = {
        type: wixCodeUtils.requestTypes.GET,
        headers: {
            Accept: 'application/json'
        }
    }
    return sendGetBulkRequest(request, codeAppInfo)
}

async function readFile(codeAppInfo, file: WixCodeFileDescriptor) {
    //TODO: handle acceptPatch
    if (!fileDescriptorUtils.isFileSystemItem(file)) {
        throw new errors.ArgumentError('filePath', 'fileSystemService.readFile', file, 'file object')
    }

    const request = {
        type: wixCodeUtils.requestTypes.GET,
        headers: {
            Accept: 'text/plain'
        },
        contentType: 'text/plain',
        dataType: 'text'
    }

    return sendFileRequest(request, codeAppInfo, file.location)
}

async function overrideFileContent(codeAppInfo, file: WixCodeFileDescriptor, content: string) {
    if (!isValidContent(content)) {
        throw new errors.ArgumentError('content', 'fileSystemService.overrideFileContent', content, 'string')
    }

    const headers = {'If-Match': file.eTag}

    const request = {
        type: wixCodeUtils.requestTypes.PUT,
        headers,
        contentType: CONTENT_TYPE_PLAIN_TEXT,
        log: false,
        data: content
    }
    const response = await sendFileRequest(request, codeAppInfo, file.location)

    return ensureDescriptorWithShortLocationFormat(response)
}

async function patchFileContent(codeAppInfo, file: WixCodeFileDescriptor, partialChange) {
    if (!isValidPartialChange(partialChange)) {
        throw new errors.ArgumentError(
            'partialChange',
            'fileSystemService.patchFileContent',
            partialChange,
            'partial change with diff object'
        )
    }
    const headers = {
        'X-HTTP-Method-Override': 'PATCH',
        'If-Match': file.eTag
    }

    const request = {
        type: wixCodeUtils.requestTypes.POST,
        headers,
        contentType: CONTENT_TYPE_PLAIN_TEXT,
        log: false,
        data: JSON.stringify(partialChange)
    }
    const response = await sendFileRequest(request, codeAppInfo, file.location)
    return ensureDescriptorWithShortLocationFormat(response)
}

async function writeFile(extensionsAPI, codeAppInfo, file: WixCodeFileDescriptor, content?) {
    if (!fileDescriptorUtils.isFileSystemItem(file)) {
        throw new errors.ArgumentError('filePath', 'fileSystemService.writeFile', file, 'file object')
    }
    if (!isValidContent(content) && !isValidPartialChange(content)) {
        throw new errors.ArgumentError(
            'content',
            'fileSystemService.writeFile',
            content,
            'string or partial-change object'
        )
    }
    // FIXME
    const serverResponse =
        (await typeof content) === 'string'
            ? overrideFileContent(codeAppInfo, file, content)
            : patchFileContent(codeAppInfo, file, content)
    notifyLocalPathsChanged(extensionsAPI, [
        file.location,
        parentPath(file.location) // because writeFile is also used for creating new files
    ])
    return serverResponse
}

async function bulkWrite(extensionsAPI: ExtensionAPI, codeAppInfo, files: WixCodeFile[]) {
    const payload = {
        updates: files.map(file => ({
            path: file.fileId,
            content: file.content
        }))
    }
    const request = {
        url: `${getBaseUrl(codeAppInfo)}/files/${codeAppInfo.appId}`,
        type: wixCodeUtils.requestTypes.PUT,
        contentType: CONTENT_TYPE_JSON,
        data: JSON.stringify(payload),
        headers: {
            Accept: '*/*'
        },
        dataType: 'text'
    }
    const serverResponse = await sendRequest(request, codeAppInfo)
    notifyLocalPathsChanged(extensionsAPI, [
        ...files.map(file => file.fileId),
        ...files.map(file => parentPath(file.fileId))
    ])
    return serverResponse
}

function getRoots() {
    return {
        schemas: getVirtualDescriptor(SCHEMAS_FOLDER_NAME, true),
        public: getVirtualDescriptor(PUBLIC_FOLDER_NAME, true),
        pages: getVirtualDescriptor(`${PUBLIC_FOLDER_NAME}/${constants.PAGES_ROOT}`, true),
        backend: getVirtualDescriptor(BACKEND_FOLDER_NAME, true),
        styles: getVirtualDescriptor(STYLES_FOLDER_NAME, true),
        components: getVirtualDescriptor(COMPONENTS_FOLDER_NAME, true)
    }
}

const createService = (extensionAPI: PS['extensionAPI'], codeAppInfo: CodeAppInfo) => {
    return {
        createFolder: createFolder.bind(null, extensionAPI, codeAppInfo),
        deleteItem: deleteItem.bind(null, extensionAPI, codeAppInfo),
        copy: copy.bind(null, extensionAPI, codeAppInfo),
        move: move.bind(null, extensionAPI, codeAppInfo),
        getChildren: getChildren.bind(null, codeAppInfo),
        readAllFiles: readAllFiles.bind(null, codeAppInfo),
        readFile: readFile.bind(null, codeAppInfo),
        writeFile: writeFile.bind(null, extensionAPI, codeAppInfo),
        bulkWrite: bulkWrite.bind(null, extensionAPI, codeAppInfo)
    }
}

export default {
    createService,
    getRoots,
    createFolder,
    deleteItem,
    copy,
    move,
    getChildren,
    getMetadata,
    readAllFiles,
    readFile,
    writeFile,
    bulkWrite,
    getVirtualDescriptor,
    notifyLocalPathsChanged
}
