import type {Pointer, PS} from '@wix/document-services-types'
import constants from '../../constants/constants'
import themeConstants from './constants'

const {THEME_DATA_ID} = themeConstants

function getThemePointer(ps: PS): Pointer {
    return ps.pointers.data.getThemeItem(THEME_DATA_ID, constants.MASTER_PAGE_ID)
}

function getCollectionPointer(ps: PS, collectionType: string): Pointer {
    const theme = getThemePointer(ps)
    return ps.pointers.getInnerPointer(theme, collectionType)
}

function getCollectionItemPointer(ps: PS, collectionType: string, index: string | number): Pointer {
    const theme = getThemePointer(ps)
    return ps.pointers.getInnerPointer(theme, [collectionType, index as string])
}

function setCollectionItem(ps: PS, collectionType: string, index: string | number, value: any): void {
    const itemPointer = getCollectionItemPointer(ps, collectionType, index)
    ps.dal.set(itemPointer, value)
}

function getCollection(ps: PS, collectionType: string): any[] {
    const pointer = getCollectionPointer(ps, collectionType)
    return ps.dal.get(pointer)
}

function getCollectionItem(ps: PS, collectionType: string, index: string | number): any {
    const itemPointer = getCollectionItemPointer(ps, collectionType, index)
    return ps.dal.get(itemPointer)
}

export default {
    getCollection,
    getCollectionItem,
    setCollectionItem
}
