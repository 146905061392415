import _ from 'lodash'
import type {CompStructure} from '@wix/document-services-types'
import type {DalValue} from '@wix/document-manager-core'
import type {DalStore, DalStoreBuilder} from '../dalStoreBuilder'
import {COMPONENT_TYPES} from './constants'
import type {APIBuilderConfig, DataOverrides, SchemaOverrides} from './types'
import {COMP_DATA_QUERY_KEYS_WITH_STYLE, DATA_TYPES} from '../../src/constants/constants'
import type {AddComponentOptions} from './componentsBuilder'

const DATA_TYPES_WITH_PROPS = _.mapKeys(DATA_TYPES)
declare interface CompStructureWithMasterPageFields extends CompStructure {
    documentType?: string
    themeData?: any
}

export const createComponentStructure = (
    componentType: string,
    schemaOverrides: Partial<CompStructureWithMasterPageFields>
): CompStructure =>
    _.merge(
        {
            id: _.uniqueId('comp-'),
            componentType,
            layout: {x: 10, y: 10, width: 10, height: 10},
            type: _.has(schemaOverrides, ['components']) ? 'Container' : 'Component',
            skin: ''
        },
        schemaOverrides
    )

// const defaultStructure = () => ({
//     masterPage: createComponentStructure(COMPONENT_TYPES.MASTER_PAGE, {
//         id: 'masterPage',
//         type: 'Document',
//         documentType: 'document',
//         dataQuery: '#masterPage',
//         components: [] as any,
//         themeData: {},
//         skin: undefined,
//         metaData: {pageId: 'masterPage'}
//     }),
//     defaultPageId: createComponentStructure(COMPONENT_TYPES.PAGE, {
//         id: 'defaultPageId',
//         type: 'Page',
//         componentType: COMPONENT_TYPES.APP_PAGE,
//         skin: 'wysiwyg.viewer.skins.page.BasicPageSkin',
//         styleId: 'p2',
//         components: [] as any,
//         metaData: {pageId: 'defaultPageId'},
//         dataQuery: '#defaultPageId'
//     })
// })

type ComponentOfType = (schemaOverrides?: SchemaOverrides, dataOverrides?: DataOverrides, ops?: any) => CompStructure

interface AddStructureAPI {
    addComponent(
        componentType: string,
        schemaOverrides: SchemaOverrides,
        dataOverrides?: DataOverrides
    ): DalStoreBuilder
    addComponentStructure(
        componentType: string,
        schemaOverrides: SchemaOverrides,
        dataOverrides?: DataOverrides
    ): CompStructure
    addComponentOfType(compType: string, type?: string): ComponentOfType
    getComponent(compId: string): DalValue
}

export interface StructureBuilder extends AddStructureAPI {
    build(): DalStore
    mobile: AddStructureAPI
    allViewModes: AddStructureAPI
}

const builder = (externalBuilder: DalStoreBuilder): StructureBuilder => {
    const model = externalBuilder.store

    const createStructureAPI = ({isMobile}: APIBuilderConfig): AddStructureAPI => ({
        addComponent: addComponent.bind(null, isMobile),
        addComponentStructure: addComponentStructure.bind(null, isMobile),
        addComponentOfType: addComponentOfType.bind(null, isMobile),
        getComponent: getComponent.bind(null, isMobile)
    })

    const desktopAPI = createStructureAPI({isMobile: false})
    const mobileAPI = createStructureAPI({isMobile: true})
    const dualAPI: AddStructureAPI = new Proxy(desktopAPI, {
        get:
            ({}, methodName) =>
            (...args: any[]) => {
                _.invoke(desktopAPI, methodName, ...args)
                return _.invoke(mobileAPI, methodName, ...args)
            }
    })

    const _builder: StructureBuilder = {
        build: () => model,
        ...createStructureAPI({isMobile: false}),
        mobile: createStructureAPI({isMobile: true}),
        allViewModes: dualAPI
    }

    function getComponent(isMobile: boolean, compId: string): Record<string, any> {
        return isMobile ? model.MOBILE[compId] : model.DESKTOP[compId]
    }

    function addComponentStructure(
        isMobile: boolean,
        componentType: string,
        schemaOverrides: SchemaOverrides,
        dataOverrides: DataOverrides,
        options: AddComponentOptions = {}
    ): CompStructure {
        const viewMode = isMobile ? 'MOBILE' : 'DESKTOP'
        const compStructure = createComponentStructure(componentType, schemaOverrides)

        _.forEach(dataOverrides, (dataItem, namespace) => {
            if (!DATA_TYPES_WITH_PROPS[namespace]) {
                throw new Error(`${namespace} is not a legitimate namespace`)
            }

            _.set(model, [namespace, dataItem.id], dataItem)
            const withHash = _.includes(
                [DATA_TYPES.design, DATA_TYPES.layout, DATA_TYPES.data, DATA_TYPES.breakpoints],
                namespace
            )
            compStructure[COMP_DATA_QUERY_KEYS_WITH_STYLE[namespace]] = `${withHash ? '#' : ''}${dataItem.id}`
        })

        const modelElement = model[viewMode]
        if (compStructure.parent) {
            const parent = modelElement[compStructure.parent]
            if (!parent) {
                throw new Error(
                    `${compStructure.type} ${compStructure.id} declared parent ${compStructure.parent} does not exist`
                )
            }
            // Ideally legitimate containers should always have components array, and we remove this fallback
            const components = parent.components ?? []

            if (!components.includes(compStructure.id)) {
                if (options.compIndex !== undefined) {
                    components.splice(options.compIndex, 0, compStructure.id)
                } else {
                    components.push(compStructure.id)
                }
            }

            modelElement[compStructure.parent].components = components
        }

        const compComponentsFromModel = _.get(modelElement[compStructure.id!], ['components'], [])
        const compComponents = _.get(compStructure, ['components'], [])
        compStructure.components = _.uniq(_.concat(compComponentsFromModel, compComponents))

        modelElement[compStructure.id!] = compStructure
        return compStructure
    }

    function addComponent(
        isMobile: boolean,
        componentType: string,
        schemaOverrides: SchemaOverrides,
        dataOverrides: DataOverrides = {}
    ) {
        addComponentStructure(isMobile, componentType, schemaOverrides, dataOverrides)
        return externalBuilder || _builder
    }

    function addComponentOfType(isMobile: boolean, compType: string, type = 'Component') {
        return (
            schemaOverrides: Partial<CompStructure> = {},
            dataOverrides: Record<string, any> = {},
            options: AddComponentOptions = {}
        ): CompStructure => {
            const pageId = options.pageId ?? externalBuilder.Page.getHomePage()
            const basicSchemaOverrides =
                compType === COMPONENT_TYPES.PAGE
                    ? {metaData: {pageId}, type}
                    : {parent: pageId, metaData: {pageId}, type, ...schemaOverrides}
            schemaOverrides = {...basicSchemaOverrides, ...schemaOverrides}
            return addComponentStructure(isMobile, compType, schemaOverrides, dataOverrides, options)
        }
    }

    return _builder
}

export {builder}
