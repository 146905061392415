import {createComponentToContainerMigrator} from './utils/componentMigrationHelper'
import type {PageMigrator} from '../dataMigrationRunner'

const {migratePage, version, name} = createComponentToContainerMigrator('migrateTPAWidgetToContainer', [
    'wysiwyg.viewer.components.tpapps.TPAWidget'
])

export const migrateTPAWidgetToContainer: PageMigrator = {
    migratePage,
    name,
    version
}
