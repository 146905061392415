import type {CompRef, PS} from '@wix/document-services-types'
import component from '../../component/component'
import structure from '../../structure/structure'
import dataModel from '../../dataModel/dataModel'

export default (ps: PS, compPointer: CompRef) => {
    const bindToComp =
        (func: (ps: PS, compPointer: CompRef, ...args: any[]) => any) =>
        (...args: any[]) =>
            func(ps, compPointer, ...args)
    return {
        layout: {
            update: bindToComp(structure.updateCompLayout)
        },
        data: {
            update: bindToComp(dataModel.updateDataItem)
        },
        properties: {
            update: bindToComp(component.properties.update)
        },
        design: {
            update: bindToComp(component.design.updateDesignItem)
        }
    }
}
