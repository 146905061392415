import {errors} from '@wix/document-services-implementation'

export default function () {
    return {
        methods: {
            /**
             * Site error constants
             * @member documentServices
             */
            errors
        }
    }
}
