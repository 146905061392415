import {
    CreateExtArgs,
    CreateExtensionArgument,
    DalValue,
    Extension,
    ExtensionAPI,
    PointerMethods,
    pointerUtils
} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import {getScopedPointer} from '../../utils/scopesUtils'
import {DATA_ACCESS_HOOKS} from './hooks'
import type {HooksExtensionApi} from '../hooks/hooks'

const {
    getPointer,
    getInnerPointer,
    getInnerPointerPathRoot,
    getOriginalPointerFromInner,
    isPointer,
    isSameComponent,
    isSamePointer
} = pointerUtils

export interface BaseDataAccessApi extends ExtensionAPI {
    get(pointer: Pointer): DalValue
    set(pointer: Pointer, value: DalValue): void
    has(pointer: Pointer): boolean
    remove(pointer: Pointer): void
}

interface DataAccessApi extends BaseDataAccessApi, ExtensionAPI {
    withSuper: BaseDataAccessApi
}

export interface DataAccessExtensionApi extends ExtensionAPI {
    dataAccess: DataAccessApi
}

const createExtension = ({experimentInstance}: CreateExtensionArgument): Extension => {
    const createExtensionAPI = ({dal, extensionAPI}: CreateExtArgs): DataAccessExtensionApi => {
        const hooksApi = () => (extensionAPI as HooksExtensionApi).hooks
        const get = (pointer: Pointer): DalValue => dal.get(pointer)
        const has = (pointer: Pointer): DalValue => dal.has(pointer)
        const set = (pointer: Pointer, value: DalValue): DalValue => dal.set(pointer, value)
        const remove = (pointer: Pointer): DalValue => dal.remove(pointer)

        const getWithSuper = (pointer: Pointer): DalValue => {
            const value = hooksApi().executeHookAndUpdateValue(
                DATA_ACCESS_HOOKS.SUPER.GET.createEvent({pointer}),
                undefined
            )
            return value !== undefined ? value : get(pointer)
        }
        const hasWithSuper = (pointer: Pointer): boolean => {
            const value = hooksApi().executeHookAndUpdateValue(
                DATA_ACCESS_HOOKS.SUPER.HAS.createEvent({pointer}),
                undefined
            )
            return value !== undefined ? value : has(pointer)
        }
        const setWithSuper = (pointer: Pointer, value: DalValue): DalValue => {
            const finishedSetting = hooksApi().executeHookAndUpdateValue(
                DATA_ACCESS_HOOKS.SUPER.SET.createEvent({pointer, value}),
                false
            )
            if (!finishedSetting) {
                set(pointer, value)
            }
        }

        const removeWithSuper = (pointer: Pointer): DalValue => {
            const finishedRemoving = hooksApi().executeHookAndUpdateValue(
                DATA_ACCESS_HOOKS.SUPER.REMOVE.createEvent({pointer}),
                false
            )
            if (!finishedRemoving) {
                remove(pointer)
            }
        }

        return {
            dataAccess: {
                get,
                set,
                has,
                remove,
                withSuper: {
                    get: getWithSuper,
                    set: setWithSuper,
                    has: hasWithSuper,
                    remove: removeWithSuper
                }
            }
        }
    }

    const createPointersMethods = (): PointerMethods => {
        const getPointerType = (pointer: Pointer): string => {
            switch (pointer.type) {
                case 'DESKTOP':
                case 'MOBILE':
                    return _.isEmpty(pointer.innerPath) ? 'component' : 'componentStructure'
                default:
                    return pointer.type
            }
        }
        return {
            getPointer,
            getInnerPointer,
            isPointer,
            isSamePointer: _.partial(isSamePointer, _, _, experimentInstance.isOpen('dm_disableScopesHybridMode')),
            // @ts-expect-error
            getInnerPointerPathRoot,
            getOriginalPointerFromInner,
            // @ts-expect-error
            getPointerType,

            components: {
                isSameComponent: _.partial(
                    isSameComponent,
                    _,
                    _,
                    experimentInstance.isOpen('dm_disableScopesHybridMode')
                )
            },

            scopes: {
                getScopedPointer
            }
        }
    }

    return {
        name: 'dataAccess',
        createPointersMethods,
        createExtensionAPI
    }
}

export {createExtension}
