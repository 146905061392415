import constants from '../../constants/constants'

const metaData = {
    resizableSides: [constants.RESIZE_SIDES.LEFT, constants.RESIZE_SIDES.RIGHT],
    layoutLimits: {
        minWidth: 110,
        maxWidth: 400
    }
}

export default metaData
