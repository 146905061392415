import {
    CreateExtArgs,
    CreateExtensionArgument,
    createSnapshotChain,
    createStore,
    DalValue,
    debug,
    DmStore,
    Extension,
    ExtensionAPI,
    isConflicting as isConflictingValue,
    LoggerDriver,
    pointerUtils,
    SnapshotDal
} from '@wix/document-manager-core'
import type {SaveStateApi} from './saveState'
import {Stack} from '@wix/document-manager-utils'
import type {Pointer, ResolvedReference, SetOperationsQueue, UndoRedoConfig} from '@wix/document-services-types'
import _ from 'lodash'
import {NON_UNDOABLE_KEYS, UNDOABLE_TYPES} from '../constants/constants'
import type {RelationshipsAPI} from './relationships'
import type {SnapshotExtApi} from './snapshots'

const {getPointer} = pointerUtils

const UNDO_REDO_TAG = 'undoRedo'
const INITIAL_HISTORY_LABEL = 'INITIAL_HISTORY_SNAPSHOT_LABEL'

const EVENTS = {
    UNDO_REDO: {
        TAKE_SNAPSHOT: 'TAKE_SNAPSHOT',
        UNDO: 'UNDO',
        REDO: 'REDO'
    }
}

interface SnapshotRef {
    id: number
    label: string
    index?: number
    tag: string
    params?: any
    placeholder?: any
}

export const undoRedoConfig: UndoRedoConfig = {
    undoableTypes: UNDOABLE_TYPES,
    nonUndoableKeys: NON_UNDOABLE_KEYS,
    nogoNamespaces: ['DESKTOP', 'MOBILE', 'variants']
}

const createExtension = ({dsConfig, environmentContext}: CreateExtensionArgument): Extension => {
    const concurrentExperimentOpen = dsConfig.cedit
    const undoStack = new Stack<SnapshotRef>('Undo')
    const redoStack = new Stack<SnapshotRef>('Redo')
    let lastSnapshotId: number = 0
    let currentSnapshotRef: SnapshotRef = null as unknown as SnapshotRef
    let soq: SetOperationsQueue | null = null

    const createSnapshotRef = (label: string, index: number | undefined, additionalProps: any = {}): SnapshotRef => ({
        id: ++lastSnapshotId,
        tag: UNDO_REDO_TAG,
        label,
        index,
        ...additionalProps
    })

    const createExtensionAPI = ({extensionAPI, dal, eventEmitter, coreConfig}: CreateExtArgs): UndoRedoExtApi => {
        const {snapshots} = extensionAPI as SnapshotExtApi
        const {relationships} = extensionAPI as RelationshipsAPI
        const {logger} = coreConfig
        const log: LoggerDriver = debug('undo', environmentContext.loggerDriver)
        const {undoableTypes, nonUndoableKeys, nogoNamespaces} = coreConfig.undoRedoConfig ?? undoRedoConfig

        /**
         * Reverts the keys in snapshot to the state at revertToSnapshot unless they're already contained in revertStore
         * @param snapshot - snapshot to be reverted
         * @param revertToSnapshot - state to revert back to
         * @param revertStore - store containing the reverted values
         */
        const revertSnapshot = (snapshot: SnapshotDal, revertToSnapshot: SnapshotDal, revertStore: DmStore) => {
            snapshot.getStore().forEach((pointer: Pointer) => {
                if (!revertStore.has(pointer)) {
                    revertStore.set(pointer, revertToSnapshot.getValue(pointer))
                }
            })
        }

        const hasSignature = (pointer: Pointer) => dal.hasSignature(pointer)

        /**
         * Returns true if storeToCheck conflicts with baseSnapshot, else returns false
         * @param storeToCheck
         * @param baseSnapshot
         */
        const hasSignatureConflict = (storeToCheck: DmStore, baseSnapshot: SnapshotDal): boolean =>
            storeToCheck.some(
                (pointer, value) =>
                    hasSignature(pointer) &&
                    isConflictingValue(
                        value,
                        baseSnapshot.getValue(pointer),
                        storeToCheck.get(dal.getBasedOnSignaturePointer(pointer))
                    )
            )

        /**
         * Checks if any pointer in storeToCheck refers to a deleted pointer in revertStore
         * @param foreignStore
         * @param revertStore
         * @returns true if foreignStore refers to a deleted pointer in revertStore
         */
        const dependsOnDeletedPointer = (foreignStore: DmStore, revertStore: DmStore): boolean => {
            const deletedRevertPointers: DmStore = revertStore.filter(
                (pointer: Pointer, value: DalValue) => value === undefined && !foreignStore.has(pointer)
            )
            if (deletedRevertPointers.isEmpty()) {
                return false
            }
            return foreignStore.some((pointer: Pointer, value: any) => {
                if (value) {
                    const refs: readonly ResolvedReference[] = relationships.extractReferences(pointer.type, value)
                    return refs.some((ref: ResolvedReference) =>
                        deletedRevertPointers.has(getPointer(ref.id, ref.referencedMap))
                    )
                }
                return false
            })
        }

        /**
         * Returns true if revertStore refers to a deleted pointer in foreignStore
         * @param foreignStore
         * @param revertStore
         * @returns true if revertStore refers to a deleted pointer in foreignStore
         */
        const refersToDeletedPointer = (revertStore: DmStore, foreignStore: DmStore) =>
            foreignStore.some((pointer: Pointer, value: any) => {
                if (value === undefined) {
                    const refs: Pointer[] = relationships.getReferencesToPointer(pointer)
                    return refs.some(ptr => revertStore.has(ptr))
                }
                return false
            })

        /**
         * Returns true if storeToCheck has either a signature dependency or a reference dependency to a deleted pointer to baseSnapshot
         * @param foreignStore
         * @param revertSnapshot
         * @returns true if there is a signature conflict or dependency relationship, else returns false
         */
        const isConflictingOrDependent = (foreignStore: DmStore, revertCommit: SnapshotDal): boolean =>
            hasSignatureConflict(foreignStore, revertCommit) ||
            dependsOnDeletedPointer(foreignStore, revertCommit.getStore()) ||
            refersToDeletedPointer(revertCommit.getStore(), foreignStore)

        /**
         * Returns true if the pointer value causes the container snapshot to be defined as a NOGO snapshot
         * @param pointer
         * @param value
         * @returns
         */
        const isNogoPointerValue = (pointer: Pointer, value: any) =>
            value === undefined && hasSignature(pointer) && nogoNamespaces.includes(pointer.type)

        /**
         * Returns true if the snapshot is a NOGO snapshot, else returns false
         * A NOGO snapshot is a foreign snapshot that should not be reverted.
         * @param snapshot
         */
        const isNogo = (snapshot: SnapshotDal): boolean =>
            snapshot.isForeign && snapshot.getStore().some(isNogoPointerValue)

        /**
         * Returns a set containing all the snapshots (local and foreign) that are dependencies of NOGO snapshots
         * @param chain
         */
        const markNogosAndDependencies = (chain: SnapshotDal[]): Set<SnapshotDal> => {
            const aggStore: DmStore = createStore()
            const nogosAndDependencies = new Set<SnapshotDal>()
            _.forEachRight(chain, (snapshot: SnapshotDal) => {
                // if the snapshot is a foreign NOGO snapshot or
                // a signature dependency of one (removing the snapshot causes aggStore to be in conflict)
                if (isNogo(snapshot) || hasSignatureConflict(aggStore, snapshot.getPreviousSnapshot()!)) {
                    aggStore.merge(snapshot.getStore())
                    nogosAndDependencies.add(snapshot)
                }
            })
            return nogosAndDependencies
        }

        const isUndoablePointer = (pointer: Pointer) => {
            const {type, id} = pointer
            return undoableTypes[type] && !_.get(nonUndoableKeys, [type, id])
        }

        const setInDal = (pointer: Pointer, value: any) => {
            if (isUndoablePointer(pointer)) {
                if (value === undefined) {
                    dal.remove(pointer)
                } else {
                    dal.setIfChanged(pointer, value)
                }
            }
        }

        const buildAndApplyRevert = (desiredSnapshotDal: SnapshotDal, currentSnapshotDal: SnapshotDal): void => {
            const chain = createSnapshotChain(desiredSnapshotDal, currentSnapshotDal, 'buildAndApplyRevert')
            const nogosAndDependencies: Set<SnapshotDal> = markNogosAndDependencies(chain)
            const revertStore: DmStore = createStore()
            chain.forEach((snapshot: SnapshotDal) => {
                const snapshotStore = snapshot.getStore()
                if (nogosAndDependencies.has(snapshot)) {
                    revertStore.merge(snapshotStore)
                } else if (snapshot.isForeign) {
                    // Check if the snapshot has signature dependencies on any previous snapshots that have been reverted.
                    // Creating the temporary snapshot enables checking for conflicts between snapshot and revertStore,
                    // and for conflicts between snapshot and desiredSnapshotDal (for values not contained in revertStore)
                    if (isConflictingOrDependent(snapshotStore, new SnapshotDal(desiredSnapshotDal, revertStore))) {
                        revertSnapshot(snapshot, desiredSnapshotDal, revertStore)
                    } else {
                        revertStore.merge(snapshotStore)
                    }
                } else {
                    revertSnapshot(snapshot, desiredSnapshotDal, revertStore)
                }
                snapshotStore.forEach((pointer: Pointer) => setInDal(pointer, revertStore.get(pointer)))
            })
        }

        const takeInitialSnapshotForUndoRedo = (): void => {
            currentSnapshotRef = createSnapshotRef(INITIAL_HISTORY_LABEL, snapshots.takeSnapshot(UNDO_REDO_TAG))
        }

        /**
         * capture a snapshot (current site state) and move the last snapshot to the undo stack.
         * @param {SetOperationsQueue} setOperationsQueue
         * @param {String} [snapshotLabel] - a name describing the snapshot
         * @param {Object} [params] - extra parameters that will be stored with the snapshot in the stack.
         * @param {String} [amendToSnapshotWithId] - id of a snapshot to amend to, if the latest snapshot is equal to this id, the snapshot will be amended, otherwise a new snapshot will be taken
         * Can be restored before performing an undo/redo action by calling getUndo/RedoLastSnapshotParams()
         */
        const add = (snapshotLabel: string, params?: object, amendToSnapshotWithId?: string) => {
            if (!soq) {
                throw new Error('not initialized with soq')
            }
            redoStack.clear()
            const shouldAmend = !!amendToSnapshotWithId && lastSnapshotId.toString() === amendToSnapshotWithId
            if (!shouldAmend) {
                undoStack.push(currentSnapshotRef)
            } else {
                params = _.assign({}, currentSnapshotRef.params, params)
            }

            currentSnapshotRef = createSnapshotRef(snapshotLabel, undefined, {params})
            const newSnapshotRef = currentSnapshotRef
            soq.flushQueueAndExecute(() => {
                newSnapshotRef.index = snapshots.takeSnapshot(UNDO_REDO_TAG)
                eventEmitter.emit(EVENTS.UNDO_REDO.TAKE_SNAPSHOT)
            })
        }

        const isSaveInProgress = () => (extensionAPI as SaveStateApi).saveState.isSaveInProgress()

        /**
         * Returns true if there is at least one snapshot to undo.
         * @returns {boolean}
         */
        const canUndo = () => !isSaveInProgress() && !undoStack.isEmpty()

        /**
         * Returns true if there is at least one snapshot to redo
         * @returns {boolean}
         */
        const canRedo = () => !isSaveInProgress() && !redoStack.isEmpty()

        const executeUndoRedo = (
            setOperationsQueue: SetOperationsQueue,
            isRedo: boolean,
            operation: string,
            event: string,
            targetSnapshotRef: SnapshotRef,
            revertStack: Stack<SnapshotRef>
        ): void => {
            const revertSnapshotRef: SnapshotRef = {...currentSnapshotRef, index: undefined}
            revertStack.push(revertSnapshotRef)
            currentSnapshotRef = targetSnapshotRef
            log.info(`schedule ${operation}: ${targetSnapshotRef}`)

            setOperationsQueue.runSetOperation(
                () => {
                    try {
                        const undoExecInteraction = `undoRedo ${operation} execute`
                        logger.interactionStarted(undoExecInteraction)

                        const currentSnapshotDal: SnapshotDal = snapshots.getCurrentSnapshot()
                        revertSnapshotRef.index = snapshots.tagSnapshot(UNDO_REDO_TAG, currentSnapshotDal)

                        const desiredSnapshotDal: SnapshotDal = snapshots.getSnapshotByTagAndIndex(
                            targetSnapshotRef.tag,
                            targetSnapshotRef.index!
                        )
                        log.info(
                            `execute ${operation}: ${currentSnapshotDal.id.slice(
                                0,
                                10
                            )} -> ${desiredSnapshotDal.id.slice(0, 10)}`
                        )
                        buildAndApplyRevert(desiredSnapshotDal, currentSnapshotDal)
                        logger.interactionEnded(undoExecInteraction)
                        eventEmitter.emit(event)
                    } catch (error) {
                        coreConfig.logger.captureError(error as Error, {
                            tags: {
                                undoRedo: true,
                                isRedo,
                                cedit: concurrentExperimentOpen
                            }
                        })
                        throw error
                    }
                },
                [],
                {methodName: operation, noBatching: true}
            )
        }

        /**
         * Undo the actions the were done between the previous and the last call to add()
         * Push the current snapshot to the redo stack and apply the last snapshot in the undo stack
         * @param {SetOperationsQueue} setOperationsQueue
         * @returns {String} the applied snapshot label
         */
        const undo = (): string => {
            if (!soq) {
                throw new Error('not initialized with soq')
            }
            if (canUndo()) {
                executeUndoRedo(soq, false, 'Undo', EVENTS.UNDO_REDO.UNDO, undoStack.pop()!, redoStack)
            }
            return currentSnapshotRef.label
        }

        /**
         * Redo the actions the were most recently rolled back by calling undo
         * Push the current snapshot to the undo stack and apply the last snapshot in the redo stack
         * @param {SetOperationsQueue} setOperationsQueue
         * @returns {String} the applied snapshot label
         */
        const redo = (): string => {
            if (!soq) {
                throw new Error('not initialized with soq')
            }
            if (canRedo()) {
                executeUndoRedo(soq, true, 'Redo', EVENTS.UNDO_REDO.REDO, redoStack.pop()!, undoStack)
            }
            return currentSnapshotRef.label
        }

        /**
         * Redo the last snapshot taken.
         * @param {SetOperationsQueue} setOperationsQueue
         * @return {string}
         */
        const applyLatestSnapshot = () => {
            if (!soq) {
                throw new Error('not initialized with soq')
            }
            const snapshotAtTheTime = currentSnapshotRef
            soq.flushQueueAndExecute(() => {
                const applied = snapshots.getSnapshotByTagAndIndex(
                    snapshotAtTheTime.tag,
                    snapshotAtTheTime.index as number
                )
                const current = snapshots.getCurrentSnapshot()
                buildAndApplyRevert(applied, current)
                dal.commitTransaction('applyLatestSnapshot')
            })

            return currentSnapshotRef.label
        }

        /**
         * Clears the undo/redo stack, meaning that after this method is called, no undo/redo actions will be performed until another snapshot is added
         */
        const clear = () => {
            undoStack.clear()
            redoStack.clear()
            takeInitialSnapshotForUndoRedo()
        }

        /**
         * Returns the parameters of the last taken snapshot, or null if there aren't any
         * @returns {Object}
         */
        const getUndoLastSnapshotParams = () => _.get(currentSnapshotRef, ['params'], null)

        /**
         * Returns the parameters of the last rolled back snapshot, or null if there aren't any
         * @returns {Object}
         */
        const getRedoLastSnapshotParams = () => _.get(redoStack.top(), ['params'], null)
        /**
         * Returns the label of the last snapshot taken, or an empty string if it wasn't set
         * @returns {String}
         */
        const getUndoLastSnapshotLabel = () => _.get(currentSnapshotRef, ['label'], '')
        /**
         * Returns the label of the last rolled back snapshot, or an empty string if it wasn't set
         * @returns {String}
         */
        const getRedoLastSnapshotLabel = () => _.get(redoStack.top(), ['label'], '')
        /**
         * Returns the id of the last snapshot taken, or an empty string if it wasn't set
         * @returns {String}
         */
        const getUndoLastSnapshotId = () => _.get(currentSnapshotRef, ['id'], '').toString()
        /**
         * Returns the id of the last rolled back snapshot, or an empty string if it wasn't set
         * @returns {String}
         */
        const getRedoLastSnapshotId = () => _.get(redoStack.top(), ['id'], '').toString()

        const initWithSOQ = (setOperationsQueue: SetOperationsQueue) => {
            soq = setOperationsQueue
        }

        return {
            undoRedo: {
                undo,
                redo,
                add,
                applyLatestSnapshot,
                clear,
                getUndoLastSnapshotParams,
                getRedoLastSnapshotParams,
                getUndoLastSnapshotLabel,
                getRedoLastSnapshotLabel,
                getUndoLastSnapshotId,
                getRedoLastSnapshotId,
                canUndo,
                canRedo
            },
            initWithSOQ,
            takeInitialSnapshotForUndoRedo,

            // Test Apis
            _buildAndApplyRevert: buildAndApplyRevert,
            _markNogosAndDependencies: markNogosAndDependencies
        }
    }

    return {
        name: 'undoRedo',
        createExtensionAPI,
        EVENTS
    }
}

export interface UndoRedoApi {
    undo(): string
    redo(): string
    add(snapshotLabel: string, params?: object, amendToSnapshotWithId?: string): void
    applyLatestSnapshot(): string
    clear(): void
    getUndoLastSnapshotParams(): any
    getRedoLastSnapshotParams(): any
    getUndoLastSnapshotLabel(): string
    getRedoLastSnapshotLabel(): string
    getUndoLastSnapshotId(): string
    getRedoLastSnapshotId(): string
    canUndo(): boolean
    canRedo(): boolean
}

export type UndoRedoExtApi = ExtensionAPI & {
    undoRedo: UndoRedoApi
    initWithSOQ(setOperationsQueue: SetOperationsQueue): void
    takeInitialSnapshotForUndoRedo(): void

    // Test Apis
    _buildAndApplyRevert(desiredSnapshot: SnapshotDal, currentSnapshot: SnapshotDal): void
    _markNogosAndDependencies(chain: SnapshotDal[]): Set<SnapshotDal>
}

export {createExtension, EVENTS}
