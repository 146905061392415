import type {CoreConfig, DAL, ExtensionAPI} from '@wix/document-manager-core'
import type {CompRef, Pointers, RawComponentExport} from '@wix/document-services-types'
import {exportRawComponent} from './raw/export'

export const exportComponent = (
    rootPointer: CompRef,
    dal: DAL,
    pointers: Pointers,
    extensionAPI: ExtensionAPI,
    {schemaService}: CoreConfig
): RawComponentExport => {
    return exportRawComponent(rootPointer, dal, pointers, extensionAPI, schemaService)
}
