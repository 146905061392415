const DEFINITIONS_TYPES = {
    GETTER: 'GETTER',
    ACTION: 'ACTION',
    DATA_MANIPULATION_ACTION: 'DATA_MANIPULATION_ACTION'
} as const

const METHOD_TYPES = {
    READ: 'read',
    ACTION: 'actions'
} as const

const LOG_TYPES = {
    DATA_MANIPULATION_ACTION: 'Q OPERATION ADDED',
    ACTION: 'IMMEDIATE ACTION'
} as const

const KNOWN_AFFECTED_BY_SCOPES_APIS = new Set([
    'deprecatedOldBadPerformanceApis.components.getAllComponents',
    'deprecatedOldBadPerformanceApis.components.getAllComponentsFromFull',
    'deprecatedOldBadPerformanceApis.components.getChildren',
    'deprecatedOldBadPerformanceApis.components.getChildrenFromFull',
    'deprecatedOldBadPerformanceApis.components.getContainer',
    'deprecatedOldBadPerformanceApis.components.getAncestors',
    'deprecatedOldBadPerformanceApis.components.getRepeatedComponents',
    'deprecatedOldBadPerformanceApis.components.get.byXYRelativeToStructure',
    'deprecatedOldBadPerformanceApis.components.get.byXYRelativeToScreen',
    'deprecatedOldBadPerformanceApis.components.get.getComponentsAtXYConsideringFrame',
    'deprecatedOldBadPerformanceApis.components.get.byType',
    'deprecatedOldBadPerformanceApis.components.get.byAncestor',
    'deprecatedOldBadPerformanceApis.components.modes.getFirstAncestorWithActiveModes',
    'deprecatedOldBadPerformanceApis.components.isDescendantOfComp',
    'deprecatedOldBadPerformanceApis.layouters.getParentCompWithOverflowHidden',
    'deprecatedOldBadPerformanceApis.mobile.hiddenComponents.get',
    'appStudio.buildWithOptions',
    'appStudioWidgets.props.set',
    'components.add',
    'components.alignment.alignComponents',
    'components.alignment.alignInContainer',
    'components.code.setNickname',
    'components.data.update',
    'components.design.update',
    'components.duplicate',
    'components.isDescendantOfComp',
    'components.layout.getRelativeToScreen',
    'components.layout.getRelativeToStructure',
    'components.layout.isShowOnFixedPosition',
    'components.layout.runtime.reattachLayoutAndUpdate',
    'components.layout.update',
    'components.layout.updateAndPreserveProportions',
    'components.layout.updateFixedPosition',
    'components.modes.getFirstAncestorWithActiveModes',
    'components.multiComponents.distribute',
    'components.properties.mobile.fork',
    'components.properties.update',
    'components.refComponents.unGhostifyComponent',
    'components.responsiveLayout.pin',
    'components.responsiveLayout.unpin',
    'components.setContainer',
    'components.slots.populate',
    'components.slots.remove',
    'documentMode.setComponentViewMode',
    'layouters.getParentCompWithOverflowHidden',
    'mobile.hiddenComponents.get',
    'mobile.hiddenComponents.hide',
    'mobile.hiddenComponents.show',
    'mobileConversion.runMobileMergeOnAllPagesWithoutLayoutOverwrite',
    'mobileConversion.runMobileMergeOnPageById',
    'mobileConversion.syncMobileSite',
    'pages.duplicate',
    'platform.controllers.connections.connect',
    'platform.controllers.connections.disconnect',
    'platform.controllers.connections.getConnectedComponents',
    'platform.controllers.connections.getControllerConnections',
    'platform.controllers.settings.set',
    'platform.controllers.settings.setExternalId',
    'platform.livePreview.refresh',
    'publish',
    'save',
    'viewMode.set'
])

export {DEFINITIONS_TYPES, METHOD_TYPES, LOG_TYPES, KNOWN_AFFECTED_BY_SCOPES_APIS}
