import _ from 'lodash'
import accessibilityUtils from './utils'
import type {PS} from '@wix/document-services-types'

export default {
    /**
     * @param {PS} ps
     * @returns {boolean}
     */
    get(ps: PS): boolean {
        const dataItem = ps.dal.get(accessibilityUtils.getAccessibilitySettingsDataPointer(ps))
        const vfFlag = _.get(dataItem, ['visualFocusDisabled'])

        return _.isUndefined(vfFlag) ? false : !vfFlag
    },
    /**
     * @param {PS} ps
     * @param {boolean} enabled
     */
    set(ps: PS, enabled: boolean) {
        if (!_.isBoolean(enabled)) {
            throw new Error('visualFocus.set accepts only boolean value')
        }

        const pointer = accessibilityUtils.getAccessibilitySettingsDataPointer(ps)
        const accessibilitySettings = ps.dal.get(pointer) || {}

        ps.dal.set(pointer, _.assign(accessibilitySettings, {visualFocusDisabled: !enabled}))
    }
}
