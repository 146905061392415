import type {Pointer, PS} from '@wix/document-services-types'
import documentMode from '../../documentMode/documentMode'

function removeComponentHook(ps: PS, compRef: Pointer) {
    const previewState = documentMode.getComponentPreviewState(ps) || {}
    if (previewState[compRef.id]) {
        documentMode.setComponentPreviewState(ps, compRef.id, null)
    }
}

export default {
    removeComponentHook
}
