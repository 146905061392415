export class EmptySnapshotMap {
    private snapshotMap: Map<string, Set<string>> = new Map()

    public get(key: string): string[] {
        return Array.from(this.snapshotMap.get(key) ?? new Set())
    }

    public set(key: string, value: string): void {
        const values = this.snapshotMap.get(key) ?? new Set()
        values.add(value)
        this.snapshotMap.set(key, values)
    }

    public delete(key: string): void {
        this.snapshotMap.delete(key)
    }
}
