import type {Pointer, PossibleViewModes, CompRef, PS, ScopePointer} from '@wix/document-services-types'

const isLoaded = (ps: PS, scopePointer: Pointer, viewMode?: PossibleViewModes) =>
    ps.extensionAPI.scopes.isLoaded(scopePointer, viewMode)
const extractScopeFromPointer = (ps: PS, pointer: Pointer) => ps.extensionAPI.scopes.extractScopeFromPointer(pointer)
const hasDefinedScopes = (ps: PS, compPointer: Pointer) => ps.extensionAPI.scopes.hasDefinedScopes(compPointer)
const getDefinedScopes = (ps: PS, compPointer: Pointer) => ps.extensionAPI.scopes.getDefinedScopes(compPointer)
const getRootComponent = (ps: PS, scopePointer: Pointer, viewMode?: PossibleViewModes) =>
    ps.extensionAPI.scopes.getRootComponent(scopePointer, viewMode)
const getScopeOwner = (ps: PS, scopePointer: Pointer, viewMode?: PossibleViewModes) =>
    ps.extensionAPI.scopes.getScopeOwner(scopePointer, viewMode)
const getTemplateCompPointer = (ps: PS, compPointer: Pointer) =>
    ps.extensionAPI.scopes.getTemplateCompPointer(compPointer)
const getTemplateRoot = (ps: PS, scopePointer: Pointer, viewMode?: PossibleViewModes) =>
    ps.extensionAPI.scopes.getTemplateRoot(scopePointer, viewMode)
const getScopesList = (ps: PS, scopePointer: ScopePointer) => ps.extensionAPI.scopes.getScopesList(scopePointer)
const getComponentInScope = (ps: PS, scopePointer: CompRef, scopes: ScopePointer | ScopePointer[]) =>
    ps.extensionAPI.scopes.getComponentInScope(scopePointer, scopes)

export default {
    isLoaded,
    extractScopeFromPointer,
    hasDefinedScopes,
    getDefinedScopes,
    getRootComponent,
    getScopeOwner,
    getTemplateCompPointer,
    getTemplateRoot,
    getScopesList,
    getComponentInScope
}
