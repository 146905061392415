import type {PublicMethodUtils} from '@wix/document-services-types'
import * as trace from '../apiUtils/dsQTrace'
import {debug} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    const {immediate, getter} = publicMethodUtils.extensionPublicAPI
    return {
        initMethod: debug.initialize,
        methods: {
            /**
             * DO NOT USE THIS IN PRODUCTION
             */
            debug: {
                trace: {
                    start: publicMethodUtils.defineAction(trace.startTrace),
                    stop: publicMethodUtils.defineAction(trace.endTrace),
                    clean: publicMethodUtils.defineAction(trace.cleanTrace),
                    get: publicMethodUtils.defineGetter(trace.get),
                    getActions: publicMethodUtils.defineGetter(trace.getActions),
                    getCalledMethods: publicMethodUtils.defineGetter(trace.getCalledMethods),
                    upload: publicMethodUtils.defineAction(trace.upload)
                },
                components: {
                    trace: {
                        register: immediate('debug.components.trace.register'),
                        unregister: immediate('debug.components.trace.unregister'),
                        unregisterAll: immediate('debug.components.trace.unregisterAll'),
                        registerNewComponents: immediate('debug.components.trace.registerNewComponents'),
                        stopRegisteringNewComponents: immediate('debug.components.trace.stopRegisteringNewComponents'),
                        get: getter('debug.components.trace.get'),
                        clear: immediate('debug.components.trace.clear')
                    }
                }
            }
        }
    }
}
