import type {PublicMethodUtils} from '@wix/document-services-types'
import {patterns} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            components: {
                patterns: {
                    getVariantsForItem: publicMethodUtils.defineGetter(patterns.getVariantsForItem),
                    apply: publicMethodUtils.defineDataManipulationAction(patterns.apply),
                    get: publicMethodUtils.defineGetter(patterns.get),
                    createVariant: publicMethodUtils.defineDataManipulationAction(
                        patterns.createRepeaterPatternVariant,
                        {
                            getReturnValue: patterns.getRepeaterPatternVariantToAddRef
                        }
                    ),
                    getRepeaterPatternVariants: publicMethodUtils.defineGetter(patterns.getRepeaterPatternVariants),
                    remove: publicMethodUtils.defineDataManipulationAction(patterns.remove)
                }
            }
        }
    }
}
