import _ from 'lodash'
import type {DocumentManager} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import {rgb2hex, str2rgba, isRgb, isRgba} from '@wix/color-kit'
import type {PageMigrator} from '../dataMigrationRunner'

const migratePage = (documentManager: DocumentManager, pageId: string) => {
    const {dal, pointers} = documentManager

    const backgroundMediaPointers = pointers.data
        .getDataItemsWithPredicate(data => data.type === 'BackgroundMedia', pageId)
        .concat(pointers.data.getDesignItemsWithPredicate(design => design.type === 'BackgroundMedia', pageId))

    _.forEach(backgroundMediaPointers, (backgroundMediaPointer: Pointer) => {
        const backgroundMedia = dal.get(backgroundMediaPointer)
        if (isRgb(backgroundMedia.color) || isRgba(backgroundMedia.color)) {
            const rgba = str2rgba(backgroundMedia.color)
            const hex = rgb2hex(rgba[0], rgba[1], rgba[2])
            const alpha = rgba[3]
            dal.set(backgroundMediaPointer, {
                ...backgroundMedia,
                color: hex,
                ...(alpha && {colorOpacity: alpha})
            })
        }
    })
}

const name = 'fixInvalidBackgroundMediaColor'
const version = 2

export const fixInvalidBackgroundMediaColor: PageMigrator = {
    migratePage,
    name,
    version
}
