import dataManipulation from './dataManipulation'
import seo from './seo'
import social from './social'
import favicon from './favicon'
import premiumFeatures from './premiumFeatures'
import generalInfo from './generalInfo'
import siteName from './siteName'
import siteDisplayName from './siteDisplayName'
import statistics from './statistics'
import compatibility from './compatibility'
import language from './language'

export default {
    PROPERTY_NAMES: dataManipulation.PROPERTY_NAMES,
    getProperty: dataManipulation.getProperty,
    setProperty: dataManipulation.setProperty,
    getRevertibleMetaDataInfo: dataManipulation.getRevertibleMetaDataInfo,
    getNonRevertibleMetaDataInfo: dataManipulation.getNonRevertibleMetaDataInfo,

    seo,
    social,
    favicon,
    premiumFeatures,
    generalInfo,
    siteName,
    siteDisplayName,
    statistics,
    compatibility,
    language
}
