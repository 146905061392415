import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import componentModes from '../../component/componentModes'

function convertModes(ps: PS, mobilePagePointer: Pointer) {
    const desktopPagePointer = ps.pointers.components.getDesktopPointer(mobilePagePointer)
    const desktopChildrenPointers = ps.pointers.full.components.getChildrenRecursively(desktopPagePointer)

    const modeIdsToCopy = _(desktopPagePointer)
        .concat(desktopChildrenPointers)
        .flatMap(desktopPointer => componentModes.getComponentModes(ps, desktopPointer))
        .compact()
        .filter(
            modeDefinition =>
                _.get(componentModes.metaData, [modeDefinition.type, 'mobileConversionConfig']) === 'copyOverrides'
        )
        .map('modeId')
        .value()

    if (_.isEmpty(modeIdsToCopy)) {
        return
    }

    const mobileChildren = ps.pointers.full.components.getChildrenRecursively(mobilePagePointer)
    _.forEach(mobileChildren, mobileChild => {
        const desktopChild = ps.pointers.components.getDesktopPointer(mobileChild)
        const desktopModes = ps.dal.full.get(ps.pointers.componentStructure.getModes(desktopChild))
        if (!desktopModes) {
            return
        }
        const overrides = _(desktopModes.overrides)
            .map(override => _.defaults({modeIds: _.intersection(override.modeIds, modeIdsToCopy)}, override))
            .filter(override => _.size(override.modeIds))
            .value()
        if (!_.isEmpty(overrides)) {
            ps.dal.full.set(ps.pointers.componentStructure.getModes(mobileChild), _.defaults({overrides}, desktopModes))
        }
    })
}

export default {convertModes}
