import _ from 'lodash'
import taskRunner from '../../saveAPI/lib/saveRunner'
import bi from '../../bi/bi'
import type {PS} from '@wix/document-services-types'
import type {SaveTaskDefinition} from '../../saveAPI/lib/registry'

function getBiCallbacks(ps: PS) {
    return {
        event: _.partial(bi.event, ps),
        error: _.partial(bi.error, ps)
    }
}

function runSaveTask(ps: PS, task: SaveTaskDefinition, isFullSave: boolean, onSuccess, onError) {
    const onlyWixCodeTasksRegistry = {
        primaryTask: task,
        secondaryTasks: []
    }

    function onTaskRunnerError(e) {
        onError(e.document)
    }

    taskRunner.runFunctionInSaveQueue(async () => {
        await ps.extensionAPI.continuousSave.forceSaveAndWaitForResult()
    })

    const runSave = isFullSave ? taskRunner.runFullSaveTasks : taskRunner.runPartialSaveTasks
    runSave(onlyWixCodeTasksRegistry, ps, onSuccess, onTaskRunnerError, getBiCallbacks(ps))
}

export default runSaveTask
