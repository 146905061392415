import {CreateExtArgs, Extension, ExtensionAPI, pointerUtils} from '@wix/document-manager-core'
import _ from 'lodash'
import type {
    CompLayout,
    Component,
    ComponentLayoutObject,
    CompStructure,
    Pointer,
    StyleRef,
    StyleRefOrStyleRefs
} from '@wix/document-services-types'
import {generateItemIdWithPrefix} from '../utils/dataUtils'
import type {ComponentsMetadataAPI} from './componentsMetadata/componentsMetadata'
import type {GridLayoutAPI} from './gridLayout'
import type {DefaultDefinitionsAPI} from './defaultDefinitions/defaultDefinitions'
import type {DataModelExtensionAPI} from './dataModel/dataModel'
import type {ThemeAPI} from './theme/theme'
import {getComponentType} from '../utils/dalUtils'
import {constants} from '..'
import type {SchemaExtensionAPI} from './schema/schema'
import {createEmptyStylableStyleItem} from '../utils/stylableUtils'
import {ReportableError} from '@wix/document-manager-utils'
import type {ComponentDefinitionExtensionAPI} from './componentDefinition'
import {VIEW_MODES} from '../constants/constants'
import {boundingLayout} from '@wix/santa-core-utils'

export interface AddOptions {
    addDefaultResponsiveLayout?: boolean
}

export interface ComponentsAPI extends ExtensionAPI {
    addComponent(
        parentPointer: Pointer,
        componentStructure: CompStructure,
        compPointerOverride?: Pointer,
        options?: AddOptions
    ): Pointer
    setComponent(componentDefinition: CompStructure, pageId: string, componentPointer: Pointer): void
    addLayoutsAsResponsiveLayout(componentPointer: Pointer, layouts?: Partial<ComponentLayoutObject>): void
    addStyles(componentPointer: Pointer, stylesDefinition?: object): void
    addBreakpointVariants(componentPointer: Pointer, breakpointsDefinition?: any): void
    removeComponent(componentPointer: Pointer): void
    buildDefaultComponentStructure(componentType: string): CompStructure
    getComponentLayout(componentPointer: Pointer): CompLayout
}

export interface ComponentsExtensionAPI extends ExtensionAPI {
    components: ComponentsAPI
}
export const EVENTS = {
    COMPONENTS: {
        BEFORE_REMOVE: 'COMPONENT_BEFORE_REMOVE',
        AFTER_REMOVE: 'COMPONENT_AFTER_REMOVE',
        AFTER_ADD_FROM_EXT: 'COMPONENT_AFTER_ADD_FROM_EXT',
        BEFORE_ADD: 'COMPONENT_BEFORE_ADD',
        BEFORE_ADD_ROOT: 'COMPONENT_BEFORE_ADD_ROOT',
        AFTER_ADD: 'COMPONENT_AFTER_ADD'
    }
}

export const DEFAULT_COMP_LAYOUT = {
    width: 100,
    height: 100,
    x: 0,
    y: 0
}

const createExtension = (): Extension => {
    const createExtensionAPI = ({dal, pointers, extensionAPI, eventEmitter}: CreateExtArgs): ExtensionAPI => {
        const defaultDefinitions = () => extensionAPI.defaultDefinitions as DefaultDefinitionsAPI
        const componentMetaData = () => (extensionAPI as ComponentsMetadataAPI).componentsMetadata
        const gridLayout = () => extensionAPI.gridLayout as GridLayoutAPI
        const dataModel = () => (extensionAPI as DataModelExtensionAPI).dataModel
        const theme = () => extensionAPI.theme as ThemeAPI

        const addData = (componentPointer: Pointer, pageId: string, dataDefinition?: object) => {
            if (dataDefinition) {
                dataModel().components.addItem(componentPointer, 'data', dataDefinition)
            }
        }

        const addDesign = (componentPointer: Pointer, pageId: string, designDefinition?: object) => {
            if (designDefinition) {
                dataModel().components.addItem(componentPointer, 'design', designDefinition)
            }
        }

        const addProperties = (componentPointer: Pointer, pageId: string, propertiesDefinition?: object) => {
            if (propertiesDefinition) {
                dataModel().components.addItem(componentPointer, 'props', propertiesDefinition)
            }
        }

        const addPresets = (componentPointer: Pointer, presetsDefinition?: object) => {
            if (presetsDefinition) {
                dataModel().components.addItem(componentPointer, constants.DATA_TYPES.presets, presetsDefinition)
            }
        }

        const addStyles = (componentPointer: Pointer, stylesDefinition?: object) => {
            if (stylesDefinition) {
                dataModel().components.addItem(componentPointer, constants.DATA_TYPES.theme, stylesDefinition)
            }
        }

        const addMobileHints = (componentPointer: Pointer, mobileHintsDefintion?: object) => {
            if (mobileHintsDefintion) {
                dataModel().components.addItem(componentPointer, constants.DATA_TYPES.mobileHints, mobileHintsDefintion)
            }
        }

        const createDalComponent = (componentDefinition: CompStructure, pageId: string, componentPointer: Pointer) => {
            const component = {
                layout: componentDefinition.layout ? componentDefinition.layout : {},
                metaData: {pageId},
                type: componentDefinition.type ?? 'Component',
                id: componentPointer.id,
                componentType: componentDefinition.componentType
            } as Component

            if (componentDefinition.styleId) {
                component.styleId = componentDefinition.styleId
            }
            if (componentDefinition.skin) {
                component.skin = componentDefinition.skin
            }

            if (componentMetaData().isContainer(componentDefinition.componentType)) {
                component.components = []
            }
            return component
        }

        const addComponentToParent = (parentPointer: Pointer, componentPointer: Pointer) => {
            const componentsPtr = pointerUtils.getInnerPointer(parentPointer, 'components')
            const components = _.cloneDeep(dal.get(componentsPtr))
            components.push(componentPointer.id)
            dal.set(componentsPtr, components)
            dal.set({...componentPointer, innerPath: ['parent']}, parentPointer.id)
        }

        const addLayoutsAsResponsiveLayout = (componentPointer: Pointer, layouts?: Partial<ComponentLayoutObject>) => {
            const layout = {...layouts, variableConnections: [], type: 'SingleLayoutData'}
            const refArray = defaultDefinitions().createRefArrayDefinition([layout])
            dataModel().components.addItem(componentPointer, 'layout', refArray)
        }

        const addBreakpointVariants = (componentPointer: Pointer, breakpointsDefinition?: any) => {
            const breakpoints = {
                values: breakpointsDefinition,
                type: 'BreakpointsData',
                componentId: componentPointer.id
            }
            dataModel().components.addItem(componentPointer, 'variants', breakpoints)
        }

        const setComponent = (componentDefinition: CompStructure, pageId: string, componentPointer: Pointer) => {
            const component = createDalComponent(componentDefinition, pageId, componentPointer)
            dal.set(componentPointer, component)
        }

        const addSystemStyle = (componentDefinition: CompStructure) => {
            if (componentDefinition.styleId) {
                theme().ensureDefaultStyleItemExists(componentDefinition.componentType, componentDefinition.styleId)
            }
        }

        const addComponent = (
            parentPointer: Pointer,
            compDefinition: CompStructure,
            compPointerOverride?: Pointer,
            options: AddOptions = {}
        ): Pointer => {
            const isResponsive = dal.get(pointers.general.isResponsive())
            const componentDefinition = defaultDefinitions().createComponentDefinition(
                compDefinition,
                {
                    parentPointer
                },
                options
            )
            eventEmitter.emit(EVENTS.COMPONENTS.BEFORE_ADD, compDefinition)
            const parentComponent = dal.get(parentPointer)
            if (!parentComponent) {
                throw new Error('Parent does not exist')
            }
            const componentPointer = compPointerOverride
                ? compPointerOverride
                : pointerUtils.getPointer(generateItemIdWithPrefix('comp'), 'DESKTOP')
            const pagePointer = pointers.structure.getPageOfComponent(parentPointer)
            const pageId = pagePointer.id
            setComponent(componentDefinition, pageId, componentPointer)
            const addResponsiveLayout = _.get(options, ['addDefaultResponsiveLayout'])

            if (addResponsiveLayout || isResponsive) {
                addLayoutsAsResponsiveLayout(componentPointer, componentDefinition.layouts)
            }
            addData(componentPointer, pageId, componentDefinition.data)
            addDesign(componentPointer, pageId, componentDefinition.design)
            addProperties(componentPointer, pageId, componentDefinition.props)
            addComponentToParent(parentPointer, componentPointer)
            if (!componentDefinition.style) {
                addSystemStyle(componentDefinition)
            } else {
                addStyles(componentPointer, componentDefinition.style as StyleRefOrStyleRefs)
            }
            addPresets(componentPointer, componentDefinition.presets)
            addMobileHints(componentPointer, componentDefinition.mobileHints)

            if ((addResponsiveLayout || isResponsive) && parentComponent.type === 'Page') {
                gridLayout().shiftItemsToBeUnderTarget(parentPointer, componentPointer)
            }
            if (componentDefinition.mobileStructure) {
                const mobilePointer = {...componentPointer, type: VIEW_MODES.MOBILE}
                const currStructure = dal.get(componentPointer)
                const mobileStructure = {...currStructure, ...componentDefinition.mobileStructure, metaData: {pageId}}
                dal.set(mobilePointer, mobileStructure)
                addComponentToParent({id: parentPointer.id, type: VIEW_MODES.MOBILE}, mobilePointer)
            }
            eventEmitter.emit(EVENTS.COMPONENTS.AFTER_ADD_FROM_EXT, componentPointer, pagePointer)
            return componentPointer
        }

        const removeComponentFromParent = (parentPointer: Pointer, removedComponentId: string) => {
            const componentsPtr = pointerUtils.getInnerPointer(parentPointer, 'components')
            const components = dal.get(componentsPtr).filter((id: string) => id !== removedComponentId)
            dal.set(componentsPtr, components)
        }

        const removeComponentFromParentIfParentExists = (parentPointer: Pointer | null, removedComponentId: string) => {
            if (!_.isNil(parentPointer)) {
                removeComponentFromParent(parentPointer, removedComponentId)
            }
        }

        const _removeComponent = (componentPointer: Pointer, removingParent: boolean) => {
            if (componentPointer.type !== constants.VIEW_MODES.DESKTOP) {
                throw Error('removing components from non desktop view mode is not supported')
            }
            const compType = getComponentType(dal, componentPointer)
            const compChildren = dal.get(pointerUtils.getInnerPointer(componentPointer, 'components'))

            for (const childId of compChildren ?? []) {
                _removeComponent(pointerUtils.getPointer(childId, componentPointer.type), true)
            }

            eventEmitter.emit(EVENTS.COMPONENTS.BEFORE_REMOVE, componentPointer)

            const mobilePointer = pointerUtils.getPointer(componentPointer.id, constants.VIEW_MODES.MOBILE)
            if (dal.has(mobilePointer)) {
                const mobileParent = pointers.structure.getParent(mobilePointer)
                dal.remove(mobilePointer)
                removeComponentFromParentIfParentExists(mobileParent, mobilePointer.id)
            }

            const compParent = pointers.structure.getParent(componentPointer)

            dataModel().removeItemRecursively(componentPointer)
            removeComponentFromParentIfParentExists(compParent, componentPointer.id)

            eventEmitter.emit(EVENTS.COMPONENTS.AFTER_REMOVE, componentPointer, compType, removingParent)
        }

        const removeComponent = (componentPointer: Pointer) => {
            _removeComponent(componentPointer, false)
        }

        const buildDefaultComponentStructure = (componentType: string): CompStructure => {
            const {schemaAPI} = extensionAPI as SchemaExtensionAPI
            const {componentDefinition} = extensionAPI as ComponentDefinitionExtensionAPI

            const dataModelAPI = dataModel()
            const compDefinition = schemaAPI.getDefinition(componentType)
            if (!_.isString(componentType)) {
                throw new ReportableError({
                    errorType: 'DEFAULT_STRUCTURE_TYPE_MISSING',
                    message: 'Must pass componentType as string'
                })
            }

            if (!compDefinition) {
                throw new ReportableError({
                    errorType: 'DEFAULT_STRUCTURE_TYPE_NOT_SUPPORTED',
                    message: 'Component type is not supported',
                    extras: {componentType}
                })
            }

            const styleId = _.head(_.keys(compDefinition.styles))
            let style: StyleRef | undefined
            if (!styleId) {
                const skin: string | undefined = _.head(compDefinition.skins || [])
                if (skin) {
                    style = dataModelAPI.createStyleItemByType('TopLevelStyle')
                    style.skin = skin
                }
                // TODO: remove once Stylable deprecation is complete
                if (compDefinition.isStylableComp) {
                    style = {
                        ...dataModelAPI.createStyleItemByType('ComponentStyle'),
                        ...createEmptyStylableStyleItem(componentType)
                    } as StyleRef
                }
            }

            const defaultDataItemType = _.includes(compDefinition.dataTypes, '')
                ? ''
                : _.head(compDefinition.dataTypes as string[])
            let defaultDataItem
            if (defaultDataItemType) {
                defaultDataItem = dataModelAPI.createDataItemByType(defaultDataItemType)
            }

            const defaultDesignItemType = _.includes(compDefinition.designDataTypes, '')
                ? ''
                : _.head(compDefinition.designDataTypes as string[])
            let defaultDesignItem
            if (defaultDesignItemType) {
                defaultDesignItem = dataModelAPI.createDesignItemByType(defaultDesignItemType)
            }

            const defaultPropertiesItemType =
                compDefinition.propertyType ||
                (_.includes(compDefinition.propertyTypes, '') ? '' : _.head(compDefinition.propertyTypes))
            let defaultPropertiesItem
            if (defaultPropertiesItemType) {
                defaultPropertiesItem = dataModelAPI.createPropertiesItemByType(defaultPropertiesItemType)
            }

            const defaultCompStructure: CompStructure = {
                layout: _.clone(DEFAULT_COMP_LAYOUT),
                componentType,
                data: defaultDataItem,
                props: defaultPropertiesItem,
                design: defaultDesignItem,
                style: styleId ?? style
            }

            if (componentDefinition.isContainer(componentType)) {
                _.assign(defaultCompStructure, {components: []})
            }

            if (compDefinition.requiredChildType) {
                _.assign(defaultCompStructure, {
                    components: [buildDefaultComponentStructure(compDefinition.requiredChildType)]
                })
            }

            return defaultCompStructure
        }

        const getComponentLayout = (componentPointer: Pointer): CompLayout | null => {
            if (!componentPointer) {
                return null
            }

            const layout = _.cloneDeep(dal.get(pointers.getInnerPointer(componentPointer, 'layout')))
            if (!layout) {
                return null
            }

            // this function is missing code that is supposed to handle layouts with docked=true, due to the original code relying on the viewer

            return _.merge(layout, {bounding: boundingLayout.getBoundingLayout(layout)})
        }

        return {
            components: {
                addComponent,
                setComponent,
                addLayoutsAsResponsiveLayout,
                addBreakpointVariants,
                addStyles,
                removeComponent,
                buildDefaultComponentStructure,
                getComponentLayout
            }
        }
    }

    return {
        name: 'components',
        EVENTS,
        createExtensionAPI
    }
}

export {createExtension}
