import _ from 'lodash'
import hooks from '../../hooks/hooks'
import component from '../../component/component'
import structure from '../../structure/structure'
import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'
import appInstallationAndDeletionEvents from './appInstallationAndDeletionEvents'
import installedTpaAppsOnSiteService from './installedTpaAppsOnSiteService'
import clientSpecMapService from './clientSpecMapService'
import tpaComponentCommonService from './tpaComponentCommonService'
import compStructure from '../compStructure'
import tpaWidgetLayoutHelper from './tpaWidgetLayoutService'
import tpaDeleteService from './tpaDeleteService'
import tpaDataService from './tpaDataService'
import documentModeInfo from '../../documentMode/documentModeInfo'
import responsiveUtils from '../utils/responsiveUtils'
import dsConstants from '../../constants/constants'
import type {AppDefinitionId, Callback, CompRef, Pointer, PS} from '@wix/document-services-types'
import tpaNotifyDeleteService from './tpaNotifyDeleteService'

const isGlued = function (widgetData) {
    return _.has(widgetData, ['gluedOptions']) && !_.isNull(widgetData.gluedOptions)
}

const addWidgetAfterProvision = function (
    ps: PS,
    componentToAddPointer: CompRef,
    options,
    responseDefinitionData,
    completeCallback?,
    onError?,
    waitForSOQ = true
) {
    options = options || {}

    let invokeAddAppCallbacks = true
    if (_.isBoolean(_.get(options, ['invokeAddAppCallbacks']))) {
        invokeAddAppCallbacks = _.get(options, ['invokeAddAppCallbacks'])
    }
    let addedCompsRef = [componentToAddPointer]
    let addedHiddenPages, essentialWidgets
    const firstAdd = !installedTpaAppsOnSiteService.isAppDefinitionIdInstalled(
        ps,
        responseDefinitionData.appDefinitionId as AppDefinitionId
    )
    if (firstAdd) {
        if (clientSpecMapService.hasSections(ps, responseDefinitionData)) {
            if (clientSpecMapService.hasMainSection(ps, responseDefinitionData)) {
                if (_.isFunction(onError)) {
                    onError({
                        onError: 'Invalid attempt to add a widget before adding its app main sections',
                        success: false
                    })
                }
                return
            }
            addedHiddenPages = tpaComponentCommonService.addHiddenPages(
                ps,
                responseDefinitionData,
                options.componentOptions
            )
        }
        essentialWidgets = addEssentialWidgets(ps, responseDefinitionData, options.componentOptions)
    }

    addWidget(ps, componentToAddPointer, options, responseDefinitionData)
    addedCompsRef = addedCompsRef.concat(addedHiddenPages, essentialWidgets)
    if (firstAdd && invokeAddAppCallbacks) {
        appInstallationAndDeletionEvents.invokeAddAppCallbacks(responseDefinitionData.appDefinitionId, options)
    }

    const addPlatformFunc = () =>
        tpaComponentCommonService
            .addPlatformAppIfNeeded(ps, responseDefinitionData, options)
            .then(_.partial(invokeWidgetCallback, options, componentToAddPointer, true, addedCompsRef)) // eslint-disable-line promise/prefer-await-to-then
            .catch(_.partial(invokeWidgetCallback, options, componentToAddPointer, false, addedCompsRef)) // eslint-disable-line promise/prefer-await-to-then

    if (waitForSOQ) {
        if (ps.setOperationsQueue.isRunningSetOperation() && firstAdd) {
            ps.setOperationsQueue.executeAfterCurrentOperationDone(addPlatformFunc)
        } else {
            Promise.resolve()
                .then(_.partial(invokeWidgetCallback, options, componentToAddPointer, true, addedCompsRef)) // eslint-disable-line promise/prefer-await-to-then
                .catch(_.partial(invokeWidgetCallback, options, componentToAddPointer, false, addedCompsRef)) // eslint-disable-line promise/prefer-await-to-then
        }

        if (completeCallback) {
            completeCallback(ps)
        }
    } else {
        addPlatformFunc().then(() => completeCallback(ps)) // eslint-disable-line promise/prefer-await-to-then
    }

    return componentToAddPointer
}

const invokeWidgetCallback = function (options, componentToAddPointer: Pointer, success, addedCompsRef?) {
    if (options.callback) {
        options.callback({
            comp: componentToAddPointer,
            addedCompsRef,
            success
        })
    }
}

const addWidget = function (ps: PS, componentToAddPointer: CompRef, options: any, responseDefinitionData: any) {
    const {applicationId, appDefinitionId} = responseDefinitionData
    const widgetId = options.widgetId || getDefaultWidgetId(ps, responseDefinitionData)
    const widgetData = _.get(responseDefinitionData, ['widgets', widgetId])
    const tpaWidgetId = _.get(widgetData, ['tpaWidgetId'])
    let tpaWidgetStyle = options.styleId || options.style
    if (tpaWidgetId && _.get(options, ['componentOptions', 'widget', 'tpaWidgetId']) === tpaWidgetId) {
        options.pageId = options.componentOptions.widget.wixPageId || options.pageId
        options.showOnAllPages = options.componentOptions.widget.allPages || options.showOnAllPages
        tpaWidgetStyle = options.componentOptions.styleId || tpaWidgetStyle
    }

    let pageId = options.pageId || ps.siteAPI.getFocusedRootId()

    if (!widgetId || !pageId || !widgetData) {
        throw new Error('invalid params')
    }

    let componentDefinition
    let layout

    if (options.componentDefinition) {
        componentDefinition = options.componentDefinition

        if (
            (componentDefinition.data?.applicationId &&
                componentDefinition.data.applicationId !== String(applicationId)) ||
            (componentDefinition.data && !componentDefinition.data.applicationId)
        ) {
            // NOTE: applicationId could be different if componentDefinition is from another side
            componentDefinition = Object.assign({}, componentDefinition, {
                data: Object.assign({}, componentDefinition.data, {
                    applicationId: String(applicationId)
                })
            })
        }
    } else {
        const defaultSize = {
            width: widgetData.defaultWidth,
            height: widgetData.defaultHeight
        }
        if (options.layout) {
            _.defaults(options.layout, defaultSize)
        }

        layout = {
            width: widgetData.defaultWidth || 0,
            height: widgetData.defaultHeight || 0,
            defaultPosition: widgetData.defaultPosition || _.get(options, ['layout', 'defaultPosition']) || {}
        }

        if (
            widgetData.defaultShowOnAllPages ||
            options.showOnAllPages ||
            isGlued(widgetData) ||
            layout.defaultPosition.region === 'header' ||
            layout.defaultPosition.region === 'footer'
        ) {
            pageId = 'masterPage'

            if (options.useRelativeToContainerLayout) {
                // in case if component should be attached to master page, ignore containerRef
                delete options.containerRef
                delete options.useRelativeToContainerLayout
            }
        }

        if (isGlued(widgetData)) {
            componentDefinition = compStructure.getGluedWidgetStructure(
                applicationId,
                appDefinitionId,
                widgetData,
                layout,
                tpaWidgetStyle
            )
        } else {
            componentDefinition = compStructure.getWidgetStructure(
                applicationId,
                appDefinitionId,
                widgetData.widgetId,
                tpaWidgetLayoutHelper.getCompLayoutFrom(
                    ps,
                    layout,
                    options.layout,
                    options.useRelativeToContainerLayout ? options.containerRef : null
                ),
                tpaWidgetStyle
            )
        }

        if (options.responsiveLayout) {
            responsiveUtils.replaceLayout(componentDefinition, options.responsiveLayout)
        }
    }
    ps.extensionAPI.logger.interactionStarted(dsConstants.TPA_WIDGET_ADDITION.GET_CONTAINER, {
        extras: {
            options,
            viewMode: documentModeInfo.getViewMode(ps),
            pageId,
            layout,
            componentToAddPointer,
            appDefinitionId
        }
    })
    const containerPointer = options.containerRef || getContainerPointer(ps, pageId, options, layout)
    ps.extensionAPI.logger.interactionEnded(dsConstants.TPA_WIDGET_ADDITION.GET_CONTAINER, {
        extras: {viewMode: documentModeInfo.getViewMode(ps), containerPointer, componentToAddPointer, appDefinitionId}
    })
    if (!componentToAddPointer) {
        componentToAddPointer = component.getComponentToAddRef(ps, containerPointer)
    }

    compStructure.convertStyleIdToStyleDef(ps, componentDefinition)

    if (componentDefinition) {
        hooks.executeHook(hooks.HOOKS.ADD_TPA.COMPONENT_DEFINITION_MODIFIER, undefined, [
            componentDefinition,
            containerPointer
        ])
    }
    ps.extensionAPI.logger.interactionStarted(dsConstants.TPA_WIDGET_ADDITION.ADD_WIDGET, {
        extras: {containerPointer, componentDefinition, appDefinitionId}
    })
    component.add(ps, componentToAddPointer, containerPointer, componentDefinition)
    ps.extensionAPI.logger.interactionEnded(dsConstants.TPA_WIDGET_ADDITION.ADD_WIDGET, {
        extras: {containerPointer, componentDefinition, appDefinitionId}
    })
    stretchToFullWidthIfNeeded(ps, componentToAddPointer, widgetData, options)

    tpaComponentCommonService.setPrefetchPageBehaviorIfNeeded(ps, componentToAddPointer, responseDefinitionData)

    if (_.isString(options.presetId) && !_.isEmpty(options.presetId)) {
        tpaDataService.set(ps, componentToAddPointer, 'presetId', options.presetId, tpaDataService.SCOPE.COMPONENT)
    }
    return componentToAddPointer
}

const getContainerPointer = function (ps: PS, pageId: string, options: any, layout: any): CompRef {
    const pagePointer = ps.pointers.components.getPage(pageId, documentModeInfo.getViewMode(ps))
    let containerPointer = pagePointer
    if (
        options.parentContainerRef &&
        ps.pointers.components.isSameComponent(
            ps.pointers.components.getPageOfComponent(options.parentContainerRef),
            pagePointer
        )
    ) {
        containerPointer = options.parentContainerRef
    }
    if (_.get(layout, ['defaultPosition', 'region']) === 'header') {
        containerPointer = componentDetectorAPI.getSiteHeader(ps)
    }
    return containerPointer
}

const stretchToFullWidthIfNeeded = function (ps: PS, componentToAddPointer: Pointer, widgetData, options) {
    const shouldStretchCase1 =
        widgetData.canBeStretched && widgetData.shouldBeStretchedByDefault && !options.dontStretch
    const shouldStretchCase2 = options.dontStretch === false
    const shouldStretchComp = shouldStretchCase1 || shouldStretchCase2
    if (shouldStretchComp) {
        structure.setDock(ps, componentToAddPointer, {left: {vw: 0}, right: {vw: 0}})
    }
}

const addEssentialWidgets = function (ps: PS, appData, componentOptions) {
    const widgets = clientSpecMapService.widgetsToAutoAddToSite(ps, appData)
    // eslint-disable-next-line lodash/prefer-reject
    return _(widgets)
        .filter(widget => !widget.default)
        .map(widget =>
            addWidget(
                ps,
                undefined,
                {
                    widgetId: widget.widgetId,
                    componentOptions
                },
                appData
            )
        )
        .value()
}

const getDefaultWidgetId = function (ps: PS, appData) {
    const appWidgets = _.filter(appData.widgets, widget => _.isNil(widget.appPage) || _.isUndefined(widget.appPage))
    const defaultWidget = _.find(appWidgets, {default: true})
    return (
        _.get(defaultWidget, ['widgetId']) || (appWidgets && !_.isEmpty(appWidgets) && appWidgets[0].widgetId) || null
    )
}

const duplicateWidget = function (ps: PS, compPointer: CompRef, pageId: string) {
    pageId = pageId || ps.siteAPI.getFocusedRootId()
    const pagePointer = ps.pointers.components.getPage(pageId, documentModeInfo.getViewMode(ps))
    if (!ps.dal.get(pagePointer)) {
        throw new Error('no such component')
    }
    const dupCompPointer = component.getComponentToDuplicateRef(ps, compPointer, pagePointer)
    component.duplicate(ps, dupCompPointer, compPointer, pagePointer)
    // TODO: this method should probably return a ref to the new comp (see component.duplicateComponent)
}

const notifyBeforeWidgetDeleteInternal = function (ps: PS, compPointer: CompRef, onComplete: Callback = _.noop) {
    const compData = component.data.get(ps, compPointer)
    const {appDefinitionId} = compData
    tpaNotifyDeleteService.notifyWidgetAboutToDelete(ps, compPointer, appDefinitionId, onComplete)
}

const notifyBeforeWidgetDelete = function (ps: PS, compPointer: CompRef) {
    notifyBeforeWidgetDeleteInternal(
        ps,
        compPointer,
        ps.setOperationsQueue.asyncPreDataManipulationComplete.bind(ps.setOperationsQueue)
    )
}

const deleteWidget = function (ps: PS, componentPointer: CompRef, onComplete?) {
    const pagesToBeRemoved = getPagesToBeRemovedWithWidget(ps, componentPointer)
    const compNode = component.data.get(ps, componentPointer)
    const {appDefinitionId} = compNode
    const completeCallback = () => {
        tpaDeleteService.deleteTpaCompleteCallback(ps, [appDefinitionId])
        if (_.isFunction(onComplete)) {
            onComplete(pagesToBeRemoved)
        }
    }
    return tpaDeleteService.deleteWidget.apply(tpaComponentCommonService, [
        ps,
        componentPointer,
        appDefinitionId,
        completeCallback
    ])
}

const getPagesToBeRemovedWithWidget = function (ps: PS, compPointer: Pointer) {
    const compData = component.data.get(ps, compPointer)
    const appDefinitionId = _.get(compData, ['appDefinitionId']) as AppDefinitionId
    const installedWidgets = installedTpaAppsOnSiteService.getWidgetsByAppDefId(ps, appDefinitionId)
    const lastInstalledMainWidget = tpaNotifyDeleteService.isLastInstalledMainWidget(
        installedWidgets,
        ps,
        appDefinitionId,
        compPointer
    )
    if (lastInstalledMainWidget) {
        const appDefIdsToDelete = _.union(
            [appDefinitionId],
            _.map(installedTpaAppsOnSiteService.getInstalledDependentAppsData(ps, appDefinitionId), 'appDefinitionId')
        )
        return installedTpaAppsOnSiteService.getPagesByAppDefinitionIds(ps, appDefIdsToDelete)
    }
    return []
}
const getTPAWidgetDeleteInteractionParams = (ps: PS, compPointer: Pointer) => {
    const compData = component.data.get(ps, compPointer)
    const applicationId = _.get(compData, ['applicationId'])
    const appDefinitionId = _.get(clientSpecMapService.getAppData(ps, applicationId), ['appDefinitionId'])

    return {app_id: appDefinitionId, comp_pointer: compPointer}
}

const addWidgetAfterProvisionAsync = (ps: PS, componentToAddRef: CompRef, options, appData) =>
    new Promise((resolve, reject) => addWidgetAfterProvision(ps, componentToAddRef, options, appData, resolve, reject))

export default {
    isGlued,
    addWidgetAfterProvision,
    addWidgetAfterProvisionAsync,
    duplicateWidget,
    notifyBeforeWidgetDelete,
    notifyBeforeWidgetDeleteInternal,
    deleteWidget,
    getPagesToBeRemovedWithWidget,
    invokeWidgetCallback,
    getTPAWidgetDeleteInteractionParams
}
