import {QueryParams, ReportableError} from '@wix/document-manager-utils'
import type {AppDefinitionId, Callback1, CompRef, Pointer, PS} from '@wix/document-services-types'
import {guidUtils} from '@wix/santa-core-utils'
import _ from 'lodash'
import {tpa} from '@wix/santa-ds-libs'
import component from '../../component/component'
import componentStylesAndSkinsAPI from '../../component/componentStylesAndSkinsAPI'
import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'
import hooks from '../../hooks/hooks'
import page from '../../page/page'
import platformStateService from '../../platform/services/platformStateService'
import {contextAdapter} from '../../utils/contextAdapter'
import tpaConstants from '../constants'
import tpaUtils from '../utils/tpaUtils'
import appMarketService from './appMarketService'
import appStoreService from './appStoreService'
import clientSpecMapService from './clientSpecMapService'
import installedTpaAppsOnSiteService from './installedTpaAppsOnSiteService'
import pendingAppsService from './pendingAppsService'
import tpaComponentCommonService from './tpaComponentCommonService'
import tpaEventHandlersService from './tpaEventHandlersService'
import tpaSectionService from './tpaSectionService'
import tpaWidgetService from './tpaWidgetService'
import tpaStyleService from './tpaStyleService'
import {loadRemoteDataForApp} from '../../platform/services/loadRemoteWidgetMetaData'
import type {
    DistributorExtensionAPI,
    TpaCompPreviewDataChange,
    TpaCompStateChange,
    TpaPostMessageToApp
} from '@wix/document-manager-extensions'

const provisionApp = function (
    ps: PS,
    componentToAddPointer: Pointer,
    type: string,
    appDefinitionId: string,
    params,
    onSuccess,
    onError
) {
    params = params || {}
    const existingAppData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
    appMarketService.requestAppMarketDataToBeCached(ps, appDefinitionId)

    //when coming from ADI (sourceTemplateId)
    if (!existingAppData) {
        appStoreService.provision(
            ps,
            [{appDefinitionId, version: params.appVersion, sourceTemplateId: params.sourceTemplateId}],
            provisionResponse => {
                const {clientSpecMap} = provisionResponse
                const appData = _.find(clientSpecMap, {appDefinitionId})

                loadRemoteDataForApp(ps, appData)
                    .then(() => {
                        onSuccess(appData)
                    })
                    .catch(() => {
                        onError()
                    })
            },
            onError
        )
    } else {
        if (pendingAppsService.isPending(ps, existingAppData)) {
            pendingAppsService.add(existingAppData)
        }
        platformStateService.clearAppPendingAction(ps, appDefinitionId)
        if (_.get(params, 'appVersion')) {
            ps.dal.set(ps.pointers.platform.getSemanticAppVersionPointer(appDefinitionId), _.get(params, 'appVersion'))
        }
        onSuccess(existingAppData)
    }
}

const addApp = function (
    privateServices: PS,
    componentToAddPointer: Pointer,
    type: string,
    params,
    appData,
    onSuccess,
    onError,
    waitForSOQ = true
) {
    clientSpecMapService.registerAppData(privateServices, appData)
    try {
        getAddCompFunctionByType(type)(
            privateServices,
            componentToAddPointer,
            params,
            appData,
            onSuccess,
            onError,
            waitForSOQ
        )
    } catch (e) {
        if (onError) {
            onError(e)
        }
    }
}

const getAddCompFunctionByType = function (type: string) {
    const compTpaAddFunctions = {
        TPAWidget: tpaWidgetService.addWidgetAfterProvision,
        TPASection: tpaSectionService.addSectionAfterProvision
    }

    return compTpaAddFunctions[type]
}

const settingsUpdated = function (ps: PS, appDefinitionId, targetCompId: string, message: any) {
    if (targetCompId === '*') {
        const comps = installedTpaAppsOnSiteService.getAllAppCompsByAppDefIds(ps, [appDefinitionId])
        _.forEach(comps, function (comp) {
            const appDefId = _.get(component.data.get(ps, comp), 'appDefinitionId')
            tpaEventHandlersService.callSettingsUpdateCallback(ps, comp.id, message, appDefId)
        })
    } else {
        const compRef = componentDetectorAPI.getComponentById(ps, targetCompId)
        const appDefId = _.get(component.data.get(ps, compRef), 'appDefinitionId')
        tpaEventHandlersService.callSettingsUpdateCallback(ps, targetCompId, message, appDefId)
    }
}

const refreshApp = function (ps: PS, comps, queryParams?: QueryParams) {
    const {distributor} = ps.extensionAPI as DistributorExtensionAPI
    queryParams = _.merge(queryParams || {}, {
        cacheKiller: `${_.now()}`
    })

    _.forEach(comps, function (comp) {
        const compId = _.get(comp, 'id')
        if (compId) {
            const data = ps.siteAPI.getTpaCompPreviewData(compId) || {}
            const previewData = _.defaults(
                {
                    queryParams
                },
                data
            )

            tpaCompPreviewDataSyncer(ps, compId, previewData)
            distributor.distributeMessageAfterApproval<TpaCompPreviewDataChange>('tpaCompPreviewDataChange', {
                compId,
                data: previewData
            })

            const appDefinitionId = _.get(comp, 'appDefinitionId')
            tpaUtils.notifyTPAAPICalledFromPanel(ps, appDefinitionId)
        }
    })
}

const isSection = function (ps: PS, compPointer: Pointer) {
    const compType = component.getType(ps, compPointer)
    return compType === tpaConstants.COMP_TYPES.TPA_SECTION || compType === tpaConstants.TPA_COMP_TYPES.TPA_SECTION
}

const getSectionRefByPageId = function (ps: PS, pageId: string) {
    const pagePointers = page.getPage(ps, pageId)
    const tpaChildrenPointers = component.getTpaChildren(ps, pagePointers)
    return _.find(tpaChildrenPointers, function (pointer) {
        return isSection(ps, pointer)
    })
}

const setExternalId = function (
    ps: PS,
    compPointer: Pointer,
    newReferenceId: string,
    callback: Callback1<string>,
    preventRefresh?: boolean
) {
    const {distributor} = ps.extensionAPI as DistributorExtensionAPI

    compStateSyncer(ps, compPointer.id, {preventRefresh: Boolean(preventRefresh)})
    distributor.distributeMessageAfterApproval<TpaCompStateChange>('tpaCompStateChange', {
        compId: compPointer.id,
        data: {preventRefresh: Boolean(preventRefresh)}
    })
    const appDefinitionId = _.get(component.data.get(ps, compPointer), 'appDefinitionId')
    const componentData = component.data.get(ps, compPointer)
    const referenceId = componentData?.referenceId
    if (referenceId !== newReferenceId) {
        tpaUtils.notifyTPAAPICalledFromPanel(ps, appDefinitionId)
    }

    component.data.update(ps, compPointer, {referenceId: newReferenceId}, true)
    if (newReferenceId) {
        callback(`ExternalId: ${newReferenceId} will be saved when the site will be saved`)
    } else {
        callback('ExternalId: will reset when the site will be saved')
    }
}

const getExternalId = function (ps: PS, compPointer: Pointer) {
    const compData = component.data.get(ps, compPointer, null, true)
    return compData?.referenceId
}

const postBackThemeData = function (ps: PS, compId: string, changedData) {
    const compStyleId = componentStylesAndSkinsAPI.style.getId(ps, componentDetectorAPI.getComponentById(ps, compId))
    //make sure to register theme once per iframe
    if (changedData.type !== 'STYLE' || (changedData.type === 'STYLE' && compStyleId === changedData.values)) {
        //post changes only to the relevant iframe
        const data = tpaStyleService.getStyleDataToPassIntoApp(ps, compId)
        postMessageBackToApp(ps, compId, 'THEME_CHANGE', data)
    }
}

const notifyTpaComponent = (ps: PS, compId: string, eventType: string, params) => {
    if (_.isEmpty(_.trim(compId))) {
        ps.extensionAPI.logger.captureError(
            new ReportableError({
                message: 'notifyTpaComponent has been called with empty compId',
                errorType: 'notifyTpaComponentCompIdMissing',
                tags: {
                    empty_notifyTpaComponent: true,
                    eventType
                }
            })
        )
        return
    }

    postMessageToAppSyncer([compId, eventType], params)
    const excludeSyncingOtherClients = _.get(params, ['metaData', 'excludeSyncingOtherClients'], false)
    if (!excludeSyncingOtherClients && !['EDIT_MODE_CHANGE', 'COMPONENT_DELETED'].includes(eventType)) {
        const {distributor} = ps.extensionAPI as DistributorExtensionAPI
        if (eventType === 'SETTINGS_UPDATED') {
            const dataSize = JSON.stringify(params)?.length ?? 0
            if (dataSize > 5000) {
                // large payloads can't distribute through duplexer
                params = null
                ps.logger.interactionStarted('big-distributor-message-data', {
                    extras: {dataSize}
                })
            }
        } else if (eventType === 'THEME_CHANGE') {
            params = null
        }
        if (tpaEventHandlersService.noDalChangeEvents.includes(eventType)) {
            distributor.distributeMessage<TpaPostMessageToApp>('tpaPostMessageToApp', {
                compId,
                eventType,
                params
            })
        } else {
            distributor.distributeMessageAfterApproval<TpaPostMessageToApp>('tpaPostMessageToApp', {
                compId,
                eventType,
                params
            })
        }
    }
}

const getComponentIdsToNotify = (ps: PS, compId: string) => {
    const compPtr = componentDetectorAPI.getComponentById(ps, compId)

    if (compPtr) {
        return ps.pointers.referredStructure.getInternallyReferredComponents(compPtr) || [compId]
    }

    return [compId]
}

const postMessageBackToApp = (ps: PS, compId: string, eventKey: string, params?) => {
    notifyTpaComponents(ps, compId, eventKey, params)
}

const notifyTpaComponents = (ps: PS, compId: string, eventKey: string, params) => {
    const compsToNotify = getComponentIdsToNotify(ps, compId)
    _.forEach(compsToNotify, compIdToNotify => {
        if (!compIdToNotify) {
            ps.extensionAPI.logger.captureError(
                new ReportableError({
                    message: 'notifyTpaComponents called with no id',
                    errorType: 'notifyTpaComponentsMissingId',
                    extras: {
                        compId,
                        compsToNotify: JSON.stringify(compIdToNotify)
                    }
                })
            )
        }
        notifyTpaComponent(ps, compIdToNotify, eventKey, params)
    })
}

const tpaCompPreviewDataSyncer = (ps: PS, compId: string, data) => {
    ps.siteAPI.setTpaCompPreviewData(compId, data)
}

const compStateSyncer = (ps: PS, compId: string, data) => {
    ps.siteAPI.setCompState(compId, data)
}

const postMessageToAppSyncer = ([compId, eventType]: [string, string], params) => {
    const domComponent = window.document.querySelector(`#${compId}`)
    if (!domComponent) {
        return
    }
    const iframe = domComponent.querySelector('iframe')

    if (!iframe) {
        return
    }

    try {
        tpa.tpaPostMessageCommon.callPostMessage(iframe, {
            intent: 'addEventListener',
            eventType,
            params
        })
    } catch (e) {
        contextAdapter.utils.fedopsLogger.captureError(
            new ReportableError({
                message: `postMessage back to app was called for ${compId} but failed`,
                errorType: 'postMessageToAppError',
                tags: {
                    errorOnPostMessageBackToApp: true
                },
                extras: {
                    error: e,
                    compId,
                    eventType
                }
            })
        )
    }
}

const getDefaultLayout = function (ps: PS, appDefinitionId: AppDefinitionId, widgetId: number | string) {
    const widgetData = clientSpecMapService.getWidgetData(ps, appDefinitionId, widgetId)

    return {
        height: _.get(widgetData, 'defaultHeight') || 500,
        width: _.get(widgetData, 'defaultWidth') || 980,
        x: 0
    }
}

const provisionWidget = function (ps: PS, componentToAddRef: Pointer, appDefinitionId: AppDefinitionId, options) {
    const onComplete = function (data, err) {
        ps.setOperationsQueue.asyncPreDataManipulationComplete(data, err)
    }
    const onError = function () {
        if (options.onError) {
            options.onError()
        }
        onComplete(null, new Error('addWidget - provision failed'))
    }
    provisionApp(ps, componentToAddRef, tpaConstants.TYPE.TPA_WIDGET, appDefinitionId, options, onComplete, onError)
}

const provisionMultiSection = function (
    ps: PS,
    pageToAddRef: Pointer,
    appDefinitionId: AppDefinitionId,
    options
): void {
    const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
    if (clientSpecMapService.isAppActive(ps, appData)) {
        const sectionId = `${tpaConstants.TYPE.TPA_SECTION}_${guidUtils.getUniqueId(undefined, undefined)}`
        options.sectionId = sectionId
        const widgetData = clientSpecMapService.getWidgetDataFromTPAPageId(ps, appDefinitionId, options.pageId)
        if (widgetData && clientSpecMapService.isMultiSectionInstanceEnabled(appData, widgetData.widgetId)) {
            options.applicationId = appData.applicationId
            options.appDefinitionId = appData.appDefinitionId
            options.widgetData = widgetData

            ps.setOperationsQueue.asyncPreDataManipulationComplete(appData)

            return
        }
        ps.setOperationsQueue.asyncPreDataManipulationComplete(null, new Error('Creating this section is not allowed'))
    } else {
        ps.setOperationsQueue.asyncPreDataManipulationComplete(null, new Error('Main section is not installed'))
    }
}

const addMultiSection = function (
    ps: PS,
    newAppData,
    pageToAddRef: CompRef,
    appDefinitionId: AppDefinitionId,
    options: any = {}
) {
    clientSpecMapService.registerAppData(ps, newAppData)

    const originalCallback = options.callback
    options.callback = (...args: any[]) => {
        hooks.executeHook(hooks.HOOKS.ADD_TPA.AFTER, null, [ps, newAppData])

        if (originalCallback) {
            originalCallback(...args)
        }
    }
    tpaSectionService.addMultiSection(ps, pageToAddRef, options)
}

const addSubSection = function (ps: PS, pageToAddPointer: CompRef, appDefinitionId: AppDefinitionId, options) {
    options = options || {}
    const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
    if (appData) {
        const widgetData = _.find(appData.widgets, ['appPage.id', options.pageId])
        if (widgetData) {
            const sectionId = tpaComponentCommonService.addSubSection(
                ps,
                pageToAddPointer,
                widgetData,
                appData,
                options
            )
            if (ps.setOperationsQueue.isRunningSetOperation()) {
                ps.setOperationsQueue.executeAfterCurrentOperationDone(function () {
                    tpaSectionService.invokeSectionCallback(options, pageToAddPointer, sectionId, true)
                    hooks.executeHook(hooks.HOOKS.ADD_TPA.AFTER, null, [ps])
                })
            } else {
                tpaSectionService.invokeSectionCallback(options, pageToAddPointer, sectionId, true)
                hooks.executeHook(hooks.HOOKS.ADD_TPA.AFTER, null, [ps])
            }
        }
    }
}

const provisionSection = function (
    ps: PS,
    pageToAddRef: Pointer,
    appDefinitionId: AppDefinitionId,
    options?,
    onError?
) {
    const sectionId = `${tpaConstants.TYPE.TPA_SECTION}_${guidUtils.getUniqueId(undefined, undefined)}`
    options = options || {}
    options.sectionId = sectionId
    const onComplete = function (data, err) {
        data = data || {}
        data.additionalOptions = options
        ps.setOperationsQueue.asyncPreDataManipulationComplete(data, err)
    }
    const completeOnError = function () {
        if (onError) {
            onError()
        }
        ps.setOperationsQueue.asyncPreDataManipulationComplete(null, new Error('addSection - provision failed'))
    }
    provisionApp(ps, pageToAddRef, tpaConstants.TYPE.TPA_SECTION, appDefinitionId, options, onComplete, completeOnError)
}

const addSection = function (
    ps: PS,
    appData,
    componentToAddRef: Pointer,
    appDefinitionId: AppDefinitionId,
    options,
    onError?
) {
    options = _.merge(options, _.get(appData, 'additionalOptions'))
    appData = _.omit(appData, 'additionalOptions')
    addAppWrapper(
        tpaConstants.TYPE.TPA_SECTION,
        ps,
        appData,
        componentToAddRef,
        appDefinitionId,
        options,
        _.noop,
        onError
    )
}

const addAppWrapper = function (
    type: string,
    ps: PS,
    appData,
    componentToAddRef: Pointer,
    appDefinitionId: AppDefinitionId,
    options: any = {},
    onSuccess?,
    onError?,
    waitForSOQ = true
) {
    if (appData && !appData.dontAdd) {
        const originalCallback = options.callback
        options.callback = (...args: any[]) => {
            hooks.executeHook(hooks.HOOKS.ADD_TPA.AFTER, null, [ps, appData])

            if (originalCallback) {
                originalCallback(...args)
            }
        }
        addApp(
            ps,
            componentToAddRef,
            type,
            options,
            appData,
            onSuccess,
            _.get(options, 'onError') || onError,
            waitForSOQ
        )
    }
}

const setCompPreviewDataForAllTPA = function (ps: PS, key: string, value) {
    const sharedQueryParams = ps.siteAPI.getTpaCompPreviewData('sharedQueryParams') || {}
    ps.siteAPI.setTpaCompPreviewData('sharedQueryParams', _.merge(sharedQueryParams, {[key]: value}))
}

export default {
    provisionApp,
    provisionWidget,
    provisionSection,
    provisionMultiSection,

    addApp,
    addAppWrapper,
    addSection,
    addMultiSection,
    addSubSection,
    settingsUpdated,
    refreshApp,
    getSectionRefByPageId,
    setExternalId,
    getExternalId,
    postBackThemeData,
    postMessageBackToApp,
    getDefaultLayout,
    tpaCompPreviewDataSyncer,
    compStateSyncer,
    postMessageToAppSyncer,
    setCompPreviewDataForAllTPA
}
