import {ReportableError} from '@wix/document-manager-utils'
import type {Callback1, AppDefinitionId, PS} from '@wix/document-services-types'
import {warmupUtils} from '@wix/santa-ds-libs'
const {ajaxLibrary} = warmupUtils
import _ from 'lodash'
import constants from '../../constants/constants'
import siteMetadata from '../../siteMetadata/siteMetadata'
import tpaConstants from '../constants'
import AppMarketUrlBuilder from '../utils/AppMarketUrlBuilder'
import cache from './appMarketCacheService'
import billing from './billingService'
import installedTpaAppsOnSiteService from './installedTpaAppsOnSiteService'

const pricedAppUrl = _.template(
    '<%= appMarketEditorAPIUrl %><%= appDefinitionId %>/priced-app?metaSiteId=<%= metaSiteId %>&lang=<%= lang %>'
)
const relatedAppsUrl = _.template(
    '<%= appMarketEditorAPIUrl %>?market=related_apps&fields=appDefinitionId,slug,name,appIcon,colorSvgIcon,weights,categories,by,relatedAppsTeaser,teaser,hasSection,widgets.defaultWidth,widgets.defaultHeight'
)
const marketAPIBulk = _.template('<%= appMarketEditorAPIUrl %>?id=<%= appDefinitionIds %>')
const sectionsTranslatedPageTitles = _.template(
    '<%= appMarketEditorAPIUrl %>page-apps?fields=widgets.title,widgets.widgetId,appDefinitionId&lang=<%= lang %>'
)
const premiumUrlTemplate = _.template(
    '<%= premiumBaseUrl %>?appInstanceId=<%= instanceId %>&appDefinitionId=<%= appDefinitionId %>&paymentCycle=<%= cycle %>&vendorProductId=<%= vendorProductId %>'
)

const getAppMarketTopology = function (ps: PS): string {
    const serviceTopology = ps.pointers.general.getServiceTopology()
    const premiumStatePointer = ps.pointers.getInnerPointer(serviceTopology, 'appMarketEditorApiUrl')
    return `${ps.dal.get(premiumStatePointer)}/`
}

function getPricedAppUrl(ps: PS, appDefinitionId: AppDefinitionId, currency: string) {
    const metaSiteId = siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.META_SITE_ID)
    const lang = siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.LANGUAGE_CODE) || 'en'
    let url = pricedAppUrl({
        appMarketEditorAPIUrl: getAppMarketTopology(ps),
        appDefinitionId,
        metaSiteId,
        lang
    })
    if (currency) {
        url += `&currency=${currency}`
    }
    return url
}

function shouldUpdateCache(options) {
    return !_.get(options, 'currency')
}

const callServerWith = function (
    ps: PS,
    appDefinitionId: AppDefinitionId,
    options,
    resolve?: Callback1<any>,
    reject?: Callback1<any>
) {
    const eventGuid = _.random(10000, true)
    ps.extensionAPI.logger.interactionStarted(constants.INTERACTIONS.APP_MARKET_SERVICE.CALL_SERVER, {eventGuid})
    const url = getPricedAppUrl(ps, appDefinitionId, _.get(options, 'currency'))
    cache.lock(appDefinitionId)
    ajaxLibrary.ajax({
        type: 'GET',
        url,
        success(response) {
            if (shouldUpdateCache(options)) {
                response = cache.set(appDefinitionId, response)
            } else {
                const dataFromCache = cache.get(appDefinitionId)
                if (dataFromCache) {
                    response = _.merge({}, dataFromCache, response)
                }
            }
            cache.unlock(appDefinitionId)
            if (resolve) {
                resolve(response)
            }
            ps.extensionAPI.logger.interactionEnded(constants.INTERACTIONS.APP_MARKET_SERVICE.CALL_SERVER, {eventGuid})
        },
        error(err) {
            const clonedErr = _.clone(err)
            _.unset(clonedErr, 'stack')
            ps.extensionAPI.logger.captureError(
                new ReportableError({
                    errorType: 'invalidAppMarketCall',
                    message: err?.message ?? `Error with calling server`,
                    tags: {
                        appMarketCallServer: true,
                        appDefinitionId
                    },
                    extras: {
                        error: clonedErr,
                        requestId: _.get(err, 'requestId'),
                        url
                    }
                })
            )
            //TODO - send bi message
            cache.unlock(appDefinitionId)
            const error = `app market response error for appDefinitionId: ${appDefinitionId}`
            if (resolve) {
                resolve({
                    error,
                    errorMessage: err?.message
                })
            } else if (reject) {
                reject({
                    error,
                    errorMessage: err?.message
                })
            }
        }
    })
}

const getAppMarketDataAsync = function (ps: PS, appDefinitionId: AppDefinitionId, options?) {
    return new Promise(function (resolve, reject) {
        if (_.isUndefined(appDefinitionId)) {
            reject({error: 'appDefinitionId was not given'})
            //TODO - send bi message
        } else {
            const dataFromCache = cache.get(appDefinitionId)
            if (dataFromCache && hasPriceData(dataFromCache) && !_.get(options, 'currency')) {
                resolve(dataFromCache)
            } else {
                callServerWith(ps, appDefinitionId, options, resolve, reject)
            }
        }
    })
}

const getAppMarketData = function (ps: PS, appDefinitionId: AppDefinitionId, options?) {
    if (_.isNil(appDefinitionId)) {
        ps.extensionAPI.logger.captureError(
            new ReportableError({
                errorType: 'invalidAppMarketData',
                message: 'getAppMarketData was called with nil appDefinitionId'
            })
        )
    }

    const dataFromCache = cache.get(appDefinitionId)
    if (shouldMakeAServerRequest(appDefinitionId)) {
        callServerWith(ps, appDefinitionId, options)
    }
    return dataFromCache
}

const hasPriceData = function (data) {
    return data?.price
}

const shouldMakeAServerRequest = function (appDefinitionId: AppDefinitionId) {
    const dataFromCache = cache.get(appDefinitionId)
    const requestInProgress = cache.isLocked(appDefinitionId)
    return requestInProgress !== 1 && (_.isUndefined(dataFromCache) || !hasPriceData(dataFromCache))
}

const getAppMarketUrl = function (ps: PS, editorParams) {
    const serviceTopology = ps.pointers.general.getServiceTopology()
    const premiumStatePointer = ps.pointers.getInnerPointer(serviceTopology, 'appMarketEditorNewUrl')
    let appMarketBaseUrl = ps.dal.get(premiumStatePointer)
    appMarketBaseUrl = _.replace(appMarketBaseUrl, 'wix-app-market', 'one-app-market')
    const appMarketUrlBuilder = new AppMarketUrlBuilder(appMarketBaseUrl)
    const lang = siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.LANGUAGE_CODE) || 'en'
    const metaSiteId = siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.META_SITE_ID)
    const siteId = siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.SITE_ID)
    //TODO TPA-DEPENDENCY-NOT-READY Needs to be implemented by IdoK's team in 1-2 weeks
    const isNewWixStores = 'true'

    return appMarketUrlBuilder
        .addOriginParam(editorParams.origin)
        .addModalParams(editorParams.modalName, editorParams.appName, editorParams.tab)
        .addDevAppParam(editorParams.appDefinitionId)
        .addLangParam(lang)
        .addAppMarketTests(editorParams.tests)
        .addCompIdParam()
        .addMetaSiteIdParam(metaSiteId)
        .addSiteIdParam(siteId)
        .addTagsParam(editorParams.query)
        .addOpenAppParam(editorParams.openAppDefId)
        .addAppMarketParams(editorParams.appMarketParams)
        .addNewWixStores(isNewWixStores)
        .addCategoryParam(editorParams.categorySlug)
        .addBiReferralInfoParam(editorParams.openMarketOrigin)
        .addBiReferralInfoCategoryParam(editorParams.referralInfoCategory)
        .addBiSectionParam(editorParams.section)
        .addAddingMethodParam(editorParams.addingMethod)
        .addStateParam(editorParams.state)
        .addCommonConfigParam(ps)
        .build()
}

const getAppMarketBulkUrl = function (ps: PS, appDefinitionIds: string[]): string {
    const base = getAppMarketTopology(ps)
    const url = marketAPIBulk({
        appMarketEditorAPIUrl: base,
        appDefinitionIds: appDefinitionIds.toString()
    })
    return url
}

const getUncachedPageApps = function (ps: PS, pageId: string): string[] {
    const apps = installedTpaAppsOnSiteService.getInstalledAppsOnPage(ps, pageId)
    const appDefinitionIds = _.map(apps, 'appDefinitionId')
    const cachedAppDefinitionIds = cache.keys()
    const uncachedAppDefinitionIds = _.remove(
        appDefinitionIds,
        appDefId => !_.includes(cachedAppDefinitionIds, appDefId)
    )
    return uncachedAppDefinitionIds
}

/**
 * gets market data with no price data
 * @param ps
 * @param pageId
 * @param resolve
 * @param reject
 */
const getAppMarketDataForPage = function (ps: PS, pageId: string, resolve: Function, reject: Function) {
    if (_.isUndefined(pageId)) {
        if (reject) {
            reject({
                error: 'pageId was not given'
            })
        }
    } else {
        const appDefinitionIds = getUncachedPageApps(ps, pageId)
        if (_.isEmpty(appDefinitionIds)) {
            return
        }
        const url = getAppMarketBulkUrl(ps, appDefinitionIds)
        ajaxLibrary.ajax({
            type: 'GET',
            url,
            success(apps) {
                _.forEach(apps, function (app) {
                    cache.set(app.appDefinitionId, app)
                })
                if (resolve) {
                    resolve(apps)
                }
            },
            error() {
                //TODO - send bi message
                if (resolve) {
                    resolve({
                        error: `app market response error for appDefinitionIds: ${appDefinitionIds.toString()}`
                    })
                } else if (reject) {
                    reject({
                        error: `app market response error for appDefinitionIds: ${appDefinitionIds.toString()}`
                    })
                }
            }
        })
    }
}

const getSectionsTranslatedPageTitlesUrl = function (ps: PS) {
    const base = getAppMarketTopology(ps)
    const lang = siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.LANGUAGE_CODE) || 'en'
    return sectionsTranslatedPageTitles({
        appMarketEditorAPIUrl: base,
        lang
    })
}

const getSectionsTranslatedPageTitles = function (ps: PS) {
    const url = getSectionsTranslatedPageTitlesUrl(ps)
    return new Promise(function (resolve, reject) {
        ajaxLibrary.ajax({
            type: 'GET',
            url,
            success(data) {
                resolve(data)
            },
            error: reject
        })
    })
}

const getRelatedAppsUrl = function (ps: PS) {
    const appMarketEditorAPIUrl = getAppMarketTopology(ps)
    const url = relatedAppsUrl({appMarketEditorAPIUrl})
    return url
}

const defaultErrorHandler = (e: any) => {
    console.error(e)
}

const getRelatedApps = function (ps: PS, onSuccess: Callback1<any>, onError = defaultErrorHandler) {
    const url = getRelatedAppsUrl(ps)
    ajaxLibrary.ajax({
        type: 'GET',
        url,
        dataType: 'json',
        contentType: 'application/json',
        success(data) {
            onSuccess(relatedAppsNeededData(data))
        },
        error: onError
    })
}

const getAppMarketInfoUrl = function (ps: PS, editorParams, appName: string) {
    editorParams.appName = appName
    editorParams.modalName = 'app-modal'
    const url = getAppMarketUrl(ps, editorParams)
    return url
}

const getAppReviewsUrl = function (ps: PS, editorParams, appName: string) {
    editorParams.appName = appName
    editorParams.modalName = 'app-modal'
    editorParams.tab = 'reviews'
    const url = getAppMarketUrl(ps, editorParams)
    return url
}

const getAppMarketPermissionsUrl = function (ps: PS, editorParams) {
    editorParams.modalName = 'permissions-modal'
    const url = getAppMarketUrl(ps, editorParams)
    return url
}

const requestPremiumAppsCycleToBeCached = function (ps: PS, json, callback: Callback1<any>) {
    const tpaPackages = _.get(json, 'tpaPackages') || []
    _.forEach(tpaPackages, function (packageData) {
        const dataFromCache = cache.get(packageData.appDefinitionId)
        if (dataFromCache) {
            cache.set(packageData.appDefinitionId, {
                upgradedToYearly: billing.isYearly(packageData)
            })
        } else {
            callServerWith(ps, packageData.appDefinitionId, {}, function () {
                cache.set(packageData.appDefinitionId, {
                    upgradedToYearly: billing.isYearly(packageData)
                })
            })
        }
    })

    if (_.isFunction(callback)) {
        callback(tpaPackages)
    }
}

const getPremiumApps = function (ps: PS, metasiteId: string, onSuccess?: Callback1<any>, onError?: Callback1<any>) {
    billing.getPremiumApps(
        ps,
        metasiteId,
        function (premiumApps) {
            requestPremiumAppsCycleToBeCached(ps, premiumApps, onSuccess)
        },
        onError
    )
}

const relatedAppsNeededData = function (data) {
    if (!_.isArray(data)) {
        data = [data]
    }
    return data
}

function setPurchaseUrl(json, baseUrl: string, instanceId: string, appDefinitionId: AppDefinitionId, cycle) {
    if (json.hasOwnProperty(cycle)) {
        if (json[cycle].hasOwnProperty('price')) {
            json[cycle].url = premiumUrlTemplate({
                premiumBaseUrl: baseUrl,
                instanceId,
                appDefinitionId,
                cycle: _.findKey(tpaConstants.CYCLE, value => value === cycle),
                vendorProductId: json.id
            })
        } else {
            delete json[cycle]
        }
    }
}

const getPackages = async function (ps: PS, appDefinitionId: AppDefinitionId, instanceId: string, options?) {
    const appMarketData: any = await getAppMarketDataAsync(ps, appDefinitionId, options)
    if (appMarketData.error) {
        throw appMarketData
    } else {
        const premiumBaseUrl = appMarketData.purchaseStartUrl
        const priceRelevantData = _.pick(appMarketData.price, 'currencyCode', 'currencySymbol')
        const result = _.map(appMarketData.packages, function (packageJson) {
            const refactoredJson = _.assign(_.cloneDeep(packageJson), priceRelevantData)
            _.forEach(tpaConstants.CYCLE, cycle => {
                setPurchaseUrl(refactoredJson, premiumBaseUrl, instanceId, appDefinitionId, cycle)
            })
            return refactoredJson
        })
        return result
    }
}

export default {
    getAppMarketDataAsync,
    getAppMarketData,
    getAppMarketDataForPage,
    getAppMarketUrl,
    getAppMarketInfoUrl,
    getAppReviewsUrl,
    getAppMarketPermissionsUrl,
    getPackages,
    getPremiumApps,
    getRelatedApps,
    getSectionsTranslatedPageTitles,
    requestAppMarketDataToBeCached: getAppMarketData
}
