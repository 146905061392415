'use strict'

const _ = require('lodash')

const TINY_MENU_SKIN = 'wysiwyg.viewer.skins.mobile.TinyMenuSkin'

function applyTinyMenuDefaults(styleData) {
    if (
        styleData.skin !== TINY_MENU_SKIN ||
        _.isUndefined(styleData.style) ||
        _.isUndefined(styleData.style.properties)
    ) {
        return
    }

    const defaults = [
        ['bg', 'bgDrop'],
        ['bg', 'bgOpen'],
        ['txt', 'bordercolor'],
        ['txt', 'iconcolor'],
        ['bordercolor', 'borderColorSelected']
    ]

    const prop = styleData.style.properties
    const propSource = styleData.style.propertiesSource

    function applyDefault(map, key, val) {
        if (!_.isUndefined(map) && !_.isUndefined(map[key])) {
            map[val] = map[val] || map[key]
        }
    }

    _.forEach(defaults, def => {
        let key = def[0]
        let val = def[1]

        applyDefault(prop, key, val)
        applyDefault(propSource, key, val)

        key = `alpha-${key}`
        val = `alpha-${val}`

        applyDefault(prop, key, val)
        applyDefault(propSource, key, val)
    })
}

/**
 * @exports utils/dataFixer/plugins/tinyMenuSkinBackgroundFixer
 * @type {{exec: function}}
 */
module.exports = {
    name: 'tinyMenuSkinBackgroundFixer',
    version: 1,
    exec(pageJson) {
        const themeData = pageJson.data && pageJson.data.theme_data
        if (!_.isEmpty(themeData)) {
            _.forEach(themeData, applyTinyMenuDefaults)
        }
    },
    fixerRequiresReruns: true
}
