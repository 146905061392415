import type {CreateExtArgs, CreateExtensionArgument, Extension, ExtensionAPI} from '@wix/document-manager-core'

import type {CSaveApi} from './continuousSave'
import type {SaveTransactionResponse} from './csaveTypes'
export interface CSaveForServerApi extends ExtensionAPI {
    continuousSaveForServer: {
        save(): Promise<void | SaveTransactionResponse>
        shouldSave(): boolean
    }
}

class CSaveForServerExtension implements Extension {
    readonly name: string = 'continuousSaveForServer'

    createExtensionAPI({dal, extensionAPI}: CreateExtArgs): CSaveForServerApi {
        const {continuousSave} = extensionAPI as CSaveApi
        return {
            continuousSaveForServer: {
                save: async () => {
                    dal.commitTransaction('csave')
                    return await continuousSave.save()
                },
                shouldSave: continuousSave.shouldSave
            }
        }
    }
}

const createExtension = ({}: CreateExtensionArgument): Extension => new CSaveForServerExtension()

export {createExtension}
