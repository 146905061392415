import _ from 'lodash'

const experiments = {
    isEcomPagesMissingExperimentOn: _.noop
}

const setExperiments = function (_experiments) {
    _.merge(experiments, _experiments)
}

const getExperiments = function () {
    return experiments
}

export default {
    setExperiments,
    getExperiments
}
