import _ from 'lodash'
import urlUtils from '../../../coreUtils/core/urlUtils'

const getMLPointerValue =
    (selector, defaultVal) =>
    ({dal, pointers}) =>
        dal.get(pointers.multilingual[selector]()) || defaultVal

const Multilingual = {
    PointerOperation: {
        GET: 'get',
        SET: 'set'
    },

    EMPTY_TRANSLATION_DATA: {
        data: {
            document_data: {}
        }
    },

    setCurrentLanguage(state) {
        return languageCode => {
            const {siteDataAPI, siteAPI, dal, pointers} = state
            const {siteData} = siteDataAPI

            if (this.getCurrentLanguageCode({dal, pointers}) === languageCode) {
                return
            }

            dal.set(pointers.multilingual.currentLanguageCode(), languageCode)

            // @ts-ignore
            if (!(siteData.isInSSR() || window.isMockWindow)) {
                const htmlElement = window.document.querySelector('html')
                htmlElement.setAttribute('lang', languageCode)
            }
            const url = siteData.currentUrl.full
            const updatedUrl = urlUtils.setUrlParams(url, {lang: languageCode})

            urlUtils.updateUrl(updatedUrl)

            const pageInfo = _.omit(siteData.getRootNavigationInfo(), ['queryParams'])
            const navigationInfo = _.assign(
                pageInfo,
                {
                    isLanguageChange: true
                },
                urlUtils.parseUrl(updatedUrl)
            )

            siteAPI.updatePageNavInfo(navigationInfo)
        }
    },

    getCurrentLanguageCode: getMLPointerValue('currentLanguageCode', null),

    getOriginalLanguage: getMLPointerValue('originalLanguage', {}),

    getTranslationLanguages: getMLPointerValue('translationLanguages', []),

    getTranslationPath(currentLanguageCode: string, path: string[], insertPosition) {
        return _.concat(_.take(path, insertPosition), 'translations', currentLanguageCode, _.drop(path, insertPosition))
    }
}

export default Multilingual
