/*
    This file is purely due to backward compatibility reasons with Santa.
    Don't use it. It will be removed as soon as we are done with Santa.
 */
import type {ContextAdapter, FedopsLogger} from '@wix/document-services-types'
import {warmupUtils, coreUtils} from '@wix/santa-ds-libs'
import experiment from 'experiment-amd'

const fedopsLogger: FedopsLogger = {
    interactionStarted: warmupUtils.loggingUtils.fedopsLogger.interactionStarted,
    interactionEnded: warmupUtils.loggingUtils.fedopsLogger.interactionEnded,
    breadcrumb: coreUtils.integrations.ravenUtils.breadcrumb,
    captureError: coreUtils.integrations.ravenUtils.captureError
} as unknown as FedopsLogger

export const contextAdapter: ContextAdapter = {
    actions: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        sendHttpRequest: (url, type, jsonData, body, success, error) => {
            throw new Error('contextAdapter.actions.sendHttpRequest was called before an implementation was registered')
        }
    },
    utils: {
        fedopsLogger
    },
    viewerName: 'santa',
    // @ts-expect-error
    experiment
}
