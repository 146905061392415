const _ = require('lodash')
const {migrateColors} = require('./migrateColors')
const migrateFonts = require('./migrateFonts')
const migrateBorder = require('./migrateBorder')
const migratePadding = require('./migratePadding')
const migrateBorderRadius = require('./migrateBorderRadius')

/**
 * @param {Array<import('../index').ParsedStyleItem>} styleItems
 * @returns {Array<import('../index').ParsedStyleItem>}
 */
const migrateProperties = _.flow(migrateColors, migrateFonts, migrateBorder, migratePadding, migrateBorderRadius)

module.exports = migrateProperties
