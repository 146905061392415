import _ from 'lodash'
import urlUtils from './urlUtils'

// eslint-disable-next-line no-console
const log = console.log.bind(console)
// eslint-disable-next-line no-console
const warn = console.warn.bind(console)
const BASE_DEPRECATION_MESSAGE = 'DocumentServices|Deprecated|'

const debugEnabled = isDebug()
const verbose = debugEnabled ? log : _.noop
const warnVerbose = debugEnabled ? warn : _.noop

function isDebug() {
    return typeof window === 'undefined'
        ? true
        : urlUtils.parseUrl(_.get(window, ['location', 'href'], '')).query.debug === 'all'
}

export default {
    isDebug,
    verbose,
    info: log,
    warnVerbose,
    warn,
    // eslint-disable-next-line no-console
    error: console.error.bind(console),
    warnDeprecation(deprecationMessage) {
        if (debugEnabled) {
            // eslint-disable-next-line no-useless-call,no-console
            console.error.call(console, BASE_DEPRECATION_MESSAGE + deprecationMessage)
        }
    }
}
