import dataModel from '../../dataModel/dataModel'
import constants from '../../constants/constants'

export default {
    styleCanBeApplied: true,
    canBeStretched: true,
    resizableSides(ps, compPointer) {
        const {imageMode} = dataModel.getPropertiesItem(ps, compPointer)

        if (imageMode === 'flexibleHeight') {
            return [constants.RESIZE_SIDES.LEFT, constants.RESIZE_SIDES.RIGHT]
        }

        return [
            constants.RESIZE_SIDES.TOP,
            constants.RESIZE_SIDES.LEFT,
            constants.RESIZE_SIDES.BOTTOM,
            constants.RESIZE_SIDES.RIGHT
        ]
    },
    mobileConversionConfig: {
        minHeight: 200
    }
}
