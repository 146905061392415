'use strict'

const _ = require('lodash')

const locationDataType = 'GeoMapLocation'
const DEFAULT_PIN_ICON =
    'https://static.parastorage.com/services/santa-resources/resources/editor/designPanel/panelTypes/default_pin_icon.png'
const DEFAULT_ZOOM = 14

function fixGoogleMapData(pageJson, docData, magicObject) {
    _.forOwn(docData, dataItem => {
        if (dataItem.type === 'GeoMap' && !_.get(dataItem, 'locations')) {
            setGoogleMapCompData(pageJson, dataItem, magicObject)
        }
    })
}

function fixGoogleMapProps(pageJson, componentProperties) {
    _.forOwn(componentProperties, props => {
        if (props.type === 'GoogleMapProperties' && isNaN(props.zoom)) {
            setGoogleMapCompProps(pageJson, props)
        }
    })
}

function setGoogleMapCompProps(pageJson, props) {
    props.zoom = DEFAULT_ZOOM
}

function setGoogleMapCompData(pageJson, dataItem, magicObject) {
    const locationId = magicObject.dataFixerUtils.uniqueIdGenerator.getUniqueId('dataItem', '-')
    dataItem.locations = [`#${locationId}`]
    dataItem.defaultLocation = 0
    pageJson.data.document_data[dataItem.id] = _.omit(dataItem, 'address', 'addressInfo', 'latitude', 'longitude')
    pageJson.data.document_data[locationId] = {
        id: locationId,
        address: dataItem.address,
        latitude: dataItem.latitude,
        longitude: dataItem.longitude,
        title: dataItem.addressInfo,
        description: '',
        link: null,
        linkTitle: '',
        pinIcon: DEFAULT_PIN_ICON,
        pinColor: '',
        type: locationDataType
    }
}

module.exports = {
    name: 'googleMapsFixer',
    version: 2,
    exec(pageJson, pageIdsArray, magicObject) {
        const docData = pageJson.data.document_data
        const componentProperties = pageJson.data.component_properties
        if (docData) {
            fixGoogleMapData(pageJson, docData, magicObject)
            fixGoogleMapProps(pageJson, componentProperties)
        }
        if (pageJson.translations) {
            _.forEach(pageJson.translations, translation => {
                fixGoogleMapData(pageJson, translation.data.document_data, magicObject)
            })
        }
    }
}
