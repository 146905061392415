import _ from 'lodash'
import type {CreateExtArgs} from '@wix/document-manager-core'
import {getDeepDataFromMaster} from './blocksDataModel'
import type {PresetData} from '@wix/document-services-types'

export const getPresetData = (createExtArgs: CreateExtArgs, presetId: string): PresetData => {
    const presetData = getDeepDataFromMaster<PresetData>(createExtArgs, presetId)
    return {
        presetId: _.trimStart(presetData.presetId, '#'),
        name: presetData.name,
        defaultSize: presetData.defaultSize
    }
}
