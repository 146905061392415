import type {AppDefinitionId, ApplicationId, PS, ClientSpecMapEntry} from '@wix/document-services-types'
import _ from 'lodash'
import dataManipulation from './dataManipulation'
import hooks from '../hooks/hooks'
import {installedTpaAppsUtils} from '@wix/document-manager-extensions'

import {ReportableError, ReportableWarning} from '@wix/document-manager-utils'
function getAppsData(ps: PS) {
    return dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.CLIENT_SPEC_MAP) || {}
}

const getAppVersion = (appData: ClientSpecMapEntry) => installedTpaAppsUtils.getAppVersion(appData)

function migrateDataAppIdToAppDefId(ps, data: any, reportErrorOnRemigrationSource?: string) {
    if (data?.appId && !data?.appDefinitionId) {
        const specMapEntry = getAppData(ps, data.appId)
        if (specMapEntry?.appDefinitionId) {
            _.set(data, 'appDefinitionId', specMapEntry.appDefinitionId)
        }
        if (reportErrorOnRemigrationSource) {
            ps.logger.captureError(
                new ReportableError({
                    errorType: 'menuUpdateAppDefIdMigration',
                    message: `${reportErrorOnRemigrationSource}: data with appId needed appDefinitionId remigration`,
                    extras: {
                        appId: data.appId,
                        migratedAppDefinitionId: data.appDefinitionId
                    }
                })
            )
        }
    }
}
hooks.registerHook(hooks.HOOKS.CLIENT_SPEC_MAP.MIGRATE_APPID_TO_APPDEF, migrateDataAppIdToAppDefId)

function registerAppData(ps: PS, newAppData) {
    const appsData = getAppsData(ps)
    if (newAppData.applicationId) {
        appsData[newAppData.applicationId] = newAppData
        dataManipulation.setProperty(ps, dataManipulation.PROPERTY_NAMES.CLIENT_SPEC_MAP, appsData)
    }
}

const setAppInstallStateByAppData = (ps: PS, newAppData) =>
    ps.extensionAPI.appsInstallState.setAppInstallStateByAppData(newAppData)

function updateAppInstance(ps: PS, appDefinitionId: AppDefinitionId, instance: string) {
    const appData = getAppDataByAppDefinitionId(ps, appDefinitionId)
    if (appData) {
        appData.instance = instance
        registerAppData(ps, appData)
    }
}

function getAppsDataWithPredicate(ps: PS, predicate: (v: any) => any): any[] {
    return _.map(ps.pointers.general.getClientSpecMapEntriesByPredicate(predicate), ps.dal.get)
}

function getAppData(ps: PS, applicationId: ApplicationId) {
    return ps.dal.get(ps.pointers.general.getClientSpecMapEntry(applicationId as string)) || {}
}

function getAppDataByAppDefinitionId(ps: PS, appDefinitionId: AppDefinitionId) {
    return ps.dal.get(ps.pointers.general.getClientSpecMapEntryByAppDefId(appDefinitionId))
}

function filterAppsDataByType(ps: PS, type: string): any[] {
    return getAppsDataWithPredicate(ps, csm => _.filter(csm, {type}))
}

function handleMigrationError(ps: PS, extras: Record<string, any>, tags: Record<string, any>) {
    ps.logger.captureError(
        new ReportableWarning({
            errorType: 'appIdMigrationToAppDefIdError',
            message: 'error on migration process from appId to appDefId',
            extras,
            tags
        })
    )
}

function getApplicationIdFromAppDefinitionId(
    ps: PS,
    appDefinitionId: AppDefinitionId,
    info: Record<string, any>
): ApplicationId | undefined {
    try {
        const csmEntry = ps.dal.get(ps.pointers.general.getClientSpecMapEntryByAppDefId(appDefinitionId))
        return csmEntry.applicationId
    } catch (e: any) {
        handleMigrationError(ps, {appDefinitionId, info}, {appDefIdToAppId: true})
    }
}

function getAppDefinitionIdFromApplicationId(
    ps: PS,
    applicationId: ApplicationId,
    info: Record<string, any>
): AppDefinitionId | undefined {
    try {
        const csmEntry = ps.dal.get(ps.pointers.general.getClientSpecMapEntry(applicationId.toString()))
        return csmEntry.appDefinitionId
    } catch (e: any) {
        handleMigrationError(ps, {applicationId, info}, {appIdToAppDefId: true})
    }
}

function hasCSMEntry(ps: PS, appDefinitionId: AppDefinitionId) {
    return !!ps.dal.isExist(ps.pointers.general.getClientSpecMapEntryByAppDefId(appDefinitionId))
}

export default {
    registerAppData,
    updateAppInstance,
    getAppData,
    getAppVersion,
    getAppDataByAppDefinitionId,
    getAppsData,
    filterAppsDataByType,
    getAppsDataWithPredicate,
    getApplicationIdFromAppDefinitionId,
    getAppDefinitionIdFromApplicationId,
    hasCSMEntry,
    setAppInstallStateByAppData
}
