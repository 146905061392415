import type {DocumentManager} from '@wix/document-manager-core'

const initialize = async (documentManager: DocumentManager) => {
    const multiLingualPointer = documentManager.pointers.multilingual.multilingualInfo()
    const overrideValue = {
        originalLanguage: {},
        translationLanguages: [] as any[]
    }
    documentManager.dal.setIfChanged(multiLingualPointer, overrideValue)
}

export default {
    initialize
}
