import type {Null, IdGenerator} from '../../types'
import type {DmStore} from '../store'
import {SnapshotDal} from './snapshotDal'
import {createUniqueIdGenerator} from '../../utils/uniqueIdGenerator'

interface FindOptions {
    limit?: SnapshotDal
    inclusive?: boolean
}

export interface SnapshotList extends Iterable<Null<SnapshotDal>> {
    last(): Null<SnapshotDal>
    add(store: DmStore, id?: string): SnapshotDal
    insert(store: DmStore, pid: string, id?: string): SnapshotDal
    addSnapshot(s: SnapshotDal): SnapshotDal
    find(predicate: (s: SnapshotDal) => boolean, options?: FindOptions): Null<SnapshotDal>
    remove(s: SnapshotDal): Null<SnapshotDal>
    findById(is: string): Null<SnapshotDal>
    findByIdLimit(is: string, limit?: SnapshotDal): Null<SnapshotDal>
    findByIdLimitCompareIds(is: string, limit?: SnapshotDal): Null<SnapshotDal>
    findByIdLimitInclusive(is: string, limit?: SnapshotDal): Null<SnapshotDal>
    addAll(snaps: SnapshotDal[]): void
    isEmpty(): boolean
    toArray(): SnapshotDal[]
    listIds(): string[]
    generateId(label?: string): string
}

export const createList = (): SnapshotList => {
    const getId: IdGenerator = createUniqueIdGenerator(12)
    let _last: SnapshotDal | null = null

    const addSnapshot = (s: SnapshotDal) => {
        s.setPreviousSnapshot(_last)
        _last = s
        return s
    }

    const add = (store: DmStore, id: string = getId()) => {
        const transaction = new SnapshotDal(_last, store, id)
        _last = transaction
        return transaction
    }

    const find = (predicate: (s: SnapshotDal) => boolean, {limit, inclusive}: FindOptions = {}) => {
        let t = _last
        while (t !== null && !predicate(t)) {
            if (limit && t === limit) {
                return null
            }

            t = t.getPreviousSnapshot()
        }

        if (limit && !inclusive && t === limit) {
            return null
        }

        return t
    }

    const findById = (sid: string) => find(({id}) => id === sid)
    const findByIdLimit = (sid: string, limit?: SnapshotDal) => find(({id}) => id === sid, {limit})
    const findByIdLimitInclusive = (sid: string, limit?: SnapshotDal) =>
        find(({id}) => id === sid, {limit, inclusive: true})

    const findCompareIds = (predicate: (s: SnapshotDal) => boolean, {limit, inclusive}: FindOptions = {}) => {
        let t = _last
        while (t !== null && !predicate(t)) {
            if (limit && t.id === limit.id) {
                return null
            }
            t = t.getPreviousSnapshot()
        }
        if (limit && !inclusive && t?.id === limit.id) {
            return null
        }
        return t
    }

    const findByIdLimitCompareIds = (sid: string, limit?: SnapshotDal) => {
        return findCompareIds(({id}) => id === sid, {limit})
    }

    const findNext = (snapshot: Null<SnapshotDal>) => find(s => s._previousSnapshot === snapshot)

    /**
     * create new Snapshot from store and insert is to the chain after the snapshot with requested id
     * @param store for the new snapshot
     * @param prevId of snapshot to insert after
     * @param id for the new snapshot
     * @returns the created snapshot
     */
    const insert = (store: DmStore, prevId: string, id: string = getId()) => {
        if (!_last) {
            throw new Error('Insert is not allowed for an empty snapshotList')
        }

        if (!prevId) {
            throw new Error('Insert is not allowed without a previous id snapshotList')
        }

        if (prevId === _last.id) {
            return add(store, id)
        }

        const prev = findById(prevId)
        const inserted = new SnapshotDal(prev, store, id)
        const next = findNext(prev)
        if (!next) {
            throw new Error('insert failed to find the next snapshot in the chain')
        }

        next._previousSnapshot = inserted
        return inserted
    }

    /**
     * removes this snapshot from chain
     * @param snapshot
     */
    const remove = (snapshot: SnapshotDal) => {
        if (snapshot === _last) {
            _last = snapshot.getPreviousSnapshot()
        } else {
            const next = findNext(snapshot)
            if (next) {
                next._previousSnapshot = snapshot._previousSnapshot
            }
        }
        return _last
    }

    const iter = (): Iterator<Null<SnapshotDal>> => {
        let curr: SnapshotDal | null
        return {
            next(): IteratorResult<Null<SnapshotDal>> {
                curr = curr ? curr._previousSnapshot : _last
                return {
                    done: curr === null,
                    value: curr
                }
            }
        }
    }

    const toArray = () => {
        // const arr = Array.from({[Symbol.iterator]: iter})
        const arr: SnapshotDal[] = []
        let t: Null<SnapshotDal> = _last
        while (t !== null) {
            arr.push(t)
            t = t.getPreviousSnapshot()
        }
        return arr.reverse()
    }

    const addAll = (snaps: SnapshotDal[]) => {
        snaps.forEach(s => {
            addSnapshot(s)
        })
    }

    const generateId = (label: string) => getId(label)

    // @ts-ignore
    return {
        last: () => _last,
        add,
        insert,
        addAll,
        addSnapshot,
        find,
        remove,
        findById,
        findByIdLimit,
        findByIdLimitInclusive,
        findByIdLimitCompareIds,
        [Symbol.iterator]: iter,
        isEmpty: () => !_last,
        // for debug
        toArray,
        listIds: () => toArray().map(s => s.id),
        generateId
    }
}
