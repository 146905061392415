import _ from 'lodash'
import pathValidationUtil from './pathValidationUtil'
import DALFactory from '../dal/DALFactory'

export default {
    setMobxDataHandlers(siteData, mobxDataHandlers) {
        DALFactory.setMobxDataHandlers(siteData, mobxDataHandlers)
        pathValidationUtil.setMobxDataHandlers(siteData, _.pick(mobxDataHandlers, 'getObservableByPath'))
    }
}
