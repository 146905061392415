import type {LoadAndStoreComponentStageData, MobileAlgoContext, Stage, StageHandler} from '../types'
import type {Pointer, SerializedCompStructure} from '@wix/document-services-types'

export const createLoadAndStoreComponentStage = (): Stage<LoadAndStoreComponentStageData> => {
    const handlers: StageHandler<LoadAndStoreComponentStageData>[] = []

    const run = async (
        ctx: MobileAlgoContext,
        componentStructure: SerializedCompStructure,
        pageId: string,
        variants: Pointer[]
    ) => {
        for (const handler of handlers) {
            await handler({globalContext: ctx, componentStructure, pageId, variants})
        }
        if (componentStructure.components) {
            for (const childStructure of componentStructure.components) {
                if (!componentStructure.mobileHints?.isLocked) {
                    await run(ctx, childStructure, pageId, variants)
                }
            }
        }
    }

    return {
        register: (handler: StageHandler<LoadAndStoreComponentStageData>) => {
            handlers.push(handler)
        },
        run
    }
}
