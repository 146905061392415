import type {DmApis, Extension} from '@wix/document-manager-core'
import {getPointerMap} from './metadataUtils'

const createPointersMethods = ({pointers}: DmApis) => ({
    metadata: {
        getSiteMetaDataPointer: (key: string) => {
            const pointer = getPointerMap(pointers)[key]
            if (!pointer) {
                throw new Error(`Missing metadata pointer for key ${key}`)
            }

            return pointer
        }
    }
})

const createExtension = (): Extension => ({
    name: 'metadata',
    createPointersMethods
})

export {createExtension}
