import _ from 'lodash'
import metaDataUtils from '../metaDataUtils'
import slideShowCommon from '../../slideshow/slideShowCommon'
import constants from '../../constants/constants'

function isStateBoxSlide(ps, parentType, child) {
    return _.includes(slideShowCommon.getMatchingChildSlideTypes(parentType), metaDataUtils.getComponentType(ps, child))
}

function getNonMasterChildren(ps, componentPointer, type) {
    return _.filter(ps.pointers.full.components.getChildren(componentPointer), isStateBoxSlide.bind(null, ps, type))
}

function getCurrentSlide(ps, compPointer) {
    const compType = metaDataUtils.getComponentType(ps, compPointer)
    return _.find(getNonMasterChildren(ps, compPointer, compType), child => ps.siteAPI.isComponentRenderedOnSite(child))
}

export default {
    minimalChildrenNumber: 1,
    resizableSides: (ps, compPointer) => {
        const currentSlide = getCurrentSlide(ps, compPointer)
        if (currentSlide) {
            const currentSlideType = metaDataUtils.getComponentType(ps, currentSlide)
            if (ps.extensionAPI.componentDefinition.isRepeater(currentSlideType)) {
                return [constants.RESIZE_SIDES.LEFT, constants.RESIZE_SIDES.RIGHT]
            }
        }
        return [
            constants.RESIZE_SIDES.TOP,
            constants.RESIZE_SIDES.LEFT,
            constants.RESIZE_SIDES.BOTTOM,
            constants.RESIZE_SIDES.RIGHT
        ]
    },
    mobileConversionConfig: {
        structuralContainer: true,
        isTightContainer: true,
        nestOverlayingSiblings: false,
        filterChildrenWhenHidden: true,
        marginX: 0
    },
    layoutLimits: {
        minHeight: 50
    }

    //because of the support of master components inside the StateBox that can be of any type we can not restrict the child types
    //allowedChildTypes: slideShowCommon.getMatchingChildSlideTypes('wysiwyg.viewer.components.StateBox')
}
