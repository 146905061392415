import _ from 'lodash'
import type {ExtensionAPI, DAL} from '@wix/document-manager-core'
import {SiteExport, getAllPageNicknames, CustomMenuExportData, errorTypes, collectIdsToPropertyMap} from './utils'
import type {
    Link,
    MenuData,
    MenuItem,
    Pointers,
    TpaPageLink,
    DocumentLink,
    DynamicPageLink,
    AnchorLink,
    PageLink,
    LinkWithPageId
} from '@wix/document-services-types'
import type {PageAPI, PageExtensionAPI} from '../page'
import type {MenuExtensionAPI} from '../menu/menu'
import type {RoutersAPI} from '../routers'
import {generateUniqueIdByType, getIdFromRef} from '../../utils/dataUtils'
import {ReportableError} from '@wix/document-manager-utils'

const replacePageNicknameAndPageId = (
    obj: Link,
    idsToNicknames: Record<string, string>,
    toPageId: boolean = false
): Link => {
    const exportObj = _.cloneDeep(obj)
    const {pageId} = obj as LinkWithPageId
    // in passed obj replace pageId prop with pageId/nickname
    // if toPageId is true, replace nickname with pageId
    // else replace pageId with nickname
    const value = toPageId
        ? `#${_.invert(idsToNicknames)[pageId] ?? pageId}`
        : idsToNicknames[getIdFromRef(pageId)] ?? pageId

    _.set(exportObj, ['pageId'], value)

    return exportObj
}
const replaceAllPropsInMenuItems = <Item extends MenuItem>(
    items: Item[],
    idsToNicks: Record<string, string>,
    toExport: boolean = true
): Item[] => {
    return _.map(items, item => {
        if (item.link) {
            _.set(item, ['link'], replacePageNicknameAndPageId(item.link, idsToNicks, !toExport))
        }
        if (item.items && item.items.length > 0) {
            item.items = replaceAllPropsInMenuItems(item.items, idsToNicks)
        }
        return item
    })
}
const replaceAllPageIdsAndNicknames = <
    From extends MenuData | CustomMenuExportData,
    To extends MenuData | CustomMenuExportData
>(
    menus: From[],
    idsToNicks: Record<string, string>,
    menuIdsToNames: Record<string, string>,
    toExport: boolean = true
): To[] => {
    return _.map(menus, menu => {
        const exportMenu = _.cloneDeep(menu)
        if (menu.name && !toExport) {
            _.set(exportMenu, ['id'], _.invert(menuIdsToNames)[menu.name])
        }
        if ((menu as MenuData).id && toExport) {
            _.set(exportMenu, ['name'], menuIdsToNames[(menu as MenuData).id])
            _.unset(exportMenu, ['id'])
        }
        if (menu.items && menu.items.length > 0) {
            _.set(exportMenu, ['items'], replaceAllPropsInMenuItems(menu.items, idsToNicks, toExport))
        }
        return exportMenu
    }) as unknown as To[]
}

const getMenusDataForExport = (
    extensionApi: ExtensionAPI,
    idsToNicks: Record<string, string>
): CustomMenuExportData[] => {
    const allMenusData = (extensionApi as MenuExtensionAPI).menus.getAll()
    const menuIdsToNames = collectIdsToPropertyMap(allMenusData, 'name')

    return replaceAllPageIdsAndNicknames<MenuData, CustomMenuExportData>(allMenusData, idsToNicks, menuIdsToNames, true)
}

export const exportSite = (extensionApi: ExtensionAPI, dal: DAL, pointers: Pointers): SiteExport => {
    const nicknames = getAllPageNicknames(extensionApi, pointers)
    const getNick = (id: string) => nicknames[id] ?? id
    const ids = (extensionApi.page as PageAPI).getAllPagesIds(false)
    const appState = dal.get(pointers.platform.getAppStatePointer())
    const menus = getMenusDataForExport(extensionApi, nicknames) as CustomMenuExportData[]
    const settings = {
        mainPage: getNick((extensionApi.page as PageAPI).getMainPageId())
    }
    return {
        pages: ids.map(getNick),
        appState: _.mapKeys(appState, (state, id) => getNick(id)),
        menus: _.keyBy(menus, 'name'),
        settings
    }
}

const validatePage = (page: string, pages: string[]): void => {
    if (!pages.includes(page)) {
        throw new ReportableError({
            errorType: errorTypes.IMPORT_MENU_VALIDATION_ERROR,
            message: `Non-existing page="${page}" referenced from link`
        })
    }
}

const validateLink = (extensionApi: ExtensionAPI, dal: DAL, pointers: Pointers, link: Link, pages: string[]): void => {
    const typesWithPageId = ['PageLink', 'AnchorLink', 'TpaPageLink']
    if (typesWithPageId.includes(link.type)) {
        const {pageId} = link as PageLink | AnchorLink | TpaPageLink

        validatePage(pageId, pages)
    }
    if (link.type === 'TpaPageLink') {
        const {appDefinitionId, id} = link as TpaPageLink
        const clientSpecMap = dal.get(pointers.rendererModel.getClientSpecMap())
        const hasInstalledApplication = !_.isEmpty(_.find(clientSpecMap, {appDefinitionId}))
        if (!hasInstalledApplication) {
            throw new ReportableError({
                errorType: errorTypes.IMPORT_MENU_VALIDATION_ERROR,
                message: `Menu can not contain TpaPageLink id="${id}" that is associated with uninstalled application appDefinitionId="${appDefinitionId}"`
            })
        }
    }
    if (link.type === 'DynamicPageLink') {
        const {routerId, id} = link as DynamicPageLink
        const allRouters = (extensionApi as RoutersAPI).routers.getAllRouters()

        if (!allRouters[routerId]) {
            throw new ReportableError({
                errorType: errorTypes.IMPORT_MENU_VALIDATION_ERROR,
                message: `Menu can not contain dynamic page link id="${id}", it relies on non-existing router id=${routerId}`
            })
        }
    }
    if (link.type === 'DocumentLink') {
        // we can't re-upload document, so just check if such link exists
        const {docId, name, id} = link as DocumentLink

        const documentDataItem = pointers.data.getDataItemsWithPredicate({docId, name}, 'masterPage')
        if (documentDataItem.length === 0) {
            throw new ReportableError({
                errorType: errorTypes.IMPORT_MENU_VALIDATION_ERROR,
                message: `Menu can not contain link id="${id}" to non-existing document docId="${docId}"`
            })
        }
    }
}

const validateMenuDataItems = (
    extensionApi: ExtensionAPI,
    dal: DAL,
    pointers: Pointers,
    items: MenuItem[],
    pages: string[]
): void => {
    items.forEach(item => {
        if (item.link) {
            validateLink(extensionApi, dal, pointers, item.link, pages)
        }
        validateMenuDataItems(extensionApi, dal, pointers, item.items, pages)
    })
}

const validateMenuData = (
    extensionApi: ExtensionAPI,
    dal: DAL,
    pointers: Pointers,
    menuData: CustomMenuExportData[],
    currentMenuNames: string[],
    pages: string[]
): void => {
    menuData.forEach(menu => {
        validateMenuDataItems(extensionApi, dal, pointers, menu.items, pages)
    })
}

const validateMainPageExists = (page: string, pages: string[]): void => {
    if (!pages.includes(page)) {
        throw new ReportableError({
            errorType: errorTypes.INVALID_PAGE_REFERENCE,
            message: `Non-existing mainPage="${page}" referenced from settings`
        })
    }
}

const validatePageRemoval = (
    nicksToIds: Record<string, string>,
    pagesToDelete: string[],
    extensionApi: ExtensionAPI
) => {
    const mainPageId = (extensionApi as PageExtensionAPI).page.getMainPageId()
    pagesToDelete.forEach(nick => {
        const id = nicksToIds[nick]
        if (mainPageId === id) {
            throw new ReportableError({
                errorType: errorTypes.INVALID_PAGE,
                message: `Can not remove page "${nick}" because it is set as homepage`
            })
        }
    })
}

export const importSite = (data: SiteExport, extensionApi: ExtensionAPI, dal: DAL, pointers: Pointers): void => {
    const currentExport = exportSite(extensionApi, dal, pointers)
    const allMenusData = (extensionApi as MenuExtensionAPI).menus.getAll()
    const menuIdsToNames = collectIdsToPropertyMap<MenuData[]>(_.values(allMenusData), 'name')
    const menuNamesToIds = _.invert(menuIdsToNames)
    const currentMenuNames = _.values(menuIdsToNames)
    const currentPages = currentExport.pages
    const importedPages = data.pages
    const pagesToDelete = _.difference(currentPages, importedPages)
    const menusToDelete = _.difference(currentMenuNames, _.keys(data.menus))

    const idsToNicks = getAllPageNicknames(extensionApi, pointers)
    const nicksToIds = _.invert(idsToNicks)
    const allPages = _.union(importedPages, currentPages)
    const appState = _.mapKeys(data.appState, (v, nickOrId) => nicksToIds[nickOrId] ?? nickOrId)
    const menusList = _.values(data.menus)
    const mainPageNickname = data.settings.mainPage
    const mainPageId = nicksToIds[mainPageNickname]

    validateMainPageExists(mainPageNickname, importedPages)
    validatePageRemoval(nicksToIds, pagesToDelete, extensionApi)
    validateMenuData(extensionApi, dal, pointers, menusList, currentMenuNames, allPages)

    pagesToDelete.forEach(nick => {
        const id = nicksToIds[nick]
        ;(extensionApi.page as PageAPI).removePage(id)
    })

    menusToDelete.forEach(name => {
        const id = menuNamesToIds[name] ?? name
        ;(extensionApi as MenuExtensionAPI).menus.removeMenu(id)
    })

    dal.set(pointers.platform.getAppStatePointer(), appState)
    ;(extensionApi.page as PageAPI).setMainPageId(mainPageId)

    const importedMenus = replaceAllPageIdsAndNicknames<CustomMenuExportData, MenuData>(
        menusList,
        idsToNicks,
        menuIdsToNames,
        false
    )

    _.forEach(importedMenus, menuData => {
        if (!currentMenuNames.includes(menuData.name)) {
            ;(extensionApi as MenuExtensionAPI).menus.createMenu(
                menuData.id ?? generateUniqueIdByType('data', 'masterPage', dal, pointers),
                menuData
            )
            return
        }
        ;(extensionApi as MenuExtensionAPI).menus.updateMenu(menuData, menuData.id)
    })
}
