const transformedExperimentsCache = new WeakMap()

export const getRunningExperiments = (context: any) => {
    const model = (context && (context.rendererModel || context.editorModel)) || {}
    const {runningExperiments = {}} = model
    return runningExperiments
}

const transformRunningExperiments = (runningExperiments: Record<string, any>) => {
    return Object.keys(runningExperiments).reduce((acc, key) => {
        acc[key.toLowerCase()] = runningExperiments[key]
        return acc
    }, Object.create(null))
}

export const getValue = (name: string, context: any) => {
    const runningExperiments = getRunningExperiments(context)
    const value = runningExperiments[name]
    if (value !== undefined) {
        return value
    }

    let transformedExperiments = transformedExperimentsCache.get(runningExperiments)
    if (!transformedExperiments) {
        transformedExperiments = transformRunningExperiments(runningExperiments)
        transformedExperimentsCache.set(runningExperiments, transformedExperiments)
    }
    return transformedExperiments[name.toLowerCase()]
}

export const isMultiValueExperimentOpen = (name: string, context: any) => {
    const value = getValue(name, context)
    return !!(value && value !== 'old' && value !== 'false')
}
export const isOpen = isMultiValueExperimentOpen
