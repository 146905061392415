import type {CompStructure, PS, Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import dataModel from '../../dataModel/dataModel'
import hooks from '../../hooks/hooks'
import mobileConversionFacade from '../mobileConversionFacade'
import conversionSettings from './conversionSettings'
import mergeAggregator from './mergeAggregator'

function canSwitchDisplayMode(ps: PS, desktopCompPointer: Pointer) {
    const compModeDefinitions = ps.dal.full.get(ps.pointers.componentStructure.getModesDefinitions(desktopCompPointer))
    return _.size(compModeDefinitions) > 1
}

function removeComponent(components: CompStructure[], predicate: _.ListIteratee<CompStructure>): boolean {
    const removedChildren = _.remove(components, predicate)
    return (
        !_.isEmpty(removedChildren) ||
        _.some(components, child => removeComponent(child.components as CompStructure[], predicate))
    )
}

function setComponentDisplayMode(ps: PS, compPointer: Pointer, mobileDisplayedModeId: string): null | undefined {
    const desktopCompPointer = ps.pointers.components.getDesktopPointer(compPointer)
    if (!canSwitchDisplayMode(ps, desktopCompPointer)) {
        return null
    }
    const pageId = ps.pointers.components.getPageOfComponent(compPointer).id
    const pagePointer = ps.pointers.page.getPagePointer(pageId)
    dataModel.updatePropertiesItem(ps, desktopCompPointer, {mobileDisplayedModeId})
    const type = ps.dal.get(ps.pointers.getInnerPointer(pagePointer, 'componentType'))
    hooks.executeHook(hooks.HOOKS.MOBILE_CONVERSION.BEFORE, type, [ps, pagePointer])
    const {desktop, mobile} = mergeAggregator.getPage(ps, pageId)
    removeComponent(mobile.components, {id: compPointer.id})
    const settings = conversionSettings.getConversionSettings(ps, {conversionType: 'MERGE_DISPLAY_MODE'})
    mobileConversionFacade.runMobileMergeOnPage(ps, desktop, mobile, settings)
}

export default {setComponentDisplayMode}
