import {constants} from '@wix/document-manager-utils'
import _ from 'lodash'

// checking if environment supports unicode. IE11 does not
const unicodeSupported = !_.isUndefined(/(?:)/.unicode)
const getIllegalCharacterPattern = () => (unicodeSupported ? /[^\p{L}0-9\-]/gu : /[^a-zA-Z0-9\-]/g)
const getStartingIllegalCharactersPattern = () => (unicodeSupported ? /^[^\p{L}0-9]*/gu : /^[a-zA-Z0-9]*/g)
const getEndingIllegalCharactersPattern = () => (unicodeSupported ? /[^\p{L}0-9]*$/gu : /[^a-zA-Z0-9]*$/g)

const convertPageNameToUrl = (title: string) => {
    const fixedTitle = title ? title : '' // defaulting to '' for every falsy value
    const uri = fixedTitle
        .toLowerCase()
        .trim()
        .replace(getStartingIllegalCharactersPattern(), '')
        .replace(getEndingIllegalCharactersPattern(), '')
        .replace(/\s+/g, '-')
        .replace(getIllegalCharacterPattern(), '-')
        .replace(/-+/gu, '-')

    return uri || 'blank'
}

const isPageUriSeoTooLong = (pageUriSEO: string, marginForError: number) => {
    marginForError = marginForError || 0
    return _.size(pageUriSEO) > constants.URLS.MAX_LENGTH - marginForError
}

const uriHasIllegalCharacters = (pageUriSEO: string) => getIllegalCharacterPattern().test(pageUriSEO)

export {convertPageNameToUrl, isPageUriSeoTooLong, uriHasIllegalCharacters}
