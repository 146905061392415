import _ from 'lodash'
import type {DocumentManager} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import type {MasterPageMigrator} from '../dataMigrationRunner'
import {MASTER_PAGE_ID} from '../../constants/constants'
import type {SchemaExtensionAPI} from '@wix/document-manager-extensions'

const migrateMasterPage = (documentManager: DocumentManager) => {
    if (!documentManager.experimentInstance.isOpen('dm_fixMissingStyleTypeInSystemStyle')) {
        return
    }

    const {extensionAPI, pointers, dal} = documentManager

    const systemStylesPointers = pointers.data.getStyleItemsWithPredicate(
        data =>
            data.type === 'TopLevelStyle' &&
            (extensionAPI as SchemaExtensionAPI).schemaAPI.isSystemStyle(data.id) &&
            data.styleType !== 'system',
        MASTER_PAGE_ID
    )

    _.forEach(systemStylesPointers, (pointer: Pointer) => {
        const styleItem = dal.get(pointer)

        dal.set(pointer, {
            ...styleItem,
            styleType: 'system'
        })
    })
}

const name = 'fixMissingStyleTypeInSystemStyle'
const version = 0

export const fixMissingStyleTypeInSystemStyle: MasterPageMigrator = {
    migrateMasterPage,
    name,
    version,
    experimentalVersions: [{version: 1, experiment: 'dm_fixMissingStyleTypeInSystemStyle'}]
}
