import {ReportableError} from '@wix/document-manager-utils'
import type {RunningExperiments} from '@wix/document-services-types'
export interface FixerContext {
    fixerName: string
}

export class NonRegisteredExperimentError extends ReportableError {
    constructor(experimentName: string, context: FixerContext) {
        super({
            message: 'Experiment used but not registered',
            errorType: 'NonRegisteredExperimentError',
            tags: {},
            extras: {experimentName, context}
        })
    }
}

export class NonLinearVersionError extends ReportableError {
    constructor(versionExperiments: RunningExperiments, context: FixerContext) {
        super({
            message: 'The experiments are opened non-linearly',
            errorType: 'NonLinearVersionError',
            tags: {},
            extras: {versionExperiments, context}
        })
    }
}
