import type {CompRef, PS, PublicMethodUtils, RemoveOverridesOptions} from '@wix/document-services-types'
import {refComponent} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    const {
        extensionPublicAPI: {dataManipulation, getter}
    } = publicMethodUtils

    return {
        methods: {
            components: {
                refComponents: {
                    hasOverridesToBeRemoved: publicMethodUtils.defineGetter(refComponent.hasOverridesToBeRemoved),
                    removeOverrides: publicMethodUtils.defineDataManipulationAction(refComponent.removeOverrides),
                    removeAllOverrides: publicMethodUtils.deprecate(
                        publicMethodUtils.defineDataManipulationAction(
                            (
                                ps: PS,
                                compRef: CompRef,
                                excludeConnectionItems = true,
                                excludeNonPropertiesItemsForMobile = false
                            ) => {
                                const options: RemoveOverridesOptions = {
                                    removeMobilePropsOnly: excludeNonPropertiesItemsForMobile
                                }

                                if (excludeConnectionItems) {
                                    options.exclusions = {connections: '*'}
                                }

                                return refComponent.removeOverrides(ps, compRef, options)
                            }
                        ),
                        'Please use components.refComponents.removeOverrides'
                    ),
                    removeUnusedOverrides: publicMethodUtils.defineDataManipulationAction(
                        refComponent.removeUnusedOverrides
                    ),
                    unGhostifyComponent: publicMethodUtils.defineDataManipulationAction(
                        refComponent.unGhostifyComponent
                    ),
                    getGhostRefComponents: publicMethodUtils.defineGetter(refComponent.getGhostRefComponents),
                    getUniqueRefCompPointer: publicMethodUtils.defineGetter(refComponent.getUniqueRefCompPointer),
                    getRefHostCompPointer: publicMethodUtils.defineGetter(refComponent.getRefHostCompPointer),
                    getRootRefHostCompPointer: publicMethodUtils.defineGetter(refComponent.getRootRefHostCompPointer),
                    getTemplateCompPointer: publicMethodUtils.defineGetter(refComponent.getTemplateCompPointer),
                    isReferredComponent: publicMethodUtils.defineGetter(refComponent.isReferredComponent),
                    getAllGhostRefComponentsPrimaryConnection: publicMethodUtils.defineGetter(
                        refComponent.getAllGhostRefComponentsPrimaryConnection
                    ),
                    /**
                     * the method get the template component references and returns all the ref components instances inflated form this template.
                     *
                     * @param {CompRef} compRef - pointers to ref component template
                     * @param {string} [pageId] - allow to filter and get the ref instance from the template of the same page. if not passed you will get instances from all pages
                     * @return {CompRef[]} all inflated refComponents that are reffered from the template passed
                     * @example documentServices.components.refComponents.getReferredComponents(compRef)
                     */
                    getReferredComponents: publicMethodUtils.defineGetter(refComponent.getReferredComponents),
                    getRefOverride: getter('refOverrides.getOverride'),
                    setRefOverride: dataManipulation('refOverrides.setOverride'),
                    removeRefOverride: dataManipulation('refOverrides.removeOverride')
                }
            }
        },
        initMethod: refComponent.init
    }
}
