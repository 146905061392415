import type {ToolDefinition} from './aiTools'
import pageData from '../page/pageData'
import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
const getSitePages = (ps: PS) => {
    const pagesData = pageData.getPagesDataItems(ps)
    return `Here is a list of all the pages on the site, formatted as [page title]: [page id]
    ${_.map(pagesData, _pageData => `- ${_pageData.title}: ${_pageData.id}`).join('\n')}`
}
export const getSitePagesTool: ToolDefinition = {
    name: 'sitePages',
    func: () => {},
    validationFunc: () => ({success: true}),
    responseFunc: getSitePages
}
