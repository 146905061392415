import type {MobileAlgoPluginInitializationArgs, PluginContext, StructureStageData} from '../../../types'
import {createServerRequest} from './request'
import {sendRequest} from './server'
import {createOrderMap} from './orderMap'
import type {SendRequest} from '../types'
import {CTX_GROUPING_RESULT, CTX_SERVER} from '../constants'

export const createGroupingMap = async (
    data: StructureStageData,
    ctx: PluginContext,
    pluginInitArgs: MobileAlgoPluginInitializationArgs
) => {
    const sendRequestImpl: SendRequest = ctx.get(CTX_SERVER) ?? sendRequest
    const {extensionAPI, environmentContext} = pluginInitArgs
    const requestData = createServerRequest(data.deepStructure, data.pageId, pluginInitArgs)
    const serverResponse = await sendRequestImpl(extensionAPI, environmentContext, requestData)
    const relativeOrderMap = createOrderMap(serverResponse, data)
    ctx.set(CTX_GROUPING_RESULT, relativeOrderMap)
}
