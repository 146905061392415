import constants from '../../constants/constants'

function pluginFn(siteAPI, compLayoutRelativeToStructure) {
    const isMobile = siteAPI.isMobileView()

    return {
        x: isMobile ? compLayoutRelativeToStructure.x : 0,
        y: compLayoutRelativeToStructure.y,
        width: isMobile ? siteAPI.getSiteWidth() : siteAPI.getSiteMeasureMap().clientWidth,
        height: siteAPI.getSiteMeasureMap().height[constants.MASTER_PAGE_ID]
    }
}

export default pluginFn
