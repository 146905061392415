const SCRIPT = 'https://vk.com/share.php?act=count&url='

function cleanScript(script) {
    // @ts-ignore
    window.VK = window._tempVK
    // @ts-ignore
    delete window._tempVK
    window.document.head.removeChild(script)
}

export default function (url, callback) {
    const script = window.document.createElement('script')
    let result = 0

    // @ts-ignore
    window._tempVK = window.VK
    // @ts-ignore
    window.VK = {
        Share: {
            count(index, count) {
                result = count
            }
        }
    }

    script.setAttribute('type', 'text/javascript')
    script.setAttribute('src', SCRIPT + encodeURIComponent(url))
    script.addEventListener('load', function () {
        callback(result)
        cleanScript(script)
    })
    script.addEventListener('error', function () {
        callback(result)
        cleanScript(script)
    })

    window.document.head.appendChild(script)
}
