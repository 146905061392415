import {pointerUtils, type CreateExtArgs} from '@wix/document-manager-core'
import type {CompStructure, Pointer} from '@wix/document-services-types'
import {dockUtils} from '@wix/santa-core-utils'

export const fiveGridLineMetaData = {
    fullWidthByStructure: ({}: CreateExtArgs, compStructure: CompStructure) => {
        return !!dockUtils.isHorizontalDockToScreen(compStructure.layout)
    },
    fullWidth: ({dal}: CreateExtArgs, compPointer: Pointer) => {
        const compLayoutPointer = pointerUtils.getInnerPointer(compPointer, 'layout')
        const compLayout = dal.get(compLayoutPointer)
        return dockUtils.isHorizontalDockToScreen(compLayout)
    }
}
