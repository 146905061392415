import type {CompRef, PS} from '@wix/document-services-types'
import component from '../component/component'
import type {DebugExtensionAPI} from '@wix/document-manager-extensions/src/extensions/debug'

const initialize = (ps: PS) => {
    const {debug} = ps.extensionAPI as DebugExtensionAPI
    debug.registerCustomComponentExport((componentPointer: CompRef) => component.serialize(ps, componentPointer))
}

export default {
    initialize
}
