import type {PossibleViewModes, PS} from '@wix/document-services-types'
import * as coreUtils from '@wix/santa-core-utils'
import _ from 'lodash'
import componentDetectorAPI from '../../../componentDetectorAPI/componentDetectorAPI'
import constants from '../../../constants/constants'
import dataModel from '../../../dataModel/dataModel'
import features from '../../../features/features'
import siteMetadataAPI from '../../../siteMetadata/siteMetadata'
import tpaConstants from '../../../tpa/constants'
import pageData from '../../../page/pageData'
import experiment from 'experiment-amd'

function renderHintsResolver(flatData) {
    const WIDGETS_COMP_TYPE = [
        'wysiwyg.viewer.components.tpapps.TPAWidget',
        'wysiwyg.viewer.components.tpapps.TPASection',
        'wysiwyg.viewer.components.tpapps.TPAMultiSection',
        'wysiwyg.viewer.components.tpapps.TPAGluedWidget'
    ]

    const CONTROLLERS_COMP_TYPE = ['platform.components.AppController', 'platform.components.AppWidget']

    function incrementCounter(target, path: string | string[]) {
        const count = _.get(target, path, 0)
        _.set(target, path, count + 1)
    }

    const {components, clientSpecMap} = flatData
    const result = {
        componentsCount: {},
        applications: {}
    }

    const updateWidgetsCount = (widgetId: string) => {
        const {appDefinitionId} = _.find(clientSpecMap, ({widgets}) => _.get(widgets, [widgetId])) || {}
        incrementCounter(result.applications, `${appDefinitionId}.widgetsCount.${widgetId}`)
    }

    _.forEach(components, comp => {
        const {
            structure: {componentType}
        } = comp

        incrementCounter(result.componentsCount, [componentType])

        if (_.includes(WIDGETS_COMP_TYPE, componentType)) {
            const {
                data: {widgetId}
            } = comp
            updateWidgetsCount(widgetId)
            return
        }

        if (componentType === 'wysiwyg.viewer.components.RefComponent') {
            const {
                data: {widgetId}
            } = comp
            // in responsive sites RefComponent has no widgetId
            if (!widgetId) {
                return
            }
            updateWidgetsCount(widgetId)
            return
        }

        if (_.includes(CONTROLLERS_COMP_TYPE, componentType)) {
            const {
                data: {applicationId, controllerType}
            } = comp
            incrementCounter(result.applications, `${applicationId}.controllersCount.${controllerType}`)
        }
    })
    return result
}

const componentsTypesMap = {
    appPart: ['wixapps.integration.components.AppPart'],
    appPart2: ['wixapps.integration.components.AppPart2'],
    tpa: _.concat(_.values(tpaConstants.COMP_TYPES), _.values(tpaConstants.TPA_COMP_TYPES))
}

const processDataToResolver = (ps: PS) => {
    const all = (viewMode: PossibleViewModes) =>
        componentDetectorAPI.getAllComponentsFromFull(ps, undefined, undefined, viewMode)
    const components = _([])
        .concat(all('DESKTOP'), all('MOBILE')) // FIXME - do we need to know the distinction between mobile and desktop
        .uniqBy('id') // FIXME - note about masking-out components props if split in mobile
        .flatMap(pointer => {
            const componentType = ps.dal.full.get(ps.pointers.getInnerPointer(pointer, 'componentType'))
            const data = dataModel.getDataItem(ps, pointer) || {}
            return {
                structure: {
                    componentType
                },
                data
            }
        })
        .compact()
        .value()
    const clientSpecMap = ps.dal.get(ps.pointers.general.getClientSpecMap())
    return {components, clientSpecMap}
}

const isSiteAlreadyFlaggedAsReadyForMesh = (ps: PS) => {
    const masterPagePointer = ps.pointers.data.getDataItemFromMaster(coreUtils.siteConstants.MASTER_PAGE_ID)
    const layoutSettingsPointer = ps.pointers.getInnerPointer(masterPagePointer, 'layoutSettings')
    const layoutSettings = ps.dal.get(layoutSettingsPointer)
    return _.get(layoutSettings, 'mechanism') === coreUtils.siteConstants.LAYOUT_MECHANISMS.MESH
}

function calcThunderboltNewRenderHints(ps: PS) {
    const flatData = processDataToResolver(ps)
    return renderHintsResolver(flatData)
}

const isClassicSectionsSite = (ps: PS) =>
    !!features.getFeatureData(ps, ps.pointers.components.getMasterPage(constants.VIEW_MODES.DESKTOP), 'pageSections')
        ?.isSectionsEnabled

export default function calculateSiteMetaDataRenderHints(ps: PS) {
    if (
        pageData.getNumberOfPages(ps) <= ps.runtimeConfig.minNumOfPagesToCalculateRenderHints ||
        experiment.isOpen('dm_dontRunClientRenderHints')
    ) {
        return
    }
    ps.extensionAPI.logger.interactionStarted(constants.INTERACTIONS.CALCULATE_SITE_META_DATA_RENDER_HINTS)
    const thunderboltNewRenderHints = calcThunderboltNewRenderHints(ps)
    const siteMetaData = siteMetadataAPI.getProperty(ps, siteMetadataAPI.PROPERTY_NAMES.SITE_META_DATA)
    const breakpointDataTypes = ['BreakpointRange', 'BreakpointsData']
    const interactionDataItems = ps.pointers.data.getVariantItemsWithPredicate(
        item => !_.includes(breakpointDataTypes, item.type),
        null
    )
    const breakpointsDataOnVariantsMapItems = ps.pointers.data.getVariantItemsWithPredicate(
        item => _.includes(breakpointDataTypes, item.type),
        null
    )
    siteMetaData.renderHints = _.defaults(
        {
            isMeshReady: isSiteAlreadyFlaggedAsReadyForMesh(ps),
            isClassicSectionsSite: isClassicSectionsSite(ps),
            containsAppPart: componentDetectorAPI.getComponentByType(ps, componentsTypesMap.appPart).length > 0,
            containsAppPart2: componentDetectorAPI.getComponentByType(ps, componentsTypesMap.appPart2).length > 0,
            containsTPA: componentDetectorAPI.getComponentByType(ps, componentsTypesMap.tpa).length > 0,
            containsInteractions: interactionDataItems.length > 0,
            containsBPVariantsData: breakpointsDataOnVariantsMapItems.length > 0,
            ...thunderboltNewRenderHints
        },
        siteMetaData.renderHints
    )
    siteMetadataAPI.setProperty(ps, siteMetadataAPI.PROPERTY_NAMES.SITE_META_DATA, siteMetaData)
    ps.dal.commitTransaction?.()
    ps.extensionAPI.logger.interactionEnded(constants.INTERACTIONS.CALCULATE_SITE_META_DATA_RENDER_HINTS)
}
