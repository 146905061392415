const _ = require('lodash')
const dataUtils = require('../../helpers/dataUtils')
const {createItemWithValues, createVariantRelation} = require('../effectsUtils')
const {migratedStyleToStyleProperties} = require('./migratedStyleToStyleProperties')
const updateStyleItem = require('./updateStyleItem')

const REF_ARRAY_TYPE = 'RefArray'
const MOBILE_VARIANT_RELATION_TYPE = 'MOBILE-VARIANT'

function createRefArray({styles, migratedComponent, getNewStyleId}) {
    const refArrayId = getNewStyleId()
    styles[refArrayId] = createItemWithValues(refArrayId, REF_ARRAY_TYPE, [migratedComponent.styleId])
    migratedComponent.styleId = refArrayId

    return refArrayId
}

function updateMobileRelationOnRefArray({styles, getNewStyleId, mobileStyleId, compId, refArrayId}) {
    const mobileVariantRelationId = getNewStyleId()

    styles[mobileVariantRelationId] = createVariantRelation(
        mobileVariantRelationId,
        [MOBILE_VARIANT_RELATION_TYPE],
        mobileStyleId,
        compId
    )

    styles[refArrayId] = dataUtils.updateItemWithValues(styles[refArrayId], [mobileVariantRelationId])
}

/**
 * @param {object} options
 * @param {object} options.styles
 * @param {import('./index').ParsedStyleItem} options.compStyleItem
 * @param {import('./index').ComponentStructure} options.migratedComponent
 * @param {boolean} options.isMobileOnlyComp
 * @param {function} options.getNewStyleId
 */
function updateOldComponentStructure({styles, getNewStyleId, compStyleItem, migratedComponent, isMobileOnlyComp}) {
    const mobileStyle = migratedStyleToStyleProperties(compStyleItem, true)

    // For mobileOnlyComponents, we write styles as defaultStyle, and not inside refArray as variants
    if (isMobileOnlyComp) {
        updateStyleItem(styles, {
            ...compStyleItem,
            style: mobileStyle
        })

        migratedComponent.styleId = compStyleItem.id
        return
    }
    const refArrayId = createRefArray({styles, migratedComponent, getNewStyleId})

    // desktop style item
    updateStyleItem(styles, {
        ...compStyleItem,
        style: migratedStyleToStyleProperties(compStyleItem)
    })

    const isDesktopOnly = _.isEmpty(mobileStyle.properties) && _.isEmpty(mobileStyle.propertiesOverride)

    if (isDesktopOnly) {
        return
    }

    const mobileStyleId = getNewStyleId()

    // mobile style item
    updateStyleItem(styles, {
        ...compStyleItem,
        id: mobileStyleId,
        style: mobileStyle
    })

    updateMobileRelationOnRefArray({
        styles,
        getNewStyleId,
        compId: migratedComponent.id,
        mobileStyleId,
        refArrayId
    })
}

module.exports = updateOldComponentStructure
