import _ from 'lodash'
import type {DocumentManager, DAL, DalValue} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import {refArrayUtils} from '@wix/document-manager-extensions'
import {cleanRef} from './migrationUtil'

const {breakpointRelation, variantRelation, refArray: refArrayRelation} = refArrayUtils

export type ShouldRemoveScopedItem = (id: string) => boolean

const hasCorruptedRelation = (documentManager: DocumentManager, relation: any, pageId: string) => {
    if (breakpointRelation.isBreakpointRelation(relation)) {
        const breakpointId = _.get(relation, ['breakpoint'])
        const bpPointer = documentManager.pointers.data.getBreakpointsDataItem(cleanRef(breakpointId), pageId)
        return !documentManager.dal.has(bpPointer)
    } else if (variantRelation.isVariantRelation(relation)) {
        const variants = (refArrayUtils.variantRelation.extractVariants(relation) || []) as string[]
        return _.some(variants, variantId => {
            const varPointer = documentManager.pointers.data.getVariantsDataItem(cleanRef(variantId), pageId)
            return !documentManager.dal.has(varPointer)
        })
    }
}

const queryPointers = (dal: DAL, ns: string, pageId: string, filter: (value: DalValue) => boolean): Pointer[] => {
    const dalValues = dal.query(ns, dal.queryFilterGetters.getPageCompFilter(pageId), filter)
    return _.map(dalValues, (v, k) => ({
        id: k,
        type: ns,
        pageId: v.metaData.pageId
    }))
}

const getRelationsFromNamespace = (dal: DAL, ns: string, pageId: string): Pointer[] =>
    queryPointers(
        dal,
        ns,
        pageId,
        _.overSome<DalValue>([breakpointRelation.isBreakpointRelation, variantRelation.isVariantRelation])
    )

const removeValueFromRefArray = (documentManager: DocumentManager, refArrayPointer: Pointer, idToRemove: string) => {
    const refArray = documentManager.dal.get(refArrayPointer)
    const values = refArrayUtils.refArray.extractValuesWithoutHash(refArray)
    const newValues = _.difference(values, [idToRemove])
    const updatedRefArray = refArrayUtils.refArray.update(refArray, newValues)
    documentManager.dal.set(refArrayPointer, updatedRefArray)
}

const cleanBrokenVariantRelations = (
    documentManager: DocumentManager,
    namespace: string,
    pageId: string,
    valuesToRefArrayMap: any,
    shouldRemoveScopedItem: ShouldRemoveScopedItem
) => {
    const relationPointers = getRelationsFromNamespace(documentManager.dal, namespace, pageId)

    _.forEach(relationPointers, relationPointer => {
        const relation = documentManager.dal.get(relationPointer)
        const scopedId = breakpointRelation.isBreakpointRelation(relation)
            ? breakpointRelation.extractRefWithoutHash(relation)
            : variantRelation.extractTo(relation)
        const refArrayId = valuesToRefArrayMap[relation.id]
        const refArrayPointer = documentManager.pointers.getPointer(refArrayId, namespace)
        const scopedStylePointer = documentManager.pointers.getPointer(scopedId, namespace)

        if (hasCorruptedRelation(documentManager, relation, pageId) || !documentManager.dal.has(scopedStylePointer)) {
            if (shouldRemoveScopedItem(cleanRef(scopedId))) {
                documentManager.dal.remove(scopedStylePointer)
            }
            documentManager.dal.remove(relationPointer)
            removeValueFromRefArray(documentManager, refArrayPointer, relationPointer.id)
        }
    })
}

const cleanBrokenReferences = (
    documentManager: DocumentManager,
    namespace: string,
    pageId: string,
    valuesToRefArrayMap: any,
    shouldRemoveScopedItem: ShouldRemoveScopedItem
) => {
    const relationPointers = queryPointers(
        documentManager.dal,
        namespace,
        pageId,
        _.overSome<DalValue>([refArrayRelation.isRefArray])
    )

    _.forEach(relationPointers, relationPointer => {
        const relation = documentManager.dal.get(relationPointer)

        const refArrayIds = relation.values
            .map((refId: string) => cleanRef(refId))
            .filter((refId: string) => !!valuesToRefArrayMap[refId])
        const refArrayPointers: Pointer[] = refArrayIds.map((refId: string) =>
            documentManager.pointers.getPointer(refId, namespace)
        )

        refArrayPointers
            .filter(pointer => documentManager.dal.has(pointer))
            .forEach(pointer => {
                if (shouldRemoveScopedItem(pointer.id)) {
                    documentManager.dal.remove(pointer)
                }
                removeValueFromRefArray(documentManager, relationPointer, pointer.id)
            })
    })
}

export {removeValueFromRefArray, cleanBrokenVariantRelations, cleanBrokenReferences, queryPointers}
