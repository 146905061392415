import _ from 'lodash'

function getWidgetDisplayName(platformAppConfiguration, widgetId?: string) {
    if (!platformAppConfiguration?.api) {
        return null
    }
    const tpaConfigurationFile = platformAppConfiguration.api
    const platformAppConfigurationObj = _.assign({}, _.assign({}, ...tpaConfigurationFile))
    if (_.has(platformAppConfigurationObj, widgetId)) {
        return _.get(platformAppConfigurationObj[widgetId], ['widgetDisplayName']) || widgetId
    }
    return null
}

export default {
    getWidgetDisplayName
}
