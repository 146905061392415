import type {PS} from '@wix/document-services-types'
import * as mobileCore from '@wix/mobile-conversion'
import * as santaCoreUtils from '@wix/santa-core-utils'
import _ from 'lodash'
import dataModel from '../../dataModel/dataModel'
import theme from '../../theme/theme'
import dsUtils from '../../utils/utils'

function getTextColorValue(ps: PS, textColorString: string) {
    if (_.isEmpty(textColorString)) {
        return null
    }
    if (/^#\w{3,6}$/.test(textColorString)) {
        return textColorString
    }
    const colorName = _.get(textColorString.match(/^\{(color_\d+)\}$/), 1, '')
    return colorName ? theme.colors.get(ps, colorName) : null
}

const isColorClassName = (className: string) => /^color_\d+$/.test(className)
const isFontClassName = (className: string) => /^font_\d{1,10}$/.test(className)
const compactObject = o => _.pickBy(o, _.identity)

function getFontDataByClassName(ps: PS, className: string) {
    if (isColorClassName(className)) {
        return compactObject({textColor: theme.colors.get(ps, className)})
    }
    if (!isFontClassName(className)) {
        return {}
    }
    const fontString = theme.fonts.get(ps, className)
    const execResult =
        /^\w+\s\w+\s\w+\s(-?\d+(?:\.\d+)?)px\/[\w\-.]*\s('[^']*'|[\w#$%&'()*+,.:;?@^_`~-]*)\s({\w+}|#\w{3,6})?$/.exec(
            fontString
        )
    const textColor = getTextColorValue(ps, _.get(execResult, 3, ''))
    return compactObject({fontSize: +_.get(execResult, 1, 0), fontName: _.get(execResult, 2), textColor})
}

function getFontDataByStyleAttribute(style: string) {
    if (!_.size(style)) {
        return {}
    }
    style = ` ${style}`
    return compactObject({
        fontSize: +_.get(style.match(/font-size:\s?(\d+(\.\d+)?)px/i), 1, 0),
        textAlignment: _.get(style.match(/text-align:\s?(left|right|center)/i), 1),
        textColor: _.get(style.match(/[^-]color:\s*(#[\da-f]{3,6}|[a-z]{3,20})/i), 1)
    })
}

function getAccumulatedTextStats(
    ps: PS,
    node: HTMLElement,
    fontData = {
        fontSize: 0,
        fontName: '',
        textAlignment: 'left',
        textColor: ''
    }
): {
    textLength: number
    accumulatedFontSize: number
    accumulatedNormalizedFontSize: number
    textAlignments: string[]
    textColors: string[]
    fontNames: string[]
} {
    const defaultTextStats = {
        textLength: 0,
        accumulatedFontSize: 0,
        accumulatedNormalizedFontSize: 0,
        textAlignments: [] as string[],
        textColors: [] as string[],
        fontNames: [] as string[]
    }
    if (_.get(node, 'nodeType') === node.TEXT_NODE) {
        const textLength = node.textContent.trim().length
        return _.assign(
            {
                textLength,
                accumulatedFontSize: textLength * fontData.fontSize,
                accumulatedNormalizedFontSize:
                    textLength * mobileCore.textUtils.getFontSizeByFontName(fontData.fontName, fontData.fontSize)
            },
            textLength > 0
                ? {
                      textAlignments: [fontData.textAlignment],
                      fontNames: [fontData.fontName],
                      textColors: [fontData.textColor]
                  }
                : {textAlignments: [], textColors: [], fontNames: []}
        )
    }
    if (!node || !_.size(node.childNodes)) {
        return defaultTextStats
    }
    const newFontData = {}
    if (node.nodeType === node.ELEMENT_NODE) {
        const className = node.getAttribute('class')
        const style = node.getAttribute('style')
        _.defaults(newFontData, getFontDataByStyleAttribute(style), getFontDataByClassName(ps, className))
    }
    _.defaults(newFontData, fontData)
    return _.reduce(
        node.childNodes,
        (result, childNode) => {
            // @ts-expect-error
            const stats = getAccumulatedTextStats(ps, childNode, newFontData)
            return {
                fontNames: [...result.fontNames, ...stats.fontNames],
                textColors: [...result.textColors, ...stats.textColors],
                textAlignments: [...result.textAlignments, ...stats.textAlignments],
                textLength: result.textLength + stats.textLength,
                accumulatedFontSize: result.accumulatedFontSize + stats.accumulatedFontSize,
                accumulatedNormalizedFontSize:
                    result.accumulatedNormalizedFontSize + stats.accumulatedNormalizedFontSize
            }
        },
        defaultTextStats
    )
}

function getMobileScale(avgFontSize: number, textLength: number) {
    const newSize = mobileCore.textUtils.convertFontSizeByTextLength(avgFontSize, textLength)
    const sizeAfterLegacyImplicitConversionInViewer = santaCoreUtils.mobileUtils.convertFontSizeToMobile(avgFontSize, 1)

    return newSize / sizeAfterLegacyImplicitConversionInViewer
}

function getNestedChildren(element: HTMLElement, acc: HTMLElement[] = []): HTMLElement[] {
    if (element.children.length > 0) {
        return [element, ...getNestedChildren(element.children[0] as HTMLElement, acc)]
    }
    return [...acc, element]
}

function getActualMobileSizesForSpecificFontSize(ps: PS, textElement: HTMLElement, fontSize: number) {
    const children = getNestedChildren(textElement)
    const fontSizeNodes = children.filter(child => !!child.style.fontSize || child.className.search(/font_/) !== -1)
    let childWithFontSizeProp
    /**
     * if textElement contains children with font data, we use it otherwise it should be taken last nested child;
     */
    if (fontSizeNodes.length > 0) {
        fontSizeNodes.forEach(node => {
            node.style.fontSize = `${fontSize}px`
        })
        childWithFontSizeProp = fontSizeNodes[0]
    } else {
        childWithFontSizeProp = children[children.length - 1]
        childWithFontSizeProp.style.fontSize = `${fontSize}px`
    }

    return {
        approximateMobileWidth: getActualWidth(childWithFontSizeProp),
        approximateMobileHeight: getActualHeight(childWithFontSizeProp)
    }
}

function getActualSize(sizeKey: 'clientHeight' | 'clientWidth', textElement: HTMLElement): number {
    return typeof window !== 'undefined' ? textElement[sizeKey] + 1 : 0
}

function getActualWidth(textElement: HTMLElement) {
    return getActualSize('clientWidth', textElement)
}

function getActualHeight(textElement: HTMLElement) {
    return getActualSize('clientHeight', textElement)
}

function reduceTextData(ps: PS, textElement: HTMLElement) {
    const stats = getAccumulatedTextStats(ps, textElement)
    const {textLength} = stats
    const averageNormalizedFontSize = stats.accumulatedNormalizedFontSize / textLength
    const mobileScale = getMobileScale(averageNormalizedFontSize, textLength)
    const averageFontSize = stats.accumulatedFontSize / stats.textLength
    const mobileAdjustedAverageFontSize = santaCoreUtils.mobileUtils.convertFontSizeToMobile(averageFontSize || 0, 1)
    const realFontSizeToSetInViewer = mobileAdjustedAverageFontSize * mobileScale
    const textAlignments = _.uniq(stats.textAlignments)
    const {approximateMobileWidth, approximateMobileHeight} = getActualMobileSizesForSpecificFontSize(
        ps,
        textElement,
        realFontSizeToSetInViewer
    )
    return {
        fontNames: _.uniq(stats.fontNames),
        textLength,
        actualTextWidth: getActualWidth(textElement),
        actualTextHeight: getActualHeight(textElement),
        textColors: _.uniq(stats.textColors),
        textAlignments,
        averageFontSize,
        mobileAdjustedAverageFontSize,
        averageNormalizedFontSize,
        mobileScale,
        approximateMobileWidth,
        approximateMobileHeight
    }
}

/**
 * @param ps
 * @param component
 * @param pageId
 * @param parentElement
 * @param isVerticalText
 * @returns {{component: ({dataQuery}|*), htmlElement: *}}
 */
function createTextElement(ps: PS, component, pageId: string, parentElement: HTMLElement, isVerticalText = false) {
    const htmlElement = window.document.createElement('DIV')
    let content = ''
    if (component.dataQuery) {
        content = _.get(
            dataModel.getDataItemById(ps, dsUtils.stripHashIfExists(component.dataQuery), pageId),
            'text',
            ''
        )
    }
    if (isVerticalText) {
        /**
         * Vertical text set additional parent div with height equals to 100% to text content,
         * for calculation real size needs to remove this property
         */
        content = content.replace(/\s{1,2}height:\s{0,2}100%|height:\s{0,2}100%/, 'height: auto')
    }
    htmlElement.innerHTML = content
    htmlElement.setAttribute(
        'style',
        'opacity: 0; visibility: hidden; max-width: none !important; white-space: nowrap; position: fixed; top: 50%; left: 50%'
    )
    parentElement.appendChild(htmlElement)
    return {htmlElement, component}
}

function reduceAllTextsData(ps: PS, allComponents, pageId: string) {
    const textsContainerElement = window.document.createElement('DIV')
    const allTexts = _(allComponents)
        .filter(mobileCore.conversionUtils.isTextComponent)
        .map(component =>
            createTextElement(ps, component, pageId, textsContainerElement, component.conversionData.isVerticalText)
        )
        .value()
    window.document.body.appendChild(textsContainerElement)
    _.forEach(allTexts, text => {
        const {textAlignments, isVerticalText} = text.component.conversionData
        _.assign(
            text.component.conversionData,
            reduceTextData(ps, text.htmlElement),
            isVerticalText ? {textAlignments} : {}
        )
    })
    window.document.body.removeChild(textsContainerElement)
}

/**
 *
 * @param ps
 * @param component
 * @param pageId
 * @param {boolean} verticalText?
 * @returns {{textAlignments, mobileAdjustedAverageFontSize, actualTextHeight: *, actualTextWidth: *, fontNames: Array, textColors, mobileScale, textLength: number, averageFontSize, averageNormalizedFontSize}}
 */
function getTextsData(ps: PS, component, pageId: string, verticalText = false) {
    const textsContainerElement = window.document.createElement('DIV')
    /**
     * this check need for case when mobile structure wasn't generated yet, and conversionData is empty we set it manually in
     */
    const isVerticalText =
        component.conversionData?.isVerticalText !== undefined ? component.conversionData.isVerticalText : verticalText

    const text = createTextElement(ps, component, pageId, textsContainerElement, isVerticalText)

    window.document.body.appendChild(textsContainerElement)
    const textData = reduceTextData(ps, text.htmlElement)
    window.document.body.removeChild(textsContainerElement)
    return textData
}

const testAPI = {
    getTextColorValue,
    getFontDataByClassName,
    getFontDataByStyleAttribute,
    getAccumulatedTextStats
}

export default {
    reduceAllTextsData,
    getTextsData,
    testAPI
}
