import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import componentStructureInfo from '../../component/componentStructureInfo'
import metaDataUtils from '../../componentsMetaData/metaDataUtils'
import popupUtils from '../../page/popupUtils'
import anchorPlugin from './anchorPlugin'
import fullViewPortPlugin from './fullViewPortPlugin'
import horizontalLinePlugin from './horizontalLinePlugin'
import matrixGalleryPlugin from './matrixGalleryPlugin'
import popupContainerWidthPlugin from './popupContainerWidthPlugin'
import quickActionBarPlugin from './quickActionBarPlugin'
import screenWidthPlugin from './screenWidthPlugin'
import siteStructurePlugin from './siteStructurePlugin'
import textHeightPlugin from './textHeightPlugin'
import verticalLinePlugin from './verticalLinePlugin'

const getScreenLayoutPlugins = (viewerName: string) => ({
    'wysiwyg.viewer.components.MatrixGallery': matrixGalleryPlugin,
    'wysiwyg.common.components.anchor.viewer.Anchor': anchorPlugin,
    'wysiwyg.viewer.components.WSiteStructure': siteStructurePlugin,
    'wysiwyg.viewer.components.WRichText': viewerName === 'tb' ? _.noop : textHeightPlugin,
    'wysiwyg.viewer.components.FiveGridLine': horizontalLinePlugin,
    'wysiwyg.viewer.components.VerticalLine': verticalLinePlugin,
    'wysiwyg.viewer.components.PopupContainer': popupContainerWidthPlugin,
    'wysiwyg.viewer.components.QuickActionBar': quickActionBarPlugin
})

export default {
    getPlugin(privateApi: PS, compPointer: Pointer) {
        const {viewerName} = privateApi.runtimeConfig
        const compType = componentStructureInfo.getType(privateApi, compPointer)
        let plugin = getScreenLayoutPlugins(viewerName)[compType]

        if (!plugin && metaDataUtils.isLegacyFullWidthContainer(privateApi, compPointer)) {
            plugin = popupUtils.isPopup(privateApi, compPointer.id) ? fullViewPortPlugin : screenWidthPlugin
        }

        return plugin
    }
}
