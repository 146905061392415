import type {ExtensionAPI, SnapshotDal} from '@wix/document-manager-core'
import type {Callback1, PS} from '@wix/document-services-types'
import appStudioSaveTask from '../../appStudio/appStudioSaveTask'
import createReloadSitePropertyInfoTask from '../../siteMetadata/saveTasks/reloadSitePropertyInfoTask'
import createSaveUnprovisionedAppsForAppFlows from '../../tpa/saveTasks/saveUnprovisionedAppsForAppFlows'
import type {ImmutableSnapshot} from '../../types'
import wixappsBlogSavePlugin from '../../wixapps/services/save/wixappsBlogSavePlugin'
import createSaveCodeTask from '../../wixCode/saveTasks/saveCode'
import type {BICallbacks, SaveOptions} from '../createSaveAPI'
import saveDocument from '../saveTasks/saveDocument'

export interface SaveTaskRegistry {
    getSaveTasksConfig(ps: PS): SaveTaskRegistryResult
}

export interface SaveTaskRegistryResult {
    requiredTasks: SaveTaskDefinition[]
    primaryTask: SaveTaskDefinition
    secondaryTasks: SaveTaskDefinition[]
}

export type CallbackSuccess = (value?: any) => void

export interface SaveTaskDefinition {
    requiresCurrentSnapshotDal?: Boolean
    shouldRun?(ps: PS, methodName: string, lastSnapshotDal: SnapshotDal, currentSnapshotDal: SnapshotDal): boolean

    partialSave(
        lastSnapshot: ImmutableSnapshot,
        currentSnapshot: ImmutableSnapshot,
        resolve: CallbackSuccess,
        reject: Callback1<any>,
        bi: BICallbacks,
        options: SaveOptions,
        lastSnapshotDal: SnapshotDal,
        currentSnapshotDal: SnapshotDal,
        extensionsAPI: ExtensionAPI
    ): void
    fullSave(
        lastSnapshot: ImmutableSnapshot,
        currentSnapshot: ImmutableSnapshot,
        resolve: CallbackSuccess,
        reject: Callback1<any>,
        bi: BICallbacks,
        options: SaveOptions,
        lastSnapshotDal: SnapshotDal,
        currentSnapshotDal: SnapshotDal,
        extensionsAPI?: ExtensionAPI
    ): void
    saveAsTemplate(
        lastSnapshot: ImmutableSnapshot,
        currentSnapshot: ImmutableSnapshot,
        resolve: CallbackSuccess,
        reject: Callback1<any>,
        bi: BICallbacks,
        options: SaveOptions,
        lastSnapshotDal: SnapshotDal,
        currentSnapshotDal: SnapshotDal,
        extensionsAPI: ExtensionAPI
    ): void
    publish(
        currentSnapshotDal: SnapshotDal,
        boundExtensionsAPI: ExtensionAPI,
        resolve: CallbackSuccess,
        reject: (reason?: any) => void,
        bi: BICallbacks,
        options?: SaveOptions
    ): void
    getTaskName(): string
    getSnapshotTags(methodName: string): string[]
    getCurrentState?(): unknown
    getLastState?(): unknown
    getCurrentState?(): unknown
    onTaskSuccess?(result): void
    takeSnapshot?(): void
    rollback?(): void

    promises?: any
    fullSaveAsync?: any
}

function getSaveTasksConfig(ps: PS): SaveTaskRegistryResult {
    return {
        requiredTasks: [createSaveCodeTask(ps), appStudioSaveTask.createPreSave(ps)],
        primaryTask: saveDocument,
        secondaryTasks: [
            createSaveUnprovisionedAppsForAppFlows(),
            createReloadSitePropertyInfoTask(),
            wixappsBlogSavePlugin.create(ps)
        ]
    }
}

export default {
    getSaveTasksConfig
}
