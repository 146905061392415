import type {CreateExtArgs, Extension, ExtensionAPI} from '@wix/document-manager-core'
import type {PageOptions, PageExtensionAPI} from '../page'
import {setLegacyData, enrichLegacyData, mergeLegacyData} from '../../utils/advancedSeoUtils'

const createExtension = (): Extension => {
    const createExtensionAPI = ({extensionAPI, coreConfig}: CreateExtArgs): AdvancedSeoExtApi => {
        const {page: pageApi} = extensionAPI as PageExtensionAPI
        const {logger} = coreConfig

        const setSeoData = (pageId: string, advancedObject: string, languageCode?: string): void => {
            const options = {languageCode}
            pageApi.setAdvancedSeoData(pageId, advancedObject, options as PageOptions)
            setLegacyData(pageApi, logger, pageId, advancedObject, options as PageOptions)
        }

        const getSeoData = (pageId: string, languageCode?: string): string => {
            const options = {languageCode}
            const advancedObject = pageApi.getAdvancedSeoData(pageId, options as PageOptions)
            return enrichLegacyData(pageApi, logger, pageId, advancedObject, options as PageOptions)
        }

        const getRawSeoData = (pageId: string, languageCode?: string): string => {
            const options = {languageCode}
            const advancedObject = pageApi.getAdvancedSeoData(pageId, options as PageOptions)
            return mergeLegacyData(pageApi, logger, pageId, advancedObject, options as PageOptions)
        }

        return {
            advancedSeo: {
                setSeoData,
                getSeoData,
                getRawSeoData
            }
        }
    }

    return {
        name: 'advancedSeo',
        createExtensionAPI
    }
}

export type AdvancedSeoExtApi = ExtensionAPI & {advancedSeo: AdvancedSeoApi}

export interface AdvancedSeoApi {
    setSeoData(pageId: string, advancedObject: string, languageCode?: string): void
    getSeoData(pageId: string, languageCode?: string): string
    getRawSeoData(pageId: string, languageCode?: string): string
}

export {createExtension}
