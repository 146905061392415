import _ from 'lodash'
import type {DocumentManager} from '@wix/document-manager-core'
import type {Pointer, ResolvedReference} from '@wix/document-services-types'
import {cleanRef, createRef} from '../utils/migrationUtil'
import {constants} from '@wix/document-manager-extensions'
import {ReportableError} from '@wix/document-manager-utils'

const getHtmlEncodedLink = (linkId: string) => `dataquery="${createRef(linkId, constants.DATA_TYPES.data)}"`

interface ReferenceUpdateArgs {
    documentManager: DocumentManager
    ownerPointer: Pointer
    resolvedReference: ResolvedReference
    newRefId: string
}

const updateWRichTextLink = ({documentManager, ownerPointer, resolvedReference, newRefId}: ReferenceUpdateArgs) => {
    const {dal, logger} = documentManager
    const owner = dal.get(ownerPointer)
    const {id: oldId} = resolvedReference
    if (owner.text.includes(getHtmlEncodedLink(oldId))) {
        dal.set(
            ownerPointer,
            _.merge(_.cloneDeep(owner), {
                text: owner.text.replace(new RegExp(getHtmlEncodedLink(oldId), 'g'), getHtmlEncodedLink(newRefId)),
                linkList: _(owner.linkList)
                    .without(createRef(oldId, constants.DATA_TYPES.data))
                    .concat([createRef(newRefId, constants.DATA_TYPES.data)])
                    .value()
            })
        )
    } else {
        logger.captureError(
            new ReportableError({
                errorType: 'unfixedTextLink',
                message: `StyledText ${owner.id} is unfixed for link ${oldId}`,
                extras: {
                    styledText: owner
                }
            })
        )
    }
}

const updateSingleReference = ({documentManager, ownerPointer, resolvedReference, newRefId}: ReferenceUpdateArgs) => {
    const {dal} = documentManager
    const owner = _.cloneDeep(dal.get(ownerPointer))
    const {path, referencedMap} = resolvedReference.refInfo
    _.set(owner, path, createRef(newRefId, referencedMap))
    dal.set(ownerPointer, owner)
}

const updateRefList = ({documentManager, ownerPointer, resolvedReference, newRefId}: ReferenceUpdateArgs) => {
    const {dal} = documentManager
    const {referencedMap, refInfo, id} = resolvedReference
    const owner = _.cloneDeep(dal.get(ownerPointer))
    const list = _.get(owner, refInfo.path)
    const updatedList = list.map((ref: string) => (cleanRef(ref) === id ? createRef(newRefId, referencedMap) : ref))
    _.set(owner, refInfo.path, updatedList)
    documentManager.dal.set(ownerPointer, owner)
}

export const updateReference = (referenceUpdateArgs: ReferenceUpdateArgs) => {
    const {documentManager, ownerPointer, resolvedReference} = referenceUpdateArgs
    const schema = documentManager.dal.get(ownerPointer).type
    const namespace = ownerPointer.type
    if (namespace === 'data' && schema === 'StyledText') {
        updateWRichTextLink(referenceUpdateArgs)
        return
    }
    if (resolvedReference.refInfo.isList) {
        updateRefList(referenceUpdateArgs)
        return
    }
    updateSingleReference(referenceUpdateArgs)
}
