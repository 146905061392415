import type {PublicMethodUtils} from '@wix/document-services-types'

export default function (publicMethodUtils: PublicMethodUtils) {
    const {
        extensionPublicAPI: {dataManipulation: extensionDataManipulation}
    } = publicMethodUtils

    return {
        methods: {
            responsiveFixers: {
                pinToSticky: {
                    migratePage: extensionDataManipulation('responsiveFixers.pinToSticky.migratePage')
                }
            }
        }
    }
}
