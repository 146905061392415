import type {AppDefinitionId, Pointer, PS, Callback, AppDataToDelete, CompRef} from '@wix/document-services-types'
import _ from 'lodash'
import page from '../../page/page'
import component from '../../component/component'
import clientSpecMapService from './clientSpecMapService'
import installedTpaAppsOnSiteService from './installedTpaAppsOnSiteService'
import tpaConstants from '../constants'
import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'

const notifyHiddenSectionsAboutToDelete = function (ps: PS, appDefinitionId: AppDefinitionId) {
    const hiddenPages = installedTpaAppsOnSiteService.getHiddenSections(ps, appDefinitionId)

    _.forEach(hiddenPages, function (hiddenPage) {
        page.validatePageRemovalInternal(ps, hiddenPage.pageId) //notify on remove
    })
}

const notifyDeleteApplication = function (ps: PS, appDefinitionId: AppDefinitionId, pageIdsToDelete) {
    const widgetCompsToDelete = getWidgetCompsToDelete(ps, appDefinitionId, pageIdsToDelete)
    _.forEach(widgetCompsToDelete, function (compMap) {
        notifyWidgetToDelete(ps, compMap.compPointer, compMap.appDefinitionId)
    })
    _.forEach(pageIdsToDelete, function (pageId) {
        page.validatePageRemovalInternal(ps, pageId)
    })
}

const getAppWidgetsOnOtherPages = function (ps: PS, appDefinitionId: AppDefinitionId, pageIdsToBeDeleted) {
    const appWidgetComps = installedTpaAppsOnSiteService.getWidgetsByAppDefId(ps, appDefinitionId)
    return _.reject(appWidgetComps, comp => _.includes(pageIdsToBeDeleted, comp.pageId))
}

const getWidgetsOnPagesToDelete = function (ps: PS, pageIdsToBeDeleted) {
    return _.reduce(
        pageIdsToBeDeleted,
        function (result, pageId) {
            const tpasOnPage = installedTpaAppsOnSiteService.getAllTpaCompsOnPage(ps, pageId)
            const widgetsOnPage = _.filter(tpasOnPage, {type: tpaConstants.DATA_TYPE.TPA_WIDGET})
            return _.union(result, widgetsOnPage)
        },
        []
    )
}

const getWidgetCompsToDeleteMap = function (
    ps: PS,
    widgetCompsToDelete
): {compPointer: CompRef; appDefinitionId: AppDefinitionId}[] {
    return _.map(widgetCompsToDelete, function (comp) {
        const pagePointer = page.getPage(ps, comp.pageId)
        const compPointer = ps.pointers.components.getComponent(comp.id, pagePointer)
        return {
            compPointer,
            appDefinitionId: comp.appDefinitionId
        }
    })
}

const getWidgetCompsToDelete = function (ps: PS, appDefinitionId: AppDefinitionId, pageIdsToBeDeleted) {
    const widgetsOnPagesToDelete = getWidgetsOnPagesToDelete(ps, pageIdsToBeDeleted)
    const appWidgetsOnOtherPages = getAppWidgetsOnOtherPages(ps, appDefinitionId, pageIdsToBeDeleted)
    const widgetCompsToDelete = _.union(widgetsOnPagesToDelete, appWidgetsOnOtherPages)
    return getWidgetCompsToDeleteMap(ps, widgetCompsToDelete)
}

const notifyAppsToDelete = function (ps: PS, appDefinitionId: AppDefinitionId, includeCurrentApp?): AppDataToDelete {
    let appDefIdsToDelete = _.map(
        installedTpaAppsOnSiteService.getInstalledDependentAppsData(ps, appDefinitionId),
        'appDefinitionId'
    )
    if (includeCurrentApp) {
        appDefIdsToDelete = _.union([appDefinitionId], appDefIdsToDelete)
    }
    const appsPagesToDelete = installedTpaAppsOnSiteService.getPagesByAppDefinitionIds(ps, appDefIdsToDelete)
    _.forEach(appDefIdsToDelete, function (appDefId: AppDefinitionId) {
        const pageIdsToDelete = _(appsPagesToDelete).filter({appDefinitionId: appDefId}).map('id').value()
        notifyDeleteApplication(ps, appDefId, pageIdsToDelete)
    })
    return {
        appsPagesToDelete,
        appDefIdsToDelete
    }
}

const notifyWidgetToDelete = function (
    ps: PS,
    compPointer: CompRef,
    appDefinitionId: AppDefinitionId,
    onComplete?: Callback
) {
    const installedWidgets = installedTpaAppsOnSiteService.getWidgetsByAppDefId(ps, appDefinitionId)
    if (isLastInstalledMainWidget(installedWidgets, ps, appDefinitionId, compPointer)) {
        notifyHiddenSectionsAboutToDelete(ps, appDefinitionId)
        notifyOnOtherWidgetsDelete(ps, appDefinitionId, installedWidgets)
        notifyAppsToDelete(ps, appDefinitionId)
    }
    component.validateRemovalInternal(ps, compPointer, onComplete)
}

const notifyOnOtherWidgetsDelete = function (ps: PS, appDefinitionId: AppDefinitionId, installedWidgets) {
    const widgetsToDelete = _.reject(installedWidgets, {widgetId: getMainWidgetId(ps, appDefinitionId)})

    _.forEach(widgetsToDelete, function (widget) {
        const compPointer = componentDetectorAPI.getComponentById(ps, widget.id, widget.pageId)
        component.validateRemovalInternal(ps, compPointer)
    })
}

const getMainWidgetId = function (ps: PS, appDefinitionId: AppDefinitionId) {
    const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
    if (appData) {
        const widgetData = _.find(appData.widgets, {default: true})
        return _.get(widgetData, 'widgetId')
    }
    return undefined
}

const isLastInstalledMainWidget = function (
    installedWidgets,
    ps: PS,
    appDefinitionId: AppDefinitionId,
    compPointer: Pointer
) {
    const installedMainWidgets = _.filter(installedWidgets, {widgetId: getMainWidgetId(ps, appDefinitionId)})
    return installedMainWidgets && installedMainWidgets.length === 1 && installedMainWidgets[0].id === compPointer.id
}

export default {
    notifyAppsToDelete,
    notifyWidgetAboutToDelete: notifyWidgetToDelete,
    getWidgetsOnPagesToDelete,
    isLastInstalledMainWidget,
    getWidgetCompsToDelete,
    getWidgetCompsToDeleteMap
}
