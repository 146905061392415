function getDefaultPlacement(compData, clientSpecMap) {
    const {applicationId} = compData
    const {widgetId} = compData

    const appData = clientSpecMap[applicationId]
    if (appData) {
        const widgetData = appData.widgets[widgetId]
        if (widgetData) {
            const widgetDataGluedOptions = widgetData.gluedOptions || {
                horizontalMargin: 0,
                placement: 'BOTTOM_RIGHT',
                verticalMargin: 0
            }
            return widgetDataGluedOptions.placement
        }
    }
    //TODO - report data error bi
}

export default {
    getDefaultPlacement
}
