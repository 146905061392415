import {CoreLogger, Events, PostTransactionOperation, TransactionEvents} from '@wix/document-manager-core'
import type {InnerViewerExtensionAPI, LayoutCB} from '@wix/document-manager-extensions'
import type {
    Callback,
    Callback1,
    Experiment,
    PS,
    RuntimeConfig,
    SetOperationsQueue,
    SoqErrorCallback,
    SoqErrorDetails
} from '@wix/document-services-types'
import type {ViewerManager} from '@wix/viewer-manager-adapter'
import _ from 'lodash'
import animationFrame from '../../utils/animationFrame'
import type {GSDAL} from '../generalSuperDal'
import {flushBatch} from './flushBatch'
import {flushBatchAnchorsLayout} from './flushBatchWithAnchors'
import {SetOperationsQueueBatch} from './SOQBatch'
import {BatchItem, createSOQItem, QItem, QItemParams} from './SOQItem'
import {SetOperationsTrace} from './SOQTrace'

class PromiseCollection {
    private promises: Promise<void>[]
    constructor() {
        this.promises = []
    }
    addPromise(promise: Promise<void>) {
        this.promises.push(promise)
    }
    async wait(): Promise<void> {
        const {promises} = this
        this.drop()
        await Promise.all(promises)
    }
    drop(): void {
        this.promises = []
    }
}

class CallbacksCollection {
    private callbacks: Function[]
    constructor() {
        this.callbacks = []
    }
    addCallback(callback: Function) {
        this.callbacks.push(callback)
    }
    once(callback: Function) {
        const cb = (...args: any[]) => {
            this.removeCallback(cb)
            callback(...args)
        }
        this.addCallback(cb)
    }
    removeCallback(callback: Function) {
        this.callbacks = _.without(this.callbacks, callback)
    }
    execute(...args: any): void {
        _.forEach(this.callbacks, callback => callback(...args))
    }
    drop(): void {
        this.callbacks = []
    }
}

export interface AdapterSOQ extends SetOperationsQueue {}

class Soq implements AdapterSOQ {
    private disposed = false
    private updateHandlesCounter = 1
    private queue: QItem[] = []
    private doneCallbacks: Record<number, CallbacksCollection> = {}
    private itemCurrentlyRunning: QItem | undefined
    private lastUpdateFinished: number | undefined
    private failed: Record<number, Error> = {}
    private onErrorCallbacks: CallbacksCollection = new CallbacksCollection()
    private onErrorCallbacksPerContext = {}
    private siteUpdatedCallbacks: CallbacksCollection = new CallbacksCollection()
    private deferFunction: Function = animationFrame.request
    private batch: SetOperationsQueueBatch | null = null
    private trace: SetOperationsTrace | null = null
    private currentContext: any = null
    private callsContext: string[] = []
    private waitForChangesEnabled = true
    private isUpdatingSiteSoon = false
    private waitForChangesAppliedInTransactionPromises: PromiseCollection = new PromiseCollection()
    private operationCallbacksInTransaction: CallbacksCollection = new CallbacksCollection()
    private commitsAndSaveDisabled: boolean = false
    private rendersEnabled: boolean = true
    private readonly flushFunction: (batch: SetOperationsQueueBatch) => Promise<void>
    constructor(
        private viewerManager: ViewerManager,
        private generalSuperDal: GSDAL,
        private runtimeConfig: RuntimeConfig,
        private layoutCircuitBreaker: LayoutCB,
        private logger: CoreLogger,
        private events: Events,
        private isDebugMode: boolean,
        private experimentInstance: Experiment,
        private viewerExtensionApi: InnerViewerExtensionAPI
    ) {
        this.flushFunction = this.createAnchorFlush()
        // trigger site changed by pushing an empty action to queue
        const runEmptyTransactionOp = (methodName: string, action: ReturnType<PostTransactionOperation>) => {
            if (action) {
                this.runSetOperation(_.noop, [], {
                    methodName: `${methodName}_afterRender`,
                    isAsyncOperation: true,
                    asyncPreDataManipulation: async () => {
                        await action()
                        this.onSiteUpdated([], [methodName])
                        this.asyncPreDataManipulationComplete(null, null as unknown as Error)
                    }
                })
            } else {
                this.onSiteUpdated([], [methodName])
                this.runSetOperation(_.noop, [], {
                    methodName: `${methodName}_afterRender`
                })
            }
        }

        events.emitter.on(
            TransactionEvents.TRANSACTION_REJECTED,
            _.partial(runEmptyTransactionOp, 'transactionRejected')
        )
        events.emitter.on(
            TransactionEvents.TRANSACTION_BY_OTHER,
            _.partial(runEmptyTransactionOp, 'transactionFromOtherClient')
        )
    }

    private createNewQueueItem(setOperation: Function, args: any[], params: QItemParams): QItem {
        this.updateHandlesCounter++
        return createSOQItem(setOperation, args, params, this.updateHandlesCounter)
    }

    private runNextStepInQueue() {
        if (this.disposed) {
            return
        }
        const nextItem = this.queue[0]
        if (!this.batch) {
            this.batch = new SetOperationsQueueBatch()
        }
        if (nextItem && this.batch.canAddToBatch(nextItem.params)) {
            this.queue.shift()
            this.executeSetOperation(nextItem)
        } else {
            this.closeBatchAndUpdate()
        }
    }

    //================================  single item handle ========================================================
    private executeSetOperation(queueItem: QItem) {
        this.itemCurrentlyRunning = queueItem
        if (!this.batch) {
            throw new Error('batch is undefined')
        }
        this.batch.addToBatch(queueItem.params, queueItem.handle, queueItem.args)

        if (this.batch.items.length === 1) {
            queueItem.firstInBatch = true
            this.trace = new SetOperationsTrace(this.runtimeConfig)
            this.deferFunction(this.applySetOperationAndRunNext.bind(this, queueItem))
        } else {
            this.applySetOperationAndRunNext(queueItem)
        }
    }

    private applySetOperationAndRunNext(queueItem: QItem) {
        if (queueItem.firstInBatch) {
            this.trace?.addTraceTime('notMe')
        }
        if (queueItem.isRealAsyncOperation && queueItem.asyncPreDataManipulation) {
            this.applyOperation(queueItem.asyncPreDataManipulation, queueItem.args, queueItem)
        } else {
            const operationSucceeded = this.applyOperation(queueItem.operation, queueItem.args, queueItem)
            if (operationSucceeded) {
                this.runNextStepInQueue()
            }
        }
    }

    private applyOperation(operation: Function, args: any[], queueItem: QItem) {
        try {
            operation(...args)
            return true
        } catch (e: any) {
            this.handleFailure(queueItem, e)
            return false
        }
    }

    private isNotTesting(): boolean {
        return !window.karmaIntegration && !window.__karma__ && typeof jest === 'undefined'
    }

    private printFailure(exception: Error) {
        if (this.isNotTesting() || this.experimentInstance.isOpen('dm_throwOnSoqFailure')) {
            console.error(exception)
        }
        if (this.isDebugMode && this.isNotTesting()) {
            window.alert('You have error in DS!!! look at the console') //eslint-disable-line no-alert
        }

        this.logger.captureError(exception, {
            tags: {soqThrow: true}
        })
    }

    private callErrorCallBacks(queueItem: QItem, exception: Error) {
        const currentContextForItem = _.get(queueItem.params, ['currentContext'])
        if (currentContextForItem && this.onErrorCallbacksPerContext[currentContextForItem]) {
            this.onErrorCallbacksPerContext[currentContextForItem]({
                error: exception,
                methodName: queueItem.params.methodName
            })
        }

        this.onErrorCallbacks.execute({
            error: exception,
            handle: queueItem.handle,
            methodName: queueItem.params.methodName,
            appDefinitionId: currentContextForItem
        })
    }

    private handleFailure(queueItem: QItem, exception: Error) {
        this.failed[queueItem.handle] = exception
        if (this.batch) {
            this.batch.removeFromBatch(queueItem.handle)
        }
        this.itemCurrentlyRunning = undefined

        this.callErrorCallBacks(queueItem, exception)
        this.printFailure(exception)

        if (this.batch) {
            this.closeBatchAndUpdate()
        }
    }

    private reportBatchFailure(exception: Error) {
        const commitItem = createSOQItem(_.noop, [], {methodName: 'batch'}, -1)
        this.generalSuperDal.dropUncommittedTransaction(exception.message)
        this.callErrorCallBacks(commitItem, exception)
        this.printFailure(exception)
    }

    //============================= batch closing ================================================================

    private createAnchorFlush(): (batch: SetOperationsQueueBatch) => Promise<void> {
        const commit = this.commit.bind(this)
        const flush = _.partial(flushBatch, commit, this.viewerManager, this.logger)
        return batch => flushBatchAnchorsLayout(batch, this.viewerManager, flush)
    }

    private async closeBatchAndUpdate() {
        if (this.batch === null) {
            return
        }
        const {items} = this.batch
        if (items.length === 0) {
            return
        }
        this.batch.closeBatch()
        this.trace?.addTraceTime('allOps')
        this.isUpdatingSiteSoon = true
        this.layoutCircuitBreaker.resetPostLayoutCount()

        try {
            await this.flushFunction(this.batch)
        } catch (err: any) {
            this.reportBatchFailure(err)
        }

        this.isUpdatingSiteSoon = false
        this.trace?.addTraceTime('renderAndStuff')

        const batchPostFlushOperations = this.batch.postFlushOperations
        _.forEach(batchPostFlushOperations, postUpdateOperation => {
            postUpdateOperation.operation()
        })
        this.trace?.addTraceTime('postUpdate')
        this.batch = null
        // @ts-ignore
        const contextChangedInBatch = _(items).values().map('currentContext').compact().uniq().value()
        _.forEach(contextChangedInBatch, context => {
            this.events.emitter.emit(this.events.CONTEXT.API_CALLED, context)
        })

        this.onSiteUpdated(
            items.map(it => it.itemId!),
            items.map(it => it.methodName!)
        )
    }

    private onSiteUpdated(handles: number[], methodNames: string[]) {
        if (this.disposed) {
            return
        }
        if (handles && !_.isEmpty(handles)) {
            this.itemCurrentlyRunning = undefined
            this.lastUpdateFinished = _.last(handles)
            _.forEach(handles, this.runDoneCallbacks.bind(this))
        }

        this.trace?.addTraceTime('waitForChanges')
        this.trace?.logTrace(handles)

        //we need this because during the done callbacks someone might run a set operation
        if (!this.itemCurrentlyRunning) {
            this.runNextStepInQueue()
        }

        this.invokeSiteUpdatedCbs(methodNames)
    }

    private invokeSiteUpdatedCbs(methodNames: string[] | null) {
        this.siteUpdatedCallbacks.execute(methodNames)
    }

    private runDoneCallbacks(handle: number) {
        const maybeFailed = this.failed[handle]
        this.doneCallbacks[handle]?.execute(maybeFailed)
        delete this.doneCallbacks[handle]
    }

    //================================= utils ===============================================================

    private getLastQueueItem(): QItem | null | undefined {
        if (!this.itemCurrentlyRunning && !this.queue.length) {
            return null
        }

        let lastQueueItem = this.itemCurrentlyRunning
        if (this.queue.length) {
            lastQueueItem = _.last(this.queue)
        }
        return lastQueueItem
    }

    private setNoBatchingAfterLastItemInQ() {
        const lastQueueItem = this.getLastQueueItem()
        if (lastQueueItem) {
            if (lastQueueItem === this.itemCurrentlyRunning) {
                this.batch?.closeBatch()
            } else {
                lastQueueItem.params.noBatchingAfter = true
            }
        }
    }

    private emitCsave() {
        if (this.queue.length === 0 && this.events.CSAVE) {
            this.events.emitter.emit(this.events.CSAVE.DO_CSAVE)
        }
    }

    private commit() {
        if (this.commitsAndSaveDisabled) {
            if (this.rendersEnabled) {
                this.viewerExtensionApi.syncViewerFromOpenTransaction()
            }
        } else if (this.waitForChangesEnabled) {
            this.generalSuperDal.commitTransaction('soq')
            this.emitCsave()
        }
    }

    private runSetOperationQueueItem(queueItem: QItem): void {
        this.queue.push(queueItem)

        if (!this.isRunningSetOperation()) {
            this.runNextStepInQueue()
        }
    }

    dispose() {
        this.queue = []
        this.doneCallbacks = {}
        this.onErrorCallbacks.drop()
        this.disposed = true
    }

    useSimpleTimeoutToDefer(timeToWait: number) {
        this.deferFunction = _.partialRight(_.delay, timeToWait)
    }

    useAnimationFrameToDefer() {
        this.deferFunction = animationFrame.request
    }

    runSetOperation(setOperation: Function, args: any[], operationParams: QItemParams = {}) {
        if (_.isNil(args)) {
            args = []
        }
        const queueItem = this.createNewQueueItem(setOperation, args, operationParams)
        this.runSetOperationQueueItem(queueItem)
        return queueItem.handle
    }

    runImmediateSetOperation(setOperation: Function, setOperationsParams: QItemParams, args: any[] = []) {
        try {
            const result = setOperation(...args)
            if (setOperationsParams.isUpdatingData) {
                this.commit()
            }
            if (setOperationsParams.currentContext) {
                this.events.emitter.emit(this.events.CONTEXT.API_CALLED, setOperationsParams.currentContext)
            }
            return result
        } catch (e: any) {
            const queueItem = this.createNewQueueItem(setOperation, args, setOperationsParams)
            this.handleFailure(queueItem, e)
            throw e
        }
    }

    isRunningSetOperation() {
        return !!this.itemCurrentlyRunning
    }

    executeAfterCurrentOperationDone(callback: Callback) {
        if (this.itemCurrentlyRunning && this.waitForChangesEnabled) {
            this.batch?.addPostUpdateOperation(callback, this.itemCurrentlyRunning.handle)
        } else {
            this.operationCallbacksInTransaction.addCallback(callback)
        }
    }

    executeOperationCallbacksInTransaction() {
        this.operationCallbacksInTransaction.execute()
        this.operationCallbacksInTransaction.drop()
    }

    asyncPreDataManipulationComplete(data: any, error: Error) {
        if (!this.itemCurrentlyRunning?.isRealAsyncOperation) {
            return
        }
        if (error) {
            this.handleFailure(this.itemCurrentlyRunning, error)
        } else {
            const queueItem = this.itemCurrentlyRunning
            if (!_.isNil(data)) {
                queueItem.args.splice(1, 0, data)
            }
            this.applyOperation(queueItem.operation, queueItem.args, queueItem)
            _.defer(this.runNextStepInQueue.bind(this))
        }
    }

    registerToSiteChanged(callback: Callback1<void>) {
        this.siteUpdatedCallbacks.addCallback(callback)
    }

    registerToNextSiteChanged(callback: Callback1<void>) {
        if (this.itemCurrentlyRunning) {
            this.batch?.addPostUpdateOperation(callback, this.itemCurrentlyRunning.handle)
        } else {
            this.siteUpdatedCallbacks.once(callback)
        }
    }

    getCurrentContext() {
        return this.currentContext
    }

    /**
     * Used for reporting, produces information about the batch that can be printed or sent as logs
     */
    getCurrentBatchSummary(): any {
        const itemSummary = _.map(this.batch?.items, (item: BatchItem) => ({
            methodName: item.methodName,
            args: _.tail(item.args) // removing ps
        }))

        return {
            commitsAndSaveDisabled: this.commitsAndSaveDisabled ? true : undefined,
            items: itemSummary
        }
    }

    registerToErrorThrown(callback: SoqErrorCallback) {
        this.onErrorCallbacks.addCallback(callback)
    }
    registerToErrorThrownForContext(context: string, callback: SoqErrorCallback) {
        this.onErrorCallbacksPerContext[context] = callback
    }
    runInContext(ps: PS, context: any, dataManipulationWrapper: Function) {
        this.currentContext = context
        const result = dataManipulationWrapper()
        this.currentContext = null
        return result
    }
    getCallsContext() {
        return [...this.callsContext]
    }
    addCallContext(name: string) {
        this.callsContext.push(name)
    }
    removeCallContext(name: string) {
        const ind = this.callsContext.lastIndexOf(name)
        if (ind !== -1) {
            this.callsContext.splice(ind, 1)
        }
    }
    unRegisterToErrorThrownForContext(context: string) {
        delete this.onErrorCallbacksPerContext[context]
    }

    unRegisterFromErrorThrown(callback: SoqErrorCallback) {
        this.onErrorCallbacks.removeCallback(callback)
    }

    unRegisterAll() {
        this.siteUpdatedCallbacks.drop()
        this.onErrorCallbacks.drop()
    }

    /**
     * can be used inside DS
     * the callback will be executed when ALL (maybe some others as well) the operations registered until now are done
     * can be used for opening panels after added the component, BI and such
     * @param {function():void} callback
     */
    waitForChangesApplied(callback: Callback) {
        if (!this.waitForChangesEnabled) {
            this.waitForChangesAppliedInTransactionPromises.wait().then(() => {
                callback() // eslint-disable-line promise/no-callback-in-promise
            })
        } else {
            this.waitForChangesAppliedInTransactionPromises.drop()
            const lastQueueItem = this.getLastQueueItem()
            if (lastQueueItem) {
                this.registerToSetOperationDone(lastQueueItem.handle, callback)
            } else {
                callback()
            }
        }
    }

    /**
     * should not be used inside DS - the promise changes the timing
     * mostly for tests. because this will be resolved after the siteChanged event is fired
     */
    async waitForChangesAppliedAsync(): Promise<void> {
        let errorInfo: SoqErrorDetails
        const errorCB = (e: SoqErrorDetails) => {
            errorInfo = errorInfo ?? e //do not reassign if we somehow have more than one error while flushing
        }
        this.registerToErrorThrown(errorCB)

        await new Promise(resolve => this.waitForChangesApplied(resolve as Callback))

        this.unRegisterFromErrorThrown(errorCB)
        if (errorInfo! && this.experimentInstance.isOpen('dm_throwOnSoqFailure')) {
            throw errorInfo.error
        }
    }

    registerToSetOperationDone(handle: number, callback: Callback1<Error | undefined>) {
        if (this.lastUpdateFinished && handle <= this.lastUpdateFinished) {
            callback(this.failed[handle])
        } else {
            this.doneCallbacks[handle] = this.doneCallbacks[handle] || new CallbacksCollection()
            this.doneCallbacks[handle].addCallback(callback)
        }
    }

    registerToWaitForChangesAppliedInTransaction(promise: Promise<void>) {
        this.waitForChangesAppliedInTransactionPromises.addPromise(promise)
    }

    /**
     * the callback will be executed when ALL AND ONLY the operations registered until now are done
     * needed for take snapshot, save and mobile conversion
     * @param callback
     */
    flushQueueAndExecute(callback: Callback) {
        this.setNoBatchingAfterLastItemInQ()
        //for now is the same since there is no batching
        this.waitForChangesApplied(callback)
    }

    triggerSiteUpdated() {
        if (!this.isUpdatingSiteSoon) {
            this.invokeSiteUpdatedCbs(null)
        }
    }

    async withWaitForChangesDisabled<T>(action: () => Promise<T>): Promise<T> {
        this.waitForChangesEnabled = false
        try {
            const actionResult = await action()
            await this.waitForChangesAppliedInTransactionPromises.wait()
            return actionResult
        } finally {
            this.waitForChangesEnabled = true
        }
    }

    async withCommitsAndSavesDisabled<T>(action: () => Promise<T>): Promise<T> {
        if (this.commitsAndSaveDisabled) {
            return await action()
        }
        this.commitsAndSaveDisabled = true
        try {
            const result = await action()
            await this.waitForChangesAppliedAsync()
            return result
        } finally {
            this.commitsAndSaveDisabled = false
        }
    }

    async noRenders<T>(action: () => Promise<T>) {
        const f = async () => {
            this.rendersEnabled = false
            try {
                return await action()
            } finally {
                this.rendersEnabled = true
            }
        }
        return await this.withCommitsAndSavesDisabled(f)
    }
}

export function createSetOperationsQueue(
    viewerManager: ViewerManager,
    generalSuperDal: GSDAL,
    runtimeConfig: RuntimeConfig,
    layoutCircuitBreaker: LayoutCB,
    logger: CoreLogger,
    events: Events,
    isDebugMode: boolean,
    experimentInstance: Experiment,
    viewerExtension: InnerViewerExtensionAPI
): AdapterSOQ {
    return new Soq(
        viewerManager,
        generalSuperDal,
        runtimeConfig,
        layoutCircuitBreaker,
        logger,
        events,
        isDebugMode,
        experimentInstance,
        viewerExtension
    )
}
