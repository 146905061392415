import _ from 'lodash'
import {contextAdapter} from '../utils/contextAdapter'
import externalComponentsMetaData from './externalComponentsMetaData'
import metaDataMap from './metaDataMap'
import META_DATA_TYPES from './metaDataTypes'
import * as documentManagerUtils from '@wix/document-manager-utils'

const {ReportableError} = documentManagerUtils

const META_DATA_KEYS = _.invert(META_DATA_TYPES)

const isMetaDataKeyLegal = (key: string) => META_DATA_KEYS[key]

function getIllegalKeys(metaData) {
    // @ts-expect-error
    return _(metaData).keys().reject(isMetaDataKeyLegal).value()
}

function informIllegalMetadataTypes(illegalKeys: string[], componentType: string, metaDataToRegister) {
    const err = new ReportableError({
        errorType: 'illegalMetaDataKey',
        message: `Unable to use illegal meta data key(s) "${illegalKeys.join(', ')}" for component "${componentType}"`
    })
    contextAdapter.utils.fedopsLogger.captureError(err, {
        tags: {
            metaDataRegistration: true
        },
        extras: {
            illegalKeys,
            metaDataToRegister
        }
    })
}

function informUnableToRegisterAlreadyRegisteredComponent(componentType: string) {
    const err = new Error(
        `Unable to register a component metaData for type "${componentType}" that is already registered`
    )
    contextAdapter.utils.fedopsLogger.captureError(err, {
        tags: {
            metaDataRegistration: true,
            failedMetaDataRegistration: true
        },
        extras: {
            componentType
        }
    })
}

const metaDataRegistrar = {
    registerMetaData: (componentType: string, metaData) => {
        if (metaDataMap[componentType]) {
            informUnableToRegisterAlreadyRegisteredComponent(componentType)
            return
        }
        metaDataMap[componentType] = metaData
    },
    registerExternalMetaData: (componentType: string, metaData) => {
        const illegalKeys = getIllegalKeys(metaData)

        if (illegalKeys.length) {
            metaData = _.omit(metaData, illegalKeys)
            informIllegalMetadataTypes(illegalKeys, componentType, metaData)
        }

        const metaDataToRegister = externalComponentsMetaData.getMetaDataToRegister(metaData)

        return metaDataRegistrar.registerMetaData(componentType, metaDataToRegister)
    },
    unregisterMetaData: (componentType: string) => {
        delete metaDataMap[componentType]
    }
}

export default metaDataRegistrar
