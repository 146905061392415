import type {PS} from '@wix/document-services-types'

export default {
    getUserConsent: (ps: PS) => ps.siteAPI.getExternalComponentConsent(),
    setUserConsent: (ps: PS, consent: boolean) => ps.siteAPI.setExternalComponentConsent(consent),
    showLoader: (ps: PS, show: boolean) => {
        const renderFlagPointer = ps.pointers.general.getRenderFlag('isExternalComponentsLoading')
        ps.dal.set(renderFlagPointer, show)
    }
}
