import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import constants from '../constants/constants'
import dataModel from '../dataModel/dataModel'
import responsiveLayoutUtils from './utils'
import variants from '../variants/variants'
import dsUtils from '../utils/utils'

const {DATA_TYPES, BREAKPOINTS_TYPES} = constants

const {createBreakpointsData} = responsiveLayoutUtils

const getBreakpointsFromVariantsMap = (ps: PS, compPointer: Pointer) => {
    const breakpointDataPointer = _.head(variants.getByComponentAndType(ps, compPointer, BREAKPOINTS_TYPES.DATA))
    const breakpointData = breakpointDataPointer && ps.dal.get(breakpointDataPointer)
    if (breakpointData) {
        const pageId = ps.pointers.full.components.getPageOfComponent(compPointer).id
        const pointers = _.map(breakpointData.values, bpRange =>
            ps.pointers.data.getItem(DATA_TYPES.variants, dsUtils.stripHashIfExists(bpRange), pageId)
        )
        const breakpointRanges = _.map(pointers, ps.dal.get)
        return {
            pointers,
            values: breakpointRanges
        }
    }
}

const getComponentBreakpoints = (ps: PS, compPointer: Pointer) => getBreakpointsFromVariantsMap(ps, compPointer)

const updateComponentBreakpoints = (ps: PS, compPointer: Pointer, bpRangesArray) => {
    if (!ps || !compPointer || !bpRangesArray || !bpRangesArray.length) {
        throw new Error('invalid args')
    }

    const pageId = ps.pointers.full.components.getPageOfComponent(compPointer).id
    const breakpointDataPointer = _.head(variants.getByComponentAndType(ps, compPointer, BREAKPOINTS_TYPES.DATA))

    const currentBreakpointData = breakpointDataPointer && ps.dal.get(breakpointDataPointer)

    if (currentBreakpointData && bpRangesArray.length < currentBreakpointData.values.length) {
        throw new Error('cant remove breakpoints with update API, please use breakpoints.remove')
    }

    const bpDataId = currentBreakpointData?.id

    const compBreakpointId = createBreakpointsData(ps, bpDataId, compPointer, bpRangesArray, pageId)

    if (!currentBreakpointData && compBreakpointId) {
        dataModel.linkComponentToItem(ps, compPointer, compBreakpointId, 'breakpointVariantsQuery')
    }
}

const removeBreakpointsFromImageXData = (ps: PS, breakpointRangePointer: Pointer) => {
    const pageId = ps.pointers.data.getPageIdOfData(breakpointRangePointer)
    const isImageX = imageData => imageData.type === 'ImageX'
    const hasScopedData = imageData => imageData.scopedData && imageData.scopedData.length > 0
    const imageXWithScopedDataPointers = ps.pointers.data.getDataItemsWithPredicate(
        imageData => isImageX(imageData) && hasScopedData(imageData),
        pageId
    )
    _.forEach(imageXWithScopedDataPointers, dataPointer => {
        const currentData = ps.dal.get(dataPointer)
        const dataToRemove = _.find(currentData.scopedData, x => x.breakpoint === `#${breakpointRangePointer.id}`)
        const updatedImageXData = _.assign({}, currentData, {
            scopedData: _.without(currentData.scopedData, dataToRemove)
        })
        ps.dal.set(dataPointer, updatedImageXData)
    })
}

const removeBreakpointsRange = (ps: PS, compPointer: Pointer, breakpointRangePointer: Pointer) => {
    const breakpointDataPointer = _.head(variants.getByComponentAndType(ps, compPointer, BREAKPOINTS_TYPES.DATA))
    const currentBreakpointData = breakpointDataPointer && ps.dal.get(breakpointDataPointer)
    if (currentBreakpointData) {
        const updatedBreakpointsData = _.assign({}, currentBreakpointData, {
            values: _.reject(
                currentBreakpointData.values,
                bp => dsUtils.stripHashIfExists(bp) === breakpointRangePointer.id
            )
        })
        removeBreakpointsFromImageXData(ps, breakpointRangePointer)
        variants.remove(ps, breakpointRangePointer)
        ps.dal.set(breakpointDataPointer, updatedBreakpointsData)
    }
}

export default {
    get: getComponentBreakpoints,
    update: updateComponentBreakpoints,
    remove: removeBreakpointsRange,
    wasBreakpointsMigratedToVariants: () => true
}
