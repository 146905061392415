import _ from 'lodash'
import type {DalPointers} from '../types'
import type {Pointer} from '@wix/document-services-types'
import {refCompDelimiter} from './inflationUtils'

const hasActiveVariants = ({dal, pointers}: DalPointers, componentId: string) => {
    const activeVariantPointer = pointers.activeVariants.getActiveVariant(componentId)
    return !_.isEmpty(dal.get(activeVariantPointer))
}

const isLocalVariant = (variant: string | Pointer, separateRemoteVariants?: boolean) => {
    if (!separateRemoteVariants) {
        return true
    }
    const id = _.isString(variant) ? variant : variant.id
    return !id.includes(refCompDelimiter)
}
export {hasActiveVariants, isLocalVariant}
