import type {Pointer, PS} from '@wix/document-services-types'
import consts, {ResizeSides} from '../../constants/constants'
import dsUtils from '../../utils/utils'

const {RESIZE_SIDES} = consts

const HeightLayout = Object.freeze({
    MANUAL: 'manual',
    AUTO: 'auto'
})

const resizableSides = (ps: PS, compPointer: Pointer): ResizeSides[] => {
    const sides: ResizeSides[] = [RESIZE_SIDES.LEFT, RESIZE_SIDES.RIGHT]
    const compStructure = ps.dal.get(compPointer)
    const page = ps.pointers.components.getPageOfComponent(compPointer)
    const pageId = page?.id
    const compPropsPointer = ps.pointers.data.getPropertyItem(
        dsUtils.stripHashIfExists(compStructure.propertyQuery),
        pageId
    )
    const compProps = ps.dal.get(compPropsPointer)
    if (compProps.heightLayout !== HeightLayout.AUTO) {
        sides.push(RESIZE_SIDES.TOP, RESIZE_SIDES.BOTTOM)
    }
    return sides
}

export default {
    defaultMobileProperties: {
        columnLayout: 'manual'
    },
    mobileConversionConfig: {
        fixedSize(ps: PS, component) {
            return {height: component.layout.height}
        }
    },
    resizableSides
}
