import _ from 'lodash'
import {ReportableError} from '@wix/document-manager-utils'
export const loadRemoteDataForApp = async (ps, csmEntry, origin = '') => {
    /*we can load only the first widget since the service does not return just the current page*/
    const studioWidget = _.find(csmEntry?.components ?? [], {type: 'STUDIO_WIDGET'})
    try {
        if (studioWidget) {
            return ps.extensionAPI.remoteStructureMetaData.loadMetaData(
                csmEntry.appDefinitionId,
                studioWidget.componentId,
                false,
                csmEntry
            )
        }
    } catch (e) {
        ps.logger.captureError(
            new ReportableError({
                tags: {
                    addApps: origin === 'addApps',
                    appDefinitionId: csmEntry.appDefinitionId
                },
                errorType: 'LoadRemoteMetaDataFailed',
                message: `load meta data failed for ${csmEntry.appDefinitionId} and widget ${
                    studioWidget ? studioWidget.componentId : ''
                }`
            })
        )
    }
}
