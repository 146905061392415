//HTMLSRCR-1183 -- see todo.md
import type {Callback1, CompRef, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import hooks from '../hooks/hooks'
import type {HookFunc} from '../hooks/hooksHandlerFactory'
import page from '../page/page'
import pageData from '../page/pageData'
import platform from '../platform/platform'
import wixCode from '../wixCode/wixCode'
import routersGetters from './routersGetters'
import constants from './utils/constants'
import routersBackendRequests from './utils/routersBackendRequests'
import documentModeInfo from '../documentMode/documentModeInfo'
import clientSpecMapService from '../tpa/services/clientSpecMapService'
import type {DistributorExtensionAPI, LivePreviewRoutersSharedStateMessage} from '@wix/document-manager-extensions'
const {ROUTER_TYPE} = constants

function getPageRoles(routerPages, pageId: string) {
    const pageRoles = _.invertBy(routerPages)
    return pageRoles[pageId]
}

/*****************************************  Public Functions  ****************************************************/

function getRouterPointer(ps: PS) {
    return ps.extensionAPI.routers.getRouterPointer()
}

/*newRouterPointer - received automatically from getRouterPointer*/
function addRouter(ps: PS, routerRef: Pointer, newRouter) {
    return ps.extensionAPI.routers.addRouter(routerRef, newRouter)
}

function removeRouter(ps: PS, routerRef) {
    return ps.extensionAPI.routers.removeRouter(routerRef, documentModeInfo.getViewMode(ps))
}

function updateRouter(ps: PS, routerPtr: Pointer, updateData) {
    ps.extensionAPI.routers.updateRouter(routerPtr, updateData)
}

function getRouterSiteMap(ps: PS, routerId: string | number, callback: Function) {
    const routerDefinition = ps.dal.get(ps.pointers.routers.getRoutersConfigMapPointer())[routerId]
    const routerBackEndParamObj = routersBackendRequests.makeParamObjFromPs(ps, routerDefinition)
    // eslint-disable-next-line promise/prefer-await-to-then
    wixCode.fileSystem.flush(ps, {origin: wixCode.fileSystem.FLUSH_ORIGINS.ROUTERS_PAGE}).then(function () {
        routersBackendRequests.getInnerRoutesSiteMap(
            routerBackEndParamObj,
            function (siteMap) {
                callback(siteMap)
            },
            function () {
                callback()
            }
        )
    })
}

function getPageFromInnerRoute(ps: PS, routerId: string | number, innerRoute: string, callback: Function) {
    getRouterSiteMap(ps, routerId, siteMap => {
        if (siteMap) {
            const currRoute = _.find(siteMap, {url: innerRoute})
            callback(currRoute ? currRoute.pageName : null)
        } else {
            callback()
        }
    })
}

function getRouterInnerRoutes(ps: PS, routerId: string | number, pageId: string, callback: Callback1<any>) {
    getRouterSiteMap(ps, routerId, siteMap => callback(siteMap ? _.filter(siteMap, {pageName: pageId}) : undefined))
}

function getRouterInnerRoutesCount(ps: PS, routerId: string | number, pageId: string, callback: Function) {
    const routerDefinition = ps.dal.get(ps.pointers.routers.getRoutersConfigMapPointer())[routerId]
    const routerBackEndParamObj = routersBackendRequests.makeParamObjFromPs(ps, routerDefinition)
    // eslint-disable-next-line promise/prefer-await-to-then
    wixCode.fileSystem.flush(ps, {origin: wixCode.fileSystem.FLUSH_ORIGINS.ROUTERS_ROUTES_COUNT}).then(function () {
        routersBackendRequests.getInnerRoutesSiteMapCount(
            routerBackEndParamObj,
            function (siteMapCount) {
                callback(siteMapCount)
            },
            function () {
                callback()
            }
        )
    })
}

function getTpaInnerRoutes(ps: PS, appDefinitionId: string, subPage: string, callback: Callback1<any>) {
    routersBackendRequests.getTpaRoutesFromSiteStructure(
        ps,
        appDefinitionId,
        subPage,
        function (response) {
            const results = _.get(response, 'results', [])
            callback(results)
        },
        function () {
            callback([])
        }
    )
}

function getCurrentInnerRoute(ps: PS) {
    const currentPageId = ps.siteAPI.getPrimaryPageId()
    const routerData = routersGetters.getRouterDataForPageIfExist(ps, currentPageId)
    if (!routerData) {
        return {isDynamic: false}
    }
    const pageInfo = ps.siteAPI.getRootNavigationInfo()
    if (pageInfo.routerDefinition) {
        if (!pageInfo.pageAdditionalData) {
            return {isDynamic: true, routerId: routerData.routerId}
        }
        const pageSuffix = pageInfo.pageAdditionalData.split('/')
        const innerRoute = _.drop(pageSuffix, 1).join('/')
        return {isDynamic: true, innerRoute, routerId: routerData.routerId}
    }
    return {isDynamic: false}
}

/****************************************** pages Functions ****************************/

function getPageToAddPointer(ps: PS) {
    return page.getPageIdToAdd(ps)
}

function addNewRouterPage(
    ps: PS,
    newItemPageRef: CompRef,
    routerPtr: Pointer,
    pageTitle: string,
    pageRoles,
    serializedPage?
) {
    page.add(ps, newItemPageRef, pageTitle, serializedPage)
    connectPageToRouter(ps, routerPtr, newItemPageRef, pageRoles)
    return newItemPageRef
}
function connectPageToRouter(
    ps: PS,
    routerPtr: Pointer,
    pagePtr: Pointer,
    pageRoles,
    connectRouterConfig: {innerRoute?: string} = {}
) {
    return ps.extensionAPI.routers.pages.connect(
        routerPtr,
        pagePtr.id,
        pageRoles,
        connectRouterConfig,
        documentModeInfo.getViewMode(ps)
    )
}

function disconnectPageFromRouter(ps: PS, routerPtr: Pointer, pagePtr: Pointer) {
    return ps.extensionAPI.routers.pages.disconnect(routerPtr, pagePtr.id, documentModeInfo.getViewMode(ps))
}

function removePageFromRouter(ps: PS, routerPtr: Pointer, pageId: string) {
    return ps.extensionAPI.routers.pages.remove(routerPtr, pageId)
}

function movePageToNewRouter(ps: PS, pagePtr: Pointer, originRouterPtr: Pointer, desRouterPtr: Pointer) {
    const pageRouterPtr = routersGetters.get.byPage(ps, pagePtr)

    if (!pageRouterPtr) {
        throw new Error('cannot move a static page')
    }

    if (!ps.pointers.isSamePointer(originRouterPtr, pageRouterPtr)) {
        throw new Error('page is not related to the origin router')
    }

    const originRouterData = ps.dal.get(originRouterPtr)
    const desRouterData = ps.dal.get(desRouterPtr)

    if (originRouterData && desRouterData && originRouterData.appDefinitionId !== desRouterData.appDefinitionId) {
        throw new Error('cannot move a page that not related to app')
    }

    if (ps.pointers.isSamePointer(originRouterPtr, desRouterPtr)) {
        return
    }

    const pageRoles = getPageRoles(originRouterData.pages, pagePtr.id)
    disconnectPageFromRouter(ps, originRouterPtr, pagePtr)
    connectPageToRouter(ps, desRouterPtr, pagePtr, pageRoles)
}

function canBeDynamic(ps: PS, currentPageData, homePageId: string, pageRef: Pointer) {
    return (
        currentPageData.type === 'Page' && //it is a page (and not AppPage for example)
        !(currentPageData.tpaApplicationId > 0) && //page is not a TPA page
        !routersGetters.get.byPage(ps, pageRef) && //page is not a dynamic page
        homePageId !== currentPageData.id //page is not the home page
    )
}

function listConnectablePages(ps: PS) {
    const pageList = pageData.getPagesDataItems(ps)
    const homePageId = page.homePage.get(ps)

    return _.reduce(
        pageList,
        function (res, currentPageData) {
            const pageRef = page.getReference(ps, currentPageData.id)
            if (canBeDynamic(ps, currentPageData, homePageId, pageRef)) {
                res.push({pageRef, pageData: currentPageData})
            }
            return res
        },
        []
    )
}

function isConnectablePage(ps: PS, pageId: string) {
    const homePageId = page.homePage.get(ps)
    const thePageData = pageData.getPageData(ps, pageId)
    if (!thePageData) {
        return false
    }
    const pageRef = page.getReference(ps, pageId)
    return canBeDynamic(ps, thePageData, homePageId, pageRef)
}

function getDynamicPagesList(ps: PS) {
    return _.map(
        routersGetters.get.all(ps),
        // @ts-expect-error
        ({appDefinitionId, pages, prefix, group, hide = false} = {}) => {
            const {appDefinitionName} = platform.getAppDataByAppDefId(ps, appDefinitionId) || {}
            return {
                appDefinitionId,
                appDefinitionName,
                prefix,
                group,
                pages: _(pages)
                    .values()
                    .uniq()
                    .map(pageId => pageData.getPageData(ps, pageId))
                    .value(),
                hide
            }
        }
    )
}

function buildIsValidPrefixReturnVal(isValid: boolean, errorCode: number | string, metadata?) {
    return {
        valid: isValid,
        message: errorCode,
        errorCode,
        metadata
    }
}

function getRouterAppDefinitionId(ps: PS, prefix: string) {
    const routerPointer = routersGetters.get.byPrefix(ps, prefix)
    if (routerPointer) {
        const routerData = ps.dal.get(routerPointer)
        return routerData.appDefinitionId
    }
}

function isValidPrefixByAppDefId(ps: PS, appDefinitionId: string, prefix?: string) {
    if (!prefix) {
        return buildIsValidPrefixReturnVal(false, constants.InvalidPrefixReason.PREFIX_CAN_NOT_BE_EMPTY)
    }

    const routerAppDefinitionId = getRouterAppDefinitionId(ps, prefix)
    if (routerAppDefinitionId) {
        //existing prefix
        if (routerAppDefinitionId === appDefinitionId) {
            return buildIsValidPrefixReturnVal(true, '')
        }
        const routerAppDefinitionName = _.get(
            platform.getAppDataByAppDefId(ps, routerAppDefinitionId),
            'appDefinitionName'
        )
        return buildIsValidPrefixReturnVal(
            false,
            constants.InvalidPrefixReason.PREFIX_IS_IN_USE_BY_ANOTHER_APPLICATION,
            {
                appDefinitionName: routerAppDefinitionName
            }
        )
    }

    if (pageData.isPageUriSeoTooLong(prefix)) {
        return buildIsValidPrefixReturnVal(false, constants.InvalidPrefixReason.PREFIX_IS_TOO_LONG)
    }
    if (pageData.isDuplicatePageUriSeo(ps, null, prefix)) {
        return buildIsValidPrefixReturnVal(false, constants.InvalidPrefixReason.PREFIX_IS_DUPLICATE_OF_URI_SEO, {
            pageTitle: pageData.getDuplicatePageTitle(ps, null, prefix)
        })
    }
    if (pageData.hasIllegalChars(prefix)) {
        return buildIsValidPrefixReturnVal(false, constants.InvalidPrefixReason.PREFIX_CONTAINS_INVALID_CHARACTERS)
    }

    const forbiddenWordsPointer = ps.pointers.general.getForbiddenPageUriSEOs()
    const forbiddenWordsArr = ps.dal.get(forbiddenWordsPointer) || {}
    if (forbiddenWordsArr[prefix]) {
        return buildIsValidPrefixReturnVal(false, constants.InvalidPrefixReason.PREFIX_IS_FORBIDDEN_WORD)
    }
    return buildIsValidPrefixReturnVal(true, '')
}

function isValidPrefix(ps: PS, applicationId: number | string, prefix?: string) {
    const appDefinitionId = clientSpecMapService.getAppDefinitionIdFromApplicationId(ps, applicationId, {
        source: 'isValidPrefix'
    })

    return isValidPrefixByAppDefId(ps, appDefinitionId, prefix)
}

function reloadRouterData(ps: PS) {
    const currentNavigationInfo = ps.siteAPI.getRootNavigationInfo()
    if (!currentNavigationInfo?.routerDefinition) {
        return
    }

    const navInfo = {
        innerRoute: currentNavigationInfo.innerRoute,
        routerDefinition: currentNavigationInfo.routerDefinition,
        pageAdditionalData: currentNavigationInfo.pageAdditionalData
    }
    ps.siteAPI.navigateToPage(navInfo)
    ps.siteAPI.registerNavigationComplete(() => {
        hooks.executeHook(hooks.HOOKS.ROUTER.DATA_RELOADED, null, [ps])
    })
}

function subscribeToConcurrentRoutersInvalidation(ps: PS, listener: HookFunc) {
    hooks.unregisterHooks([hooks.HOOKS.ROUTER.CONCURRENT_ROUTER_INVALIDATION])
    hooks.registerHook(hooks.HOOKS.ROUTER.CONCURRENT_ROUTER_INVALIDATION, listener)
}

function notifyConcurrentRoutersInvalidation(ps: PS, currentRouteInfo, newSelectedRoute) {
    const dataToSet = {currentRouteInfo, selectedRoute: newSelectedRoute}
    const {distributor} = ps.extensionAPI as DistributorExtensionAPI
    distributor.distributeMessage<LivePreviewRoutersSharedStateMessage>('routersConcurrentInvalidationState', dataToSet)
}

const concurrentRoutersInvalidationListener = (ps: PS, originEditorCurrentRouteInfo, selectedRoute) => {
    try {
        const routerIdChanged = originEditorCurrentRouteInfo.routerId
        const currentRouteInfo = getCurrentInnerRoute(ps)
        const routerId = _.get(currentRouteInfo, ['routerId'])
        if (routerId === routerIdChanged) {
            hooks.executeHook(hooks.HOOKS.ROUTER.CONCURRENT_ROUTER_INVALIDATION, undefined, [
                _.isEqual(currentRouteInfo, originEditorCurrentRouteInfo) ? selectedRoute : null
            ])
        }
    } catch (e) {
        console.error(e)
        ps.logger.captureError(e as Error, {tags: {routerInvalidationError: true}})
    }
}

const initialize = (ps: PS) => {
    hooks.registerHook(
        hooks.HOOKS.ROUTER.ON_DISCONNECT,
        (_ps, routerPointer: Pointer, pageId: string) => {
            if (ps.siteAPI.getPrimaryPageId() === pageId) {
                page.navigateTo(ps, pageId)
            }
        },
        ROUTER_TYPE
    )
    const {distributor} = ps.extensionAPI as DistributorExtensionAPI
    distributor.subscribeToMessage<LivePreviewRoutersSharedStateMessage>(
        'routersConcurrentInvalidationState',
        ({data: {currentRouteInfo: originEditorCurrentRouteInfo, selectedRoute}}) =>
            concurrentRoutersInvalidationListener(ps, originEditorCurrentRouteInfo, selectedRoute)
    )
}

export default {
    initialize,
    add: addRouter,
    remove: removeRouter,
    get: {
        all: routersGetters.get.all,
        byApp: routersGetters.get.byApp,
        byRef: routersGetters.get.byRef,
        byId: routersGetters.get.byId
    },
    getRouterRef: {
        byPrefix: routersGetters.get.byPrefix,
        byPage: routersGetters.get.byPage
    },
    update: updateRouter,
    getRouterDataForPageIfExist: routersGetters.getRouterDataForPageIfExist,
    getPageFromInnerRoute,
    getRouterInnerRoutes,
    getRouterInnerRoutesCount,
    getRouterSiteMap,
    getTpaInnerRoutes,
    getCurrentInnerRoute,
    isValidPrefix,
    isValidPrefixByAppDefId,
    pages: {
        add: addNewRouterPage,
        connect: connectPageToRouter,
        disconnect: disconnectPageFromRouter,
        removePageFromRouter,
        listConnectablePages,
        isConnectablePage,
        getDynamicPagesList,
        move: movePageToNewRouter
    },
    getRouterPointer,
    getPageToAddPointer,
    reloadRouterData,
    subscribeToConcurrentRoutersInvalidation,
    notifyConcurrentRoutersInvalidation
}
