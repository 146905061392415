import type {PS} from '@wix/document-services-types'
import type {SaveStateApi} from '@wix/document-manager-extensions'

const setSaveProgress = (ps: PS, isSaving: boolean): void => {
    ;(ps.extensionAPI as SaveStateApi).saveState.setSaveProgress(isSaving)
}

const isSaveInProgress = (ps: PS) => (ps.extensionAPI as SaveStateApi).saveState.isSaveInProgress()
const setSaveAllowed = (ps: PS, isAllowed: boolean): void =>
    (ps.extensionAPI as SaveStateApi).saveState.setSaveAllowed(isAllowed)
const isEnabled = (ps: PS) => (ps.extensionAPI as SaveStateApi).saveState.isSaveAllowed()
const canSave = (ps: PS): boolean => (ps.extensionAPI as SaveStateApi).saveState.canSave()
const isSaveAllowed = (ps: PS): boolean => (ps.extensionAPI as SaveStateApi).saveState.isSaveAllowed()
const setPublishProgress = (ps: PS, isPublishing: boolean): void =>
    (ps.extensionAPI as SaveStateApi).saveState.setSaveProgress(isPublishing)
const isPublishInProgress = (ps: PS) => (ps.extensionAPI as SaveStateApi).saveState.isSaveInProgress()

export default {
    isSaveAllowed,
    setSaveProgress,
    isSaveInProgress,
    canSave,
    isEnabled,
    setSaveAllowed,
    setPublishProgress,
    isPublishInProgress
}
