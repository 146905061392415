import _ from 'lodash'

const visibilityPlugins: any[] = []

function registerVisibilityPlugin(plugin) {
    visibilityPlugins.push(plugin)
}

function isComponentVisible(argsObj) {
    return _.every(visibilityPlugins, plugin => plugin(argsObj))
}

export default {
    isComponentVisible,
    registerPlugin: registerVisibilityPlugin
}
