import type {Callback3, Layout, Pointer, PS, MeshData} from '@wix/document-services-types'
import _ from 'lodash'
import component from '../../component/component'
import layoutHooksUtils from './utils/layoutHooksUtils'
import componentLayout from '../../structure/utils/componentLayout'

function applyLayoutToWidgetRootOnLayoutUpdate(
    ps: PS,
    compPointer: Pointer,
    newLayout: Layout,
    updateCompLayoutCallback: (ps: PS, widgetRoot: Pointer, rootLayoutUpdate: Layout) => void
) {
    const previousLayout = componentLayout.getComponentLayout(ps, compPointer)
    const shouldUpdateChildLayout =
        layoutHooksUtils.isCompResizing(previousLayout, newLayout) ||
        layoutHooksUtils.isCompRescaling(previousLayout, newLayout)
    if (_.isFunction(updateCompLayoutCallback) && shouldUpdateChildLayout) {
        const [widgetRoot] = component.getChildren(ps, compPointer)
        const dx = layoutHooksUtils.calculateDelta(newLayout.x, previousLayout.x)
        const dy = layoutHooksUtils.calculateDelta(newLayout.y, previousLayout.y)
        const rootLayoutUpdate = _.pickBy(
            {
                x: dx,
                y: dy,
                width: newLayout.width,
                height: newLayout.height,
                scale: newLayout.scale
            },
            _.isNumber
        )
        // @ts-expect-error
        updateCompLayoutCallback(ps, widgetRoot, rootLayoutUpdate)
    }
}

function applyLayoutToWidgetRootAfterLayoutUpdate(
    ps: PS,
    compPointer: Pointer,
    newLayout: Layout,
    updateCompLayoutCallback: Callback3<PS, Pointer, MeshData>
) {
    const [widgetRoot] = component.getChildren(ps, compPointer)
    updateCompLayoutCallback(ps, widgetRoot, {
        x: 0,
        y: 0,
        width: newLayout.width,
        height: newLayout.height
    })
}

export default {
    applyLayoutToWidgetRootOnLayoutUpdate,
    applyLayoutToWidgetRootAfterLayoutUpdate
}
