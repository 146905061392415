/* eslint-disable guard-for-in */
type KeyValPredicate = (v: any, k: string) => boolean

// Custom pickBy is significantly faster than _.pickBy for large objects
export function map_pickBy(map: Map<string, any>, predicate: KeyValPredicate): Map<string, any> {
    const result = new Map()
    for (const [key, value] of map) {
        if (typeof value !== 'undefined' && predicate(value, key)) {
            result.set(key, value)
        }
    }
    return result
}

export function pickBy(object: any, predicate: KeyValPredicate): any {
    const result = {}
    for (const key in object) {
        const value = object[key]
        if (typeof value !== 'undefined' && predicate(value, key)) {
            result[key] = value
        }
    }
    return result
}

export function map_findBy(map: Map<string, any> | undefined, predicate: KeyValPredicate) {
    if (!map) {
        return undefined
    }

    for (const [key, value] of map) {
        if (typeof value !== 'undefined' && predicate(value, key)) {
            return {key, value}
        }
    }

    return undefined
}

export function findBy(object: any, predicate: KeyValPredicate) {
    for (const key in object) {
        const value = object[key]
        if (typeof value !== 'undefined' && predicate(value, key)) {
            return {key, value}
        }
    }
    return undefined
}
