const SIMPLE_ENTRANCE_ANIMATIONS = ['entrance', 'animation', 'scrub']
const SPECIAL_ENTRANCE_ANIMATIONS = ['mask', '3d']
const ALL_ENTRANCE_ANIMATIONS = SIMPLE_ENTRANCE_ANIMATIONS.concat(SPECIAL_ENTRANCE_ANIMATIONS)

const TEXT_ANIMATIONS = ['text']
const EXIT_ANIMATIONS = ['exit']
const BACKGROUND_ANIMATIONS = ['animation', 'background']
const PAGE_TRANSITIONS = ['transition', 'pageTransition']
const NONE: string[] = []

const schema = {
    // Default
    AllComponents: ALL_ENTRANCE_ANIMATIONS.concat(EXIT_ANIMATIONS),
    // Special
    PopupContainer: ALL_ENTRANCE_ANIMATIONS.concat(EXIT_ANIMATIONS),
    WRichText: ALL_ENTRANCE_ANIMATIONS.concat(TEXT_ANIMATIONS).concat(EXIT_ANIMATIONS),
    WSiteStructure: PAGE_TRANSITIONS,
    Page: PAGE_TRANSITIONS,
    AppPage: PAGE_TRANSITIONS,
    Anchor: NONE,
    FlashComponent: NONE,
    PagesContainer: NONE,
    Header: NONE,
    Footer: ALL_ENTRANCE_ANIMATIONS,
    TPAWidget: NONE,
    TPASection: NONE,
    TPAGluedWidget: NONE,
    TPAMultiSection: NONE,
    AppPart: NONE,
    AppPart2: NONE,
    // TPA Galleries
    Masonry: NONE,
    Accordion: NONE,
    Impress: NONE,
    Freestyle: NONE,
    Collage: NONE,
    Honeycomb: NONE,
    StripShowcase: NONE,
    StripSlideshow: NONE,
    Thumbnails: NONE,
    TPA3DGallery: NONE,
    TPA3DCarousel: NONE,
    // iFrame Components
    FlickrBadgeWidget: NONE,
    GoogleMap: NONE,
    HtmlComponent: NONE,
    PinItPinWidget: NONE,
    PinterestPinIt: NONE,
    FacebookLikeBox: NONE,
    SkypeCallButton: NONE,
    SoundCloudWidget: NONE,
    SpotifyFollow: NONE,
    SpotifyPlayer: NONE,
    Video: NONE,
    VKShareButton: NONE,
    WTwitterTweet: NONE,
    WTwitterFollow: NONE,
    YouTubeSubscribeButton: NONE,
    MediaControls: NONE,
    MediaOverlayControls: NONE,
    StripContainer: BACKGROUND_ANIMATIONS,
    StripContainerSlideShowSlide: BACKGROUND_ANIMATIONS,
    StateBoxState: BACKGROUND_ANIMATIONS,
    StateBoxFormState: BACKGROUND_ANIMATIONS,
    StateStrip: BACKGROUND_ANIMATIONS,
    StateStripState: BACKGROUND_ANIMATIONS,
    Column: ALL_ENTRANCE_ANIMATIONS.concat(BACKGROUND_ANIMATIONS),
    StripColumnsContainer: ALL_ENTRANCE_ANIMATIONS.concat(BACKGROUND_ANIMATIONS),
    ClassicSection: ALL_ENTRANCE_ANIMATIONS.concat(BACKGROUND_ANIMATIONS),
    BoxSlideShowSlide: BACKGROUND_ANIMATIONS,
    // Responsive Only
    Section: ALL_ENTRANCE_ANIMATIONS.concat(BACKGROUND_ANIMATIONS)
}

Object.freeze(schema)

function getSchema() {
    return schema
}

export default {
    getSchema
}
