import type {MobileHints, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import dataModel from '../../dataModel/dataModel'
import mobileHints from './mobileHints'

const getComponentAuthor = (ps: PS, comp, pageId: string) => {
    const mobileHintsItemId = _.get(comp, 'mobileHintsQuery', '')
    const mobileHintsItem = dataModel.getMobileHintsItemById(ps, mobileHintsItemId, pageId)
    return _.get(mobileHintsItem, 'author')
}

const updateModifiedByUser = (
    ps: PS,
    pageId: string,
    compPointer: Pointer,
    modifiedByUser: boolean,
    shouldMarkParent = true
) => {
    const property = {modifiedByUser}
    if (shouldMarkParent && !ps.pointers.full.components.isPage(compPointer)) {
        /**
         * In case when it updates component it also should update
         * it's parent to avoid recalculations
         */
        const parent = ps.pointers.full.components.getParent(compPointer)
        updateModifiedByUser(ps, pageId, parent, modifiedByUser, false)
    }
    mobileHints.updateProperty(ps, property, compPointer, {}, pageId)
}

const markComponentAsTouched = (ps: PS, pageId: string, compPointer: Pointer, shouldUpdateParent: boolean) => {
    const modifiedByUser = true
    updateModifiedByUser(ps, pageId, compPointer, modifiedByUser, shouldUpdateParent)
}

const unmarkComponentAsTouched = (ps: PS, pageId: string, compPointer: Pointer, shouldUpdateParent?: boolean) => {
    const modifiedByUser = false
    updateModifiedByUser(ps, pageId, compPointer, modifiedByUser, shouldUpdateParent)
}

const getModifiedByUser = (ps: PS, comp, pageId: string): boolean => {
    const mobileHintsItemId = _.get(comp, 'mobileHintsQuery', '')
    const mobileHintsItem = dataModel.getMobileHintsItemById(ps, mobileHintsItemId, pageId)
    return isModifiedByUser(mobileHintsItem)
}

const isModifiedByUser = (mobileHintsItem: MobileHints): boolean => _.get(mobileHintsItem, 'modifiedByUser', false)

export default {
    getComponentAuthor,
    getModifiedByUser,
    markComponentAsTouched,
    unmarkComponentAsTouched,
    isModifiedByUser
}
