export function reduceQueryToObject(str: string, separator: string = '&', equalizer: string = '='): any {
    return (str || '')
        .replace(/^\?/, '')
        .split(separator)
        .reduce((o, pairString) => {
            const pair = pairString.split(equalizer)
            o[pair[0]] = pair[1]
            return o
        }, {})
}
