'use strict'

const _ = require('lodash')
const dataUtils = require('./dataUtils')

const oldDirections = ['top left', 'top right', 'bottom left', 'bottom right']
const oldFlyInDirectionsMap = {
    top: 0,
    'top-right': 45,
    right: 90,
    'bottom-right': 135,
    bottom: 180,
    'bottom-left': 225,
    left: 270,
    'top-left': 315
}

const createItemWithValues = (id, type, values = []) => ({
    id,
    type,
    values: _.map(values, v => `#${dataUtils.stripHashIfExists(v)}`)
})

//any change to this function needs to be done in effectsUtils in component-migration dir as well
const createNamedEffectItem = (id, behavior) => {
    const {name, params, viewMode} = behavior
    delete params.doubleDelayFixed
    if (params.direction && _.includes(oldDirections, params.direction)) {
        params.direction = params.direction.split(' ').join('-')
    }

    const namedEffect = {
        id
    }
    switch (name) {
        case 'FadeIn':
            _.assign(namedEffect, {
                type: 'FadeIn'
            })
            break
        case 'FloatIn':
            _.assign(namedEffect, {
                direction: 'right',
                ...params,
                type: 'FloatIn'
            })
            break
        case 'ExpandIn':
            _.assign(namedEffect, {
                power: 'hard',
                ...params,
                direction: 'center',
                type: 'ExpandIn'
            })
            break
        case 'SpinIn':
            _.assign(namedEffect, {
                power: 'hard',
                ...params,
                type: 'SpinIn',
                direction: params.direction === 'cw' ? 'clockwise' : 'counter-clockwise',
                spins: params.cycles ?? 2
            })
            delete namedEffect.cycles
            break
        case 'FlyIn':
            _.assign(namedEffect, {
                ...params,
                type: 'GlitchIn',
                power: 'soft',
                startFromOffScreen: true,
                direction: oldFlyInDirectionsMap[params.direction ?? 'right'] ?? 90,
                distance: {value: 400, type: 'px'}
            })
            break
        case 'TurnIn':
            _.assign(namedEffect, {
                direction: 'right',
                ...params,
                type: 'CircleIn'
            })
            break
        case 'ArcIn':
            _.assign(
                namedEffect,
                viewMode === 'DESKTOP'
                    ? {direction: 'right', ...params, type: 'CurveIn'}
                    : {direction: 'right', ...params, type: 'ArcIn', power: 'soft'}
            )
            break
        case 'DropIn':
        case 'DropClipIn':
            _.assign(namedEffect, {
                power: 'hard',
                ...params,
                type: 'DropIn'
            })
            break
        case 'FlipIn':
            _.assign(namedEffect, {
                direction: 'left',
                power: 'soft',
                ...params,
                type: 'FlipIn'
            })
            break
        case 'FoldIn':
            _.assign(namedEffect, {
                direction: 'left',
                power: 'hard',
                ...params,
                type: 'FoldIn'
            })
            break
        case 'Reveal':
            if (params.direction === 'center') {
                _.assign(namedEffect, {
                    direction: 'left',
                    ...params,
                    type: 'ShapeIn',
                    shape: 'rectangle'
                })
            } else {
                _.assign(namedEffect, {
                    direction: 'left',
                    ...params,
                    type: 'RevealIn'
                })
            }
            break
        case 'SlideIn':
            _.assign(namedEffect, {
                direction: 'left',
                power: 'hard',
                ...params,
                type: 'SlideIn'
            })
            break
        case 'BounceIn':
            _.assign(namedEffect, {
                direction: 'top-left',
                ...params,
                type: 'PunchIn',
                power: params.bounce ?? 'medium'
            })
            delete namedEffect.bounce
            break
        case 'GlideIn':
            _.assign(namedEffect, {
                ...params,
                type: 'GlitchIn',
                power: 'soft',
                startFromOffScreen: false,
                direction: params.angle ?? 270,
                distance: {value: params.distance ?? 150, type: 'px'}
            })
            delete namedEffect.angle
    }

    return namedEffect
}

const createTimeAnimationOptionsItem = (id, behavior, namedEffectId) => {
    const {duration, delay, playOnce} = behavior

    return {
        id,
        type: 'TimeAnimationOptions',
        duration: Math.trunc(parseFloat(duration) * 1000 || 0),
        delay: Math.trunc(parseFloat(delay) * 1000 || 0),
        fill: 'backwards',
        allowReplay: playOnce ? 'never' : 'perPageView',
        iterations: 1,
        namedEffect: `#${namedEffectId}`
    }
}

const createEmptyTimeAnimationOptionsItem = id => ({
    id,
    allowReplay: 'perPageView',
    iterations: 1,
    type: 'TimeAnimationOptions'
})

const createTimeAnimationItem = (id, refArrayId) => ({
    id,
    type: 'TimeAnimation',
    name: '',
    value: `#${refArrayId}`
})

const createPlayReactionItem = (id, effectId) => ({
    id,
    effect: `#${effectId}`,
    type: 'Play',
    once: true
})

const createVariantRelation = (id, variants, scopedDataId, from) => {
    const variantRelationData = {
        id,
        variants,
        scopedDataId,
        from: `#${from}`
    }
    return dataUtils.variantRelation.create(variantRelationData)
}

const createViewportEnterVariantItem = (id, componentId) => ({
    id,
    componentId,
    trigger: 'viewport-enter',
    type: 'Trigger',
    params: {
        threshold: 0.15
    }
})

module.exports = {
    createItemWithValues,
    createNamedEffectItem,
    createVariantRelation,
    createPlayReactionItem,
    createTimeAnimationItem,
    createTimeAnimationOptionsItem,
    createViewportEnterVariantItem,
    createEmptyTimeAnimationOptionsItem
}
