//this was migrated from the extensionsAPI wrapper. Eventually this can be discarded as well
import type {PS} from '@wix/document-services-types'

const refArray = {
    extractValues: (ps: PS, ...args: any[]) => ps.extensionAPI.data.refArray.extractValues(...args),
    extractValuesWithoutHash: (ps: PS, ...args: any[]) =>
        ps.extensionAPI.data.refArray.extractValuesWithoutHash(...args),
    create: (ps: PS, ...args: any[]) => ps.extensionAPI.data.refArray.create(...args),
    update: (ps: PS, ...args: any[]) => ps.extensionAPI.data.refArray.update(...args),
    isRefArray: (ps: PS, ...args: any[]) => ps.extensionAPI.data.refArray.isRefArray(...args)
}

const breakpointRelation = {
    extractBreakpoint: (ps: PS, ...args: any[]) => ps.extensionAPI.data.breakpointRelation.extractBreakpoint(...args),
    extractRef: (ps: PS, ...args: any[]) => ps.extensionAPI.data.breakpointRelation.extractRef(...args),
    extractRefWithoutHash: (ps: PS, ...args: any[]) =>
        ps.extensionAPI.data.breakpointRelation.extractRefWithoutHash(...args),
    create: (ps: PS, ...args: any[]) => ps.extensionAPI.data.breakpointRelation.create(...args),
    isBreakpointRelation: (ps: PS, ...args: any[]) =>
        ps.extensionAPI.data.breakpointRelation.isBreakpointRelation(...args)
}

const variantRelation = {
    extractVariants: (ps: PS, ...args: any[]) => ps.extensionAPI.data.variantRelation.extractVariants(...args),
    extractAllVariants: (ps: PS, ...args: any[]) => ps.extensionAPI.data.variantRelation.extractAllVariants(...args),
    extractTo: (ps: PS, ...args: any[]) => ps.extensionAPI.data.variantRelation.extractTo(...args),
    extractFrom: (ps: PS, ...args: any[]) => ps.extensionAPI.data.variantRelation.extractFrom(...args),
    create: (ps: PS, ...args: any[]) => ps.extensionAPI.data.variantRelation.create(...args),
    isVariantRelation: (ps: PS, ...args: any[]) => ps.extensionAPI.data.variantRelation.isVariantRelation(...args)
}

export default {
    refArray,
    breakpointRelation,
    variantRelation
}
