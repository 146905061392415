import type {PublicMethodUtils} from '@wix/document-services-types'

export default function (publicMethodUtils: PublicMethodUtils) {
    const {
        extensionPublicAPI: {dataManipulation, getter}
    } = publicMethodUtils

    return {
        methods: {
            keyframeEffect: {
                add: dataManipulation('keyframeEffect.add', {
                    getReturnValueMethodPath: 'keyframeEffect.getKeyframeEffectToAddRef'
                }),
                get: getter('keyframeEffect.get'),
                list: getter('keyframeEffect.list'),
                remove: dataManipulation('keyframeEffect.remove'),
                update: dataManipulation('keyframeEffect.update')
            }
        }
    }
}
