import _ from 'lodash'
import warmupUtilsLib from '@wix/santa-core-utils'
const MASTER_PAGES_SECTIONS = {
    MOBILE: [
        'wysiwyg.viewer.components.HeaderContainer',
        'wysiwyg.viewer.components.SiteRegionContainer',
        'wysiwyg.viewer.components.PagesContainer',
        'wysiwyg.viewer.components.FooterContainer'
    ],
    DESKTOP: [
        'wysiwyg.viewer.components.HeaderContainer',
        'wysiwyg.viewer.components.PagesContainer',
        'wysiwyg.viewer.components.FooterContainer'
    ]
}

function isMasterPageSection(isMobileView: boolean, compStructure) {
    const masterPagesSections = isMobileView ? MASTER_PAGES_SECTIONS.MOBILE : MASTER_PAGES_SECTIONS.DESKTOP

    return _.includes(masterPagesSections, compStructure.componentType)
}

function getMasterPageSectionOrder(isMobileView: boolean, compStructure) {
    const masterPagesSections = isMobileView ? MASTER_PAGES_SECTIONS.MOBILE : MASTER_PAGES_SECTIONS.DESKTOP

    return _.indexOf(masterPagesSections, compStructure.componentType)
}

function getMasterPageSections(masterPageStructure, isMobileView: boolean) {
    const masterPageChildren = warmupUtilsLib.dataUtils.getChildrenData(masterPageStructure, isMobileView)

    return _(masterPageChildren)
        .filter(isMasterPageSection.bind(null, isMobileView))
        .sortBy(getMasterPageSectionOrder.bind(null, isMobileView))
        .value()
}

function getMasterPageChildrenSegregateByIsSection(masterPageStructure, isMobileView) {
    const masterPageChildren = warmupUtilsLib.dataUtils.getChildrenData(masterPageStructure, isMobileView)
    const segregatedChildren = _.partition(masterPageChildren, isMasterPageSection.bind(null, isMobileView))

    segregatedChildren[0] = _.sortBy(segregatedChildren[0], getMasterPageSectionOrder.bind(null, isMobileView))

    return segregatedChildren
}
export default {
    getMasterPageSections,
    getMasterPageChildrenSegregateByIsSection,
    MASTER_PAGES_SECTIONS
}
