'use strict'

const _ = require('lodash')
const {coreUtils} = require('@wix/santa-ds-libs/src/basic-index')
const {blogAppPartNames} = coreUtils

module.exports = {
    name: 'blogDateAlignmentCustomizationFixer',
    version: 1,
    exec(pageJson) {
        _(pageJson.data.document_data)
            .filter(
                compData =>
                    compData.type === 'AppPart' &&
                    _.includes(
                        [blogAppPartNames.CUSTOM_FEED, blogAppPartNames.FEED, blogAppPartNames.SINGLE_POST],
                        compData.appPartName
                    )
            )
            .forEach(compData => {
                _(compData.appLogicCustomizations)
                    .filter(customizationMatches)
                    .forEach(customization => {
                        _.assign(customization, {
                            fieldId: 'vars',
                            key: 'dateAndAuthorPosition',
                            value: customization.value === '0' ? 'left' : 'right'
                        })
                    })
            })
    }
}

function customizationMatches(customization) {
    return (
        customization.forType === 'Post' && //eslint-disable-line lodash/prefer-matches
        customization.key === 'layout.spacerBefore' &&
        customization.fieldId === 'date' &&
        _.includes(
            [
                'MediaTop',
                'MediaBottom',
                'MediaLeft',
                'MediaRight',
                'MediaZigzag',
                'Masonry',
                'MediaTopPage',
                'MediaBottomPage',
                'MediaLeftPage',
                'MediaRightPage',
                'MediaZigzagPage',
                'MasonryPage',
                'SinglePostMediaBottom',
                'SinglePostMediaTop'
            ],
            customization.view
        )
    )
}
