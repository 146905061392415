import type {CreateExtArgs, DalItem, Extension, ExtensionAPI} from '@wix/document-manager-core'
import type {DataModelExtensionAPI} from '../dataModel/dataModel'
import type {CompRef, Pointer} from '@wix/document-services-types'
import type {VariantsExtensionAPI} from '../variants/variants'
import {stripHashIfExists} from '../../utils/refArrayUtils'
import type {SchemaExtensionAPI} from '../schema/schema'
import {ReportableError} from '@wix/document-manager-utils'
import {DATA_TYPES} from '../../constants/constants'

type InnerElementPath = string[]

interface InnerElementsApi extends ExtensionAPI {
    getInnerElementData(compRef: CompRef, innerElementPath: InnerElementPath): DalItem | undefined
    getInnerElementStyle(compRef: CompRef, innerElementPath: InnerElementPath): DalItem | undefined
    setInnerElementData(compRef: CompRef, innerElementPath: InnerElementPath, data: any): void
    setInnerElementStyle(compRef: CompRef, innerElementPath: InnerElementPath, style: any): void
}

interface InnerElementsExtensionAPI extends ExtensionAPI {
    innerElements: InnerElementsApi
}

interface InnerElementsPublicApi extends ExtensionAPI {
    innerElements: InnerElementsApi
}

function error(message: string, extras: any = {}) {
    throw new ReportableError({errorType: 'InnerElementsError', message, ...extras})
}

const createExtension = (): Extension => {
    return {
        name: 'innerElements',
        dependencies: new Set(['schema', 'dataModel', 'variants']),
        createPublicAPI(args: CreateExtArgs): InnerElementsPublicApi {
            const {innerElements} = args.extensionAPI as InnerElementsExtensionAPI
            return {
                innerElements: {
                    getInnerElementData(compRef: CompRef, innerElementPath: InnerElementPath) {
                        return innerElements.getInnerElementData(compRef, innerElementPath)
                    },
                    getInnerElementStyle(compRef: CompRef, innerElementPath: InnerElementPath) {
                        return innerElements.getInnerElementStyle(compRef, innerElementPath)
                    },
                    setInnerElementData(compRef: CompRef, innerElementPath: InnerElementPath, data: any) {
                        return innerElements.setInnerElementData(compRef, innerElementPath, data)
                    },
                    setInnerElementStyle(compRef: CompRef, innerElementPath: InnerElementPath, style: any) {
                        return innerElements.setInnerElementStyle(compRef, innerElementPath, style)
                    }
                }
            }
        },
        createExtensionAPI(args: CreateExtArgs): InnerElementsExtensionAPI {
            const {dal, pointers} = args
            const {schemaAPI, dataModel, variants} = args.extensionAPI as SchemaExtensionAPI &
                DataModelExtensionAPI &
                VariantsExtensionAPI
            const namespace = DATA_TYPES.innerElements

            function innerElementsPointer(id: string, overrides?: Partial<Pointer>): Pointer {
                return pointers.getPointer(id, DATA_TYPES.innerElements, overrides)
            }

            function dataPointer(id: string): Pointer {
                return pointers.getPointer(id, DATA_TYPES.data)
            }

            function getComp(compRef: CompRef): DalItem {
                const comp = dal.get(compRef)
                if (!comp) {
                    throw error('Component not found', {compRef})
                }
                return comp
            }

            function getOrCreateInnerElement(compRef: CompRef, innerElementPath: InnerElementPath): DalItem {
                const comp = getComp(compRef)
                let innerElementsQuery = stripHashIfExists(comp.innerElementsQuery)
                if (!innerElementsQuery) {
                    const def = schemaAPI.getDefinition(comp.componentType)
                    if (!def) {
                        throw error('No definition for component type', {compType: comp.componentType})
                    }
                    const {innerElementsMapType} = def
                    if (!innerElementsMapType) {
                        throw error('No inner elements map type for component type', {compType: comp.componentType})
                    }
                    const mapPointer = dataModel.components.addItem(compRef, namespace, {
                        type: innerElementsMapType
                    })
                    innerElementsQuery = mapPointer.id
                }

                const mapPointer = innerElementsPointer(innerElementsQuery)
                let map = dal.get(mapPointer)
                const name = innerElementPath[1] // Nested inner elements are not supported yet
                if (!map[name]) {
                    const ptr = dataModel.addItem(
                        {
                            type: 'InnerElement'
                        },
                        namespace,
                        compRef.pageId!
                    )
                    dal.modify(mapPointer, (m: any) => ({
                        ...m,
                        [name]: ptr.id
                    }))
                    map = dal.get(mapPointer)
                }
                const innerElementId = stripHashIfExists(map[name])
                return dal.get(innerElementsPointer(innerElementId))
            }

            function getInnerElement(compRef: CompRef, innerElementPath: InnerElementPath): DalItem | undefined {
                const comp = getComp(compRef)
                const innerElementsQuery = stripHashIfExists(comp.innerElementsQuery)
                if (!innerElementsQuery) {
                    return undefined
                }
                const map = dal.get(innerElementsPointer(innerElementsQuery))
                if (!map) {
                    return undefined
                }
                const name = innerElementPath[1] // Nested inner elements are not supported yet
                const innerElementId = stripHashIfExists(map[name])
                if (!innerElementId) {
                    return undefined
                }
                const element = dal.get(innerElementsPointer(innerElementId))
                if (!element) {
                    return undefined
                }
                return element
            }

            // Style adjustments for development purposes, to be removed
            const adjustStyle = (style: any) => {
                // Until a decision is made, support both StyleRef and Style
                const adjustedStyle = style.style ? style.style : style

                if (!adjustedStyle.type) {
                    // Until a decision is made, patch the generic style type if needed
                    return {type: 'ComponentStyle', ...adjustedStyle}
                }
                return adjustedStyle
            }

            return {
                innerElements: {
                    getInnerElementData(compRef: CompRef, innerElementPath: InnerElementPath) {
                        const innerElement = getInnerElement(compRef, innerElementPath)
                        const dataQuery = stripHashIfExists(innerElement?.dataQuery)
                        return dal.get(dataPointer(dataQuery))
                    },
                    setInnerElementData(compRef: CompRef, innerElementPath: InnerElementPath, data: any) {
                        const innerElement = getOrCreateInnerElement(compRef, innerElementPath)
                        if (!innerElement.dataQuery) {
                            const dataQuery = dataModel.addItem(data, 'data', compRef.pageId!)
                            dal.modify(innerElementsPointer(innerElement.id!), (i: any) => ({
                                ...i,
                                dataQuery: dataQuery.id
                            }))
                        } else {
                            const dataQuery = stripHashIfExists(innerElement.dataQuery)
                            dal.set(dataPointer(dataQuery), data)
                        }
                    },
                    getInnerElementStyle(compRef: CompRef, innerElementPath: InnerElementPath) {
                        const innerElement = getInnerElement(compRef, innerElementPath)
                        if (!innerElement?.styleId) {
                            return undefined
                        }
                        return variants.getComponentItemConsideringVariants(
                            innerElementsPointer(innerElement.id!, {variants: compRef.variants ?? []}),
                            'style'
                        )
                    },
                    setInnerElementStyle(compRef: CompRef, innerElementPath: InnerElementPath, style: any) {
                        const adjustedStyle = adjustStyle(style)

                        const innerElement = getOrCreateInnerElement(compRef, innerElementPath)
                        if (!innerElement.styleId) {
                            dal.modify(innerElementsPointer(innerElement.id!), (ie: any) => ({
                                ...ie,
                                styleId: '<INVALID_STYLE_ID_FROM_INNER_ELEMENTS>' // Will be replaced in the next step
                            }))
                        }
                        variants.updateComponentDataConsideringVariants(
                            innerElementsPointer(innerElement.id!, {variants: compRef.variants ?? []}),
                            adjustedStyle,
                            'style'
                        )
                    }
                }
            }
        }
    }
}

export {createExtension}
export type {InnerElementsExtensionAPI, InnerElementsApi, InnerElementPath}
