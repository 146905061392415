import type {ModeDefinition, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import documentModeInfo from '../documentMode/documentModeInfo'
import componentModes from './componentModes'
import dsUtils from '../utils/utils'

function getSOSPModes(ps: PS) {
    const masterPagePointer = ps.pointers.components.getMasterPage(documentModeInfo.getViewMode(ps))
    return componentModes.getComponentModesByType(
        ps,
        masterPagePointer,
        santaCoreUtils.siteConstants.COMP_MODES_TYPES.SHOW_ON_SOME_PAGES
    )
}

function removeSOSPMode(ps: PS, modeId: string) {
    const masterPagePointer = ps.pointers.components.getMasterPage(documentModeInfo.getViewMode(ps))
    componentModes.removeComponentMode(ps, masterPagePointer, modeId)
}

function isShowOnSomePages(ps: PS, compPointer: Pointer, sospModesIds: string[]) {
    const compOverrides = componentModes.overrides.getAllOverrides(ps, compPointer)
    return _.some(compOverrides, override => !_(override.modeIds).intersection(sospModesIds).isEmpty())
}

function getShowOnSomePagesRoot(ps: PS, compPointer: Pointer) {
    if (!ps.pointers.components.isInMasterPage(compPointer)) {
        return null
    }

    const sospModes = getSOSPModes(ps)

    if (_.isEmpty(sospModes)) {
        return null
    }

    const sospModesIds = _.map(sospModes, 'modeId')

    let compToCheck = compPointer
    while (!isShowOnSomePages(ps, compToCheck, sospModesIds)) {
        compToCheck = ps.pointers.components.getParent(compToCheck)
        if (!compToCheck) {
            return null
        }
    }

    return compToCheck
}

function getSOSPRootMode(ps: PS, sospRoot: Pointer) {
    const masterPagePointer = ps.pointers.components.getMasterPage(documentModeInfo.getViewMode(ps))
    const sospModesIds = _.map(getSOSPModes(ps), 'modeId')
    const compOverrides = componentModes.overrides.getAllOverrides(ps, sospRoot)
    const sospOverride = _.find(compOverrides, override => !_(override.modeIds).intersection(sospModesIds).isEmpty())

    return componentModes.getComponentModeById(ps, masterPagePointer, sospOverride.modeIds[0])
}

function getSOSPModePagesGroupId(sospModeDefinition: ModeDefinition) {
    return dsUtils.stripHashIfExists(_.get(sospModeDefinition, ['settings', 'pagesGroupId'], ''))
}

function getComponentPagesGroupId(ps: PS, compPointer: Pointer) {
    const sospRoot = getShowOnSomePagesRoot(ps, compPointer)
    const sospMode = sospRoot ? getSOSPRootMode(ps, sospRoot) : null

    return getSOSPModePagesGroupId(sospMode)
}

export default {
    getSOSPModes,
    removeSOSPMode,
    getShowOnSomePagesRoot,
    getComponentPagesGroupId
}
