import type {PublicMethodUtils} from '@wix/document-services-types'
import {scopes, scopesMetaData} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            scopes: {
                metaData: {
                    loadMetaData: publicMethodUtils.defineGetter(scopesMetaData.loadMetaData),
                    getMetaData: publicMethodUtils.defineGetter(scopesMetaData.getMetaData)
                }
            },
            components: {
                scopes: {
                    isLoaded: publicMethodUtils.defineGetter(scopes.isLoaded),
                    extractScopeFromPointer: publicMethodUtils.defineGetter(scopes.extractScopeFromPointer),
                    getDefinedScopes: publicMethodUtils.defineGetter(scopes.getDefinedScopes),
                    hasDefinedScopes: publicMethodUtils.defineGetter(scopes.hasDefinedScopes),
                    getRootComponent: publicMethodUtils.defineGetter(scopes.getRootComponent),
                    getScopeOwner: publicMethodUtils.defineGetter(scopes.getScopeOwner),
                    getTemplateCompPointer: publicMethodUtils.defineGetter(scopes.getTemplateCompPointer),
                    getTemplateRoot: publicMethodUtils.defineGetter(scopes.getTemplateRoot),
                    getScopesList: publicMethodUtils.defineGetter(scopes.getScopesList),
                    getComponentInScope: publicMethodUtils.defineGetter(scopes.getComponentInScope)
                }
            }
        }
    }
}
