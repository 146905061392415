import type {InnerElementsPathsBuilderStructures, PS} from '@wix/document-services-types'
import type {ComponentBoundingBox} from '@wix/viewer-manager-interface'

const buildPaths = (ps: PS, structures: InnerElementsPathsBuilderStructures) => ps.siteAPI.buildPaths(structures)
const getInnerElementBoundingBoxes = (ps: PS, path: string[]): ComponentBoundingBox[] =>
    ps.siteAPI.getInnerElementBoundingBoxes(path)

export default {
    buildPaths,
    getInnerElementBoundingBoxes
}
