/**
 * Created by eitanr on 6/17/14.
 */
import _ from 'lodash'

const emptyAjaxFn = () => {
    throw new Error('must register ajax function: zepto $.ajax for clientSide, or request() for serverSide')
}

export type AjaxFn = (options?: AjaxOp) => void

let ajaxFn: AjaxFn = emptyAjaxFn

export interface AjaxLibrary {
    register(ajaxMethod: AjaxFn): void
    ajax(options: AjaxOp): void
    ajaxAsync(options: AjaxOp): Promise<any>
}

function register(ajaxMethod: AjaxFn) {
    if (typeof ajaxMethod !== 'function') {
        throw new Error('ajaxMethod must be a function')
    }
    ajaxFn = ajaxMethod
}

export interface AjaxOp {
    credentials?: string
    url?: string
    asForm?: boolean
    contentType?: string
    type?: string
    dataType?: string
    data?: any
    processData?: boolean
    crossDomain?: boolean
    headers?: Record<string, any>
    // use auth from client spec map
    appIdAutoAuth?: string
    xhrFields?: {
        withCredentials: true
    }
    success?(response): void
    error?(xhr, errorType, err): void
}

function ajax(options: AjaxOp) {
    if (options.asForm) {
        options.contentType = 'application/x-www-form-urlencoded'
    } else if (options.type === 'POST' && options.dataType === 'json' && _.isObject(options.data) && !options.asForm) {
        _.assign(options, {
            data: JSON.stringify(options.data),
            contentType: options.contentType ?? 'application/json; charset=utf-8',
            processData: false
        })
    }
    return ajaxFn(options)
}

const ajaxAsync = (options: AjaxOp): Promise<any> =>
    new Promise<any>((resolve, reject) => {
        ajax({
            ...options,
            success: resolve,
            error: reject
        })
    })

export default {
    register,
    ajaxAsync,
    ajax,
    // FIXME - need to be removed (seems to be unused)
    get(url, data, success, dataType) {
        if (typeof url === 'object') {
            return ajaxFn(url)
        }
        const options: any = {
            url
        }
        if (data) {
            options.data = data
        }
        if (success) {
            options.success = success
        }
        if (dataType) {
            options.dataType = dataType
        }
        return ajaxFn(options)
    }
} as AjaxLibrary
