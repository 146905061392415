import _ from 'lodash'
import objectUtils from '../../coreUtils/core/objectUtils'

export function doesAllowHeightResize(partDefinition, viewName, format?) {
    const path = 'allowHeightResize'

    const configByFormat = objectUtils.resolvePath(partDefinition, ['configByFormat', format, path])
    if (configByFormat !== null) {
        return configByFormat
    }

    const configByView = objectUtils.resolvePath(partDefinition, ['configByView', viewName, path])
    if (configByView !== null) {
        return configByView
    }

    if (_.has(partDefinition, path)) {
        return partDefinition[path]
    }

    return false
}
