'use strict'

const _ = require('lodash')

const TINY_MENU_SKINS = [
    'wysiwyg.viewer.skins.mobile.TinyMenuSkin',
    'wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin',
    'wysiwyg.viewer.skins.mobile.TinyMenuPullFromLeftSkin',
    'wysiwyg.viewer.skins.mobile.TinyMenuPullFromTopSkin',
    'wysiwyg.viewer.skins.mobile.TinyMenuPullFromRightSkin'
]

const TINY_MENU_DEFAULT_SKIN = 'wysiwyg.viewer.skins.mobile.TinyMenuSkin'
const TINY_MENU_DEFAULT_STYLE_ID = 'tm2'

function hasValidSkin(tinyMenuComponent, themeData) {
    const styleSkin = _.get(themeData, [tinyMenuComponent.styleId, 'skin'])
    const structureSkin = tinyMenuComponent.skin
    return (
        styleSkin &&
        structureSkin &&
        _.includes(TINY_MENU_SKINS, styleSkin) &&
        _.includes(TINY_MENU_SKINS, structureSkin)
    )
}

function fixTinyMenuSkin(tinyMenuComponent) {
    tinyMenuComponent.skin = TINY_MENU_DEFAULT_SKIN
    tinyMenuComponent.styleId = TINY_MENU_DEFAULT_STYLE_ID
}

function findTinyMenuComponent(components) {
    let tinyMenuComponent = _.find(components, {id: 'TINY_MENU'})
    if (tinyMenuComponent) {
        return tinyMenuComponent
    }
    _.forEach(components, component => {
        if (_.isEmpty(component.components)) {
            return
        }
        tinyMenuComponent = findTinyMenuComponent(component.components)
        return !tinyMenuComponent
    })
    return tinyMenuComponent
}

/**
 * @exports utils/dataFixer/plugins/tinyMenuSkinFixer
 * @type {{exec: function}}
 */
module.exports = {
    name: 'tinyMenuSkinFixer',
    version: 1,
    exec(pageJson) {
        const isMasterPage = _.get(pageJson, ['structure', 'id']) === 'masterPage'
        if (!isMasterPage) {
            return
        }
        const tinyMenuComponent = findTinyMenuComponent(pageJson.structure.mobileComponents)
        if (tinyMenuComponent && !hasValidSkin(tinyMenuComponent, pageJson.data.theme_data)) {
            fixTinyMenuSkin(tinyMenuComponent)
        }
    }
}
