import _ from 'lodash'
import constants from '../../constants/constants'
import experiment from 'experiment-amd'
import type {Pointer, PS} from '@wix/document-services-types'
import type {DsFixer} from '../dsDataFixers'

function someCompAnchorsWasAlreadyRemoved(ps: PS, pageCompPointer: Pointer) {
    function someCompAnchorsWasAlreadyRemovedRec(compPointer: Pointer): boolean {
        const jsonAnchorsPointer = ps.pointers.getInnerPointer(compPointer, 'layout.anchors')
        if (!ps.dal.isExist(jsonAnchorsPointer)) {
            return true
        }
        const children = ps.pointers.components.getChildren(compPointer)
        return _.some(children, someCompAnchorsWasAlreadyRemovedRec)
    }

    return someCompAnchorsWasAlreadyRemovedRec(pageCompPointer)
}

function getPageCompPointer(ps: PS, pagePointer: Pointer) {
    return ps.pointers.components.getPage(pagePointer.id, constants.VIEW_MODES.DESKTOP)
}

const getCompsWithWrongAnchors = function (ps: PS, pageCompPointer: Pointer) {
    const pageComponentsIncludingRoot = [pageCompPointer].concat(
        ps.pointers.components.getChildrenRecursively(pageCompPointer)
    )

    const compsWithJsonAnchors = _.filter(pageComponentsIncludingRoot, function (compPointer) {
        const jsonAnchorsPointer = ps.pointers.getInnerPointer(compPointer, 'layout.anchors')
        return ps.dal.isExist(jsonAnchorsPointer)
    })

    const compsWithJsonAnchorsIds = _.map(compsWithJsonAnchors, 'id')

    return _.filter(compsWithJsonAnchors, function (compPointer) {
        const jsonAnchorsPointer = ps.pointers.getInnerPointer(compPointer, 'layout.anchors')
        const anchors = ps.dal.get(jsonAnchorsPointer)

        return _.some(
            anchors,
            anchor => anchor.type !== 'BOTTOM_PARENT' && !_.includes(compsWithJsonAnchorsIds, anchor.targetComponent)
        )
    })
}

function getPagesToCheck(ps: PS) {
    const allPages = ps.pointers.page.getNonDeletedPagesPointers(false)

    return _(allPages)
        .map(page => getPageCompPointer(ps, page))
        .filter(pageCompPointer => someCompAnchorsWasAlreadyRemoved(ps, pageCompPointer))
        .value()
}

function exec(ps: PS) {
    if (experiment.isOpen('sv_fixBrokenAnchors')) {
        const pagesToCheck = getPagesToCheck(ps)

        _.forEach(pagesToCheck, function (pageCompPointer) {
            const compsWithWrongAnchors = getCompsWithWrongAnchors(ps, pageCompPointer)

            if (!_.isEmpty(compsWithWrongAnchors)) {
                _.forEach(compsWithWrongAnchors, function (compPointer) {
                    const jsonAnchorsPointer = ps.pointers.getInnerPointer(compPointer, 'layout.anchors')
                    ps.dal.full.remove(jsonAnchorsPointer)
                })
            }
        })
    }
}

const fixer: DsFixer = {
    exec,
    name: 'brokenAnchorsFixer',
    version: 1
}
export default fixer
