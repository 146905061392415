import sessionFreezer from '../core/SessionFreezer'

const regularJsonPointerGenerators = {}

const bothRegularAndFullPointerGenerators = {}

const pointersGenerators = {}

const types = {}

sessionFreezer.freeze(regularJsonPointerGenerators)
sessionFreezer.freeze(bothRegularAndFullPointerGenerators)
sessionFreezer.freeze(pointersGenerators)
sessionFreezer.freeze(types)

export default {
    getRegularJsonGenerators() {
        return regularJsonPointerGenerators
    },
    getBothRegularAndFullJsonGenerators() {
        return bothRegularAndFullPointerGenerators
    },
    getAllTypes() {
        return types
    }
}
