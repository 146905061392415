import type {FetchFn} from '@wix/document-manager-extensions'
import {ReportableError, retryTaskAndReport} from '@wix/document-manager-utils'
import _ from 'lodash'
import type {ClientSpecMap, Experiment, MetaSiteClientSpecEntry, RendererModel} from '@wix/document-services-types'
import {checkResponseForError} from '../utils/fetch'
import {AuthorizationMap, AuthorizationStatusMap, csmToAuthorizationMap} from './AuthorizationMap'
import type {CoreLogger} from '@wix/document-manager-core'

export const META_SITE_APP_ID = '-666'
const META_SITE_CLIENT_SPEC_MAP_URL = '/_api/msm/v1/meta-site/editor-client-spec-map/'

const createMetaSiteClientSpecMapUrl = (msid: string): string => `${META_SITE_CLIENT_SPEC_MAP_URL}${msid}?https=true`

const fetchCsmResponse = async (fetchFn: FetchFn, msid: string): Promise<Response> => {
    const response = await fetchFn(createMetaSiteClientSpecMapUrl(msid))
    checkResponseForError(response, 'fetchClientSpecMapError')
    return response
}

export const FIVE_MINUTES = 1000 * 60 * 5

const getWixInstanceExpirationTime = (now: number, expirationTimestamp: string): number => {
    if (!expirationTimestamp) {
        return now
    }
    const expirationDate = new Date(expirationTimestamp)
    return expirationDate.getTime() - FIVE_MINUTES
}

const getTimeUntilWixInstanceExpiration = (now: number, expirationTimestamp: string) =>
    getWixInstanceExpirationTime(now, expirationTimestamp) - now

export interface AuthorizationStatus {
    get instance(): string
    get metaSiteId(): string
    get expirationDate(): string
    isExpired(): boolean
}

export class AuthorizationInstance implements AuthorizationStatus {
    constructor(
        public readonly instance: string,
        public expirationDate: string,
        public readonly metaSiteId: string,
        public readonly appId: string
    ) {}

    isExpired(): boolean {
        const timeUntil = getTimeUntilWixInstanceExpiration(Date.now(), this.expirationDate)
        return timeUntil <= 0
    }
}

export class InvalidAuthorizationInstance implements AuthorizationStatus {
    constructor() {}

    isExpired(): boolean {
        return true
    }

    get metaSiteId(): string {
        throw new Error('Mocked AuthorizationStatus - metaSiteId')
    }

    get expirationDate(): string {
        throw new Error('Mocked AuthorizationStatus - expirationDate')
    }

    get instance(): string {
        throw new Error('Mocked AuthorizationStatus - instance')
    }
}

export class TestNonExpiringAuthorizationStatus implements AuthorizationStatus {
    constructor() {}

    isExpired(): boolean {
        return false
    }

    get metaSiteId(): string {
        return 'msid'
    }

    get expirationDate(): string {
        return 'expirationDate'
    }

    get instance(): string {
        return 'instance'
    }
}

export const extractMetaSiteInstance = (clientSpecMap: ClientSpecMap, appId: string = META_SITE_APP_ID) => {
    const {metaSiteId, expirationDate, instance} = clientSpecMap[appId] as MetaSiteClientSpecEntry
    return new AuthorizationInstance(instance, expirationDate!, metaSiteId, appId)
}

export const extractRendererModelToAuthorizationMap = (rendererModel: RendererModel): AuthorizationMap => {
    return csmToAuthorizationMap(rendererModel.clientSpecMap as Record<string, MetaSiteClientSpecEntry>)
}

const fetchRetryAndReport = async (fetchFn: FetchFn, msid: string, logger?: CoreLogger): Promise<Response> =>
    retryTaskAndReport({
        task: () => fetchCsmResponse(fetchFn, msid),
        checkIfShouldRetry: (e: any) => (e?.status === 503 ? {shouldRetry: true, reason: '503'} : {shouldRetry: false}),
        interactionName: 'retry_csm',
        maxRetries: 1,
        logger
    })

export const fetchClientSpecMap = async (
    fetchFn: FetchFn,
    msid: string,
    experimentInstance: Experiment,
    logger?: CoreLogger
): Promise<AuthorizationStatusMap> => {
    const response = await fetchRetryAndReport(fetchFn, msid, logger)
    const refreshedClientSpecMap = await response.json()
    if (!(META_SITE_APP_ID in refreshedClientSpecMap)) {
        throw new ReportableError({
            errorType: 'fetchClientSpecMapError',
            message: 'could not find -666 in client spec map',
            extras: {
                clientSpecMapIds: _(refreshedClientSpecMap).keys().take(20).value()
            }
        })
    }
    return csmToAuthorizationMap(refreshedClientSpecMap)
}
