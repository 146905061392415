import immutable from 'immutable'

const isImmutable = (val: any) => val?.toJS

export interface SimpleSnapshots {
    initial: any
    last: any
    pending?: any
    current: any
}
export interface ImmutableDAL {
    getByPath(path: string[]): any
    setByPath(path: string[], value: any): void
    getKeysByPath(path: string[]): string[]
    isPathExist(path: string[]): boolean
    removeByPath(path: string[]): void
    pushByPath(path: string[], value: any): void
    takeTaggedSnapshot(tagName: string): void
    markPendingSnapshot(): void
    takeInitialSnapshot(): void
    getTaggedSnapshot(tagName: string): any
    getCurrentSnapshot(): any
    getLastSnapshot(): any
    getInitialSnapshot(): any
    getPendingSnapshot(): any
    rollbackSnapshot(): void
    commitPendingSnapshot(): void
}
export const createImmutableDAL = (store: any = {}): ImmutableDAL => {
    const snapshots: Record<string, any> = {}
    let lastState: any, initialState: any, pending: any //eslint-disable-line one-var
    let currentState = store ? immutable.fromJS(store) : null

    return {
        getByPath(path: string[]) {
            const val = currentState.getIn(path)
            if (isImmutable(val)) {
                return val.toJS()
            }
            return val
        },
        setByPath(path: string[], value: any) {
            currentState = currentState.setIn(path, immutable.fromJS(value))
        },
        getKeysByPath(path: string[]) {
            return currentState.getIn(path).keySeq().toArray()
        },
        isPathExist(path: string[]) {
            return currentState.hasIn(path)
        },
        removeByPath(path: string[]) {
            currentState = currentState.removeIn(path)
        },
        pushByPath(path: string[], value: any) {
            currentState = currentState.updateIn(path, (list: any) => list.push(immutable.fromJS(value)))
        },
        takeTaggedSnapshot(tagName: string) {
            snapshots[tagName] = currentState
        },
        takeInitialSnapshot() {
            if (initialState) {
                throw new Error('Cannot take initial snapshot if it has already been taken')
            }
            initialState = currentState
            lastState = currentState
        },
        markPendingSnapshot() {
            if (pending) {
                throw new Error(
                    'cannot take a snapshot while one is already pending. the pending snapshot must first be committed'
                )
            }
            pending = currentState
        },
        getTaggedSnapshot(tagName: string) {
            return snapshots[tagName]
        },
        getCurrentSnapshot() {
            return currentState
        },
        getLastSnapshot() {
            return lastState
        },
        getPendingSnapshot() {
            return pending
        },
        getInitialSnapshot() {
            return initialState
        },
        rollbackSnapshot() {
            if (pending) {
                pending = undefined
            }
        },
        commitPendingSnapshot() {
            if (pending) {
                lastState = pending
                pending = undefined
            }
        }
    }
}
