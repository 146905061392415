import {sessionFreezer} from '../../warmupUtils/index'

const jsonUpdaterPlugins: Record<string, any> = {}
const layouterComps: Record<string, any> = {}

sessionFreezer.freeze(jsonUpdaterPlugins)
sessionFreezer.freeze(layouterComps)

function registerPlugin(compType: string, callback) {
    jsonUpdaterPlugins[compType] = callback
}

function getCompPlugin(compType: string) {
    return jsonUpdaterPlugins[compType]
}

export default {
    getCompPlugin,
    registerPlugin
}
