import _ from 'lodash'
import type {Pointer, ScopePointer} from '@wix/document-services-types'
import {
    REF_COMPONENT_TYPE,
    REF_DELIMITER,
    REPEATER_DELIMITER,
    VIEW_MODES,
    SHARED_PARTS_PREFIX
} from './constants/constants'
import {pointerUtils} from '@wix/document-manager-core'
import type {ViewerAPI} from '@wix/viewer-manager-interface'

const {getPointer} = pointerUtils

const REF_SUFFIX = '-ref'
const {DESKTOP, MOBILE} = VIEW_MODES
const POSSIBLE_VIEW_MODES = new Set([DESKTOP, MOBILE, DESKTOP + REF_SUFFIX, MOBILE + REF_SUFFIX])

const buildScopedPointer = (id: string, type: string, scope?: ScopePointer): Pointer => {
    const pointer = getPointer(id, type)

    if (scope) {
        pointer.scope = scope
    }

    return pointer
}

const buildScopePath = (scopePointer: ScopePointer): string[] => {
    const {id, scope} = scopePointer
    if (!scope) {
        return [id]
    }

    return [...buildScopePath(scope), id]
}

const addScopeToInflatedId = (id: string, scope: string): string => {
    if (scope.includes(REPEATER_DELIMITER)) {
        const repeaterItem = scope.split(REPEATER_DELIMITER)[1]
        return `${id}${REPEATER_DELIMITER}${repeaterItem}`
    }

    return `${scope}${REF_DELIMITER}${id}`
}

const buildInflatedId = (pointer: Pointer): string => {
    if (pointer.scope) {
        return buildScopePath(pointer.scope).reverse().reduce(addScopeToInflatedId, pointer.id)
    }
    return pointer.id
}

const getScopePathWithoutRepeaters = (scopePath: string[]) => {
    let repeatersNestingSuffix = ''
    const filteredPath = []

    for (const scope of scopePath) {
        if (scope.includes(REPEATER_DELIMITER)) {
            const itemId = scope.split(REPEATER_DELIMITER)[1]
            repeatersNestingSuffix = `${REPEATER_DELIMITER}${itemId}${repeatersNestingSuffix}`
        } else {
            filteredPath.push(`${scope}${repeatersNestingSuffix}`)
        }
    }

    return filteredPath
}

const buildScopeByPath = (scopePath: string[]): ScopePointer | undefined => {
    return _.reduce(
        scopePath,
        (scopePointer: ScopePointer | undefined, scopeId: string) => {
            if (scopeId === SHARED_PARTS_PREFIX) {
                return scopePointer
            }

            return buildScopedPointer(scopeId, 'scope', scopePointer) as ScopePointer
        },
        undefined
    )
}

const buildScopePointerByInflatedId = (id: string): ScopePointer | undefined => {
    const splittedId = id.split(REF_DELIMITER)
    splittedId.pop()

    if (splittedId.length > 0) {
        return buildScopeByPath(splittedId)
    }
}

const getScopePathById = (viewer: ViewerAPI, id: string, enableRepeatersInScopes?: boolean): string[] => {
    let scopePath = viewer.scopes.getScope(id)

    if (!enableRepeatersInScopes) {
        scopePath = getScopePathWithoutRepeaters(scopePath)
    }

    return scopePath
}

const getRootScopeIdById = (viewer: ViewerAPI, id: string, enableRepeatersInScopes?: boolean): string => {
    let [rootScopeId] = getScopePathById(viewer, id, enableRepeatersInScopes)

    if (rootScopeId === undefined) {
        rootScopeId = id.split(REF_DELIMITER)[0]
    }

    return rootScopeId
}

const getScopeById = (
    viewer: ViewerAPI,
    id: string,
    enableRepeatersInScopes: boolean = false
): ScopePointer | undefined => {
    const scopePath = getScopePathById(viewer, id, enableRepeatersInScopes)

    return buildScopeByPath(scopePath)
}

const createScopedPointer = (
    pointer: Pointer,
    scopePath: string[],
    enableRepeatersInScopes: boolean = false
): Pointer => {
    if (!enableRepeatersInScopes) {
        scopePath = getScopePathWithoutRepeaters(scopePath)
    }

    const scope = buildScopeByPath(scopePath)

    return {
        ...pointer,
        ...buildScopedPointer(pointer.id, pointer.type, scope)
    }
}

const isInflatedIdInSameScope = (scopedPointer: Pointer, inflatedId: string) => {
    const pointerScope = scopedPointer.id.split(REF_DELIMITER)
    pointerScope.pop()
    const inflatedIdScope = inflatedId.split(REF_DELIMITER)
    inflatedIdScope.pop()

    return _.isEqual(pointerScope, inflatedIdScope)
}

const isPropertyRelevant = (pointer: Pointer, innerPath: string): boolean =>
    !pointer.innerPath || (pointer.innerPath.length === 1 && pointer.innerPath[0] === innerPath)

const removeOutOfScopeData = (pointer: Pointer, value: any) => {
    if (!value || !POSSIBLE_VIEW_MODES.has(pointer.type)) {
        return value
    }

    const shouldRemoveParent =
        value.parent && isPropertyRelevant(pointer, 'parent') && !isInflatedIdInSameScope(pointer, value.parent)

    const shouldRemoveChildren =
        value.components?.length > 0 &&
        isPropertyRelevant(pointer, 'components') &&
        value.componentType === REF_COMPONENT_TYPE

    if (!shouldRemoveParent && !shouldRemoveChildren) {
        return value
    }

    const newValue = {...value}

    if (shouldRemoveParent) {
        newValue.parent = undefined
    }

    if (shouldRemoveChildren) {
        newValue.components = []
    }

    return newValue
}

export {
    createScopedPointer,
    buildScopePointerByInflatedId,
    getScopeById,
    buildScopePath,
    buildInflatedId,
    removeOutOfScopeData,
    getRootScopeIdById
}
