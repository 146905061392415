import type {PS} from '@wix/document-services-types'
import dataManipulation from './dataManipulation'

function getSiteDisplayName(ps: PS) {
    return dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.SITE_DISPLAY_NAME)
}

export default {
    get: getSiteDisplayName
}
