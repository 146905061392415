import type {SerializedCompStructure, Pointers, Experiment} from '@wix/document-services-types'
import {generateItemIdWithPrefix} from '../../utils/dataUtils'
import type {
    EntitySuggestionResult,
    FlatOutline,
    Outline,
    OutlineSuggestionResultForStructure,
    OutlineValidationResults,
    OutlineWithIdMapForStructure
} from './aiContentExtension'
import type {CoreConfig, EnvironmentContext, ExtensionAPI} from '@wix/document-manager-core'
import type {SerializedStructureExtensionAPI} from '../serializedStructure'
import {
    getSectionName,
    unsectionedOutlineSectionName,
    outlineCompIdSectionNameOverrides,
    ignoredContainerTypes,
    getContentForComponent,
    getNextIdForPrefix,
    CompletionCallMetadata,
    invalidOutlineRetryAmount,
    reportOutlineBIError,
    validateOutlineSuggestion,
    validateContentLengthInternal,
    updateDataItemValue,
    serializedRepeaterDataItemType,
    validateFlatOutlineSuggestion,
    structureTypes,
    textComponentType
} from './aiExtensionContent'
import type {ComponentDefinitionExtensionAPI} from '../componentDefinition'
import {
    aiContentBadJsonErrorMessage,
    aiContentBadJsonErrorType,
    aiContentMissingOnOutlineErrorType,
    aiContentMissingOnOutlineMessage,
    aiContentOutlineResultConsecutiveErrorMessage,
    aiContentOutlineResultConsecutiveErrorType,
    aiContentOutlineResultErrorMessage,
    aiContentOutlineResultErrorType,
    aiContentTooLongErrorMessage,
    aiContentTooLongErrorType,
    aiContentUnexpectedError,
    aiContentUnexpectedErrorMessage,
    aiPageSuggestionInteraction,
    aiStructureMissingOutlineMessage,
    nonApplicableParamsVersion,
    promptHubFlatStructurePromptId,
    promptHubStructurePromptId
} from './constants'
import {
    fetchOutline,
    parsePaddedJSON,
    getSectionInjectionVersions,
    fetchOutlineWithPromptParamsFromLegacy,
    getNewOutlineFromJSON,
    fetchOutlineWithPromptParamsFromPromptHub,
    getPromptHubInjectionParams
} from './aiExtensionUtils'
import {getReportableFromError, ReportableError} from '@wix/document-manager-utils'
import _ from 'lodash'
import {deepClone} from '@wix/wix-immutable-proxy'
import type {PromptUsage} from './types'
const getSectionNameByStructure = (extensionAPI: ExtensionAPI, sectionStructure: SerializedCompStructure): string => {
    const {serializedStructure} = extensionAPI as SerializedStructureExtensionAPI
    const anchorItem = serializedStructure.getComponentByNamespace(sectionStructure, 'anchor')
    return getSectionName(anchorItem)
}
const getOutlineContainerNameByStructure = (
    extensionAPI: ExtensionAPI,
    containerStructure: SerializedCompStructure,
    containerType: string
): string | undefined => {
    const {serializedStructure} = extensionAPI as SerializedStructureExtensionAPI
    const {componentDefinition} = extensionAPI as ComponentDefinitionExtensionAPI

    if (componentDefinition.isSection(containerType)) {
        return getSectionNameByStructure(extensionAPI, containerStructure)
    }
    const overrideName = outlineCompIdSectionNameOverrides[serializedStructure.getComponentId(containerStructure)!]
    if (overrideName) {
        return overrideName
    } else if (componentDefinition.isPage(containerType)) {
        return 'page'
    }
}
const getOverrideId = (id: string, idSuffix: string) => (idSuffix ? `${id}_${idSuffix}` : id)
const getComponentsDataItemAndOverrides = (dataItem: Record<string, any>) => {
    const {original, overrides} = dataItem
    const overridesItems = Object.entries(overrides).map(([overrideId, item]) => ({item, overrideId}))
    return [{item: original, overrideId: ''}, ...overridesItems]
}
export const getContentRecursivelyForSerializedStructure = (
    extensionArgs: ExtensionArgs,
    compStructure: SerializedCompStructure,
    outline: Outline | FlatOutline,
    typeCounter: Record<string, number>,
    idMap: Record<string, string>,
    idsToKeep: Record<string, string>,
    structureType: string,
    unsectionedSectionName: string = unsectionedOutlineSectionName,
    outlineSectionName?: string | undefined
): void => {
    const {extensionAPI} = extensionArgs
    const {serializedStructure} = extensionAPI as SerializedStructureExtensionAPI
    const {componentDefinition} = extensionAPI as ComponentDefinitionExtensionAPI

    const type = serializedStructure.getComponentType(compStructure)
    const getContentAndUpdateOutline = (
        _outline: Outline | FlatOutline,
        dataItem: Record<string, any>,
        idSuffix: string = '',
        overrideFieldType?: string
    ) => {
        const content = getContentForComponent(type, dataItem)
        if (content) {
            content.type = overrideFieldType ?? content.type

            const mappedId = getNextIdForPrefix(content.type, typeCounter)
            const compId = serializedStructure.getComponentId(compStructure)!
            idMap[mappedId] = idSuffix ? getOverrideId(compId, idSuffix) : compId
            if (structureType === structureTypes.SECTION) {
                _outline[mappedId] = content.value
            } else {
                const outlineSectionNameOrDefault = outlineSectionName ?? unsectionedSectionName
                _outline[outlineSectionNameOrDefault] ??= {}
                _outline[outlineSectionNameOrDefault][mappedId] = content.value
            }
        }
    }
    if (ignoredContainerTypes.has(type)) {
        return
    }
    const id = serializedStructure.getComponentId(compStructure)
    if (!id) {
        serializedStructure.setComponentId(compStructure, generateItemIdWithPrefix('comp'))
    } else {
        idsToKeep[id] = id
    }
    if (componentDefinition.isContainer(type)) {
        const containerName = getOutlineContainerNameByStructure(extensionAPI, compStructure, type)
        const containerOutlineSectionName = containerName
            ? getNextIdForPrefix(containerName, typeCounter)
            : outlineSectionName
        const children = serializedStructure.getChildren(compStructure) ?? []
        for (const childStructure of children) {
            getContentRecursivelyForSerializedStructure(
                extensionArgs,
                childStructure as SerializedCompStructure,
                outline,
                typeCounter,
                idMap,
                idsToKeep,
                structureType,
                unsectionedSectionName,
                containerOutlineSectionName
            )
        }
    } else if (outlineSectionName) {
        let contentLabelOverride: string | undefined

        // for wrichtexts, prefer to use fieldName from contentRole to signify the outline parameter name (ie: subtitle instead of title/paragraph)
        const fieldRoleExp = extensionArgs.coreConfig.experimentInstance.isOpen('dm_sgAiUseFieldRoles')
        const isWRichText = serializedStructure.getComponentType(compStructure) === textComponentType
        if (fieldRoleExp && isWRichText) {
            const contentRoleItem = serializedStructure.getComponentByNamespace(compStructure, 'contentRole')
            contentLabelOverride = contentRoleItem?.fieldRole
        }

        const dataItem = serializedStructure.getComponentByNamespace(compStructure, 'data')
        if (dataItem && dataItem.type === serializedRepeaterDataItemType) {
            const dataItemsOverrides = getComponentsDataItemAndOverrides(dataItem)
            for (const dataItemOverride of dataItemsOverrides) {
                const idSuffix = dataItemOverride.overrideId
                getContentAndUpdateOutline(outline, dataItemOverride.item, idSuffix, contentLabelOverride)
            }
        } else {
            getContentAndUpdateOutline(outline, dataItem, '', contentLabelOverride)
        }
    }
}
const getOutlineByStructure = (
    extensionArgs: ExtensionArgs,
    compStructure: SerializedCompStructure,
    structureType: string,
    sectionCategory?: string
): OutlineWithIdMapForStructure => {
    const outline: Outline | FlatOutline = {}
    const typeCounter: Record<string, number> = {}
    const idMap: Record<string, string> = {}
    const idsToKeep: Record<string, string> = {}
    getContentRecursivelyForSerializedStructure(
        extensionArgs,
        compStructure,
        outline,
        typeCounter,
        idMap,
        idsToKeep,
        structureType,
        sectionCategory
    )
    return {outline, idMap, idsToKeep}
}

const buildPromptsParams = (
    businessName: string,
    businessType: string,
    additionalInformation: string,
    outline: Record<any, any>,
    sectionCategory?: string
) => {
    const params = {
        'BUSINESS_NAME-e15e6cd0-0aa6-11ee-be56-0242ac120002': businessName,
        'BUSINESS_TYPE-e15e6cd0-0aa6-11ee-be56-0242ac120002': businessType,
        'USERINFO-e15e6cd0-0aa6-11ee-be56-0242ac120002': additionalInformation,
        'OUTLINE-e15e6cd0-0aa6-11ee-be56-0242ac120002': JSON.stringify(outline)
    }
    if (sectionCategory) {
        _.assign(params, {'SECTION-e15e6cd0-0aa6-11ee-be56-0242ac120002': sectionCategory})
    }
    return params
}

const buildMessages = (experiments: Experiment, isFlatStructure: boolean) => {
    if (!isFlatStructure) {
        return [
            {
                role: 'user',
                content: 'userMessage1'
            }
        ]
    }
    return [
        {
            role: 'system',
            content: 'systemMessage1'
        },
        {
            role: 'user',
            content: 'userMessage1'
        }
    ]
}

const getFlatStructureSuggestion = async (
    experimentInstance: Experiment,
    gptPromptVersion: string | null,
    serverFacade: any,
    entityId: string,
    businessType: string,
    businessName: string,
    additionalInformation: string,
    outline: Outline | FlatOutline,
    sectionCategory: string | undefined,
    isFlatStructure: boolean,
    versionOverrides: VersionOverrides | undefined,
    gptParamsVersion: string | null
) => {
    let completionText: string | null
    let usage: PromptUsage
    if (experimentInstance.isOpen('dm_aiStructureInjectionUsePromptHub')) {
        gptPromptVersion = promptHubFlatStructurePromptId
        const completionResponse = await fetchOutlineWithPromptParamsFromPromptHub(
            serverFacade,
            entityId,
            businessType,
            businessName,
            additionalInformation,
            outline,
            gptPromptVersion,
            getPromptHubInjectionParams(
                businessName,
                businessType,
                additionalInformation,
                outline,
                undefined,
                sectionCategory
            )
        )
        completionText = completionResponse.response.generatedTexts[0]
        usage = completionResponse.response.openAiChatCompletionResponse.usage
    } else {
        const versions = getSectionInjectionVersions(experimentInstance, isFlatStructure, versionOverrides)
        gptParamsVersion = versions.paramsVersion
        gptPromptVersion = versions.promptVersion

        const promptParams = buildPromptsParams(
            businessName,
            businessType,
            additionalInformation,
            outline,
            sectionCategory
        )
        const messages = buildMessages(experimentInstance, isFlatStructure)
        const completionResponse = await fetchOutlineWithPromptParamsFromLegacy(
            serverFacade,
            entityId,
            businessType,
            businessName,
            additionalInformation,
            outline,
            gptParamsVersion,
            gptPromptVersion,
            promptParams,
            messages
        )
        completionText = completionResponse.choices[0].text
        usage = completionResponse.usage
    }
    return {gptPromptVersion, completionText: completionText!, usage, gptParamsVersion}
}

const getStructureSuggestion = async (
    experimentInstance: Experiment,
    gptPromptVersion: string | null,
    serverFacade: any,
    entityId: string,
    businessType: string,
    businessName: string,
    additionalInformation: string,
    outline: Outline | FlatOutline,
    sectionCategory: string | undefined,
    language: string | undefined,
    isFlatStructure: boolean,
    versionOverrides: VersionOverrides | undefined,
    gptParamsVersion: string | null
) => {
    let completionText: string | null
    let usage: PromptUsage
    if (experimentInstance.isOpen('dm_aiStructureInjectionUsePromptHub')) {
        gptPromptVersion = promptHubStructurePromptId
        const completionResponse = await fetchOutlineWithPromptParamsFromPromptHub(
            serverFacade,
            entityId,
            businessType,
            businessName,
            additionalInformation,
            outline,
            gptPromptVersion,
            getPromptHubInjectionParams(
                businessName,
                businessType,
                additionalInformation,
                outline,
                undefined,
                sectionCategory,
                language
            )
        )
        completionText = completionResponse.response.generatedTexts[0]
        usage = completionResponse.response.openAiChatCompletionResponse.usage
    } else {
        const versions = getSectionInjectionVersions(experimentInstance, isFlatStructure, versionOverrides)
        gptParamsVersion = versions.paramsVersion
        gptPromptVersion = versions.promptVersion

        const messages = [
            {
                role: 'user',
                content: 'contentUserMessage'
            }
        ]
        if (experimentInstance.isOpen('dm_sg11')) {
            messages.unshift({role: 'system', content: 'contentSystemPrompt'})
        }
        const completionResponse = await fetchOutline(
            serverFacade,
            entityId,
            businessType,
            businessName,
            additionalInformation,
            outline,
            gptParamsVersion,
            gptPromptVersion,
            undefined,
            messages,
            language
        )
        completionText = completionResponse.choices[0].text
        usage = completionResponse.usage
    }
    return {gptPromptVersion, completionText: completionText!, usage, gptParamsVersion}
}

const getSuggestedOutline = async (
    extensionArgs: ExtensionArgs,
    entityId: string,
    businessType: string,
    businessName: string,
    additionalInformation: string,
    content: OutlineWithIdMapForStructure,
    structureType: string,
    sectionCategory?: string,
    versionOverrides?: VersionOverrides,
    language?: string
): Promise<OutlineSuggestionResultForStructure> => {
    const {environmentContext, coreConfig} = extensionArgs
    const {serverFacade} = environmentContext
    const {logger, experimentInstance} = coreConfig
    const {outline, idMap, idsToKeep} = content
    const isFlatStructure = structureType === structureTypes.SECTION

    let resultOutline: Outline | FlatOutline | null = null

    let apiCallCount = 0
    let validationResults: OutlineValidationResults
    let lastError: Error | null = null
    const tokenUsage: any[] = []
    const callsMetadata: CompletionCallMetadata[] = []

    let gptParamsVersion: string | null = null
    let gptPromptVersion: string | null = null

    try {
        while (apiCallCount <= invalidOutlineRetryAmount) {
            let completionText: string | null
            let usage: PromptUsage

            if (isFlatStructure) {
                ;({gptPromptVersion, completionText, usage, gptParamsVersion} = await getFlatStructureSuggestion(
                    experimentInstance,
                    gptPromptVersion,
                    serverFacade,
                    entityId,
                    businessType,
                    businessName,
                    additionalInformation,
                    outline,
                    sectionCategory,
                    isFlatStructure,
                    versionOverrides,
                    gptParamsVersion
                ))
            } else {
                ;({gptPromptVersion, completionText, usage, gptParamsVersion} = await getStructureSuggestion(
                    experimentInstance,
                    gptPromptVersion,
                    serverFacade,
                    entityId,
                    businessType,
                    businessName,
                    additionalInformation,
                    outline,
                    sectionCategory,
                    language,
                    isFlatStructure,
                    versionOverrides,
                    gptParamsVersion
                ))
            }
            apiCallCount += 1
            tokenUsage.push(usage)

            try {
                let parsedOutline = parsePaddedJSON(completionText!)
                if (!isFlatStructure) {
                    parsedOutline = getNewOutlineFromJSON(parsedOutline)
                }
                resultOutline = parsedOutline
            } catch (e) {
                callsMetadata.push({
                    isBadJson: true
                })
                lastError = new ReportableError({
                    errorType: aiContentBadJsonErrorType,
                    message: aiContentBadJsonErrorMessage,
                    extras: {
                        completionText,
                        entityId,
                        businessType,
                        businessName,
                        additionalInformation
                    }
                })
                continue
            }
            if (isFlatStructure) {
                validationResults = validateFlatOutlineSuggestion(resultOutline! as FlatOutline, outline as FlatOutline)
            } else {
                validationResults = validateOutlineSuggestion(resultOutline! as Outline, outline as Outline)
            }

            callsMetadata.push({
                isBadJson: false,
                textCounters: validationResults.counters
            })

            if (validationResults.isValid) {
                break
            } else if (apiCallCount > 1) {
                reportOutlineBIError(
                    logger,
                    aiContentOutlineResultConsecutiveErrorType,
                    aiContentOutlineResultConsecutiveErrorMessage,
                    resultOutline!,
                    entityId,
                    businessType,
                    businessName,
                    additionalInformation,
                    validationResults
                )
            } else {
                reportOutlineBIError(
                    logger,
                    aiContentOutlineResultErrorType,
                    aiContentOutlineResultErrorMessage,
                    resultOutline!,
                    entityId,
                    businessType,
                    businessName,
                    additionalInformation,
                    validationResults
                )
            }
        }

        if (!resultOutline) {
            throw (
                lastError ??
                new ReportableError({
                    errorType: aiContentUnexpectedError,
                    message: aiContentUnexpectedErrorMessage
                })
            )
        }

        return {
            outline: resultOutline!,
            idMap,
            idsToKeep,
            validationResults: validationResults!,
            originalOutline: outline,
            retries: apiCallCount - 1,
            completionMetadata: {
                gptParamsVersion: gptParamsVersion ?? nonApplicableParamsVersion,
                promptsVersion: gptPromptVersion!,
                tokenUsage
            }
        }
    } catch (e: any) {
        // ensure that tokenUsage is always in the error
        const reportedError = getReportableFromError(e, {
            errorType: e.errorType ?? aiContentUnexpectedError,
            tags: {
                aiContentInjection: true
            },
            extras: {tokenUsage}
        })
        logger.captureError(reportedError)

        throw reportedError
    } finally {
        logger.interactionEnded(aiPageSuggestionInteraction, {
            extras: {
                entityId,
                tokenUsage,
                outline,
                resultOutline,
                completionCalls: callsMetadata
            }
        })
    }
}

const safeGetSuggestion = async (
    extensionArgs: ExtensionArgs,
    entityId: string,
    outline: OutlineWithIdMapForStructure,
    businessType: string,
    businessName: string,
    additionalInformation: string,
    structureType: string,
    compStructure: SerializedCompStructure,
    sectionCategory?: string,
    versionOverrides?: VersionOverrides,
    language?: string
): Promise<EntitySuggestionResult> => {
    try {
        const contentSuggestion = await getSuggestedOutline(
            extensionArgs,
            entityId,
            businessType,
            businessName,
            additionalInformation,
            outline,
            structureType,
            sectionCategory,
            versionOverrides,
            language
        )
        return {entityId, contentSuggestion, compStructure}
    } catch (e: unknown) {
        return {entityId, error: e as Error, compStructure}
    }
}
export interface ExtensionArgs {
    pointers: Pointers
    extensionAPI: ExtensionAPI
    environmentContext: EnvironmentContext
    coreConfig: CoreConfig
}
export interface VersionOverrides {
    paramsVersionOverride?: string
    promptVersionOverride?: string
}
export const getSuggestedOutlineByStructure = async (
    extensionArgs: ExtensionArgs,
    businessType: string,
    businessName: string,
    additionalInformation: string,
    compStructure: SerializedCompStructure,
    sectionCategory?: string,
    versionOverrides?: VersionOverrides
): Promise<EntitySuggestionResult> => {
    const structureType = structureTypes.SECTION
    const clonedStructure = deepClone(compStructure)
    const structureOutline = getOutlineByStructure(extensionArgs, clonedStructure, structureType, sectionCategory)
    return safeGetSuggestion(
        extensionArgs,
        clonedStructure.id!,
        structureOutline,
        businessType,
        businessName,
        additionalInformation,
        structureType,
        clonedStructure,
        sectionCategory ?? '',
        versionOverrides
    )
}

export const getSuggestedPageOutlineByStructure = async (
    extensionArgs: ExtensionArgs,
    businessType: string,
    businessName: string,
    additionalInformation: string,
    compStructure: SerializedCompStructure,
    versionOverrides?: VersionOverrides,
    language?: string
): Promise<EntitySuggestionResult> => {
    const structureType = structureTypes.PAGE
    const clonedPage = deepClone(compStructure)
    const structureOutline = getOutlineByStructure(extensionArgs, clonedPage, structureType)
    return safeGetSuggestion(
        extensionArgs,
        clonedPage.id!,
        structureOutline,
        businessType,
        businessName,
        additionalInformation,
        structureType,
        clonedPage,
        '',
        versionOverrides,
        language
    )
}

const validateContentLengthForStructure = (
    extensionArgs: ExtensionArgs,
    componentType: string,
    dataItem: Record<string, any>,
    newContent: string
): boolean => {
    const {coreConfig} = extensionArgs
    const {logger} = coreConfig

    const currentContent = getContentForComponent(componentType, dataItem)?.value
    const res = validateContentLengthInternal(currentContent, newContent)
    if (!res) {
        logger.captureError(
            new ReportableError({
                errorType: aiContentTooLongErrorType,
                message: aiContentTooLongErrorMessage,
                extras: {
                    dataItem: JSON.stringify(dataItem),
                    componentType,
                    currentContent,
                    newContent
                }
            })
        )
    }
    return res
}

const applyOutlinesToStructureInternal = (
    extensionArgs: ExtensionArgs,
    compStructure: SerializedCompStructure,
    section: any,
    compIdToName: Record<string, string>,
    idsToKeep: Record<string, string>
): void => {
    const {extensionAPI} = extensionArgs
    const {serializedStructure} = extensionAPI as SerializedStructureExtensionAPI
    const {coreConfig} = extensionArgs
    const {logger} = coreConfig
    const updateContent = (id: string, dataItem: Record<string, any>) => {
        const fieldName = compIdToName[id]
        if (fieldName) {
            const content = section[fieldName]
            if (!content) {
                logger.captureError(
                    new ReportableError({
                        errorType: aiContentMissingOnOutlineErrorType,
                        message: aiContentMissingOnOutlineMessage,
                        extras: {
                            fieldName,
                            compStructure
                        }
                    })
                )
            }
            const componentType = serializedStructure.getComponentType(compStructure)
            if (content && validateContentLengthForStructure(extensionArgs, componentType, dataItem, content)) {
                updateDataItemValue(dataItem, componentType, content)
            }
        }
    }
    const dataItem = serializedStructure.getComponentByNamespace(compStructure, 'data')
    const compStructureId = serializedStructure.getComponentId(compStructure)

    if (dataItem && dataItem.type === serializedRepeaterDataItemType) {
        const dataItemsOverrides = getComponentsDataItemAndOverrides(dataItem)
        _.forEach(dataItemsOverrides, (dataItemOverride: Record<string, any>) => {
            const idSuffix = dataItemOverride.overrideId
            const idOnOutline = getOverrideId(compStructureId!, idSuffix)
            updateContent(idOnOutline, dataItemOverride.item)
        })
    } else {
        updateContent(compStructureId!, dataItem)
    }
    const children = compStructure.components ?? []
    const id = serializedStructure.getComponentId(compStructure)
    if (id && !idsToKeep[id]) {
        delete compStructure.id
    }
    if (_.isEmpty(children)) {
        return
    }
    for (const childStructure of children) {
        applyOutlinesToStructureInternal(
            extensionArgs,
            childStructure as SerializedCompStructure,
            section,
            compIdToName,
            idsToKeep
        )
    }
}
export const applyOutlineToStructure = (
    extensionArgs: ExtensionArgs,
    outlineWithIds: OutlineWithIdMapForStructure,
    compStructure: SerializedCompStructure
): SerializedCompStructure => {
    if (!outlineWithIds) {
        throw new ReportableError({
            errorType: aiContentMissingOnOutlineErrorType,
            message: aiStructureMissingOutlineMessage,
            extras: {
                compStructure
            }
        })
    }
    const {outline, idMap} = outlineWithIds
    const compIdToName = _.invert(idMap)
    applyOutlinesToStructureInternal(extensionArgs, compStructure, outline, compIdToName, {})
    return compStructure
}

export const applyOutlineToPageStructure = (
    extensionArgs: ExtensionArgs,
    outlineWithIds: OutlineWithIdMapForStructure,
    compStructure: SerializedCompStructure
): SerializedCompStructure => {
    if (!outlineWithIds) {
        throw new ReportableError({
            errorType: aiContentMissingOnOutlineErrorType,
            message: aiStructureMissingOutlineMessage,
            extras: {
                compStructure
            }
        })
    }
    const {outline, idMap, idsToKeep} = outlineWithIds
    const compIdToName = _.invert(idMap)
    const flattenOutline = Object.assign({}, ..._.values(outline))
    applyOutlinesToStructureInternal(extensionArgs, compStructure, flattenOutline, compIdToName, idsToKeep ?? {})
    return compStructure
}
