import type {
    CreateExtArgs,
    DmApis,
    DocumentDataTypes,
    Extension,
    ExtensionAPI,
    PointerMethods,
    DmStore,
    ModelsInitializationType
} from '@wix/document-manager-core'
import _ from 'lodash'

const dataType = 'serviceTopology'
const createPointersMethods = (): PointerMethods => {
    const getServiceTopology = () => ({id: dataType, type: dataType})
    return {
        serviceTopology: {
            getServiceTopology
        },
        general: {
            getServiceTopology
        }
    }
}

const getDocumentDataTypes = (): DocumentDataTypes => ({[dataType]: {}})

export interface STExtApi extends ExtensionAPI {
    serviceTopology: {
        getServiceTopology(): any
        getServiceRoot(path: string[]): string | undefined
    }
}

const createExtensionAPI = ({dal, pointers}: CreateExtArgs): STExtApi => ({
    serviceTopology: {
        getServiceTopology: (): any => dal.get(pointers.serviceTopology.getServiceTopology()),
        getServiceRoot: (path: string[]): string | undefined => {
            const topology = dal.get(pointers.serviceTopology.getServiceTopology())
            const result = _.get(topology, path)
            if (result && _.isString(result)) {
                return result
            }
        }
    }
})

const initializeModels = (initialStore: DmStore, initialModels: ModelsInitializationType, {pointers}: DmApis) => {
    const {serviceTopology} = initialModels
    initialStore.set(pointers.serviceTopology.getServiceTopology(), serviceTopology)
}
const createExtension = (): Extension => ({
    name: 'serviceTopology',
    createPointersMethods,
    initializeModels,
    getDocumentDataTypes,
    createExtensionAPI
})

export type ServiceTopology = Record<string, string>

export {createExtension}
