/**
 * This module use for add some specific conversion data e.g. some calculated values from desktop properties,
 * specific values calculated on the fly, during merge/optimize runs
 * */
import type {CompStructure, PS} from '@wix/document-services-types'
import _ from 'lodash'
import buttonConversionData from './buttonConversionData'
import vectorImageConversionData from './vectorImageConversionData'
import wRichTextConversionData from './wRichTextConversionData'

const vectorImageHandlerWrapper = (ps: PS, component: CompStructure) =>
    vectorImageConversionData.getVectorImageConversionData(component)

const specificComponentHandlers = {
    'wixui.StylableButton': buttonConversionData.getButtonConversionData,
    'wysiwyg.viewer.components.SiteButton': buttonConversionData.getButtonConversionData,
    'wysiwyg.viewer.components.VectorImage': vectorImageHandlerWrapper,
    'wysiwyg.viewer.components.WPhoto': vectorImageHandlerWrapper,
    'wysiwyg.viewer.components.ClipArt': vectorImageHandlerWrapper,
    'wysiwyg.viewer.components.WRichText': wRichTextConversionData.getWRichTextConversionData
}

function getComponentSpecificConversionData(ps: PS, component: CompStructure, pageId: string): any {
    const componentConversionDataHandler = _.get(specificComponentHandlers, component.componentType, _.noop)
    // @ts-expect-error
    return componentConversionDataHandler(ps, component, pageId) || null
}

export default {
    getComponentSpecificConversionData
}
