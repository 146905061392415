import type {PublicMethodUtils} from '@wix/document-services-types'
import {componentStylesAndSkinsAPI, idGenerator, refComponent} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    const {
        defineGetter,
        deprecate,
        actions: {dataManipulation}
    } = publicMethodUtils

    return {
        methods: {
            components: {
                style: {
                    getId: defineGetter(componentStylesAndSkinsAPI.style.getId),
                    get: defineGetter(componentStylesAndSkinsAPI.style.get),
                    update: dataManipulation(componentStylesAndSkinsAPI.style.update, {
                        isUpdatingAnchors: refComponent.shouldUpdateAnchors
                    }),

                    setCustom: deprecate(
                        dataManipulation(componentStylesAndSkinsAPI.style.setCustom, {
                            getReturnValue: idGenerator.getStyleIdToAdd
                        }),
                        'This API is deprecated. Please either use components.style.update or components.style.fork instead'
                    ),
                    connectToTheme: dataManipulation(componentStylesAndSkinsAPI.style.connectToThemeStyle),
                    fork: dataManipulation(componentStylesAndSkinsAPI.style.fork),
                    scoped: {
                        remove: dataManipulation(componentStylesAndSkinsAPI.style.removeScoped)
                    }
                }
            }
        }
    }
}
