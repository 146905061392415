import * as dmUtils from '@wix/document-manager-utils'
import {HttpRequestError, saveErrorCodes, searchRequestId} from '@wix/document-manager-utils'
import _ from 'lodash'
import errorConstants from '../errors/errors'
import {staticCodeMapping} from './saveTasks/saveDocumentBase'
import type {CSaveApi} from '@wix/document-manager-extensions'
import type {PS} from '@wix/document-services-types'

const presaveOperationsRejectedMsg = 'DocumentServices presave operations were rejected by server.'
const saveDisabledMsg = 'DocumentServices was created with parameter disableSave=true, so save is disabled.'
const saveInProgressMsg = 'Save is already in progress. The last save request is being ignored.'

/**
 * information about the failure of a specific service within the process
 */
export interface ErrorInfo {
    errorInfo?: any
    errorCode: number | string
    errorType: string
    errorDescription: string
    requestId?: string
}

/**
 * A map of 'serviceName: errorInfo'
 */
// export type FailInfo = Record<string, ErrorInfo>
export interface DSError {
    documentServices?: ErrorInfo
    document?: ErrorInfo
    preSaveAsTemplateOperation?: Error
    preSaveOperation?: Error
    isPresaveOperationsRejected?: boolean
}

const error = (
    errorType: string,
    errorDescription: string,
    errorCode: number | string,
    isPresaveOperationsRejected?: boolean
): DSError => ({
    documentServices: {
        errorCode,
        errorType,
        errorDescription
    },
    ...(isPresaveOperationsRejected ? {isPresaveOperationsRejected} : {})
})

const USER_NOT_AUTHORIZED_FOR_SITE = error(
    errorConstants.save.USER_NOT_AUTHORIZED_FOR_SITE,
    saveDisabledMsg,
    'USER_NOT_AUTHORIZED_FOR_SITE'
)
const SAVE_DISABLED_IN_DOCUMENT_SERVICES = error(
    errorConstants.save.SAVE_DISABLED_IN_DOCUMENT_SERVICES,
    saveDisabledMsg,
    dmUtils.clientSaveErrors.SAVE_DISABLED_IN_DOCUMENT_SERVICES
)
const SAVE_IN_PROGRESS = error(
    errorConstants.save.SAVE_IN_PROGRESS,
    saveInProgressMsg,
    dmUtils.clientSaveErrors.SAVE_IN_PROGRESS
)

const createPresaveError = (ps: PS, e: any) => {
    const extensionAPI = ps.extensionAPI as CSaveApi
    const userInfo = ps.dal.get(ps.pointers.documentServicesModel.getUserInfo())
    const dsError = extensionAPI.continuousSave.cSaveErrors.convertToDSError(e, userInfo) as DSError
    const requestId = e.extras?.requestId || e.reason?.extras?.requestId
    if (requestId) {
        dsError.document.requestId = requestId
    }
    if (!dsError.document?.errorDescription) {
        const msg = e.message || e.details?.applicationError?.code || presaveOperationsRejectedMsg
        dsError.document.errorDescription = msg
    }
    dsError.isPresaveOperationsRejected = true
    return dsError
}

function isSaveValidationError(errorDescription: DSError) {
    return errorDescription?.document?.errorCode === -10104
}

export const getErrorDetails = (e: DSError) => {
    return e.document ?? e.documentServices
}

function normalizeError(e: DSError) {
    const details = getErrorDetails(e)
    if (!details) {
        e.documentServices = {
            errorCode: dmUtils.clientSaveErrors.UNKNOWN_SERVER_ERROR,
            errorDescription: 'unknown save error',
            errorType: 'unknownSaveError'
        }
        return e
    }
    details.errorCode = details.errorCode ?? dmUtils.clientSaveErrors.UNKNOWN_SERVER_ERROR
    details.errorDescription = details.errorDescription ?? 'unknown save error'
    details.errorType = details.errorType ?? 'unknownSaveError'
    return e
}

export const getErrorTypeAndMessage = (e: DSError): {errorType: string; message: string} => {
    return {
        errorType: getErrorDetails(e)?.errorType ?? 'unknownSaveError',
        message: getErrorDetails(e)?.errorDescription ?? 'unknown save error'
    }
}

export function convertToDSError(e: string | DSError): DSError {
    if (_.isString(e)) {
        return {
            documentServices: {
                errorCode: '',
                errorType: e,
                errorDescription: e
            }
        }
    }
    return e
}

const getSiteDeleted = (errorDescription: string, requestId: string): ErrorInfo => ({
    errorCode: saveErrorCodes.SITE_DELETED,
    errorType: errorConstants.save.SITE_DELETED,
    errorDescription,
    requestId
})

const codeToType = errorCode => (errorCode in staticCodeMapping ? staticCodeMapping[errorCode] : errorCode)

export const convertHttpError = async (e: any /*Error | Response | ServerErrorData*/): Promise<ErrorInfo> => {
    if (e instanceof HttpRequestError) {
        if (e.status === 404) {
            return getSiteDeleted(e.message, e.extras.requestId)
        }
    }
    // if (e instanceof ServerError) {
    //     const errorCode = e.result?.details?.applicationError?.code
    //     const errorDescription = e.result?.details?.applicationError?.description
    //     return {
    //         errorCode,
    //         errorType: errorCode,
    //         errorDescription,
    //         requestId: e.extras.requestId
    //     }
    // }
    if (e.details?.applicationError?.code /* || e instanceof ServerError*/) {
        const errorCode = e.details?.applicationError?.code
        const errorDescription = e.details?.applicationError?.description
        return {
            errorCode,
            errorType: codeToType(errorCode),
            errorDescription,
            requestId: e.extras.requestId || searchRequestId(e)
        }
    }
    if (e.status === 404) {
        return getSiteDeleted(e.statusText, searchRequestId(e))
    }
    if (e.headers?.get('Content-Type') === 'application/json') {
        try {
            const json = await e.json()
            if ('details' in json) {
                return {
                    errorCode: json.details?.applicationError?.code,
                    errorType: codeToType(json.details?.applicationError?.code),
                    errorDescription: json.details?.applicationError?.description,
                    requestId: searchRequestId(e)
                }
            }
            return {
                errorCode: json.errorCode,
                errorType: codeToType(json.errorCode),
                errorDescription: json.errorDescription,
                requestId: searchRequestId(e)
            }
        } catch (e1) {
            // ignore
        }
    }

    return {
        errorCode: e.status,
        errorType: errorConstants.save.HTTP_REQUEST_ERROR,
        errorDescription: e.statusText,
        requestId: searchRequestId(e)
    }
}

export default {
    USER_NOT_AUTHORIZED_FOR_SITE,
    SAVE_DISABLED_IN_DOCUMENT_SERVICES,
    SAVE_IN_PROGRESS,
    createPresaveError,
    isSaveValidationError,
    normalizeError
}
