import _ from 'lodash'
import type {DAL, DalValue} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import {extensions, constants} from '@wix/document-manager-extensions'
import {deepClone} from '@wix/wix-immutable-proxy'

const {VIEW_MODES, DATA_TYPES, MULTILINGUAL_TYPES} = constants

type Relationships = extensions.relationships.RelationshipsAPI['relationships']

type PtrValPredicate = (pointer: Pointer, value: DalValue) => boolean

const samePointer = (p1: Pointer, p2: Pointer) => p1.type === p2.type && p1.id === p2.id

const pointerToStr = (pointer: Pointer) => `${pointer.type} : ${pointer.id}`

const propertiesToDisplay = {
    type: null,
    compType: (val: DalValue) => _.last(val?.componentType?.split('.')),
    page: (val: DalValue) => val?.metaData?.pageId,
    name: null,
    menuType: null,
    label: null,
    target: null,
    title: null,
    min: null,
    max: null,
    styleType: null
}

const namespaceColors = {
    [VIEW_MODES.DESKTOP]: 'khaki1',
    [VIEW_MODES.MOBILE]: 'cyan',
    [DATA_TYPES.data]: 'sandybrown',
    [DATA_TYPES.theme]: 'orchid1',
    [DATA_TYPES.prop]: 'darkseagreen3',
    [DATA_TYPES.design]: 'bisque',
    [MULTILINGUAL_TYPES.multilingualTranslations]: 'aquamarine',
    [DATA_TYPES.variants]: 'pink'
}

interface GraphArgs {
    excludeTypes: string[]
    filter: PtrValPredicate
    maxNodes: number
    maxUpDistance: number
    maxDownDistance: number
    upDown: boolean
    shortenIds: boolean
}

export type OptionalGraphArgs = {
    [K in keyof GraphArgs]?: GraphArgs[K]
}

class Node {
    public readonly id: string
    private shortPointer: Pointer

    constructor(private readonly pointer: Pointer) {
        this.id = pointerToStr(pointer)
        this.shortPointer = {...pointer}
    }

    setShortId(shortId: string) {
        this.shortPointer.id = shortId
    }

    getShortPointer() {
        return this.shortPointer
    }

    getPointer() {
        return this.pointer
    }

    getNodeLabel = (dal: DAL, sourcePointers: Pointer[]) => {
        const val = dal.get(this.pointer)
        const label = this.getLabel(val)
        const color = this.getColor(val, sourcePointers)
        return `"${this.id}" [label=<${label}> fillcolor="${color}"]`
    }

    private getColor(val: DalValue, sourcePointers: Pointer[]) {
        if (!val) {
            return 'red'
        } else if (sourcePointers.find(sourcePtr => samePointer(sourcePtr, this.pointer))) {
            return 'lime'
        }
        return namespaceColors[this.pointer.type] ?? 'aliceblue'
    }

    private getLabel(val: DalValue): string {
        const {type} = this.pointer
        let label = `{${type} : ${this.shortPointer.id}`
        if (label.length > 25) {
            label = `{${type} :<BR/>${this.shortPointer.id}`
        }
        Object.entries(propertiesToDisplay).forEach(([key, fn]) => {
            const value = fn?.(val) ?? val?.[key]
            if (value) {
                label += `|${key}: ${value}`
            }
        })
        label += '}'
        return label
    }
}

class Edge {
    public readonly from: Node
    public readonly to: Node
    constructor(from: Pointer, to: Pointer) {
        this.from = new Node(from)
        this.to = new Node(to)
    }
    getDotDef(relationships: Relationships): string {
        const {from, to} = this
        const originalFrom = from.getPointer()
        const originalTo = to.getPointer()
        const owningRefs = relationships.getOwningReferencesToPointer(originalTo)
        const attr = owningRefs.find(ref => samePointer(ref, originalFrom)) ? '' : '[color = "red"]'
        return `"${from.id}" -> "${to.id}" ${attr}`
    }
}

class Graph {
    private readonly edges: Edge[] = []
    private readonly nodes = new Map<string, Node>()

    private includePointerInGraph(dal: DAL, pointer: Pointer, excludeTypes: string[], filter: PtrValPredicate) {
        return !excludeTypes.includes(pointer.type.toLowerCase()) && filter(pointer, dal.get(pointer))
    }

    constructor(dal: DAL, relationships: Relationships, sourcePointers: Pointer[], args: GraphArgs) {
        const {filter, maxNodes, maxDownDistance, upDown} = args
        let {excludeTypes, maxUpDistance} = args
        maxUpDistance = -maxUpDistance
        excludeTypes = excludeTypes.map(t => t.toLowerCase())
        interface QueueItem {
            pointer: Pointer
            distance: number
        }
        const queue: QueueItem[] = sourcePointers.map(pointer => ({pointer, distance: 0}))
        const marked = new Set()
        while (queue.length && this.nodes.size < maxNodes) {
            const {pointer: from, distance}: QueueItem = queue.shift()!
            if (!this.includePointerInGraph(dal, from, excludeTypes, filter)) {
                continue
            }
            const s = pointerToStr(from)
            if (!marked.has(s)) {
                marked.add(s)
                const referred: Pointer[] = dal.has(from) ? relationships.getReferredPointers(from, false) : []
                if (distance < maxDownDistance) {
                    referred.forEach((to: Pointer) => {
                        if (distance < 0 && !upDown && !marked.has(pointerToStr(to))) {
                            return
                        }
                        if (this.includePointerInGraph(dal, to, excludeTypes, filter)) {
                            queue.push({pointer: to, distance: distance + 1})
                            const edge = new Edge(from, to)
                            this.edges.push(edge)
                            this.nodes.set(edge.from.id, edge.from)
                            this.nodes.set(edge.to.id, edge.to)
                        }
                    })
                }
                const upDistance = Math.min(0, distance)
                if (upDistance > maxUpDistance) {
                    relationships.getReferencesToPointer(from).forEach((ref: Pointer) => {
                        if (!samePointer(ref, from) && this.includePointerInGraph(dal, ref, excludeTypes, filter)) {
                            queue.push({pointer: ref, distance: upDistance - 1})
                        }
                    })
                }
            }
        }
    }

    getNodeLabels(dal: DAL, sourcePointers: Pointer[]): string[] {
        const labels = {}
        const nodes = {}
        this.nodes.forEach(node => {
            const val = deepClone(dal.get(node.getPointer()))
            nodes[node.id] = val
            labels[node.id] = node.getNodeLabel(dal, sourcePointers)
        })
        console.log(nodes)
        return Object.values(labels)
    }

    getEdgeDefs(relationships: Relationships) {
        return this.edges.map((edge: Edge) => edge.getDotDef(relationships))
    }

    getNodes(): Node[] {
        return [...this.nodes.values()]
    }

    shortenIds() {
        const idMap = {}
        const reverseIdMap = {}
        let idCount = 0
        this.edges.forEach(edge => {
            ;[edge.from, edge.to].forEach((node: Node) => {
                const {type, id} = node.getShortPointer()
                if (!reverseIdMap[id] && id.includes('-') && type !== MULTILINGUAL_TYPES.multilingualTranslations) {
                    if (!idMap[id]) {
                        idMap[id] = `id${idCount++}`
                        reverseIdMap[idMap[id]] = id
                    }
                    node.setShortId(idMap[id])
                }
            })
        })
        this.edges.forEach(edge => {
            ;[edge.from, edge.to].forEach((node: Node) => {
                const {type, id} = node.getShortPointer()
                if (type === MULTILINGUAL_TYPES.multilingualTranslations && !reverseIdMap[id]) {
                    Object.keys(idMap).forEach(originalId => {
                        if (id !== originalId && id.includes(originalId)) {
                            idMap[id] = id.replace(originalId, idMap[originalId])
                            reverseIdMap[idMap[id]] = id
                            node.setShortId(idMap[id])
                        }
                    })
                }
            })
        })
        console.log(reverseIdMap)
    }
}

/**
 * This graphing module uses the Graphviz open source graph visualization software
 * https://graphviz.org/
 * @param pointer
 * @param dal
 * @param relationships
 * @param optionalArgs
 */
export const graphComponents = (
    dal: DAL,
    relationships: Relationships,
    pointers: Pointer | Pointer[],
    optionalArgs: OptionalGraphArgs
): void => {
    const defaultOptionalArgs: GraphArgs = {
        excludeTypes: [],
        filter: () => true,
        maxUpDistance: 1,
        maxDownDistance: 10,
        maxNodes: 100,
        upDown: true,
        shortenIds: false
    }
    const args = _.mapValues(defaultOptionalArgs, (val, key) => optionalArgs[key] ?? val)
    if (!Array.isArray(pointers)) {
        pointers = [pointers]
    }
    console.log(
        `%cGraph Options:\n    ${Object.entries(args)
            .map(e => `${e[0]}: ${e[1]}`)
            .join('\n    ')}`,
        'font-size: 1.2em; color: lightblue'
    )
    const graph = new Graph(dal, relationships, pointers, args)
    if (args.shortenIds) {
        graph.shortenIds()
    }
    const edgeDefs = graph.getEdgeDefs(relationships)
    const nodeLabels = graph.getNodeLabels(dal, pointers)
    const html = `<!DOCTYPE html>
        <meta charset="utf-8">
        <body>
        <script src="https://d3js.org/d3.v7.min.js"></script>
        <script src="https://unpkg.com/@hpcc-js/wasm@1.12.6/dist/index.min.js"></script>
        <script src="https://unpkg.com/d3-graphviz@4.0.0/build/d3-graphviz.js"></script>
        <div id="graph" style="text-align: center;"></div>
        <script>
          const dot = [
            \'digraph {\',
               \'node [style="filled", shape="record", fillcolor="aliceblue", fontname="Arial"]\',
               \'edge [arrowhead="normal", color="blue", penwidth="2"]\',
               \'${nodeLabels.join(' ')}\',
               \'${edgeDefs.join(' ')}\',
            \'}\',
          ].join(" ");
          d3.select("#graph").graphviz().renderDot(dot)
        </script>`

    const tab = window.open('', '_blank')!
    tab.document.open()
    tab.document.write(html)
    tab.document.close()
}
