/* eslint-disable promise/prefer-await-to-then */
import * as platformAppDefIds from '@wix/app-definition-ids'
import type {
    AppDefinitionId,
    AppInstanceSessionInstances,
    ApplicationId,
    Callback,
    ComponentRemovedHookFunc,
    Pointer,
    PS,
    DataItem,
    CompRef
} from '@wix/document-services-types'
import * as platformEvents from '@wix/platform-editor-sdk/lib/platformEvents.min'
import experiment from 'experiment-amd'
import _ from 'lodash'
import hooks from '../hooks/hooks'
import clientSpecMap from '../siteMetadata/clientSpecMap'
import clientSpecMapService from '../tpa/services/clientSpecMapService'
import permissionsUtils from '../tpa/utils/permissionsUtils'
import provisionUtils from '../tpa/utils/provisionUtils'
import constants from './common/constants'
import componentAddedToStageCompatability from './componentAddedToStageCompatability'
import connectedComponentsHooks from './hooks/connectedComponentsHooks'
import platformHooks from './hooks/platformHooks'
import livePreview from './livePreview/livePreview'
import platformPages from './pages'
import notificationService from './services/notificationService'
import originService from './services/originService'
import platformAppDataGetter from './services/platformAppDataGetter'
import platformEventsService from './services/platformEventsService'
import platformStateService from './services/platformStateService'
import sdkAPIService from './services/sdkAPIService'
import workerService from './services/workerService'
import appControllerUtils from '../appControllerData/appControllerUtils'
import type {EventType, PageRef, PlatformEvent} from '@wix/platform-editor-sdk'
import {loadRemoteDataForApp} from './services/loadRemoteWidgetMetaData'
import {ReportableError} from '@wix/document-manager-utils'
import componentStructureInfo from '../component/componentStructureInfo'
import {
    CONTROLLER_TYPE,
    MOBILE_WIDGET_BUILDER_BRANCH_ID,
    APPS_TO_LOAD_ON_MOBILE_BUILDER
} from '@wix/document-manager-extensions/src/constants/constants'
import structure from '../structure/structure'
import {FEATURE_DATA_HOOKS} from '@wix/document-manager-extensions/src/hooks'
import type {FeatureDataUpdatedEvent} from '@wix/document-manager-extensions/src/extensions/dataModel/hooks'
import {registerHookWithPs} from '../hooks/eventHooks/eventHooks'

function init(ps: PS, api?, config?, options?) {
    if (!ps.runtimeConfig.supportsPlatformInitialization) {
        return
    }
    const wasWorkerInitiated = workerService.isInitiated()
    originService.setOrigin(_.get(options, 'origin'))
    initBasePaths(ps)
    platformEventsService.init(ps)
    const worker = workerService.init(ps, api, config, options)
    clientSpecMapService.registerToClientSpecMapOnLoad(ps, loadEditorApps)
    ps.siteAPI.registerToNotifyApplicationRequestFromViewerWorker((appDefinitionId, data) => {
        notificationService.notifyApplication(ps, appDefinitionId, data)
    })
    ps.siteAPI.registerToAppInstanceUpdate(appInstanceMap => {
        updateClientSpecMap(ps, appInstanceMap)
    })
    if (!wasWorkerInitiated) {
        registerConnectedComponentHooks()
        registerPlatformHooks(ps)
    }

    ps.extensionAPI.platformSharedState.subscribeToManifestWasChanges((appDefinitionId: string) => {
        const appata = platformAppDataGetter.getAppDataByAppDefId(ps, appDefinitionId)
        workerService.loadManifest(ps, appata)
    })
    ps.extensionAPI.platformSharedState.subscribeToPendingAppsChanges((appsToGrant: any, appsToRemove: any) => {
        appsToGrant.forEach(app => workerService.notifyGrantApp(ps, app))
        appsToRemove.forEach(app => workerService.notifyRevokeApp(ps, app))
    })
    ps.extensionAPI.theme.onChange.onThemeChangeAddListener(({type, values}) => {
        notifyAppsOnCustomEvent(ps, platformEvents.factory.themeChanged({changeType: type, values}))
    })
    _.forEach(clientSpecMap.getAppsData(ps), csmEntry => {
        if (clientSpecMapService.isAppActive(ps, csmEntry)) {
            loadRemoteDataForApp(ps, csmEntry, 'editorLoad').catch(err => {
                console.log(`Failed to load remote meta data for ${csmEntry.appDefinitionId}: ${err.message}`)
            })
        }
    })
    return worker
}

function initialize(ps: PS) {
    if (!ps.runtimeConfig.supportsPlatformInitialization) {
        return
    }
    if (livePreview.isActive(ps)) {
        livePreview.autoRun(ps)
    }

    if (experiment.isOpen('dm_documentOperationError')) {
        ps.setOperationsQueue.registerToErrorThrown(({appDefinitionId, error, methodName}) => {
            if (!appDefinitionId) {
                return
            }

            if (!clientSpecMap.hasCSMEntry(ps, appDefinitionId)) {
                return
            }

            notifyApplication(
                ps,
                appDefinitionId,
                // @ts-ignore
                platformEvents.factory.documentOperationError({
                    error: {
                        name: error.name,
                        message: error.message,
                        stack: error.stack
                    },
                    methodName
                })
            )
        })
    }
}

function updateClientSpecMap(ps: PS, appInstanceSessionInstances: AppInstanceSessionInstances) {
    _.forEach(appInstanceSessionInstances.results.instances, (instance: string, appDefinitionId: AppDefinitionId) => {
        clientSpecMap.updateAppInstance(ps, appDefinitionId, instance)
        notificationService.notifyApplication(ps, appDefinitionId, platformEvents.factory.instanceChanged(instance))
    })
    _.attempt(ps.dal.commitTransaction)
}

function registerConnectedComponentHooks() {
    hooks.registerHook(hooks.HOOKS.METADATA.DUPLICATABLE, connectedComponentsHooks.isDuplicatable)
    hooks.registerHook(hooks.HOOKS.METADATA.CAN_REPARENT, connectedComponentsHooks.canReparent)
    hooks.registerHook(hooks.HOOKS.METADATA.ROTATABLE, connectedComponentsHooks.isRotatable)
    hooks.registerHook(hooks.HOOKS.METADATA.FIXED_POSITION, connectedComponentsHooks.canBeFixedPosition)
    hooks.registerHook(hooks.HOOKS.METADATA.RESIZABLE_SIDES, connectedComponentsHooks.isResizableSides)
    hooks.registerHook(hooks.HOOKS.METADATA.LAYOUT_LIMITS, connectedComponentsHooks.layoutLimitsHook)
    hooks.registerHook(hooks.HOOKS.METADATA.CONTAINABLE, connectedComponentsHooks.isContainable)
}

const onSiteSaved = (ps: PS, isFirstSave: boolean) => {
    if (isFirstSave) {
        ps.siteAPI.reloadAppsContainer()
        platformHooks.notifyOnFirstSaved(ps, getInstalledEditorApps(ps), notificationService.notifyApplication)
    }

    notifyAppsOnCustomEvent(ps, platformEvents.factory.siteWasSaved({isAutosave: false}))

    Object.values(clientSpecMapService.getAppsData(ps))
        .filter(appData => clientSpecMapService.isAppPermissionsIsRevoked(appData))
        .forEach(appData => platformStateService.clearAppPendingAction(ps, _.get(appData, 'appDefinitionId')))
}

function registerPlatformHooks(ps: PS) {
    hooks.registerHook(hooks.HOOKS.PLATFORM.APP_UPDATED, platformHooks.removeGhostStructureForApp)
    hooks.registerHook(hooks.HOOKS.SAVE.SITE_SAVED, onSiteSaved)
    hooks.registerHook(
        hooks.HOOKS.DUPLICATE_ROOT.AFTER,
        notifyApplicationOfPageDuplicated,
        'mobile.core.components.Page'
    )
    registerHookWithPs(ps, FEATURE_DATA_HOOKS.UPDATE.AFTER.id, notifyAppsAfterFeatureDataUpdate)
    hooks.registerHook(hooks.HOOKS.ADD_ROOT.AFTER, notifyApplicationsOnPageAdded, 'mobile.core.components.Page')
    hooks.registerHook(hooks.HOOKS.CHANGE_PARENT.AFTER, platformHooks.notifyAddToAppWidget)
    hooks.registerHook(hooks.HOOKS.ADD_ROOT.AFTER, platformHooks.getComponentAddedToStageHook(notifyApplication))
    hooks.registerHook(hooks.HOOKS.ADD.AFTER, platformHooks.notifyWidgetAddedToStage)
    hooks.registerHook(hooks.HOOKS.SERIALIZE.DATA_AFTER, platformHooks.addOriginCompIdToWidget)
    hooks.registerHook(hooks.HOOKS.CONNECTION.AFTER_DISCONNECT, platformHooks.notifyComponentDisconnected)
    hooks.registerHook(hooks.HOOKS.CONNECTION.AFTER_CONNECT, platformHooks.notifyComponentConnected)
    hooks.registerHook(hooks.HOOKS.REMOVE.AFTER, platformHooks.getOnDeleteHook(notificationService.notifyApplication))
    hooks.registerHook(hooks.HOOKS.GHOSTIFY.AFTER, platformHooks.getOnDeleteHook(notificationService.notifyApplication))
}

function notifyAppsAfterFeatureDataUpdate(ps: PS, {compPointer, featureName, featureData}: FeatureDataUpdatedEvent) {
    if (featureName === 'presets') {
        platformHooks.notifyAppOnPresetChanged(ps, compPointer, featureData.style)
    }
}

function notifyApplicationOfPageDuplicated(ps: PS, newPageId: string, pageId: string) {
    const newPagePointer = ps.pointers.page.getPagePointer(newPageId)
    const pagePointer = ps.pointers.page.getPagePointer(pageId)

    notifyAppsOnCustomEvent(
        ps,
        platformEvents.factory.pageDuplicated({
            originalPageRef: ps.pointers.components.getDesktopPointer(pagePointer) as PageRef,
            duplicatedPageRef: ps.pointers.components.getDesktopPointer(newPagePointer) as PageRef
        })
    )
}

function notifyApplicationsOnPageAdded(ps: PS, pagePointer: Pointer) {
    notifyAppsOnCustomEvent(
        ps,
        platformEvents.factory.pageAdded({
            pageRef: pagePointer as PageRef
        })
    )
}

function initBasePaths(ps: PS) {
    const platformPointer = ps.pointers.platform.getPlatformPointer()
    const semanticAppVersionsPointer = ps.pointers.platform.getSemanticAppVersionsPointer()
    const pagesPlatformApplicationsPointer = ps.pointers.platform.getPagesPlatformApplicationsPointer()
    const platformValue = ps.dal.full.get(platformPointer)
    const appsInstallationStatePointer = ps.pointers.platform.getAppsInstallationStatePointer()
    const appsStatePointer = ps.pointers.platform.getAppStatePointer()
    ps.dal.full.set(
        platformPointer,
        _.assign(
            {
                appManifest: {},
                appPublicApiName: {}
            },
            platformValue
        )
    )

    if (!ps.dal.full.isExist(appsStatePointer)) {
        ps.dal.full.set(appsStatePointer, {})
    }

    if (!ps.dal.full.isExist(pagesPlatformApplicationsPointer)) {
        ps.dal.full.set(pagesPlatformApplicationsPointer, {})
    }

    if (!ps.dal.full.isExist(semanticAppVersionsPointer)) {
        ps.dal.full.set(semanticAppVersionsPointer, {})
    }

    if (!ps.dal.full.isExist(appsInstallationStatePointer)) {
        ps.dal.full.set(appsInstallationStatePointer, {})
    }
}

function getInstalledAppsData(ps: PS) {
    // later - change to getConnectableAppIds(compRef) which will query the worker about apps that the comp can connect to (according to their manifests)
    const dataBindingAppData = platformAppDataGetter.getAppDataByAppDefId(ps, constants.APPS.DATA_BINDING.appDefId)
    return [dataBindingAppData]
}

// TODO: remove temp functions once platform apps provision flow has been decided
function _tempInitApp(ps: PS, appDefinition, appUrlQueryParams?) {
    let appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinition.appDefinitionId)

    if (!appData) {
        appData = registerApp(ps, appDefinition)
    }

    if (workerService.isInitiated()) {
        if (appUrlQueryParams) {
            appData.appUrlQueryParams = appUrlQueryParams
        }
        appData.firstInstall = true
        appData.origin = originService.getOrigin()
        appData.settings = appUrlQueryParams?.settings

        workerService.addApp(ps, appData, _.noop, {internalOrigin: '_tempInitApp'})
    }

    const localApp = _tempGetLocalAppResources(ps)
    if (localApp) {
        const localAppData: any = registerApp(ps, localApp)
        localAppData.settings = appUrlQueryParams?.settings
        workerService.addApp(ps, localAppData, _.noop, {internalOrigin: '_tempInitAppLocalApp'})
    }
}

function registerApp(ps: PS, appDefinition) {
    const csm = clientSpecMap.getAppsData(ps)
    const currentLargestId = clientSpecMapService.getLargestApplicationId(csm)
    const newId = provisionUtils.generateAppFlowsLargestAppId(currentLargestId)
    const appData = {
        type: 'Application',
        displayName: appDefinition.appDefinitionId,
        appDefinitionId: appDefinition.appDefinitionId,
        applicationId: newId,
        editorArtifact: appDefinition.editorArtifact,
        appFields: {
            platform: {
                editorScriptUrl: _.get(appDefinition, 'appFields.platform.editorScriptUrl')
            }
        }
    }
    if (_.get(appDefinition, 'appFields.platform.viewerScriptUrl')) {
        //TODO remove this from here, move it to _tmpInit___ under wixCode
        _.set(appData, 'appFields.platform.viewerScriptUrl', appDefinition.appFields.platform.viewerScriptUrl)
    }
    clientSpecMap.registerAppData(ps, appData)
    clientSpecMap.setAppInstallStateByAppData(ps, appData)
    return appData
}

function getInstalledEditorApps(ps: PS) {
    const installedApps = clientSpecMap.getAppsData(ps)
    return _.filter(
        installedApps,
        appData => hasActiveEditorPlatformApp(ps, appData) || hasActiveUnifiedComponentApp(ps, appData)
    )
}

function _tempGetLocalAppResources(ps: PS) {
    function getLocalAppLocation(appSource) {
        return `http://localhost:${appSource.port}/${appSource.path}`
    }

    function parseAppSources(type) {
        const currentUrl = ps.siteAPI.getCurrentUrl()
        const appSources = _.get(currentUrl, ['query', type])
        return _(appSources || '')
            .split(',')
            .invokeMap('split', ':')
            .fromPairs()
            .value()
    }

    const editorAppSources = parseAppSources('editorPlatformAppSources')
    const viewerAppSources = parseAppSources('viewerPlatformAppSources')
    const appDefinitionId = editorAppSources.id

    if (appDefinitionId) {
        return {
            appDefinitionId,
            appFields: {
                platform: {
                    viewerScriptUrl: getLocalAppLocation(viewerAppSources),
                    editorScriptUrl: getLocalAppLocation(editorAppSources)
                }
            }
        }
    }
}

function getAPIForSDK(ps: PS, api) {
    return sdkAPIService.getAPIForSDK(api)
}

function pageHasPlatformApp(ps: PS, pageId: string, appDefinitionId: AppDefinitionId) {
    const pagesPlatformApplicationPointer = ps.pointers.platform.getPagesPlatformApplicationPointer(appDefinitionId)
    const applicationPages = ps.dal.full.get(pagesPlatformApplicationPointer) || {}
    return !!applicationPages[pageId]
}

function updatePagePlatformApp(ps: PS, pageRef: Pointer, appDefinitionId: AppDefinitionId, value) {
    const pageId = pageRef.id
    const pagesPlatformApplicationPointer = ps.pointers.platform.getPagesPlatformApplicationPointer(appDefinitionId)
    const applicationPages = ps.dal.full.get(pagesPlatformApplicationPointer) || {}
    delete applicationPages[pageId]
    if (value) {
        _.set(applicationPages, pageId, true)
    }
    //TODO : remove once members use new uninstall
    if (_.isEmpty(applicationPages) && appDefinitionId === '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9') {
        if (ps.dal.full.isExist(pagesPlatformApplicationPointer)) {
            ps.dal.full.remove(pagesPlatformApplicationPointer)
        }
        return
    }
    ps.dal.full.set(pagesPlatformApplicationPointer, applicationPages)
}

function removePageFromPlatformApps(ps: PS, pageRef: Pointer) {
    const pagesPlatformApplicationsPointer = ps.pointers.platform.getPagesPlatformApplicationsPointer()
    const pagesPlatformApplications = ps.dal.full.get(pagesPlatformApplicationsPointer)
    _.forEach(pagesPlatformApplications, function (pages, appDefId) {
        updatePagePlatformApp(ps, pageRef, appDefId, false)
    })
}

function getPagesForPlatformApp(ps: PS, appDefinitionId: AppDefinitionId) {
    const pagesPlatformApplicationPointer = ps.pointers.platform.getPagesPlatformApplicationPointer(appDefinitionId)
    const applicationPages = ps.dal.full.get(pagesPlatformApplicationPointer) || {}
    return Object.keys(applicationPages)
}

function prefetchScripts(scripts) {
    _.forEach(scripts, function (script) {
        const id = `prefetch-editor-${script.id}`
        if (!window.document.getElementById(id)) {
            const link = window.document.createElement('link')
            link.setAttribute('rel', 'prefetch')
            link.setAttribute('href', script.url)
            link.setAttribute('id', id)
            window.document.head.appendChild(link)
        }
    })
}

const isMobileWidgetBuilder = ps => {
    const branchId = ps.dal.get(ps.pointers.documentServicesModel.getBranchId())
    return branchId && branchId === MOBILE_WIDGET_BUILDER_BRANCH_ID
}
const filterScriptsToLoad = (ps, appToLoad) => {
    if (isMobileWidgetBuilder(ps)) {
        return _.filter(appToLoad, app => _.includes(APPS_TO_LOAD_ON_MOBILE_BUILDER, app.appDefinitionId))
    }
    return appToLoad
}

function loadEditorApps(ps: PS) {
    const migrationId = ps.siteAPI.getQueryParam('migrationId')
    const appDefinitionId = ps.siteAPI.getQueryParam('appDefinitionId')
    const isMigratingApp = (_ps: PS, appData) => appData.appDefinitionId === appDefinitionId
    const filterFn = migrationId !== undefined ? isMigratingApp : hasActiveEditorPlatformApp
    let appsToLoad = _(
        clientSpecMapService.getAppsDataWithPredicate(ps, csm => _.filter(csm, appData => filterFn(ps, appData)))
    )
        .map(workerService.enhanceAppWithEditorScript(ps))
        .map(val => {
            val.origin = originService.getOrigin()
            val.firstInstall = false
            return val
        })
        .value()
    /*TODO: temp sol for mobile editor using branches - we want to load only white listed apps MAW-751*/
    appsToLoad = filterScriptsToLoad(ps, appsToLoad)
    if (appsToLoad.length) {
        const appsToPrefetch = appsToLoad.map(app => ({
            id: app.applicationId,
            url: app.appFields.platform.editorScriptUrl
        }))
        prefetchScripts(appsToPrefetch)

        workerService.addApps(ps, appsToLoad)
    } else {
        workerService.handleAllAppsCompleted(true)
    }
}

function hasActiveEditorPlatformApp(ps: PS, appData) {
    return _.get(appData, ['appFields', 'platform', 'editorScriptUrl']) && clientSpecMapService.isAppActive(ps, appData)
}

function hasActiveUnifiedComponentApp(ps: PS, appData) {
    const isPlatformUnifiedComponent =
        _.get(appData, ['appFields', 'newEditorSchemaVersion']) === constants.APP.TYPE.UNIFIED_COMPONENT
    return isPlatformUnifiedComponent && clientSpecMapService.isAppActive(ps, appData)
}

function getPagesDataFromAppManifest(ps: PS, appDefinitionId: AppDefinitionId, key: string, states, pageRef?: Pointer) {
    const appManifest = platformAppDataGetter.getAppManifest(ps, appDefinitionId)

    const pageState = pageRef ? platformPages.getState(ps, pageRef) : 'default'
    const hasManifestByState = !!_.get(appManifest, ['pages', key, pageState])
    const manifestByState = _.get(appManifest, ['pages', key, hasManifestByState ? pageState : 'default'])
    const overridesFormat = !!_.get(manifestByState, ['defaultValues'])

    const data = overridesFormat ? _.get(manifestByState, 'defaultValues') : manifestByState

    const overrides = overridesFormat
        ? _.get(manifestByState, 'overrides')
        : _.get(appManifest, ['pages', key, 'overrides'])

    if (_.isEmpty(overrides)) {
        return data
    }

    return _.reduce(
        overrides,
        (res, overrideDef) => {
            if (_.isMatch(states, overrideDef.condition)) {
                if (_.isArray(overrideDef.override)) {
                    res = overrideDef.override
                } else {
                    _.assign(res, overrideDef.override)
                }
            }
            return res
        },
        data
    )
}

function getAppDescriptor(ps: PS, appDefinitionId: AppDefinitionId) {
    const appManifest = platformAppDataGetter.getAppManifest(ps, appDefinitionId)
    return _.get(appManifest, 'appDescriptor')
}

async function remove(ps: PS, appDefinitionId: AppDefinitionId, {intent = null} = {}) {
    const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)

    try {
        if (clientSpecMapService.hasEditorPlatformPart(appData)) {
            await workerService.removeApp(ps, appDefinitionId)
        }
    } catch (e) {
        ps.extensionAPI.logger.captureError(
            new ReportableError({
                errorType: 'failedToNotifyAppOnRemove',
                message: `Failed to notify appDefinitionId ${appDefinitionId} upon remove application successfully`,
                extras: {
                    appDefinitionId,
                    error: e
                }
            })
        )
        throw new Error(`Failed to notify appDefinitionId ${appDefinitionId} upon remove application successfully`)
    }
    if (await permissionsUtils.shouldAvoidRevoking({ps}, {intent})) {
        platformStateService.setUnusedApps(ps, [appData])
    } else {
        platformStateService.setAppPendingAction(ps, appDefinitionId, constants.APP_ACTION_TYPES.REMOVE)
    }
    ps.extensionAPI.appsInstallState.setAppUninstalled(appDefinitionId)
    return
}

function registerToAppsCompleted(ps: PS, cb: Callback) {
    workerService.registerToAppsCompleted(cb)
}

//appDefinitionId migration public api
function notifyApplicationByAppId_publicApi(
    ps,
    applicationId: ApplicationId,
    options: Partial<PlatformEvent> = {},
    isAmendableAction?: boolean
) {
    if (applicationId) {
        const appDefinitionId = clientSpecMap.getAppDefinitionIdFromApplicationId(ps, applicationId, {
            source: 'platform.notifyApplicationByAppId_publicApi',
            options
        })
        return notifyApplication(ps, appDefinitionId, options, isAmendableAction)
    }
}

function notifyApplication(
    ps: PS,
    appDefinitionId: AppDefinitionId,
    options: Partial<{eventType: EventType; eventPayload: any; eventOrigin?: string}> = {},
    isAmendableAction?: boolean
) {
    // ignoring events that were moved to DM from editor
    if (componentAddedToStageCompatability.shouldIgnoreEvent(options.eventType, options.eventOrigin)) {
        return
    }

    return notificationService.notifyApplication(ps, appDefinitionId, options, isAmendableAction)
}

function notifyAppsOnCustomEvent(
    ps: PS,
    {eventType, eventPayload, eventOrigin}: {eventType: string; eventPayload: any; eventOrigin?: string}
) {
    notificationService.notifyAppsOnCustomEvent(ps, {eventType, eventPayload, eventOrigin})
}

function migrate(ps: PS, appDefId: string, payload) {
    const appData = platformAppDataGetter.getAppDataByAppDefId(ps, appDefId)
    if (!appData) {
        return Promise.reject(new Error(`Migration failed: Unknown appDefinitionId: ${appDefId}`))
    }

    return workerService.notifyMigrate(ps, appData.appDefinitionId, payload).catch(e => {
        throw new Error(e.message || `Failed to migrate appDefinitionId ${appData.appDefinitionId}`)
    })
}

function setGhostStructure(ps: PS, value) {
    const ghostStructurePointer = ps.pointers.general.getGhostStructure()
    const currentGhostStructure = ps.dal.get(ghostStructurePointer)
    ps.dal.set(ghostStructurePointer, _.defaults(value, currentGhostStructure))
}

function setGhostControllers(ps: PS, value) {
    const ghostControllersPointer = ps.pointers.general.getGhostControllers()
    const currentGhostControllers = ps.dal.get(ghostControllersPointer)
    ps.dal.set(ghostControllersPointer, _.defaults(value, currentGhostControllers))
}

const getAppsDependenciesWithPredicate = (ps: PS, appDefinitionIds: AppDefinitionId[], predicate?: Function) => {
    if (predicate && typeof predicate !== 'function') {
        throw new Error('predicate should be a function')
    }
    const dependencies = []
    appDefinitionIds.forEach(appDefId => {
        platformAppDefIds.getAppDependencies(appDefId).forEach(dependency => {
            const existingDep = dependencies.find(({appDefinitionId}) => appDefinitionId === dependency.appDefinitionId)
            if (existingDep) {
                existingDep.isRequired = dependency.isRequired || existingDep.isRequired
            } else {
                const didPass = predicate ? predicate(dependency) : true
                if (didPass) {
                    dependencies.unshift(dependency)
                }
            }
        })
    })
    return dependencies
}

const getInstalledApps = (ps: PS) =>
    clientSpecMapService.getAppsDataWithPredicate(ps, csm =>
        Object.values(csm).filter(
            (appData: any) =>
                appData.appDefinitionId &&
                clientSpecMapService.isAppActive(ps, appData) &&
                !clientSpecMapService.isDashboardAppOnly(appData)
        )
    )

const isAppActive = (ps: PS, appDefinitionId: AppDefinitionId) => {
    const existingAppData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
    return clientSpecMapService.isAppActive(ps, existingAppData)
}

const shouldProvisionApp = (ps: PS, appDefinitionId: AppDefinitionId) => {
    if (!experiment.isOpen('dm_removePendingActions')) {
        return !isAppActive(ps, appDefinitionId)
    }
    return !clientSpecMapService.isAppProvisioned(ps, appDefinitionId)
}

const getAppPublicApi = (ps: PS, appDefinitionId: AppDefinitionId) =>
    getAppApi(ps, platformAppDataGetter.getAppPublicApiName(ps, appDefinitionId), appDefinitionId)

const getAppPrivateApi = (ps: PS, appDefinitionId: AppDefinitionId) =>
    getAppApi(ps, platformAppDataGetter.getAppPrivateApiName(ps, appDefinitionId), appDefinitionId)

const getAppEditorApi = (ps: PS, appDefinitionId: AppDefinitionId) =>
    getAppApi(ps, platformAppDataGetter.getAppEditorApiName(ps, appDefinitionId), appDefinitionId)

const getAppApi = (ps: PS, apiName: string, appDefinitionId: AppDefinitionId) => {
    const shouldTriggerApi = workerService.isAppStartedOnWorker(appDefinitionId)

    return shouldTriggerApi ? workerService.requestAPIFromWorker(ps, apiName).catch(() => null) : null
}

const registerToPlatformAPIChange = (ps: PS, appDefinitionId: AppDefinitionId, callback) => {
    if (!appDefinitionId) {
        throw new Error('appDefinitionId is mandatory')
    }
    if (!_.isFunction(callback)) {
        throw new Error('callback is mandatory and should be function')
    }
    ps.extensionAPI.platformSharedState.subscribeToPlatformAPICalls(appDefinitionId, callback)
}

const unregisterToPlatformAPIChange = (ps: PS, appDefinitionId: AppDefinitionId) => {
    ps.extensionAPI.platformSharedState.unsubscribeToPlatformAPICalls(appDefinitionId)
}

const addPlatformAppsExperiments = (ps: PS, newExperiments) => {
    const platformAppsExperimentsPointer = ps.pointers.rendererModel.getPlatformAppsExperiments()
    const platformAppsExperiments = ps.dal.get(platformAppsExperimentsPointer) ?? {}
    _.forEach(newExperiments, (value, expName) => _.set(platformAppsExperiments, expName, value))
    ps.dal.set(platformAppsExperimentsPointer, platformAppsExperiments)
}

const addBlocksExperiments = (ps: PS, newExperiments) => {
    const blocksExperimentsPointer = ps.pointers.rendererModel.getBlocksExperiments()
    const blocksExperiments = ps.dal.get(blocksExperimentsPointer) ?? {}
    _.forEach(newExperiments, (value, expName) => _.set(blocksExperiments, expName, value))
    ps.dal.set(blocksExperimentsPointer, blocksExperiments)
}

const getAppDefIdFromData = (dataItem: DataItem) => {
    return appControllerUtils.isAppControllerData(dataItem)
        ? appControllerUtils.getControllerAppDefinitionId(dataItem)
        : dataItem?.appDefinitionId
}

const registerToComponentRemoved = (ps: PS, hook: ComponentRemovedHookFunc) => {
    hooks.registerHook(hooks.HOOKS.REMOVE.AFTER, platformHooks.getOnComponentRemovedHook(hook))
}

const validateAppControllerPointer = (ps: PS, appControllerPointer: CompRef) => {
    const compType = componentStructureInfo.getType(ps, appControllerPointer)
    if (compType !== CONTROLLER_TYPE) {
        throw new ReportableError({
            errorType: 'incorrectAppControllerType',
            message: `Component with ${JSON.stringify(
                appControllerPointer
            )} pointer has "${compType}" component type. Expected component type: "${CONTROLLER_TYPE}"`
        })
    }
}

const setCoordinatesToZero = (ps: PS, appControllerPointer: CompRef) => {
    const xPointer = ps.pointers.getInnerPointer(appControllerPointer, ['layout', 'x'])
    const yPointer = ps.pointers.getInnerPointer(appControllerPointer, ['layout', 'y'])
    ps.dal.set(xPointer, 0)
    ps.dal.set(yPointer, 0)
}

const setPositionToFixed = (ps: PS, appControllerPointer: CompRef) => {
    if (structure.isFixedPosition(ps, appControllerPointer)) return

    structure.updateFixedPosition(ps, appControllerPointer, true)
}

const moveAppControllerToMasterPage = (ps: PS, appControllerPointer: CompRef) => {
    validateAppControllerPointer(ps, appControllerPointer)

    const masterPagePointer = ps.pointers.structure.getMasterPage('DESKTOP')

    structure.setContainer(ps, appControllerPointer, appControllerPointer, masterPagePointer as CompRef)

    setCoordinatesToZero(ps, appControllerPointer)
    setPositionToFixed(ps, appControllerPointer)
}

const moveAppControllerToPage = (ps: PS, appControllerPointer: CompRef, pagePointer: Pointer) => {
    validateAppControllerPointer(ps, appControllerPointer)

    const desktopPagePointer = ps.pointers.structure.getDesktopPointer(pagePointer)

    structure.setContainer(ps, appControllerPointer, appControllerPointer, desktopPagePointer as CompRef)

    setCoordinatesToZero(ps, appControllerPointer)
    setPositionToFixed(ps, appControllerPointer)
}

export default {
    initialize,
    init,
    setManifest: platformAppDataGetter.setManifest,
    registerToManifestAdded: platformAppDataGetter.registerToManifestAdded,
    initApp: _tempInitApp,
    remove,
    migrate,
    subscribeOnAppEvents: notificationService.subscribeOnAppEvents,
    subscribeOnCustomAppEvents: notificationService.subscribeOnCustomAppEvents,
    notifyApplication,
    notifyApplicationByAppId_publicApi,
    getAPIForSDK,
    getInstalledAppsData,
    pageHasPlatformApp,
    updatePagePlatformApp,
    removePageFromPlatformApps,
    getPagesForPlatformApp,
    getAppManifest: platformAppDataGetter.getAppManifest,
    hasAppManifest: platformAppDataGetter.hasAppManifest,
    getAppDataByAppDefId: platformAppDataGetter.getAppDataByAppDefId,
    getAppDataByApplicationId: platformAppDataGetter.getAppDataByApplicationId,
    getAppPublicApi,
    getAppPrivateApi,
    getAppEditorApi,
    getAppDescriptor,
    requestAPIFromWorker: workerService.requestAPIFromWorker,
    getInstalledEditorApps,
    notifyAppsOnCustomEvent,
    registerAppToCustomEvents: (ps: PS, applicationId: ApplicationId, eventTypes) => {
        const appDefinitionId = clientSpecMapService.getAppDefinitionIdFromApplicationId(ps, applicationId, {
            source: 'registerAppToCustomEvents',
            eventTypes
        })
        if (appDefinitionId) {
            platformEventsService.registerAppToEvents(appDefinitionId, eventTypes)
        }
    },
    registerAppToCustomEventsByAppDefId: (ps: PS, appDefinitionId: AppDefinitionId, eventTypes) => {
        platformEventsService.registerAppToEvents(appDefinitionId, eventTypes)
    },
    registerToPublicApiSet: platformAppDataGetter.registerToPublicApiSet,
    registerToPrivateApiSet: platformAppDataGetter.registerToPrivateApiSet,
    registerToPublicApiUnset: platformAppDataGetter.registerToPublicApiUnset,
    registerToPrivateApiUnset: platformAppDataGetter.registerToPrivateApiUnset,
    isPlatformAppInstalled: platformAppDataGetter.isPlatformAppInstalled,
    getPagesDataFromAppManifest,
    registerToAppsCompleted,
    registerToAppRemoved: (ps, cb) => workerService.registerToAppRemoved(cb),
    registerToComponentRemoved,
    getEditorSdkUrl: workerService.getEditorSdkUrl,
    setGhostStructure,
    setGhostControllers,
    getAppsDependenciesWithPredicate,
    isAppActive,
    shouldProvisionApp,
    getInstalledApps,
    concurrentEditing: {
        registerToPlatformAPIChange,
        unregisterToPlatformAPIChange
    },
    addPlatformAppsExperiments,
    addBlocksExperiments,
    getAppDefIdFromData,
    loadEditorApps,
    moveAppControllerToMasterPage,
    moveAppControllerToPage
}
