import {ReportableError} from '@wix/document-manager-utils'
import type {ContextAdapter, PS} from '@wix/document-services-types'

const PARTNERS_SERVER_URL = '/_api/partners-accounts-server/v1/partners/account'

const waitForAjaxToBeResolved = async (contextAdapter: ContextAdapter, url: string) =>
    new Promise((resolve, reject) => {
        const onPartnersDataResolved = partnersJsonData => {
            resolve(partnersJsonData)
        }
        const onPartnersDataRejected = (failedReq, textStatus, errorMessage) => {
            reject(errorMessage)
        }
        contextAdapter.actions.sendHttpRequest(url, 'GET', null, null, onPartnersDataResolved, onPartnersDataRejected)
    })

const isPartner = async (contextAdapter: ContextAdapter) => {
    const response: any = await waitForAjaxToBeResolved(contextAdapter, PARTNERS_SERVER_URL)
    return response.partnerAccount.isOfficialPartner
}

const setIsPotentialPartner = (ps: PS, val: boolean) => {
    const {dal, pointers} = ps
    dal.set(pointers.partners.getIsPotentialPartnerPointer(), val)
}

const fetchAndWritePartnersData = async (ps: PS, contextAdapter: ContextAdapter) => {
    try {
        const isPotentialPartnerVal = await isPartner(contextAdapter)
        setIsPotentialPartner(ps, isPotentialPartnerVal)
        return isPotentialPartnerVal
    } catch (e: any) {
        setIsPotentialPartner(ps, false)
        ps.extensionAPI.logger.captureError(
            new ReportableError({errorType: 'partnersDataError', message: 'failed to fetch partners data'}),
            {
                tags: {fetchAndWritePartnersData: true},
                extras: {message: e?.message}
            }
        )
        return false
    }
}

export default {
    fetchAndWritePartnersData
}
