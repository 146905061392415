import _ from 'lodash'
import type {CoreConfig} from '../../documentManagerCore'
import type {BaselineBlacklistCheck, ValidationWhitelistCheck, ValidatorAndWhitelistResult} from '../../types'
import type {Pointer} from '@wix/document-services-types'
import type {DmStore, DalValue} from '../store'
import {ReportableError, ReportableWarning} from '@wix/document-manager-utils'

type Tags = Record<string, any>
type Extras = Record<string, any>

export interface ValidatorResult {
    shouldFail: boolean
    type: string
    message: string
    tags?: Tags
    extras?: Extras
}

export type ValidateValue = (pointer: Pointer, value: DalValue) => ValidatorResult[] | undefined | void

const createValidator = (coreConfig: CoreConfig, namespacesNotToValidate: string[] = []) => {
    const {logger, experimentInstance} = coreConfig
    const validators = {}
    const baseline = {}
    const alreadyWhitelistedError = {}
    const whitelistChecks: ValidationWhitelistCheck[] = []
    const baselineBlacklistChecks: BaselineBlacklistCheck[] = []

    const getWhiteListedIssues = (pointer: Pointer, value: DalValue, validationResult: ValidatorResult) =>
        _(whitelistChecks)
            .map(check => check(pointer, value, validationResult))
            .filter('isWhiteListed')
            .map('issue')
            .value()

    const safeValidate = (
        pointer: Pointer,
        value: DalValue,
        validate: ValidateValue,
        validatorName: string,
        additionalTags?: Tags
    ): ValidatorAndWhitelistResult[] => {
        try {
            return _.map((validate(pointer, value) as ValidatorResult[]) ?? [], (result: ValidatorResult) => {
                const processedResult = {
                    ...result,
                    tags: _.assign(
                        {
                            validatorName,
                            dataType: value?.type,
                            namespace: pointer.type,
                            dalValidation: true,
                            shouldFail: result.shouldFail
                        },
                        additionalTags,
                        result.tags
                    ),
                    extras: _.assign(
                        {
                            offendingData: value
                        },
                        result.extras
                    )
                }
                const issues = getWhiteListedIssues(pointer, value, processedResult)
                return {
                    ...processedResult,
                    extras: _.assign({issues}, processedResult.extras),
                    isWhitelisted: issues.length > 0
                }
            })
        } catch (err) {
            logger.captureError(err as Error, {
                tags: {
                    validationFailure: true
                }
            })
            throw err
        }
    }

    const createError = ({message, type, tags, extras, isWhitelisted, shouldFail}: ValidatorAndWhitelistResult) => {
        const isWarning = isWhitelisted || !shouldFail
        return new ReportableError({
            message,
            errorType: type,
            tags: isWarning ? {...tags, warning: true} : tags,
            extras
        })
    }

    const reportViolations = (violations: ValidatorAndWhitelistResult[]) => {
        const reportedViolations = experimentInstance.isOpen('dm_ignoreStrictModeWarnings')
            ? violations.filter(({shouldFail, isWhitelisted}) => shouldFail && !isWhitelisted)
            : violations

        reportedViolations.forEach(violation => logger.captureError(createError(violation)))
    }

    const handleViolations = (
        violations: ValidatorAndWhitelistResult[],
        pointer: Pointer,
        onViolation: OnViolation
    ) => {
        violations.forEach(violation => {
            onViolation(createError(violation), pointer, violation)
        })
    }

    const markFirstFail = (violations: ValidatorResult[]) => {
        if (violations.length > 0) {
            violations[0].tags!.firstFail = true
        }
    }

    const validateAndReport = (pointer: Pointer, value: DalValue, onError: OnViolation, additionalTags?: Tags) => {
        for (const [name, validate] of Object.entries(validators)) {
            const violations = safeValidate(pointer, value, validate as ValidateValue, name, additionalTags)
            const [whitelisted, failures] = _(violations).filter('shouldFail').partition('isWhitelisted').value()
            markFirstFail(failures)
            reportViolations(
                violations.filter(violation => {
                    if (violation.isWhitelisted) {
                        const issueName = _.get(violation, ['extras', 'issues', 0])
                        return !_.get(alreadyWhitelistedError, [pointer.type, pointer.id, issueName])
                    }
                    return true
                })
            )
            handleViolations(whitelisted, pointer, violation => {
                const issueName = _.get(violation, ['extras', 'issues', 0])
                _.set(alreadyWhitelistedError, [pointer.type, pointer.id, issueName], true)
            })
            handleViolations(failures, pointer, onError)
        }
    }

    type OnViolation = (err: ReportableError, pointer: Pointer, violation: ValidatorAndWhitelistResult) => void

    const runValidation = (store: DmStore, onError: OnViolation, withBaseLine: boolean, tags?: Tags) => {
        const storeActionsAmount = store.size()
        if (storeActionsAmount > coreConfig.transactionActionsLimit) {
            const err = new ReportableWarning({
                errorType: 'exceedsMaxActionsPerTransaction',
                message: `Exceeds actions reached ${storeActionsAmount}`,
                extras: {tags}
            })
            logger.captureError(err)
            if (experimentInstance.isOpen('dm_failOnExceedsMaxActionsPerTransaction')) {
                throw err
            }
        }
        store.omit(namespacesNotToValidate).forEach((pointer: Pointer, value: DalValue) => {
            if (!withBaseLine || !_.get(baseline, [pointer.type, pointer.id])) {
                validateAndReport(pointer, value, onError, tags)
            }
        })
    }
    const isBlacklistedForBaseline = (
        err: ReportableError,
        pointer: Pointer,
        violation: ValidatorAndWhitelistResult
    ) => {
        for (const baselineBlacklistCheck of baselineBlacklistChecks) {
            if (baselineBlacklistCheck(violation, pointer, err)?.isBlacklisted) {
                return true
            }
        }
        return false
    }
    const throwError: OnViolation = (err: ReportableError) => {
        throw err
    }

    const addToBaseline: OnViolation = (err: ReportableError, pointer, violation) => {
        if (isBlacklistedForBaseline(err, pointer, violation)) {
            throwError(err, pointer, violation)
        }
        _.set(baseline, [pointer.type, pointer.id], true)
    }

    const validateStore = (store: DmStore, withBaseline: boolean, tags?: Tags) =>
        runValidation(store, throwError, withBaseline, tags)

    const createBaseline = (store: DmStore) => runValidation(store, addToBaseline, false, {baseline: true})

    const registerValidator = (name: string, validate: ValidateValue): void => {
        validators[name] = validate
    }

    const registerWhitelistCheck = (whitelistCheck: ValidationWhitelistCheck) => {
        whitelistChecks.push(whitelistCheck)
    }

    const registerBaselineBlacklistCheck = (check: BaselineBlacklistCheck) => {
        baselineBlacklistChecks.push(check)
    }

    return {
        registerValidator,
        validateStore,
        createBaseline,
        registerWhitelistCheck,
        registerBaselineBlacklistCheck
    }
}

export {createValidator}
