import type {AppDefinitionId, Callback1, Pointer, PS, CompRef} from '@wix/document-services-types'
import * as santaCoreUtils from '@wix/santa-core-utils'
import _ from 'lodash'
import * as platformEvents from '@wix/platform-editor-sdk/lib/platformEvents.min'
import component from '../../component/component'
import dataModel from '../../dataModel/dataModel'
import notificationService from '../../platform/services/notificationService'
import tpaUtils from '../utils/tpaUtils'
import clientSpecMapService from './clientSpecMapService'
import installedTpaAppsOnSiteService from './installedTpaAppsOnSiteService'
import tpaEventHandlersService from './tpaEventHandlersService'
import experiment from 'experiment-amd'

const SCOPE = {
    APP: 'APP',
    COMPONENT: 'COMPONENT'
} as const

export type Scope = (typeof SCOPE)[keyof typeof SCOPE]

const MAX_SIZE_FOR_APP = 1000
const MAX_SIZE_FOR_COMP = 400
const MAX_SIZE_FOR_SUPER_APP_COMP = 4000
const MAX_SIZE_FOR_SUPER_APP = 2000

const ONLY_TPA_COMPS_SUPPORTED =
    'tpa.data APIs can only be used with TPA components (TPAWidget, TPASection, TPAMultiSection, TPAGluedWidget)'
const PREFIX_TPA_DATA_ID = 'tpaData-'

const getTpaDataId = (ps: PS, appDefinitionIdId: AppDefinitionId, source: string) => {
    if (experiment.isOpen('dm_moveTpaDataToExt')) {
        return ps.extensionAPI.tpa.data.getTpaAppDataId(appDefinitionIdId)
    }
    const appId = clientSpecMapService.getApplicationIdFromAppDefinitionId(ps, appDefinitionIdId, {source})
    return PREFIX_TPA_DATA_ID + appId
}

const setAppValueByCompPointer = function (
    ps: PS,
    compPointer: Pointer,
    key: string,
    value,
    scope: Scope,
    callback?: Callback1<any>
) {
    if (!isValidValue(value)) {
        handleFailure(callback, 'Invalid value: value should be of type: string, boolean, number or Json')
        return
    }

    const compData = component.data.get(ps, compPointer, null, true)
    if (isAppScope(scope)) {
        const appTpaData = getAppTpaData(ps, compData.appDefinitionId)
        const oldAppTpaData = _.clone(appTpaData)
        setAppValueByAppDefId(ps, appTpaData, callback, compData.appDefinitionId, {[key]: value})
        notifyComponentDataChanged(ps, compData.appDefinitionId, compPointer, oldAppTpaData)
    } else {
        const componentTpaData = getCompTpaData(compData)
        const oldComponentTpaData = _.clone(componentTpaData)
        setComponentValue(ps, componentTpaData, compPointer, callback, {[key]: value})
        notifyComponentDataChanged(ps, compData.appDefinitionId, compPointer, oldComponentTpaData)
    }
}

const setMultipleValues = function (ps: PS, compPointer: Pointer, config, scope: Scope, callback: Callback1<any>) {
    const invalids = Object.values(config).filter(value => !isValidValue(value))
    if (invalids.length) {
        handleFailure(
            callback,
            `Invalid value/s: value should be of type: string, boolean, number or Json - values:${invalids.join(',')}`
        )
        return
    }
    const compData = component.data.get(ps, compPointer, null, true)
    if (isAppScope(scope)) {
        const appTpaData = getAppTpaData(ps, compData.appDefinitionId)
        const oldAppTpaData = _.clone(appTpaData)
        setAppValueByAppDefId(ps, appTpaData, callback, compData.appDefinitionId, config)
        notifyComponentDataChanged(ps, compData.appDefinitionId, compPointer, oldAppTpaData)
    } else {
        const componentTpaData = getCompTpaData(compData)
        const oldComponentTpaData = _.clone(componentTpaData)
        setComponentValue(ps, componentTpaData, compPointer, callback, config)
        notifyComponentDataChanged(ps, compData.appDefinitionId, compPointer, oldComponentTpaData)
    }
}

const notifyComponentDataChanged = function (ps: PS, appDefinitionId: AppDefinitionId, compRef: Pointer, previousData) {
    notificationService.notifyApplication(
        ps,
        appDefinitionId,
        platformEvents.factory.componentDataChanged({
            compRef: compRef as CompRef,
            previousData
        })
    )
}

// TODO : remove once santa-editor is deployed with new data api
const getValueOldAPI = function (ps: PS, compPointer: Pointer, key: string, scope: Scope, callback: Callback1<any>) {
    const compData = component.data.get(ps, compPointer, null, true)
    let returnObj

    if (isAppScope(scope)) {
        const appTpaData = getAppTpaData(ps, compData.appDefinitionId)
        returnObj = appTpaData ? _.pick(appTpaData.content, key) : null
    } else {
        if (!tpaUtils.isTpaComp(ps, compPointer)) {
            //technically appController works for appScope, so this check is only for component scope
            handleFailure(callback, ONLY_TPA_COMPS_SUPPORTED)
            return
        }
        const compTpaData = getCompTpaData(compData)
        returnObj = compTpaData ? _.pick(compTpaData.content, key) : null
    }

    if (!_.isEmpty(returnObj)) {
        callback(returnObj)
    } else {
        handleFailure(callback, `key ${key} not found in ${scope} scope`)
    }
}

const getMulti = function (ps: PS, compPointer: Pointer, keys, scope: Scope, callback: Callback1<any>) {
    const compData = component.data.get(ps, compPointer, null, true)
    let result

    keys = _.uniq(keys)

    if (isAppScope(scope)) {
        const appTpaData = getAppTpaData(ps, compData.appDefinitionId)
        result = appTpaData ? _.pick(appTpaData.content, keys) : null
    } else {
        if (!tpaUtils.isTpaComp(ps, compPointer)) {
            //technically appController works for appScope, so this check is only for component scope
            handleFailure(callback, ONLY_TPA_COMPS_SUPPORTED)
            return
        }
        const compTpaData = getCompTpaData(compData)
        result = compTpaData ? _.pick(compTpaData.content, keys) : null
    }

    const keysString = _.map(keys, key => `${key}`)
    if (!_.isEmpty(result) && _(result).keys().isEqual(keysString)) {
        callback(result)
    } else {
        const resultKeys = _.keys(result)
        const keysNotFound = _(resultKeys).xor(keys).intersection(keys).value()
        handleFailure(callback, `keys ${keysNotFound} not found in ${scope} scope`)
    }
}

const getCompTpaData = function (compData) {
    const componentTpaData = compData.tpaData
    if (componentTpaData) {
        componentTpaData.content = componentTpaData.content ? JSON.parse(componentTpaData.content) : {}
    }

    return componentTpaData
}
const getDefaultTpaAppData = (tpaDataId: string) => ({
    type: 'TPAGlobalData',
    id: tpaDataId,
    content: {}
})

const setAppValueByAppDefId = function (
    ps: PS,
    appTpaData,
    callback: Callback1<any>,
    appDefinitionId: AppDefinitionId,
    config
) {
    if (experiment.isOpen('dm_moveTpaDataToExt')) {
        const useLanguage = _.get(ps.dal.get(ps.pointers.multilingual.originalLanguage()), 'languageCode')
        const res = ps.extensionAPI.tpa.data.setAppValueByAppDefId(appTpaData, appDefinitionId, config, useLanguage)
        if (!res.success) {
            handleFailure(callback, res.reason)
            return
        }

        if (callback) {
            callback(res.keyValue)
        }

        publicDataUpdated(ps, SCOPE.APP, appDefinitionId, null, res.keyValue)
    } else {
        const pageId = 'masterPage'

        const tpaDataId = appTpaData
            ? appTpaData.id
            : getTpaDataId(ps, appDefinitionId, 'tpaDataService.setAppValueByAppDefId')

        if (!appTpaData) {
            appTpaData = getDefaultTpaAppData(tpaDataId)
        }

        appTpaData.appDefinitionId = appDefinitionId

        const limit = getContentLimit(ps, appDefinitionId, SCOPE.APP)
        const keyValue = setAndReturnConfig(ps, tpaDataId, appTpaData, pageId, config, limit, appDefinitionId)
        if (!keyValue) {
            handleFailure(callback, `Your app has exceeded the provided ${limit} bytes storage space`)
            return
        }

        if (callback) {
            callback(keyValue)
        }

        publicDataUpdated(ps, SCOPE.APP, appDefinitionId, null, keyValue)
    }
}

const publicDataUpdated = function (ps: PS, scope: Scope, appDefinitionId: AppDefinitionId, compId: string, data) {
    if (scope === SCOPE.APP) {
        tpaEventHandlersService.callPublicDataChangedCallbackForAllAppRegisteredComps(appDefinitionId, data)
    } else {
        tpaEventHandlersService.callPublicDataChangedCallback(compId, appDefinitionId, data)
    }
}

const setComponentValue = function (ps: PS, componentTpaData, compPointer: Pointer, callback: Callback1<any>, config) {
    if (experiment.isOpen('dm_moveTpaDataToExt')) {
        const useLanguage = _.get(ps.dal.get(ps.pointers.multilingual.originalLanguage()), 'languageCode')
        const result = ps.extensionAPI.tpa.data.setComponentValue(componentTpaData, compPointer, config, useLanguage)
        if (!result.success) {
            //technically appController works for appScope, so this check is only for component scope
            handleFailure(callback, result.reason)
            return
        }
        if (callback) {
            callback(result.keyValue)
        }

        publicDataUpdated(
            ps,
            SCOPE.COMPONENT,
            result.compDataDeserialized.appDefinitionId,
            compPointer.id,
            result.keyValue
        )
    } else {
        if (!tpaUtils.isTpaComp(ps, compPointer)) {
            //technically appController works for appScope, so this check is only for component scope
            handleFailure(callback, ONLY_TPA_COMPS_SUPPORTED)
            return
        }

        const pageId = ps.pointers.components.getPageOfComponent(compPointer).id
        const compDataPointer = dataModel.getDataItemPointer(ps, compPointer)
        const compDataDeserialized = ps.dal.get(compDataPointer)

        const tpaDataId = componentTpaData
            ? componentTpaData.id
            : santaCoreUtils.guidUtils.getUniqueId(PREFIX_TPA_DATA_ID, undefined)
        const limit = getContentLimit(ps, compDataDeserialized.appDefinitionId, SCOPE.COMPONENT)

        if (!componentTpaData) {
            componentTpaData = {
                type: 'TPAData',
                id: tpaDataId,
                content: {}
            }
        }
        const appDefinitionId = _.get(compDataDeserialized, 'appDefinitionId')

        const keyValue = setAndReturnConfig(ps, tpaDataId, componentTpaData, pageId, config, limit, appDefinitionId)

        if (!keyValue) {
            handleFailure(callback, `Your app has exceeded the provided ${limit} chars storage space`)
            return
        }

        compDataDeserialized.tpaData = `#${tpaDataId}`
        ps.dal.set(compDataPointer, compDataDeserialized)

        if (callback) {
            callback(keyValue)
        }

        publicDataUpdated(ps, SCOPE.COMPONENT, compDataDeserialized.appDefinitionId, compPointer.id, keyValue)
    }
}
const getContentLimit = (ps: PS, appDefinitionId: AppDefinitionId, scope: Scope) => {
    const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)

    if (_.get(appData, 'isWixTPA')) {
        return scope === SCOPE.APP ? MAX_SIZE_FOR_SUPER_APP : MAX_SIZE_FOR_SUPER_APP_COMP
    }

    return scope === SCOPE.APP ? MAX_SIZE_FOR_APP : MAX_SIZE_FOR_COMP
}
const getAppValue = function (ps: PS, appDefinitionId: AppDefinitionId, key: string, callback: Callback1<any>) {
    const appTpaData = getAppTpaData(ps, appDefinitionId)
    getValue(key, appTpaData, callback, SCOPE.APP)
}

const getComponentValue = function (ps: PS, compPointer: Pointer, key: string, callback: Callback1<any>) {
    const compData = component.data.get(ps, compPointer, null, true)
    const compTpaData = getCompTpaData(compData)
    getValue(key, compTpaData, callback, SCOPE.COMPONENT)
}

const getValue = function (key: string, tpaData, callback: Callback1<any>, scope: Scope) {
    const returnObj = tpaData ? _.pick(tpaData.content, key) : null

    if (!_.isEmpty(returnObj)) {
        callback(returnObj)
    } else {
        handleFailure(callback, `key ${key} not found in ${scope} scope`)
    }
}

const getPublicData = function (
    ps: PS,
    appDefinitionId: AppDefinitionId,
    compPointer: Pointer,
    callback: Callback1<any>
) {
    if (!tpaUtils.isTpaComp(ps, compPointer)) {
        //technically appController works for appScope, so this check is only for component scope
        handleFailure(callback, ONLY_TPA_COMPS_SUPPORTED)
        return
    }
    const APP = _.get(getAppTpaData(ps, appDefinitionId), 'content')
    const COMPONENT = _.get(getCompTpaData(component.data.get(ps, compPointer, null, true)), 'content')
    callback({
        APP,
        COMPONENT
    })
}

const getAppValues = function (ps: PS, appDefinitionId: AppDefinitionId, keys, callback: Callback1<any>) {
    keys = _.uniq(keys)

    const appTpaData = getAppTpaData(ps, appDefinitionId)
    const result = appTpaData ? _.pick(appTpaData.content, keys) : null

    getValues(keys, result, callback, SCOPE.APP)
}

const getComponentValues = function (ps: PS, compPointer: Pointer, keys, callback: Callback1<any>) {
    const compData = component.data.get(ps, compPointer, null, true)
    keys = _.uniq(keys)

    const compTpaData = getCompTpaData(compData)
    const result = compTpaData ? _.pick(compTpaData.content, keys) : null

    getValues(keys, result, callback, SCOPE.COMPONENT)
}

const removeValue = function (ps: PS, compPointer: Pointer, key: string, scope: Scope, callback: Callback1<any>) {
    const compData = component.data.get(ps, compPointer, null, true)

    if (isAppScope(scope)) {
        const appTpaData = getAppTpaData(ps, compData.appDefinitionId)
        remove(ps, appTpaData, key, 'masterPage', callback, scope, compData.appDefinitionId, compPointer.id)
    } else {
        const pageId = ps.pointers.components.getPageOfComponent(compPointer).id
        const compTpaData = getCompTpaData(compData)
        remove(ps, compTpaData, key, pageId, callback, scope, compData.appDefinitionId, compPointer.id)
    }
}

const remove = function (
    ps: PS,
    tpaData,
    key: string,
    pageId: string,
    callback: Callback1<any>,
    scope: Scope,
    appDefinitionId: AppDefinitionId,
    compId: string
) {
    if (isKeyExists(tpaData, key)) {
        const resultObj = _.pick(tpaData.content, key)
        tpaData.content = JSON.stringify(_.omit(tpaData.content, key))
        dataModel.addSerializedDataItemToPage(ps, pageId, tpaData, tpaData.id)
        callback(resultObj)

        publicDataUpdated(ps, scope, appDefinitionId, compId, resultObj)
    } else {
        handleFailure(callback, `key ${key} not found in ${scope} scope`)
    }
}

const getValues = function (keys, result, callback: Callback1<any>, scope: Scope) {
    const keysString = _.map(keys, key => `${key}`)
    if (!_.isEmpty(result) && _(result).keys().isEqual(keysString)) {
        callback(result)
    } else {
        const resultKeys = _.keys(result)
        const keysNotFound = _(resultKeys).xor(keys).intersection(keys).value()
        handleFailure(callback, `keys ${keysNotFound} not found in ${scope} scope`)
    }
}

const handleFailure = function (callback: Callback1<any>, message) {
    callback({
        error: {
            message
        }
    })
}

const isAppScope = (scope: string) => scope === SCOPE.APP

const isValidSize = function (tpaDataContent, maxSize: number) {
    try {
        return tpaDataContent.length <= maxSize
    } catch (e) {
        return false
    }
}

const isValidValue = function (value) {
    return _.isBoolean(value) || _.isString(value) || _.isNumber(value) || _.isPlainObject(value)
}

const isKeyExists = function (tpaData, key: string) {
    if (!tpaData) {
        return false
    }
    return _(tpaData.content).keys().includes(key.toString())
}

const getAppTpaData = (ps: PS, appDefinitionId: AppDefinitionId) => {
    if (experiment.isOpen('dm_moveTpaDataToExt')) {
        return ps.extensionAPI.tpa.data.getTpaAppData(appDefinitionId)
    }
    const appTpaDataId = getTpaDataId(ps, appDefinitionId, 'tpaDataService.getAppTpaData')
    const dataPointer = ps.pointers.data.getDataItem(appTpaDataId, 'masterPage')
    const tpaData = ps.dal.get(dataPointer)
    if (tpaData) {
        tpaData.content = tpaData.content ? JSON.parse(tpaData.content) : {}
    }
    return tpaData
}

const setAndReturnConfig = function (
    ps: PS,
    tpaDataId: string,
    tpaData,
    pageId: string,
    config,
    maxSize: number,
    appDefinitionId: AppDefinitionId
) {
    const currentContent = tpaData.content
    const tpaDataContentAsString = JSON.stringify({...tpaData.content, ...config})
    if (!_.isEqual(currentContent, JSON.parse(tpaDataContentAsString))) {
        tpaUtils.notifyTPAAPICalledFromPanel(ps, appDefinitionId)
    }
    if (!isValidSize(tpaDataContentAsString, maxSize)) {
        return null
    }
    const useLanguage = _.get(ps.dal.get(ps.pointers.multilingual.originalLanguage()), 'languageCode')
    tpaData.content = tpaDataContentAsString
    dataModel.addSerializedDataItemToPage(ps, pageId, tpaData, tpaData.id, useLanguage)
    return config
}

const isExistsAppTpaData = function (ps: PS, tpaDataId: string) {
    const dataPointer = ps.pointers.data.getDataItem(tpaDataId, 'masterPage')
    return ps.dal.isExist(dataPointer)
}

// TODO: add test
const getOrphanAppTpaData = function (ps: PS, appDefIdsToDelete?: AppDefinitionId[]) {
    const deletedAppDefIds = appDefIdsToDelete || installedTpaAppsOnSiteService.getDeletedAppDefIds(ps)
    return _(deletedAppDefIds)
        .map(appDefinitionId => getTpaDataId(ps, appDefinitionId, 'tpaDataService.getOrphanAppTpaData'))
        .filter(tpaDataId => isExistsAppTpaData(ps, tpaDataId))
        .value()
}

const runGarbageCollection = function (ps: PS, appDefIdsToDelete?: AppDefinitionId[]) {
    const orphanTpaData = getOrphanAppTpaData(ps, appDefIdsToDelete)
    if (!_.isEmpty(orphanTpaData)) {
        let orphanDataNodes = ps.dal.get(ps.pointers.general.getOrphanPermanentDataNodes())
        orphanDataNodes = orphanDataNodes.concat(orphanTpaData)
        ps.dal.set(ps.pointers.general.getOrphanPermanentDataNodes(), orphanDataNodes)
        _.forEach(orphanTpaData, removeTpaData.bind(null, ps))
    }
}

const removeTpaData = function (ps: PS, tpaDataId: string) {
    ps.dal.remove(ps.pointers.data.getDataItem(tpaDataId, 'masterPage'))
}

const setAppValue = (ps: PS, appDefinitionId: AppDefinitionId, key: string, value, callback: Callback1<any>) => {
    const appTpaData = getAppTpaData(ps, appDefinitionId)
    return setAppValueByAppDefId(ps, appTpaData, callback, appDefinitionId, {[key]: value})
}

export default {
    set: setAppValueByCompPointer,
    setMultiple: setMultipleValues,
    getAppValue,
    getAppValues,
    setAppValue,
    setAppValueByAppDefId,
    getPublicData,
    get: getValueOldAPI,
    getMulti,
    remove: removeValue,
    getComponentValue,
    getComponentValues,
    runGarbageCollection,
    SCOPE
}
