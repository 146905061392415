'use strict'

const _ = require('lodash')
const {createHelperAndMigratePage} = require('../helpers/CompsMigrationHelper')

const DEFAULT_PROPERTIES = ['isHidden', 'isCollapsed', 'ghost']

function fixVectorImageProperties(pageJson, cache, mobileView, comp) {
    const properties = this.getComponentProperties(pageJson, comp)
    if (properties && properties.type !== 'VectorImageProperties') {
        this.setComponentProperties(pageJson, comp, {
            type: 'VectorImageProperties',
            ..._.pick(properties, DEFAULT_PROPERTIES)
        })
    }

    if (comp.modes && comp.modes.overrides) {
        _.forEach(comp.modes.overrides, mode => {
            const modeProperties = this.getComponentProperties(pageJson, mode)
            if (modeProperties && modeProperties.type !== 'VectorImageProperties') {
                this.setComponentProperties(pageJson, mode, {
                    type: 'VectorImageProperties',
                    ..._.pick(modeProperties, DEFAULT_PROPERTIES)
                })
            }
        })
    }
}

const MIGRATION_MAP = {
    'wysiwyg.viewer.components.VectorImage': fixVectorImageProperties
}

/**
 * @exports utils/dataFixer/plugins/vectorImagePropsFixer
 * @type {{exec: function}}
 */

module.exports = {
    name: 'vectorImagePropsFixer',
    version: 3,
    exec: createHelperAndMigratePage(MIGRATION_MAP)
}
