const getAppComponents = app => app?.appFields?.appService?.components || app?.components || app?.data?.components || []

const getCodePackage = app => getAppComponents(app).find(({type}) => type === 'CODE_PACKAGE')

const hasCodePackage = app => Boolean(getCodePackage(app))

export default {
    getAppComponents,
    getCodePackage,
    hasCodePackage
}
