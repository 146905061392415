import _ from 'lodash'
import type {DocumentManager} from '@wix/document-manager-core'
import type {MasterPageMigrator} from '../dataMigrationRunner'

const stripHashIfExists = (query: string): string => {
    return query && (_.startsWith(query, '#') ? query.slice(1) : query)
}

const migrateMasterPage = (documentManager: DocumentManager) => {
    const {dal, pointers} = documentManager
    const menusIds = dal.get(pointers.data.getDataItemFromMaster('CUSTOM_MENUS'))?.menus ?? []
    const pagesGroupsIds = dal.get(pointers.data.getDataItemFromMaster('PAGES_GROUP_COLLECTION'))?.groups ?? []
    menusIds
        .concat(pagesGroupsIds)
        .map(stripHashIfExists)
        .forEach((dataItemId: string) => {
            const dataPointer = pointers.data.getDataItemFromMaster(dataItemId)
            const dataItem = dal.get(dataPointer)
            if (dataItem?.appId) {
                const specMapEntry = dal.get(pointers.general.getClientSpecMapEntry(JSON.stringify(dataItem.appId)))
                if (specMapEntry?.appDefinitionId) {
                    dal.set(dataPointer, {
                        ...dataItem,
                        appDefinitionId: specMapEntry.appDefinitionId
                    })
                }
            }
        })
}

const name = 'addAppDefinitionIdToAppsData'
const version = 1

export const addAppDefinitionIdToAppsData: MasterPageMigrator = {
    migrateMasterPage,
    name,
    version
}
