import type {Extension, ExtensionAPI} from '@wix/document-manager-core'

export interface SaveStateApi extends ExtensionAPI {
    saveState: {
        setSaveProgress(isSaving: boolean): void
        isSaveInProgress(): boolean
        setSaveAllowed(isAllowed: boolean): void
        isSaveAllowed(): boolean
        canSave(): boolean
        setPublishProgress(isPublishing: boolean): void
        isPublishInProgress(): boolean
        isSaveOrPublishInProgress(): boolean
    }
}

/**
 * @returns {Extension}
 */
const createExtension = (): Extension => {
    const saveState = {
        isSaveInProgress: false,
        isSaveAllowedByConfig: true,
        isPublishInProgress: false
    }

    const createExtensionAPI = (): SaveStateApi => {
        const setSaveProgress = (isSaving: boolean) => {
            saveState.isSaveInProgress = isSaving
        }
        const isSaveInProgress = () => saveState.isSaveInProgress
        const setSaveAllowed = (isAllowed: boolean) => {
            saveState.isSaveAllowedByConfig = isAllowed
        }
        const isSaveAllowed = () => saveState.isSaveAllowedByConfig
        const canSave = () => isSaveAllowed() && !isSaveInProgress()
        const setPublishProgress = (isPublishing: boolean) => {
            saveState.isPublishInProgress = isPublishing
        }
        const isPublishInProgress = () => saveState.isPublishInProgress
        const isSaveOrPublishInProgress = () => isPublishInProgress() || isSaveInProgress()
        return {
            saveState: {
                setSaveProgress,
                isSaveInProgress,
                setSaveAllowed,
                isSaveAllowed,
                canSave,
                setPublishProgress,
                isPublishInProgress,
                isSaveOrPublishInProgress
            }
        }
    }

    return {
        name: 'saveState',
        createExtensionAPI
    }
}

export {createExtension}
