import _ from 'lodash'
import {createLayoutsDefinition, layoutDefinition} from './defaultLayoutDefinitions'

import type {
    CompStructure,
    BaseDsData,
    StyleRef,
    PagesStructure,
    SerializedPageStructure,
    Pointer
} from '@wix/document-services-types'
import {schemas} from '@wix/document-services-json-schemas'
import type {CreateExtArgs, Extension, ExtensionAPI} from '@wix/document-manager-core'
import {createBreakpointsDefinition} from './defaultBreakpointsDefinitions'
import {
    getDefaultClassicBlankPageDefinition,
    getDefaultResponsiveBlankPageDefinition,
    getDefaultPageStyle
} from './defaultBlankPageDefinition'
import {VARIANTS} from '../../constants/constants'
/*
 * In some IDEs this import (falsely, if taking the command line execution as the source of truth)
 * triggers eslint's import/no-extraneous-dependencies rule. Be sure to ignore it.
 */
import type {AddOptions} from '../components'

export interface DefaultDefinitionsAPI extends ExtensionAPI {
    createComponentDefinition(compDefinition: CompStructure, overrides?: any, options?: AddOptions): CompStructure
    createPageDefinition(compDefinition: CompStructure, overrides?: any): CompStructure
    createRefArrayDefinition(references: BaseDsData[]): {values: BaseDsData[]; type: string}
    createSystemStyle(
        styleId: string,
        compType: string,
        skin: string,
        styleProperties: Record<string, any>,
        propertiesSource: Record<string, any>
    ): StyleRef
    getBlankBackground(): any
    createBlankPageDefinition(overrides?: Partial<SerializedPageStructure>): SerializedPageStructure
    getResponsiveSectionDefaultStructure(): CompStructure
    getDefaultInternalRef(rootIdPointer: Pointer): CompStructure
}

const createExtension = (): Extension => {
    const createExtensionAPI = ({extensionAPI, dal, pointers}: CreateExtArgs): ExtensionAPI => {
        const supportedTypes = {
            'responsive.components.Section': true,
            'wysiwyg.viewer.components.RefComponent': true,
            'mobile.core.components.Page': true,
            'wysiwyg.viewer.components.tpapps.TPASection': true,
            'wysiwyg.viewer.components.tpapps.TPAMultiSection': true,
            'wysiwyg.viewer.components.tpapps.TPAWidget': true
        }
        const isResponsive = () => dal.get(pointers.general.isResponsive()) ?? false
        const isSupported = (componentType: string) => {
            // TODO: Reinstate the responsive check once the testkit supports it
            // return dal.get(pointers.general.isResponsive()) && supportedTypes[componentType]
            return Boolean(supportedTypes[componentType])
        }

        function overrideEmptyBreakpointsValues(
            mergedDefinition: Partial<PagesStructure>,
            overrides: Partial<PagesStructure>
        ) {
            if (isResponsive() && mergedDefinition.breakpoints && _.isEmpty(overrides.breakpoints?.values)) {
                mergedDefinition.breakpoints.values = []
            }
        }

        const getMergedPageDefinition = (
            defaultDefinition: Partial<PagesStructure>,
            overrides: Partial<PagesStructure> | undefined
        ) => {
            if (!overrides) {
                return defaultDefinition
            }

            const mergedDefinition = _.merge(defaultDefinition, overrides)
            overrideEmptyBreakpointsValues(mergedDefinition, overrides)
            return mergedDefinition
        }
        const createComponentDefinition = (
            compDefinition: CompStructure,
            overrides?: Record<any, any>,
            options: AddOptions = {}
        ): CompStructure => {
            const {componentType} = compDefinition

            if (!isSupported(componentType)) {
                throw Error('The creation of this component type is not supported.')
            }

            const {type, styles} = schemas.default.allComponentsDefinitionsMap[componentType]
            const component = {
                layout: layoutDefinition,
                type,
                ...compDefinition
            }
            if (!component.style && !component.styleId) {
                component.styleId = _.keys(styles)[0]
            }
            const addResponsiveLayout = _.get(options, ['addDefaultResponsiveLayout'])
            if ((addResponsiveLayout || isResponsive()) && !component.layouts) {
                component.layouts = createLayoutsDefinition(
                    {extensionAPI, dal, pointers},
                    componentType,
                    overrides?.parentPointer,
                    compDefinition
                )
                const defaultBreakpoints = createBreakpointsDefinition(componentType)
                if (!_.isNil(defaultBreakpoints)) {
                    component.breakpointVariants = defaultBreakpoints
                }
            }

            return component
        }

        const createPageDefinition = (pageDefinition: CompStructure, overrides?: Record<any, any>): CompStructure => {
            if (!pageDefinition.skin) {
                pageDefinition.skin = 'skins.core.InlineSkin'
            }
            if (!pageDefinition.layout) {
                pageDefinition.layout = {x: 0, y: 0, width: 980, height: 500, anchors: []}
            }
            return createComponentDefinition(pageDefinition, overrides)
        }
        const createRefArrayDefinition = (references: string[]) => {
            return {
                type: 'RefArray',
                values: references
            }
        }

        const createSystemStyle = (
            styleId: string,
            compType: string,
            skin: string,
            styleProperties: Record<string, any>,
            propertiesSource: Record<string, any>
        ): StyleRef => {
            return {
                id: styleId,
                compId: '',
                componentClassName: compType,
                pageId: '',
                styleType: 'system',
                type: 'TopLevelStyle',
                skin,
                style: {
                    groups: {},
                    properties: styleProperties,
                    propertiesSource
                }
            }
        }

        const removeBreakpointsIfPageHasVariants = (page: Partial<PagesStructure>) => {
            const hasBreakpoints = _.some(page?.variants, x => x.type === VARIANTS.VARIANT_TYPES.BREAKPOINTS_DATA)
            if (hasBreakpoints) {
                delete page?.breakpoints
            }
            return page
        }

        const createBlankPageDefinition = (overrides?: Partial<PagesStructure>) => {
            const defaultDefinition = isResponsive()
                ? getDefaultResponsiveBlankPageDefinition()
                : getDefaultClassicBlankPageDefinition()

            const mergedPageDefinition = getMergedPageDefinition(defaultDefinition, overrides)
            return removeBreakpointsIfPageHasVariants(mergedPageDefinition)
        }

        function getBlankBackground() {
            return {
                desktop: {
                    custom: true,
                    ref: {
                        type: 'BackgroundMedia',
                        color: '{color_11}',
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none'
                    },
                    isPreset: true
                },
                mobile: {
                    custom: true,
                    ref: {
                        type: 'BackgroundMedia',
                        color: '{color_11}',
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none'
                    },
                    isPreset: true
                }
            }
        }

        const getResponsiveStyleDef = (componentClassName: string, skin: string) => ({
            styleType: 'custom',
            componentClassName,
            style: {
                properties: {
                    'alpha-bg': '0',
                    bg: 'color_11'
                },
                propertiesSource: {
                    'alpha-bg': 'value',
                    bg: 'theme'
                },
                groups: {}
            },
            pageId: '',
            compId: '',
            type: 'ComponentStyle',
            skin
        })

        const getResponsiveSectionDefaultStructure = () => {
            return {
                componentType: 'responsive.components.Section',
                components: [],
                design: {
                    type: 'MediaContainerWithDividers',
                    background: {
                        type: 'BackgroundMedia',
                        color: 'color_11',
                        colorOpacity: 0,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0,
                        colorLayers: [
                            {
                                fill: {
                                    type: 'SolidColor',
                                    color: 'color_11'
                                },
                                opacity: 1,
                                type: 'SolidColorLayer'
                            }
                        ]
                    }
                },
                style: getResponsiveStyleDef(
                    'responsive.components.Section',
                    'wysiwyg.viewer.skins.area.RectangleArea'
                ),
                type: 'Container'
            }
        }

        const getDefaultInternalRef = (rootCompIdPointer: Pointer) => {
            return {
                componentType: 'wysiwyg.viewer.components.RefComponent',
                skin: 'wysiwyg.viewer.skins.ResponsiveContainerRefSkin',
                data: {
                    type: 'InternalRef',
                    rootCompId: rootCompIdPointer.id
                },
                style: getResponsiveStyleDef(
                    'wysiwyg.viewer.components.RefComponent',
                    'wysiwyg.viewer.skins.ResponsiveContainerRefSkin'
                )
            }
        }

        return {
            defaultDefinitions: {
                createBreakpointsDefinition,
                createComponentDefinition,
                createPageDefinition,
                createRefArrayDefinition,
                createSystemStyle,
                getBlankBackground,
                createBlankPageDefinition,
                getResponsiveSectionDefaultStructure,
                getDefaultInternalRef,
                getDefaultPageStyle
            }
        }
    }
    return {
        name: 'defaultDefinitions',
        createExtensionAPI
    }
}

export {createExtension}
