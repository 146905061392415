import _ from 'lodash'

interface TraceLogBase {
    namespace: string
    args: any[]
    methodName: string
    res: any
}

interface TraceLog extends TraceLogBase {
    ts: number
}

export interface VMtrace {
    isTracing: boolean
    log: TraceLog[]
}
class ViewerApiCallsManager {
    _viewerManagerTrace: VMtrace
    constructor() {
        this._viewerManagerTrace = {
            isTracing: false,
            log: [] as TraceLog[]
        }
    }

    start() {
        this._viewerManagerTrace.isTracing = true
    }

    end() {
        this._viewerManagerTrace.isTracing = false
    }

    clean() {
        this._viewerManagerTrace.log = []
    }

    get() {
        return this._viewerManagerTrace.log
    }
    getByNamespace(namespace: string) {
        return _.filter(this.get(), {namespace})
    }
    shouldLogTrace() {
        return this._viewerManagerTrace.isTracing
    }
    logTrace(logData: TraceLogBase) {
        if (this.shouldLogTrace()) {
            const ts = window.performance ? window.performance.now() : _.now()
            this._viewerManagerTrace.log.push(_.assign({ts}, logData))
        }
    }
}

const createViewerApiTrace = () => new ViewerApiCallsManager()

export type {ViewerApiCallsManager}

export {createViewerApiTrace}
