import {constants} from '@wix/document-manager-extensions'
const {
    VIEWER_DATA_TYPES,
    MASTER_PAGE_ID,
    DATA_TYPES,
    VIEWER_PAGE_DATA_TYPES,
    PAGE_SCHEMA,
    RELATION_DATA_TYPES,
    PAGE_DATA_TYPES,
    VIEW_MODES,
    COMP_DATA_QUERY_KEYS_WITH_STYLE,
    DATA_TYPES_VALUES_WITH_HASH,
    REF_ARRAY_DATA_TYPE,
    VARIANTS,
    DATA_ITEM_TYPES
} = constants

const STYLES = {
    STYLES_PER_PAGE_VER: '1.0'
}

const COMP_MODES_TYPES = {
    HOVER: 'HOVER',
    DEFAULT: 'DEFAULT',
    APPLICATIVE: 'APPLICATIVE',
    SHOW_ON_SOME_PAGES: 'SHOW_ON_SOME_PAGES',
    POPOVER: 'POPOVER'
}

const DATA_REF_KEYS_TO_NAMES = {
    dataQuery: 'document_data',
    propertyQuery: 'component_properties',
    behaviorQuery: 'behaviors_data',
    mobileHintsQuery: 'mobile_hints',
    styleId: 'theme_data',
    connectionQuery: 'connections_data',
    designQuery: 'design_data',
    transformationQuery: 'transformations_data',
    transitionQuery: 'transitions_data'
}

const INTERACTIONS = {
    FULL_LOAD: 'ds-full-load',
    CREATE_HOST: 'ds-create-host',
    INITIALIZE_HOST: 'ds-initialize-host',
    LOADING_VIEWER: 'ds-loading-viewer',
    VIEWER_MODE_SWITCH: 'viewer-mode-switch',
    VIEWER_NAVIGATION: 'viewer-navigation',
    VIEWER_DATA_REQUIREMENTS: 'viewer-data-requirements',
    VIEWER_LAYOUT: 'viewer-layout',
    EXTEND_VIEWER_MANAGER: 'ds-extend-viewer-manager',
    CREATE_ADAPTER: 'ds-create-adapter',
    WAIT_FOR_VIEWER: 'ds-wait-for-viewer',
    FIX_DOMAIN: 'ds-fix-domain',
    INIT_PUBLIC_MODULES: 'ds-init-public-modules',
    CREATE_DOCUMENT_SERVICES: 'ds-create-document-services',
    VALIDATE_STORE: 'ds-validate-store',
    AFTER_LOADING_MOBILE_MERGE: 'ds-after-load-mobile-merge',
    CREATE_UNDO: 'ds-create-undo',
    REGISTER_MESSAGE_ON_LOAD: 'ds-register-message-on-load',
    FETCH_PAGES: 'fetch-pages',
    LOAD_PAGE_PAYLOADS: 'ds-load-page-payloads',
    LOAD_AUTOSAVE_PAYLOAD: 'ds-load-autosave-payload',
    LOAD_AUTOSAVE_RECOVERY: 'ds-load-autosave-recovery',
    CREATE_SERVER_STORE: 'ds-create-server-store',
    RUN_FIXERS: 'ds-run-fixers',
    RUN_VIEWER_FIXERS: 'ds-run-viewer-fixers',
    RUN_MIGRATORS: 'ds-run-migrators',
    MULTILINGUAL_INIT: 'ds-multilingual-init',
    INIT_FINAL_COMMIT: 'ds-final-init-commit',
    INIT_VIEWS: 'ds-init-views',
    INIT_CSAVE: 'ds-init-csave',
    INIT_DISTRIBUTOR: 'ds-init-fistributor',
    APPLY_AUTOSAVE_PATCH: 'ds-apply-autosave-patch',
    INIT_PUBLISH_PIPELINE: 'ds-init-publish-pipeline'
}

const {MOBILE_ONLY_COMPS} = constants

export {
    VIEW_MODES,
    PAGE_DATA_TYPES,
    VIEWER_DATA_TYPES,
    VIEWER_PAGE_DATA_TYPES,
    DATA_TYPES,
    MOBILE_ONLY_COMPS,
    STYLES,
    MASTER_PAGE_ID,
    COMP_MODES_TYPES,
    DATA_REF_KEYS_TO_NAMES,
    PAGE_SCHEMA,
    INTERACTIONS,
    RELATION_DATA_TYPES,
    COMP_DATA_QUERY_KEYS_WITH_STYLE,
    DATA_TYPES_VALUES_WITH_HASH,
    REF_ARRAY_DATA_TYPE,
    VARIANTS,
    DATA_ITEM_TYPES
}

export const TOPICS = {
    MULTILINGUAL_LOCALE: 'domain_events_wix.multilingual.v1.locale',
    META_SITE_SPECIAL_EVENTS: 'meta-site-manager-special-events'
}

export const NEW_COLOR_PALETTE_LENGTH = 66
