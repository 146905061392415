import type {DocumentManager} from '@wix/document-manager-core'
import type {DataModelExtensionAPI} from '@wix/document-manager-extensions'
import type {PageMigrator} from '../dataMigrationRunner'

const reg = /rgba\(.*[^)]$/

const migratePage = (documentManager: DocumentManager, pageId: string) => {
    const {dataModel} = documentManager.extensionAPI as DataModelExtensionAPI

    const allCorruptedStyleItemPointers = documentManager.pointers.data.getStyleItemsWithPredicate(styleItem => {
        const shadow = styleItem.style?.properties?.shd
        if (shadow) {
            const corruptedRgbaIndex = shadow.search(reg)
            return corruptedRgbaIndex !== -1
        }
        return false
    }, pageId)

    allCorruptedStyleItemPointers.forEach(pointer => {
        const styleItem = documentManager.dal.get(pointer)
        const shadow = styleItem.style.properties.shd
        const corruptedRgbaIndex = shadow.search(reg)
        if (corruptedRgbaIndex !== -1) {
            let corruptedRgba = shadow.substring(corruptedRgbaIndex)
            corruptedRgba += ')'

            const noSpx = corruptedRgba
                .replaceAll('spx', '')
                .replaceAll('px', '')
                .replaceAll(',', '')
                .replaceAll(' ', ',')

            const fullNewShadow = shadow.substring(0, corruptedRgbaIndex) + noSpx
            const fixedStyle = {
                ...styleItem,
                style: {
                    ...styleItem.style,
                    properties: {
                        ...styleItem.style.properties,
                        shd: fullNewShadow
                    }
                }
            }

            dataModel.addItem(fixedStyle, 'style', pageId, pointer.id)
        }
    })
}

const name = 'fixCorruptedStyleShadow'
const version = 1

export const fixCorruptedStyleShadow: PageMigrator = {
    migratePage,
    name,
    version,
    fixerRequiresReruns: true
}
