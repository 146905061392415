import {DocumentManager, pointerUtils} from '@wix/document-manager-core'
import type {Experiment, Pointer} from '@wix/document-services-types'
import type {RelationshipsAPI} from '@wix/document-manager-extensions/src/extensions/relationships'
import type {DataModelExtensionAPI, PageAPI} from '@wix/document-manager-extensions'
import {updateReference} from './referenceUpdater'
import {createReferrerGroups, ReferrerGroup} from './referrerGroups'

const {getPointer} = pointerUtils

const SCHEMA_OPTIONS = {
    data: {
        Page: {neverDuplicate: true}
    }
}

const shouldDuplicate = (namespace: string, schema: string) => {
    if (SCHEMA_OPTIONS[namespace]?.[schema]?.neverDuplicate) {
        return false
    }
    return true
}

const PAGE_TYPES = {
    Page: 'Page',
    AppPage: 'AppPage'
}

const isCrossPageRef = (documentManager: DocumentManager, ownerGroups: ReferrerGroup[], pageId: string) => {
    if (ownerGroups.length === 0) {
        return false
    }
    if (pageId === 'masterPage') {
        return false
    }
    if (ownerGroups[0].pageId === pageId) {
        return false
    }
    const {id, type} = documentManager.dal.get(ownerGroups[0].referrers[0])
    if (PAGE_TYPES.hasOwnProperty(type) && pageId === id) {
        return false
    }
    return true
}

const patchReferrer = (
    documentManager: DocumentManager,
    referrer: Pointer,
    reference: Pointer,
    schema: string,
    newReference: Pointer
) => {
    const {dal, logger} = documentManager
    const owner = dal.get(referrer)
    const resolvedReference = dal.schema
        .getReferences(referrer.type, owner)
        .find(r => r.id === reference.id && r.referencedMap === reference.type)!

    const {refInfo} = resolvedReference
    logger.interactionStarted('splitDuplicate', {
        extras: {
            ownerSchema: owner.type,
            path: refInfo.path,
            referrer,
            ref: reference,
            schema
        }
    })
    updateReference({documentManager, ownerPointer: referrer, resolvedReference, newRefId: newReference.id})
}

const splitReference = (
    documentManager: DocumentManager,
    pageId: string,
    ref: Pointer,
    schema: string,
    ownerGroups: ReferrerGroup[]
) => {
    const {extensionAPI} = documentManager
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    ownerGroups.forEach(({pageId: refOwnerPageId, referrers}) => {
        const duplicatePointer: Pointer = dataModel.duplicate(ref.id, ref.type, pageId, refOwnerPageId)
        referrers.forEach(ownerPointer => patchReferrer(documentManager, ownerPointer, ref, schema, duplicatePointer))
    })
}

export const migratePage = (documentManager: DocumentManager, pageId: string, experimentInstance: Experiment) => {
    const namespaces = ['data', 'design', 'connections', 'mobileHints']
    if (experimentInstance.isOpen('dm_fixCrossPagePropsAndDuplicateOwnedBehaviors')) {
        namespaces.push('props', 'behaviors')
    }
    const {dal, extensionAPI} = documentManager
    const {relationships} = extensionAPI as RelationshipsAPI
    for (const namespace of namespaces) {
        const pageCompFilter = (extensionAPI.page as PageAPI).getPageIndexId(pageId)
        const refsToCheck: Pointer[] = dal
            .queryKeys(namespace, pageCompFilter)
            .map((id: string) => getPointer(id, namespace))

        refsToCheck.forEach(ref => {
            const ownerRefs = relationships.getOwningReferencesToPointer(ref)
            const ownerGroups = createReferrerGroups(documentManager, ownerRefs)
            if (ownerGroups.length > 1 || isCrossPageRef(documentManager, ownerGroups, pageId)) {
                const schema = dal.get(ref).type
                if (shouldDuplicate(namespace, schema)) {
                    splitReference(documentManager, pageId, ref, schema, ownerGroups)
                } else {
                    documentManager.logger.interactionStarted('ignoreDuplicate', {
                        extras: {
                            owners: ownerRefs.slice(0, 10),
                            schema,
                            ref
                        }
                    })
                }
            }
        })
    }
}
