import * as tinyMenuLayoutHandler from './tinyMenuLayoutHandler'
import * as backToTopLayoutHandler from './backToTopLayoutHandler'
import * as conversionUtils from '../conversionUtils'
import type {DeepStructure} from '../../types'

const HANDLERS = {
    TINY_MENU: tinyMenuLayoutHandler,
    BACK_TO_TOP_BUTTON: backToTopLayoutHandler
}

export function addComponent(masterPage: DeepStructure, compId: string): void {
    const handler = HANDLERS[compId]
    if (!handler || conversionUtils.getComponentByIdFromStructure(compId, masterPage)) {
        return
    }
    handler.addToStructure(masterPage)
}

export const getAdditionalStyles = () => tinyMenuLayoutHandler.getAdditionalStyles()
