import type {CompRef, Pointer, PS} from '@wix/document-services-types'
import * as santaCoreUtils from '@wix/santa-core-utils'
import constants from '../../constants/constants'
import {getMeasureMapValue} from '../../utils/utils'

export default {
    layoutLimits(ps: PS, compPointer: Pointer) {
        return {
            minWidth: getMeasureMapValue(
                ps,
                compPointer as CompRef,
                'minWidth',
                santaCoreUtils.siteConstants.COMP_SIZE.MIN_WIDTH
            ),
            minHeight: getMeasureMapValue(
                ps,
                compPointer as CompRef,
                'minHeight',
                santaCoreUtils.siteConstants.COMP_SIZE.MIN_HEIGHT
            )
        }
    },
    a11yConfigurable: false,
    mobileConversionConfig: {
        hideByDefault(ps: PS, component: Pointer, pageId: string) {
            const masterPagePointer = ps.pointers.components.getMasterPage(constants.VIEW_MODES.MOBILE)
            const menuContainerPointer = ps.pointers.components.getComponent(
                constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER,
                masterPagePointer
            )
            const menuContainerIsExist = ps.dal.isExist(menuContainerPointer)
            return pageId === 'masterPage' && !menuContainerIsExist
        }
    }
}
