import type {PS} from '@wix/document-services-types'
import {coreUtils} from '@wix/santa-ds-libs'
import themeConstants from '../common/constants'
import themeDAL from '../common/themeDAL'
import themeColors from '../colors/colors'
import {deepClone} from '@wix/wix-immutable-proxy'

const {PROPERTY_TYPE} = themeConstants
const {textThemeToFontString} = coreUtils.fontUtils

function getTextThemes(ps: PS) {
    return themeDAL.getCollection(ps, PROPERTY_TYPE.TEXT_THEME)
}

function getAll(ps: PS) {
    return deepClone(ps.extensionAPI.theme.getTextTheme())
}

function get(ps: PS, index: string | number) {
    return themeDAL.getCollectionItem(ps, PROPERTY_TYPE.TEXT_THEME, index)
}

/**
 * We update both font and textTheme, since there are APIs in core-utils that uses font
 * so they won't return an old font string.
 */
function set(ps: PS, index: number, val) {
    const fontString = textThemeToFontString(val)
    themeDAL.setCollectionItem(ps, PROPERTY_TYPE.TEXT_THEME, index, val)
    themeDAL.setCollectionItem(ps, PROPERTY_TYPE.FONT, index, fontString)
}

function getStyles(ps: PS) {
    const colors = themeColors.getAll(ps)
    const textThemes = getTextThemes(ps)

    return coreUtils.fontUtils.getTextThemesCss(textThemes, colors)
}

export default {
    set,
    get,
    getAll,
    getStyles
}
