import _ from 'lodash'
import type {ComponentLayoutObject, GridItemLayout, Pointer, CompStructure} from '@wix/document-services-types'
import {deepClone} from '@wix/wix-immutable-proxy'
import type {GridLayoutAPI} from '../gridLayout'
import type {DalPointers, DalPointersWithExtensionAPI} from '../../types'
import {COMPONENT_TYPE_TO_RESPONSIVE_LAYOUT_MAP} from './layoutForComps/responsiveLayoutForCompsMap'
const layoutDefinition = {
    x: 0,
    y: 0,
    width: 100,
    height: 100
}

const getLayout = (dalPointers: DalPointers, componentType: string, compDefinition: CompStructure) => {
    const componentLayoutMap = COMPONENT_TYPE_TO_RESPONSIVE_LAYOUT_MAP[componentType].getLayout(
        dalPointers,
        compDefinition
    )
    return {
        componentLayout: deepClone(componentLayoutMap.COMPONENT_LAYOUT_TYPES),
        containerLayout: deepClone(componentLayoutMap.CONTAINER_LAYOUT_TYPES),
        itemLayout: deepClone(componentLayoutMap.ITEM_LAYOUT_TYPES)
    }
}

const createLayoutsDefinition = (
    {extensionAPI, dal, pointers}: DalPointersWithExtensionAPI,
    componentType: string,
    parentPointer: Pointer,
    compDefinition: CompStructure
): Partial<ComponentLayoutObject> => {
    const gridLayout = () => extensionAPI.gridLayout as GridLayoutAPI
    const layout: Partial<ComponentLayoutObject> = getLayout({dal, pointers}, componentType, compDefinition)
    if ((layout.itemLayout as GridItemLayout)?.gridArea) {
        const {rowStart, rowEnd} = gridLayout().findNextRowInGrid(parentPointer)
        _.assign((layout.itemLayout as GridItemLayout)?.gridArea, {rowStart, rowEnd})
    }
    return layout
}

export {createLayoutsDefinition, layoutDefinition}
