import {pointerUtils, DmStore, DalValue, DocumentManager} from '@wix/document-manager-core'
import _ from 'lodash'
import type {InitConfig} from '../types'
import type {DocumentServicesModel} from './modelTypes'

const {getPointer} = pointerUtils

const defaultSet = (dmStore: DmStore, modelName: string, key: string, value: DalValue) => {
    const pointer = getPointer(key, modelName)
    dmStore.set(pointer, value)
}

const setWithSignature = (dmStore: DmStore, modelName: string, key: string, value: DalValue, signature?: string) => {
    if (signature) {
        const signedValue = {...value, metaData: _.clone(value.metaData)}
        _.setWith(signedValue, ['metaData', 'sig'], signature, Object)

        defaultSet(dmStore, modelName, key, signedValue)
    } else {
        defaultSet(dmStore, modelName, key, value)
    }
}

const setDocumentServicesModel = (
    documentManager: DocumentManager,
    dmStore: DmStore,
    modelData: Record<string, DalValue>,
    signatures: Record<string, string>
) => {
    const pointer = getPointer('versionInfo', 'documentServicesModel')
    dmStore.set(pointer, {
        id: 'versionInfo',
        type: 'versionInfo',
        version: (modelData as DocumentServicesModel)?.version,
        revision: (modelData as DocumentServicesModel)?.revision
    })
    _.forEach(modelData, (value: DalValue, key) => {
        switch (key) {
            case 'version':
            case 'revision':
                return
            default:
                setWithSignature(dmStore, 'documentServicesModel', key, value, signatures[key])
        }
    })
}

const setRendererModel = (
    documentManager: DocumentManager,
    dmStore: DmStore,
    modelData: Record<string, DalValue>,
    signatures: Record<string, string>
) => {
    _.forEach(modelData, (value: DalValue, key) => {
        switch (key) {
            case 'urlFormatModel': {
                let {forbiddenPageUriSEOs} = value
                if (_.isArray(forbiddenPageUriSEOs)) {
                    forbiddenPageUriSEOs = _(forbiddenPageUriSEOs)
                        .keyBy()
                        .mapValues(() => true)
                        .value()
                }

                const urlFormatModelValue = {...value, forbiddenPageUriSEOs}
                const pointer = getPointer('urlFormatModel', 'rendererModel')
                dmStore.set(pointer, urlFormatModelValue)
                return
            }

            case 'passwordProtectedPages': {
                const pointer = getPointer('passwordProtectedPages', 'rendererModel')
                const wrappedPasswordProtectedPages = _.isArray(value) ? {pages: value} : value
                dmStore.set(pointer, wrappedPasswordProtectedPages)
                return
            }

            default:
                setWithSignature(dmStore, 'rendererModel', key, value, signatures[key])
        }
    })
}

const setServiceTopologyModel = (
    documentManager: DocumentManager,
    dmStore: DmStore,
    modelData: Record<string, DalValue>
) => {
    const serviceTopologyMap = _.has(modelData, ['serviceTopology']) ? modelData.serviceTopology : modelData
    defaultSet(dmStore, 'serviceTopology', 'serviceTopology', serviceTopologyMap)
}

const modelSetters = {
    documentServicesModel: setDocumentServicesModel,
    rendererModel: setRendererModel,
    serviceTopology: setServiceTopologyModel
}

const initialize = (
    {documentManager, serviceTopology, documentServicesModel, rendererModel}: InitConfig,
    initialState: DmStore
) => {
    _.forEach(
        {
            serviceTopology,
            documentServicesModel,
            rendererModel
        },
        (modelData, modelName) => {
            const signatures = _(_.get(documentServicesModel, ['signatures']))
                .pickBy((v: DalValue, k: string) => k.split('.')[0] === modelName)
                .mapKeys((v: DalValue, k: string) => k.split('.')[1])
                .value()

            const modelDataWithSignaturesPlaceholders = _.defaults(
                modelData,
                _.mapValues(signatures, () => ({}))
            )

            modelSetters[modelName](documentManager, initialState, modelDataWithSignaturesPlaceholders, signatures)
        }
    )
}

export {initialize}
