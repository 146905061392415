const extensionsList: string[] = [
    'schema',
    'componentDefinition',
    'logger',
    'environment',
    'hooks',
    'relationships',
    'dataAccess',
    'dataModel',
    'snapshots',
    'documentServicesModel',
    'data',
    'rendererModel',
    'structure',
    'menu',
    'page',
    'multilingual',
    'platform',
    'tpa',
    'saveState',
    'distributor',
    'platformSharedState',
    'mobile',
    'validationWhitelist',
    'dataFixerVersioning',
    'validations',
    'variables',
    'slots',
    'componentsMetadata',
    'connections',
    'routers',
    'platformAppReflow',
    'theme',
    'components',
    'gridLayout',
    'defaultDefinitions',
    'internalRef',
    'variants',
    'refOverrides',
    'appController',
    'mobilePresets',
    'wixCode',
    'wixCodeFileSystem',
    'nicknames',
    'mobileConversion',
    'effects',
    'importExport',
    'features',
    'actionsAndBehaviors',
    'structureMetaData',
    'remoteStructureMetaData',
    'blocks',
    'meshLayout',
    'atomicScopes',
    'siteStructure',
    'mobileHints',
    'serializedStructure',
    'appsInstallState',
    'mobileAlgo',
    'arrangement',
    'publishPipeline',
    'serviceTopology',
    'builderComponents',
    'innerElements',
    'debug'
]

export default {
    extensionsList
}
