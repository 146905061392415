/* eslint-env node, browser */
import {noop} from 'lodash'
import type {LoggerDriver} from '../types'

const debugKey = 'dm-debug'

const isStorageSupported = (function () {
    try {
        return typeof window !== 'undefined' && !!window.localStorage
    } catch (e) {
        return false
    }
})()

const get = (s: string) => {
    // @ts-ignore
    if (typeof process !== 'undefined' && !process.browser) {
        return process.env.DEBUG
    }
    if (isStorageSupported) {
        return window.localStorage.getItem(s)
    }
}

let localScope = get(debugKey)

const enrichMessageWithScope = (scope: string, message: string) => `[${scope}] ${message}`

/**
 * a simple debug function
 * usage:
 * import {debug} from '../../utils/debug'
 * const log = debug('csave')
 * ...
 * log('this is a debug log')
 *
 * set local storage dm-debug to csave to see the logs
 * @param {string} scope
 * @param {LoggerDriver} [loggerDriver=console] - tool which will write logs
 * @returns {any}
 */
export function debug(scope: string, loggerDriver: LoggerDriver = console): LoggerDriver {
    if (/*isStorageSupported && */ localScope !== scope) {
        return {
            info: noop,
            error: noop
        }
    }

    return {
        info(message, ...args) {
            loggerDriver.info(enrichMessageWithScope(scope, message), ...args)
        },
        error(message, error, ...args) {
            loggerDriver.error(enrichMessageWithScope(scope, message), error, ...args)
        }
    }
}

export function setDebug(val: string) {
    if (isStorageSupported) {
        window.localStorage.setItem(debugKey, val)
        localScope = get(debugKey)
    }
}
