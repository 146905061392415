import _ from 'lodash'
import {TpaBaseUrlBuilder} from './TPABaseUrlBuilder'

export class TpaUrlBuilder extends TpaBaseUrlBuilder {
    constructor(baseUrl: string) {
        super(baseUrl)
    }
    addInstance(instance: string) {
        return this.addQueryParam('instance', instance)
    }
    addWidth(width: string) {
        return this.addQueryParam('width', width)
    }

    addLocale(locale: string) {
        return this.addQueryParam('locale', locale)
    }

    addLang(lang: string) {
        return this.addQueryParam('lang', lang)
    }

    addDateNumberFormat(dateNumberFormat: string) {
        return this.addQueryParam('dateNumberFormat', dateNumberFormat)
    }

    addIsPrimaryLanguage(isPrimaryLanguage) {
        return this.addQueryParam('isPrimaryLanguage', isPrimaryLanguage)
    }

    addCurrency(currency) {
        return this.addQueryParam('currency', currency)
    }
    addCommonConfig(commonConfig) {
        let commonConfigQueryParam: any = _.isString(commonConfig) ? commonConfig : ''
        if (_.isObject(commonConfig)) {
            commonConfigQueryParam = {}
            //Needed since the Aspect in the server expects BSI according to it's proto: https://github.com/wix-private/fed-infra/blob/master/fed-infra-protos/src/main/proto/common-config.proto#L26
            const mappedKeys = {bsi: 'BSI'}
            //Removing unneeded data that breaks JSON.parse
            const denyList = ['consentPolicyHeader', 'consentPolicy']
            Object.keys(commonConfig).forEach(function manipulateKeys(key) {
                if (mappedKeys[key]) {
                    commonConfigQueryParam[mappedKeys[key]] = commonConfig[key]
                }
                if (!_.includes(denyList, key, 0) && typeof commonConfig[key] !== 'function') {
                    commonConfigQueryParam[key] = commonConfig[key]
                }
            })
            commonConfigQueryParam = JSON.stringify(commonConfigQueryParam)
        }
        return this.addQueryParam('commonConfig', commonConfigQueryParam)
    }

    addTimeZone(timeZone) {
        return this.addQueryParam('tz', timeZone)
    }
    addViewMode(viewMode) {
        return this.addQueryParam('viewMode', viewMode)
    }

    addCompId(compId) {
        return this.addQueryParam('compId', compId)
    }
    addDeviceType(deviceType) {
        return this.addQueryParam('deviceType', deviceType)
    }
    addEndpointType(endpointType) {
        return this.addQueryParam('endpointType', endpointType)
    }

    addOrigCompId(origCompId) {
        return this.addQueryParam('origCompId', origCompId)
    }

    addExternalId(externalId) {
        return this.addQueryParam('externalId', externalId)
    }

    addOrigin(origin) {
        return this.addQueryParam('origin', origin)
    }
    addCbi(bi) {
        return this.addQueryParam('cbi', bi)
    }
}
