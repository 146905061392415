import documentModeInfo from '../../documentMode/documentModeInfo'
import constants from '../../constants/constants'

export default {
    resizableSides(ps /*, compPath*/) {
        if (documentModeInfo.getViewMode(ps) === 'MOBILE') {
            // todo use consts, align with getViewNode signature
            return []
        }

        return [constants.RESIZE_SIDES.LEFT, constants.RESIZE_SIDES.RIGHT]
    },

    layoutLimits(ps) {
        return documentModeInfo.getViewMode(ps) === 'MOBILE' ? {} : {minWidth: 400}
    },
    mobileConversionConfig: {
        stretchHorizontally: true
    }
}
