import _ from 'lodash'

const roundToNearestDecade = (value: number) => Math.round(value / 10) * 10

const buildRangeDataStructure = (upToValue, valueHandler, additionalParamsForHandler?) => ({
    upToValue,
    valueHandler,
    additionalParamsForHandler: additionalParamsForHandler || []
})

const buildScaleFactorDataStructure = (scaleFactor, minValueLimit, roundFunction) => ({
    scaleFactor,
    minValueLimit,
    roundFunction: roundFunction || Math.round
})

const scaleByRange = (originalValue, rangeData) => {
    // @ts-expect-error
    const normalizedValue = Math.min(originalValue, _.last(rangeData).upToValue)
    const rangeValue = _.find(rangeData, rangeStair => normalizedValue <= rangeStair.upToValue)
    const {valueHandler} = rangeValue
    return _.isFunction(valueHandler)
        ? valueHandler(originalValue, ...rangeValue.additionalParamsForHandler)
        : valueHandler
}

const scaleByFactor = (originalValue: number, scaleFactorData) => {
    const scaledValue = originalValue * scaleFactorData.scaleFactor
    const roundedValue = scaleFactorData.roundFunction(scaledValue)
    return Math.max(scaleFactorData.minValueLimit, roundedValue)
}

const scaleProportionally = (originalValue: number, numerator: number, denominator: number) => {
    const ratio = numerator && denominator ? numerator / denominator : 1
    return Math.round(originalValue * ratio)
}

export default {
    roundToNearestDecade,
    buildRangeDataStructure,
    buildScaleFactorDataStructure,
    scaleByRange,
    scaleByFactor,
    scaleProportionally
}
