import type {PublicMethodUtils} from '@wix/document-services-types'
import {responsiveLayout} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            utils: {
                mesh: {
                    absoluteToMesh: publicMethodUtils.defineGetter(responsiveLayout.absoluteToMesh),
                    remeasureContainer: publicMethodUtils.defineDataManipulationAction(
                        responsiveLayout.remeasureContainer
                    ),
                    getMeasurements: publicMethodUtils.defineGetter(responsiveLayout.getMeasurements)
                }
            },
            components: {
                responsiveLayout: {
                    update: publicMethodUtils.defineDataManipulationAction(responsiveLayout.update),
                    remeasureAndUpdate: publicMethodUtils.defineDataManipulationAction(
                        responsiveLayout.remeasureAndUpdate
                    ),
                    get: publicMethodUtils.defineGetter(responsiveLayout.get),
                    pin: publicMethodUtils.defineDataManipulationAction(responsiveLayout.pin),
                    unpin: publicMethodUtils.defineDataManipulationAction(responsiveLayout.unpin),
                    runtime: {
                        measure: {
                            getRelativeToContainerBoundingBox: publicMethodUtils.defineGetter(
                                responsiveLayout.getRelativeToContainerBoundingBox
                            )
                        },
                        detachComponents: publicMethodUtils.defineAction(responsiveLayout.detachComponents),
                        updateDetachedLayout: publicMethodUtils.defineAction(responsiveLayout.updateDetachedLayout),
                        reattachComponents: publicMethodUtils.defineAction(responsiveLayout.reattachComponents)
                    }
                }
            }
        }
    }
}
