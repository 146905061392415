import type {
    AbstractComponent,
    CompStructure,
    MobileHints,
    Point,
    Pointer,
    PS,
    DeepStructureOptions
} from '@wix/document-services-types'
import * as mobileCore from '@wix/mobile-conversion'
import * as santaCoreUtils from '@wix/santa-core-utils'
import experiment from 'experiment-amd'
import _ from 'lodash'
import componentData from '../../../component/componentData'
import componentsMetaData from '../../../componentsMetaData/componentsMetaData'
import constants from '../../../constants/constants'
import dataModel from '../../../dataModel/dataModel'
import mobileOnlyComponents from '../mobileOnlyComponents'
import * as mobileConversionUtils from '../utils'
import mobileHintsPresetsConstants from './mobilePresetsConstants'
import {
    convertMobilePresetsToMobileHints,
    getComponentsMap,
    getComponentStructure,
    getOrderIndex,
    hasGeneralData,
    hasOffsetData,
    hasSizeData,
    isDeadComponentById,
    isMobileHintsPreset,
    shouldRemoveOffset,
    shouldRemovePresetSize
} from './mobilePresetsUtils'
import {pointerUtils} from '@wix/document-manager-core'
import componentModes from '../../../component/componentModes'
import {createImmutableProxy} from '@wix/wix-immutable-proxy'
import {ReportableError} from '@wix/document-manager-utils'

const {getRepeatedItemPointerIfNeeded} = pointerUtils

const {HINTS_PROPERTIES, PRESET_CONVERSION_CONFIG, MOBILE_HINTS_AUTHORS, PROPERTIES_FOR_MOBILE_PRESETS, ALIASES} =
    mobileHintsPresetsConstants

const setMobileHints = (ps: PS, mobileHintsMap, pagePointer: Pointer, fullStructureMap) => {
    const pageId = pagePointer.id
    const pageActiveModesMap = createImmutableProxy(
        _.set({}, pageId, componentModes.getMobileActiveModesMap(ps, pageId))
    )

    _.forEach(mobileHintsMap, (mobileHints, componentId) => {
        const componentPointer = ps.pointers.full.components.getComponent(componentId, pagePointer)
        if (ps.dal.full.isExist(componentPointer)) {
            const fullChildStructure = fullStructureMap[componentId]
            const fullChildStructureWithModes = getComponentStructure(
                ps,
                fullChildStructure,
                pageId,
                pageActiveModesMap
            )
            if (_.has(fullChildStructureWithModes, ['mobileStructure', 'props'])) {
                mobileHints.props = fullChildStructureWithModes.mobileStructure.props
            }
            dataModel.updateMobileHintsItem(ps, componentPointer, mobileHints)
        }
    })
}

const removeMobileStructures = (ps: PS, componentIds: string[], pagePointer: Pointer) => {
    _.forEach(componentIds, componentId => {
        const componentPointer = ps.pointers.full.components.getComponent(componentId, pagePointer)
        const mobileStructurePointer = ps.pointers.getInnerPointer(componentPointer, 'mobileStructure')
        ps.dal.full.remove(mobileStructurePointer)
    })
}
/**
 * Runs when user duplicates component
 * @param {PS} ps
 * @param {string} pageId
 * @param {AbstractComponent} componentPointer
 * @param {*} desktopComponent
 */
const createDuplicateMobilePreset = (
    ps: PS,
    pageId: string,
    componentPointer: AbstractComponent,
    desktopComponent: any
) => {
    const mobileComponentPointer = ps.pointers.components.getMobilePointer(componentPointer)
    if (!pageId || !mobileComponentPointer || !ps.dal.isExist(mobileComponentPointer)) {
        return
    }
    const isQA = ps.siteAPI.isQaMode()
    const mobileComponent = ps.dal.full.get(mobileComponentPointer)
    /**
     * For new merge flow we need to save mobile structure
     * and mobile hints for mobile conversion, to generate mobile components
     */
    const shouldNotCreatePreset =
        !mobileComponent ||
        (!mobileConversionUtils.shouldEnableImprovedMergeFlow(ps) &&
            !isQA &&
            isMobileHintsPreset(desktopComponent.mobileHints))
    if (shouldNotCreatePreset) {
        return
    }

    const mobileProps = getMobilePropsIfForked(ps, mobileComponentPointer.id, pageId)
    _.set(desktopComponent, 'mobileStructure', {
        layout: santaCoreUtils.objectUtils.cloneDeep(mobileComponent.layout),
        props: mobileProps ? _.omit(mobileProps, 'id') : undefined,
        metaData: {originalCompId: mobileComponentPointer.id, author: MOBILE_HINTS_AUTHORS.DUPLICATE}
    })
}

/**
 * Use to remove preset data from component. It needs for optimize
 * layout to not use layout from origin mobile component when generated
 * mobile structure from scratch
 * @param ps
 * @param parentPointer
 */
const removeAutomaticPresets = (ps: PS, parentPointer: Pointer) => {
    if (mobileConversionUtils.shouldEnableImprovedMergeFlow(ps)) {
        const childrenPointers = ps.pointers.components.getChildren(parentPointer)
        _.forEach(childrenPointers, compPointer => removeAutomaticPresets(ps, compPointer))
        const mobileHints = dataModel.getMobileHintsItem(ps, parentPointer)
        if (_.get(mobileHints, 'author') === MOBILE_HINTS_AUTHORS.DUPLICATE) {
            removeAllPresetData(ps, parentPointer)
        }
        return
    }
    const childrenPointers = ps.pointers.components.getChildren(parentPointer)
    _.forEach(childrenPointers, removeAutomaticPresets.bind(null, ps))

    const mobileHints = dataModel.getMobileHintsItem(ps, parentPointer)
    if (_.get(mobileHints, 'author') === MOBILE_HINTS_AUTHORS.DUPLICATE) {
        dataModel.deleteMobileHintsItem(ps, parentPointer, true)
    }
}

const getMobilePropsIfForked = (ps: PS, componentId: string, pageId: string) => {
    const pagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.MOBILE)
    const componentPointer = ps.pointers.components.getComponent(componentId, pagePointer)
    if (!componentPointer || !ps.dal.isExist(componentPointer)) {
        return null
    }
    const arePropsForked = componentData.isMobileComponentPropertiesSplit(ps, componentPointer)
    if (!arePropsForked) {
        return null
    }
    const propsPointer = dataModel.getPropertyItemPointer(ps, componentPointer)
    return propsPointer && ps.dal.isExist(propsPointer) ? ps.dal.get(propsPointer) : null
}
/**
 *
 * @param ps
 * @param componentPointer
 * @param presetDataToRemove preset data fields look in ./mobilePresetsConstants.js
 */
const removePresetData = (ps: PS, componentPointer: Pointer, presetDataToRemove: string[]) => {
    if (ps.pointers.components.isMobile(componentPointer) || ps.pointers.full.components.isMobile(componentPointer)) {
        return
    }
    const mobileHintsItem = dataModel.getMobileHintsItem(ps, componentPointer)
    if (!isMobileHintsPreset(mobileHintsItem)) {
        return
    }
    const newMobileHintsItem = _.omit(mobileHintsItem, [
        ...presetDataToRemove,
        ...HINTS_PROPERTIES.GENERAL_HINTS_PROPERTIES
    ])
    if (!_.isEmpty(newMobileHintsItem)) {
        dataModel.deleteMobileHintsItem(ps, componentPointer)
        dataModel.updateMobileHintsItem(ps, componentPointer, newMobileHintsItem)
    } else {
        // clearing the query
        dataModel.deleteMobileHintsItem(ps, componentPointer, true)
    }
}

const removePresetSizeData = (ps: PS, componentPointer: Pointer) => {
    removePresetData(ps, componentPointer, HINTS_PROPERTIES.SIZE_DATA)
}

const removeAllPresetData = (ps: PS, componentPointer: Pointer) => {
    const presetDataToRemove = [
        ...HINTS_PROPERTIES.OFFSET_DATA,
        ...HINTS_PROPERTIES.SIZE_DATA,
        ...HINTS_PROPERTIES.GENERAL_PRESET_DATA
    ]
    removePresetData(ps, componentPointer, presetDataToRemove)
}

const removeAllPresetDataRecursively = (ps: PS, componentPointer: Pointer) => {
    removeAllPresetData(ps, componentPointer)
    const children = ps.pointers.components.getChildren(componentPointer)
    _.forEach(children, childPointer => removeAllPresetDataRecursively(ps, childPointer))
}

const removeGeneralPresetData = (ps: PS, componentPointer: Pointer) => {
    removePresetData(ps, componentPointer, HINTS_PROPERTIES.GENERAL_PRESET_DATA)
}

const removeGeneralPresetDataIfNeeded = (ps: PS, componentPointer: Pointer, mobileHintsItem: MobileHints) => {
    if (!hasGeneralData(mobileHintsItem)) {
        return
    }
    removeGeneralPresetData(ps, componentPointer)
}

const removeChildrenGeneralPresetData = (ps: PS, componentPointer: Pointer) => {
    const children = ps.pointers.components.getChildren(componentPointer)
    _.forEach(children, childPointer => removeGeneralPresetData(ps, childPointer))
}

/**
 * @param {PS} ps
 * @param {Pointer} componentPointer
 * @param {Object} newLayout layout data
 * @param {Object} previousLayout layout data
 * @param {MobileHints} mobileHintsItem
 */
const removePresetOffsetDataIfNeeded = (
    ps: PS,
    componentPointer: Pointer,
    newLayout: object,
    previousLayout: object,
    mobileHintsItem: MobileHints
) => {
    removeGeneralPresetDataIfNeeded(ps, componentPointer, mobileHintsItem)
    if (!hasOffsetData(mobileHintsItem)) {
        return
    }
    if (shouldRemoveOffset(newLayout, previousLayout)) {
        removePresetOffsetData(ps, componentPointer)
        const parentPointer = ps.pointers.components.getParent(componentPointer)
        if (!ps.pointers.components.isPage(componentPointer)) {
            removePresetOffsetDataOfChildren(ps, parentPointer)
            removeChildrenGeneralPresetData(ps, parentPointer)
        }
    }
}

/**
 * @param ps
 * @param componentPointer
 * @param {Object} newLayout layout data
 * @param {Object} previousLayout layout data
 * @param mobileHintsItem
 */
const removePresetSizeIfNeeded = (
    ps: PS,
    componentPointer: Pointer,
    newLayout,
    previousLayout,
    mobileHintsItem: MobileHints
) => {
    if (!hasSizeData(mobileHintsItem)) {
        return
    }
    if (shouldRemovePresetSize(newLayout, previousLayout)) {
        removePresetSizeData(ps, componentPointer)
        removeGeneralPresetDataIfNeeded(ps, componentPointer, mobileHintsItem)
    }
}

const removePresetOffsetData = (ps: PS, componentPointer: Pointer) => {
    const presetDataToRemove = HINTS_PROPERTIES.OFFSET_DATA
    removePresetData(ps, componentPointer, presetDataToRemove)
}

const removePresetOffsetDataOfChildren = (ps: PS, componentPointer: Pointer) => {
    if (experiment.isOpen('dm_useExtRemovePresetOffsetDataOfChildren')) {
        ps.extensionAPI.mobilePresets.removePresetOffsetDataOfChildren(componentPointer)
    } else {
        const children =
            ps.pointers.components.getChildren(componentPointer) ||
            ps.pointers.full.components.getChildren(componentPointer)

        _.forEach(children, childPointer => removePresetOffsetData(ps, childPointer))
        removePresetHeight(ps, componentPointer)
    }
}

const removePresetHeight = (ps: PS, componentPointer: Pointer) => {
    const presetDataToRemove = ['recommendedHeight']
    removePresetData(ps, componentPointer, presetDataToRemove)
    const parentPointer =
        ps.pointers.components.getParent(componentPointer) || ps.pointers.full.components.getParent(componentPointer)
    if (parentPointer && !ps.pointers.components.isPage(parentPointer)) {
        removePresetHeight(ps, parentPointer)
    }
}

/**
 * @param ps
 * @param {Array} components array of full components // TODO: Add type
 * @param pageId
 * @returns {Array|null} List of components id
 */
const getRecommendedComponentsOrder = (ps: PS, components, pageId: string) => {
    const hasOrderIndex = component => _.isNumber(getOrderIndex(ps, pageId, component))
    if (!_.isEmpty(components) && !_.every(components, hasOrderIndex)) {
        return null
    }
    return _(components)
        .reject(['conversionData.mobileHints.hidden', true])
        .sortBy(component => getOrderIndex(ps, pageId, component))
        .map('id')
        .value()
}
/**
 *
 * @param ps
 * @param mobileHintsItem
 * @param pageId
 * @param {Object} component
 */
const getMobileProps = (ps: PS, mobileHintsItem: MobileHints, pageId: string, component) => {
    const {originalCompId} = mobileHintsItem
    const originalCompMobileProps = originalCompId ? getMobilePropsIfForked(ps, originalCompId, pageId) : null
    const maybeMobileProps = mobileHintsItem.props
    if (!component?.conversionData) {
        ps.logger.captureError(
            new ReportableError({
                message: `Either the component is undefined or it does not have conversionData`,
                errorType: 'CompMissingConversionData',
                extras: {
                    component,
                    originalCompId,
                    pageId
                }
            })
        )
    }
    return originalCompMobileProps || maybeMobileProps || component.conversionData.mobileProps
}
/**
 * @param ps
 * @param {Array<Object>} components list of full components
 * @param pageId
 */
const applyPresetsToConversionData = (ps: PS, components: any[], pageId: string) => {
    const MOBILE_HINTS_CONVERSION_PROPERTIES = [
        ...HINTS_PROPERTIES.SIZE_DATA,
        ...HINTS_PROPERTIES.OFFSET_DATA,
        ...HINTS_PROPERTIES.GENERAL_PRESET_DATA
    ]
    _.forEach(components, component => {
        const mobileHintsItem = dataModel.getMobileHintsItemById(ps, component.mobileHintsQuery, pageId)
        applyPresetsToConversionData(ps, component.components, pageId)
        if (!isMobileHintsPreset(mobileHintsItem)) {
            return
        }

        const mobileProps = getMobileProps(ps, mobileHintsItem, pageId, component)
        const mobileScale = mobileHintsItem.recommendedScale || component.conversionData.mobileScale
        const recommendedComponentsOrder = getRecommendedComponentsOrder(ps, component.components, pageId)

        const mobileHints = _.pick(mobileHintsItem, MOBILE_HINTS_CONVERSION_PROPERTIES)
        _.assign(
            component.conversionData,
            {
                mobileScale,
                mobileProps
            },
            PRESET_CONVERSION_CONFIG
        )

        _.assign(component.conversionData.mobileHints, mobileHints, {recommendedComponentsOrder})
    })
}
/**
 * Used only for converting presets for components added from add panel
 */
const convertPresetToMobileHints = (ps: PS, desktopStructure, fullStructureMap, pagePointer: Pointer) => {
    const mobileHintsMap = mobileCore.mobileHints.parseMobilePreset(desktopStructure)
    setMobileHints(ps, mobileHintsMap, pagePointer, fullStructureMap)
    removeMobileStructures(ps, _.keys(mobileHintsMap), pagePointer)
}

const convertMobileComponentToMobileHints = (ps: PS, compPointer: Pointer, options: DeepStructureOptions) => {
    if (!ps.dal.isExist(compPointer)) {
        return
    }
    if (mobileOnlyComponents.isMobileOnlyComponent(ps, compPointer.id)) {
        return
    }

    const fullStructure = ps.extensionAPI.components.getDeepStructure(compPointer, options)
    const fullStructureMap = getComponentsMap(fullStructure)
    const componentPointer = getRepeatedItemPointerIfNeeded(compPointer)
    const mobileStructure = fullStructureMap[componentPointer.id]
    const mobileHintsMap = mobileCore.mobileHints.parseMobileStructure(mobileStructure)
    const desktopCompPointer = ps.pointers.components.getDesktopPointer(componentPointer)
    const pagePointer = ps.pointers.components.getPageOfComponent(desktopCompPointer)
    setMobileHints(ps, mobileHintsMap, pagePointer, fullStructureMap)
}

/**
 * @param ps
 * @param {object} compStructure TODO: FIX TYPE
 * @param {{ [compId: string]: object; }} fullStructureMap
 * @param pagePointer
 */
const migrateCompPreset = (
    ps: PS,
    compStructure: CompStructure,
    fullStructureMap: Record<string, unknown>,
    pagePointer: Pointer
) => {
    const desktopStructure = fullStructureMap[compStructure.id]
    if (_.get(desktopStructure, 'mobileStructure')) {
        convertPresetToMobileHints(ps, desktopStructure, fullStructureMap, pagePointer)
    } else {
        _.forEach(compStructure.components, childStructure =>
            migrateCompPreset(ps, childStructure as CompStructure, fullStructureMap, pagePointer)
        )
    }
}

export interface MobileStructure {
    layout: Point
    styleId: string
    components: string[]
    componentType: string
    type: string
    id: string
    props?: any
    style?: any
}

const getMobilePreset = (ps: PS, mobilePointer: Pointer, pageId: string): MobileStructure => {
    const props = getMobilePropsIfForked(ps, mobilePointer.id, pageId)
    const component = ps.dal.get(mobilePointer)

    const mobileStructure: MobileStructure = PROPERTIES_FOR_MOBILE_PRESETS.reduce((structure, propName) => {
        const alias = ALIASES[propName] || propName
        const value = component[propName]

        if (value) {
            structure[alias] = value
        }

        return structure
    }, {} as MobileStructure)

    if (props) {
        mobileStructure.props = props
    }
    return mobileStructure
}

const fillPresetsMap = (
    ps: PS,
    mobilePointer: Pointer,
    pageId: string,
    componentsPreset: Map<string, MobileStructure>
) => {
    const children = ps.pointers.components.getChildren(mobilePointer)

    if (children) {
        children.forEach(child => {
            const mobileStructure: MobileStructure = getMobilePreset(ps, child, pageId)
            const isNonLayoutComponent = componentsMetaData.public.getMobileConversionConfigByName(
                ps,
                mobileStructure,
                'nonLayoutComponent',
                pageId
            )

            if (isNonLayoutComponent) {
                fillPresetsMap(ps, child, pageId, componentsPreset)
                return
            }

            componentsPreset.set(mobileStructure.id, mobileStructure)
            fillPresetsMap(ps, child, pageId, componentsPreset)
        })
    }
}

const getMobilePresets = (ps: PS, mobilePointer: Pointer, pageId: string): Map<string, MobileStructure> => {
    const componentsPreset = new Map<string, MobileStructure>()
    fillPresetsMap(ps, mobilePointer, pageId, componentsPreset)
    return componentsPreset
}

const createMobileHintsForPageComponents = (ps: PS, pageId: string) => {
    const pagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.MOBILE)
    const componentsPresets = getMobilePresets(ps, pagePointer, pageId)
    const mobileHintsMap = convertMobilePresetsToMobileHints(ps, pagePointer, componentsPresets)

    for (const [id, mobileHints] of mobileHintsMap) {
        const componentPointer = ps.pointers.getPointer(id, constants.VIEW_MODES.DESKTOP)

        if (mobileOnlyComponents.isMobileOnlyComponent(ps, id) || isDeadComponentById(componentPointer.id)) {
            continue
        }

        dataModel.updateMobileHintsItem(ps, componentPointer, mobileHints)
    }
    /**
     * Need to set author equals to studio for page, because it used in check inside migration. We don't need
     * other mobile data for page like layout because it should calculated by algorithm
     */
    dataModel.updateMobileHintsItem(ps, ps.pointers.getPointer(pageId, constants.VIEW_MODES.DESKTOP), {
        author: MOBILE_HINTS_AUTHORS.STUDIO
    })
}

/**
 * @description
 * function migrate component preset kept as mobileStructure in component preset JSON,
 * and convert it to mobileHints item to further processing while mobile conversion runs
 * @param ps
 * @param compPointer
 */
const handleMobileStructure = (ps: PS, compPointer: Pointer) => {
    /**
     * Handle adding of new page from template
     */
    if (mobileConversionUtils.shouldEnableImprovedMergeFlow(ps)) {
        if (ps.pointers.full.components.isPage(compPointer)) {
            createMobileHintsForPageComponents(ps, compPointer.id)
            return
        }
    }
    const pagePointer = ps.pointers.components.getPageOfComponent(compPointer)

    // This should not be converted to absolute layout yet, I doubt it will work and
    // maybe there will be another way to achieve whatever this was meant for
    // DM-9196
    const fullStructure = ps.extensionAPI.components.getDeepStructure(compPointer)

    const fullStructureMap = getComponentsMap(fullStructure)
    migrateCompPreset(ps, fullStructure, fullStructureMap, pagePointer)
}

const beforeAddRoot = (ps: PS, componentPointer: Pointer, containerPointer: Pointer) => {
    if (ps.pointers.components.isMobile(componentPointer) || ps.pointers.components.isPage(componentPointer)) {
        return
    }
    removePresetOffsetDataOfChildren(ps, containerPointer)
    removePresetOffsetData(ps, componentPointer)
    if (ps.pointers.components.isPage(containerPointer)) {
        return
    }
    removeAllPresetDataRecursively(ps, componentPointer)
}

export default {
    createDuplicateMobilePreset,
    removeAutomaticPresets,
    removeAllPresetDataRecursively,
    removeGeneralPresetDataIfNeeded,
    removePresetOffsetDataOfChildren,
    removePresetOffsetDataIfNeeded,
    removePresetSizeIfNeeded,
    handleMobileStructure,
    setMobileHints,
    removePresetOffsetData,
    applyPresetsToConversionData,
    getMobilePropsIfForked,
    removePresetData,
    removePresetHeight,
    createMobileHintsForPageComponents,
    convertPresetToMobileHints,
    convertMobileComponentToMobileHints,
    getMobilePresets,
    getMobilePreset,
    beforeAddRoot
}
