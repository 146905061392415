import _ from 'lodash'
import type {DocumentManager} from '@wix/document-manager-core'
import {constants} from '@wix/document-manager-extensions'
import {deepClone} from '@wix/wix-immutable-proxy'
import {cleanRef} from './utils/migrationUtil'
import {MASTER_PAGE_ID} from '../../constants/constants'
import type {PageMigrator} from '../dataMigrationRunner'

const fixBrokenStyleRefArray = (documentManager: DocumentManager, pageId: string) => {
    const {dal} = documentManager
    const pageFilter = dal.queryFilterGetters.getPageCompFilter(pageId)
    const allRefArraysInStyleNS = documentManager.dal.query(
        constants.DATA_TYPES.theme,
        pageFilter,
        item => item.type === 'RefArray'
    )
    _.forOwn(allRefArraysInStyleNS, refArrStyleObj => {
        const maybeRefArrayId = refArrStyleObj.values[0]
        if (_.isNil(maybeRefArrayId)) {
            return
        }
        const topRefArrPointer = documentManager.pointers.data.getThemeItem(refArrStyleObj.id, MASTER_PAGE_ID)
        const innerMaybeRefArrayStyleValuePointer = documentManager.pointers.data.getThemeItem(
            cleanRef(maybeRefArrayId),
            MASTER_PAGE_ID
        )
        const styleOrVariantRelation = dal.get(innerMaybeRefArrayStyleValuePointer)
        if (styleOrVariantRelation && styleOrVariantRelation.type === 'RefArray') {
            const styleToReplaceId = styleOrVariantRelation.values[0]
            const ptr = documentManager.pointers.data.getThemeItem(cleanRef(styleToReplaceId), MASTER_PAGE_ID)
            const styleToReplace = dal.get(ptr)
            if (styleToReplace.type === 'ComponentStyle') {
                const refArr = deepClone(dal.get(topRefArrPointer))
                refArr.values[0] = `#${styleToReplace.id}`

                documentManager.dal.set(topRefArrPointer, refArr)
                documentManager.dal.remove(innerMaybeRefArrayStyleValuePointer)
            }
        }
    })
}

const migratePage = (documentManager: DocumentManager, pageId: string) => {
    fixBrokenStyleRefArray(documentManager, pageId)
}

const name = 'removeInvalidStyleRefArr'
const version = 1

export const removeInvalidStyleRefArr: PageMigrator = {
    migratePage,
    name,
    version
}
