import type {
    ClientSpecMapEntry,
    EditorClientSpecMapEntry,
    TPAWidget,
    DataItem,
    AppData
} from '@wix/document-services-types'
import _ from 'lodash'
import {TPA_CONSTANTS} from '../../constants/constants'

const CONTROLLER_DATA_TYPE = 'AppController'

export const isAppRevoked = (appData: ClientSpecMapEntry) => _.get(appData, ['permissions', 'revoked'])
export const isAppExcludedFromAutoRevoke = (appData: ClientSpecMapEntry) =>
    _.get(appData, ['appFields', 'excludeFromAutoRevoke'])
export const isSection = (appData: EditorClientSpecMapEntry, widgetId: string) =>
    !!_.get(appData?.widgets, [widgetId, 'appPage'])

const getTpaPageIdFromWidgetId = (appData: EditorClientSpecMapEntry, widgetId: string) =>
    _.get(appData?.widgets, [widgetId, 'appPage', 'id'])
export const isSectionInstalledByTpaPageId = (
    pagesData: Record<string, any>,
    appData: EditorClientSpecMapEntry,
    widgetId: string
) => {
    if (!isSection(appData, widgetId)) {
        throw new Error(`widgetId ${widgetId} is not section`)
    }
    const tpaPageId = getTpaPageIdFromWidgetId(appData, widgetId)
    return _.some(pagesData, {appDefinitionId: appData.appDefinitionId, tpaPageId})
}
export const computeHidePage = (widgetData: TPAWidget, isHidden: boolean | undefined, defaultValue: boolean) => {
    const hidePage = _.defaultTo(isHidden, _.get(widgetData, ['componentFields', 'hiddenInPagesPanel']))
    return _.defaultTo(hidePage, defaultValue)
}

export const isSectionInstalledByTpaPageIdPredicate = (appData: EditorClientSpecMapEntry, widgetId: string) => {
    if (!isSection(appData, widgetId)) {
        throw new Error(`widgetId ${widgetId} is not section`)
    }
    const tpaPageId = getTpaPageIdFromWidgetId(appData, widgetId)
    return (partialPageData: {appDefinitionId?: string; tpaPageId?: string}) =>
        partialPageData.appDefinitionId === appData.appDefinitionId && partialPageData.tpaPageId === tpaPageId
}

export const hasEditorPlatformPart = (appData: ClientSpecMapEntry) =>
    _.get(appData, ['appFields', 'platform', 'editorScriptUrl'])

const hasViewerPlatformPart = (appData: ClientSpecMapEntry) =>
    _.get(appData, ['appFields', 'platform', 'viewerScriptUrl'])

export const isAppPlatformEditorOnly = (appData: ClientSpecMapEntry) =>
    // @ts-ignore
    hasEditorPlatformPart(appData) && !hasViewerPlatformPart(appData) && _.isEmpty(appData.widgets)

export const getAppVersion = (appData: ClientSpecMapEntry) =>
    _.get(appData, ['appFields', 'semanticVersion']) ?? _.get(appData, ['appFields', 'installedVersion'])

export const isEditorOrHybridApp = (appData: ClientSpecMapEntry) => {
    const appType = _.get(appData, ['appType'])
    return appType === 'Hybrid' || appType === 'Editor'
}

export const isStudioApp = (appData: ClientSpecMapEntry) => _.has(appData, ['appFields', 'platform', 'studio'])

export const isAppControllerData = (dataItem: DataItem) => {
    return dataItem?.type === CONTROLLER_DATA_TYPE
}

export const isTpaByDataType = (dataType: string) => {
    return _.includes(TPA_CONSTANTS.DATA_TYPE, dataType)
}

export const isUnifiedComponentData = (dataType: string) => {
    return dataType === 'CustomElement'
}

export const isDashboardAppOnly = (appData: ClientSpecMapEntry) => {
    const appType = _.get(appData, ['appType'])
    return hasEditorPlatformPart(appData) && appType === 'Dashboard'
}

export const appHasRouter = (app: ClientSpecMapEntry, routerConfigMap: any) => {
    return hasEditorPlatformPart(app) && _.some(routerConfigMap, {appDefinitionId: _.get(app, ['appDefinitionId'])})
}

const isHybridAppFromAppData = (appData: AppData) => {
    if (_.isUndefined(appData) || _.isUndefined(appData.appType)) {
        return false
    }
    return appData.appType === 'Hybrid'
}

export const isPendingAppData = (appData: AppData) =>
    appData?.requiresEditorComponent && isHybridAppFromAppData(appData)
