import _ from 'lodash'
import type {ObjMap, FontComparisonEntry} from '../../types'

import * as fontSizeComparisonTable from './fontSizeComparisonTable'
import * as fontSizeDefinitions from './fontSizeDefinitions'

const BASELINE_REFERENCE_DATA = [15, 40, 82]

function approxMatchByTwoLines(xVector: number[], yVector: number[], value: number): number {
    const from = {
        x: value < xVector[1] ? xVector[0] : xVector[1],
        y: value < xVector[1] ? yVector[0] : yVector[1]
    }
    const to = {
        x: value < xVector[1] ? xVector[1] : xVector[2],
        y: value < xVector[1] ? yVector[1] : yVector[2]
    }
    const m = (from.y - to.y) / (from.x - to.x)
    const n = to.y - to.x * m
    return value * m + n
}

export function getFontSizeByFontName(
    fontName: string,
    fontSize: number,
    tableEntries: ObjMap<FontComparisonEntry> = fontSizeComparisonTable.tableEntries
): number {
    if (fontSize === 0) {
        return 0
    }

    const comparisonEntry = tableEntries[fontName]
    if (!comparisonEntry) {
        return fontSize
    }

    return approxMatchByTwoLines(comparisonEntry.referencePoints, BASELINE_REFERENCE_DATA, fontSize)
}

function clamp(value: number, min: number, max: number) {
    return Math.max(Math.min(value, max), min)
}

export function convertFontSizeByTextLength(fontSize: number, characterCount: number): number {
    const table = fontSizeDefinitions.fontSizeCharacterCountMap
    const fsRange = fontSizeDefinitions.fontSizeRange
    const ccRange = fontSizeDefinitions.characterCountRange
    const fsAdjusted = Math.round(clamp(fontSize, fsRange.min, fsRange.max)) - fsRange.min
    const ccAdjusted = Math.round(clamp(characterCount, ccRange.min, ccRange.max)) - ccRange.min
    return _.get(table, [fsAdjusted, ccAdjusted]) as number
}
