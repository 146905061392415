import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import constants from '../constants/constants'
import dataIds from '../dataModel/dataIds'
import dataModel from '../dataModel/dataModel'
import validations from './validations'

const {DATA_TYPES, VARIANTS} = constants

const createBreakpointsData = (ps: PS, currentId: string, compPointer: Pointer, rangeArray, pageId: string) => {
    validations.validateBreakpointsDataAdd(ps, compPointer)
    const id = currentId || dataIds.generateNewId(DATA_TYPES.variants)
    const breakpointPointerDataPointer = ps.pointers.data.getVariantsDataItem(id, pageId)
    const values = getBreakpointRangesArray(rangeArray)
    validations.validateNewBreakpointsIds(ps, values, pageId)
    return dataModel.createVariantData(ps, breakpointPointerDataPointer, VARIANTS.TYPES.BREAKPOINTS, {
        compPointer,
        values
    })
}

const getBreakpointRangesArray = rangeArray =>
    rangeArray.map(item => {
        _.assign(item, {metaData: {schemaVersion: '2.0'}})
        _.assign(item, {id: item.id || dataIds.generateNewId(DATA_TYPES.variants)})
        return item
    })

export default {
    createBreakpointsData
}
