import type {CompStructure, Pointer, PS} from '@wix/document-services-types'
import constants, {MoveDirections, ResizeSides} from '../../constants/constants'
import {dockUtils} from '@wix/santa-core-utils'

const {RESIZE_SIDES, MOVE_DIRECTIONS} = constants

function isStretched(ps: PS, componentPointer: Pointer) {
    const compLayoutPointer = ps.pointers.getInnerPointer(componentPointer, 'layout')
    const compLayout = ps.dal.get(compLayoutPointer)
    return dockUtils.isStretched(compLayout)
}

function isHorizontallyStretchedToScreen(ps: PS, componentPointer: Pointer) {
    const compLayoutPointer = ps.pointers.getInnerPointer(componentPointer, 'layout')
    const compLayout = ps.dal.get(compLayoutPointer)
    return dockUtils.isHorizontalDockToScreen(compLayout)
}

function moveDirections(isFullWidth: boolean): MoveDirections[] {
    if (isFullWidth) {
        return [MOVE_DIRECTIONS.VERTICAL]
    }
    return [MOVE_DIRECTIONS.HORIZONTAL, MOVE_DIRECTIONS.VERTICAL]
}

export default {
    mobileConversionConfig: {
        category: 'graphic'
    },

    styleCanBeApplied: true,
    rotatable(ps: PS, compPointer: Pointer) {
        return !isStretched(ps, compPointer)
    },

    resizableSides(/*ps, compPointer*/): ResizeSides[] {
        return [RESIZE_SIDES.LEFT, RESIZE_SIDES.RIGHT]
    },

    moveDirections(ps: PS, compPointer: Pointer) {
        return moveDirections(isStretched(ps, compPointer))
    },

    fullWidth(ps: PS, compPointer: Pointer) {
        return isHorizontallyStretchedToScreen(ps, compPointer)
    },

    fullWidthByStructure(ps: PS, compStructure: CompStructure) {
        return !!dockUtils.isHorizontalDockToScreen(compStructure.layout)
    },

    canBeStretched: true,

    heightAuto: true
}
