/**
 * Created by alexandreroitman on 07/11/2016.
 */

import _ from 'lodash'

const WARNING = 'warning'
const ERROR = 'error'

function getDeprecatedComponents() {
    return {
        'wysiwyg.viewer.components.StripContainer': {
            deprecated: WARNING,
            message: 'This component will be deprecated'
        },
        'wysiwyg.viewer.components.ClipArt': {
            deprecated: WARNING,
            message: 'This component will be deprecated'
        }
    }
}

export default {
    isComponentDeprecated(compType: string) {
        return _.get(getDeprecatedComponents(), [compType, 'deprecated']) === ERROR
    },

    shouldWarnForDeprecation(compType: string) {
        return _.get(getDeprecatedComponents(), [compType, 'deprecated']) === WARNING
    },

    getDeprecationMessage(compType: string) {
        return `${compType}|${_.get(getDeprecatedComponents(), [compType, 'message'])}`
    }
}
