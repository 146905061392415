/* eslint-disable promise/prefer-await-to-then */
import _ from 'lodash'
import type {BICallbacks} from '../../saveAPI/createSaveAPI'
import type {SaveTaskDefinition} from '../../saveAPI/lib/registry'
import type {ImmutableSnapshot} from '../../types'
import saveCodeConsts from './saveCodeConsts'
import wixCodeUserScriptsService from '../utils/wixCodeUserScriptsService'
import codeAppInfoUtils from '../utils/codeAppInfo'
import constants from '../utils/constants'
import schemaUtils from '../utils/schemaUtils'
import filesDAL from '../services/filesDAL'
import wixDataSchemas from '../services/wixDataSchemas'
import wixCodeMonitoring from '../services/wixCodeMonitoringWrapper'
import fileDescriptorUtils from '../utils/fileDescriptorUtils'
import type {SnapshotDal} from '@wix/document-manager-core'
import {deepClone} from '@wix/wix-immutable-proxy'
import {fileSystemServiceFacade} from '../services/fileSystemServiceFacade'
import {getVirtualDescriptor} from '../services/IFileSystemService'

const dataProviderFromSnapshotDal = (snapshotDal: SnapshotDal, wixCodeSpec) => {
    const getValue = (type: string, id?: string, innerPath: string[] = []) => {
        const pointer = {type, id, innerPath}
        return deepClone(snapshotDal.getValue(pointer))
    }
    return {
        rendererModel: (id: string, innerPath: string[]) => getValue('rendererModel', id, innerPath),
        serviceTopology: (key: string) => getValue('serviceTopology', 'serviceTopology', [key]),
        wixCodeNonUndoable: (wixCodeInnerPath: string[] = []) =>
            getValue('wixCode', 'wixCode', ['nonUndoable', ...wixCodeInnerPath]),
        wixCodeUndoable: (wixCodeInnerPath: string[] = []) =>
            getValue('wixCodeUndoable', 'wixCode', ['undoable', ...wixCodeInnerPath]),
        pagesPlatformApplications: (appId: string) =>
            getValue('pagesPlatformApplications', 'pagesPlatformApplications', [appId]),
        wixCodeSpec,
        wixCodeInstanceId: wixCodeSpec ? wixCodeSpec.instanceId : ''
    }
}

const getWixCodeSpec = extensionAPI => {
    const specMap = extensionAPI.rendererModel.getClientSpecMap()
    return _.find(specMap, {type: constants.WIX_CODE_SPEC_TYPE})
}

const createRunTask =
    () =>
    (
        lastSnapshot: ImmutableSnapshot,
        currentSnapshot: ImmutableSnapshot,
        resolve,
        reject,
        bi: BICallbacks,
        options,
        lastSnapshotDal: SnapshotDal,
        currentSnapshotDal: SnapshotDal,
        extensionsAPI
    ) => {
        const wixCodeSpec = getWixCodeSpec(extensionsAPI)

        const currentDataProvider = dataProviderFromSnapshotDal(currentSnapshotDal, wixCodeSpec)

        if (!wixCodeSpec) {
            resolve()
            return
        }

        const traceEnd = wixCodeMonitoring.traceWithExtensionAPI(extensionsAPI, {action: 'saveCode'})

        Promise.resolve()
            // @ts-expect-error
            .then(function runSave() {
                const changes = filesDAL.getChangesBetweenSnapshots(lastSnapshot, currentSnapshot)
                return saveCode(extensionsAPI, changes, wixCodeSpec)
            })
            .then(
                function onSuccess({
                    // @ts-expect-error
                    message,
                    // @ts-expect-error
                    params,
                    // @ts-expect-error
                    savedPaths,
                    // @ts-expect-error
                    modifiedSchemas,
                    // @ts-expect-error
                    directoryFlagByDeletedPath,
                    // @ts-expect-error
                    pagesWithoutCode
                }) {
                    traceEnd({message, params})
                    resolve(
                        createSaveResult(
                            extensionsAPI,
                            currentDataProvider,
                            savedPaths,
                            modifiedSchemas,
                            directoryFlagByDeletedPath,
                            pagesWithoutCode
                        )
                    )
                },
                function onError(error) {
                    if (error?.error && error.params) {
                        traceEnd({level: wixCodeMonitoring.levels.ERROR, message: error.error, params: error.params})
                        reject(error.error)
                    } else {
                        error = _.isError(error) ? error : new Error(error)
                        traceEnd({level: wixCodeMonitoring.levels.ERROR, message: error})
                        reject(error)
                    }
                }
            )
    }

const createRunTaskWithProvision =
    runTask =>
    (
        lastSnapshot,
        currentSnapshot,
        resolve,
        reject,
        bi: BICallbacks,
        options,
        lastSnapshotDal: SnapshotDal,
        currentSnapshotDal: SnapshotDal,
        extensionsAPI
    ) => {
        runTask(
            lastSnapshot,
            currentSnapshot,
            resolve,
            reject,
            bi,
            options,
            lastSnapshotDal,
            currentSnapshotDal,
            extensionsAPI
        )
    }

function createSaveResult(
    extensionsAPI,
    dataProvider,
    savedPaths = [],
    modifiedSchemas?,
    directoryFlagByDeletedPath?,
    pagesWithoutCode?
) {
    updateCacheKillers(extensionsAPI, savedPaths)
    const changesForPagesWithoutCode = createChangesForPagesWithoutCode(dataProvider, pagesWithoutCode)

    _.forOwn(directoryFlagByDeletedPath, (v, filePath) => {
        extensionsAPI.wixCodeFileSystem.clearDeletedPath(filePath)
    })
    if (changesForPagesWithoutCode) {
        return {
            changes: changesForPagesWithoutCode
        }
    }
}

function createChangesForPagesWithoutCode(dataProvider, pagesWithoutCode: string[]) {
    const wixCodeAppPath = ['pagesPlatformApplications', constants.WIX_CODE_APP_ID]
    if (pagesWithoutCode) {
        const pageCodeMap = dataProvider.pagesPlatformApplications(constants.WIX_CODE_APP_ID)
        if (pageCodeMap) {
            pagesWithoutCode.forEach(pageId => _.set(pageCodeMap, pageId, false))
            return [
                {
                    path: wixCodeAppPath,
                    value: pageCodeMap
                }
            ]
        }
    }
}

function updateCacheKillers(extensionAPI, savedPaths) {
    const relevantPaths = savedPaths.filter(path => wixCodeUserScriptsService.filePathAffectsBundles(path))
    if (_.isEmpty(relevantPaths)) {
        return
    }
    const allSingleBundles = _.every(relevantPaths, path => wixCodeUserScriptsService.filePathAffectsSingleBundle(path))
    if (allSingleBundles) {
        updatePerBundleCacheKiller(extensionAPI, relevantPaths)
    } else {
        resetAllBundleCacheKillers(extensionAPI)
    }
}

function updatePerBundleCacheKiller(extensionAPI, filePaths = []) {
    filePaths.forEach(path => {
        const bundleId = wixCodeUserScriptsService.bundleIdFromFilePath(path)
        extensionAPI.wixCodeFileSystem.updateBundleCacheKiller(bundleId)
    })
}

function resetAllBundleCacheKillers(extensionAPI) {
    extensionAPI.wixCodeFileSystem.updateGlobalBundleCacheKiller()
    extensionAPI.wixCodeFileSystem.clearBundleCacheKillers()
}

function saveCode(extensionsAPI, changes, wixCodeSpec) {
    if (filesDAL.isChangesEmpty(changes)) {
        return Promise.resolve({message: 'no changes'})
    }

    const codeAppInfo = createCodeAppInfo(extensionsAPI, wixCodeSpec)
    const fileIdsToSave = changes.toSave.map(({fileId}) => fileId)
    const params = {
        filesToSave: JSON.stringify(fileIdsToSave),
        filesToCopy: JSON.stringify(changes.toCopy),
        filesToDelete: JSON.stringify(changes.toDelete)
    }

    function rethrowErrorWithParams(error) {
        throw {error, params} //eslint-disable-line no-throw-literal
    }

    try {
        const savePromises = createSavePromises(extensionsAPI, codeAppInfo, changes.toSave)
        const copyPromises = createCopyPromises(extensionsAPI, codeAppInfo, changes.toCopy)
        const deletionPromises = createDeletionPromises(extensionsAPI, codeAppInfo, changes.toDelete)
        const allPromises = savePromises.concat(copyPromises, deletionPromises)

        return Promise.all(allPromises)
            .then(results => {
                const modifiedSchemas = _(results).filter('modifiedSchemas').flatMap('modifiedSchemas').value()
                const pagesWithoutCode = _(results).filter('pagesWithoutCode').flatMap('pagesWithoutCode').value()
                return {
                    message: 'changes saved',
                    params,
                    savedPaths: fileIdsToSave,
                    modifiedSchemas,
                    directoryFlagByDeletedPath: changes.toDelete,
                    pagesWithoutCode
                }
            })
            .catch(rethrowErrorWithParams)
    } catch (error) {
        return Promise.reject({error, params})
    }
}
function createCodeAppInfo(extensionsAPI, wixCodeSpec) {
    const baseUrl = extensionsAPI.serviceTopology.getServiceRoot(['wixCodeIdeServerUrl'])
    const gridAppId = extensionsAPI.wixCode.getEditedGridAppId()
    return codeAppInfoUtils.createCodeAppInfo({
        baseUrl,
        editorRootUrl: extensionsAPI.serviceTopology.getServiceRoot(['editorRootUrl']),
        appId: gridAppId,
        signedInstance: wixCodeSpec.instance,
        instanceId: wixCodeSpec.instanceId
    })
}

function createSavePromises(extensionsAPI, codeAppInfo, filesToSave) {
    const [schemaFiles, nonSchemaFiles] = _.partition(filesToSave, file => schemaUtils.isSchemaFile(file.fileId))

    const saveSchemas = schemaFiles.map(schemaFile =>
        saveFile(extensionsAPI, codeAppInfo, schemaFile.fileId, schemaFile.content)
    )

    if (_.isEmpty(nonSchemaFiles)) {
        return saveSchemas
    }

    const saveNonSchemas = fileSystemServiceFacade.bulkWrite(extensionsAPI, codeAppInfo, nonSchemaFiles)

    return [...saveSchemas, saveNonSchemas]
}

function createCopyPromises(extensionsAPI, codeAppInfo, toCopy) {
    return _.map(toCopy, function (entry) {
        return copyFile(extensionsAPI, codeAppInfo, entry.srcFileId, entry.destFileId)
    })
}

function createDeletionPromises(extensionsAPI, codeAppInfo, directoryFlagByDeletedPath) {
    return _.map(directoryFlagByDeletedPath, (isDirectory, deletedPath) =>
        deleteItem(extensionsAPI, codeAppInfo, deletedPath, isDirectory)
    )
}

async function saveFile(extensionsAPI, codeAppInfo, fileId: string, content: string) {
    if (schemaUtils.isSchemaFile(fileId)) {
        const result = await wixDataSchemas.save(
            extensionsAPI,
            codeAppInfo,
            schemaUtils.getSchemaIdFromFilePath(fileId),
            JSON.parse(content)
        )
        return result ? {modifiedSchemas: result} : result
    }
    const descriptor = getVirtualDescriptor(fileId, false)
    return fileSystemServiceFacade.writeFile(extensionsAPI, codeAppInfo, descriptor, content)
}

function copyFile(extensionsAPI, codeAppInfo, srcFileId: string, destFileId: string) {
    const srcFileDescriptor = getVirtualDescriptor(srcFileId, false)
    const destFileDescriptor = getVirtualDescriptor(destFileId, false)
    const lastSep = destFileId.lastIndexOf('/')
    const targetFolderPath = destFileId.substr(0, lastSep)
    const targetFolder = getVirtualDescriptor(targetFolderPath, true)
    const newName = destFileId.substr(lastSep + 1)
    return fileSystemServiceFacade
        .copy(extensionsAPI, codeAppInfo, srcFileDescriptor, targetFolder, newName)
        .catch(e => {
            const response = e.xhr ?? e.response
            if (response && response.status === 404 && fileDescriptorUtils.isPageFile(srcFileDescriptor)) {
                return Promise.resolve({
                    pagesWithoutCode: [srcFileDescriptor, destFileDescriptor].map(fileDescriptorUtils.getPageId)
                })
            }
            return Promise.reject(e)
        })
}

async function deleteItem(extensionsAPI, codeAppInfo, path: string, isDirectory: boolean) {
    if (!isDirectory && schemaUtils.isSchemaFile(path)) {
        const result = await wixDataSchemas.remove(codeAppInfo, schemaUtils.getSchemaIdFromFilePath(path))
        return result ? {modifiedSchemas: result} : result
    }
    const itemDescriptor = getVirtualDescriptor(path, isDirectory)
    return fileSystemServiceFacade.deleteItem(extensionsAPI, codeAppInfo, itemDescriptor)
}

const createTask = (ps): SaveTaskDefinition => {
    const runTask = createRunTask()
    const runTaskWithProvision = createRunTaskWithProvision(runTask)
    const task: SaveTaskDefinition = {
        partialSave: runTaskWithProvision,
        fullSave: runTaskWithProvision,
        saveAsTemplate: runTaskWithProvision,
        publish(currentData, extensionAPI, resolve) {
            resolve()
        },
        getTaskName: saveCodeConsts.getTaskName,
        getSnapshotTags: saveCodeConsts.getSnapshotTags
    }
    const taskLifecycleInterface = {
        getLastState() {
            return ps.extensionAPI.wixCodeFileSystem.snapshots.getLastSnapshot()
        },
        getCurrentState() {
            return ps.extensionAPI.wixCodeFileSystem.snapshots.getPendingSnapshot()
        },
        onTaskSuccess() {
            ps.extensionAPI.wixCodeFileSystem.snapshots.commitPendingSnapshot()
        },
        takeSnapshot() {
            ps.extensionAPI.wixCodeFileSystem.snapshots.markPendingSnapshot()
        },
        rollback() {
            ps.extensionAPI.wixCodeFileSystem.snapshots.rollbackSnapshot()
        },
        requiresCurrentSnapshotDal: true
    }
    return _.assign(task, taskLifecycleInterface)
}
export default (ps): SaveTaskDefinition => createTask(ps)
