import _ from 'lodash'
import theme from '../../../theme/theme'
import constants from '../../../constants/constants'
import isSystemStyle from '../../../theme/isSystemStyle'
import type {DsFixer} from '../../dsDataFixers'

const fixer: DsFixer = {
    exec(ps) {
        const styles = _(ps)
            .thru(() => theme.styles.getAllIds(ps, constants.MASTER_PAGE_ID))
            // @ts-expect-error
            .without(constants.STYLES.THEME_DATA_ID)
            .map(function (styleId) {
                return _.merge(theme.styles.get(ps, styleId, constants.MASTER_PAGE_ID), {id: styleId})
            })
            .filter(function (style) {
                return style.styleType === constants.STYLES.TYPES.SYSTEM && !isSystemStyle(ps, style.id)
            })
            .value()

        _.forEach(styles, function (style) {
            style.styleType = constants.STYLES.TYPES.CUSTOM
            style.type = ps.runtimeConfig.stylesPerPage
                ? constants.STYLES.COMPONENT_STYLE
                : constants.STYLES.TOP_LEVEL_STYLE
            ps.dal.set(ps.pointers.data.getThemeItem(style.id, constants.MASTER_PAGE_ID), style)
        })

        return _.size(styles)
    },
    name: 'unknownSystemStylesFixer',
    version: 1
}
export default fixer
