import type {DAL, ExtensionAPI} from '@wix/document-manager-core'
import type {PageExtensionAPI, SchemaExtensionAPI} from '../../..'
import type {CompRef, ExternalRefsMap, Pointers, RawComponentExportStructure} from '@wix/document-services-types'
import {DATA_TYPES, VIEW_MODES} from '../../../constants/constants'
import {generateItemIdWithPrefix} from '../../../utils/dataUtils'
import {stripHashIfExists} from '../../../utils/refArrayUtils'
import {DEFAULT_COMP_LAYOUT} from '../../components'

const {DESKTOP} = VIEW_MODES

export const withHash = (id: string) => `#${id}`

const getIdPrefixByNamespace = (namespace: string): string => {
    if (VIEW_MODES[namespace]) {
        return 'comp'
    }

    return namespace
}

export const generateItemIdByNamespace = (namespace: string) => {
    const prefix = getIdPrefixByNamespace(namespace)
    return generateItemIdWithPrefix(prefix)
}

export const getNewItemId = (
    itemId: string | undefined,
    namespace: string,
    externalRefs: ExternalRefsMap,
    originalItemId?: string
): string => {
    if (!itemId) {
        return originalItemId ?? generateItemIdByNamespace(namespace)
    }

    let id = externalRefs[itemId]
    if (!id) {
        id = originalItemId ?? generateItemIdByNamespace(namespace)
        externalRefs[itemId] = id
    }

    return id
}

export const getNewItemRef = (
    originalId: string | undefined,
    namespace: string,
    shouldAddHash: boolean,
    constantValues: Set<string> | undefined,
    externalRefs: ExternalRefsMap
): string => {
    if (originalId) {
        originalId = stripHashIfExists(originalId)

        if (constantValues?.has(originalId)) {
            return originalId
        }
    }

    const id = getNewItemId(originalId, namespace, externalRefs)
    if (shouldAddHash) {
        return withHash(id)
    }

    return id
}

export const addPlaceholderComponent = (
    componentId: string | undefined,
    containerPointer: CompRef,
    dal: DAL,
    pointers: Pointers
): CompRef => {
    const containerChildrenPointer = pointers.structure.getChildrenContainer(containerPointer)
    const pagePointer = pointers.structure.getPageOfComponent(containerPointer)
    componentId ??= generateItemIdByNamespace(DESKTOP)
    const componentPointer = pointers.getPointer(componentId, DESKTOP) as CompRef

    dal.set(componentPointer, {
        id: componentId,
        parent: containerPointer.id,
        components: [],
        layout: DEFAULT_COMP_LAYOUT,
        metaData: {pageId: pagePointer.id}
    })

    const containerChildren = dal.get(containerChildrenPointer) ?? []
    dal.set(containerChildrenPointer, [...containerChildren, componentId])

    return componentPointer
}

export const addPlaceholderPage = (
    pageId: string | undefined,
    pageExport: RawComponentExportStructure,
    pointers: Pointers,
    extensionAPI: ExtensionAPI
): CompRef => {
    const {page} = extensionAPI as PageExtensionAPI
    const pagePointer = pointers.getPointer(pageId ?? generateItemIdByNamespace(DESKTOP), DESKTOP) as CompRef
    return page.addPage(pageExport.data.data, undefined, pagePointer) as CompRef
}

export const isSystemDataItem = (
    componentQuery: string | undefined,
    namespace: string,
    extensionAPI: ExtensionAPI
): boolean => {
    const {schemaAPI} = extensionAPI as SchemaExtensionAPI
    return componentQuery !== undefined && namespace === DATA_TYPES.theme && schemaAPI.isSystemStyle(componentQuery)
}
