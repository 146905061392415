import _ from 'lodash'
import type {DocumentManager} from '@wix/document-manager-core'
import {constants, DataModelExtensionAPI} from '@wix/document-manager-extensions'
import type {PageMigrator} from '../dataMigrationRunner'
const {DATA_TYPES} = constants
const fixerVersionsNamespace = DATA_TYPES.fixerVersions

const removeFixerVersionFromComponent = ({dal, pointers}: DocumentManager, pageId: string) => {
    _.forEach(constants.VIEW_MODES, viewMode => {
        const fixerVersionsQueryPointer = pointers.page.getFixerVersionsQueryPointer(pageId, viewMode)
        if (dal.has(fixerVersionsQueryPointer)) {
            dal.remove(fixerVersionsQueryPointer)
        }
    })
}
const cleanBrokenFixerVersions = (documentManager: DocumentManager, pageId: string) => {
    const {logger, pointers, dal, extensionAPI} = documentManager
    const fixerVersionsQueryPointer = pointers.page.getFixerVersionsQueryPointer(pageId)
    const fixerVersionsQuery = dal.get(fixerVersionsQueryPointer)
    if (fixerVersionsQuery) {
        const {dataModel} = extensionAPI as DataModelExtensionAPI
        const pageFixerVersions = dataModel.getItem(fixerVersionsQuery, fixerVersionsNamespace, pageId)
        if (!pageFixerVersions) {
            removeFixerVersionFromComponent(documentManager, pageId)
            logger.interactionStarted('removeInvalidFixerVersion', {
                extras: {pageId}
            })
        }
    }
}

const migratePage = (documentManager: DocumentManager, pageId: string) => {
    if (documentManager.experimentInstance.isOpen(`dm_brokenReferenceFixer`)) {
        // handled in brokenReferenceFixer
        return
    }

    cleanBrokenFixerVersions(documentManager, pageId)
}

const name = 'removeBrokenFixerVersion'
const version = 0

export const removeBrokenFixerVersion: PageMigrator = {
    migratePage,
    name,
    version
}
