import type {PublicMethodUtils} from '@wix/document-services-types'
import {states, utils} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            components: {
                states: {
                    add: publicMethodUtils.defineDataManipulationAction(states.add, {
                        isUpdatingAnchors: utils.DONT_CARE,
                        getReturnValue: states.getStateVariantToAddRef
                    }),
                    update: publicMethodUtils.defineDataManipulationAction(states.update),
                    remove: publicMethodUtils.defineDataManipulationAction(states.remove),
                    get: publicMethodUtils.defineGetter(states.get),
                    getAll: publicMethodUtils.defineGetter(states.getAll)
                }
            }
        }
    }
}
