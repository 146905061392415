import type {PS} from '@wix/document-services-types'
import _ from 'lodash'

function getEditorSessionId(ps: PS) {
    return ps.siteAPI.getEditorSessionId()
}

function getEditorWorkingMode(ps: PS) {
    return ps.siteAPI.isMobileView() ? 'mobile' : 'desktop'
}

function event(ps: PS, ...args: any[]) {
    args[0] = _.defaults({}, args[0], {
        src: 38,
        endpoint: 'editor',
        sampleRatio: 0
    })
    args[1] = _.defaults({}, args[1], {
        esi: getEditorSessionId(ps),
        editor_working_mode: getEditorWorkingMode(ps)
    })

    if (ps.runtimeConfig.viewerName && (args[0].src === 40 || args[0].src === 38)) {
        args[0].viewerName = ps.runtimeConfig.viewerName
        args[0].viewerVersion = ps.runtimeConfig.viewerVersion
    }

    // @ts-expect-error
    return ps.siteAPI.reportBI(...args)
}

function error(ps: PS, ...args: any[]) {
    args[0] = _.defaults(args[0], {endpoint: 'trg', evid: 10})
    args[1] = _.defaults(args[1], {dsOrigin: ps.config.origin})
    return event(ps, ...args)
}

/**
 * param reportDefCollection map of errors by the errorName
 * param packageName to assign the errors
 * param shouldMutate - should mutate the error.reportDefCollection object?
 * @returns {*} mutated/clone of reportDefCollection with the added properties packageName and errorName
 */
function formatErrorEvents({
    reportDefCollection,
    packageName,
    shouldMutate = false
}: {
    reportDefCollection: any
    packageName: string
    shouldMutate?: boolean
}) {
    reportDefCollection = shouldMutate === true ? reportDefCollection : _.cloneDeep(reportDefCollection)

    _.forOwn(reportDefCollection, (reportDef, errorName) => {
        reportDef.packageName = packageName
        reportDef.errorName = errorName
    })

    return reportDefCollection
}

/**
 * adds errorName and packageName fields to errors
 * @param packageName
 * @param reportType
 * @param reportDefCollection
 */
function register(packageName: string, reportType: 'error', reportDefCollection) {
    if (reportType === 'error') {
        formatErrorEvents({packageName, reportDefCollection, shouldMutate: true})
    }
}

export default {
    event,
    error,
    formatErrorEvents,
    register
}
