import type {PublicMethodUtils} from '@wix/document-services-types'
import {reactions, utils} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            components: {
                reactions: {
                    add: publicMethodUtils.defineDataManipulationAction(reactions.addWithRef, {
                        isUpdatingAnchors: utils.DONT_CARE,
                        getReturnValue: reactions.getReactionsRef
                    }),
                    update: publicMethodUtils.defineDataManipulationAction(reactions.update),
                    disable: publicMethodUtils.defineDataManipulationAction(reactions.disable),
                    get: publicMethodUtils.defineGetter(reactions.get),
                    getByTrigger: publicMethodUtils.defineGetter(reactions.getByTrigger),
                    removeAll: publicMethodUtils.defineGetter(reactions.removeAll),
                    remove: publicMethodUtils.defineGetter(reactions.remove)
                }
            }
        }
    }
}
