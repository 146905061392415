import _ from 'lodash'
import slideShowCommon from '../slideshow/slideShowCommon'
import component from '../component/component'
import componentsMetaData from '../componentsMetaData/componentsMetaData'
import structure from '../structure/structure'
import type {CompRef, Pointer, PS} from '@wix/document-services-types'

function isBoxSlideShowSlide(ps: PS, parentType: string, child: Pointer) {
    return _.includes(
        slideShowCommon.getMatchingChildSlideTypes(parentType),
        componentsMetaData.getComponentType(ps, child)
    )
}

function getMasterChildren(ps: PS, componentPointer: Pointer, type: string) {
    return _.reject(component.getChildren(ps, componentPointer), isBoxSlideShowSlide.bind(null, ps, type))
}

function getNonMasterChildren(ps: PS, componentPointer: Pointer, type: string) {
    return _.filter(component.getChildren(ps, componentPointer), isBoxSlideShowSlide.bind(null, ps, type))
}

function getCurrentRenderedChildren(ps: PS, componentPointer: Pointer) {
    const parentType = componentsMetaData.getComponentType(ps, componentPointer)
    const currentSlide = _.find(getNonMasterChildren(ps, componentPointer, parentType), child =>
        component.isRenderedOnSite(ps, child)
    )
    const shownOnAllSlidesChildren = getMasterChildren(ps, componentPointer, parentType)
    return currentSlide ? [currentSlide].concat(shownOnAllSlidesChildren) : shownOnAllSlidesChildren
}

function findParentWithType(ps: PS, componentPointer: Pointer, type: string) {
    if (ps.pointers.components.isPage(componentPointer)) {
        return null
    }
    let currParent = component.getContainer(ps, componentPointer)

    while (currParent && !ps.pointers.components.isPage(currParent)) {
        if (componentsMetaData.getComponentType(ps, currParent) === type) {
            return currParent
        }
        currParent = component.getContainer(ps, currParent)
    }
    return null
}

function isMasterChild(ps: PS, componentPointer: Pointer, parentType: string) {
    const childTypes = slideShowCommon.getMatchingChildSlideTypes(parentType)
    return (
        canBeMasterChild(ps, componentPointer, parentType) &&
        _.every(childTypes, childType => !findParentWithType(ps, componentPointer, childType))
    )
}

function canBeMasterChild(ps: PS, componentPointer: Pointer, parentType: string) {
    const compType = componentsMetaData.getComponentType(ps, componentPointer)
    const childTypes = slideShowCommon.getMatchingChildSlideTypes(parentType)
    return (
        componentPointer.type !== 'MOBILE' &&
        !_.includes(childTypes, compType) &&
        !!findParentWithType(ps, componentPointer, parentType)
    )
}

function toggleMasterChild(ps: PS, componentPointer: CompRef, parentType: string) {
    const boxSlideShowParent = findParentWithType(ps, componentPointer, parentType)
    const childTypes = slideShowCommon.getMatchingChildSlideTypes(parentType)

    if (!!boxSlideShowParent && !_.includes(childTypes, componentsMetaData.getComponentType(ps, componentPointer))) {
        const childType = _.find(childTypes, _childType => findParentWithType(ps, componentPointer, _childType))
        const boxSlideShowSlideParent = findParentWithType(ps, componentPointer, childType)
        let newParent

        if (boxSlideShowSlideParent) {
            newParent = boxSlideShowParent
        } else {
            newParent = _.find(getCurrentRenderedChildren(ps, boxSlideShowParent), child =>
                _.includes(childTypes, componentsMetaData.getComponentType(ps, child))
            )
        }

        structure.setContainer(ps, undefined, componentPointer, newParent)
    }
}

function getParentCompWithOverflowHidden(ps: PS, componentPointer: Pointer, parentType: string) {
    const boxSlideShowParent = findParentWithType(ps, componentPointer, parentType)
    const slideshowProps = component.properties.get(ps, boxSlideShowParent)
    return slideshowProps.shouldHideOverflowContent ? boxSlideShowParent : null
}

export default {
    getMasterChildren,
    getNonMasterChildren,
    getCurrentRenderedChildren,
    isMasterChild,
    canBeMasterChild,
    toggleMasterChild,
    getParentCompWithOverflowHidden
}
