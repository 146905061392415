import type {Pointer} from '@wix/document-services-types'
import {createHookDefinition} from '../../utils/hooksUtils'
import type {HookEventData} from '../hooks/hooks'
import type {FeatureData} from './features'
export interface GetItemQueryIdData {
    compPointer: Pointer
    propName: string
}
export interface FeatureDataUpdate {
    compPointer: Pointer
    featureName: string
    featureData: FeatureData
}

export interface FeatureDataUpdatedEvent extends FeatureDataUpdate, HookEventData<'after_feature_data_update'> {}

export const FEATURE_DATA_HOOKS = {
    UPDATE: {
        AFTER: createHookDefinition<'after_feature_data_update', FeatureDataUpdate>('after_feature_data_update')
    }
}
