import type {CreateExtArgs, CreateExtensionArgument, Extension, ExtensionAPI} from '@wix/document-manager-core'
import {
    ChatServiceClient,
    type LiveChat,
    type ToolExecutionErrors,
    type ToolExecutionRegisry
} from '@wix/chat-service-sdk'

export interface AiToolResponse {
    content: string
    success: boolean
}

export interface AiToolRequest {
    name: string
    func(params: any): Promise<AiToolResponse>
}

export interface AiApi {
    conversation: {
        sendMessage(message: string): Promise<string>
        registerTool(tool: AiToolRequest): boolean
    }
}

export type AiConversationExtApi = ExtensionAPI & {ai: AiApi}

const createExtension = ({environmentContext}: CreateExtensionArgument): Extension => {
    const createExtensionAPI = ({pointers, dal}: CreateExtArgs): AiConversationExtApi => {
        const {serverFacade} = environmentContext
        const toolsMap: ToolExecutionRegisry = {}

        let liveChat: LiveChat

        const toUrl = (endpoint: string) => `/_serverless/chat-service/v1/${endpoint}`

        const chatServiceSdk = new ChatServiceClient({
            createConversation: (payload: any) => serverFacade.post(toUrl('conversations'), payload),
            getConversationMessages: (payload: any) =>
                serverFacade.getJsonWithParams(toUrl('conversation-messages'), payload),
            sendMessage: (payload: any) => serverFacade.post(toUrl('user-message'), payload)
        })

        const startChat = async () => {
            const esi = dal.get(pointers.documentServicesModel.getEditorSessionId())
            const startChatResult = await chatServiceSdk.startChat(
                {
                    type: 'existing-conversation',
                    id: esi
                },
                ['https://bo.wix.com/_serverless/dm-server-copilot-agent-domain'],
                toolsMap,
                {showPlatformLogs: true}
            )
            if (!startChatResult.ok) {
                throw new Error(
                    `[chat-service] Failed to start chat: ${startChatResult.cause} (${startChatResult.error.message})`
                )
            }
            liveChat = startChatResult.chat
        }

        const sendMessage = async (message: string) => {
            if (!liveChat) {
                await startChat()
            }

            const response = await liveChat.sendUserMessage(message)
            if (!response.ok) {
                throw new Error(`[chat-service] Failed to send message: ${response.cause} (${response.error.message})`)
            }
            return response.content
        }

        const registerTool = (tool: AiToolRequest) => {
            const {name, func} = tool
            const execute = func || (tool as any).function
            if (!name || !execute) {
                throw new Error('Tool must contain name and function')
            }
            toolsMap[name] = {
                execute,
                handleExecuteError: async (cause: ToolExecutionErrors, error: Error) => {
                    return {
                        success: false,
                        content: `Failed to execute tool ${name}: ${cause} (${error.message})`
                    }
                }
            }
            return true
        }

        return {
            ai: {
                conversation: {
                    sendMessage,
                    registerTool
                }
            }
        }
    }

    return {
        name: 'aiConversation',
        dependencies: new Set(),
        createExtensionAPI
    }
}

export {createExtension}
