import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import {coreUtils} from '@wix/santa-ds-libs'
const {urlUtils} = coreUtils
const {joinURL} = urlUtils

const getEditorSdkUrl = function (ps: PS): string {
    const currentUrl = ps.siteAPI.getCurrentUrl()
    const sdkSource = currentUrl?.query ? currentUrl.query.EditorSdkSource || currentUrl.query.editorSdkSource : null

    if (sdkSource) {
        return sdkSource
    }

    const serviceTopology = ps.dal.get(ps.pointers.general.getServiceTopology())
    return joinURL(
        _.get(serviceTopology, ['scriptsLocationMap', 'js-platform-editor-sdk'], ''),
        'lib',
        'editorSDK.min.js'
    )
}

export default getEditorSdkUrl
