import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import {wixappsBuilder} from '@wix/santa-ds-libs'
import linksConverter from '../utils/linksConverter'
import pathUtils from '../utils/pathUtils'

const STATES = {
    DRAFT: 'Draft',
    SAVED: 'Saved',
    PUBLISHED: 'Published',
    MODIFIED: 'Modified'
}

function convertLinks(links: any, convertFunc: any) {
    if (!_.isObject(links)) {
        return links
    }
    const mapFunc = _.isArray(links) ? _.map : _.mapValues
    // @ts-expect-error
    return mapFunc(links, link => (_.isObject(link) ? convertFunc(link) : link))
}

function convertItemLinks(item: any, convertFunc: any) {
    let convertedItem = item
    if (_.has(item, ['links'])) {
        convertedItem = _.clone(item)
        convertedItem.links = convertLinks(item.links, convertFunc)
    }
    _.forEach(convertedItem, function (fieldValue, fieldName) {
        if (_.has(fieldValue, ['links'])) {
            convertedItem[fieldName] = _.clone(fieldValue)
            convertedItem[fieldName].links = convertLinks(fieldValue.links, convertFunc)
        }
    })
    return convertedItem
}

function convertToWixFormat(ps: PS, item: any) {
    return convertItemLinks(item, _.partial(linksConverter.convertWixappsDataToWLink, ps))
}
function fixImageData(serviceTopology: any, item: any) {
    return _.mapValues(item, function (fieldValue) {
        return fieldValue._type === 'wix:Image'
            ? wixappsBuilder.resolveImageData(fieldValue, serviceTopology)
            : fieldValue
    })
}

/**
 * Get all items of a given type
 * @param {ps} ps Private Services
 * @param {string} typeId
 * @returns {Object|{}} map of items of the given type
 */
function getAllItemsOfType(ps: PS, typeId: string) {
    const serviceTopology = ps.dal.get(ps.pointers.general.getServiceTopology())
    const items = ps.wixappsDAL.getByPath(pathUtils.getBaseItemsPath(typeId)) || {}
    return _(items)
        .mapValues(_.partial(fixImageData, serviceTopology))
        .mapValues(_.partial(convertToWixFormat, ps))
        .value()
}
export default {
    STATES,
    getAllItemsOfType
}
