import type {ExternalsMap, ExternalsBuilderMap, Pointer} from '@wix/document-services-types'
import type {ExternalsHandler} from '../../constants'
import type {DetailedValue} from '@wix/import-export-utils'
import type {DalValue} from '@wix/document-manager-core'
import _ from 'lodash'

export const defaultRefsHandler: ExternalsHandler = {
    pack: (ref: DetailedValue, externals: ExternalsBuilderMap, refPointer: Pointer) =>
        externals.nonOwnedRefs.add(refPointer.id),
    unpack: (ref: DetailedValue, externals: ExternalsMap, newId: string, item: DalValue) => {
        _.set(item, ref.path, newId)
    }
}
