import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import componentDetectorAPI from '../componentDetectorAPI/componentDetectorAPI'
import dataModel from '../dataModel/dataModel'

const OLD_APPS = ['news', 'menu', 'faq']
const APP_PART_COMP_TYPE = 'wixapps.integration.components.AppPart'

function getMasterPageDocumentData(ps: PS) {
    const masterPageDataPointer = ps.pointers.page.getPageData('masterPage')
    return ps.dal.get(masterPageDataPointer)
}

function hasAppPage(ps: PS) {
    const masterPageDocumentData = getMasterPageDocumentData(ps)
    return !!_.find(masterPageDocumentData, {type: 'AppPage', appPageType: 'AppBuilderPage'})
}

function hasOldApp(ps: PS) {
    const clientSpecMapPointer = ps.pointers.general.getClientSpecMap()
    const clientSpecMap = ps.dal.get(clientSpecMapPointer)
    const oldAppClientSpecMap = {}
    _.forEach(OLD_APPS, function (oldApp) {
        const app = _.find(clientSpecMap, {type: 'wixapps', packageName: oldApp})
        if (app) {
            const appId = app.applicationId
            oldAppClientSpecMap[appId] = app
        }
    })

    const oldEcom = _.find(clientSpecMap, {packageName: 'ecommerce'})
    if (oldEcom) {
        oldAppClientSpecMap[oldEcom.applicationId] = oldEcom
    }

    if (_.isEmpty(oldAppClientSpecMap)) {
        return false
    }

    const appPartCompPointers = componentDetectorAPI.getComponentByType(ps, APP_PART_COMP_TYPE)

    return _.some(appPartCompPointers, function (compPointer) {
        const dataItem = dataModel.getDataItem(ps, compPointer)
        return !!oldAppClientSpecMap[dataItem.appInnerID]
    })
}

function hasNewBlog(ps: PS) {
    const clientSpecMapPointer = ps.pointers.general.getClientSpecMap()
    const clientSpecMap = ps.dal.get(clientSpecMapPointer)

    const newBlogApp = _.find(clientSpecMap, ['appDefinitionName', 'Wix Blog'])
    return !!newBlogApp
}

export default {
    /**compata
     * Returns whether or not the site has an app page (if so it's not compatible to editor >= 1.6)
     *
     * @returns {boolean}
     */
    hasAppPage,
    /**
     * Returns whether or not the site has an old app (i.e news/e-com/menu/faq, if so it's not compatible to editor >= 1.6)
     *
     * @returns {boolean}
     */
    hasOldApp,

    hasNewBlog
}
