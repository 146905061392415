import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import experiment from 'experiment-amd'
import * as santaCoreUtils from '@wix/santa-core-utils'
import type {DsFixer} from '../dsDataFixers'

function visualFocusIsDefault(ps: PS, accessibilitySettingsPointer: Pointer) {
    const vfFlag = _.get(ps.dal.get(accessibilitySettingsPointer), 'visualFocusDisabled')
    return _.isUndefined(vfFlag)
}

const fixer: DsFixer = {
    exec(ps: PS) {
        const masterPageDataPointer = ps.pointers.data.getDataItemFromMaster(
            santaCoreUtils.siteConstants.MASTER_PAGE_ID
        )
        const accessibilitySettingsPointer = ps.pointers.getInnerPointer(masterPageDataPointer, 'accessibilitySettings')

        if (experiment.isOpen('sv_visualFocus') && visualFocusIsDefault(ps, accessibilitySettingsPointer)) {
            ps.dal.set(accessibilitySettingsPointer, {visualFocusDisabled: false})
        }
    },
    name: 'visualFocusFixer',
    version: 1
}
export default fixer
