import type {PS} from '@wix/document-services-types'
import themeConstants from '../common/constants'
import themeDAL from '../common/themeDAL'

const {PROPERTY_TYPE} = themeConstants

function getAll(ps: PS) {
    return themeDAL.getCollection(ps, PROPERTY_TYPE.COLOR)
}

function get(ps: PS, index: string | number) {
    return themeDAL.getCollectionItem(ps, PROPERTY_TYPE.COLOR, index)
}

function set(ps: PS, index: string | number, val) {
    return themeDAL.setCollectionItem(ps, PROPERTY_TYPE.COLOR, index, val)
}

export default {
    set,
    get,
    getAll
}
