'use strict'

const _ = require('lodash')

const KEYWORD_SIZE_TYPES = new Set(['auto', 'maxContent', 'minContent'])
const SINGLE_LAYOUT_DATA_TYPE = 'SingleLayoutData'

function fixInvalidLayoutSize(layoutData) {
    if (!_.isPlainObject(layoutData) && !_.isArray(layoutData)) return

    if (layoutData.type && KEYWORD_SIZE_TYPES.has(layoutData.type) && Object.keys(layoutData).length === 2) {
        delete layoutData.value
        return
    }

    _.forEach(layoutData, fixInvalidLayoutSize)
}

function exec(pageJson) {
    const layouts = _.get(pageJson, 'data.layout_data')
    if (!layouts) return

    _(layouts).pickBy({type: SINGLE_LAYOUT_DATA_TYPE}).forOwn(fixInvalidLayoutSize)
}

/**
 * Fixes all layout size objects that are a mix of UnitSize and KeywordSize.
 * For example: {type: 'auto', value: 5}
 * This was caused by an issue in the schema builder that allowed putting any string as type for UnitSize.
 * @exports utils/dataFixer/plugins/invalidLayoutSizeFixer
 * @type {{exec: function}}
 */
module.exports = {
    name: 'invalidLayoutSizeFixer',
    version: 1,
    exec
}
