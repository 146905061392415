import {ChannelEvents, DuplexerChannel, EventChannel} from './duplexer'

export type EventCB = (payload?: any) => Promise<void>

export class MockChannel implements EventChannel {
    protected events = {} as Record<ChannelEvents, EventCB>

    on(msg: ChannelEvents, cb: EventCB): void {
        this.events[msg] = cb
    }

    emit(msg: ChannelEvents, ...args: any[]) {
        this.events[msg](...args)
    }
}

export class MockDuplexer extends DuplexerChannel {
    constructor() {
        super(() => 'mock-instance', 'test')
    }

    createChannel(): MockChannel {
        return new MockChannel()
    }

    connect(): Promise<void> {
        this.appConnection = {
            subscribe: async () => this.createChannel(),
            on: () => {
                throw new Error('Not implemented')
            },
            emit: () => {
                throw new Error('Not implemented')
            }
        }
        return Promise.resolve()
    }
}
