import {fetchWithAuth} from '../../../../../utils/fetchUtils'
import {parseProtoOutput} from '../../../../../utils/aiPredictionProtoUtil'
import type {EnvironmentContext, ExtensionAPI} from '@wix/document-manager-core'
import type {RMApi} from '../../../../rendererModel'
import type {GroupingResult} from '../types'

export const sendRequest = async (
    extensionAPI: ExtensionAPI,
    environmentContext: EnvironmentContext,
    serverInput: any
): Promise<GroupingResult> => {
    const endpoint = '/_api/predictor-service//v1/invoke/model/layout-sectiongrouping-2-851'
    const instance = (extensionAPI as RMApi).siteAPI.getInstance()
    const {fetchFn} = environmentContext
    const result = await fetchWithAuth(endpoint, instance, fetchFn, 'POST', serverInput)
    return parseProtoOutput(result.output.values.GroupTree)
}
