import type {CompRef} from '@wix/document-services-types'

export const findCompBFS = (
    root: CompRef,
    getChildren: (p: CompRef) => CompRef[],
    predicate: (p: CompRef) => boolean
): CompRef | undefined | null => {
    const components = getChildren(root)
    let comp: CompRef | undefined
    let nextLevel: CompRef[]
    while (components.length) {
        comp = components.shift()
        if (predicate(comp as CompRef)) {
            return comp
        }
        nextLevel = getChildren(comp as CompRef)
        nextLevel.forEach(child => components.push(child))
    }
    return null
}
