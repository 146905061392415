import {CreateExtArgs, pointerUtils} from '@wix/document-manager-core'
import type {BlocksEntity, Pointer, WidgetDescriptor, WidgetPluginDescriptor} from '@wix/document-services-types'
import {COMP_TYPES, DATA_TYPES, MASTER_PAGE_ID} from '../../constants/constants'
import type {DataModelExtensionAPI} from '../dataModel/dataModel'
import _ from 'lodash'
import {getComponentType} from '../../utils/dalUtils'
import {getLanguageByUseOriginal} from '../page/language'
import type {NicknamesExtensionAPI} from '../nicknames'
import {constants} from '../..'
import type {FeaturesExtensionAPI} from '../features/features'

const {getPointer} = pointerUtils

const WIDGETS_CACHE = {}
const VARIATIONS_CACHE = {}

export interface WidgetData {
    pointer: Pointer
    name: string
    panels?: string[]
    variations?: string[]
    presets?: string[]
    plugin?: WidgetPluginDescriptor
}

export const getData = (createExtArgs: CreateExtArgs, pointer: Pointer) => {
    const originalLanguage = getLanguageByUseOriginal(createExtArgs, true)
    const nonTranslatablePointer = {...pointer, useLanguage: originalLanguage}

    return createExtArgs.dal.get(nonTranslatablePointer)
}

export const getDataFromMaster = <T>(createExtArgs: CreateExtArgs, dataItemId: string): T => {
    const pointer = createExtArgs.pointers.data.getDataItemFromMaster(_.trimStart(dataItemId, '#'))

    return getData(createExtArgs, pointer) as T
}

export const getDeepDataFromMaster = <T>(createExtArgs: CreateExtArgs, dataItemId: string): T => {
    const {dataModel} = createExtArgs.extensionAPI as DataModelExtensionAPI
    return dataModel.getItem(_.trimStart(dataItemId, '#'), DATA_TYPES.data, MASTER_PAGE_ID)
}

export const getBlocksDataShallow = (createExtArgs: CreateExtArgs) => {
    const masterPageData = createExtArgs.dal.get(getPointer(MASTER_PAGE_ID, DATA_TYPES.data))
    const appStudioDataItemId = masterPageData.appStudioData
    if (!appStudioDataItemId) {
        return null
    }

    return getDataFromMaster<any>(createExtArgs, appStudioDataItemId)
}

export const getBlocksData = ({dal, extensionAPI}: CreateExtArgs) => {
    const masterPageData = dal.get(getPointer(MASTER_PAGE_ID, DATA_TYPES.data))
    const appStudioDataItemId = masterPageData.appStudioData
    if (!appStudioDataItemId) {
        return null
    }

    const {dataModel} = extensionAPI as DataModelExtensionAPI
    return dataModel.getItem(_.trimStart(appStudioDataItemId, '#'), DATA_TYPES.data, MASTER_PAGE_ID)
}

export const getDescriptorPointerById = ({pointers}: CreateExtArgs, widgetId: string) =>
    pointers.data.getDataItemFromMaster(widgetId)

export const getAllWidgets = (createExtArgs: CreateExtArgs): WidgetData[] => {
    const appStudioData = getBlocksData(createExtArgs) || {}

    return _.map(appStudioData.widgets, function (widget) {
        const widgetPointer = getDescriptorPointerById(createExtArgs, widget.id)
        const widgetDataItem = getData(createExtArgs, widgetPointer)
        return {
            pointer: widgetPointer,
            name: widgetDataItem.name,
            panels: widgetDataItem.panels,
            variations: widgetDataItem.variations,
            presets: widgetDataItem.presets,
            plugin: widgetDataItem.plugin
        }
    })
}

const findVariationByPageId = (createExtArgs: CreateExtArgs, pageId: string) => {
    const {pointers, dal} = createExtArgs

    const allWidgets = getAllWidgets(createExtArgs)
    let variationId
    const widget = allWidgets.find((currentWidget: any) => {
        variationId = currentWidget.variations.find((currentVariationId: string) => {
            const pointer = pointers.data.getDataItemFromMaster(_.trimStart(currentVariationId, '#'))
            const variationData = dal.get(pointer)
            return variationData?.rootCompId === `#${pageId}`
        })
        return variationId
    })
    return {
        variationId,
        widget
    }
}

export const findWidgetByPageId = (createExtArgs: CreateExtArgs, pageId: string): WidgetData | undefined => {
    const allWidgets = getAllWidgets(createExtArgs)

    const widget = _.find<WidgetData>(allWidgets, currentWidget => {
        const widgetData = getData(createExtArgs, currentWidget.pointer)
        return widgetData && widgetData.rootCompId === `#${pageId}`
    })

    return widget ?? findVariationByPageId(createExtArgs, pageId)?.widget
}

const getWidgetByRootCompId = (createExtArgs: CreateExtArgs, rootCompId: string) =>
    findWidgetByPageId(createExtArgs, rootCompId)?.pointer

const getWidgetPointerByRefComp = (createExtArgs: CreateExtArgs, refComp: Pointer) => {
    const {dataModel} = createExtArgs.extensionAPI as DataModelExtensionAPI

    const {pageId: widgetPageId, type} = dataModel.components.getItem(refComp, DATA_TYPES.data)
    if (type === 'InternalRef') {
        return getWidgetByRootCompId(createExtArgs, widgetPageId)
    }
}

export const getWidgetDataByRefComp = (
    createExtArgs: CreateExtArgs,
    refComp: Pointer
): WidgetDescriptor | undefined => {
    const {dal} = createExtArgs
    const widgetPointer = getWidgetPointerByRefComp(createExtArgs, refComp)

    if (widgetPointer) {
        return dal.get(widgetPointer)
    }
}

export const getRootCompIdByPointer = (createExtArgs: CreateExtArgs, pointer: Pointer): string | undefined => {
    const widgetData = getData(createExtArgs, pointer)

    if (widgetData?.rootCompId) {
        return _.replace(widgetData.rootCompId, '#', '')
    }
}

const getPageByWidgetPointer = (createExtArgs: CreateExtArgs, pointer: Pointer) => {
    const pageId = getRootCompIdByPointer(createExtArgs, pointer)

    return getPointer(pageId!, constants.VIEW_MODES.DESKTOP)
}

const isVariationPage = (createExtArgs: CreateExtArgs, pageId: string) => {
    if (!createExtArgs.pointers.page.isExists(pageId)) {
        return false
    }

    if (_.isUndefined(VARIATIONS_CACHE[pageId])) {
        VARIATIONS_CACHE[pageId] = Boolean(findVariationByPageId(createExtArgs, pageId)?.variationId)
    }

    return VARIATIONS_CACHE[pageId]
}

const isWidgetPage = (createExtArgs: CreateExtArgs, pageId: string) => {
    if (!createExtArgs.pointers.page.isExists(pageId)) {
        return false
    }

    if (_.isUndefined(WIDGETS_CACHE[pageId])) {
        WIDGETS_CACHE[pageId] = Boolean(findWidgetByPageId(createExtArgs, pageId))
    }
    return WIDGETS_CACHE[pageId] || isVariationPage(createExtArgs, pageId)
}

const getAllPanels = (createExtArgs: CreateExtArgs) => {
    const blocksData = getBlocksData(createExtArgs) || {}
    // eslint-disable-next-line lodash/prefer-flat-map
    return _(blocksData.widgets)
        .map('panels')
        .flatten()
        .map(panel => ({
            pointer: panel && createExtArgs.pointers.data.getDataItemFromMaster(panel.id),
            ...panel
        }))
        .value()
}

const findPanelByPageId = (createExtArgs: CreateExtArgs, pageId: string) => {
    const allPanels = getAllPanels(createExtArgs)
    const result = _.find(allPanels, panelData => {
        // MAKE SURE THIS IS ACTUALLY PANEL DATA AND NOT SOMETHING MINIMAL
        return panelData && panelData.rootCompId === `#${pageId}`
    })
    return result
}

const isPanelPage = (createExtArgs: CreateExtArgs, pageId: string) => {
    if (!createExtArgs.pointers.page.isExists(pageId)) {
        return false
    }

    return Boolean(findPanelByPageId(createExtArgs, pageId))
}

function getPageIntent(createExtArgs: CreateExtArgs, pageRef: Pointer) {
    const {features} = createExtArgs.extensionAPI as FeaturesExtensionAPI

    const intentData = features.component.get(pageRef, 'intent')
    return intentData?.intent
}

export const isDashboardPage = (createExtArgs: CreateExtArgs, pageRef: Pointer) => {
    if (!createExtArgs.pointers.page.isExists(pageRef.id)) {
        return false
    }

    return getPageIntent(createExtArgs, pageRef) === 'dashboardPage'
}

export const isBlocksComponentPage = (createExtArgs: CreateExtArgs, pageRef: Pointer) =>
    isWidgetPage(createExtArgs, pageRef.id) ||
    isPanelPage(createExtArgs, pageRef.id) ||
    isDashboardPage(createExtArgs, pageRef)

const isAppWidgetComponent = ({dal}: CreateExtArgs, compRef: Pointer) =>
    getComponentType(dal, compRef) === COMP_TYPES.APP_WIDGET_TYPE

const findRootWidgetByComponentType = (createExtArgs: CreateExtArgs, compRef: Pointer): Pointer => {
    const [firstChild] = createExtArgs.pointers.structure.getChildren(compRef)
    if (!firstChild || isAppWidgetComponent(createExtArgs, firstChild)) {
        return firstChild
    }
    return findRootWidgetByComponentType(createExtArgs, firstChild)
}

export const getRootAppWidgetByPage = (createExtArgs: CreateExtArgs, pagePointer: Pointer) => {
    if (!isBlocksComponentPage(createExtArgs, pagePointer)) {
        return
    }
    return findRootWidgetByComponentType(createExtArgs, pagePointer)
}

const getAppWidgetRefFromPointer = (createExtArgs: CreateExtArgs, widgetPointer: Pointer) => {
    const pageRef = getPageByWidgetPointer(createExtArgs, widgetPointer)
    return getRootAppWidgetByPage(createExtArgs, pageRef)
}

const getFirstLevelRefChildren = (createExtArgs: CreateExtArgs, widgetPointer: Pointer) => {
    const widgetRef = getAppWidgetRefFromPointer(createExtArgs, widgetPointer)
    const children = createExtArgs.pointers.structure.getChildrenRecursively(widgetRef!)
    return _.filter(children, child => getComponentType(createExtArgs.dal, child) === COMP_TYPES.REF_TYPE)
}

const getContainedWidgets = (createExtArgs: CreateExtArgs, widgetPointer: Pointer, widgetsMap: Record<string, any>) => {
    const refChildren = getFirstLevelRefChildren(createExtArgs, widgetPointer)
    let containedWidgets: string[] = []

    _.forEach(refChildren, ref => {
        const widgetChildPointer = getWidgetPointerByRefComp(createExtArgs, ref)
        if (widgetChildPointer) {
            if (!widgetsMap[widgetChildPointer.id]) {
                widgetsMap[widgetChildPointer.id] = getContainedWidgets(createExtArgs, widgetChildPointer, widgetsMap)
            }
            containedWidgets = _.concat(containedWidgets, widgetChildPointer.id, widgetsMap[widgetChildPointer.id])
        }
    })
    return _.uniq(containedWidgets)
}

export const getContainingWidgetsMap = (createExtArgs: CreateExtArgs) => {
    const widgets = _.map(getAllWidgets(createExtArgs), 'pointer')
    const widgetsMap = {}
    _.forEach(widgets, widgetPointer => {
        if (!widgetsMap[widgetPointer.id]) {
            widgetsMap[widgetPointer.id] = getContainedWidgets(createExtArgs, widgetPointer, widgetsMap)
        }
    })
    return widgetsMap
}

const getComponentNicknameContext = (createExtArgs: CreateExtArgs, compPointer: Pointer) => {
    const {pointers} = createExtArgs
    if (compPointer) {
        const pagePointer = pointers.structure.isPage(compPointer)
            ? compPointer
            : pointers.structure.getPageOfComponent(compPointer)

        if (!pagePointer) {
            return null
        }

        if (pointers.structure.isMasterPage(pagePointer)) {
            return null
        }

        if (pagePointer) {
            return getRootAppWidgetByPage(createExtArgs, pagePointer)
        }
    }

    return null
}

export const getBlocksComponentNickname = (createExtArgs: CreateExtArgs, compPointer: Pointer): string | undefined => {
    const {nicknames} = createExtArgs.extensionAPI as NicknamesExtensionAPI
    const nicknameContext = getComponentNicknameContext(createExtArgs, compPointer)

    const nicknameMap = nicknames.getComponentNickname(compPointer, nicknameContext!)

    return Object.values(nicknameMap)[0]
}

export const getAllSerializedCustomDefinitions = (createExtArgs: CreateExtArgs) => {
    const appStudioData = getBlocksData(createExtArgs) || {}

    return _.map(appStudioData.customDefinitions, 'structure')
}

type ConstEnum<T extends string> = {
    [K in T]: K
}

const BlocksEntityE: ConstEnum<BlocksEntity> = {
    DASHBOARD: 'DASHBOARD',
    CONFIGURATION: 'CONFIGURATION',
    WIDGET: 'WIDGET',
    PANEL: 'PANEL',
    NONE: 'NONE'
}

export const getEntityByPage = (createExtArgs: CreateExtArgs, pageRef: Pointer): BlocksEntity => {
    const pageIntent = getPageIntent(createExtArgs, pageRef)

    switch (pageIntent) {
        case 'dashboardPage':
            return BlocksEntityE.DASHBOARD
        case 'configurationPage':
            return BlocksEntityE.CONFIGURATION
        case 'widgetPage':
            return BlocksEntityE.WIDGET
        case 'panelPage':
            return BlocksEntityE.PANEL
    }

    // TODO remove this fallback once we migrate to widgetPage intent
    if (isWidgetPage(createExtArgs, pageRef.id)) {
        return BlocksEntityE.WIDGET
    }

    // TODO remove this fallback once we migrate to panelPage intent
    if (isPanelPage(createExtArgs, pageRef.id)) {
        return BlocksEntityE.PANEL
    }

    return BlocksEntityE.NONE
}

export const isDefaultPage = (createExtArgs: CreateExtArgs, pageRef: Pointer) => {
    if (!createExtArgs.pointers.page.isExists(pageRef.id)) {
        return false
    }

    if (getPageIntent(createExtArgs, pageRef) === 'defaultPage') {
        return true
    }

    // TODO remove this fallback once we migrate to defaultPage intent
    return getEntityByPage(createExtArgs, pageRef) === 'NONE'
}
