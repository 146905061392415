// eslint-disable-next-line wix-editor/no-internal-import, import/named
import {IDocumentManagementComponentModel, registry} from '@wix/editor-elements-registry/2.0/documentManagement'
// eslint-disable-next-line wix-editor/no-internal-import
import {getComponentsLibraries} from '@wix/editor-elements-registry/2.0/toolbox'

import type {DmApis, Extension, CreateExtensionArgument, ExtensionAPI} from '@wix/document-manager-core'

export interface ComponentsRegistryAPI extends ExtensionAPI {
    componentsRegistry: {
        waitRegistryLoaded(): Promise<void>
        getComponentSDKType(componentType: string): string
        getComponents(): Record<string, IDocumentManagementComponentModel>
    }
}

const createExtension = ({environmentContext, experimentInstance}: CreateExtensionArgument): Extension => {
    const {fetchFn} = environmentContext

    let resolveWait: Function
    let rejectWait: Function

    let waitRegistryLoaded: Promise<void>

    function createRegistryLoadingPromise() {
        waitRegistryLoaded = new Promise((resolve, reject) => {
            resolveWait = resolve
            rejectWait = reject
        })
    }

    const components: Record<string, IDocumentManagementComponentModel> = {}
    const statics: Record<string, Record<string, any>> = {}

    const createExtensionAPI = (): ComponentsRegistryAPI => {
        return {
            componentsRegistry: {
                waitRegistryLoaded: () => waitRegistryLoaded,
                getComponentSDKType: (componentType: string) => statics[componentType]?.sdkType,
                getComponents: () => components
            }
        }
    }

    const loadRegistry = async ({dal, pointers}: DmApis) => {
        if (waitRegistryLoaded) {
            return waitRegistryLoaded
        }

        createRegistryLoadingPromise()

        try {
            const serviceTopology = dal.get(pointers.serviceTopology.getServiceTopology())
            /**
             * we use current url to get query params to parse custom overrides rules.
             * – `&editor-elements-override=xxx` – is the default wix-way rule to override artifact
             * – `&_rb-whatever-override=xxx` – only registry related override param.
             *
             * Components team widely use it when we start local dev server only for a specific component.
             * (e.g `yarn start --components Button, Slider`). In this way registry will fetch both prod and local artifacts, and correctly override with components from local.
             *
             * as a workaround for now – use it only for browser environment
             */
            const currentURL = typeof window !== 'undefined' ? window.location.href : ''

            const libraries = getComponentsLibraries({
                serviceTopology,
                url: currentURL,
                useNewStatics: experimentInstance.isOpen('specs.editor-elements.useNewStatics'),
                experimentalMobileLibrary: experimentInstance.isOpen('specs.mobile-elements.useRegistry')
            })
            const registryAPI = await registry({
                mode: 'eager',
                libraries,
                fetcher: async (url: string) => {
                    const response = await fetchFn(url)
                    return response.text()
                }
            })

            Object.entries(registryAPI.getComponentsLoaders()).forEach(([componentType, componentLoader]) => {
                statics[componentType] = componentLoader.statics
            })

            Object.assign(components, await registryAPI.loadAllComponents())

            resolveWait()
        } catch (e) {
            console.error('failed loading registry', e)
            rejectWait()
        }
    }

    return {
        name: 'componentsRegistry',
        createExtensionAPI,
        initialize: loadRegistry
    }
}

export {createExtension}
