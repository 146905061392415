import type {CoreLogger} from '@wix/document-manager-core'
import _ from 'lodash'
import type {Method} from '@wix/document-services-types'

interface ErrorDescriptor {
    tags?: any
    message: string
}

export type FetchFn = (input: RequestInfo, init?: RequestInit) => Promise<Response>

export async function fetch<T = any>(url: string, method: Method, fetchFn: FetchFn): Promise<T> {
    const requestOptions = {
        method,
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const response = await fetchFn(url, requestOptions)
    const result = await response.json()
    if (response.ok) {
        return result
    }
    throw new Error(result.message)
}

export async function tryFetch<T = any>(
    url: string,
    method: Method,
    fetchFn: FetchFn,
    logger: CoreLogger,
    errorDescriptor: ErrorDescriptor
): Promise<T | void> {
    try {
        return fetch(url, method, fetchFn)
    } catch (err) {
        const e = err as Error
        if (_.isFunction(logger.captureError)) {
            const errorMsg = _.isString(errorDescriptor?.message) ? errorDescriptor?.message : e.message
            e.message = errorMsg
            const tags = _.get(errorDescriptor, ['tags'], {})
            logger.captureError(e, tags)
        } else {
            throw e
        }
    }
}

export async function fetchWithAuth<T = any, R = any>(
    url: string,
    instance: string,
    fetchFn: FetchFn,
    method: Method,
    data?: R
): Promise<T> {
    const body: string | undefined = JSON.stringify(data)
    const requestOptions: RequestInit = {
        method,
        headers: {
            Authorization: instance,
            'Content-Type': 'application/json'
        },
        body,
        redirect: 'follow' as RequestRedirect
    }

    const response = await fetchFn(url, requestOptions)

    const toJson = () => response.json()
    if (response.ok) {
        return await toJson()
    }

    const text = await response.text()
    throw new Error(text)
}
