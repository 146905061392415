import _ from 'lodash'
import type {DalValue, DmApis} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import type {WixCodeExtensionAPI} from '../wixCode'
import type {ViewerManagerDAL} from '@wix/viewer-manager-adapter'
import {deepClone} from '@wix/wix-immutable-proxy'

const VIEW_MODES = ['DESKTOP', 'MOBILE']
const rendererModelItemsWrappedAsPages = new Set(['passwordProtectedPages', 'pageToHashedPassword'])
export const viewerTransformSet = (
    dsApis: DmApis,
    viewerManagerDal: ViewerManagerDAL,
    pointer: Pointer,
    value: DalValue
): DalValue => {
    const {extensionAPI, pointers: documentManagerPointers, dal: documentManagerDal, coreConfig} = dsApis

    if (pointer.type === 'rendererModel' && rendererModelItemsWrappedAsPages.has(pointer.id)) {
        viewerManagerDal.set(pointer, value.pages)
        return
    }

    if (
        (pointer.type === 'wixCode' && pointer.id === 'ISOLATED_GRID_APP') ||
        (pointer.type === 'documentServicesModel' && pointer.id === 'openWixCodeAppId')
    ) {
        const wixCodeModelPtr = documentManagerPointers.wixCode.getWixCodeModel()
        const wixCodeModel = deepClone(documentManagerDal.get(wixCodeModelPtr))
        viewerTransformSet(dsApis, viewerManagerDal, wixCodeModelPtr, wixCodeModel)
        return
    }

    if (pointer.type === 'rendererModel' && pointer.id === 'wixCodeModel') {
        const {wixCode} = extensionAPI as WixCodeExtensionAPI
        const wixCodeModel = deepClone(value)
        if (wixCode) {
            _.setWith(wixCodeModel, ['appData', 'codeAppId'], wixCode.getEditedGridAppId(), Object)
        }
        viewerManagerDal.set(pointer, wixCodeModel)
        return
    }

    if (
        VIEW_MODES.includes(pointer.type) &&
        coreConfig.experimentInstance.isOpen('dm_meshLayout') &&
        !coreConfig.experimentInstance.isOpen('dm_avoidZeroLayout')
    ) {
        viewerManagerDal.set(pointer, {...value, layout: {x: 0, y: 0, width: 0, height: 0}})
        return
    }

    viewerManagerDal.set(pointer, value)
}
