import {DalValue, pointerUtils} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'

const {hasInnerPath} = pointerUtils

const createWithPageId = (value: DalValue, pageId: string) => ({
    ...value,
    metaData: {
        ...value.metaData,
        pageId
    }
})

const addPageIdFromPointer = (pointer: Pointer, value: any) => {
    if (pointer.type === 'data' && value?.type === 'Page') {
        return createWithPageId(value, 'masterPage')
    }

    if (!hasInnerPath(pointer) && pointer.pageId && value) {
        return createWithPageId(value, pointer.pageId)
    }

    return value
}

export {addPageIdFromPointer}
