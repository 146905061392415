import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'

function hasChildren(ps: PS, repeaterPointer: Pointer) {
    const children = ps.pointers.full.components.getChildren(repeaterPointer)
    return _.size(children) > 0
}

function canContain(ps: PS, popoverPointer: Pointer, potentialChild, targetedContainerPointer: Pointer) {
    const isTargetedContainerAPopover = targetedContainerPointer.id === popoverPointer.id
    return !(isTargetedContainerAPopover && hasChildren(ps, popoverPointer))
}

export default {
    defaultMobileProperties: {
        closeMethod: 'outerClick'
    },
    canContain,
    canContainByStructure: canContain
}
