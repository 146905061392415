import type {Callback} from '@wix/document-services-types'
import _ from 'lodash'
import * as batchingStrategy from './anchorLayoutStrategy'
import type {QItemParams, BatchItem} from './SOQItem'

const MAX_BATCH_SIZE = 1000

interface PostFlushOperation {
    itemId: number
    operation: Callback
}

export class SetOperationsQueueBatch {
    readonly items: BatchItem[]
    readonly activeItems: Record<number, QItemParams> = {}
    postFlushOperations: PostFlushOperation[]
    private isBatchClosed: boolean

    constructor() {
        this.items = []
        this.activeItems = {}
        this.postFlushOperations = []
        this.isBatchClosed = false
    }

    canAddToBatch(item: QItemParams) {
        const withinBatchSize = this.items.length < MAX_BATCH_SIZE

        return !this.isBatchClosed && batchingStrategy.canAddToBatch(item, this.items) && withinBatchSize
    }

    addToBatch(item: QItemParams, itemId: number, args: any[]) {
        if (this.isBatchClosed) {
            throw new Error("the site is during update or this item isn't compatible with the current batch")
        }
        this.isBatchClosed = batchingStrategy.addingToBatch(item, this.items)
        this.items.push(_.assign({}, item, {itemId}, {args}))
        this.activeItems[itemId] = item
    }

    removeFromBatch(itemId: number) {
        delete this.activeItems[itemId]
        this.postFlushOperations = _.reject(this.postFlushOperations, {itemId})
    }

    addPostUpdateOperation(op: Callback, itemId: number) {
        this.postFlushOperations.push({itemId, operation: op})
    }

    closeBatch() {
        this.isBatchClosed = true
    }
}
