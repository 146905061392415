const TASK_NAME = 'saveCode'

export default {
    getTaskName() {
        return TASK_NAME
    },
    getSnapshotTags() {
        return ['primary']
    }
}
