import {asArray} from '@wix/document-manager-utils'
import type {CompRef, CompVariantPointer, Pointer, PS, VariantPointer} from '@wix/document-services-types'
import _ from 'lodash'
import component from '../component/component'
import componentStylesAndSkinsAPI from '../component/componentStylesAndSkinsAPI'
import constants from '../constants/constants'
import features from '../features/features'
import refComponent from '../refComponent/refComponent'
import refComponentUtils from '../refComponent/refComponentUtils'
import responsiveLayout from '../responsiveLayout/responsiveLayout'
import utils from '../utils/utils'
import variables from '../variables/variables'
import transformations from '../variants/transformations'
import transitions from '../variants/transitions'
import variants from '../variants/variants'
import states from '../states/states'
import triggers from '../triggers/triggers'
import reactions from '../reactions/reactions'
import appStudioDataModel from './appStudioDataModel'
import nameGenerator from './nameGenerator'
import type {CopyScopedPresetsAndOverridesOptions} from './appStudioPresets.types'
import effects from '../effects/effects'

const PRESET_TYPE = 'PresetDescriptor'
const NEW_PRESET_PREFIX = 'Design Preset '
const MAX_PRESET_NAME_LENGTH = 100

const addPresetsToWidgetDataItem = (ps: PS, presetsPointers: Pointer | Pointer[], widgetPointer: Pointer) => {
    const presetsPointersArray = asArray(presetsPointers)
    const widgetData = appStudioDataModel.getData(ps, widgetPointer)

    if (widgetData) {
        const presetsIdsArray = _.map(presetsPointersArray, presetPointer => `#${presetPointer.id}`)
        widgetData.presets = [...(widgetData.presets || []), ...presetsIdsArray]
        appStudioDataModel.setWidgetData(ps, widgetPointer, widgetData)
    }
}

const addDuplicatedPresetToWidgetDataItem = (
    ps: PS,
    presetPointer: Pointer,
    originalPresetPointer: Pointer,
    widgetPointer: Pointer
) => {
    const widgetData = appStudioDataModel.getData(ps, widgetPointer)

    if (widgetData) {
        const originalPresetIndex = _.findIndex(widgetData.presets, preset => preset === `#${originalPresetPointer.id}`)
        widgetData.presets.splice(originalPresetIndex + 1, 0, `#${presetPointer.id}`)
        appStudioDataModel.setWidgetData(ps, widgetPointer, widgetData)
    }
}

const removePresetFromWidgetDataItem = (ps: PS, presetPointer: Pointer, widgetPointer: Pointer) => {
    const widgetData = appStudioDataModel.getData(ps, widgetPointer)

    if (widgetData) {
        widgetData.presets = _.filter(widgetData.presets, item => item !== `#${presetPointer.id}`)
        appStudioDataModel.setWidgetData(ps, widgetPointer, widgetData)
    }
}

const addInitialScopedDataToVariant = (ps: PS, variantPointer: VariantPointer, widgetPointer: Pointer) => {
    const pageRef = appStudioDataModel.getPageByWidgetPointer(ps, widgetPointer)
    const pageVariantRef = variants.getPointerWithVariants(ps, pageRef, variantPointer)
    transformations.updateTransformationsData(ps, pageVariantRef, {rotate: 0})
}

const createPresetVariant = (ps: PS, widgetPointer: Pointer) => {
    const presetType = variants.getPresetType()
    const pageRef = appStudioDataModel.getPageByWidgetPointer(ps, widgetPointer)
    const variantToAddRef = variants.getVariantToAddRef(ps, pageRef, presetType)
    variants.create(ps, variantToAddRef, pageRef, presetType)

    addInitialScopedDataToVariant(ps, variantToAddRef, widgetPointer)

    return variantToAddRef
}

const removePresetVariant = (ps: PS, presetPointer: Pointer, widgetPointer: Pointer) => {
    const variantPointer = getPresetVariantPointer(ps, presetPointer, widgetPointer)
    variants.remove(ps, variantPointer)
}

const generateNewPresetName = (ps: PS, prefix: string, widgetPointer: Pointer) => {
    const widgetPresets = getWidgetPresets(ps, widgetPointer)
    return nameGenerator.generateName(widgetPresets, prefix)
}

const generateDuplicatedPresetName = (originalPresetName: string) => {
    const duplicatedPresetName = `Copy of ${originalPresetName}`
    return duplicatedPresetName.substr(0, MAX_PRESET_NAME_LENGTH)
}

const validatePresetName = (presetName: string) => {
    if (_.isEmpty(presetName)) {
        throw new Error('appStudio.presets: Preset name is required')
    }

    if (presetName.length > MAX_PRESET_NAME_LENGTH) {
        throw new Error('appStudio.presets: Preset name is too long')
    }
}

const createBlankPresetData = (ps: PS, presetPointer: Pointer, presetName: string, variantId: string) => {
    const presetDataItem = ps.extensionAPI.dataModel.createDataItemByType(PRESET_TYPE)
    presetDataItem.id = presetPointer.id
    presetDataItem.name = presetName
    presetDataItem.presetId = `#${variantId}`
    return presetDataItem
}

const createDuplicatedPresetData = (
    ps: PS,
    presetPointer: Pointer,
    originalPresetPointer: Pointer,
    presetName: string,
    variantId: string
) => {
    const presetData = createBlankPresetData(ps, presetPointer, presetName, variantId)
    const originalPresetData = appStudioDataModel.getData(ps, originalPresetPointer)

    if (_.get(originalPresetData, 'defaultSize')) {
        presetData.defaultSize = originalPresetData.defaultSize
    }

    return presetData
}

const createDuplicatedPreset = (
    ps: PS,
    presetPointer: Pointer,
    originalPresetPointer: Pointer,
    widgetPointer: Pointer,
    newPresetName: string
) => {
    const variantPointer = createPresetVariant(ps, widgetPointer)
    const originalVariantPointer = getPresetVariantPointer(ps, originalPresetPointer, widgetPointer)

    const originalPresetName = getPresetName(ps, originalPresetPointer)
    const presetName = newPresetName || generateDuplicatedPresetName(originalPresetName)
    validatePresetName(presetName)

    const presetData = createDuplicatedPresetData(
        ps,
        presetPointer,
        originalPresetPointer,
        presetName,
        variantPointer.id
    )
    appStudioDataModel.setData(ps, presetPointer, presetData)

    copyPresetScopedData(ps, variantPointer, originalVariantPointer, widgetPointer)
}

const copyOverridesBetweenVariants = (
    ps: PS,
    refComp: Pointer,
    originalVariantPointer: VariantPointer,
    destinationVariantPointer: VariantPointer
) => {
    const pageRef = ps.pointers.components.getPageOfComponent(refComp)

    const overriddenDataArr = refComponentUtils.getOverriddenData(ps, refComp)
    _.forEach(overriddenDataArr, overriddenData => {
        if (overriddenData.itemType === 'style') {
            const compRef = ps.pointers.components.getComponent(overriddenData.compId, pageRef)
            const originalComp = refComponent.getUniqueRefCompPointer(ps, refComp, compRef)
            const originalCompVariantRef = variants.getPointerWithVariants(ps, originalComp, originalVariantPointer)
            const compVariantRef = variants.getPointerWithVariants(ps, originalComp, destinationVariantPointer)

            const originalScopedStyle = componentStylesAndSkinsAPI.style.get(ps, originalCompVariantRef)
            if (originalScopedStyle) {
                componentStylesAndSkinsAPI.style.update(ps, compVariantRef as CompRef, originalScopedStyle)
            }
        }
    })
}

const maybeCopyScopedPresetsAndOverrides = ({
    ps,
    compPointer,
    originalPresetVariantPointer,
    originalCompVariantPointer,
    presetVariantPointer,
    compVariantPointer
}: CopyScopedPresetsAndOverridesOptions) => {
    if (refComponentUtils.isRefHost(ps, compPointer)) {
        const originalScopedPreset = features.getFeatureData(
            ps,
            originalCompVariantPointer,
            constants.DATA_TYPES.presets
        )
        if (originalScopedPreset) {
            features.updateFeatureData(ps, compVariantPointer, constants.DATA_TYPES.presets, originalScopedPreset)
        }

        copyOverridesBetweenVariants(ps, compPointer, originalPresetVariantPointer, presetVariantPointer)
    }
}

const copyScopedStyles = (
    ps: PS,
    originalCompVariantPointer: CompVariantPointer,
    compVariantPointer: CompVariantPointer
) => {
    const originalScopedStyle = componentStylesAndSkinsAPI.style.get(ps, originalCompVariantPointer)
    if (originalScopedStyle) {
        componentStylesAndSkinsAPI.style.update(ps, compVariantPointer as CompRef, originalScopedStyle)
    }
}

const copyScopedLayouts = (
    ps: PS,
    originalCompVariantPointer: CompVariantPointer,
    compVariantPointer: CompVariantPointer
) => {
    const originalScopedLayout = responsiveLayout.get(ps, originalCompVariantPointer)
    if (originalScopedLayout) {
        responsiveLayout.update(ps, compVariantPointer, _.omit(originalScopedLayout, 'id'))
    }
}

const copyScopedTransformations = (
    ps: PS,
    originalCompVariantPointer: CompVariantPointer,
    compVariantPointer: CompVariantPointer
) => {
    const originalScopedTransformations = transformations.getTransformationsData(ps, originalCompVariantPointer)
    if (originalScopedTransformations) {
        transformations.updateTransformationsData(ps, compVariantPointer, _.omit(originalScopedTransformations, 'id'))
    }
}

const copyScopedTransitions = (
    ps: PS,
    originalCompVariantPointer: CompVariantPointer,
    compVariantPointer: CompVariantPointer
) => {
    const originalScopedTransitions = transitions.getTransitionsData(ps, originalCompVariantPointer)
    if (originalScopedTransitions) {
        transitions.updateTransitionsData(ps, compVariantPointer, _.omit(originalScopedTransitions, 'id'))
    }
}

const copyScopedEffects = (
    ps: PS,
    originalCompVariantPointer: CompVariantPointer,
    compVariantPointer: CompVariantPointer
) => {
    const originalScopedEffects = effects.getComponentEffectList(ps, originalCompVariantPointer)
    originalScopedEffects.forEach(effectPointer => {
        const componentEffect = effects.getEffect(ps, originalCompVariantPointer, effectPointer)
        effects.updateEffectData(ps, compVariantPointer, effectPointer, componentEffect)
    })
}

const getTriggersWithTypes = (ps: PS, compVariantPointer: CompVariantPointer) => {
    const allTriggers = triggers.getAllTriggers(ps, compVariantPointer)

    return allTriggers.map(triggerPointer => ({
        triggerType: triggers.getTrigger(ps, compVariantPointer, triggerPointer).trigger,
        pointer: triggerPointer
    }))
}

const getScopedReactionsWithMatchingTrigger = (ps: PS, compVariantPointer: CompVariantPointer) => {
    const triggersWithTypes = getTriggersWithTypes(ps, compVariantPointer)
    const scopedReactions = reactions.get(ps, compVariantPointer) ?? []
    return scopedReactions
        .map(scopedReaction => {
            const {triggerType} = scopedReaction
            const triggerWithType = triggersWithTypes.find(trigger => trigger.triggerType === triggerType)
            return {scopedReaction, triggerPointer: triggerWithType?.pointer}
        })
        .filter(({triggerPointer}) => triggerPointer)
}

const copyScopedReactions = (
    ps: PS,
    compPointer: Pointer,
    originalPresetVariantPointer: VariantPointer,
    presetVariantPointer: VariantPointer
) => {
    const originalCompPointerInPreset = variants.getPointerWithVariants(ps, compPointer, originalPresetVariantPointer)
    const scopedReactionsWithTrigger = getScopedReactionsWithMatchingTrigger(ps, originalCompPointerInPreset)

    scopedReactionsWithTrigger.forEach(({scopedReaction, triggerPointer}) => {
        const reactionTargetPointer = variants.getPointerWithVariants(
            ps,
            scopedReaction.component,
            presetVariantPointer
        )
        return reactions.add(ps, reactionTargetPointer, triggerPointer, scopedReaction)
    })
}

const copyCompDataScopedToPreset = (
    ps: PS,
    originalCompVariantPointer: CompVariantPointer,
    compVariantPointer: CompVariantPointer
) => {
    copyScopedStyles(ps, originalCompVariantPointer, compVariantPointer)
    copyScopedLayouts(ps, originalCompVariantPointer, compVariantPointer)
    copyScopedTransformations(ps, originalCompVariantPointer, compVariantPointer)
    copyScopedTransitions(ps, originalCompVariantPointer, compVariantPointer)
    copyScopedEffects(ps, originalCompVariantPointer, compVariantPointer)
}

const copyCompDataScopedToPresetAndStates = (
    ps: PS,
    compPointer: Pointer,
    originalPresetVariantPointer: VariantPointer,
    presetVariantPointer: VariantPointer
) => {
    const stateVariantPointers = states.getAll(ps, compPointer) as VariantPointer[]

    stateVariantPointers.forEach(stateVariantPointer => {
        const originalCompPointerInPresetAndState = variants.getPointerWithVariants(ps, compPointer, [
            stateVariantPointer,
            originalPresetVariantPointer
        ])
        const compPointerInPresetAndState = variants.getPointerWithVariants(ps, compPointer, [
            stateVariantPointer,
            presetVariantPointer
        ])

        copyScopedStyles(ps, originalCompPointerInPresetAndState, compPointerInPresetAndState)
        copyScopedTransformations(ps, originalCompPointerInPresetAndState, compPointerInPresetAndState)
        copyScopedTransitions(ps, originalCompPointerInPresetAndState, compPointerInPresetAndState)
    })
}

const copyVariablesScopedData = (
    ps: PS,
    widgetPointer: Pointer,
    originalVariantPointer: VariantPointer,
    variantPointer: VariantPointer
) => {
    const widgetRef = appStudioDataModel.getAppWidgetRefFromPointer(ps, widgetPointer)
    if (!widgetRef) {
        return
    }
    const originalCompVariantRef = variants.getPointerWithVariants(ps, widgetRef, originalVariantPointer)
    const compVariantRef = variants.getPointerWithVariants(ps, widgetRef, variantPointer)

    const widgetVariablePointers = variables.getComponentVariablesList(ps, widgetRef)
    widgetVariablePointers.forEach(variablePointer => {
        const variableData = variables.getVariableData(ps, originalCompVariantRef, variablePointer)
        if (variableData) {
            variables.updateVariableData(ps, compVariantRef, variablePointer, variableData)
        }
    })
}

const copyPresetScopedData = (
    ps: PS,
    presetVariantPointer: Pointer,
    originalPresetVariantPointer: Pointer,
    widgetPointer: Pointer
) => {
    const pageRef = appStudioDataModel.getPageByWidgetPointer(ps, widgetPointer)
    const allCompPointers = component.getChildrenFromFull(ps, pageRef, true)

    _.forEach(allCompPointers, compPointer => {
        const originalCompVariantPointer = variants.getPointerWithVariants(
            ps,
            compPointer,
            originalPresetVariantPointer
        )
        const compVariantPointer = variants.getPointerWithVariants(ps, compPointer, presetVariantPointer)

        maybeCopyScopedPresetsAndOverrides({
            ps,
            compPointer,
            originalPresetVariantPointer,
            originalCompVariantPointer,
            presetVariantPointer,
            compVariantPointer
        })

        copyCompDataScopedToPreset(ps, originalCompVariantPointer, compVariantPointer)
        copyCompDataScopedToPresetAndStates(ps, compPointer, originalPresetVariantPointer, presetVariantPointer)
        copyScopedReactions(ps, compPointer, originalPresetVariantPointer, presetVariantPointer)
    })

    copyVariablesScopedData(ps, widgetPointer, originalPresetVariantPointer, presetVariantPointer)
}

const getWidgetPresets = (ps: PS, widgetPointer: Pointer) => {
    if (widgetPointer) {
        const widgetData = appStudioDataModel.getData(ps, widgetPointer) || {}

        return _.map(widgetData.presets, presetId => {
            const pointer = ps.pointers.data.getDataItemFromMaster(utils.stripHashIfExists(presetId))
            const data = appStudioDataModel.getData(ps, pointer)

            return {
                pointer,
                name: data?.name
            }
        })
    }

    return []
}

const createPreset = (ps: PS, presetPointer: Pointer, widgetPointer: Pointer, options: any = {}) => {
    if (!widgetPointer) {
        throw new Error('appStudio.presets: Invalid arguments')
    }

    const widgetPresets = getWidgetPresets(ps, widgetPointer)
    const isFirstPreset = _.isEmpty(widgetPresets)

    const presetName = options.newPresetName || generateNewPresetName(ps, NEW_PRESET_PREFIX, widgetPointer)
    validatePresetName(presetName)

    if (isFirstPreset) {
        const variantPointer = createPresetVariant(ps, widgetPointer)
        const presetData = createBlankPresetData(ps, presetPointer, presetName, variantPointer.id)
        appStudioDataModel.setData(ps, presetPointer, presetData)
    } else {
        const firstPresetPointer = _.get(_.head(widgetPresets), 'pointer')
        createDuplicatedPreset(ps, presetPointer, firstPresetPointer, widgetPointer, presetName)
    }
    addPresetsToWidgetDataItem(ps, presetPointer, widgetPointer)

    options.callback?.(presetPointer)
}

const getDefaultPresetVariantId = (ps: PS, widgetPointer: Pointer) => {
    const [firstPreset] = getWidgetPresets(ps, widgetPointer)

    return getPresetVariantId(ps, firstPreset.pointer)
}

const changePresetDataIfNeeded = (ps: PS, refComp: Pointer, oldPresetVariant, newPresetVariant) => {
    const presetData = features.getFeatureData(ps, refComp, constants.DATA_TYPES.presets)

    if (!presetData) {
        return
    }

    if (presetData.layout === oldPresetVariant || presetData.style === oldPresetVariant) {
        features.updateFeatureData(ps, refComp, constants.DATA_TYPES.presets, {
            layout: presetData.layout === oldPresetVariant ? newPresetVariant : presetData.layout,
            style: presetData.style === oldPresetVariant ? newPresetVariant : presetData.style,
            type: constants.PRESETS.PRESET_DATA_TYPE
        })
    }
}

const fixInnerWidgetsPresetData = (
    ps: PS,
    innerWidgets: Pointer[],
    removedPresetVariantId: string,
    defaultVariantId: string
) => {
    innerWidgets.forEach(innerWidgetPointer => {
        changePresetDataIfNeeded(ps, innerWidgetPointer, removedPresetVariantId, defaultVariantId)

        const affectingVariants = variants.getAllAffectingVariantsForPresets(ps, innerWidgetPointer)
        affectingVariants.forEach(variant => {
            const scopedInnerWidgetPointer = variants.getPointerWithVariants(
                ps,
                innerWidgetPointer,
                variant as VariantPointer
            )
            changePresetDataIfNeeded(ps, scopedInnerWidgetPointer, removedPresetVariantId, defaultVariantId)
        })
    })
}

const fixInnerWidgetPresetInOtherWidgets = (ps: PS, widgetPointer: Pointer, removedPresetPointer: Pointer) => {
    const defaultVariantId = getDefaultPresetVariantId(ps, widgetPointer)
    const removedPresetVariantId = getPresetVariantId(ps, removedPresetPointer)
    const allWidgets = appStudioDataModel.getAllWidgets(ps)

    allWidgets.forEach(({pointer: currentWidgetPointer}) => {
        const innerWidgets = appStudioDataModel.getFirstLevelRefChildren(ps, currentWidgetPointer)
        fixInnerWidgetsPresetData(ps, innerWidgets, removedPresetVariantId, defaultVariantId)
    })
}

const removePreset = (ps: PS, presetPointer: Pointer, widgetPointer: Pointer) => {
    if (!presetPointer || !widgetPointer) {
        throw new Error('appStudio.presets: Invalid arguments')
    }

    removePresetFromWidgetDataItem(ps, presetPointer, widgetPointer)
    removePresetVariant(ps, presetPointer, widgetPointer)
    fixInnerWidgetPresetInOtherWidgets(ps, widgetPointer, presetPointer)
}

const duplicatePreset = (
    ps: PS,
    presetPointer: Pointer,
    originalPresetPointer: Pointer,
    widgetPointer: Pointer,
    options: any = {}
) => {
    if (!originalPresetPointer || !widgetPointer) {
        throw new Error('appStudio.presets: Invalid arguments')
    }

    createDuplicatedPreset(ps, presetPointer, originalPresetPointer, widgetPointer, options.newPresetName)
    addDuplicatedPresetToWidgetDataItem(ps, presetPointer, originalPresetPointer, widgetPointer)

    options.callback?.(presetPointer)
}

const getPresetVariantId = (ps: PS, presetPointer: Pointer) => {
    const presetData = appStudioDataModel.getData(ps, presetPointer)
    return utils.stripHashIfExists(_.get(presetData, 'presetId'))
}

const getPresetVariantPointer = (ps: PS, presetPointer: Pointer, widgetPointer: Pointer): VariantPointer => {
    const presetVariantId = getPresetVariantId(ps, presetPointer)
    const rootCompId = appStudioDataModel.getRootCompIdByPointer(ps, widgetPointer)
    return ps.pointers.data.getVariantsDataItem(presetVariantId, rootCompId) as VariantPointer
}

const getPresetDefaultSize = (ps: PS, presetPointer: Pointer) => {
    const presetData = appStudioDataModel.getData(ps, presetPointer)
    return _.get(presetData, 'defaultSize')
}

const getPresetName = (ps: PS, presetPointer: Pointer) => {
    const presetData = appStudioDataModel.getData(ps, presetPointer)
    return _.get(presetData, 'name')
}

const setPresetDefaultSize = (ps: PS, presetPointer: Pointer, defaultSize) => {
    appStudioDataModel.mergeData(ps, presetPointer, {defaultSize})
}

const setPresetName = (ps: PS, presetPointer: Pointer, name: string) => {
    validatePresetName(name)
    appStudioDataModel.mergeData(ps, presetPointer, {name})
}

const displayPreset = (ps: PS, presetPointer: Pointer, widgetPointer: Pointer) => {
    const variantPointer = getPresetVariantPointer(ps, presetPointer, widgetPointer)
    variants.enable(ps, variantPointer)
}

const movePreset = (ps: PS, widgetPointer: Pointer, fromIndex: number, toIndex: number) => {
    appStudioDataModel.moveWidgetEntity(ps, widgetPointer, 'presets', fromIndex, toIndex)
}

export default {
    createPreset,
    removePreset,
    displayPreset,
    setPresetName,
    getPresetName,
    duplicatePreset,
    getWidgetPresets,
    getPresetVariantId,
    getPresetVariantPointer,
    setPresetDefaultSize,
    getPresetDefaultSize,
    createDuplicatedPresetData,
    addPresetsToWidgetDataItem,
    movePreset
}
