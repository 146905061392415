import type {CompStructure, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import constants, {MoveDirections} from '../../constants/constants'
import dataModel from '../../dataModel/dataModel'
import dsUtils from '../../utils/utils'

const {MOVE_DIRECTIONS, RESIZE_SIDES} = constants

function moveDirections(isFullWidth: boolean) {
    const directions: MoveDirections[] = [MOVE_DIRECTIONS.VERTICAL]
    if (!isFullWidth) {
        directions.push(MOVE_DIRECTIONS.HORIZONTAL)
    }
    return directions
}

function getResizableSides(ps: PS, compPointer: Pointer) {
    if (!isCompFullWidth(ps, compPointer)) {
        return [RESIZE_SIDES.LEFT, RESIZE_SIDES.RIGHT]
    }
    return []
}

function moveDirectionsByPointer(ps: PS, compPointer: Pointer) {
    return moveDirections(isCompFullWidth(ps, compPointer))
}

function isCompFullWidth(ps: PS, compPointer: Pointer) {
    return !!dataModel.getPropertiesItem(ps, compPointer).fitToScreenWidth
}

function isCompFullWidthByStructure(ps: PS, compStructure: CompStructure) {
    return !!compStructure.props.fitToScreenWidth
}

function isScreenWidth(ps: PS, component: CompStructure, pageId: string) {
    const props = ps.dal.get(
        ps.pointers.data.getPropertyItem(dsUtils.stripHashIfExists(component.propertyQuery), pageId)
    )
    return _.get(props, 'fitToScreenWidth', false)
}

export default {
    mobileConversionConfig: {
        isScreenWidth
    },
    moveDirections: moveDirectionsByPointer,
    resizableSides: getResizableSides,
    fullWidth: isCompFullWidth,
    fullWidthByStructure: isCompFullWidthByStructure
}
