import type {PublicMethodUtils} from '@wix/document-services-types'
import {transitions, utils} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            components: {
                transitions: {
                    update: publicMethodUtils.defineDataManipulationAction(transitions.updateTransitionsData, {
                        isUpdatingAnchors: utils.NO
                    }),
                    get: publicMethodUtils.defineGetter(transitions.getTransitionsData),
                    remove: publicMethodUtils.defineDataManipulationAction(transitions.removeTransitionsData),
                    has: publicMethodUtils.defineGetter(transitions.hasTransitionsData)
                }
            },
            transitions: {
                enable: publicMethodUtils.defineAction(transitions.setEnableVariantsTransitionsInEditor)
            }
        }
    }
}
