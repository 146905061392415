import type {CompStructure, SerializedCompStructure, TextTheme} from '@wix/document-services-types'
import type {MobileAlgoPluginInitializationArgs} from '../../../types'
import {MASTER_PAGE_ID} from '../../../../../constants/constants'
import type {StructureExtensionAPI} from '../../../../structure'
import type {RMApi} from '../../../../rendererModel'
import type {PageExtensionAPI} from '../../../../page'
import type {ThemeExtAPI} from '../../../../theme/theme'
import {composeProtoInput} from '../../../../../utils/aiPredictionProtoUtil'

interface Layout {
    x: number
    y: number
    width: number
    height: number
    top: number
    left: number
    bottom: number
    right: number
}

export interface ComponentInput {
    id: string
    type: string
    componentType: string
    components: string[]
    data: any
    fixed_position: boolean
    in_footer: boolean
    in_header: boolean
    in_master: boolean
    is_responsive: boolean
    is_visible: boolean
    pageId: string
    parent: string
    url: string | null
}

export const createServerRequest = (
    rootCompStructure: SerializedCompStructure,
    pageId: string,
    {extensionAPI, pointers}: MobileAlgoPluginInitializationArgs
) => {
    const createComponentMap = (compStructure: SerializedCompStructure, compMap: Map<string, ComponentInput>) => {
        const isInMaster = pageId === MASTER_PAGE_ID
        compMap.set(compStructure.id!, {
            id: compStructure.id!,
            type: compStructure.type!,
            componentType: compStructure.componentType,
            components:
                compStructure.components !== undefined
                    ? (compStructure.components.map((childStructure: CompStructure) => childStructure.id) as string[])
                    : [],
            data: compStructure.data,
            url: compStructure.data?.url,
            fixed_position: false,
            is_visible: true,
            in_header: false,
            in_footer: false,
            is_responsive: false,
            in_master: isInMaster,
            parent: compStructure.parent!,
            pageId
        })
        if (compStructure.components) {
            compStructure.components.forEach(childStructure => createComponentMap(childStructure, compMap))
        }
    }

    function createMasterPageComp() {
        const masterPageComps = (extensionAPI as StructureExtensionAPI).components.getChildren(
            pointers.getPointer(MASTER_PAGE_ID, 'DESKTOP')
        )
        const masterPageComp: ComponentInput = {
            id: MASTER_PAGE_ID,
            pageId: MASTER_PAGE_ID,
            type: 'Document',
            componentType: 'mobile.core.components.MasterPage',
            parent: 'SITE_ROOT',
            fixed_position: false,
            data: null,
            is_visible: true,
            url: null,
            in_master: false,
            in_header: false,
            in_footer: false,
            is_responsive: false,
            components: masterPageComps.map(pointer => pointer.id)
        }
        return masterPageComp
    }

    const createSiteDataInput = (compStructure: SerializedCompStructure) => {
        const compMap: Map<string, ComponentInput> = new Map<string, ComponentInput>()
        createComponentMap(compStructure, compMap)
        const masterPageComp = createMasterPageComp()
        const isTemplate = (extensionAPI as RMApi).siteAPI.isTemplate()

        const homePageId = (extensionAPI as PageExtensionAPI).page.getMainPageId()

        const fontMap = Object.values((extensionAPI as ThemeExtAPI).theme.getTextTheme()).map(
            (font: TextTheme) =>
                `${font.fontStyle} ${font.fontVariant} ${font.fontWeight} ${font.fontSize} ${font.fontFamily} {${font.color}}`
        )

        return {
            components: {
                ...Object.fromEntries(compMap),
                [MASTER_PAGE_ID]: masterPageComp
            },
            globalData: {
                pageId,
                homePageId,
                isHomePage: pageId === homePageId,
                isTemplate,
                fontMap
            }
        }
    }
    const createDomMaps = (
        compStructure: SerializedCompStructure,
        layoutMap: Map<string, Layout>,
        zIndexMap: Map<string, number>
    ) => {
        const measurements = compStructure.layout!

        // This is relying on reverse migraiton. Which is not 100% accurate, it is the alternative to:
        // viewerManager.viewerSiteAPI.getBasicMeasureForComp(
        //             pointers.getPointer(compStructure.id!, 'DESKTOP')
        //)

        layoutMap.set(compStructure.id!, {
            x: measurements.x,
            y: measurements.y,
            width: measurements.width,
            height: measurements.height,
            top: measurements.y,
            left: measurements.x,
            bottom: measurements.y + measurements.height,
            right: measurements.x + measurements.width
        })
        zIndexMap.set(compStructure.id!, zIndexMap.size)
        compStructure.components?.forEach(childStructure => createDomMaps(childStructure, layoutMap, zIndexMap))
    }
    const createDomDataInput = (compStructure: SerializedCompStructure) => {
        const layoutMap = new Map<string, Layout>()
        const zIndexMap = new Map<string, number>()
        createDomMaps(compStructure, layoutMap, zIndexMap)
        return {
            domLayout: {...Object.fromEntries(layoutMap)},
            isVisibleList: {},
            textLayout: {},
            zIndexMap: {}
        }
    }

    const metaSiteId = (extensionAPI as RMApi).rendererModel.getMetaSiteId()
    const siteId = (extensionAPI as RMApi).rendererModel.getSiteId()

    return {
        features: {
            values: {
                SectionData: composeProtoInput({
                    siteData: createSiteDataInput(rootCompStructure),
                    domData: createDomDataInput(rootCompStructure)
                }),
                meta_site_id: {strVal: metaSiteId},
                page_id: {strVal: siteId},
                section_name: {strVal: ''},
                revision: {strVal: 'NA'}
            }
        }
    }
}
