import _ from 'lodash'
import type {QItemParams} from './SOQItem'

const canAddToBatch = (item: QItemParams, itemsInBatch: QItemParams[]) => {
    const hasItemsInBatch = !_.isEmpty(itemsInBatch)
    if (hasItemsInBatch) {
        const denyAsync = Boolean(item.isRealAsyncOperation && !item.transaction)
        const deny = Boolean(item.waitingForTransition) || Boolean(item.noBatching) || denyAsync
        return !deny
    }
    return true
}

const addingToBatch = (item: QItemParams, itemsInBatch: QItemParams[]) => {
    if (!canAddToBatch(item, itemsInBatch)) {
        throw new Error("the site is during update or this item isn't compatible with the current batch")
    }
    return Boolean(item.noBatching) || Boolean(item.noBatchingAfter)
}

export {canAddToBatch, addingToBatch}
