export default {
    'wysiwyg.viewer.skins.button.ButtonArrow': {maxH: 100},
    'wysiwyg.viewer.skins.button.PlasticButton': {_maxWidth: 400, _maxHeight: 400},
    'wysiwyg.viewer.skins.button.RibbonButton': {maxH: 170},
    'wysiwyg.viewer.skins.button.ShinyGradientButton': {_maxWidth: 400, _maxHeight: 400},
    'wysiwyg.viewer.skins.line.DashedLine': {_maxHeight: 2},
    'wysiwyg.viewer.skins.line.DoubleLine2': {minH: 30},
    'wysiwyg.viewer.skins.line.DoubleLine3': {minH: 30},
    'wysiwyg.viewer.skins.ClipArtSkin': {img: {skin: 'mobile.core.skins.ImageNewSkin'}},
    'wysiwyg.viewer.skins.photo.CirclePhoto': {img: {skin: 'skins.core.ImageNewSkinZoomable'}},
    'wysiwyg.viewer.skins.photo.DefaultPhoto': {
        contentPaddingLeft: 0,
        contentPaddingRight: 0,
        contentPaddingTop: 0,
        contentPaddingBottom: 0,
        img: {skin: 'skins.core.ImageNewSkinZoomable'}
    },
    'wysiwyg.viewer.skins.photo.DoubleBorderCirclePhoto': {img: {skin: 'skins.core.ImageNewSkinZoomable'}},
    'wysiwyg.viewer.skins.photo.DoubleBorderPhoto': {img: {skin: 'skins.core.ImageNewSkinZoomable'}},
    'wysiwyg.viewer.skins.photo.GlowLinePhoto': {img: {skin: 'skins.core.ImageNewSkinZoomable'}},
    'wysiwyg.viewer.skins.photo.IronPhoto': {img: {skin: 'skins.core.ImageNewSkinZoomable'}},
    'wysiwyg.viewer.skins.photo.LiftedShadowPhoto': {img: {skin: 'skins.core.ImageNewSkinZoomable'}},
    'wysiwyg.viewer.skins.photo.LiftedTopPhoto': {img: {skin: 'skins.core.ImageNewSkinZoomable'}},
    'wysiwyg.viewer.skins.photo.MouseOverPhoto': {img: {skin: 'skins.core.ImageNewSkinZoomable'}},
    'wysiwyg.viewer.skins.photo.NewPolaroidPhoto': {
        contentPaddingBottom: '50px',
        img: {skin: 'skins.core.ImageNewSkinZoomable'}
    },
    'wysiwyg.viewer.skins.photo.NoSkinPhoto': {img: {skin: 'skins.core.ImageNewSkinZoomable'}},
    'wysiwyg.viewer.skins.photo.PaperclipPhoto': {img: {skin: 'skins.core.ImageNewSkin'}},
    'wysiwyg.viewer.skins.photo.PolaroidPhoto': {
        contentPaddingBottom: '30px',
        img: {skin: 'skins.core.ImageNewSkinZoomable'}
    },
    'wysiwyg.viewer.skins.photo.RoundPhoto': {img: {skin: 'skins.core.ImageNewSkinZoomable'}},
    'wysiwyg.viewer.skins.photo.RoundShadowPhoto': {img: {skin: 'skins.core.ImageNewSkinZoomable'}},
    'wysiwyg.viewer.skins.photo.ScotchDoubleHorizontal': {img: {skin: 'skins.core.ImageNewSkinZoomable'}},
    'wysiwyg.viewer.skins.photo.ScotchDoubleVertical': {img: {skin: 'skins.core.ImageNewSkinZoomable'}},
    'wysiwyg.viewer.skins.photo.ScotchTapePhoto': {img: {skin: 'skins.core.ImageNewSkin'}},
    'wysiwyg.viewer.skins.photo.ScotchTopPhoto': {img: {skin: 'skins.core.ImageNewSkinZoomable'}},
    'wysiwyg.viewer.skins.photo.SloppyPhoto': {img: {skin: 'skins.core.ImageNewSkinZoomable'}},
    'wysiwyg.viewer.skins.photo.VintagePhoto': {
        contentPaddingLeft: '30px',
        contentPaddingRight: '30px',
        contentPaddingTop: '30px',
        contentPaddingBottom: '30px',
        img: {skin: 'skins.core.ImageNewSkinZoomable'}
    },
    'skins.viewer.bgVideo.html5VideoSkin': {poster: {skin: 'skins.core.ImageNewSkinZoomable'}},
    'skins.viewer.bgVideo.iframeVideoSkin': {poster: {skin: 'skins.core.ImageNewSkinZoomable'}},
    'skins.input.CheckboxGroupDefaultSkin': {checkbox: {skin: 'wysiwyg.viewer.skins.input.CheckboxBasicSkin'}},
    'wysiwyg.viewer.skins.PasswordLoginSkin': {passwordInput: {skin: 'wysiwyg.viewer.skins.input.TextInputSquareSkin'}},
    'wysiwyg.common.components.dropdownmenu.viewer.skins.ArrowRightMenuButtonSkin': {
        maxH: 280,
        repeaterButton: {skin: 'wysiwyg.viewer.skins.dropmenubutton.ArrowRightMenuButtonNSkin', styleGroup: 'inherit'}
    },
    'wysiwyg.common.components.dropdownmenu.viewer.skins.IndentedMenuButtonSkin': {
        maxH: 430,
        repeaterButton: {skin: 'wysiwyg.viewer.skins.dropmenubutton.IndentedMenuButtonNSkin', styleGroup: 'inherit'}
    },
    'wysiwyg.common.components.dropdownmenu.viewer.skins.LinesMenuButtonBorderRadiusFixSkin': {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.LinesMenuButtonNBorderRadiusFixSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.common.components.dropdownmenu.viewer.skins.LinesMenuButtonSkin': {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.LinesMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.common.components.dropdownmenu.viewer.skins.OverlineMenuButtonHorizontalMenuAdaptationSkin': {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.OverlineMenuButtonHorizontalMenuAdaptationSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.common.components.dropdownmenu.viewer.skins.OverlineMenuButtonSkin': {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.OverlineMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.common.components.dropdownmenu.viewer.skins.PointerMenuButtonHorizontalMenuAdaptationSkin': {
        menuTotalBordersY: 1,
        labelPad: 13,
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.PointerMenuButtonHorizontalMenuAdaptationSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.common.components.dropdownmenu.viewer.skins.PointerMenuButtonSkin': {
        menuTotalBordersY: 1,
        labelPad: 13,
        repeaterButton: {skin: 'wysiwyg.viewer.skins.dropmenubutton.PointerMenuButtonNSkin', styleGroup: 'inherit'}
    },
    'wysiwyg.common.components.dropdownmenu.viewer.skins.RibbonsMenuButtonSkin': {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.RibbonsMenuButtonNSkin',
            styleGroup: 'inherit'
        },
        ribbonExtra: 10
    },
    'wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateBasicMenuButtonSkin': {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.SeparateBasicMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateIndentedMenuButtonSkin': {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.SeparateIndentedMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateLinesMenuButtonHorizontalMenuAdaptationSkin': {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.SeparateLinesMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateLinesMenuButtonSkin': {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.SeparateLinesMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateShinyIIMenuButtonBorderRadiusFixSkin': {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.SeparateShinyIIMenuButtonNBorderRadiusFixSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateShinyIIMenuButtonSkin': {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.SeparateShinyIIMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateShinyIMenuButtonSkin': {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.SeparateShinyIMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.common.components.dropdownmenu.viewer.skins.ShinyMenuIButtonSkin': {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.ShinyMenuIButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.common.components.dropdownmenu.viewer.skins.ShinyMenuIIButtonSkin': {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.ShinyMenuIIButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.common.components.dropdownmenu.viewer.skins.SloppyBorderMenuButtonSkin': {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.SloppyBorderMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.common.components.dropdownmenu.viewer.skins.SolidColorMenuButtonSkin': {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.SolidColorMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.common.components.dropdownmenu.viewer.skins.TextOnlyMenuButtonBgFixSkin': {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.TextOnlyMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.common.components.dropdownmenu.viewer.skins.TextOnlyMenuButtonSkin': {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.TextOnlyMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.common.components.dropdownmenu.viewer.skins.TextSeparatorsMenuButtonSkin': {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.TextSeparatorsMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.common.components.dropdownmenu.viewer.skins.VerticalRibbonsMenuButtonSkin': {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.VerticalRibbonsMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.viewer.skins.dropmenu.DiagonalMenuSkin': {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.DiagonalMenuButtonSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.viewer.skins.menu.ArrowsMenuSkin': {
        minH: 40,
        maxH: 40,
        repeaterButton: {skin: 'wysiwyg.viewer.skins.menubutton.ArrowsMenuButtonSkin', styleGroup: 'inherit'}
    },
    'wysiwyg.viewer.skins.menu.CirclesMenuSkin': {
        minH: 140,
        maxH: 140,
        repeaterButton: {skin: 'wysiwyg.viewer.skins.menubutton.CirclesMenuButtonSkin', styleGroup: 'inherit'}
    },
    'wysiwyg.viewer.skins.menu.SeparateArrowDownMenuSkin': {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.menubutton.SeparateArrowDownMenuButtonSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.viewer.skins.menu.SeparatedArrowsMenuSkin': {
        minH: 40,
        maxH: 40,
        repeaterButton: {skin: 'wysiwyg.viewer.skins.menubutton.SeparatedArrowsMenuButtonSkin', styleGroup: 'inherit'}
    },
    'wysiwyg.viewer.skins.menubutton.ArrowsMenuButtonSkin': {minH: 40, maxH: 40},
    'wysiwyg.viewer.skins.menubutton.CirclesMenuButtonSkin': {minH: 140, maxH: 140},
    'wysiwyg.viewer.skins.menubutton.SeparatedArrowsMenuButtonSkin': {minH: 40, maxH: 40},
    'wysiwyg.viewer.skins.dropmenubutton.ArrowsMenuButtonNSkin': {minH: 40, maxH: 40},
    'wysiwyg.viewer.skins.dropmenubutton.PointerMenuButtonHorizontalMenuAdaptationSkin': {addToMinH: 14},
    'wysiwyg.viewer.skins.dropmenubutton.PointerMenuButtonNSkin': {addToMinH: 14},
    'wysiwyg.viewer.skins.dropmenubutton.SeparatedArrowsMenuButtonNSkin': {minH: 40, maxH: 40},
    'wysiwyg.viewer.skins.page.BasicPageSkin': {paddingBottom: '20px'},
    'wysiwyg.viewer.skins.page.BorderPageSkin': {paddingBottom: '20px'},
    'wysiwyg.viewer.skins.page.LiftedBottomPageSkin': {paddingBottom: '20px'},
    'wysiwyg.viewer.skins.page.LiftedShadowPageSkin': {paddingBottom: '20px'},
    'wysiwyg.viewer.skins.page.LiftedTopPageSkin': {paddingBottom: '20px'},
    'wysiwyg.viewer.skins.page.NoMarginPageSkin': {paddingBottom: '20px'},
    'wysiwyg.viewer.skins.page.ShinyIPageSkin': {paddingBottom: '20px'},
    'wysiwyg.viewer.skins.paginatedgrid.PaginatedGridArrowsOutside': {
        heightDiff: 20,
        widthDiff: 100,
        img: {skin: 'skins.core.ImageSkin'}
    },
    'wysiwyg.viewer.skins.paginatedgrid.PaginatedGridDefaultSkin': {img: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.paginatedgrid.PaginatedGridOverlay': {img: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.paginatedgrid.PaginatedGridRibbonArrows': {
        heightDiff: 20,
        widthDiff: 20,
        img: {skin: 'skins.core.ImageSkin'}
    },
    'wysiwyg.viewer.skins.paginatedgrid.PaginatedGridTextBottom': {heightDiff: 60, img: {skin: 'skins.core.ImageSkin'}},
    'skins.viewer.galleryslider.SliderDisplayerIronSkin2': {image: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.gallery.SliderGalleryBorder': {
        widthDiff: 0,
        heightDiff: 0,
        imageItem: {skin: 'wysiwyg.viewer.skins.galleryslider.SliderDisplayerDefaultSkin', styleGroup: 'inherit'},
        itemContainerTotalOffset: 70
    },
    'wysiwyg.viewer.skins.galleryselectableslider.SelectableSliderGalleryDefaultSkin': {
        widthDiff: 0,
        heightDiff: 0,
        imageItem: {
            skin: 'wysiwyg.viewer.skins.galleryselectableslider.SelectableSliderDisplayerDefaultSkin',
            styleGroup: 'inherit'
        },
        itemContainerTotalOffset: 70
    },
    'wysiwyg.viewer.skins.galleryslider.SliderDisplayerVintageSkin': {image: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.galleryslider.SliderGalleryCircleSkin': {
        widthDiff: 0,
        heightDiff: 0,
        itemContainerTotalOffset: 70,
        imageItem: {skin: 'wysiwyg.viewer.skins.galleryslider.SliderDisplayerCircleSkin', styleGroup: 'inherit'}
    },
    'wysiwyg.viewer.skins.galleryslider.SliderGalleryDefaultSkin': {
        widthDiff: 0,
        heightDiff: 0,
        itemContainerTotalOffset: 70,
        imageItem: {skin: 'wysiwyg.viewer.skins.galleryslider.SliderDisplayerDefaultSkin', styleGroup: 'inherit'}
    },
    'wysiwyg.viewer.skins.galleryslider.SliderGalleryIronSkin': {
        imageItem: {
            skin: 'wysiwyg.viewer.skins.galleryslider.SliderDisplayerIronSkin',
            styleGroup: 'inherit'
        },
        itemContainerAdditionalHeight: -7,
        itemContainerAdditionalwidth: 114,
        itemContainerTotalOffset: 114
    },
    'wysiwyg.viewer.skins.galleryslider.SliderGalleryMinimal': {
        widthDiff: 0,
        heightDiff: 0,
        imageItem: {skin: 'wysiwyg.viewer.skins.galleryslider.SliderDisplayerDefaultSkin', styleGroup: 'inherit'},
        itemContainerTotalOffset: 70
    },
    'wysiwyg.viewer.skins.galleryslider.SliderGalleryNoArrow': {
        widthDiff: 0,
        heightDiff: 0,
        imageItem: {skin: 'wysiwyg.viewer.skins.galleryslider.SliderDisplayerNoArrows', styleGroup: 'inherit'}
    },
    'wysiwyg.viewer.skins.galleryslider.SliderGalleryScotchTapeSkin': {
        imageItem: {
            skin: 'wysiwyg.viewer.skins.galleryslider.SliderDisplayerScotchTapeSkin',
            styleGroup: 'inherit'
        },
        itemContainerTotalOffset: 80
    },
    'wysiwyg.viewer.skins.galleryslider.SliderGalleryVintageSkin': {
        widthDiff: 0,
        heightDiff: 0,
        imageItem: {skin: 'wysiwyg.viewer.skins.galleryslider.SliderDisplayerVintageSkin', styleGroup: 'inherit'},
        itemContainerTotalOffset: 70
    },
    'wysiwyg.viewer.skins.displayers.BorderDisplayer': {
        widthDiff: 10,
        heightDiff: 10,
        image: {skin: 'skins.core.ImageSkin'}
    },
    'wysiwyg.viewer.skins.displayers.DetailedDisplayerSkin': {
        heightDiff: 50,
        widthDiff: 0,
        image: {skin: 'skins.core.ImageSkin'}
    },
    'wysiwyg.viewer.skins.displayers.LiftedShadowDisplayer': {
        heightDiff: 50,
        widthDiff: 10,
        image: {skin: 'skins.core.ImageSkin'}
    },
    'wysiwyg.viewer.skins.displayers.MinimalDisplayer': {heightDiff: 0, image: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.displayers.SimpleDetailedDisplayer': {
        widthDiff: 20,
        heightDiff: 20,
        image: {skin: 'skins.core.ImageSkin'}
    },
    'wysiwyg.viewer.skins.dropmenu.ArrowsMenuNSkin': {
        minH: 40,
        maxH: 40,
        repeaterButton: {skin: 'wysiwyg.viewer.skins.dropmenubutton.ArrowsMenuButtonNSkin', styleGroup: 'inherit'}
    },
    'wysiwyg.viewer.skins.dropmenu.CirclesMenuNSkin': {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.CirclesMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.viewer.skins.dropmenu.ForkedBannerMenuSkin': {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.ForkedBannerMenuButtonSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.viewer.skins.dropmenu.ForkedRibbonMenuSkin': {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.ForkedRibbonMenuButtonSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.viewer.skins.dropmenu.SeparateArrowDownMenuNSkin': {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.SeparateArrowDownMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.viewer.skins.dropmenu.SeparatedArrowsMenuNSkin': {
        minH: 40,
        maxH: 40,
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.SeparatedArrowsMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.viewer.skins.dropmenu.SlantedMenuNSkin': {
        maxH: 210,
        repeaterButton: {skin: 'wysiwyg.viewer.skins.dropmenubutton.SlantedMenuButtonNSkin', styleGroup: 'inherit'}
    },
    'wysiwyg.viewer.skins.gallery.LiftedShadowMatrixGallery': {
        widthDiff: 0,
        heightDiff: 40,
        imageItem: {skin: 'wysiwyg.viewer.skins.displayers.LiftedShadowDisplayer', styleGroup: 'displayer'}
    },
    'wysiwyg.viewer.skins.gallery.MatrixGalleryMinimal': {
        heightDiff: 25,
        imageItem: {skin: 'wysiwyg.viewer.skins.displayers.MinimalDisplayer'}
    },
    'wysiwyg.viewer.skins.gallery.MatrixGalleryTextBelowSkin': {
        heightDiff: 40,
        imageItem: {skin: 'wysiwyg.viewer.skins.displayers.TextBelowDisplayerSkin', styleGroup: 'displayer'}
    },
    'wysiwyg.viewer.skins.gallery.TextOnRollMatrixGallerySkin': {
        heightDiff: 50,
        imageItem: {skin: 'wysiwyg.viewer.skins.displayers.TextOnRollDisplayerSkin', styleGroup: 'displayer'}
    },
    'wysiwyg.viewer.skins.gallerymatrix.GlowLineMatrixGallery': {
        heightDiff: 25,
        imageItem: {skin: 'wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerGlowLine', styleGroup: 'inherit'}
    },
    'wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerGlowLine': {image: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.galleryselectableslider.SelectableSliderDisplayerDefaultSkin': {
        image: {skin: 'skins.core.ImageSkin'}
    },
    'wysiwyg.viewer.skins.paginatedgrid.PaginatedGridPolaroid': {heightDiff: 40},
    'wysiwyg.viewer.skins.photo.LfShwDBPhoto': {img: {skin: 'skins.core.ImageNewSkin'}},
    'wysiwyg.viewer.skins.SliderGallerySkin': {
        imageItem: {skin: 'wysiwyg.viewer.skins.displayers.DetailedDisplayerSkin'}
    },
    'wysiwyg.viewer.skins.SlideShowGallerySkin': {
        imageItem: {skin: 'wysiwyg.viewer.skins.displayers.DetailedDisplayerSkin'}
    },
    'wysiwyg.viewer.skins.sm.SMContainerSkin': {register: {skin: 'wysiwyg.viewer.skins.sm.SMRegisterSkin'}},
    'wysiwyg.viewer.skins.sm.SMLoginSkin': {email: {skin: 'wysiwyg.viewer.skins.input.TextInputSquareSkin'}},
    'wysiwyg.viewer.skins.sm.SMResetPasswordSkin': {password: {skin: 'wysiwyg.viewer.skins.input.TextInputSquareSkin'}},
    'wysiwyg.viewer.skins.TryAgainMessageViewSkin': {
        okButton: {skin: 'wysiwyg.viewer.skins.button.ViewerButtonBlueSkin'}
    },
    'wysiwyg.viewer.skins.wphoto.PaperclipWPhotoSkin': {img: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.wphoto.PolaroidWPhotoSkin': {img: {skin: 'skins.core.ImageSkin'}},
    'skins.core.NetworkListSkin': {itemSkinClassName: 'mobile.core.skins.NetworkItemSkin'},
    'skins.core.PhotoFullScreenSkin': {img: {skin: 'skins.core.ImageSkin'}},
    'skins.core.PhotoGalleryFullScreenDefaultSkin': {imageSkinClassName: 'skins.core.ImageSkin'},
    'skins.core.PhotoGalleryGridDefaultSkin': {
        itemSkinClassName: 'skins.core.ImageSkin',
        fullScreenViewSkinClassName: 'mobile.core.skins.PhotoGalleryFullScreenDefaultSkin'
    },
    'skins.core.PhotoSkin': {
        img: {skin: 'skins.core.ImageSkin'},
        photoFullScreen: {skin: 'mobile.core.skins.PhotoFullScreenSkin'}
    },
    'wysiwyg.viewer.skins.contactform.ButtonInputSkinDefault': {
        button: {skin: 'wysiwyg.viewer.skins.contactform.ButtonBaseSkinDefault'}
    },
    'wysiwyg.viewer.skins.contactform.DefaultContactForm': {hidePlaceholders: false},
    'wysiwyg.viewer.skins.CalendarDefaultSkin': {month: {skin: 'wysiwyg.viewer.skins.MonthDefaultSkin'}},
    'wysiwyg.viewer.skins.CalendarTextBetweenNavSkin': {month: {skin: 'wysiwyg.viewer.skins.MonthDefaultSkin'}},
    'wysiwyg.viewer.skins.CalendarTextYearNavSkin': {month: {skin: 'wysiwyg.viewer.skins.MonthDefaultSkin'}},
    'wysiwyg.viewer.skins.input.DatePickerDefaultSkin': {calendar: {skin: 'wysiwyg.viewer.skins.CalendarDefaultSkin'}},
    'wysiwyg.viewer.skins.input.DatePickerTextBetweenNavSkin': {
        calendar: {skin: 'wysiwyg.viewer.skins.CalendarTextBetweenNavSkin'}
    },
    'wysiwyg.viewer.skins.input.DatePickerTextYearNavSkin': {
        calendar: {skin: 'wysiwyg.viewer.skins.CalendarTextYearNavSkin'}
    },
    'wysiwyg.viewer.skins.MonthDefaultSkin': {day: {skin: 'wysiwyg.viewer.skins.DayDefaultSkin'}},
    'wysiwyg.viewer.skins.dialogs.enterPasswordDialogSkinMaterial': {
        inputWithValidation: {skin: 'wysiwyg.viewer.skins.InputWithValidationSkin'}
    },
    'wysiwyg.viewer.skins.dialogs.notificationDialogSkinMaterial': {
        inputWithValidation: {skin: 'wysiwyg.viewer.skins.InputWithValidationSkin'}
    },
    'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.emailVerificationDialogSkinMaterial': {
        inputWithValidation: {skin: 'wysiwyg.viewer.skins.InputWithValidationSkin'}
    },
    'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.memberLoginDialogSkinMaterial': {
        inputWithValidation: {skin: 'wysiwyg.viewer.skins.InputWithValidationSkin'}
    },
    'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.noPermissionsToPageMaterial': {
        inputWithValidation: {skin: 'wysiwyg.viewer.skins.InputWithValidationSkin'}
    },
    'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.requestPasswordResetDialogSkinMaterial': {
        inputWithValidation: {skin: 'wysiwyg.viewer.skins.InputWithValidationSkin'}
    },
    'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.resetPasswordDialogSkinMaterial': {
        inputWithValidation: {skin: 'wysiwyg.viewer.skins.InputWithValidationSkin'}
    },
    'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.sentConfirmationEmailMaterial': {
        inputWithValidation: {skin: 'wysiwyg.viewer.skins.InputWithValidationSkin'}
    },
    'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.signUpDialogSkinMaterial': {
        inputWithValidation: {skin: 'wysiwyg.viewer.skins.InputWithValidationSkin'}
    },
    'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.welcomeDialogSkinMaterial': {
        inputWithValidation: {skin: 'wysiwyg.viewer.skins.InputWithValidationSkin'},
        okButton: {skin: 'wysiwyg.viewer.skins.button.BasicButton', styleGroup: 'b1'}
    },
    'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.welcomeDialogSkinNew': {
        inputWithValidation: {skin: 'wysiwyg.viewer.skins.InputWithValidationSkin'},
        okButton: {skin: 'wysiwyg.viewer.skins.button.BasicButton', styleGroup: 'b1'}
    },
    'skins.viewer.displayers.SlideIronDisplayer': {
        imageWrapperBottom: 12,
        imageWrapperTop: 12,
        imageWrapperRight: 12,
        imageWrapperLeft: 12,
        image: {skin: 'skins.core.ImageSkin'}
    },
    'skins.viewer.displayers.SlideScotchTapeDisplayer': {image: {skin: 'skins.core.ImageSkin'}},
    'skins.viewer.gallerymatrix.MatrixDisplayerIronSkin': {
        imageWrapperBottom: -12,
        imageWrapperTop: -12,
        imageWrapperRight: -12,
        imageWrapperLeft: -12,
        image: {skin: 'skins.core.ImageSkin'}
    },
    'skins.viewer.gallerymatrix.MatrixDisplayerScotchTapeSkin': {image: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.common.components.matrixgallery.viewer.skins.MatrixDisplayerSeparateTextBoxSkin': {
        heightDiff: 40,
        image: {skin: 'skins.core.ImageSkin'}
    },
    'wysiwyg.common.components.matrixgallery.viewer.skins.MatrixDisplayerTextOnCenterSkin': {
        image: {skin: 'skins.core.ImageSkin'}
    },
    'wysiwyg.viewer.skins.displayers.SlideShowDisplayerLiftedShadowSkin': {image: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.displayers.SlideShowPolaroidDisplayer': {
        heightDiff: 70,
        image: {skin: 'skins.core.ImageSkin'}
    },
    'wysiwyg.viewer.skins.displayers.SlideShowSloopyDisplayer': {image: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.displayers.SlideShowTextBottomDisplayer': {
        heightDiff: 70,
        image: {skin: 'skins.core.ImageSkin'}
    },
    'wysiwyg.viewer.skins.displayers.SlideTextFloatingDisplayer': {image: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.displayers.SlideTextOverlayDisplayer': {image: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.displayers.SlideTextRightDisplayer': {
        widthDiff: 240,
        m_widthDiff: 0,
        image: {skin: 'skins.core.ImageSkin'}
    },
    'wysiwyg.viewer.skins.displayers.TextBelowDisplayerSkin': {
        heightDiff: 50,
        widthDiff: 0,
        image: {skin: 'skins.core.ImageSkin'}
    },
    'wysiwyg.viewer.skins.displayers.TextOnRollDisplayerSkin': {
        heightDiff: 20,
        widthDiff: 20,
        image: {skin: 'skins.core.ImageSkin'}
    },
    'wysiwyg.viewer.skins.gallerymatrix.BlogMatrixDisplayer': {image: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerCircleSkin': {
        addMarginToContainer: true,
        image: {skin: 'skins.core.ImageSkin'}
    },
    'wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerDefaultSkin': {image: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerLiftedShadow': {image: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerPolaroidSkin': {
        heightDiff: 80,
        m_heightDiff: 40,
        image: {skin: 'skins.core.ImageSkin'}
    },
    'wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerSloopy': {image: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerTextBelowSkin': {
        heightDiff: 80,
        m_heightDiff: 40,
        image: {skin: 'skins.core.ImageSkin'}
    },
    'wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerTextSlideUpSkin': {image: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerTransparentSkin': {image: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.gallerymatrix.PolaroidDisplayerCustomHeightSkin': {
        customDiff: 'panel',
        heightDiff: 80,
        m_heightDiff: 40,
        image: {skin: 'skins.core.ImageSkin'}
    },
    'wysiwyg.viewer.skins.gallerymatrix.TextBottomDisplayerCustomHeightSkin': {
        customDiff: 'panel',
        heightDiff: 80,
        m_heightDiff: 40,
        image: {skin: 'skins.core.ImageSkin'}
    },
    'wysiwyg.viewer.skins.galleryslider.SliderDisplayerCircleSkin': {image: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.galleryslider.SliderDisplayerDefaultSkin': {image: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.galleryslider.SliderDisplayerIronSkin': {image: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.galleryslider.SliderDisplayerNoArrows': {image: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.galleryslider.SliderDisplayerScotchTapeSkin': {image: {skin: 'skins.core.ImageSkin'}},
    'skins.viewer.documentmedia.DocumentMediaSkin': {img: {skin: 'skins.core.ImageNewSkin'}},
    'wysiwyg.viewer.skins.EbayItemsBySellerSkin': {minW: 515, minH: 180, maxW: 820},
    'wysiwyg.viewer.skins.ExpandableMenuSkin': {menuContainer: {dontMergeParams: true}},
    'wysiwyg.viewer.skins.ResponsiveExpandableMenuSkin': {menuContainer: {dontMergeParams: true}},
    'wysiwyg.viewer.skins.FileUploaderDefaultSkin': {uploadName: {skin: 'wysiwyg.viewer.skins.UploadNameDefaultSkin'}},
    'wysiwyg.viewer.skins.ResponsiveFileUploaderDefaultSkin': {
        uploadName: {skin: 'wysiwyg.viewer.skins.UploadNameDefaultSkin'}
    },
    'wysiwyg.viewer.skins.FlashComponentSkin': {noFlashImg: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.FlickrBadgeWidgetSkin': {minW: 130, minH: 130},
    'wysiwyg.viewer.skins.IconSkin': {
        image: {skin: 'skins.core.ImageNewSkin'},
        svg: {skin: 'skins.viewer.VectorImageShapeBasicSkin'}
    },
    'wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin': {
        defaultImage: {skin: 'skins.core.ImageNewSkin'},
        hoverImage: {skin: 'skins.core.ImageNewSkin'},
        activeImage: {skin: 'skins.core.ImageNewSkin'}
    },
    'wysiwyg.skins.ImageZoomDisplayerSkin': {image: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.skins.ImageZoomSkin': {
        imageItem: {skin: 'wysiwyg.skins.ImageZoomDisplayerSkin'},
        mediaZoom: {skin: 'wysiwyg.viewer.skins.MediaZoomSkin'}
    },
    'wysiwyg.skins.NonOptimizedImageZoomSkin': {
        imageItem: {skin: 'wysiwyg.skins.ImageZoomDisplayerSkin'},
        mediaZoom: {skin: 'wysiwyg.viewer.skins.NonOptimizedMediaZoomSkin'}
    },
    'wysiwyg.viewer.skins.TouchMediaZoom': {image: {skin: 'wysiwyg.viewer.skins.TouchMediaZoomItem'}},
    'wysiwyg.viewer.skins.TouchMediaZoomItem': {image: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.LanguageSelectorSkin': {languageButton: {skin: 'wysiwyg.viewer.skins.LanguageButtonSkin'}},
    'wysiwyg.viewer.skins.displayers.LinkBarItemNoBGSkin': {image: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.displayers.LinkBarItemSkin': {image: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.LinkBarNoBGSkin': {
        imageItem: {skin: 'wysiwyg.viewer.skins.displayers.LinkBarItemNoBGSkin', styleGroup: 'displayer'}
    },
    'wysiwyg.viewer.skins.LoginSocialBarSkin': {
        button: {skin: 'wysiwyg.viewer.skins.LoginSocialButtonSkin'},
        avatar: {skin: 'wysiwyg.viewer.skins.IconSkin'}
    },
    'wysiwyg.viewer.skins.LoginSocialButtonSkin': {icon: {skin: 'wysiwyg.viewer.skins.IconSkin'}},
    'skins.viewer.gallerymatrix.MatrixGalleryIronSkin': {
        heightDiff: 40,
        imageItem: {skin: 'skins.viewer.gallerymatrix.MatrixDisplayerIronSkin', styleGroup: 'inherit'}
    },
    'skins.viewer.gallerymatrix.MatrixGalleryScotchTapeSkin': {
        heightDiff: 50,
        imageItem: {skin: 'skins.viewer.gallerymatrix.MatrixDisplayerScotchTapeSkin', styleGroup: 'inherit'}
    },
    'wysiwyg.common.components.matrixgallery.viewer.skins.MatrixGallerySeparateTextBoxSkin': {
        heightDiff: 40,
        imageItem: {
            skin: 'wysiwyg.common.components.matrixgallery.viewer.skins.MatrixDisplayerSeparateTextBoxSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.common.components.matrixgallery.viewer.skins.MatrixGalleryTextOnCenterSkin': {
        heightDiff: 25,
        imageItem: {
            skin: 'wysiwyg.common.components.matrixgallery.viewer.skins.MatrixDisplayerTextOnCenterSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.viewer.skins.displayers.CircleDetailedDisplayer': {image: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.displayers.PolaroidDisplayer': {heightDiff: 40, image: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.gallery.CircleMatrixGallery': {
        heightDiff: 40,
        imageItem: {skin: 'wysiwyg.viewer.skins.displayers.CircleDetailedDisplayer', styleGroup: 'inherit'}
    },
    'wysiwyg.viewer.skins.gallery.MatrixGallerySkin': {
        heightDiff: 40,
        imageItem: {skin: 'wysiwyg.viewer.skins.displayers.DetailedDisplayerSkin', styleGroup: 'displayer'}
    },
    'wysiwyg.viewer.skins.gallery.PolaroidMatrixGallery': {
        imageItem: {
            skin: 'wysiwyg.viewer.skins.displayers.PolaroidDisplayer',
            styleGroup: 'displayer'
        }
    },
    'wysiwyg.viewer.skins.gallerymatrix.BlogMatrixGallery': {
        heightDiff: 25,
        imageItem: {skin: 'wysiwyg.viewer.skins.gallerymatrix.BlogMatrixDisplayer', styleGroup: 'inherit'}
    },
    'wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerVintage': {
        heightDiff: 80,
        image: {skin: 'skins.core.ImageSkin'}
    },
    'wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryCircleSkin': {
        heightDiff: 40,
        imageItem: {skin: 'wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerCircleSkin', styleGroup: 'inherit'}
    },
    'wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryDefaultSkin': {
        heightDiff: 25,
        imageItem: {skin: 'wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerDefaultSkin', styleGroup: 'inherit'}
    },
    'wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryLiftedShadow': {
        heightDiff: 25,
        imageItem: {skin: 'wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerLiftedShadow', styleGroup: 'inherit'}
    },
    'wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryPolaroidSkin': {
        heightDiff: 25,
        imageItem: {skin: 'wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerPolaroidSkin', styleGroup: 'inherit'}
    },
    'wysiwyg.viewer.skins.gallerymatrix.MatrixGallerySloopy': {
        heightDiff: 25,
        imageItem: {skin: 'wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerSloopy', styleGroup: 'inherit'}
    },
    'wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryTextBelowSkin': {
        heightDiff: 25,
        imageItem: {skin: 'wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerTextBelowSkin', styleGroup: 'inherit'}
    },
    'wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryTextSlideUpSkin': {
        heightDiff: 25,
        imageItem: {skin: 'wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerTextSlideUpSkin', styleGroup: 'inherit'}
    },
    'wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryTransparentSkin': {
        heightDiff: 25,
        imageItem: {skin: 'wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerTransparentSkin', styleGroup: 'inherit'}
    },
    'wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryVintage': {
        heightDiff: 25,
        imageItem: {skin: 'wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerVintage', styleGroup: 'inherit'}
    },
    'wysiwyg.viewer.skins.gallerymatrix.PolaroidCustomHeightSkin': {
        heightDiff: 25,
        imageItem: {skin: 'wysiwyg.viewer.skins.gallerymatrix.PolaroidDisplayerCustomHeightSkin', styleGroup: 'inherit'}
    },
    'wysiwyg.viewer.skins.gallerymatrix.TextBottomCustomHeightSkin': {
        heightDiff: 25,
        imageItem: {
            skin: 'wysiwyg.viewer.skins.gallerymatrix.TextBottomDisplayerCustomHeightSkin',
            styleGroup: 'inherit'
        }
    },
    'skins.viewer.mediaControlFullscreenDefaultSkin': {icon: {skin: 'skins.viewer.VectorImageSkin'}},
    'skins.viewer.mediaControlPlayDefaultSkin': {icon: {skin: 'skins.viewer.VectorImageSkin'}},
    'skins.viewer.mediaControlProgressDefaultSkin': {
        playheadIcon: {skin: 'skins.viewer.VectorImageSkin'},
        playheadGhostIcon: {skin: 'skins.viewer.VectorImageSkin'},
        storyboard: {skin: 'skins.viewer.mediaControlStoryboardDefaultSkin'},
        storyboardMinimal: {skin: 'skins.viewer.mediaControlStoryboardMinimalSkin'}
    },
    'skins.viewer.mediaControlsDarkSkin': {
        play: {skin: 'skins.viewer.mediaControlPlayDefaultSkin'},
        volume: {skin: 'skins.viewer.mediaControlVolumeDefaultSkin'},
        fullscreen: {skin: 'skins.viewer.mediaControlFullscreenDefaultSkin'},
        progress: {skin: 'skins.viewer.mediaControlProgressDefaultSkin'},
        time: {skin: 'skins.viewer.mediaControlTimeDefaultSkin'}
    },
    'skins.viewer.mediaControlsLightSkin': {
        play: {skin: 'skins.viewer.mediaControlPlayDefaultSkin'},
        volume: {skin: 'skins.viewer.mediaControlVolumeDefaultSkin'},
        fullscreen: {skin: 'skins.viewer.mediaControlFullscreenDefaultSkin'},
        progress: {skin: 'skins.viewer.mediaControlProgressDefaultSkin'},
        time: {skin: 'skins.viewer.mediaControlTimeDefaultSkin'}
    },
    'skins.viewer.mediaControlsNoControlsSkin': {volume: {skin: 'skins.viewer.mediaControlVolumeDefaultSkin'}},
    'skins.viewer.mediaControlStoryboardMinimalSkin': {icon: {skin: 'skins.viewer.VectorImageSkin'}},
    'skins.viewer.mediaControlVolumeDefaultSkin': {icon: {skin: 'skins.viewer.VectorImageSkin'}},
    'skins.viewer.mediaOverlayControlsDefaultSkin': {indicator: {skin: 'skins.viewer.VectorImageSkin'}},
    'wysiwyg.viewer.skins.MessageViewSkin': {okButton: {skin: 'wysiwyg.viewer.skins.button.SiteButtonBlueSkin'}},
    'wysiwyg.viewer.skins.MobileMessageViewSkin': {okButton: {skin: 'wysiwyg.viewer.skins.button.SiteButtonBlueSkin'}},
    'wysiwyg.viewer.skins.quickActionBar.anchoredSkin': {
        'qab-left': 0,
        'qab-bottom': 0,
        'qab-width': 320,
        'max-actions': 5
    },
    'wysiwyg.viewer.skins.quickActionBar.floatingSkin': {
        'qab-left': 16,
        'qab-right': 16,
        'qab-bottom': 16,
        'has-alignment': true,
        'text-overflow': true,
        'max-actions': 5,
        isVertical: true
    },
    'wysiwyg.viewer.skins.quickActionBar.ovalSkin': {'qab-bottom': 12, 'max-actions': 4},
    'wysiwyg.viewer.skins.quickActionBar.rectSkin': {
        'qab-left': 12,
        'qab-bottom': 12,
        'qab-width': 296,
        'max-actions': 4
    },
    'skins.input.RadioGroupDefaultSkin': {
        radioButton: {skin: 'wysiwyg.common.components.radio.viewer.skins.RadioButtonDefaultSkin'}
    },
    'wysiwyg.common.components.rssbutton.viewer.skins.RSSButtonSkin': {image: {skin: 'skins.core.ImageNewSkin'}},
    'wysiwyg.common.components.singleaudioplayer.viewer.skins.EPlayerFramedPlay': {
        barSpaceLeft: 46,
        barSpaceRight: 60,
        mobileHeight: 82,
        desktopHeight: 68
    },
    'wysiwyg.common.components.singleaudioplayer.viewer.skins.EPlayerLargePlay': {
        barSpaceLeft: 0,
        barSpaceRight: 0,
        mobileHeight: 75,
        desktopHeight: 75
    },
    'wysiwyg.common.components.singleaudioplayer.viewer.skins.EPlayerRoundPlay': {
        barSpaceLeft: 91,
        barSpaceRight: 53,
        mobileHeight: 65,
        desktopHeight: 52
    },
    'wysiwyg.common.components.singleaudioplayer.viewer.skins.SingleAudioPlayerSkin': {
        barSpaceLeft: 29,
        barSpaceRight: 130,
        mobileHeight: 76,
        desktopHeight: 60
    },
    'skins.viewer.displayers.BlogSlideshowDisplayer': {image: {skin: 'skins.core.ImageSkin'}},
    'skins.viewer.displayers.SlideCleanAndSimple2Displayer': {image: {skin: 'skins.core.ImageSkin'}},
    'skins.viewer.displayers.SlideCleanAndSimpleDisplayer': {image: {skin: 'skins.core.ImageSkin'}},
    'skins.viewer.gallery.BlogSlideShow': {
        imageItem: {skin: 'skins.viewer.displayers.BlogSlideshowDisplayer', styleGroup: 'inherit'}
    },
    'skins.viewer.gallery.SlideShowCleanAndSimple': {
        imageItem: {skin: 'skins.viewer.displayers.SlideCleanAndSimpleDisplayer', styleGroup: 'inherit'}
    },
    'skins.viewer.gallery.SlideShowCleanAndSimple2': {
        imageItem: {skin: 'skins.viewer.displayers.SlideCleanAndSimple2Displayer', styleGroup: 'inherit'}
    },
    'skins.viewer.gallery.SlideShowIron': {
        imageItem: {skin: 'skins.viewer.displayers.SlideIronDisplayer', styleGroup: 'inherit'}
    },
    'skins.viewer.gallery.SlideShowScotchTape': {
        imageItem: {skin: 'skins.viewer.displayers.SlideScotchTapeDisplayer', styleGroup: 'inherit'}
    },
    'wysiwyg.viewer.skins.displayers.FrameDisplayer': {heightDiff: 60, image: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.displayers.RoundFrameDisplayer': {heightDiff: 60, image: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.displayers.SlideDisplayer': {image: {skin: 'skins.core.ImageSkin'}},
    'wysiwyg.viewer.skins.gallery.FrameShowGallery': {
        imageItem: {skin: 'wysiwyg.viewer.skins.displayers.FrameDisplayer'}
    },
    'wysiwyg.viewer.skins.gallery.RibbonsSlideShow': {
        widthDiff: 20,
        imageItem: {skin: 'wysiwyg.viewer.skins.displayers.FrameDisplayer'}
    },
    'wysiwyg.viewer.skins.gallery.RoundSlideShowGallery': {
        widthDiff: 20,
        heightDiff: 20,
        imageItem: {skin: 'wysiwyg.viewer.skins.displayers.RoundFrameDisplayer'}
    },
    'wysiwyg.viewer.skins.gallery.SlideShowGalleryLiftedShadowSkin': {
        imageItem: {
            skin: 'wysiwyg.viewer.skins.displayers.SlideShowDisplayerLiftedShadowSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.viewer.skins.gallery.SlideShowGallerySimple': {
        heightDiff: 0,
        imageItem: {skin: 'wysiwyg.viewer.skins.displayers.SlideDisplayer', styleGroup: 'displayer'}
    },
    'wysiwyg.viewer.skins.gallery.SlideShowGallerySloopy': {
        imageItem: {
            skin: 'wysiwyg.viewer.skins.displayers.SlideShowSloopyDisplayer',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.viewer.skins.gallery.SlideShowPolaroid': {
        imageItem: {
            skin: 'wysiwyg.viewer.skins.displayers.SlideShowPolaroidDisplayer',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.viewer.skins.gallery.SlideShowTextBottom': {
        imageItem: {
            skin: 'wysiwyg.viewer.skins.displayers.SlideShowTextBottomDisplayer',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.viewer.skins.gallery.SlideShowTextFloating': {
        imageItem: {
            skin: 'wysiwyg.viewer.skins.displayers.SlideTextFloatingDisplayer',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.viewer.skins.gallery.SlideShowTextOverlay': {
        imageItem: {
            skin: 'wysiwyg.viewer.skins.displayers.SlideTextOverlayDisplayer',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.viewer.skins.gallery.SlideShowTextRight': {
        imageItem: {
            skin: 'wysiwyg.viewer.skins.displayers.SlideTextRightDisplayer',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.common.components.boxSlideShow.viewer.skins.longArrowsLargeSelectedCircleSkin': {
        slide: {
            skin: 'wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin',
            styleGroup: 'inherit'
        },
        arrowWidthToHeightRatio: 0.3,
        arrowWidthToSizeRatio: 0.7,
        selectedButtonSizeRatio: 1.66
    },
    'wysiwyg.common.components.boxSlideShow.viewer.skins.squareButtonsSkin': {
        slide: {
            skin: 'wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin',
            styleGroup: 'inherit'
        },
        arrowWidthToHeightRatio: 0.5,
        arrowWidthToSizeRatio: 1,
        selectedButtonSizeRatio: 1
    },
    'wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin': {
        slide: {
            skin: 'wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin',
            styleGroup: 'inherit'
        },
        arrowWidthToHeightRatio: 1,
        arrowWidthToSizeRatio: 1,
        selectedButtonSizeRatio: 1.66
    },
    'wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsSkin': {
        slide: {
            skin: 'wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin',
            styleGroup: 'inherit'
        },
        arrowWidthToHeightRatio: 1,
        arrowWidthToSizeRatio: 1,
        selectedButtonSizeRatio: 1
    },
    'wysiwyg.common.components.statebox.viewer.skins.StateBoxSkin': {
        slide: {
            skin: 'wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.common.components.statebox.viewer.skins.StateStripSkin': {
        slide: {
            skin: 'wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin',
            styleGroup: 'inherit'
        }
    },
    'wysiwyg.common.components.stripSlideShow.viewer.skins.longArrowsLargeSelectedCircleSkin': {
        arrowWidthToHeightRatio: 0.3,
        arrowWidthToSizeRatio: 0.7,
        selectedButtonSizeRatio: 1.66
    },
    'wysiwyg.common.components.stripSlideShow.viewer.skins.squareButtonsSkin': {
        arrowWidthToHeightRatio: 0.5,
        arrowWidthToSizeRatio: 1,
        selectedButtonSizeRatio: 1
    },
    'wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin': {
        arrowWidthToHeightRatio: 1,
        arrowWidthToSizeRatio: 1,
        selectedButtonSizeRatio: 1.66
    },
    'wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsSkin': {
        arrowWidthToHeightRatio: 1,
        arrowWidthToSizeRatio: 1,
        selectedButtonSizeRatio: 1
    },
    'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutEnvelope': {
        hidePlaceholders: false,
        successMessageOutside: true
    },
    'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutFlat': {
        hidePlaceholders: false,
        successMessageOutside: true
    },
    'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutShiny': {
        hidePlaceholders: false,
        successMessageOutside: true
    },
    'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutFlat': {
        hidePlaceholders: false,
        successMessageOutside: true
    },
    'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutTransparentWithIcon': {
        hidePlaceholders: false,
        successMessageOutside: true
    },
    'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormPlaceholderSkin': {hidePlaceholders: false},
    'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormSkin': {hidePlaceholders: true},
    'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormSkinTopLabels': {hidePlaceholders: true},
    'wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin': {
        tinyMenuMemberSection: {skin: 'wysiwyg.viewer.skins.mobile.TinyMenuMemberSectionSkin'},
        tinyMenuLanguageSection: {skin: 'wysiwyg.viewer.skins.mobile.TinyMenuLanguageSectionSkin'}
    },
    'wysiwyg.viewer.skins.mobile.TinyMenuMemberSectionSkin': {
        button: {skin: 'wysiwyg.viewer.skins.LoginSocialButtonSkin'},
        avatar: {skin: 'wysiwyg.viewer.skins.IconSkin'}
    },
    'wysiwyg.viewer.skins.mobile.TinyMenuPullFromLeftSkin': {
        tinyMenuMemberSection: {skin: 'wysiwyg.viewer.skins.mobile.TinyMenuMemberSectionSkin'},
        tinyMenuLanguageSection: {skin: 'wysiwyg.viewer.skins.mobile.TinyMenuLanguageSectionSkin'}
    },
    'wysiwyg.viewer.skins.mobile.TinyMenuPullFromRightSkin': {
        tinyMenuMemberSection: {skin: 'wysiwyg.viewer.skins.mobile.TinyMenuMemberSectionSkin'},
        tinyMenuLanguageSection: {skin: 'wysiwyg.viewer.skins.mobile.TinyMenuLanguageSectionSkin'}
    },
    'wysiwyg.viewer.skins.mobile.TinyMenuPullFromTopSkin': {
        tinyMenuMemberSection: {skin: 'wysiwyg.viewer.skins.mobile.TinyMenuMemberSectionSkin'},
        tinyMenuLanguageSection: {skin: 'wysiwyg.viewer.skins.mobile.TinyMenuLanguageSectionSkin'}
    },
    'wysiwyg.viewer.skins.mobile.TinyMenuSkin': {
        tinyMenuMemberSection: {skin: 'wysiwyg.viewer.skins.mobile.TinyMenuMemberSectionSkin'},
        tinyMenuLanguageSection: {skin: 'wysiwyg.viewer.skins.mobile.TinyMenuLanguageSectionSkin'}
    },
    'wysiwyg.viewer.skins.TPASectionSkin': {
        preloaderOverlay: {skin: 'wysiwyg.viewer.skins.TPAPreloaderSkin', styleGroup: 'preloaderStyleGroup'},
        unavailableMessageOverlay: {
            skin: 'wysiwyg.viewer.skins.TPAUnavailableMessageOverlaySkin',
            styleGroup: 'tpaUnavailableMessageOverlaySkinStyleGroup'
        }
    },
    'wysiwyg.viewer.skins.TPAWidgetNativeSkin': {
        TPAWidgetNativeDeadcomp: {skin: 'wysiwyg.viewer.skins.TPAWidgetNativeDeadcompSkin'}
    },
    'wysiwyg.viewer.skins.TPAWidgetSkin': {
        preloaderOverlay: {skin: 'wysiwyg.viewer.skins.TPAPreloaderSkin', styleGroup: 'preloaderStyleGroup'},
        unavailableMessageOverlay: {
            skin: 'wysiwyg.viewer.skins.TPAUnavailableMessageOverlaySkin',
            styleGroup: 'tpaUnavailableMessageOverlaySkinStyleGroup'
        }
    },
    'wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuLinkedNoTextSkin': {
        hoverElementClass: 'symbol',
        hasConnectingLines: true
    },
    'wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuSymbolSkin': {
        hoverElementClass: 'symbol'
    },
    'wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuSymbolWithHiddenTextSkin': {
        hoverElementClass: 'symbol',
        disableClickOnHiddenElement: true
    },
    'wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuSymbolWithTextSkin': {
        hoverElementClass: 'link'
    },
    'wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuTextSkin': {
        hoverElementClass: 'link'
    },
    'wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuArrowSkin': {
        menuContainer: {skin: 'ComboBoxInputSkin', dontMergeParams: true}
    },
    'wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuRibbonsSkin': {
        menuContainer: {skin: 'ComboBoxInputSkin', dontMergeParams: true}
    },
    'wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSeparatedButtonFixedWidthSkin': {
        menuContainer: {
            skin: 'ComboBoxInputSkin',
            dontMergeParams: true
        },
        separatorNotIncludedInLineHeight: true,
        borderNotIncludedInLineHeight: true
    },
    'wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSeparatedButtonSkin': {
        menuContainer: {
            skin: 'ComboBoxInputSkin',
            dontMergeParams: true
        },
        separatorNotIncludedInLineHeight: true,
        borderNotIncludedInLineHeight: true
    },
    'wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSolidColorSkin': {
        menuContainer: {skin: 'ComboBoxInputSkin', dontMergeParams: true},
        separatorNotIncludedInLineHeight: true
    },
    'wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuTextSkin': {
        menuContainer: {skin: 'ComboBoxInputSkin', dontMergeParams: true}
    },
    'wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuTextWithSeparatorsSkin': {
        menuContainer: {
            skin: 'ComboBoxInputSkin',
            dontMergeParams: true
        }
    },
    'wysiwyg.skins.AppPartZoomSkin': {
        appPart: {skin: 'wysiwyg.viewer.skins.AppPartSkin'},
        mediaZoom: {skin: 'wysiwyg.viewer.skins.AppPartMediaZoomSkin'}
    },
    'wysiwyg.skins.MobileAppPartZoomSkin': {
        appPart: {skin: 'wysiwyg.viewer.skins.AppPartSkin'},
        mediaZoom: {skin: 'wysiwyg.viewer.skins.AppPartMobileMediaZoomSkin'}
    },
    'ecommerce.skins.mcom.MobileSelectableSliderGalleryDefaultSkin': {
        imageItem: {
            skin: 'wysiwyg.viewer.skins.galleryselectableslider.SelectableSliderDisplayerDefaultSkin',
            styleGroup: 'inherit'
        },
        itemContainerTotalOffset: 70
    },
    'wixapps.integration.skins.ecommerce.options.ColorOptionSkin': {
        tooltip: {skin: 'wixapps.integration.skins.ecommerce.options.InfoTipSkin'}
    },
    'wixapps.integration.skins.ecommerce.options.TextOptionSkin': {
        tooltip: {skin: 'wixapps.integration.skins.ecommerce.options.InfoTipSkin'}
    },
    'wixapps.integration.skins.inputs.SubmitInputMessageAboveButtonSkin': {
        button: {skin: 'wysiwyg.editor.skins.buttons.ButtonSmallerSkin'}
    },
    'wysiwyg.viewer.skins.paginatedgrid.wixapps.PaginatedGridSimple': {heightDiff: 40},
    'wysiwyg.viewer.skins.paginatedgrid.wixapps.TemplateChooserGallerySkin': {
        graphicCounter: {skin: 'wysiwyg.viewer.skins.paginatedgrid.counter.CounterGraphicSkin'}
    }
}
