import type {PS} from '@wix/document-services-types'
import {siteConstants} from '@wix/santa-core-utils'
import _ from 'lodash'

export default {
    get(ps: PS): boolean {
        const masterPageDataPointer = ps.pointers.data.getDataItemFromMaster(siteConstants.MASTER_PAGE_ID)
        const dataItem = ps.dal.get(masterPageDataPointer)
        const reorderFlag = _.get(dataItem, ['autoDomReorder'])

        return _.isUndefined(reorderFlag) ? true : reorderFlag
    },
    set(ps: PS, enabled: boolean): void {
        if (!_.isBoolean(enabled)) {
            throw new Error('autoDomReorder.set accepts only boolean value')
        }

        const masterPageDataPointer = ps.pointers.data.getDataItemFromMaster(siteConstants.MASTER_PAGE_ID)
        const dataItem = ps.dal.get(masterPageDataPointer) || {}

        ps.dal.set(masterPageDataPointer, _.assign(dataItem, {autoDomReorder: enabled}))
    }
}
