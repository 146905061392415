import type {SnapshotDal} from '@wix/document-manager-core'
import {timeout} from '@wix/document-manager-utils'
import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import editorServerFacade from '../editorServerFacade/editorServerFacade'
import {contextAdapter} from '../utils/contextAdapter'

const GET_BRANCHES_REQUEST_TIMEOUT = 1500

interface Args {
    ps?: PS
    snapshotDal?: SnapshotDal
}

const getBranchesList = async ({ps = null, snapshotDal = null}: Args = {}): Promise<any> => {
    contextAdapter.utils.fedopsLogger.interactionStarted('LIST_BRANCHES_REQUEST')

    try {
        let branchesList
        if (ps) {
            branchesList = await editorServerFacade.sendWithPsAsync(
                ps,
                editorServerFacade.ENDPOINTS.LIST_BRANCHES,
                null
            )
        } else if (snapshotDal) {
            branchesList = await editorServerFacade.sendWithSnapshotDalAsync(
                snapshotDal,
                editorServerFacade.ENDPOINTS.LIST_BRANCHES,
                null
            )
        }
        return branchesList
    } finally {
        contextAdapter.utils.fedopsLogger.interactionEnded('LIST_BRANCHES_REQUEST', {})
    }
}

const getBranchId = ({ps, snapshotDal}: {ps?: PS; snapshotDal?: SnapshotDal}): string | null => {
    try {
        if (ps) {
            return ps.extensionAPI.siteAPI.getBranchId()
        } else if (snapshotDal) {
            return snapshotDal.getValue({type: 'documentServicesModel', id: 'branchId'})
        }
    } catch (e) {
        return null
    }
}

const hasBranches = async (args: Args = {}) => {
    const branchesList = await getBranchesList(args)
    return branchesList.branches.length > 1
}

const doesHaveBranches = async ({ps = null, snapshotDal = null}: Args = {}): Promise<boolean> => {
    const branchId = getBranchId({ps, snapshotDal})

    if (_.isNil(branchId)) {
        return false
    }

    try {
        return await timeout(hasBranches({ps, snapshotDal}), GET_BRANCHES_REQUEST_TIMEOUT)
    } catch (e) {
        return true
    }
}

export {doesHaveBranches}
