import type {Experiment, ServiceTopology} from '@wix/document-services-types'
import {
    isExperimentOpen,
    mergeInto,
    node,
    PkgObj,
    scriptLocation,
    unpkgOrNode,
    unpkgOrNodeObj
} from './rjs-config-utils'
import {joinURL} from './joinURL'

const defaults = {
    '@wix/santa-core-utils': '1.307.0',
    '@wix/santa-skin-utils': '1.1.0',
    'image-client-api': '1.40.0',
    imageClientLib: '1.40.0',
    zepto: '1.2.0',
    xss: '0.2.12',
    'react-dom-factories': '1.0.2',
    lodash: '4.7.0',
    'pm-rpc': '1.0.7',
    hammerjs: '2.0.8',
    // jsVendor: {
    mobx: '3.3.3',
    'mobx-react': '4.2.2',
    tweenmax: '1.19.0',
    '@wix/fedops-logger': '3.0.5'
}

const wixUiSantaStaticOverrideExp = 'allowWixUiSantaStaticOverride'

export interface RjsPkg {
    location?: string
}

export interface RjsConfig {
    baseUrl?: string
    paths?: Record<string, undefined | string | PkgObj>
    shim?: Record<string, any>
    map?: Record<string, any>
    packages?: RjsPkg[]
    config?: Record<string, any>
    waitSeconds?: number
    deps?: string[]
    bundles?: Record<string, any>
    callback?(): void
}

export function getRjsConfig(
    versions: Record<string, string>,
    serviceTopology: ServiceTopology,
    local: boolean,
    experiment: Experiment
): RjsConfig {
    const platformSdkRoot = scriptLocation(serviceTopology, 'js-platform-editor-sdk')
    const platformAppConfigurationUrl = scriptLocation(
        serviceTopology,
        'js-platform-apps-configuration',
        'https://static.parastorage.com/services/js-platform-apps-configuration/1.7.0'
    )
    const promoteAnalyticsAdapterUrl = scriptLocation(serviceTopology, 'promote-analytics-adapter')
    const santaSiteAuthUrl = scriptLocation(serviceTopology, 'santa-site-auth-module')

    mergeInto(versions, defaults)

    const _unpkg = unpkgOrNode.bind(null, versions, local)
    const _unpkgObj = unpkgOrNodeObj.bind(null, versions, local)

    const promoteAnalyticsAdapterPath = local
        ? node('@wix/promote-analytics-adapter', 'dist/statics/analytics-event-adapter.bundle.min')
        : joinURL(promoteAnalyticsAdapterUrl, 'analytics-event-adapter.bundle.min')
    const santaSiteAuthPath = local
        ? node('@wix/santa-site-auth-module', 'dist/statics/santa-site-auth-module.bundle.min')
        : joinURL(santaSiteAuthUrl, 'santa-site-auth-module.bundle.min')

    const paths: RjsConfig['paths'] = {
        // -------------- RUNTIME --------------------------------------------------------------
        wixImmutable: _unpkg('@wix/santa-core-utils', 'dist/wixImmutable'),
        lodash: _unpkg('lodash', 'lodash.min'),

        vendor: _unpkgObj('@wix/santa-bundle', 'dist/bundle.min', 'dist/bundle'),

        react: _unpkgObj('react', 'umd/react.production.min', 'umd/react.development'),
        reactDOM: _unpkgObj('react-dom', 'umd/react-dom.production.min', 'umd/react-dom.development'),
        reactDOMServer: _unpkgObj(
            'react-dom',
            'umd/react-dom-server.browser.production.min',
            'umd/react-dom-server.browser.development'
        ),
        reactTestUtils: _unpkg('react-dom', 'umd/react-dom-test-utils.development'),
        'prop-types': _unpkgObj('prop-types', 'prop-types.min', 'prop-types'),
        'create-react-class': _unpkgObj('create-react-class', 'create-react-class.min', 'create-react-class'),
        'react-dom-factories': _unpkg('react-dom-factories', 'index'),
        mobx: _unpkgObj('mobx', 'lib/mobx.umd.min', 'lib/mobx.umd'),
        'mobx-react': _unpkgObj('mobx-react', 'index.min', 'index'),

        zepto: _unpkgObj('zepto', 'dist/zepto.min', 'dist/zepto'),
        immutable: _unpkgObj('immutable', 'dist/immutable.min', 'dist/immutable'),
        TweenMax: _unpkgObj('gsap', 'src/minified/TweenMax.min', 'src/uncompressed/TweenMax'), //bundle
        TweenLite: _unpkgObj('gsap', 'src/minified/TweenMax.min', 'src/uncompressed/TweenMax'),
        TimelineMax: _unpkgObj('gsap', 'src/minified/TweenMax.min', 'src/uncompressed/TweenMax'),
        ScrollToPlugin: _unpkgObj(
            'gsap',
            'src/minified/plugins/ScrollToPlugin.min',
            'src/uncompressed/plugins/ScrollToPlugin'
        ), //bundle
        Draggable: _unpkgObj('gsap', 'src/minified/utils/Draggable.min', 'src/uncompressed/utils/Draggable'),
        DrawSVGPlugin: _unpkgObj(
            '@wix/santa-external-modules',
            'tweenmax-plugins/1.19.0/DrawSVGPlugin.min',
            'tweenmax-plugins/1.19.0/DrawSVGPlugin'
        ),
        MorphSVGPlugin: _unpkgObj(
            '@wix/santa-external-modules',
            'tweenmax-plugins/1.19.0/MorphSVGPlugin.min',
            'tweenmax-plugins/1.19.0/MorphSVGPlugin'
        ),
        //gsap3 - upgrade GSAP - temporary path for the transition phase
        gsap3: _unpkgObj(
            '@wix/santa-external-modules',
            'tweenmax-plugins/3.1.1-transition-phase/gsap.min',
            'tweenmax-plugins/3.1.1-transition-phase/gsap'
        ),
        ScrollToPlugin3: _unpkgObj(
            '@wix/santa-external-modules',
            'tweenmax-plugins/3.1.1-transition-phase/ScrollToPlugin.min',
            'tweenmax-plugins/3.1.1-transition-phase/ScrollToPlugin'
        ),

        color: _unpkg('@wix/santa-external-modules', 'color-convert/0.2.0/color.min'),
        speakingurl: _unpkg('@wix/santa-external-modules', 'speakingurl/7.0.0/speakingurl.min'),
        'date-fns': _unpkgObj('@wix/santa-external-modules', 'date-fns/1.3.0/date_fns.min', 'date-fns/1.3.0/date_fns'),
        xss: _unpkg('xss', 'dist/xss.min'), //shim
        immutableDiff: _unpkg('@wix/santa-external-modules', 'immutableDiff/immutablejsdiff.min'),
        pmrpc: _unpkg('pm-rpc', 'build/pm-rpc.min'),
        'ag-grid': _unpkgObj('ag-grid', 'dist/ag-grid.min', 'dist/ag-grid'),

        SoundManager: _unpkg('soundmanager2', 'script/soundmanager2-nodebug-jsmin'),
        hammer: _unpkg('hammerjs', 'hammer.min'),
        mousetrap: _unpkg('mousetrap', 'mousetrap.min'),
        swfobject: _unpkg('@wix/santa-external-modules', 'swfobject/2.3.20130521/swfobject.min'),
        ajv: _unpkgObj('ajv', 'dist/ajv.min', 'dist/ajv.bundle'),
        'fedops-logger': _unpkg('@wix/fedops-logger', 'dist/statics/fedops-logger-module.bundle.min'),
        'fast-json-stable-stringify': _unpkg('@wix/santa-external-modules', 'dist/fast-json-stable-stringify'),

        // ------------- internal -------------------------------------------------------------------
        platformEvents: _unpkgObj('@wix/platform-editor-sdk', 'lib/platformEvents.min', 'lib/platformEvents'),
        'document-services': _unpkgObj(
            '@wix/document-services',
            'dist/document-services.min',
            'dist/document-services'
        ),
        documentServices: _unpkg('@wix/document-services-implementation', 'dist/document-services-implementation'),
        'document-services-implementation': _unpkgObj(
            '@wix/document-services-implementation',
            'dist/document-services-implementation.min',
            'dist/document-services-implementation'
        ),
        mobileCore: _unpkgObj('@wix/santa-mobile-core', 'dist/mobileCore.min', 'dist/mobileCore'),
        platformUtils: _unpkg('@wix/santa-platform-utils', 'dist/platformUtils-bundle'),
        'santa-shared-schemas': _unpkg('@wix/santa-shared-schemas', 'dist/santa-shared-schemas'),
        'santa-components': _unpkgObj(
            '@wix/santa-components',
            'dist/santa-components.prod',
            'dist/santa-components.devel'
        ),
        'santa-components-layout': _unpkgObj(
            '@wix/santa-components',
            'dist/santa-components-layout.prod',
            'dist/santa-components-layout.devel'
        ),
        'santa-components/popover': _unpkgObj(
            '@wix/santa-components',
            'dist/santa-component-popover.prod',
            'dist/santa-component-popover.devel'
        ),
        'santa-renderer': _unpkg('santa-renderer', 'dist/santa-renderer'),
        mobileLayoutUtils: _unpkgObj('@wix/santa-mobile-core', 'dist/mobileLayoutUtils.min', 'dist/mobileLayoutUtils'),
        skinUtils: _unpkg('@wix/santa-skin-utils', 'dist/skin-utils'),
        'mesh-migrator': _unpkg('@wix/mesh-migrator', 'dist/mesh-migrator'),
        'santa-core-utils': _unpkg('@wix/santa-core-utils', 'dist/coreUtils'),
        warmupUtilsLib: _unpkg('@wix/santa-core-utils', 'dist/warmupUtils'),
        tweenEngine: _unpkg('@wix/santa-core-utils', 'dist/tweenEngine'),
        'image-client-api': _unpkg('image-client-api', 'dist/imageClientApi'),
        imageClientSDK: _unpkg('image-client-api', 'dist/imageClientSDK'),
        'santa-site-auth-module': santaSiteAuthPath,
        wixDomSanitizer: _unpkg('@wix/wix-dom-sanitizer', 'dist/wix-dom-sanitizer'),
        'santa-data-fixer': _unpkgObj('@wix/santa-data-fixer', 'dist/santa-data-fixer.min', 'dist/santa-data-fixer'),
        'santa-animations': _unpkg('@wix/santa-animations', 'dist/santa-animations'),
        'media-resize-map': _unpkg('@wix/santa-animations', 'dist/mediaResizeMap'),
        'host-platform-api': _unpkg('@wix/santa-host-platform-services', 'dist/host-platform-api'),
        'host-worker-init': _unpkg('@wix/santa-host-platform-services', 'dist/host-worker-init'),
        wixFullstoryLoader: _unpkg('@wix/wix-fullstory-loader', 'dist/statics/app.bundle'),
        'data-capsule': _unpkg('data-capsule', 'dist/statics/frame-listener.bundle.min'),
        coreMultilingual: _unpkg('@wix/santa-multilingual', 'dist/languages'),
        'hls-light': _unpkg('hls.js', 'dist/hls.light.min'), //lazy
        hls: _unpkg('hls.js', 'dist/hls.min'), //lazy
        'promote-analytics-adapter': promoteAnalyticsAdapterPath,
        'js-platform-apps-configuration-editor': joinURL(platformAppConfigurationUrl, 'platform-apps-editor.min'),
        wixUiRichTextArea: _unpkg('@wix/wix-ui-santa', 'dist/statics/RichTextArea.bundle.min'),
        wixUiRichTextBox: _unpkg('@wix/wix-ui-santa', 'dist/statics/RichTextBox.bundle.min'),
        wixUiToggleSwitch: _unpkg('@wix/wix-ui-santa', 'dist/statics/ToggleSwitch.bundle.min'),
        wixUiTimePicker: _unpkg('@wix/wix-ui-santa', 'dist/statics/TimePicker.bundle.min'),
        wixUiVideoPlayer: _unpkg('@wix/wix-ui-santa', 'dist/statics/VideoPlayer.bundle.min'),
        wixUiPagination: _unpkg('@wix/wix-ui-santa', 'dist/statics/Pagination.bundle.min'),
        'wix-json-schema-utils': _unpkg('@wix/wix-json-schema-utils', 'dist/wix-json-schema-utils'),
        wixUiRating: _unpkg('@wix/wix-ui-santa', 'dist/statics/Rating.bundle.min'),
        wixUiSlider: _unpkg('@wix/wix-ui-santa', 'dist/statics/Slider.bundle.min'),
        wixUiTags: _unpkg('@wix/wix-ui-santa', 'dist/statics/Tags.bundle.min'),
        wixUiCompsToPackages: _unpkg('@wix/wix-ui-santa', 'dist/statics/compsToPackages.bundle.min'),
        wixUiSsrViewerCompsService: node('@wix/wix-ui-santa', 'dist/src/components-service/viewer-ssr'),
        'wix-ui-santa': isExperimentOpen(experiment, wixUiSantaStaticOverrideExp)
            ? scriptLocation(serviceTopology, 'wix-ui-santa')
            : _unpkg('@wix/wix-ui-santa', 'dist/statics'),
        'stylable-panel': _unpkg('@wixc3/stylable-panel', 'dist/stylable-panel'),
        'stylable-panel-drivers': _unpkg('@wixc3/stylable-panel-drivers', 'dist/stylable-panel-drivers'),
        'stylable-santa-flatten': _unpkg('@wix/stylable-santa-flatten', 'dist/main'),
        'wix-base-ui': _unpkg('@wix/wix-base-ui', 'dist/base-ui'),
        'io-client': _unpkg('socket.io-client', 'dist/socket.io'),
        editorSkinsData: 'dist/editorSkinsData',
        '@wix/wix-code-backend-testkit': _unpkg('@wix/wix-code-backend-testkit', 'dist/bundle'),

        // ------------- TESTING -------------------------------------------------------------------
        fake: 'js/plugins/fake/src/main/fake',
        definition: 'js/plugins/definition/src/main/definition',
        ReactProxy: _unpkg('@wix/santa-external-modules', 'react-proxy/ReactProxy'),
        Squire: _unpkg('@wix/santa-external-modules', 'squire/Squire'),
        jasmine: _unpkg('@wix/santa-external-modules', 'jasmine/2.1.3/jasmine'),
        'jasmine-html': _unpkg('@wix/santa-external-modules', 'jasmine/2.1.3/jasmine-html'),
        'jasmine-boot': _unpkg('@wix/santa-external-modules', 'jasmine/2.1.3/jasmine-boot'),
        // ------------- DEVELOPMENT ---------------------------------------------------------------
        io: 'https://cdnjs.cloudflare.com/ajax/libs/socket.io/1.4.0/socket.io.min',
        patcher: node('@wix/santa-utils', 'common/hot/patcher'),
        hot: node('@wix/santa-utils', 'common/hot/listener')
    }

    if (platformSdkRoot) {
        paths.platformAPI = {
            min: joinURL(platformSdkRoot, 'lib', 'platform-api.min'),
            source: joinURL(platformSdkRoot, 'lib', 'platform-api')
        } as PkgObj
        // @ts-ignore
        paths.platformEvents = {
            min: joinURL(platformSdkRoot, 'lib', 'platformEvents.min'),
            source: joinURL(platformSdkRoot, 'lib', 'platformEvents')
        } as PkgObj
    }

    const wixCodePlatformBaseUrl = scriptLocation(serviceTopology, 'wix-code-platform')
    if (wixCodePlatformBaseUrl) {
        const getPathToWixCodePlatformFile = (filename: string) =>
            local ? node('@wix/wix-code-platform', `dist/${filename}`) : joinURL(wixCodePlatformBaseUrl, filename)
        paths['elementory-browser-support'] = getPathToWixCodePlatformFile('elementory-browser-support.min')
        paths['wix-data-schemas-creator'] = {
            min: getPathToWixCodePlatformFile('wix-data-schemas-creator.min'),
            source: getPathToWixCodePlatformFile('wix-data-schemas-creator')
        } as PkgObj
    }

    return {
        //By default load any module IDs from js/lib
        baseUrl: '/',
        //except, if the module ID starts with "app",
        //load it from the js/app directory. paths
        //config is relative to the baseUrl, and
        //never includes a ".js" extension since
        //the paths config could be for a directory.
        paths,
        bundles: {
            vendor: ['prop-types', 'create-react-class', 'mobx']
        },
        map: {
            '*': {
                imageClientLib: 'image-client-api',
                'react-dom': 'reactDOM',
                coreUtilsLib: 'santa-core-utils'
            }
        },
        shim: {
            color: {exports: 'Color'},
            'jasmine-html': {
                deps: ['jasmine']
            },
            'jasmine-boot': {
                deps: ['jasmine', 'jasmine-html']
            },
            SoundManager: {exports: 'soundManager'},
            ReactProxy: {
                deps: ['react'],
                exports: 'ReactProxy'
            },
            xss: {exports: 'filterXSS'}
        },
        waitSeconds: 0
    }
}
