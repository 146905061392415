import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import dsUtils from '../utils/utils'
import hooks from '../hooks/hooks'
import constants from '../constants/constants'
import persistentItemsValidations from './persistentItemsValidations'
import {ReportableError} from '@wix/document-manager-utils'

const {DATA_TYPES, COMP_DATA_QUERY_KEYS_WITH_STYLE} = constants
const {isItemPersistent} = persistentItemsValidations

function getComponentDataItemId(ps: PS, componentPointer: Pointer, dataQueryPointer: Pointer) {
    const dal = ps.dal.isExist(dataQueryPointer) ? ps.dal : ps.dal.full
    const dataQuery = dal.get(dataQueryPointer)
    if (!dataQuery) {
        return null
    }
    return dsUtils.stripHashIfExists(dataQuery)
}

function getPageId(ps: PS, componentPointer: Pointer) {
    const pageOfComponent =
        ps.pointers.components.getPageOfComponent(componentPointer) ||
        ps.pointers.full.components.getPageOfComponent(componentPointer)
    return pageOfComponent.id
}

function removeItemRecursively(
    ps: PS,
    dataType: string,
    dataItemPointer: Pointer,
    pageId: string,
    forceRemoveRootItem: boolean
) {
    if (!ps.dal.isExist(dataItemPointer)) {
        return
    }

    const dataItem = ps.dal.get(dataItemPointer)

    if (!dataItem || isItemPersistent(ps, dataType, dataItemPointer.id)) {
        return
    }

    const isReferenced = ps.extensionAPI.relationships.isReferenced(dataItemPointer)

    if (
        isReferenced &&
        !forceRemoveRootItem
        // is not original item that we want to remove
    ) {
        ps.extensionAPI.logger.captureError(
            new ReportableError({
                errorType: 'attemptedRemoveOfReferencedDataError',
                message: `${JSON.stringify(dataItemPointer)} was removed though it has references to it`,
                extras: {
                    dataItemPointer
                }
            })
        )
        return
    }

    const pointersToProcess: Pointer[] = ps.extensionAPI.relationships.getOwnedReferredPointers(dataItemPointer)

    ps.dal.remove(dataItemPointer)

    _.forEach(pointersToProcess, pointer => {
        removeItemRecursively(ps, dataType, pointer, pageId, false)
    })
}

function removeItemRecursivelyByType(ps: PS, dataPointer: Pointer) {
    if (!ps.dal.isExist(dataPointer)) {
        return
    }
    const pageId = ps.pointers.data.getPageIdOfData(dataPointer)
    removeItemRecursively(ps, dataPointer.type, dataPointer, pageId, true)
}

const removeItems = (ps: PS, pointers: Pointer[]) =>
    pointers.forEach(pointer => removeItemRecursivelyByType(ps, pointer))

/**
 * delete recursively item by compPointer and item Type
 *
 * @param {ps} ps
 * @param {Pointer} componentPointer
 * @param {String} itemType
 */
function deleteItemByType(ps: PS, componentPointer: Pointer, itemType: string) {
    if (ps && componentPointer && itemType) {
        const propName = COMP_DATA_QUERY_KEYS_WITH_STYLE[itemType]
        const dataQueryPointer = ps.pointers.getInnerPointer(componentPointer, propName)
        const itemId = getComponentDataItemId(ps, componentPointer, dataQueryPointer)
        if (!itemId) {
            return null
        }
        const pageId = getPageId(ps, componentPointer)
        const itemPointer = ps.pointers.data.getItem(itemType, itemId, pageId)

        removeItemRecursivelyByType(ps, itemPointer)

        return itemId
    }
}

/**
 * delete item key from comp by compPointer and item Type
 *
 * @param {ps} ps
 * @param {Pointer} componentPointer
 * @param {String} itemType
 */
function deleteCompKeyByType(ps: PS, componentPointer: Pointer, itemType: string) {
    if (ps && componentPointer && itemType) {
        const propName = COMP_DATA_QUERY_KEYS_WITH_STYLE[itemType]
        const compDataQueryKeyPointer = ps.pointers.getInnerPointer(componentPointer, propName)
        if (ps.dal.full.isExist(compDataQueryKeyPointer)) {
            ps.dal.full.remove(compDataQueryKeyPointer)
        }
    }
}

function deleteCompKeyByTypeDesktopAndMobile(ps: PS, componentPointer: Pointer, itemType: string) {
    const desktopPointer = ps.pointers.full.components.getDesktopPointer(componentPointer)
    const mobilePointer = ps.pointers.full.components.getMobilePointer(componentPointer)
    deleteCompKeyByType(ps, desktopPointer, itemType)
    deleteCompKeyByType(ps, mobilePointer, itemType)
}

function deletePropertiesItem(ps: PS, componentPointer: Pointer) {
    removeComponentDataByType(ps, componentPointer, DATA_TYPES.prop)
}

function deleteTransformationsItem(ps: PS, componentPointer: Pointer) {
    removeComponentDataByType(ps, componentPointer, DATA_TYPES.transformations, true)
}

function deleteTransitionsItem(ps: PS, componentPointer: Pointer) {
    removeComponentDataByType(ps, componentPointer, DATA_TYPES.transitions, true)
}

function deleteReactionsItem(ps: PS, componentPointer: Pointer) {
    removeComponentDataByType(ps, componentPointer, DATA_TYPES.reactions, true)
}

function deleteSlotsItem(ps: PS, componentPointer: Pointer) {
    removeComponentDataByType(ps, componentPointer, DATA_TYPES.slots, true)
}

function deleteStatesItem(ps: PS, componentPointer: Pointer) {
    removeComponentDataByType(ps, componentPointer, DATA_TYPES.states, true)
}

function deleteTriggersItem(ps: PS, componentPointer: Pointer) {
    hooks.executeHook(hooks.HOOKS.TRIGGERS.REMOVE_BEFORE, '', [ps, componentPointer])
    removeComponentDataByType(ps, componentPointer, DATA_TYPES.triggers, true)
}

function removeAnchorItem(ps: PS, componentPointer: Pointer) {
    removeComponentDataByType(ps, componentPointer, DATA_TYPES.anchors, true)
}

function removeVariablesListItem(ps: PS, componentPointer: Pointer) {
    removeComponentDataByType(ps, componentPointer, DATA_TYPES.variables, true)
}

function removeFixerVersions(ps: PS, componentPointer: Pointer) {
    removeComponentDataByType(ps, componentPointer, DATA_TYPES.fixerVersions, true)
}

function removeEffectListItem(ps: PS, componentPointer: Pointer) {
    removeComponentDataByType(ps, componentPointer, DATA_TYPES.effects, true)
}

function deleteVariantsItems(ps: PS, componentPointer: Pointer) {
    if (ps && componentPointer) {
        // this removes all variants we have that are held directly by component, such as BreakpointsData and subsequent BreakpointsRange
        removeComponentDataByType(ps, componentPointer, DATA_TYPES.variants, true)

        //this removes all variants that are component-specific, but are not held directly by our component. For example, a hover state
        const pageId = getPageId(ps, componentPointer)
        const componentVariantsData = ps.pointers.data.getVariantDataItemsByComponentId(componentPointer.id)
        _.forEach(componentVariantsData, variantData => {
            const variantPointer = ps.pointers.data.getItem(DATA_TYPES.variants, variantData.id, pageId)

            const isReferenced = ps.extensionAPI.relationships.isReferenced(variantPointer)

            if (isReferenced) {
                ps.extensionAPI.logger.captureError(
                    new ReportableError({
                        errorType: 'attemptedRemoveOfReferencedVariantError',
                        message: `${JSON.stringify(variantPointer)} was removed though it has references to it`,
                        extras: {
                            dataItemPointer: variantPointer
                        }
                    })
                )
            }
            ps.dal.remove(variantPointer)
        })
    }
}

function deleteDesignItem(ps: PS, componentPointer: Pointer) {
    removeComponentDataByType(ps, componentPointer, DATA_TYPES.design)
}

function removeBehaviorsItem(ps: PS, componentPointer: Pointer) {
    const deletedItem = removeComponentDataByType(ps, componentPointer, DATA_TYPES.behaviors, true)
    if (!deletedItem) {
        return
    }

    const compType = dsUtils.getComponentType(ps, componentPointer)
    hooks.executeHook(hooks.HOOKS.BEHAVIORS.UPDATE_AFTER, compType, [ps])
}

function removeConnectionsItem(ps: PS, componentPointer: Pointer) {
    const deletedItem = deleteItemByType(ps, componentPointer, DATA_TYPES.connections)
    if (!deletedItem) {
        return
    }
    deleteCompKeyByTypeDesktopAndMobile(ps, componentPointer, DATA_TYPES.connections)
}

function deleteDataItem(ps: PS, componentPointer: Pointer) {
    removeComponentDataByType(ps, componentPointer, DATA_TYPES.data)
}

function deleteLayoutItem(ps: PS, componentPointer: Pointer) {
    removeComponentDataByType(ps, componentPointer, DATA_TYPES.layout)
}

function removePattern(ps: PS, compPointer: Pointer) {
    removeRepeaterPatternVariantsList(ps, compPointer)
    removeComponentDataByType(ps, compPointer, DATA_TYPES.patterns, true)
}

function removeRepeaterPatternVariantsList(ps: PS, compPointer: Pointer) {
    const queryItemPointer = ps.pointers.getInnerPointer(compPointer, 'variantsQuery')
    const patternsList = ps.dal.get(queryItemPointer)
    const {id: pageId} = ps.pointers.components.getPageOfComponent(compPointer)
    const repeaterPatternsList = ps.pointers.data.getVariantsDataItem(patternsList, pageId)
    removeItemRecursivelyByType(ps, repeaterPatternsList)
    ps.dal.full.remove(queryItemPointer)
}

function deleteFeatureItem(ps: PS, componentPointer: Pointer, featureNamespace: string) {
    removeComponentDataByType(ps, componentPointer, DATA_TYPES[featureNamespace])
}

function removeMobileHintsItem(ps: PS, componentPointer: Pointer, shouldRemoveQuery = false) {
    removeComponentDataByType(ps, componentPointer, DATA_TYPES.mobileHints, shouldRemoveQuery)
}

const removeComponentDataByType = (ps: PS, componentPointer: Pointer, itemType: string, shouldRemoveQuery = false) => {
    const deletedItem = deleteItemByType(ps, componentPointer, itemType)
    if (!deletedItem) {
        return
    }

    if (shouldRemoveQuery) {
        deleteCompKeyByTypeDesktopAndMobile(ps, componentPointer, itemType)
    }
    return deletedItem
}

export default {
    removeComponentDataByType,
    removeItemRecursivelyByType,
    removeItems,
    removeConnectionsItem,
    removeBehaviorsItem,
    removeMobileHintsItem,
    deleteDesignItem,
    deletePropertiesItem,
    deleteDataItem,
    deleteTransitionsItem,
    deleteTransformationsItem,
    deleteVariantsItems,
    deleteLayoutItem,
    deleteFeatureItem,
    deleteReactionsItem,
    deleteSlotsItem,
    deleteStatesItem,
    deleteTriggersItem,
    removeAnchorItem,
    removeVariablesListItem,
    removeFixerVersions,
    removeEffectListItem,
    removePattern,
    removeRepeaterPatternVariantsList
}
