import type {Extension, ExtensionAPI, CreateExtensionArgument, CoreLogger} from '@wix/document-manager-core'

export interface LoggerAPI extends ExtensionAPI {
    captureError: CoreLogger['captureError']
    interactionStarted: CoreLogger['interactionStarted']
    interactionEnded: CoreLogger['interactionEnded']
    breadcrumb: CoreLogger['breadcrumb']
}

export interface LoggerExtAPI extends ExtensionAPI {
    logger: LoggerAPI
}

const createExtension = ({logger}: CreateExtensionArgument): Extension => {
    const createExtensionAPI = () => ({
        logger: {
            captureError: logger.captureError,
            interactionStarted: logger.interactionStarted,
            interactionEnded: logger.interactionEnded,
            breadcrumb: logger.breadcrumb
        }
    })

    return {
        name: 'logger',
        createExtensionAPI
    }
}

export {createExtension}
