import type {PublicMethodUtils} from '@wix/document-services-types'
import {customCSS} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            customCSS: {
                refresh: publicMethodUtils.defineAction(customCSS.refresh)
            }
        }
    }
}
