import _ from 'lodash'
import type {DocumentManager} from '@wix/document-manager-core'
import type {PageMigrator} from '../dataMigrationRunner'
import type {MultilingualExtensionAPI, PageExtensionAPI} from '@wix/document-manager-extensions'

const UNTRANSLATABLE_FIELDS = ['managingAppDefId']

const migratePage = (documentManager: DocumentManager, pageId: string) => {
    if (!documentManager.experimentInstance.isOpen('dm_alignTranslatedPageDataWithMainLanguage')) return

    const {dal, pointers, extensionAPI} = documentManager as DocumentManager & {
        extensionAPI: PageExtensionAPI & MultilingualExtensionAPI
    }

    const allTranslationLanguages = extensionAPI.multilingualTranslations.getAllTranslationLanguages()

    if (_.isEmpty(allTranslationLanguages)) return

    const mainLanguagePageData = extensionAPI.page.data.get(pageId)

    const nonEmptyUntranslatableFields = UNTRANSLATABLE_FIELDS.filter(field => mainLanguagePageData[field])

    if (_.isEmpty(nonEmptyUntranslatableFields)) return

    allTranslationLanguages.forEach(languageCode => {
        const translatedPageDataPointer = pointers.multilingualTranslations.translationDataItem(
            pageId,
            languageCode,
            pageId
        )

        nonEmptyUntranslatableFields.forEach(field => {
            const fieldPointer = pointers.getInnerPointer(translatedPageDataPointer, field)
            dal.set(fieldPointer, mainLanguagePageData[field])
        })
    })
}

export const alignTranslatedPageDataWithMainLanguage: PageMigrator = {
    migratePage,
    name: 'alignTranslatedPageDataWithMainLanguage',
    version: 0,
    experimentalVersions: [{experiment: 'dm_alignTranslatedPageDataWithMainLanguage', version: 1}]
}
