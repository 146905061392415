import type {PS} from '@wix/document-services-types'
import constants from '../../constants/constants'

export default {
    mobileConversionConfig: {
        hideByDefault(ps: PS) {
            const masterPagePointer = ps.pointers.components.getMasterPage(constants.VIEW_MODES.MOBILE)
            const menuContainerPointer = ps.pointers.components.getComponent(
                constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER,
                masterPagePointer
            )
            const menuContainerIsExist = ps.dal.isExist(menuContainerPointer)
            return !menuContainerIsExist
        },
        fixedSize: {
            width: 40,
            height: 26
        }
    },
    // has to return `defaultMobileProperties` otherwise the component will not splitted
    defaultMobileProperties: (ps: PS, componentStructure, desktopProps) => desktopProps
}
