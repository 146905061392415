import _ from 'lodash'
import type {ExternalsHandler} from '../../constants'
import {connectionListHandler} from './connectionList'
import {defaultRefsHandler} from './default'

const specialExternalHandlers: Record<string, Record<string, Record<string, ExternalsHandler>>> = {
    connections: {
        ConnectionList: {
            'items.controllerId': connectionListHandler
        }
    }
}

export const getExternalsHandler = (namespace: string, itemType: string, path: readonly string[]): ExternalsHandler => {
    return _.get(specialExternalHandlers, [namespace, itemType, path.join('.')], defaultRefsHandler)
}
