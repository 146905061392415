import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import mobileMetaDataUtils from './mobileMetaDataUtils'
import quickActionBarItemsDataBuilder from './quickActionBarItemsDataBuilder'
import quickActionBarManager from './quickActionBarManager'
import quickActionsDefinitions from './quickActionsDefinitions'

function revert(ps: PS) {
    quickActionBarManager.removeQuickActionBar(ps)
}

function upgrade(ps: PS) {
    const siteMetaData = mobileMetaDataUtils.getSiteMetaData(ps)
    let quickActionBarItemsData: any = quickActionBarItemsDataBuilder.createFromSiteMetaData(siteMetaData)
    if (_.isEmpty(quickActionBarItemsData)) {
        quickActionBarItemsData = quickActionsDefinitions.defaultQuickActionItems
    }
    quickActionBarManager.addQuickActionBar(ps, quickActionsDefinitions.defaultSerializedMAB, quickActionBarItemsData)
}

function getVersion(ps: PS) {
    return quickActionBarManager.hasQuickActionBar(ps) ? 2 : 1
}

export default {
    revert,
    upgrade,
    getVersion
}
