import {pointerUtils} from '@wix/document-manager-core'
const pointerType = 'runtime'

const {getPointer} = pointerUtils

const createPointersMethods = () => {
    const getWantedNavInfo = () => getPointer('wantedNavInfo', pointerType)
    const getWantedIsMobileView = () => getPointer('wantedIsMobileView', pointerType)

    return {
        runtime: {
            getWantedNavInfo,
            getWantedIsMobileView
        }
    }
}

export {createPointersMethods}
