'use strict'

const _ = require('lodash')
const constants = require('../helpers/constants')
const coreUtils = require('@wix/santa-core-utils')
const {stripHashIfExists} = require('../helpers/dataUtils')
const {COMP_DATA_QUERY_KEYS_WITH_STYLE} = require('@wix/document-services-json-schemas/dist/constants.json')

const createPageManager = (pageJson, uniqueIdGenerator) => {
    const {data, structure} = pageJson

    const generateId = prefix => uniqueIdGenerator.getUniqueId(prefix, '-')

    const addDataItem = (namespace, type, value) => {
        const id = generateId(type)
        data[namespace][id] = {
            ...value,
            id
        }
        return id
    }

    const getDataItem = (namespace, id) => {
        return data[namespace][id]
    }

    const updateDataItem = (id, namespace, value) => {
        _.assign(data[namespace][id], value)
    }

    const addVariantRelation = (namespace, type, variantsArray, scopedDataId, from) => {
        const id = generateId(type)
        data[namespace][id] = {
            id,
            from: `#${from}`,
            type: constants.VARIANT_RELATION_TYPE,
            to: `#${stripHashIfExists(scopedDataId)}`,
            variants: variantsArray ? _.map(variantsArray, variant => `#${stripHashIfExists(variant)}`) : []
        }
        return id
    }

    const addRefArrayItem = (namespace, type, values) => {
        const id = generateId(type)
        data[namespace][id] = {
            id,
            type: constants.REF_ARRAY_TYPE,
            values: _.map(values, v => `#${stripHashIfExists(v)}`)
        }
        return id
    }

    const linkQueryToComponent = (compStructure, type, id) => {
        compStructure[COMP_DATA_QUERY_KEYS_WITH_STYLE[type]] = constants.DATA_TYPES_WITH_HASH.includes(type)
            ? `#${id}`
            : id
    }

    const addItemAndLinkToComponent = (namespace, type, value, compStructure) => {
        const id = addDataItem(namespace, type, value)
        linkQueryToComponent(compStructure, type, id)
    }

    const addComponent = (parentRef, overrides) => {
        const id = generateId(constants.COMP_PREFIX)
        const compStructure = {
            id,
            components: [],
            ...overrides
        }
        parentRef.components.push(compStructure)
        return compStructure
    }

    const getAllComponentsBasedOnType = compType => {
        const desktopComponents = coreUtils.dataUtils.getAllCompsInStructure(
            structure,
            false,
            comp => comp.componentType === compType
        )
        const mobileComponents = coreUtils.dataUtils.getAllCompsInStructure(
            structure,
            true,
            comp => comp.componentType === compType
        )
        return {
            desktopComponents,
            mobileComponents
        }
    }

    const verifyDataMapExists = namespaces => {
        namespaces.forEach(namespace => (data[namespace] = data[namespace] ?? {}))
    }

    return {
        component: {
            addComponent,
            data: {
                linkQueryToComponent,
                addItemAndLinkToComponent
            }
        },
        structure: {
            getAllComponentsBasedOnType
        },
        data: {
            addRefArrayItem,
            addVariantRelation,
            verifyDataMapExists,
            getItem: getDataItem,
            addItem: addDataItem,
            updateItem: updateDataItem
        },
        generateId
    }
}

module.exports = {
    createPageManager
}
