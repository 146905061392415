import _ from 'lodash'
import {pointerUtils, type DAL, ExtensionAPI} from '@wix/document-manager-core'
import type {
    Breakpoint,
    ComponentExport,
    ExternalRefsMap,
    CompRef,
    Pointer,
    SchemaService
} from '@wix/document-services-types'
import {type ReferredDataItemsIterateeContext, forEachReferredDataItem} from '@wix/import-export-utils'
import {COMP_DATA_QUERY_KEYS_WITH_STYLE, DATA_TYPES, VIEW_MODES} from '../../../constants/constants'
import type {RelationshipsAPI} from '../../relationships'

type ReusableIdsMap = Record<string, Record<string, boolean>>

const {DESKTOP, MOBILE} = VIEW_MODES

const isReusable = (
    pointer: Pointer,
    reusableIds: ReusableIdsMap,
    relationships: RelationshipsAPI['relationships']
): boolean => {
    const {id, type} = pointer
    const reusableMapValue = reusableIds[type]?.[id]
    if (reusableMapValue !== undefined) {
        return reusableMapValue
    }

    let reusable = false
    const owners = relationships.getOwningReferencesToPointer(pointer).filter(owner => owner.type !== MOBILE)
    if (owners.length > 0) {
        reusable = owners.every(owner => isReusable(owner, reusableIds, relationships))
    }

    reusableIds[type] ??= {}
    reusableIds[type][id] = reusable
    return reusable
}

const canReuseId = (
    id: string,
    type: string,
    reusableIds: ReusableIdsMap,
    dal: DAL,
    extensionAPI: ExtensionAPI
): boolean => {
    const {relationships} = extensionAPI as RelationshipsAPI

    const pointer = pointerUtils.getPointer(id, type)
    return !dal.has(pointer) || isReusable(pointer, reusableIds, relationships)
}

const reuseId = (
    id: string,
    type: string,
    externalRefs: ExternalRefsMap,
    reusableIds: ReusableIdsMap,
    dal: DAL,
    extensionAPI: ExtensionAPI
) => {
    if (id && !externalRefs[id] && canReuseId(id, type, reusableIds, dal, extensionAPI)) {
        externalRefs[id] = id
    }
}

export const reuseIds = (
    componentExport: ComponentExport,
    extensionAPI: ExtensionAPI,
    dal: DAL,
    schemaService: SchemaService,
    componentToReplace?: CompRef
): void => {
    const reusableIds: ReusableIdsMap = {[componentToReplace!.type]: {[componentToReplace!.id]: true}}
    const {components, externalRefs, page} = componentExport
    _.forOwn(components, component => {
        reuseId(component.structure.id, DESKTOP, externalRefs, reusableIds, dal, extensionAPI)

        _.forOwn(COMP_DATA_QUERY_KEYS_WITH_STYLE, (query: string, namespace: string) => {
            const dataItem = component.data[namespace]
            if (!dataItem) {
                return
            }

            forEachReferredDataItem(
                schemaService,
                dataItem,
                namespace,
                ({item, namespace: itemNamespace}: ReferredDataItemsIterateeContext) =>
                    reuseId(item.id, itemNamespace, externalRefs, reusableIds, dal, extensionAPI)
            )
        })
    })

    _.forOwn(page.breakpoints, (breakpoint: Breakpoint, breakpointId: string) => {
        reuseId(breakpointId, DATA_TYPES.variants, externalRefs, reusableIds, dal, extensionAPI)
    })
}
