import type {PublicMethodUtils} from '@wix/document-services-types'
import {features} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            components: {
                features: {
                    get: publicMethodUtils.defineGetter(features.getFeatureData),
                    update: publicMethodUtils.defineDataManipulationAction(features.updateFeatureData),
                    remove: publicMethodUtils.defineDataManipulationAction(features.removeComponentFeatureData),
                    getAllCompsInPageBasedOnFeatureType: publicMethodUtils.defineGetter(
                        features.getAllCompsInPageBasedOnFeatureType
                    )
                }
            },
            page: {
                features: {
                    get: publicMethodUtils.defineGetter(features.page.get),
                    update: publicMethodUtils.defineDataManipulationAction(features.page.update),
                    remove: publicMethodUtils.defineDataManipulationAction(features.page.remove)
                }
            },
            site: {
                features: {
                    get: publicMethodUtils.defineGetter(features.site.get),
                    update: publicMethodUtils.defineDataManipulationAction(features.site.update),
                    remove: publicMethodUtils.defineDataManipulationAction(features.site.remove)
                }
            }
        }
    }
}
