import type {DalValue, ExtensionAPI, DAL} from '@wix/document-manager-core'
import type {
    CompRef,
    Pointer,
    Pointers,
    ExternalsBuilderMap,
    ExternalsMap,
    SchemaService
} from '@wix/document-services-types'
import _ from 'lodash'
import type {DataModelExtensionAPI} from '../../../../dataModel/dataModel'
import {getNewItemId, getNewItemRef} from '../../utils'
import {
    forEachReferredDataItem,
    getDetailedValueByPath,
    type ExtendedRefInfo,
    type ReferredDataItemsIterateeContext
} from '@wix/import-export-utils'
// eslint-disable-next-line wix-editor/no-internal-import
import * as referableByExternalNodes from '@wix/document-services-json-schemas/dist/referableByExternalNodes.json'
import {stripHashIfExists} from '../../../../../utils/refArrayUtils'
import type {RelationshipsAPI} from '../../../../relationships'
import type {SchemaExtensionAPI} from '../../../../..'
import type {ExternalsHandler} from '../../constants'
import {getExternalsHandler} from '../externals'

const sanitizeDataItem = (dataItem: DalValue, namespace: string) => {
    if (!referableByExternalNodes[namespace]?.[dataItem.type]) {
        delete dataItem.id
    }
}

const collectNonOwnedReferences = (
    item: DalValue,
    namespace: string,
    refFieldsInfo: ExtendedRefInfo[],
    pointers: Pointers,
    extensionAPI: ExtensionAPI,
    externals: ExternalsBuilderMap
) => {
    for (const {isRefOwner, path, constantValues, referencedMap} of refFieldsInfo) {
        if (isRefOwner) {
            continue
        }

        const refs = getDetailedValueByPath(item, path)
        for (const ref of refs) {
            if (!constantValues?.has(ref.value)) {
                const refId: string = stripHashIfExists(ref.value)
                const refPointer: Pointer = pointers.getPointer(refId, referencedMap)

                const handleExternals: ExternalsHandler = getExternalsHandler(namespace, item.type, path)
                handleExternals.pack(ref, externals, refPointer, item, extensionAPI)
            }
        }
    }
}

export const resolveDataItem = (
    itemPointer: Pointer,
    pagePointer: CompRef,
    pointers: Pointers,
    extensionAPI: ExtensionAPI,
    schemaService: SchemaService,
    externals?: ExternalsBuilderMap
): DalValue => {
    const {dataModel} = extensionAPI as DataModelExtensionAPI

    const dataItem = dataModel.getItem(itemPointer.id, itemPointer.type, pagePointer.id)

    forEachReferredDataItem(
        schemaService,
        dataItem,
        itemPointer.type,
        ({item, namespace, refFieldsInfo}: ReferredDataItemsIterateeContext) => {
            externals?.internalIds.add(item.id)
            sanitizeDataItem(item, namespace)
            if (externals) {
                collectNonOwnedReferences(item, namespace, refFieldsInfo, pointers, extensionAPI, externals)
            }
        }
    )

    return dataItem
}

const shouldRemoveOriginalItem = (item: DalValue, originalItem?: DalValue) =>
    originalItem && originalItem.id !== item.id

const removeReferencesToItem = (
    itemPointer: Pointer,
    namespace: string,
    dal: DAL,
    pointers: Pointers,
    extensionAPI: ExtensionAPI
) => {
    const {relationships, schemaAPI} = extensionAPI as RelationshipsAPI & SchemaExtensionAPI
    const referringPointers = relationships.getReferencesToPointer(itemPointer)
    for (const referringPointer of referringPointers) {
        const referringItem = dal.get(referringPointer)
        const refFieldsInfo = schemaAPI.extractReferenceFieldsInfo(referringPointer.type, referringItem.type)
        for (const refFieldInfo of refFieldsInfo) {
            if (refFieldInfo.referencedMap !== namespace) {
                continue
            }

            const referredPointers = getDetailedValueByPath(referringItem, refFieldInfo.path)
            for (const {value, path} of referredPointers) {
                if (stripHashIfExists(value) === itemPointer.id) {
                    const referencePointer = pointers.getInnerPointer(referringPointer, path)
                    dal.remove(referencePointer)
                }
            }
        }
    }
}

const removeOriginalItem = (
    originalItem: DalValue,
    namespace: string,
    dal: DAL,
    pointers: Pointers,
    extensionAPI: ExtensionAPI
) => {
    const originalItemPointer = pointers.getPointer(originalItem.id, namespace)
    removeReferencesToItem(originalItemPointer, namespace, dal, pointers, extensionAPI)
    dal.remove(originalItemPointer)
}

const replaceSingleItemReferences =
    (externals: ExternalsMap, dal: DAL, pointers: Pointers, extensionAPI: ExtensionAPI) =>
    ({item, namespace, refFieldsInfo, originalItem}: ReferredDataItemsIterateeContext) => {
        item.id = getNewItemId(item.id, namespace, externals.externalRefs, originalItem?.id)

        if (shouldRemoveOriginalItem(item, originalItem)) {
            removeOriginalItem(originalItem, namespace, dal, pointers, extensionAPI)
        }

        for (const {isRefOwner, path, constantValues, referencedMap, noHash} of refFieldsInfo) {
            if (isRefOwner) {
                continue
            }

            const valueByPath = getDetailedValueByPath(item, path)
            for (const detailedValue of valueByPath) {
                const {value} = detailedValue

                let newValue
                if (_.isArray(value)) {
                    newValue = value.map(ref =>
                        getNewItemRef(ref, referencedMap, !noHash, constantValues, externals.externalRefs)
                    )
                } else {
                    newValue = getNewItemRef(value, referencedMap, !noHash, constantValues, externals.externalRefs)
                }

                const handleExternals: ExternalsHandler = getExternalsHandler(namespace, item.type, path)
                handleExternals.unpack(detailedValue, externals, newValue, item)
            }
        }
    }

const replaceDataItemReferences = (
    dataItem: DalValue,
    origDataItem: DalValue,
    namespace: string,
    externals: ExternalsMap,
    dal: DAL,
    pointers: Pointers,
    extensionAPI: ExtensionAPI,
    schemaService: SchemaService
) => {
    forEachReferredDataItem(
        schemaService,
        dataItem,
        namespace,
        replaceSingleItemReferences(externals, dal, pointers, extensionAPI),
        {
            originalItem: origDataItem
        }
    )
}

export const updateComponentDataItem = (
    componentPointer: CompRef,
    dataItem: DalValue,
    namespace: string,
    externals: ExternalsMap,
    dal: DAL,
    pointers: Pointers,
    extensionAPI: ExtensionAPI,
    schemaService: SchemaService
) => {
    const {dataModel} = extensionAPI as DataModelExtensionAPI

    const origDataItem = dataModel.components.getItem(componentPointer, namespace)

    if (dataItem) {
        replaceDataItemReferences(
            dataItem,
            origDataItem,
            namespace,
            externals,
            dal,
            pointers,
            extensionAPI,
            schemaService
        )
        dataModel.components.addItem(componentPointer, namespace, dataItem)
    } else if (origDataItem) {
        dataModel.components.removeItem(componentPointer, namespace)
    }
}

export const updateDataItem = (
    dataItem: DalValue,
    origDataItem: DalValue,
    namespace: string,
    pagePointer: CompRef,
    externals: ExternalsMap,
    dal: DAL,
    pointers: Pointers,
    extensionAPI: ExtensionAPI,
    schemaService: SchemaService
) => {
    const {dataModel} = extensionAPI as DataModelExtensionAPI

    if (dataItem) {
        replaceDataItemReferences(
            dataItem,
            origDataItem,
            namespace,
            externals,
            dal,
            pointers,
            extensionAPI,
            schemaService
        )
        dataModel.addItemWithRefReuse(dataItem, namespace, pagePointer.id)
    } else if (origDataItem) {
        dataModel.removeItemRecursively(pointers.getPointer(origDataItem.id, namespace))
    }
}
