import _ from 'lodash'
import type {CreateExtArgs} from '@wix/document-manager-core'
import {getDeepDataFromMaster} from './blocksDataModel'
import type {Panel} from '@wix/document-services-types'

export const getPanelData = (createExtArgs: CreateExtArgs, panelId: string): Panel => {
    const panelData = getDeepDataFromMaster<Panel>(createExtArgs, panelId)
    return {
        name: panelData.name,
        kind: panelData.kind,
        title: panelData.title,
        pageUrl: panelData.pageUrl,
        helpId: panelData.helpId,
        // ensure height is an integer
        height: Math.round(panelData.height),
        rootCompId: _.trimStart(panelData.rootCompId, '#')
    }
}
