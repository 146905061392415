import {DocumentManager, pointerUtils} from '@wix/document-manager-core'
import {constants, PageAPI, DataModelExtensionAPI} from '@wix/document-manager-extensions'
import _ from 'lodash'
import type {PageMigrator} from '../dataMigrationRunner'

const {getPointer} = pointerUtils

const DEFAULT_DESIGN_TO_SET = {
    'wysiwyg.viewer.components.StripColumnsContainer': 'MediaContainerDesignData',
    'wysiwyg.viewer.components.PopupContainer': 'MediaContainerDesignData',
    'wysiwyg.viewer.components.MediaContainer': 'MediaContainerDesignData',
    'wysiwyg.viewer.components.VectorImage': 'VectorImageDesignData'
}

const {design} = constants.DATA_TYPES
const migratePage = (documentManager: DocumentManager, pageId: string) => {
    const {dataModel} = documentManager.extensionAPI as DataModelExtensionAPI
    const pageCompFilter = (documentManager.extensionAPI.page as PageAPI).getPageIndexId(pageId)
    const {dal} = documentManager
    const {DESKTOP, MOBILE} = constants.VIEW_MODES
    const desktopComps = dal.query(DESKTOP, pageCompFilter)
    const mobileComps = dal.query(MOBILE, pageCompFilter)
    _.forOwn(desktopComps, comp => {
        if (DEFAULT_DESIGN_TO_SET[comp.componentType] && !comp.designQuery) {
            const compPointer = getPointer(comp.id, DESKTOP)
            dataModel.components.addItem(compPointer, design, {type: DEFAULT_DESIGN_TO_SET[comp.componentType]})
        }
    })
    _.forOwn(mobileComps, comp => {
        if (DEFAULT_DESIGN_TO_SET[comp.componentType] && !comp.designQuery) {
            const compPointer = getPointer(comp.id, MOBILE)
            const desktopComp = desktopComps[comp.id]
            if (desktopComp) {
                dal.set(compPointer, {...comp, designQuery: desktopComp.designQuery})
            } else {
                dataModel.components.addItem(compPointer, design, {type: DEFAULT_DESIGN_TO_SET[comp.componentType]})
            }
        }
    })
}

const name = 'fixMissingDefaultDesignData'
const version = 1

export const fixMissingDefaultDesignData: PageMigrator = {
    migratePage,
    name,
    version
}
