import constants from '../../constants/constants'

export default {
    anchors: {
        to: {allow: true, lock: constants.ANCHORS.LOCK_CONDITION.THRESHOLD},
        from: {allow: true, lock: constants.ANCHORS.LOCK_CONDITION.ALWAYS}
    },
    resizableSides: [constants.RESIZE_SIDES.TOP, constants.RESIZE_SIDES.BOTTOM],
    containable: false
}
