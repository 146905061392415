import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import experiment from 'experiment-amd'
import constants from '../../constants/constants'
const {DATA_TYPES} = constants

function getLayoutLimits(ps: PS, repeaterPointer: Pointer) {
    const itemPointer = _.head(ps.pointers.full.components.getChildren(repeaterPointer))

    if (experiment.isOpen('dm_meshLayout')) {
        const meshItemLayout = ps.extensionAPI.variants.getComponentItemConsideringVariants(
            itemPointer,
            DATA_TYPES.layout
        )
        const minWidth = meshItemLayout?.componentLayout.width.value

        return {minWidth}
    }

    const itemLayout = itemPointer && ps.dal.full.get(ps.pointers.getInnerPointer(itemPointer, ['layout']))
    return {
        minWidth: _.get(itemLayout, 'width')
    }
}

export default {
    layoutLimits: getLayoutLimits,
    canBeStretched: true,
    resizableSides: [constants.RESIZE_SIDES.LEFT, constants.RESIZE_SIDES.RIGHT],
    mobileConversionConfig: {
        filterChildrenWhenHidden: true,
        stretchHorizontally: true
    }
}
