import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import skinExports from '@wix/santa-ds-libs/src/skinExports'

const MIN_GALLERY_HEIGHT = 70

function beforeAdd(ps: PS, compToAddPointer: Pointer, containerPointer: Pointer, compDefinition) {
    const currentItemHeight = _.get(compDefinition, 'props.itemHeight')
    if (_.isNumber(currentItemHeight)) {
        return
    }
    const {height} = compDefinition.layout
    const newHeight = Math.max(MIN_GALLERY_HEIGHT, height)
    const currentSkinExports = skinExports[compDefinition.skin]
    const heightDiff = currentSkinExports?.heightDiff || 0
    const numberOfRows = santaCoreUtils.matrixCalculations.getAvailableRowsNumber(
        compDefinition.props.maxRows,
        compDefinition.props.numCols,
        compDefinition.data.items.length
    )
    const itemHeight = santaCoreUtils.matrixCalculations.getItemHeight(
        compDefinition.props.margin,
        newHeight,
        numberOfRows,
        heightDiff
    )
    if (_.isFinite(itemHeight)) {
        _.set(compDefinition, 'props.itemHeight', itemHeight)
    }
}

export default {
    beforeAdd
}
