import _ from 'lodash'
import type {CurrentContext, PS, PublicMethodDefinition} from '@wix/document-services-types'

const IS_UPDATING_ANCHORS = {
    YES: 'yes',
    NO: 'no',
    DONT_CARE: "don't care"
} as const

const ENFORCE_TYPES = {
    NONE: 'NONE',
    INNER: 'INNER',
    FULL: 'FULL',
    DONT_CARE: 'DONT_CARE'
}

function getEnforceTypeForDataManipulation(isUpdatingAnchors: boolean | string) {
    switch (isUpdatingAnchors) {
        case IS_UPDATING_ANCHORS.YES:
            return ENFORCE_TYPES.NONE
        case IS_UPDATING_ANCHORS.NO:
            return ENFORCE_TYPES.FULL
        default:
            return ENFORCE_TYPES.DONT_CARE
    }
}

function getLockedComp(methodDefinition: PublicMethodDefinition, methodArgs: any[]) {
    const components = methodArgs[1]

    return methodDefinition.shouldLockComp && components ? components.id : null
}

function shouldUpdateAnchors(ps: PS, isUpdatingAnchors: undefined | boolean | string | Function, methodArgs: any[]) {
    const components = methodArgs[1]

    const updatingAnchors = _.isFunction(isUpdatingAnchors)
        ? isUpdatingAnchors(ps, components, methodArgs)
        : isUpdatingAnchors

    if (_.isString(updatingAnchors)) {
        return updatingAnchors
    }

    return updatingAnchors ? IS_UPDATING_ANCHORS.YES : IS_UPDATING_ANCHORS.NO
}

function getDataManipulationParams(
    ps: PS,
    dataManipulationDefinition: PublicMethodDefinition,
    methodName: string,
    currentContext: CurrentContext,
    args: any[]
) {
    const isUpdatingAnchors = shouldUpdateAnchors(ps, dataManipulationDefinition.isUpdatingAnchors, args)

    return {
        methodName,
        asyncPreDataManipulation: dataManipulationDefinition.asyncPreDataManipulation,
        noRefresh: dataManipulationDefinition.noRefresh,
        noBatching: dataManipulationDefinition.noBatching,
        noBatchingAfter: dataManipulationDefinition.noBatchingAfter,
        isAsyncOperation: dataManipulationDefinition.isAsyncOperation,
        waitingForTransition: dataManipulationDefinition.waitingForTransition,
        lockedComp: getLockedComp(dataManipulationDefinition, args),
        isUpdatingAnchors,
        enforceType: getEnforceTypeForDataManipulation(isUpdatingAnchors),
        currentContext,
        updateData: true
    }
}

function getImmediateActionParams(ps: PS, methodName: string, currentContext: CurrentContext, isUpdatingData: boolean) {
    return {
        methodName,
        currentContext,
        isUpdatingData
    }
}

export default {
    IS_UPDATING_ANCHORS,
    ENFORCE_TYPES,
    getDataManipulationParams,
    getImmediateActionParams
}
