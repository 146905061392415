import _ from 'lodash'
import * as extraSkinsByComponentType from './extraSkinsByComponentType.json'

function addToArrays(skins: any, additions: any) {
    _.forEach(additions, (values, key) => {
        skins[key] = (skins[key] || []).concat(values)
    })
}

export function createSkinsByComponentType(componentDefinitions: any, extras: any) {
    const skins = _.mapValues(componentDefinitions, i => (i.skins || []).slice())
    const responsiveSkins = _(componentDefinitions).mapValues('responsiveSkins').pickBy(_.identity).value()
    addToArrays(skins, extraSkinsByComponentType)
    addToArrays(skins, extras)
    return {skins, responsiveSkins}
}
