import type {
    CreateExtArgs,
    DocumentDataTypes,
    Extension,
    ExtensionAPI,
    InitializeExtArgs,
    Transaction
} from '@wix/document-manager-core'
import _ from 'lodash'
import {APPS_INSTALL_STATE_ID, AppsInstallStateAPI} from './appsInstallState'
import {APP_STATUS} from '../constants/constants'
import type {AppDefinitionId, AppsInstallStateMap} from '@wix/document-services-types'
import type {DistributorExtensionAPI} from './distributor/distributor'
import type {OpenPlatformPanel, PlatformSharedAPIState, PlatformSharedManifestState} from './distributor/messages'

const PANEL_STATE = {
    OPEN: 'open',
    CLOSED: 'closed'
}

type AppStateManifestListener = (appDefId: string) => void
type PlatformAPICallsListener = (appDefId: string) => void

type PendingAppsChangesListener = (appsToGrant: string[], appsToRemove: string[]) => void

const getDocumentDataTypes = (): DocumentDataTypes => ({})

const EVENTS = {
    CONTEXT: {
        API_CALLED: 'API_CALLED'
    }
}

export interface PlatformSharedState extends ExtensionAPI {
    platformSharedState: {
        notifyManifestWasChanged(appDefId: string): void
        subscribeToManifestWasChanges(listener: AppStateManifestListener): void
        notifyPlatformAPIWasCalled(appDefId: string): void
        subscribeToPlatformAPICalls(appDefId: string, listener: PlatformAPICallsListener): void
        unsubscribeToPlatformAPICalls(appDefId: string): void
        subscribeToPendingAppsChanges(listener: PendingAppsChangesListener): void
    }
}

const createExtension = (): Extension => {
    let localAppsInstallState: AppsInstallStateMap = {}

    const openPlatformPanelsMap = new Map()

    const appsPendingStateListeners: PendingAppsChangesListener[] = []
    const platformAPICallsListeners: Record<string, PlatformAPICallsListener> = {}

    const notifyAPIChangeListeners = (appDefIds: string[]) => {
        appDefIds.forEach(appDefId => {
            try {
                if (platformAPICallsListeners[appDefId]) {
                    platformAPICallsListeners[appDefId](appDefId)
                }
            } catch (e) {
                console.error(e)
            }
        })
    }

    const updateOpenPanelsMap = (appDefinitionId: AppDefinitionId, action: string) => {
        try {
            if (action === PANEL_STATE.OPEN) {
                if (!openPlatformPanelsMap.get(appDefinitionId)) {
                    openPlatformPanelsMap.set(appDefinitionId, {count: 0})
                }
                const openPanelObjForApp = openPlatformPanelsMap.get(appDefinitionId)
                openPanelObjForApp.count += 1
                openPlatformPanelsMap.set(appDefinitionId, openPanelObjForApp)
            } else {
                const openPanelObjForApp = openPlatformPanelsMap.get(appDefinitionId)
                openPanelObjForApp.count -= 1
                openPlatformPanelsMap.set(appDefinitionId, openPanelObjForApp)
                if (openPanelObjForApp.count <= 0) {
                    openPlatformPanelsMap.delete(appDefinitionId)
                }
            }
        } catch (e) {
            console.error(e)
        }
    }
    const notifyPendingAppsChangesListeners = (newPendingAppsState: AppsInstallStateMap) => {
        const appsToRevoke: any = []
        const appsToGrant: any = []
        if (!_.isEqual(localAppsInstallState, newPendingAppsState)) {
            const appsStatesKeys = new Set([...Object.keys(newPendingAppsState), ...Object.keys(localAppsInstallState)])
            appsStatesKeys.forEach(appDefId => {
                const currentStatus = localAppsInstallState?.[appDefId]?.status
                const newStatus = newPendingAppsState?.[appDefId]?.status
                if (currentStatus !== APP_STATUS.INSTALLED && newStatus === APP_STATUS.INSTALLED) {
                    appsToGrant.push(appDefId)
                } else if (currentStatus === APP_STATUS.INSTALLED && newStatus !== APP_STATUS.INSTALLED) {
                    appsToRevoke.push(appDefId)
                }
            })
            if (!_.isEmpty(appsToRevoke) || !_.isEmpty(appsToGrant)) {
                appsPendingStateListeners.forEach(listener => listener(appsToGrant, appsToRevoke))
            }
            localAppsInstallState = newPendingAppsState
        }
    }

    const notifyPlatformAPIWasCalledInternal = (extensionAPI: ExtensionAPI, appDefinitionId: string) => {
        const {distributor} = extensionAPI as DistributorExtensionAPI
        distributor.distributeMessageAfterApproval<PlatformSharedAPIState>('platformSharedAPIState', {appDefinitionId})
    }

    const notifyPlatformPanelStateChanged = (extensionAPI: ExtensionAPI, appDefinitionId: string, state: string) => {
        const {distributor} = extensionAPI as DistributorExtensionAPI
        distributor.distributeMessageAfterApproval<OpenPlatformPanel>('openPlatformPanelsState', {
            appDefinitionId,
            action: state
        })
    }

    const initialState = {}

    const createExtensionAPI = ({extensionAPI}: CreateExtArgs): PlatformSharedState => ({
        platformSharedState: {
            notifyManifestWasChanged: (appDefinitionId: string) => {
                const {distributor} = extensionAPI as DistributorExtensionAPI
                distributor.distributeMessage<PlatformSharedManifestState>('platformSharedManifestState', {
                    appDefinitionId
                })
            },
            subscribeToManifestWasChanges: (listener: AppStateManifestListener) => {
                const {distributor} = extensionAPI as DistributorExtensionAPI
                distributor.subscribeToMessage<PlatformSharedManifestState>(
                    'platformSharedManifestState',
                    ({data: {appDefinitionId}}) => listener(appDefinitionId)
                )
            },
            notifyPlatformAPIWasCalled: appDefId => notifyPlatformAPIWasCalledInternal(extensionAPI, appDefId),
            subscribeToPlatformAPICalls: (appDefinitionId: string, listener: PlatformAPICallsListener) => {
                platformAPICallsListeners[appDefinitionId] = listener
                notifyPlatformPanelStateChanged(extensionAPI, appDefinitionId, PANEL_STATE.OPEN)
            },
            unsubscribeToPlatformAPICalls: (appDefinitionId: string) => {
                notifyPlatformPanelStateChanged(extensionAPI, appDefinitionId, PANEL_STATE.CLOSED)
                delete platformAPICallsListeners[appDefinitionId]
            },
            subscribeToPendingAppsChanges: (listener: PendingAppsChangesListener) => {
                if (_.isFunction(listener)) {
                    appsPendingStateListeners.push(listener)
                }
            }
        }
    })

    const createPostTransactionOperations = () => ({
        platform: (documentTransaction: Transaction) => {
            const pendingAppsChanges = documentTransaction.items.filter(e => e?.key?.id === APPS_INSTALL_STATE_ID)

            if (!_.isEmpty(pendingAppsChanges)) {
                pendingAppsChanges.forEach(e => notifyPendingAppsChangesListeners(e.value?.stateMap))
            }
        }
    })

    const populateLocalMapWithAppsInstallState = (extensionAPI: ExtensionAPI) => {
        const appsInstallState = extensionAPI.appsInstallState as AppsInstallStateAPI
        localAppsInstallState = appsInstallState.getAllAppsInstallStatus()
    }
    const initialize = async ({eventEmitter, extensionAPI}: InitializeExtArgs) => {
        const {distributor} = extensionAPI as DistributorExtensionAPI
        distributor.subscribeToMessage<PlatformSharedAPIState>('platformSharedAPIState', ({data: {appDefinitionId}}) =>
            notifyAPIChangeListeners([appDefinitionId])
        )
        distributor.subscribeToMessage<OpenPlatformPanel>(
            'openPlatformPanelsState',
            ({data: {appDefinitionId, action}}) => updateOpenPanelsMap(appDefinitionId, action)
        )
        eventEmitter.on(EVENTS.CONTEXT.API_CALLED, (appDefId: string) => {
            const otherEditorHaveAppPanelOpened = openPlatformPanelsMap.get(appDefId)
            if (otherEditorHaveAppPanelOpened && otherEditorHaveAppPanelOpened.count > 0) {
                notifyPlatformAPIWasCalledInternal(extensionAPI, appDefId)
            }
        })
        populateLocalMapWithAppsInstallState(extensionAPI)
    }

    return {
        name: 'platformSharedState',
        getDocumentDataTypes,
        initialState,
        createExtensionAPI,
        createPostTransactionOperations,
        initialize,
        EVENTS
    }
}

export {createExtension, EVENTS}
