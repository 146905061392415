import type {Layout, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import component from '../../component/component'

export default function (ps: PS, compPointer: Pointer, newLayout: Layout) {
    if (_.isUndefined(newLayout.height)) {
        return
    }
    component.properties.update(ps, compPointer, {height: newLayout.height})
}
