import _ from 'lodash'
import componentsMetaData from '../../componentsMetaData/componentsMetaData'
import structure from '../../structure/structure'
import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'
import type {Pointer, PS} from '@wix/document-services-types'
import type {DsFixer} from '../dsDataFixers'

const GROUP_COMPONENT_TYPE = 'wysiwyg.viewer.components.Group'

function isSiteFromOnBoarding(ps: PS) {
    const clientSpecMap = ps.dal.get(ps.pointers.general.getClientSpecMap())
    const onBoardingSpec = _.find(clientSpecMap, {type: 'onboarding'})
    return onBoardingSpec ? onBoardingSpec.inUse === false : false
}

function fixGroup(ps: PS, groupPointer: Pointer) {
    const groupedComponents = ps.pointers.components.getChildren(groupPointer)
    _.forEach(groupedComponents, function (componentPointer) {
        if (!componentsMetaData.public.isGroupable(ps, componentPointer)) {
            structure.reparentComponentToPage(ps, componentPointer, true)
        }
    })
}

const fixer: DsFixer = {
    exec(ps: PS) {
        if (isSiteFromOnBoarding(ps)) {
            const allGroups = componentDetectorAPI.getAllComponentsFromFull(ps, null, function (pointer) {
                const comp = ps.dal.get(pointer)
                return comp && comp.componentType === GROUP_COMPONENT_TYPE
            })
            _.forEach(allGroups, function (pointer) {
                fixGroup(ps, pointer)
            })
        }
    },
    name: 'adiGroupFixer',
    version: 1
}
export default fixer
