import type {Pointer, Callback1, PS, Callback} from '@wix/document-services-types'
import _ from 'lodash'
import {requestsUtil, svgUtils} from '@wix/santa-core-utils'
import {coreUtils} from '@wix/santa-ds-libs'
const {svg} = coreUtils
import dsUtils from '../utils/utils'
import {ReportableError} from '@wix/document-manager-utils'

const {SVG_STORES, EMPTY_SHAPE, svgIdToUrl} = svgUtils
const {svgStringToStoreData} = svg
const fetchOtions = {
    method: 'GET',
    headers: {'content-type': 'text/plain'},
    mode: 'cors'
}

/**
 * Cache an SVG to siteData and notify asyncPreDataManipulationComplete
 * @param {ps} ps
 * @param {string} svgId
 */
function cacheSvgString(ps: PS, svgId: string) {
    getSvgString(ps, svgId, () => {
        ps.setOperationsQueue.asyncPreDataManipulationComplete()
    })
}

function getSvgString(ps: PS, svgId: string, callback: Callback) {
    const svgString = getSvgStringFromCache(ps, svgId)
    if (svgString) {
        callback()
    } else {
        fetchSvgString(ps, svgId, callback)
    }
}

/**
 * Ajax get the contents of an SVG file and add it to cache
 * @param {ps} ps
 * @param {string} svgId
 */
async function fetchSvgStringAsync(ps: PS, svgId: string) {
    const url = getSvgFullUrl(ps, svgId)
    const response = await requestsUtil.fetch(url, fetchOtions)
    const svgString = await response.text()
    setSvgToCache(ps, svgId, svgString)
    return svgString
}

/**
 * Ajax get the contents of an SVG file and add it to cache
 * @param {ps} ps
 * @param {string} svgId
 * @param {function(*): *} callback
 */
async function fetchSvgString(ps: PS, svgId: string, callback: Callback) {
    try {
        await fetchSvgStringAsync(ps, svgId)
    } catch (err) {
        ps.extensionAPI.logger.captureError(
            new ReportableError({
                message: 'failed to fetch SVG string from URL',
                errorType: 'fetchSvgStringError',
                extras: {
                    svgId
                }
            })
        )
    }

    callback()
}

/**
 * Get a url from a publicMedia SVG id
 * @param {ps} ps
 * @param {string} svgId
 * @returns {string}
 */
function getSvgFullUrl(ps: PS, svgId: string) {
    const serviceTopologyPointer = ps.pointers.general.getServiceTopology()
    const mediaRootUrlPointer = ps.pointers.getInnerPointer(serviceTopologyPointer, 'mediaRootUrl')
    const mediaRootUrl = ps.dal.get(mediaRootUrlPointer)
    // @ts-expect-error
    return svgIdToUrl(mediaRootUrl, svgId, {rendererModel: ps.siteDataAPI.siteData.rendererModel})
}

/**
 *
 * @param {ps} ps
 * @param {string} svgId
 * @param {function} [callback]
 * @returns {string}
 */
function getSvgStringFromCache(ps: PS, svgId: string, callback?: Callback1<string>) {
    const storePointer = ps.pointers.svgShapes.getSvgShapePointer(svgId)
    const contentPointer = ps.pointers.getInnerPointer(storePointer, SVG_STORES.CONTENT)
    const svgString = ps.dal.get(contentPointer)
    if (callback && _.isFunction(callback)) {
        callback(svgString)
    }
    return svgString
}

function getSvgInfoFromCache(ps: PS, svgId: string): string {
    const storePointer = ps.pointers.svgShapes.getSvgShapePointer(svgId)
    const infoPointer = ps.pointers.getInnerPointer(storePointer, SVG_STORES.INFO)
    return ps.dal.get(infoPointer)
}

function setSvgToCache(ps: PS, svgId: string, svgString: string) {
    const svgStorePointer = ps.pointers.svgShapes.getSvgShapePointer(svgId)
    const storeData = svgStringToStoreData(svgString || EMPTY_SHAPE)
    ps.dal.set(svgStorePointer, storeData)
}

/**
 * Get the original aspect ration of the currently rendered SVG
 * @param ps
 * @param compRef
 * @returns {number}
 */
function getOriginalAspectRatio(ps: PS, compRef: Pointer) {
    const compStructure = ps.dal.get(compRef)
    const page = ps.pointers.components.getPageOfComponent(compRef)
    const compDataPointer = ps.pointers.data.getDataItem(dsUtils.stripHashIfExists(compStructure.dataQuery), page.id)
    const compData = ps.dal.get(compDataPointer)
    const svgId = _.get(compData, ['svgId'])
    const storePointer = ps.pointers.svgShapes.getSvgShapePointer(svgId)
    const boxBoundariesPointer = ps.pointers.getInnerPointer(storePointer, SVG_STORES.BOX_BOUNDARIES)
    const boxBoundaries = ps.dal.get(boxBoundariesPointer)
    if (_.isEmpty(boxBoundaries)) {
        return 0
    }
    return boxBoundaries.width / boxBoundaries.height
}

export default {
    cacheSvgString,
    getSvgFromCache: getSvgStringFromCache,
    getSvgInfoFromCache,
    getOriginalAspectRatio
}
