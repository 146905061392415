import type {CreateExtArgs, Extension, ExtensionAPI, DeepFunctionMap} from '@wix/document-manager-core'
import {deepClone} from '@wix/wix-immutable-proxy'
import type {WixCodeFileDescriptor} from '@wix/document-services-types'
import * as undoRedoUtils from './wixCodeUndoableFiles'
import _ from 'lodash'
import {createImmutableDAL, ImmutableDAL} from '../../utils/immutableDAL'

interface FilesMapAPI<T> {
    get(filePath: string): T
    set(filePath: string, content: T): void
    remove(filePath: string): void
    getAll(): Record<string, T>
    keys(): string[]
    clear(): void
}

interface FileStorageSnapshotsAPI
    extends DeepFunctionMap,
        Pick<
            ImmutableDAL,
            | 'commitPendingSnapshot'
            | 'rollbackSnapshot'
            | 'markPendingSnapshot'
            | 'getLastSnapshot'
            | 'getPendingSnapshot'
            | 'getCurrentSnapshot'
            | 'getTaggedSnapshot'
            | 'takeTaggedSnapshot'
        > {}

interface FileStorageAPI {
    modifiedFileContents: FilesMapAPI<string>
    loadedFileContents: FilesMapAPI<string>
    duplicatedFilesInfo: FilesMapAPI<string>
    filePathToIdMap: FilesMapAPI<string>
    areChildrenLoaded: FilesMapAPI<boolean>
    loadedChildren: FilesMapAPI<WixCodeFileDescriptor[]>
    directoryFlagByDeletedPath: FilesMapAPI<boolean>
    bundleCacheKiller: FilesMapAPI<string>
    snapshots?: FileStorageSnapshotsAPI
}

interface WixCodeFilesAPI extends ExtensionAPI {
    writeFile(filePath: string, content: string): void
    readFile(filePath: string): string | void | null
    isFileExists(filePath: string): boolean
    moveFile(sourceFilePath: string, targetFilePath: string): void
    deleteFile(filePath: string): void
    deleteFolder(folderPath: string): void
    loadFileContent(filePath: string, content: string): void
    isFileReadable(filePath: string): boolean
    getSourceFilePath(filePath: string): string
    updateDuplicates(filePath: string, content: string): void
    markFileForDuplication(sourceFilePath: string, targetFilePath: string): void

    addChild(parentFolderPath: string, itemDescriptor: WixCodeFileDescriptor): void
    removeChild(parentFolderPath: string, itemDescriptor: WixCodeFileDescriptor): void
    hasKnownChild(parentFolderPath: string, itemDescriptor: WixCodeFileDescriptor): boolean
    isChildrenExists(parentFolderPath: string): boolean
    areChildrenLoaded(parentFolderPath: string): boolean
    getChildren(parentFolderPath: string): WixCodeFileDescriptor[]
    loadChildren(parentFolderPath: string, children: WixCodeFileDescriptor[]): void
    getLoadedFileContentMap(): Record<string, string>
    getModifiedFileContentMap(): Record<string, string>
    getFilePathToIdMap(): Record<string, string>
    getDuplicatesMap(): Record<string, string>
    getDirectoryFlagByDeletedPathMap(): Record<string, boolean>
    markPathAsDeleted(path: string, isDirectory: boolean): void
    clearDeletedPath(path: string): void
    // clearAllDeletedPaths(): void
    getGlobalBundleCacheKiller(): string
    updateGlobalBundleCacheKiller(): void
    getBundleCacheKiller(bundleId: string): string
    updateBundleCacheKiller(bundleId: string): void
    clearBundleCacheKillers(): void
    snapshots: FileStorageSnapshotsAPI | {}
}

const createStandaloneFilesDal = ({}: CreateExtArgs) => {
    const wixcodeDal = createImmutableDAL({
        loadedFileContents: {},
        modifiedFileContents: {},
        duplicatedFilesInfo: {},
        filePathToIdMap: {},
        loadedChildren: {},
        areChildrenLoaded: {},
        directoryFlagByDeletedPath: {},
        bundleCacheKiller: {}
    })
    wixcodeDal.takeInitialSnapshot()
    function createFileAPIsForImmutable<T>(stateName: string): FilesMapAPI<T> {
        return {
            get(filePath: string) {
                return wixcodeDal.getByPath([stateName, filePath])
            },
            set(filePath: string, newContent: T) {
                wixcodeDal.setByPath([stateName, filePath], newContent)
            },
            remove(filePath: string) {
                wixcodeDal.removeByPath([stateName, filePath])
            },
            getAll() {
                return wixcodeDal.getByPath([stateName])
            },
            keys() {
                return wixcodeDal.getKeysByPath([stateName])
            },
            clear() {
                wixcodeDal.setByPath([stateName], {})
            }
        }
    }
    return {
        loadedFileContents: createFileAPIsForImmutable<string>('loadedFileContents'),
        modifiedFileContents: createFileAPIsForImmutable<string>('modifiedFileContents'),
        duplicatedFilesInfo: createFileAPIsForImmutable<string>('duplicatedFilesInfo'),
        filePathToIdMap: createFileAPIsForImmutable<string>('filePathToIdMap'),
        loadedChildren: createFileAPIsForImmutable<WixCodeFileDescriptor[]>('loadedChildren'),
        areChildrenLoaded: createFileAPIsForImmutable<boolean>('areChildrenLoaded'),
        directoryFlagByDeletedPath: createFileAPIsForImmutable<boolean>('directoryFlagByDeletedPath'),
        bundleCacheKiller: createFileAPIsForImmutable<string>('bundleCacheKiller'),
        snapshots: _.pick(wixcodeDal, [
            'commitPendingSnapshot',
            'rollbackSnapshot',
            'markPendingSnapshot',
            'getLastSnapshot',
            'getPendingSnapshot',
            'getCurrentSnapshot',
            'getTaggedSnapshot',
            'takeTaggedSnapshot'
        ])
    }
}
const createFileSystemAPI = ({dal, pointers}: CreateExtArgs, filesDal: FileStorageAPI): WixCodeFilesAPI => {
    function belongsToFolder(folderPath: string) {
        const ensuredFolderPath = folderPath.replace(/\/*$/, '/')
        return function (itemPath: string) {
            return _.startsWith(itemPath, ensuredFolderPath)
        }
    }
    function isFileExists(filePath: string) {
        return (
            !_.isNil(filesDal.loadedFileContents.get(filePath)) || !_.isNil(filesDal.modifiedFileContents.get(filePath))
        )
    }
    const isFileReadable = (filePath: string) => {
        if (undoRedoUtils.isUndoableFile(filePath)) {
            return typeof filesDal.loadedFileContents.get(filePath) !== 'undefined'
        }

        return isFileExists(filePath)
    }
    function readUndoableFile(filePath: string) {
        const loadedContent = filesDal.loadedFileContents.get(filePath)
        const nonUndoableModifiedContent = filesDal.modifiedFileContents.get(filePath)
        const undoableModifiedContent = undoRedoUtils.getUndoableContent(filesDal, dal, pointers, filePath)

        if (!loadedContent && !nonUndoableModifiedContent && !undoableModifiedContent) {
            return null
        }

        return undoRedoUtils.assembleUndoableFile(
            filePath,
            loadedContent,
            nonUndoableModifiedContent,
            undoableModifiedContent
        )
    }

    const readFile = (filePath: string) => {
        if (undoRedoUtils.isUndoableFile(filePath)) {
            return readUndoableFile(filePath)
        }
        return filesDal.modifiedFileContents.get(filePath) ?? filesDal.loadedFileContents.get(filePath) ?? null
    }

    const writeFile = (filePath: string, newContent: string) => {
        const isNewFile = !isFileExists(filePath)
        filesDal.modifiedFileContents.set(filePath, newContent)
        filesDal.duplicatedFilesInfo.remove(filePath)

        if (undoRedoUtils.isUndoableFile(filePath)) {
            undoRedoUtils.setUndoableContent(filesDal, dal, pointers, filePath, newContent)
            if (isNewFile) {
                filesDal.loadedFileContents.set(filePath, newContent)
            }
        }
    }

    const moveFile = (currentFilePath: string, targetFilePath: string) => {
        if (isFileExists(targetFilePath)) {
            throw new Error(`target file [${targetFilePath}] already exists`)
        }

        _.forEach(
            [filesDal.loadedFileContents, filesDal.modifiedFileContents, filesDal.filePathToIdMap],
            ({get, set, remove}) => {
                const value = get(currentFilePath)
                if (typeof value !== 'undefined') {
                    set(targetFilePath, value)
                    remove(currentFilePath)
                }
            }
        )
    }

    const loadFileContent = (filePath: string, newContent: string) => {
        filesDal.loadedFileContents.set(filePath, newContent)
    }

    const deleteFile = (filePath: string) => {
        _.forEach(
            [
                filesDal.loadedFileContents,
                filesDal.modifiedFileContents,
                filesDal.filePathToIdMap,
                filesDal.duplicatedFilesInfo
            ],
            ({get, remove}) => {
                const value = get(filePath)
                if (typeof value !== 'undefined') {
                    remove(filePath)
                }
            }
        )
    }

    const deleteFolder = (folderPath: string) => {
        _.forEach(
            [
                filesDal.loadedFileContents,
                filesDal.modifiedFileContents,
                filesDal.filePathToIdMap,
                filesDal.duplicatedFilesInfo,
                filesDal.areChildrenLoaded,
                filesDal.loadedChildren
            ],
            ({keys, remove}) => {
                _(keys())
                    .filter(belongsToFolder(folderPath))
                    .forEach(filePath => remove(filePath))
            }
        )
    }

    const getSourceFilePath = (filePath: string) => {
        const duplicatesMap = filesDal.duplicatedFilesInfo.getAll()
        while (duplicatesMap[filePath]) {
            filePath = duplicatesMap[filePath]
        }

        return filePath
    }

    const updateDuplicates = (filePath: string, content: string) => {
        const duplicatesMap = filesDal.duplicatedFilesInfo.getAll()
        const pathsToUpdate = _.transform(
            duplicatesMap,
            function (result, sourceFilePath, targetFilePath) {
                if (targetFilePath === filePath && !isFileExists(sourceFilePath)) {
                    result.push(sourceFilePath)
                } else if (sourceFilePath === filePath && !isFileExists(targetFilePath as any)) {
                    result.push(targetFilePath)
                }
            },
            [] as string[]
        )

        _.forEach(pathsToUpdate, function (path) {
            loadFileContent(path, content)
        })

        _.forEach(pathsToUpdate, function (path) {
            updateDuplicates(path, content)
        })
    }
    const markFileForDuplication = (source: string, target: string) => {
        filesDal.duplicatedFilesInfo.set(target, source)
    }

    function sortFiles(children: WixCodeFileDescriptor[]) {
        return _.orderBy(children, ['directory', 'name'], ['desc', 'asc'])
    }

    const addChild = (parentFolderPath: string, itemDescriptor: WixCodeFileDescriptor) => {
        const prevChildren = filesDal.loadedChildren.get(parentFolderPath) || []
        const existingItem = _.find(prevChildren, {name: itemDescriptor.name, directory: itemDescriptor.directory})

        if (!existingItem) {
            const nextChildren = sortFiles(prevChildren.concat(itemDescriptor))
            filesDal.loadedChildren.set(parentFolderPath, nextChildren)
        }
    }
    const removeChild = (parentFolderPath: string, itemDescriptor: WixCodeFileDescriptor) => {
        const prevChildren = filesDal.loadedChildren.get(parentFolderPath) || []
        const nextChildren = _.reject(prevChildren, {name: itemDescriptor.name, directory: itemDescriptor.directory})
        filesDal.loadedChildren.set(parentFolderPath, nextChildren)
    }
    const hasKnownChild = (parentFolderPath: string, itemDescriptor: WixCodeFileDescriptor) => {
        const children = filesDal.loadedChildren.get(parentFolderPath) || []
        const child = _.find(children, {name: itemDescriptor.name, directory: itemDescriptor.directory})
        return !!child
    }

    const isChildrenExists = (parentFolderPath: string) =>
        typeof filesDal.loadedChildren.get(parentFolderPath) !== 'undefined'

    const areChildrenLoaded = (parentFolderPath: string) => !!filesDal.areChildrenLoaded.get(parentFolderPath)
    const getChildren = (parentFolderPath: string) => deepClone(filesDal.loadedChildren.get(parentFolderPath))

    const loadChildren = (parentFolderPath: string, newlyLoadedChildren: WixCodeFileDescriptor[]) => {
        const prevChildren = filesDal.loadedChildren.get(parentFolderPath) || []

        const childrenToSet = sortFiles(_.unionBy(prevChildren, newlyLoadedChildren, 'name'))
        filesDal.loadedChildren.set(parentFolderPath, childrenToSet)
        filesDal.areChildrenLoaded.set(parentFolderPath, true)
    }
    const getLoadedFileContentMap = () => filesDal.loadedFileContents.getAll()
    const getModifiedFileContentMap = () => filesDal.modifiedFileContents.getAll()
    const getFilePathToIdMap = () => filesDal.filePathToIdMap.getAll()
    const getDuplicatesMap = () => filesDal.duplicatedFilesInfo.getAll()
    const getDirectoryFlagByDeletedPathMap = () => filesDal.directoryFlagByDeletedPath.getAll()

    const markPathAsDeleted = (path: string, isDirectory: boolean) =>
        filesDal.directoryFlagByDeletedPath.set(path, isDirectory)
    const clearDeletedPath = (path: string) => filesDal.directoryFlagByDeletedPath.remove(path)
    // const clearAllDeletedPaths = () => filesDal.directoryFlagByDeletedPath.clear()

    const getNewCacheKillerValue = (existingValue: string) => {
        const suggestedValue = Date.now().toString()
        return suggestedValue === existingValue ? `${suggestedValue}_1` : suggestedValue
    }

    const getGlobalBundleCacheKiller = () => filesDal.bundleCacheKiller.get('globalBundleCacheKiller')
    const updateGlobalBundleCacheKiller = () =>
        filesDal.bundleCacheKiller.set(`globalBundleCacheKiller`, getNewCacheKillerValue(getGlobalBundleCacheKiller()))

    const getBundleCacheKiller = (bundleId: string) => filesDal.bundleCacheKiller.get(`bundleCK_${bundleId}`)
    const updateBundleCacheKiller = (bundleId: string) =>
        filesDal.bundleCacheKiller.set(`bundleCK_${bundleId}`, getNewCacheKillerValue(getBundleCacheKiller(bundleId)))

    const clearBundleCacheKillers = () => {
        const globalCK = getGlobalBundleCacheKiller() //backwards compatibility, this didnt used to clear
        filesDal.bundleCacheKiller.clear()
        filesDal.bundleCacheKiller.set(`globalBundleCacheKiller`, globalCK)
    }

    return {
        readFile,
        writeFile,
        moveFile,
        deleteFile,
        isFileReadable,
        isFileExists,
        loadFileContent,
        deleteFolder,
        getSourceFilePath,
        updateDuplicates,
        markFileForDuplication,
        addChild,
        removeChild,
        hasKnownChild,
        isChildrenExists,
        areChildrenLoaded,
        getChildren,
        loadChildren,
        getLoadedFileContentMap,
        getModifiedFileContentMap,
        getFilePathToIdMap,
        markPathAsDeleted,
        clearDeletedPath,
        getDuplicatesMap,
        getDirectoryFlagByDeletedPathMap,
        // clearAllDeletedPaths,
        getGlobalBundleCacheKiller,
        updateGlobalBundleCacheKiller,
        getBundleCacheKiller,
        updateBundleCacheKiller,
        clearBundleCacheKillers,
        snapshots: filesDal.snapshots ?? {}
    }
}
const createExtension = (): Extension => {
    const createExtensionAPI = (createExtensionArgs: CreateExtArgs) => {
        const filesDalAPI = createStandaloneFilesDal(createExtensionArgs)

        const fileSystemAPI = createFileSystemAPI(createExtensionArgs, filesDalAPI)
        return {
            wixCodeFileSystem: fileSystemAPI
        }
    }

    return {
        name: 'wixCodeFileSystem',
        createExtensionAPI
    }
}

export {createExtension}
