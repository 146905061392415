import metaDataUtils from '../metaDataUtils'

const {isMembersMenu} = metaDataUtils

export default {
    a11yConfigurable: false,
    styleCanBeApplied: true,
    mobileConversionConfig: {
        isScreenWidth: true,
        desktopOnly(ps, compStructure, pageId) {
            return !isMembersMenu(ps, compStructure, pageId)
        },
        fixedSize: {
            height: 48
        }
    }
}
