import $ from 'zepto'

const SCRIPT = 'https://widgets.pinterest.com/v1/urls/count.json?url='

export default function (url, callback) {
    let result = 0
    // @ts-ignore
    window._tempReceiveCount = window.receiveCount
    // @ts-ignore
    window.receiveCount = function (obj) {
        result = obj.count
    }
    $.ajax({
        url: SCRIPT + encodeURIComponent(url),
        dataType: 'script',
        complete() {
            callback(result)
            // @ts-ignore
            window.receiveCount = window._tempReceiveCount
            // @ts-ignore
            delete window._tempReceiveCount
        }
    })
}
