import type {CompStructure, PS} from '@wix/document-services-types'
import metaDataUtils from '../metaDataUtils'

const {isMembersMenu} = metaDataUtils

export default {
    a11yConfigurable: false,
    canBeStretched: true,
    styleCanBeApplied: true,
    mobileConversionConfig: {
        hideByDefault(ps: PS, compStructure: CompStructure, pageId: string) {
            return isMembersMenu(ps, compStructure, pageId) ? false : undefined
        },
        isScreenWidth(ps: PS, compStructure: CompStructure, pageId: string) {
            return isMembersMenu(ps, compStructure, pageId) ? true : undefined
        },
        desktopOnly(ps: PS, compStructure: CompStructure, pageId: string) {
            return !isMembersMenu(ps, compStructure, pageId)
        },
        fixedSize(ps: PS, compStructure: CompStructure, pageId: string) {
            return isMembersMenu(ps, compStructure, pageId) ? {height: 48} : undefined
        }
    }
}
