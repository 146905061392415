import appStudioConstants from './constants'
import platform from '../platform/platform'
import appStudioDataModel from './appStudioDataModel'
import clientSpecMap from '../siteMetadata/clientSpecMap'
import type {PS} from '@wix/document-services-types'

const UPDATE_PUBLIC_API = 'updatePublicAPI'

function updateApp(ps: PS) {
    const appExist = clientSpecMap.hasCSMEntry(ps, appStudioConstants.APP_BUILDER_PREVIEW_APP_ID)

    if (appExist) {
        platform.notifyApplication(ps, appStudioConstants.APP_BUILDER_PREVIEW_APP_ID, {
            // @ts-ignore
            eventType: UPDATE_PUBLIC_API,
            eventPayload: {
                widgets: appStudioDataModel.getAllSerializedWidgets(ps),
                definitions: appStudioDataModel.getAllSerializedCustomDefinitions(ps)
            }
        })
    }
}

export default {
    updateApp
}
