import type {AppDefinitionId, PS} from '@wix/document-services-types'
import platformEventsService from './platformEventsService'
import clientSpecMapDS from '../../siteMetadata/clientSpecMap'

const getEditorReadyCachedParams = ({firstInstall, silentInstallation, origin}) => ({
    firstInstall,
    silentInstallation,
    origin
})

const reloadApplication = (
    ps: PS,
    {
        appDefinitionId,
        cachedEditorReadyOptions,
        registeredEvents
    }: {appDefinitionId: AppDefinitionId; cachedEditorReadyOptions; registeredEvents},
    editorScriptResolver
) => {
    // Get applicationId from appDefintionId
    const clientSpecMapAppData = clientSpecMapDS.getAppDataByAppDefinitionId(ps, appDefinitionId)

    // Unregister old events
    platformEventsService.unRegisterAppFromEvents(appDefinitionId, registeredEvents)

    // Part from platform.loadEditorApps ()
    const appToLoad = editorScriptResolver(clientSpecMapAppData)
    const appToLoadMerged = {...appToLoad, ...getEditorReadyCachedParams(cachedEditorReadyOptions)}
    return appToLoadMerged
}

export default {
    reloadApplication
}
