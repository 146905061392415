const _ = require('lodash')

const SITE_COLORS_TO_THEME = {
    'value(site_1_1)': 'color_11',
    'value(site_1_2)': 'color_12',
    'value(site_1_3)': 'color_13',
    'value(site_1_4)': 'color_14',
    'value(site_1_5)': 'color_15',
    'value(site_2_1)': 'color_16',
    'value(site_2_2)': 'color_17',
    'value(site_2_3)': 'color_18',
    'value(site_2_4)': 'color_19',
    'value(site_2_5)': 'color_20',
    'value(site_3_1)': 'color_21',
    'value(site_3_2)': 'color_22',
    'value(site_3_3)': 'color_23',
    'value(site_3_4)': 'color_24',
    'value(site_3_5)': 'color_25',
    'value(site_4_1)': 'color_26',
    'value(site_4_2)': 'color_27',
    'value(site_4_3)': 'color_28',
    'value(site_4_4)': 'color_29',
    'value(site_4_5)': 'color_30',
    'value(site_5_1)': 'color_31',
    'value(site_5_2)': 'color_32',
    'value(site_5_3)': 'color_33',
    'value(site_5_4)': 'color_34',
    'value(site_5_5)': 'color_35'
}

/**
 * transforms theme-based color values from Stylable representation (site_1_1) to Viewer-compatible (color_1)
 * @param {*} color - string containing theme-based font
 * @param {boolean} shouldWrapWithVar - when true, we expect that Thunderbolt should not resolve the value, so we wrap it with var(--myValue)
 * @returns modified string
 */
const siteColorToThemeColor = (color, shouldWrapWithVar) => {
    return color.replaceAll(/value\(site_[0-9]{1,2}_[0-9]{1,2}\)/g, val => {
        const themeColor = SITE_COLORS_TO_THEME[val]
        if (shouldWrapWithVar) {
            return `rgb(var(--${themeColor}))`
        }
        return themeColor
    })
}

/**
 * transforms theme-based color values from Stylable representation value(color_15) to Viewer-compatible (color_1)
 * @param {*} color - string containing theme-based font
 * @param {boolean} shouldWrapWithVar - when true, we expect that Thunderbolt should not resolve the value, so we wrap it with var(--myValue)
 * @returns modified string
 */
const stylableValueToSiteColor = (color, shouldWrapWithVar) => {
    return color.replaceAll(/value\(color_\d+\)/g, val => {
        const colorVarMatches = val.match(/(color_\d+)\)/)
        const themeColor = colorVarMatches[1]
        if (shouldWrapWithVar) {
            return `rgb(var(--${themeColor}))`
        }
        return themeColor
    })
}

/**
 * @param {string} rgbaString
 * @returns {{color: Array<string>, alpha: string}} - color and alpha values extracted from rgbaString
 */
function extractColorAndAlphaFromRgba(rgbaString) {
    const rgbaRegex = /^rgba\((\d+,\s*\d+,\s*\d+),\s*([\d.]+)\)$/
    const matches = rgbaString.match(rgbaRegex)

    if (!matches) {
        return {color: [], alpha: ''}
    }

    const color = matches[1].split(',').map(c => c.trim())
    const alpha = matches[2]

    return {color, alpha}
}

function extractColorFromRgb(rgbString) {
    const rgbRegex = /^rgb\((\d+,\s*\d+,\s*\d+)\)$/
    const matches = rgbString.match(rgbRegex)

    if (!matches) {
        return ''
    }

    return matches[1].split(',').map(c => c.trim())
}

const rgbToHexString = rgb =>
    `#${rgb
        .map(channel => {
            const hex = Number(channel).toString(16)

            return hex.length === 1 ? `0${hex}` : hex
        })
        .join('')}`

/**
 * @param {import('../index').Property} property
 * @param {string} selector
 * @returns {{
 *  color?: import('../index').Property;
 *  alpha?: import('../index').Property;
 * }}
 */
const transformColorValues = (property, selector) => {
    const {prop, value, cssProp} = property
    if (_.includes(value, 'value(site')) {
        // text-shadows with theme colors are not resolved by Thunderbolt (unlike other color properties with solitary colors as values). We need to transform tex-shadows from "1px 1px value(site_1_1)" to "1px 1px var(--color_1)" format
        const shouldWrapWithVarFunction = cssProp === 'text-shadow'
        return {color: {prop, cssProp, value: siteColorToThemeColor(value, shouldWrapWithVarFunction)}}
    }

    if (_.includes(value, 'value(color_')) {
        const shouldWrapWithVarFunction = cssProp === 'text-shadow'
        return {color: {prop, cssProp, value: stylableValueToSiteColor(value, shouldWrapWithVarFunction)}}
    }

    if (_.startsWith(value, 'rgba')) {
        const {color, alpha} = extractColorAndAlphaFromRgba(value)

        return {
            color: {prop, cssProp, value: rgbToHexString(color)},
            alpha: {
                cssProp: 'alpha',
                prop: `alpha-${prop}`,
                value: alpha,
                absolutePropName: `alpha-${selector}-${prop}`
            }
        }
    }

    if (_.startsWith(value, 'rgb')) {
        const color = extractColorFromRgb(value)
        return {color: {prop, value: rgbToHexString(color), cssProp}}
    }
    return {}
}

/**
 * @param {import('../index').Property} property
 * @param {string} selector
 * @returns {Array<import('../index').Property>}
 */
function getUpdatedProperties(property, selector) {
    const {color, alpha} = transformColorValues(property, selector)

    if (color) {
        if (alpha) {
            return [color, alpha]
        }

        return [color]
    }

    return [property]
}

/**
 * @param {Array<import('../index').ParsedStyleItem>} styleItems
 * @returns {Array<import('../index').ParsedStyleItem>}
 */
function migrateColors(styleItems) {
    return styleItems.map(styleItem => {
        const {parsedStyle} = styleItem

        const updatedParsedStyle = Object.entries(parsedStyle).reduce(
            (acc, [selector, {properties, mobileProperties}]) => {
                /** @type {import('../index').ParsedStyleItem['parsedStyle'][0]['properties']} */
                const updatedProperties = []
                /** @type {import('../index').ParsedStyleItem['parsedStyle'][0]['mobileProperties']} */
                const updatedMobileProperties = []

                properties.forEach(property => {
                    updatedProperties.push(...getUpdatedProperties(property, selector))
                })

                mobileProperties.forEach(property => {
                    updatedMobileProperties.push(...getUpdatedProperties(property, selector))
                })

                return {
                    ...acc,
                    [selector]: {
                        ...acc[selector],
                        properties: updatedProperties,
                        mobileProperties: updatedMobileProperties
                    }
                }
            },
            parsedStyle
        )

        return {
            ...styleItem,
            parsedStyle: updatedParsedStyle
        }
    })
}

module.exports = {migrateColors, SITE_COLORS_TO_THEME}
