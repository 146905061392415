import _ from 'lodash'
import type {DocumentManager} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import {constants} from '@wix/document-manager-extensions'
import {cleanRef} from './utils/migrationUtil'
import type {PageMigrator} from '../dataMigrationRunner'

const getCleanedPages = (documentManager: DocumentManager, pages: string[]): string[] =>
    _.filter(pages, pageId => {
        const dataPointer = documentManager.pointers.getPointer(cleanRef(pageId), constants.DATA_TYPES.data)
        return !!documentManager.dal.get(dataPointer)
    })

const membersPageGroup = (value: any) => value.type === 'PagesGroupData' && value.groupName === 'members_pages_group'

const cleanPageGroup = (documentManager: DocumentManager, groupPointer: Pointer) => {
    const groupData = documentManager.dal.get(groupPointer)
    if (groupData.pages) {
        const {pages} = groupData
        const cleanPages = getCleanedPages(documentManager, pages)
        if (pages.length !== cleanPages.length) {
            const newValue = _.cloneDeep(groupData)
            newValue.pages = cleanPages
            documentManager.dal.set(groupPointer, newValue)
        }
    }
}

const migratePage = (documentManager: DocumentManager, pageId: string) => {
    const membersPageGroupPointers = documentManager.pointers.data.getDataItemsWithPredicate(membersPageGroup, pageId)
    _.forEach(membersPageGroupPointers, groupPointer => cleanPageGroup(documentManager, groupPointer))
}

const name = 'removeBrokenMemberPages'
const version = 1

export const removeBrokenMemberPages: PageMigrator = {
    migratePage,
    name,
    version
}
