import type {Pointer, PS} from '@wix/document-services-types'
import experiment from 'experiment-amd'
import _ from 'lodash'
import bi from '../../../bi/bi'
import biErrors from '../../../bi/errors.json'
import componentStyleAndSkinAPI from '../../../component/componentStylesAndSkinsAPI'
import componentDetectorAPI from '../../../componentDetectorAPI/componentDetectorAPI'
import constants from '../../../constants/constants'
import dataModel from '../../../dataModel/dataModel'
import variantsUtils from '../../../variants/variantsUtils'
import styleFixerUtils from './utils'
import type {DsFixer} from '../../dsDataFixers'

const {getComponentType, getDefaultSystemStyleForComp} = styleFixerUtils

const reportComponentWithoutDefaultStyleAndThrowError = (ps: PS, compPointer: Pointer, componentType: string) => {
    const pageId = ps.pointers.components.getPageOfComponent(compPointer).id
    bi.error(ps, biErrors.COMPONENT_WITHOUT_DEFAULT_STYLE, {
        componentType,
        pageId
    })

    throw new Error(`componentType: ${componentType} does not have default system style, pageId: ${pageId}`)
}

const fixComponentsWithoutDefaultStyle = (ps: PS) => (compPointer: Pointer) => {
    const componentType = getComponentType(ps, compPointer)
    const styleIdByCompType = getDefaultSystemStyleForComp(ps, compPointer)
    if (styleIdByCompType) {
        return componentStyleAndSkinAPI.style.connectToThemeStyle(ps, compPointer, styleIdByCompType, _.noop)
    }

    reportComponentWithoutDefaultStyleAndThrowError(ps, compPointer, componentType)
}

const withoutDefaultStyle = (ps: PS) => (compPointer: Pointer) => {
    const isVariantsStyle = dataModel.isComponentPointsToRefArray(ps, compPointer, constants.DATA_TYPES.theme)
    if (!isVariantsStyle) {
        return false
    }

    const nonScopedStylePointer = variantsUtils.getComponentDataPointerConsideringVariants(
        ps,
        compPointer,
        constants.DATA_TYPES.theme
    )
    return !nonScopedStylePointer || !ps.dal.full.isExist(nonScopedStylePointer)
}

const getCompsWithoutDefaultStyle = (ps: PS) =>
    _.uniqBy(
        componentDetectorAPI.getAllComponentsFromFull(ps, null!, withoutDefaultStyle(ps), constants.VIEW_MODES.DESKTOP),
        'id'
    )

const fixer: DsFixer = {
    exec(ps: PS) {
        if (experiment.isOpen('dm_fixMissingDefaultStyles')) {
            try {
                const compsWithoutDefaultStyle = getCompsWithoutDefaultStyle(ps)
                _.forEach(compsWithoutDefaultStyle, fixComponentsWithoutDefaultStyle(ps))
            } catch (e) {
                const err = new Error('Failed to migrate site add missing default style')
                ps.extensionAPI.logger.captureError(err, {
                    tags: {
                        addMissingDefaultStylesDataFixer: true
                    },
                    extras: {
                        originalError: e
                    }
                })
                throw e //should not get here
            }
        }
    },
    name: 'addMissingDefaultStylesDataFixer',
    version: 1
}
export default fixer
