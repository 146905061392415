import _ from 'lodash'
import constants from '../constants/constants'
import variantsUtils from './variantsUtils'
import relationsUtils from './relationsUtils'
import dataModel from '../dataModel/dataModel'
import {updateConsideringOverrides} from '../overrides/overrides'
import type {ComponentDefinitionType, CompRef, Pointer, PS} from '@wix/document-services-types'
import {asArray} from '@wix/document-manager-utils'
import responsiveLayout from '../responsiveLayout/responsiveLayout'

const {DATA_TYPES} = constants

const updateTransformationsData = (ps: PS, componentVariantsPointer: CompRef, transformation) => {
    if (_.isEmpty(transformation)) {
        console.warn('please pass non empty object, for full delete use transformations.remove api')
        return
    }
    const transformationsWithType = _.defaults({}, {type: 'TransformData'}, transformation)
    const transformationsId = updateConsideringOverrides(
        ps,
        componentVariantsPointer,
        transformationsWithType,
        DATA_TYPES.transformations
    )

    const containerPointer = ps.pointers.components.getParent(componentVariantsPointer)
    responsiveLayout.remeasureContainer(ps, containerPointer)

    return transformationsId
}

const getTransformationsData = (ps: PS, componentVariantsPointer: Pointer) =>
    variantsUtils.getComponentDataConsideringVariants(ps, componentVariantsPointer, DATA_TYPES.transformations)

const removeTransformationsData = (ps: PS, componentVariantsPointer: Pointer) => {
    variantsUtils.removeComponentDataConsideringVariants(ps, componentVariantsPointer, DATA_TYPES.transformations)

    const containerPointer = ps.pointers.components.getParent(componentVariantsPointer)
    responsiveLayout.remeasureContainer(ps, containerPointer)
}

const getAllHiddenComponentsByVariants = (ps: PS, variantsPointers) => {
    const variantsPointersArray = asArray(variantsPointers)
    const pageId = ps.pointers.data.getPageIdOfData(_.head(variantsPointersArray))

    const hiddenTransformationPredicate = relation => {
        const scopedValueId = dataModel.variantRelation.extractTo(ps, relation)
        if (scopedValueId) {
            const scopedValuePointer = ps.pointers.data.getItem(DATA_TYPES.transformations, scopedValueId, pageId)
            const scopedValue = ps.dal.get(scopedValuePointer)
            return _.isMatch(scopedValue, {hidden: true})
        }

        return false
    }

    const relationsPointers = relationsUtils.getRelationsByVariantsAndPredicate(
        ps,
        variantsPointersArray,
        DATA_TYPES.transformations,
        true,
        hiddenTransformationPredicate
    )

    return _.map(relationsPointers, relationPointer => {
        const relation = ps.dal.get(relationPointer)
        return relationsUtils.getComponentFromRelation(ps, relation, pageId)
    })
}

const getAllHiddenComponentsByDefault = (ps: PS, pagePointer: Pointer) => {
    if (!pagePointer) {
        throw new Error('no page pointer provided')
    }

    const viewMode = ps.pointers.components.getViewMode(pagePointer)
    const comps = ps.pointers.components.getAllComponentPointers(viewMode, pagePointer.id)

    const nonScopedHiddenComps = comps
        .map(x => {
            if (_.get(getTransformationsData(ps, x), ['hidden'])) {
                return x
            }
            return undefined
        })
        .filter(i => i)

    return nonScopedHiddenComps
}

const setPropsToIgnore = (ps: PS, compPointers: Pointer | Pointer[], ignoredTransformProps: string[]) => {
    const compPointersArray = asArray(compPointers)
    const compIds = compPointersArray.map(compPointer => compPointer.id)
    return ps.siteAPI.setCompsIgnoredTransformProps(compIds, ignoredTransformProps)
}

const setGlobalPropsToIgnore = (ps: PS, ignoredTransformProps: string[]): void =>
    ps.siteAPI.setGlobalIgnoredTransformProps(ignoredTransformProps)

const setPropsToIgnoreForType = (
    ps: PS,
    ignoredTransformPropsForType: Partial<Record<ComponentDefinitionType, string[]>>
) => ps.siteAPI.setTypeIgnoredTransformProps(ignoredTransformPropsForType)

export default {
    updateTransformationsData,
    getTransformationsData,
    removeTransformationsData,
    getAllHiddenComponentsByVariants,
    getAllHiddenComponentsByDefault,
    setPropsToIgnore,
    setGlobalPropsToIgnore,
    setPropsToIgnoreForType
}
