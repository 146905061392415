import _ from 'lodash'
import dataModel from '../../dataModel/dataModel'
import dsUtils from '../../utils/utils'

const MIN_HEIGHT_PER_ITEM = 25
const MIN_WIDTH = 100

const getItemsCount = (ps, compPointer) => {
    const compData = dataModel.getDataItem(ps, compPointer)
    const menuRef = _.isString(compData.menuRef) ? dsUtils.stripHashIfExists(compData.menuRef) : compData.menuRef.id
    const menuItems = dataModel.getDataItemById(ps, menuRef).items
    return _.filter(menuItems, 'isVisible').length
}

const getLayoutLimits = (ps, compPointer) => {
    const itemsCount = getItemsCount(ps, compPointer)

    return {
        minHeight: itemsCount * MIN_HEIGHT_PER_ITEM,
        minWidth: MIN_WIDTH
    }
}

export default {
    duplicatable: false,
    layoutLimits: getLayoutLimits,
    a11yConfigurable: false
}
