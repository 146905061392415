import type {Deprecation} from '../apiUtils/deprecation'
import type {PublicMethodUtils} from '@wix/document-services-types'

export default function (publicMethodUtils: PublicMethodUtils, deprecation: Deprecation) {
    return {
        methods: {
            deprecation: {
                setThrowOnDeprecation: publicMethodUtils.defineAction(deprecation.setThrowOnDeprecation)
            }
        }
    }
}
