import type {CreateExtArgs} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import {isComponentSuitableForNonRenderingStateByPointer} from '../utils/componentUtils'

export const boxSlideShowSlideMetaData = {
    enforceContainerChildLimitsByWidth: false,
    enforceContainerChildLimitsByHeight: false,
    canContain: ({dal}: CreateExtArgs, componentPointer: Pointer, potentialContainee: Pointer) =>
        isComponentSuitableForNonRenderingStateByPointer(dal, potentialContainee)
}
