/**
 * Get value of URL parameter by its name
 */
function getParameterFromQuery(query: string, name: string) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`)
    const results = regex.exec(query)
    return results?.[1] ? decodeURIComponent(results[1]).replace(/\+/g, ' ') : ''
}

/**
 * Get state of boolean URL parameter by its name
 */
function isParameterTrueInQuery(query: string, name: string): boolean {
    return getParameterFromQuery(query, name) === 'true'
}

export function getQueryUtils(window: Window) {
    return {
        getParameterFromQuery,
        isParameterTrueInQuery,
        getParameterByName: getParameterFromQuery.bind(null, window.location.search),
        isParameterTrue: isParameterTrueInQuery.bind(null, window.location.search)
    }
}
