const propertiesSourceType = {
    THEME: 'theme',
    VALUE: 'value'
}

/**
 * detects font_* and color_* substring in values
 * @param {string|object} value - font or color
 * @returns boolean
 */
const isThemeBasedValue = value => {
    return !!['color_', 'font_'].find(template => typeof value === 'string' && value.includes(template))
}

/**
 * @param {import('./index').ParsedStyleItem['parsedStyle'][0]['properties']} properties
 * @returns {import('./index').ParsedStyleItem['parsedStyle'][0]['propertiesSource']}
 */
function getPropertiesSource(properties) {
    return properties.reduce((updatedProperties, property) => {
        const {prop, value, absolutePropName} = property
        const propertySource = isThemeBasedValue(value) ? propertiesSourceType.THEME : propertiesSourceType.VALUE

        return {
            ...updatedProperties,
            [absolutePropName || prop]: propertySource
        }
    }, {})
}

/**
 * @param {Array<import('./index').ParsedStyleItem>} styleItems
 * @returns {Array<import('./index').ParsedStyleItem>}
 */
function createPropertiesSource(styleItems) {
    return styleItems.map(styleItem => {
        const {parsedStyle} = styleItem
        const updatedParsedStyle = Object.entries(parsedStyle).reduce(
            (updatedStyle, [selector, {properties, mobileProperties}]) => {
                return {
                    ...updatedStyle,
                    [selector]: {
                        ...updatedStyle[selector],
                        propertiesSource: {
                            ...getPropertiesSource(properties),
                            ...getPropertiesSource(mobileProperties)
                        }
                    }
                }
            },
            parsedStyle
        )

        return {
            ...styleItem,
            parsedStyle: updatedParsedStyle
        }
    })
}

module.exports = createPropertiesSource
