import type {ToolDefinition} from './aiTools'
import type {PS} from '@wix/document-services-types'
import dataModel from '../dataModel/dataModel'
import componentsDataModel from '../dataModel/componentsDataModel'
import pageData from '../page/pageData'
import _ from 'lodash'
import dataForProtoMapping from '@wix/document-services-json-schemas/dist/dataForProtoMapping.json'
import constants from '../constants/constants'

const {VIEW_MODES} = constants

enum LinkTypes {
    PageLink = 'PageLink',
    ExternalLink = 'ExternalLink',
    EmailLink = 'EmailLink',
    PhoneLink = 'PhoneLink'
}
const getLinkableFields = (ps, compPointer) => {
    const compData = dataModel.getDataItem(ps, compPointer)
    if (!compData?.type) {
        return []
    }
    return _.get(dataForProtoMapping, ['data', compData.type, 'links']) ?? []
}

const createLinkData = (linkType: any, linkData: any) => {
    switch (linkType) {
        case LinkTypes.PageLink:
            return {
                type: linkType,
                pageId: linkData.page_id,
                target: linkData.target ?? '_blank'
            }
        case LinkTypes.ExternalLink:
            return {
                type: linkType,
                url: linkData.url,
                target: linkData.target ?? '_blank'
            }
        case LinkTypes.EmailLink:
            return {
                type: linkType,
                recipient: linkData.recipient,
                subject: linkData.subject ?? ''
            }
        case LinkTypes.PhoneLink:
            return {
                type: linkType,
                phoneNumber: linkData.phone_number
            }
        default:
            throw new Error(`Link type ${linkType} is not supported`)
    }
}
const addLinkToComponent = (ps: PS, params: any) => {
    const {component_id, link_type, ...rest} = params
    const compPointer = ps.pointers.getPointer(component_id, VIEW_MODES.DESKTOP)
    const linkableField = getLinkableFields(ps, compPointer)[0]
    componentsDataModel.updateDataItem(
        ps,
        compPointer,
        {
            [linkableField]: createLinkData(link_type, rest)
        },
        true
    )
}

const validatePageLink = (ps: PS, linkData: Record<string, any>) => {
    const {link_type, page_id} = linkData
    if (!link_type || !page_id) {
        throw new Error('Missing required fields for Page Link')
    }
    const pageIds = pageData.getPagesList(ps)
    if (!_.includes(pageIds, page_id)) {
        throw new Error(`Page id ${page_id} does not exist on the site`)
    }
}

const validateExternalLink = (linkData: Record<string, any>) => {
    const {link_type, url} = linkData
    if (!link_type || !url) {
        throw new Error('Missing required fields for External Link')
    }
}

const validateEmailLink = (linkData: Record<string, any>) => {
    const {link_type, recipient} = linkData
    if (!link_type || !recipient) {
        throw new Error('Missing required fields for Email Link')
    }
}

const validatePhoneLink = (linkData: Record<string, any>) => {
    const {link_type, phone_number} = linkData
    if (!link_type || !phone_number) {
        throw new Error('Missing required fields for Phone Link')
    }
}

const validateLinkData = (ps: PS, linkType: string, linkData: Record<string, any>) => {
    switch (linkType) {
        case LinkTypes.PageLink:
            validatePageLink(ps, linkData)
            break
        case LinkTypes.ExternalLink:
            validateExternalLink(linkData)
            break
        case LinkTypes.EmailLink:
            validateEmailLink(linkData)
            break
        case LinkTypes.PhoneLink:
            validatePhoneLink(linkData)
            break
        default:
            throw new Error(`Link type ${linkType} is not supported`)
    }
}

const addLinkToComponentValidation = (ps: PS, params: any) => {
    const {component_id: compId, link_type} = params
    const compPointer = ps.pointers.getPointer(compId, VIEW_MODES.DESKTOP)
    const compStructure = ps.dal.get(compPointer)
    if (!compId || !compStructure) {
        return {
            success: false,
            validationMessage: `Component ${compId} doesn't exist`
        }
    }
    try {
        validateLinkData(ps, link_type, params)
    } catch (e: any) {
        return {
            success: false,
            validationMessage: e.message
        }
    }

    const linkableFields = getLinkableFields(ps, compPointer)
    if (linkableFields.length > 0) {
        return {success: true}
    }
    return {
        success: false,
        validationMessage: `Component ${compId} is not linkable`
    }
}

const addLinkToComponentResponse = (ps: PS, params: any) =>
    `Added link to component ${params.component_id} successfully`

export const addLinkToComponentTool: ToolDefinition = {
    name: 'addLinkToComponent',
    func: addLinkToComponent,
    validationFunc: addLinkToComponentValidation,
    responseFunc: addLinkToComponentResponse
}
