export type Schema = any

const styleTypeSchemas: Record<string, Schema> = {
    borderRadius: {
        type: 'object',
        additionalProperties: false,
        properties: {
            value: 'number',
            unit: {
                enum: ['px', '%']
            }
        }
    }
}

export function getStyleTypeSchema(typeName: string): Schema {
    // We don't have the types yet, so we return a schema that accepts anything for now
    return styleTypeSchemas[typeName] ?? true
}
