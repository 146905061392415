import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import metaDataUtils from '../../../componentsMetaData/metaDataUtils'
import * as jsonSchemas from '@wix/document-services-json-schemas'

const STYLES_PER_PAGE_VERSION = '1.0'
const DUPLICATE_STYLES_VERSION = '4.0'
const {componentTypeAliases} = jsonSchemas

const getStyleSettingsPointer = (ps: PS) =>
    ps.pointers.getInnerPointer(ps.pointers.data.getDataItemFromMaster('masterPage'), 'styleSettings')

const getStylesMigrationVersion = (ps: PS): string => {
    const styleSettings = ps.dal.get(getStyleSettingsPointer(ps)) || {}
    return styleSettings.stylesPerPage
}

const stylesPerPageVersionGreaterThenOrEquals = (ps: PS, ver2) => {
    const ver = getStylesMigrationVersion(ps)
    return parseInt(ver, 10) >= parseInt(ver2, 10)
}

const isSiteMigrated = (ps: PS) => stylesPerPageVersionGreaterThenOrEquals(ps, STYLES_PER_PAGE_VERSION)

const didRunDuplicateStylesFixer = (ps: PS) => stylesPerPageVersionGreaterThenOrEquals(ps, DUPLICATE_STYLES_VERSION)

const setStylesPerPageVersion = (ps: PS, version) => {
    if (!stylesPerPageVersionGreaterThenOrEquals(ps, version)) {
        ps.dal.set(getStyleSettingsPointer(ps), {
            stylesPerPage: version
        })
    }
}

const markSiteAsMigrated = (ps: PS) => setStylesPerPageVersion(ps, STYLES_PER_PAGE_VERSION)
const markSiteAsRunDuplicateStylesFixer = (ps: PS) => setStylesPerPageVersion(ps, DUPLICATE_STYLES_VERSION)

const setStylesMigrationVersion = (ps: PS, version) => ps.dal.set(getStyleSettingsPointer(ps), {stylesPerPage: version})

/**
 * This function is here because:
 * 1. When doing getComponentSkins, we go through an alias to get the skins
 * 2. When getting the defintion, we want to be consistent...
 * 3. For some reason PopupCloseTextButton exists in the aliases, probably for the purpose of skins, but it is not really an alias in this manner.
 * While it is a SiteButton, it has special meaning as a PopupCloseTextButton - so this function is here instead of having the metaDataUtils return the alias
 *
 * In the future, will need to think of a migration strategy for these components (besides for the popup) to migrate them all to the actual supported componentType
 * @param ps
 * @param compPointer
 * @return {*}
 */
const getComponentType = (ps: PS, compPointer) =>
    componentTypeAliases.getAlias(metaDataUtils.getComponentType(ps, compPointer))

/**
 * same comment as above...
 * some components are in the componentDefinitionsMap under the alias, some are under the original... :(
 * @param ps
 * @param compPointer
 * @return {*}
 */
const getDefinition = (ps: PS, compPointer) => {
    const compType = metaDataUtils.getComponentType(ps, compPointer)
    return (
        ps.extensionAPI.schemaAPI.getDefinition(compType) ||
        ps.extensionAPI.schemaAPI.getDefinition(componentTypeAliases.getAlias(compType))
    )
}

const getDefaultSystemStyleForComp = (ps: PS, compPointer) => {
    const definition = getDefinition(ps, compPointer)
    return definition ? _.keys(definition.styles).sort()[0] : definition
}

export default {
    isSiteMigrated,
    didRunDuplicateStylesFixer,
    markSiteAsMigrated,
    markSiteAsRunDuplicateStylesFixer,
    getStylesMigrationVersion,
    setStylesMigrationVersion,
    getDefaultSystemStyleForComp,
    getComponentType
}
