import type {PS} from '@wix/document-services-types'
import constants from '../../constants/constants'
import siteGapMap from '../../structure/siteGapMap'

export default {
    resizableSides: [constants.RESIZE_SIDES.BOTTOM],
    moveDirections(ps: PS) {
        const initialSiteGapMap = siteGapMap.getInitialGapMap(ps)
        return initialSiteGapMap && initialSiteGapMap.aboveHeader >= constants.SITE_SEGMENTS_GAP_THRESHOLD
            ? [constants.MOVE_DIRECTIONS.VERTICAL]
            : []
    },
    canBeFixedPosition: true,
    removable: false,
    duplicatable: false,
    containable: false,
    fullWidth: true,
    styleCanBeApplied(ps: PS) {
        const isStudioUserPointer = ps.pointers.general.getIsStudioUser()
        return ps.dal.get(isStudioUserPointer)
    },
    hiddenable: false,
    collapsible: false,
    mobileConversionConfig: {
        preserveAspectRatio: false,
        minHeight: 90,
        topRightMargin: [90, 90],
        descendantImageScaleFactor: 0.7,
        siteSegmentRole: 'SITE_HEADER',
        category: 'siteSegments'
    }
}
