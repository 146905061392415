import type {RefInfo, ResolvedReference, WixReferenceAnalysis} from '@wix/document-services-types'
import _ from 'lodash'

const REFERENCE_TO_CONTAINING_NAMESPACE_INDICATOR = '#'

const addReference = (extracted: ResolvedReference[], refInfo: RefInfo, ref: any, namespace: any) => {
    if (_.isString(ref)) {
        const original = refInfo.referencedMap
        extracted.push({
            refInfo,
            id: ref.startsWith('#') ? ref.slice(1) : ref,
            referencedMap: original === REFERENCE_TO_CONTAINING_NAMESPACE_INDICATOR ? namespace : original
        })
    }
}

const extractRefsFromData = (
    extracted: ResolvedReference[],
    refInfo: RefInfo,
    namespace: any,
    data: any,
    pathIndex: number
): void => {
    const {path} = refInfo
    while (data && pathIndex < path.length) {
        data = data[path[pathIndex++] as string]
        if (Array.isArray(data)) {
            for (const item of data) {
                extractRefsFromData(extracted, refInfo, namespace, item, pathIndex)
            }
            return
        }
    }
    addReference(extracted, refInfo, data, namespace)
}

const extractReferences = (
    references: WixReferenceAnalysis,
    namespace: string,
    dataTypeName: string,
    data: any
): readonly ResolvedReference[] => {
    const refInfos = references[namespace]?.[dataTypeName] || []
    const extracted = [] as ResolvedReference[]
    _.forOwn(refInfos, (refInfo: RefInfo) => {
        extractRefsFromData(extracted, refInfo, namespace, data, 0)
    })
    return extracted as readonly ResolvedReference[]
}

export {extractReferences}
