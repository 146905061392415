import type {PS, BaseFixer} from '@wix/document-services-types'
import _ from 'lodash'
import constants from '../constants/constants'
import fixers from './dsResponsiveDataFixers'
import experiment from 'experiment-amd'

function fix(ps: PS) {
    const dsDataFixersFedOpsTags = constants.INTERACTIONS.DOCUMENT_SERVICES_DATA_FIXERS

    ps.extensionAPI.logger.interactionStarted(dsDataFixersFedOpsTags.ALL)

    const shouldDisableFixersWithoutRerunFlag = experiment.isOpen('dm_onlyRerunWhenFixerRequiresReruns')
    const shouldRerunFixer = (fixer: BaseFixer) =>
        !fixer.disableFixerAfterFirstRun && (!shouldDisableFixersWithoutRerunFlag || fixer.fixerRequiresReruns)

    _.forEach(fixers, function (fixerData) {
        const fedopsTag = _.get(fixerData, ['config', 'fedopsTag'])
        ps.extensionAPI.logger.interactionStarted(fedopsTag)
        if (!shouldRerunFixer(fixerData.fixer)) {
            return
        }
        fixerData.fixer.exec(ps)
        ps.extensionAPI.logger.interactionEnded(fedopsTag)
    })
    ps.extensionAPI.logger.interactionEnded(dsDataFixersFedOpsTags.ALL)
}

export default {
    fix
}
