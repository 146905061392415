import type {CreateExtArgs, Extension, ExtensionAPI} from '@wix/document-manager-core'
import type {BlocksEntity, CompRef, Pointer, ScopeMetaDataTemplate} from '@wix/document-services-types'
import {deepClone} from '@wix/wix-immutable-proxy'
import _ from 'lodash'
import {VIEW_MODES} from '../../constants/constants'
import type {StructureMetaDataExtensionAPI} from '../structureMetaData'
import {
    findWidgetByPageId,
    getAllSerializedCustomDefinitions,
    getAllWidgets,
    getBlocksData,
    getContainingWidgetsMap,
    getData,
    getDescriptorPointerById,
    getEntityByPage,
    getRootAppWidgetByPage,
    getRootCompIdByPointer,
    isDashboardPage,
    isDefaultPage,
    WidgetData
} from './blocksDataModel'
import {getWidgetManifest} from './manifestUtils'
import type {BlocksAppDescriptor, WidgetInfo, WidgetDescriptorInfo} from './types'
import {createPublicDescriptor} from './widgetDescriptors'
import {getExtendedMetadataTemplate} from './blocksMetadata'
import {getAllDashboards} from './dashboards'
import {getPanelData} from './panels'
import {getPresetData} from './presets'

export interface BlocksAPI {
    getEntityByPage(pageRef: Pointer): BlocksEntity
    getWidgetsMapForBuild(): Record<string, WidgetInfo>
    getBlocksData(): any
    getBlocksAppDescriptor(): BlocksAppDescriptor
    isDashboardPage(pageRef: Pointer): boolean
    isDefaultPage(pageRef: Pointer): boolean
    getWidgetMetaDataTemplate(
        appDefinitionId: string,
        widgetPageId: string,
        includeCompData?: boolean
    ): ScopeMetaDataTemplate | undefined
}

export type BlocksExtensionAPI = ExtensionAPI & {
    blocks: BlocksAPI
}

const createExtension = (): Extension => {
    const createExtensionAPI = (createExtArgs: CreateExtArgs): BlocksExtensionAPI => {
        const getWidgetsMapForBuild = () => {
            const allWidgets = getAllWidgets(createExtArgs)

            const containingWidgetsMap = _.mapValues(getContainingWidgetsMap(createExtArgs), (widgetIds: string[]) =>
                widgetIds.map(widgetId => {
                    const pointer = getDescriptorPointerById(createExtArgs, widgetId)
                    return getData(createExtArgs, pointer).devCenterWidgetId
                })
            )

            const containingWidgetsPageIdsMap =
                createExtArgs.coreConfig.experimentInstance.isOpen('dm_passNestedWidgetsPageIdsMapForBuild') &&
                _.mapValues(getContainingWidgetsMap(createExtArgs), (widgetIds: string[]) =>
                    widgetIds.map(widgetId => {
                        const pointer = getDescriptorPointerById(createExtArgs, widgetId)
                        return getData(createExtArgs, pointer).rootCompId?.replace('#', '')
                    })
                )

            return _.reduce(
                allWidgets,
                (accumulator, widgetData) => {
                    const {presetsInfo, ...widgetInfo} = getWidgetInfo(widgetData)
                    accumulator[widgetInfo.widgetId] = {
                        ...widgetInfo,
                        nestedWidgetsIds: containingWidgetsMap[widgetData.pointer.id] || []
                    }

                    if (containingWidgetsPageIdsMap) {
                        accumulator[widgetInfo.widgetId].nestedWidgetsPageIds =
                            containingWidgetsPageIdsMap[widgetData.pointer.id] ?? []
                    }

                    if (createExtArgs.coreConfig.experimentInstance.isOpen('dm_sendPresetInfoInBlocksBuild')) {
                        accumulator[widgetInfo.widgetId].presetsInfo = presetsInfo
                    }

                    return accumulator
                },
                {}
            )
        }

        const getWidgetMetaDataTemplate = (
            appDefinitionId: string,
            widgetPageId: string,
            includeCompData: boolean = false
        ): ScopeMetaDataTemplate | undefined => {
            const {pointers, extensionAPI} = createExtArgs
            const pagePointer = pointers.structure.getPage(widgetPageId, VIEW_MODES.DESKTOP)
            const rootWidget = getRootAppWidgetByPage(createExtArgs, pagePointer)
            const widgetDescriptor = findWidgetByPageId(createExtArgs, widgetPageId)

            if (!rootWidget || !widgetDescriptor) {
                return
            }

            const metaDataTemplate = (
                extensionAPI as StructureMetaDataExtensionAPI
            ).structureMetaData.getMetaDataTemplate(rootWidget as CompRef)

            return getExtendedMetadataTemplate(
                createExtArgs,
                metaDataTemplate,
                appDefinitionId,
                widgetDescriptor,
                rootWidget,
                includeCompData
            )
        }

        function getWidgetInfo(widgetData: WidgetData): WidgetInfo {
            const {pointer, variations, name, presets, plugin} = widgetData

            const widgetRootCompId = getRootCompIdByPointer(createExtArgs, widgetData.pointer)
            if (!widgetRootCompId) {
                throw new Error('No widgetRootCompId')
            }

            const variationRootCompIds = _.map(variations, variationId => {
                const variationPointer = createExtArgs.pointers.data.getDataItemFromMaster(
                    _.replace(variationId, '#', '')
                )
                const variationRootCompId = getRootCompIdByPointer(createExtArgs, variationPointer)
                if (!variationRootCompId) {
                    throw new Error('No widgetRootCompId')
                }
                return variationRootCompId
            })
            const manifestInfo = getWidgetManifest(createExtArgs, pointer)
            const widgetPublicDescriptor = createPublicDescriptor(createExtArgs, pointer)

            return {
                widgetId: widgetRootCompId,
                variations: variationRootCompIds,
                manifestInfo,
                widgetPublicDescriptor,
                presetsInfo: (presets ?? []).map(presetId => getPresetData(createExtArgs, presetId)),
                name,
                plugin: deepClone(plugin)
            }
        }

        function getVariationsData(variationIds: string[]): any[] {
            return variationIds.map(variationId => {
                const variationPointer = createExtArgs.pointers.data.getDataItemFromMaster(
                    _.replace(variationId, '#', '')
                )
                return getData(createExtArgs, variationPointer)
            })
        }

        function getWidgetDescriptorInfo(widgetData: WidgetData): WidgetDescriptorInfo {
            const widgetInfo = getWidgetInfo(widgetData)
            const {devCenterWidgetId, kind, defaultSize, widgetApi} = deepClone(
                getData(createExtArgs, widgetData.pointer)
            )

            const variationsData = getVariationsData(widgetInfo.variations)
            const panelIds = widgetData.panels ?? []

            return {
                ...widgetInfo,
                variationsData,
                widgetApi,
                devCenterWidgetId,
                defaultSize,
                kind,
                panels: panelIds.map(panelId => getPanelData(createExtArgs, panelId))
            }
        }

        function getBlocksAppDescriptor(): BlocksAppDescriptor {
            const allWidgets = getAllWidgets(createExtArgs)

            const widgetsMap = allWidgets.reduce((accumulator, widgetData) => {
                const widgetDescriptorInfo = getWidgetDescriptorInfo(widgetData)
                accumulator[widgetDescriptorInfo.widgetId] = widgetDescriptorInfo
                return accumulator
            }, {})

            const dashboards = getAllDashboards(createExtArgs)

            const customDefinitions = getAllSerializedCustomDefinitions(createExtArgs)

            return {
                widgetsMap,
                dashboards,
                customDefinitions
            }
        }

        return {
            blocks: {
                getEntityByPage: (pageRef: Pointer) => getEntityByPage(createExtArgs, pageRef),
                getBlocksData: () => getBlocksData(createExtArgs),
                isDashboardPage: (pageRef: Pointer) => isDashboardPage(createExtArgs, pageRef),
                isDefaultPage: (pageRef: Pointer) => isDefaultPage(createExtArgs, pageRef),
                getWidgetsMapForBuild,
                getBlocksAppDescriptor,
                getWidgetMetaDataTemplate
            }
        }
    }

    return {
        name: 'blocks',
        createExtensionAPI,
        dependencies: new Set(['structure', 'dataModel', 'page', 'nicknames', 'features', 'actionsAndBehaviors'])
    }
}

export {createExtension}
