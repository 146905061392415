import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import dataModel from '../../dataModel/dataModel'
import constants from '../../constants/constants'
import baseInputMetadata from './baseInputMetadata'

const isWithLabel = (ps: PS, componentPointer: Pointer) => {
    const compData = dataModel.getDataItem(ps, componentPointer)
    return !!compData.label
}

const metadata = {
    mobileConversionConfig: {
        category: 'graphic',
        inlineWhenGrouped: true
    },
    resizableSides(ps: PS, componentPointer: Pointer) {
        return isWithLabel(ps, componentPointer) ? [constants.RESIZE_SIDES.LEFT, constants.RESIZE_SIDES.RIGHT] : []
    },
    layoutLimits(ps: PS, componentPointer: Pointer) {
        const layoutLimits = {
            minWidth: 60
        }
        if (!isWithLabel(ps, componentPointer)) {
            _.assign(layoutLimits, {
                minWidth: 12,
                minHeight: 12,
                aspectRatio: 1
            })
        }
        return layoutLimits
    }
}

export default _.assign({}, baseInputMetadata, metadata)
