const schema = {
    screenIn: {
        groups: ['animation', 'entrance']
    },
    inlinePopupIn: {
        groups: ['animation', 'entrance']
    },
    inlinePopupOut: {
        groups: ['exit', 'animation']
    },
    bgScrub: {
        groups: ['animation', 'background']
    },
    velocityScrub: {
        groups: ['animation', 'background']
    },
    pageTransition: {
        groups: ['transition', 'pageTransition']
    },
    exit: {
        groups: ['exit', 'animation']
    },
    load: {
        groups: ['transition', 'pageTransition']
    },
    modeIn: {
        groups: ['animation', 'entrance']
    },
    modeOut: {
        groups: ['animation', 'exit']
    },
    modeChange: {
        groups: ['animation', 'entrance'] //todo Shimi_Liderman 06/03/2016 19:29 change this to real groups
    },
    scrollScrub: {
        groups: ['scrub']
    },
    backToTopIn: {
        groups: ['animation', 'entrance']
    }
}

Object.freeze(schema)

function getSchema() {
    return schema
}

export default {
    getSchema
}
