import {ReportableError} from '@wix/document-manager-utils'

function buildAbortedError() {
    const error = new ReportableError({
        message: 'Request aborted by browser',
        errorType: 'RequestAbortError'
    })
    error.name = 'RequestAbortError'
    return error
}

function buildServerError(status: string, responseText: string) {
    return new ReportableError({
        message: `Server Error\nStatus: ${status}\nResponse Text: ${responseText}`,
        errorType: 'BuildServerError'
    })
}

export default {
    buildAbortedError,
    buildServerError
}
