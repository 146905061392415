/* eslint-disable promise/prefer-await-to-then */
import {ReportableError} from '@wix/document-manager-utils'
import type {AppDefinitionId, Callback, PS} from '@wix/document-services-types'
import {platformInit, tpa} from '@wix/santa-ds-libs'
import _ from 'lodash'
import * as rpc from 'pm-rpc'
import bi from '../../bi/bi'
import biEvents from '../../bi/events.json'
import documentModeInfo from '../../documentMode/documentModeInfo'
import siteMetadata from '../../siteMetadata/siteMetadata'
import clientSpecMapService from '../../tpa/services/clientSpecMapService'
import constants from '../common/constants'
import messageFormatter from '../core/messageFormatter'
import workerFactory from '../core/workerFactory'
import apiCallBiService from './apiCallBiService'
import platformAppDataGetter from './platformAppDataGetter'
import reloadAppService from './reloadAppService'
import sdkAPIService from './sdkAPIService'
import experiment from 'experiment'
import * as platformEvents from '@wix/platform-editor-sdk/lib/platformEvents.min'
import notificationService from './notificationService'

let _appsContainer: Worker
let editorSDKrpcSet = false
let callbackQueue: Record<string, Callback[]> = {}
let messageQueue: unknown[] = []
let applicationMessagesQueue: Record<string, any[]> = {}
let isWorkerReadyToReceiveMessages = false
let isAppsCompleted = false
let isAppReadyToReceiveMessages: Record<string, boolean> = {}
let isAddAppStarted: Record<string, boolean> = {}
let platformInitiated = false
const appsToTriggerEvent = new Set<string>()

function _reset() {
    _appsContainer = undefined
    editorSDKrpcSet = false
    callbackQueue = {}
    messageQueue = []
    applicationMessagesQueue = {}
    isWorkerReadyToReceiveMessages = false
    isAppsCompleted = false
    isAppReadyToReceiveMessages = {}
    isAddAppStarted = {}
    platformInitiated = false
    appsToTriggerEvent.clear()
}

const canAppTriggerEvent = (appDefId: string) => appsToTriggerEvent.has(appDefId)
const addAppToTriggerEventList = (appDefId: string) => appsToTriggerEvent.add(appDefId)

const handleSendMessageToWorker = (appDefId: string, message: string) => {
    if (experiment.isOpen('dm_whiteListForAppsToTriggerPlatformWorker')) {
        if (canAppTriggerEvent(appDefId)) {
            sendMessageToWorker(message)
        }
    } else {
        sendMessageToWorker(message)
    }
}

function didMessageArriveFromWorker(event) {
    return event.target === _appsContainer
}

const handleAddAppCompleted = event => {
    const {appDefinitionId} = event.data
    isAppReadyToReceiveMessages[appDefinitionId] = true
    _.forEach(applicationMessagesQueue[appDefinitionId], (message: string) =>
        handleSendMessageToWorker(appDefinitionId, message)
    )
    delete applicationMessagesQueue[appDefinitionId]
    executeCallbacks([constants.MessageTypes.ADD_APP_COMPLETED, appDefinitionId], event.data, true)
}

const handleAllAppsCompleted = success => {
    if (success === true) {
        isAppsCompleted = true
        executeCallbacks(constants.MessageTypes.ADD_ALL_APPS_COMPLETED)
    }
}

const handleSetAppExportedApis = (ps: PS, event) => {
    platformAppDataGetter.setAppExportedAPIs(ps, event.data.appDefinitionId, event.data.apisNames)
    isAppReadyToReceiveMessages[event.data.appDefinitionId] = true
}

const setAppCompletedCallback = (appDefinitionId: AppDefinitionId, cb) => {
    registerCallback([constants.MessageTypes.ADD_APP_COMPLETED, appDefinitionId], cb)
}

function reloadPlatformApplication(
    ps: PS,
    {
        appDefinitionId,
        cachedEditorReadyOptions,
        registeredEvents
    }: {appDefinitionId: AppDefinitionId; cachedEditorReadyOptions; registeredEvents}
) {
    const editorScriptHot = ps.siteAPI.getQueryParam('editorScriptHot') === 'true'
    if (editorScriptHot) {
        const appToAdd = reloadAppService.reloadApplication(
            ps,
            {
                appDefinitionId,
                cachedEditorReadyOptions: JSON.parse(cachedEditorReadyOptions),
                registeredEvents: registeredEvents.split(',')
            },
            enhanceAppWithEditorScript(ps)
        )
        addApp(ps, appToAdd, _.noop, 'reloadPlatformApplication')
    }
}

function handleMessageFromWorker(ps: PS, event) {
    if (didMessageArriveFromWorker(event)) {
        switch (event.data.type) {
            case constants.MessageTypes.RELOAD_PLATFORM_APPLICATION:
                reloadPlatformApplication(ps, event.data)
                break
            case constants.MessageTypes.SDK_READY:
                isWorkerReadyToReceiveMessages = true
                _.forEach(messageQueue, sendMessageToWorker)
                messageQueue.length = 0
                break
            case constants.MessageTypes.SET_MANIFEST:
                platformAppDataGetter.setManifest(ps, event.data.appDefinitionId, event.data.manifest)
                if (event.data.firstInstall === false) {
                    ps.extensionAPI.undoRedo.clear()
                }
                if (event.data.origin === 'reloadManifest') {
                    ps.setOperationsQueue.runSetOperation(() => {
                        ps.extensionAPI.platformSharedState.notifyManifestWasChanged(event.data.appDefinitionId)
                    })
                }
                break
            case constants.MessageTypes.SET_APP_EXPORTED_APIS:
                handleSetAppExportedApis(ps, event)
                break
            case constants.MessageTypes.ADD_APP_COMPLETED: {
                handleAddAppCompleted(event)
                break
            }
            case constants.MessageTypes.ADD_ALL_APPS_COMPLETED: {
                handleAllAppsCompleted(event.data.success)
                break
            }
            case constants.MessageTypes.GET_INSTALLED_APPS:
                const installedApps = getInstalledApps(ps)
                if (event.ports?.[0] && _.isFunction(event.ports[0].postMessage)) {
                    event.ports[0].postMessage(installedApps.map((appData: any) => appData.appDefinitionId))
                }
                break
            case constants.MessageTypes.GET_IS_PLATFORM_APP_INSTALLED:
                const isPlatformAppInstalled = platformAppDataGetter.isPlatformAppInstalled(
                    ps,
                    event.data.appDefinitionId
                )
                event.ports?.[0]?.postMessage({isAppInstalled: isPlatformAppInstalled})
                break
            default:
                break
        }
    }
}

function reportWorkerCreation(ps: PS) {
    if (window.performance?.measure && window.performance.mark) {
        window.performance.mark('platformWorkerCreated')
        const timeForWorker = window.performance.measure(
            'timeUntilWorkerReady',
            'documentServicesLoaded',
            'platformWorkerCreated'
        )
        bi.event(ps, biEvents.PLATFORM_WORKER_LOADED, {
            dsOrigin: ps.config.origin,
            viewerName: ps.runtimeConfig.viewerName,
            ts: Math.round(_.get(timeForWorker, 'duration'))
        })
    }
}

function init(ps: PS, apiOverride, config?, overrides?) {
    if (_appsContainer) {
        clearWorker()
    }

    platformInitiated = true
    const workerPath = overrides?.workerPath
    const cookiesOverride = overrides?.cookiesOverride
    let appsContainerUrl = workerPath || workerFactory.getAppsContainerUrl(ps)
    if (!workerPath && workerFactory.isLocalWorker(appsContainerUrl)) {
        appsContainerUrl = workerFactory.createWorkerBlobUrl(appsContainerUrl)
    }
    _appsContainer = new Worker(appsContainerUrl)
    setEditorSDKRpc(ps, apiOverride)
    _appsContainer.postMessage(
        messageFormatter.initialize(
            workerFactory.getAppsWorkerInitializeParams(ps, config, cookiesOverride),
            getInitData(ps)
        )
    )

    reportWorkerCreation(ps)

    workerFactory.addWorkerHandler(handleMessageFromWorker.bind(this, ps), _appsContainer)
    return _appsContainer
}

function setEditorSDKRpc(ps: PS, apiOverride) {
    if (editorSDKrpcSet) {
        return
    }
    const onApiCall = function (message) {
        apiCallBiService.reportAPICallBI(ps, message)
        apiCallBiService.reportAPICallFedOps(ps, message)
        apiCallBiService.reportAPICallBIHybridModeMethods(ps, message)
        return performance.now()
    }

    const onApiSettled = function (message) {
        apiCallBiService.reportAPICallDuration(ps, message)
    }

    rpc.api.set(
        constants.AppIds.EDITOR,
        sdkAPIService.getAPIForSDK(apiOverride),
        {
            onApiCall,
            onApiSettled
        },
        // @ts-expect-error
        [_appsContainer]
    )

    editorSDKrpcSet = true
}

function getEditorSdkUrl(ps: PS) {
    return workerFactory.getEditorSdkUrl(ps)
}

function clearWorker() {
    if (_appsContainer.terminate) {
        _appsContainer.terminate()
    }

    messageQueue.length = 0
    callbackQueue = {}
    applicationMessagesQueue = {}
    isAppReadyToReceiveMessages = {}
    isAddAppStarted = {}
    isWorkerReadyToReceiveMessages = false
}

function sendMessageToWorker(message) {
    _appsContainer.postMessage(message)
}

function sendMessageToWorkerWhenPossible(message) {
    if (isWorkerReadyToReceiveMessages) {
        sendMessageToWorker(message)
    } else {
        messageQueue.push(message)
    }
}

function sendAppMessageToWorkerWhenPossible(ps: PS, message, appDefinitionId: AppDefinitionId) {
    if (clientSpecMapService.isAppUnused(ps, appDefinitionId)) {
        return
    }
    if (isWorkerReadyToReceiveMessages && isAppReadyToReceiveMessages[appDefinitionId]) {
        handleSendMessageToWorker(appDefinitionId, message)
    } else {
        applicationMessagesQueue[appDefinitionId] = applicationMessagesQueue[appDefinitionId] || []
        applicationMessagesQueue[appDefinitionId].push(message)
    }
}

function triggerEvent(ps: PS, appDefinitionId: AppDefinitionId, options) {
    const message = messageFormatter.triggerEvent(appDefinitionId, options)
    sendAppMessageToWorkerWhenPossible(ps, message, appDefinitionId)
}

const getInitData = (ps: PS) => ({
    languageCode: siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.LANGUAGE_CODE) || 'en',
    viewMode: documentModeInfo.getViewMode(ps),
    metaSiteId: siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.META_SITE_ID),
    userId: siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.USER_INFO)?.userId,
    editorSessionId: siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.EDITOR_SESSION_ID),
    syncWorkerAfterUndoRedo: true
})

function addApps(ps: PS, apps) {
    _.forEach(apps, appData => {
        isAddAppStarted[appData.appDefinitionId] = true
        appsToTriggerEvent.add(appData.appDefinitionId)
        const enhancedAppData = enhanceAppWithEditorScript(ps)(appData)
        addAppCallbackWrapper(ps, enhancedAppData)
    })
    sendMessageToWorkerWhenPossible(messageFormatter.addApps(apps, getInitData(ps)))
}

function addApp(ps: PS, appData, callback?, options: any = {}) {
    if (
        !appData?.instance &&
        ![constants.APPS.DATA_BINDING.appDefId, constants.APPS.DYNAMIC_PAGES.appDefId].includes(appData.appDefinitionId)
    ) {
        const error = new ReportableError({
            message: `Instance missing for appDefinitionId: ${appData.appDefinitionId}`,
            errorType: 'TPAInstanceMissing',
            extras: {
                appDefinitionId: appData.appDefinitionId,
                appData,
                origin: options.origin,
                internalOrigin: options.internalOrigin
            }
        })
        _.set(error, ['extras', 'stackTrace'], error.stack)
        ps.extensionAPI.logger.captureError(error)
    }
    isAddAppStarted[appData.appDefinitionId] = true
    addAppToTriggerEventList(appData.appDefinitionId)

    const enhancedAppData = enhanceAppWithEditorScript(ps)(appData)
    addAppCallbackWrapper(ps, enhancedAppData, callback)
    sendMessageToWorkerWhenPossible(messageFormatter.addApp(enhancedAppData, getInitData(ps)))
}

function addAppCallbackWrapper(ps: PS, appData: {appDefinitionId: AppDefinitionId; firstInstall: boolean}, callback?) {
    const originalCallback = _.isFunction(callback) ? callback : _.noop
    setAppCompletedCallback(
        appData.appDefinitionId,
        appData.firstInstall
            ? eventData =>
                  notifyAppInstalled(ps, appData).then(() => {
                      originalCallback(eventData)
                  })
            : originalCallback
    )
}

function loadManifest(ps: PS, appData) {
    sendMessageToWorkerWhenPossible(messageFormatter.loadManifest(appData, getInitData(ps)))
}

function notifyAppInstalled(ps: PS, {appDefinitionId, biData = {}}: {appDefinitionId: AppDefinitionId; biData?}) {
    return notifyAppsAsync(ps, constants.NotifyMethods.APP_INSTALLED, {appDefinitionId, biData})
}

function notifyAppUpdated(ps: PS, appDefinitionId: AppDefinitionId, options) {
    const updateEvent = platformEvents.factory.appUpdateCompleted({appDefinitionId})
    notificationService.notifyAppsOnCustomEvent(ps, {
        ...updateEvent
    })
    return notifyAppAsyncIfExists(ps, appDefinitionId, constants.NotifyMethods.APP_UPDATED, options)
}
function notifyAppRefreshed(ps: PS, appDefinitionId: AppDefinitionId) {
    const updateEvent = platformEvents.factory.appRefreshCompleted({appDefinitionId})
    notificationService.notifyAppsOnCustomEvent(ps, updateEvent)
}

function executeCallbacks(path: string | string[], payload = undefined, shouldClear = false) {
    path = _.isArray(path) ? path : [path]
    const callbacks = _.get(callbackQueue, [...path], [])
    callbacks.forEach(callback => {
        if (_.isFunction(callback)) {
            callback(payload)
        }
    })
    if (shouldClear) {
        _.unset(callbackQueue, [...path])
    }
}

function registerCallback(path: string | string[], callback) {
    path = _.isArray(path) ? path : [path]
    const callbacksArr = _.get(callbackQueue, [...path])
    if (callbacksArr) {
        callbacksArr.push(callback)
    } else {
        _.set(callbackQueue, [...path], [callback])
    }
}

async function removeApp(ps: PS, appDefinitionId: AppDefinitionId) {
    await notifyAppAsyncIfExists(ps, appDefinitionId, constants.NotifyMethods.REMOVE_APP, {})
    await notifyAppsAsync(ps, constants.NotifyMethods.REMOVE_APP_COMPLETED, {appDefinitionId})
    isAppReadyToReceiveMessages[appDefinitionId] = false
    isAddAppStarted[appDefinitionId] = false

    if (experiment.isOpen('dm_platformAddSubscribeOnEventFunctionality')) {
        const removeAppCompletedEvent = platformEvents.factory.removeAppCompleted({appDefinitionId})
        notificationService.notifyApplication(ps, appDefinitionId, removeAppCompletedEvent)
    }

    executeCallbacks(constants.NotifyMethods.REMOVE_APP_COMPLETED, appDefinitionId)
    appsToTriggerEvent.delete(appDefinitionId)
}

function notifyBeforeRemoveApp(ps: PS, appDefinitionId: AppDefinitionId) {
    return notifyAppAsyncIfExists(ps, appDefinitionId, constants.NotifyMethods.BEFORE_REMOVE_APP, {})
}

function notifyMigrate(ps: PS, appDefinitionId: AppDefinitionId, payload) {
    return notifyAppAsyncIfExists(ps, appDefinitionId, constants.NotifyMethods.MIGRATE, payload)
}

function isInitiated() {
    return platformInitiated
}

function registerToAppsCompleted(callback: Callback) {
    if (isAppsCompleted) {
        callback()
    }
    registerCallback(constants.MessageTypes.ADD_ALL_APPS_COMPLETED, callback)
}

function registerToAppRemoved(callback: Callback) {
    registerCallback(constants.NotifyMethods.REMOVE_APP_COMPLETED, callback)
}

function notifyAppsAsync(ps: PS, methodName: string, methodPayload) {
    const appDefIds = getInstalledApps(ps).map((appData: any) => appData.appDefinitionId)
    return Promise.all(
        appDefIds.reduce(
            (acc, appDefinitionId) => [...acc, notifyAppAsync(ps, appDefinitionId, methodName, methodPayload)],
            []
        )
    )
}

function notifyAppAsyncIfExists(ps: PS, appDefinitionId: AppDefinitionId, methodName: string, methodPayload) {
    const appData = platformAppDataGetter.getAppDataByAppDefId(ps, appDefinitionId)
    if (appData && shouldNotifyApp(ps, appData)) {
        return notifyAppAsync(ps, appDefinitionId, methodName, methodPayload)
    }
}

function notifyAppAsync(ps: PS, appDefinitionId: AppDefinitionId, methodName: string, methodPayload) {
    return new Promise(resolve => {
        const handleReject = error => {
            ps.extensionAPI.logger.captureError(
                new ReportableError({
                    message: `Failed notifyAppAsync ${appDefinitionId}`,
                    errorType: 'NotifyAppAsyncError',
                    extras: {
                        appDefinitionId,
                        methodName,
                        methodPayload,
                        error: error?.message ?? error
                    }
                })
            )
            resolve(undefined)
        }
        const notify = () => {
            const apiNamePointer = ps.pointers.platform.appEditorApiNamePointer(appDefinitionId)
            const apiName = ps.dal.get(apiNamePointer)
            if (!apiName) {
                return resolve(undefined)
            }
            return requestAPIFromWorker(ps, apiName)
                .then(editorApi => editorApi[methodName](methodPayload))
                .then(resolve)
                .catch(handleReject)
        }
        if (isAppReadyToReceiveMessages[appDefinitionId] && isAddAppStarted[appDefinitionId]) {
            notify()
        } else {
            setAppCompletedCallback(appDefinitionId, notify)
        }
    })
}

function shouldNotifyApp(ps: PS, appData) {
    return (
        appData.appDefinitionId &&
        isAddAppStarted[appData.appDefinitionId] &&
        clientSpecMapService.isAppActive(ps, appData) &&
        clientSpecMapService.hasEditorPlatformPart(appData) &&
        !clientSpecMapService.isDashboardAppOnly(appData)
    )
}

function getInstalledApps(ps: PS) {
    return clientSpecMapService.getAppsDataWithPredicate(ps, csm =>
        Object.values(csm).filter(appData => shouldNotifyApp(ps, appData))
    )
}

function requestAPIFromWorker(ps: PS, apiName: string) {
    return rpc.api.request(apiName, {target: _appsContainer})
}

function enhanceAppWithEditorScript(ps: PS) {
    return function resolveEditorScript(app) {
        const currentUrl = ps.siteAPI.getCurrentUrl()
        const serviceTopology = ps.dal.get(ps.pointers.general.getServiceTopology())
        const editorScriptOverrideUrl = tpa.common.utils.getTpaOverrideMap(currentUrl, 'editorScriptUrlOverride')[
            app.appDefinitionId
        ]
        const withEditorScript = _.get(app, 'appFields.platform.editorScriptUrl')
        if (editorScriptOverrideUrl && withEditorScript) {
            app = _.merge({}, app, {
                appFields: {
                    platform: {
                        editorScriptUrl: editorScriptOverrideUrl
                    }
                }
            })
        }
        return platformInit.specMapUtils.resolveEditorScriptUrl(app, {clientSpec: app, serviceTopology})
    }
}

function getNotInitError(ps: PS, additionalMessage) {
    const err = new Error(`Worker Service is not initiated ${additionalMessage}`)
    ps.extensionAPI.logger.captureError(err, {
        tags: {
            workerNotInit: true
        },
        extras: {
            originalError: err
        }
    })
    return err
}

function triggerEventsForAppsSync(ps: PS, options) {
    const appDefIds = getInstalledApps(ps).map((appData: any) => appData.appDefinitionId)
    appDefIds.forEach(appDefId => triggerEvent(ps, appDefId, options))
}

function notifyGrantApp(ps: PS, appDefinitionId: AppDefinitionId) {
    isAddAppStarted[appDefinitionId] = true
    triggerEventsForAppsSync(
        ps,
        platformEvents.factory.grantApp({
            appDefinitionId
        })
    )
}

function notifyRevokeApp(ps: PS, appDefinitionId: AppDefinitionId) {
    isAddAppStarted[appDefinitionId] = false
    triggerEventsForAppsSync(
        ps,
        platformEvents.factory.revokeApp({
            appDefinitionId
        })
    )
}

function isAppStartedOnWorker(appDefinitionId: AppDefinitionId) {
    return isAddAppStarted[appDefinitionId] ?? false
}

export default {
    _reset,
    init,
    getNotInitError,
    triggerEvent,
    addApp,
    addApps,
    removeApp,
    loadManifest,
    notifyAppInstalled,
    notifyAppUpdated,
    notifyMigrate,
    notifyBeforeRemoveApp,
    isInitiated,
    registerToAppsCompleted,
    registerToAppRemoved,
    requestAPIFromWorker,
    getEditorSdkUrl,
    enhanceAppWithEditorScript,
    notifyGrantApp,
    notifyRevokeApp,
    notifyAppRefreshed,
    isAppStartedOnWorker,
    handleAllAppsCompleted,
    canAppTriggerEvent,
    addAppToTriggerEventList
}
