import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import dsUtils from '../../utils/utils'
import type {DsFixer} from '../dsDataFixers'

const exec = (ps: PS) => {
    const translatedPagesData = _(ps.siteAPI.getPageDataTranslations())
        .flatMap(({languageCode, pageId}) => {
            const pointer = ps.pointers.multilingualTranslations.translationDataItem('masterPage', languageCode, pageId)
            if (!ps.dal.full.isExist(pointer)) {
                return []
            }

            return _.map(['desktop', 'mobile'], viewMode => ({
                pointer,
                pageId,
                viewMode
            }))
        })
        .value()

    translatedPagesData.forEach(({pointer, pageId, viewMode}) => {
        const translatedBgRefPointer = ps.pointers.page.getInnerBackgroundRefPointer(pointer, viewMode)
        const translatedBgRef = ps.dal.full.get(translatedBgRefPointer)
        if (!translatedBgRef) {
            return
        }

        const translatedBgPointer = ps.pointers.data.getDataItem(dsUtils.stripHashIfExists(translatedBgRef), pageId)
        if (translatedBgRef && ps.dal.full.isExist(translatedBgPointer)) {
            return
        }

        const originalPageDataPointer = ps.pointers.data.getDataItem(pageId, 'masterPage')
        const originalBgRefPointer = ps.pointers.page.getInnerBackgroundRefPointer(originalPageDataPointer, viewMode)
        const originalBgRef = ps.dal.full.get(originalBgRefPointer)
        ps.dal.full.set(translatedBgRefPointer, originalBgRef)
    })
}

const fixer: DsFixer = {
    exec,
    name: 'pageBackgroundTranslationFixer',
    version: 1
}
export default fixer
