import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import actionsAndBehaviors from '../../actionsAndBehaviors/actionsAndBehaviors'
import constants from '../../constants/constants'
import dataModel from '../../dataModel/dataModel'
import connections from '../../connections/connections'
import tpaConfigurationUtils from '../utils/tpaConfigurationUtils'
import popupUtils from '../../page/popupUtils'
import componentCode from '../../component/componentCode'
import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'
import tpaSectionMetaData from '../../componentsMetaData/components/tpaSectionMetaData'
import appStudioDataModel from '../../appStudio/appStudioDataModel'
import platformAppConfiguration from '@wix/js-platform-apps-configuration/build/platform-apps-editor'
import platform from '../../platform/platform'
import typeDefUtils from '../utils/typeDefUtils'
import componentsMetaData from '../../componentsMetaData/componentsMetaData'
import dsUtils from '../../utils/utils'
import {getFullSdkType} from './sdkTypeMap'

const isDatabindingApp = (appId: string) => appId === 'dataBinding'

function _getCompatibleCompSdkType(ps: PS, compType: string, widgetId: string) {
    const platformUtilsSdkType = getFullSdkType(compType, widgetId)
    const registrySdkType = ps.extensionAPI.componentsRegistry.getComponentSDKType(compType)
    return registrySdkType ? `$w.${registrySdkType}` : platformUtilsSdkType
}

function _getWidgetIdIfExist(compData) {
    if (!_.has(compData, ['applicationId']) || !_.has(compData, ['widgetId'])) {
        return
    }
    return compData.widgetId
}

function _getPageNicknamesToTypeMap(ps: PS, pageId: string) {
    const components = ps.siteAPI.getComponentsByPageIdForWixCode(pageId)
    return _.transform(
        components,
        (result, comp, compId) => {
            // @ts-expect-error
            const compPointer = componentDetectorAPI.getComponentById(ps, compId, pageId)
            const nickname = componentCode.getNickname(ps, compPointer)
            if (result[nickname] || !componentsMetaData.canConnectToCode(ps, compPointer)) {
                return
            }
            const compType = comp.componentType
            const compData = comp.dataQuery && dataModel.getDataItemById(ps, dsUtils.stripHashIfExists(comp.dataQuery))

            if (connections.isControllerType(compType)) {
                // controller OR app widget
                const applicationId = _.get(compData, ['applicationId'])
                const controllerType = _.get(compData, ['controllerType'])
                const appManifest = platform.getAppManifest(ps, applicationId)
                const controllerExports = _.get(appManifest, ['exports', controllerType])

                if (controllerExports?.typeDef) {
                    result[nickname] = controllerExports.typeDef
                } else if (isDatabindingApp(applicationId)) {
                    result[nickname] = `$w.${controllerType}`
                } else if (controllerExports) {
                    result[nickname] = typeDefUtils.controllerExportsToTypeDef(controllerExports)
                } else if (connections.isAppWidgetType(compType)) {
                    result[nickname] = '$w.Node'
                }
                // otherwise it's a controller that does not want to be selected
            } else {
                const widgetId = _getWidgetIdIfExist(compData)
                const tpaWidgetDisplayName =
                    widgetId && tpaConfigurationUtils.getWidgetDisplayName(platformAppConfiguration, widgetId)
                if (tpaWidgetDisplayName) {
                    // specialy treated widgets from https://github.com/wix-private/js-platform-apps-configuration/tree/master/json/editor/api
                    result[nickname] = `$w.${tpaWidgetDisplayName}`
                } else {
                    // regular components
                    const sdkType = _getCompatibleCompSdkType(ps, compType, widgetId)
                    result[nickname] = sdkType
                }
            }
        },
        {}
    )
}

function getCompSdkType(ps: PS, compPointer: Pointer, compType?: string) {
    const componentType = ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'componentType')) || compType
    const dataItemPointer = dataModel.getDataItemPointer(ps, compPointer)
    const data = dataItemPointer && ps.dal.get(dataItemPointer)
    const widgetId = _.get(tpaSectionMetaData.getWidgetData(ps, data), ['widgetId']) || null
    const tpaWidgetDisplayName = tpaConfigurationUtils.getWidgetDisplayName(platformAppConfiguration, widgetId)
    if (tpaWidgetDisplayName) {
        return tpaWidgetDisplayName
    }
    const sdkType = _getCompatibleCompSdkType(ps, componentType, widgetId)
    return sdkType
}

function getNicknameToTypeMap(ps: PS, pageIds: string[]) {
    const pagesNicknamesMap = _.transform(
        pageIds,
        (result, pageId) => {
            result[pageId] = _getPageNicknamesToTypeMap(ps, pageId)
        },
        {}
    )

    const masterPageNicknamesMap = _getPageNicknamesToTypeMap(ps, constants.MASTER_PAGE_ID)

    return _.transform(
        pagesNicknamesMap,
        (result, pageNicknamesMap, pageId) => {
            if (pageId === constants.MASTER_PAGE_ID) {
                result[pageId] = masterPageNicknamesMap
            } else if (popupUtils.isPopup(ps, pageId)) {
                result[pageId] = pageNicknamesMap
            } else {
                result[pageId] = _.merge({}, pageNicknamesMap, masterPageNicknamesMap)
            }
        },
        {}
    )
}

function _shouldAddMasterPageComponents(ps: PS, pageId: string) {
    return (
        pageId !== constants.MASTER_PAGE_ID &&
        !popupUtils.isPopup(ps, pageId) &&
        !appStudioDataModel.isWidgetPage(ps, pageId)
    )
}

function _isSupportedAppController(compType: string, applicationId: string) {
    return connections.isAppControllerType(compType) && !isDatabindingApp(applicationId)
}

function getPageComponentsCodeModel(ps: PS, pageId: string) {
    const pageComponents = ps.siteAPI.getComponentsByPageIdForWixCode(pageId)
    let components = pageComponents

    if (_shouldAddMasterPageComponents(ps, pageId)) {
        const masterPageComponents = ps.siteAPI.getComponentsByPageIdForWixCode(constants.MASTER_PAGE_ID)
        components = _.merge(pageComponents, masterPageComponents)
    }
    return _.transform(
        components,
        (result, comp, compId) => {
            // @ts-expect-error
            const compPointer = componentDetectorAPI.getComponentById(ps, compId, pageId)
            const nickname = componentCode.getNickname(ps, compPointer)
            if (!nickname || santaCoreUtils.displayedOnlyStructureUtil.isRepeatedComponent(compId)) {
                return
            }

            const compType = comp.componentType
            let type = compType
            let sdkType = getCompSdkType(ps, compPointer, compType)

            if (connections.isControllerType(compType)) {
                const compData =
                    comp.dataQuery && dataModel.getDataItemById(ps, dsUtils.stripHashIfExists(comp.dataQuery))
                const applicationId = _.get(compData, ['applicationId'])
                const controllerType = _.get(compData, ['controllerType'])

                if (_isSupportedAppController(compType, applicationId)) {
                    return
                }

                if (controllerType) {
                    type = controllerType
                    sdkType = controllerType
                }
            }

            const compActionsAndBehaviors = actionsAndBehaviors.getBehaviors(ps, compPointer)
            const events = _.map(compActionsAndBehaviors, actionAndBehavior =>
                _.assign(
                    {eventType: _.get(actionAndBehavior, ['action', 'name'])},
                    _.get(actionAndBehavior, ['behavior', 'params'])
                )
            )
            result.push({compId, compName: nickname, events, type, sdkType, children: []})
        },
        []
    )
}

export default {
    getNicknameToTypeMap,
    getPageComponentsCodeModel,
    getCompSdkType
}
