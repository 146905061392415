import type {CompRef, CompStructure, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import arrayUtils from './arrayUtils'
import arrangementUtils from './arrangementUtils'
import masterPageArrangement from './masterPageArrangement'
import {ReportableError} from '@wix/document-manager-utils'
import {pointerUtils} from '@wix/document-manager-core'

const getChildPtr = (ps: PS, childrenPointer: Pointer, index: number) =>
    ps.pointers.getInnerPointer(childrenPointer, index as any)

function switchChildren(ps: PS, parentPointer: Pointer, i: number, j: number) {
    const childrenPointer = ps.pointers.full.components.getChildrenContainer(parentPointer)
    const firstCompPointer = getChildPtr(ps, childrenPointer, i)
    const secondCompPointer = getChildPtr(ps, childrenPointer, j)
    const firstComp = ps.dal.full.get(firstCompPointer)
    const secondComp = ps.dal.full.get(secondCompPointer)

    ps.dal.transaction(() => {
        // changes in this transaction are not applied to displayed
        ps.dal.full.set(firstCompPointer, secondComp)
    })
    ps.dal.full.set(secondCompPointer, firstComp)
}

const getNewIndex = (ps: PS, compPointer: Pointer, parentPointer: Pointer, preferredIndex: number) =>
    masterPageArrangement.shouldUseMasterPageArrangement(ps, parentPointer)
        ? masterPageArrangement.findValidIndexForChild(ps, compPointer, parentPointer, preferredIndex)
        : preferredIndex

const getNewIndexForNewComp = (ps: PS, compStructure: CompStructure, parentPointer: Pointer) =>
    masterPageArrangement.shouldUseMasterPageArrangement(ps, parentPointer)
        ? masterPageArrangement.findValidIndexForNewChild(ps, compStructure, parentPointer)
        : null

function moveToIndex(ps: PS, compPointer: Pointer, index: number) {
    if (!canMoveCompToIndex(ps, compPointer, index)) {
        throw new Error("index is out of children's bounds, cannot move to index")
    }

    const parentPointer = ps.pointers.full.components.getParent(compPointer)
    const childrenContainerPointer = ps.pointers.full.components.getChildrenContainer(parentPointer)
    const childrenPointers = ps.pointers.full.components.getChildren(parentPointer)
    const compIndex = _.findIndex(childrenPointers, {id: compPointer.id})
    if (compIndex === index) {
        return
    }
    const newIndex = getNewIndex(ps, compPointer, parentPointer, index)
    const compToMove = ps.dal.full.get(compPointer)
    ps.dal.full.remove(compPointer)
    ps.dal.full.push(childrenContainerPointer, compToMove, compPointer, newIndex)
}

const getCompIndex = (ps: PS, compPointer: Pointer) => {
    const templatePointer = pointerUtils.getRepeatedItemPointerIfNeeded(compPointer)
    const parentPointer = ps.pointers.full.components.getParent(templatePointer)

    if (!parentPointer) {
        return -1
    }

    const childrenPointers = ps.pointers.full.components.getChildren(parentPointer)
    return _.findIndex(childrenPointers, {id: templatePointer.id})
}

const fixIndexForMasterPageChild = (ps: PS, compPointer: Pointer) => {
    const parentPointer = ps.pointers.full.components.getParent(compPointer)

    if (!masterPageArrangement.shouldUseMasterPageArrangement(ps, parentPointer)) {
        return
    }

    const currentIndex = getCompIndex(ps, compPointer)
    const childrenContainerPointer = ps.pointers.full.components.getChildrenContainer(parentPointer)
    const newIndex = getNewIndex(ps, compPointer, parentPointer, currentIndex)

    if (currentIndex === newIndex) {
        return
    }

    const compToMove = ps.dal.full.get(compPointer)
    ps.dal.full.remove(compPointer)
    ps.dal.full.push(childrenContainerPointer, compToMove, compPointer, newIndex)
}

function getNextCompIndexInDisplayedJson(ps: PS, compPointer: Pointer) {
    const parentPointer = ps.pointers.components.getParent(compPointer)

    if (masterPageArrangement.shouldUseMasterPageArrangement(ps, parentPointer)) {
        return masterPageArrangement.getNextCompIndexInDisplayedJson(ps, compPointer)
    }

    const childrenPointers = ps.pointers.components.getChildren(parentPointer)
    const compIndex = _.findIndex(childrenPointers, {id: compPointer.id})

    return arrayUtils.findNext(
        childrenPointers,
        (targetPointer: Pointer) => arrangementUtils.canSwapComponents(ps, compPointer, targetPointer),
        compIndex
    )
}

function getNextComp(ps: PS, compPointer: Pointer) {
    const parentPointer = ps.pointers.components.getParent(compPointer)
    const nextCompIndexInDisplayedJson = getNextCompIndexInDisplayedJson(ps, compPointer)
    const childrenPointersInDisplayedJson = ps.pointers.components.getChildren(parentPointer)
    return childrenPointersInDisplayedJson[nextCompIndexInDisplayedJson]
}

function getPrevCompIndexInDisplayedJson(ps: PS, compPointer: Pointer) {
    const parentPointer = ps.pointers.components.getParent(compPointer)

    if (masterPageArrangement.shouldUseMasterPageArrangement(ps, parentPointer)) {
        return masterPageArrangement.getPrevCompIndexInDisplayedJson(ps, compPointer)
    }

    const childrenPointers = ps.pointers.components.getChildren(parentPointer)
    const compIndex = _.findIndex(childrenPointers, {id: compPointer.id})

    return arrayUtils.findPrev(
        childrenPointers,
        (targetPointer: Pointer) => arrangementUtils.canSwapComponents(ps, compPointer, targetPointer),
        compIndex
    )
}

function getPrevComp(ps: PS, compPointer: Pointer) {
    const parentPointer = ps.pointers.components.getParent(compPointer)
    const prevCompIndexInDisplayedJson = getPrevCompIndexInDisplayedJson(ps, compPointer)
    const childrenPointersInDisplayedJson = ps.pointers.components.getChildren(parentPointer)

    return childrenPointersInDisplayedJson[prevCompIndexInDisplayedJson]
}

function moveBackward(ps: PS, compPointer: Pointer) {
    if (!canMoveBackward(ps, compPointer)) {
        throw new Error('component is the bottom one, cannot move backward')
    }

    const parentPointer = ps.pointers.components.getParent(compPointer)

    if (
        masterPageArrangement.shouldUseMasterPageArrangement(ps, parentPointer) &&
        masterPageArrangement.isMasterPageSection(ps, compPointer as CompRef)
    ) {
        return masterPageArrangement.moveMasterPageSectionBackward(ps, compPointer)
    }

    const prevComp = getPrevComp(ps, compPointer)
    const childrenPointers = ps.pointers.full.components.getChildren(parentPointer)
    const compIndex = _.findIndex(childrenPointers, {
        id: santaCoreUtils.displayedOnlyStructureUtil.getRepeaterTemplateId(compPointer.id)
    })
    const prevCompIndex = _.findIndex(childrenPointers, {
        id: santaCoreUtils.displayedOnlyStructureUtil.getRepeaterTemplateId(prevComp.id)
    })

    switchChildren(ps, parentPointer, compIndex, prevCompIndex)
}

function moveForward(ps: PS, compPointer: Pointer) {
    if (!canMoveForward(ps, compPointer)) {
        throw new Error('component is the top one, cannot move forward')
    }

    const parentPointer = ps.pointers.components.getParent(compPointer)

    if (
        masterPageArrangement.shouldUseMasterPageArrangement(ps, parentPointer) &&
        masterPageArrangement.isMasterPageSection(ps, compPointer as CompRef)
    ) {
        return masterPageArrangement.moveMasterPageSectionForward(ps, compPointer)
    }

    const nextComp = getNextComp(ps, compPointer)
    const childrenPointers = ps.pointers.full.components.getChildren(parentPointer)

    const compIndex = _.findIndex(childrenPointers, {
        id: santaCoreUtils.displayedOnlyStructureUtil.getRepeaterTemplateId(compPointer.id)
    })
    const nextCompIndex = _.findIndex(childrenPointers, {
        id: santaCoreUtils.displayedOnlyStructureUtil.getRepeaterTemplateId(nextComp.id)
    })

    switchChildren(ps, parentPointer, compIndex, nextCompIndex)
}

function moveToFront(ps: PS, compPointer: Pointer) {
    if (!canMoveForward(ps, compPointer)) {
        throw new ReportableError({
            errorType: 'moveToFrontError',
            message: 'component is the top one, cannot move to front'
        })
    }

    const parentPointer = ps.pointers.full.components.getParent(compPointer)

    if (masterPageArrangement.shouldUseMasterPageArrangement(ps, parentPointer)) {
        return masterPageArrangement.moveMasterPageChildToFront(ps, compPointer)
    }

    const comp = ps.dal.full.get(compPointer)
    ps.dal.full.remove(compPointer)

    const childrenPointer = ps.pointers.full.components.getChildrenContainer(parentPointer)
    ps.dal.full.push(childrenPointer, comp, compPointer)
}

function moveToBack(ps: PS, compPointer: Pointer) {
    if (!canMoveBackward(ps, compPointer)) {
        throw new Error('component is the bottom one, cannot move to back')
    }

    const parentPointer = ps.pointers.full.components.getParent(compPointer)

    if (masterPageArrangement.shouldUseMasterPageArrangement(ps, parentPointer)) {
        return masterPageArrangement.moveMasterPageChildToBack(ps, compPointer)
    }

    const childrenContainerPointer = ps.pointers.full.components.getChildrenContainer(parentPointer)
    const childrenComps = ps.dal.full.get(childrenContainerPointer)
    const compIndex = getCompIndex(ps, compPointer)
    const comp = childrenComps.splice(compIndex, 1)[0]

    childrenComps.unshift(comp)
    ps.dal.full.set(childrenContainerPointer, childrenComps)
}

function canMoveCompToIndex(ps: PS, compPointer: Pointer, index: number) {
    if (!arrangementUtils.isCompArrangeable(ps, compPointer)) {
        return false
    }
    const parentPointer = ps.pointers.full.components.getParent(compPointer)
    const childrenPointers = ps.pointers.full.components.getChildren(parentPointer)
    return _.isFinite(index) && index >= 0 && index < childrenPointers.length
}

const canCompMoveToDirection =
    (getNextIndexInDirection: (ps: PS, compPointer: Pointer) => number) => (ps: PS, compPointer: Pointer) => {
        if (!arrangementUtils.isCompArrangeable(ps, compPointer)) {
            return false
        }
        return getNextIndexInDirection(ps, compPointer) > -1
    }

const canMoveBackward = canCompMoveToDirection(getPrevCompIndexInDisplayedJson)
const canMoveForward = canCompMoveToDirection(getNextCompIndexInDisplayedJson)

export default {
    getNewIndex,
    getCompIndex,
    getNewIndexForNewComp,
    /**
     * Moves a component to a specific index in the children array under it's container.
     *
     * @member documentServices.components.arrangement
     * @param {AbstractComponent} compPointer pointer to the component to move.
     *      @example
     *      documentServices.components.moveToIndex(componentPointer, 3);
     */
    moveToIndex,

    /**
     * Moves a component one position backward under it's container.
     *
     * @member documentServices.components.arrangement
     * @param {AbstractComponent} compPointer pointer to the component to move.
     *      @example
     *      documentServices.components.moveBackward(componentPointer);
     */
    moveBackward,

    /**
     * Moves a component one position forward under it's container.
     *
     * @member documentServices.components.arrangement
     * @param {AbstractComponent} compReference Reference of the component to move.
     *      @example
     *      documentServices.components.moveForward(componentReference);
     */
    moveForward,

    /**
     * Checks if a component can move forward under it's container.
     * Will return true if there is another component in front of it, under the same container.
     * @member documentServices.components.arrangement
     * @param {AbstractComponent} compReference
     * @returns {boolean}
     */
    canMoveForward,

    /**
     * Checks if a component can move backward under it's container.
     * Will return true if there is another component behind of it, under the same container.
     * @member documentServices.components.arrangement
     * @param {AbstractComponent} compPointer
     * @returns {boolean}
     */
    canMoveBackward,

    /**
     * Moves a component to the front position under it's container.
     * All other components under the same container will be behind it.
     *
     * @member documentServices.components.arrangement
     * @param {AbstractComponent} compPointer pointer to the component to move.
     *      @example
     *      documentServices.components.arrangement.moveToFront(componentPointer);
     */
    moveToFront,

    /**
     * Moves a component to the back most position under it's container.
     * All other components under the same container will be in front of it.
     *
     * @member documentServices.components.arrangement
     * @param {AbstractComponent} compReference Reference of the component to move.
     *      @example
     *      documentServices.components.arrangement.moveToBack(componentReference);
     */
    moveToBack,
    fixIndexForMasterPageChild
}
