import dataModel from '../../dataModel/dataModel'
import mobileUtil from '../../mobileUtilities/mobileUtilities'
import component from '../../component/component'

function removeMobileCropOverrideIfNeeded(ps, compRef, updatedDataItem) {
    const compData = dataModel.getDataItem(ps, compRef)
    const imageSourceWasChanged = updatedDataItem.uri && compData.uri !== updatedDataItem.uri

    mobileUtil.wPhoto.removeMobileCropOverrideIfNeeded(ps, compRef, imageSourceWasChanged, {dataModel, component})
}

export default {
    removeMobileCropOverrideIfNeeded
}
