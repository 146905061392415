import _ from 'lodash'

const movePageDataItemToMasterPageWithoutRefs = (masterPage: any, page: any, lang: string) => {
    const translatedDataPath = ['translations', lang, 'data', 'document_data', page.structure.id]
    const pageTranslation = _.get(page, translatedDataPath)
    if (!pageTranslation) {
        return
    }

    _.unset(page, translatedDataPath)

    if (!_.has(masterPage, translatedDataPath)) {
        _.setWith(masterPage, translatedDataPath, pageTranslation, Object)
    }
}

const movePageDataToMaster = (page: any, masterPage: any) => {
    if (!page.structure) {
        return
    }

    if (page.translations) {
        Object.keys(page.translations).forEach(lang => {
            movePageDataItemToMasterPageWithoutRefs(masterPage, page, lang)
        })
    }
}

export {movePageDataToMaster}
