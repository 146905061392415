import _ from 'lodash'
import {pointerUtils, type CreateExtArgs, type DAL} from '@wix/document-manager-core'
import {getComponentType} from '../../../utils/dalUtils'
import type {ComponentDefinitionExtensionAPI} from '../../componentDefinition'
import type {CompStructure, Pointer} from '@wix/document-services-types'
import {
    COMPONENTS_NOT_SUITABLE_FOR_NON_RENDERING_STATE,
    CONTAINERS_SUITABLE_FOR_NON_RENDERING_STATE
} from './componentConstants'
import {dockUtils} from '@wix/santa-core-utils'
import {LEGACY_FULL_WIDTH_CONTAINERS} from '../legacyFullWidthContainers'

export const isComponentAContainer = (createExtArgs: CreateExtArgs, componentPointer: Pointer): boolean => {
    const {componentDefinition} = createExtArgs.extensionAPI as ComponentDefinitionExtensionAPI

    return componentDefinition.isContainer(getComponentType(createExtArgs.dal, componentPointer))
}

export const isComponentSuitableForNonRenderingState = (componentType: string) => {
    const list = _.without(
        COMPONENTS_NOT_SUITABLE_FOR_NON_RENDERING_STATE,
        'wysiwyg.viewer.components.StripColumnsContainer'
    )
    return !_.includes(list, componentType)
}

export const isComponentSuitableForNonRenderingStateByPointer = (dal: DAL, componentPointer: Pointer) => {
    const componentType = getComponentType(dal, componentPointer)
    return isComponentSuitableForNonRenderingState(componentType)
}

export const isContainerSuitableForNonRenderingState = (componentType: string): boolean => {
    return _.includes(CONTAINERS_SUITABLE_FOR_NON_RENDERING_STATE, componentType)
}

const isLegacyFullWidthContainerByType = (compType: string) => {
    return !!(LEGACY_FULL_WIDTH_CONTAINERS[compType] && !_.isFunction(LEGACY_FULL_WIDTH_CONTAINERS[compType]))
}

export const defaultIsFullWidthByStructure = (compStructure: CompStructure) => {
    return (
        dockUtils.isHorizontalDockToScreen(compStructure.layout) ||
        isLegacyFullWidthContainerByType(compStructure.componentType)
    )
}

const isLegacyFullWidthContainer = (createExtArgs: CreateExtArgs, compPtr: Pointer) => {
    const compType = getComponentType(createExtArgs.dal, compPtr)
    const isLegacyFullWidth = LEGACY_FULL_WIDTH_CONTAINERS[compType]

    if (_.isFunction(isLegacyFullWidth)) {
        return isLegacyFullWidth(createExtArgs, compPtr)
    }

    return !!isLegacyFullWidth
}

export const defaultIsFullWidth = (createExtArgs: CreateExtArgs, compPointer: Pointer) => {
    const compLayoutPointer = pointerUtils.getInnerPointer(compPointer, 'layout')
    const compLayout = createExtArgs.dal.get(compLayoutPointer)

    return dockUtils.isHorizontalDockToScreen(compLayout) || isLegacyFullWidthContainer(createExtArgs, compPointer)
}
