import type {CreateExtArgs} from '@wix/document-manager-core'

const isPopup = ({pointers, dal}: CreateExtArgs, pageId: string) => {
    if (pageId === 'masterPage') {
        return false
    }

    const pageDataItemPointer = pointers.data.getDataItemFromMaster(pageId)
    const pageData = dal.get(pageDataItemPointer)
    return pageData.isPopup
}

export {isPopup}
