import {
    CreateExtArgs,
    CreateExtensionArgument,
    DocumentDataTypes,
    Extension,
    ExtensionAPI,
    InitializeExtArgs,
    pointerUtils
} from '@wix/document-manager-core'
import {guidUtils} from '@wix/santa-core-utils'
import {TPA_CONSTANTS} from '@wix/document-manager-utils'
import {addAppDefinitionIdToTPAPages} from '../utils/tpaUtils'
import type {DalPointers} from '../types'
import {isAppRevoked, isSectionInstalledByTpaPageIdPredicate} from './tpa/installedTpaAppsUtils'
import type {PageExtensionAPI} from './page'
import type {Layout, Pointer, AppDefinitionId} from '@wix/document-services-types'
import type {ComponentsExtensionAPI} from './components'
import {generatePageAndTpaSectionStructure} from './tpa/tpaStructureGenerator'
import {VIEW_MODES} from '../constants/constants'
import _ from 'lodash'
import {getComponentType} from '../utils/dalUtils'
import type {AppsInstallStateExtensionAPI} from './appsInstallState'
import {getTpaAppDataId, getTpaAppData, setAppValue, setAppValueByAppDefId, setComponentValue} from './tpa/tpaData'

const {getPointer} = pointerUtils
const NO_MATCH: string[] = []
const EMPTY_COMP_ID = 'EMPTY_COMP_ID'
const MATCH: string[] = [EMPTY_COMP_ID]
const tpaSharedStatePointerType = 'tpaSharedState'
const getDocumentDataTypes = (): DocumentDataTypes => ({
    [tpaSharedStatePointerType]: {}
})

const initialState = {}

export interface AddPageWithTpaSectionOptions {
    isHidden?: boolean
    managingAppDefId?: string
    requireLogin?: boolean
    layout?: Layout
    styleId?: string
    tpaSectionCompType?: string
    tpaSectionDataType?: string
    shouldAddMobileStructure?: boolean
}

interface UpdateTpaCompDataRes {
    success: boolean
    reason?: string
    compDataDeserialized?: Record<string, any>
    keyValue?: Record<string, any>
}
export interface TPAExtensionAPI extends ExtensionAPI {
    tpa: {
        isTpaByCompType(type: string): boolean
        addPageWithTpaSection(
            appDefinitionId: string,
            widgetId: string,
            isMultiSection?: boolean,
            addOptions?: any,
            pageId?: string,
            tpaSectionId?: string
        ): {sectionPointer: Pointer; pagePointer: Pointer}
        data: {
            getTpaAppDataId(appDefinitionId: AppDefinitionId): string
            getTpaAppData(appDefinitionId: AppDefinitionId): any
            setAppValue(
                appDefinitionId: AppDefinitionId,
                key: string,
                value: any,
                langCode?: string
            ): Record<string, any> | undefined
            setAppValueByAppDefId(
                appTpaData: any,
                appDefinitionId: AppDefinitionId,
                config: Record<string, any>,
                langCode?: string
            ): Record<string, any> | undefined
            setComponentValue(
                componentTpaData: Record<string, any> | undefined,
                compPointer: Pointer,
                config: Record<string, any>,
                langCode?: string
            ): UpdateTpaCompDataRes
        }
        fixers: {
            removeItemsWithEmptyCompId(): void
        }
    }
}

const createPointersMethods = () => ({
    tpa: {
        getTpaSharedStatePointer: (hash: string) => getPointer(hash, tpaSharedStatePointerType)
    }
})

const createFilters = () => ({
    getBrokenSharedState: (namespace: string, value: any): string[] => {
        if (namespace !== tpaSharedStatePointerType) {
            return NO_MATCH
        }

        if (_.trim(value?.compId)) {
            return NO_MATCH
        }

        return MATCH
    }
})

const createExtension = ({}: CreateExtensionArgument): Extension => {
    const createExtensionAPI = (createExtArgs: CreateExtArgs): TPAExtensionAPI => {
        const {dal, pointers, extensionAPI} = createExtArgs
        const isTpaByCompType = (type: string) =>
            _.includes(TPA_CONSTANTS.COMP_TYPES, type) || _.includes(TPA_CONSTANTS.TPA_COMP_TYPES, type)
        const findParentOfTpaSection = (isResponsive: boolean, pageToAddPointer: Pointer) => {
            let parentPointer = pageToAddPointer
            if (isResponsive) {
                const children = pointers.structure.getChildren(pageToAddPointer)
                const section = _.find(children, compPointer => {
                    const componentType = getComponentType(dal, compPointer)
                    return componentType === 'responsive.components.Section'
                })
                if (section) {
                    parentPointer = section
                }
            }
            return parentPointer
        }
        const addPageWithTpaSection = (
            appDefinitionId: string,
            widgetId: string,
            isMultiSection = false,
            addOptions: AddPageWithTpaSectionOptions = {},
            pageToCreateId?: string,
            tpaSectionToCreateId?: string
        ) => {
            const {page} = extensionAPI as PageExtensionAPI
            const {components} = extensionAPI as ComponentsExtensionAPI
            const {appsInstallState} = extensionAPI as AppsInstallStateExtensionAPI
            const csmEntryPointer = pointers.rendererModel.getClientSpecMapEntryByAppDefId(appDefinitionId)
            const csmEntry = dal.get(csmEntryPointer)
            if (csmEntry && !isAppRevoked(csmEntry)) {
                const sectionData = _.find(csmEntry.widgets, {widgetId})
                if (!sectionData) {
                    throw new Error(`widgetId id does not exist`)
                }
                if (!sectionData.appPage) {
                    throw new Error(`widgetId id does not section`)
                }
                const isInstalled = isSectionInstalledByTpaPageIdPredicate(csmEntry, widgetId)
                const allPageIds = page.getAllPagesIds(false)
                const isSectionInstalled = _.some(allPageIds, pageId =>
                    isInstalled(page.data.pick(pageId, ['tpaPageId', 'appDefinitionId']))
                )
                if (isSectionInstalled) {
                    throw new Error(`section already installed on site`)
                }

                const pageToAddPointer = page.generateNewPagePointer(undefined, pageToCreateId)
                const id = isMultiSection ? TPA_CONSTANTS.TYPE.TPA_MULTI_SECTION : TPA_CONSTANTS.TYPE.TPA_SECTION
                const tpaSectionCompType = isMultiSection
                    ? TPA_CONSTANTS.COMP_TYPES.TPA_MULTI_SECTION
                    : TPA_CONSTANTS.COMP_TYPES.TPA_SECTION
                const tpaSectionDataType = isMultiSection
                    ? TPA_CONSTANTS.DATA_TYPE.TPA_MULTI_SECTION
                    : TPA_CONSTANTS.DATA_TYPE.TPA_SECTION

                const sectionId = tpaSectionToCreateId
                    ? tpaSectionToCreateId
                    : `${id}_${guidUtils.getUniqueId(undefined, undefined)}`
                const sectionPointer = pointers.getPointer(sectionId, VIEW_MODES.DESKTOP)
                const isResponsive = dal.get(pointers.general.isResponsive())
                const {pageStructure, sectionStructure} = generatePageAndTpaSectionStructure(
                    extensionAPI,
                    isResponsive,
                    pageToAddPointer,
                    sectionPointer,
                    widgetId,
                    csmEntry,
                    isMultiSection,
                    tpaSectionCompType,
                    tpaSectionDataType,
                    addOptions
                )
                page.addPageWithDefinition(pageStructure, VIEW_MODES.DESKTOP, pageToAddPointer)

                components.addComponent(
                    findParentOfTpaSection(isResponsive, pageToAddPointer),
                    sectionStructure,
                    sectionPointer
                )
                appsInstallState.setAppInstallStateByAppData(csmEntry)
                return {
                    pagePointer: pageToAddPointer,
                    sectionPointer
                }
            }
            throw new Error('app not installed')
        }
        return {
            tpa: {
                isTpaByCompType,
                addPageWithTpaSection,
                data: {
                    getTpaAppDataId: (appDefinitionId: AppDefinitionId) =>
                        getTpaAppDataId(createExtArgs, appDefinitionId),
                    getTpaAppData: (appDefinitionId: AppDefinitionId) => getTpaAppData(createExtArgs, appDefinitionId),
                    setAppValue: (appDefinitionId: AppDefinitionId, key: string, value: any, langCode?: string) =>
                        setAppValue(createExtArgs, appDefinitionId, key, value, langCode),
                    setAppValueByAppDefId: (
                        appTpaData: any,
                        appDefinitionId: AppDefinitionId,
                        config: Record<string, any>,
                        langCode?: string
                    ) => setAppValueByAppDefId(createExtArgs, appTpaData, appDefinitionId, config, langCode),
                    setComponentValue: (
                        componentTpaData: Record<string, any> | undefined,
                        compPointer: Pointer,
                        config: Record<string, any>,
                        langCode?: string
                    ) => setComponentValue(createExtArgs, componentTpaData, compPointer, config, langCode)
                },
                fixers: {
                    removeItemsWithEmptyCompId() {
                        const brokenSharedStateFilter = dal.queryFilterGetters.getBrokenSharedState(EMPTY_COMP_ID)
                        const brokenSharedStates = dal.query(tpaSharedStatePointerType, brokenSharedStateFilter)

                        _.forEach(brokenSharedStates, (v, hash) => {
                            dal.remove(pointers.tpa.getTpaSharedStatePointer(hash))
                        })
                    }
                }
            }
        }
    }

    const initialize = async (extArgs: InitializeExtArgs) => {
        const {eventEmitter, EVENTS, dal} = extArgs
        eventEmitter.on(EVENTS.PAGE.PAGE_DATA_ADDED, (pagePointer: Pointer) => {
            addAppDefinitionIdToTPAPages(extArgs as DalPointers, pagePointer)
        })
        eventEmitter.on(EVENTS.COMPONENTS.BEFORE_ADD, compDefinition => {
            if (
                compDefinition.componentType === 'tpa.viewer.components.tpapps.TPAWidget' ||
                compDefinition.componentType === 'wysiwyg.viewer.components.tpapps.TPAWidget'
            ) {
                const tpaData = _.get(compDefinition, ['data'])
                if (!tpaData?.appDefinitionId) {
                    throw new Error('data must contain appDefinitionId & applicationId')
                }
                const csmEntry = dal.get(
                    extArgs.pointers.rendererModel.getClientSpecMapEntryByAppDefId(tpaData.appDefinitionId)
                )
                if (!csmEntry || _.get(csmEntry, ['permissions', 'revoked'])) {
                    throw new Error('application must be installed before adding widget')
                }
                /*TODO : removed when appId is deprecated*/
                if (!tpaData.applicationId) {
                    const applicationId = _.get(csmEntry, ['applicationId'])
                    tpaData.applicationId = String(applicationId)
                }
            }
        })
    }

    return {
        name: 'tpa',
        getDocumentDataTypes,
        initialState,
        createExtensionAPI,
        createFilters,
        createPointersMethods,
        initialize
    }
}

export {createExtension}
