export class TaskQueue {
    private lastPromise: Promise<any>
    private taskCounter: number

    constructor() {
        this.lastPromise = Promise.resolve()
        this.taskCounter = 0
    }

    private afterFunc(): void {
        this.taskCounter--
    }

    run(func: () => Promise<any>): Promise<any> {
        // When not during a task, run the first function synchronously
        // Otherwise, run it after the last promise in queue
        // eslint-disable-next-line promise/prefer-await-to-then
        const funcPromise = this.taskCounter > 0 ? this.lastPromise.then(func) : func()
        // eslint-disable-next-line promise/prefer-await-to-then
        this.lastPromise = funcPromise.then(this.afterFunc.bind(this), this.afterFunc.bind(this))
        this.taskCounter++
        return funcPromise
    }
}
