import type {CompStructure, Pointer, PS} from '@wix/document-services-types'

export default function (
    ps: PS,
    compToAddPointer: Pointer,
    containerPointer: Pointer,
    compDefinitionPrototype: CompStructure
) {
    compDefinitionPrototype.data.compId = compToAddPointer.id
}
