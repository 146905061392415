/* eslint-disable promise/prefer-await-to-then */
/**
 * This is the description for the tpa namespace.
 * @memberof documentServices
 * @namespace documentServices.tpa
 */
import {asArray, QueryParams} from '@wix/document-manager-utils'
import type {
    AppDefinitionId,
    ApplicationId,
    AppDataToDelete,
    Callback1,
    Callback,
    Pointer,
    PS,
    ClientSpecMap,
    EditorClientSpecMapEntry,
    PagesData,
    Size,
    CompRef
} from '@wix/document-services-types'
import * as tpaExtUtils from '@wix/document-manager-extensions/src/utils/tpaUtils'
import _ from 'lodash'
import {tpa, warmupUtils} from '@wix/santa-ds-libs'
const {loggingUtils} = warmupUtils
import component from '../component/component'
import documentModeInfo from '../documentMode/documentModeInfo'
import hooks from '../hooks/hooks'
import page from '../page/page'
import platformProvision from '../platform/provision'
import platformStateService from '../platform/services/platformStateService'
import generalInfo from '../siteMetadata/generalInfo'
import fixedComponentMeasuring from '../structure/fixedComponentMeasuring'
import structure from '../structure/structure'
import errors from './bi/errors'
import tpaConstants from './constants'
import componentDefinitionService from './data/componentDefinition'
import tpaHandlers from './handlers/tpaHandlers'
import appInstallationAndDeletionEvents from './services/appInstallationAndDeletionEvents'
import appMarketService from './services/appMarketService'
import appStoreService from './services/appStoreService'
import billingService from './services/billingService'
import clientSpecMapService from './services/clientSpecMapService'
import installedTpaAppsOnSiteService from './services/installedTpaAppsOnSiteService'
import pendingAppsService from './services/pendingAppsService'
import provisionService from './services/provisionService'
import sectionsTranslatedPageTitlesCache from './services/sectionsTranslatedPageTitlesCache'
import tpaAddService from './services/tpaAddService'
import tpaComponentCommonService from './services/tpaComponentCommonService'
import tpaComponentService from './services/tpaComponentService'
import tpaDataService from './services/tpaDataService'
import tpaDeleteService from './services/tpaDeleteService'
import tpaEventHandlersService from './services/tpaEventHandlersService'
import tpaPageService from './services/tpaPageService'
import tpaPostMessageService, {TpaMsg} from './services/tpaPostMessageService'
import tpaPreviewEditorCommunicationService from './services/tpaPreviewEditorCommunicationService'
import tpaSectionService from './services/tpaSectionService'
import tpaSettingsService from './services/tpaSettingsService'
import tpaStyleService from './services/tpaStyleService'
import tpaWidgetService from './services/tpaWidgetService'
import componentAppDataClone from './utils/componentAppDataClone'
import permissionsUtils from './utils/permissionsUtils'
import tpaUtils from './utils/tpaUtils'
import menuAPI from '../menu/menuAPI'
import tpaNotifyDeleteService from './services/tpaNotifyDeleteService'
import type {
    DistributorExtensionAPI,
    TpaCompPreviewDataChange,
    TpaCompStateChange,
    TpaPostMessageToApp,
    PageExtensionAPI
} from '@wix/document-manager-extensions'

let _dsHandlersReady = false

function undoRedoHookHandler(ps: PS) {
    const tpaCompPointers = getAllTPAsOnCurrentPage(ps) || []

    tpaCompPointers.forEach(tpaCompPointer => {
        tpaEventHandlersService.executeHistoryChangeHandler(tpaCompPointer.id)
    })
}

const addAppDefinitionIdToTPApages = (ps: PS, pageComponentPointer: Pointer) => {
    tpaExtUtils.addAppDefinitionIdToTPAPages(ps, pageComponentPointer)
}

const initTpaSharedStateMessageDistribution = (ps: PS) => {
    const {distributor} = ps.extensionAPI as DistributorExtensionAPI

    distributor.subscribeToMessage<TpaCompPreviewDataChange>('tpaCompPreviewDataChange', ({data: {compId, data}}) =>
        tpaComponentService.tpaCompPreviewDataSyncer(ps, compId, data)
    )

    distributor.subscribeToMessage<TpaCompStateChange>('tpaCompStateChange', ({data: {compId, data}}) =>
        tpaComponentService.compStateSyncer(ps, compId, data)
    )

    distributor.subscribeToMessage<TpaPostMessageToApp>(
        'tpaPostMessageToApp',
        ({data: {compId, eventType, params}}) => {
            if (eventType === 'THEME_CHANGE') {
                params = tpaStyleService.getStyleDataToPassIntoApp(ps, compId)
            } else if (eventType === 'SETTINGS_UPDATED' && _.isNil(params)) {
                const data = ps.siteAPI.getTpaCompPreviewData(compId) || {}
                const previewData = _.defaults(
                    {
                        queryParams: {cacheKiller: `${_.now()}`}
                    },
                    data
                )
                return tpaComponentService.tpaCompPreviewDataSyncer(ps, compId, previewData)
            }
            return tpaComponentService.postMessageToAppSyncer([compId, eventType], params)
        }
    )
}

hooks.registerHook(hooks.HOOKS.ADD_PAGE.AFTER, addAppDefinitionIdToTPApages)

const initialize = function (ps: PS) {
    if (!ps.runtimeConfig.supportsPlatformInitialization) {
        return
    }
    loggingUtils.performance.start(loggingUtils.performanceMetrics.TPA.INITIALIZE)

    tpaDataService.runGarbageCollection(ps)
    const clientSpecMap = ps.dal.get(ps.pointers.general.getClientSpecMap())
    fixedComponentMeasuring.setMeasuringForType(
        'wysiwyg.viewer.components.tpapps.TPAGluedWidget',
        tpa.gluedWidgetMeasuringUtils.getGluedWidgetMeasurements.bind(null, clientSpecMap)
    )

    tpaHandlers.settpads(api)
    tpaPostMessageService.init(ps)
    tpaPreviewEditorCommunicationService.init()
    markDocumentServicesHandlersAreReady(true)

    permissionsUtils.shouldAvoidRevoking({ps}).then(shouldAvoidRevoking => {
        if (shouldAvoidRevoking) {
            cacheUnusedAppsMap(ps)
        }

        appStoreService.settleOnLoad(ps, shouldAvoidRevoking, () =>
            clientSpecMapService.setClientSpecMapReadyOnLoad(ps)
        )
    })

    fixPageUriSEOIfNeeded(ps)

    Promise.resolve(
        appMarketService.getSectionsTranslatedPageTitles(ps).then(function (data) {
            sectionsTranslatedPageTitlesCache.init(data)
            fixHiddenPagesTitleIfNeeded(ps, clientSpecMap, data)
        })
    ).catch(e => {
        console.log(e)
    })

    initTpaSharedStateMessageDistribution(ps)

    ps.extensionAPI.rendererModel.onAppsInstalledRemotely(newApps => {
        _.forEach(newApps, newApp => {
            platformProvision.onRemoteProvision(ps, newApp)
        })
    })

    hooks.registerHook(hooks.HOOKS.UNDO_REDO.AFTER_APPLY_SNAPSHOT, undoRedoHookHandler)
    loggingUtils.performance.finish(loggingUtils.performanceMetrics.TPA.INITIALIZE)
}

/**
 * This grabs and caches apps that are unused, a.k.a would have been revoked if site had not had branches enabled
 * so clientSpecMapService.isAppActive(...) will still behave like the app is revoked
 */
const cacheUnusedAppsMap = (ps: PS) => {
    const unusedApps = installedTpaAppsOnSiteService.getUnusedApps(ps)
    platformStateService.setUnusedApps(ps, unusedApps)
}

const getAllTPAsOnCurrentPage = (ps: PS) => {
    if (!ps.siteAPI.getCurrentTpaIds) {
        // Non-TB logic, remove once Bolt is over
        const renderedRootIds = ps.siteAPI.getAllRenderedRootIds()
        const rootPagePointers = renderedRootIds.map(rootId => page.getPage(ps, rootId))

        const tpaChildrenPerPage = rootPagePointers.map(rootPagePointer =>
            component.getTpaChildren(ps, rootPagePointer)
        )

        return _.union(...tpaChildrenPerPage)
    }

    const tpaIds = ps.siteAPI.getCurrentTpaIds()
    const viewMode = documentModeInfo.getViewMode(ps)
    return tpaIds.map(id => ps.pointers.getPointer(id, viewMode))
}

const fixHiddenPagesTitleIfNeeded = function (ps: PS, clientSpecMap: ClientSpecMap, data) {
    if (generalInfo.isDraft(ps)) {
        const pagesData = page.getPagesDataItems(ps)
        _.forEach(pagesData, pageData => {
            if (pageData.appDefinitionId && pageData.tpaPageId) {
                const appData = _.find(clientSpecMap, {
                    appDefinitionId: pageData.appDefinitionId
                }) as EditorClientSpecMapEntry
                if (appData) {
                    const widget = _.find(
                        appData.widgets,
                        widgetData => _.get(widgetData, ['appPage', 'id']) === pageData.tpaPageId
                    )
                    if (_.get(widget, ['appPage', 'hidden'])) {
                        const appTranslatedData = _.find(data, {appDefinitionId: appData.appDefinitionId})
                        const widgetTranslatedData = _.find(_.get(appTranslatedData, ['widgets']), {
                            widgetId: widget.widgetId
                        })
                        const translatedTitle = _.get(widgetTranslatedData, ['title'])
                        if (translatedTitle) {
                            page.data.set(ps, pageData.id, {title: translatedTitle} as PagesData)
                        }
                    }
                }
            }
        })
    }
}

const provisionSystemApp = function (ps: PS, componentToAddRef: Pointer, appDefinitionId: AppDefinitionId, options?) {
    const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
    if (appData && !isAppPermissionsIsRevoked(ps, appData)) {
        const widgetId = _.get(options, ['widgetId'])
        const widgetData = appData.widgets[widgetId]
        const isAlreadyInstalled = tpaWidgetService.isGlued(widgetData)
            ? isAppInstalledOnPage(ps, 'masterPage', appDefinitionId)
            : isAppInstalledBy(ps, appDefinitionId)
        if (!isAlreadyInstalled) {
            tpaComponentService.provisionWidget(ps, componentToAddRef, appDefinitionId, {widgetId})
            pendingAppsService.add(appData) //in order to settle on save
            return
        }
    }
    ps.setOperationsQueue.asyncPreDataManipulationComplete({dontAdd: true})
}

const sectionAlreadyInstalled = function (ps: PS, appDefinitionId: AppDefinitionId) {
    return tpaSectionService.alreadyInstalled(ps, appDefinitionId)
}

const getPageData = function (ps: PS, pageId: string) {
    const pageData = (ps.extensionAPI as PageExtensionAPI).page.data.pick(pageId, ['appDefinitionId', 'title'])
    const hasSection = !!pageData.appDefinitionId
    let appData
    if (hasSection) {
        appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, pageData.appDefinitionId)
    }
    return {
        title: pageData.title,
        hasSection,
        appData
    }
}

const getComponentDefinition = function (ps: PS, params: {compType: string}) {
    return componentDefinitionService.getComponentDefinition(params)
}

const isPremiumAppByAppId = function (ps: PS, applicationId: ApplicationId) {
    if (applicationId) {
        const appDefId = clientSpecMapService.getAppDefinitionIdFromApplicationId(ps, applicationId, {
            source: 'isPremiumAppByAppId'
        })
        return clientSpecMapService.isPremiumApp(ps, appDefId)
    }
}

const hasPremiumOfferingByAppId = function (ps: PS, applicationId: ApplicationId) {
    if (!applicationId) {
        return false
    }
    const appDefId = clientSpecMapService.getAppDefinitionIdFromApplicationId(ps, applicationId, {
        source: 'hasPremiumOfferingByAppId'
    })
    return clientSpecMapService.hasPremiumOffering(ps, appDefId)
}

const getWidgetDataFromTPAPageId = function (ps: PS, appDefinitionId: AppDefinitionId, tpaPageId: string) {
    return clientSpecMapService.getWidgetDataFromTPAPageId(ps, appDefinitionId, tpaPageId)
}

const getWidgetDataFromTPAWidgetId = function (ps: PS, appDefinitionId: AppDefinitionId, tpaWidgetId: string) {
    return clientSpecMapService.getWidgetDataFromTPAWidgetId(ps, appDefinitionId, tpaWidgetId)
}

const registerOnAppInstalled = function (ps: PS, appDefinitionId: AppDefinitionId, cb: Callback1<any>) {
    appInstallationAndDeletionEvents.registerOnAppInstalled(appDefinitionId, cb)
}

const registerOnAppDeleted = function (ps: PS, appDefinitionId: AppDefinitionId, cb: Callback) {
    appInstallationAndDeletionEvents.registerOnAppDeleted(appDefinitionId, cb)
}

const getExtensionsWidgets = function (ps: PS, appData) {
    return clientSpecMapService.getExtensionsWidgets(ps, appData)
}

const isHybridAppByAppId = function (ps: PS, applicationId: ApplicationId) {
    if (!applicationId) {
        return false
    }
    const appDefId = clientSpecMapService.getAppDefinitionIdFromApplicationId(ps, applicationId, {
        source: 'isHybridAppByAppId'
    })
    return clientSpecMapService.isHybridApp(ps, appDefId)
}

const isDashboardAppOnlyByAppId = function (ps: PS, applicationId: ApplicationId) {
    const appData = applicationId && getAppData(ps, applicationId)
    return clientSpecMapService.isDashboardAppOnly(appData)
}

const isDashboardAppOnly = function (ps: PS, appDefinitionId: AppDefinitionId) {
    const appData = appDefinitionId && clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
    return clientSpecMapService.isDashboardAppOnly(appData)
}

const getFirstAppCompPageId = function (ps: PS, appDefinitionId: AppDefinitionId, useDefaultWidget: boolean) {
    return installedTpaAppsOnSiteService.getFirstAppCompPageId(ps, appDefinitionId, useDefaultWidget)
}

const duplicateWidget = function (ps: PS, compPointer: CompRef, pageId: string) {
    tpaWidgetService.duplicateWidget(ps, compPointer, pageId)
}

const registerDeleteCompHandler = function (ps: PS, compId: string, handler: Callback) {
    tpaEventHandlersService.registerDeleteCompHandler(compId, handler)
}

const registerDisconnectCompHandler = function (ps: PS, compId: string, handler: Callback) {
    tpaEventHandlersService.registerDisconnectCompHandler(compId, handler)
}

const registerCompLoadedHandler = function (ps: PS, handler: Callback1<{compPointer: Pointer}>) {
    tpaEventHandlersService.registerCompLoaded(handler)
}

const registerEditModeChangeHandler = function (ps: PS, compId: string, handler: Callback1<string>) {
    tpaEventHandlersService.registerEditModeChangeHandler(compId, handler)
}

const registerEditorEventHandler = function (ps: PS, compId: string, handler: Callback1<TpaMsg>) {
    tpaEventHandlersService.registerEditorEventHandler(compId, handler)
}

const registerThemeChangeHandler = function (ps: PS, compId: string, handler: Callback1<any>) {
    tpaEventHandlersService.registerThemeChangeHandler(ps, compId, handler)
}

const registerSettingsUpdatedHandler = function (ps: PS, compId: string, handler: Callback1<any>) {
    tpaEventHandlersService.registerSettingsUpdatedHandler(compId, handler)
}

const registerPublicDataChangedHandlerByAppId = function (
    ps: PS,
    compId: string,
    applicationId: ApplicationId,
    handler: Callback1<any>
) {
    if (applicationId) {
        const appDefId = clientSpecMapService.getAppDefinitionIdFromApplicationId(ps, applicationId, {
            source: 'registerPublicDataChangedHandlerByAppId'
        })
        registerPublicDataChangedHandler(ps, compId, appDefId, handler)
    }
}

const registerPublicDataChangedHandler = function (
    ps: PS,
    compId: string,
    appDefinitionId: AppDefinitionId,
    handler: Callback1<any>
) {
    if (appDefinitionId) {
        tpaEventHandlersService.registerPublicDataChangedHandler(compId, appDefinitionId, handler)
    }
}

const registerSitePublishedHandler = function (ps: PS, compId: string, handler: Callback) {
    tpaEventHandlersService.registerSitePublishedHandler(compId, handler)
}

const registerDeviceTypeChangeHandler = function (ps: PS, compId: string, handler: Callback1<any>) {
    tpaEventHandlersService.registerDeviceTypeChangeHandler(compId, handler)
}

const registerWindowPlacementChangedHandler = function (ps: PS, compId: string, handler: Callback1<any>) {
    tpaEventHandlersService.registerWindowPlacementChangedHandler(compId, handler)
}

const registerHistoryChangeHandler = function (ps: PS, compId: string, handler: Callback) {
    tpaEventHandlersService.registerHistoryChangeHandler(compId, handler)
}

const editModeChange = function (ps: PS, editorMode: string) {
    tpaEventHandlersService.editModeChange(editorMode)
}

const triggerEditorEvent = function (ps: PS, msg) {
    tpaEventHandlersService.triggerEditorEvent(msg)
}

const registerSiteSavedHandler = function (ps: PS, compId: string, handler: Callback) {
    tpaEventHandlersService.registerSiteSavedHandler(compId, handler)
}

const getSanitizedNumber = (str: string) => {
    const num = parseFloat(str)
    return isNaN(num) ? undefined : num
}

const fixLayoutStrings = (layout: Size) => {
    const props = ['width', 'height']
    _.forEach(props, prop => {
        if (typeof layout[prop] === 'string') {
            layout[prop] = getSanitizedNumber(layout[prop])
        }
    })
}

const resizeComponent = function (
    ps: PS,
    compPointer: Pointer,
    width: number | string,
    height: number | string,
    callback: Callback1<any>
) {
    const isStretched = structure.isHorizontallyStretchedToScreen(ps, compPointer)
    if (isStretched) {
        callback({onError: 'component is already set to full width'})
    } else {
        let layout = component.layout.get(ps, compPointer)
        const exit = () => {
            callback({
                width: layout.width,
                height: layout.height
            })
        }
        const newLayout: Size = _.defaults({width, height}, {width: layout.width, height: layout.height})
        fixLayoutStrings(newLayout)
        const layoutIsInvalid = _(newLayout).values().some(_.isUndefined)
        if (layoutIsInvalid) {
            exit()
            return
        }
        structure.updateCompLayout(ps, compPointer, newLayout)
        ps.siteAPI.forceUpdate()
        layout = component.layout.get(ps, compPointer)
        exit()
    }
}

const setExternalId = function (
    ps: PS,
    componentPointer: Pointer,
    referenceId: string,
    callback: Callback1<string>,
    preventRefresh: boolean
) {
    tpaComponentService.setExternalId(ps, componentPointer, referenceId, callback, preventRefresh)
}

const getExternalId = function (ps: PS, componentPointer: Pointer) {
    return tpaComponentService.getExternalId(ps, componentPointer)
}

const sitePublished = function () {
    tpaEventHandlersService.sitePublished()
}

const siteSaved = function () {
    tpaEventHandlersService.siteSaved()
}

const deviceTypeChange = function (ps: PS, deviceType: string) {
    tpaEventHandlersService.deviceTypeChange(deviceType)
}

const triggerSettingsUpdatedByAppId = function (
    ps: PS,
    applicationId: ApplicationId,
    targetCompId: string,
    message: any
) {
    if (applicationId) {
        const appDefId = clientSpecMapService.getAppDefinitionIdFromApplicationId(ps, applicationId, {
            source: 'triggerSettingsUpdatedByAppId'
        })
        tpaComponentService.settingsUpdated(ps, appDefId, targetCompId, message)
    }
}

const refreshApp = function (ps: PS, comps, queryParams: QueryParams) {
    tpaComponentService.refreshApp(ps, comps, queryParams)
}

const setDeviceType = function (ps: PS, deviceType: string) {
    const deviceTypes = ['desktop', 'mobile']
    if (deviceTypes.includes(deviceType)) {
        tpaComponentService.setCompPreviewDataForAllTPA(ps, 'deviceTypeForTPA', deviceType)
        ps.siteAPI.reloadAppsContainer()
    } else {
        console.error(`${deviceType} is invalid device type! use {desktop, mobile}`)
    }
}

const getAppData = function (ps: PS, applicationId: ApplicationId) {
    return clientSpecMapService.getAppData(ps, applicationId)
}

const setStyleParam = function (ps: PS, styleId: string, compId: string) {
    registerThemeChangeHandler(ps, compId, function (changedData) {
        tpaComponentService.postBackThemeData(ps, compId, changedData)
    })
}

const getSettingsModalParams = function (ps: PS, urlParams, panelParams) {
    return tpaSettingsService.getSettingsModalParams(ps, urlParams, panelParams)
}

const triggerOnWindowPlacementChanged = function (ps: PS, msg: any) {
    tpaEventHandlersService.triggerOnWindowPlacementChanged(msg)
}

const getStyleDataToPassIntoApp = function (ps: PS, compId: string) {
    return tpaStyleService.getStyleDataToPassIntoApp(ps, compId)
}

const getCompStyleDataToPassIntoApp = function (ps: PS, compRef: Pointer) {
    return tpaStyleService.getCompStyleDataToPassIntoApp(ps, compRef)
}

const getNameToFontsKeyMap = function () {
    return tpa.styleUtils.getNameToFontsKeyMap()
}

const isAppInstalledBy = function (ps: PS, appDefinitionId: AppDefinitionId, filterDemoMode?) {
    return installedTpaAppsOnSiteService.isAppInstalledBy(ps, appDefinitionId, filterDemoMode)
}

const isAppInstalledOnPage = function (ps: PS, pageId: string, appDefinitionId: AppDefinitionId, filterDemoMode?) {
    return installedTpaAppsOnSiteService.isAppInstalledOnPage(ps, pageId, appDefinitionId, filterDemoMode)
}

const isLastAppComp = function (ps: PS, appDefinitionId: AppDefinitionId) {
    const comps = installedTpaAppsOnSiteService.getAllAppCompsByAppDefIds(ps, [appDefinitionId])
    return comps && comps.length === 1
}

const isLastAppCompbyAppId = function (ps: PS, applicationId: ApplicationId) {
    if (!applicationId) {
        return false
    }
    const appDefId = clientSpecMapService.getAppDefinitionIdFromApplicationId(ps, applicationId, {
        source: 'isLastAppCompbyAppId'
    })
    return isLastAppComp(ps, appDefId)
}

const getAppByAppDefId = function (ps: PS, appDefId: string) {
    return clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefId)
}

const getSectionRefByPageId = function (ps: PS, pageId: string) {
    return tpaComponentService.getSectionRefByPageId(ps, pageId)
}

const isContainerContainsPremiumTpa = function (ps: PS, containerPointer: Pointer) {
    const tpaPremiumComps = getPremiumTpaRecursive(ps, containerPointer)

    return !_.isEmpty(tpaPremiumComps)
}

const getPremiumTpaRecursive = function (ps: PS, containerPointers: Pointer | Pointer[]) {
    const premiumTPAs = _(getTpaPointersRecursive(ps, containerPointers))
        .map(compPointer => component.data.get(ps, compPointer))
        .filter(tpaCompData => clientSpecMapService.isPremiumApp(ps, tpaCompData.appDefinitionId))
        .map(tpaPremiumCompData =>
            clientSpecMapService.getAppDataByAppDefinitionId(ps, tpaPremiumCompData.appDefinitionId)
        )
        .value()

    return _.uniqBy(premiumTPAs, 'appDefinitionId')
}

const getTpaPointersRecursive = function (ps: PS, componentPointers: Pointer | Pointer[]) {
    componentPointers = asArray(componentPointers)
    let tpaPointers: Pointer[] = []

    _.forEach(componentPointers, function (componentPointer) {
        const compType = component.getType(ps, componentPointer)
        const isTPA = tpaUtils.isTpaByCompType(compType)

        if (isTPA) {
            tpaPointers.push(componentPointer)
        }
        tpaPointers = tpaPointers.concat(component.getTpaChildren(ps, componentPointer))
    })

    return tpaPointers
}

const getPremiumTpaChildrenOnPageByAppId = function (
    ps: PS,
    pagePointer: Pointer,
    tpaApplicationId: string,
    filterSubComponents
) {
    const appDefId = clientSpecMapService.getAppDefinitionIdFromApplicationId(ps, tpaApplicationId, {
        source: 'getPremiumTpaChildrenOnPageByAppId'
    })
    return getPremiumTpaChildrenOnPage(ps, pagePointer, appDefId, filterSubComponents)
}

const getPremiumTpaChildrenOnPage = function (
    ps: PS,
    pagePointer: Pointer,
    appDefinitionId: AppDefinitionId,
    filterSubComponents
) {
    const premiumTpaChildren = getPremiumTpaRecursive(ps, pagePointer)

    if (filterSubComponents) {
        return _.reject(premiumTpaChildren, function (tpaPremiumAppData) {
            if (!appDefinitionId || appDefinitionId !== tpaPremiumAppData.appDefinitionId) {
                return clientSpecMapService.hasSections(ps, tpaPremiumAppData)
            }
        })
    }

    return premiumTpaChildren
}

const closePopupsAndModal = function (ps: PS) {
    ps.siteAPI.removeAllPopups()
    ps.siteAPI.removeModal()
}

const isTpaByCompType = function (ps: PS, compType: string) {
    return tpaUtils.isTpaByCompType(compType)
}

const getSectionsByAppId = function (ps: PS, applicationId: ApplicationId) {
    if (!applicationId) {
        return false
    }
    const appDefId = clientSpecMapService.getAppDefinitionIdFromApplicationId(ps, applicationId, {
        source: 'getSectionsByAppId'
    })
    return getSections(ps, appDefId)
}

const getSections = function (ps: PS, appDefinitionId: AppDefinitionId) {
    const appComps = installedTpaAppsOnSiteService.getAllAppCompsByAppDefIds(ps, [appDefinitionId])
    return _.filter(
        appComps,
        appComp =>
            appComp.type === tpaConstants.DATA_TYPE.TPA_SECTION ||
            appComp.type === tpaConstants.DATA_TYPE.TPA_MULTI_SECTION
    )
}

//DM-8066: appDefinitionId migration public api
const deleteAppByAppId = function (
    ps: PS,
    appDeletionData: AppDataToDelete,
    applicationId: ApplicationId,
    onSuccess?,
    onError?
) {
    if (applicationId) {
        const appDefId = clientSpecMapService.getAppDefinitionIdFromApplicationId(ps, applicationId, {
            source: 'deleteAppByAppId'
        })
        return deleteApp(ps, appDeletionData, appDefId, onSuccess, onError)
    }
}

const deleteApp = function (
    ps: PS,
    appDeletionData: AppDataToDelete,
    appDefinitionId: AppDefinitionId,
    onSuccess?,
    onError?
) {
    return tpaDeleteService.actualDeleteApps(ps, appDeletionData, appDefinitionId).then(onSuccess, () => {
        onError({error: 'error when deleting app'})
    })
}

const getTPAAppDeleteInteractionParamsByAppId = (ps: PS, applicationId: ApplicationId) => {
    const appDefinitionId = _.get(clientSpecMapService.getAppData(ps, applicationId), ['appDefinitionId'])
    return {app_id: appDefinitionId}
}

const getTPAAppDeleteInteractionParams = (ps: PS, appDefinitionId: AppDefinitionId) => {
    return {app_id: appDefinitionId}
}

//DM-8066: appDefinitionId migration public ap
const notifyBeforeApplicationDeleteByAppId = function (ps: PS, applicationId: ApplicationId) {
    let appsPagesToDelete: AppDataToDelete = {appsPagesToDelete: [], appDefIdsToDelete: []}

    if (applicationId) {
        const appDefId = clientSpecMapService.getAppDefinitionIdFromApplicationId(ps, applicationId, {
            source: 'tpa.notifyBeforeApplicationDeleteByAppId'
        })
        appsPagesToDelete = tpaNotifyDeleteService.notifyAppsToDelete(ps, appDefId, true)
    }
    ps.setOperationsQueue.asyncPreDataManipulationComplete(appsPagesToDelete)
}

const notifyBeforeApplicationDelete = function (ps: PS, appDefinitionId: AppDefinitionId) {
    const appsPagesToDelete = tpaNotifyDeleteService.notifyAppsToDelete(ps, appDefinitionId, true)
    ps.setOperationsQueue.asyncPreDataManipulationComplete(appsPagesToDelete)
}

const isAppPermissionsIsRevoked = function (ps: PS, appData) {
    return clientSpecMapService.isAppPermissionsIsRevoked(appData, platformStateService.getAppsState(ps))
}

const fixEcomIfNeeded = function (ps: PS, pageToAddRef) {
    const eComAppDefId = '1380b703-ce81-ff05-f115-39571d94dfcd'
    const eComData = clientSpecMapService.getAppDataByAppDefinitionId(ps, eComAppDefId)

    if (eComData && installedTpaAppsOnSiteService.isAppInstalledBy(ps, eComAppDefId)) {
        const eComPagesData = ps.pointers.data.getDataItemsWithPredicate({appDefinitionId: eComAppDefId}, 'masterPage')
        const eComPages = eComPagesData.map(ps.dal.get)
        const clientSpecMapPages = clientSpecMapService.getAppSections(undefined, eComData)

        const pageIdsFromCSM = _(clientSpecMapPages).map('appPage').map('id').value()
        const pageIdFromEComPagesData = _.map(eComPages, 'tpaPageId')

        const mainSectionWidgetData = clientSpecMapService.getMainSectionWidgetData(undefined, eComData)
        const mainSectionId = mainSectionWidgetData.appPage.id
        let intersection, subPageIdsFromCSM

        const mainSectionInstalled = _.some(eComPages, eComPage => _.startsWith(eComPage.tpaPageId, mainSectionId))

        if (mainSectionInstalled) {
            subPageIdsFromCSM = _.reject(pageIdsFromCSM, id => _.startsWith(id, mainSectionId))
            const subPageIdFromEComPagesData = _.reject(pageIdFromEComPagesData, id => _.startsWith(id, mainSectionId))
            intersection = _.intersection(subPageIdsFromCSM, subPageIdFromEComPagesData)
        }

        if (mainSectionInstalled) {
            if (!_.isEqual(intersection, subPageIdsFromCSM)) {
                tpaComponentCommonService.addHiddenPages(ps, eComData)
            }
        } else if (!_.isEqual(intersection, subPageIdsFromCSM) || _.size(eComPages) === 0) {
            tpaComponentService.provisionSection(ps, pageToAddRef, eComAppDefId)
        }
    }
}
const fixProGallery = function (ps: PS) {
    const proGalleryAppDefId = '14271d6f-ba62-d045-549b-ab972ae1f70e'
    const proGalleryData = clientSpecMapService.getAppDataByAppDefinitionId(ps, proGalleryAppDefId)
    if (proGalleryData && installedTpaAppsOnSiteService.isAppInstalledBy(ps, proGalleryAppDefId)) {
        const proGalleryPage = ps.pointers.data.getDataItemWithPredicate(
            {appDefinitionId: proGalleryAppDefId},
            'masterPage'
        )

        if (!proGalleryPage) {
            tpaComponentCommonService.addHiddenPages(ps, proGalleryData)
        }
    }
}
const PAGE_DATA_PROPS_TO_GET = ['appDefinitionId', 'pageUriSEO', 'tpaPageId', 'id']
const fixPageUriSEOIfNeeded = function (ps: PS) {
    const pagesIds = page.getPageIdList(ps, false, false)
    const pagesWithoutPageUriSEO = _(pagesIds)
        .map(pageId => (ps.extensionAPI as PageExtensionAPI).page.data.pick(pageId, PAGE_DATA_PROPS_TO_GET))
        .filter('appDefinitionId')
        .reject('pageUriSEO')
        .value()

    _.forEach(pagesWithoutPageUriSEO, function (pageData) {
        const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, pageData.appDefinitionId)
        const widgetData = getWidgetDataFromTPAPageId(ps, appData.appDefinitionId, pageData.tpaPageId)
        if (widgetData) {
            const pageUriSEO = tpaComponentCommonService.getPageUriSEO(ps, _.get(widgetData, ['appPage', 'name']))
            page.data.set(ps, pageData.id, {pageUriSEO} as PagesData)
        }
    })
}

const areDocumentServicesHandlersReady = function () {
    return _dsHandlersReady
}

const markDocumentServicesHandlersAreReady = function (isDsHandlersInit: boolean) {
    _dsHandlersReady = isDsHandlersInit
}

const isPageMarkedAsHideFromMenuByAppId = function (ps: PS, applicationId: ApplicationId, tpaPageId: string) {
    if (!applicationId) {
        return false
    }
    const appDefId = clientSpecMapService.getAppDefinitionIdFromApplicationId(ps, applicationId, {
        source: 'isPageMarkedAsHideFromMenuByAppId'
    })
    if (!appDefId) {
        return false
    }
    return menuAPI.isTPAPageMarkedAsHideFromMenu(ps, appDefId, tpaPageId)
}

const sendBIEvent = function (ps: PS, msg, origin: string) {
    tpaUtils.sendBIEvent(ps, msg, origin)
}

const getCompToAddWithOptionalCustomIdRef = function (ps: PS, containerReference, options) {
    return component.getComponentToAddRef(ps, containerReference, options, _.get(options, ['optionalCustomId']))
}

const hasEditorPlatformPart = function (ps: PS, appData) {
    return clientSpecMapService.hasEditorPlatformPart(appData)
}

const setComponentDefinitionModifier = (psDoNotUseInsideHook: PS, modifierFn?: (props: any) => any) => {
    hooks.unregisterHooks(hooks.HOOKS.ADD_TPA.COMPONENT_DEFINITION_MODIFIER)
    if (_.isFunction(modifierFn)) {
        //it is not good to register an anonymous or bound function as a hook. however, if you are, you MUST:
        // 1. unregister beforehand (otherwise this causes a memory leak in tests
        // 2. not use ps inside. if you need to use ps, then you should receive it from whoever executes the hook
        hooks.registerHook(hooks.HOOKS.ADD_TPA.COMPONENT_DEFINITION_MODIFIER, (compDefinition, containerRef) => {
            const modified = modifierFn({
                containerRef,
                layout: compDefinition.layout,
                layouts: compDefinition.layouts,
                scopedLayouts: compDefinition.scopedLayouts,
                layoutResponsive: compDefinition.layoutResponsive,
                style: compDefinition.style,
                componentType: compDefinition.componentType,
                appDefinitionId: _.get(compDefinition, ['data', 'appDefinitionId']) || '',
                widgetId: _.get(compDefinition, ['data', 'widgetId']) || '',
                skin: compDefinition.skin
            })

            _.assign(compDefinition, {
                ...(modified.layout ? {layout: modified.layout} : {}),
                ...(modified.layouts ? {layouts: modified.layouts} : {}),
                ...(modified.scopedLayouts ? {scopedLayouts: modified.scopedLayouts} : {}),
                ...(modified.layoutResponsive ? {layoutResponsive: modified.layoutResponsive} : {}),
                ...(modified.style ? {style: modified.style} : {}),
                skin: modified.skin || compDefinition.skin
            })
            _.forEach(['breakpoints', 'variants', 'layouts', 'scopedLayouts'], key => {
                if (modified[key]) {
                    compDefinition[key] = modified[key]
                }
            })
            _.assign(containerRef, {...(modified.containerRef ? modified.containerRef : {})})
        })
    }
}

const setDefaultSectionSkin = (ps: PS, defaultSkin: string) => {
    _.set(ps, ['runtimeConfig', 'tpa', 'section', 'defaults', 'skin'], defaultSkin)
}

const setDefaultIsContainableState = (ps: PS, isContainable: boolean) => {
    _.set(ps, ['runtimeConfig', 'tpa', 'section', 'defaults', 'isContainable'], isContainable)
}

const getAppSectionsToInstallByAppId = (ps: PS, applicationId: ApplicationId) => {
    if (applicationId) {
        const appDefId = clientSpecMapService.getAppDefinitionIdFromApplicationId(ps, applicationId, {
            source: 'getAppSectionsToInstallByAppId'
        })
        return clientSpecMapService.getAppSectionsToInstall(ps, appDefId)
    }
}

const getMainSectionWidgetDataByAppId = (ps: PS, applicationId: ApplicationId) => {
    if (applicationId) {
        return clientSpecMapService.getMainSectionWidgetDataFromApplicationId(ps, applicationId)
    }
}

const isAppProvisionedOnServerByAppId = (ps: PS, applicationId: ApplicationId) => {
    if (!applicationId) {
        return false
    }
    const appDefId = clientSpecMapService.getAppDefinitionIdFromApplicationId(ps, applicationId, {
        source: 'isAppProvisionedOnServerByAppId'
    })
    return clientSpecMapService.isAppProvisionedOnServer(ps, appDefId)
}

const getDefaultLayoutByAppId = (ps: PS, applicationId: ApplicationId, widgetId: number | string) => {
    if (applicationId) {
        const appDefId = clientSpecMapService.getAppDefinitionIdFromApplicationId(ps, applicationId, {
            source: 'getDefaultLayoutByAppId'
        })
        return tpaComponentService.getDefaultLayout(ps, appDefId, widgetId)
    }
}

const getPublicDataByAppId = (ps: PS, applicationId: ApplicationId, compPointer: Pointer, callback: Callback1<any>) => {
    if (applicationId) {
        const appDefId = clientSpecMapService.getAppDefinitionIdFromApplicationId(ps, applicationId, {
            source: 'getPublicDataByAppId'
        })
        return tpaDataService.getPublicData(ps, appDefId, compPointer, callback)
    }
}

const getAppValueByAppId = (ps: PS, applicationId: ApplicationId, key: string, callback: Callback1<any>) => {
    if (applicationId) {
        const appDefId = clientSpecMapService.getAppDefinitionIdFromApplicationId(ps, applicationId, {
            source: 'getAppValueByAppId'
        })
        return tpaDataService.getAppValue(ps, appDefId, key, callback)
    }
}

const getAppValuesByAppId = (ps: PS, applicationId: ApplicationId, keys, callback: Callback1<any>) => {
    if (applicationId) {
        const appDefId = clientSpecMapService.getAppDefinitionIdFromApplicationId(ps, applicationId, {
            source: 'getAppValuesByAppId'
        })
        return tpaDataService.getAppValues(ps, appDefId, keys, callback)
    }
}
const api = {
    initialize,
    /**
     *
     * @return if a component type is a tpa
     */
    isTpaByCompType,

    /**
     *set deviceType in tpa url
     */
    setDeviceType,

    addApp: tpaComponentService.addAppWrapper,
    getCompToAddWithOptionalCustomIdRef,

    /**
     * @return if a page hideFromMenu flag is true in the client spec map
     */
    isPageMarkedAsHideFromMenu: {
        byAppId: isPageMarkedAsHideFromMenuByAppId,
        byAppDefId: menuAPI.isTPAPageMarkedAsHideFromMenu
    },

    /**
     * return if the container contains at least one premium tpa app
     *
     * @param {Object} containerPointer - container reference
     * @return return if the container contains at least on premium tpa app
     * @example documentServices.tpa.isContainerContainsPremiumTpa(containerPointer)
     */
    isContainerContainsPremiumTpa,

    /**
     * return all premium TPA child components of a given component ref.
     *
     * @param {Object} containerPointer - container reference
     * @return all container's premium TPA child components
     * @example documentServices.tpa.getPremiumTpaChildren(componentsPointers)
     */
    getPremiumTpaChildren: getPremiumTpaRecursive,

    /**
     * recursively searches for tpa components among the given components and returns their pointers
     *
     * @param {Object} componentPointers - pointers of components
     * @return pointers for all TPA components that were found
     * @example documentServices.tpa.getTpaPointersRecursive(componentPointers)
     */
    getTpaPointersRecursive,

    /**
     * filter out from tpa app premiums comps that are sub components.
     * for example: sub component of hotels section
     *
     * @param {Object} containerPointer - container reference
     * @param tpaApplicationId {String} - tpaApplicationId on page
     * @param filterSubComponents {Boolean} - indicate if to filter sub components
     * @return all container's TPA premium child components filtering out the sub components if required
     * @example documentServices.tpa.getPremiumTpaChildrenOnPage(pagePointer, tpaApplicationId, filterSubComponents)
     */
    getPremiumTpaChildrenOnPage: {
        byAppId: getPremiumTpaChildrenOnPageByAppId,
        byAppDefId: getPremiumTpaChildrenOnPage
    },

    /**
     * close all opened popups and modal
     *
     * @example documentServices.tpa.closePopupsAndModal()
     */
    closePopupsAndModal,

    /**
     * Add widget to the document if it was provisioned (e.g. from My Account) but not installed
     *
     * @param {string} appDefinitionId of the widget being added
     * @param {Object} [options]
     * {String} [widgetId] of the widget being added
     *
     * @example documentServices.tpa.addSystemApp('135aad86-9125-6074-7346-29dc6a3c9bcf',
     * { widgetId: '135aae78-42c9-63b5-d09a-77233cebc1c4' })
     */
    addSystemApp: _.partial(tpaComponentService.addAppWrapper, tpaConstants.TYPE.TPA_WIDGET),
    provisionSystemApp,

    /**
     * widget
     * @member documentServices.tpa.widget
     * @namespace documentServices.tpa.widget
     */
    widget: {
        /**
         * Add widget to the document
         * For displaying the new widget refresh the site
         *
         * @param {string} appDefinitionId of the widget being added
         * @param {Object} [options]
         * {String} [widgetId] of the widget being added
         * {Object} [Layout] object describes the app width, height, x and y location - if no layout is given a default one will be taken
         * {String} [pageId] Wix pageId that the widget should be added to
         * @example documentServices.tpa.widget.add('135aad86-9125-6074-7346-29dc6a3c9bcf',
         * { widgetId: '135aae78-42c9-63b5-d09a-77233cebc1c4', pageId: 'mainPage', layout: {"width": 300, "height": 200, "x": 200, "y": 200 } })
         */
        add: _.partial(tpaComponentService.addAppWrapper, tpaConstants.TYPE.TPA_WIDGET),
        provisionWidget: tpaComponentService.provisionWidget,

        /**
         * Removes a tpa widget from a given compId.
         * @param {Object} compPointer of the widget
         *
         * @example
         * documentServices.tpa.widget.delete(compPointer, onSuccess, onError)
         */
        delete: tpaWidgetService.deleteWidget,
        notifyBeforeDelete: tpaWidgetService.notifyBeforeWidgetDelete,
        getTPAWidgetDeleteInteractionParams: tpaWidgetService.getTPAWidgetDeleteInteractionParams,

        /**
         * Returns an array with the pages that will be removed when the widget is removed
         * @param {Object} compPointer of the widget

         * @example
         * documentServices.tpa.widget.getPagesToBeDeleted(compPointer)
         */
        getPagesToBeDeleted: tpaWidgetService.getPagesToBeRemovedWithWidget,

        /**
         * Duplicates the given widget into the given page.
         *
         * @param {object} compPointer of the Widget id in query
         * @param {string} pageId of the page to paste in
         * @example
         * documentServices.tpa.widget.duplicate(compPointer,'c1qpo')
         */
        duplicate: duplicateWidget,
        cloneTpaCompData: componentAppDataClone.cloneTpaCompData,
        addAndCloneTpaCompData: componentAppDataClone.addAndCloneTpaCompData
    },

    /**
     * section
     * @member documentServices.tpa.section
     * @namespace documentServices.tpa.section
     */
    section: {
        /**
         * Add section to the document and navigate to it
         *
         * @param {string} appDefinitionId of the widget being added
         * @param {Object} [options]
         * {String} [widgetId] of the multi multi section being added
         * @return an object contains the new added page id that contains the new section and the section id
         * @example
         * documentServices.tpa.section.add("135aad86-9125-6074-7346-29dc6a3c9bcf")
         */
        add: tpaComponentService.addSection,
        provisionSection: tpaComponentService.provisionSection,

        /**
         * Add section to the document and navigate to it
         *
         * @param {string} appDefinitionId of the widget being added
         * @param {Object} options
         * @param {String} pageId of the multi multi section being added
         * @param {String} [title] of the new page
         * @example
         * const options = {
         *   pageId: 'tpaPageId',
         *   title: 'title'
         * }
         * documentServices.tpa.section.addMultiSection('appDef', options)
         */
        addMultiSection: tpaComponentService.addMultiSection,
        provisionMultiSection: tpaComponentService.provisionMultiSection,

        /**
         * Add sub section to the document
         *
         * @param {string} appDefinitionId of the section being added
         * @param {Object} options
         * @param {String} pageId of the sub section being added
         * @param {String} [title] of the new page
         * @example
         * const options = {
         *   pageId: 'tpaPageId',
         *   title: 'title'
         * }
         * documentServices.tpa.section.addSubSection('appDef', options)
         */
        addSubSection: tpaComponentService.addSubSection,

        /**
         * Removes a section for a given page id.
         *
         * @param {string} pageId of the tpa section
         * @param {Object} [options]
         * @example
         * documentServices.tpa.deleteSection("k66c")
         */
        delete: tpaSectionService.actualDeleteSection,
        notifyBeforeDelete: tpaSectionService.notifyDeleteSection,
        getTPAWidgetDeleteInteractionParams: tpaSectionService.getTPAWidgetDeleteInteractionParams,

        /**
         * check if the section is already installed in the site
         *
         * @param {string} appDefinitionId of the app
         *
         * @example
         * documentServices.tpa.section.alreadyInstalled("135aad86-9125-6074-7346-29dc6a3c9bcf")
         */
        alreadyInstalled: sectionAlreadyInstalled,

        /**
         * checks if a tpa section page is already installed in the site
         *
         * @param {Number} applicationId of the app
         * @param {string} tpaPageId of the section
         *
         * @example
         * documentServices.tpa.section.isSectionInstalledByTpaPageId('1234', 'product_page')
         */
        isSectionInstalledByTpaPageId: {
            byAppId: installedTpaAppsOnSiteService.isSectionInstalledByTpaPageIdByAppId,
            byAppDefId: installedTpaAppsOnSiteService.isSectionInstalledByTpaPageId
        },

        /**
         * check if the a given page id has a section installed on it.
         *
         * @param {string} pageId
         *
         * @example
         * documentServices.tpa.section.getPageData("pageId")
         */
        getPageData,

        /**
         * Gets the section ref on a given page
         * @param {string} pageId
         * @return the section on the given page or undefined if none exists
         */
        getSectionRefByPageId,

        /**
         * defaults
         * @member documentServices.tpa.section.defaults
         * @namespace documentServices.tpa.section.defaults
         */
        defaults: {
            /**
             * skin
             * @member documentServices.tpa.section.defaults.skin
             * @namespace documentServices.tpa.section.defaults.skin
             */
            skin: {
                /**
                 * Sets the default skin a section will be created with
                 *
                 * @param {string} skin
                 *
                 * @example
                 * documentServices.tpa.section.defaults.skin.set("wysiwyg.viewer.skins.page.TransparentPageSkin")
                 */
                set: setDefaultSectionSkin
            },
            isContainable: {
                /**
                 * Controls whether the TPA section should be containable or not
                 *
                 * @param {boolean} isContainable
                 *
                 * @example
                 * documentServices.tpa.section.defaults.isContainable.set(true)
                 */
                set: setDefaultIsContainableState
            }
        }
    },

    /**
     * app
     * @member documentServices.tpa.app
     * @namespace documentServices.tpa.app
     */
    app: {
        /**
         * installs an application if it doesn't exist yet
         *
         * @param {string} appDefinitionId of the application
         * @param [options] object which can contain 'callback', 'widgetId', 'showPageAddedPanel', 'x', 'y', 'width', 'height', 'shouldNavigate', 'pageId', 'containerRef'
         *
         * @example
         * documentServices.tpa.app.add('1363adbc-c783-b1e0-d8ef-4a661300ac8c', {})
         */
        provisionApp: tpaAddService.provisionApp,
        add: tpaAddService.addApp,
        getAddTPAAppInteractionParams: tpaAddService.getAddTPAAppInteractionParams,
        getAddTPAInteractionParams: tpaAddService.getAddTPAInteractionParams,
        /**
         * Gets the app data by application id
         *
         * @param {string} applicationId of the application
         * @return the application data from the client spec map
         *
         * @example
         * documentServices.tpa.app.getData(16)
         */
        getData: {
            byAppId: getAppData,
            byAppDefId: getAppByAppDefId
        },

        /**
         * Checks if this application is hybrid
         *
         * @param {string} applicationId of the application in query
         * @example
         * documentServices.tpa.app.isHybrid(16)
         */
        isHybrid: {
            byAppId: isHybridAppByAppId,
            byAppDefId: clientSpecMapService.isHybridApp
        },

        /**
         * Checks if this application is dashboard only
         *
         * @param {string} applicationId of the application in query
         * @example
         * documentServices.tpa.app.isDashboardAppOnly(16)
         */
        isDashboardAppOnly: {
            byAppId: isDashboardAppOnlyByAppId,
            byAppDefId: isDashboardAppOnly
        },

        /**
         * Checks if this application was upgraded to premium
         *
         * @param {string} applicationId of the application in query
         * @example
         * documentServices.tpa.app.isPremium(16)
         */
        isPremium: {
            byAppId: isPremiumAppByAppId,
            byAppDefId: clientSpecMapService.isPremiumApp
        },

        /**
         * Checks if an application has premium offering
         *
         * @param {string} applicationId of the application in query
         * @example
         * documentServices.tpa.app.hasPremiumOffering(16)
         */
        hasPremiumOffering: {
            byAppId: hasPremiumOfferingByAppId,
            byAppDefId: clientSpecMapService.hasPremiumOffering
        },

        /**
         * Checks if this application has only one component
         *
         * @param {string} applicationId of the application in query
         * @example
         * documentServices.tpa.app.isLastAppComp(16)
         */
        isLastAppComp: {
            byAppId: isLastAppCompbyAppId,
            byAppDefId: isLastAppComp
        },

        /**
         * Returns true if and only if the application has been installed in the site
         *
         * @param {string} appDefinitionId of the application
         * @params {boolean} [filterDemoMode] filter out demo mode app
         * @return {boolean} application was installed
         *
         * @example
         * documentServices.tpa.app.isInstalled('13016589-a9eb-424a-8a69-46cb05ce0b2c')
         */
        isInstalled: isAppInstalledBy,

        /**
         * Returns widget data from pageId
         *
         * @param {string} applicationId of the application in query
         * @param {string} tpaPageId of the app widget
         * @example
         * documentServices.tpa.getWidgetDataFromTPAPageId('appDefId', 'page_id')
         */
        getWidgetDataFromTPAPageId,

        /**
         * Returns widget data from tpaWidgetId
         *
         * @param {string} applicationId of the application in query
         * @param {string} tpaPageId of the app widget
         * @example
         * documentServices.tpa.getWidgetDataFromTPAWidgetId('appDefId', 'tpa_widget_id')
         */
        getWidgetDataFromTPAWidgetId,

        /**
         * Register a callback to be called when an app is installed
         *
         * @param {string} appDefinitionId of the application
         * @return {function} callback when the app was installed
         *
         * @example
         * documentServices.tpa.app.registerOnAppInstalled('13016589-a9eb-424a-8a69-46cb05ce0b2c', function () {])
         */
        registerOnInstalled: registerOnAppInstalled,

        /**
         * Register a callback to be called when an app is removed
         *
         * @param {string} appDefinitionId of the application
         * @return {function} callback when the app was installed
         *
         * @example
         * documentServices.tpa.app.registerOnAppDeleted('13016589-a9eb-424a-8a69-46cb05ce0b2c', function () {])
         */
        registerOnDeleted: registerOnAppDeleted,

        /**
         * Get all comps on site by Application Id
         *
         * @param {string|string[]} applicationIds - Id/s of the application/s
         *
         * @example
         * documentServices.tpa.app.getAllCompsByApplicationId('18')
         * @example
         * documentServices.tpa.app.getRenderedReactCompsByApplicationId(['23', '42'])
         */
        getAllComps: {
            byAppId: installedTpaAppsOnSiteService.getAllAppCompsByAppId,
            byAppDefId: installedTpaAppsOnSiteService.getAllAppCompsByAppDefIds
        },

        /**
         * Get all comps on site by appDefIds
         *
         * @param {string|string[]} appDefIds - Id/s of the appDefId/s
         *
         * @example
         * documentServices.tpa.app.getRenderedReactCompsByApplicationId(['2222-3333', '4444-2222'])
         */
        getAllCompsByAppDefIds: installedTpaAppsOnSiteService.getAllAppCompsByAppDefIds,

        /**
         * Returns true if and only if a given app has at least one section.
         *
         * @example
         * documentServices.tpa.app.hasSections(appData)
         */
        hasSections: clientSpecMapService.hasSections,

        getDefaultLayout: {
            byAppId: getDefaultLayoutByAppId,
            byAppDefId: tpaComponentService.getDefaultLayout
        },

        /**
         * url
         * @member documentServices.tpa.app.url
         * @namespace documentServices.tpa.app.url
         */
        url: {
            /**
             * Gets the settings url of the app
             *
             * @param {string} applicationId of the application
             * @param {string} [widgetId] of the application
             * @return {string} the settings url
             *
             * @example
             * documentServices.tpa.app.url.getSettingsUrl(16, '135aae78-42c9-63b5-d09a-77233cebc1c4', 'comp-1234')
             */
            getSettingsUrl: {
                byAppId: tpaSettingsService.getSettingsUrl_publicApi,
                byAppDefId: tpaSettingsService.getSettingsUrlByAppDefId
            },

            /**
             * Gets the settings modal url of the app
             *
             * @param {object} urlParams must contain url
             * @param {string} [compId] compId
             * @return {string} the settings modal url
             *
             * @example
             * documentServices.tpa.app.url.getSettingsModalUrl({url: 'url'}, 'comp-1234')
             */
            getSettingsModalUrl: tpaSettingsService.getSettingsModalUrl
        },

        /**
         * Returns this application extensions' widgets
         *
         * @param {Object} appData the application data
         * @example
         * documentServices.tpa.app.getExtensionsWidgets({})
         */
        getExtensionsWidgets,

        /**
         * Returns this application installed dependent apps appData
         *
         * @param {Number} applicationId the main application id
         * @example
         * documentServices.tpa.app.getInstalledDependentAppsData(1234)
         */
        getInstalledDependentAppsData: {
            byAppId: installedTpaAppsOnSiteService.getInstalledDependentAppsDataByAppId,
            byAppDefId: installedTpaAppsOnSiteService.getInstalledDependentAppsData
        },

        /**
         * Gets the first application component's page id and comp id. In case the application has a section and
         * a widget - the section's page id anc comp id are returned.
         *
         * @param {string} applicationId of the application in query
         * @param [boolean] useDefaultWidget if true will return the default widget data
         * @example
         * documentServices.tpa.getFirstAppCompPageId("1363adbc-c783-b1e0-d8ef-4a661300ac8c")
         */
        getFirstAppCompPageId,

        refreshApp,
        shouldDeleteWholeApp: tpaSectionService.shouldDeleteWholeApp,
        /**
         * Returns true iff multi section installed (has more than one main section).
         *
         * @param {string} applicationId The application ID of the app
         * @example
         * documentServices.tpa.section.isMultiSectionInstalled("20")
         */
        isMultiSectionInstalled: {
            byAppId: installedTpaAppsOnSiteService.isMultiSectionInstalledByAppId,
            byAppDefId: installedTpaAppsOnSiteService.isMultiSectionInstalled
        },

        /**
         * Returns the app sections
         *
         * @param {string} applicationId The application ID of the app
         * @example
         * documentServices.tpa.app.getSections("20")
         */
        getSections: {
            byAppId: getSectionsByAppId,
            byAppDefId: getSections
        },

        /**
         * Returns true if app is proviosned on server and false otherwise
         *
         * @param {string} applicationId The application ID of the app
         * @example
         * documentServices.tpa.app.isAppProvisionedOnServer("20")
         */
        isAppProvisionedOnServer: {
            byAppId: isAppProvisionedOnServerByAppId,
            byAppDefId: clientSpecMapService.isAppProvisionedOnServer
        },

        /**
         * Returns true if app's permissions are revoked
         *
         * @param {string} applicationId The application ID of the app
         * @example
         * documentServices.tpa.app.isPermissionsRevoked("20")
         */
        isPermissionsRevoked: isAppPermissionsIsRevoked,

        /**
         * Returns the widget data of the main section
         *
         * @param {string} applicationId The application ID of the app
         * @example
         * documentServices.tpa.app.getMainSectionWidgetData("20")
         */
        getMainSectionWidgetData: {
            byAppId: getMainSectionWidgetDataByAppId,
            byAppDefId: clientSpecMapService.getMainSectionWidgetDataFromAppDefinitionId,
            byData: clientSpecMapService.getMainSectionWidgetData
        },

        /**
         * Returns an array of the sections that should be installed automatically in the correct order
         *
         * @param {Number} applicationId The application ID of the app
         * @example
         * documentServices.tpa.app.getSectionsToAutoInstallWidgetData(applicationId)
         */
        getSectionsToAutoInstallWidgetData: {
            byAppId: getAppSectionsToInstallByAppId,
            byAppDefId: clientSpecMapService.getAppSectionsToInstall
        },

        /**
         * Returns true if the app has a main section
         *
         * @param {object} appData The data of the app
         * @example
         * documentServices.tpa.app.hasMainSection(appData)
         */
        hasMainSection: clientSpecMapService.hasMainSection,

        /**
         *  Returns true if the app is a super app (an app Developed internally by Wix)
         *  @param {ps} ps
         *  @param {string} compId a component ID the app is from
         */
        isSuperAppByCompId(ps: PS, compId: string) {
            return clientSpecMapService.isSuperAppByCompId(ps, compId)
        },

        /**
         *  Returns true if the app has platform part
         *  @param {object} appData The data of the app
         */
        hasEditorPlatformPart,

        /**
         * Removes all app TPA components and notify the app platform script
         *
         * @param {string} applicationId
         * @param {Function} [onSuccess]
         * @param {Function} [onError]
         *
         * @example
         * documentServices.tpa.app.delete('1234', ()=> {}, ()=> {})
         */
        delete: {
            byAppId: deleteAppByAppId,
            byAppDefId: deleteApp
        },
        notifyBeforeApplicationDelete: {
            byAppId: notifyBeforeApplicationDeleteByAppId,
            byAppDefId: notifyBeforeApplicationDelete
        },
        getTPAAppDeleteInteractionParams: {
            byAppId: getTPAAppDeleteInteractionParamsByAppId,
            byAppDefId: getTPAAppDeleteInteractionParams
        }
    },

    /**
     * change
     * @member documentServices.tpa.change
     * @namespace documentServices.tpa.change
     */
    change: {
        /**
         * Notify the ds the editor mode has changed
         *
         * @param {string} editorMode the editor new mode
         *
         * @example
         * documentServices.tpa.change.editMode('preview')
         */
        editMode: editModeChange,

        /**
         * Notify the ds the site was published
         *
         *
         * @example
         * documentServices.tpa.change.sitePublished()
         */
        siteSaved,

        /**
         * Notify the ds the site was published
         *
         *
         * @example
         * documentServices.tpa.change.sitePublished()
         */
        sitePublished,

        /**
         * Notify the ds the editor switch from mobile to desktop or vice versa
         *
         *
         * @example
         * documentServices.tpa.change.deviceType()
         */
        deviceType: deviceTypeChange,

        /**
         * register
         * @member documentServices.tpa.change.register
         * @namespace documentServices.tpa.change.register
         */
        register: {
            /**
             * Register for an editor event.
             * Callback will be called when a custom editor event happened such as "ADI_SETTINGS_PANEL_CLOSED"
             * event payload will have a type field
             * @param {function} callback A callback function that will get called when an editor event happened
             * @example
             * documentServices.tpa.change.register.editorEventHandler(function({type}) {
             *    console.log(type)
             * })
             */
            editorEventHandler: registerEditorEventHandler,

            /**
             * Register for component loaded handler.
             * Callback will be called, with an object containing compPointer property, every time a TPA calls Wix.Performance.applicationLoaded() SDK func.
             *
             * @param {function} callback A callback function that will get called once a comp has loaded.
             * @example
             * documentServices.tpa.change.register.compLoadedHandler(function(data) {
             *      console.log(data.compPointer)
             * })
             */
            compLoadedHandler: registerCompLoadedHandler,

            /**
             * Register for component delete handler.
             *
             * @param {String} compId of the component
             * @param {function} handler A callback function that will get called once a delete comp event happens.
             */
            deleteCompHandler: registerDeleteCompHandler,
            /**
             * Register for component disconnect handler.
             *
             * @param {String} compId of the component
             * @param {function} handler A callback function that will get called once a disconnect comp event happens.
             */
            disconnectCompHandler: registerDisconnectCompHandler,

            /**
             * Register for editor mode change handler.
             *
             * @param {String} compId of the component
             * @param {function} handler A callback function that will get called once the editor mode has changed.
             */
            editModeChangeHandler: registerEditModeChangeHandler,

            /**
             * Register for device type change handler.
             *
             * @param {String} compId of the component
             * @param {function} handler A callback function that will get called once the device type has changed.
             */
            deviceTypeChangeHandler: registerDeviceTypeChangeHandler,

            /**
             * Register for editor theme change handler.
             *
             * @param {String} compId of the component
             * @param {function} handler A callback function that will get called once the editor theme has changed.
             */
            themeChangeHandler: registerThemeChangeHandler,

            /**
             * Register for window placement  change handler.
             *
             * @param {String} compId of the component
             * @param {function} handler A callback function that will get called once the editor mode has changed.
             */
            windowPlacementChangedHandler: registerWindowPlacementChangedHandler,

            /**
             * Register for settings update change handler.
             *
             * @param {String} compId of the component
             * @param {function} handler A callback function that will get called once the editor mode has changed.
             */
            settingsUpdatedHandler: registerSettingsUpdatedHandler,

            /**
             * Register for set public data change handler.
             *
             * @param {String} compId of the component
             * @param {function} handler A callback function that will get called once the editor mode has changed.
             */
            registerPublicDataChangedHandler: {
                byAppId: registerPublicDataChangedHandlerByAppId,
                byAppDefId: registerPublicDataChangedHandler
            },

            /**
             * Register for site published handler.
             *
             * @param {String} compId of the component
             * @param {function} handler A callback function that will get called once the site is published
             */
            sitePublishedHandler: registerSitePublishedHandler,

            /**
             * Register a handler for site saved event.
             *
             * @param {String} compId - id of the component
             * @param {function} handler A callback function that will get called once the site is saved
             */
            siteSavedHandler: registerSiteSavedHandler,

            /**
             * Register a handler for undo & redo events
             *
             * @param {String} compId - id of the component
             * @param {function} handler A callback function that will get called once revision changed
             */
            historyChangeHandler: registerHistoryChangeHandler
        },

        /**
         * trigger
         * @member documentServices.tpa.change.trigger
         * @namespace documentServices.tpa.change.trigger
         */
        trigger: {
            /**
             * Notify the ds when an editor event happened
             * @param {type} the type of the editor event
             */
            editorEvent: triggerEditorEvent,

            /**
             * Tell the ds that the window placement was changed
             * @param  {String} placement of the glued widget
             */
            windowPlacement: triggerOnWindowPlacementChanged,

            /**
             * Tell the ds that the setting app triggered an update
             *
             * @param {String} applicationId the app application id
             * @param {String} targetCompId the target comp id or * to broadcast the data to all the app components
             * @param {Object} message the data to broadcast
             *
             * @example
             * const applicationId = 16
             * const targetCompIds = 'compId'
             * const message = {
             *      data: 'show-all'
             * }
             * documentServices.tpa.triggerSettingsUpdated(applicationId, targetCompId, message)
             */
            settingsUpdated: {
                byAppId: triggerSettingsUpdatedByAppId,
                byAppDefId: tpaComponentService.settingsUpdated
            }
        }
    },

    getComponentDefinition,

    getSettingsModalParams,

    pending: {
        /**
         * Add a pending app
         * @param appDefId
         * @example
         * documentServices.tpa.pending.add('appDefId')
         */
        add: pendingAppsService.addPendingDashboardApp,
        /**
         * Dismiss a pending app from the meta-site
         * @param appDefId
         * @example
         * documentServices.tpa.pending.dismiss('appDefId')
         */
        dismiss: pendingAppsService.dismiss,
        /**
         * Returns premium pending applications
         *
         * @return {Array} list of pending applications
         *
         * @example
         * documentServices.tpa.pending.getPendingApps()
         */
        getPendingApps: pendingAppsService.getPendingAppsFromSiteMetaData
    },

    preAdd: {
        setComponentDefinitionModifier,
        setComponentLayoutModifier: setComponentDefinitionModifier //deprecated
    },

    /**
     * Returns hybrid pending applications
     *
     * @return {Array} list of pending applications
     * @deprecated
     *
     * @example
     * documentServices.tpa.getPendingApps()
     */
    getPendingApps: pendingAppsService.getPendingApps,

    /**
     * Returns premium pending applications
     *
     * @return {Array} list of pending applications
     * @deprecated
     * @example
     * documentServices.tpa.getPendingApps()
     */
    getPremiumPendingApps: pendingAppsService.getPremiumPendingApps,

    /**
     * AppMarket
     * @member documentServices.tpa.appMarket
     * @namespace documentServices.tpa.appMarket
     */
    appMarket: {
        /**
         * Returns app market data for the given appDefinitionId
         *
         * @param {String} appDefinitionId
         * @returns {Object} app market data
         */
        getData: appMarketService.getAppMarketData,

        /**
         * Returns app market data for the given pageId
         *
         * @param {String} pageId
         * @returns {Object} app market data
         */
        getDataForPage: appMarketService.getAppMarketDataForPage,

        /**
         * Returns app market data for the given appDefinitionId or retrieves it async
         *
         * @param {String} appDefinitionId
         * @returns Promise
         */
        getDataAsync: appMarketService.getAppMarketDataAsync,

        /**
         * Returns app market URL which is its iframe src
         *
         * @param {Object} editorParams Set of parameters from editor
         * @returns String
         * @example
         * documentServices.tpa.appMarket.getUrl({
         *  openAppDefId: '1234',
         *  experiments: 'exp1',
         *  query: 'tag1',
         *  origin: 'http://editor.wix.com',
         *  appDefinitionId: '1234'
         * })
         */
        getUrl: appMarketService.getAppMarketUrl,

        /**
         * Returns app market info URL which is its iframe src
         *
         * @param {Object} editorParams Set of parameters from editor
         * @returns String
         * @example
         * documentServices.tpa.appMarket.getInfoUrl({
         *  openAppDefId: '1234',
         *  experiments: 'exp1',
         *  query: 'tag1',
         *  origin: 'http://editor.wix.com',
         *  appDefinitionId: '1234'
         * })
         */
        getInfoUrl: appMarketService.getAppMarketInfoUrl,

        /**
         * Returns the app info URL, on the reviews tab
         *
         * @param {Object} editorParams Set of parameters from editor:
         * origin (mandatory) ,
         * openMarketOrigin (mandatory),
         * test (optional)
         * @returns String
         * @example
         * documentServices.tpa.appMarket.getAppReviewsUrl({
         *  origin: 'http://editor.wix.com',
         *  openMarketOrigin: 'settings_panel,
         *  tests: 'exp1'
         * })
         */
        getAppReviewsUrl: appMarketService.getAppReviewsUrl,

        /**
         * Returns app market permissions URL which is its iframe src
         *
         * @param {Object} editorParams Set of parameters from editor
         * @returns String
         * @example
         * documentServices.tpa.appMarket.getPermissionsUrl({
         *  openAppDefId: '1234',
         *  experiments: 'exp1',
         *  query: 'tag1',
         *  origin: 'http://editor.wix.com',
         *  appDefinitionId: '1234'
         * })
         */
        getPermissionsUrl: appMarketService.getAppMarketPermissionsUrl,

        /**
         * Returns app market related apps
         * @returns Array
         * @example
         * documentServices.tpa.appMarket.getRelatedApps(function(apps){console.log(apps)})
         */
        getRelatedApps: appMarketService.getRelatedApps,

        /**
         * Returns app market packages with purchase url and currency info for the given appDefinitionId and instanceId
         *
         * @param {String} appDefinitionId
         * @param {String} instanceId
         * @returns Promise
         * @example
         * documentServices.tpa.appMarket.getPackages('appDefId','instanceId')
         */
        getPackages: appMarketService.getPackages
    },

    billing: {
        /**
         * Returns premium apps
         * @returns Array containing appDefinitionIds of upgraded apps
         * @example
         * documentServices.tpa.billing.getPremiumApps(function(appDefIds){console.log(appDefIds)})
         */
        getPremiumApps: appMarketService.getPremiumApps,

        /**
         * Returns the meta site upgrade url
         * @param {Object} url params referralAdditionalInfo
         * @returns String
         * @example
         * documentServices.tpa.billing.getSiteUpgradeUrl({referralAdditionalInfo : 'gfpp'})
         */
        getSiteUpgradeUrl: billingService.getSiteUpgradeUrl,

        /**
         * Gets upgrade to premium url for an app.
         *
         * @function
         * @memberof documentServices.tpa
         * @param {string} applicationId
         * @param {string} vendorProductId
         * @param {string} paymentCycle could be 'YEARLY' or 'MONTHLY', default: 'MONTHLY'
         * @parma {Object} options param like pp_type and referralAdditionalInfo
         * @return {string} upgrade page payment url
         *
         * @example
         * documentServices.tpa.billing.getAppUpgradeUrl(16, 'Premium1', 'MONTHLY', options)
         */
        getAppUpgradeUrl: {
            byAppId: billingService.getAppUpgradeUrlByAppId,
            byAppDefId: billingService.getAppUpgradeUrl
        }
    },

    /**
     * style
     * @member documentServices.tpa.style
     * @namespace documentServices.tpa.style
     */
    style: {
        /**
         * Set style param handler for SDK 1.22.0-1.24.0.
         *
         * @param {String} compId the component id
         * @param {Object} data the style param data.
         */
        setParamOldSDK: setStyleParam,

        setStyleParam: tpaStyleService.setStyleParam,

        setCompStyleParam: tpaStyleService.setCompStyleParam,

        mapStyleParamData: tpaStyleService.mapStyleParamData,

        mapWixParamsToCssValues: tpaStyleService.mapWixParamsToCssValues,

        /**
         * Returns theme and style data to the given component
         *
         * @param {String} component id
         * @returns Object style data
         */
        get: getStyleDataToPassIntoApp,

        /**
         * Returns theme and style data to the given component
         *
         * @param {Object} componentPointer - component reference
         * @returns Object style data
         */
        getByComp: getCompStyleDataToPassIntoApp,

        postBackThemeData: tpaComponentService.postBackThemeData,

        /**
         * Returns Name To FontsKey Map
         *
         * @returns Returns Name To FontsKey Map
         */
        getNameToFontsKeyMap,

        /**
         * Returns a map of tpa colors to site colors
         *
         * @returns Returns Name To FontsKey Map
         */
        getTpaColorsToSiteColors: tpaStyleService.getTpaColorsToSiteColors
    },

    provision: {
        refreshAppSpecMap: {
            byAppId: provisionService.refreshSpecMap
        }
    },

    /**
     * Tpa constants
     *
     * Constants Definitions for tpa components:
     * TYPE: Describe the tpa type constants: TPASection, TPAMultiSection and etc
     * @example documentServices.tpa.constants.TYPE.TPA_WIDGET
     *
     * COMP_TYPES: Describes tpa component types: 'wysiwyg.viewer.components.tpapps.TPASection', 'wysiwyg.viewer.components.tpapps.TPAMultiSection'
     * @example documentServices.tpa.constants.COMP_TYPE.TPA_WIDGET
     *
     * TPA_COMP_TYPES: Deprecated - Describes tpa component types: 'tpa.viewer.components.tpapps.TPASection', 'tpa.viewer.components.tpapps.TPAMultiSection'
     * @example documentServices.tpa.constants.TPA_COMP_TYPE.TPA_WIDGET
     *
     * SKINS: Describes tpa component skins: 'wysiwyg.viewer.skins.TPASectionSkin', 'wysiwyg.viewer.skins.TPAWidgetSkin'
     * @example documentServices.tpa.constants.SKINS.TPA_WIDGET
     *
     */
    constants: tpaConstants,

    /**
     * Tpa BI error and events constants
     *
     */
    bi: {
        /*
         * TPA Bi errors constants
         *
         * @example documentServices.tpa.bi.errors.EVENT_TYPE_NOT_SUPPORTED
         */
        errors,

        /*
         * Send a BI event for a message to the SDK endpoint from a specified origin
         *
         * @example documentServices.tpa.bi.sendBIEvent(msg, 'editor')
         */
        sendBIEvent
    },

    comp: {
        /*
         * resize a comp
         *
         * @example documentServices.tpa.comp.resize(compPointer, {width: 100, height: 100})
         */
        resize: resizeComponent,

        /*
         * set a comp externalId
         *
         * @example documentServices.tpa.comp.setExternalId(compPointer, '123-456-789')
         */
        setExternalId,

        /*
         * get a comp externalId if one is set
         *
         * @example documentServices.tpa.comp.getExternalId(compPointer)
         */
        getExternalId,

        /*
         * post message back to the comp iframe
         */
        postMessageBackToApp: tpaComponentService.postMessageBackToApp,

        /**
         * Used for checking if a certain component from an app was added to the site
         *
         * @param {String} widgetId of the component
         * @return {Boolean} true if the widget is installed, false otherwise
         * @example documentServices.tpa.comp.isInstalled('135aad86-9125-6074-7346-29dc6a3c9bcf')
         */
        isInstalled: installedTpaAppsOnSiteService.isAppComponentInstalled,

        /**
         * Adds a tpa widget or section to the site. if the comp application doesn't already exist, it will first install the application.
         *
         * @param [options] object which can contain:
         * appDefinitionId (mandatory),
         * componentType (mandatory),
         *  copyStyle,
         *  styleId,
         *  widget - object containing widgetId, allPages, wixPageId
         *  page - object containing title, pageId
         *
         * @example
         * documentServices.tpa.comp.add({
         *  appDefinitionId: '1380b703-ce81-ff05-f115-39571d94dfcd',
         *  componentType: 'PAGE',
         *  page: {pageId:'order_history'},
         *  callback: console.log
         *  }
         * })
         *
         * documentServices.tpa.comp.add({
         *  appDefinitionId: '1380b703-ce81-ff05-f115-39571d94dfcd',
         *  componentType: 'WIDGET',
         *  widget: {widgetId:'grid_gallery'},
         *  callback: console.log
         *  }
         * })
         */
        add: tpaAddService.addComponent,
        provisionComp: tpaAddService.provisionComp
    },

    data: {
        set: {byCompPointer: tpaDataService.set, byAppDefId: tpaDataService.setAppValue},
        setMultiple: tpaDataService.setMultiple,
        app: {
            get: {
                byAppId: getAppValueByAppId,
                byAppDefId: tpaDataService.getAppValue
            },
            getMulti: {
                byAppId: getAppValuesByAppId,
                byAppDefId: tpaDataService.getAppValues
            }
        },
        comp: {
            get: tpaDataService.getComponentValue,
            getMulti: tpaDataService.getComponentValues
        },
        getPublicData: {
            byAppId: getPublicDataByAppId,
            byAppDefId: tpaDataService.getPublicData
        },
        remove: tpaDataService.remove,
        SCOPE: tpaDataService.SCOPE,

        // TODO: remove once santa-editor version is deployed
        get: tpaDataService.get,
        getMulti: tpaDataService.getMulti
    },
    page: {
        getSubPages: tpaPageService.getSubPages
    },
    getSiteMap: (ps: PS) => ps.siteAPI.getSiteMap(),
    __privates: {
        fixEcomIfNeeded,
        areDocumentServicesHandlersReady,
        fixProGallery
    }
}

export default api
