import type {SerializedCompStructure, PagesStructure, PS, SerializedPageStructure} from '@wix/document-services-types'
import _ from 'lodash'
import pageData from './pageData'
import imageClientApi from '@wix/image-client-api/dist/imageClientApi'
import type {DefaultDefinitionsAPI} from '@wix/document-manager-extensions/dist/src/extensions/defaultDefinitions/defaultDefinitions'

function getBlankPageStructure({
    ps,
    pageId,
    overrides
}: {
    ps: PS
    pageId?: string
    overrides?: Partial<PagesStructure>
}): SerializedPageStructure {
    const pageStructure = (ps.extensionAPI.defaultDefinitions as DefaultDefinitionsAPI).createBlankPageDefinition(
        overrides
    )
    pageStructure.data = _.assign(pageStructure.data, {
        pageUriSEO: pageData.getValidPageUriSEO(ps, pageId, 'blank'),
        pageBackgrounds: _.get(pageData.getPageDataWithoutIds(ps, ps.siteAPI.getPrimaryPageId()), 'pageBackgrounds')
    })
    return pageStructure
}

function getBlankPopupPageContainerStructure(): SerializedCompStructure {
    return {
        type: 'Container',
        components: [],
        skin: 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer',
        props: {
            type: 'PopupContainerProperties',
            metaData: {
                schemaVersion: '1.0'
            },
            horizontalAlignment: 'center',
            verticalAlignment: 'center',
            alignmentType: 'nineGrid',
            horizontalOffset: 0,
            verticalOffset: 0
        },
        design: {
            type: 'MediaContainerDesignData',
            metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false
            },
            background: {
                type: 'BackgroundMedia',
                metaData: {
                    isPreset: false,
                    schemaVersion: '1.0',
                    isHidden: false
                },
                color: '#FFFFFE',
                colorOpacity: 1,
                alignType: 'center',
                fittingType: 'fill',
                scrollType: 'none',
                colorOverlay: '',
                colorOverlayOpacity: 0
            }
        },
        layout: {
            width: 340,
            height: 276,
            x: 620,
            y: 80,
            scale: 1,
            rotationInDegrees: 0,
            anchors: []
        },
        componentType: 'wysiwyg.viewer.components.PopupContainer',
        style: {
            type: 'TopLevelStyle',
            metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false
            } as any,
            style: {
                properties: {
                    'alpha-bg': '1',
                    bg: 'rgba(255,255,255,1)'
                },
                propertiesSource: {
                    'alpha-bg': 'value',
                    bg: 'value'
                },
                groups: {}
            },
            componentClassName: 'wysiwyg.viewer.components.PopupContainer',
            pageId: '',
            compId: '',
            styleType: 'custom',
            skin: 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer',
            id: 'style-ili7m0pe'
        }
    }
}

function getBlankPopupPageStructure(ps: PS, overrides?: Partial<PagesStructure>) {
    const pageStructure = (ps.extensionAPI.defaultDefinitions as DefaultDefinitionsAPI).createBlankPageDefinition(
        overrides
    )
    pageStructure.data = _.assign({}, pageStructure.data, {
        pageBackgrounds: {
            desktop: {
                custom: true,
                ref: {
                    type: 'BackgroundMedia',
                    color: '{color_11}',
                    alignType: 'top',
                    fittingType: 'fill',
                    scrollType: 'fixed'
                },
                isPreset: false
            },
            mobile: {
                custom: true,
                ref: {
                    type: 'BackgroundMedia',
                    color: '{color_11}',
                    alignType: 'top',
                    fittingType: 'fill',
                    scrollType: 'fixed'
                },
                isPreset: true
            }
        },
        indexable: false,
        isPopup: true
    })

    const props = {
        popup: {
            closeOnOverlayClick: true
        }
    }

    pageStructure.props = _.defaults(
        {
            type: 'PageProperties',
            metaData: {
                schemaVersion: '1.0'
            },
            mobile: _.cloneDeep(props),
            desktop: _.cloneDeep(props)
        },
        pageStructure.props
    )

    pageStructure.components = [getBlankPopupPageContainerStructure()]

    return pageStructure
}

function getBlankBackground() {
    return {
        desktop: {
            custom: true,
            ref: {
                type: 'BackgroundMedia',
                color: '{color_11}',
                alignType: imageClientApi.alignTypes.CENTER,
                fittingType: imageClientApi.fittingTypes.SCALE_TO_FILL,
                scrollType: 'none'
            },
            isPreset: true
        },
        mobile: {
            custom: true,
            ref: {
                type: 'BackgroundMedia',
                color: '{color_11}',
                alignType: imageClientApi.alignTypes.CENTER,
                fittingType: imageClientApi.fittingTypes.SCALE_TO_FILL,
                scrollType: 'none'
            },
            isPreset: true
        }
    }
}

export default {
    getBlankPageStructure,
    getBlankPopupPageStructure,
    getBlankPopupPageContainerStructure,
    getBlankBackground
}
