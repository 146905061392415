import type {MobileAlgoContext, PluginContextHelper} from '../types'

export const createPluginContextHelper = (name: string): PluginContextHelper => {
    const ctxKey = `plugin_${name}::`
    const ctxSet = (ctx: MobileAlgoContext, key: string, value: any) => {
        ctx[ctxKey + key] = value
    }
    const ctxGet = (ctx: MobileAlgoContext, key: string) => {
        return ctx[ctxKey + key]
    }

    const enable = (ctx: MobileAlgoContext) => {
        ctxSet(ctx, 'enabled', true)
    }
    const disable = (ctx: MobileAlgoContext) => {
        ctxSet(ctx, 'enabled', false)
    }

    const isEnabled = (ctx: MobileAlgoContext) => {
        return ctxGet(ctx, 'enabled')
    }

    return {
        ctxSet,
        ctxGet,
        isEnabled,
        enable,
        disable,
        name
    }
}
