import type {CompRef, CompStructure, Pointer, PossibleViewModes, PS} from '@wix/document-services-types'
import _ from 'lodash'
import arrangementUtils from './arrangementUtils'
import layoutSettingsUtils from './layoutSettingsUtils'
import arrayUtils from './arrayUtils'
import {coreUtils} from '@wix/santa-ds-libs'
import componentsMetaData from '../../componentsMetaData/componentsMetaData'
import * as santaCoreUtils from '@wix/santa-core-utils'

const {MASTER_PAGES_SECTIONS} = coreUtils.masterPageLayoutUtils
const PAGES_CONTAINER_TYPE = 'wysiwyg.viewer.components.PagesContainer'
const getDisplayedChildPointerInIndex = (ps: PS, container, index: number) =>
    ps.pointers.components.getChildren(container)[index]

const isDesktopMasterPage = (ps: PS, compPointer: Pointer) =>
    ps.pointers.components.isSameComponent(
        compPointer,
        ps.pointers.components.getMasterPage(santaCoreUtils.constants.VIEW_MODES.DESKTOP)
    )

const getPagesContainer = (ps: PS, viewMode: PossibleViewModes) => ps.pointers.components.getPagesContainer(viewMode)

const isPagesContainerByType = (compType: string) => compType === PAGES_CONTAINER_TYPE

const isPagesContainer = (ps: PS, compPointer: Pointer) =>
    isPagesContainerByType(componentsMetaData.getComponentType(ps, compPointer))

const isFixedPosition = (ps: PS, compPointer: Pointer) => {
    const isFixedPointer = ps.pointers.getInnerPointer(compPointer, 'layout.fixedPosition')
    return !!ps.dal.get(isFixedPointer)
}

const isFixedPositionByStructure = (compStructure: CompStructure) =>
    _.get(compStructure, ['layout', 'fixedPosition'], false)

const canSwapMasterPageChildren = (ps: PS, source: CompRef, target: CompRef) => {
    if (!arrangementUtils.canSwapComponents(ps, source, target)) {
        return false
    }

    if (isFixedPosition(ps, source) && isFixedPosition(ps, target)) {
        return true
    }

    if (isMasterPageSection(ps, source)) {
        return isMasterPageSection(ps, target)
    }

    return !isMasterPageSection(ps, target) || isPagesContainer(ps, target)
}

const switchChildrenRange = (ps: PS, container: Pointer, groupARange, groupBRange) => {
    const dal = ps.dal.full
    const pointers = ps.pointers.full

    const childrenContainerPointer = pointers.components.getChildrenContainer(container)
    const children = dal.get(childrenContainerPointer)
    const groups = _.sortBy([groupARange, groupBRange], range => range[0])
    const newChildren = arrayUtils.swapGroups(children, groups[0], groups[1])

    dal.set(childrenContainerPointer, newChildren)
}

const moveChildrenRangeToEnd = (ps: PS, container: Pointer, groupRange) => {
    const dal = ps.dal.full
    const pointers = ps.pointers.full

    const childrenContainerPointer = pointers.components.getChildrenContainer(container)
    const children = dal.get(childrenContainerPointer)
    const newChildren = arrayUtils.moveGroupToEndOfArray(children, groupRange)

    dal.set(childrenContainerPointer, newChildren)
}

const moveChildrenRangeToStart = (ps: PS, container: Pointer, groupRange) => {
    const dal = ps.dal.full
    const pointers = ps.pointers.full

    const childrenContainerPointer = pointers.components.getChildrenContainer(container)
    const children = dal.get(childrenContainerPointer)
    const newChildren = arrayUtils.moveGroupToStartOfArray(children, groupRange)

    dal.set(childrenContainerPointer, newChildren)
}

const moveChildrenRangeToIndex = (ps: PS, container: Pointer, groupRange, index: number) => {
    const dal = ps.dal.full
    const pointers = ps.pointers.full

    const childrenContainerPointer = pointers.components.getChildrenContainer(container)
    const children = dal.get(childrenContainerPointer)
    const newChildren = arrayUtils.moveGroupToIndex(children, groupRange, index)

    dal.set(childrenContainerPointer, newChildren)
}

const getSingleCompRange = (ps: PS, compPointer: Pointer) => {
    const parentPointer = ps.pointers.full.components.getParent(compPointer)
    const childrenPointers = ps.pointers.full.components.getChildren(parentPointer)
    const compIndex = _.findIndex(childrenPointers, {
        id: santaCoreUtils.displayedOnlyStructureUtil.getRepeaterTemplateId(compPointer.id)
    })

    return [compIndex, compIndex]
}

const getSectionIndexRange = (ps: PS, sectionPointer: Pointer) =>
    isPagesContainer(ps, sectionPointer)
        ? getPagesContainerRange(ps, sectionPointer)
        : getSingleCompRange(ps, sectionPointer)

const moveMasterPageSectionForward = (ps: PS, compPointer: Pointer) => {
    const nextCompIndex = getNextCompIndexInDisplayedJson(ps, compPointer)
    const parentPointer = ps.pointers.components.getParent(compPointer)
    const targetComp = getDisplayedChildPointerInIndex(ps, parentPointer, nextCompIndex)

    switchChildrenRange(ps, parentPointer, getSectionIndexRange(ps, compPointer), getSectionIndexRange(ps, targetComp))
}

const isSiteShowOnAllPagesZindexMigrated = (ps: PS) =>
    _.get(layoutSettingsUtils.getLayoutSettings(ps), 'soapCompsAroundPagesContainer', false)

const isMasterPageSectionByCompType = (compType: string, viewMode: PossibleViewModes) =>
    _.includes(MASTER_PAGES_SECTIONS[viewMode], compType)
const isMasterPageSection = (ps: PS, compPointer: CompRef) =>
    isMasterPageSectionByCompType(componentsMetaData.getComponentType(ps, compPointer), compPointer.type)

const getPagesContainerRangeStart = (ps: PS, childrenPointers: Pointer[], pagesContainerIndex: number) => {
    if (pagesContainerIndex === 0) {
        return pagesContainerIndex
    }

    return (
        arrayUtils.findPrev(
            childrenPointers,
            child => isMasterPageSection(ps, child as CompRef) || isFixedPosition(ps, child),
            pagesContainerIndex
        ) + 1
    )
}

const getPagesContainerRangeEnd = (ps: PS, childrenPointers: Pointer[], pagesContainerIndex: number) => {
    const lastChildIndex = childrenPointers.length - 1

    if (pagesContainerIndex === lastChildIndex) {
        return pagesContainerIndex
    }

    const firstSectionFromRight = arrayUtils.findNext(
        childrenPointers,
        child => isMasterPageSection(ps, child as CompRef) || isFixedPosition(ps, child),
        pagesContainerIndex
    )

    return firstSectionFromRight === -1 ? lastChildIndex : firstSectionFromRight - 1
}

const moveMasterPageChildToFront = (ps: PS, compPointer: Pointer) => {
    const parentPointer = ps.pointers.components.getParent(compPointer)

    if (isMasterPageSection(ps, compPointer as CompRef)) {
        moveChildrenRangeToEnd(ps, parentPointer, getSectionIndexRange(ps, compPointer))
    } else {
        const masterPageChildren = ps.pointers.full.components.getChildren(parentPointer)
        const pagesContainerIndex = _.findIndex(masterPageChildren, {id: 'PAGES_CONTAINER'})
        const pagesContainerRangeEnd = getPagesContainerRangeEnd(ps, masterPageChildren, pagesContainerIndex)

        moveChildrenRangeToIndex(ps, parentPointer, getSingleCompRange(ps, compPointer), pagesContainerRangeEnd)
    }
}

const findPagesContainerRange = (ps: PS, pagesContainer: Pointer, childrenPointers: Pointer[]) => {
    const pagesContainerIndex = _.findIndex(childrenPointers, {id: pagesContainer.id})

    return [
        getPagesContainerRangeStart(ps, childrenPointers, pagesContainerIndex),
        getPagesContainerRangeEnd(ps, childrenPointers, pagesContainerIndex)
    ]
}

const getPagesContainerRange = (ps: PS, pagesContainer: Pointer) => {
    const parentPointer = ps.pointers.full.components.getParent(pagesContainer)

    return findPagesContainerRange(ps, pagesContainer, ps.pointers.full.components.getChildren(parentPointer))
}

const moveMasterPageChildToBack = (ps: PS, compPointer: Pointer) => {
    const parentPointer = ps.pointers.components.getParent(compPointer)

    if (isMasterPageSection(ps, compPointer as CompRef)) {
        moveChildrenRangeToStart(ps, parentPointer, getSectionIndexRange(ps, compPointer))
    } else {
        const masterPageChildren = ps.pointers.full.components.getChildren(parentPointer)
        const pagesContainerIndex = _.findIndex(masterPageChildren, {id: 'PAGES_CONTAINER'})
        const pagesContainerRangeStart = getPagesContainerRangeStart(ps, masterPageChildren, pagesContainerIndex)

        moveChildrenRangeToIndex(ps, parentPointer, getSingleCompRange(ps, compPointer), pagesContainerRangeStart)
    }
}

const moveMasterPageSectionBackward = (ps: PS, compPointer: Pointer) => {
    const prevCompIndex = getPrevCompIndexInDisplayedJson(ps, compPointer)
    const parentPointer = ps.pointers.components.getParent(compPointer)
    const targetComp = getDisplayedChildPointerInIndex(ps, parentPointer, prevCompIndex)

    switchChildrenRange(ps, parentPointer, getSectionIndexRange(ps, compPointer), getSectionIndexRange(ps, targetComp))
}

const getNextCompIndexInDisplayedJson = (ps: PS, compPointer: Pointer) => {
    const parentPointer = ps.pointers.components.getParent(compPointer)
    const childrenPointers = ps.pointers.components.getChildren(parentPointer)
    const compIndex = _.findIndex(childrenPointers, {id: compPointer.id})

    return arrayUtils.findNext(
        childrenPointers,
        targetPointer => canSwapMasterPageChildren(ps, compPointer as CompRef, targetPointer as CompRef),
        compIndex
    )
}

const getPrevCompIndexInDisplayedJson = (ps: PS, compPointer: Pointer) => {
    const parentPointer = ps.pointers.components.getParent(compPointer)
    const childrenPointers = ps.pointers.components.getChildren(parentPointer)
    const compIndex = _.findIndex(childrenPointers, {id: compPointer.id})

    return arrayUtils.findPrev(
        childrenPointers,
        targetPointer => canSwapMasterPageChildren(ps, compPointer as CompRef, targetPointer as CompRef),
        compIndex
    )
}

const getRange = (start: number, end: number): [number, number] => [start, end]

const rejectEmptyRanges = (ranges: [number, number][]) =>
    _.reject(ranges, ([startIndex, endIndex]) => startIndex > endIndex)

const getValidIndexRangesForChild = (ps: PS, compPointer: Pointer, parentPointer: Pointer) => {
    const children = ps.pointers.full.components.getChildren(parentPointer)
    const childrenWithoutMovingChild = _.reject(children, child =>
        ps.pointers.components.isSameComponent(compPointer, child)
    )
    const pagesContainer = getPagesContainer(ps, compPointer.type as PossibleViewModes)
    const [soapGroupStart, soapGroupEnd] = findPagesContainerRange(ps, pagesContainer, childrenWithoutMovingChild)

    const shouldContainInSoapGroup =
        !(isMasterPageSection(ps, compPointer as CompRef) && !isPagesContainer(ps, compPointer)) &&
        !isFixedPosition(ps, compPointer)

    return shouldContainInSoapGroup
        ? [getRange(soapGroupStart, soapGroupEnd + 1)]
        : rejectEmptyRanges([
              getRange(0, soapGroupStart),
              getRange(soapGroupEnd + 1, childrenWithoutMovingChild.length)
          ])
}

const findValidIndexForChild = (ps: PS, compPointer: Pointer, parentPointer: Pointer, preferredIndex: number) => {
    const children = ps.pointers.full.components.getChildren(parentPointer)
    const childrenWithoutMovingChild = _.reject(children, child =>
        ps.pointers.components.isSameComponent(compPointer, child)
    )
    preferredIndex = _.isUndefined(preferredIndex) ? childrenWithoutMovingChild.length : preferredIndex

    const validIndexRangesForChild = getValidIndexRangesForChild(ps, compPointer, parentPointer)

    return arrayUtils.findNearestValidIndex(childrenWithoutMovingChild, preferredIndex, validIndexRangesForChild)
}

const getValidIndexRangesForNewChild = (ps: PS, compStructure: CompStructure, parentPointer: Pointer) => {
    const viewMode = parentPointer.type as PossibleViewModes
    const children = ps.pointers.full.components.getChildren(parentPointer)
    const pagesContainer = getPagesContainer(ps, viewMode)
    const [soapGroupStart, soapGroupEnd] = findPagesContainerRange(ps, pagesContainer, children)

    const shouldContainInSoapGroup =
        !(
            isMasterPageSectionByCompType(compStructure.componentType, viewMode) &&
            !isPagesContainerByType(compStructure.componentType)
        ) && !isFixedPositionByStructure(compStructure)

    return shouldContainInSoapGroup
        ? [getRange(soapGroupStart, soapGroupEnd + 1)]
        : rejectEmptyRanges([getRange(0, soapGroupStart), getRange(soapGroupEnd + 1, children.length)])
}

const findValidIndexForNewChild = (ps: PS, compStructure: CompStructure, parentPointer: Pointer) => {
    const validIndexRangesForNewChild = getValidIndexRangesForNewChild(ps, compStructure, parentPointer)
    const children = ps.pointers.full.components.getChildren(parentPointer)

    return arrayUtils.findNearestValidIndex(children, children.length, validIndexRangesForNewChild)
}

const shouldUseMasterPageArrangement = (ps: PS, parentPointer: Pointer) =>
    isDesktopMasterPage(ps, parentPointer) && isSiteShowOnAllPagesZindexMigrated(ps)

export default {
    shouldUseMasterPageArrangement,
    isMasterPageSection,
    getPrevCompIndexInDisplayedJson,
    getNextCompIndexInDisplayedJson,
    findValidIndexForNewChild,
    findValidIndexForChild,
    moveMasterPageSectionBackward,
    moveMasterPageSectionForward,
    moveMasterPageChildToFront,
    moveMasterPageChildToBack
}
