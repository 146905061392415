export const integrations = {
    dataCapsuleWrapper: {},
    promoteAnalytics: {},
    fullStory: {},
    ravenUtils: {
        // TODO fix
        breadcrumb: () => {},
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        captureError: (e?: Error, args?: any) => {}
    }
}
