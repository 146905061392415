import type {DmApis, Extension, DeepFunctionMap} from '@wix/document-manager-core'
import _ from 'lodash'
import {createImmutableDAL, ImmutableDAL} from '../../utils/immutableDAL'

interface WixAppsDAL extends ImmutableDAL, DeepFunctionMap {}
/**
 * Creates the wixapps extension that will provide the wixappsDAL service to privateServices
 */
const createExtension = (): Extension => {
    const wixappsDAL = createImmutableDAL({}) as WixAppsDAL

    const createExtensionAPI = () => ({
        services: {
            wixappsDAL
        }
    })

    const initialize = async ({dal, pointers}: DmApis) => {
        const clientSpecMap = dal.get(pointers.rendererModel.getClientSpecMap())
        const appBuilderCSMEEntry = _.find(clientSpecMap, {type: 'appbuilder'})
        const siteMetaData = dal.get(pointers.rendererModel.getSiteMetaData())
        const avoidAppPart2 = siteMetaData.renderHints && siteMetaData.renderHints.containsAppPart2 === false

        if (appBuilderCSMEEntry && !avoidAppPart2) {
            wixappsDAL.takeInitialSnapshot()
        }
    }

    return {
        name: 'wixapps',
        createExtensionAPI,
        initialize
    }
}

export {createExtension}
