/** Expose everything in santa-ds-libs that does not rely on `zepto` or on `experiment`.
 * Those packages bring a configuration burden to the consumer, and not everyone needs them.
 */
export * as coreUtils from './coreUtils/basic-index'
export * as utils from './utils/basic-index'
import * as warmupUtils from './warmupUtils'

export * as platformInit from './platformInit'
export * as tpa from './tpa/basic-index'
export * as wixappsBuilder from './wixapps/builder/index'
export * as wixappsClassics from './wixapps/classics/index'
export * as wixappsCore from './wixapps/core/index'
import * as skinExports from './skinExports'
export * as santa from './santa'

export type AjaxFn = warmupUtils.AjaxFn
export type AjaxOp = warmupUtils.AjaxOp
export type AjaxLibrary = warmupUtils.AjaxLibrary

export type {PageInfo} from './warmupUtils/src/wixUrlParser/wixUrlParser'
export type {TpaUrlBuilder} from './tpa/tpaComponents/common/TPAUrlBuilder'
const {ajaxLibrary} = warmupUtils
export {ajaxLibrary, warmupUtils, skinExports}

export type {CharSet} from './coreUtils/basic-index'
