const _ = require('lodash')
const {DATA_TYPES, NAMESPACE_MAPPING} = require('@wix/document-services-json-schemas/dist/constants.json')
const migrateStylableUtils = require('./index')
const dataUtils = require('../../helpers/dataUtils')

const BLOCKS_APP_DEF_ID = '46b2ad43-5720-41d2-8436-2058979cb53f'

/**
 * @param {object} props
 * @param {object} props.styles
 * @param {import('./index').SelectorsMapping} props.selectorsMappings
 * @param {string} props.newSkin
 * @param {Array<string>} props.systemStyleIDs
 * @returns {Array<import('./index').ParsedStyleItem>}
 */
const migrateSystemStyles = ({styles, selectorsMappings, newSkin, systemStyleIDs}) => {
    const systemStyles = systemStyleIDs
        .map(id => styles[id])
        .filter(item => _.get(item, 'style.properties.$st-css') !== undefined) // not all of supported systemStyles will be defined and be valid styleItems
    const parsedStyleItems = migrateStylableUtils.parseStylableStyleItems(systemStyles, {
        selectorsMappings
    })

    // SystemStyles undergo same transformations as ComponentStyles, except for breakpoint: they do not need to be merged by breakpoint
    const migratedStyleItems = _.flow([
        migrateStylableUtils.migrateProperties,
        styleItems => migrateStylableUtils.migrateStyleItemsSkin(styleItems, newSkin),
        migrateStylableUtils.createPropertiesSource
    ])(parsedStyleItems)
    return migratedStyleItems
}
/**
 *
 * @param {object} props
 * @param {object} props.styles
 * @param {object} props.variants
 * @param {Array<import('./index').ComponentStructure>} props.components
 * @param {object} props.mobileOnlyComponentsIds
 * @param {import('./index').SelectorsMapping} props.selectorsMappings
 * @param {string} props.newSkin
 * @returns {Array<{migratedComponent: import('./index').ComponentStructure, migratedStyleItems: Array<import('./index').ParsedStyleItem>, shouldMigrate: boolean}>}
 */
const getMigratedComponentAndStyleItems = ({
    styles,
    variants,
    components,
    mobileOnlyComponentsIds,
    selectorsMappings,
    newSkin
}) =>
    components.map(component => {
        const compStyleItems = migrateStylableUtils.extractComponentStyleItems({
            component,
            styles,
            variants,
            mobileOnlyComponentsIds
        })

        if (!compStyleItems.length) {
            return {
                migratedComponent: component,
                migratedStyleItems: [],
                shouldMigrate: false
            }
        }

        const parsedStyleItems = migrateStylableUtils.parseStylableStyleItems(compStyleItems, {
            selectorsMappings
        })

        const migratedStyleItems = _.flow([
            migrateStylableUtils.mergeStylesByBreakpoints,
            migrateStylableUtils.migrateProperties,
            styleItems => migrateStylableUtils.migrateStyleItemsSkin(styleItems, newSkin),
            migrateStylableUtils.createPropertiesSource
        ])(parsedStyleItems)

        const migratedComponent = migrateStylableUtils.migrateComponentSkin(component, newSkin)

        return {
            migratedComponent,
            migratedStyleItems,
            shouldMigrate: true
        }
    })

/**
 * @param {object} props
 * @param {object} props.data
 * @param {object} props.clientSpecMap
 * @returns {boolean}
 */
const shouldIgnorePageEnvironment = (data, clientSpecMap) => {
    return (
        _.some(clientSpecMap, {appDefinitionId: BLOCKS_APP_DEF_ID}) &&
        _.some(data[NAMESPACE_MAPPING.variants], ({type}) => type === 'Preset')
    )
}

/**
 * @param {object} props
 * @param {object} props.structure
 * @param {object} props.data
 * @param {string} props.compType
 * @param {{getUniqueId: any}} props.uniqueIdGenerator
 * @param {object} props.clientSpecMap
 * @param {import('./index').SelectorsMapping} props.selectorsMappings
 * @param {string} props.newSkin
 * @param {boolean} [props.isMasterPage]
 * @param {Array<string>} [props.systemStyleIDs]
 * @returns {void}
 */
function migrateStylableComponentsByCompType({
    structure,
    data,
    compType,
    uniqueIdGenerator,
    clientSpecMap,
    selectorsMappings,
    newSkin,
    isMasterPage = false,
    systemStyleIDs = []
}) {
    if (shouldIgnorePageEnvironment(data, clientSpecMap)) {
        return
    }

    // update page data with style object if not exists
    migrateStylableUtils.initiateStyleObject(data)
    const {components, mobileOnlyComponentsIds, mobileComps} = migrateStylableUtils.getAllComponentsOfType(
        structure,
        compType
    )
    const getNewStyleId = () => uniqueIdGenerator.getUniqueId(DATA_TYPES.theme, '-')

    const styles = data[NAMESPACE_MAPPING.style]
    const variants = data[NAMESPACE_MAPPING.variants]

    const migratedComponentsAndStyleItems = getMigratedComponentAndStyleItems({
        styles,
        variants,
        components: Object.values(components),
        mobileOnlyComponentsIds,
        selectorsMappings,
        newSkin
    })

    migratedComponentsAndStyleItems.forEach(({migratedComponent, migratedStyleItems, shouldMigrate}) => {
        if (!shouldMigrate) {
            return
        }

        const {styleId} = migratedComponent
        const compHasRefArray = dataUtils.refArray.isRefArray(styles[styleId])

        if (compHasRefArray) {
            migratedStyleItems.forEach(migratedStyleItem => {
                migrateStylableUtils.updateStyleItem(styles, {
                    ...migratedStyleItem,
                    style: migrateStylableUtils.migratedStyleToStyleProperties(migratedStyleItem)
                })
            })
        } else {
            migrateStylableUtils.updateOldComponentStructure({
                styles,
                migratedComponent,
                getNewStyleId,
                compStyleItem: migratedStyleItems[0],
                isMobileOnlyComp: _.includes(mobileOnlyComponentsIds, migratedComponent.id)
            })
        }

        Object.assign(components[migratedComponent.id], migratedComponent)
        const correspondingMobileComp = mobileComps[migratedComponent.id]
        if (correspondingMobileComp) {
            Object.assign(mobileComps[migratedComponent.id], {
                ...correspondingMobileComp,
                styleId: migratedComponent.styleId,
                skin: migratedComponent.skin
            })
        }
    })
    if (isMasterPage) {
        const systemStyles = migrateSystemStyles({styles, selectorsMappings, newSkin, systemStyleIDs})
        systemStyles.forEach(styleItem => {
            migrateStylableUtils.updateStyleItem(styles, {
                ...styleItem,
                style: migrateStylableUtils.migratedStyleToStyleProperties(styleItem)
            })
        })
    }
}

module.exports = {
    migrateStylableComponentsByCompType,
    getMigratedComponentAndStyleItems
}
