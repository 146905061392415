import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import pathUtils from '../utils/pathUtils'

const FIELD_TYPE_ORDER = {
    String: 1,
    'wix:Date': 2,
    'wix:RichText': 3,
    'String|AsPrice': 4,
    'wix:Image': 5,
    'wix:Video': 6,
    'String|AsButton': 7
}

/**
 * Performs an IN-PLACE sort the given type definition's fields, according to the FIELD_TYPE_ORDER map (title field will always be first).
 * @param typeDef
 */
function reOrderFields(typeDef) {
    if (typeDef?.fields) {
        typeDef.fields = _.sortBy(typeDef.fields, function (field) {
            if (field.name === 'title') {
                return Number.MIN_VALUE
            }
            return FIELD_TYPE_ORDER[getFieldTypeKey(field)] || Number.MAX_VALUE
        })
    }
}

/**
 * @param field
 * @returns {string} returns a field key built from its type and showAs metdata (for example: String|AsButton)
 */
function getFieldTypeKey(field) {
    const showAsHint = field.metadata?.showAsHint
    return _.compact([field.type, showAsHint]).join('|')
}

function getType(ps: PS, typeId: string) {
    const type = ps.wixappsDAL.getByPath(pathUtils.getTypePath(typeId))
    reOrderFields(type)
    return type
}

export default {
    /**
     * Returns a type definition
     * @param {PS} ps Private Services
     * @param {string} id Type definition ID
     * @returns {Object} The type definition object with the requested ID
     */
    getType
}
