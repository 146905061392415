import type {Layout, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import component from '../../component/component'
import componentsMetaData from '../../componentsMetaData/componentsMetaData'
import layoutConstraintsUtils from '../../structure/utils/layoutConstraintsUtils'
import type {LayoutCallback} from './utils/layoutHooksUtils'

function getRowMargin(ps: PS, columnsContainerCompPtr: Pointer) {
    return component.properties.get(ps, columnsContainerCompPtr).rowMargin
}

function isShortening(currColumnsContainerLayout: Layout, newLayout: Layout) {
    return _.isNumber(newLayout.height) && newLayout.height < currColumnsContainerLayout.height
}

function isShorteningFromBottom(currColumnsContainerLayout: Layout, newLayout: Layout) {
    if (!isShortening(currColumnsContainerLayout, newLayout)) {
        return false
    }

    return !_.isNumber(newLayout.y) || currColumnsContainerLayout.y === newLayout.y
}

function getUpdatedLayoutForIndividualColumns(
    ps: PS,
    columnsContainerCompPtr: Pointer,
    updatedColumnsContainerLayout: Layout
) {
    const currColumnsContainerLayout = component.layout.get(ps, columnsContainerCompPtr)
    const rowMargin = getRowMargin(ps, columnsContainerCompPtr)

    const layout: any = {
        height: updatedColumnsContainerLayout.height - rowMargin * 2 // eslint-disable-line no-mixed-operators
    }

    if (_.isNumber(updatedColumnsContainerLayout.y)) {
        layout.y = updatedColumnsContainerLayout.y - currColumnsContainerLayout.y + rowMargin
    }

    return layout
}

export default function changeChildrenColumnsHeightBeforeContainer(
    ps: PS,
    columnsContainerCompPtr: Pointer,
    newLayoutToUpdate: Layout,
    updateCompLayoutFn: LayoutCallback
) {
    const columnPtrs = component.getChildren(ps, columnsContainerCompPtr)
    const currColumnsContainerLayout = component.layout.get(ps, columnsContainerCompPtr)
    const isMultiColumns = columnPtrs.length > 1

    const isChangingHeight =
        _.isNumber(newLayoutToUpdate.height) && newLayoutToUpdate.height !== currColumnsContainerLayout.height
    if (!isChangingHeight || (isMultiColumns && ps.pointers.components.isMobile(columnsContainerCompPtr))) {
        return
    }

    const isShorteningContainer = isShortening(currColumnsContainerLayout, newLayoutToUpdate)

    const updatedColumnsLayout: any = getUpdatedLayoutForIndividualColumns(
        ps,
        columnsContainerCompPtr,
        newLayoutToUpdate
    )
    updatedColumnsLayout.y = _.isUndefined(updatedColumnsLayout.y)
        ? component.layout.get(ps, columnPtrs[0]).y
        : updatedColumnsLayout.y

    _.forEach(columnPtrs, function (columnPtr) {
        const isEnforcingByWidth = componentsMetaData.public.isEnforcingContainerChildLimitationsByWidth(ps, columnPtr)
        const isEnforcingByHeight = componentsMetaData.public.isEnforcingContainerChildLimitationsByHeight(
            ps,
            columnPtr
        )

        layoutConstraintsUtils.constrainByChildrenLayout(
            ps,
            columnPtr,
            updatedColumnsLayout,
            !isEnforcingByWidth,
            !isEnforcingByHeight
        )
    })

    _.forEach(columnPtrs, function (columnPtr) {
        updateCompLayoutFn(ps, columnPtr, updatedColumnsLayout)

        if (isShorteningContainer) {
            const rowMargin = getRowMargin(ps, columnsContainerCompPtr)

            // constrain the parent columnsContainer when it resizes
            if (isShorteningFromBottom(currColumnsContainerLayout, newLayoutToUpdate)) {
                updateCompLayoutFn(ps, columnPtr, {y: updatedColumnsLayout.y + rowMargin} as any)
            } else {
                updateCompLayoutFn(ps, columnPtr, {y: updatedColumnsLayout.y - rowMargin} as any)
            }
        }
    })
}
