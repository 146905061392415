import type {ToolDefinition} from './aiTools'
import type {PS} from '@wix/document-services-types'
import componentsDataModel from '../dataModel/componentsDataModel'
import {insertTextInHtml} from '@wix/document-manager-extensions'
import component from '../component/component'
import constants from '../constants/constants'

const {VIEW_MODES} = constants

const isRichText = (ps: PS, compPointer: any) => {
    const compType = component.getType(ps, compPointer)
    return compType === 'wysiwyg.viewer.components.WRichText'
}

const getRichTextProperty = (ps: PS, compPointer: any, newText: string) => {
    const compData = component.data.get(ps, compPointer)
    const content = insertTextInHtml(compData.text, newText, false)
    return {text: content}
}

const getExternalLinkProperty = (linkUrl: string) => {
    const linkData = {type: 'ExternalLink', url: linkUrl, target: '_blank'}
    return {link: linkData}
}

const modifyElementsAttributes = (ps: PS, params: any) => {
    const {modification_specs} = params
    console.log('[modifyElementsAttributesTool] params are', params)
    modification_specs.forEach((spec: any) => {
        const {element_ID} = spec
        let {modifications} = spec
        console.log('component id is', element_ID)
        const compPointer = ps.pointers.getPointer(element_ID, VIEW_MODES.DESKTOP)
        if (isRichText(ps, compPointer) && modifications.text) {
            modifications = getRichTextProperty(ps, compPointer, modifications.text)
        }
        if (modifications.link) {
            modifications = getExternalLinkProperty(modifications.link)
        }
        componentsDataModel.updateDataItem(ps, compPointer, modifications, true)
    })
}

export const modifyElementsAttributesTool: ToolDefinition = {
    name: 'modifyElementsAttributes',
    func: modifyElementsAttributes,
    validationFunc: () => ({success: true}),
    responseFunc: () => `Modified element properties successfully`
}
