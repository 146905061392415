import type {PS} from '@wix/document-services-types'
import type {HookEventData, HooksExtensionApi} from '@wix/document-manager-extensions/src/extensions/hooks/hooks'

export const eventHooks = (ps: PS) => {
    const {hooks: hooksApi} = ps.extensionAPI as HooksExtensionApi
    return hooksApi
}

export type HookEventHandlerWithPs<E extends string, D extends HookEventData<E>, R = undefined> = (
    ps: PS,
    event: D,
    value?: R
) => R

export const registerHookWithPs = <E extends string, D extends HookEventData<E>, R = undefined>(
    ps: PS,
    event: E,
    handler: HookEventHandlerWithPs<E, D, R>,
    compType?: string
) => {
    const hooksApi = eventHooks(ps)
    hooksApi.registerHook(event, (e: D, value?: R) => handler(ps, e, value), compType)
}
