export type UnitLengthTypesArray = ['px', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']
export type UnitLengthType = UnitLengthTypesArray[number]

export declare interface UnitLength {
    value: number
    type: UnitLengthType
}

export declare interface UnitAngle {
    value: number
    type: 'deg'
}

export declare interface UnitPercentage {
    value: number
    type: 'percentage'
}

export declare type UnitAnglePercentage = UnitAngle | UnitPercentage

export declare type UnitLengthPercentage = UnitLength | UnitPercentage

interface UnitSizePoint {
    x: UnitLengthPercentage
    y: UnitLengthPercentage
}

export declare interface UnitSizeArea {
    width: UnitLengthPercentage
    height: UnitLengthPercentage
}

export declare type GradientSizeKeywordValue = 'closest-side' | 'closest-corner' | 'farthest-side' | 'farthest-corner'

export declare interface GradientSizeKeyword {
    keyword: GradientSizeKeywordValue
}

export declare type BlendMode =
    | 'normal'
    | 'multiply'
    | 'screen'
    | 'darken'
    | 'lighten'
    | 'overlay'
    | 'colorDodge'
    | 'colorBurn'
    | 'hardLight'
    | 'softLight'
    | 'difference'
    | 'exclusion'
    | 'hue'
    | 'saturation'
    | 'color'
    | 'luminosity'

declare interface GradientBase {
    repeat?: boolean
    blendMode?: BlendMode
}

export declare interface GradientColorStop {
    size: UnitLengthPercentage
    color?: string
    opacity?: number
}

export declare interface GradientAngularColorStop {
    size: UnitAngle | UnitPercentage
    color: string
    opacity?: number
}

export declare interface GradientPercentageColorStop {
    size: UnitPercentage
    color: string
    opacity?: number
}

export declare type GradientLinear = GradientBase & {
    type: 'GradientLinear'
    angle?: number
    colorStops: GradientColorStop[]
}

export declare type GradientCircle = GradientBase & {
    type: 'GradientCircle'
    size?: UnitLength | GradientSizeKeyword
    position: UnitSizePoint
    colorStops: GradientColorStop[]
}

export declare type GradientEllipse = GradientBase & {
    type: 'GradientEllipse'
    size?: UnitSizeArea | GradientSizeKeyword
    position: UnitSizePoint
    colorStops: GradientColorStop[]
}

export declare type GradientConic = GradientBase & {
    type: 'GradientConic'
    angle?: number
    position: UnitSizePoint
    colorStops: GradientAngularColorStop[]
}

export type GradientData = GradientLinear | GradientCircle | GradientEllipse | GradientConic

export interface GradientMesh {
    type: 'GradientMesh'
    gradients: (GradientCircle | GradientEllipse)[]
}

export interface GradientGeneratorOptions {
    alpha?: number
    resolveColor?(color: string, combinedAlpha?: number): string
}

export enum ColorLayerType {
    GradientLinearLayer = 'GradientLinearLayer',
    GradientCircleLayer = 'GradientCircleLayer',
    GradientEllipseLayer = 'GradientEllipseLayer',
    GradientConicLayer = 'GradientConicLayer',
    GradientMeshLayer = 'GradientMeshLayer',
    SolidColorLayer = 'SolidColorLayer'
}

export interface ColorLayerData {
    type: ColorLayerType
    opacity?: number
    fill:
        | GradientLinear
        | GradientCircle
        | GradientEllipse
        | GradientConic
        | GradientMesh
        | {type: 'SolidColor'; color: string}
}
