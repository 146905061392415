import type {PublicMethodUtils} from '@wix/document-services-types'
import {wixapps} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    const {lists, classics, initializer, blogUtils} = wixapps
    const {deprecate} = publicMethodUtils
    // @ts-ignore
    const defineGetter = (...args: any[]) => deprecate(publicMethodUtils.defineGetter(...args), 'wixapps is deprecated')
    // @ts-ignore
    const defineDataManipulationAction = (...args: any[]) =>
        // @ts-ignore
        deprecate(publicMethodUtils.defineDataManipulationAction(...args), 'wixapps is deprecated')

    return {
        methods: {
            wixapps: {
                classics: {
                    getAllAppPartComps: defineGetter(classics.getAllAppPartComps),
                    getPackageName: defineGetter(classics.getPackageName),
                    getDataStoreId: defineGetter(classics.getDataStoreId),
                    reloadApp: defineDataManipulationAction(classics.reloadApp),
                    registerHooks: defineDataManipulationAction(classics.registerHooks),
                    isBlogPage: defineGetter(blogUtils.isBlogPage),
                    blog: {
                        isSinglePost: defineGetter(classics.blog.isSinglePost)
                    }
                },
                appbuilder: {
                    metadata: {
                        wasPartLoadedSuccessfully: defineGetter(() => true)
                    },
                    data: {
                        lists: {
                            getItemSchema: defineGetter(lists.getType),
                            getItems: defineGetter(lists.getItems)
                        }
                    }
                }
            }
        },
        initMethod: initializer.initialize
    }
}
