import type {ToolDefinition} from './aiTools'
import componentsDefinitionMap from '@wix/document-services-json-schemas/dist/schemas/componentsDefinitionsMap.json'
import dataSchemasMap from '@wix/document-services-json-schemas/dist/restrictedSchemas/document_data.json'
import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import component from '../component/component'
import constants from '../constants/constants'

const {VIEW_MODES} = constants

const getElementAttributesSchema = (ps: PS, params: any) => {
    const {elements_IDs} = params
    const compPointer = ps.pointers.getPointer(elements_IDs[0], VIEW_MODES.DESKTOP)
    const componentType = component.getType(ps, compPointer)
    const compDataType = _.get(componentsDefinitionMap, [componentType, 'dataTypes'])
    const schema = _.get(dataSchemasMap, compDataType)
    delete schema.properties.metaData
    return `
\`\`\`json
${JSON.stringify(schema)}
\`\`\`
`
}

const getElementAttributesSchemaValidation = (ps: PS, params: any) => {
    const {elements_IDs} = params
    if (!elements_IDs.length) {
        return {success: false, validationMessage: 'The given elements Ids array is empty'}
    }
    const elements = elements_IDs.map((elementId: string) => {
        const compPointer = ps.pointers.getPointer(elements_IDs[0], VIEW_MODES.DESKTOP)
        return {elementId, elementType: component.getType(ps, compPointer)}
    })
    const firstType = elements[0].elementType
    const isSameType = _.every(elements, (element: any) => element.elementType === firstType)
    if (!isSameType) {
        return {
            success: false,
            validationMessage: `The given elements Ids array contains different types of elements.\n ${elements
                .map((element: any) => `elementId: ${element.elementId}, elementType: ${element.elementType}`)
                .join('\n')}`
        }
    }
    return {success: true}
}

export const getElementPropertiesSchemaTool: ToolDefinition = {
    name: 'getElementAttributesSchema',
    func: () => {},
    validationFunc: getElementAttributesSchemaValidation,
    responseFunc: getElementAttributesSchema
}
