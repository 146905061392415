import {multilingualReferenceFixer} from './multilingualReferenceFixer'
import {removeBrokenQueries} from './removeBrokenQueries'
import {removeBrokenMemberPages} from './removeBrokenMemberPages'
import {removeBrokenUnitSizesFromLayout} from './removeBrokenUnitSizesFromLayout'
import {removeBrokenVariantRelations as removeBrokenStyleRelations} from './removeBrokenVariantRelations'
import {removeBrokenTpaSharedState} from './removeBrokenTpaSharedState'
import {fixBrokenConnections} from './fixBrokenConnections'
import {addAppDefinitionIdToAppsData} from './addAppDefinitionIdToAppsData'
import {removeFromConnectionBrokenControllers} from './removeFromConnectionBrokenControllers'
import {removeMetaDataFromMaps} from './removeMetaDataFromMaps'
import {wrapMapsForSignatures} from './wrapMapsForSignatures'
import {removeCorruptedLinksFromStyledText} from './removeCorruptedLinksFromStyledText'
import {customMenusCollectionFixer} from './customMenusCollectionFixer'
import {migrateStylableMenuToContainer} from './migrateStylableMenuToContainer'
import {migrateTPAWidgetToContainer} from './migrateTPAWidgetToContainer'
import {migrateTPASectionToContainer} from './migrateTPASectionToContainer'
import {fixMobileComponentsWithWrongPage as fixMobileComponentsWithWrongParent} from './fixMobileComponentsWithWrongPage'
import {migrateMobileVariant} from './migrateMobileVariant'
import {runComponentSpecificMigrations} from './componentMigrations/runComponentSpecificMigrations'
import {childRefFixer} from './childRefFixer'
import {duplicateAndCrossPageRefFixer} from './duplicateAndCrossPageRefFixer/duplicateAndCrossPageRefFixer'
import {fixBrokenLayout} from './fixBrokenLayout'
import {fixBreakpointsVariantsQuery} from './fixBreakpointsVariantsQuery'
import {fixInvalidBackgroundMediaColor} from './fixInvalidBackgroundMediaColor'
import {fixInvalidMediaContainerWithDividers} from './fixInvalidMediaContainerWithDividers'
import {fixMissingDefaultDesignData} from './fixMissingDefaultDesignData'
import {fixBrokenMenusRefs} from './fixBrokenMenuRefs'
import {fixBrokenStylableMenuSubSubStyles} from './fixBrokenStylableMenuSubSubStyles'
import {colorPaletteMigrator} from './colorPaletteMigrator'
import {fixBrokenScopedLayout} from './fixBrokenScopedLayout'
import {addKeyframeEffectListToMasterPage} from './addKeyframeEffectListToMasterPage'
import {addUsesNewAnimationsFlagToMasterPageData} from './addUsesNewAnimationsFlagToMasterPageData'
import {removeBrokenFixerVersion} from './removeBrokenFixerVersion'
import {removeInvalidStyleRefArr as fixInvalidDefaultStyleInRefArr} from './fixInvalidDefaultStyleInRefArr'
import {addMissingSignatures} from './addMissingSignatures'
import {internalRefGarbageCollector} from './internalRefGarbageCollector'
import {removePermaLinksFixer as removePermaLinks} from './removePermaLinks'
import {migrateRectanglePageToTransparent} from './migrateRectanglePageToTransparent'
import {fixCommonMissingReferences} from './fixCommonMissingReferences'
import {fixCorruptedStyleShadow} from './fixCorruptedStyleShadow'
import {fixPageBackgroundNodeLocation} from './fixPageBackgroundNodeLocation'
import {meshLayoutMigrator} from './meshLayoutMigrator'
import {persistAppsInstallState} from './persistAppsInstallState'
import {mlTypeMismatchFixer} from './mlTypeMismatchFixer'
import {brokenReferenceFixer} from './brokenReferenceFixer'

import type {AnyPageMigrator, SiteMigrator} from '../dataMigrationRunner'
import {fixDifferentIdsInMainMenuWithMultilingual} from './fixDifferentIdsInMainMenuWithMultilingual'
import {alignTranslatedPageDataWithMainLanguage} from './alignTranslatedPageDataWithMainLanguage'
import {fixMissingStyleTypeInSystemStyle} from './fixMissingStyleTypeInSystemStyle'
import {fixSystemStyleRefArray} from './fixSystemStyleRefArray'

const defaultPageMigrations: AnyPageMigrator[] = [
    removePermaLinks,
    removeBrokenQueries,
    removeBrokenMemberPages,
    removeBrokenUnitSizesFromLayout,
    removeBrokenStyleRelations,
    removeFromConnectionBrokenControllers,
    removeCorruptedLinksFromStyledText,
    fixPageBackgroundNodeLocation,
    runComponentSpecificMigrations,
    migrateStylableMenuToContainer,
    migrateTPAWidgetToContainer,
    migrateTPASectionToContainer,
    fixMobileComponentsWithWrongParent,
    fixBrokenLayout,
    migrateMobileVariant,
    fixBrokenStylableMenuSubSubStyles,
    duplicateAndCrossPageRefFixer,
    customMenusCollectionFixer,
    fixBrokenScopedLayout,
    fixInvalidMediaContainerWithDividers,
    fixMissingDefaultDesignData,
    fixMissingStyleTypeInSystemStyle,
    fixSystemStyleRefArray,
    fixCommonMissingReferences,
    fixBrokenConnections,
    fixBreakpointsVariantsQuery,
    removeBrokenFixerVersion,
    fixInvalidBackgroundMediaColor,
    fixInvalidDefaultStyleInRefArr,
    addMissingSignatures,
    meshLayoutMigrator,
    internalRefGarbageCollector,
    migrateRectanglePageToTransparent,
    fixCorruptedStyleShadow,
    alignTranslatedPageDataWithMainLanguage,
    mlTypeMismatchFixer,
    brokenReferenceFixer,
    addAppDefinitionIdToAppsData,
    removeBrokenTpaSharedState,
    fixBrokenMenusRefs,
    removeMetaDataFromMaps,
    wrapMapsForSignatures,
    childRefFixer,
    addKeyframeEffectListToMasterPage,
    addUsesNewAnimationsFlagToMasterPageData,
    colorPaletteMigrator,
    persistAppsInstallState,
    fixDifferentIdsInMainMenuWithMultilingual
]

const defaultRunOncePerSite: [SiteMigrator] = [multilingualReferenceFixer]

export {defaultPageMigrations, defaultRunOncePerSite}
