import _ from 'lodash'
import type {Layout} from '@wix/document-services-types'

const roundToNearestHalf = (num: number): number => Math.round(num * 2) / 2

const degreesToRadians = (angleInDegrees: number): number => (angleInDegrees * Math.PI) / 180

const getBoundingWidthWithRotation = (layout: Layout, angleInRadians: number): number => {
    const boundingWidthWithRotation =
        Math.abs(layout.width * Math.cos(angleInRadians)) + Math.abs(layout.height * Math.sin(angleInRadians))
    return roundToNearestHalf(boundingWidthWithRotation)
}

const getBoundingHeightWithRotation = (layout: Layout, angleInRadians: number): number => {
    const boundingHeightWithRotation =
        Math.abs(layout.width * Math.sin(angleInRadians)) + Math.abs(layout.height * Math.cos(angleInRadians))
    return roundToNearestHalf(boundingHeightWithRotation)
}

const getXAfterRotation = (layout: Layout, rotatedWidth: number): number =>
    roundToNearestHalf(layout.x - (rotatedWidth - layout.width) / 2)

const getYAfterRotation = (layout: Layout, rotatedHeight: number): number =>
    roundToNearestHalf(layout.y - (rotatedHeight - layout.height) / 2)

const rotateAndGetBoundingLayout = (layout: Layout, rotationInDegrees: number) => {
    if (rotationInDegrees === 0) {
        return _.pick(layout, ['x', 'y', 'width', 'height'])
    }

    const angleInRadians = degreesToRadians(rotationInDegrees)
    const boundingWidth = getBoundingWidthWithRotation(layout, angleInRadians)
    const boundingHeight = getBoundingHeightWithRotation(layout, angleInRadians)

    return {
        x: getXAfterRotation(layout, boundingWidth),
        y: getYAfterRotation(layout, boundingHeight),
        width: boundingWidth,
        height: boundingHeight
    }
}

export const getBoundingWidth = (layout: Layout) => {
    if (layout.rotationInDegrees === 0) {
        return layout.width
    }
    const currentAngleInRadians = degreesToRadians(layout.rotationInDegrees)
    return getBoundingWidthWithRotation(layout, currentAngleInRadians)
}

export const getBoundingX = (layout: Layout) => {
    if (layout.rotationInDegrees === 0) {
        return layout.x
    }
    const boundingWidth = getBoundingWidth(layout)
    return getXAfterRotation(layout, boundingWidth)
}

export const getBoundingHeight = (layout: Layout): number => {
    if (!layout.rotationInDegrees) {
        return layout.height
    }
    const currentAngleInRadians = degreesToRadians(layout.rotationInDegrees)
    return getBoundingHeightWithRotation(layout, currentAngleInRadians)
}

export const getBoundingY = (layout: Layout): number => {
    if (!layout.rotationInDegrees) {
        return layout.y
    }
    const boundingHeight = getBoundingHeight(layout)
    return getYAfterRotation(layout, boundingHeight)
}

export const getBoundingLayout = (layout: Layout) => rotateAndGetBoundingLayout(layout, layout.rotationInDegrees || 0)

export const getLayoutFromBoundingLayout = (boundingLayout: Layout, currentRotationInDegrees: number) => {
    const layout: Layout = rotateAndGetBoundingLayout(boundingLayout, -currentRotationInDegrees) //we rotate it the reverse of the current rotations, and get the new bounding layout
    layout.rotationInDegrees = currentRotationInDegrees
    return layout
}
