import {pointerUtils} from '@wix/document-manager-core'
const pointerType = 'multilingual'

const {getPointer} = pointerUtils

const createPointersMethods = () => {
    const currentLanguageCode = () => getPointer('currentLanguageCode', pointerType)

    return {
        multilingual: {
            currentLanguageCode
        }
    }
}

export {createPointersMethods}
