import baseConfig from './baseConfig'
import {clientCsaveExtensions} from './csaveConfigs'
import type {DSConfig} from '@wix/document-services-types'
const config = {
    runStylesGC: false,
    supportMobile: false,
    postUpdateOperation: false,
    documentDataFixer: 'responsiveDocument',
    isReadOnly: false,
    noUndo: false,
    disableCommonConfig: false,
    modulesList: [
        'accessibilityPublicAPI',
        'dataModelPublicAPI',
        'featuresPublicAPI',
        'slotsPublicAPI',
        'statesPublicAPI',
        'triggersPublicAPI',
        'reactionsPublicAPI',
        'documentModePublicAPI',
        'renderPluginsPublicApi',
        'savePublicAPI',
        'componentPublicAPI',
        'refComponentPublicAPI',
        'componentDetectorPublicAPI',
        'responsivePublicAPI',
        'breakpointsPublicAPI',
        'responsiveStylesPublicAPI',
        'siteMetadataPublicAPI',
        'themePublicAPI',
        'snapshotPublicAPI',
        'pagePublicAPI',
        'scrollAnchorsPublicAPI',
        'siteSegmentsPublicAPI',
        'componentsMetaDataPublicAPI',
        'appControllerDataPublicAPI',
        'connectionsPublicAPI',
        'platformPublicAPI',
        'routersPublicAPI',
        'autosavePublicAPI',
        'stylableEditorPublicAPI',
        'biPublicAPI',
        'environmentPublicAPI',
        'actionsAndBehaviorsPublicAPI',
        'structurePublicAPI',
        'debugPublicAPI',
        'atomicScopesPublicAPI',
        'menuPublicAPI',
        'smartBoxesPublicAPI',
        'sectionOrderPublicAPI',
        'fontsPublicAPI',
        'mediaPublicAPI',
        'tpaPublicAPI',
        'pagesGroupPublicAPI',
        'siteMembersPublicAPI',
        'wixCodePublicAPI',
        'variantsPublicAPI',
        'accountPublicAPI',
        'transformationsPublicAPI',
        'transitionsPublicAPI',
        'errorsPublicAPI',
        'componentsLoaderPublicAPI',
        'appStudioWidgetsPublicAPI',
        'multilingualPublicAPI',
        'partnersPublicAPI',
        'variablesPublicAPI',
        'scopesPublicAPI',
        'patternsPublicAPI',
        'keyframeEffectPublicAPI',
        'keyframeEffectPublicAPI',
        'effectsPublicAPI',
        'deprecatedOldBadPerformancePublicAPI',
        'customCSSPublicAPI',
        'aiPublicAPI',
        'externalComponentsPublicAPI',
        'importExportPublicAPI',
        'responsiveFixersPublicAPI'
    ],
    extensionsList: [
        ...baseConfig.extensionsList,
        'undoRedo',
        'metadata',
        'save',
        'lifecycle',
        'layoutCircuitBreaker',
        'viewerRuntime',
        'contactFormMetaData',
        'appBuilder',
        'sectionOrder',
        'documentToViewer',
        'wixapps',
        'accountInfo',
        'commonConfig',
        'seo',
        ...clientCsaveExtensions,
        'componentsByType',
        'partners',
        'platformWithViewer',
        'componentsRegistry',
        'aiContent',
        'aiConversation',
        'responsiveFixers'
    ]
}

export default config as DSConfig
