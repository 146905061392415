import type {CreateExtArgs, CreateExtensionArgument} from '@wix/document-manager-core'
import {getControllerNickname} from '../utils/nicknameUtils'
import type {CompRef, Pointer} from '@wix/document-services-types'
import {isBlocksEditor} from '../../../utils/appUtils'
import {getRootAppWidgetByPage} from '../../blocks/blocksDataModel'
import _ from 'lodash'
import {getComponentType} from '../../../utils/dalUtils'
import {COMPONENTS_NOT_SUITABLE_FOR_NON_RENDERING_STATE} from '../utils/componentConstants'

const EXCLUDED_COMPONENT_TYPES = [
    'wysiwyg.viewer.components.StateBoxState',
    'wysiwyg.viewer.components.StateBox',
    'wysiwyg.viewer.components.BoxSlideShow',
    'wysiwyg.viewer.components.BoxSlideShowSlide',
    'wysiwyg.viewer.components.tpapps.TPAWidget'
]

const getUnsupportedComponentInAppWidget = () => {
    return _.without(COMPONENTS_NOT_SUITABLE_FOR_NON_RENDERING_STATE, ...EXCLUDED_COMPONENT_TYPES)
}

const allChildrenAllowedInAppWidget = (createExtArgs: CreateExtArgs, potentialChild: CompRef) => {
    const recusiveChildrenPointers = createExtArgs.pointers.structure.getChildrenRecursively(potentialChild)
    const every = _.every(recusiveChildrenPointers.concat(potentialChild), componentPointer => {
        const compType = getComponentType(createExtArgs.dal, componentPointer)

        const notInclude = !_.includes(getUnsupportedComponentInAppWidget(), compType)
        return notInclude
    })
    return every
}

export const appWidgetMetaData = {
    nickname: getControllerNickname,
    canConnectToCode: (
        createExtArgs: CreateExtArgs,
        componentPointer: Pointer,
        createExtensionArgument?: CreateExtensionArgument
    ) => {
        if (!isBlocksEditor(createExtensionArgument)) {
            return true
        }

        const {pointers} = createExtArgs

        const pageOfComponent = pointers.structure.getPageOfComponent(componentPointer)
        const rooAppWidget = getRootAppWidgetByPage(createExtArgs, pageOfComponent) || null
        return !pointers.components.isSameComponent(rooAppWidget, componentPointer)
    },
    isRepeatable: false,
    container: true,
    canContain: (createExtArgs: CreateExtArgs, componentPointer: Pointer, potentialChild: CompRef) => {
        return allChildrenAllowedInAppWidget(createExtArgs, potentialChild)
    }
}
