import type {CreateExtArgs, CreateExtensionArgument, DmApis, Extension, ExtensionAPI} from '@wix/document-manager-core'
import _ from 'lodash'
import {ReportableError} from '@wix/document-manager-utils'
import {isRefHostType, isRefPointer} from '../utils/refStructureUtils'
import type {Pointer} from '@wix/document-services-types'
import {COMPONENT_TYPES} from '../../testkit/builders/constants'

export interface ArrangementAPI {
    reorderComponents(containerPointer: Pointer, componentsPointers: Pointer[]): void
}

export type ArrangementExtensionAPI = ExtensionAPI & {
    arrangement: ArrangementAPI
}

const throwReorderingError = (message: string, extras?: Record<string, any>) => {
    throw new ReportableError({errorType: 'componentsReordering', message, extras})
}

const createExtension = ({}: CreateExtensionArgument): Extension => {
    const createExtensionAPI = ({pointers, dal}: CreateExtArgs): ArrangementExtensionAPI => {
        const validateReordering = (containerPointer: Pointer, componentsPointers: Pointer[]) => {
            const container = dal.get(containerPointer)

            if (pointers.structure.isMasterPage(container)) {
                throwReorderingError('Cannot reorder a master page')
            }
            if (isRefHostType(container.componentType)) {
                throwReorderingError('Cannot reorder a ref component')
            }
            if (isRefPointer(container)) {
                throwReorderingError('Cannot reorder a ref component')
            }
            if (container.componentType === COMPONENT_TYPES.REPEATER) {
                throwReorderingError('Cannot reorder a repeater')
            }

            const originalIds = dal.get({...containerPointer, innerPath: ['components']})
            const newOrderIds = _.map(componentsPointers, ({id}) => id)

            if (newOrderIds.length !== originalIds.length) {
                throwReorderingError("Given array's length is different from the original", {
                    originalOrder: originalIds,
                    newOrder: newOrderIds
                })
            }

            const difference = _.difference(originalIds, newOrderIds)
            if (!_.isEmpty(difference)) {
                throwReorderingError('Given array should contain same components as the original', {
                    originalOrder: originalIds,
                    newOrder: newOrderIds,
                    missing: difference
                })
            }
        }

        const reorderComponents = (containerPointer: Pointer, componentsPointers: Pointer[]) => {
            validateReordering(containerPointer, componentsPointers)

            dal.set(
                {...containerPointer, innerPath: ['components']},
                _.map(componentsPointers, ({id}) => id)
            )
        }

        return {
            arrangement: {
                reorderComponents
            }
        }
    }

    const createPublicAPI = ({extensionAPI}: DmApis) => {
        const {arrangement} = extensionAPI as ArrangementExtensionAPI
        return {
            arrangement: {
                reorderComponents: arrangement.reorderComponents
            }
        }
    }

    return {
        name: 'arrangement',
        createExtensionAPI,
        createPublicAPI
    }
}

export {createExtension}
