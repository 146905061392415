import _ from 'lodash'
export const createNoopLogger = () => ({
    updatePageNumber: () => {},
    updatePageId: () => {},
    updateApplicationsMetaSite: () => {},
    runAsyncAndReport: <T>(asyncMethod: () => Promise<T> | T) => {
        return Promise.resolve(asyncMethod())
    },
    reportAsyncWithCustomKey: <T>(asyncMethod: () => Promise<T>) => {
        return Promise.resolve(asyncMethod())
    },
    runAndReport: <T>(fn: () => T) => {
        return fn()
    },
    phaseStarted: _.noop,
    phaseEnded: _.noop,
    meter: _.noop,
    appLoaded: _.noop,
    reportAppLoadStarted: _.noop,
    captureError: _.noop,
    addBreadcrumbToBatch: _.noop,
    flushBreadcrumbBatch: _.noop,
    setGlobalsForErrors: _.noop,
    breadcrumb: _.noop,
    interactionStarted: _.noop,
    interactionEnded: _.noop,
    registerPlatformWidgets: _.noop,
    getEventsData: () => []
})
