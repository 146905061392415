import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import dataModel from '../../dataModel/dataModel'
import quickActionBarManager from './quickActionBarManager'
import clientSpecMapService from '../../tpa/services/clientSpecMapService'
import mobileMetaDataUtils from './mobileMetaDataUtils'
import platformStateService from '../../platform/services/platformStateService'

function validateActionSupport(ps: PS, actions) {
    const availableActionIds = _(dataModel.getDataSchemaByType(ps, 'QuickActions').configuration.properties)
        .omit('quickActionsMenuEnabled')
        .keys()
        .map(id => /(\w*)Enabled/.exec(id)[1])
        .value()
    const unsupportedActions = _.difference(actions, availableActionIds)
    if (_.some(unsupportedActions)) {
        throw new Error(`[${unsupportedActions}] are unsupported.`)
    }
}

function validateActionEnable(ps: PS, actionsEnableMap) {
    const actionIds = _(actionsEnableMap)
        .pickBy(v => !!v) //if empty pickBy fallback to _.identity, this is what we want but typescript will fail to compile
        .keys()
        .value()
    validateActionSupport(ps, actionIds)
    const siteMetaData = mobileMetaDataUtils.getSiteMetaData(ps)
    const enabledUndefinedActions = _.filter(
        actionIds,
        id =>
            (_.includes(['phone', 'email', 'address'], id) && !siteMetaData.contactInfo[id]) ||
            (id === 'socialLinks' && _.isEmpty(siteMetaData.quickActions.socialLinks))
    )
    if (_.some(enabledUndefinedActions)) {
        throw new Error(`Actions [${enabledUndefinedActions}] has a missing value and thus cannot be enabled`)
    }
}

function validateVersion(ps: PS, methodName: string) {
    if (quickActionBarManager.hasQuickActionBar(ps)) {
        throw new Error(`"${methodName}" is allowed only for actionBar version 1`)
    }
}

function enable(ps: PS, enabled: boolean) {
    const siteMetaData = mobileMetaDataUtils.getSiteMetaData(ps)
    siteMetaData.quickActions.configuration.quickActionsMenuEnabled = enabled
    mobileMetaDataUtils.setSiteMetaData(ps, siteMetaData)
    const chatAppDefId = '14517e1a-3ff0-af98-408e-2bd6953c36a2'
    const clientSpecMap = ps.dal.get(ps.pointers.general.getClientSpecMap())
    const chatApp = _(clientSpecMap).values().find({appDefinitionId: chatAppDefId})
    const chatAppInstalled = clientSpecMapService.isAppPermissionsIsGranted(
        chatApp,
        platformStateService.getAppsState(ps)
    )
    if (!enabled && chatAppInstalled) {
        quickActionBarManager.addQuickActionBar(ps)
    }
    if (enabled && _(quickActionBarManager.getActions(ps)).reject('isDynamic').isEmpty()) {
        quickActionBarManager.removeQuickActionBar(ps)
    }
}

function isEnabled(ps: PS) {
    return mobileMetaDataUtils.getSiteMetaData(ps).quickActions.configuration.quickActionsMenuEnabled
}

function setColorScheme(ps: PS, colorScheme: string) {
    validateVersion(ps, 'actionBar.colorScheme.set')
    const siteMetaData = mobileMetaDataUtils.getSiteMetaData(ps)
    siteMetaData.quickActions.colorScheme = colorScheme
    mobileMetaDataUtils.setSiteMetaData(ps, siteMetaData)
}

function getColorScheme(ps: PS) {
    return mobileMetaDataUtils.getSiteMetaData(ps).quickActions.colorScheme
}

function enableActions(ps: PS, actionsEnableMap) {
    validateVersion(ps, 'actionBar.actions.enable')
    validateActionEnable(ps, actionsEnableMap)
    const siteMetaData = mobileMetaDataUtils.getSiteMetaData(ps)
    _.assign(
        siteMetaData.quickActions.configuration,
        _.transform(
            actionsEnableMap,
            (res, value, key: string) => {
                res[`${key}Enabled`] = value
            },
            {}
        )
    )
    mobileMetaDataUtils.setSiteMetaData(ps, siteMetaData)
}

function getEnabledMap(ps: PS): Record<string, any> {
    const enabledActions = _.omit(
        mobileMetaDataUtils.getSiteMetaData(ps).quickActions.configuration,
        'quickActionsMenuEnabled'
    )
    return _.transform(
        enabledActions,
        (res, value, key) => {
            const newKey = /(\w*)Enabled/.exec(key)[1]
            res[newKey] = value
        },
        {}
    )
}

function updateActions(ps: PS, actionsUpdateMap) {
    validateVersion(ps, 'actionBar.actions.update')
    validateActionSupport(ps, _.keys(actionsUpdateMap))
    const siteMetaData = mobileMetaDataUtils.getSiteMetaData(ps)
    _.forEach(actionsUpdateMap, function (value, action) {
        switch (action) {
            case 'phone':
            case 'email':
            case 'address':
                siteMetaData.contactInfo[action] = value
                break
            case 'socialLinks':
                siteMetaData.quickActions.socialLinks = _(siteMetaData.quickActions.socialLinks)
                    .reject(link => _.includes(_.keys(value), link.id))
                    .concat(_.map(value, (v, k) => ({id: k, url: v})))
                    .reject(link => _.isEmpty(link.url))
                    .value()
                break
        }
    })
    mobileMetaDataUtils.setSiteMetaData(ps, siteMetaData)
}

function getActions(ps: PS) {
    const siteMetaData = mobileMetaDataUtils.getSiteMetaData(ps)
    const contactActions = _.pick(siteMetaData.contactInfo, ['phone', 'email', 'address'])
    const socialLinkIds = dataModel.getDataSchemaByType(ps, 'SocialLink').id.enum
    const emptySocialLinks = _.transform(
        socialLinkIds,
        (res, id) => {
            res[id] = ''
        },
        {}
    )
    const userDefinedSocialLinks = _.transform(
        siteMetaData.quickActions.socialLinks,
        (res, link) => {
            res[link.id] = link.url
        },
        {}
    )
    return _.assign(contactActions, {socialLinks: _.assign(emptySocialLinks, userDefinedSocialLinks)})
}

const quickActions = {
    QUICK_ACTIONS_PROPS: {
        NAVIGATION_MENU: {type: 'boolean', id: 'navigationMenu'},
        PHONE: {type: 'string', id: 'phone'},
        EMAIL: {type: 'string', id: 'email'},
        WHATSAPP: {type: 'string', id: 'whatsapp'},
        ADDRESS: {type: 'string', id: 'address'},
        SOCIAL_LINKS: {type: 'object', id: 'socialLinks'}
    },
    SOCIAL_LINKS_IDS: [
        'facebook',
        'twitter',
        'pinterest',
        'google_plus',
        'tumblr',
        'blogger',
        'linkedin',
        'youtube',
        'vimeo',
        'flickr'
    ],
    /**
     * enables or disables the mobile action bar based on enabled param.
     * when mobile action bar is enabled a layer appears on mobile sites that allows users to navigate the site more easily.
     * @param {boolean} enabled  true - show the quick actions bar , false - hides the quick actions bar
     */
    enable,
    /**
     * Returns whether the mobile action bar is enabled or not
     * @return {boolean} returns if the mobile actions bar is enabled or not
     * @example
     * // returns true
     */
    isEnabled,
    /**
     * @class documentServices.mobile.actionBar.colorScheme
     */
    colorScheme: {
        /**
         * Sets the mobile action bar color scheme
         * @param {String} colorScheme possible values are 'light' or 'dark'
         */
        set: setColorScheme,
        /**
         * Returns the mobile action bar's color scheme
         * @return {string} string describing color schema (possible values 'light' or 'dark')
         * @example
         * // dark
         */
        get: getColorScheme
    },
    /**
     * @class documentServices.mobile.actionBar.actions
     */
    actions: {
        /**
         *  enable or disable specific functionalities of the mobile action bar.
         *  Possible to pass only the functionality we want to change.
         *  possible functionalities are ['navigationMenu', 'phone', 'email', 'address', 'socialLinks']
         * @param {Object.<string, boolean>} actionToIsEnabledMap - a map of actions and their enabled state
         * @example {navigationMenu: true, phone: false, email: false, address: false, socialLinks: false}
         */
        enable: enableActions,
        /**
         * returns a map containing  all the functionalities of the mobile action bar and returns if each functionality is enabled or not.
         * @returns {Object.<string, boolean>} actionToIsEnabledMap - a map of actions and their enabled state
         * possible actions are ['navigationMenu', 'phone', 'email', 'address', 'socialLinks']
         * @example
         * //returns {navigationMenu: true, phone: false, email: false, address: false, socialLinks: false}
         */
        getEnabled: getEnabledMap,
        /**
         * Updates the functionalities of the mobile action bar based on a map that is passed. the map will contain the functionality name and the value.
         * @param {Object.<string, (string|object)>} actionToValueMap  map of functionalities and their values. Possible actions are ['phone', 'email', 'address', 'socialLinks']
         * @example {phone: '05233333', email: 'x@example.com', address: 'example address', socialLinks:{blogger: '',facebook: '',flickr: '',google_plus: '',linkedin: '',pinterest: '',tumblr: '',twitter: '',vimeo: '',youtube: ''}}
         *
         */
        update: updateActions,
        /**
         * Gets all possible functionalities of the mobile action bar and their value
         * possible actions are ['phone', 'email', 'address', 'socialLinks']
         * @returns {Object.<string, (string|object)>} - a map of actions and their values
         * @example
         * //return  {phone: '', email: 'x@example.com', address: '', socialLinks: Object}
         *
         */
        get: getActions
    }
}
export default quickActions
