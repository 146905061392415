'use strict'

const _ = require('lodash')

const NEW_MIN_BREAKPINT = 10

const exec = (pageJson, _pageIdsArray, magicObject) => {
    if (magicObject.isExperimentOpen('dm_changeMinBreakpointValue')) {
        const variants = pageJson.data.variants_data
        const breakpointRanges = _.filter(variants, {type: 'BreakpointRange'})

        breakpointRanges.forEach(range => {
            range.min = NEW_MIN_BREAKPINT
        })
    }
}

module.exports = {
    name: 'minBreakpointFixer',
    version: 0,
    exec,
    experimentalVersions: [{version: 1, experiment: 'dm_changeMinBreakpointValue'}]
}
