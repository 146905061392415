import type {DocumentManager} from '@wix/document-manager-core'
import {constants, type PageExtensionAPI} from '@wix/document-manager-extensions'
import type {PageMigrator} from '../dataMigrationRunner'
import type {Experiment} from '@wix/document-services-types'

const migratePage = (documentManager: DocumentManager, pageId: string, experimentInstance: Experiment) => {
    if (experimentInstance.isOpen('dm_fixPageBackgroundNodeLocation')) {
        const {page} = documentManager.extensionAPI as PageExtensionAPI
        const pagePointer = documentManager.pointers.structure.getPage(pageId, constants.VIEW_MODES.DESKTOP)
        page.data.update(pagePointer, page.data.get(pageId), false)
    }
}

const name = 'fixPageBackgroundNodeLocation'
const version = 0
const experimentalVersions = [
    {
        version: 1,
        experiment: 'dm_fixPageBackgroundNodeLocation'
    }
]
export const fixPageBackgroundNodeLocation: PageMigrator = {
    migratePage,
    name,
    version,
    experimentalVersions
}
