import _ from 'lodash'

export default {
    mobileConversionConfig: {
        category: 'graphic',
        isSuitableForProportionGrouping: true
    },
    rotatable: true,
    layoutLimits(ps, compPtr) {
        //Better use componentStylesAndSkinsAPI, but can't due to circular dependency
        const styleId = ps.dal.get(ps.pointers.getInnerPointer(compPtr, 'styleId'))
        const pageId = ps.pointers.components.getPageOfComponent(compPtr).id
        const styleObj = ps.dal.get(ps.pointers.data.getThemeItem(styleId, pageId))

        const strokeWidth = parseInt(_.get(styleObj, 'style.properties.strokewidth', 0), 10)
        let minWidth = strokeWidth + 1
        let minHeight = strokeWidth + 1

        const svgId = _.get(styleObj, 'svgId')
        const storePointer = ps.pointers.svgShapes.getSvgShapePointer(svgId)
        const boxBoundariesPointer = ps.pointers.getInnerPointer(storePointer, 'boxBoundaries')
        const boxBoundaries = ps.dal.get(boxBoundariesPointer)
        const originalAspectRatio = _.isEmpty(boxBoundaries) ? 0 : boxBoundaries.width / boxBoundaries.height
        if (originalAspectRatio) {
            if (originalAspectRatio > 1) {
                minHeight = strokeWidth + 1
                minWidth = minHeight * originalAspectRatio
            } else {
                minWidth = strokeWidth + 1
                minHeight = minWidth / originalAspectRatio
            }
        }

        return {
            minWidth: Math.ceil(minWidth),
            minHeight: Math.ceil(minHeight),
            maxHeight: 2000
        }
    }
}
