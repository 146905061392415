import type {CompLayout, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import component from '../../component/component'
import layoutConstraintsUtils from '../../structure/utils/layoutConstraintsUtils'
import layoutHooksUtils, {LayoutCallback} from './utils/layoutHooksUtils'

function constrainByContentWidth(ps: PS, childPointer: Pointer, desiredLayout: Partial<CompLayout>) {
    layoutConstraintsUtils.constrainByChildrenLayout(ps, childPointer, desiredLayout, false, true)
}

function constrainWidthByChildBiggestWidth(
    ps: PS,
    compPointer: Pointer,
    newLayout: CompLayout,
    updateCompLayoutCallback: LayoutCallback
) {
    if (!_.isFunction(updateCompLayoutCallback)) {
        return
    }

    const currentLayout = component.layout.get(ps, compPointer)
    const isWidthChanged = newLayout.width && newLayout.width !== currentLayout.width

    if (!isWidthChanged) {
        return
    }

    const {width: desiredWidth} = newLayout
    const xShiftInPx = layoutHooksUtils.calculateDelta(newLayout.x, currentLayout.x)
    const children = ps.pointers.components.getChildren(compPointer)

    let minWidth = 0
    children.forEach(childPointer => {
        const {x} = component.layout.get(ps, childPointer)
        const desiredLayout = {width: desiredWidth, x}
        constrainByContentWidth(ps, childPointer, desiredLayout)
        minWidth = Math.max(minWidth, desiredLayout.width)
    })
    const newWidth = Math.max(desiredWidth, minWidth)

    if (minWidth > desiredWidth) {
        // When width is constrained, reset the width to constrained width
        newLayout.width = newWidth

        /**
         * When narrowing from the left, the component is shifted to the right by the amount we narrow
         * Since we constrain the narrowing, we need to shift the component back by the amount of pixels that were constrained
         */
        if (xShiftInPx > 0) {
            newLayout.x -= minWidth - desiredWidth
        }
    }

    children.forEach(childPointer => {
        const childLayout = component.layout.get(ps, childPointer)
        updateCompLayoutCallback(ps, childPointer, {
            ...childLayout,
            width: newWidth
        })
    })
}

export default {
    constrainWidthByChildBiggestWidth
}
