import type {CompRef, ExternalComponentsReadOnlyDriver, PS, Pointer} from '@wix/document-services-types'
import component from '../../component/component'
import dataModel from '../../dataModel/dataModel'
import dsUtils from '../../utils/utils'

export default (ps: PS, compPointer: CompRef): ExternalComponentsReadOnlyDriver => ({
    layout: {
        get: () => component.layout.get(ps, compPointer)
    },
    data: {
        get: () => dataModel.getDataItem(ps, compPointer)
    },
    properties: {
        get: () => component.properties.get(ps, compPointer)
    },
    design: {
        get: () => component.design.getDesignItem(ps, compPointer)
    },
    isMobile: () => ps.pointers.components.isMobile(compPointer),
    getComponentType: (pointer: Pointer) => dsUtils.getComponentType(ps, pointer),
    getChildrenRecursively: (pointer: Pointer) => ps.pointers.structure.getChildrenRecursively(pointer)
})
