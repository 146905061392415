import type {PS} from '@wix/document-services-types'

export default {
    anchors: {
        to: false,
        from: false
    },
    resizableSides: [],
    dockable: false,
    alignable: false,
    removable: false,
    groupable: false,
    rotatable: true,
    duplicatable: false,
    canBeFixedPosition: false,
    canConnectToCode: false,
    shouldAutoSetNickname: false,
    enforceMaxDimensionsOnProportionalResize: true,
    layoutLimits() {
        // See layoutHooks/mediaPlayer.js for layout restrictions
        return {
            minWidth: 50,
            minHeight: 50,
            aspectRatio: 1
        }
    },
    mobileConversionConfig: {
        preserveAspectRatio: true,
        fixedSize(ps: PS, comp) {
            //get media player layout
            const mediaPlayerPointer = ps.pointers.components.getParent({id: comp.id, type: 'DESKTOP'})
            const mediaPlayerLayout = ps.dal.get(ps.pointers.getInnerPointer(mediaPlayerPointer, 'layout'))
            // calc desktop ratio
            const ratio =
                (comp.layout.width * comp.layout.height) / (mediaPlayerLayout.width * mediaPlayerLayout.height)
            // get mobile target dimensions
            const mobileWidth = 280
            const mobileHeight = Math.max(mediaPlayerLayout.height * (mobileWidth / mediaPlayerLayout.width), 120)
            // calc new bigplay dimension
            const bigPlaySize = Math.max(Math.sqrt(ratio * mobileWidth * mobileHeight), 50)

            return {width: bigPlaySize, height: bigPlaySize}
        }
    }
}
