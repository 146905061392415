import type {PS, WixCodeFileDescriptor} from '@wix/document-services-types'
import {createService as createFileSystemNGService, createService} from './fileSystemServiceNG'
import type {IFileSystemService, WixCodeFile} from './IFileSystemService'
import deprecatedFileSystemService from './deprecatedFileSystemService'
import experiment from 'experiment-amd'
import codeAppInfoUtils, {CodeAppInfo} from '../utils/codeAppInfo'

let memoizedServiceArgs:
    | {
          useIdeServerNg: boolean
          extensionAPI: PS['extensionAPI']
          codeAppInfo: CodeAppInfo
          fileSystemNGService: ReturnType<typeof createService>
      }
    | undefined

export const getServiceArgsFromPS = (ps: PS) => {
    const {extensionAPI} = ps
    const codeAppInfo = codeAppInfoUtils.getCodeAppInfoFromPS(ps)
    return getServiceArgs(extensionAPI, codeAppInfo)
}

const getServiceArgs = (extensionAPI: PS['extensionAPI'], codeAppInfo: CodeAppInfo) => {
    const useIdeServerNg = experiment.isOpen('dm_ideServerNG')
    if (
        memoizedServiceArgs &&
        memoizedServiceArgs.useIdeServerNg === useIdeServerNg &&
        memoizedServiceArgs.codeAppInfo.appId === codeAppInfo.appId
    ) {
        return memoizedServiceArgs
    }

    memoizedServiceArgs = {
        useIdeServerNg,
        extensionAPI,
        codeAppInfo,
        fileSystemNGService: createFileSystemNGService(extensionAPI, codeAppInfo)
    }
    return memoizedServiceArgs
}

export const fileSystemServiceFacade: IFileSystemService = {
    createFolder(ps: PS, folderName: string, parentFolder: WixCodeFileDescriptor) {
        const {useIdeServerNg, extensionAPI, codeAppInfo, fileSystemNGService} = getServiceArgsFromPS(ps)

        return useIdeServerNg
            ? fileSystemNGService.createFolder(folderName, parentFolder)
            : deprecatedFileSystemService.createFolder(extensionAPI, codeAppInfo, folderName, parentFolder)
    },
    deleteItem(extensionAPI: PS['extensionAPI'], codeAppInfo: CodeAppInfo, itemToDelete: WixCodeFileDescriptor) {
        const {useIdeServerNg, fileSystemNGService} = getServiceArgs(extensionAPI, codeAppInfo)
        return useIdeServerNg
            ? fileSystemNGService.deleteItem(itemToDelete)
            : deprecatedFileSystemService.deleteItem(extensionAPI, codeAppInfo, itemToDelete)
    },
    writeFile(
        extensionAPI: PS['extensionAPI'],
        codeAppInfo: CodeAppInfo,
        file: WixCodeFileDescriptor,
        content?: string
    ) {
        const {useIdeServerNg, fileSystemNGService} = getServiceArgs(extensionAPI, codeAppInfo)
        return useIdeServerNg
            ? fileSystemNGService.writeFile(file, content)
            : deprecatedFileSystemService.writeFile(extensionAPI, codeAppInfo, file, content)
    },
    move(ps: PS, itemToMove: WixCodeFileDescriptor, newParent: WixCodeFileDescriptor, newName?: string) {
        const {useIdeServerNg, extensionAPI, codeAppInfo, fileSystemNGService} = getServiceArgsFromPS(ps)
        return useIdeServerNg
            ? fileSystemNGService.move(itemToMove, newParent, newName)
            : deprecatedFileSystemService.move(extensionAPI, codeAppInfo, itemToMove, newParent, newName)
    },
    copy(
        extensionAPI: PS['extensionAPI'],
        codeAppInfo: CodeAppInfo,
        itemToCopy: WixCodeFileDescriptor,
        newParent: WixCodeFileDescriptor,
        newName?: string
    ) {
        const {useIdeServerNg, fileSystemNGService} = getServiceArgs(extensionAPI, codeAppInfo)
        return useIdeServerNg
            ? fileSystemNGService.copy(itemToCopy, newParent, newName)
            : deprecatedFileSystemService.copy(extensionAPI, codeAppInfo, itemToCopy, newParent, newName)
    },
    getChildren(ps: PS, parentFolder: WixCodeFileDescriptor) {
        const {useIdeServerNg, codeAppInfo, fileSystemNGService} = getServiceArgsFromPS(ps)
        return useIdeServerNg
            ? fileSystemNGService.getChildren(parentFolder)
            : deprecatedFileSystemService.getChildren(codeAppInfo, parentFolder)
    },
    readAllFiles(ps: PS) {
        const {useIdeServerNg, codeAppInfo, fileSystemNGService} = getServiceArgsFromPS(ps)
        return useIdeServerNg
            ? fileSystemNGService.readAllFiles()
            : deprecatedFileSystemService.readAllFiles(codeAppInfo)
    },
    readFile(ps: PS, file: WixCodeFileDescriptor) {
        const {useIdeServerNg, codeAppInfo, fileSystemNGService} = getServiceArgsFromPS(ps)
        return useIdeServerNg
            ? fileSystemNGService.readFile(file)
            : deprecatedFileSystemService.readFile(codeAppInfo, file)
    },
    bulkWrite(extensionAPI: PS['extensionAPI'], codeAppInfo: CodeAppInfo, files: WixCodeFile[]) {
        const {useIdeServerNg, fileSystemNGService} = getServiceArgs(extensionAPI, codeAppInfo)
        return useIdeServerNg
            ? fileSystemNGService.bulkWrite(files)
            : deprecatedFileSystemService.bulkWrite(extensionAPI, codeAppInfo, files)
    }
}
