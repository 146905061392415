import type {StructureStageData} from '../../../types'
import type {SerializedCompStructure} from '@wix/document-services-types'
import type {GroupingResult} from '../types'

const createAbsoluteOrderFromGrouping = (gr: GroupingResult, childMap: Map<string, number>) => {
    if (gr.tag === 'Group' && gr.children) {
        gr.children.forEach(child => {
            createAbsoluteOrderFromGrouping(child, childMap)
        })
    } else if (gr.tag === 'Component') {
        childMap.set(gr.id, childMap.size)
    }
}

const createRelativeOrderMap = (
    deepStructure: SerializedCompStructure,
    absoluteOrderMap: Map<string, number>,
    relativeOrderMap: Map<string, number>
) => {
    let row = 1
    if (deepStructure.components) {
        const comps = [...deepStructure.components]
        comps.sort((a, b) => {
            return (absoluteOrderMap.get(a.id!) ?? 0) - (absoluteOrderMap.get(b.id!) ?? 0)
        })
        comps.forEach(child => {
            relativeOrderMap[child.id!] = row
            row++
            createRelativeOrderMap(child, absoluteOrderMap, relativeOrderMap)
        })
    }
}

export const createOrderMap = (serverResponse: GroupingResult, data: StructureStageData) => {
    const absoluteOrderMap = new Map<string, number>()
    const relativeOrderMap = new Map<string, number>()
    createAbsoluteOrderFromGrouping(serverResponse, absoluteOrderMap)
    createRelativeOrderMap(data.deepStructure, absoluteOrderMap, relativeOrderMap)
    return relativeOrderMap
}
