import type {Pointer, PS} from '@wix/document-services-types'
import * as snapify from '@wix/snapify'

function getSelectorOfComp(compRef) {
    return `#${compRef.id}`
}

function isHTMLElement(element) {
    return element instanceof HTMLElement
}

/**
 * @param {ps} ps
 * @param {Pointer} compRef
 * @returns string
 */
function getSnapshot(ps: PS, compRef: Pointer): string {
    const selector = getSelectorOfComp(compRef)
    const element = document.querySelector(selector)

    if (!element || !isHTMLElement(element)) {
        throw new Error(`Couldn't find element for selector: '${selector}' of comp id: '${compRef.id}'`)
    }

    return snapify.createSnapshotString(element)
}

export default {
    getSnapshot
}
