const _ = require('lodash')
const {markPropertyToRemove} = require('./utils')

const sides = [
    'border-radius-top-left',
    'border-radius-top-right',
    'border-radius-bottom-left',
    'border-radius-bottom-right'
]

const borderRadiusPropertiesToRemove = ['border-radius', ...sides.map(markPropertyToRemove)]

const borderSideCssMapping = {
    topLeft: 'top-left',
    topRight: 'top-right',
    bottomLeft: 'bottom-left',
    bottomRight: 'bottom-right'
}

function borderRadiusToProperty(borderRadius) {
    const borderRadiusProperties = []
    Object.entries(borderRadius).forEach(([side, value]) => {
        const sideCss = borderSideCssMapping[side]
        borderRadiusProperties.push({
            prop: `border-radius-${sideCss}`,
            cssProp: `border-radius-${sideCss}`,
            value
        })
    })

    return borderRadiusProperties
}

function getBorderRadiusValues(borderRadius) {
    const borderRadiusValues = borderRadius.value.split(' ')
    const borderRadiusLength = borderRadiusValues.length

    if (borderRadiusLength === 1) {
        const [borderRadiusValue] = borderRadiusValues
        return {
            topLeft: borderRadiusValue,
            topRight: borderRadiusValue,
            bottomLeft: borderRadiusValue,
            bottomRight: borderRadiusValue
        }
    }

    if (borderRadiusLength === 2) {
        const [borderRadiusTopLeftAndBottomRight, borderTopRightAndBottomLeft] = borderRadiusValues
        return {
            topLeft: borderRadiusTopLeftAndBottomRight,
            topRight: borderTopRightAndBottomLeft,
            bottomRight: borderRadiusTopLeftAndBottomRight,
            bottomLeft: borderTopRightAndBottomLeft
        }
    }

    if (borderRadiusLength === 3) {
        const [borderRadiusTopLeft, borderRadiusLeftAndRight, borderRadiusBottomRight] = borderRadiusValues
        return {
            topLeft: borderRadiusTopLeft,
            topRight: borderRadiusLeftAndRight,
            bottomRight: borderRadiusBottomRight,
            bottomLeft: borderRadiusLeftAndRight
        }
    }

    const [borderRadiusTopLeft, borderRadiusTopRight, borderRadiusBottomLeft, borderRadiusBottomRight] =
        borderRadiusValues

    return {
        topLeft: borderRadiusTopLeft,
        topRight: borderRadiusTopRight,
        bottomLeft: borderRadiusBottomLeft,
        bottomRight: borderRadiusBottomRight
    }
}

function handleBorderRadiusProperty(property, borderRadiusSides) {
    if (property.cssProp === 'border-radius') {
        const {topLeft, topRight, bottomLeft, bottomRight} = getBorderRadiusValues(property)
        borderRadiusSides.topLeft = topLeft
        borderRadiusSides.topRight = topRight
        borderRadiusSides.bottomLeft = bottomLeft
        borderRadiusSides.bottomRight = bottomRight
    }

    if (sides.includes(property.cssProp)) {
        const side = _.camelCase(property.cssProp.replace('border-radius-', ''))
        property.cssProp = markPropertyToRemove(property.cssProp)
        borderRadiusSides[side] = property.value
    }
}

function getUpdatedProperties(properties, borderRadiusSides) {
    return [
        ...properties.filter(property => !borderRadiusPropertiesToRemove.includes(property.cssProp)),
        ...borderRadiusToProperty(borderRadiusSides)
    ]
}

/**
 * @param {Array<import('../index').ParsedStyleItem>} styleItems
 * @returns {Array<import('../index').ParsedStyleItem>}
 */
function migrateBorderRadius(styleItems) {
    return styleItems.map(styleItem => {
        const {parsedStyle} = styleItem
        const updatedParsedStyle = Object.entries(parsedStyle).reduce(
            (acc, [selector, {properties, mobileProperties}]) => {
                const borderRadiusSides = {}
                const mobileBorderRadiusSides = {}

                properties.forEach(property => {
                    handleBorderRadiusProperty(property, borderRadiusSides)
                })

                mobileProperties.forEach(property => {
                    handleBorderRadiusProperty(property, mobileBorderRadiusSides)
                })

                const updatedProperties = getUpdatedProperties(properties, borderRadiusSides)
                const updatedMobileProperties = getUpdatedProperties(mobileProperties, mobileBorderRadiusSides)

                return {
                    ...acc,
                    [selector]: {
                        ...acc[selector],
                        properties: updatedProperties,
                        mobileProperties: updatedMobileProperties
                    }
                }
            },
            parsedStyle
        )

        return {
            ...styleItem,
            parsedStyle: updatedParsedStyle
        }
    })
}

module.exports = migrateBorderRadius
