import baseConfig from './baseConfig'
import type {DSConfig} from '@wix/document-services-types'
import responsiveConfig from './responsiveConfig'
const modulesList: string[] = []

const extensionsList = [
    ...baseConfig.extensionsList,
    'undoRedo',
    'metadata',
    'save',
    'lifecycle',
    'layoutCircuitBreaker',
    'viewerRuntime',
    'contactFormMetaData',
    'appBuilder',
    'documentToViewer',
    'wixapps',
    'accountInfo',
    'commonConfig',
    'seo',
    'componentsByType',
    'partners',
    'platformWithViewer'
]

const config = {
    modulesList,
    extensionsList,
    cedit: false,
    disableSave: true,
    createRevision: false,
    autosaveRestore: 'false',
    keepMultiLingualModelsFromServer: true,
    disableAutoSave: true,
    continuousSave: false,
    isReadOnly: true,
    noUndo: true,
    runStylesGC: false,
    supportMobile: false,
    postUpdateOperation: true,
    lazyLoadPages: true
} as DSConfig

function getLazyPageLoadConfig(origin: string): DSConfig {
    return {...config, origin}
}

export const siteHistoryConfig: DSConfig = getLazyPageLoadConfig('siteHistory')

export const siteHistoryResponsiveConfig: DSConfig = {
    ...responsiveConfig,
    ...config,
    origin: 'siteHistory'
}

export const externalPreviewConfig: DSConfig = getLazyPageLoadConfig('externalPreview')

export const snapshotterConfig: DSConfig = getLazyPageLoadConfig('snapshotter')
