import {Extension, PointerMethods, pointerUtils, DocumentDataTypes} from '@wix/document-manager-core'
const {getPointer} = pointerUtils

/**
 * Creates the partners extension that will provide partners info to privateServices
 */
const partnersPointerType = 'partners'
const isPotentialPartner = 'isPotentialPartner'

const createExtension = (): Extension => {
    const getDocumentDataTypes = (): DocumentDataTypes => ({[partnersPointerType]: {}})

    const createPointersMethods = (): PointerMethods => {
        const getIsPotentialPartnerPointer = () => getPointer(isPotentialPartner, partnersPointerType)
        return {
            partners: {getIsPotentialPartnerPointer}
        }
    }

    return {
        name: 'partners',
        createPointersMethods,
        getDocumentDataTypes
    }
}

export {createExtension}
