import {ENFORCE_TYPES, SetOpParams} from '@wix/document-services-types'
import _ from 'lodash'

const apiFlagOverrides = {
    'components.layout.update': {
        forceAnchorsGeneration: true
    },
    'pages.navigateTo': {
        enforceType: ENFORCE_TYPES.GENERATE_AND_ENFORCE
    },
    'mobile.reLayoutPage': {
        enforceType: ENFORCE_TYPES.GENERATE_AND_ENFORCE
    },
    'viewMode.set': {
        enforceType: ENFORCE_TYPES.GENERATE_AND_ENFORCE
    },
    'components.data.update': {
        // This fixes DM-1041. Repeaters need to generate anchors based off structure before enforcing. Should be no harm for other components.
        enforceType: ENFORCE_TYPES.GENERATE_AND_ENFORCE
    },
    'documentMode.setComponentViewMode': {
        enforceType: ENFORCE_TYPES.GENERATE_AND_ENFORCE //wixCode worker is created/destroyed based on this flag -> repeater inflation -> needs generate + enforce
    },
    'mobile.hiddenComponents.hide': {
        enforceType: ENFORCE_TYPES.GENERATE_AND_ENFORCE
    },
    'mobile.hiddenComponents.show': {
        enforceType: ENFORCE_TYPES.GENERATE_AND_ENFORCE
    },
    'documentMode.enableShouldUpdateJsonFromMeasureMap': {
        needsLayoutOnAllComps: true
    }
}

const getMaybeFunctionParamValue = (queueItem: QItem, functionFieldName: string, defaultValue?: any) => {
    let value = _.get(queueItem.params, functionFieldName, defaultValue)
    if (_.isFunction(value)) {
        // eslint-disable-next-line prefer-spread
        value = value.apply(null, queueItem.args)
    }
    return value
}

export interface QItemParams extends SetOpParams {
    isRealAsyncOperation?: boolean
    needsLayoutOnAllComps?: boolean
    lockedComp?: any
}

export interface BatchItem extends QItemParams {
    itemId: number
    args: any[]
}

const getParamsWithOverrides = (params: QItemParams): QItemParams =>
    _.assign(params, apiFlagOverrides[params.methodName!] || {})

export interface QItem extends Pick<QItemParams, 'asyncPreDataManipulation' | 'enforceType' | 'isRealAsyncOperation'> {
    handle: number
    operation: Function
    args: any[]
    params: QItemParams
    snapshot: any
    firstInBatch?: boolean
}

export function createSOQItem(operation: Function, args: any[], params: QItemParams, handle: number): QItem {
    const queueItem: QItem = {
        handle,
        operation,
        args,
        params: getParamsWithOverrides(params),
        snapshot: null,
        asyncPreDataManipulation: params.asyncPreDataManipulation,
        isRealAsyncOperation: undefined
    }

    if (_.isFunction(params.isAsyncOperation) && params.methodName !== 'components.remove') {
        throw Error(
            "isAsyncOperation has to be a boolean. functions are still allowed for components.remove since we didn't have a choice. please make it false there as soon as you can"
        )
    }
    //the fact that this might be a function and that we run it here is a problem.
    //we run it too early, before all the operations before it executed (the data might be wrong)
    //but since the only bad side effect that can happen is that we won't notify some tpa widget that it's being deleted
    //we decided that it isn't so bad..
    queueItem.isRealAsyncOperation = getMaybeFunctionParamValue(queueItem, 'isAsyncOperation')
    queueItem.params.isRealAsyncOperation = queueItem.isRealAsyncOperation
    return queueItem
}
