'use strict'
const _ = require('lodash')

const exec = (pageJson, pageIdsArray, {clientSpecMap}) => {
    if (pageJson.structure.type === 'Document') {
        const {
            data: {document_data}
        } = pageJson
        _(document_data)
            .pick(pageIdsArray)
            .forOwn(({tpaApplicationId, appDefinitionId}, pageId) => {
                if (
                    tpaApplicationId &&
                    !appDefinitionId &&
                    clientSpecMap[tpaApplicationId] &&
                    clientSpecMap[tpaApplicationId].appDefinitionId
                ) {
                    document_data[pageId].appDefinitionId = clientSpecMap[tpaApplicationId].appDefinitionId
                }
            })
    }
}

module.exports = {
    name: 'addAppDefinitionIdToTpaPages',
    version: 1,
    exec
}
