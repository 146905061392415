import {pointerUtils} from '@wix/document-manager-core'
const pointerType = 'renderFlags'

const {getPointer} = pointerUtils

const createPointersMethods = () => {
    const getRenderFlag = (flagName: string) => getPointer(pointerType, pointerType, {innerPath: [flagName]})
    const getRenderFlags = () => getPointer(pointerType, pointerType)
    return {
        general: {
            getRenderFlag,
            getRenderFlags
        },
        renderFlags: {
            getRenderFlag,
            getRenderFlags
        }
    }
}

export {createPointersMethods}
