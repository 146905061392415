import _ from 'lodash'
import type {FixerActions, FixerVersionAndPageMods} from '@wix/document-services-types'

export const maxBIReportExtrasSize = 4000

const maxNumberOfPathsToReport = 5

// const exampleBIReport = {
//     tags: {
//         type: 'FixerVerChanges',
//         category: 'viewer_fixer / migration_fixer / ds_fixer'
//     },
//     extras: {
//         fixer1: {
//             ver: 1,
//             page1: ['a/b', 'c/d/e'],
//             page2: ['a/b/x', 'a/b/y', 'a/b/z'],            => 'a/b/x,y,z'
//             page3: ['a', 'b', 'c', 'd', 'e', 'f', 'g']     => ['a', 'b', 'c', 'd', 'e']
//         },
//         fixer2: {
//             ver: 1,
//             site: ['x/y']
//         }
//     }
// }

// const exampleVeryLongReport = {
//     tags: {
//         type: 'FixerVerChanges',
//         category: 'viewer_fixer / migration_fixer / ds_fixer'
//     },
//     extras: {
//         fixer1: {
//             ver: 1,
//             pages: ['page1', 'page2', 'page3', ...]
//         },
//         fixer2: {
//             ver: 1,
//             pages: ['_site_']
//         }
//     }
// }

const quotes = 2
const colon = 1
const comma = 1
const brackets = 2

export const trimLongReports = (fixerChangesOnReruns: FixerActions) => {
    let stringifiedSize = 1
    const trimmedPayload = _.mapValues(
        fixerChangesOnReruns,
        (fixerModsAndVersion: FixerVersionAndPageMods, fixerName: string) => {
            stringifiedSize += fixerName.length + brackets + colon + quotes
            return _.mapValues(fixerModsAndVersion, (paths, pageId) => {
                stringifiedSize += pageId.length + brackets + colon + quotes
                // if we're dealing with a page and not the fixer version
                if (Array.isArray(paths)) {
                    // some fixer changes have common paths up to the last path property, so combine these changes for brevity
                    const firstPath = paths[0]
                    const lastSlash = firstPath.lastIndexOf('/')
                    if (lastSlash >= 0) {
                        const prefix = firstPath.slice(0, lastSlash + 1)
                        if (paths.every(path => path.startsWith(prefix) && path.lastIndexOf('/') === lastSlash)) {
                            // convert   page1: ['a/b/x', 'a/b/y', 'a/b/z']   to   page1: 'a/b/x,y,z'
                            let combined = firstPath
                            for (let i = 1; i < paths.length; ++i) {
                                combined += `,${paths[i].slice(paths[i].lastIndexOf('/') + 1)}`
                            }
                            stringifiedSize += combined.length + brackets
                            return combined
                        }
                    }
                    // ensure that the number of reported modifications for every page is at most "maxNumberOfPathsToReport"
                    const res = paths.slice(0, maxNumberOfPathsToReport)
                    res.forEach(e => (stringifiedSize += e.length + quotes + comma))
                    return res
                }
                return paths
            })
        }
    )

    if (stringifiedSize > maxBIReportExtrasSize) {
        // Remove the modification paths from all fixers and convert the page ids to an array, example:
        // {fixer1 = {ver: 1, page1: ['a/b'], page2: ['c/d']}}}  =>  {fixer1: {ver: 1, pages: ['page1', 'page2']}}
        return _.mapValues(trimmedPayload, (fixerModsAndVersion: FixerVersionAndPageMods) => ({
            ver: fixerModsAndVersion.ver,
            pages: Object.keys(fixerModsAndVersion).filter(s => s !== 'ver')
        }))
    }

    return trimmedPayload
}
