import _ from 'lodash'
import type {Pointer} from '@wix/document-services-types'
import type {DocumentManager} from '@wix/document-manager-core'
import {DataModelExtensionAPI, RelationshipsAPI, inflationUtils} from '@wix/document-manager-extensions'
import type {PageMigrator} from '../dataMigrationRunner'
const {getRefHostTemplateId, extractTargetCompId} = inflationUtils

// We make an assumption there are no mobile-only internalRef components
// As of writing this, there are none, and we plan oneStructure to make this distinction irrelevant
const HOST_VIEW_MODE = 'DESKTOP'

const migratePage = (documentManager: DocumentManager) => {
    const {pointers, dal, extensionAPI} = documentManager
    const {referredStructure} = pointers
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const {relationships} = documentManager.extensionAPI as RelationshipsAPI

    const isInternalRef = (refHost: Pointer) => {
        const refHostData = dataModel.components.getItem(refHost, 'data')
        return refHostData?.type === 'InternalRef'
    }

    const isInternalRefOverride = (pointer: Pointer) => {
        // outer_r_middle_r_inner_r_component-dataQuery => isInternalRef(inner)
        const refHostId = getRefHostTemplateId(pointer.id)
        const innerRefHostId = extractTargetCompId(refHostId, HOST_VIEW_MODE)
        const innerRefHostPointer = pointers.getPointer(innerRefHostId, HOST_VIEW_MODE)
        return isInternalRef(innerRefHostPointer)
    }

    const isUnreferenced = (pointer: Pointer) => {
        return relationships.getReferencesToPointer(pointer).length === 0
    }

    const isGarbage = (pointer: Pointer) => {
        const targetCompId = extractTargetCompId(pointer.id, pointer.type)
        const targetCompPointer = pointers.getPointer(targetCompId, HOST_VIEW_MODE)
        return !dal.has(targetCompPointer)
    }

    const allHosts: string[] = referredStructure.getAllOverrideHosts()
    const allInternalRefs = _(allHosts)
        .map(id => extractTargetCompId(id, HOST_VIEW_MODE))
        .uniq()
        .map(id => pointers.getPointer(id, HOST_VIEW_MODE))
        .filter(pointer => isInternalRef(pointer))
        .value()

    const overrides = allInternalRefs.flatMap(pointer => referredStructure.getAllOverrides(pointer))
    const overridesToDelete = overrides.filter(
        (pointer: Pointer) => isUnreferenced(pointer) && isInternalRefOverride(pointer) && isGarbage(pointer)
    )
    overridesToDelete.forEach(pointer => dataModel.removeItemRecursively(pointer))
}

const name = 'internalRefGarbageCollector'
const version = 1

export const internalRefGarbageCollector: PageMigrator = {
    migratePage,
    name,
    version,
    fixerRequiresReruns: true
}
