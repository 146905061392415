import type {PublicMethodUtils} from '@wix/document-services-types'
import {variables} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            components: {
                variables: {
                    list: publicMethodUtils.defineGetter(variables.getComponentVariablesList),
                    get: publicMethodUtils.defineGetter(variables.getVariable),
                    add: publicMethodUtils.defineDataManipulationAction(variables.addVariable, {
                        getReturnValue: variables.getVariableToAddRef
                    }),
                    remove: publicMethodUtils.defineDataManipulationAction(variables.removeVariable),
                    update: publicMethodUtils.defineDataManipulationAction(variables.updateVariableDefinition),
                    getComponentsUsingVariable: publicMethodUtils.defineGetter(variables.getComponentsUsingVariable),
                    getVariableConnections: publicMethodUtils.defineGetter(variables.getVariableConnections),
                    getComponentVariableConnections: publicMethodUtils.defineGetter(
                        variables.getComponentVariableConnections
                    ),
                    connect: publicMethodUtils.defineGetter(variables.connect),
                    disconnect: publicMethodUtils.defineGetter(variables.disconnect),
                    value: {
                        get: publicMethodUtils.defineGetter(variables.getVariableData),
                        remove: publicMethodUtils.defineDataManipulationAction(variables.removeVariableData),
                        update: publicMethodUtils.defineDataManipulationAction(variables.updateVariableData)
                    }
                }
            }
        }
    }
}
