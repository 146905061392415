import type {DocumentManager} from '@wix/document-manager-core'
import type {MasterPageMigrator} from '../dataMigrationRunner'

const MASTER_PAGE_ID = 'masterPage'

const migrateMasterPage = (documentManager: DocumentManager) => {
    const {dal} = documentManager

    const permaLinkDataPointers = documentManager.pointers.data.getDataItemsWithPredicate(
        dataItem => dataItem.type === 'PermaLink',
        MASTER_PAGE_ID
    )

    permaLinkDataPointers.forEach(permaLinkDataPointer => {
        dal.remove(permaLinkDataPointer)
    })
}

const name = 'removePermaLinksFixer'
const version = 1

export const removePermaLinksFixer: MasterPageMigrator = {
    migrateMasterPage,
    name,
    version
}
