import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import dataManipulation from './dataManipulation'

/**
 * Enable/disable the usage of cookies for the site users
 * @param ps
 * @param enabled true if cookies should be used with the site users, false otherwise
 */
function enable(ps: PS, enabled: boolean) {
    if (!_.isBoolean(enabled)) {
        throw new Error('argument enabled must be boolean')
    }
    dataManipulation.setProperty(ps, dataManipulation.PROPERTY_NAMES.SUPPRESS_COOKIES, !enabled)
}

/**
 * Retrieves the status of the usage of cookies with the site's users
 * @returns {boolean} true if cookies are enabled for the site users, false otherwise
 */
function isEnabled(ps: PS): boolean {
    return !dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.SUPPRESS_COOKIES)
}

export default {
    cookies: {
        enable,
        isEnabled
    }
}
