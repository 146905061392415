import type {PublicMethodUtils} from '@wix/document-services-types'
import {browserThemeColor} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            browserThemeColor: {
                get: publicMethodUtils.defineGetter(browserThemeColor.getBrowserThemeColor),
                set: publicMethodUtils.defineDataManipulationAction(browserThemeColor.setBrowserThemeColor),
                reset: publicMethodUtils.defineDataManipulationAction(browserThemeColor.resetBrowserThemeColor),
                isSet: publicMethodUtils.defineGetter(browserThemeColor.isBrowserThemeColorSet)
            }
        }
    }
}
