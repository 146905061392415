import _ from 'lodash'
import clientSpecMap from '../../siteMetadata/clientSpecMap'
import constants from './constants'
import type {PS} from '@wix/document-services-types'
import type {SnapshotDal} from '@wix/document-manager-core'

function getExistingWixCodeAppFromPS(ps: PS): any {
    return _.head(clientSpecMap.filterAppsDataByType(ps, constants.WIX_CODE_SPEC_TYPE))
}

function getExistingWixCodeAppFromSnapshot(snapshotDal: SnapshotDal) {
    const specMap = snapshotDal.getValue({type: 'rendererModel', id: 'clientSpecMap'})
    return _.find(specMap, {type: constants.WIX_CODE_SPEC_TYPE})
}

function getExistingWixCodeAppFromDataProvider(dataProvider) {
    const specMap = dataProvider.clientSpecMap
    return _.find(specMap, {type: constants.WIX_CODE_SPEC_TYPE})
}

export default {
    getExistingWixCodeAppFromPS,
    getExistingWixCodeAppFromSnapshot,
    getExistingWixCodeAppFromDataProvider
}
