'use strict'

const _ = require('lodash')
const {stripHashIfExists} = require('../../helpers/dataUtils')

const TPA_MULTI_SECTION_COMP_TYPE = 'wysiwyg.viewer.components.tpapps.TPAMultiSection'
const PRO_GALLERY_FULL_SCREEN_WIDGET_ID = '144f04b9-aab4-fde7-179b-780c11da4f46'
const CHECKOUT_PAGE_WIDGET_ID = '14fd5970-8072-c276-1246-058b79e70c1a'

const getCompDataQuery = comp => stripHashIfExists(_.get(comp, ['dataQuery'], ''))
const getCompWidgetId = (pageJson, comp) =>
    _.get(pageJson, ['data', 'document_data', getCompDataQuery(comp), 'widgetId'])
const isFullScreenWidget = pageJson => comp =>
    _.includes([PRO_GALLERY_FULL_SCREEN_WIDGET_ID, CHECKOUT_PAGE_WIDGET_ID], getCompWidgetId(pageJson, comp))
const fixCompLayout = comp => _.set(comp, ['layout', 'width'], 320)

module.exports = {
    name: 'tpaFullScreenPageDataFixer',
    version: 1,
    exec(pageJson) {
        const mobileComponents = _.get(pageJson, ['structure', 'mobileComponents'])
        _(mobileComponents)
            .filter({componentType: TPA_MULTI_SECTION_COMP_TYPE})
            .filter(isFullScreenWidget(pageJson))
            .forEach(fixCompLayout)
    }
}
