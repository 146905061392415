/**
 * Created by talm on 18/08/15.
 */
import type {CompRef, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import dataModel from '../../dataModel/dataModel'
import component from '../../component/component'
import componentsMetaData from '../../componentsMetaData/componentsMetaData'
import structure from '../../structure/structure'
import dsUtils from '../../utils/utils'
import componentData from '../../component/componentData'
import constants from '../../constants/constants'

const COLUMNS_CONTAINER_TYPE = 'wysiwyg.viewer.components.StripColumnsContainer'

function splitMobileComponentPropsIfNeeded(ps: PS, componentPointer: Pointer) {
    if (componentPointer.type === constants.VIEW_MODES.DESKTOP) {
        return
    }

    if (componentData.isMobileComponentPropertiesSplit(ps, componentPointer)) {
        return
    }

    componentData.splitMobileComponentProperties(ps, componentPointer)
}

function handleColumnDeletion(ps: PS, deletedCompPointer: Pointer, deletingParent) {
    if (deletingParent) {
        return
    }

    const columnsContainerPtr =
        ps.pointers.components.getParent(deletedCompPointer) ||
        ps.pointers.full.components.getParent(deletedCompPointer)
    const columnPointers =
        ps.pointers.components.getChildren(columnsContainerPtr) ||
        ps.pointers.full.components.getChildren(columnsContainerPtr)
    const numberOfColumns = columnPointers.length

    if (numberOfColumns === 2) {
        const noMargin = {
            rowMargin: 0,
            columnsMargin: 0,
            siteMargin: 0,
            frameMargin: 0
        }

        splitMobileComponentPropsIfNeeded(ps, columnsContainerPtr)
        dataModel.updatePropertiesItem(ps, columnsContainerPtr, noMargin)
    }
}

function deleteContainerIfHasNoMoreColumns(
    ps: PS,
    componentPointer: Pointer,
    deletingParent,
    removeArgs,
    deletedParentFromFull,
    copyDataItem,
    parentPointer: CompRef
) {
    const columnPointers =
        ps.pointers.components.getChildren(parentPointer) || ps.pointers.full.components.getChildren(parentPointer)

    if (!columnPointers.length && !deletingParent) {
        component.deleteComponent(ps, parentPointer)
    }
}

function moveFullWidthCompsToPage(ps: PS, columnsContainerPtr: Pointer) {
    const singleColumnStripPtr = _.head(ps.pointers.components.getChildren(columnsContainerPtr))
    const stripChildren = ps.pointers.components.getChildren(singleColumnStripPtr)

    _.forEach(stripChildren, function (childComp) {
        if (
            dsUtils.getComponentType(ps, childComp) === COLUMNS_CONTAINER_TYPE ||
            componentsMetaData.public.isFullWidth(ps, childComp)
        ) {
            structure.reparentComponentToPage(ps, childComp, true)
        }
    })
}

function handleSplitToColumns(
    ps: PS,
    addedColumnPtr: Pointer,
    columnsContainerPtr: Pointer /*, compDefinitionPrototype*/
) {
    const columns = ps.pointers.components.getChildren(columnsContainerPtr)
    if (columns.length === 1) {
        // splitting from strip to column
        moveFullWidthCompsToPage(ps, columnsContainerPtr)
    }
}

function handleFullWidthPropertyChange(ps: PS, columnsContainerPtr: Pointer, updatedPropertiesItem) {
    const currProperties = dataModel.getPropertiesItem(ps, columnsContainerPtr)
    if (
        currProperties.fullWidth &&
        _.get(updatedPropertiesItem, 'fullWidth', currProperties.fullWidth) !== currProperties.fullWidth
    ) {
        moveFullWidthCompsToPage(ps, columnsContainerPtr)
    }
}

export default {
    handleFullWidthPropertyChange,
    handleSplitToColumns,
    handleColumnDeletion,
    deleteContainerIfHasNoMoreColumns
}
