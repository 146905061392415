import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import componentModes from '../../component/componentModes'
import * as santaCoreUtils from '@wix/santa-core-utils'
import mobileUtil from '../../mobileUtilities/mobileUtilities'

function activatePageMobileHoverModes(ps: PS, pagePointer: Pointer) {
    const pageComponentPointer = ps.pointers.components.getPage(
        pagePointer.id,
        santaCoreUtils.constants.VIEW_MODES.DESKTOP
    )
    const pageChildren = ps.pointers.components.getChildrenRecursively(pageComponentPointer)

    _(pageChildren)
        .filter(isHoverModeFoundOnComponent.bind(null, ps))
        .forEach(mobileUtil.prepareComponentStructureForMobileAlgorithm.bind(null, ps, componentModes))
}

function isHoverModeFoundOnComponent(ps: PS, componentPointer: Pointer) {
    return !!getComponentHoverMode(ps, componentPointer)
}

function getComponentHoverMode(ps: PS, componentPointer: Pointer) {
    const compModeDefinitions = componentModes.getComponentModes(ps, componentPointer)
    return _.find(compModeDefinitions, {type: 'HOVER'})
}

export default {
    activatePageMobileHoverModes
}
