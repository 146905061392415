import {Duplexer, InstanceUpdater} from '@wix/duplexer-js'
import {CoreLogger, debug} from '@wix/document-manager-core'
import {EventEmitter} from 'events'
import {editorX} from '@wix/document-manager-utils'
const log = debug('channel')

export const editorXUri = 'duplexer.editorx.com'
export const wixUri = 'duplexer.wix.com'

export const appDefId = '251a12da-ff5c-4f20-a7b0-4fc8a9eb3cf4'

export enum ChannelEvents {
    approved = 'approved',
    rejected = 'rejected',
    majorSiteChange = 'majorSiteChange',
    outOfSync = 'outOfSync',
    viewsUpdate = 'viewsUpdate',
    messageDistribute = 'messageDistribute'
}

export enum DuplexerEvents {
    connected = '@duplexer:connected',
    disconnected = '@duplexer:disconnected',
    connectError = '@duplexer:connect_error',
    subscriptionSucceeded = '@duplexer:subscription_succeeded',
    subscriptionFailed = '@duplexer:subscription_failed'
    // unsubscribe_succeeded = '@duplexer:unsubscribe_succeeded'
}

export interface EventChannel {
    on(msg: string, cb: Function): void
    emit(msg: string, ...args: any[]): void
}

export interface AppConnection extends EventChannel {
    subscribe(channelName: string, opts?: any): Promise<EventChannel>
}

export interface Channel {
    readonly connected: boolean
    on(msg: string, cb: Function): void
    removeChannelEventListeners(): void
    rejectNext(): void
    subscribe(channelName: string): Promise<EventChannel>
    simulateApproval(correlationId: string, transactionId: string): Promise<void>
    // testing
    simulateSubscriptionSucceeded(args: SubscriptionSucceededArgs): void
}

interface SubscriptionSucceededArgs {
    isSynced: boolean
}

interface ChannelConnector {
    connect(args: any): AppConnection
}

export type EventCB = (...args: any[]) => void

export type WixInstanceProvider = InstanceUpdater['getInstance']

const getUri = (origin: string) => {
    if (origin === editorX) {
        return editorXUri
    }
    return wixUri
}

export class DuplexerChannel implements Channel {
    private duplexer: ChannelConnector
    public readonly emitter: EventEmitter = new EventEmitter()
    protected _channel: EventChannel | null = null
    get channel(): EventChannel | null {
        return this._channel
    }
    private _connected: boolean = false
    protected appConnection: AppConnection | null = null
    get connected(): boolean {
        return this._connected
    }
    private logger?: CoreLogger

    constructor(
        wixInstanceProvider: WixInstanceProvider,
        origin: string,
        logger?: CoreLogger,
        private branch?: string,
        dup?: ChannelConnector
    ) {
        const instanceUpdater = {getInstance: wixInstanceProvider}
        if (dup) {
            this.duplexer = dup
        } else {
            const uri = getUri(origin)
            this.duplexer = new Duplexer(uri, {instanceUpdater}) as unknown as ChannelConnector
        }
        this.logger = logger
    }

    connect(): Promise<void> {
        this.appConnection = this.duplexer.connect({appDefId})
        return new Promise<void>((resolve, reject) => {
            this.appConnection!.on(DuplexerEvents.connected, () => {
                this._connected = true
                this.logger?.interactionStarted('DuplexerConnected')
                log.info('Duplexer successfully connected')
                resolve()
            })
            this.appConnection!.on(DuplexerEvents.connectError, (error?: Error) => {
                console.log('Error connecting to Duplexer!')
                this._connected = false
                this.logger?.interactionStarted('DuplexerConnectError', {extras: {error: JSON.stringify(error)}})
                reject(new Error('fail connecting to Duplexer'))
            })
            this.appConnection!.on(DuplexerEvents.disconnected, (error?: Error) => {
                console.log('Duplexer disconnected!')
                this._connected = false
                this.logger?.interactionStarted('DuplexerDisconnected', {extras: {error: JSON.stringify(error)}})
            })
        })
    }

    on(msg: string, cb: EventCB) {
        this.emitter.on(msg, cb)
    }

    removeChannelEventListeners() {
        this.emitter.removeAllListeners()
    }

    async subscribe(channelName: string) {
        await this.connect()
        this._channel = await this.appConnection!.subscribe(
            channelName,
            this.branch ? {resourceId: this.branch} : undefined
        )
        this._channel.on(DuplexerEvents.subscriptionSucceeded, (args: SubscriptionSucceededArgs) => {
            /**
             * if the duplexer is synced, then all missed event will be sent via the channel
             * otherwise we will load it from the document store
             */
            if (!args.isSynced) {
                this.emitter.emit(ChannelEvents.outOfSync)
            }
            log.info('Successfully subscribed to channel isSynced =', args.isSynced)
        })
        this._channel.on(DuplexerEvents.subscriptionFailed, () => {
            console.log('Error subscribing to channel!')
        })
        const events = [
            ChannelEvents.approved,
            ChannelEvents.rejected,
            ChannelEvents.majorSiteChange,
            ChannelEvents.viewsUpdate,
            ChannelEvents.messageDistribute
        ]
        events.forEach(event => {
            this._channel!.on(event, (...args: any[]) => {
                this.emitter.emit(event, ...args)
            })
        })
        return this._channel
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async simulateApproval(correlationId: string, transactionId: string) {}

    simulateSubscriptionSucceeded(args: SubscriptionSucceededArgs) {
        this._channel?.emit(DuplexerEvents.subscriptionSucceeded, args)
    }

    rejectNext() {}
}

export interface DuplexerSource {
    createDuplexer(
        wixInstanceProvider: WixInstanceProvider,
        origin: string,
        logger: CoreLogger,
        branch?: string
    ): Channel
}

export const createDuplexer = (
    wixInstanceProvider: WixInstanceProvider,
    origin: string,
    logger: CoreLogger,
    branch?: string
): Channel => new DuplexerChannel(wixInstanceProvider, origin, logger, branch)
