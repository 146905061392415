import {tpa} from '@wix/santa-ds-libs'

export class ProvisionUrlBuilder extends tpa.common.TpaBaseUrlBuilder {
    addMetaSiteId(metaSiteId: string) {
        return this.addQueryParam('metaSiteId', metaSiteId)
    }

    addEditorSessionId(editorSessionId: string) {
        return this.addQueryParam('editorSessionId', editorSessionId)
    }

    addAcceptJson() {
        return this.addQueryParam('accept', 'json')
    }

    addAppVersionAndSiteRevision(appVersion: string, siteRevision: string) {
        if (appVersion) {
            return this.addQueryParam('appVersion', appVersion).addQueryParam('siteRevision', siteRevision)
        }
        return this
    }
}
