import type {Pointer, PS} from '@wix/document-services-types'
import dataModel from '../../dataModel/dataModel'
import constants, {ResizeSides} from '../../constants/constants'

const {RESIZE_SIDES} = constants

export default {
    resizableSides(ps: PS, componentPointer: Pointer) {
        const compProps = dataModel.getPropertiesItem(ps, componentPointer)
        const sides: ResizeSides[] = [RESIZE_SIDES.LEFT, RESIZE_SIDES.RIGHT]
        if (compProps.orientation === 'horizontal') {
            return sides.concat([RESIZE_SIDES.TOP, RESIZE_SIDES.BOTTOM])
        }
        return sides
    }
}
