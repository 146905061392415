import bi from '../../bi/bi'

const errors = {
    EVENT_TYPE_NOT_SUPPORTED: {
        errorCode: 28004,
        desc: 'tpa event type is not supported',
        severity: 'error'
    },

    ECOM_HIDDEN_PAGES_MISSING: {
        errorCode: 28005,
        desc: 'ecom pages are missing after save',
        severity: 'error'
    },

    FAIL_TO_GET_APP_MARKET_DATA: {
        errorCode: 28006,
        desc: 'failure while trying to get app market data',
        severity: 'error'
    }
}

bi.register('documentServices', 'error', errors)

export default errors
