import {DocumentDataTypes, Extension, PointerMethods, pointerUtils} from '@wix/document-manager-core'

const {getPointer} = pointerUtils
const pointerType = 'appBuilder'

const createPointersMethods = (): PointerMethods => {
    const getDevSiteAppDefIdPointer = () => getPointer('appBuilder', 'devSiteAppDefId')
    const getLastBuildIdPointer = () => getPointer('appBuilder', 'lastBuildId')
    const getDraftAppNamePointer = () => getPointer('appBuilder', 'draftAppName')
    const getAppStudioModelPointer = () => getPointer('appBuilder', 'appStudioModel')

    return {
        appBuilder: {
            getDraftAppNamePointer,
            getDevSiteAppDefIdPointer,
            getLastBuildIdPointer,
            getAppStudioModelPointer
        },
        general: {
            getDevSiteAppDefIdPointer,
            getDraftAppNamePointer,
            getAppStudioModelPointer
        }
    }
}

const getDocumentDataTypes = (): DocumentDataTypes => ({[pointerType]: {}})

const createExtension = (): Extension => ({
    name: 'appBuilder',
    createPointersMethods,
    getDocumentDataTypes
})

export {createExtension}
