import {clientSaveErrors, ReportableError, saveErrorCodes, saveErrors} from '@wix/document-manager-utils'
import type {DocumentServiceError, DocumentServiceErrorType, UserInfoObject} from '@wix/document-services-types'
import _ from 'lodash'
import {VersionInfoUpdateError} from './continuousSave'
import {shouldSwitchWorkspace} from '@wix/workspace-switcher'

/**
 * document store server error codes
 * https://github.com/wix-private/editor-server/blob/master/wix-html-server/editor-document-store/src/main/scala/com/wixpress/editor/exceptions/ErrorHandler.scala
 */
export const documentStoreErrors = {
    MISSING_CONTEXT: 'MISSING_CONTEXT',
    APP_NOT_FOUND: 'APP_NOT_FOUND',
    PAGE_GROUP_NOT_FOUND: 'PAGE_GROUP_NOT_FOUND',
    MISSING_CORRELATION_ID: 'MISSING_CORRELATION_ID',
    CONFLICT_DETECTED: 'CONFLICT_DETECTED',
    MISSING_LARGE_PAYLOAD: 'MISSING_LARGE_PAYLOAD',
    MISSING_TEMP_ID: 'MISSING_TEMP_ID',
    BIG_TRANSACTION_DUPLICATE_ID: 'BIG_TRANSACTION_DUPLICATE_ID',
    SITE_NOT_FOUND: 'SITE_NOT_FOUND',
    STALE_VERSION: 'STALE_VERSION',
    TOO_MANY_TRANSACTIONS: 'TOO_MANY_TRANSACTIONS',
    TYPE_NOT_FOUND: 'TYPE_NOT_FOUND',
    STALE_TX_ID: 'STALE_TX_ID',
    SCHEMA_VALIDATION_FAILED: 'SCHEMA_VALIDATION_FAILED',
    TOO_MANY_PAGES: 'TOO_MANY_PAGES',
    BROKEN_REF_DELETED: 'delete of a referenced node',
    BROKEN_REF_ADDED: 'add node with broken ref'
}
//error codes we return to the editor
const documentStoreErrorsCode = {
    MISSING_CONTEXT: -10920,
    APP_NOT_FOUND: -10921,
    PAGE_GROUP_NOT_FOUND: -10922,
    MISSING_CORRELATION_ID: -10923,
    CONFLICT_DETECTED: -10924,
    MISSING_LARGE_PAYLOAD: -10925,
    MISSING_TEMP_ID: -10926,
    BIG_TRANSACTION_DUPLICATE_ID: -10927,
    SITE_NOT_FOUND: -10928,
    STALE_VERSION: -10929,
    TOO_MANY_TRANSACTIONS: -10930,
    TYPE_NOT_FOUND: -10931,
    STALE_TX_ID: -10932,
    IDENTITY_UNKNOWN_RUNTIME_ERROR: -10933,
    TOO_MANY_PAGES: -10934,
    CANARY_ERROR: -10935,
    UPSTREAM_CONNECT_ERROR: -10936,
    DEFERRED_TIMEOUT_ERROR: -10937,
    PUBLISH_PIPELINE_FAILED: -10938
}

const documentStoreToSaveError = {
    MISSING_CONTEXT: saveErrors.NOT_LOGGED_IN,
    APP_NOT_FOUND: saveErrors.SITE_DELETED,
    PAGE_GROUP_NOT_FOUND: saveErrors.UNKNOWN_SERVER_ERROR,
    MISSING_CORRELATION_ID: saveErrors.UNKNOWN_SERVER_ERROR,
    CONFLICT_DETECTED: saveErrors.UNKNOWN_SERVER_ERROR,
    MISSING_LARGE_PAYLOAD: saveErrors.UNKNOWN_SERVER_ERROR,
    MISSING_TEMP_ID: saveErrors.UNKNOWN_SERVER_ERROR,
    BIG_TRANSACTION_DUPLICATE_ID: saveErrors.UNKNOWN_SERVER_ERROR,
    SITE_NOT_FOUND: saveErrors.SITE_DELETED,
    STALE_VERSION: saveErrors.SITE_STALE_STATE_FROM_AUTO_SAVE,
    TOO_MANY_TRANSACTIONS: saveErrors.UNKNOWN_SERVER_ERROR,
    TYPE_NOT_FOUND: saveErrors.SITE_DESERIALIZATION_ERROR,
    STALE_TX_ID: saveErrors.SITE_STALE_STATE_FROM_AUTO_SAVE,
    '-1888': saveErrors.IDENTITY_UNKNOWN_RUNTIME_ERROR
}

const networkErrorMessages = new Set([
    'Failed to fetch',
    'NetworkError when attempting to fetch resource.',
    'Load failed'
])
export const isNetworkError = (e: any) =>
    (e instanceof TypeError && networkErrorMessages.has(e.message)) ||
    (e instanceof ReportableError && e.errorType === 'fetchJsonWithAuthorization' && e.extras?.status === 502) ||
    (e?.message || e?.extras?.result?.message || '').includes('Communications link failure')

export const isNonRecoverable = (e: any) =>
    [documentStoreErrors.TYPE_NOT_FOUND].includes(e?.details?.applicationError?.code)

export const isStaleTransactionError = (e: any) =>
    e?.details?.applicationError?.code === documentStoreErrors.STALE_TX_ID
export const isStaleVersionError = (e: any) => e?.details?.applicationError?.code === documentStoreErrors.STALE_VERSION

export interface ServerError {
    details?: {
        applicationError?: {
            code?: string
        }
    }
}

export const createDSError = (
    errorType: DocumentServiceErrorType,
    errorCode: number,
    errorDescription?: string
): DocumentServiceError => ({
    document: {errorType, errorCode, errorDescription}
})

export const staleStateError = createDSError(
    saveErrors.CONCURRENT_SAVE as DocumentServiceErrorType,
    documentStoreErrorsCode[documentStoreErrors.STALE_TX_ID]
)
export const userNotAuthorizedError = createDSError(
    saveErrors.USER_NOT_AUTHORIZED_FOR_SITE as DocumentServiceErrorType,
    saveErrorCodes.USER_NOT_AUTHORIZED_FOR_SITE
)

export const siteDeletedError = createDSError(
    saveErrors.SITE_DELETED as DocumentServiceErrorType,
    saveErrorCodes.SITE_DELETED
)

export const httpRequestError = createDSError(
    saveErrors.HTTP_REQUEST_ERROR as DocumentServiceErrorType,
    clientSaveErrors.HTTP_REQUEST_ERROR
)

export const identityUnknownRuntimeError = createDSError(
    saveErrors.IDENTITY_UNKNOWN_RUNTIME_ERROR as DocumentServiceErrorType,
    documentStoreErrorsCode[saveErrors.IDENTITY_UNKNOWN_RUNTIME_ERROR]
)

export const tooManyPagesError = createDSError(
    saveErrors.TOO_MANY_PAGES as DocumentServiceErrorType,
    documentStoreErrorsCode[saveErrors.TOO_MANY_PAGES]
)

export const publishPipelineFailedError = (data: any) =>
    createDSError(
        saveErrors.PUBLISH_PIPELINE_FAILED as DocumentServiceErrorType,
        documentStoreErrorsCode[saveErrors.PUBLISH_PIPELINE_FAILED],
        _.isString(data) ? data : JSON.stringify(data)
    ).document

const isAbacError = (e: any) => {
    const msg = (e?.message ?? '').trim()
    return msg.startsWith('ABAC ')
}

const isTooManyPagesError = (e: any) => e?.errorCode === documentStoreErrors.TOO_MANY_PAGES

const isTooManyTransactionsError = (e: any) => e?.errorCode === documentStoreErrors.TOO_MANY_TRANSACTIONS

const isBrokenRefError = (e: any) =>
    e?.errorCode === documentStoreErrors.BROKEN_REF_DELETED || e?.errorCode === documentStoreErrors.BROKEN_REF_ADDED

const isCanaryError = (e: any) => e?.errorType === 'canaryError'

const isDeferredTimeoutError = (e: any) => e?.errorType === 'DeferredTimeoutError'

const isUpstreamConnectError = (e: any) => {
    const msg = (e?.extras?.result ?? '').trim()
    return msg.startsWith('upstream connect error')
}

export const convertToDSError = (e: any, userInfo?: UserInfoObject): DocumentServiceError => {
    if (e.status === 403) {
        if (userInfo) {
            const {userId, accountId} = userInfo
            const workspaceIdMismatch = shouldSwitchWorkspace(userId, accountId)
            if (workspaceIdMismatch) {
                return createDSError(
                    saveErrors.WORKSPACE_ID_MISMATCH as DocumentServiceErrorType,
                    saveErrorCodes.WORKSPACE_ID_MISMATCH,
                    e.statusText
                )
            }
        }
        return createDSError(
            saveErrors.USER_NOT_AUTHORIZED_FOR_SITE as DocumentServiceErrorType,
            saveErrorCodes.USER_NOT_AUTHORIZED_FOR_SITE,
            e.statusText
        )
    }
    if (e.status === 404) {
        return createDSError(
            saveErrors.SITE_DELETED as DocumentServiceErrorType,
            saveErrorCodes.SITE_DELETED,
            e.statusText
        )
    }
    const code = e?.details?.applicationError?.code
    if (_.has(documentStoreToSaveError, code)) {
        return createDSError(documentStoreToSaveError[code], documentStoreErrorsCode[code])
    }
    if (isNetworkError(e) || VersionInfoUpdateError.isVersionInfoUpdateError(e)) {
        return httpRequestError
    }
    if (isAbacError(e)) {
        return identityUnknownRuntimeError
    }
    if (isTooManyPagesError(e)) {
        return tooManyPagesError
    }
    if (isTooManyTransactionsError(e)) {
        return createDSError(
            saveErrors.TOO_MANY_TRANSACTIONS as DocumentServiceErrorType,
            documentStoreErrorsCode[saveErrors.TOO_MANY_TRANSACTIONS]
        )
    }
    if (isBrokenRefError(e)) {
        return createDSError(
            saveErrors.DATA_REFERENCE_MISMATCH as DocumentServiceErrorType,
            saveErrorCodes.DATA_REFERENCE_MISMATCH,
            e.reason
        )
    }
    if (isCanaryError(e)) {
        return createDSError(
            saveErrors.CANARY_ERROR as DocumentServiceErrorType,
            documentStoreErrorsCode[saveErrors.CANARY_ERROR],
            e.message
        )
    }
    if (isUpstreamConnectError(e)) {
        return createDSError(
            saveErrors.UPSTREAM_CONNECT_ERROR as DocumentServiceErrorType,
            documentStoreErrorsCode[saveErrors.UPSTREAM_CONNECT_ERROR]
        )
    }
    if (isDeferredTimeoutError(e)) {
        return createDSError(
            saveErrors.DEFERRED_TIMEOUT_ERROR as DocumentServiceErrorType,
            documentStoreErrorsCode[saveErrors.DEFERRED_TIMEOUT_ERROR],
            e.errorDescription
        )
    }
    if (!e?.document?.errorCode) {
        const errType = code ?? saveErrors.UNKNOWN_SERVER_ERROR
        const errCode = _.get(documentStoreErrorsCode, [errType], clientSaveErrors.UNKNOWN_SERVER_ERROR)
        return createDSError(errType, errCode)
    }
    return e
}

export const makeReportable = (e: any): any => {
    if (_.has(e, ['details', 'applicationError', 'code'])) {
        const reportable = new ReportableError({
            message: e.message ?? _.get(e, ['details', 'applicationError', 'description'], 'unknown csaveError'),
            errorType: _.get(e, ['details', 'applicationError', 'code'], 'csaveError')
        })
        if (e.stack) {
            reportable.stack = e.stack
        }
        return reportable
    }

    if (!e.errorType) {
        e.errorType = 'csaveNonServerError'
    }

    return e
}

interface TransactionAlreadyApproveErrorParams {
    correlationId: string
    transactionId: string
    lastTxId: string
    source: 'processForeignTransactions' | 'duplexerOrderCheck'
}

export class TransactionAlreadyApproveError extends ReportableError {
    constructor({correlationId, transactionId, lastTxId, source}: TransactionAlreadyApproveErrorParams) {
        super({
            message: 'received an approve for an existing transaction',
            errorType: 'transactionAlreadyApprove',
            tags: {csave: true, csaveOp: 'save', source},
            extras: {correlationId, transactionId, lastTxId}
        })
    }
}

export class InvalidLastTransactionIdError extends ReportableError {
    constructor(transactionId: string) {
        super({
            message: 'new transaction id is invalid',
            errorType: 'invalidLastTransactionId',
            tags: {
                csave: true,
                csaveOp: 'save',
                transactionIdError: 'repeat'
            },
            extras: {transactionId}
        })
    }
}

export class MissingTransactionInServerPayloadError extends ReportableError {
    constructor(correlationId: string, transactionId: string) {
        super({
            message: 'Received broken transaction',
            errorType: 'missingTransactionInServerPayloadError',
            extras: {correlationId, transactionId}
        })
    }
}

export class RepeatingLastTransactionIdError extends ReportableError {
    constructor(correlationId: string, transactionId?: string) {
        super({
            message: 'new transaction id is same as previous id',
            errorType: 'repeatingLastTransactionId',
            tags: {
                csave: true,
                csaveOp: 'save',
                transactionIdError: 'repeat'
            },
            extras: {correlationId, transactionId}
        })
    }
}

export class FirstTransactionRejectionError extends ReportableError {
    constructor(correlationId: string) {
        super({
            message: 'First csave/cedit transaction was rejected',
            errorType: 'FirstTransactionRejection',
            tags: {FirstTransactionRejection: true},
            extras: {correlationId}
        })
    }
}
