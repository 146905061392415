import {loadScript, loadStyle} from './utils'
// @ts-ignore
import {version} from '../dist/version.json'

export const startTb = async (): Promise<void> => {
    const viewerSourceQuery = new URLSearchParams(window.location.search).get('viewerSource')
    const isLocal = viewerSourceQuery ? viewerSourceQuery.startsWith('https://localhost:') : false
    window.viewerSource = viewerSourceQuery ? viewerSourceQuery : version
    window.viewerBase = isLocal
        ? viewerSourceQuery ?? ''
        : `https://static.parastorage.com/services/wix-thunderbolt-ds/${window.viewerSource}`

    try {
        const response = await fetch(`${window.viewerBase}/manifest.min.json`)
        window.manifest = await response.json()
        await Promise.all(
            Object.entries(window.manifest)
                .filter(([entry]) => entry !== 'workerIframeWrapper.js')
                .map(([entry, src]) => {
                    if (/.js$/.test(src)) {
                        return loadScript(src, entry)
                    } else if (/.css$/.test(src)) {
                        return loadStyle(src, entry)
                    }
                    return null
                })
        )
    } catch (e) {
        console.error(e)
    }
}
