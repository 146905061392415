const safe = (a?: string) => (typeof a === 'undefined' ? 'undefined' : a)

function safeReplace(a: string | undefined, search: RegExp, replaceValue: string) {
    return safe(a).replace(search, replaceValue)
}

export function joinURL(...args: (string | undefined)[]): string | undefined {
    let url = args[0]
    for (let i = 1; i < args.length; ++i) {
        url = safeReplace(url, /\/$/, '') + '/' + safeReplace(args[i], /^\//, '') //eslint-disable-line prefer-template
    }
    return url
}
