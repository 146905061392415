import type {Pointer, PS, IConnectionItem} from '@wix/document-services-types'
import _ from 'lodash'
import constants from '../constants/constants'
import hooks from '../hooks/hooks'
import utils from './atomicScopesUtils'
import type {PageExtensionAPI} from '@wix/document-manager-extensions'
import experiment from 'experiment-amd'

const {
    PAGE_TYPES,
    ATOMIC_SCOPES: {URI_PREFIX}
} = constants
const {getPageUriAtomicScopeId, addAtomicScope, removeAtomicScope, changeAtomicScope} = utils

const initialize = () => {
    hooks.registerHook(hooks.HOOKS.DATA.UPDATE_BEFORE, beforePageDataChanged, PAGE_TYPES.MOBILE_PAGE)
    hooks.registerHook(hooks.HOOKS.ADD_PAGE.AFTER, afterPageAdd, PAGE_TYPES.MOBILE_PAGE)
    hooks.registerHook(hooks.HOOKS.REMOVE.BEFORE, beforePageRemove, PAGE_TYPES.MOBILE_PAGE)
    hooks.registerHook(hooks.HOOKS.WIX_CODE.SET_NICKNAME_AFTER, afterNicknameChanged)
    if (!experiment.isOpen('dm_moveAfterAddComponentHook')) {
        hooks.registerHook(hooks.HOOKS.ADD.AFTER, afterComponentAdd)
    }
}

const NICKNAMES_KEY_PREFIX = 'wcid_'
const CLEAR_NICKNAMES_DEBOUNCE_TIME = 60000
const DELETE_NICKNAMES_COUNT_THRESHOLD = 200

const getAllNicknameKeys = (ps: PS) =>
    ps.extensionAPI.data.queryKeys(
        'atomicScopes',
        null,
        (value: any, key: string): boolean => value && key.startsWith(NICKNAMES_KEY_PREFIX)
    )

const clearAtomicNicknames = (ps: PS) => {
    const keys = getAllNicknameKeys(ps)
    if (keys.length >= DELETE_NICKNAMES_COUNT_THRESHOLD) {
        keys.forEach((id: string) => {
            removeAtomicScope(ps, id)
        })
    }
}

const getAtomicScopeNicknameKey = (nickname: string) => `${NICKNAMES_KEY_PREFIX}${nickname}`

const debounce = (() => {
    let timeoutId: any
    return (callback: Function, delay: number) => {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(callback, delay)
    }
})()

const addNicknameToAtomicScopes = (ps: PS, nickname: string) => {
    addAtomicScope(ps, getAtomicScopeNicknameKey(nickname))
    debounce(() => clearAtomicNicknames(ps), CLEAR_NICKNAMES_DEBOUNCE_TIME)
}

const afterComponentAdd = (ps: PS, pointer: Pointer, serializedComp) => {
    const nickname = serializedComp.connections?.items?.find(
        (e: IConnectionItem) => e.type === 'WixCodeConnectionItem'
    )?.role
    if (nickname) {
        addNicknameToAtomicScopes(ps, nickname)
    }
}

const afterNicknameChanged = (ps: PS, pointer: Pointer, nickname: string) => {
    addNicknameToAtomicScopes(ps, nickname)
}

const afterPageAdd = (ps: PS, pagePointer: Pointer) => {
    const id = getPageUriAtomicScopeId(ps, pagePointer.id)
    addAtomicScope(ps, id)
}

const beforePageDataChanged = (ps: PS, pagePointer: Pointer, dataToChange) => {
    const {pageUriSEO} = dataToChange
    const currentPageUriSEO = (ps.extensionAPI as PageExtensionAPI).page.getPageUriSEO(pagePointer.id)
    if (pageUriSEO && !_.isEqual(pageUriSEO, currentPageUriSEO)) {
        const newId = `${URI_PREFIX}-${pageUriSEO}`
        const oldId = `${URI_PREFIX}-${currentPageUriSEO}`
        changeAtomicScope(ps, oldId, newId)
    }
}

const beforePageRemove = (ps: PS, pagePointer: Pointer) => {
    const id = getPageUriAtomicScopeId(ps, pagePointer.id)
    removeAtomicScope(ps, id)
}
export default {
    initialize,
    _atomicScopeNicknames: {
        getKey: getAtomicScopeNicknameKey,
        getAllNicknameKeys
    }
}
