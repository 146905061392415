import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import metaDataUtils from '../metaDataUtils'
import constants from '../../constants/constants'
import popupUtils from '../../page/popupUtils'

const PAGE_COMP_TYPES = [
    'mobile.core.components.Page',
    'core.components.Page',
    'wixapps.integration.components.AppPage'
]
const ANCHOR_COMP_TYPE = 'wysiwyg.viewer.components.ClassicSection'

function isContainable(ps: PS, comp: unknown, containerPointer: Pointer) {
    if (!containerPointer) {
        return false
    }

    const containerCompType = metaDataUtils.getComponentType(ps, containerPointer)
    const pagePointer = ps.pointers.components.getPageOfComponent(containerPointer)
    const isContainerPage = _.includes(PAGE_COMP_TYPES, containerCompType) && !popupUtils.isPopup(ps, pagePointer.id)
    const isContainerClassicSection = containerCompType === ANCHOR_COMP_TYPE

    return isContainerPage || isContainerClassicSection
}

const metaData = {
    resizableSides: [] as any[],
    moveDirections: [constants.MOVE_DIRECTIONS.VERTICAL],
    containable: isContainable,
    containableByStructure: isContainable,
    fullWidth: true,
    fullWidthByStructure: true,
    collapsible: false,
    mobileConversionConfig: {
        isInvisible: true
    }
}

export default metaData
