import {Extension, pointerUtils, ExtensionAPI, CreateExtArgs} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import {VIEW_MODES} from '../constants/constants'
import {EVENTS as SLOTS_EVENTS} from './slots'
import _ from 'lodash'

const {getPointer, getInnerPointer} = pointerUtils

export interface MobileConversionAPI {
    handleMobileSlotsRefs(
        ownerOfComponentInSlot: Pointer,
        componentWithSlots: Pointer,
        compInSlotPointer: Pointer
    ): void
}

export type MobileConversionExtensionAPI = ExtensionAPI & {
    mobileConversion: MobileConversionAPI
}

const createExtension = (): Extension => {
    const createExtensionAPI = ({eventEmitter, dal}: CreateExtArgs): MobileConversionExtensionAPI => {
        const handleMobileSlotsRefs = (
            ownerOfComponentInSlot: Pointer,
            componentWithSlots: Pointer,
            compInSlotPointer: Pointer
        ) => {
            const mobileParentPointer = getPointer(ownerOfComponentInSlot.id, VIEW_MODES.MOBILE)
            const mobileParentFromDal = dal.get(mobileParentPointer)
            const compWithSlotsFromDal = dal.get(componentWithSlots)

            if (
                mobileParentFromDal &&
                compWithSlotsFromDal.slotsQuery &&
                !mobileParentFromDal.components.includes(compInSlotPointer.id)
            ) {
                const mobileCompInSlotPointer = getPointer(compInSlotPointer.id, VIEW_MODES.MOBILE)

                if (!dal.has(mobileCompInSlotPointer)) {
                    dal.set(mobileCompInSlotPointer, dal.get(compInSlotPointer))
                }

                dal.set(
                    getInnerPointer(mobileParentPointer, ['components']),
                    _.uniq([compInSlotPointer.id, ...mobileParentFromDal.components])
                )

                const mobileCompSlotsQueryPointer = getPointer(componentWithSlots.id, VIEW_MODES.MOBILE, {
                    innerPath: ['slotsQuery']
                })
                dal.set(mobileCompSlotsQueryPointer, compWithSlotsFromDal.slotsQuery)
            }
        }

        eventEmitter.addListener(SLOTS_EVENTS.SLOTS.AFTER_POPULATE, handleMobileSlotsRefs)

        return {
            mobileConversion: {
                handleMobileSlotsRefs
            }
        }
    }

    return {
        name: 'mobileConversion',
        createExtensionAPI
    }
}

export {createExtension}
