import responsiveConfig from './responsiveConfig'
import _ from 'lodash'

// these were not present in testResponsiveConfig till now, and might have issues when adding - I'm guessing mainly due to initMethod
// we will add them gradually to align completely with responsiveConfig
const OMIT_FROM_TEST_CONFIG = ['smartBoxesPublicAPI', 'wixCodePublicAPI', 'componentsLoaderPublicAPI']
const modulesList = _.without(responsiveConfig.modulesList, ...OMIT_FROM_TEST_CONFIG)

export default {
    ...responsiveConfig,
    modulesList
}
