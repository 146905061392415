import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import dataModel from '../../dataModel/dataModel'
import constants from '../../constants/constants'
import baseInputMetadata from './baseInputMetadata'

const checkboxGroupMetaData = {
    defaultMobileProperties: {
        layout: 'vertical'
    },
    layoutLimits(ps: PS, compPointer: Pointer) {
        const buttonsAmount = dataModel.getDataItem(ps, compPointer).options.length
        const isVerticalLayout = dataModel.getPropertiesItem(ps, compPointer).layout === 'vertical'
        const minButtonWidth = 60
        return {
            minWidth: isVerticalLayout ? minButtonWidth : minButtonWidth * buttonsAmount
        }
    },
    resizableSides: [constants.RESIZE_SIDES.LEFT, constants.RESIZE_SIDES.RIGHT]
}

export default _.assign(checkboxGroupMetaData, baseInputMetadata)
