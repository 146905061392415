import _ from 'lodash'
import type {PublicMethodUtils} from '@wix/document-services-types'
import {wixCode} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    const provisionConfig = {
        isAsyncOperation: true,
        nonUndoable: true,
        asyncPreDataManipulation: wixCode.provision
    }

    function defineActionThatWasSupposedToBeAGetter(getter: Function) {
        // we use this for apis that were wrongly defined as actions, while they should have been defined as getters.
        // the reason we cannot just change their definition is that it makes them move from editorAPI.dsActions to editorAPI.dsRead
        // which currently breaks a lot of code outside of DM.
        // as a temporary workaround, we make sure to state that the action doesn't update data so it won't create unnecessary commits.
        return publicMethodUtils.defineAction(getter, {isUpdatingData: false})
    }

    return {
        methods: {
            wixCode: {
                provision: publicMethodUtils.defineDataManipulationAction(_.noop, provisionConfig),
                setIsolatedGridApp: publicMethodUtils.defineAction(wixCode.setIsolatedGridApp),
                getIsolatedGridApp: publicMethodUtils.defineGetter(wixCode.getIsolatedGridApp),
                getWixCodeSessionId: publicMethodUtils.defineGetter(wixCode.getWixCodeSessionId),
                getEditedGridApp: publicMethodUtils.defineGetter(wixCode.getEditedGridApp),
                isProvisioned: publicMethodUtils.defineGetter(wixCode.isProvisioned),
                getClientSpec: publicMethodUtils.defineGetter(wixCode.getClientSpec),
                generatePageComponentsModel: defineActionThatWasSupposedToBeAGetter(
                    wixCode.codeIntelligence.getPageComponentsCodeModel
                ),
                getWidgetRef: publicMethodUtils.defineGetter(wixCode.getWidgetRef),
                getCompSdkType: publicMethodUtils.defineGetter(wixCode.getCompSdkType),
                codeIntelligence: {
                    getNicknameToTypeMap: defineActionThatWasSupposedToBeAGetter(
                        wixCode.codeIntelligence.getNicknameToTypeMap
                    ),
                    generatePageComponentsCodeModel: defineActionThatWasSupposedToBeAGetter(
                        wixCode.codeIntelligence.getPageComponentsCodeModel
                    )
                },
                log: {
                    levels: wixCode.log.levels,
                    trace: publicMethodUtils.defineAction(wixCode.log.trace, {isUpdatingData: false})
                },
                fileSystem: {
                    isReadOnly: publicMethodUtils.defineGetter(wixCode.fileSystem.isReadOnly),
                    ensureAppIsWriteable: publicMethodUtils.defineAction(wixCode.fileSystem.ensureAppIsWriteable),
                    notifyFileChanges: publicMethodUtils.defineAction(wixCode.fileSystem.notifyFileChanges),
                    flush: publicMethodUtils.defineAction(wixCode.fileSystem.flush),
                    readFile: publicMethodUtils.defineAction(wixCode.fileSystem.readFile),
                    refreshFilesOrFolders: publicMethodUtils.defineAction(wixCode.fileSystem.refreshFilesOrFolders),
                    loadAllFiles: publicMethodUtils.defineAction(wixCode.fileSystem.loadAllFiles),
                    createFolder: publicMethodUtils.defineAction(wixCode.fileSystem.createFolder),
                    writeFile: publicMethodUtils.defineAction(wixCode.fileSystem.writeFile),
                    getChildren: publicMethodUtils.defineAction(wixCode.fileSystem.getChildren),
                    deleteItem: publicMethodUtils.defineAction(wixCode.fileSystem.deleteItem),
                    move: publicMethodUtils.defineAction(wixCode.fileSystem.move),
                    getRoots: defineActionThatWasSupposedToBeAGetter(wixCode.fileSystem.getRoots),
                    getVirtualDescriptor: defineActionThatWasSupposedToBeAGetter(
                        wixCode.fileSystem.getVirtualDescriptor
                    ),
                    getViewerInfo: defineActionThatWasSupposedToBeAGetter(wixCode.fileSystem.getViewerInfo),
                    subscribeToSchemasInvalidation: publicMethodUtils.defineAction(
                        wixCode.fileSystem.subscribeToSchemasInvalidation,
                        {
                            isUpdatingData: false
                        }
                    ),
                    subscribeToConcurrentChange: publicMethodUtils.defineAction(
                        wixCode.fileSystem.subscribeToConcurrentChange,
                        {isUpdatingData: false}
                    ),
                    subscribeToFlush: publicMethodUtils.defineAction(wixCode.fileSystem.subscribeToFlush, {
                        isUpdatingData: false
                    })
                },
                pages: {
                    hasCode: publicMethodUtils.defineGetter(wixCode.pages.hasCode),
                    readCode: publicMethodUtils.defineAction(wixCode.pages.readCode),
                    getFileId: defineActionThatWasSupposedToBeAGetter(wixCode.pages.getFileId)
                },
                userScripts: {
                    isUserCodeUrl: publicMethodUtils.defineGetter(wixCode.userScripts.isUserCodeUrl),
                    getSourceMapUrl: publicMethodUtils.defineGetter(wixCode.userScripts.getSourceMapUrl)
                },
                codePackages: {
                    installCodeReusePkg: publicMethodUtils.defineAction(wixCode.codePackages.installCodeReusePkg, {
                        isUpdatingData: false
                    }),
                    installNpmPkg: publicMethodUtils.defineAction(wixCode.codePackages.installNpmPkg, {
                        isUpdatingData: false
                    }),
                    uninstallNpmPkg: publicMethodUtils.defineAction(wixCode.codePackages.uninstallNpmPkg, {
                        isUpdatingData: false
                    }),
                    uninstallCodeReusePkg: publicMethodUtils.defineAction(wixCode.codePackages.uninstallCodeReusePkg, {
                        isUpdatingData: false
                    }),
                    getNpmPkgs: publicMethodUtils.defineGetter(wixCode.codePackages.getNpmPkgs),
                    getCodeReusePkgs: publicMethodUtils.defineGetter(wixCode.codePackages.getCodeReusePkgs)
                }
            }
        },
        initMethod: wixCode.initializeWixCode.bind(wixCode)
    }
}
