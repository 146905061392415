import type {EventOptions, HookEventData} from '../extensions/hooks/hooks'

export interface HooksDefinition<E extends string, D> {
    id: E
    createEvent(hookEventData: D): HookEventData<E>
}

export const createHookDefinition = <E extends string, D>(
    event: E,
    eventOptions?: EventOptions
): HooksDefinition<E, D> => {
    const createEvent = (data: D | (D & HookEventData<E>)): D & HookEventData<E> => {
        ;(data as D & HookEventData<E>).event = event
        if (eventOptions) {
            ;(data as D & HookEventData<E>).eventOptions = eventOptions
        }
        return data as D & HookEventData<E>
    }
    return {
        id: event,
        createEvent
    }
}
