export default {
    type: 'Component',
    skin: 'wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin4',
    layout: {
        width: 50,
        height: 50,
        x: 261,
        y: 19,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false
    },
    componentType: 'wysiwyg.viewer.components.MenuToggle',
    style: {
        type: 'ComponentStyle',
        metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false
        },
        style: {
            properties: {
                'alpha-bg': '0',
                'alpha-bgOpen': '0',
                bg: 'color_11',
                bgOpen: 'color_11',
                bordercolor: 'color_15',
                bordercolorOpen: 'color_15',
                'boxShadowToggleOn-shd': 'false',
                'boxShadowToggleOn-shdOpen': 'false',
                lineColor: 'color_15',
                lineColorOpen: 'color_15',
                shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
                shdOpen: '0px 1px 4px 0px rgba(0,0,0,0.6)'
            },
            propertiesSource: {
                'alpha-bg': 'value',
                'alpha-bgOpen': 'value',
                bg: 'theme',
                bgOpen: 'theme',
                bordercolor: 'theme',
                bordercolorOpen: 'theme',
                'boxShadowToggleOn-shd': 'value',
                'boxShadowToggleOn-shdOpen': 'value',
                lineColor: 'theme',
                lineColorOpen: 'theme',
                shd: 'value',
                shdOpen: 'value'
            },
            groups: {}
        },
        componentClassName: 'wysiwyg.viewer.components.MenuToggle',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin4'
    },
    behaviors: {
        type: 'ObsoleteBehaviorsList',
        items: '[{"action":{"sourceId":"MENU_AS_CONTAINER_TOGGLE","name":"click","type":"comp"},"behavior":{"targetId":"MENU_AS_CONTAINER","name":"toggle","type":"comp"}}]'
    },
    activeModes: {}
}
