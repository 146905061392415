import type {PublicMethodUtils} from '@wix/document-services-types'
import {menuAPI, dataModel} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    const {defineGetter, defineDataManipulationAction} = publicMethodUtils
    return {
        methods: {
            multilingual: {
                isMLSlugExperimentOpen: defineGetter(dataModel.multilingual.isMLSlugExperimentOpen),
                languages: {
                    remove: defineGetter(dataModel.multilingual.remove),
                    removeAll: defineGetter(dataModel.multilingual.removeAll),
                    hasTranslations: defineGetter(dataModel.multilingual.hasTranslations)
                },
                components: {
                    data: {
                        /**
                         *
                         * @param {ps} ps
                         * @param {string|undefined} languageCode
                         * @param {Pointer} componentPointer
                         * @param {boolean} deleteId
                         */
                        get: defineGetter(dataModel.multilingual.get),
                        /**
                         *
                         * @param {ps} ps
                         * @param {string|undefined} languageCode
                         * @param {Pointer} componentPointer
                         * @param {any} data
                         */
                        update: defineDataManipulationAction(dataModel.multilingual.update),
                        remove: defineDataManipulationAction(dataModel.multilingual.removeByComponent),
                        removeAll: defineDataManipulationAction(dataModel.multilingual.removeAllByComponent)
                    }
                },
                menu: {
                    /**
                     * @member documentServices.multilingual.menu
                     * @param {string} languageCode
                     * @param {string} menuId
                     * @returns {[menuData]}}
                     */
                    get: defineGetter(menuAPI.multilingual.get),

                    /**
                     * @member documentServices.multilingual.menu
                     * @param {string} languageCode
                     * @param {string} menuId
                     * @param {menuData} menuData
                     */
                    update: defineDataManipulationAction(menuAPI.multilingual.update),
                    /**
                     * @member documentServices.multilingual.menu
                     * @param {String} languageCode
                     * @param {String} menuId
                     * @param {String} itemId
                     * @param {menuItem} menuItem
                     */
                    updateItem: defineDataManipulationAction(menuAPI.multilingual.updateItem)
                }
            }
        }
    }
}
