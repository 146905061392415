import {runWithTimeout} from '@wix/document-manager-utils'

export type QueueFunction = (() => void) | (() => Promise<void>)

interface Task {
    processItem: QueueFunction
    timeout?: number
}

export interface AsyncQueue {
    add(processItem: QueueFunction): Promise<void>

    /**
     * Returns a promise that can be awaited for
     */
    toBeEmpty(): Promise<void> | null

    getSize(): number
}

export interface AsyncQueueConfig {
    useTimeout?: boolean
    queueName?: string
    defaultTimeout?: number
}

const defaultConfig: AsyncQueueConfig = {
    useTimeout: false,
    queueName: '',
    defaultTimeout: 1000 * 60 * 5
}

const createQueue = (config?: AsyncQueueConfig): AsyncQueue => {
    const cfg: AsyncQueueConfig = {...defaultConfig, ...config}
    const {defaultTimeout, queueName, useTimeout} = cfg
    const queue: Task[] = []
    let running: Promise<void> | null = null

    const run = async () => {
        try {
            while (queue.length > 0) {
                const task = queue.shift()
                if (task) {
                    const {processItem, timeout} = task
                    if (useTimeout) {
                        const taskTimeout = (timeout ?? defaultTimeout)!
                        await runWithTimeout(processItem, taskTimeout, {
                            message: `Task in ${queueName} queue timed out`
                        })
                    } else {
                        await processItem()
                    }
                }
            }
        } finally {
            running = null
        }
    }

    const add = (processItem: QueueFunction, timeout?: number): Promise<void> => {
        queue.push({processItem, timeout})
        if (!running) {
            running = run()
        }

        return running
    }

    const getSize = () => queue.length

    const toBeEmpty = () => running

    return {
        add,
        toBeEmpty,
        getSize
    }
}

export {createQueue}
