import _ from 'lodash'
import type {AppDefinitionId} from '@wix/document-services-types'
import type {GlobalOverrides} from '@wix/fedops-logger'
import type {PlatformEvent} from '@wix/platform-editor-sdk'
import constants from '../common/constants'

interface EventMessage<T extends Partial<PlatformEvent>> {
    intent: typeof constants.Intents.PLATFORM_WORKER
    type: typeof constants.MessageTypes.TRIGGER_EVENT
    appDefinitionId: AppDefinitionId
    args: T
}

export interface CustomEventOptions {
    eventType: string
    eventPayload: any
}

interface CustomEventMessage<T extends CustomEventOptions> {
    intent: typeof constants.Intents.PLATFORM_WORKER
    type: typeof constants.MessageTypes.TRIGGER_CUSTOM_EVENT
    args: T
}

function triggerEvent<T extends Partial<PlatformEvent>>(appDefinitionId: AppDefinitionId, options: T): EventMessage<T> {
    return {
        intent: constants.Intents.PLATFORM_WORKER,
        type: constants.MessageTypes.TRIGGER_EVENT,
        appDefinitionId,
        args: options
    }
}

function triggerCustomEvent<T extends CustomEventOptions>(options: T): CustomEventMessage<T> {
    return {
        intent: constants.Intents.PLATFORM_WORKER,
        type: constants.MessageTypes.TRIGGER_CUSTOM_EVENT,
        args: options
    }
}

const addApps = (apps, initData) => ({
    intent: constants.Intents.PLATFORM_WORKER,
    type: constants.MessageTypes.ADD_APPS,
    apps: _.map(apps, app =>
        _.pick(app, [
            'applicationId',
            'appFields.platform.editorScriptUrl',
            'appFields.platform.baseUrls',
            'appDefinitionId',
            'firstInstall',
            'origin',
            'biData',
            'instance',
            'instanceId',
            'appUrlQueryParams',
            'silentInstallation',
            'headlessInstallation',
            'components',
            'settings'
        ])
    ),
    initData
})

function addApp(app, initData) {
    return addApps([app], initData)
}

const removeApp = (appDefinitionId: AppDefinitionId) => ({
    intent: constants.Intents.PLATFORM_WORKER,
    type: constants.NotifyMethods.REMOVE_APP,
    appDefinitionId
})

function loadManifest(appData, initData) {
    return {
        intent: constants.Intents.PLATFORM_WORKER,
        type: constants.MessageTypes.LOAD_MANIFEST,
        appDefinitionId: appData.appDefinitionId,
        appData,
        initData
    }
}

const initialize = (
    {
        extraNamespaces,
        sdkVersion,
        editorType,
        metaSiteId,
        userId,
        commonConfig,
        wixExpConfig,
        fedopsLoggerDefaultOverrides,
        concurrentEditing,
        editorScriptHot,
        cookiesOverride,
        sentryEnv
    }: {
        extraNamespaces
        sdkVersion: string
        editorType: string
        metaSiteId: string
        userId: string
        commonConfig
        wixExpConfig
        fedopsLoggerDefaultOverrides?: GlobalOverrides
        concurrentEditing
        editorScriptHot
        cookiesOverride
        sentryEnv: string
    },
    initData
) => ({
    intent: constants.Intents.PLATFORM_WORKER,
    type: constants.MessageTypes.INITIALIZE,
    extraNamespaces,
    sdkVersion,
    editorType,
    metaSiteId,
    userId,
    commonConfig,
    wixExpConfig,
    fedopsLoggerDefaultOverrides,
    concurrentEditing,
    editorScriptHot,
    cookiesOverride,
    sentryEnv,
    initData
})

export default _.mapValues(
    {
        initialize,
        triggerEvent,
        triggerCustomEvent,
        addApp,
        addApps,
        removeApp,
        loadManifest
    },
    fn => _.flow(fn, JSON.stringify)
)
