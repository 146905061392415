import type {PublicMethodUtils} from '@wix/document-services-types'
import {
    utils,
    platform,
    provision,
    platformPages,
    addApps,
    sessionState,
    serialization,
    viewerInfoChangedEmitter,
    apiCallBiService,
    livePreview,
    platformConstants,
    uninstall
} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            platform: {
                init: publicMethodUtils.defineAction(platform.init),
                isAppActive: publicMethodUtils.defineGetter(platform.isAppActive),
                initApp: publicMethodUtils.defineAction(platform.initApp),
                isPlatformAppInstalled: publicMethodUtils.defineGetter(platform.isPlatformAppInstalled),
                getAppsDependenciesWithPredicate: publicMethodUtils.defineGetter(
                    platform.getAppsDependenciesWithPredicate
                ),
                addApps: publicMethodUtils.defineDataManipulationAction(addApps.addApps, {
                    isUpdatingAnchors: utils.YES,
                    /**
                     * @param {ps} ps
                     * @param {string} appDefinitionIds
                     * @return {{apps_ids: string}}
                     */
                    getInteractionParams: addApps.getAddAppsParams
                }),
                installationFlow: {
                    provisionApps: publicMethodUtils.defineAction(addApps.provisionApps),
                    addToDocumentWithEditorScript: publicMethodUtils.defineAction(addApps.addToDocumentWithEditorScript)
                },
                provision: publicMethodUtils.defineAction(provision.provision),
                uninstall: publicMethodUtils.defineDataManipulationAction(uninstall.uninstall, {
                    isUpdatingAnchors: utils.YES,
                    isAsyncOperation: true,
                    asyncPreDataManipulation: uninstall.notifyBeforeApplicationDelete,
                    /**
                     * @param {ps} ps
                     * @param {string} appDefinitionId
                     * @return {{app_id: string}}
                     */
                    // @ts-expect-error BUG: getInteractionParams is not defined in uninstall
                    getInteractionParams: uninstall.getUninstallInteractionParams
                }),
                update: publicMethodUtils.defineAction(provision.update_publicApi),
                subscribeOnAppEvents: publicMethodUtils.defineAction(platform.subscribeOnAppEvents),
                subscribeOnCustomAppEvents: publicMethodUtils.defineAction(platform.subscribeOnCustomAppEvents),
                notifyApplication: publicMethodUtils.defineAction(platform.notifyApplicationByAppId_publicApi),
                notifyApplicationByAppDefId: publicMethodUtils.defineAction(platform.notifyApplication),
                notifyAppsOnCustomEvent: publicMethodUtils.defineAction(platform.notifyAppsOnCustomEvent),
                registerAppToCustomEvents: publicMethodUtils.defineAction(platform.registerAppToCustomEvents),
                registerAppToCustomEventsByAppDefId: publicMethodUtils.defineAction(
                    platform.registerAppToCustomEventsByAppDefId
                ),
                getAPIForSDK: publicMethodUtils.defineGetter(platform.getAPIForSDK),
                getInstalledAppsData: publicMethodUtils.defineGetter(platform.getInstalledAppsData),
                getAppManifest: publicMethodUtils.defineGetter(platform.getAppManifest),
                hasAppManifest: publicMethodUtils.defineGetter(platform.hasAppManifest),
                getAppPublicApi: publicMethodUtils.defineGetter(platform.getAppPublicApi),
                getAppPrivateApi: publicMethodUtils.defineGetter(platform.getAppPrivateApi),
                getAppEditorApi: publicMethodUtils.defineGetter(platform.getAppEditorApi),
                getPagesDataFromAppManifest: publicMethodUtils.defineGetter(platform.getPagesDataFromAppManifest),
                getAppDataByAppDefId: publicMethodUtils.defineGetter(platform.getAppDataByAppDefId),
                getAppDataByApplicationId: publicMethodUtils.defineGetter(platform.getAppDataByApplicationId),
                getAppDescriptor: publicMethodUtils.defineGetter(platform.getAppDescriptor),
                getInstalledEditorApps: publicMethodUtils.defineGetter(platform.getInstalledEditorApps),
                getInstalledApps: publicMethodUtils.defineGetter(platform.getInstalledApps),
                editorApps: platformConstants.APPS,
                registerToAppsCompleted: publicMethodUtils.defineAction(platform.registerToAppsCompleted),
                registerToAppRemoved: publicMethodUtils.defineAction(platform.registerToAppRemoved),
                registerToComponentRemoved: publicMethodUtils.defineAction(platform.registerToComponentRemoved),
                getEditorSdkUrl: publicMethodUtils.defineGetter(platform.getEditorSdkUrl),
                pageHasPlatformApp: publicMethodUtils.defineGetter(platform.pageHasPlatformApp),
                serialization: {
                    /**
                     * Returns controller serialized data with the passed connectedComponents info (i.e connections from other components to the serialized controller)
                     * @member documentServices.platform.serialization
                     * @param {Object} serializedController - a serialized data of an appController component
                     * @param {Object} connectedComponents - a serialized connections data of components connected to the serialized controller
                     * @returns {Object} the serializedController data with the passed connectedComponents
                     */
                    setConnectedComponents: publicMethodUtils.defineAction(serialization.setConnectedComponents),
                    /**
                     * Returns controller serialized connectedComponents info (i.e connections from other components to the serialized controller)
                     * @member documentServices.platform.serialization
                     * @param {Object} serializedController - a serialized data of an appController component
                     * @returns {Object} a map from component id to an array containing the component connections to the serialized controller
                     */
                    getConnectedComponents: publicMethodUtils.defineGetter(serialization.getConnectedComponents)
                },
                sessionState: {
                    update: publicMethodUtils.defineAction(sessionState.updateSessionState),
                    get: publicMethodUtils.defineGetter(sessionState.getSessionState)
                },
                pages: {
                    navigateTo: publicMethodUtils.defineAction(platformPages.navigateTo),
                    setState: publicMethodUtils.defineDataManipulationAction(platformPages.setState),
                    getState: publicMethodUtils.defineGetter(platformPages.getState)
                },
                livePreview: {
                    refresh: publicMethodUtils.defineAction(livePreview.debouncedRefresh),
                    isActive: publicMethodUtils.defineGetter(livePreview.isActive),
                    autoRun: publicMethodUtils.defineAction(livePreview.autoRun),
                    updateWidgetViewState: publicMethodUtils.defineAction(livePreview.updateWidgetViewState),
                    updateWidgetViewStateAfterRefresh: publicMethodUtils.defineAction(
                        livePreview.updateWidgetViewStateAfterRefresh
                    ),
                    toggleLivePreviewEnabled: publicMethodUtils.defineAction(livePreview.toggleLivePreviewEnabled)
                },
                concurrentEditing: {
                    registerToPlatformAPIChange: publicMethodUtils.defineAction(
                        platform.concurrentEditing.registerToPlatformAPIChange
                    ),
                    unregisterToPlatformAPIChange: publicMethodUtils.defineAction(
                        platform.concurrentEditing.unregisterToPlatformAPIChange
                    )
                },
                registerToViewerInfoChanged: publicMethodUtils.defineAction(viewerInfoChangedEmitter.register),
                registerToManifestAdded: publicMethodUtils.defineAction(platform.registerToManifestAdded),
                registerToPublicApiSet: publicMethodUtils.defineAction(platform.registerToPublicApiSet),
                registerToPrivateApiSet: publicMethodUtils.defineAction(platform.registerToPrivateApiSet),
                registerToPublicApiUnset: publicMethodUtils.defineAction(platform.registerToPublicApiUnset),
                registerToPrivateApiUnset: publicMethodUtils.defineAction(platform.registerToPrivateApiUnset),
                reportAPICallFedOps: publicMethodUtils.defineAction(apiCallBiService.reportAPICallFedOps),
                reportAPICallBIHybridModeMethods: publicMethodUtils.defineAction(
                    apiCallBiService.reportAPICallBIHybridModeMethods
                ),
                /**
                 * Returns promise to the API by the requested apiName
                 * Used to get applications API (public and private) for applications running in Platform panels.
                 * Note: now getAppPublicApi, getAppPrivateApi, getAppEditorApi  are exposed, so no need to use requestAPIFromWorker directly
                 */
                requestAPIFromWorker: publicMethodUtils.defineGetter(platform.requestAPIFromWorker),
                migrate: publicMethodUtils.defineAction(platform.migrate),
                controllers: {
                    relocate: {
                        moveAppControllerToMasterPage: publicMethodUtils.defineDataManipulationAction(
                            platform.moveAppControllerToMasterPage
                        ),
                        moveAppControllerToPage: publicMethodUtils.defineDataManipulationAction(
                            platform.moveAppControllerToPage
                        )
                    }
                }
            }
        },
        initMethod: platform.initialize
    }
}
