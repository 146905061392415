import {tpaPostMessageCommon} from './basic-index'
import {styleUtils} from '../utils'
import tpaBi from './common/tpaBi'
import tpaNavigationService from './services/tpaNavigationService'
import * as tpaComponents from './tpaComponents/index'

const {bi, gluedWidgetMeasuringUtils} = tpaComponents
const {common} = tpaComponents
const services = {
    tpaNavigationService
}
const tpaStyleUtils = styleUtils
export {bi, gluedWidgetMeasuringUtils, common, services, tpaStyleUtils, tpaPostMessageCommon, styleUtils, tpaBi}
