import _ from 'lodash'
import type {DocumentManager} from '@wix/document-manager-core'
import type {DataFixerVersioningApi} from '@wix/document-manager-extensions/src/extensions/dataFixerVersioning/dataFixerVersioning'
import {getPageList} from './migrators/utils/migrationUtil'
import type {BaseFixer, FixerActions, FixerNameToVersion, Experiment, Pointer} from '@wix/document-services-types'
import {defaultPageMigrations, defaultRunOncePerSite} from './migrators'
import config from './config.json'
import type {FixerVersioningConfig} from '@wix/document-manager-utils'

const migratorsFixerVersioningConfig = _.pick(config, ['lastFixerIndex', 'fixers']) as FixerVersioningConfig

export enum FixerCategory {
    VIEWER = 'viewer_fixer',
    MIGRATOR = 'migration_fixer',
    DS = 'ds_fixer'
}

export interface BaseMigrator extends BaseFixer {
    disableVersioning?(documentManager: DocumentManager): boolean
}

export interface PageMigrator extends BaseMigrator {
    migratePage(
        documentManager: DocumentManager,
        pageId: string,
        experimentInstance?: Experiment,
        namespace?: string,
        pointer?: Pointer
    ): void
}

export interface MasterPageMigrator extends BaseMigrator {
    migrateMasterPage(
        documentManager: DocumentManager,
        experimentInstance?: Experiment,
        namespace?: string,
        pointer?: Pointer
    ): void
}

export type AnyPageMigrator = PageMigrator | MasterPageMigrator

export interface SiteMigrator extends BaseMigrator {
    migrateSite(
        documentManager: DocumentManager,
        experimentInstance?: Experiment,
        namespace?: string,
        pointer?: Pointer
    ): void
}

export interface DataMigrationRunner {
    runDataMigration(documentManager: DocumentManager, fixerChangesOnReruns?: FixerActions): void
}

const createDataMigrationRunner = (
    pageMigrators: AnyPageMigrator[] = defaultPageMigrations,
    runOncePerSite: SiteMigrator[] = defaultRunOncePerSite,
    fixerVersioningConfig: FixerVersioningConfig = migratorsFixerVersioningConfig
): DataMigrationRunner => {
    const runDataMigration = (documentManager: DocumentManager): void => {
        const {dataFixerVersioning} = documentManager.extensionAPI as DataFixerVersioningApi
        const fixerChangesOnReruns: FixerActions = {}

        const pageIds = getPageList(documentManager)

        const shouldDisableFixersWithoutRerunFlag = documentManager.experimentInstance.isOpen(
            'dm_onlyRerunWhenFixerRequiresReruns'
        )

        const shouldRerunMigrator = (migrator: BaseMigrator) =>
            !migrator.disableFixerAfterFirstRun &&
            (!shouldDisableFixersWithoutRerunFlag || migrator.fixerRequiresReruns)
        const addFixerVersion = (
            fixerVersions: FixerNameToVersion,
            migrator: BaseMigrator,
            versionOverride?: number
        ) => {
            const {name, version, disableVersioning} = migrator
            if (_.isFunction(disableVersioning) && disableVersioning?.(documentManager)) {
                return
            }
            fixerVersions[name] = versionOverride ?? version
        }

        const migratePage = (migrator: PageMigrator, pageId: string, fixerVersions: FixerNameToVersion) => {
            const fixerExperiment = dataFixerVersioning.decorateExperimentWithVersions(
                documentManager.experimentInstance,
                {fixerName: migrator.name},
                migrator.version,
                migrator.experimentalVersions
            )

            if (
                dataFixerVersioning.hasFixerRunOnCurrentVersion(
                    pageId,
                    FixerCategory.MIGRATOR,
                    migrator.name,
                    fixerExperiment.version,
                    fixerVersioningConfig
                )
            ) {
                if (!shouldRerunMigrator(migrator)) {
                    return
                }

                const changes = dataFixerVersioning.executeFixerAndSetModifications(
                    () => migrator.migratePage(documentManager, pageId, fixerExperiment),
                    pageId,
                    migrator.name,
                    fixerExperiment.version
                )

                _.merge(fixerChangesOnReruns, changes)
            } else {
                addFixerVersion(fixerVersions, migrator, fixerExperiment.version)
                migrator.migratePage(documentManager, pageId, fixerExperiment)
            }
        }

        const migrateMasterPage = (migrator: MasterPageMigrator, fixerVersions: FixerNameToVersion) => {
            const pageId = 'masterPage'
            const fixerExperiment = dataFixerVersioning.decorateExperimentWithVersions(
                documentManager.experimentInstance,
                {fixerName: migrator.name},
                migrator.version,
                migrator.experimentalVersions
            )
            if (
                dataFixerVersioning.hasFixerRunOnCurrentVersion(
                    pageId,
                    FixerCategory.MIGRATOR,
                    migrator.name,
                    fixerExperiment.version,
                    fixerVersioningConfig
                )
            ) {
                if (!shouldRerunMigrator(migrator)) {
                    return
                }

                const changes = dataFixerVersioning.executeFixerAndSetModifications(
                    () => migrator.migrateMasterPage(documentManager, fixerExperiment),
                    pageId,
                    migrator.name,
                    fixerExperiment.version
                )

                _.merge(fixerChangesOnReruns, changes)
            } else {
                addFixerVersion(fixerVersions, migrator, fixerExperiment.version)
                migrator.migrateMasterPage(documentManager, fixerExperiment)
            }
        }

        const masterPageId = 'masterPage'
        pageIds.forEach(pageId => {
            const fixerVersions: FixerNameToVersion = {}

            pageMigrators.forEach(migrator => {
                if ('migratePage' in migrator) {
                    migratePage(migrator, pageId, fixerVersions)
                }
                if ('migrateMasterPage' in migrator && pageId === masterPageId) {
                    migrateMasterPage(migrator, fixerVersions)
                }
            })

            dataFixerVersioning.updatePageVersionData(
                pageId,
                {[FixerCategory.MIGRATOR]: fixerVersions},
                {[FixerCategory.MIGRATOR]: fixerVersioningConfig}
            )
        })

        const fixerVersions: FixerNameToVersion = {}
        runOncePerSite.forEach(migrator => {
            const {name: fixerName, version: baseVersion, experimentalVersions} = migrator
            const fixerExperiment = dataFixerVersioning.decorateExperimentWithVersions(
                documentManager.experimentInstance,
                {fixerName: migrator.name},
                baseVersion,
                experimentalVersions
            )
            const {version: fixerVersion} = fixerExperiment
            if (
                dataFixerVersioning.hasFixerRunOnCurrentVersion(
                    masterPageId,
                    FixerCategory.MIGRATOR,
                    fixerName,
                    fixerVersion,
                    fixerVersioningConfig
                )
            ) {
                if (!shouldRerunMigrator(migrator)) {
                    return
                }
                const changes = dataFixerVersioning.executeFixerAndSetModifications(
                    () => migrator.migrateSite(documentManager, fixerExperiment),
                    '_site_',
                    fixerName,
                    fixerVersion
                )
                _.merge(fixerChangesOnReruns, changes)
            } else {
                addFixerVersion(fixerVersions, migrator)
                migrator.migrateSite(documentManager, fixerExperiment)
            }
        })
        dataFixerVersioning.updatePageVersionData(
            masterPageId,
            {[FixerCategory.MIGRATOR]: fixerVersions},
            {[FixerCategory.MIGRATOR]: fixerVersioningConfig}
        )
        dataFixerVersioning.reportFixerActions(FixerCategory.MIGRATOR, fixerChangesOnReruns)
    }

    return {
        runDataMigration
    }
}

export {createDataMigrationRunner}
