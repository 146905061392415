import type {ComponentStageData, MobileAlgoContext, Stage, StageHandler} from '../types'
import type {SerializedCompStructure} from '@wix/document-services-types'

export const createComponentStage = (): Stage<ComponentStageData> => {
    const handlers: StageHandler<ComponentStageData>[] = []
    const run = async (ctx: MobileAlgoContext, componentStructure: SerializedCompStructure, pageId: string) => {
        if (componentStructure.type === 'Component' || componentStructure.type === 'Container') {
            for (const handler of handlers) {
                await handler({globalContext: ctx, componentStructure, pageId})
            }
        }

        if (componentStructure.components) {
            for (const childStructure of componentStructure.components) {
                if (!componentStructure.mobileHints?.isLocked) {
                    await run(ctx, childStructure, pageId)
                }
            }
        }
    }

    return {
        register: (handler: StageHandler<ComponentStageData>) => {
            handlers.push(handler)
        },
        run
    }
}
