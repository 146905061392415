const {NAMESPACE_MAPPING} = require('@wix/document-services-json-schemas/dist/constants.json')

/**
 * @param {object} data - page data
 * @returns {void}
 * @description - initiate style object in page data
 */
function initiateStyleObject(data) {
    data[NAMESPACE_MAPPING.style] = data[NAMESPACE_MAPPING.style] || {}
    data[NAMESPACE_MAPPING.variants] = data[NAMESPACE_MAPPING.variants] || {}
}

module.exports = initiateStyleObject
