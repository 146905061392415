import type {Pointer, PS} from '@wix/document-services-types'
import {coreUtils} from '@wix/santa-ds-libs'
const {scrollAnchors} = coreUtils
import constants from '../constants/constants'
import namespaces from '../namespaces/namespaces'

const initAnchorsAPI = () => {}

const update = (ps: PS, compPointer: Pointer, anchorData) => {
    if (anchorData) {
        anchorData.type = 'AnchorInfo'
    }
    return namespaces.updateNamespaceData(ps, compPointer, constants.DATA_TYPES.anchors, anchorData)
}

const remove = (ps: PS, compPointer: Pointer) => {
    namespaces.removeComponentNamespaceData(ps, compPointer, constants.DATA_TYPES.anchors)
}

const get = (ps: PS, compPointer: Pointer) => namespaces.getNamespaceData(ps, compPointer, constants.DATA_TYPES.anchors)

const getPageAnchorsByPageRef = (ps: PS, pageRef: Pointer, pageTopLabel: string) => {
    const pagePointer = ps.pointers.components.getPage(pageRef.id, pageRef.type)
    const pageComponentPointers = ps.pointers.components.getChildrenRecursively(pagePointer)
    const anchorDataItems = pageComponentPointers
        .map(compPointer => {
            const anchorDataItem = namespaces.getNamespaceData(ps, compPointer, constants.DATA_TYPES.anchors)

            if (anchorDataItem) {
                anchorDataItem.compId = compPointer.id
            }

            return anchorDataItem
        })
        .filter(Boolean)
    const pageTopAnchor = scrollAnchors.getPageTopAnchor(pageRef.id, pageTopLabel)
    return [pageTopAnchor].concat(anchorDataItems)
}

const getPageAnchors = (ps: PS, pageId: string, pageTopLabel: string) => {
    const pageRef = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.DESKTOP)
    return getPageAnchorsByPageRef(ps, pageRef, pageTopLabel)
}

export default {
    init: initAnchorsAPI,
    getPageAnchorsByPageRef,
    getPageAnchors,
    update,
    remove,
    get
}
