import _ from 'lodash'
import {siteConstants} from '@wix/santa-core-utils'

const getAnchorData = function (anchorInfo) {
    return {
        id: anchorInfo.compId,
        title: anchorInfo.name
    }
}

const getCurrentPageAnchors = function (siteAPI, msg, pageId: string, pageAnchors, callback) {
    callback(getCurrentPageAnchorsInfo(pageId, pageAnchors))
}

const getCurrentPageAnchorsInfo = function (pageId: string, pageAnchors) {
    return _.map(pageAnchors, getAnchorData)
}

const navigateToAnchor = function (siteAPI, msg, pageId: string, pageAnchors, getAnchorDataId, onFailure) {
    const anchorCompId = msg.data.anchorId
    if (anchorCompId === siteConstants.PAGE_ANCHORS.TOP_ANCHOR_COMP_ID) {
        siteAPI.scrollToAnchor(siteConstants.PAGE_ANCHORS.TOP_ANCHOR_ID)
    } else if (anchorCompId === siteConstants.PAGE_ANCHORS.BOTTOM_ANCHOR_COMP_ID) {
        siteAPI.scrollToAnchor(siteConstants.PAGE_ANCHORS.BOTTOM_ANCHOR_ID)
    } else if (isAnchorExistsOnPage(pageId, pageAnchors, anchorCompId)) {
        const anchorDataId = getAnchorDataId(anchorCompId)
        if (anchorDataId) {
            siteAPI.scrollToAnchor(anchorDataId)
        }
    } else if (onFailure) {
        onFailure({
            error: {
                message: `anchor with id "${anchorCompId}" was not found on the current page.`
            }
        })
    }
}

function isAnchorExistsOnPage(pageId: string, pageAnchors, anchorCompId: string) {
    const currentPageAnchors = getCurrentPageAnchorsInfo(pageId, pageAnchors)
    return _.some(currentPageAnchors, {id: anchorCompId})
}

export default {
    getCurrentPageAnchors,
    navigateToAnchor
}
