/* eslint-disable promise/prefer-await-to-then */
import {getReportableFromError, ReportableError} from '@wix/document-manager-utils'
import {contextAdapter} from '../../utils/contextAdapter'

const DEFAULT_TIMEOUT = 60 * 1000

const onTimeout = () => {
    contextAdapter.utils.fedopsLogger.captureError(
        new ReportableError({
            errorType: 'appStoreServiceQueueTimeoutError',
            message: `appStoreService queue had timed out`,
            tags: {
                appStoreQueue: true
            }
        })
    )

    console.error('queue timeout')
}

const createQueue = (fn: Function, timeout = DEFAULT_TIMEOUT) => {
    let lastPromise = Promise.resolve()

    const run = (...args: any[]) => {
        const timeoutHandle = setTimeout(onTimeout, timeout)

        const returnPromise = lastPromise.catch(() => {}).then(() => fn(...args))

        lastPromise = returnPromise
            .catch(e => {
                contextAdapter.utils.fedopsLogger.captureError(
                    getReportableFromError(e, {
                        errorType: 'appStoreQueueOpFailed',
                        message: `appStoreService queue operation had failed`,
                        tags: {
                            appStoreQueue: true
                        }
                    })
                )
            })
            .then(() => clearTimeout(timeoutHandle))

        return returnPromise
    }

    const wait = async () => await lastPromise

    return {
        run,
        wait
    }
}
export default {
    createQueue
}
