import _ from 'lodash'
import quickActionBarManager from '../../mobileConversion/mobileEditorSettings/quickActionBarManager'
import dataModel from '../../dataModel/dataModel'

function enforceIconLimitations(ps, compPointer, updatedDataItem) {
    const compData = _.defaults({}, updatedDataItem, dataModel.getDataItem(ps, compPointer))
    quickActionBarManager.validateActionItemData(compData)
    _.assign(updatedDataItem, _.pick(compData, ['itemType', 'icon', 'text']))
}

export default {
    enforceIconLimitations
}
