import dataModel from '../../dataModel/dataModel'
import constants from '../../constants/constants'

export default {
    resizableSides(ps, compPointer) {
        const {uri} = dataModel.getPropertiesItem(ps, compPointer)

        if (!uri) {
            return []
        }

        return [constants.RESIZE_SIDES.LEFT, constants.RESIZE_SIDES.RIGHT]
    },

    mobileConversionConfig: {
        preserveAspectRatio: false
    }
}
