import type {MobileAlgoContext, Stage, StageHandler, StructureStageData} from '../types'
import type {SerializedCompStructure} from '@wix/document-services-types'

export const createStructureStage = (): Stage<StructureStageData> => {
    const handlers: StageHandler<StructureStageData>[] = []
    return {
        register: (handler: StageHandler<StructureStageData>) => {
            handlers.push(handler)
        },
        run: async (ctx: MobileAlgoContext, deepStructure: SerializedCompStructure, pageId: string) => {
            for (const handler of handlers) {
                await handler({globalContext: ctx, deepStructure, pageId})
            }
        }
    }
}
