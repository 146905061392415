import {
    type DmStore,
    type DocumentDataTypes,
    type Extension,
    type PointerMethods,
    type ModelsInitializationType,
    type DmApis,
    pointerUtils
} from '@wix/document-manager-core'

import _ from 'lodash'

const {getPointer} = pointerUtils

const pointerType = 'commonConfig'

const createPointersMethods = (): PointerMethods => {
    const getCommonConfig = () => getPointer('commonConfig', 'commonConfig')
    return {
        general: {
            getCommonConfig
        }
    }
}

const initializeModels = (
    initialStore: DmStore,
    initialModels: ModelsInitializationType,
    {coreConfig, pointers}: DmApis
) => {
    if (!coreConfig.disableCommonConfig && typeof window !== 'undefined') {
        const commonConfigPointer = pointers.general.getCommonConfig()
        initialStore.set(commonConfigPointer, _.get(window, ['commonConfig'], {}))
    }
}
const getDocumentDataTypes = (): DocumentDataTypes => ({[pointerType]: {}})

const createExtension = (): Extension => ({
    name: 'commonConfig',
    createPointersMethods,
    initializeModels,
    getDocumentDataTypes
})

export {createExtension}
