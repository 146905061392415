import type {DeepStructure} from '@wix/mobile-conversion/src/types'
import _ from 'lodash'
import * as mobileCore from '@wix/mobile-conversion'

function extractNonLayoutComponentsFromStructure(root: DeepStructure): DeepStructure[] {
    const children: DeepStructure[] = mobileCore.conversionUtils.getChildren(root)
    const nonLayoutChildren = _.remove(children, isNonLayoutComponent)
    return _.concat(nonLayoutChildren, _.flatMap(children, extractNonLayoutComponentsFromStructure))
}

function removeNonLayoutComponentsFromStructure(root: DeepStructure, nonLayoutComponents) {
    const children: DeepStructure[] = mobileCore.conversionUtils.getChildren(root)
    _.remove(children, comp => _.some(nonLayoutComponents, ['id', comp.id]))
    _.forEach(children, comp => removeNonLayoutComponentsFromStructure(comp, nonLayoutComponents))
}

function getRootForNonLayoutComponents(page) {
    const popupContainerCompType = 'wysiwyg.viewer.components.PopupContainer'
    const children = mobileCore.conversionUtils.getChildren(page)
    if (children.length === 1 && children[0].componentType === popupContainerCompType) {
        return children[0]
    }
    return page
}

function insertNonLayoutComponentsToStructure(root, comps) {
    _.forEach(comps, comp =>
        _.assign(comp.layout, {
            width: 0,
            height: 0,
            x: 0,
            y: 0
        })
    )
    mobileCore.conversionUtils.addComponentsTo(root, comps)
}

function isNonLayoutComponent(comp) {
    const desktopOnlyComponent = _.get(comp, ['conversionData', 'desktopOnly'], false)
    const nonLayoutComponent = _.get(comp, ['conversionData', 'nonLayoutComponent'], false)
    return !desktopOnlyComponent && nonLayoutComponent
}

function extractNonLayoutComponentsFromPages(page, mobilePage?) {
    const nonLayoutComponents = extractNonLayoutComponentsFromStructure(page)
    if (mobilePage && !_.isEmpty(nonLayoutComponents)) {
        // just remove comps from mobile - if needed they will be added later
        removeNonLayoutComponentsFromStructure(mobilePage, nonLayoutComponents)
    }

    return nonLayoutComponents
}

function insertNonLayoutComponentsToPage(mobilePage, nonLayoutComps) {
    const root = getRootForNonLayoutComponents(mobilePage)
    insertNonLayoutComponentsToStructure(root, nonLayoutComps)
}

export default {
    extractNonLayoutComponentsFromPages,
    insertNonLayoutComponentsToPage
}
