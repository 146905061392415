import {ReportableError, searchRequestId} from '@wix/document-manager-utils'
import type {Experiment} from '@wix/document-services-types'
import _ from 'lodash'
import type {AjaxFn, AjaxLibrary, AjaxOp} from '@wix/santa-ds-libs'
import type {ServerFacadeWithAuthorization} from '../host/serverFacadeWithAuthorization'

const APP_JSON_TYPE = 'application/json'
const TYPES_WITHOUT_CONTENT = ['GET', 'DELETE']

export async function fetchFunction(
    url: string,
    options?: RequestInit,
    dataType?: string,
    allowErrors?: boolean
): Promise<Response> {
    const res = await fetch(url, options ?? undefined)
    if (res.ok || allowErrors) {
        return res[dataType ?? 'json']()
    }
    throw res
}

const wrapErrorHandler = (handler: (response: Response) => void) => (response: Response) => {
    const requestId = searchRequestId(response)
    if (_.isFunction(response.text)) {
        response
            .clone()
            .text()
            .then((responseText: string) => handler(Object.assign(response, {response: responseText, requestId})))
    } else {
        handler(Object.assign(response, requestId))
    }
}

const ajaxMethod = (options: AjaxOp = {}, instance?: string) => {
    options.headers = options.headers ?? {}
    const contentType = options.headers['Content-Type'] || options.contentType
    if (contentType) {
        options.headers['Content-Type'] = contentType
    } else if (!options.data && !_.includes(TYPES_WITHOUT_CONTENT, options.type)) {
        options.headers['Content-Type'] = APP_JSON_TYPE
    }
    const acceptHeaders = options.headers.Accept
    if (_.includes(options.headers['Content-Type'], APP_JSON_TYPE) && !acceptHeaders) {
        options.headers.Accept = APP_JSON_TYPE
    }
    if (_.get(options, ['xhrFields', 'withCredentials'])) {
        options.credentials = 'include'
    }
    if (options.headers.Authorization && options.appIdAutoAuth) {
        throw new ReportableError({
            errorType: 'appIdAutoAuth',
            message: 'Both appIdAutoAuth and Authorization supplied to ajax call'
        })
    }
    if (options.appIdAutoAuth && !options.headers.Authorization && instance) {
        options.headers.Authorization = instance
    }
    const supportedOptions = _.pick(options, [
        'cache',
        'credentials',
        'headers',
        'integrity',
        'keepalive',
        'mode',
        'redirect',
        'referrer',
        'referrerPolicy'
    ])
    return fetchFunction(
        options.url!,
        {method: options.type, body: options.data, ...supportedOptions} as RequestInit,
        options.dataType!
    )
        .then(options.success)
        .catch(wrapErrorHandler(options.error ?? _.noop))
}

const wrapAjaxMethod = (
    serverFacade: ServerFacadeWithAuthorization,
    experimentInstance: Experiment,
    isReadOnly: boolean = false
): AjaxFn => {
    return (options?: AjaxOp): void => {
        if (isReadOnly && options?.type !== 'GET') {
            throw new Error("this operation isn't allowed while in read only mode")
        }
        if (options?.appIdAutoAuth) {
            serverFacade
                .refreshInstanceIfExpired(options?.appIdAutoAuth, 'legacyFetch')
                .then(auth => ajaxMethod(options, auth.getInstance(options!.appIdAutoAuth!)))
                .catch(options.error ?? _.noop)
        } else {
            ajaxMethod(options)
        }
    }
}

export const registerAjaxMethod = (
    ajaxLibrary: AjaxLibrary,
    serverFacade: ServerFacadeWithAuthorization,
    experimentInstance: Experiment,
    isReadOnly?: boolean
) => {
    ajaxLibrary.register(wrapAjaxMethod(serverFacade, experimentInstance, isReadOnly))
}
