import {
    CreateExtArgs,
    CreateExtensionArgument,
    DalItem,
    DalValue,
    DmApis,
    Extension,
    ExtensionAPI,
    PointerMethods,
    pointerUtils,
    ValidatorMap
} from '@wix/document-manager-core'
import {removePrefix, ReportableError} from '@wix/document-manager-utils'
import type {
    CompRef,
    CompVariantPointer,
    Pointer,
    PossibleViewModes,
    RefArray,
    ResolvedRefArray,
    ResolvedVariantRelation,
    VariantDataItem,
    VariantPointer,
    VariantRelation
} from '@wix/document-services-types'
import _ from 'lodash'
import * as constants from '../../constants/constants'
import {REF_ARRAY_DATA_TYPE} from '../../constants/constants'
import type {DataModelExtensionAPI} from '../dataModel/dataModel'
import type {RelationshipsAPI} from '../relationships'
import type {DataExtensionAPI} from '../data'
import {stripHashIfExists} from '../../utils/refArrayUtils'
import * as jsonSchemas from '@wix/document-services-json-schemas'
import * as santaCoreUtils from '@wix/santa-core-utils'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'
import type {SchemaExtensionAPI} from '../schema/schema'
import {isLocalVariant} from '../../utils/variantsUtils'
import type {LoggerExtAPI} from '../logger'
import type {HooksExtensionApi} from '../hooks/hooks'
import {VARIANT_HOOKS} from './hooks'
import {getPointerWithoutFallbacksFromPointer, isRefPointer} from '../../utils/refStructureUtils'
import type {DataAccessExtensionApi} from '../dataAccess/dataAccess'

const {
    namespaceMapping: {getNamespaceConfig}
} = jsonSchemas
export type CustomUpdater = (item: DalValue, namespace: string, pageId: string, customId?: string) => Pointer
const {getPointer, getInnerPointer} = pointerUtils
const {
    DATA_TYPES,
    VARIANTABLE_DATA_TYPES,
    DATA_TYPES_VALUES_MAP,
    RELATION_DATA_TYPES,
    VIEW_MODES,
    MASTER_PAGE_ID,
    VARIANTS: {
        MOBILE_VARIANT_ID,
        VARIANTS_QUERY,
        VARIANT_TYPES: {VARIANTS_LIST, MOBILE, SELECTED}
    }
} = constants

const NON_DUPLICATABLE_VARIANTS = [SELECTED]

export class NonExistentVariantError extends Error {
    constructor(public variantId: string) {
        super(`variant ${variantId} does not exist`)
    }
}
interface PointerVariantData {
    pageId: string
    variants?: any[]
    relationPointer: Pointer | null
    refArrayOrValue: DalValue
    isRefArray: boolean
}
export interface VariantsAPI {
    getContextsForComponent(pointer: CompRef, namespaces?: string[]): Record<string, VariantPointer[][]>
    removeRelation(
        ownerPointerWithVariants: Pointer,
        relationPointer: Pointer,
        namespace: string,
        pageId: string,
        refArrPointer: Pointer
    ): void
    setComponentVariantRelationRefArray(
        compPointer: Pointer,
        refArrayNamespace: string,
        newRefArray: Partial<ResolvedRefArray<any>>,
        languageCode?: string,
        useOriginalLanguageFallback?: boolean
    ): void
    getPointerVariantsData(
        pointerWithVariants: Pointer,
        namespace: string,
        refArrayOrValuePointer: Pointer
    ): PointerVariantData
    getRelationPointerFromRefArrayByVariants(
        namespace: string,
        refArray: RefArray,
        variants: any[] | undefined,
        pageId: string
    ): Pointer | null
    exactMatchVariantRelationPredicateItem(relation: VariantRelation, variants: any[]): boolean
    getConditionalValueByRelationPtr(namespace: string, relationPointer: Pointer): Pointer
    getConditionalValuePointerByVariants(
        namespace: string,
        refArray: any,
        variants: any[],
        pageId: string,
        relationPointer: Pointer
    ): Pointer | null | undefined
    getDefaultValuePointer(namespace: string, refArray: any, pageId: string): null | Pointer
    getDataPointerConsideringVariants(
        pointerWithVariants: Pointer,
        namespace: string,
        referenceName?: string
    ): Pointer | null | undefined
    getComponentDataPointerConsideringVariants(
        compPointerWithVariants: Pointer,
        namespace: string
    ): Pointer | null | undefined
    getVariantOwner(variantPointer: Pointer): CompRef
    addMobileVariant(): void
    getItemConsideringVariants(
        id: string,
        namespace: string,
        pageId: string,
        variants: Pointer[],
        languageCode?: string,
        useOriginalLanguageFallback?: boolean
    ): DalValue | void
    getComponentItemConsideringVariants(
        compPointer: Pointer,
        namespace: string,
        languageCode?: string,
        useOriginalLanguageFallback?: boolean
    ): DalValue | void
    removeComponentDataConsideringVariants(componentPointer: Pointer, namespace: string): void
    getDataPointerConsideringVariantsForRefPointer(
        pointerWithVariants: Pointer,
        namespace: string,
        refArrayOrValuePointer: Pointer
    ): Pointer | null | undefined
    updateDataConsideringVariants(
        pointerWithVariants: Pointer,
        valueToUpdate: DalValue,
        namespace: string,
        referenceName?: string,
        customUpdater?: CustomUpdater
    ): Pointer
    updateComponentDataConsideringVariants(
        compPointerWithVariants: Pointer,
        valueToUpdate: DalValue,
        namespace: string,
        customUpdater?: CustomUpdater
    ): Pointer
    createAndAddScopedValueToRelation(
        pointerWithVariants: Pointer,
        valueToUpdate: DalValue,
        namespace: string,
        refArrayOrValue: DalValue,
        variants: any[],
        pageId: string,
        customUpdater?: CustomUpdater
    ): {dataItemPointer: Pointer; refArrPointer: Pointer}
    addScopedValueToRelation(
        compRef: Pointer,
        scopedValueId: string,
        namespace: string,
        refArrayOrDataItem: Pointer,
        variants: string[],
        pageId: string
    ): Pointer
    removeDataConsideringVariants(pointerWithVariants: Pointer, namespace: string, referenceName?: string): void
    removeRefArrayDataConsideringVariants(
        pointerWithVariants: Pointer,
        namespace: string,
        refArrPointer: Pointer,
        referenceName?: string
    ): void
    hasOverrides(compPointer: CompVariantPointer, namespace: string): boolean
}

export type VariantsExtensionAPI = ExtensionAPI & {
    variants: VariantsAPI
}

const NO_MATCH: string[] = []

const createPointersMethods = ({dal}: DmApis): PointerMethods => {
    const getVariantRelations = (itemDataType: string, variantId: string) => {
        const result = dal.query(itemDataType, dal.queryFilterGetters.getVariantRelationFilter(`#${variantId}`))
        return _(result)
            .keys()
            .map(id => getPointer(id, itemDataType))
            .value()
    }
    const getVariantDataItemsByComponentId = (componentId: string) =>
        dal.query(DATA_TYPES.variants, dal.queryFilterGetters.getVariantFilter(componentId))

    return {
        components: {
            getComponentVariantsPointer: (compPointer: Pointer, variants: VariantPointer[] = []): Pointer =>
                ({
                    ...compPointer,
                    ...(variants?.length && {
                        variants: _.unionWith(
                            compPointer.variants,
                            variants,
                            (variantPointer1: Pointer, variantPointer2: Pointer) =>
                                variantPointer1.id === variantPointer2.id
                        )
                    })
                } as CompVariantPointer)
        },
        data: {
            getVariantRelations,
            getVariantDataItemsByComponentId
        },
        variants: {
            getVariantRelations,
            getVariantDataItemsByComponentId
        }
    }
}

const createFilters = () => ({
    getVariantRelationFilter: (namespace: string, value: DalItem): string[] => {
        if (value.variants && value.type === RELATION_DATA_TYPES.VARIANTS) {
            return value.variants
        }
        return NO_MATCH
    },
    getVariantFilter: (namespace: string, value: any): string[] => {
        if (value?.componentId) {
            return [value.componentId]
        }
        return NO_MATCH
    }
})

const createExtensionAPI = ({dal, extensionAPI, pointers}: CreateExtArgs): VariantsExtensionAPI => {
    const dataAccess = (extensionAPI as DataAccessExtensionApi).dataAccess.withSuper
    const dataModel = (extensionAPI as DataModelExtensionAPI).dataModel.withSuper
    const {data: dataApi} = extensionAPI as DataExtensionAPI
    const {relationships} = extensionAPI as RelationshipsAPI
    const {hooks: hooksApi} = extensionAPI as HooksExtensionApi
    const {schemaAPI} = extensionAPI as SchemaExtensionAPI
    const getVariantRelationsByVariantsMap = (
        variantRelations: ResolvedVariantRelation<any>[]
    ): Record<string, ResolvedVariantRelation<any>> => {
        const variantRelationsByVariants = {}
        for (const variantRelation of variantRelations ?? []) {
            const key = variantRelation.variants?.join(',')
            variantRelationsByVariants[key] = variantRelation
        }

        return variantRelationsByVariants
    }

    const removeItemIfOrphaned = (itemPointer: Pointer) => {
        const referencesToPointer = relationships.getReferencesToPointer(itemPointer)
        if (referencesToPointer.length === 0) {
            dataAccess.remove(itemPointer)
        }
    }

    const removeVariantRelationAndRefsIfNecessary = (
        dalItem: ResolvedVariantRelation<any> | any,
        itemNamespace: string
    ) => {
        const itemPointer = getPointer(dalItem.id, itemNamespace)
        removeItemIfOrphaned(itemPointer)

        for (const variantId of dalItem.variants ?? []) {
            const variantPointer = getPointer(removePrefix(variantId, '#'), DATA_TYPES.variants)
            removeItemIfOrphaned(variantPointer)
        }

        if (dalItem.to) {
            const toPointer = getPointer(removePrefix(dalItem.to.id, '#'), itemNamespace)
            removeItemIfOrphaned(toPointer)
        }
    }

    const verifyVariantsExist = (variantRelations: ResolvedVariantRelation<any>[]) => {
        const variantLists = variantRelations.map(
            value => value.variants?.map((variantId: string) => removePrefix(variantId, '#')) ?? []
        )
        const allVariants = _.uniq(_.flatten(variantLists))
        for (const variantId of allVariants) {
            const variantPointer = getPointer(variantId, DATA_TYPES.variants)
            if (!dataAccess.get(variantPointer)) {
                throw new NonExistentVariantError(variantId)
            }
        }
    }

    const setComponentVariantRelationRefArray = (
        compPointer: Pointer,
        refArrayNamespace: string,
        newRefArray: Partial<ResolvedRefArray<any>>,
        languageCode?: string,
        useOriginalLanguageFallback?: boolean
    ) => {
        verifyVariantsExist(newRefArray.values!)

        const refArrayInDal = dataModel.components.getItem(
            compPointer,
            refArrayNamespace,
            languageCode,
            useOriginalLanguageFallback
        ) as ResolvedRefArray<any>

        const refArrayInDalByVariants = getVariantRelationsByVariantsMap(refArrayInDal?.values)
        const newRefArrayByVariants = getVariantRelationsByVariantsMap(newRefArray.values!)

        for (const [variantsKey, variantRelation] of Object.entries(newRefArrayByVariants)) {
            const matchingVariantRelationInDal = refArrayInDalByVariants[variantsKey]
            if (matchingVariantRelationInDal) {
                // reuse the id of the existing variant relation to prevent the creation of a new one
                variantRelation.id = matchingVariantRelationInDal.id
                if (
                    variantRelation.to &&
                    typeof variantRelation.to === 'object' &&
                    typeof matchingVariantRelationInDal.to === 'object'
                ) {
                    // reuse the id of the data item to prevent the creation of a new one
                    variantRelation.to.id = matchingVariantRelationInDal.to.id
                }
                delete refArrayInDalByVariants[variantsKey]
            }
        }

        dataModel.components.addItem(compPointer, refArrayNamespace, newRefArray, languageCode)
        for (const leftoverVariantRelation of Object.values(refArrayInDalByVariants)) {
            removeVariantRelationAndRefsIfNecessary(leftoverVariantRelation, refArrayNamespace)
        }
    }

    const exactMatchVariantRelationPredicateItem = (relation: VariantRelation, variants: string[]) =>
        _.get(relation, ['type']) === RELATION_DATA_TYPES.VARIANTS &&
        _.isEqual(_.sortBy(dataApi.variantRelation.extractAllVariants(relation)), _.sortBy(variants))

    const getRelationPointerFromRefArrayByVariants = (
        namespace: string,
        refArray: RefArray,
        variants: any[] | undefined,
        pageId: string
    ): Pointer | null => {
        if (!dataApi.refArray.isRefArray(refArray) || !variants) {
            return null
        }

        const relationId = _.find(dataApi.refArray.extractValuesWithoutHash(refArray), refValueId => {
            const refValuePointer = pointers.data.getItem(namespace, refValueId, pageId)
            const refValue = dataAccess.get(refValuePointer)
            return exactMatchVariantRelationPredicateItem(refValue, variants)
        })

        return relationId ? pointers.data.getItem(namespace, relationId, pageId) : null
    }

    const getPointerVariantsData = (
        pointerWithVariants: Pointer,
        namespace: string,
        refArrayOrValuePointer: Pointer
    ): PointerVariantData => {
        const compPointerToGetPage = isRefPointer(pointerWithVariants)
            ? getPointer(displayedOnlyStructureUtil.getRootRefHostCompId(pointerWithVariants.id), VIEW_MODES.DESKTOP)
            : pointerWithVariants
        const pagePointer = pointers.structure.getPageOfComponent(compPointerToGetPage)
        const pageId = pagePointer?.id
        const refArrayOrValue =
            refArrayOrValuePointer && dataAccess.has(refArrayOrValuePointer)
                ? dataAccess.get(refArrayOrValuePointer)
                : null

        const variants = pointers.structure.isWithVariants(pointerWithVariants)
            ? _.map(pointerWithVariants.variants, 'id')
            : undefined
        const relationPointer = getRelationPointerFromRefArrayByVariants(namespace, refArrayOrValue, variants, pageId)
        const isRefArray = dataApi.refArray.isRefArray(refArrayOrValue)
        return {
            pageId,
            variants,
            relationPointer,
            refArrayOrValue,
            isRefArray
        }
    }

    const getDefaultValuePointer = (namespace: string, refArray: DalValue, pageId: string) => {
        if (!dataApi.refArray.isRefArray(refArray)) {
            return null
        }

        const nonRelationId = _.find(dataApi.refArray.extractValuesWithoutHash(refArray), refValueId => {
            const refValuePointer = pointers.data.getItem(namespace, refValueId, pageId)
            const refValue = dataAccess.get(refValuePointer)
            return _.get(refValue, ['type']) !== RELATION_DATA_TYPES.VARIANTS
        })

        return nonRelationId ? pointers.data.getItem(namespace, nonRelationId, pageId) : null
    }

    const getConditionalValueByRelationPtr = (namespace: string, relationPointer: Pointer) => {
        const pageId = pointers.data.getPageIdOfData(relationPointer)
        const relation = dataAccess.get(relationPointer)
        const scopedValueId = dataApi.variantRelation.extractTo(relation)
        return pointers.data.getItem(namespace, scopedValueId, pageId)
    }

    const getConditionalValuePointerByVariants = (
        namespace: string,
        refArray: any,
        variants: any[],
        pageId: string,
        relationPointer: Pointer
    ): Pointer | null | undefined => {
        if (!DATA_TYPES_VALUES_MAP[namespace]) {
            throw new Error(`data type ${namespace}, is not valid`)
        }

        const relationPointerFromArray =
            relationPointer || getRelationPointerFromRefArrayByVariants(namespace, refArray, variants, pageId)
        if (!relationPointerFromArray) {
            return undefined
        }

        return getConditionalValueByRelationPtr(namespace, relationPointer)
    }

    const getDataPointerConsideringVariantsForRefPointer = (
        pointerWithVariants: Pointer,
        namespace: string,
        refArrayOrValuePointer: Pointer
    ): Pointer | null | undefined => {
        //lines added before

        const {pageId, refArrayOrValue, variants, relationPointer, isRefArray} = getPointerVariantsData(
            pointerWithVariants,
            namespace,
            refArrayOrValuePointer
        )
        if (isRefArray) {
            if (variants) {
                return getConditionalValuePointerByVariants(
                    namespace,
                    refArrayOrValue,
                    variants,
                    pageId,
                    relationPointer!
                )
            }

            return getDefaultValuePointer(namespace, refArrayOrValue, pageId)
        }

        return !variants ? refArrayOrValuePointer : null
    }

    const getDataPointerConsideringVariants = (
        pointerWithVariants: Pointer,
        namespace: string,
        referenceName?: string
    ) => {
        const referencePointer = schemaAPI.getReferencePointer(pointerWithVariants, namespace, referenceName)
        if (!referencePointer) {
            return null
        }

        const refArrayOrValuePointer = pointerWithVariants.variants
            ? getPointer(referencePointer.id, referencePointer.type, {
                  variants: pointerWithVariants.variants
              })
            : referencePointer
        return getDataPointerConsideringVariantsForRefPointer(pointerWithVariants, namespace, refArrayOrValuePointer)
    }

    const getComponentDataPointerConsideringVariants = (
        compPointerWithVariants: Pointer,
        namespace: string
    ): Pointer | null | undefined => {
        const refArrayOrValuePointer = dataModel.components.getItemPointer(compPointerWithVariants, namespace)
        const valuePointer = refArrayOrValuePointer
            ? getDataPointerConsideringVariantsForRefPointer(compPointerWithVariants, namespace, refArrayOrValuePointer)
            : null

        if (!valuePointer) {
            return valuePointer
        }
        return valuePointer
    }

    const getVariantOwner = (variantPointer: Pointer, viewMode: PossibleViewModes = 'DESKTOP'): CompRef => {
        const variantData = dataAccess.get(variantPointer) as VariantDataItem

        if (variantData.type === 'BreakpointRange') {
            const [breakpointsData] = relationships.getReferencesToPointer(variantPointer, 'variants')
            return getVariantOwner(breakpointsData, viewMode)
        }

        return getPointer(variantData.componentId, viewMode) as CompRef
    }

    const hasOverrides = (compPointer: CompVariantPointer, namespace: string): boolean => {
        if (
            !Array.isArray(compPointer.variants) ||
            !compPointer.variants.length ||
            !(namespace in VARIANTABLE_DATA_TYPES)
        ) {
            return false
        }

        const variantsIds = compPointer.variants.map(variantPointer => variantPointer.id)
        const variantRelations = pointers.data
            .getVariantRelations(namespace, variantsIds[0])
            .map(pointer => dal.get(pointer))
            .filter(
                relation =>
                    compPointer.id === dataApi.variantRelation.extractFrom(relation) &&
                    exactMatchVariantRelationPredicateItem(relation, variantsIds)
            )

        return !!variantRelations.length
    }

    const addMobileVariant = () => {
        const mobilePointer = pointers.data.getVariantsDataItem(MOBILE_VARIANT_ID, MASTER_PAGE_ID)
        if (dataAccess.get(mobilePointer)) {
            return
        }

        const variantsListPtr = dataModel.addItem(
            {
                type: VARIANTS_LIST,
                componentId: MASTER_PAGE_ID,
                values: [{type: MOBILE, componentId: MASTER_PAGE_ID, id: MOBILE_VARIANT_ID}]
            },
            DATA_TYPES.variants,
            MASTER_PAGE_ID
        )

        const masterPage = pointers.structure.getMasterPage(VIEW_MODES.DESKTOP)
        dataModel.components.linkComponentToItemByTypeDesktopAndMobile(
            masterPage,
            variantsListPtr.id,
            DATA_TYPES.variants,
            VARIANTS_QUERY
        )
    }

    const getComponentItemConsideringVariants = (
        compPointerWithVariants: Pointer,
        namespace: string,
        languageCode?: string,
        useOriginalLanguageFallback: boolean = false
    ) => {
        const pageId = _.get(pointers.structure.getPageOfComponent(compPointerWithVariants), ['id'])
        const variantPointer = getComponentDataPointerConsideringVariants(compPointerWithVariants, namespace)
        if (variantPointer) {
            return dataModel.getItem(variantPointer.id, namespace, pageId, languageCode, useOriginalLanguageFallback)
        }
    }

    const getItemConsideringVariants = (
        id: string,
        namespace: string,
        pageId: string,
        variants: Pointer[],
        languageCode?: string,
        useOriginalLanguageFallback: boolean = false
    ): any => {
        const variantPointer = getDataPointerConsideringVariants(getPointer(id, namespace, {variants}), namespace)
        if (variantPointer) {
            return dataModel.getItem(variantPointer.id, namespace, pageId, languageCode, useOriginalLanguageFallback)
        }
    }
    const validateNamespaceIsDefinedWithRelationalSplit = (namespace: string) => {
        const config = getNamespaceConfig(namespace)
        if (!config.isRelationalSplitFromQuery) {
            throw new Error(`${namespace} namespace should have isRelationalSplitFromQuery`)
        }
    }

    const removeReferenceToRefArray = (
        ownerPointerWithVariants: Pointer,
        namespace: string,
        refArrPointer: Pointer,
        referenceName?: string
    ) => {
        if (isRefPointer(refArrPointer)) {
            return dataModel.removeItemRecursively(refArrPointer)
        }

        const isComponent = _.includes(VIEW_MODES, ownerPointerWithVariants.type)

        if (!referenceName && isComponent) {
            dataModel.components.removeItemForDesktopAndMobile(ownerPointerWithVariants.id, namespace)
        } else if (referenceName || isComponent) {
            const referencePath = schemaAPI.getReferencePath(ownerPointerWithVariants, namespace, referenceName)
            const data = dataAccess.get(ownerPointerWithVariants)

            if (referencePath) {
                dataAccess.set(ownerPointerWithVariants, _.omit(data, referencePath))
                dataModel.removeItemRecursively(refArrPointer)
            }
        }
    }

    const filterRemovedValues = (
        valuesToFilter: string[],
        overrideRefArrayPointer: Pointer,
        relId: string
    ): string[] => {
        const valuesWithoutRel = _.without(valuesToFilter, `#${relId}`)
        if (!santaCoreUtils.displayedOnlyStructureUtil.isRefPointer(overrideRefArrayPointer)) {
            return valuesWithoutRel
        }
        const arrayOfOverridesPointer = getPointerWithoutFallbacksFromPointer(overrideRefArrayPointer)
        const overrideItems = dataAccess.get(arrayOfOverridesPointer)
        return _.without(overrideItems?.values, `#${relId}`)
    }

    const removeRelationFromRefArray = (
        ownerPointerWithVariants: Pointer,
        relation: DalValue,
        namespace: string,
        pageId: string,
        refArrPointer: Pointer,
        referenceName?: string
    ) => {
        if (!dataAccess.has(refArrPointer)) {
            return
        }

        const refArrayData = dataAccess.get(refArrPointer)
        const unfilteredRefArrayValues = dataApi.refArray.extractValues(refArrayData)
        const refArrayValues = filterRemovedValues(unfilteredRefArrayValues, refArrPointer, relation.id)

        if (!_.isEmpty(refArrayValues)) {
            dataAccess.set(getInnerPointer(refArrPointer, 'values'), refArrayValues)
        } else {
            removeReferenceToRefArray(ownerPointerWithVariants, namespace, refArrPointer, referenceName)
        }
    }

    const removeRelation = (
        ownerPointerWithVariants: Pointer,
        relationPointer: Pointer,
        namespace: string,
        pageId: string,
        refArrPointer: Pointer,
        referenceName?: string
    ) => {
        const relation = dataAccess.get(relationPointer)
        const scopedValueId = dataApi.variantRelation.extractTo(relation)

        const shouldRemoveScopedDataItem = namespace !== DATA_TYPES.theme || !dal.schema.isSystemStyle(scopedValueId)

        if (shouldRemoveScopedDataItem) {
            const scopedValuePointer = pointers.data.getItem(namespace, scopedValueId, pageId)
            dataModel.removeItemRecursively(scopedValuePointer)
        }

        removeRelationFromRefArray(ownerPointerWithVariants, relation, namespace, pageId, refArrPointer, referenceName)
        dataAccess.remove(relationPointer)
    }

    const removeForPointerWithVariants = (
        ownerPointer: Pointer,
        namespace: string,
        refArrPointer: Pointer,
        referenceName?: string
    ) => {
        const {pageId, relationPointer, isRefArray} = getPointerVariantsData(ownerPointer, namespace, refArrPointer)

        if (isRefArray && relationPointer) {
            removeRelation(ownerPointer, relationPointer, namespace, pageId, refArrPointer, referenceName)
        }
    }

    const removeForPointerWithoutVariants = (
        ownerPointer: Pointer,
        namespace: string,
        refArrPointer: Pointer,
        referenceName?: string
    ) => {
        if (!refArrPointer) {
            return
        }
        const refArrayOrValueData = dataAccess.get(refArrPointer)
        if (dataApi.refArray.isRefArray(refArrayOrValueData)) {
            removeReferenceToRefArray(ownerPointer, namespace, refArrPointer, referenceName)
        }
    }

    const removeRefArrayDataConsideringVariants = (
        pointerWithVariants: Pointer,
        namespace: string,
        refArrPointer: Pointer,
        referenceName?: string
    ) => {
        if (pointers.structure.isWithVariants(pointerWithVariants)) {
            removeForPointerWithVariants(pointerWithVariants, namespace, refArrPointer, referenceName)
        } else {
            removeForPointerWithoutVariants(pointerWithVariants, namespace, refArrPointer, referenceName)
        }
    }

    const removeDataConsideringVariants = (pointerWithVariants: Pointer, namespace: string, referenceName?: string) => {
        const refArrPointer = schemaAPI.getReferencePointer(pointerWithVariants, namespace, referenceName)
        if (!refArrPointer) {
            return
        }

        return removeRefArrayDataConsideringVariants(pointerWithVariants, namespace, refArrPointer, referenceName)
    }

    const removeComponentDataConsideringVariants = (componentPointer: Pointer, namespace: string) => {
        validateNamespaceIsDefinedWithRelationalSplit(namespace)
        removeDataConsideringVariants(componentPointer, namespace, '')
    }

    const validateIsTheReferenceARelationalSplit = (namespace: string, dataItem: DalValue, referenceName?: string) => {
        if (!referenceName) return
        const referenceFieldsInfo = (extensionAPI as SchemaExtensionAPI).schemaAPI.extractReferenceFieldsInfo(
            namespace,
            dataItem.type,
            false
        )
        const reference = _.find(referenceFieldsInfo, ref => _.isEqual(ref.path, [referenceName]))
        if (!reference?.isRelationalSplit) {
            throw new ReportableError({
                message: `the ${dataItem.id} dataItem should have isRelationalSplit at ${referenceName} property`,
                errorType: 'relationalSplitValidation'
            })
        }
    }
    const validateVariants = (ownerPointerWithVariants: Pointer) => {
        _.forEach(ownerPointerWithVariants.variants, variant => {
            if (!dataAccess.has(variant) && isLocalVariant(variant, true)) {
                const error = new ReportableError({
                    errorType: 'nonExistingVariant',
                    message: `Update with non-existing variant ${variant.id}`,
                    extras: {
                        compPointer: ownerPointerWithVariants
                    }
                })
                const {logger} = extensionAPI as LoggerExtAPI
                logger.captureError(error)
                throw error
            }
        })
    }

    const updateValue = (
        item: DalValue,
        namespace: string,
        pageId: string,
        customId?: string,
        customUpdater?: CustomUpdater
    ) => {
        if (customUpdater) {
            return customUpdater(item, namespace, pageId, customId)
        }
        return dataModel.addItem(item, namespace, pageId, customId)
    }

    const addScopedValueToRelation = (
        compRef: Pointer,
        scopedValueId: string,
        namespace: string,
        refArrayOrDataItem: DalValue,
        variants: string[],
        pageId: string
    ): Pointer => {
        const compIdToAdd = displayedOnlyStructureUtil.getRepeaterTemplateId(compRef.id)

        const variantRelation = dataApi.variantRelation.create(variants, compIdToAdd, scopedValueId)
        const {id: relId} = dataModel.addDeserializedItem(variantRelation, namespace, pageId)

        if (dataApi.refArray.isRefArray(refArrayOrDataItem)) {
            const newValues = [...dataApi.refArray.extractValues(refArrayOrDataItem as RefArray), `#${relId}`]

            const newValuesAfterHook = hooksApi.executeHookAndUpdateValue(
                VARIANT_HOOKS.SET_SCOPED_VALUE.BEFORE.createEvent({
                    compRef,
                    itemType: namespace,
                    pageId,
                    refArrayId: refArrayOrDataItem.id,
                    relId
                }),
                newValues
            )
            const refArrayPointer = pointers.data.getItem(namespace, refArrayOrDataItem.id, pageId)
            const refArrayValue = {...dataAccess.get(refArrayPointer), values: newValuesAfterHook}

            dataAccess.set(refArrayPointer, refArrayValue)

            return refArrayPointer
        }

        const refValues = _.get(refArrayOrDataItem, ['id']) ? [refArrayOrDataItem.id, relId] : [relId]
        const refArr = dataApi.refArray.create(refValues)
        return dataModel.addDeserializedItem(refArr, namespace, pageId)
    }

    const createAndAddScopedValueToRelation = (
        pointerWithVariants: Pointer,
        valueToUpdate: DalValue,
        namespace: string,
        refArrayOrValue: Pointer,
        variants: any[],
        pageId: string,
        customUpdater?: CustomUpdater
    ): {dataItemPointer: Pointer; refArrPointer: Pointer} => {
        const newDataItemID = dataModel.generateUniqueIdByType(namespace, pageId, dal, pointers)
        const dataItemPointer = updateValue(valueToUpdate, namespace, pageId, newDataItemID, customUpdater)

        const refArrPointer = addScopedValueToRelation(
            pointerWithVariants,
            dataItemPointer.id,
            namespace,
            refArrayOrValue,
            variants,
            pageId
        )

        return {dataItemPointer, refArrPointer}
    }

    const shouldUpdateDataIdHandler = (ownerPointer: Pointer, nonScopedValuePointer: Pointer) => {
        if (_.includes(VIEW_MODES, ownerPointer?.type)) {
            return nonScopedValuePointer && schemaAPI.isSystemStyle(nonScopedValuePointer.id)
        }
        return false
    }
    const updateScopedData = (
        pointerWithVariants: Pointer,
        valueToUpdate: DalValue,
        namespace: string,
        refArrOrValuePointer: Pointer,
        customUpdater?: CustomUpdater,
        referenceName?: string
    ): Pointer => {
        const {
            pageId,
            refArrayOrValue,
            relationPointer: relationPtr,
            variants,
            isRefArray
        } = getPointerVariantsData(pointerWithVariants, namespace, refArrOrValuePointer)

        const relationPointer = hooksApi.executeHookAndUpdateValue(
            VARIANT_HOOKS.SET_OVERRIDE.BEFORE.createEvent({}),
            relationPtr
        )

        if (!relationPointer) {
            const {dataItemPointer, refArrPointer} = createAndAddScopedValueToRelation(
                pointerWithVariants,
                valueToUpdate,
                namespace,
                refArrayOrValue,
                variants!,
                pageId,
                customUpdater
            )
            if (!isRefArray) {
                dataModel.linkDataToItemByType(pointerWithVariants, refArrPointer.id, namespace, referenceName)
            }
            return dataItemPointer
        }

        const scopedValuePointer = getConditionalValuePointerByVariants(
            namespace,
            refArrayOrValue,
            variants!,
            pageId,
            relationPointer
        ) as Pointer

        const shouldUpdateDataId = shouldUpdateDataIdHandler(pointerWithVariants, scopedValuePointer)
        const dataIdToUpdate = shouldUpdateDataId ? valueToUpdate.id : scopedValuePointer?.id
        const dataItemPointer = updateValue(valueToUpdate, namespace, pageId, dataIdToUpdate, customUpdater)

        if (shouldUpdateDataId) {
            dataAccess.set(getInnerPointer(relationPointer, 'to'), `#${dataIdToUpdate}`)
        }

        return dataItemPointer
    }

    function createRefArr(
        refArrContent: string[],
        namespace: string,
        pageId: string,
        pointerWithVariants: Pointer,
        referenceName: string | undefined
    ) {
        const refArr = dataApi.refArray.create(refArrContent)
        const refArrayPointer = dataModel.addDeserializedItem(refArr, namespace, pageId)
        dataModel.linkDataToItemByType(pointerWithVariants, refArrayPointer.id, namespace, referenceName)

        return refArrayPointer
    }

    const updateNonScopedData = (
        pointerWithVariants: Pointer,
        valueToUpdate: DalValue,
        namespace: string,
        refArrayOrValuePointer: Pointer,
        referenceName?: string,
        customUpdater?: CustomUpdater
    ): Pointer => {
        const {pageId, refArrayOrValue, isRefArray} = getPointerVariantsData(
            pointerWithVariants,
            namespace,
            refArrayOrValuePointer
        )

        // create refArr & scope the data
        //may need to handle regular update as well (without refArr)
        if (!isRefArray && namespace !== DATA_TYPES.theme) {
            const dataItemPointer = updateValue(valueToUpdate, namespace, pageId, undefined, customUpdater)
            const refArrContent = [`#${dataItemPointer.id}`]
            createRefArr(refArrContent, namespace, pageId, pointerWithVariants, referenceName)
            return dataItemPointer
        }

        const nonScopedPointer = getDefaultValuePointer(namespace, refArrayOrValue, pageId) as Pointer
        const nonScopedValuePointer = hooksApi.executeHookAndUpdateValue(
            VARIANT_HOOKS.SET_OVERRIDE.BEFORE.createEvent({}),
            nonScopedPointer
        )
        const shouldUpdateDataId = shouldUpdateDataIdHandler(pointerWithVariants, nonScopedValuePointer)
        const dataIdToUpdate = shouldUpdateDataId ? valueToUpdate.id : nonScopedValuePointer?.id
        const dataItemPointer = updateValue(valueToUpdate, namespace, pageId, dataIdToUpdate, customUpdater)
        const dataItemID = dataItemPointer.id

        if (!nonScopedValuePointer || shouldUpdateDataId) {
            const refArrToUpdatePtr = refArrayOrValuePointer?.id
                ? refArrayOrValuePointer
                : createRefArr([], namespace, pageId, pointerWithVariants, referenceName)
            const currentDefaultValueId = shouldUpdateDataId ? nonScopedValuePointer.id : ''
            const currentValues = _.without(
                dataApi.refArray.extractValues(refArrayOrValue),
                `#${currentDefaultValueId}`
            )
            const newValues = [`#${dataItemID}`, ...currentValues]
            const newValuesAfterHook = hooksApi.executeHookAndUpdateValue(
                VARIANT_HOOKS.SET_NON_SCOPED_VALUE.BEFORE.createEvent({
                    compRef: pointerWithVariants,
                    itemType: namespace,
                    pageId,
                    newDefaultValueId: dataItemID,
                    refArrayId: refArrToUpdatePtr.id
                }),
                newValues
            )
            const refArrayPointer = pointers.data.getItem(namespace, refArrToUpdatePtr.id, pageId)
            const refArrayValue = {...dataAccess.get(refArrayPointer), values: newValuesAfterHook}

            dataAccess.set(refArrayPointer, refArrayValue)
        }

        return dataItemPointer
    }

    const updateDataConsideringVariants = (
        pointerWithVariants: Pointer,
        valueToUpdate: DalValue,
        namespace: string,
        referenceName?: string,
        customUpdater?: CustomUpdater
    ): Pointer => {
        validateVariants(pointerWithVariants)

        const dataItem = dataAccess.get(pointerWithVariants)
        validateIsTheReferenceARelationalSplit(namespace, dataItem, referenceName)

        const refArrPointer = schemaAPI.getReferencePointer(pointerWithVariants, namespace, referenceName) as Pointer

        if (pointers.structure.isWithVariants(pointerWithVariants)) {
            return updateScopedData(
                pointerWithVariants!,
                valueToUpdate,
                namespace,
                refArrPointer,
                customUpdater,
                referenceName
            )
        }

        return updateNonScopedData(
            pointerWithVariants,
            valueToUpdate,
            namespace,
            refArrPointer,
            referenceName,
            customUpdater
        )
    }

    const updateComponentDataConsideringVariants = (
        compPointerWithVariants: Pointer,
        valueToUpdate: DalValue,
        namespace: string,
        customUpdater?: CustomUpdater
    ): Pointer => {
        validateNamespaceIsDefinedWithRelationalSplit(namespace)

        return updateDataConsideringVariants(
            compPointerWithVariants,
            valueToUpdate,
            namespace,
            undefined,
            customUpdater
        )
    }

    const getContextsForComponent = (pointer: CompRef, namespaces?: string[]): Record<string, VariantPointer[][]> => {
        const namespaceToPointers: Record<string, VariantPointer[][]> = {}
        namespaces ??= Object.values(VARIANTABLE_DATA_TYPES)
        namespaces.forEach(namespace => {
            const item = dataModel.components.getItem(pointer, namespace)
            if (item?.type === REF_ARRAY_DATA_TYPE) {
                const namespaceResult: VariantPointer[][] = []
                item.values.forEach((relation: DalValue) => {
                    if (RELATION_DATA_TYPES.VARIANTS === relation.type) {
                        namespaceResult.push(
                            relation.variants.map(
                                (variantRef: string) =>
                                    pointers.getPointer(stripHashIfExists(variantRef), 'variants') as VariantPointer
                            )
                        )
                    }
                })
                namespaceToPointers[namespace] = namespaceResult
            }
        })

        return namespaceToPointers
    }

    return {
        variants: {
            removeRelation,
            getContextsForComponent,
            setComponentVariantRelationRefArray,
            getPointerVariantsData,
            getRelationPointerFromRefArrayByVariants,
            exactMatchVariantRelationPredicateItem,
            getConditionalValueByRelationPtr,
            getConditionalValuePointerByVariants,
            getDefaultValuePointer,
            getDataPointerConsideringVariants,
            getComponentDataPointerConsideringVariants,
            getVariantOwner,
            addMobileVariant,
            getComponentItemConsideringVariants,
            getItemConsideringVariants,
            removeComponentDataConsideringVariants,
            getDataPointerConsideringVariantsForRefPointer,
            updateDataConsideringVariants,
            updateComponentDataConsideringVariants,
            createAndAddScopedValueToRelation,
            addScopedValueToRelation,
            removeDataConsideringVariants,
            removeRefArrayDataConsideringVariants,
            hasOverrides
        }
    }
}

const uniqueVariantTypesInRelation = ['Preset']

const createValidator = ({dal, coreConfig, extensionAPI}: DmApis): ValidatorMap => {
    const variantsApi = () => (extensionAPI as VariantsExtensionAPI).variants
    const dataModel = () => (extensionAPI as DataModelExtensionAPI).dataModel
    const validateVariantRelation = (pointer: Pointer, value: DalValue) => {
        if (value?.type === 'VariantRelation' && value.variants.length > 1) {
            const {variants} = value

            const variantsFromDal = variants.map((variantRef: string) =>
                dal.get(getPointer(removePrefix(variantRef, '#'), DATA_TYPES.variants))
            )
            const nonUniqueVariants = uniqueVariantTypesInRelation
                .map(type => variantsFromDal.filter((variant: any) => variant.type === type))
                .filter(item => item.length > 1)
                .map(arr => arr[0].id)

            if (!_.isEmpty(nonUniqueVariants)) {
                return [
                    {
                        shouldFail: true,
                        type: 'nonUniqueVariantsInRelation',
                        message: `VariantRelation ${JSON.stringify(
                            pointer
                        )} has more than one variant that should be unique`,
                        extras: {
                            namespace: pointer.type,
                            pointer,
                            variantRefs: nonUniqueVariants
                        }
                    }
                ]
            }
        }
    }

    const disallowedDuplicateVariantsValidator = (pointer: Pointer, value: DalValue) => {
        if (pointer?.type !== DATA_TYPES.variants || !NON_DUPLICATABLE_VARIANTS.includes(value?.type)) {
            return
        }
        const ownerPointerWithVariants = variantsApi().getVariantOwner(pointer)
        const owner = dal.get(ownerPointerWithVariants)
        const variantsList = dataModel().getItem(
            stripHashIfExists(owner.variantsQuery),
            'variants',
            owner.metaData.pageId
        )
        if (variantsList.values.filter((variant: any) => variant.type === value.type).length > 1) {
            return [
                {
                    shouldFail: true,
                    type: 'duplicateVariant',
                    message: `Component ${JSON.stringify(
                        ownerPointerWithVariants
                    )} has more than one variant that should be unique`,
                    extras: {
                        namespace: pointer.type,
                        pointer,
                        ownerPointerWithVariants
                    }
                }
            ]
        }
    }

    return coreConfig.supportsUsingPresetVariants
        ? {validateVariantRelation, disallowedDuplicateVariantsValidator}
        : {disallowedDuplicateVariantsValidator}
}

const createExtension = ({}: CreateExtensionArgument): Extension => ({
    name: 'variants',
    createFilters,
    createPointersMethods,
    createExtensionAPI,
    createPublicAPI: ({extensionAPI}: DmApis) => {
        const {variants} = extensionAPI as VariantsExtensionAPI

        return {
            components: {
                variants: {
                    getContexts: variants.getContextsForComponent
                }
            }
        }
    },
    createValidator
})

export {createExtension}
