export const DEAD_COMP_TYPE = 'to.be.removed.by.MobileMerge'

export const SPLITTABLE_QUERIES = ['propertyQuery', 'designQuery']

export const NON_SPLITTABLE_QUERIES_WITHOUT_NAMESPACES = ['nickname']

export const INTERACTIONS = {
    MERGE_PAGE: 'mobileAlgo-mergePage',
    CONVERT_PAGE: 'mobileAlgo-convertPage',
    PREPARE: 'mobileAlgo-prepare',
    PRE_MERGE: 'mobileAlgo-preMerge',
    PRE_CONVERT: 'mobileAlgo-preConvert',
    SYNC_MOBILE_SITE: 'mobileAlgo-syncMobileSite',
    GET_COMPONENTS_ORDER: 'mobileAlgo-getComponentsOrder',
    ANALYZE: 'mobileAlgo-analyze',
    RESCALE: 'mobileAlgo-rescale',
    INSERT: 'mobileAlgo-insert',
    POST_MERGE: 'mobileAlgo-postMerge',
    POST_CONVERT: 'mobileAlgo-postConvert'
}
