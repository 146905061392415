import type {PublicMethodUtils} from '@wix/document-services-types'
import {inlinePopup} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            inlinePopup: {
                setAlignment: publicMethodUtils.defineDataManipulationAction(inlinePopup.setAlignment)
            }
        }
    }
}
