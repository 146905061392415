import type {PublicMethodUtils} from '@wix/document-services-types'
import {theme, utils} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    const {
        deprecate,
        defineGetter,
        actions: {dataManipulation, immediate},
        extensionPublicAPI: {dataManipulation: extensionDataManipulation, getter: extensionGetter}
    } = publicMethodUtils

    return {
        methods: {
            theme: {
                spx: {
                    get: extensionGetter('theme.spx.get'),
                    set: extensionDataManipulation('theme.spx.set', {isUpdatingAnchors: utils.DONT_CARE})
                },
                fontDisplay: {
                    get: extensionGetter('theme.fontDisplay.get'),
                    set: extensionDataManipulation('theme.fontDisplay.set', {isUpdatingAnchors: utils.DONT_CARE})
                },
                colors: {
                    update: dataManipulation(theme.colors.update),
                    get: defineGetter(theme.colors.get),
                    render: defineGetter(theme.colors.render),
                    getAll: defineGetter(theme.colors.getAll),
                    getCustomUsedInSkins: defineGetter(theme.colors.getCustomUsedInSkins),
                    getColorPresets: defineGetter(theme.colors.getColorPresets),
                    getCss: defineGetter(theme.colors.getCssString)
                },
                fonts: {
                    update: dataManipulation(theme.fonts.update),
                    get: defineGetter(theme.fonts.get),
                    getAll: defineGetter(theme.fonts.getAll),
                    getMap: defineGetter(theme.fonts.getMap),
                    getCharacterSet: defineGetter(theme.fonts.getCharacterSet),
                    getLanguageCharacterSet: defineGetter(theme.fonts.getLanguageCharacterSet),
                    getCharacterSetByGeo: defineGetter(theme.fonts.getCharacterSetByGeo),
                    updateCharacterSet: immediate(theme.fonts.updateCharacterSet),
                    getThemeStyles: defineGetter(theme.fonts.getThemeStyles)
                },
                textThemes: {
                    update: dataManipulation(theme.textThemes.update),
                    get: defineGetter(theme.textThemes.get),
                    getAll: defineGetter(theme.textThemes.getAll),
                    getStyles: defineGetter(theme.textThemes.getStyles)
                },
                styles: {
                    createItem: deprecate(
                        immediate(theme.styles.createAndAddStyleItem),
                        'This method is deprecated due to MobX refactor. Please use styles.createAndAddItem instead. Creating style item now causes render, and should be followed by wait for changes applied to use the new item'
                    ),
                    createAndAddItem: dataManipulation(theme.styles.addStyleItem, {
                        getReturnValue: theme.styles.createStyleItemToAdd
                    }),
                    getDefaultThemeStyle: defineGetter(theme.styles.getDefaultThemeStyle),
                    update: dataManipulation(theme.styles.updateAndWarn),
                    get: defineGetter(theme.styles.getAndWarn),

                    getAll: defineGetter(theme.styles.getAll),
                    getAllIds: defineGetter(theme.styles.getAllIds),
                    getAllFromAllPages: defineGetter(theme.styles.getAllFromAllPages),
                    getAllIdsFromAllPages: defineGetter(theme.styles.getAllIdsFromAllPages)
                },
                skins: {
                    getComponentResponsiveSkins: defineGetter(theme.skins.getComponentResponsiveSkins),
                    getComponentSkins: defineGetter(theme.skins.getComponentSkins),
                    getSkinDefinition: defineGetter(theme.skins.getSkinDefinition)
                },
                events: {
                    onChange: {
                        addListener: immediate(theme.events.onChange.addListener),
                        removeListener: immediate(theme.events.onChange.removeListener)
                    }
                }
            }
        }
    }
}
