import wixCodeUserScriptsService from '../utils/wixCodeUserScriptsService'
import type {PS} from '@wix/document-services-types'

const {filePathAffectsBundles, filePathAffectsSingleBundle, bundleIdFromFilePath} = wixCodeUserScriptsService

function init(ps: PS) {
    reset(ps)
}

function reset(ps: PS) {
    ps.extensionAPI.wixCodeFileSystem.clearBundleCacheKillers()
    ps.extensionAPI.wixCodeFileSystem.updateGlobalBundleCacheKiller()
}

function notifyPathModified(ps: PS, path: string) {
    if (filePathAffectsSingleBundle(path)) {
        const bundleId = bundleIdFromFilePath(path)
        ps.extensionAPI.wixCodeFileSystem.updateBundleCacheKiller(bundleId)
    } else if (filePathAffectsBundles(path)) {
        reset(ps)
    }
}

function getNewCacheKillerValue(existingValue: string) {
    const suggestedValue = Date.now().toString()
    return suggestedValue === existingValue ? `${suggestedValue}_1` : suggestedValue
}
export default {
    init,
    getNewCacheKillerValue,
    notifyPathModified
}
