const getFunctionTypeParamsString = (params = []) => params.map(param => `${param.name}: any`).join(', ')

const getEventHandlersTypes = eventHandlers =>
    Object.keys(eventHandlers).map(eventName => `${eventName}(eventHandler: (event: any) => void);`)

const getMembersTypes = members =>
    Object.entries(members).map(
        // @ts-expect-error
        ([name, {kind, params}]) =>
            `${kind === 'function' ? `${name}(${getFunctionTypeParamsString(params)});` : `${name}: any;`}`
    )

const controllerExportsToTypeDef = (controllerExports: any = {}) => {
    const members = controllerExports.members || {}
    const events = controllerExports.eventHandlers || {}
    return `{${[...getMembersTypes(members), ...getEventHandlersTypes(events)].join(' ')}} & $w.Node`
}

export default {
    controllerExportsToTypeDef
}
