import type {Extension} from '@wix/document-manager-core'
import _ from 'lodash'
import {CSaveApi, CS_EVENTS, CSaveTestApi} from './continuousSave'
import type {GetTransactionsResponse} from './serverProtocol'
import type {GetDocumentResponse} from '@wix/ambassador-editor-document-store/types'

const asyncNoop = async () => undefined

export class EmptyCSaveExt implements Extension {
    readonly name: string = 'continuousSave'
    readonly EVENTS: Record<string, any> = CS_EVENTS
    readonly dependencies = new Set(['schema'])
    createExtensionAPI(): CSaveApi {
        return {
            continuousSave: {
                createRevision: async () => ({
                    actions: [],
                    siteRevision: {
                        revision: 1,
                        version: 1
                    }
                }),
                save: asyncNoop,
                saveAndWaitForResult: asyncNoop,
                forceSaveAndWaitForResult: asyncNoop,
                initCSave: async () => false,
                deleteTx: asyncNoop,
                setSaving: _.noop,
                getLastTransactionId: () => undefined,
                getLastSaveDate: async () => undefined,
                initHooks: _.noop,
                getWrappedHooks: () => ({}),
                setEnabled: _.noop,
                shouldSave: () => false,
                isValidationRecovery: () => false,
                getTransactions: async (): Promise<GetTransactionsResponse> => ({transactions: []}),
                getTransactionsFromLastRevision: async (): Promise<GetTransactionsResponse> => ({transactions: []}),
                getStore: async (): Promise<GetDocumentResponse> => ({
                    actions: [],
                    firstTransactionId: '',
                    lastTransactionId: ''
                }),
                approveForeignTransaction: asyncNoop,
                isCSaveOpen: () => false,
                isCEditOpen: () => false,
                disableSaveDuringRequiredAndPrimary: () => false,
                isCreateRevisionOpen: () => false,
                rejectNext(): void {},
                registerToTransactionApproved: _.noop,
                waitForResponsesToBeProcessed: asyncNoop,
                test: (): CSaveTestApi => undefined! as CSaveTestApi,
                onRevisionChange: _.noop,
                isUnappliedTransactionThresholdPassed: () => false,
                getUnappliedActionCountSinceLastRevision: () => 0,
                getActionCount: () => 0,
                cSaveErrors: {
                    convertToDSError: _.identity
                },
                isLocalTransaction: () => true
            }
        }
    }
}
