import _ from 'lodash'

function isPlatformAppInstalled(plaformApps, appData) {
    if (!appData) {
        return false
    }
    const appDefinitionId = _.get(appData, 'appDefinitionId')
    const appInstalledPages = _.get(plaformApps, [appDefinitionId], {})

    return !_.isEmpty(appInstalledPages)
}

export default {
    isPlatformAppInstalled
}
