import accessibilityUtils from './utils'
import type {PS} from '@wix/document-services-types'

export default {
    /**
     * @param {PS} ps
     * @returns {boolean}
     */
    get(ps: PS): boolean {
        const pointer = accessibilityUtils.getAccessibilitySettingsDataPointer(ps)
        const accessibilitySettings = ps.dal.get(pointer) || {}
        return Boolean(accessibilitySettings.skipToContent)
    },
    /**
     * @param {PS} ps
     * @param {boolean} enabled
     */
    set(ps: PS, enabled: boolean): void {
        if (enabled !== false && enabled !== true) {
            throw new Error('skipToContent.set accepts only boolean value')
        }

        const pointer = accessibilityUtils.getAccessibilitySettingsDataPointer(ps)
        const accessibilitySettings = ps.dal.get(pointer) || {}
        accessibilitySettings.skipToContent = enabled
        ps.dal.set(pointer, accessibilitySettings)
    }
}
