import type {SnapshotDal} from '@wix/document-manager-core'
import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'

const createFromImmutable = (pagesDataImmutable: any[]) => {
    const getter = (data, propName: string) => data.get(propName)
    const accept = visitor =>
        pagesDataImmutable.forEach(page => {
            page.getIn(['data', 'document_data']).forEach(data => visitor(getter, data))
        })
    return {accept}
}

const createFromPrivateServices = (ps: PS) => {
    const getter = (pointer: Pointer, propName: string) => ps.dal.get(ps.pointers.getInnerPointer(pointer, propName))
    const accept = visitor => {
        const allDataPointers = ps.pointers.data.getItemsWithPredicate('data', {}, null)
        allDataPointers.forEach(pointer => visitor(getter, pointer))
    }
    return {accept}
}

const createFromSnapshotDiff = diff => {
    const getter = (value, propName: string) => value[propName]
    const accept = visitor => {
        _.forEach(diff.data, value => {
            if (value) {
                visitor(getter, value)
            }
        })
    }
    return {accept}
}

const createFromSnapshotDal = (ids: string[], snapshotDal: SnapshotDal) => {
    const getter = (value, propName: string) => value[propName]
    const accept = visitor => {
        _.forEach(ids, id => {
            const value = snapshotDal.getValue({type: 'data', id})
            if (value) {
                visitor(getter, value)
            }
        })
    }
    return {accept}
}

const createFromJS = obj => {
    const getter = (value, propName: string) => value[propName]
    const accept = visitor => {
        _.forEach(obj, value => visitor(getter, value))
    }
    return {accept}
}

const createEmpty = () => ({
    accept: () => {}
})

export {
    createFromImmutable,
    createFromPrivateServices,
    createFromSnapshotDiff,
    createFromSnapshotDal,
    createFromJS,
    createEmpty
}
