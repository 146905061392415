import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import component from '../../component/component'
import platformStateService from '../../platform/services/platformStateService'
import theme from '../../theme/theme'
import clientSpecMapService from '../../tpa/services/clientSpecMapService'
import quickActionsDefinitions from './quickActionsDefinitions'
import constants from '../../constants/constants'

function validateActionText(actionItemData) {
    actionItemData.text = actionItemData.text.slice(0, quickActionsDefinitions.QUICK_ACTION_ITEM_MAX_LENGTH)
}

function validateActionType(actionItemData) {
    if (!_.includes(quickActionsDefinitions.actionTypesMap, actionItemData.itemType)) {
        throw new Error(`"${actionItemData.itemType}" is an unsupported action`)
    }
}

function validatePredefinedIcon(actionItemData) {
    const predefinedIconData = quickActionsDefinitions.quickActionIconsData[actionItemData.itemType]
    _.assign(actionItemData, {
        icon: predefinedIconData.hash,
        color: predefinedIconData.color
    })
}

function validateActionItemData(actionItemData) {
    validateActionText(actionItemData)
    validateActionType(actionItemData)
    if (actionItemData.itemType !== 'custom') {
        validatePredefinedIcon(actionItemData)
    }
}

function getMasterPagePointer(ps: PS) {
    return ps.pointers.components.getPage('masterPage', constants.VIEW_MODES.MOBILE)
}

function getQuickActionBarPointer(ps: PS) {
    return ps.pointers.components.getComponent('QUICK_ACTION_BAR', getMasterPagePointer(ps))
}

function hasQuickActionBar(ps: PS) {
    const quickActionBarPointer = getQuickActionBarPointer(ps)
    return !_.isNull(quickActionBarPointer) && component.isExist(ps, quickActionBarPointer)
}

function addAction(ps: PS, actionBarItemData) {
    validateActionItemData(actionBarItemData)
    const serializedQuickActionBarItem = _.merge(_.clone(quickActionsDefinitions.defaultSerializedQuickActionBarItem), {
        data: _.assign(
            ps.extensionAPI.dataModel.createDataItemByType('QuickActionBarItem', {
                itemType: actionBarItemData.itemType
            }),
            _.pick(actionBarItemData, ['link', 'text', 'icon'])
        ),
        style: _.set({}, 'style.properties', _.pick(actionBarItemData, ['color']))
    })
    const quickActionBarRef = getQuickActionBarPointer(ps)
    const compToAddRef = component.getComponentToAddRef(ps, quickActionBarRef, null)
    component.add(ps, compToAddRef, quickActionBarRef, serializedQuickActionBarItem)
}

function addQuickActionBar(ps: PS, serializedActionBar?, actionBarItemsData?) {
    const hasActionBar = hasQuickActionBar(ps)
    const isEmpty = _(getActions(ps)).reject('isDynamic').isEmpty()
    if (hasActionBar && !isEmpty) {
        return
    }
    if (!hasActionBar) {
        const serializedQuickActionBar = _.merge(
            _.clone(serializedActionBar || quickActionsDefinitions.defaultSerializedFAB),
            {
                props: ps.extensionAPI.dataModel.createPropertiesItemByType('QuickActionBarProperties')
            }
        )
        const masterPagePointer = getMasterPagePointer(ps)
        const compToAddRef = component.getComponentToAddRef(ps, masterPagePointer, null, 'QUICK_ACTION_BAR')
        component.add(ps, compToAddRef, masterPagePointer, serializedQuickActionBar)
    }
    _.forEach(actionBarItemsData, _.partial(addAction, ps, _))
}

function removeQuickActionBar(ps: PS) {
    if (!hasQuickActionBar(ps)) {
        return
    }
    component.deleteComponent(ps, getQuickActionBarPointer(ps))
}

function getStructuredActions(ps: PS) {
    const actionCompPointers = ps.pointers.components.getChildren(getQuickActionBarPointer(ps))
    return _.map(actionCompPointers, function (p) {
        const actionData = component.data.get(ps, p)
        const actionStyleId = ps.dal.get(p).styleId
        const actionTheme = theme.styles.get(ps, actionStyleId)
        return _.assign(
            {pointer: p},
            _.pick(actionData, ['text', 'icon', 'link', 'itemType']),
            _.pick(actionTheme.style.properties, ['color'])
        )
    })
}

function getDynamicActions(ps: PS) {
    const appsState = platformStateService.getAppsState(ps)
    const optionalAppIds = _(ps.siteDataAPI.siteData.getClientSpecMap())
        .reject(appData => clientSpecMapService.isAppPermissionsIsRevoked(appData, appsState))
        .map('appDefinitionId')
        .value()
    return _(constants.SUPPORTED_DYNAMIC_ACTIONS)
        .map(act => _.assign({}, act, {isDynamic: true}))
        .filter(act => _.includes(optionalAppIds, act.appId))
        .value()
}

function getActions(ps: PS) {
    if (!hasQuickActionBar(ps)) {
        return []
    }
    const structuredActions = getStructuredActions(ps)
    const dynamicActions = getDynamicActions(ps)
    // @ts-expect-error
    return _.concat(structuredActions, dynamicActions)
}

function clearActions(ps: PS) {
    if (!hasQuickActionBar(ps)) {
        return
    }
    const actionCompPointers = ps.pointers.components.getChildren(getQuickActionBarPointer(ps))
    _.forEach(actionCompPointers, p => component.deleteComponent(ps, p))
}

function getAllTypes() {
    return quickActionsDefinitions.actionTypesMap
}

function getIconsMap() {
    return quickActionsDefinitions.quickActionIconsData
}

export default {
    getQuickActionBarPointer,
    hasQuickActionBar,
    addAction,
    addQuickActionBar,
    removeQuickActionBar,
    getActions,
    clearActions,
    getAllTypes,
    validateActionItemData,
    getIconsMap
}
