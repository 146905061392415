import type {Pointer, PS} from '@wix/document-services-types'
import dataModel from '../../dataModel/dataModel'
import constants from '../../constants/constants'

const {RESIZE_SIDES} = constants

export default {
    resizableSides(ps: PS, componentPointer: Pointer) {
        const compData = dataModel.getDataItem(ps, componentPointer)
        const compact = !compData.showStream
        const resizableSides = compact
            ? [RESIZE_SIDES.LEFT, RESIZE_SIDES.RIGHT]
            : [RESIZE_SIDES.TOP, RESIZE_SIDES.LEFT, RESIZE_SIDES.BOTTOM, RESIZE_SIDES.RIGHT]
        return resizableSides
    },
    layoutLimits: {
        minWidth: 280,
        minHeight: 575,
        maxWidth: 500
    }
}
