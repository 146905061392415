const {DATA_TYPES, COMP_DATA_QUERY_KEYS_WITH_STYLE} = require('@wix/document-services-json-schemas/dist/constants.json')

const constants = {
    MOBILE: 'MOBILE',
    DESKTOP: 'DESKTOP',
    COMP_PREFIX: 'comp',
    ABSOLUTE_LAYOUT: 'layout',
    REF_ARRAY_TYPE: 'RefArray',
    CONTAINER_TYPE: 'Container',
    TRANSFORM_DATA_TYPE: 'TransformData',
    VARIANT_RELATION_TYPE: 'VariantRelation',
    CONTAINER_COMP_TYPE: 'mobile.core.components.Container',
    MEDIA_CONTAINER_COMP_TYPE: 'wysiwyg.viewer.components.MediaContainer',
    PROPS_QUERY: COMP_DATA_QUERY_KEYS_WITH_STYLE[DATA_TYPES.prop],
    STYLE_QUERY: COMP_DATA_QUERY_KEYS_WITH_STYLE[DATA_TYPES.theme],
    DESIGN_QUERY: COMP_DATA_QUERY_KEYS_WITH_STYLE[DATA_TYPES.design],
    BEHAVIOR_QUERY: COMP_DATA_QUERY_KEYS_WITH_STYLE[DATA_TYPES.behaviors],
    CONNECTION_QUERY: COMP_DATA_QUERY_KEYS_WITH_STYLE[DATA_TYPES.connections],
    DATA_TYPES_WITH_HASH: [DATA_TYPES.design, DATA_TYPES.layout, DATA_TYPES.data, DATA_TYPES.breakpoints]
}

module.exports = constants
