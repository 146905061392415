import _ from 'lodash'
import urlUtils from '../../coreUtils/core/urlUtils'
import oldImagesConversionMap from './oldImagesConversionMap'

const NON_MEDIA_URL = /^(http:\/\/)?(images\/.*)/

export function resolveImageData(data, serviceTopology) {
    data = fixNonMediaServiceImage(data)

    const appBasedPath = _.get(serviceTopology, 'scriptsLocationMap.wixapps')
    const match = NON_MEDIA_URL.exec(data.src)
    if (match && appBasedPath) {
        data.src = urlUtils.joinURL(appBasedPath, match[2])
    }

    return data
}

/**
 * Replace old images that were part of the wixapps artifact to images from the media server.
 * @param data
 * @returns {*}
 */
function fixNonMediaServiceImage(data) {
    if (NON_MEDIA_URL.test(data.src)) {
        const imageFileName = _.last(data.src.split('/'))
        // @ts-ignore
        const convertedImage = oldImagesConversionMap[imageFileName]
        if (convertedImage) {
            return _.assign({}, data, convertedImage)
        }
    }

    return _.clone(data)
}
