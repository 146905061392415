import type {Extension, CreateExtArgs, ExtensionAPI} from '@wix/document-manager-core'
import {getSiteStructure, removePageFromChildren} from './siteStructureUtils'

export interface SiteStructureAPI extends ExtensionAPI {
    siteStructure: {
        getSiteStructure(pageId?: string, langCode?: string): any
        removePageFromChildren(pageId: string): void
    }
}

const createExtension = (): Extension => {
    const createExtensionAPI = (createExtArgs: CreateExtArgs) => ({
        siteStructure: {
            getSiteStructure: (pageId: string | null, langCode?: string) =>
                getSiteStructure(createExtArgs, pageId, langCode),
            removePageFromChildren: (pageIdToRemove: string) => removePageFromChildren(createExtArgs, pageIdToRemove)
        }
    })

    return {
        name: 'siteStructure',
        createExtensionAPI
    }
}

export {createExtension}
