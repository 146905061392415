import {ENFORCE_TYPES} from '@wix/document-services-types'
import _ from 'lodash'
import * as basicStrategy from './basicStrategy'
import type {QItemParams} from './SOQItem'

const canAddToBatch = (item: QItemParams, itemsInBatch: QItemParams[]) => {
    if (!basicStrategy.canAddToBatch(item, itemsInBatch)) {
        return false
    }

    if (item.enforceType === ENFORCE_TYPES.DONT_CARE) {
        return true
    }

    const firstItemThatCaresAboutLayout = _.find(itemsInBatch, it => it.enforceType !== ENFORCE_TYPES.DONT_CARE)
    const typeToEnforce = _.get(firstItemThatCaresAboutLayout, ['enforceType'], ENFORCE_TYPES.DONT_CARE)

    return typeToEnforce === ENFORCE_TYPES.DONT_CARE || typeToEnforce === item.enforceType
}

const addingToBatch = (item: QItemParams, itemsInBatch: QItemParams[]) => {
    if (!canAddToBatch(item, itemsInBatch)) {
        throw new Error("the site is during update or this item isn't compatible with the current batch")
    }
    return basicStrategy.addingToBatch(item, itemsInBatch)
}

export {canAddToBatch, addingToBatch}
