import _ from 'lodash'
import {deepCompare} from './deepCompare'
import type {DalValue} from '../dal/store'
const ignoredBaseKeys = ['metaData']
const ignoredMetaDataKeys = ['sig']

type SimpleMapObject = Record<string, any>

const isSimpleMapObject = (a: any) => _.isObject(a) && !Array.isArray(a)

const countKeys = (a: SimpleMapObject, ignoredKeys: string[]) =>
    _(a)
        .keys()
        .without(...ignoredKeys)
        .size()

const hasSameNumberOfKeys = (a: SimpleMapObject, b: SimpleMapObject, ignoredKeys: string[]): boolean =>
    countKeys(a, ignoredKeys) === countKeys(b, ignoredKeys)

const hasEqualKeys = (a: SimpleMapObject, b: SimpleMapObject, ignoredKeys: string[]): boolean =>
    _(a)
        .keys()
        .without(...ignoredKeys)
        .every(key => deepCompare(a[key], b[key]))

const compareObjects = (a: SimpleMapObject, b: SimpleMapObject, ignoredKeys: string[]): boolean =>
    hasSameNumberOfKeys(a, b, ignoredKeys) && hasEqualKeys(a, b, ignoredKeys)

/**
 * Compares two dal values while ignoring their signatures
 * @param a {DalValue}
 * @param b {DalValue}
 * @returns {boolean}
 */
export const deepCompareIgnoreSignature = (a: DalValue, b: DalValue): boolean => {
    if (!isSimpleMapObject(a) || !isSimpleMapObject(b)) {
        return deepCompare(a, b)
    }

    return (
        compareObjects(a, b, ignoredBaseKeys) && compareObjects(a.metaData ?? {}, b.metaData ?? {}, ignoredMetaDataKeys)
    )
}
