'use strict'

const _ = require('lodash')
const fixers = require('../imageService/imageTransformDataFixers')
const imageClientApi = require('@wix/image-client-api/dist/imageClientApi')

const BG_MEDIA_TYPE = 'BackgroundMedia'
const BG_IMAGE_TYPE = 'BackgroundImage'
//var BG_OLD_PREFIX = 'customBgImg';
const BG_IMAGE_PREFIX = 'bgImage'
const BG_MEDIA_PREFIX = 'mediaBg'
const BG_DATA_DELIMITER = '-'

/**
 * Create BackgroundMedia Items on MasterPage
 * @param {object} theme
 * @param {object} masterPageData
 * @param uniqueIdGenerator
 */
function createBackgroundMediaOnMaster(masterPageData, theme, uniqueIdGenerator) {
    // Pages data items from master page
    const pageDataItems = _.filter(masterPageData, item => item.type === 'Page' || item.type === 'AppPage')
    const pageIds = _.map(pageDataItems, 'id')
    const hasBgImage = _.some(pageDataItems, item => _.get(item, ['pageBackgrounds', 'desktop', 'ref']))

    if (hasBgImage) {
        convertBgImageToBgMediaItems(masterPageData, uniqueIdGenerator)
    } else {
        const fallbackThemeDesktopBg = theme.siteBg
        const fallbackThemeMobileBg = !theme.mobileBg || theme.mobileBg === '[siteBg]' ? theme.siteBg : theme.mobileBg
        createBgDataFromTheme(masterPageData, fallbackThemeDesktopBg, fallbackThemeMobileBg, pageIds, uniqueIdGenerator)
    }
}

/**
 * Create BackgroundMedia items on Page (if there were BackgroundImage items before)
 * @param {object} pageData page.data.document_data
 */
function createBackgroundMediaOnPages(pageData, uniqueIdGenerator) {
    convertBgImageToBgMediaItems(pageData, uniqueIdGenerator)
}

/**
 * Covert all BackgroundImage items to BackgroundMedia items
 * @param {object} data masterPage.data.document_data or page.data.document_data
 */
function convertBgImageToBgMediaItems(data, uniqueIdGenerator) {
    const backgroundImageDataItems = _.filter(data, {type: BG_IMAGE_TYPE})
    _.forEach(backgroundImageDataItems, item => {
        convertBgImageToBgMediaItem(data, item, uniqueIdGenerator)
    })
}

/**
 * Covert a single BackgroundImage item to BackgroundMedia item
 * @param {object} data masterPage.data.document_data or page.data.document_data
 * @param {object} backgroundImageItem
 */
function convertBgImageToBgMediaItem(data, backgroundImageItem, uniqueIdGenerator) {
    const bgId = backgroundImageItem.id
    const imageId = uniqueIdGenerator.getUniqueId(BG_IMAGE_PREFIX + BG_DATA_DELIMITER)
    const backgroundMediaItem = generateDataItemsFromBgImageData(backgroundImageItem)
    addBgMediaItemsToData(data, backgroundMediaItem, bgId, imageId)
}

/**
 * Add converted BackgroundMedia to the page/master page data
 * @param {object} data masterPage.data.document_data or page.data.document_data
 * @param {object} backgroundMediaItem
 * @param {string} bgId
 * @param {string} imageId
 */
function addBgMediaItemsToData(data, backgroundMediaItem, bgId, imageId) {
    data[bgId] = _.assign({id: bgId}, backgroundMediaItem.bgItem)
    if (backgroundMediaItem.imageItem) {
        data[bgId].mediaRef = `#${imageId}`
        data[imageId] = _.assign({id: imageId}, backgroundMediaItem.imageItem)
    }
}

/**
 * Use the theme siteBg data to generate the BackgroundMedia data items
 * @param {object} masterPageData masterPage.data.document_data
 * @param {array} pageIds page ids from siteAsJson.masterPage.data.document_data
 * @param {string} themeDesktopBg siteBg string from siteAsJson.masterPage.data.theme_data.THEME_DATA
 * @param {string} themeMobileBg mobileBg string from siteAsJson.masterPage.data.theme_data.THEME_DATA
 * @param {Array} pageIds
 */
function createBgDataFromTheme(masterPageData, themeDesktopBg, themeMobileBg, pageIds, uniqueIdGenerator) {
    const desktopBackgroundItems = generateDataItemsFromBgString(themeDesktopBg)
    const mobileBackgroundItems = generateDataItemsFromBgString(themeMobileBg)

    _.forEach(pageIds, pageId => {
        const desktopBgId = uniqueIdGenerator.getUniqueId(BG_MEDIA_PREFIX, BG_DATA_DELIMITER)
        const mobileBgId = uniqueIdGenerator.getUniqueId(BG_MEDIA_PREFIX, BG_DATA_DELIMITER)
        const desktopImageId = desktopBgId.replace(BG_MEDIA_PREFIX, BG_IMAGE_PREFIX)
        const mobileImageId = mobileBgId.replace(BG_MEDIA_PREFIX, BG_IMAGE_PREFIX)

        setBgToPage(masterPageData, desktopBackgroundItems, pageId, desktopBgId, desktopImageId, 'desktop')
        setBgToPage(masterPageData, mobileBackgroundItems, pageId, mobileBgId, mobileImageId, 'mobile')
    })
}

/**
 * Set BackgroundMedia to a page while recycling old BackgroundImage data id
 * @param {object} data masterPage.data.document_data or page.data.document_data
 * @param {object} bgDataItems the generated data items
 * @param {string} pageId page id
 * @param {string} bgId old BackgroundImage id
 * @param {string} imageId  Image id
 * @param {string} device 'mobile' or 'desktop'
 */
function setBgToPage(data, bgDataItems, pageId, bgId, imageId, device) {
    data[pageId].pageBackgrounds = data[pageId].pageBackgrounds || {desktop: '', mobile: ''}
    data[pageId].pageBackgrounds[device] = {ref: `#${bgId}`, custom: true, isPreset: false}
    addBgMediaItemsToData(data, bgDataItems, bgId, imageId)
}

/**
 * create BackgroundMedia item from theme siteBg string
 * @param {string} bgString theme siteBg string
 * @returns {{bgItem:object, imageItem?:object}}
 */
function generateDataItemsFromBgString(bgString) {
    const bgObject = getBgObjectFromOldValues.apply(this, _.compact(bgString.split(' ')))
    return generateDataItemsFromBg(bgObject)
}

/**
 * create BackgroundMedia item from BackgroundImage Data
 * @param {*} data theme siteBg string
 * @returns {{bgItem:object, imageItem?:object}}
 */
function generateDataItemsFromBgImageData(data) {
    const bgObject = getBgObjectFromOldValues.apply(this, [
        data.url,
        data.imagesizew,
        data.imagesizeh,
        data.positionx,
        data.positiony,
        data.width,
        data.repeatx,
        data.repeaty,
        data.attachment,
        data.color,
        data.metaData
        //data.id
    ])
    return generateDataItemsFromBg(bgObject)
}

/**
 * Helper function to convert bg array to object
 * @param imageId
 * @param imageW
 * @param imageH
 * @param x
 * @param y
 * @param width
 * @param repeatX
 * @param repeatY
 * @param attachment
 * @param color
 * @param metaData
 * @returns {{imageId: *, imageW: *, imageH: *, x: *, y: *, width: *, repeatX: *, repeatY: *, attachment: *, color: *, metaData: *}}
 */
function getBgObjectFromOldValues(imageId, imageW, imageH, x, y, width, repeatX, repeatY, attachment, color, metaData) {
    metaData = metaData || {}
    return {
        imageId,
        imageW,
        imageH,
        x,
        y,
        width,
        repeatX,
        repeatY,
        attachment,
        color,
        metaData: {
            isPreset: metaData.isPreset || false,
            schemaVersion: '2.0',
            isHidden: metaData.isHidden || false
        }
    }
}

/**
 * Build the data items for our background
 * @param {object} bgObject
 * @returns {{bgItem:object, imageItem?:object|undefined}}
 */
function generateDataItemsFromBg(bgObject) {
    const dataItems = {bgItem: {}}
    let color = 'rgb(255,255,255)'
    const alignType = fixers.cssToAlignType(`${bgObject.x} ${bgObject.y}`)
    let fittingType = fixers.cssToFittingType({
        bgRepeat: `${bgObject.repeatX} ${bgObject.repeatY}`,
        bgSize: bgObject.width
    })
    const scrollType = bgObject.attachment === 'fixed' ? 'fixed' : 'scroll'

    fittingType = getLegacyFittingTypes(fittingType)

    if (bgObject.color && bgObject.color !== 'none') {
        color = bgObject.color
    }

    if (bgObject.imageId && bgObject.imageId !== 'none') {
        dataItems.imageItem = {
            type: 'Image',
            uri: bgObject.imageId,
            width: parseInt(bgObject.imageW, 10),
            height: parseInt(bgObject.imageH, 10),
            metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false
            }
        }
    }

    dataItems.bgItem = {
        type: 'BackgroundMedia',
        color,
        alignType,
        fittingType,
        scrollType,
        metaData: bgObject.metaData
    }

    return dataItems
}

/**
 * HTML-Client background has some annoying fitting type combos, we need to support them.
 * @param fittingType
 * @returns {string}
 */
function getLegacyFittingTypes(fittingType) {
    // Legacy map for backgrounds from old editor.
    const legacyBgTypesMap = {}
    legacyBgTypesMap[imageClientApi.fittingTypes.TILE] = imageClientApi.fittingTypes.LEGACY_BG_FIT_AND_TILE
    legacyBgTypesMap[imageClientApi.fittingTypes.TILE_HORIZONTAL] =
        imageClientApi.fittingTypes.LEGACY_BG_FIT_AND_TILE_HORIZONTAL
    legacyBgTypesMap[imageClientApi.fittingTypes.TILE_VERTICAL] =
        imageClientApi.fittingTypes.LEGACY_BG_FIT_AND_TILE_VERTICAL
    legacyBgTypesMap[imageClientApi.fittingTypes.LEGACY_ORIGINAL_SIZE] = imageClientApi.fittingTypes.LEGACY_BG_NORMAL

    return legacyBgTypesMap[fittingType] || fittingType
}

function fixCorruptedBG(theme) {
    theme.siteBg = fixBgData(theme.siteBg)
    if (theme.mobileBg && theme.mobileBg !== '[siteBg]') {
        theme.mobileBg = fixBgData(theme.mobileBg)
    }
}

function fixBgData(bgString) {
    const bgParts = _.compact(bgString.split(' '))
    const bgObject = getBgObjectFromOldValues.apply(this, bgParts)
    let {color} = bgObject
    if (color) {
        color = color.replace('[', '{')
        color = color.replace(']', '}')
        bgParts[9] = color
    }
    return bgParts.join(' ')
}

/**
 * @exports utils/dataFixer/plugins/backgroundMediaFixer
 * @type {{exec: function}}
 */
module.exports = {
    name: 'backgroundMediaConverter',
    version: 1,
    exec(pageJson, pageIdsArray, magicObject) {
        if (!pageJson.structure) {
            return
        }

        const theme = pageJson.data.theme_data && pageJson.data.theme_data.THEME_DATA
        const data = pageJson.data.document_data
        const hasBgMedia = _.some(data, {type: BG_MEDIA_TYPE})
        const hasBgImage = _.some(data, {type: BG_IMAGE_TYPE})
        const {uniqueIdGenerator} = magicObject.dataFixerUtils

        if (hasBgMedia && !hasBgImage) {
            return
        }

        if (pageJson.structure.type === 'Document') {
            fixCorruptedBG(theme)
            createBackgroundMediaOnMaster(data, theme, uniqueIdGenerator)
        } else {
            createBackgroundMediaOnPages(data, uniqueIdGenerator)
        }
    }
}
