import _ from 'lodash'
import warmupUtilsLib from '@wix/santa-core-utils'
import siteDataUtils from '../../dal/siteDataUtils'
import queryParamsWhitelist from './queryParamsWhitelist'

function getResolvedSiteData(siteData) {
    if (siteData.isResolvedSiteData) {
        return siteData
    }

    const currentUrlPageId = siteData.getCurrentUrlPageId()
    const metaSiteId = siteData.getMetaSiteId()
    const pagesDataItemsMap = _.keyBy(siteData.getPagesDataItems(), 'id')
    pagesDataItemsMap.masterPage = siteData.getDataByQuery('masterPage')

    const resolvedSiteData = {
        primaryPageId: siteData.getPrimaryPageId(),
        urlFormat: siteData.getUrlFormat(),
        mainPageId: siteData.getMainPageId(),
        externalBaseUrl: siteData.getExternalBaseUrl(),
        unicodeExternalBaseUrl: siteData.getUnicodeExternalBaseUrl(),
        publicBaseUrl: siteData.getPublicBaseUrl(),
        currentUrl: siteData.currentUrl,
        currentUrlPageId,
        isFeedbackEndpoint: siteData.isFeedbackEndpoint(),
        isSiteHistoryEndpoint: siteData.isSiteHistoryEndpoint(),
        isViewerMode: siteData.isViewerMode(),
        isWixSite: siteData.isWixSite(),
        languageCode: siteData.getLanguageCode(),
        isTemplate: siteData.isTemplate(),
        isUsingSlashUrlFormat: siteData.isUsingUrlFormat(warmupUtilsLib.siteConstants.URL_FORMATS.SLASH),
        isPremiumDomain: siteData.isPremiumDomain(),
        allPageIds: siteData.getAllPageIds(), // Hashbang parser uses it to check if id from URL is a page
        routersConfigMap: _.get(siteData, ['routers', 'configMap']),
        cookie: siteData.getCookie(),
        rendererModel: {
            runningExperiments: siteDataUtils.getRendererModel(siteData, 'runningExperiments', true),
            customNotFoundPageId: siteDataUtils.getRendererModel(siteData, 'customNotFoundPageId')
        },
        serviceTopology: {
            basePublicUrl: siteData.getServiceTopologyProperty('basePublicUrl'),
            baseDomain: siteData.getServiceTopologyProperty('baseDomain'),
            staticDocsUrl: siteData.getServiceTopologyProperty('staticDocsUrl'),
            userFileDomainUrl: siteData.getServiceTopologyProperty('userFileDomainUrl')
        },
        pagesDataItemsMap,
        isPermalink: siteData.isPermalink,
        mapFromPageUriSeoToPageId: siteDataUtils.getMapFromPageUriSeoToPageId(siteData),
        pageResponseForUrl: siteData.pageResponseForUrl,
        rootNavigationInfo: siteData.getRootNavigationInfo(),
        getMetaSiteId: () => metaSiteId,
        isAndroidMobileDevice: siteData.mobile.isAndroidMobileDevice()
    }

    // @ts-ignore
    resolvedSiteData.isResolvedSiteData = true

    return resolvedSiteData
}

export default {
    getResolvedSiteData,
    queryParamsWhitelist
}
