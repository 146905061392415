import type {Layout, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import layoutHooksUtils, {LayoutCallback} from './utils/layoutHooksUtils'

function updateChildLayout(
    ps: PS,
    compPointer: Pointer,
    layoutToUpdate: Layout,
    updateCompLayoutFn: LayoutCallback,
    isTriggeredByHook: boolean,
    previousLayout: Layout
) {
    const [childCompPointer] = ps.pointers.components.getChildren(compPointer)
    if (
        childCompPointer &&
        layoutHooksUtils.isCompResizing(previousLayout, layoutToUpdate) &&
        _.isFunction(updateCompLayoutFn)
    ) {
        const dx = _.isUndefined(layoutToUpdate.x) ? 0 : layoutToUpdate.x - previousLayout.x
        const dy = _.isUndefined(layoutToUpdate.y) ? 0 : layoutToUpdate.y - previousLayout.y
        const rootLayoutUpdate = _.defaults({x: dx, y: dy}, layoutToUpdate)
        updateCompLayoutFn(ps, childCompPointer, rootLayoutUpdate)
    }
}

export default {
    updateChildLayout
}
