export default {
    INVALID_VERSION_TYPE: 'INVALID_VERSION_TYPE',
    NO_GRID_APP_ID: 'NO_GRID_APP_ID',
    NO_STUDIO_APP_APP_ID: 'NO_STUDIO_APP_APP_ID',
    UNKNOWN_SERVER_ERROR: 'UNKNOWN_SERVER_ERROR',
    USER_NOT_AUTHORIZED_FOR_APP: 'USER_NOT_AUTHORIZED_FOR_APP',
    NO_APP_DEF_ID: 'NO_APP_DEF_ID',
    NO_BUILD_ID: 'NO_BUILD_ID',
    NO_PACKAGE_IMPORT_NAME: 'NO_PACKAGE_IMPORT_NAME'
}
