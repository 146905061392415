import {pointerUtils} from '@wix/document-manager-core'
const blocksPreviewDataPointer = 'blocksPreviewData'

const {getPointer} = pointerUtils

const createPointersMethods = () => {
    const getBlocksPreviewData = () => getPointer(blocksPreviewDataPointer, blocksPreviewDataPointer)
    return {
        blocks: {
            getBlocksPreviewData
        }
    }
}

export {createPointersMethods}
