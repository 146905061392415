import _ from 'lodash'
import type {
    Pointer,
    AbsoluteLayout,
    CompStructure,
    MeshItemLayout,
    ItemLayouts,
    FixedItemLayout,
    CompRef,
    Rect
} from '@wix/document-services-types'
import type {DataModelExtensionAPI} from '../dataModel/dataModel'
import type {VariantsExtensionAPI} from '../variants/variants'
import type {ComponentsMetadataAPI} from '../componentsMetadata/componentsMetadata'
import {
    StructureToConvertToMesh,
    MeshConversionResult,
    ConversionContext,
    convertToMesh,
    ReverseConversionContext,
    MeshStructureToConvertToAbsolute,
    getAbsoluteLayoutPropsFromFixedItemLayout
} from '@wix/document-manager-utils'
import {CreateExtArgs, Extension, ExtensionAPI, pointerUtils} from '@wix/document-manager-core'
import {
    DATA_TYPES,
    VARIANTS,
    VIEW_MODES,
    RELATION_DATA_TYPES,
    REF_ARRAY_DATA_TYPE,
    BASE_PROPS_SCHEMA_TYPE
} from '../../constants/constants'
import {stripHashIfExists} from '../../utils/refArrayUtils'
import {deepClone} from '@wix/wix-immutable-proxy'
import type {FeaturesExtensionAPI} from '../features/features'
import type {ViewerExtensionAPI} from '../viewer/viewerExtension'

const {getPointer} = pointerUtils
const {MOBILE_VARIANT_ID} = VARIANTS
const {DESKTOP, MOBILE} = VIEW_MODES
const mobileVariantPointer = getPointer(MOBILE_VARIANT_ID, DATA_TYPES.variants)

export interface MeshLayoutApi {
    pageUsesMeshLayout(pageId: string): boolean

    siteQualifiesForMeshLayout(): boolean

    convertToMeshLayout(componentId: string, conversionSettings?: ConversionSettings): void

    convertToStructureLayout(
        compStructures: Record<string, CompStructure>,
        reverseConversionContext?: ReverseConversionContext
    ): void

    getMeasurements(compPointer: CompRef): Rect
}

export type MeshLayoutExtApi = ExtensionAPI & {
    meshLayout: MeshLayoutApi
}

interface ConversionSettings {
    shouldUpdateOnlyMobile?: boolean
    ignoreRoot?: boolean
}

export const createExtension = (): Extension => {
    const createExtensionAPI = ({dal, extensionAPI, pointers, coreConfig}: CreateExtArgs): MeshLayoutExtApi => {
        const {dataModel} = extensionAPI as DataModelExtensionAPI
        const {features} = extensionAPI as FeaturesExtensionAPI
        const {viewer} = extensionAPI as ViewerExtensionAPI
        const {schema} = dal

        const getStructure = (componentId: string): StructureToConvertToMesh => {
            const desktopPointer = getPointer(componentId, DESKTOP)
            const mobilePointer = getPointer(componentId, MOBILE)
            const desktop = dal.get(desktopPointer)
            const mobile = dal.get(mobilePointer)
            const desktopProps = dataModel.components.getItem(desktopPointer, DATA_TYPES.prop)
            const mobileProps = dataModel.components.getItem(mobilePointer, DATA_TYPES.prop)
            const structure = desktop ?? mobile
            const structureToConvertToMesh: StructureToConvertToMesh = {
                componentType: structure.componentType,
                pageId: structure.metaData.pageId,
                componentId,
                type: structure.type
            }
            if (desktop) {
                structureToConvertToMesh.desktop = {
                    layout: _.cloneDeep(desktop.layout),
                    props: desktopProps,
                    parent: desktop.parent,
                    components: desktop.components,
                    ratio: 0
                }
            }
            if (mobile) {
                structureToConvertToMesh.mobile = {
                    layout: _.cloneDeep(mobile.layout),
                    props: mobileProps,
                    parent: mobile.parent,
                    components: mobile.components,
                    ratio: 0
                }
            }
            return structureToConvertToMesh
        }

        const createVariantRelation = (conversionResult: MeshConversionResult, componentId: string) => {
            return {
                type: RELATION_DATA_TYPES.VARIANTS,
                from: `#${componentId}`,
                to: conversionResult!.mobile.layout,
                variants: [`#${MOBILE_VARIANT_ID}`]
            }
        }

        const addVariantRelation = (
            conversionResult: MeshConversionResult,
            componentId: string,
            layoutRefArrayPointer: Pointer
        ) => {
            const variantRelation = createVariantRelation(conversionResult, componentId)
            const pageId = pointers.structure.getPageOfComponent(getPointer(componentId, DESKTOP)).id
            const variantRelationPointer = dataModel.addItem(variantRelation, DATA_TYPES.layout, pageId)
            const updatedRefArrayValues = [...dal.get(layoutRefArrayPointer).values, `#${variantRelationPointer.id}`]
            dal.set({...layoutRefArrayPointer, innerPath: ['values']}, updatedRefArrayValues)
        }

        const addMeshLayout = (conversionResult: MeshConversionResult, componentId: string, viewMode: string) => {
            if (!conversionResult) {
                return
            }

            const values = conversionResult.default.layout ? [conversionResult.default.layout] : []
            const refArray = {
                type: REF_ARRAY_DATA_TYPE,
                values
            }

            if (conversionResult.mobile.layout) {
                // @ts-ignore
                refArray.values.push(createVariantRelation(conversionResult, componentId))
            }

            const layoutId = dataModel.components.addItem(
                getPointer(componentId, viewMode),
                DATA_TYPES.layout,
                refArray
            ).id

            if (viewMode !== VIEW_MODES.MOBILE) {
                dataModel.components.linkComponentToItemByTypeDesktopAndMobile(
                    getPointer(componentId, VIEW_MODES.MOBILE),
                    layoutId,
                    DATA_TYPES.layout
                )
            }
        }

        const updateMeshLayoutForMobile = (
            conversionResult: MeshConversionResult,
            componentId: string,
            layoutRefArrayPointer: Pointer
        ) => {
            if (!conversionResult.mobile.layout) {
                return
            }

            const {variants} = extensionAPI as VariantsExtensionAPI

            const layoutToUpdatePointer = variants.getDataPointerConsideringVariantsForRefPointer(
                getPointer(componentId, DESKTOP, {
                    variants: [{type: DATA_TYPES.variants, id: MOBILE_VARIANT_ID}]
                }),
                DATA_TYPES.layout,
                layoutRefArrayPointer
            )

            if (!layoutToUpdatePointer) {
                addVariantRelation(conversionResult, componentId, layoutRefArrayPointer)
            } else {
                const currentLayout = deepClone(dal.get(layoutToUpdatePointer!))
                const layoutToUpdate = {...currentLayout, ...conversionResult!.mobile.layout}
                dal.set(layoutToUpdatePointer!, layoutToUpdate)
            }
        }

        const updateProps = (conversionResult: MeshConversionResult, componentId: string) => {
            if (conversionResult.mobile.props) {
                const {componentsMetadata} = extensionAPI as ComponentsMetadataAPI
                const mobilePointer = getPointer(componentId, MOBILE)
                const desktopPointer = getPointer(componentId, DESKTOP)
                const isSplit = componentsMetadata.isMobileComponentPropertiesSplit(mobilePointer)
                if (isSplit) {
                    dataModel.components.addItem(mobilePointer, DATA_TYPES.prop, conversionResult.mobile.props)
                } else {
                    const propId = dataModel.components.addItem(
                        desktopPointer,
                        DATA_TYPES.prop,
                        conversionResult.mobile.props
                    ).id

                    //TODO dataModel should update queries for the 2 view modes and then remove this line
                    dal.set({...mobilePointer, innerPath: ['propertyQuery']}, propId)
                }
            }
        }

        const notConvertedTypes = new Set(['wysiwyg.viewer.components.PageGroup'])
        const prepare = (
            componentsIds: string[],
            structureToConvertToMesh: Record<string, StructureToConvertToMesh>
        ) => {
            for (const componentId of componentsIds) {
                const structure = getStructure(componentId)
                if (!notConvertedTypes.has(structure.componentType)) {
                    structureToConvertToMesh[componentId] = structure
                }
            }
        }

        const write = (convertedToMeshStructure: Record<string, MeshConversionResult>) => {
            for (const [componentId, convertedData] of Object.entries(convertedToMeshStructure)) {
                const isMobileOnly = !dal.has(getPointer(componentId, DESKTOP))
                const compViewMode = isMobileOnly ? MOBILE : DESKTOP

                updateProps(convertedData, componentId)

                const layoutId = dal.get(getPointer(componentId, compViewMode))?.layoutQuery
                if (!layoutId) {
                    addMeshLayout(convertedData, componentId, compViewMode)
                } else {
                    const layoutRefArrayPointer = getPointer(stripHashIfExists(layoutId), DATA_TYPES.layout)
                    updateMeshLayoutForMobile(convertedData, componentId, layoutRefArrayPointer)
                }
            }
        }

        const createPropertiesItemByType = (type: string) => {
            const propsType = schema.getComponentDefinition(type).propertyTypes?.[0] ?? BASE_PROPS_SCHEMA_TYPE
            return schema.createItemAccordingToSchema(propsType, 'props')
        }

        const getChildren = (rootId: string, viewMode: string): string[] => {
            const compPointer = getPointer(rootId, viewMode)
            return pointers.structure
                .getChildrenRecursivelyRightLeftRootIncludingRoot(compPointer)
                .map(pointer => pointer.id)
        }

        const convertToMeshLayout = (rootId: string, {shouldUpdateOnlyMobile, ignoreRoot}: ConversionSettings = {}) => {
            const desktopComponentsIds = getChildren(rootId, DESKTOP)
            const mobileComponentsIds = getChildren(rootId, MOBILE)
            const uniqueComponentsIds = _.union(desktopComponentsIds, mobileComponentsIds)
            const componentsIds = shouldUpdateOnlyMobile ? mobileComponentsIds : uniqueComponentsIds
            const structureToConvertToMesh: Record<string, StructureToConvertToMesh> = {}
            const convertedToMeshStructure: Record<string, MeshConversionResult> = {}
            const masterPagePointer = pointers.structure.getMasterPage(DESKTOP)
            const masterPageDataPointer = pointers.data.getDataItemFromMaster(masterPagePointer.id)
            const siteWidth = dal.get(pointers.getInnerPointer(masterPageDataPointer, 'renderModifiers.siteWidth'))
            const conversionContext: ConversionContext = {createPropertiesItemByType, siteWidth}

            prepare(componentsIds, structureToConvertToMesh)
            convertToMesh(structureToConvertToMesh, convertedToMeshStructure, conversionContext)

            if (ignoreRoot) {
                delete convertedToMeshStructure[rootId]
            }

            write(convertedToMeshStructure)
        }

        const convertMeshLayoutToAbsoluteLayout = (
            meshLayoutStructureToConvertToAbsolute: Record<string, MeshStructureToConvertToAbsolute>,
            convertedToAbsoluteLayout: Record<string, AbsoluteLayout>
        ) => {
            for (const [id, structure] of Object.entries(meshLayoutStructureToConvertToAbsolute)) {
                if (structure.layout) {
                    if ((structure.layout.itemLayout as ItemLayouts).type === 'MeshItemLayout') {
                        convertedToAbsoluteLayout[id] = {
                            x: (structure.layout.itemLayout as MeshItemLayout).meshData.x,
                            y: (structure.layout.itemLayout as MeshItemLayout).meshData.y,
                            width: (structure.layout.itemLayout as MeshItemLayout).meshData.width,
                            height: (structure.layout.itemLayout as MeshItemLayout).meshData.height,
                            rotationInDegrees:
                                (structure.layout.itemLayout as MeshItemLayout).meshData.rotationInDegrees ?? 0,
                            fixedPosition: false,
                            scale: 1
                        }
                    }
                    if ((structure.layout.itemLayout as ItemLayouts).type === 'FixedItemLayout') {
                        convertedToAbsoluteLayout[id] = {
                            ...getAbsoluteLayoutPropsFromFixedItemLayout(
                                structure.layout.itemLayout as FixedItemLayout,
                                structure.componentType
                            ),
                            x: 0,
                            y: 0,
                            width: 0,
                            height: 0,
                            scale: 1,
                            fixedPosition: true
                        } as AbsoluteLayout
                    }
                }
            }
        }

        const convertToStructureLayout = (
            flatComponents: Record<string, CompStructure>,
            reverseConversionContext: ReverseConversionContext = {}
        ): void => {
            const meshLayoutStructureToConvertToAbsolute = {}
            const absoluteLayouts: Record<string, AbsoluteLayout> = {}
            const {variants} = extensionAPI as VariantsExtensionAPI

            for (const [componentId, component] of Object.entries(flatComponents)) {
                const {components, propertyQuery, componentType} = component
                const props = propertyQuery
                    ? dal.get(pointers.data.getPropertyItem(stripHashIfExists(propertyQuery)))
                    : undefined

                const layoutPointer =
                    variants.getComponentDataPointerConsideringVariants(
                        pointers.getPointer(
                            componentId,
                            DESKTOP,
                            reverseConversionContext.isMobile ? {variants: [mobileVariantPointer]} : {}
                        ),
                        DATA_TYPES.layout
                    ) ??
                    variants.getComponentDataPointerConsideringVariants(
                        pointers.getPointer(
                            componentId,
                            MOBILE,
                            reverseConversionContext.isMobile ? {variants: [mobileVariantPointer]} : {}
                        ),
                        DATA_TYPES.layout
                    )
                const layout = layoutPointer ? dal.get(layoutPointer) : undefined

                meshLayoutStructureToConvertToAbsolute[componentId] = {
                    componentType,
                    layout,
                    components,
                    props
                }
            }

            convertMeshLayoutToAbsoluteLayout(meshLayoutStructureToConvertToAbsolute, absoluteLayouts)

            for (const [componentId, layout] of Object.entries(absoluteLayouts)) {
                flatComponents[componentId].layout = layout
            }
        }

        const siteQualifiesForMeshLayout = (): boolean =>
            coreConfig.experimentInstance.isOpen('dm_meshLayout') &&
            !!features.component.get(pointers.structure.getMasterPage(DESKTOP), 'pageSections')?.isSectionsEnabled

        const pageUsesMeshLayout = (pageId: string): boolean =>
            !!dataModel.components.getItem(pointers.structure.getPage(pageId, DESKTOP), DATA_TYPES.layout)

        const getMeasurements = (compPointer: CompRef) => {
            const {x, y, width, height} = dal.get(pointers.getInnerPointer(compPointer, 'layout'))
            return viewer?.getComponentMeasurements(compPointer) ?? {x, y, width, height}
        }

        return {
            meshLayout: {
                siteQualifiesForMeshLayout,
                pageUsesMeshLayout,
                convertToMeshLayout,
                // temporary solution until measurements will be introduced
                convertToStructureLayout,
                getMeasurements
            }
        }
    }

    return {
        name: 'meshLayout',
        dependencies: new Set(['dataModel', 'structure', 'features', 'variants', 'componentsMetaData', 'viewer']),
        createExtensionAPI
    }
}
