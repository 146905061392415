import _ from 'lodash'
import type {
    CompRef,
    Pointer,
    Pointers,
    ExternalsBuilderMap,
    RawComponentExportStructure,
    ExternalsMap,
    SchemaService
} from '@wix/document-services-types'
import type {DAL, DalValue, ExtensionAPI} from '@wix/document-manager-core'
import {resolveDataItem, updateDataItem} from './dataItems'
import type {DataModelExtensionAPI} from '../../../../dataModel/dataModel'
import type {ComponentDefinitionExtensionAPI} from '../../../../componentDefinition'

interface OverridesUpdate {
    overridesPointer: Pointer
    origDataItem?: DalValue
    dataItem?: DalValue
}

type OverridesUpdates = OverridesUpdate[]

const getOverrides = (
    component: RawComponentExportStructure,
    componentPointer: CompRef,
    pointers: Pointers,
    extensionAPI: ExtensionAPI
): Pointer[] => {
    const {dataModel, componentDefinition} = extensionAPI as DataModelExtensionAPI & ComponentDefinitionExtensionAPI
    const overridesPointers = dataModel.components.getAllRepeaterOverridesForComponent(componentPointer)

    if (componentDefinition.isRefComponent(component.structure.componentType)) {
        overridesPointers.push(...pointers.full.referredStructure.getAllOverrides(componentPointer))
    }

    return overridesPointers
}

export const resolveOverrides = (
    component: RawComponentExportStructure,
    componentPointer: CompRef,
    pagePointer: CompRef,
    pointers: Pointers,
    extensionAPI: ExtensionAPI,
    schemaService: SchemaService,
    externals?: ExternalsBuilderMap
) => {
    const overridesPointers = getOverrides(component, componentPointer, pointers, extensionAPI)

    const overrides = {}
    for (const overridePointer of overridesPointers) {
        const dataItem = resolveDataItem(overridePointer, pagePointer, pointers, extensionAPI, schemaService, externals)
        overrides[overridePointer.type] ??= {}
        overrides[overridePointer.type][overridePointer.id] = dataItem
    }

    if (!_.isEmpty(overrides)) {
        component.overrides = overrides
    }
}

const getUpdates = (
    componentPointer: CompRef,
    component: RawComponentExportStructure,
    pagePointer: CompRef,
    pointers: Pointers,
    extensionAPI: ExtensionAPI
): OverridesUpdates => {
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const {overrides} = component
    const origOverridesPointers = getOverrides(component, componentPointer, pointers, extensionAPI)
    const updates: OverridesUpdates = origOverridesPointers.map(overridesPointer => {
        const {id, type} = overridesPointer
        const origDataItem = dataModel.getItem(id, type, pagePointer.id)
        const dataItem = overrides?.[type]?.[id]
        delete overrides?.[type]?.[id]

        return {
            overridesPointer,
            origDataItem,
            dataItem
        }
    })

    _.forOwn(overrides, (items: Record<string, DalValue>, namespace: string) => {
        _.forOwn(items, (dataItem: DalValue, id: string) => {
            updates.push({
                overridesPointer: pointers.getPointer(id, namespace),
                dataItem
            })
        })
    })

    return updates
}

export const updateOverrides = (
    componentPointer: CompRef,
    component: RawComponentExportStructure,
    externals: ExternalsMap,
    dal: DAL,
    pointers: Pointers,
    extensionAPI: ExtensionAPI,
    schemaService: SchemaService
) => {
    const pagePointer = pointers.structure.getPageOfComponent(componentPointer) as CompRef
    const updates = getUpdates(componentPointer, component, pagePointer, pointers, extensionAPI)
    for (const {overridesPointer, dataItem, origDataItem} of updates) {
        if (dataItem) {
            dataItem.id ??= overridesPointer.id
            externals.externalRefs[dataItem.id] ??= dataItem.id
        }

        updateDataItem(
            dataItem,
            origDataItem,
            overridesPointer.type,
            pagePointer,
            externals,
            dal,
            pointers,
            extensionAPI,
            schemaService
        )
    }
}
