import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import constants from '../../../constants/constants'
import dataModel from '../../../dataModel/dataModel'
import hooks from '../../../hooks/hooks'
import mobileHints from '../mobileHints'
import {shouldEnableImprovedMergeFlow} from '../utils'
import mobilePresetsAPI from './mobilePresetsAPI'
import {hasGeneralData, isMobileHintsPreset, shouldMarkAsDirty} from './mobilePresetsUtils'
import experiment from 'experiment-amd'

const {
    removeAllPresetDataRecursively,
    removePresetOffsetDataOfChildren,
    removeAutomaticPresets,
    removePresetOffsetDataIfNeeded,
    handleMobileStructure,
    removeGeneralPresetDataIfNeeded,
    removePresetSizeIfNeeded,
    convertMobileComponentToMobileHints,
    beforeAddRoot
} = mobilePresetsAPI

const mobileHintsChangeHooks = {
    [hooks.HOOKS.CHANGE_PARENT.BEFORE]: (ps: PS, componentPointer: Pointer, newContainerPointer: Pointer) => {
        if (ps.pointers.components.isMobile(componentPointer)) {
            return
        }
        removeAllPresetDataRecursively(ps, componentPointer)
        removePresetOffsetDataOfChildren(ps, newContainerPointer)
        const containerPointer = ps.pointers.components.getParent(componentPointer)
        removePresetOffsetDataOfChildren(ps, containerPointer)
    },
    [hooks.HOOKS.REMOVE.BEFORE]: (ps: PS, componentPointer: Pointer, removeParent) => {
        if (removeParent || ps.pointers.components.isPage(componentPointer)) {
            return
        }
        if (ps.pointers.components.isMobile(componentPointer)) {
            if (shouldEnableImprovedMergeFlow(ps)) {
                const parent = ps.pointers.components.getParent(componentPointer)
                convertMobileComponentToMobileHints(ps, parent, {
                    convertToAbsolute: experiment.isOpen('dm_meshLayout')
                })
            }
            return
        }
        const containerPointer =
            ps.pointers.components.getParent(componentPointer) ||
            ps.pointers.full.components.getParent(componentPointer)
        removePresetOffsetDataOfChildren(ps, containerPointer)
    },
    [hooks.HOOKS.LAYOUT.UPDATE_AFTER]: (
        ps: PS,
        componentPointer: Pointer,
        newLayoutData,
        _updateCompLayoutCallbackForHooks,
        _isTriggeredByHook,
        previousLayout
    ) => {
        if (shouldEnableImprovedMergeFlow(ps)) {
            if (ps.pointers.components.isMobile(componentPointer)) {
                return
            }

            const mobileHintsItem = dataModel.getMobileHintsItem(ps, componentPointer)
            const parent = ps.pointers.components.getParent(componentPointer)
            if (
                hasGeneralData(mobileHintsItem) &&
                !ps.pointers.components.isPage(componentPointer) &&
                ps.pointers.components.isPage(parent)
            ) {
                /** To change order of components on mobile when it was changed in zoom out mode on desktop */
                mobileHints.markComponentAsDirtyForForceReRender(ps, componentPointer)
                return
            }

            if (shouldMarkAsDirty(newLayoutData, previousLayout)) {
                mobileHints.markComponentAsDirtyForForceReRender(ps, componentPointer)
            }

            if (!isMobileHintsPreset(mobileHintsItem)) {
                return
            }

            const parentPointer = ps.pointers.components.getParent(componentPointer)
            const parentMobileHintsItem = dataModel.getMobileHintsItem(ps, parentPointer)
            removeGeneralPresetDataIfNeeded(ps, parentPointer, parentMobileHintsItem)
            removePresetOffsetDataIfNeeded(ps, componentPointer, newLayoutData, previousLayout, mobileHintsItem)
            removePresetSizeIfNeeded(ps, componentPointer, newLayoutData, previousLayout, mobileHintsItem)
        }
    }
}

const afterMobileConversion = (ps: PS, updatedRoots, commitConversionResults) => {
    if (!commitConversionResults) {
        return
    }
    _.forEach(updatedRoots, rootPointer =>
        removeAutomaticPresets(ps, ps.pointers.components.getPage(rootPointer.id, constants.VIEW_MODES.DESKTOP))
    )
}

const initialize = () => {
    hooks.registerHook(hooks.HOOKS.ADD_ROOT.AFTER, handleMobileStructure)
    if (!experiment.isOpen('dm_useExtBeforeAddRootHook')) {
        hooks.registerHook(hooks.HOOKS.ADD_ROOT.BEFORE, beforeAddRoot)
    }
    _.forEach(mobileHintsChangeHooks, (cb, hookName) => hooks.registerHook(hookName, cb))
    hooks.registerHook(hooks.HOOKS.MOBILE_CONVERSION.AFTER, afterMobileConversion)
}

export default {initialize}
