import type {CompRef, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import componentStylesAndSkinsAPI from '../component/componentStylesAndSkinsAPI'
import utils from './stylableUtils'

const getStylableEditor = (ps: PS) => ps.siteAPI.getStylableEditorInstance()

const mergeStylesheets = (ps: PS, destination, source) => ps.siteAPI.mergeStylesheets(destination, source)

const updateComponentStyle = (ps: PS, compPointer: CompRef, styleData) => {
    componentStylesAndSkinsAPI.style.update(ps, compPointer, styleData)
    ps.siteAPI.revertQuickChanges(compPointer)
}

const createEmptyStyle = (ps: PS, compPointerWithVariant, originalStyle) => {
    const styleItem = componentStylesAndSkinsAPI.style.get(ps, compPointerWithVariant)
    if (!styleItem) {
        // @ts-expect-error
        const emptyStylableStyle = _.clone(originalStyle, true)
        emptyStylableStyle.style.properties['$st-css'] = utils.createEmptyStylableStylesheet(
            emptyStylableStyle.componentClassName
        )
        return updateComponentStyle(ps, compPointerWithVariant, emptyStylableStyle)
    }
}

const removeRefIfEmptyStyle = (ps: PS, compPointerWithVariant) => {
    const styleItem = componentStylesAndSkinsAPI.style.get(ps, compPointerWithVariant)
    if (
        styleItem &&
        _.get(styleItem, ['style', 'properties', '$st-css']) ===
            utils.createEmptyStylableStylesheet(styleItem.componentClassName)
    ) {
        componentStylesAndSkinsAPI.style.removeScoped(ps, compPointerWithVariant) // will remove the scoped style
    }
}

const setQuickChange = (ps: PS, compPointer: Pointer, selector, cssMap) => {
    ps.siteAPI.setQuickChanges(compPointer, selector, cssMap)
}

const revertQuickChange = (ps: PS, compPointer: Pointer) => {
    ps.siteAPI.revertQuickChanges(compPointer)
}

const forceState = (ps: PS, compPointer: Pointer, forceStateParams) => {
    ps.siteAPI.forceState(compPointer, forceStateParams)
}

const revertForceState = (ps: PS, compPointer: Pointer) => {
    ps.siteAPI.revertForceState(compPointer)
}

export default {
    getStylableEditorInstance: getStylableEditor,
    update: updateComponentStyle,
    createEmptyStyle,
    removeRefIfEmptyStyle,
    setQuickChange,
    revertQuickChange,
    forceState,
    revertForceState,
    mergeStylesheets
}
