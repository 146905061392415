import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import clientSpecMapService from './clientSpecMapService'
import type {PageExtensionAPI} from '@wix/document-manager-extensions'

function getSubPages(ps: PS, pageId: string) {
    const data = (ps.extensionAPI as PageExtensionAPI).page.data.pick(pageId, ['appDefinitionId', 'tpaPageId'])
    if (data?.appDefinitionId && data.tpaPageId) {
        const widgetData = clientSpecMapService.getWidgetDataFromTPAPageId(ps, data.appDefinitionId, data.tpaPageId)
        /*
         * In app CSM data, each widget can have router types (sub pages) linked to it.
         * These sub pages are a list of string identifiers that can be used to fetch sub page inner routes.
         * Each tpa page can have multiple usages and the app populates the content on runtime
         * The different usages are mapped to sub pages.
         */
        return _.get(widgetData, ['componentFields', 'subPages'], [])
    }
    return []
}

export default {
    getSubPages
}
