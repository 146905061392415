import type {PS} from '@wix/document-services-types'
import mobileConversionFacade from '../../../mobileConversion/mobileConversionFacade'

export default function runMobileMergeOnAllPages(ps: PS) {
    if (ps.siteAPI.isMobileView()) {
        return
    }
    const commitConversionResults = false
    mobileConversionFacade.runPartialConversionAllPages(ps, commitConversionResults)
}
