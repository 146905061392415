import {constants} from '@wix/santa-core-utils'

const metaData = {
    default: {
        to: {allow: true},
        from: {allow: true, lock: constants.ANCHORS.LOCK_CONDITION.THRESHOLD}
    },
    'wysiwyg.viewer.components.BoxSlideShow': {
        to: {allow: true, zeroBottomParentDistance: true}
    },
    'wysiwyg.viewer.components.StateBox': {
        to: {allow: true, zeroBottomParentDistance: true}
    },
    'wysiwyg.viewer.components.StateStrip': {
        to: {allow: true, zeroBottomParentDistance: true}
    },
    'wysiwyg.viewer.components.StripContainerSlideShow': {
        to: {allow: true, zeroBottomParentDistance: true}
    },
    'wysiwyg.viewer.components.Repeater': {
        to: {allow: true, zeroBottomParentDistance: true},
        disablePushRows: true,
        skipGroupingForChildren: true
    },
    'wysiwyg.viewer.components.WRichText': {
        from: {allow: true, positiveAnchorToDisplayedOnlyParent: true}
    },
    'wysiwyg.viewer.components.Column': {
        to: {allow: true},
        from: {allow: false, lock: constants.ANCHORS.LOCK_CONDITION.NEVER}
    },
    'wysiwyg.viewer.components.PageGroup': {
        to: {allow: true},
        from: {allow: true, lock: constants.ANCHORS.LOCK_CONDITION.ALWAYS}
    },
    'mobile.core.components.Page': {
        to: {allow: true},
        from: {allow: false, lock: constants.ANCHORS.LOCK_CONDITION.NEVER}
    },
    'wysiwyg.viewer.components.PagesContainer': {
        to: {allow: true},
        from: {allow: true, lock: constants.ANCHORS.LOCK_CONDITION.THRESHOLD}
    },
    'wysiwyg.viewer.components.PopupContainer': {
        to: {allow: true},
        from: {allow: false}
    },
    'wysiwyg.viewer.components.SiteSegmentContainer': {
        to: {allow: true},
        from: {allow: true, lock: constants.ANCHORS.LOCK_CONDITION.ALWAYS}
    },
    'wysiwyg.viewer.components.WSiteStructure': {
        to: {allow: true, distance: 0},
        from: {allow: false, lock: constants.ANCHORS.LOCK_CONDITION.NEVER}
    },
    'wysiwyg.common.components.backtotopbutton.viewer.BackToTopButton': {
        from: {allow: false}
    },
    'wysiwyg.viewer.components.tpapps.TPAGluedWidget': {
        from: {allow: false}
    },
    'wixapps.integration.components.AppPage': {
        from: {allow: false}
    },
    'platform.components.AppController': {
        to: {allow: false},
        from: {allow: false}
    },
    'wysiwyg.viewer.components.Popover': {
        to: {allow: false, zeroBottomParentDistance: true},
        from: {allow: false}
    }
}

export const getComponentsAnchorsMetaData = () => metaData
