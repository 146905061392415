const {markPropertyToRemove} = require('./utils')

const sides = ['border-left', 'border-right', 'border-top', 'border-bottom']
const borderPropertiesToRemove = ['border', 'border-width', ...sides.map(markPropertyToRemove)]

function updateBorderWidth(borderValue = '1px solid', borderWidth) {
    const [, style, color] = borderValue.split(' ')
    return color ? `${borderWidth} ${style} ${color}` : `${borderWidth} ${style}`
}

function updateBorderWidthInBorderObject(borderObject, borderWidthProp) {
    const borderWidth = borderWidthProp.value.split(' ')
    const bordersLength = borderWidth.length

    if (bordersLength === 1) {
        const [borderWidthValue] = borderWidth
        return {
            left: updateBorderWidth(borderObject.left, borderWidthValue),
            right: updateBorderWidth(borderObject.right, borderWidthValue),
            top: updateBorderWidth(borderObject.top, borderWidthValue),
            bottom: updateBorderWidth(borderObject.bottom, borderWidthValue)
        }
    }

    if (bordersLength === 2) {
        const [borderTopAndBottom, borderLeftAndRight] = borderWidth
        return {
            left: updateBorderWidth(borderObject.left, borderLeftAndRight),
            right: updateBorderWidth(borderObject.right, borderLeftAndRight),
            top: updateBorderWidth(borderObject.top, borderTopAndBottom),
            bottom: updateBorderWidth(borderObject.bottom, borderTopAndBottom)
        }
    }

    if (bordersLength === 3) {
        const [borderTop, borderLeftAndRight, borderBottom] = borderWidth
        return {
            left: updateBorderWidth(borderObject.left, borderLeftAndRight),
            right: updateBorderWidth(borderObject.right, borderLeftAndRight),
            top: updateBorderWidth(borderObject.top, borderTop),
            bottom: updateBorderWidth(borderObject.bottom, borderBottom)
        }
    }

    const [borderTop, borderRight, borderBottom, borderLeft] = borderWidth

    return {
        left: updateBorderWidth(borderObject.left, borderLeft),
        right: updateBorderWidth(borderObject.right, borderRight),
        top: updateBorderWidth(borderObject.top, borderTop),
        bottom: updateBorderWidth(borderObject.bottom, borderBottom)
    }
}

function borderToProperty(border) {
    const borderProperties = []
    Object.entries(border).forEach(([side, value]) => {
        borderProperties.push({
            prop: `border-${side}`,
            cssProp: `border-${side}`,
            value
        })
    })

    return borderProperties
}

function handleBorderProperty(property, borderSides) {
    if (property.cssProp === 'border') {
        borderSides.left = property.value
        borderSides.right = property.value
        borderSides.top = property.value
        borderSides.bottom = property.value
    }

    if (property.cssProp === 'border-width') {
        const updatedBorder = updateBorderWidthInBorderObject(borderSides, property)
        borderSides.left = updatedBorder.left
        borderSides.right = updatedBorder.right
        borderSides.top = updatedBorder.top
        borderSides.bottom = updatedBorder.bottom
    }

    if (sides.includes(property.cssProp)) {
        const side = property.cssProp.split('-')[1]
        property.cssProp = markPropertyToRemove(property.cssProp)
        borderSides[side] = property.value
    }
}

function getUpdatedProperties(properties, borderSides) {
    return [
        ...properties.filter(property => !borderPropertiesToRemove.includes(property.cssProp)),
        ...borderToProperty(borderSides)
    ]
}
/**
 * @param {Array<import('../index').ParsedStyleItem>} styleItems
 * @returns {Array<import('../index').ParsedStyleItem>}
 */
function migrateBorder(styleItems) {
    return styleItems.map(styleItem => {
        const {parsedStyle} = styleItem
        const updatedParsedStyle = Object.entries(parsedStyle).reduce(
            (acc, [selector, {properties, mobileProperties}]) => {
                const borderSides = {}
                const mobileBorderSides = {}

                properties.forEach(property => {
                    handleBorderProperty(property, borderSides)
                })

                mobileProperties.forEach(property => {
                    handleBorderProperty(property, mobileBorderSides)
                })

                const updatedProperties = getUpdatedProperties(properties, borderSides)
                const updatedMobileProperties = getUpdatedProperties(mobileProperties, mobileBorderSides)

                return {
                    ...acc,
                    [selector]: {
                        ...acc[selector],
                        properties: updatedProperties,
                        mobileProperties: updatedMobileProperties
                    }
                }
            },
            parsedStyle
        )

        return {
            ...styleItem,
            parsedStyle: updatedParsedStyle
        }
    })
}

module.exports = migrateBorder
