const ITEM_LAYOUT_TYPES = {
    STRETCH_ITEM_LAYOUT: {
        type: 'GridItemLayout',
        alignSelf: 'stretch',
        justifySelf: 'stretch',
        gridArea: {
            columnStart: 1,
            columnEnd: 2
        }
    },
    STRETCH_ITEM_LAYOUT_WITH_MARGINS: {
        type: 'GridItemLayout',
        alignSelf: 'stretch',
        justifySelf: 'stretch',
        gridArea: {
            columnStart: 1,
            columnEnd: 2,
            rowEnd: 2,
            rowStart: 1
        },
        margins: {
            bottom: {type: 'px', value: 20},
            left: {type: 'percentage', value: 5},
            right: {type: 'percentage', value: 5},
            top: {type: 'px', value: 20}
        }
    },
    CENTER: {
        type: 'GridItemLayout',
        alignSelf: 'start',
        justifySelf: 'center',
        gridArea: {
            columnStart: 1,
            columnEnd: 2,
            rowStart: 1,
            rowEnd: 2
        }
    },
    NO_ITEM_LAYOUT: {},
    FIXED_ITEM_LAYOUT: {
        type: 'FixedItemLayout',
        alignSelf: 'start',
        justifySelf: 'end',
        margins: {
            top: {
                type: 'px',
                value: 0
            },
            right: {
                type: 'px',
                value: 0
            }
        }
    }
}

const CONTAINER_LAYOUT_TYPES = {
    FRACTION: {
        rows: [
            {
                type: 'fr',
                value: 1
            }
        ],
        columns: [
            {
                type: 'fr',
                value: 1
            }
        ],
        type: 'GridContainerLayout'
    },
    MIN_MAX_SIZE: {
        overflowX: 'clip',
        overflowY: 'clip',
        columns: [
            {
                max: {type: 'fr', value: 1},
                min: {type: 'px', value: 0},
                type: 'MinMaxSize'
            }
        ],
        rows: [{type: 'auto'}, {type: 'auto'}, {type: 'auto'}],
        type: 'GridContainerLayout'
    },
    NONE: {}
}

const COMPONENT_LAYOUT_TYPES = {
    HEIGHT_AUTO: {
        height: {type: 'auto'},
        type: 'ComponentLayout'
    },
    MIN_HEIGHT: {
        height: {type: 'auto'},
        minHeight: {type: 'px', value: 0},
        type: 'ComponentLayout'
    },
    HEIGHT_WIDTH_AUTO: {
        height: {type: 'auto'},
        width: {type: 'auto'},
        type: 'ComponentLayout'
    }
}

export {ITEM_LAYOUT_TYPES, CONTAINER_LAYOUT_TYPES, COMPONENT_LAYOUT_TYPES}
