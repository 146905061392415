import type {PossibleViewModes, PS, Logger} from '@wix/document-services-types'
import * as mobileCore from '@wix/mobile-conversion'
import * as santaCoreUtils from '@wix/santa-core-utils'
import experiment from 'experiment-amd'
import _ from 'lodash'
import constants from '../../constants/constants'
import features from '../../features/features'
import mobileUtilities from '../../mobileUtilities/mobileUtilities'
import originService from '../../platform/services/originService'
import componentReducer from './componentReducer'
import conversionSettings from './conversionSettings'

const shouldEnableImprovedMergeFlow = (ps: PS) =>
    isEditor2(ps) || (experiment.isOpen('dm_mobileAlgoImprovements') && shouldRunMerge(ps))

const isEditor2 = (ps: PS) => {
    const isSectionsEnabled = features.getFeatureData(
        ps,
        ps.pointers.components.getMasterPage(constants.VIEW_MODES.DESKTOP),
        'pageSections'
    )?.isSectionsEnabled

    return isSectionsEnabled
}

const shouldRunMerge = (ps: PS) => {
    const origin = originService.getOrigin()
    const isADI = _.get(origin, ['type']) === 'ADI'
    const isEditorX = !mobileUtilities.isMobileSupported(ps)
    return !isADI && !isEditorX
}

const getDeepStructureForPageInViewMode = (ps, pageId, viewMode) => {
    if (experiment.isOpen('dm_meshLayout')) {
        return ps.extensionAPI.page.getDeepStructureForPageInViewMode(pageId, viewMode)
    }

    const componentsMapPointer = ps.pointers.page.getComponentsMapPointer(pageId, viewMode)
    const compsMap = ps.dal.get(componentsMapPointer, true)
    return santaCoreUtils.mobileUtils.buildDeepStructure(compsMap[pageId], compsMap)
}

function prepareConversionForAccessibility(ps: PS, page) {
    page.mobileComponents = []
    const allComps = _.values(mobileCore.conversionUtils.getAllCompsInStructure(page)).concat(
        mobileCore.conversionUtils.isMasterPage(page) ? [] : [page]
    )
    _.forEach(allComps, component => {
        componentReducer.createConversionData(ps, component, page.id)
    })
    return page
}

function getComponentsOrderInStructure(
    ps: PS,
    pageId: string,
    viewMode: PossibleViewModes,
    logger: Logger
): Record<string, any[]> {
    const settings = conversionSettings.getConversionSettings(ps, {
        heuristicStrategy: 'accessibility',
        conversionType: 'FULL'
    })
    const getChildrenNaturalOrder = container => _.map(container.components, 'id')
    const getChildrenOrder = container =>
        _.get(container, ['conversionData', 'componentsOrder'], getChildrenNaturalOrder(container))
    const page = getDeepStructureForPageInViewMode(ps, pageId, viewMode)

    prepareConversionForAccessibility(ps, page)
    logger.interactionStarted(mobileCore.monitoring.INTERACTIONS.ANALYZE, {tags: {method: 'getComponentsOrder'}})
    mobileCore.conversion.inspectStructure(page, settings)
    logger.interactionEnded(mobileCore.monitoring.INTERACTIONS.ANALYZE)

    const componentsMap = mobileCore.conversionUtils.getAllCompsInStructure(page)
    const isVirtualGroup = (childId: string) => componentsMap[childId].componentType === 'RESCALE_VIRTUAL_GROUP'
    const order = _.reduce(
        componentsMap,
        (componentsOrderMap, component) => {
            if (!_.has(component, 'components') || isVirtualGroup(component.id)) {
                return componentsOrderMap
            }
            const childrenOrder = _.reduce(
                getChildrenOrder(component),
                (res, childId) =>
                    res.concat(isVirtualGroup(childId) ? getChildrenOrder(componentsMap[childId]) : [childId]),
                []
            )
            return _.set(componentsOrderMap, component.id, childrenOrder)
        },
        {}
    )

    return order
}

export {shouldEnableImprovedMergeFlow, getComponentsOrderInStructure, shouldRunMerge}
