import _ from 'lodash'
import * as conversionUtils from '../conversionUtils'
import * as mergeUtils from './mergeUtils'
import {preProcessStructureMinimal} from '../structurePreprocessor'
import {conversionConfig} from '../conversionConfig'
import {isMobileOnly} from '../mobileOnlyComponents/mobileOnlyComponentsUtils'
import {getChildren} from '../conversionUtils'
import type {ObjMap, DeepStructure} from '../../types'

function cutComponentChildren(components: DeepStructure[]): DeepStructure[] {
    return <DeepStructure[]>_.map(components, component => {
        const componentClone = _.cloneDeep(component)
        if (conversionUtils.getChildren(componentClone)) {
            componentClone.components = []
        }
        return componentClone
    })
}

function getComponentsByIdsFromStructure(componentsIds: string[], structure: DeepStructure): DeepStructure[] {
    return <DeepStructure[]>_(componentsIds)
        .map(compId => conversionUtils.getComponentByIdFromStructure(compId, structure))
        .compact()
        .value()
}

function convertComponentsIdsToComponentsTrees(componentsIds: string[], container: DeepStructure): DeepStructure[] {
    const components = getComponentsByIdsFromStructure(componentsIds, container)
    const flattenedComponents = cutComponentChildren(components)
    const componentForest = []
    _.forEach(flattenedComponents, flatComponent => {
        for (let j = 0; j < flattenedComponents.length; j++) {
            const potentialParentWithChildrenInfo = components[j]
            if (
                _.some(conversionUtils.getChildren(potentialParentWithChildrenInfo), {
                    id: flatComponent.id
                })
            ) {
                const potentialParent = flattenedComponents[j]
                conversionUtils.addComponentsTo(potentialParent, [flatComponent])
                return
            }
        }
        componentForest.push(flatComponent)
    })
    return componentForest
}

function findComponentsParents(desktopParentComponent: DeepStructure, mobilePage: DeepStructure, componentForestToBeAdded, parentsMap = {}) {
    const desktopChildren = conversionUtils.getChildren(desktopParentComponent)
    if (!desktopChildren) {
        return parentsMap
    }
    _.forEach(desktopChildren, (desktopChild: DeepStructure) => {
        findComponentsParents(desktopChild, mobilePage, componentForestToBeAdded, parentsMap)
        const componentToAdd = _(componentForestToBeAdded).remove({id: desktopChild.id}).head()
        if (_.isEmpty(componentToAdd)) {
            return
        }
        const mobileParent = conversionUtils.getComponentByIdFromStructure(desktopParentComponent.id, mobilePage) || mobilePage
        parentsMap[mobileParent.id] = parentsMap[mobileParent.id] || {
            componentsToAdd: [],
            parent: mobileParent
        }
        parentsMap[mobileParent.id].componentsToAdd.push(componentToAdd)
    })

    return parentsMap
}

function getDesktopComponentsOfPage(desktopPage: DeepStructure): ObjMap<DeepStructure> {
    return <ObjMap<DeepStructure>>conversionUtils.getAllCompsInStructure(desktopPage, false, _.negate(conversionUtils.isDesktopOnlyComponent))
}

function getMobileComponentsOfPage(mobilePage: DeepStructure): ObjMap<DeepStructure> {
    return conversionUtils.getAllCompsInStructure(mobilePage, false, _.negate(isMobileOnly))
}

function getComponentIdsToDelete(
    componentIdsShouldBeInMobile: string[],
    allDesktopComponents: ObjMap<DeepStructure>,
    allMobileComponents: ObjMap<DeepStructure>
): string[] {
    const currentMobileComponentIds = _.keys(allMobileComponents)
    const hiddenAndDeletedComponentIds = _.difference(currentMobileComponentIds, componentIdsShouldBeInMobile)
    const replacedDesktopComponentIds = mergeUtils.getReplacedCompIds(allDesktopComponents, allMobileComponents)

    return _.uniq([...hiddenAndDeletedComponentIds, ...replacedDesktopComponentIds])
}

function deleteComponents(component: DeepStructure, idsOfComponentsToDelete: string[]): void {
    if (_.isEmpty(idsOfComponentsToDelete)) {
        return
    }
    const children = <DeepStructure[]>conversionUtils.getChildren(component)
    if (!children) {
        return
    }
    const childrenIds = _.map(children, 'id')
    const idsOfComponentsToDeleteFromCurrentComponent = _.remove(idsOfComponentsToDelete, idToDelete => _.includes(childrenIds, idToDelete))
    const componentsToDeleteFromCurrentComponent = conversionUtils.getComponentsByIds(component, idsOfComponentsToDeleteFromCurrentComponent)
    if (componentsToDeleteFromCurrentComponent.length > 0) {
        conversionUtils.removeChildrenFrom(component, componentsToDeleteFromCurrentComponent)
    }
    _.forEach(children, child => deleteComponents(child, idsOfComponentsToDelete))
}

function containsComponent(compId: string, container: DeepStructure): boolean {
    return _.some(getChildren(container), (child: DeepStructure) => child.id === compId)
}

function getComponentParent(compId: string, compMap: ObjMap<DeepStructure>): DeepStructure {
    return _.find(compMap, container => containsComponent(compId, container))
}

function reorderAddedComponentsAccordingToDesktop(idsOfComponentsToAdd: string[], mobilePage: DeepStructure, desktopPage: DeepStructure): void {
    const mobileComps = <ObjMap<DeepStructure>>conversionUtils.getAllCompsInStructure(mobilePage)
    const desktopComps = <ObjMap<DeepStructure>>conversionUtils.getAllCompsInStructure(desktopPage)
    const compIdsToSort = _.filter(idsOfComponentsToAdd, compId => _.has(mobileComps, compId))
    _.forEach(compIdsToSort, compId => {
        const mobileParent = getComponentParent(compId, mobileComps)
        const desktopParent = getComponentParent(compId, desktopComps)
        if (mobileParent.id === desktopParent.id) {
            const mobileChildren = getChildren(mobileParent)
            const potentialOrder = _.filter(getChildren(desktopParent), comp => containsComponent(comp.id, mobileParent))
            const compIdToInsertBefore = _.get(potentialOrder, [_.findIndex(potentialOrder, {id: compId}) + 1, 'id'], null)
            if (compIdToInsertBefore) {
                const mobileCompToBeOrdered = _.remove(mobileChildren, {id: compId})
                mobileChildren.splice(_.findIndex(mobileChildren, {id: compIdToInsertBefore}), 0, _.head(mobileCompToBeOrdered))
            }
        }
    })
}

function addComponents(mobilePage, desktopPage, idsOfComponentsToAdd): void {
    if (_.isEmpty(idsOfComponentsToAdd)) {
        return
    }
    const componentTreesToBeAdded = convertComponentsIdsToComponentsTrees(idsOfComponentsToAdd, desktopPage)
    const componentTreesWithParents = findComponentsParents(desktopPage, mobilePage, componentTreesToBeAdded)
    _.forEach(componentTreesWithParents, (componentTreesWithParent: {componentsToAdd: DeepStructure[]; parent: DeepStructure}) => {
        const treesRoots = _.cloneDeep(componentTreesWithParent.componentsToAdd)
        const treesComponents = <DeepStructure[]>_.flatMap(treesRoots, root => _.values(conversionUtils.getAllCompsInStructure(root)))
        _.forEach(treesComponents, comp => {
            if (conversionUtils.shouldStretchToScreenWidth(comp)) {
                return _.set(comp, ['layout', 'width'], conversionConfig.MOBILE_WIDTH)
            }
            return _.set(comp, ['layout', 'width'], _.min([comp.layout.width, conversionConfig.MOBILE_WIDTH]))
        })
        conversionUtils.addComponentsTo(componentTreesWithParent.parent, treesRoots)
    })
    reorderAddedComponentsAccordingToDesktop(idsOfComponentsToAdd, mobilePage, desktopPage)
}

function getDescendantComponentsIds(ancestorIds: string[], page: DeepStructure): string[] {
    const ancestorComponents = getComponentsByIdsFromStructure(ancestorIds, page)
    return <string[]>_(ancestorComponents)
        .flatMap(comp => _.values(conversionUtils.getAllCompsInStructure(comp)))
        .map('id')
        .uniq()
        .difference(ancestorIds)
        .value()
}

function syncPage(desktopPage: DeepStructure, mobilePage: DeepStructure) {
    const desktopComponents = getDesktopComponentsOfPage(desktopPage)
    preProcessStructureMinimal(desktopPage, desktopComponents)
    const componentIdsShouldBeInMobile = _(desktopComponents).reject(['conversionData.mobileHints.hidden', true]).map('id').value()

    // remove from mobile all deleted from desktop, hidden components, and components with replaced ids
    const componentIdsToDelete = getComponentIdsToDelete(componentIdsShouldBeInMobile, desktopComponents, getMobileComponentsOfPage(mobilePage))
    deleteComponents(mobilePage, componentIdsToDelete)

    // remove from mobile descendants of components that are going to be re-added since descendants could be re-parented in previous mobile structure
    const componentIdsToAdd = _.difference(componentIdsShouldBeInMobile, _.keys(getMobileComponentsOfPage(mobilePage)))
    const descendantsOfComponentsToAdd = getDescendantComponentsIds(componentIdsToAdd, desktopPage)
    deleteComponents(mobilePage, descendantsOfComponentsToAdd)

    // add visible desktop components that are not in cleaned mobile structure
    const componentIdsToBeAddedIncludingDescendants = _.difference(componentIdsShouldBeInMobile, _.keys(getMobileComponentsOfPage(mobilePage)))
    addComponents(mobilePage, desktopPage, componentIdsToBeAddedIncludingDescendants)
}

const testAPI = {
    convertComponentsIdsToComponentsTrees,
    findComponentsParents,
    deleteComponents,
    addComponents,
    getComponentIdsToDelete
}

export {testAPI, syncPage}
