'use strict'

const _ = require('lodash')
const constants = require('../helpers/constants')
const {stripHashIfExists} = require('../helpers/dataUtils')
const {createPageManager} = require('../helpers/pageManager')
const {
    DATA_TYPES,
    NAMESPACE_MAPPING,
    DATA_TYPE_BY_COMP_DATA_QUERY,
    COMP_DATA_QUERY_KEYS_WITH_STYLE
} = require('@wix/document-services-json-schemas/dist/constants.json')

const HOVER = 'HOVER'
const DEFAULT = 'DEFAULT'
const CONTAINER_DEFAULT_STYLE = 'c1'
const ZERO_PX = {value: 0, unit: 'px'}
const ZERO_RGBA = {red: 0, green: 0, blue: 0, alpha: 0}
const HOVER_BOX_COMP_TYPE = 'wysiwyg.viewer.components.HoverBox'
const MEDIA_CONTAINER_DEFAULT_CSS_STYLE = {
    cssBorderRadius: {
        topLeft: ZERO_PX,
        topRight: ZERO_PX,
        bottomLeft: ZERO_PX,
        bottomRight: ZERO_PX
    },
    cssBorder: {
        width: {
            top: ZERO_PX,
            right: ZERO_PX,
            bottom: ZERO_PX,
            left: ZERO_PX
        },
        style: {
            top: 'solid',
            right: 'solid',
            left: 'solid',
            bottom: 'solid'
        },
        color: {
            top: ZERO_RGBA,
            right: ZERO_RGBA,
            left: ZERO_RGBA,
            bottom: ZERO_RGBA
        }
    }
}
const MEDIA_CONTAINER_CUSTOM_STYLE = {
    styleType: 'custom',
    componentClassName: 'wysiwyg.viewer.components.MediaContainer',
    style: {
        groups: {},
        properties: {},
        propertiesSource: {}
    },
    type: 'ComponentStyle',
    skin: 'wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer'
}
const COMP_DATA_QUERIES = COMP_DATA_QUERY_KEYS_WITH_STYLE
const NON_SPECIAL_COMP_QUERIES = _.omit(DATA_TYPE_BY_COMP_DATA_QUERY, ['behaviorQuery', 'connectionQuery', 'styleId'])

const migratePage = pageManager => {
    pageManager.data.verifyDataMapExists([
        NAMESPACE_MAPPING.effects,
        NAMESPACE_MAPPING.variants,
        NAMESPACE_MAPPING.transformations
    ])
    const {desktopComponents, mobileComponents} = pageManager.structure.getAllComponentsBasedOnType(HOVER_BOX_COMP_TYPE)

    const getId = prefix => pageManager.generateId(prefix)

    const removeQueriesFromCompStructure = (compStructure, queriesToRemove) => {
        queriesToRemove.forEach(query => delete compStructure[query])
    }

    const duplicateItem = (idToDuplicate, namespace, type, overrides) => {
        const duplicatedItem = _.cloneDeep(pageManager.data.getItem(namespace, idToDuplicate))
        return pageManager.data.addItem(namespace, type, {...duplicatedItem, ...overrides})
    }

    const linkQueryToComponent = (type, id, compStructure) => {
        compStructure[COMP_DATA_QUERY_KEYS_WITH_STYLE[type]] = constants.DATA_TYPES_WITH_HASH.includes(type)
            ? `#${id}`
            : id
    }

    const duplicateItemAndLinkToComponent = (compStructure, namespace, type, itemToDuplicateId, overrides) => {
        const newId = duplicateItem(stripHashIfExists(itemToDuplicateId), namespace, type, overrides)
        linkQueryToComponent(type, newId, compStructure)
        return newId
    }

    const addConnectionData = (comp, connectionIdToDuplicate) => {
        const newId = duplicateItem(
            connectionIdToDuplicate ?? comp[constants.CONNECTION_QUERY],
            NAMESPACE_MAPPING.connections,
            DATA_TYPES.connections
        )
        linkQueryToComponent(DATA_TYPES.connections, newId, comp)
        _.find(pageManager.data.getItem(NAMESPACE_MAPPING.connections, newId).items, item => {
            if (item.type === 'WixCodeConnectionItem') {
                item.role = getId(item.role)
                return true
            }
        })
    }

    const addTransformation = (compStructure, variantId, modeType) => {
        const defaultTransformId = pageManager.data.addItem(
            NAMESPACE_MAPPING.transformations,
            DATA_TYPES.transformations,
            {hidden: modeType === HOVER, type: constants.TRANSFORM_DATA_TYPE}
        )
        const transformInVariantId = pageManager.data.addItem(
            NAMESPACE_MAPPING.transformations,
            DATA_TYPES.transformations,
            {hidden: modeType === DEFAULT, type: constants.TRANSFORM_DATA_TYPE}
        )
        const variantRelationId = pageManager.data.addVariantRelation(
            NAMESPACE_MAPPING.transformations,
            DATA_TYPES.transformations,
            [variantId],
            transformInVariantId,
            compStructure.id
        )
        const refArrayId = pageManager.data.addRefArrayItem(
            NAMESPACE_MAPPING.transformations,
            DATA_TYPES.transformations,
            [defaultTransformId, variantRelationId]
        )
        pageManager.component.data.linkQueryToComponent(compStructure, DATA_TYPES.transformations, refArrayId)
    }

    const addMobileHints = (compStructure, value) => {
        duplicateItemAndLinkToComponent(
            compStructure,
            NAMESPACE_MAPPING.mobileHints,
            DATA_TYPES.mobileHints,
            compStructure[COMP_DATA_QUERIES[DATA_TYPES.mobileHints]],
            {...value, type: 'MobileHints'}
        )
    }

    const cleanAndUpdateMasterContainerStructure = compStructure => {
        removeQueriesFromCompStructure(compStructure, [
            'modes',
            constants.PROPS_QUERY,
            constants.DESIGN_QUERY,
            constants.BEHAVIOR_QUERY
        ])
        addMobileHints(compStructure, {type: 'MobileHints', modifiedByUser: true})
        compStructure[constants.STYLE_QUERY] = CONTAINER_DEFAULT_STYLE
        compStructure.componentType = constants.CONTAINER_COMP_TYPE
        compStructure.components = []
    }

    const createDesktopMasterContainer = compStructure => {
        cleanAndUpdateMasterContainerStructure(compStructure)
        addMobileHints(compStructure, {type: 'MobileHints', modifiedByUser: true})
        if (compStructure[constants.CONNECTION_QUERY]) {
            addConnectionData(compStructure)
        }
        return pageManager.data.addItem(NAMESPACE_MAPPING.variants, DATA_TYPES.variants, {
            type: 'Hover',
            componentId: compStructure.id
        })
    }

    const createMediaContainerDesignData = designId => {
        const oldDesignItem = pageManager.data.getItem(NAMESPACE_MAPPING.design, designId)
        const overrides = _.isEmpty(oldDesignItem.cssStyle) ? {cssStyle: MEDIA_CONTAINER_DEFAULT_CSS_STYLE} : undefined
        const newDesignQuery = duplicateItem(designId, NAMESPACE_MAPPING.design, DATA_TYPES.design, overrides)
        const newBackgroundId = duplicateItem(
            stripHashIfExists(pageManager.data.getItem(NAMESPACE_MAPPING.design, designId).background),
            NAMESPACE_MAPPING.design,
            DATA_TYPES.design
        )
        pageManager.data.updateItem(newDesignQuery, NAMESPACE_MAPPING.design, {background: `#${newBackgroundId}`})
        const oldMediaRefId = stripHashIfExists(
            pageManager.data.getItem(NAMESPACE_MAPPING.design, newBackgroundId).mediaRef
        )
        if (oldMediaRefId) {
            const newMediaRefId = duplicateItem(oldMediaRefId, NAMESPACE_MAPPING.design, DATA_TYPES.design)
            pageManager.data.updateItem(newBackgroundId, NAMESPACE_MAPPING.design, {mediaRef: `#${newMediaRefId}`})
        }
        return newDesignQuery
    }

    const createContainerPerMode = (oldStructure, parentRef, isMobile, modeIdToAdd, hoverVariantId) => {
        const {modes, designQuery} = oldStructure
        const {definitions, overrides} = modes
        const designIdToDuplicate = _.isEmpty(overrides)
            ? stripHashIfExists(designQuery)
            : stripHashIfExists(_.find(overrides, ({modeIds}) => _.some(modeIds, id => id === modeIdToAdd)).designQuery)
        const newDesignId = createMediaContainerDesignData(designIdToDuplicate)
        const styleId = pageManager.data.addItem(
            NAMESPACE_MAPPING.style,
            DATA_TYPES.theme,
            MEDIA_CONTAINER_CUSTOM_STYLE
        )
        const mediaContainerStructure = pageManager.component.addComponent(parentRef, {
            styleId,
            type: constants.CONTAINER_TYPE,
            designQuery: `#${newDesignId}`,
            componentType: constants.MEDIA_CONTAINER_COMP_TYPE,
            layout: {x: 0, y: 0, height: _.clone(parentRef.layout.height), width: _.clone(parentRef.layout.width)}
        })
        addMobileHints(mediaContainerStructure, {modifiedByUser: true, hidden: !isMobile})
        addConnectionData(mediaContainerStructure, oldStructure.connectionQuery)
        if (!isMobile) {
            addTransformation(
                mediaContainerStructure,
                hoverVariantId,
                _.find(definitions, ({modeId}) => modeId === modeIdToAdd).type
            )
        }
        return mediaContainerStructure
    }

    const prepare = (desktopHoverBox, mobileHoverBox) => {
        const desktopHoverBoxClone = _.cloneDeep(desktopHoverBox)
        const mobileHoverBoxClone = _.cloneDeep(mobileHoverBox)
        const {definitions} = desktopHoverBox.modes
        const defaultModeId = _.find(definitions, ({type}) => type === DEFAULT).modeId
        const hoverModeId = _.find(definitions, ({type}) => type === HOVER).modeId
        const hoverVariantId = createDesktopMasterContainer(desktopHoverBox)
        cleanAndUpdateMasterContainerStructure(mobileHoverBox)
        const defaultMediaContainer = createContainerPerMode(
            desktopHoverBoxClone,
            desktopHoverBox,
            false,
            defaultModeId,
            hoverVariantId
        )
        const hoverMediaContainer = createContainerPerMode(
            desktopHoverBoxClone,
            desktopHoverBox,
            false,
            hoverModeId,
            hoverVariantId
        )
        const mobileMediaContainer = createContainerPerMode(mobileHoverBoxClone, mobileHoverBox, true)
        return [
            {
                parentRef: defaultMediaContainer,
                oldStructure: desktopHoverBoxClone,
                modeId: defaultModeId,
                context: {
                    isHiddenByMobileHints: true
                }
            },
            {
                parentRef: hoverMediaContainer,
                oldStructure: _.cloneDeep(desktopHoverBoxClone),
                modeId: hoverModeId,
                context: {
                    isHiddenByMobileHints: true
                }
            },
            {
                parentRef: mobileMediaContainer,
                oldStructure: mobileHoverBoxClone,
                context: {
                    isHiddenByMobileHints: false
                }
            }
        ]
    }

    const applyOverrides = (comp, modeId) => {
        if (comp.modes && modeId) {
            const overridesInModeId = _.find(comp.modes.overrides, ({modeIds}) => modeIds.includes(modeId))
            if (!_.isEmpty(overridesInModeId)) {
                const apply = query => {
                    if (comp[query]) {
                        comp[query] = overridesInModeId[query] ?? comp[query]
                    }
                }
                ;[
                    constants.STYLE_QUERY,
                    constants.PROPS_QUERY,
                    constants.DESIGN_QUERY,
                    constants.ABSOLUTE_LAYOUT
                ].forEach(compQuery => apply(compQuery))
                comp.modes.isHiddenByModes = overridesInModeId.hasOwnProperty('isHiddenByModes')
                    ? overridesInModeId.isHiddenByModes
                    : comp.modes.isHiddenByModes
            }
            delete comp.modes.overrides
        }
    }

    const duplicateReferences = comp => {
        _.forEach(_.keys(comp), queryId => {
            if (NON_SPECIAL_COMP_QUERIES[queryId]) {
                const queryIdWithoutHash = stripHashIfExists(comp[queryId])
                const type = DATA_TYPE_BY_COMP_DATA_QUERY[queryId]
                const namespace = NAMESPACE_MAPPING[type]
                duplicateItemAndLinkToComponent(comp, namespace, type, queryIdWithoutHash)
            }
        })

        if (comp[constants.CONNECTION_QUERY]) {
            addConnectionData(comp)
        }

        const {styleId} = comp
        const styleItem = pageManager.data.getItem(NAMESPACE_MAPPING.style, styleId)
        if (styleItem && styleItem.styleType === 'custom') {
            duplicateItemAndLinkToComponent(comp, NAMESPACE_MAPPING.style, DATA_TYPES.theme, styleId)
        }
    }

    const migrateChildren = containerDescriptor => {
        const {parentRef, oldStructure, modeId, context} = containerDescriptor
        const childrenStack = [oldStructure]
        while (childrenStack.length > 0) {
            const comp = childrenStack.pop()
            delete comp.modes
            if (comp.components) {
                comp.components.forEach(child => {
                    delete child[constants.BEHAVIOR_QUERY]
                    applyOverrides(child, modeId)
                    duplicateReferences(child)
                    child.id = getId(constants.COMP_PREFIX)
                    addMobileHints(child, {modifiedByUser: true, hidden: context.isHiddenByMobileHints})
                })
                comp.components = comp.components.filter(({modes}) => !modes || !modes.isHiddenByModes)
                childrenStack.push(...comp.components)
            }
        }
        parentRef.components = oldStructure.components
    }

    _.forEach(desktopComponents, hoverBoxStructure => {
        const mobileHoverBoxStructure = mobileComponents[hoverBoxStructure.id]
        const containersDescriptors = prepare(hoverBoxStructure, mobileHoverBoxStructure)
        containersDescriptors.forEach(migrateChildren)
    })
}

module.exports = {
    name: 'migrateOldHoverBoxToNewFixer',
    version: 0,
    experimentalVersions: [{version: 1, experiment: 'dm_migrateOldHoverBoxToNewFixer'}],
    exec(pageJson, pageIdsArray, magicObject) {
        const {uniqueIdGenerator} = magicObject.dataFixerUtils

        if (!magicObject.isExperimentOpen('dm_migrateOldHoverBoxToNewFixer')) {
            return
        }

        const pageManager = createPageManager(pageJson, uniqueIdGenerator)
        migratePage(pageManager)
    }
}
