import type {DalPointers} from '../../../../types'
import type {CompStructure} from '@wix/document-services-types'
import {CONTAINER_LAYOUT_TYPES, ITEM_LAYOUT_TYPES} from '../defaultResponsiveLayoutDefinitions'

const getTpaSectionDefaultLayout = (dalPointers: DalPointers, compDefinition: CompStructure) => {
    const topDock = compDefinition?.layout?.docked?.top?.px
    const bottomDock = compDefinition?.layout?.docked?.bottom?.px

    return {
        COMPONENT_LAYOUT_TYPES: {
            type: 'ComponentLayout',
            width: {type: 'auto'},
            height: topDock === 0 && bottomDock === 0 ? {type: 'vh', value: 100} : {type: 'auto'}
        },
        CONTAINER_LAYOUT_TYPES: CONTAINER_LAYOUT_TYPES.NONE,
        ITEM_LAYOUT_TYPES: ITEM_LAYOUT_TYPES.STRETCH_ITEM_LAYOUT_WITH_MARGINS
    }
}

export const tpaSectionDefaultResponsiveLayout = {
    getLayout: getTpaSectionDefaultLayout
}
