import type {
    ViewerWithIdentity,
    ViewerManager,
    BoltViewerManager,
    ViewerManagerActions,
    ViewerManagerAdapterConfig
} from './types'
import type {ViewerAPI} from '@wix/viewer-manager-interface'
import _ from 'lodash'
import {createDAL} from './viewerManagerDal'
import {buildViewerSiteAPI} from './buildViewerSiteAPI'
import createPointers from './pointers/pointers'
import {createBoltAPIAdapter} from './boltApiAdapter'
import {createViewerApiTrace, ViewerApiCallsManager} from './debug/viewerInterfaceCallsTrace'
import type {Experiment} from '@wix/document-services-types'

const BOLT_VIEWER_NAME = 'bolt'

const buildViewerActions = (viewer: ViewerAPI): ViewerManagerActions =>
    _.defaults(viewer.actions, {
        runInBatch: _.noop
    })

// TODO: remove `Partial` when finished
const buildViewerManagerFromViewer = (
    viewer: ViewerAPI,
    viewerApiTrace: ViewerApiCallsManager,
    experimentInstance: Experiment,
    config?: ViewerManagerAdapterConfig
): Partial<ViewerManager> => ({
    dal: createDAL(viewer, viewerApiTrace, experimentInstance, config),
    pointers: createPointers(),
    viewerSiteAPI: buildViewerSiteAPI(viewer, config),
    actions: buildViewerActions(viewer),
    viewerConfig: viewer.viewerConfig,
    viewerApiTrace
})

const checkViewerIdentity = (viewer: any): viewer is ViewerWithIdentity => {
    const viewerWithIdentity = viewer as ViewerWithIdentity
    return (
        !!viewerWithIdentity &&
        _.isObject(viewerWithIdentity) &&
        'viewerConfig' in viewerWithIdentity &&
        _.isObject(viewerWithIdentity.viewerConfig) &&
        'viewerName' in viewerWithIdentity.viewerConfig
    )
}

export const createViewerManagerAdapter = (
    viewer: unknown,
    experimentInstance?: Experiment,
    config?: ViewerManagerAdapterConfig
): ViewerManager => {
    if (!checkViewerIdentity(viewer)) {
        throw new Error('Wrong params provided for viewer manager adapter creation')
    }
    const {viewerName} = viewer.viewerConfig
    // bolt have ViewerManager type almost implemented, {@see BoltViewerManager}
    if (BOLT_VIEWER_NAME === viewerName) {
        return createBoltAPIAdapter(viewer as BoltViewerManager)
    }

    const viewerApiTrace = createViewerApiTrace()
    return buildViewerManagerFromViewer(
        viewer as unknown as ViewerAPI,
        viewerApiTrace,
        experimentInstance!,
        config
    ) as ViewerManager
}
export * from './types'
