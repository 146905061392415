import fullFunctionalityConfig from './fullFunctionalityConfig'

const modulesList: string[] = [...fullFunctionalityConfig.modulesList, 'wix2xPublicAPI', 'mobileMigrationAPI']

export default {
    ...fullFunctionalityConfig,
    modulesList,
    supportMobile: false,
    disableAutoSave: true
}
