import _ from 'lodash'
import experiment from 'experiment-amd'
import constants from '../constants/constants'
import dataModel from '../dataModel/dataModel'
import {asArray} from '@wix/document-manager-utils'
import allowedGroups from './allowedGroupsByCompType'
import actionsEditorSchema from './actionsEditorSchema'
import behaviorsEditorSchema from './behaviorsEditorSchema'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'
import pageTransitionsEditorSchema from './pageTransitionsEditorSchema'
import componentStructureInfo from '../component/componentStructureInfo'
import type {
    AbstractComponent,
    ActionDef,
    BehaviorDef,
    BehaviorObject,
    Pointer,
    PS,
    SavedBehavior,
    ScrubAnimationDef,
    TransitionDef,
    TriggerVariant
} from '@wix/document-services-types'

const ACTION_PROPS_TO_COMPARE = ['sourceId', 'type', 'name']
const BEHAVIOR_PROPS_TO_COMPARE = ['targetId', 'type', 'name', 'part', 'viewMode']
const CODE_BEHAVIOR_NAMES = {runCode: true, runAppCode: true}
const CODE_BEHAVIOR_TYPE = 'widget'

/**
 * Structure of behaviors object that can be saved to a component:
 * @example
 * [
 *      {
 *          "action":"screenIn"
 *          "targetId":"Clprt0-wl2"
 *          "name":"SpinIn",
 *          "duration":"2.45",
 *          "delay":"1.60",
 *          "params":{"cycles":5,"direction":"cw"},
 *          "playOnce":true
 *      }
 * ]
 *
 * @typedef {Array<SavedBehavior>} SavedBehaviorsList
 * @property {String} actionName
 * @property {String} targetId
 */

const allowedBehaviorKeys = [
    'action',
    'targetId',
    'name',
    'duration',
    'delay',
    'params',
    'playOnce',
    'type',
    'viewMode'
]

// Static getters

/**
 * Names of available actions, sorted a-z
 */
function getActionNames(): string[] {
    return _.sortBy(_.keys(actionsEditorSchema.getSchema()))
}

/**
 * Return the definition containing settings and parameters of an action
 */
function getActionDefinition(ps: PS, actionName: string): ActionDef {
    if (_.has(actionsEditorSchema.getSchema(), actionName)) {
        // TODO: obviously that type is not always 'comp', needs to be finished
        return {
            type: 'comp',
            name: actionName
        }
    }
}

/**
 * Return the definition containing settings and parameters of a behavior
 */
function getBehaviorDefinition(ps: PS, behaviorName: string): BehaviorDef {
    const behavior = _.cloneDeep(_.find(behaviorsEditorSchema.getSchema(), {name: behaviorName}))
    // Need to set default value equals true for popup in mobile
    if (behaviorName === 'openPopup') {
        behavior.params.openInMobile = behavior.params.openInDesktop
    }
    return behavior
}

/**
 * Get names of behaviors.
 * Filter by optional compType and/or actionName
 * @param ps
 * @param {String|null} [compType]
 * @param [actionName]
 * @returns {string[]}
 */
function getBehaviorNames(ps: PS, compType?: string, actionName?: string): string[] {
    let behaviors = behaviorsEditorSchema.getSchema()

    if (compType) {
        const shortCompType: string = _.last(compType.split('.'))!
        const compTypeGroups = allowedGroups.getSchema()[shortCompType] || allowedGroups.getSchema().AllComponents
        behaviors = _.isEmpty(compTypeGroups)
            ? []
            : _.filter(behaviors, behavior => _(behavior.groups).difference(compTypeGroups).isEmpty())
    }

    if (actionName) {
        behaviors = _.filter(behaviors, behavior =>
            _(actionsEditorSchema.getSchema()[actionName].groups).difference(behavior.groups).isEmpty()
        )
    }

    return _(behaviors).map('name').sortBy().value()
}

/**
 * A wrapper for getBehaviorNames that returns false if the list of behaviors returns empty, and true otherwise
 * @param ps
 * @param compRef
 * @param [actionName]
 * @returns {Boolean}
 */
function isBehaviorable(ps: PS, compRef: AbstractComponent, actionName: string): boolean {
    const compType = componentStructureInfo.getType(ps, compRef)
    const hasBehaviors = !_.isEmpty(getBehaviorNames(ps, compType, actionName))
    return hasBehaviors
}

/**
 * for each element inside a repeater the behavior registration should be on the template id and not on the item id (the static behavior query is shared between the items)
 */
function getOriginalCompRef(ps: PS, actionSourceRef: Pointer): Pointer {
    if (displayedOnlyStructureUtil.isRepeatedComponent(actionSourceRef.id)) {
        const originalId = displayedOnlyStructureUtil.getRepeaterTemplateId(actionSourceRef.id)
        const pagePointer = ps.pointers.components.getPageOfComponent(actionSourceRef)
        const originalCompPointer = ps.pointers.full.components.getComponent(originalId, pagePointer)
        return originalCompPointer
    }
    return actionSourceRef
}

// Component Getters

/**
 * Returns the behaviors saved on a component structure
 */
function getComponentBehaviors(ps: PS, componentPointer: AbstractComponent): BehaviorObject[] | null {
    const compBehaviors = getBehaviors(ps, componentPointer)
    return _.isEmpty(compBehaviors) ? null : compBehaviors
}

function isMobileAndHasDesktopComponent(ps: PS, componentPointer: Pointer) {
    return (
        _.get(componentPointer, ['type']) === constants.VIEW_MODES.MOBILE &&
        ps.dal.isExist(toDesktopComponent(componentPointer))
    )
}

function toDesktopComponent(componentPointer: Pointer): Pointer {
    return _.defaults({type: constants.VIEW_MODES.DESKTOP}, componentPointer)
}

const validateIfCanUseScreenInBehaviors = (ps: PS, behaviorAction: string): void => {
    const {effects} = ps.extensionAPI
    if (behaviorAction === 'screenIn' && effects.usesNewAnimations()) {
        throw effects.createEffectsError(
            'The site uses effects - the new module for screenIn animations, so it cannot write or update screenIn using the old module - behaviors'
        )
    }
}

// Component Setters

/**
 * Set a behavior to a component structure,
 * will override any previous behavior with same name and action (one type of behavior per one type of action)
 * @param ps
 * @param {AbstractComponent} componentPointer
 * @param {SavedBehavior} behavior
 * @param {String} [actionName]
 */
function setComponentBehavior(
    ps: PS,
    componentPointer: AbstractComponent,
    behavior: SavedBehavior,
    actionName?: string
) {
    validateIfCanUseScreenInBehaviors(ps, behavior.action)
    if (actionName) {
        behavior.action = actionName
    }
    const compType = componentStructureInfo.getType(ps, componentPointer)
    const validation = validateBehavior(ps, behavior, compType)
    if (validation.type === 'error') {
        throw new Error(validation.message)
    }

    /*
     * Part of fix for https://jira.wixpress.com/browse/WEED-11138
     * TODO: convert old behaviors to real data structure and on the way kill the flag
     */
    if (behavior.action === 'screenIn') {
        _.set(behavior, ['params', 'doubleDelayFixed'], true)
    }

    if (isMobileAndHasDesktopComponent(ps, componentPointer)) {
        // if setting a mobile component's behavior, set it's desktop behavior first..
        setComponentBehavior(ps, toDesktopComponent(componentPointer), behavior, actionName)
    }

    let behaviors = getComponentBehaviors(ps, componentPointer) || []
    const isSameBehavior = _.partial(areBehaviorsEqual, behavior as any)
    behaviors = _.reject(behaviors, isSameBehavior) as any

    updateBehaviorsInDAL(ps, componentPointer, behaviors.concat(behavior as any) as any as SavedBehavior[])
}

function updateBehavior(
    ps: PS,
    actionSourceRef: Pointer,
    action: BehaviorObject['action'],
    behaviorTargetRef: Pointer,
    behavior: BehaviorDef
) {
    const compRef = getOriginalCompRef(ps, actionSourceRef)
    const existingBehaviors = getBehaviors(ps, compRef)
    const updatedBehaviorObject: BehaviorObject = {
        action: _.defaults({sourceId: compRef.id}, action),
        behavior: _.defaults({targetId: behaviorTargetRef.id}, behavior)
    }
    const isEqualToUpdatedBehaviorObject = _.partial(areBehaviorObjectsEqual, updatedBehaviorObject)
    const updatedBehaviors = _.reject(existingBehaviors, isEqualToUpdatedBehaviorObject).concat(updatedBehaviorObject)
    updateBehaviorsInDAL(ps, compRef, updatedBehaviors as any)
}

function isAnimationBehavior(behavior: BehaviorDef) {
    return _.get(behavior, ['type'], 'animation') === 'animation'
}

function isCodeBehavior(behavior: BehaviorDef) {
    const {type, name} = behavior || {}
    return type === CODE_BEHAVIOR_TYPE && _.has(CODE_BEHAVIOR_NAMES, name)
}

function areActionsEqual(action1: ActionDef, action2: ActionDef) {
    return _.isEqual(_.pick(action1, ACTION_PROPS_TO_COMPARE), _.pick(action2, ACTION_PROPS_TO_COMPARE))
}

function areBehaviorsEqual(behavior1: BehaviorDef, behavior2: BehaviorDef) {
    return _.isEqual(_.pick(behavior1, BEHAVIOR_PROPS_TO_COMPARE), _.pick(behavior2, BEHAVIOR_PROPS_TO_COMPARE))
}

function areBehaviorObjectsEqual(behaviorObj1: BehaviorObject, behaviorObj2: BehaviorObject) {
    return (
        areActionsEqual(behaviorObj1.action, behaviorObj2.action) &&
        areBehaviorsEqual(behaviorObj1.behavior, behaviorObj2.behavior)
    )
}

function removeBehavior(
    ps: PS,
    actionSourceRef: Pointer,
    action: ActionDef,
    behaviorTargetRef: Pointer,
    behavior?: BehaviorDef
) {
    const compRef = getOriginalCompRef(ps, actionSourceRef)
    const existingBehaviors = getBehaviors(ps, compRef)
    const behaviorObjectToRemove = {
        action: _.defaults({sourceId: compRef.id}, action),
        behavior: _.defaults({targetId: behaviorTargetRef.id}, behavior)
    }

    const shouldRemoveBehavior = _.partial(areBehaviorObjectsEqual, behaviorObjectToRemove)
    const updatedBehaviors = _.reject(existingBehaviors, shouldRemoveBehavior)

    if (updatedBehaviors.length !== existingBehaviors.length) {
        updateBehaviorsInDAL(ps, compRef, updatedBehaviors as any)
    }
}

function hasBehavior(
    ps: PS,
    actionSourceRef: Pointer,
    action: ActionDef,
    behaviorTargetRef: Pointer,
    behavior: BehaviorDef
) {
    const compRef = getOriginalCompRef(ps, actionSourceRef)
    const existingBehaviors = getBehaviors(ps, compRef)
    const behaviorObjectToSeek = {
        action: _(compRef && {sourceId: compRef.id})
            .defaults(action)
            .pick(ACTION_PROPS_TO_COMPARE)
            .value(),
        behavior: _(behaviorTargetRef && {targetId: behaviorTargetRef.id})
            .defaults(behavior)
            .pick(BEHAVIOR_PROPS_TO_COMPARE)
            .value()
    }

    return _.some(existingBehaviors, behaviorObjectToSeek)
}

function getBehaviors(ps: PS, actionSourceRef: Pointer): BehaviorObject[] {
    if (experiment.isOpen('dm_useExtensionGetBehaviors')) {
        return ps.extensionAPI.actionsAndBehaviors.getBehaviors()
    }
    const compRef = getOriginalCompRef(ps, actionSourceRef)
    const behaviors = dataModel.getBehaviorsItem(ps, compRef)
    return behaviors ? JSON.parse(behaviors) : []
}

function updateBehaviorsInDAL(ps: PS, componentPointer: Pointer, behaviors: SavedBehavior[]) {
    const behaviorsToSet = JSON.stringify(behaviors)
    if (_.isEmpty(behaviors)) {
        dataModel.removeBehaviorsItem(ps, componentPointer)
    } else {
        dataModel.updateBehaviorsItem(ps, componentPointer, behaviorsToSet)
    }
}

/**
 * Remove a single behavior from a component structure
 * @param ps
 * @param {AbstractComponent} componentPointer
 * @param {SavedBehavior|String} [behavior]
 * @param {String} [actionName]
 * @deprecated
 */
function removeComponentSingleBehavior(
    ps: PS,
    componentPointer: Pointer,
    behavior: SavedBehavior | string,
    actionName: string
) {
    if (isMobileAndHasDesktopComponent(ps, componentPointer)) {
        // if removing a mobile component's behavior explicitly, remove the desktop behavior first..
        removeComponentSingleBehavior(ps, toDesktopComponent(componentPointer), behavior, actionName)
    }

    let behaviors = getComponentBehaviors(ps, componentPointer)
    let toRemove: any = {
        action: actionName
    }

    if (_.isString(behavior)) {
        toRemove.name = behavior
    } else if (_.isObject(behavior)) {
        toRemove = _.assign(toRemove, behavior)
    }
    behaviors = behaviors ? _.reject(behaviors, toRemove) : []
    updateBehaviorsInDAL(ps, componentPointer, behaviors as any)
}

/**
 * Remove behaviors from a component structure
 */
function removeComponentBehaviors(ps: PS, componentPointer: AbstractComponent) {
    updateBehaviorsInDAL(ps, componentPointer, [])
}

/**
 * @param ps
 * @param componentPointer
 * @param filterObject this will be used to filter  behaviors to remove
 */
function removeComponentsBehaviorsWithFilter(ps: PS, componentPointer: AbstractComponent, filterObject) {
    if (isMobileAndHasDesktopComponent(ps, componentPointer)) {
        // if removing a mobile component's behaviors explicitly, remove the desktop behaviors first..
        removeComponentsBehaviorsWithFilter(ps, toDesktopComponent(componentPointer), filterObject)
    }
    let behaviors: SavedBehavior[] = getComponentBehaviors(ps, componentPointer) as any
    behaviors = behaviors ? (_.reject(behaviors, filterObject) as SavedBehavior[]) : []
    updateBehaviorsInDAL(ps, componentPointer, behaviors)
}

// Page

function getPageGroupTransitionsPointer(ps: PS, viewMode: string) {
    const page = ps.pointers.components.getMasterPage(viewMode)
    const pageGroupPointer = ps.pointers.components.getComponent(constants.COMP_IDS.PAGE_GROUP, page)
    const propertiesPointer = dataModel.getPropertyItemPointer(ps, pageGroupPointer)
    return ps.pointers.getInnerPointer(propertiesPointer, 'transition')
}

/**
 * Set the pages transition
 * @param ps
 * @param transitionName
 */
function setPagesTransition(ps: PS, transitionName: string) {
    if (!_.includes(getPageTransitionsNames(), transitionName)) {
        throw new Error(`No such transition ${transitionName}`)
    }
    const transitionPointer = getPageGroupTransitionsPointer(ps, constants.VIEW_MODES.DESKTOP)
    ps.dal.set(transitionPointer, transitionName)
}

/**
 * Get the current pages transition
 */
function getPagesTransition(ps: PS): string {
    const transitionPointer = getPageGroupTransitionsPointer(ps, constants.VIEW_MODES.DESKTOP)
    return ps.dal.get(transitionPointer)
}

/**
 * Returns the names of *legacy* transitions sorted a-z
 * @todo: this will change when we will have transition per page
 * @returns {string[]}
 */
function getPageTransitionsNames(): string[] {
    return _.sortBy(_.map(pageTransitionsEditorSchema.getSchema(), 'legacyName'))
}

// Preview

/**
 * Trigger an action
 */
function executeAction(ps: PS, actionName: string) {
    ps.siteAPI.executeAction(actionName)
}

/**
 * Stop all animations
 */
function stopAndClearAllAnimations(ps: PS) {
    ps.siteAPI.stopAndClearAllAnimations()
}

/**
 * @deprecated
 */
function deprecatedPreviewAnimation(
    ps: PS,
    componentReference: Pointer,
    animationDef: TransitionDef,
    transformationsToRestore: unknown,
    onComplete: Function
) {
    return previewAnimation(ps, componentReference, animationDef, onComplete)
}

/**
 * Preview an animation on a component
 * @param ps
 * @param componentReference
 * @param animationDef
 * @param onComplete a callback to run at the end of the preview animation
 * @returns sequence id (to be used with stopPreview)
 */
function previewAnimation(
    ps: PS,
    componentReference: AbstractComponent,
    animationDef: TransitionDef,
    onComplete: Function
): string {
    const componentPointers = ps.pointers.components
    return ps.siteAPI.previewAnimation(
        componentReference,
        componentPointers.getPageOfComponent(componentReference).id,
        animationDef,
        onComplete
    )
}

/**
 * Preview a transition on 2 or more components
 * @param ps
 * @param srcCompReference (or an array)
 * @param targetCompReference (or an array)
 * @param transitionDef
 * @param onComplete a callback to run at the end of the preview animation
 * @returns sequence id (to be used with stopPreview)
 */
function previewTransition(
    ps: PS,
    srcCompReference: AbstractComponent | AbstractComponent[],
    targetCompReference: AbstractComponent | AbstractComponent[],
    transitionDef: TransitionDef,
    onComplete: Function
): string {
    const componentPointers = ps.pointers.components
    const srcRefs = asArray(srcCompReference)
    const targetRefs = asArray(targetCompReference)
    const firstCompRef = srcRefs.length > 0 ? srcRefs[0] : targetRefs[0]
    return ps.siteAPI.previewTransition(
        _.map(srcRefs, 'id'),
        _.map(targetRefs, 'id'),
        componentPointers.getPageOfComponent(firstCompRef).id,
        transitionDef,
        onComplete
    )
}

/**
 * @deprecated
 */
function deprecatedStopPreviewAnimation(ps: PS, componentReference: unknown, sequenceId: string) {
    stopPreviewAnimation(ps, sequenceId)
}

/**
 * Stop animation preview by sequence id returned by previewAnimation
 * @param {ps} ps
 * @param {string} sequenceId
 * @param [seekTo]
 */
function stopPreviewAnimation(ps: PS, sequenceId: string, seekTo?: number) {
    ps.siteAPI.stopPreviewAnimation(sequenceId, seekTo)
}

/**
 * Preview scrub animations on stage by animations and corresponding triggers
 * @member documentServices.components.behaviors
 * @param {ps} ps
 * @param {Record<string, ScrubAnimationDef>}  animations
 * @param {Record<string, TriggerVariant>} triggers
 */
function previewScrubAnimations(
    ps: PS,
    animations: Record<string, ScrubAnimationDef>,
    triggers: Record<string, TriggerVariant>
) {
    ps.siteAPI.previewScrubAnimations(animations, triggers)
}
/**
 * Update preview of current scrub animations on stage by animations and corresponding triggers
 * @member documentServices.components.behaviors
 * @param {ps} ps
 * @param {Record<string, ScrubAnimationDef>}  animations
 * @param {Record<string, TriggerVariant>} triggers
 */
function updateScrubAnimationsPreview(
    ps: PS,
    animations: Record<string, ScrubAnimationDef>,
    triggers: Record<string, TriggerVariant>
) {
    ps.siteAPI.updateScrubAnimationsPreview(animations, triggers)
}
/**
 * Stop preview of all scrub animations on stage
 * @member documentServices.components.behaviors
 * @param {ps} ps
 * @param {Function} [onComplete]
 */
function stopPreviewScrubAnimations(ps: PS, onComplete?: () => void) {
    ps.siteAPI.stopPreviewScrubAnimations(onComplete)
}

// Privates

/**
 * Validate a behavior structure.
 * @example
 *
 *      {
 *          "action":"screenIn"
 *          "targetId":"Clprt0-wl2"
 *          "name":"SpinIn",
 *          "duration":"2.45",
 *          "delay":"1.60",
 *          "params":{"cycles":5,"direction":"cw"},
 *          "playOnce":true
 *      }
 *
 *
 * @param ps
 * @param {SavedBehavior} behavior
 * @param compType
 */
function validateBehavior(ps: PS, behavior: SavedBehavior, compType: string) {
    const message = {
        type: 'ok',
        message: ''
    }

    const compTypeBehaviors = getBehaviorNames(ps, compType)

    if (!_.includes(compTypeBehaviors, behavior.name)) {
        message.type = 'error'
        message.message += `Behavior of type ${behavior.name} is not allowed on component of type ${compType}`
    } else if (!_.isPlainObject(behavior)) {
        // Check if action is an object
        message.type = 'error'
        message.message += 'Each behavior should be an object\n'
    } else if (_.isEmpty(behavior)) {
        message.type = 'error'
        message.message += 'Behavior can not be empty\n'
    } else {
        const actionNames = getActionNames()
        if (!_.includes(actionNames, behavior.action)) {
            message.type = 'error'
            message.message += `Action of type ${behavior.action} does not exist\n`
        }

        const actionBehaviors = getBehaviorNames(ps, null, behavior.action)
        if (!_.includes(actionBehaviors, behavior.name)) {
            message.type = 'error'
            message.message += `Behavior of type ${behavior.name} is not allowed on action ${behavior.action}`
        }
        if (
            isAnimationBehavior(behavior as any) &&
            _.isNaN(Number(behavior.duration)) &&
            _.isNaN(Number(behavior.params.duration))
        ) {
            message.type = 'error'
            message.message += 'Animation duration must be a number\n'
        }

        if (
            isAnimationBehavior(behavior as any) &&
            _.isNaN(Number(behavior.delay)) &&
            _.isNaN(Number(behavior.params.delay))
        ) {
            message.type = 'error'
            message.message += 'Animation delay must be a number\n'
        }

        if (
            behavior.viewMode &&
            (!_.isString(behavior.viewMode) || !_.includes(constants.VIEW_MODES, behavior.viewMode))
        ) {
            message.type = 'error'
            message.message += "Behavior viewMode must be undefined or a string: 'DESKTOP', 'MOBILE' or 'BOTH' \n"
        }

        if (behavior.params && !_.isPlainObject(behavior.params)) {
            message.type = 'error'
            message.message += 'Animation params property are optional, but if they exist params must be an object\n'
        }

        if (behavior.playOnce && !_.isBoolean(behavior.playOnce)) {
            message.type = 'error'
            message.message += 'Animation playOnce property is optional, but if is exists playOnce must be a boolean\n'
        }

        const redundantKeys = _.difference(_.uniq(_.keys(behavior).concat(allowedBehaviorKeys)), allowedBehaviorKeys)
        if (!_.isEmpty(redundantKeys)) {
            message.type = 'error'
            message.message += `The keys [${redundantKeys}] are not allowed values of a Behavior`
        }
    }

    return message
}

function executeAnimationsInPage(ps: PS) {
    ps.siteAPI.reloadPageAnimations()
}

export default {
    // Static lists
    isBehaviorable,
    isCodeBehavior,
    getActionNames,
    getBehaviorNames,
    getActionDefinition,
    getBehaviorDefinition,

    // Component getters
    getComponentBehaviors,

    // component setters
    setComponentBehavior,
    removeComponentBehaviors,
    removeComponentsBehaviorsWithFilter,
    removeComponentSingleBehavior,

    hasBehavior,
    getBehaviors,
    updateBehavior,
    removeBehavior,

    // Page transitions
    getPagesTransition,
    setPagesTransition,
    getPageTransitionsNames,

    // Preview
    executeAction,
    previewAnimation,
    previewTransition,
    stopPreviewAnimation,
    previewScrubAnimations,
    executeAnimationsInPage,
    stopAndClearAllAnimations,
    stopPreviewScrubAnimations,
    updateScrubAnimationsPreview,
    deprecatedPreviewAnimation,
    deprecatedStopPreviewAnimation
}
