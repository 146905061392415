import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import constants from '../constants/constants'
import appStudioDataModel from './appStudioDataModel'
import features from '../features/features'
import component from '../component/component'
import nickname from './nickname'

const STAGE_DATA = ['gfpp', 'behavior', 'displayName']

const hasLabel = button => !!button?.label

const convertTranslatableLabelIfNeeded = (button, buttonPath, translationPathsAccumulator) => {
    if (hasLabel(button)) {
        if (button.label.shouldTranslate) {
            translationPathsAccumulator.push(`${buttonPath}.label`)
        }
        return {...button, label: button.label.value}
    }

    return button
}

const getTranslatedGfppData = (gfppData, basePath) => {
    const gfppTranslationPaths = []
    const translatedGfppData: any = {
        desktop: {}
    }
    if (gfppData.desktop?.mainAction1) {
        translatedGfppData.desktop.mainAction1 = convertTranslatableLabelIfNeeded(
            gfppData.desktop.mainAction1,
            `${basePath}.gfpp.desktop.mainAction1`,
            gfppTranslationPaths
        )
    }
    if (gfppData.desktop?.mainAction2) {
        translatedGfppData.desktop.mainAction2 = convertTranslatableLabelIfNeeded(
            gfppData.desktop.mainAction2,
            `${basePath}.gfpp.desktop.mainAction2`,
            gfppTranslationPaths
        )
    }
    if (gfppData.desktop?.iconButtons) {
        translatedGfppData.desktop.iconButtons = gfppData.desktop.iconButtons
    }
    return {translatedGfppData, gfppTranslationPaths}
}

const applyGfppToMobile = (translatedGfppData, gfppTranslationPaths) => {
    translatedGfppData.mobile = _.cloneDeep(translatedGfppData.desktop)
    const mobileTranslations = gfppTranslationPaths.map(path => path.replace('gfpp.desktop', 'gfpp.mobile'))
    return {
        gfppWithMobile: translatedGfppData,
        translationPathsWithMobile: gfppTranslationPaths.concat(mobileTranslations)
    }
}

const getWidgetStageDataWithTranslationPaths = (stageData, basePath) => {
    const translationPaths = []
    const newStageData: any = {}
    if (stageData.displayName) {
        if (stageData.displayName.shouldTranslate) {
            translationPaths.push(`${basePath}.displayName`)
        }
        newStageData.displayName = stageData.displayName.value
    }
    if (stageData.gfpp) {
        const {translatedGfppData, gfppTranslationPaths} = getTranslatedGfppData(stageData.gfpp, basePath)
        const {gfppWithMobile, translationPathsWithMobile} = applyGfppToMobile(translatedGfppData, gfppTranslationPaths)
        newStageData.gfpp = gfppWithMobile
        translationPaths.push(...translationPathsWithMobile)
    }
    if (stageData.behavior) {
        newStageData.behavior = stageData.behavior
    }
    return {
        stageData: newStageData,
        translationPaths
    }
}

const getInnerCompsStageData = (ps: PS, rootAppWidgetRef) => {
    const translationPaths = []
    const widgetComps = component.getChildrenFromFull(ps, rootAppWidgetRef, true)
    const connections = _.reduce(
        widgetComps,
        (result, compRef) => {
            const manifestData = features.getFeatureData(ps, compRef, 'manifest')
            if (manifestData) {
                const role = nickname.get(ps, compRef)
                const {stageData, translationPaths: compTranslationPaths} = getWidgetStageDataWithTranslationPaths(
                    _.pick(manifestData, STAGE_DATA),
                    `default.connections.${role}`
                )
                result[role] = stageData
                translationPaths.push(...compTranslationPaths)
            }
            return result
        },
        {}
    )
    return {
        connections,
        translationPaths
    }
}

const getWidgetManifest = (ps: PS, widgetPointer: Pointer) => {
    const widgetData = appStudioDataModel.getData(ps, widgetPointer)
    const pageRef = ps.pointers.components.getPage(
        _.replace(widgetData.rootCompId, '#', ''),
        constants.VIEW_MODES.DESKTOP
    )
    const rootAppWidgetRef = appStudioDataModel.getRootAppWidgetByPage(ps, pageRef)
    const manifestData = features.getFeatureData(ps, rootAppWidgetRef, 'manifest')
    const {stageData: widgetStageData, translationPaths: widgetTranslationPaths} =
        getWidgetStageDataWithTranslationPaths(_.pick(manifestData, STAGE_DATA), 'default')
    const {connections, translationPaths: connectionsTranslationPaths} = getInnerCompsStageData(ps, rootAppWidgetRef)

    const widgetConnections = {}
    const widgetConnectionsTranslationPaths: string[] = []

    if (manifestData?.connections) {
        Object.entries(manifestData.connections).forEach(([role, manifestStageData]) => {
            const {stageData, translationPaths} = getWidgetStageDataWithTranslationPaths(
                manifestStageData,
                `default.connections.${role}`
            )
            widgetConnections[role] = stageData
            widgetConnectionsTranslationPaths.push(...translationPaths)
        })
    }

    if (!_.isEmpty(widgetConnections) || !_.isEmpty(widgetStageData) || !_.isEmpty(connections)) {
        return {
            manifest: {
                default: {
                    ...widgetStageData,
                    connections: {
                        ...widgetConnections,
                        ...connections
                    }
                }
            },
            translationPaths: [
                ...widgetConnectionsTranslationPaths,
                ...widgetTranslationPaths,
                ...connectionsTranslationPaths
            ]
        }
    }
}

export default {
    getWidgetManifest
}
