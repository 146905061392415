import variantThemeUtils from '../../utils/variantThemeUtils'
import type {PS} from '@wix/document-services-types'

export default {
    componentsClasses: [
        'wysiwyg.viewer.components.svgshape.SvgShape',
        'wysiwyg.viewer.components.PopupCloseIconButton'
    ],
    collect(ps: PS, comp) {
        const styles = []

        variantThemeUtils.applyToEachComponentStyleAndReference(ps, comp, style => styles.push(style.id))

        return styles
    }
}
