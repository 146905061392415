import type {CompStructure} from '@wix/document-services-types'

const getPageStructure = (pageId: string, structureType: string, structureOverride: Partial<CompStructure> = {}) => ({
    id: pageId,
    type: 'Page',
    componentType: structureType,
    skin: 'wysiwyg.viewer.skins.page.BasicPageSkin',
    styleId: 'p2',
    components: [] as any,
    metaData: {pageId},
    dataQuery: `#${pageId}`,
    ...structureOverride
})
export {getPageStructure}
