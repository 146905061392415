import {ReportableError} from '@wix/document-manager-utils'
import type {MetaSiteClientSpecEntry} from '@wix/document-services-types'
import _ from 'lodash'
import {AuthorizationInstance, AuthorizationStatus, TestNonExpiringAuthorizationStatus} from './clientSpecMap'

export interface AuthorizationStatusMap {
    isExpired(appId: string): boolean
    getInstance(appId: string): string
    get(appId: string): AuthorizationStatus
}

export class AuthorizationMap implements AuthorizationStatusMap {
    constructor(private appIdToAuthorizationStatus: Record<string, AuthorizationStatus> = {}) {}

    isExpired(appId: string): boolean {
        const app = this.get(appId)
        if (app) {
            return app.isExpired()
        }
        throw new ReportableError({
            errorType: 'InvalidAuthorizationInstance',
            message: 'InvalidAuthorizationInstance',
            extras: {appId}
        })
    }

    build(refreshedClientSpecMap: Record<string, MetaSiteClientSpecEntry>) {
        this.appIdToAuthorizationStatus = _.mapValues(refreshedClientSpecMap, (clientSpecMapEntry, appId: string) => {
            const {metaSiteId, expirationDate, instance} = clientSpecMapEntry as MetaSiteClientSpecEntry
            return new AuthorizationInstance(instance, expirationDate!, metaSiteId, appId)
        })
    }

    getInstance(appId: string): string {
        return this.get(appId).instance
    }

    get(appId: string): AuthorizationStatus {
        if (appId in this.appIdToAuthorizationStatus) {
            return this.appIdToAuthorizationStatus[appId]
        }
        throw new ReportableError({
            errorType: 'InvalidAuthorizationInstance',
            message: 'InvalidAuthorizationInstance',
            extras: {appId}
        })
    }
}

export class InvalidAuthorizationMap implements AuthorizationStatusMap {
    constructor() {}

    isExpired(): boolean {
        return true
    }

    get metaSiteId(): string {
        throw new Error('Mocked AuthorizationMap - metaSiteId')
    }

    getInstance(): string {
        throw new Error('Mocked AuthorizationMap - getInstance')
    }

    get(): AuthorizationInstance {
        throw new Error('Mocked AuthorizationMap - get')
    }
}

export class TestNonExpiringAuthorizationMap implements AuthorizationStatusMap {
    get(): AuthorizationStatus {
        return new TestNonExpiringAuthorizationStatus()
    }

    getInstance(): string {
        return ''
    }

    isExpired(): boolean {
        return false
    }
}

export const csmToAuthorizationMap = (
    refreshedClientSpecMap: Record<string, MetaSiteClientSpecEntry>
): AuthorizationMap => {
    const authorizationMap = new AuthorizationMap()
    authorizationMap.build(refreshedClientSpecMap)
    return authorizationMap
}
