import _ from 'lodash'
import type {CreateExtArgs} from '@wix/document-manager-core'
import {getBlocksDataShallow, getDeepDataFromMaster} from './blocksDataModel'
import type {BlocksDashboardData} from '@wix/document-services-types'

export const getDashboardData = (createExtArgs: CreateExtArgs, dashboardId: string): BlocksDashboardData => {
    const dashboardData = getDeepDataFromMaster<BlocksDashboardData>(createExtArgs, dashboardId)
    return {
        id: dashboardData.id,
        rootCompId: _.trimStart(dashboardData.rootCompId, '#')
    }
}

export function getAllDashboardIds(createExtArgs: CreateExtArgs): string[] {
    return getBlocksDataShallow(createExtArgs)?.dashboards ?? []
}

export function getAllDashboards(createExtArgs: CreateExtArgs): BlocksDashboardData[] {
    const dashboardIds = getAllDashboardIds(createExtArgs)

    return dashboardIds.map(dashboardId => getDashboardData(createExtArgs, dashboardId))
}
