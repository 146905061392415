'use strict'

const fixPageData = require('./core/fixPageDataWrapper')
const deprecatedSiteModelMigrater = require('./plugins/deprecatedSiteModelMigrater')
const imageTransformDataFixers = require('./imageService/imageTransformDataFixers')
const anchorCyclesHelper = require('./helpers/anchorCyclesHelper')
const config = require('./core/config.json')
const _ = require('lodash')

const fixerVersioningConfig = _.pick(config, ['lastFixerIndex', 'fixers'])

module.exports = {
    fix: fixPageData.fixPageData,
    getFixerVersioningConfig: () => fixerVersioningConfig,
    deprecatedSiteModelMigrater,
    imageTransformDataFixers,
    anchorCyclesHelper
}
