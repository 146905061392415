import type {Callback, Pointer, PS} from '@wix/document-services-types'

const onComponentsScroll = (ps: PS, callback: Callback) => ps.siteAPI.setOnComponentsScrollListener(callback)

const scroll = (ps: PS, componentPointer: Pointer, x: number, y: number, callback: Function) =>
    ps.siteAPI.scroll(componentPointer, x, y, callback)

const scrollBy = (ps: PS, componentPointer: Pointer, x: number, y: number, callback: Function) =>
    ps.siteAPI.scrollBy(componentPointer, x, y, callback)

const getTotalScrolls = (ps: PS, componentPointer: Pointer) => ps.siteAPI.getTotalScroll(componentPointer)

export default {
    onComponentsScroll,
    scroll,
    scrollBy,
    getTotalScrolls
}
