/* eslint-disable promise/prefer-await-to-then,@typescript-eslint/naming-convention */
import type {SnapshotDal} from '@wix/document-manager-core'
import type {DocumentServicesDal, PS} from '@wix/document-services-types'
import * as santaCoreUtils from '@wix/santa-core-utils'
import _ from 'lodash'
import type {BICallbacks, SaveOptions} from '../createSaveAPI'
import monitoring from '../monitoring'
import type {SaveResult} from '../saveTasks/saveDocumentBase'
import {TaskQueue} from '../TaskQueue'
import type {SaveTaskDefinition, SaveTaskRegistryResult} from './registry'

const queue = new TaskQueue()

class PrimaryTaskError extends Error {
    public reason: {document: any}

    constructor(reason) {
        super('Document save has failed')
        this.name = 'PrimaryTaskError'
        this.reason = {document: reason}
    }
}

class SecondaryTasksError extends Error {
    public reason: any

    constructor(errorMap) {
        super('One or more Secondary save tasks have failed')
        this.name = 'SecondarySaveError'
        this.reason = errorMap
    }
}

class RequiredTasksError extends Error {
    public reason: any

    constructor(errorMap) {
        super('One or more required save tasks have failed')
        this.name = 'RequiredTaskSaveError'
        this.reason = errorMap
    }
}

function getFedopsInteractionName(methodName: string, taskName?: string) {
    return taskName ? `SaveTasks_${methodName}_${taskName}` : `SaveTasks_${methodName}`
}

const executeTaskAsync = (task: SaveTask, biCallbacks: BICallbacks, options: SaveOptions, extraArgs) =>
    new Promise<any>(function (resolve, reject) {
        const args = task.args().concat([resolve, reject, biCallbacks, options], extraArgs)
        task.execute.apply(null, args)
    })

/**
 * @description executes the save task and returns a promise
 * @param task
 * @param {Object} biCallbacks
 * @param {Object} options
 * @returns {Promise} a Promise that the task will be executed. Rejects on save failure.
 */
async function executeTask(task: SaveTask, biCallbacks: BICallbacks, options: SaveOptions): Promise<any> {
    const {name: taskName, methodName} = task
    const fedopsInteractionName = getFedopsInteractionName(methodName, taskName)

    try {
        const extraArgs = task.extraArgs ? task.extraArgs() : []
        monitoring.start(fedopsInteractionName)
        const result = await executeTaskAsync(task, biCallbacks, options, extraArgs)
        monitoring.end(fedopsInteractionName)
        if (task.onTaskSuccess) {
            return task.onTaskSuccess(result)
        }
        return result
    } catch (e: any) {
        monitoring.error(
            e,
            {errorDescription: e},
            {
                saveTaskError: true,
                methodName,
                taskName,
                ds_csave: task.ps.extensionAPI.continuousSave.isCSaveOpen()
            }
        )
        task.onTaskFailure?.(e)
        throw e
    }
}

async function attempt(promise: Promise<void>, name: string) {
    try {
        await promise
    } catch (e) {
        return {error: true, name, result: e}
    }
}

/**
 * @param {Object.<string, *>} tasks save tasks map
 * @param {Object} biCallbacks
 * @param {Object} options
 * @param {function} TasksError
 * @returns {Promise} a promise that all the tasks in the map will be executed. If the promise is rejected, it is rejected with a map of the rejection reasons of the task map.
 */
async function executeTaskMap(tasks: SaveTask[], biCallbacks: BICallbacks, options: SaveOptions, TasksError) {
    const promiseMap = _(tasks)
        .keyBy('name')
        .mapValues(task => attempt(executeTask(task, biCallbacks, options), task.name))
        .value()

    const resultsArr = await Promise.all(_.values(promiseMap))
    const result = _(resultsArr)
        .filter(finishedPromise => !!finishedPromise?.error)
        .keyBy('name')
        .mapValues('result')
        .value()

    if (!_.isEmpty(result)) {
        throw new TasksError(result)
    }
    return promiseMap
}

const onTaskSuccess = (task: BaseTask, dal: DocumentServicesDal, result) => {
    if (task.definition.onTaskSuccess) {
        task.definition.onTaskSuccess(result)
    }

    if (!result) {
        return result
    }

    if (!_.isEmpty(result.historyAlteringChanges)) {
        applyChangesToSnapshot(dal, result.historyAlteringChanges, task.name, task.tags)
    }

    result = _.omit(result, 'historyAlteringChanges')
    updateDALbyTaskResult(dal, result.changes)
    return result.result
}

export interface SaveTask {
    useLastApprovedSnapshot: boolean
    tags: string[]
    name: string
    methodName: string
    ps: PS
    currentSavedSnapshotDal?: SnapshotDal
    definition: SaveTaskDefinition
    args()
    execute()
    onTaskFailure?(e): void
    onTaskSuccess?(result: SaveResult): void

    extraArgs?(): any[]
    cancel?(result?: SaveResult): void
}

class BaseTask {
    public methodName: string
    public execute: any
    public definition: any
    public tags: string[]
    public useLastApprovedSnapshot: boolean
    public ps: PS
    protected dal: DocumentServicesDal
    public name: string

    constructor(ps: PS, taskDefinition: SaveTaskDefinition, methodName: string) {
        this.ps = ps
        this.dal = ps.dal
        this.name = taskDefinition.getTaskName()
        this.methodName = methodName
        this.execute = taskDefinition[methodName]
        this.definition = taskDefinition
        this.tags = getSnapshotTags(taskDefinition, methodName)
        const api = ps.extensionAPI
        const useLastOnCSave = api.continuousSave.isCSaveOpen()
        const useLastOnCEdit = !taskDefinition.getCurrentState && api.continuousSave.isCEditOpen()
        this.useLastApprovedSnapshot = useLastOnCSave || useLastOnCEdit
    }

    onTaskSuccess(result: SaveResult) {
        return onTaskSuccess(this, this.dal, result)
    }
}

/**
 * Updates the DAL and task snapshots with the deleted items from server
 * @param {DocumentServicesDal} dal
 * @param {string[]} changes a collection of page ids, each containing a collection of data maps, each containing an array of deleted items ids
 * @param {String} taskName
 * @param {string[]} taskTags
 */
function applyChangesToSnapshot(dal: DocumentServicesDal, changes: string[], taskName: string, taskTags: string[]) {
    _.forEach(taskTags, function (taskTag) {
        const snapshotTag = taskName + taskTag
        dal.duplicateLastSnapshot(snapshotTag, changes)
    })
}

const getTaskTag = (task: SaveTask) => task.name + task.tags[0]
const getLastSnapshotDal = (task: SaveTask, dal: DocumentServicesDal) =>
    dal.full.snapshot.getLastSnapshotByTagName(getTaskTag(task)) || dal.full.snapshot.getInitialSnapshot()
const getCurrentSnapshotDal = (task: SaveTask, dal: DocumentServicesDal) =>
    dal.full.snapshot.getLastSnapshotByTagName(getTaskTag(task))

const takeSnapshots = (task: SaveTask, dal: DocumentServicesDal) => {
    if (task.definition.takeSnapshot) {
        task.definition.takeSnapshot()
    }
    if (!task.definition.takeSnapshot || task.definition.requiresCurrentSnapshotDal) {
        _.forEach(task.tags, tag => {
            const tagName = task.name + tag
            if (task.useLastApprovedSnapshot) {
                // @ts-expect-error
                dal.takeLastApprovedSnapshot(tagName)
            } else {
                dal.takeSnapshot(tagName)
            }
        })
    }
}

const rollback = (task: SaveTask, dal: DocumentServicesDal, result) => {
    monitoring.start('saveRunnerRollback')
    if (task.definition.rollback) {
        task.definition.rollback()
    }
    if (!task.definition.rollback || task.definition.requiresCurrentSnapshotDal) {
        _.forEach(task.tags, tag => {
            dal.removeLastSnapshot(task.name + tag)
        })
        if (result && !_.isEmpty(result.changes)) {
            _.forEach(task.tags, tag => {
                dal.duplicateLastSnapshot(task.name + tag, result.changes)
            })
            updateDALbyTaskResult(dal, result.changes)
        }
    }
    monitoring.end('saveRunnerRollback')
}

class TaskWithHistory extends BaseTask implements SaveTask {
    protected lastSavedState: unknown
    protected lastSavedSnapshotDal: SnapshotDal
    protected currentState: any
    currentSavedSnapshotDal: SnapshotDal

    constructor(ps: PS, taskDefinition: SaveTaskDefinition, methodName: string) {
        super(ps, taskDefinition, methodName)
        if (taskDefinition.getLastState) {
            this.lastSavedState = taskDefinition.getLastState()
        } else {
            this.lastSavedSnapshotDal = getLastSnapshotDal(this, this.dal)
        }

        takeSnapshots(this, this.dal)

        if (taskDefinition.getCurrentState) {
            this.currentState = taskDefinition.getCurrentState()
        }
        if (!taskDefinition.getCurrentState || taskDefinition.requiresCurrentSnapshotDal) {
            this.currentSavedSnapshotDal = getCurrentSnapshotDal(this, this.dal)
            if (this.currentSavedSnapshotDal) {
                // TODO maybe should be on last
                this.currentSavedSnapshotDal.lastTransactionId = ps.extensionAPI.continuousSave.getLastTransactionId()
            }
        }
    }

    args(): any[] {
        return [this.lastSavedState, this.currentState]
    }

    extraArgs(): any[] {
        return [this.lastSavedSnapshotDal, this.currentSavedSnapshotDal, this.ps.extensionAPI]
    }

    rollBackSnapshot(result: SaveResult) {
        rollback(this, this.dal, result)
    }

    onTaskFailure(result: SaveResult) {
        this.rollBackSnapshot(result)
    }

    cancel(result: SaveResult) {
        this.rollBackSnapshot(result)
    }
}

const getCurrentSnapshotDalForPublish = (task: SaveTask, ps: PS) => {
    if (task.definition.getLastState) {
        return null
    }
    const snapshotDal = getLastSnapshotDal(task, ps.dal)
    const metaSiteIdPointer = {type: 'rendererModel', id: 'metaSiteId'}
    const siteIdPointer = {type: 'rendererModel', id: 'siteInfo', innerPath: 'siteId'}
    const currentMetaSiteId = ps.dal.full.get(metaSiteIdPointer)
    const currentSiteId = ps.dal.full.get(siteIdPointer)
    return ps.extensionAPI.snapshots.createWithChanges(snapshotDal, [
        {pointer: metaSiteIdPointer, value: currentMetaSiteId},
        {pointer: siteIdPointer, value: currentSiteId}
    ])
}

class PublishTask extends BaseTask implements SaveTask {
    currentState: any

    constructor(ps: PS, taskDefinition, methodName: string) {
        super(ps, taskDefinition, methodName)
        this.currentState = getCurrentSnapshotDalForPublish(this, this.ps)
    }

    args(): any[] {
        return [this.currentState, this.ps.extensionAPI]
    }
}

/**
 * Updates the DAL according to the result parameter.
 * @param dal
 * @param {*} result Object of the changes needed in the DAL the key is the path and the value is the new value,
 *                                   undefined value will remove the path from DAL.
 */
function updateDALbyTaskResult(dal: DocumentServicesDal, result) {
    _.forEach(result, ({path, value}) => {
        if (_.isUndefined(value)) {
            dal.full.removeByPathInHostModel(path)
            return
        }
        dal.full.setByPath(path, value)
    })
}

function updateModelsForSecondaryTasks(ps: PS, secondaryTasksMap) {
    const secondaryTasks = _.reject(secondaryTasksMap, task => task.definition.getCurrentState)
    updateModelsForSecondaryTasksWithSnapshotDal(ps, secondaryTasks)
}

function updateModelsForSecondaryTasksWithSnapshotDal(ps: PS, secondaryTasksMap: SaveTask[]) {
    if (secondaryTasksMap.length) {
        const changes = _.concat(
            ps.extensionAPI.siteAPI.getDocumentServicesModel(),
            ps.extensionAPI.siteAPI.getRendererModel()
        )

        _.forOwn(secondaryTasksMap, function (task: any) {
            task.currentSavedSnapshotDal = ps.extensionAPI.snapshots.createWithChanges(
                task.currentSavedSnapshotDal,
                changes
            )
        })
    }
}

/**
 *
 * @param {ps} ps
 * @param primaryTask
 * @param requiredTasksMap
 * @param secondaryTasksMap
 * @param onSuccess
 * @param onError
 * @param {Object} options
 * @param biCallbacks an object with "event" and "error" callback for sending bi events & errors
 * @param {string} methodName
 * @returns {Promise<*>}
 */
function runTasks(
    ps: PS,
    primaryTask: SaveTask,
    secondaryTasksMap: SaveTask[],
    requiredTasksMap: SaveTask[],
    onSuccess,
    onError,
    biCallbacks,
    options,
    methodName: string
): Promise<any> {
    const interactionName = getFedopsInteractionName(methodName)
    monitoring.start(interactionName)
    const taskExecutionPromise = executeTaskMap(requiredTasksMap, biCallbacks, options, RequiredTasksError)
        .catch(e => {
            primaryTask.cancel()
            _.invokeMap(secondaryTasksMap, 'cancel')
            throw e
        })
        .then(() =>
            executeTask(primaryTask, biCallbacks, options)
                .catch(reason => {
                    _.invokeMap(secondaryTasksMap, 'cancel')
                    throw new PrimaryTaskError(reason)
                })
                .then(taskResult => {
                    updateModelsForSecondaryTasks(ps, secondaryTasksMap)
                    return executeTaskMap(secondaryTasksMap, biCallbacks, options, SecondaryTasksError).then(
                        () => taskResult
                    )
                })
        )

    taskExecutionPromise
        .then(result => {
            _.invoke(ps.dal, 'commitTransaction')
            _.invoke({onSuccess}, 'onSuccess', result)
            monitoring.end(interactionName)
        })
        .catch(err => {
            santaCoreUtils.log.error('Save has failed - please see the failure details below:', err.reason)
            if (onError) {
                const reason = err.reason || {documentServicesInternalError: err}
                onError(reason)
            }
        })

    return taskExecutionPromise
}

function getSnapshotTags(taskDefinition: SaveTaskDefinition, methodName: string) {
    const snapshotsTags = taskDefinition.getSnapshotTags(methodName)
    if (!snapshotsTags || !_.isArray(snapshotsTags) || _.isEmpty(snapshotsTags)) {
        return ['']
    }
    return snapshotsTags
}

/**
 * Actually runs the save tasks.
 * @param {string} methodName
 * @param {*} TaskCtor
 * @param {Object} tasksRegistry
 * @param {ps} ps
 * @param {function} onSuccess
 * @param {function} onError
 * @param {Object} options
 * @param {Object} biCallbacks an object with "event" and "error" callback for sending bi events & errors
 * @return {Promise} a promise that the save tasks will be executed. Will resolve when all have resolved.
 *         If the documentSave is rejected, the promise will be rejected immediately. If it succeeds and a secondary task rejects, then it will be rejected once all the tasks have been settled.
 */
function buildAndRunTasks(
    methodName: string,
    TaskCtor: new (ps1: PS, taskDef1: SaveTaskDefinition, methodName1: string) => SaveTask,
    tasksRegistry: SaveTaskRegistryResult,
    ps: PS,
    onSuccess,
    onError,
    biCallbacks: BICallbacks,
    options: SaveOptions
) {
    return queue.run(() => {
        const documentSaveTask = new TaskCtor(ps, tasksRegistry.primaryTask, methodName)

        function buildTask(taskDefinition) {
            return new TaskCtor(ps, taskDefinition, methodName)
        }

        const requiredTasks = _.map(tasksRegistry.requiredTasks, buildTask)
        const secondaryTasks = _.map(tasksRegistry.secondaryTasks, buildTask)

        return runTasks(
            ps,
            documentSaveTask,
            secondaryTasks,
            requiredTasks,
            onSuccess,
            onError,
            biCallbacks,
            options,
            methodName
        )
    })
}

const shouldSaveBeforePublish = ({primaryTask}: SaveTaskRegistryResult, ps: PS) => {
    const methodName = 'partialSave'
    if (primaryTask.shouldRun) {
        const tag = primaryTask.getTaskName() + getSnapshotTags(primaryTask, methodName)[0]
        const lastSnapshotDal =
            ps.dal.full.snapshot.getLastSnapshotByTagName(tag) || ps.dal.full.snapshot.getInitialSnapshot()
        const currentSnapshotDal = ps.dal.full.snapshot.getCurrentSnapshot()
        return primaryTask.shouldRun?.(ps, methodName, lastSnapshotDal, currentSnapshotDal)
    }
    return false
}

const buildAndRunTasksAsync = (
    methodName: string,
    TaskCtor: new (ps1: PS, taskDef1: SaveTaskDefinition, methodName1: string) => SaveTask,
    tasksRegistry: SaveTaskRegistryResult,
    ps: PS,
    biCallbacks: BICallbacks,
    options: SaveOptions
) =>
    new Promise((resolve, reject) => {
        buildAndRunTasks(methodName, TaskCtor, tasksRegistry, ps, resolve, reject, biCallbacks, options)
    })

const createTasks = func => ({
    runPartialSaveTasks: func.bind(null, 'partialSave', TaskWithHistory),
    runFullSaveTasks: func.bind(null, 'fullSave', TaskWithHistory),
    runSaveAsTemplate: func.bind(null, 'saveAsTemplate', TaskWithHistory),
    runPublishTasks: func.bind(null, 'publish', PublishTask)
})

export default {
    promises: createTasks(buildAndRunTasksAsync),
    ...createTasks(buildAndRunTasks),
    runFunctionInSaveQueue: queue.run.bind(queue),
    shouldSaveBeforePublish
}
