import type {DocumentManager} from '@wix/document-manager-core'
import type {MasterPageMigrator} from '../dataMigrationRunner'
import type {MenuExtensionAPI} from '@wix/document-manager-extensions'

const EXP = 'dm_fixDifferentIdsInMainMenuWithMultilingual'
const CUSTOM_MAIN_MENU_ID = 'CUSTOM_MAIN_MENU'
export const BASIC_MENU_ITEM_TYPE = 'BasicMenuItem'
export const CUSTOM_MENU_TYPE = 'CustomMenu'

const migrateMasterPage = (documentManager: DocumentManager) => {
    const {extensionAPI} = documentManager
    const {menus} = extensionAPI as MenuExtensionAPI
    if (!documentManager.experimentInstance.isOpen(EXP)) {
        return
    }
    menus.fixItemsWithDifferentIdAndSamePageId(CUSTOM_MAIN_MENU_ID)
}

const name = 'fixDifferentIdsInMainMenuWithMultilingual'
const version = 0
const experimentalVersions = [{experiment: EXP, version: 1}]

export const fixDifferentIdsInMainMenuWithMultilingual: MasterPageMigrator = {
    migrateMasterPage,
    name,
    version,
    experimentalVersions,
    fixerRequiresReruns: true
}
