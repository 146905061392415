import {
    CreateExtArgs,
    CreateExtensionArgument,
    DalValue,
    Extension,
    ExtensionAPI,
    pointerUtils,
    InitializeExtArgs,
    Namespace
} from '@wix/document-manager-core'
import _ from 'lodash'
import {ReportableError} from '@wix/document-manager-utils'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'
import {extractBaseComponentId, createInflatedRefId} from '../utils/refStructureUtils'
import type {DataModelExtensionAPI} from './dataModel/dataModel'
import type {PossibleViewModes, Pointer} from '@wix/document-services-types'
import type {ConnectionsAPI} from './connections'
import {
    MASTER_PAGE_ID,
    NICKNAMES,
    COMP_TYPES,
    VIEWER_DATA_TYPES,
    VIEW_MODES,
    COMPS_DATA_TYPE
} from '../constants/constants'
import type {ComponentsMetadataAPI} from './componentsMetadata/componentsMetadata'
import type {DalPointers} from '../types'
import type {WixCodeExtensionAPI} from './wixCode'
import {getComponentNicknameByType} from './componentsMetadata/utils/nicknameUtils'
import {deepClone} from '@wix/wix-immutable-proxy'
import type {RefOverridesExtensionAPI} from './refOverrides'
import {getNicknameFromConnectionList, getWixCodeConnectionItem} from '../utils/nicknamesUtils'

const {getRepeaterTemplateId} = displayedOnlyStructureUtil
const {getPointer} = pointerUtils
const {VALIDATIONS} = NICKNAMES
const MAX_NICKNAME_LENGTH = 128
type HasComponentWithThatNicknameFn = (
    dalPointers: DalPointers,
    containingPagePointer: Pointer,
    searchedNickname: string,
    compPointerToExclude: Pointer | undefined
) => boolean
export type SetNickname = (compPointer: Pointer, nickname: string) => void
export type ValidateNickname = (
    compPointer: Pointer,
    nickname: string,
    hasComponentWithThatNicknameFn?: HasComponentWithThatNicknameFn
) => void
export type GenerateNicknamesForComponent = (
    compPointer: Pointer,
    pagePointer: Pointer,
    viewMode?: PossibleViewModes
) => void

export type GenerateConnectionOverrideForRefComponentRoot = (
    refCompPointer: Pointer,
    pagePointer: Pointer,
    rootCompId: string,
    rootComponentType: string
) => void

const ALL_PAGES_FILTER = 'all'

export type ShouldSetNickname = (compPointer: Pointer) => boolean
type UsedNickNames = {[x: string]: string} | undefined
type GetComponentNickname = (compPointer: Pointer, context?: Record<string, any>) => Record<string, string>
export interface NicknamesAPI {
    hasComponentWithThatNickname(
        containingPagePointer: Pointer,
        searchedNickname: string,
        compPointerToExclude: Pointer | undefined
    ): boolean
    setNickname: SetNickname
    generateNicknamesForComponent: GenerateNicknamesForComponent
    shouldSetNickname: ShouldSetNickname
    validateNickname: ValidateNickname
    getComponentNickname: GetComponentNickname
    generateConnectionOverrideForRefComponentRoot: GenerateConnectionOverrideForRefComponentRoot
}

export type NicknamesExtensionAPI = ExtensionAPI & {
    nicknames: NicknamesAPI
}

const createExtension = ({}: CreateExtensionArgument): Extension => {
    const getKeyFilterName = (page: string, nickname: string) => `${page}__${nickname}`

    const createExtensionAPI = ({pointers, extensionAPI, dal}: CreateExtArgs): NicknamesExtensionAPI => {
        const getNicknameByConnectionPointer = (
            connectionPtr: Pointer,
            pagePointer: Pointer,
            compPointer: Pointer,
            context?: Record<string, any>
        ) => {
            const {connections} = extensionAPI as ConnectionsAPI
            const connectionItems = connections.getResolvedConnectionsByDataItem(
                connectionPtr.id,
                pagePointer,
                compPointer.type
            )

            if (connectionItems) {
                return getNicknameFromConnectionList(connectionItems, context)
            }
        }

        const getRefComponentNicknameFromOverrides = (
            compPointer: Pointer,
            compNickname: string,
            context?: Record<string, any>
        ) => {
            const pagePointer = pointers.structure.getPageOfComponent(compPointer)
            return _(compPointer)
                .thru(pointers.referredStructure.getConnectionOverrides)
                .mapKeys((connectionPtr: Pointer) => extractBaseComponentId(connectionPtr))
                .mapValues((connectionPtr: Pointer) =>
                    getNicknameByConnectionPointer(connectionPtr, pagePointer, compPointer, context)
                )
                .assign({[compPointer.id]: compNickname})
                .pickBy()
                .value()
        }

        function getComponentNickname(compPointer: Pointer, context?: Record<string, any>) {
            const {connections} = extensionAPI as ConnectionsAPI
            const compConnections = connections.getResolved(compPointer)

            const nickname = getNicknameFromConnectionList(compConnections, context)
            const compData = dal.get(compPointer)
            const componentType = _.get(compData, ['componentType'])

            if (componentType === COMP_TYPES.REF_TYPE) {
                return getRefComponentNicknameFromOverrides(compPointer, nickname!, context)
            }
            return nickname ? {[compPointer.id]: nickname} : {}
        }

        const getNicknames = (comps: Pointer[]) => {
            return _(comps)
                .map(c => getComponentNickname(c))
                .reduce(_.assign)
        }

        const hasInvalidCharacters = (nickname: string) => {
            const validName = /^[a-zA-Z0-9\-]+$/
            return !validName.test(nickname)
        }

        const nicknameKeyExists = (keyFilterName: string, compPointerToExclude: Pointer | undefined): boolean => {
            const filter = dal.queryFilterGetters.nicknames(keyFilterName)
            const filterResult = Object.values(dal.query('connections', filter))
            if (!compPointerToExclude || filterResult.length === 0) {
                return filterResult.length > 0
            }
            const {dataModel} = extensionAPI as DataModelExtensionAPI

            const connectionToExclude =
                compPointerToExclude.type === 'DESKTOP'
                    ? dataModel.components.getItem(compPointerToExclude, 'connections')
                    : dataModel.components.getItem({...compPointerToExclude, type: 'DESKTOP'}, 'connections') ??
                      dataModel.components.getItem(compPointerToExclude, 'connections')
            return !connectionToExclude || filterResult.filter(item => connectionToExclude.id !== item.id).length > 0
        }

        const hasComponentWithThatNicknameWithIndex = (
            containingPagePointer: Pointer,
            searchedNickname: string,
            compPointerToExclude: Pointer | undefined
        ) => {
            const actualCompPointerToExclude = compPointerToExclude
                ? getPointer(getRepeaterTemplateId(compPointerToExclude.id), compPointerToExclude.type)
                : compPointerToExclude

            if (containingPagePointer.id === MASTER_PAGE_ID) {
                return nicknameKeyExists(
                    getKeyFilterName(ALL_PAGES_FILTER, searchedNickname),
                    actualCompPointerToExclude
                )
            }

            return (
                nicknameKeyExists(getKeyFilterName(MASTER_PAGE_ID, searchedNickname), actualCompPointerToExclude) ||
                nicknameKeyExists(
                    getKeyFilterName(containingPagePointer.id, searchedNickname),
                    actualCompPointerToExclude
                )
            )
        }

        const hasComponentWithThatNickname = (
            containingPagePointer: Pointer,
            searchedNickname: string,
            compPointerToExclude: Pointer | undefined
        ) => {
            if (!searchedNickname) {
                return false
            }
            return hasComponentWithThatNicknameWithIndex(containingPagePointer, searchedNickname, compPointerToExclude)
        }

        const validateNickname = (compPointer: Pointer, nickname: string, hasComponentWithThatNicknameFn?: any) => {
            if (_.isEmpty(nickname)) {
                return VALIDATIONS.TOO_SHORT
            }

            if (nickname.length > MAX_NICKNAME_LENGTH) {
                return VALIDATIONS.TOO_LONG
            }

            const _hasComponentWithThatNicknameFn = hasComponentWithThatNicknameFn || hasComponentWithThatNickname
            const componentPagePointer = pointers.structure.getPageOfComponent(compPointer)

            if (_hasComponentWithThatNicknameFn(componentPagePointer, nickname, compPointer)) {
                return VALIDATIONS.ALREADY_EXISTS
            }

            if (hasInvalidCharacters(nickname)) {
                return VALIDATIONS.INVALID_NAME
            }

            return VALIDATIONS.VALID
        }

        const setNickname = (compPointer: Pointer, nickname: string) => {
            if (validateNickname(compPointer, nickname) !== VALIDATIONS.VALID) {
                throw new ReportableError({
                    errorType: 'invalidNickname',
                    message: 'The new nickname you provided is invalid'
                })
            }
            const {connections} = extensionAPI as ConnectionsAPI
            let currentConnections = connections.get(compPointer)
            const connection = getWixCodeConnectionItem(currentConnections)
            if (connection) {
                connection.role = nickname
            } else {
                const newConnectionItem = connections.createWixCodeConnectionItem(nickname)
                currentConnections = currentConnections!.concat([newConnectionItem])
            }

            connections.updateConnectionsItem(compPointer, currentConnections!)
        }

        const createNicknameOverride = (refRootId: string, pageId: string, nickname: string) => {
            const {connections} = extensionAPI as ConnectionsAPI
            const {refOverrides} = extensionAPI as RefOverridesExtensionAPI

            const connectionItemId = refOverrides.getOverrideId(refRootId, VIEWER_DATA_TYPES.connections)
            let currentConnections = deepClone(connections.getConnectionsByDataItem(connectionItemId, pageId))
            const connection = getWixCodeConnectionItem(currentConnections)
            if (connection) {
                connection.role = nickname
            } else {
                const newConnectionItem = connections.createWixCodeConnectionItem(nickname)
                currentConnections = currentConnections!.concat([newConnectionItem])
            }
            connections.updateConnectionsItemByDataItem(connectionItemId, pageId, currentConnections!)
        }

        const getComponentsInContainer = (containerPointer: Pointer) =>
            pointers.structure.getChildrenRecursivelyRightLeftRootIncludingRoot(containerPointer)

        const getNextSuffixIndex = (compNickname: string, compsNicknames: UsedNickNames) => {
            const regex = new RegExp(`${compNickname}(\\d+)`) //will match the number in the end of the nickname
            const maxSuffixOfDefaultNickname = _(compsNicknames)
                .map(nickname => {
                    const match = regex.exec(nickname)
                    return match ? _.parseInt(match[1]) : null
                })
                .concat(0)
                .max()

            return maxSuffixOfDefaultNickname! + 1
        }

        const shouldSetNickname = (compPointer: Pointer) => {
            const {componentsMetadata} = extensionAPI as ComponentsMetadataAPI
            return (
                !pointers.structure.isMasterPage(compPointer) &&
                !getComponentNickname(compPointer)[compPointer.id] &&
                componentsMetadata.shouldAutoSetNickname(compPointer)
            )
        }

        const generateNicknamesForComponentsImpl = (compPointer: Pointer, usedNickNames: UsedNickNames) => {
            const {componentsMetadata} = extensionAPI as ComponentsMetadataAPI
            const defaultCompNickname = componentsMetadata.getDefaultNickname(compPointer)
            let maxSuffixOfDefaultNickname = getNextSuffixIndex(defaultCompNickname, usedNickNames)
            const newNickname = defaultCompNickname + maxSuffixOfDefaultNickname++
            setNickname(compPointer, newNickname)
        }

        const getUsedNicknames = (pagePointer: Pointer, viewMode: PossibleViewModes = VIEW_MODES.DESKTOP) => {
            const masterPagePointer = pointers.structure.getPage(MASTER_PAGE_ID, viewMode)
            if (pagePointer.id === MASTER_PAGE_ID) {
                throw Error('Cannot add currently add to master page')
            }
            const allCompsInContext = getComponentsInContainer(pagePointer).concat(
                getComponentsInContainer(masterPagePointer)
            )
            return getNicknames(allCompsInContext)
        }

        const generateConnectionOverrideForRefComponentRoot = (
            compPointer: Pointer,
            pagePointer: Pointer,
            rootCompId: string,
            rootComponentType: string
        ) => {
            const usedNickNames = getUsedNicknames(pagePointer)
            const inflatedRefId = createInflatedRefId(compPointer.id, rootCompId)
            const defaultCompNickname = getComponentNicknameByType({dal, pointers}, rootComponentType) || 'widget'
            let maxSuffixOfDefaultNickname = getNextSuffixIndex(defaultCompNickname, usedNickNames)
            const newNickname = defaultCompNickname + maxSuffixOfDefaultNickname++
            createNicknameOverride(inflatedRefId, pagePointer.id, newNickname)
        }
        const generateNicknamesForComponent = (
            compPointer: Pointer,
            pagePointer: Pointer,
            viewMode: PossibleViewModes = VIEW_MODES.DESKTOP
        ) => {
            const usedNicknames = getUsedNicknames(pagePointer, viewMode)
            generateNicknamesForComponentsImpl(compPointer, usedNicknames)
        }

        return {
            nicknames: {
                hasComponentWithThatNickname,
                shouldSetNickname,
                getComponentNickname,
                generateNicknamesForComponent,
                setNickname,
                validateNickname,
                generateConnectionOverrideForRefComponentRoot
            }
        }
    }

    const initialize = async (extArgs: InitializeExtArgs) => {
        const {eventEmitter, EVENTS, extensionAPI, dal, pointers} = extArgs
        const addNicknameIfNeeded = (compPointer: Pointer, pagePointer: Pointer) => {
            const {wixCode} = extensionAPI as WixCodeExtensionAPI
            const {nicknames} = extensionAPI as NicknamesExtensionAPI
            if (wixCode.isProvisioned() && nicknames.shouldSetNickname(compPointer)) {
                nicknames.generateNicknamesForComponent(compPointer, pagePointer)
            }
        }

        const generateConnectionOverrideForRefComponent = (compPointer: Pointer, pagePointer: Pointer) => {
            const {dataModel} = extensionAPI as DataModelExtensionAPI
            const {nicknames} = extensionAPI as NicknamesExtensionAPI
            const componentData = dataModel.components.getItem(compPointer, 'data')
            const {appDefinitionId, widgetId} = componentData
            const removeWidgetMetaData = dal.get(
                pointers.remoteStructureMetaData.getRemoteStructureWidgetMetaData(appDefinitionId, widgetId)
            )
            if (!removeWidgetMetaData) {
                throw new Error('Need to load remote widget meta data for nickname creation')
            }
            const {rootId} = removeWidgetMetaData
            const rootComponentType = removeWidgetMetaData.rootType
            nicknames.generateConnectionOverrideForRefComponentRoot(compPointer, pagePointer, rootId, rootComponentType)
        }
        eventEmitter.on(EVENTS.COMPONENTS.AFTER_ADD_FROM_EXT, (componentPointer: Pointer, pagePointer: Pointer) => {
            const {dataModel} = extensionAPI as DataModelExtensionAPI

            const compData = dataModel.components.getItem(componentPointer, VIEWER_DATA_TYPES.data)
            const componentType = _.get(compData, ['type'])
            if (componentType === COMPS_DATA_TYPE.BLOCK_WIDGET) {
                return generateConnectionOverrideForRefComponent(componentPointer, pagePointer)
            }
            addNicknameIfNeeded(componentPointer, pagePointer)
        })
    }

    const createFilters = () => {
        return {
            nicknames: (namespace: Namespace, value: DalValue) => {
                const result: string[] = []
                if (namespace !== 'connections' && value.type !== 'ConnectionList') {
                    return result
                }

                value.items
                    ?.filter(
                        (item: DalValue) => item?.type === 'WixCodeConnectionItem' || item?.type === 'ConnectionItem'
                    )
                    .forEach((connection: DalValue) => {
                        const nickname = connection?.role
                        if (nickname) {
                            result.push(getKeyFilterName(value.metaData.pageId, nickname))
                            result.push(getKeyFilterName(ALL_PAGES_FILTER, nickname))
                        }
                    })

                return result
            }
        }
    }

    return {
        name: 'nicknames',
        dependencies: new Set(['dataModel', 'components']),
        createExtensionAPI,
        initialize,
        createFilters
    }
}

export {createExtension}
