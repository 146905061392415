import type {SnapshotDal} from '@wix/document-manager-core'
import _ from 'lodash'
import type {BICallbacks} from '../../saveAPI/createSaveAPI'
import type {SaveTaskDefinition} from '../../saveAPI/lib/registry'
import type {ImmutableSnapshot} from '../../types'
import pendingAppsService from '../services/pendingAppsService'
import appStoreService from '../services/appStoreService'
import metaSiteProvisioner from '../../metaSiteProvisioner/metaSiteProvisioner'
import semanticAppVersionsCleaner from '../../metaSiteProvisioner/semanticAppVersionsCleaner'
import * as appServiceData from '../../saveAPI/appServiceData'
import type {TpaMsg} from '../services/tpaPostMessageService'

const TASK_NAME = 'unProvisionedAppsAppFlows'

function clearPendingApps() {
    pendingAppsService.onSave()
}

const fetchSettleDataFromSnapshotDal = (lastSnapshotDal: SnapshotDal, currentSnapshotDal: SnapshotDal) => ({
    revision: currentSnapshotDal.getValue({type: 'documentServicesModel', id: 'revision'}),
    appStoreUrl: _.get(currentSnapshotDal.getValue({type: 'serviceTopology', id: 'serviceTopology'}), 'appStoreUrl'),
    metaSiteId: currentSnapshotDal.getValue({type: 'rendererModel', id: 'metaSiteId'}),
    editorSessionId: currentSnapshotDal.getValue({type: 'documentServicesModel', id: 'editorSessionId'}),
    appStoreServiceData: appServiceData.createFromSnapshotDal(lastSnapshotDal, currentSnapshotDal)
})

const settleAfterSiteSaveWithSnapshotDal = function (
    currentImmutable: SnapshotDal,
    {revision, appStoreUrl, metaSiteId, editorSessionId, appStoreServiceData},
    concurrentResolver: (msg: TpaMsg) => void,
    reject
) {
    const onComplete = function (msg: TpaMsg) {
        concurrentResolver(msg)
        clearPendingApps()
    }

    setTimeout(function () {
        const urlData = {appStoreUrl, metaSiteId, editorSessionId}
        appStoreService.settleOnSaveWithImmutableSnapshot(
            currentImmutable,
            appStoreServiceData,
            revision,
            urlData,
            true,
            onComplete,
            reject
        )
    }, 200)
}

const getSettleWithConcurrencyErrorRetry = fetchSettleData =>
    metaSiteProvisioner(
        function (
            lastImmutable: ImmutableSnapshot,
            currentImmutable: ImmutableSnapshot,
            resolve,
            reject,
            bi,
            options,
            lastSnapshotDal: SnapshotDal,
            currentSnapshotDal: SnapshotDal
        ) {
            const settleData = fetchSettleData(lastImmutable, currentImmutable, lastSnapshotDal, currentSnapshotDal)
            settleAfterSiteSaveWithSnapshotDal(currentSnapshotDal, settleData, resolve, reject)
        },
        [semanticAppVersionsCleaner]
    )

const getOnSave =
    fetchSettleData =>
    (
        lastImmutable: ImmutableSnapshot,
        currentImmutable: ImmutableSnapshot,
        resolve,
        reject: (res: {changes: any[]}) => void,
        bi: BICallbacks,
        options: {settleInServer: boolean},
        lastSnapshotDal: SnapshotDal,
        currentSnapshotDal: SnapshotDal
    ) => {
        const {settleInServer} = options || {}
        if (settleInServer) {
            clearPendingApps()
            resolve({
                changes: []
            })
        } else {
            const settleWithConcurrencyErrorRetry = getSettleWithConcurrencyErrorRetry(fetchSettleData)
            settleWithConcurrencyErrorRetry(
                lastImmutable,
                currentImmutable,
                resolve,
                reject,
                bi,
                {},
                lastSnapshotDal,
                currentSnapshotDal
            )
        }
    }

const createTask = (): SaveTaskDefinition => {
    const fetchSettleData = (
        lastImmutable: ImmutableSnapshot,
        currentImmutable: ImmutableSnapshot,
        lastSnapshotDal: SnapshotDal,
        currentSnapshotDal: SnapshotDal
    ) => fetchSettleDataFromSnapshotDal(lastSnapshotDal, currentSnapshotDal)

    const onSave = getOnSave(fetchSettleData)

    return {
        saveAsTemplate(lastImmutableSnapshot, currentImmutableSnapshot, resolve) {
            resolve(undefined)
        },
        publish(currentImmutableSnapshot, extensionAPI, resolve) {
            resolve()
        },
        getTaskName() {
            return TASK_NAME
        },
        getSnapshotTags() {
            return ['primary']
        },
        partialSave: onSave,
        fullSave: onSave,
        promises: {
            partialSave(
                lastImmutable: ImmutableSnapshot,
                currentImmutable: ImmutableSnapshot,
                bi: BICallbacks,
                options,
                lastSnapshotDal: SnapshotDal,
                currentSnapshotDal: SnapshotDal
            ) {
                return new Promise((resolve, reject) => {
                    onSave(
                        lastImmutable,
                        currentImmutable,
                        resolve,
                        reject,
                        bi,
                        options,
                        lastSnapshotDal,
                        currentSnapshotDal
                    )
                })
            },
            fullSave(
                lastImmutable: ImmutableSnapshot,
                currentImmutable: ImmutableSnapshot,
                bi: BICallbacks,
                options,
                lastSnapshotDal: SnapshotDal,
                currentSnapshotDal: SnapshotDal
            ) {
                return new Promise((resolve, reject) => {
                    onSave(
                        lastImmutable,
                        currentImmutable,
                        resolve,
                        reject,
                        bi,
                        options,
                        lastSnapshotDal,
                        currentSnapshotDal
                    )
                })
            }
        }
    }
}

export default () => createTask()
