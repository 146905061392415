import type {
    ComponentStageData,
    MobileAlgoPlugin,
    MobileAlgoPluginInitializationArgs,
    PluginHeuristicsRegistry
} from '../../types'

export const createPlugin = ({stages}: MobileAlgoPluginInitializationArgs): MobileAlgoPlugin => {
    //  const defaultMargin = 10
    // const specificMargin: Record<string, number> = {}
    const yMargins = ({componentStructure}: ComponentStageData) => {
        if (componentStructure.layouts?.itemLayout?.type === 'MeshItemLayout') {
            //const margin = specificMargin[componentStructure.componentType] ?? defaultMargin
            //  componentStructure.layouts.itemLayout.margins.top = {value: 0, type: 'px'}
            // componentStructure.layouts.itemLayout.margins.bottom = {value: margin, type: 'px'}
        }
    }

    const register = (registry: PluginHeuristicsRegistry) => {
        registry.register(stages.ADJUST, yMargins)
    }

    const name = 'yMargins'
    return {register, name, dependencies: ['loadRefs']}
}
