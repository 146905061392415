'use strict'

const _ = require('lodash')
const dataUtils = require('../helpers/dataUtils')
const {
    NAMESPACE_MAPPING,
    COMP_DATA_QUERY_KEYS_WITH_STYLE
} = require('@wix/document-services-json-schemas/dist/constants.json')
const {rgb2hex, str2rgba, isRgb, isRgba} = require('@wix/color-kit')

const sectionDefaultSystemStyle = {
    style: {
        properties: {
            'alpha-bg': '0',
            bg: 'color_11'
        }
    }
}
const MediaContainerDesignData = 'MediaContainerDesignData'
const MediaContainerWithDividers = 'MediaContainerWithDividers'
const sectionsTypes = {
    'responsive.components.Section': true,
    'responsive.components.HeaderSection': true,
    'responsive.components.FooterSection': true
}

const getChildren = structure => {
    if (structure.type === 'Document') {
        return structure.children
    }

    return structure.components
}

const getStyleItem = (data, styleId) => {
    const styleItem = dataUtils.getDataByQuery(data[NAMESPACE_MAPPING.style], styleId)
    return _.isEmpty(styleItem) ? sectionDefaultSystemStyle : styleItem
}

const getDesignItem = (data, designId) => {
    return dataUtils.getDataByQuery(data[NAMESPACE_MAPPING.design], designId)
}

const generateDesignId = uniqueIdGenerator => uniqueIdGenerator.getUniqueId('dataItem', '-', {bucket: 'design'})

const setDesignItem = (data, item, uniqueIdGenerator) => {
    item.id = item.id || generateDesignId(uniqueIdGenerator)
    data[NAMESPACE_MAPPING.design][item.id] = item
}

const fixSingleDesignType = designItem => {
    if (designItem.type === MediaContainerDesignData) {
        designItem.type = MediaContainerWithDividers
        delete designItem.dataChangeBehaviors
    }
}

const fixSectionDesignType = (designItem, data) => {
    if (dataUtils.refArray.isRefArray(designItem)) {
        dataUtils.refArray.extractValues(designItem).forEach(designRef => {
            const resolvedDesignRef = getDesignItem(data, designRef)

            if (dataUtils.variantRelation.isVariantRelation(resolvedDesignRef)) {
                const scopedDesignItem = getDesignItem(
                    data,
                    dataUtils.variantRelation.extractToWithoutHash(resolvedDesignRef)
                )
                fixSingleDesignType(scopedDesignItem)
            } else {
                fixSingleDesignType(resolvedDesignRef)
            }
        })
    } else {
        fixSingleDesignType(designItem)
    }
}

const convertRgbToHex = color => {
    const rgba = str2rgba(color)
    const hex = rgb2hex(rgba[0], rgba[1], rgba[2])
    const alpha = rgba[3]
    return {
        color: hex,
        colorOpacity: alpha
    }
}

const isRgbOrRgba = color => isRgb(color) || isRgba(color)

const createColorLayerItem = (data, styleItem, uniqueIdGenerator) => {
    let styleColor = _.get(styleItem, 'style.properties.bg', 'color_11')

    let opacity
    if (isRgbOrRgba(styleColor)) {
        const {color, colorOpacity} = convertRgbToHex(styleColor)
        opacity = colorOpacity
        styleColor = color
    }

    if (_.isUndefined(opacity)) {
        opacity = parseFloat(_.get(styleItem, 'style.properties.alpha-bg', '1'))
    }

    const colorLayerItem = {
        type: 'SolidColorLayer',
        opacity: _.isFinite(opacity) ? opacity : 1,
        fill: {
            type: 'SolidColor',
            color: styleColor
        }
    }

    setDesignItem(data, colorLayerItem, uniqueIdGenerator)

    return colorLayerItem.id
}

const createBackgroundMediaDataItem = (data, styleItem, uniqueIdGenerator) => {
    const backgroundItem = {
        type: 'BackgroundMedia',
        colorLayers: [`#${createColorLayerItem(data, styleItem, uniqueIdGenerator)}`],
        colorOverlay: '',
        colorOverlayOpacity: 1
    }

    setDesignItem(data, backgroundItem, uniqueIdGenerator)

    return backgroundItem.id
}

const migrateVariantRelationStyleToDesign = (styleItem, data, uniqueIdGenerator) => {
    const scopedValue = getStyleItem(data, dataUtils.variantRelation.extractToWithoutHash(styleItem))

    const designVariantRelation = dataUtils.variantRelation.create({
        id: generateDesignId(uniqueIdGenerator),
        variants: dataUtils.variantRelation.extractVariantsWithoutHash(styleItem),
        from: styleItem.from,
        scopedDataId: migrateSingleStyleToDesign(scopedValue, data, uniqueIdGenerator)
    })

    setDesignItem(data, designVariantRelation, uniqueIdGenerator)

    return designVariantRelation.id
}

const migrateSingleStyleToDesign = (styleItem, data, uniqueIdGenerator) => {
    if (dataUtils.variantRelation.isVariantRelation(styleItem)) {
        return migrateVariantRelationStyleToDesign(styleItem, data, uniqueIdGenerator)
    }

    const designItem = {
        type: MediaContainerWithDividers,
        background: `#${createBackgroundMediaDataItem(data, styleItem, uniqueIdGenerator)}`
    }

    setDesignItem(data, designItem, uniqueIdGenerator)
    styleItem.style.properties['alpha-bg'] = '0'

    return designItem.id
}

const migrateStyleToDesign = (styleItem, data, uniqueIdGenerator) => {
    const designRefArray = dataUtils.refArray.create()

    if (dataUtils.refArray.isRefArray(styleItem)) {
        dataUtils.refArray.extractValues(styleItem).forEach(styleRef => {
            const resolvedStyleRef = getStyleItem(data, styleRef)
            const refArrayItemId = migrateSingleStyleToDesign(resolvedStyleRef, data, uniqueIdGenerator)

            designRefArray.values.push(`#${refArrayItemId}`)
        })
    } else {
        const refArrayItemId = migrateSingleStyleToDesign(styleItem, data, uniqueIdGenerator)

        designRefArray.values.push(`#${refArrayItemId}`)
    }

    setDesignItem(data, designRefArray, uniqueIdGenerator)

    return designRefArray.id
}

const fixPageData = (structure, data, uniqueIdGenerator) => {
    const styleQuery = COMP_DATA_QUERY_KEYS_WITH_STYLE.style
    const designQuery = COMP_DATA_QUERY_KEYS_WITH_STYLE.design
    data[NAMESPACE_MAPPING.design] = data[NAMESPACE_MAPPING.design] || {}
    const shouldRunFixer =
        _.isEmpty(data[NAMESPACE_MAPPING.design]) ||
        Object.values(data[NAMESPACE_MAPPING.design]).some(({type}) => type === MediaContainerDesignData)

    if (!shouldRunFixer) {
        return
    }

    const sectionsWithDesign = getChildren(structure).filter(
        compStructure => compStructure.componentType === 'responsive.components.Section' && compStructure[designQuery]
    )

    const sectionsToFix = getChildren(structure).filter(
        compStructure =>
            sectionsTypes[compStructure.componentType] && compStructure[styleQuery] && !compStructure[designQuery]
    )

    sectionsWithDesign.forEach(section => {
        const designItem = getDesignItem(data, section[designQuery])
        fixSectionDesignType(designItem, data)
    })

    sectionsToFix.forEach(section => {
        const styleId = section[styleQuery]
        const styleItem = getStyleItem(data, styleId)
        const designId = migrateStyleToDesign(styleItem, data, uniqueIdGenerator)
        section[designQuery] = `#${designId}`
    })
}

module.exports = {
    name: 'migrateResponsiveSectionStyleItemToDesign',
    version: 1,
    exec(pageJson, pageIdsArray, magicObject) {
        try {
            fixPageData(pageJson.structure, pageJson.data, magicObject.dataFixerUtils.uniqueIdGenerator)
        } catch (e) {
            const error = {
                errorType: 'migrateResponsiveSectionStyleItemToDesign',
                message: e.toString(),
                stack: e.stack,
                extras: {pageId: pageJson.structure.id}
            }
            magicObject.captureError(error)
            throw error
        }
    },
    fixerRequiresReruns: true
}
