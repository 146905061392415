import {ExtensionAPI, pointerUtils} from '@wix/document-manager-core'
import type {CompRef, Pointer, ScopePointer} from '@wix/document-services-types'
import _ from 'lodash'
import {SCOPE} from '../constants/constants'
import type {ScopesExtensionAPI} from '../extensions/scopes'
import {RefDelimiter} from './refStructureUtils'
import {repeaterDelimiter} from './repeaterUtils'

const SHARED_PARTS_PREFIX = 'sharedParts'

const {getPointer} = pointerUtils

export interface RepeaterAndScope {
    repeaterPointer: Pointer
    item: string
}

export const buildScopePath = (scopePointer: ScopePointer): string[] => {
    const {id, scope} = scopePointer
    if (!scope) {
        return [id]
    }

    return [...buildScopePath(scope), id]
}

const addScopeToInflatedId = (id: string, scope: string, enableRepeatersInScopes?: boolean): string => {
    if (enableRepeatersInScopes && scope.includes(repeaterDelimiter)) {
        const repeaterItem = scope.split(repeaterDelimiter)[1]
        return `${id}${repeaterDelimiter}${repeaterItem}`
    }

    return `${scope.split(repeaterDelimiter, 1)[0]}${RefDelimiter}${id}`
}

export const buildOldInflatedId = (pointer: Pointer, enableRepeatersInScopes?: boolean): string => {
    const {id, scope} = pointer

    if (!scope) {
        return id
    }

    const scopePath = buildScopePath(pointer.scope!)
    const reduceScopesToInflatedId = (accumulatedId: string, scopeId: string): string =>
        addScopeToInflatedId(accumulatedId, scopeId, enableRepeatersInScopes)

    return scopePath.reduceRight(reduceScopesToInflatedId, id)
}

export const getItemIdByInflatedId = (id: string, enableRepeatersInScopes?: boolean): string => {
    const itemId = id.split(RefDelimiter).pop()!

    if (enableRepeatersInScopes) {
        return itemId.split(repeaterDelimiter, 1)[0]
    }

    return itemId
}

export const getInflatedPointer = (compPointer: CompRef, enableRepeatersInScopes?: boolean) => {
    const inflatedPointer = {
        ...compPointer,
        id: getItemIdByInflatedId(compPointer.id, enableRepeatersInScopes)
    }
    inflatedPointer.id = buildOldInflatedId(inflatedPointer, enableRepeatersInScopes)

    return inflatedPointer
}

export const getScopedPointer = (id: string, type: string, scope?: ScopePointer, disableScopes?: boolean): Pointer => {
    const pointer = getPointer(id, type)

    if (!disableScopes && scope) {
        pointer.scope = scope
    }

    return pointer
}

const buildScopePointerByInflatedId = (id: string): ScopePointer | undefined => {
    const splittedId = id.split(RefDelimiter)
    splittedId.pop()

    if (splittedId.length > 0) {
        return _.reduce(
            splittedId,
            (scopePointer: ScopePointer | undefined, scopeId: string) => {
                if (scopeId === SHARED_PARTS_PREFIX) {
                    return scopePointer
                }

                return getScopedPointer(scopeId, 'scope', scopePointer) as ScopePointer
            },
            undefined
        )
    }
}

export const buildScopePointerByOwner = (id: string, scope: ScopePointer | undefined): ScopePointer =>
    getScopedPointer(_.last(id.split(RefDelimiter))!, SCOPE, scope ?? buildScopePointerByInflatedId(id)) as ScopePointer

export const removeMostOuterScope = (scopePointer: ScopePointer): ScopePointer | undefined => {
    const {id, type, scope} = scopePointer
    if (scope === undefined) {
        return undefined
    }

    return getScopedPointer(id, type, removeMostOuterScope(scope)) as ScopePointer
}

export const isRepeaterScope = (scope: ScopePointer): boolean => scope.id.includes(repeaterDelimiter)

export const getItemAndRepeaterFromScope = (scope: ScopePointer, viewMode: string = 'DESKTOP'): RepeaterAndScope => {
    if (!isRepeaterScope(scope)) {
        throw Error(`The given scope ${JSON.stringify(scope)} is not a repeater scope`)
    }

    const split = scope.id.split(repeaterDelimiter)

    return {
        item: split[1],
        repeaterPointer: getScopedPointer(split[0], viewMode, scope.scope)
    }
}

export const getPointerGetterConsideringScopes =
    (extensionAPI: ExtensionAPI) => (sourcePointer: Pointer, id: string) => {
        const {scopes} = extensionAPI as ScopesExtensionAPI
        const func = scopes?.getScopesPointerConsideringHybrid ?? getScopedPointer
        return func(id, sourcePointer.type, sourcePointer.scope)
    }

export const getRepeatersInScope = (compPointer: Pointer) => {
    const repeaters = []
    for (
        let scopePointer: ScopePointer | undefined = compPointer.scope;
        scopePointer;
        scopePointer = scopePointer.scope
    ) {
        if (isRepeaterScope(scopePointer)) {
            repeaters.push(getItemAndRepeaterFromScope(scopePointer, compPointer.type).repeaterPointer)
        }
    }
    return repeaters.reverse()
}
