import constants from '../constants/constants'
import type {PossibleViewModes, PS} from '@wix/document-services-types'

export default {
    /**
     *  returns document's view mode (mobile or desktop)- i.e. the view that's rendered, regardless of the displaying device
     *  @param ps
     *  @returns specifying MOBILE or DESKTOP
     */
    getViewMode(ps: PS): PossibleViewModes {
        return ps.siteAPI.isMobileView() ? constants.VIEW_MODES.MOBILE : constants.VIEW_MODES.DESKTOP
    },

    isMobileView(ps: PS): boolean {
        return ps.siteAPI.isMobileView()
    }
}
