import _ from 'lodash'
import * as conversionUtils from './conversionUtils'
import {conversionConfig} from './conversionConfig'
import type {ConversionData, Layout, DeepStructure, ConversionSettings} from '../types'
import {identifyBlocks} from './utils/blocksUtils'

export function flattenGroups(parent: DeepStructure, comps: DeepStructure[], settings: ConversionSettings): void {
    if (!comps) {
        return
    }

    const virtualGroupsToRemove = []
    let childIndex = 0
    while (childIndex < comps.length) {
        const child = comps[childIndex]
        if (conversionUtils.isVirtualGroup(child)) {
            conversionUtils.translateComps(child.components, child.layout.x, child.layout.y)
            conversionUtils.addComponentsTo(parent, child.components, childIndex + 1)
            virtualGroupsToRemove.push(child)
        }
        childIndex++
    }

    conversionUtils.removeChildrenFrom(parent, virtualGroupsToRemove)

    if (settings.recalculateBlocksOnGroupingAndUngrouping && virtualGroupsToRemove.length > 0) {
        identifyBlocks(parent, conversionUtils.getChildren(parent), settings)
    }

    _.forEach(comps, comp => flattenGroups(comp, comp.components, settings))
}

let nextGroupId = 1000

export interface VirtualGroupData {
    componentType: string
    conversionData: ConversionData
    layout: Layout
    components: DeepStructure[]
}

export function createVirtualComponent(virtualGroupData: VirtualGroupData): DeepStructure {
    const virtualGroupComp = <DeepStructure>(<unknown>_.assign(virtualGroupData, {
        type: 'undefined',
        id: `${_.toLower(virtualGroupData.componentType)}_${nextGroupId++}`,
        skin: 'undefined',
        styleId: 'undefined'
    }))

    return virtualGroupComp
}

export function createRescaleGroup(parent, components, conversionData, settings: ConversionSettings, index?: number): DeepStructure {
    const virtualGroup = createVirtualComponent({
        layout: conversionUtils.getSnugLayout(components),
        conversionData: _.assign(conversionData, {
            hasTightYMargin: true,
            isTightContainer: true,
            marginX: 0
        }),
        componentType: conversionConfig.VIRTUAL_GROUP_TYPES.RESCALE,
        components
    })
    conversionUtils.translateComps(components, -virtualGroup.layout.x, -virtualGroup.layout.y)
    conversionUtils.removeChildrenFrom(parent, components)
    conversionUtils.addComponentsTo(parent, [virtualGroup], index)

    if (settings.recalculateBlocksOnGroupingAndUngrouping) {
        identifyBlocks(parent, conversionUtils.getChildren(parent), settings)
    }

    return virtualGroup
}

export function reset() {
    nextGroupId = 1000
}
