import type {Extension, CreateExtArgs, ExtensionAPI} from '@wix/document-manager-core'
import type {SchemaExtensionAPI} from './schema/schema'

export interface ComponentDefinitionExtensionAPI extends ExtensionAPI {
    componentDefinition: {
        isRepeater(componentType: string): boolean
        isContainer(componentType: string): boolean
        isSection(componentType: string): boolean
        isPage(componentType: string): boolean
        isRefComponent(componentType: string): boolean
        getComponentSkins(componentType: string): string[]
        getComponentResponsiveSkins(componentType: string): string[]
    }
}
const createExtension = (): Extension => ({
    name: 'componentDefinition',
    createExtensionAPI({extensionAPI}: CreateExtArgs): ComponentDefinitionExtensionAPI {
        const {schemaAPI} = extensionAPI as SchemaExtensionAPI

        const containerTypesSet = new Set(['Page', 'Container', 'Document', 'RepeaterContainer', 'RefComponent'])

        const getComponentType = (componentType: string) => schemaAPI.getDefinition(componentType)?.type
        const isContainer = (componentType: string) => containerTypesSet.has(getComponentType(componentType))
        const isSection = (componentType: string) =>
            ['wysiwyg.viewer.components.ClassicSection', 'responsive.components.Section'].includes(componentType)
        const isPage = (componentType: string) => getComponentType(componentType) === 'Page'
        const isRepeater = (componentType: string) => getComponentType(componentType) === 'RepeaterContainer'
        const isRefComponent = (componentType: string) => getComponentType(componentType) === 'RefComponent'

        const getComponentSkins = (componentType: string) => schemaAPI.getDefinition(componentType)?.skins ?? []
        const getComponentResponsiveSkins = (componentType: string) =>
            schemaAPI.getDefinition(componentType)?.responsiveSkins ?? getComponentSkins(componentType)

        return {
            componentDefinition: {
                isRepeater,
                isContainer,
                isSection,
                isPage,
                isRefComponent,
                getComponentSkins,
                getComponentResponsiveSkins
            }
        }
    },
    dependencies: new Set(['schema'])
})

export {createExtension}
