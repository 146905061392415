import type {Pointer, PS, CompRef} from '@wix/document-services-types'
import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import connections from '../../connections/connections'
import component from '../../component/component'
import dataModel from '../../dataModel/dataModel'
import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'
import platformAppComp from './utils/platformAppComp'
import appControllerUtils from '../../appControllerData/appControllerUtils'

function removeControllerConnections(ps: PS, controllerRef: Pointer) {
    const connectedComponents = connections.getConnectedComponentsForController(ps, controllerRef as CompRef, true)

    _.forEach(connectedComponents, function (comp) {
        connections.disconnect(ps, comp, controllerRef)
    })

    const mobileControllerRef = ps.pointers.structure.getMobilePointer(controllerRef)
    if (controllerRef.type === 'DESKTOP' && ps.dal.isExist(mobileControllerRef)) {
        removeControllerConnections(ps, mobileControllerRef)
    }
}

function handleControllerDeletion(ps: PS, controllerRef: Pointer) {
    removeControllerConnections(ps, controllerRef)
    platformAppComp.removePlatformApplicationFromPageIfLastController(ps, controllerRef)
    platformAppComp.removeControllerFromStateMap(ps, controllerRef)
}

function addRelatedConnections(ps: PS, controllerRef: Pointer, customStructureData) {
    const connectedComponents = connections.getConnectedComponents(ps, controllerRef)
    if (_.isEmpty(connectedComponents)) {
        return
    }
    const relatedConnections = _.transform(
        connectedComponents,
        function (acc, compRef) {
            acc[compRef.id] = _.filter(connections.get(ps, compRef), {controllerRef})
        },
        {}
    )
    _.set(customStructureData, 'relatedConnections', relatedConnections)
}

function handleControllerSerialization(ps: PS, controllerRef: Pointer, customStructureData) {
    addRelatedConnections(ps, controllerRef, customStructureData)
    platformAppComp.addControllerStateToSerializedStructure(ps, controllerRef, customStructureData)
}

function getRelatedConnections(controllerDefinition, oldToNewIdMap) {
    return (
        _(controllerDefinition)
            .chain()
            // @ts-expect-error
            .get('custom.relatedConnections')
            // @ts-expect-error
            .transform(function (acc, compConnections, compId) {
                acc[_.get(oldToNewIdMap, compId, compId)] = compConnections
            }, {})
            .value()
    )
}

function reconnectComponentsToController(componentsToReconnect, relatedConnections, ps: PS, controllerRef: Pointer) {
    _.forEach(componentsToReconnect, function (compRef) {
        const compConnections = _.get(relatedConnections, compRef.id, [])
        _.forEach(compConnections, function (connectionItem) {
            connections.connect(
                ps,
                compRef,
                controllerRef,
                connectionItem.role,
                connectionItem.config,
                connectionItem.isPrimary
            )
        })
    })
}

function reconnectComponents(
    ps: PS,
    controllerRef: Pointer,
    controllerDefinition,
    optionalCustomId: string,
    oldToNewIdMap
) {
    const relatedConnections = getRelatedConnections(controllerDefinition, oldToNewIdMap)
    const connectedComponentIds = _.keys(relatedConnections)
    const controllerPageId = component.getPage(ps, controllerRef as CompRef).id
    const pageToGetCompsFrom =
        controllerPageId === santaCoreUtils.siteConstants.MASTER_PAGE_ID ? null : controllerPageId
    const componentsToReconnect = componentDetectorAPI.getAllComponentsFromFull(ps, pageToGetCompsFrom, compRef =>
        _.includes(connectedComponentIds, compRef.id)
    )

    reconnectComponentsToController(componentsToReconnect, relatedConnections, ps, controllerRef)
}

function handleControllerAddition(
    ps: PS,
    controllerRef: Pointer,
    controllerDefinition,
    optionalCustomId: string,
    mappers
) {
    reconnectComponents(ps, controllerRef, controllerDefinition, optionalCustomId, mappers.oldToNewIdMap)
    platformAppComp.setControllerStateFromSerializedStructure(ps, controllerRef, controllerDefinition)
    platformAppComp.addPlatformAppToPageIfFirstController(
        ps,
        controllerRef,
        appControllerUtils.getControllerAppDefinitionId(controllerDefinition.data)
    )
}

function removeInvalidConnections(ps: PS, controllerRef: Pointer, newParentPointer: Pointer) {
    const newPageId = component.getPage(ps, newParentPointer as CompRef).id
    if (newPageId === santaCoreUtils.siteConstants.MASTER_PAGE_ID) {
        return
    }
    const connectedComponents = connections.getConnectedComponents(ps, controllerRef)
    _(connectedComponents)
        .reject(comp => component.getPage(ps, comp).id === newPageId)
        .forEach(comp => {
            connections.disconnect(ps, comp, controllerRef)
        })
}

function handleControllerParentChange(ps: PS, controllerRef: Pointer, newParentPointer: CompRef) {
    const dataItem = dataModel.getDataItem(ps, controllerRef)
    removeInvalidConnections(ps, controllerRef, newParentPointer)
    platformAppComp.removePlatformApplicationFromPageIfLastController(ps, controllerRef)
    platformAppComp.addPlatformAppToPageIfFirstController(
        ps,
        controllerRef,
        appControllerUtils.getControllerAppDefinitionId(dataItem),
        component.getPage(ps, newParentPointer)
    )
}

export default {
    //Before Remove
    handleControllerDeletion,
    //Custom Serialize
    handleControllerSerialization,
    //After Add
    handleControllerAddition,
    //Change Parent
    handleControllerParentChange
}
