import type {Callback3, Layout, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'

function isCompResizing(oldLayout: Layout, newLayout: Layout) {
    return (
        (_.isNumber(newLayout.width) && newLayout.width !== oldLayout.width) ||
        (_.isNumber(newLayout.height) && newLayout.height !== oldLayout.height)
    )
}

const isCompRescaling = (oldLayout: Layout, newLayout: Layout) =>
    _.isNumber(newLayout.scale) && newLayout.scale !== oldLayout.scale

const calculateDelta = (valueA: number, valueB: number) => (_.isUndefined(valueA) ? 0 : valueA - valueB)

export default {
    isCompRescaling,
    isCompResizing,
    calculateDelta
}

export type LayoutCallback = Callback3<PS, Pointer, Layout>
