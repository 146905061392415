'use strict'

const _ = require('lodash')
const coreUtils = require('@wix/santa-core-utils')
const dataUtils = require('../../helpers/dataUtils')

const CART_PAGE_WIDGET_ID = '1380bbab-4da3-36b0-efb4-2e0599971d14'

/**
 * Fixes for CartPage tpa component:
 * - componentLayout height to be 'auto'
 * */
const fixLayout = (cartPageTPAComp, layoutData) => {
    const layoutDataItems = dataUtils.extractLayoutDataItems(layoutData, cartPageTPAComp)
    _.forEach(layoutDataItems, layoutDataItem => {
        const componentLayout =
            layoutDataItem.type === 'SingleLayoutData' ? layoutDataItem.componentLayout : layoutDataItem
        if (componentLayout.type === 'ComponentLayout' && componentLayout.height) {
            // componentLayout.type maybe of any type here in case it is legacy layoutDataItem
            componentLayout.height.type = 'auto'
            delete componentLayout.height.value
        }
    })
}

const getCompData = (component, documentData) => documentData[dataUtils.stripHashIfExists(component.dataQuery)] || {}

const getCartPageTPA = pageJson => {
    const documentData = pageJson.data.document_data
    return coreUtils.dataUtils.findCompInStructure(
        pageJson.structure,
        false,
        component =>
            component.componentType === 'wysiwyg.viewer.components.tpapps.TPAMultiSection' &&
            getCompData(component, documentData).widgetId === CART_PAGE_WIDGET_ID
    )
}

/**
 * @exports utils/dataFixer/plugins/tpa/tpaCartPageResponsiveLayoutFixer
 * @type {{exec: function}}
 */
module.exports = {
    name: 'tpaCartPageResponsiveLayoutFixer',
    version: 1,
    exec(pageJson) {
        const shouldFixLayout = !!pageJson.data.layout_data
        if (shouldFixLayout) {
            const cartPageTPAComp = getCartPageTPA(pageJson)
            if (cartPageTPAComp && cartPageTPAComp.layoutQuery) {
                fixLayout(cartPageTPAComp, pageJson.data.layout_data)
            }
        }
    },
    getCartPageTPA
}
