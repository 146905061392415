module.exports = {
    'wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryDefaultSkin': 'wysiwyg.viewer.components.MatrixGallery',
    'wysiwyg.viewer.skins.gallerymatrix.TextBottomCustomHeightSkin': 'wysiwyg.viewer.components.MatrixGallery',
    'wysiwyg.viewer.skins.gallerymatrix.PolaroidCustomHeightSkin': 'wysiwyg.viewer.components.MatrixGallery',
    'wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryLiftedShadow': 'wysiwyg.viewer.components.MatrixGallery',
    'wysiwyg.viewer.skins.gallerymatrix.MatrixGallerySloopy': 'wysiwyg.viewer.components.MatrixGallery',
    'wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryCircleSkin': 'wysiwyg.viewer.components.MatrixGallery',
    'wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryTextSlideUpSkin': 'wysiwyg.viewer.components.MatrixGallery',
    'skins.viewer.gallerymatrix.MatrixGalleryIronSkin': 'wysiwyg.viewer.components.MatrixGallery',
    'skins.viewer.gallerymatrix.MatrixGalleryScotchTapeSkin': 'wysiwyg.viewer.components.MatrixGallery',
    'wysiwyg.common.components.matrixgallery.viewer.skins.MatrixGalleryTextOnCenterSkin':
        'wysiwyg.viewer.components.MatrixGallery',
    'wysiwyg.common.components.matrixgallery.viewer.skins.MatrixGallerySeparateTextBoxSkin':
        'wysiwyg.viewer.components.MatrixGallery',
    'wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryTransparentSkin': 'wysiwyg.viewer.components.MatrixGallery',
    'wysiwyg.viewer.skins.paginatedgrid.PaginatedGridDefaultSkin': 'wysiwyg.viewer.components.PaginatedGridGallery',
    'wysiwyg.viewer.skins.paginatedgrid.PaginatedGridArrowsOutside': 'wysiwyg.viewer.components.PaginatedGridGallery',
    'wysiwyg.viewer.skins.paginatedgrid.PaginatedGridOverlay': 'wysiwyg.viewer.components.PaginatedGridGallery',
    'wysiwyg.viewer.skins.paginatedgrid.PaginatedGridRibbonArrows': 'wysiwyg.viewer.components.PaginatedGridGallery',
    'wysiwyg.viewer.skins.paginatedgrid.PaginatedGridTextBottom': 'wysiwyg.viewer.components.PaginatedGridGallery',
    'wysiwyg.viewer.skins.galleryslider.SliderGalleryDefaultSkin': 'wysiwyg.viewer.components.SliderGallery',
    'wysiwyg.viewer.skins.galleryslider.SliderGalleryCircleSkin': 'wysiwyg.viewer.components.SliderGallery',
    'wysiwyg.viewer.skins.galleryslider.SliderGalleryNoArrow': 'wysiwyg.viewer.components.SliderGallery',
    'wysiwyg.viewer.skins.galleryslider.SliderGalleryIronSkin': 'wysiwyg.viewer.components.SliderGallery',
    'wysiwyg.viewer.skins.galleryslider.SliderGalleryScotchTapeSkin': 'wysiwyg.viewer.components.SliderGallery',
    'wysiwyg.viewer.skins.gallery.SlideShowTextOverlay': 'wysiwyg.viewer.components.SlideShowGallery',
    'wysiwyg.viewer.skins.gallery.SlideShowTextFloating': 'wysiwyg.viewer.components.SlideShowGallery',
    'wysiwyg.viewer.skins.gallery.SlideShowTextRight': 'wysiwyg.viewer.components.SlideShowGallery',
    'wysiwyg.viewer.skins.gallery.SlideShowPolaroid': 'wysiwyg.viewer.components.SlideShowGallery',
    'wysiwyg.viewer.skins.gallery.SlideShowTextBottom': 'wysiwyg.viewer.components.SlideShowGallery',
    'wysiwyg.viewer.skins.gallery.SlideShowGallerySloopy': 'wysiwyg.viewer.components.SlideShowGallery',
    'wysiwyg.viewer.skins.gallery.SlideShowGalleryLiftedShadowSkin': 'wysiwyg.viewer.components.SlideShowGallery',
    'skins.viewer.gallery.SlideShowIron': 'wysiwyg.viewer.components.SlideShowGallery',
    'skins.viewer.gallery.SlideShowCleanAndSimple2': 'wysiwyg.viewer.components.SlideShowGallery',
    'skins.viewer.gallery.SlideShowScotchTape': 'wysiwyg.viewer.components.SlideShowGallery',
    'skins.viewer.gallery.SlideShowCleanAndSimple': 'wysiwyg.viewer.components.SlideShowGallery',
    'wysiwyg.viewer.skins.TPAMasonrySkin': 'tpa.viewer.components.Masonry',
    'wysiwyg.viewer.skins.TPAAccordionSkin': 'tpa.viewer.components.Accordion',
    'wysiwyg.viewer.skins.TPAImpressSkin': 'tpa.viewer.components.Impress',
    'wysiwyg.viewer.skins.TPAFreestyleSkin': 'tpa.viewer.components.Freestyle',
    'wysiwyg.viewer.skins.TPACollageSkin': 'tpa.viewer.components.Collage',
    'wysiwyg.viewer.skins.TPAHoneycombSkin': 'tpa.viewer.components.Honeycomb',
    'wysiwyg.viewer.skins.TPAStripShowcaseSkin': 'tpa.viewer.components.StripShowcase',
    'wysiwyg.viewer.skins.TPAStripSlideshowSkin': 'tpa.viewer.components.StripSlideshow',
    'wysiwyg.viewer.skins.TPAThumbnailsSkin': 'tpa.viewer.components.Thumbnails',
    'wysiwyg.viewer.skins.TPA3DGallerySkin': 'wysiwyg.viewer.components.tpapps.TPA3DGallery',
    'wysiwyg.viewer.skins.TPA3DCarouselSkin': 'wysiwyg.viewer.components.tpapps.TPA3DCarousel'
}
