import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import constants from '../constants/constants'
import generalInfo from '../siteMetadata/generalInfo'
import siteName from '../siteMetadata/siteName'

const coBrandedValues = {
    None: false,
    CoBranded: true,
    CoBranded_Customer_New: true,
    CoBranded_Customer_Existing: true
}
const CO_BRANDED_DEFAULT = 'None'

function hasSites(ps: PS): boolean {
    const value = ps.dal.get(ps.pointers.general.hasSites())

    if (_.isNil(value)) {
        const usedSiteNames = siteName.getUsedSiteNames(ps)
        return _.get(usedSiteNames, ['length'], 0) > 0
    }

    return value
}

function isCoBranded(ps: PS): boolean {
    const coBrandedPointer = ps.pointers.accountInfo.getCoBranding()
    const coBranding = ps.dal.get(coBrandedPointer)
    return !!coBrandedValues[coBranding]
}

/**
 * Returns whether the user can upgrade the site
 * @param ps
 * @returns {boolean}
 */
function canUserUpgrade(ps: PS) {
    const permissions = new Set(generalInfo.getUserPermissions(ps))
    return permissions.has(constants.PERMISSIONS.CONNECT_DOMAIN) || permissions.has(constants.PERMISSIONS.UPGRADE)
}

/**
 * @param {ps} ps
 * @returns {string}
 */
function getCoBrandedType(ps: PS) {
    const coBrandedPointer = ps.pointers.accountInfo.getCoBranding()
    const coBranding = ps.dal.get(coBrandedPointer)
    return _.includes(_.keys(coBrandedValues), coBranding) ? coBranding : CO_BRANDED_DEFAULT
}

export default {
    hasSites,
    isCoBranded,
    canUserUpgrade,
    getCoBrandedType
}
