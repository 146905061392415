import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'
import namespaces from '../../namespaces/namespaces'
import constants from '../../constants/constants'
import reactions from '../../reactions/reactions'
import triggers from '../../triggers/triggers'
import states from '../../states/states'
import type {DsFixer} from '../dsDataFixers'

const STATES_NAMESPACE = constants.DATA_TYPES.states
const STATES_TYPE = 'States'

const getANewStateName = (ps: PS, compRef: Pointer) => {
    const allStates = states.getAll(ps, compRef)
    return `Hover ${allStates.length === 0 ? '' : allStates.length + 1}`.trim()
}

const getExistingStatesValues = (ps: PS, compRef: Pointer) => {
    const compStatesData = namespaces.getNamespaceData(ps, compRef, STATES_NAMESPACE)
    return compStatesData?.values || []
}

const MIGRATING_HOVER_VARIANT_TO_STATE_WHILE_THERE_IS_HOVER_TRIGGER =
    'MIGRATING_HOVER_VARIANT_TO_STATE_WHILE_THERE_IS_HOVER_TRIGGER'
const logTheHoverVariantAndHoverTriggerVariantFoundTogetherWhileMigrating = (
    ps: PS,
    compRef: Pointer,
    pageId: string,
    hoverTriggers,
    hoverPointer: Pointer
) => {
    ps.extensionAPI.logger.interactionStarted(MIGRATING_HOVER_VARIANT_TO_STATE_WHILE_THERE_IS_HOVER_TRIGGER, {
        extras: {
            pageId,
            compRef,
            triggerVariantsIds: hoverTriggers.map(trigger => trigger.id),
            hoverVariantId: hoverPointer.id
        }
    })
    ps.extensionAPI.logger.interactionEnded(MIGRATING_HOVER_VARIANT_TO_STATE_WHILE_THERE_IS_HOVER_TRIGGER)
}

const addHoverTriggerIfThereIsNoSuch = (ps: PS, compRef: Pointer, pageId: string, hoverPointer: Pointer) => {
    const existingHoverTriggers = ps.pointers.data.getVariantItemsWithPredicate(
        {type: 'Trigger', trigger: 'hover', componentId: compRef.id},
        pageId
    )
    if (existingHoverTriggers.length > 0) {
        logTheHoverVariantAndHoverTriggerVariantFoundTogetherWhileMigrating(
            ps,
            compRef,
            pageId,
            existingHoverTriggers,
            hoverPointer
        )
        return existingHoverTriggers[0]
    }
    const newHoverTrigger = triggers.getTriggerVariantToAddRef(ps, compRef)
    triggers.addTrigger(ps, newHoverTrigger, compRef, {trigger: 'hover'})
    return newHoverTrigger
}

const MIGRATE_HOVER_TO_STATE_REACTION_FIXER = 'migrate_hover_to_state_reaction_fixer'
const makeHoverVariantsStatesVariants = (ps: PS) => {
    const allPages = ps.pointers.page.getNonDeletedPagesPointers(true)
    const allDesktopPages = _.map(allPages, ps.pointers.components.getDesktopPointer)
    const toMigrate = _.map(allDesktopPages, (page: any) => {
        const hoverPointers = ps.pointers.data.getVariantItemsWithPredicate(
            variant => variant.type === 'Hover',
            page.id
        )
        return {page, hoverPointers}
    })

    ps.extensionAPI.logger.interactionStarted(MIGRATE_HOVER_TO_STATE_REACTION_FIXER)

    // migrating the hover variant to a state variant, maintaining the id
    _.forEach(toMigrate, item => {
        item.hoverPointers.forEach(hoverPointer => {
            const data = ps.dal.get(hoverPointer)
            const compRef = componentDetectorAPI.getComponentById(ps, data.componentId)

            states.update(ps, compRef, hoverPointer, {type: 'State', name: getANewStateName(ps, compRef)})

            const statesValues = [...getExistingStatesValues(ps, compRef), `#${data.id}`]
            namespaces.updateNamespaceData(ps, compRef, STATES_NAMESPACE, {type: STATES_TYPE, values: statesValues})

            const hoverTrigger = addHoverTriggerIfThereIsNoSuch(ps, compRef, item.page.id, hoverPointer)
            reactions.add(ps, compRef, hoverTrigger, {type: 'AddState', state: hoverPointer})
        })
    })

    ps.extensionAPI.logger.interactionEnded(MIGRATE_HOVER_TO_STATE_REACTION_FIXER)
}

const fixer: DsFixer = {
    exec(ps: PS) {
        try {
            makeHoverVariantsStatesVariants(ps)
        } catch (e) {
            const err = new Error('Failed to migrate hover to state reaction')
            ps.extensionAPI.logger.captureError(err, {
                extras: {
                    originalError: e
                }
            })
            throw e
        }
    },
    name: 'hoverToStateReactionFixer',
    version: 2 //bumped when merging since this used an experiment without experimentalVersions
}
export default fixer
