import type {ViewerManager, ViewerSiteAPI} from '@wix/viewer-manager-adapter'
import _ from 'lodash'
import type {SetOperationsQueueBatch} from './SOQBatch'
import type {QItemParams} from './SOQItem'
import type {CoreLogger} from '@wix/document-manager-core'
import {IssueSeverity, ReportableError, ReportableIssueParams, ReportableWarning} from '@wix/document-manager-utils'

interface Args {
    logger: CoreLogger
    viewerManager: ViewerManager
    errorMessage: string
    batchItems: QItemParams[]
    tagName: string
    errorName: string
    errorCode: number
    waitingFor: any
    batchWaitingForTransition: boolean
    issueSeverity: IssueSeverity
}

const getViewerInstanceName = () => (_.has(window, ['boltInstance']) ? 'boltInstance' : 'tbInstance')
const getInfoFromViewerInstance = () => ({
    dataRequirementCheckers: JSON.stringify(
        _.omitBy(_.get(window, [getViewerInstanceName(), 'dataRequirementCheckers'], {})),
        null,
        4
    )
})

function getRelevantReportableIssue({
    errorType,
    message,
    tags,
    extras,
    issueSeverity
}: ReportableIssueParams): ReportableError | ReportableWarning {
    const issueParams = {
        errorType,
        message,
        tags,
        extras
    }
    let reportableIssue: ReportableError | ReportableWarning

    if (issueSeverity === 'warning') {
        reportableIssue = new ReportableWarning(issueParams)
    } else {
        reportableIssue = new ReportableError(issueParams)
    }

    return reportableIssue
}

const reportError = ({
    logger,
    viewerManager,
    errorMessage,
    batchItems,
    tagName,
    errorName,
    errorCode,
    waitingFor,
    batchWaitingForTransition,
    issueSeverity
}: Args) => {
    const appLoaded = _.get(window, ['APP_LOADED'], false)
    const methodNames = JSON.stringify(_.map(batchItems, 'methodName'), null, 4)
    const debugInfoFromViewerAPI = viewerManager.viewerSiteAPI.getDebugInfo?.()
    const viewerDebugInfo = debugInfoFromViewerAPI ? debugInfoFromViewerAPI : getInfoFromViewerInstance()

    const reportableIssue = getRelevantReportableIssue({
        errorType: errorName,
        message: errorMessage,
        tags: {[tagName]: true, appLoaded, waitingFor, batchWaitingForTransition},
        extras: {methodNames, ...viewerDebugInfo},
        issueSeverity
    })
    logger.captureError(reportableIssue)

    // Bundle these together because there's a 4 parameter limit in reportBIError (not counting errorCode & errorName)
    const loadedAndWaiting = JSON.stringify({appLoaded, waitingFor})
    viewerManager.viewerSiteAPI.reportBIError(
        errorCode,
        errorName,
        methodNames,
        viewerDebugInfo.dataRequirementCheckers as string,
        loadedAndWaiting
    )
}

function isBatchWaitingForTransition(batch: SetOperationsQueueBatch) {
    return _.some(batch.activeItems, {waitingForTransition: true} as any)
}

/** Wait for pending async viewer updates to finish, if necessary
 *
 * The aforementioned viewer updates are:
 * - View mode switch
 * - Navigation
 * - Data requirements
 * - Layout
 */
export async function waitForViewerLoaded(viewerSiteAPI: ViewerSiteAPI) {
    await viewerSiteAPI.waitForViewer()
}

//in santa we always listen to did layout, and if there is no batch in progress do stuff
//in bolt, if we have layout we probably have to do the same, and if we don't I'm not sure
export async function flushBatch(
    commit: () => void,
    viewerManager: ViewerManager,
    logger: CoreLogger,
    batch: SetOperationsQueueBatch
) {
    const {viewerSiteAPI} = viewerManager
    let waitingFor = 'nothing'
    let wasQueueWaitingTooLong = false
    const timeoutHandle = setTimeout(() => {
        wasQueueWaitingTooLong = true
        reportError({
            logger,
            viewerManager,
            errorMessage: 'ds soq timeout',
            batchItems: batch.items,
            tagName: 'ds_soq',
            errorName: 'DS_SOQ_TIMEOUT',
            errorCode: 65536,
            waitingFor,
            batchWaitingForTransition: isBatchWaitingForTransition(batch),
            issueSeverity: 'warning'
        })
    }, 35000)

    //what happens if undo triggers a page transition - we can compare currentPage and pageToNavigateTo
    try {
        commit()
    } catch (exception) {
        clearTimeout(timeoutHandle)
        throw exception
    }

    waitingFor = 'viewer'
    await viewerSiteAPI.waitForViewer()
    waitingFor = 'nothing'
    clearTimeout(timeoutHandle)

    if (wasQueueWaitingTooLong) {
        wasQueueWaitingTooLong = false
        reportError({
            logger,
            viewerManager,
            errorMessage: 'ds soq timeout ended',
            batchItems: batch.items,
            tagName: 'ds_soq_timeout_ended',
            errorName: 'DS_SOQ_TIMEOUT_ENDED',
            errorCode: 65537,
            waitingFor,
            batchWaitingForTransition: isBatchWaitingForTransition(batch),
            issueSeverity: 'warning'
        })
    }
}
