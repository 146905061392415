import type {UserInfo} from '@wix/document-services-types'
import {getHttpResponseExtras, ReportableError} from '@wix/document-manager-utils'

const WIX_COM = 'wix.com'
const NO_HEALTHY_UPSTREAM = 'no healthy upstream'
const CANARY_ERROR_MESSAGE =
    'no healthy upstream - this is an issue only for wix employees. Please be patient and refresh'

class CanaryError extends ReportableError {
    constructor(message: string, response: Response) {
        super({
            errorType: 'canaryError',
            message,
            extras: {
                ...getHttpResponseExtras(response),
                result: message
            }
        })
    }
}

export const checkForCanaryError = (message: string, response: Response, userInfo?: UserInfo): void => {
    if (!userInfo?.email) {
        return
    }
    const {email} = userInfo
    const domain = email.substring(email.lastIndexOf('@') + 1)
    if (domain === WIX_COM && message?.includes(NO_HEALTHY_UPSTREAM)) {
        throw new CanaryError(CANARY_ERROR_MESSAGE, response)
    }
}
