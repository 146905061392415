import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import pageData from '../../../page/pageData'
import type {SaveTaskDefinition} from '../../../saveAPI/lib/registry'
import clientSpecMap from '../../../siteMetadata/clientSpecMap'
import provisionService from '../../../tpa/services/provisionService'
import blogUtils from '../blogUtils'

const WIXAPPS_OLD_BLOG_APP_DEFID = '61f33d50-3002-4882-ae86-d319c1a249ab'
const TASK_NAME = 'wixappsSavePermissionsPlugin'
const SNAPSHOT_TAGS = ['primary']

const updateClientSpecMapWithEntry = (ps: PS, clientSpecMapAppData: any) => {
    clientSpecMap.registerAppData(ps, clientSpecMapAppData)
}

const updateDataFromServerOnResolve = (ps: PS, operationDescription: any, resolve: any, reject: any) => (data: any) => {
    if (data?.success) {
        updateClientSpecMapWithEntry(ps, data.payload)
        resolve()
    } else {
        santaCoreUtils.log.error(
            `wixapps blog "${operationDescription}" operation failed on MetaSite, errorCode: ${data.errorCode}, errorDecription: ${data.errorDescription}`
        )
        reject()
    }
}

const isWixappsBlogFoundOnSite = (ps: PS) => _.some(pageData.getPagesList(ps), _.partial(blogUtils.isBlogPage, ps))
const isWixappDeleted = (clientSpecMapWixappEntry: any) => clientSpecMapWixappEntry.state === 'Deleted'

const handleWixappsBlogOnSave = (ps: PS, resolve: any, reject: any) => {
    const blogClientSpecMapEntry = clientSpecMap.getAppDataByAppDefinitionId(ps, WIXAPPS_OLD_BLOG_APP_DEFID)
    const isWixappsBlogFoundOnClientSpecMap = !!blogClientSpecMapEntry
    if (isWixappsBlogFoundOnClientSpecMap) {
        const isBlogPageComponentFound = isWixappsBlogFoundOnSite(ps)
        const isWixappRevoked = isWixappDeleted(blogClientSpecMapEntry)
        if (isBlogPageComponentFound && isWixappRevoked) {
            provisionService.restoreWixappOnServer(
                ps,
                blogClientSpecMapEntry.appDefinitionId,
                updateDataFromServerOnResolve(ps, 'restore', resolve, reject),
                reject
            )
        } else if (!isBlogPageComponentFound && !isWixappRevoked) {
            provisionService.disableWixappOnServer(
                ps,
                blogClientSpecMapEntry.appDefinitionId,
                updateDataFromServerOnResolve(ps, 'disable', resolve, reject),
                reject
            )
        } else if (!isBlogPageComponentFound && isWixappRevoked) {
            resolve()
        } else if (isBlogPageComponentFound && !isWixappRevoked) {
            resolve()
        }
    } else {
        resolve()
    }
}

const create = (ps: PS): SaveTaskDefinition => ({
    partialSave(lastImmutableSnapshot, currentImmutableSnapshot, resolve, reject) {
        handleWixappsBlogOnSave(ps, resolve, reject)
    },

    fullSave(lastImmutableSnapshot, currentImmutableSnapshot, resolve, reject) {
        handleWixappsBlogOnSave(ps, resolve, reject)
    },

    saveAsTemplate(lastImmutableSnapshot, currentImmutableSnapshot, resolve) {
        resolve(undefined)
    },

    publish(currentImmutableSnapshot, extensionAPI, resolve) {
        resolve()
    },

    getTaskName() {
        return TASK_NAME
    },

    getSnapshotTags() {
        return SNAPSHOT_TAGS
    }
})

export default {
    create
}
