import _ from 'lodash'

let origin = {}

function setOrigin(originPassedByEditor = {}) {
    origin = originPassedByEditor
}

const getOrigin = () => origin

const getEditorType = () => _.get(origin, 'type', '')

export default {
    setOrigin,
    getOrigin,
    getEditorType
}
