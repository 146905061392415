import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import pages from '../../../page/page'

function markAsPreset(ps: PS, pageDataItems, pageId: string) {
    _.forEach(pageDataItems, dataItem => {
        dataItem.metaData.isPreset = true
        const dataItemPointer = ps.pointers.data.getDataItem(dataItem.id, pageId)
        ps.dal.set(dataItemPointer, dataItem)
    })
}

export default function markDataItemsAsPreset(ps: PS) {
    const pageIdToDataItemsMap = _(pages.getPageIdList(ps, true))
        .transform((acc, pageId) => {
            acc[pageId] = ps.pointers.page.getPageData(pageId)
        }, {})
        .mapValues(ps.dal.get.bind(ps.dal))
        .mapValues(dataItems => _.pickBy(dataItems, 'metaData'))
        .value()

    _.forEach(pageIdToDataItemsMap, markAsPreset.bind(null, ps))
}
