import responsiveWidgetConfig from './responsiveWidgetConfig'
import classicWidgetConfig from './classicWidgetConfig'

const createNewWidgetConfig = () => classicWidgetConfig
const createNewResponsiveWidgetConfig = () => responsiveWidgetConfig

export default {
    createNewWidgetConfig,
    createNewResponsiveWidgetConfig
}
