import type {AppDefinitionId, PS} from '@wix/document-services-types'
import _ from 'lodash'
import experiment from 'experiment-amd'

const setAppPendingAction = (ps: PS, appDefinitionId: AppDefinitionId, pendingAction) => {
    if (experiment.isOpen('dm_removePendingActions')) {
        return
    }
    const appStatePointer = ps.pointers.platform.getAppInstallationStatePointer(appDefinitionId)
    const state = ps.dal.get(appStatePointer) || {}
    state.pendingAction = pendingAction
    ps.dal.set(appStatePointer, state)
}

const getAppPendingAction = (ps: PS, appDefinitionId: AppDefinitionId) => {
    if (experiment.isOpen('dm_removePendingActions')) {
        return
    }
    const appStatePointer = ps.pointers.platform.getAppInstallationStatePointer(appDefinitionId)
    return _.get(ps.dal.get(appStatePointer), 'pendingAction')
}

const clearPendingActions = (ps: PS) => {
    if (experiment.isOpen('dm_removePendingActions')) {
        return
    }
    const appsStatePointer = ps.pointers.platform.getAppsInstallationStatePointer()
    const appsState = ps.dal.get(appsStatePointer) || {}
    _.forEach(appsState, state => {
        delete state.pendingAction
    })

    ps.dal.set(appsStatePointer, appsState)
}

const clearAppPendingAction = (ps: PS, appDefinitionId: AppDefinitionId) => {
    if (experiment.isOpen('dm_removePendingActions')) {
        return
    }
    const appStatePointer = ps.pointers.platform.getAppInstallationStatePointer(appDefinitionId)
    const state = ps.dal.get(appStatePointer) || {}
    delete state.pendingAction

    ps.dal.set(appStatePointer, state)
}

const setUnusedApps = (ps: PS, unusedApps) => {
    if (experiment.isOpen('dm_removePendingActions')) {
        return
    }
    _.forEach(unusedApps, unusedApp => {
        const appStatePointer = ps.pointers.platform.getAppInstallationStatePointer(unusedApp.appDefinitionId)
        const state = ps.dal.get(appStatePointer) || {}
        state.unused = true
        ps.dal.set(appStatePointer, state)
    })
}

const getAppsState = (ps: PS) => {
    if (experiment.isOpen('dm_removePendingActions')) {
        return {}
    }

    const appsStatePointer = ps.pointers.platform.getAppsInstallationStatePointer()
    const appsState = ps.dal.get(appsStatePointer) || {}
    return appsState
}

const setAppAsUsed = (ps: PS, appDefId: string) => {
    if (experiment.isOpen('dm_removePendingActions')) {
        return
    }
    const appStatePointer = ps.pointers.platform.getAppInstallationStatePointer(appDefId)
    const state = ps.dal.get(appStatePointer) || {}
    delete state.unused
    ps.dal.set(appStatePointer, state)
}

export default {
    getAppPendingAction,
    setAppPendingAction,
    clearPendingActions,
    clearAppPendingAction,
    setUnusedApps,
    setAppAsUsed,
    getAppsState
}
