'use strict'

const _ = require('lodash')

const textTypes = ['RichText', 'Text', 'StyledText', 'MediaRichText']

function fixTextNodes(dataMap) {
    return _(dataMap)
        .pickBy(dataNode => _.includes(textTypes, dataNode.type))
        .mapValues(textData => {
            textData.text = removeObjectElements(textData.text)
            textData.text = removeImgElementsWithEventHandlers(textData.text)
            return textData
        })
        .value()
}

function removeObjectElements(html) {
    return html && html.replace(/(<object[^>]*>)(.*?)(<\/object>)/gi, '')
}

function removeImgElementsWithEventHandlers(html) {
    return (
        html &&
        html.replace(
            /<img[^>]*(onerror|onload|onmouseenter|onmousemove|onmouseleave|onmouseover|onmouseup|onmouseout)[^>]*\/?>(<\/img>)?/gi,
            ''
        )
    )
}

/**
 * @exports utils/dataFixer/plugins/objectTagTextSecurityFixer
 */
module.exports = {
    name: 'textSecurityFixer',
    version: 1,
    exec(pageJson) {
        const data = pageJson.data.document_data
        if (data) {
            fixTextNodes(data)
        }
    }
}
