import type {CompRef, Pointer, PS, VariantRelation, VariantsReplacementOperation} from '@wix/document-services-types'
import variantsUtils from './variantsUtils'
import _ from 'lodash'
import relationsUtils from './relationsUtils'
import {DalValue, pointerUtils} from '@wix/document-manager-core'
import utils from '../utils/utils'
import constants from '../constants/constants'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'
import {ReportableError} from '@wix/document-manager-utils'
import dataModel from '../dataModel/dataModel'

const {
    RELATION_DATA_TYPES: {VARIANTS},
    DATA_TYPES_SUPPORT_VARIANTS_BUT_NOT_SCOPED_ON_ROOT
} = constants

interface RelationData {
    originalVariants: Set<string>
    transformedVariants: Set<string>
    relationPointer?: Pointer
    from?: string
    to?: string
    value?: DalValue
    indexInRefArray?: number
}

interface ScopedValueToMaintain {
    variants: string[]
    value: DalValue
    indexInRefArray: number
}

interface ScopedValuesToMaintain {
    [variantsKey: string]: ScopedValueToMaintain
}

const NON_SCOPED_KEY = ''

const createVariantRelation = (variants: string[], from: string, to: string | DalValue, id?: string) => ({
    ...(id && {id}),
    type: VARIANTS,
    variants: variants.map(variant => (typeof variant === 'string' ? `#${utils.stripHashIfExists(variant)}` : variant)),
    from: `#${utils.stripHashIfExists(from)}`,
    to: typeof to === 'string' ? `#${utils.stripHashIfExists(to)}` : to
})

const sortRelationsBySpecificity = ({originalVariants: originalVariants1}, {originalVariants: originalVariants2}) =>
    originalVariants1.size - originalVariants2.size

const sortRelationsByIndexInRefArray = (
    relationData1: RelationData | ScopedValueToMaintain,
    relationData2: RelationData | ScopedValueToMaintain
) => relationData1.indexInRefArray - relationData2.indexInRefArray

export const applyOperations = (operations: VariantsReplacementOperation[], relationsData: RelationData[]) => {
    for (const operation of operations) {
        const {from, to} = operation

        for (const {originalVariants, transformedVariants} of relationsData) {
            const shouldApplyOperation = from.every(variant => originalVariants.has(variant.id))

            if (shouldApplyOperation) {
                for (const variant of from) {
                    transformedVariants.delete(variant.id)
                }

                for (const variant of to) {
                    transformedVariants.add(variant.id)
                }
            }
        }
    }
}

const updateVariantRelationsIfNeeded = (ps: PS, relationsData: RelationData[]) => {
    for (const {relationPointer, from, to, originalVariants, transformedVariants} of relationsData) {
        const variants = [...transformedVariants]
        if (
            variants.length !== originalVariants.size ||
            _.intersection(variants, [...originalVariants]).length !== originalVariants.size
        ) {
            ps.dal.set(relationPointer, createVariantRelation(variants, from, to, relationPointer.id))
        }
    }
}

const getFromIdForRelation = (
    ps: PS,
    relationPointer: Pointer,
    componentPointer: CompRef,
    dataType: string
): string => {
    const relation = ps.dal.get(relationPointer)
    const from = utils.stripHashIfExists(relation?.from)

    if (displayedOnlyStructureUtil.isReferredId(from) || DATA_TYPES_SUPPORT_VARIANTS_BUT_NOT_SCOPED_ON_ROOT[dataType]) {
        return from
    } else if (from !== componentPointer.id) {
        ps.extensionAPI?.logger.captureError(
            new ReportableError({
                message: 'from property value in variant relation does not equal component id',
                errorType: 'variantRelationWithInvalidFromProperty',
                extras: {
                    componentPointer,
                    from
                }
            })
        )
    }

    return componentPointer.id
}

const setRelationValueAsDefault = (ps: PS, componentPointer: CompRef, relationToDefault: Pointer, dataType: string) => {
    if (!relationToDefault) {
        return
    }

    const fromId = getFromIdForRelation(ps, relationToDefault, componentPointer, dataType)
    const newNonScopedDataPointer = relationsUtils.scopedValuePointer(ps, dataType, relationToDefault)
    const newNonScopedValue = dataModel.serializeDataItem(ps, dataType, newNonScopedDataPointer)

    if (DATA_TYPES_SUPPORT_VARIANTS_BUT_NOT_SCOPED_ON_ROOT[dataType]) {
        const pointerWithNoVariants = pointerUtils.getPointer(fromId, dataType)
        variantsUtils.updateDataConsideringVariants(ps, pointerWithNoVariants, 'value', newNonScopedValue, dataType)
    } else {
        const compPointerWithNoVariants = pointerUtils.getPointerFromPointer(fromId, componentPointer)
        variantsUtils.updateComponentDataConsideringVariants(ps, compPointerWithNoVariants, newNonScopedValue, dataType)
    }
}

const getValidRelations = (
    relationsData: RelationData[],
    isValidRelationPredicate: (variants: string[]) => boolean = () => true,
    relationsPointersToRemove: Pointer[] = []
) => {
    const validRelations = {}
    let relationToDefault

    for (const {relationPointer, indexInRefArray, from, to, originalVariants, transformedVariants} of relationsData) {
        const variants = [...transformedVariants]

        if (transformedVariants.size === 0) {
            relationToDefault = {relationPointer, to}
            relationsPointersToRemove.push(relationPointer)
            continue
        }

        if (!isValidRelationPredicate(variants)) {
            relationsPointersToRemove.push(relationPointer)
            continue
        }

        const relationKey = variants.sort().join(',')

        if (validRelations[relationKey]) {
            relationsPointersToRemove.push(validRelations[relationKey].relationPointer)
        }

        validRelations[relationKey] = {
            ...(relationPointer && {relationPointer}),
            ...(indexInRefArray && {indexInRefArray}),
            from,
            to,
            originalVariants,
            transformedVariants
        }
    }

    return {
        validRelations,
        relationToDefault,
        relationsPointersToRemove
    }
}

export const replaceVariantsOnSerialized = (refArrayValues: Object, operations: VariantsReplacementOperation[]) => {
    const relationsData: RelationData[] = []

    for (const [indexInRefArray, [variants, value]] of Object.entries(refArrayValues).entries()) {
        const relationVariants = variants.split(',').filter(variantId => variantId !== NON_SCOPED_KEY)

        relationsData.push({
            originalVariants: new Set(relationVariants),
            transformedVariants: new Set(relationVariants),
            value,
            indexInRefArray
        })
    }

    relationsData.sort(sortRelationsBySpecificity)
    applyOperations(operations, relationsData)

    const scopedValuesToMaintain: ScopedValuesToMaintain = {}
    let defaultValue

    for (const {transformedVariants, value, indexInRefArray} of relationsData) {
        const variants = [...transformedVariants]
        const variantsKey = variants.sort().join(',')

        if (variantsKey === '') {
            defaultValue = value
            continue
        }

        scopedValuesToMaintain[variantsKey] = {variants, value, indexInRefArray}
    }

    /**
     *  Please note the following:
     *
     *  1. The order of variant relations in the refArray is important to the viewer.
     *  2. scopedValues in the refArray are serialized as an object,
     *     so we have no guarantee regarding the actual order.
     *  3. When this code was written, the system already expected to preserve order in this way.
     *     Thus, we had to maintain this behavior (insertion according to order of values in object).
     */

    const scopedValues: Object = Object.values(scopedValuesToMaintain)
        .sort(sortRelationsByIndexInRefArray)
        .reduce(
            (relations: Object, updatedRelation) => ({
                ...relations,
                [updatedRelation.variants.join(',')]: updatedRelation.value
            }),
            {}
        )

    return {defaultValue, scopedValues}
}

const transformToRelationData = (relation: VariantRelation) => {
    const relationVariants = (relation.variants || []).map(variant => utils.stripHashIfExists(variant))

    return {
        to: relation.to,
        from: relation.from,
        originalVariants: new Set(relationVariants),
        transformedVariants: new Set(relationVariants)
    }
}

const getRelationDataFromPointer = (ps: PS, relationPointer: Pointer): RelationData => {
    const relation = ps.dal.get(relationPointer)

    if (!relation?.variants) {
        return undefined
    }

    return {
        relationPointer,
        ...transformToRelationData(relation)
    }
}

export const fixVariantsRelationsIfPossible = (
    ps: PS,
    relationsPointersToRemove: [],
    relationsPointers: Pointer[],
    componentPointer: CompRef,
    validVariantsIds: string[],
    dataType: string,
    operations: VariantsReplacementOperation[] = [],
    shouldIgnoreIllegalRelations: boolean = false
) => {
    const validVariants = new Set(validVariantsIds)
    /**
     * shouldIgnoreIllegalRelations is used to ignore trigger variants that affect the component but do not belong to it or its ancestors
     * The validation is not limited to variants of type trigger since reactions were never cleaned-up
     */
    const isValidRelationPredicate = variants =>
        shouldIgnoreIllegalRelations || variants.every(variantId => validVariants.has(variantId))
    const relationsData: RelationData[] = relationsPointers
        .map(relationPointer => getRelationDataFromPointer(ps, relationPointer))
        .filter(Boolean)
        .sort(sortRelationsBySpecificity)

    applyOperations(operations, relationsData)

    const {validRelations, relationToDefault} = getValidRelations(
        relationsData,
        isValidRelationPredicate,
        relationsPointersToRemove
    )

    updateVariantRelationsIfNeeded(ps, Object.values(validRelations))
    setRelationValueAsDefault(ps, componentPointer, relationToDefault?.relationPointer, dataType)

    return relationsPointersToRemove
}

export const replaceVariantsOnSerializedOverrides = (sourceRefArray: VariantRelation[], operations = []) => {
    const relationsData: RelationData[] = []

    if (!Array.isArray(sourceRefArray) || !Array.isArray(operations) || operations.length === 0) {
        return sourceRefArray
    }

    for (const [indexInRefArray, refArrayValue] of sourceRefArray.entries()) {
        relationsData.push({indexInRefArray, ...transformToRelationData(refArrayValue)})
    }

    relationsData.sort(sortRelationsBySpecificity)
    applyOperations(operations, relationsData)

    const {validRelations, relationToDefault} = getValidRelations(relationsData)
    const refArray = (Object.values(validRelations) as RelationData[])
        .sort(sortRelationsByIndexInRefArray)
        .map(({from, to, transformedVariants}: RelationData) =>
            createVariantRelation([...transformedVariants], from, to)
        )

    if (relationToDefault?.to) {
        refArray.unshift(relationToDefault.to)
    }

    return refArray
}
