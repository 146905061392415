import $ from 'zepto'

const SCRIPT = 'https://api.facebook.com/restserver.php?format=json&method=links.getStats&urls='

function getNumbers(result) {
    return {
        likesAndShares: result.total_count || 0, //surprisingly enough, facebook counts them as the same thing. If you use the wysiwyg.viewer.components.FacebookShare to share a page, its like counter (wysiwyg.viewer.components.WFacebookLike) will grow too :)
        comments: result.commentsbox_count || 0
    }
}

function facebook(property) {
    return function (url, callback) {
        $.ajax({
            url: SCRIPT + encodeURIComponent(url),
            dataType: 'json',
            success(result, status) {
                if (status === 'success' && result[0]) {
                    const numbers = getNumbers(result[0])
                    callback(numbers[property])
                } else {
                    callback(0, 0)
                }
            },
            error() {
                callback(0, 0)
            }
        })
    }
}

facebook.likes = facebook.shares = facebook('likesAndShares')
facebook.comments = facebook('comments')

export default facebook
