import type {PS} from '@wix/document-services-types'
import _ from 'lodash'

function omitEmptyCustomData(clonedSerializedComp) {
    const customWithoutConnections = _.omit(_.get(clonedSerializedComp, 'custom'), 'relatedConnections')
    if (_.isEmpty(customWithoutConnections)) {
        return _.omit(clonedSerializedComp, 'custom')
    }
    return _.assign({}, clonedSerializedComp, {custom: customWithoutConnections})
}

function setConnectedComponents(ps: PS, serializedComponent, connectedComponents) {
    const clonedSerializedComp = _.cloneDeep(serializedComponent)
    if (_.isEmpty(connectedComponents)) {
        return omitEmptyCustomData(clonedSerializedComp)
    }
    _.set(clonedSerializedComp, 'custom.relatedConnections', connectedComponents)
    return clonedSerializedComp
}

function getConnectedComponent(ps: PS, serializedComponent) {
    return _.get(serializedComponent, 'custom.relatedConnections')
}

export default {
    setConnectedComponents,
    getConnectedComponents: getConnectedComponent
}
