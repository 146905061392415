import {ReportableError} from '@wix/document-manager-utils'
import type {AppDefinitionId, ApplicationId, BIEvt, Callback1, Pointer, PS} from '@wix/document-services-types'
import {utils, tpa, coreUtils} from '@wix/santa-ds-libs'
const {scrollAnchors} = coreUtils
const {urlUtils} = coreUtils
import _ from 'lodash'
import component from '../../component/component'
import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'
import pageService from '../../page/page'
import pageUtils from '../../page/pageUtils'
import platform from '../../platform/platform'
import refComponent from '../../refComponent/refComponent'
import routersGetters from '../../routers/routersGetters'
import seo from '../../siteMetadata/seo'
import siteMetadata from '../../siteMetadata/siteMetadata'
import structure from '../../structure/structure'
import {contextAdapter} from '../../utils/contextAdapter'
import tpaConstants from '../constants'
import installedTpaAppsOnSiteService from '../services/installedTpaAppsOnSiteService'
import tpaDataService from '../services/tpaDataService'
import tpaEventHandlersService from '../services/tpaEventHandlersService'
import type {TpaMsg} from '../services/tpaPostMessageService'
import tpaPreviewEditorCommunicationService from '../services/tpaPreviewEditorCommunicationService'
import tpaUtils from '../utils/tpaUtils'
import {getMetaSiteId} from '../../utils/dalUtil'
import clientSpecMapService from '../services/clientSpecMapService'

type TpaApi = typeof import('../tpa').default

let tpads: TpaApi
const {addCallback} = tpaPreviewEditorCommunicationService

const reportedAsDeprecated = new Set<string>()
const reportDeprecated = (name: string) => {
    if (!reportedAsDeprecated.has(name)) {
        contextAdapter.utils.fedopsLogger.interactionStarted('deprecatedTpaHandlers', {extras: {name}})
        reportedAsDeprecated.add(name)
    }
}

const getCompData = function (ps: PS, compId: string) {
    const compPointer = componentDetectorAPI.getComponentById(ps, compId)
    return (
        component.data.get(ps, compPointer) ||
        tpaUtils.getModalCompData(ps, compId) ||
        tpaUtils.getPopupCompData(ps, compId)
    )
}

const getApplicationId = function (ps: PS, compId: string): ApplicationId {
    const compData = getCompData(ps, compId)
    return parseInt(_.get(compData, 'applicationId'), 10)
}

const getAppDefId = function (ps: PS, compId: string): AppDefinitionId {
    const compData = getCompData(ps, compId)
    return compData?.appDefinitionId
}

const getAppPagesByAppDefId = function (ps: PS, appDefinitionId: AppDefinitionId) {
    const sitePages = pageService.getPagesDataItems(ps)
    return _.filter(sitePages, {appDefinitionId})
}

const getAppPagesByCompId = function (ps: PS, compId: string) {
    const appDe = getAppDefId(ps, compId)
    return getAppPagesByAppDefId(ps, appDe)
}

const getMainAppPage = function (appPages) {
    return _.find(appPages, {hidePage: false})
}

const getAppPageByTpaPageId = function (appPages, tpaPageId: string) {
    return _.find(appPages, {tpaPageId})
}

const getSectionUrl = function (ps: PS, msg, callback: Callback1<any>) {
    const tpaPageId = msg.data.sectionIdentifier
    const appPages = getAppPagesByCompId(ps, msg.compId)
    const appPage = getAppPageByTpaPageId(appPages, tpaPageId) || getMainAppPage(appPages)
    if (appPage) {
        ps.siteAPI.handleDsTpaHandler({msg, addCallback, ps, callback, siteMetadata})
    } else {
        callback({error: {message: 'This app does not have any pages.'}})
    }
}

const getStateUrl = function (ps: PS, msg, callback: Callback1<any>) {
    const tpaPageId = msg.data.sectionId
    const appPages = getAppPagesByCompId(ps, msg.compId)
    const appPage = getAppPageByTpaPageId(appPages, tpaPageId)

    let dynamicPageData
    if (appPage) {
        dynamicPageData = routersGetters.getRouterDataForPageIfExist(ps, appPage.id)
        if (dynamicPageData) {
            callback({
                error: {message: "Can't retrieve url for a dynamic page. Please use the platform app API instead."}
            })
            return
        }
        ps.siteAPI.handleDsTpaHandler({msg, addCallback, ps, callback, siteMetadata})
    } else {
        const mainAppPage = getMainAppPage(appPages)
        if (mainAppPage) {
            dynamicPageData = routersGetters.getRouterDataForPageIfExist(ps, mainAppPage.id)
            if (dynamicPageData) {
                callback({
                    error: {message: "Can't retrieve url for a dynamic page. Please use the platform app API instead."}
                })
                return
            }
            console.log('msg', msg, mainAppPage)
            msg.data = {
                sectionId: _.get(mainAppPage, ['tpaPageId'])
            }
            ps.siteAPI.handleDsTpaHandler({msg, addCallback, ps, callback, siteMetadata})
        } else {
            callback({error: {message: 'This app does not have any pages.'}})
        }
    }
}

const siteInfo = function (ps: PS, msg, callback: Callback1<any>) {
    const info: any = {}
    const pageData = pageService.data.get(ps, ps.siteAPI.getCurrentUrlPageId())
    pageData.siteTitle = getCurrentPageTitle(ps, pageData)

    if (tpa.common.utils.sdkVersionIsAtLeast(msg.version, '1.42.0')) {
        info.pageTitle = pageData.siteTitle || ''
    } else {
        info.siteTitle = pageData.siteTitle || ''
        info.pageTitle = pageData.title || ''
    }

    info.pageTitleOnly = getCurrentPageTitle(ps, pageData, false)
    info.siteDescription = seo.description.get(ps)
    info.siteKeywords = seo.keywords.get(ps)

    const currentUrl = siteMetadata.generalInfo.getPublicUrl(ps) || ''
    const currentUrlParsed: any = (currentUrl && urlUtils.parseUrl(currentUrl)) || ''
    info.baseUrl =
        (currentUrl && `${currentUrlParsed.protocol}//${currentUrlParsed.host}${currentUrlParsed.path}`) ||
        'baseUrl is not available - site is saved but not published'

    const editorUrl = ps.siteAPI.getCurrentUrl()
    info.url = tpa.common.utils.appendProtocolToUrlIfNeeded(editorUrl.host, editorUrl)
    info.referer = window.document.referrer
    callback(info)
}

const getCurrentPageTitle = function (ps: PS, pageData, withAddedSiteName = true) {
    let title: string = seo.title.get(ps) || '' // site name
    const pageName = pageData.title || ''
    const pageTitleSEO = utils.pageUtils.extractPageTitleFromOriginalTitle(
        pageData.pageTitleSEO,
        withAddedSiteName,
        title
    )
    const urlPageId = ps.siteAPI.getCurrentUrlPageId()
    const isHomePage = pageUtils.isHomepage(ps, urlPageId)

    if (pageTitleSEO) {
        title = pageTitleSEO
    } else if (!isHomePage) {
        title = withAddedSiteName ? `${title} | ${pageName}` : pageName
    }

    return title
}

const registerEventListener = function (ps: PS, msg) {
    const {eventKey} = msg.data
    const {compId} = msg
    const compPointer = componentDetectorAPI.getComponentById(ps, compId)
    const compIdToUpdate = compPointer ? getCompPointerToUpdate(ps, compPointer).id : compId

    switch (eventKey) {
        case 'EDITOR_EVENT':
            tpads.change.register.editorEventHandler(ps, compIdToUpdate, function (data) {
                ps.setOperationsQueue.runSetOperation(() => {
                    tpads.comp.postMessageBackToApp(ps, compIdToUpdate, eventKey, data)
                })
            })
            break

        case 'COMPONENT_DELETED':
            tpads.change.register.deleteCompHandler(ps, msg.compId, function () {
                tpads.comp.postMessageBackToApp(ps, compId, eventKey, {})
            })
            break

        case 'COMPONENT_DISCONNECT':
            tpads.change.register.disconnectCompHandler(ps, msg.compId, function () {
                ps.setOperationsQueue.runSetOperation(() => {
                    tpads.comp.postMessageBackToApp(ps, compId, eventKey, {})
                })
            })
            break

        case 'EDIT_MODE_CHANGE':
            if (!compIdToUpdate) {
                ps.extensionAPI.logger.captureError(
                    new ReportableError({
                        message: 'register to EDIT_MODE_CHANGE with no compId',
                        errorType: 'registerToEditModeChangeWithoutId'
                    })
                )
            }
            tpads.change.register.editModeChangeHandler(ps, compIdToUpdate, function (mode) {
                ps.setOperationsQueue.runSetOperation(() => {
                    tpads.comp.postMessageBackToApp(ps, compIdToUpdate, eventKey, {
                        editMode: mode
                    })
                })
            })
            break

        case 'SITE_PUBLISHED':
            tpads.change.register.sitePublishedHandler(ps, compIdToUpdate, function () {
                ps.setOperationsQueue.runSetOperation(() => {
                    tpads.comp.postMessageBackToApp(ps, compIdToUpdate, eventKey, {})
                })
            })
            break

        case 'SETTINGS_UPDATED':
            tpads.change.register.settingsUpdatedHandler(ps, compIdToUpdate, function (data) {
                ps.setOperationsQueue.runSetOperation(() => {
                    tpads.comp.postMessageBackToApp(ps, compIdToUpdate, eventKey, data)
                })
            })
            break

        case 'WINDOW_PLACEMENT_CHANGED':
            tpads.change.register.windowPlacementChangedHandler(ps, compIdToUpdate, function (data) {
                ps.setOperationsQueue.runSetOperation(() => {
                    tpads.comp.postMessageBackToApp(ps, compIdToUpdate, eventKey, data)
                })
            })
            break
        case 'THEME_CHANGE':
            tpads.change.register.themeChangeHandler(ps, compIdToUpdate, function (changedData) {
                ps.setOperationsQueue.runSetOperation(() => {
                    tpads.style.postBackThemeData(ps, compIdToUpdate, changedData)
                })
            })
            break
        case 'DEVICE_TYPE_CHANGED':
            tpads.change.register.deviceTypeChangeHandler(ps, compIdToUpdate, function (device) {
                ps.setOperationsQueue.runSetOperation(() => {
                    tpads.comp.postMessageBackToApp(ps, compIdToUpdate, eventKey, {
                        deviceType: device
                    })
                })
            })
            break
        case 'STYLE_PARAMS_CHANGE':
            break
        case 'SITE_SAVED':
            tpads.change.register.siteSavedHandler(ps, compIdToUpdate, function () {
                ps.setOperationsQueue.runSetOperation(() => {
                    tpads.comp.postMessageBackToApp(ps, compIdToUpdate, eventKey)
                })
            })
            break
        case 'ON_MESSAGE_RESPONSE':
            ps.siteAPI.reportBI(tpads.bi.errors.EVENT_TYPE_NOT_SUPPORTED as BIEvt)
            break
        case 'PUBLIC_DATA_CHANGED':
            const appDefId = getAppDefId(ps, compId)

            tpads.change.register.registerPublicDataChangedHandler.byAppDefId(
                ps,
                compIdToUpdate,
                appDefId,
                function (data) {
                    ps.setOperationsQueue.runSetOperation(() => {
                        tpads.comp.postMessageBackToApp(ps, compIdToUpdate, eventKey, data)
                    })
                }
            )
            break
        case 'REVISION_CHANGED':
            tpads.change.register.historyChangeHandler(ps, compIdToUpdate, function () {
                ps.setOperationsQueue.runSetOperation(() => {
                    tpads.comp.postMessageBackToApp(ps, compIdToUpdate, eventKey)
                })
            })
            break
    }
}

const getSitePages = function (ps: PS, msg: TpaMsg, callback) {
    ps.siteAPI.handleDsTpaHandler({msg, addCallback, ps, callback, siteMetadata})
}

const getSiteMap = function (ps: PS, msg: TpaMsg, callback) {
    ps.siteAPI.handleDsTpaHandler({msg, addCallback, ps, callback, siteMetadata})
}

const getValidComps = function (ps: PS, compIds: string[]) {
    const comps: Pointer[] = []
    _.forEach(compIds, function (compId) {
        const comp = componentDetectorAPI.getComponentById(ps, compId)
        if (comp) {
            comps.push(comp)
        }
    })
    return comps
}

const refreshApp = function (ps: PS, msg: TpaMsg) {
    let comps
    if (_.isArray(msg.data.compIds)) {
        comps = getValidComps(ps, msg.data.compIds)
    } else {
        const appDefId = getAppDefId(ps, msg.compId)
        comps = tpads.app.getAllComps.byAppDefId(ps, [appDefId])
    }

    tpads.app.refreshApp(ps, comps, msg.data.queryParams)
    ps.siteAPI.forceUpdate()
}

const openSettingsDialog = function (ps: PS, msg: TpaMsg, callback) {
    const appDefId = getAppDefId(ps, msg.compId)
    const comps = tpads.app.getAllComps.byAppDefId(ps, [appDefId])
    let comp
    if (msg.data?.compId) {
        comp = _.find(comps, {id: msg.data.compId})

        if (_.isUndefined(comp)) {
            callback({onError: 'comp not found'})
            return
        }
    } else {
        comp = _.find(comps, {id: msg.compId})
    }

    const params = {
        pageId: comp.pageId,
        compId: comp.id
    }

    tpaPreviewEditorCommunicationService.doPostMessage('openSettingsDialog', params, msg.compId)
}

const isSupported = (ps: PS, msg: TpaMsg, callback) => {
    callback(true)
}

const navigateToPage = function (ps: PS, msg: TpaMsg, callback) {
    const primaryPageId = ps.siteAPI.getPrimaryPageId()
    const allPageIds = pageService.getPageIdList(ps, false, true)

    const getPageAnchors = function (pageId: string) {
        const topPageAnchorName = tpa.common.utils.Constants.TOP_PAGE_ANCHOR_PREFIX + pageId
        return pageService.getPageAnchors(ps, pageId, topPageAnchorName)
    }

    const getAnchorDataId = function (anchorCompId: string) {
        const compPointer = componentDetectorAPI.getComponentById(ps, anchorCompId)
        const compData = component.data.get(ps, compPointer)
        return _.get(compData, 'id')
    }

    tpa.services.tpaNavigationService.navigateToPage(
        {
            scrollToAnchor: ps.siteAPI.scrollToAnchor.bind(ps.siteAPI),
            registerNavigationComplete: ps.siteAPI.registerNavigationComplete.bind(ps.siteAPI),
            navigateToPage: ps.siteAPI.navigateToPage.bind(ps.siteAPI)
        },
        msg,
        primaryPageId,
        allPageIds,
        getPageAnchors,
        getAnchorDataId,
        callback
    )
    tpaPreviewEditorCommunicationService.doPostMessage('deselectComponent')
}

const getExternalId = function (ps: PS, msg: TpaMsg, callback) {
    callback(tpads.comp.getExternalId(ps, componentDetectorAPI.getComponentById(ps, msg.compId)))
}

const getResultDataForUninstalledApp = () => ({
    onError: true
})

const getInstalledInstance = function (ps: PS, msg: TpaMsg, callback) {
    let installedInstance = {}

    const doNotFilterDemoMode = false
    const appData = tpads.app.getData.byAppDefId(ps, msg.data.appDefinitionId)
    if (_.isEmpty(appData)) {
        installedInstance = getResultDataForUninstalledApp()
    } else {
        const isInstalled = tpads.app.isInstalled(ps, msg.data.appDefinitionId, doNotFilterDemoMode)
        if (isInstalled) {
            installedInstance = {
                instanceId: appData.instanceId
            }
        } else {
            installedInstance = getResultDataForUninstalledApp()
        }
    }

    callback(installedInstance)
}

const revalidateSession = function (ps: PS, msg: TpaMsg, callback) {
    const applicationId = getApplicationId(ps, msg.compId)
    const metaSiteId = getMetaSiteId(ps)

    tpads.provision.refreshAppSpecMap.byAppId(
        ps,
        applicationId,
        metaSiteId,
        function (appData) {
            callback({
                instance: appData.instance
            })
        },
        function (error) {
            callback({
                onError: true,
                error: {
                    errorCode: error.status,
                    errorText: error.statusText,
                    error: JSON.parse(error.response)
                }
            })
        }
    )
}

const resizeComponent = function (ps: PS, msg: TpaMsg, callback) {
    const {compId} = msg
    const compPointer = componentDetectorAPI.getComponentById(ps, compId)
    tpads.comp.resize(ps, compPointer, msg.data.width, msg.data.height, callback)
}

const settingsResizeComponent = function (ps: PS, msg, callback) {
    resizeComponent(ps, msg, callback)
}

const getOrigCompPointer = function (ps: PS, compId: string) {
    const compData = tpaUtils.getModalCompData(ps, compId) || tpaUtils.getPopupCompData(ps, compId)
    const origCompId = _.get(compData, 'origCompId')
    return origCompId ? componentDetectorAPI.getComponentById(ps, origCompId) : null
}

const handleCompNotFound = function (callback) {
    callback({
        error: {
            message: 'comp not found'
        }
    })
}

const getPublicData = function (ps: PS, msg, callback) {
    const {compId} = msg
    const applicationId = getApplicationId(ps, compId)
    const compPointer = componentDetectorAPI.getComponentById(ps, compId) ?? getOrigCompPointer(ps, compId)
    if (!applicationId || !compPointer) {
        handleCompNotFound(callback)
    } else {
        tpads.data.getPublicData.byAppId(ps, applicationId, compPointer, callback)
    }
}

const getValue = function (ps: PS, msg, callback) {
    const {compId} = msg
    if (msg.data.scope === tpads.data.SCOPE.APP) {
        const appId = getApplicationId(ps, compId)
        if (!appId) {
            handleCompNotFound(callback)
        } else {
            tpads.data.app.get.byAppId(ps, appId, msg.data.key, callback)
        }
    } else {
        const compPointer = componentDetectorAPI.getComponentById(ps, compId) ?? getOrigCompPointer(ps, compId)
        if (!compPointer) {
            handleCompNotFound(callback)
        } else {
            tpads.data.comp.get(ps, compPointer, msg.data.key, callback)
        }
    }
}

const getValues = function (ps: PS, msg: TpaMsg, callback) {
    const {compId} = msg
    if (msg.data.scope === tpads.data.SCOPE.APP) {
        const appId = getApplicationId(ps, compId)
        if (!appId) {
            handleCompNotFound(callback)
        } else {
            tpads.data.app.getMulti.byAppId(ps, appId, msg.data.keys, callback)
        }
    } else {
        const compPointer = componentDetectorAPI.getComponentById(ps, compId) ?? getOrigCompPointer(ps, compId)
        if (!compPointer) {
            handleCompNotFound(callback)
        } else {
            tpads.data.comp.getMulti(ps, compPointer, msg.data.keys, callback)
        }
    }
}

function getCompPointerToUpdate(ps: PS, compPointer: Pointer) {
    if (refComponent.isPartOfSharedBlock(ps, compPointer)) {
        return refComponent.getTemplateCompPointer(ps, compPointer)
    }
    return compPointer
}

const setValue = function (ps: PS, msg: TpaMsg, callback) {
    const compPointer = componentDetectorAPI.getComponentById(ps, msg.compId)
    if (!compPointer) {
        handleCompNotFound(callback)
    } else {
        const compPointerToUpdate = getCompPointerToUpdate(ps, compPointer)
        tpaDataService.set(ps, compPointerToUpdate, msg.data.key, msg.data.value, msg.data.scope, callback)
    }
}

const removeValue = function (ps: PS, msg: TpaMsg, callback) {
    const compPointer = componentDetectorAPI.getComponentById(ps, msg.compId)

    if (!compPointer) {
        handleCompNotFound(callback)
    } else {
        const compPointerToUpdate = getCompPointerToUpdate(ps, compPointer)
        tpads.data.remove(ps, compPointerToUpdate, msg.data.key, msg.data.scope, callback)
    }
}

const getCurrentPageAnchors = function (ps: PS, msg: TpaMsg, callback) {
    //we have anchors only on primary page for now
    //you should use some existing method for this, I'm sure we have one..
    const pageId = ps.siteAPI.getPrimaryPageId()
    const pagePointer = pageService.getPage(ps, pageId)
    const currentPageAnchors = componentDetectorAPI.getComponentByType(
        ps,
        'wysiwyg.common.components.anchor.viewer.Anchor',
        pagePointer
    )
    const anchors = _.map(currentPageAnchors, anchorRef => getAnchorInfo(ps, anchorRef))

    const topPageAnchorName = tpa.common.utils.Constants.TOP_PAGE_ANCHOR_PREFIX + pageId
    const topPageAnchor = scrollAnchors.getPageTopAnchor(pageId, topPageAnchorName)
    const topPageAnchorInfo = {
        id: topPageAnchor.compId,
        title: topPageAnchor.name
    }
    anchors.push(topPageAnchorInfo)
    callback(anchors)
}

const getAnchorInfo = function (ps: PS, anchorRef: Pointer) {
    const data = component.data.get(ps, anchorRef)
    return {
        id: data.compId,
        title: data.name
    }
}

const getComponentInfo = function (ps: PS, msg: TpaMsg, callback) {
    const compPointer = componentDetectorAPI.getComponentById(ps, msg.compId)
    const compData = component.data.get(ps, compPointer)
    const {appDefinitionId} = compData
    const appData = tpads.app.getData.byAppDefId(ps, appDefinitionId)
    const showOnAllPages = structure.isShowOnAllPages(ps, compPointer)
    const widgetData = appData.widgets[compData.widgetId]

    const returnObj = {
        compId: msg.compId,
        showOnAllPages,
        pageId: showOnAllPages ? '' : ps.pointers.components.getPageOfComponent(compPointer).id,
        tpaWidgetId: _.get(widgetData, 'tpaWidgetId', ''),
        appPageId: _.get(widgetData, 'appPage.id', '')
    }

    callback(returnObj)
}

const settpads = function (tpaAPI: TpaApi) {
    tpads = tpaAPI
}

const isPageComp = function (ps: PS, compPointer: Pointer) {
    const compType = component.getType(ps, compPointer)
    return compType === tpaConstants.COMP_TYPES.TPA_SECTION || compType === tpaConstants.COMP_TYPES.TPA_MULTI_SECTION
}

const navigateToSectionPage = function (ps: PS, msg: TpaMsg, callback) {
    tpaPreviewEditorCommunicationService.doPostMessage('deselectComponent')
    //delete all but msg when santa is deprecated
    ps.siteAPI.handleDsTpaHandler({
        msg,
        ps,
        addCallback,
        callback,
        tpads,
        installedTpaAppsOnSiteService,
        componentDetectorAPI,
        isPageComp,
        routersGetters,
        structure,
        getApplicationId
    })
}

const addApplication = function (ps: PS, msg: TpaMsg, callback: Callback1<any>) {
    tpaPreviewEditorCommunicationService.doPostMessage('editorAddApplication', msg.data, msg.compId, callback)
}

const isApplicationInstalled = function (ps: PS, msg: TpaMsg, callback: Callback1<any>) {
    tpaPreviewEditorCommunicationService.doPostMessage('editorIsApplicationInstalled', msg.data, msg.compId, callback)
}

const isFullWidth = function (ps: PS, msg: TpaMsg, callback: Callback1<any>) {
    tpaPreviewEditorCommunicationService.doPostMessage('editorIsFullWidth', msg.data, msg.compId, callback)
}

const isCustomApplicationPermissionsGranted = function (ps: PS, msg: TpaMsg, callback: Callback1<any>) {
    tpaPreviewEditorCommunicationService.doPostMessage(
        'isCustomApplicationPermissionsGranted',
        msg.data,
        msg.compId,
        callback
    )
}

const isGroupApplicationPermissionsGranted = function (ps: PS, msg: TpaMsg, callback: Callback1<any>) {
    tpaPreviewEditorCommunicationService.doPostMessage(
        'isGroupApplicationPermissionsGranted',
        msg.data,
        msg.compId,
        callback
    )
}

const isAppSectionInstalled = function (ps: PS, msg: TpaMsg, callback: Callback1<any>) {
    let appDefinitionId = _.get(msg, ['data', 'appDefinitionId'])
    if (!appDefinitionId) {
        const applicationId = _.get(msg, ['data', 'applicationId'])
        if (applicationId) {
            appDefinitionId = clientSpecMapService.getAppDefinitionIdFromApplicationId(ps, applicationId, {
                source: 'isAppSectionInstalled'
            })
        }
        if (!appDefinitionId) {
            callback(false)
            return
        }
    }

    const appPages = getAppPagesByAppDefId(ps, appDefinitionId)
    const isInstalled = _.some(appPages, {
        tpaPageId: _.get(msg, ['data', 'sectionId'])
    })
    callback(isInstalled)
}

const getAppVendorProductId = function (ps: PS, msg: TpaMsg, callback: Callback1<any>) {
    const appDefinitionId = _.get(msg, ['data', 'appDefinitionId'])
    const appData = tpads.app.getData.byAppDefId(ps, appDefinitionId)

    if (!appData) {
        callback(null)
        return
    }

    const applicationSpecData = platform.getAppDataByAppDefId(ps, appData.appDefinitionId)

    callback(_.get(applicationSpecData, 'vendorProductId') || null)
}

const smCurrentMember = function (ps: PS, msg: TpaMsg, callback: Callback1<any>) {
    ps.siteAPI.getMemberDetailsInPreview(function (memberData) {
        if (memberData) {
            callback(memberData)
        } else {
            callback(null)
        }
    })
}

const refreshCurrentMember = function (ps: PS) {
    ps.siteAPI.getMemberDetailsInPreview(null, null, true)
}

const applicationLoaded = function (ps: PS, msg: TpaMsg) {
    const compPointer = componentDetectorAPI.getComponentById(ps, msg.compId)
    tpaEventHandlersService.callCompLoaded(compPointer)
}

const onReady = function (ps: PS, msg: TpaMsg, callback: Callback1<any>) {
    tpaPreviewEditorCommunicationService.doPostMessage('onReady', msg.data, msg.compId, callback)
}

export default _.mapValues(
    {
        registerEventListener,
        siteInfo,
        getSitePages,
        getSiteMap,
        refreshApp,
        refreshAppByCompIds: refreshApp,
        getSectionUrl,
        openSettingsDialog,
        isSupported,
        navigateToPage,
        getExternalId,
        getInstalledInstance,
        revalidateSession,
        resizeComponent,
        settingsResizeComponent,
        getValue,
        getValues,
        getPublicData,
        setValue,
        removeValue,
        getCurrentPageAnchors,
        getComponentInfo,
        settpads,
        navigateToSectionPage,
        getStateUrl,
        addApplication,
        applicationLoaded,
        isApplicationInstalled,
        isAppSectionInstalled,
        isFullWidth,
        getAppVendorProductId,
        smCurrentMember,
        refreshCurrentMember,
        onReady,
        isGroupApplicationPermissionsGranted,
        isCustomApplicationPermissionsGranted
    },
    (fn, name) => {
        return (...args: any[]) => {
            reportDeprecated(name)
            // @ts-expect-error
            return fn(...args)
        }
    }
)
