import errors from './errors.json'
import bi from './bi'

/**
 * Please ctrl/cmd + click on biError to see the schema :)
 * @type {Object.<String, BIError>}
 */

bi.register('documentServices', 'error', errors)

export default errors
