import type {Pointer, PS} from '@wix/document-services-types'
import dataModel from '../dataModel/dataModel'
import appControllerUtils from './appControllerUtils'

function getControllerDataItem(ps: PS, controllerRef: Pointer) {
    appControllerUtils.verifyController(ps, controllerRef)
    return dataModel.getDataItem(ps, controllerRef)
}

function setControllerDataItem(ps: PS, controllerRef: Pointer, dataItem, useOriginalLanguage = false) {
    appControllerUtils.verifyController(ps, controllerRef)
    dataModel.updateDataItem(ps, controllerRef, dataItem, useOriginalLanguage)
}

export default {
    getControllerDataItem,
    setControllerDataItem
}
