import type {CompRef, Pointer, PS} from '@wix/document-services-types'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'
import _ from 'lodash'
import hooks from '../hooks/hooks'
import tpaUtils from '../tpa/utils/tpaUtils'
import dsUtils from '../utils/utils'
import repeaterUtils from '../utils/repeater'
import {asArray} from '@wix/document-manager-utils'

function getComponentType(ps: PS, componentPointer: Pointer): string | null | undefined {
    let result = null
    if (ps && componentPointer) {
        result = ps.pointers.components.isMasterPage(componentPointer)
            ? 'wysiwyg.viewer.components.WSiteStructure'
            : dsUtils.getComponentType(ps, componentPointer)
    }
    return result
}

function getComponentDefinition(ps: PS, componentType: string) {
    return ps.extensionAPI.schemaAPI.getDefinition(componentType)
}

function getComponentProperties(ps: PS, componentPointer: Pointer) {
    const propQueryPointer = ps.pointers.getInnerPointer(componentPointer, 'propertyQuery')
    const propQuery = propQueryPointer && ps.dal.get(propQueryPointer)
    const page = ps.pointers.components.getPageOfComponent(componentPointer)
    const pageId = page?.id
    const propPointer = propQuery && ps.pointers.data.getPropertyItem(dsUtils.stripHashIfExists(propQuery), pageId)
    return propPointer && ps.dal.get(propPointer)
}

function getSiblings(ps: PS, compPointer: Pointer) {
    const parentComp = ps.pointers.components.getParent(compPointer)
    const siblings = getChildComponents(ps, parentComp)
    _.remove(siblings, {id: compPointer.id})
    return siblings
}

function getRepeatedComponents(ps: PS, compPointer: Pointer): Pointer[] {
    const viewerResult = ps.extensionAPI.viewer.getRepeatedComponents(compPointer)
    if (viewerResult?.length) {
        return viewerResult
    }

    if (!displayedOnlyStructureUtil.isRepeatedComponent(compPointer.id)) {
        return []
    }
    return ps.pointers.components.getAllDisplayedOnlyComponents(compPointer)
}

/**
 * this should be used in every method that adds a component to a container,
 * some containers have other containers in them that the component should be added to
 * @param ps
 * @param containerPointer the container that we want to add a component to
 * @returns a pointer to the container
 */
function getContainerToAddComponentTo(ps: PS, containerPointer: CompRef): CompRef {
    const type = getComponentType(ps, containerPointer)
    const values = hooks.executeHooksAndCollectValues(hooks.HOOKS.ADD_ROOT.GET_CONTAINER_TO_ADD_TO, type, [
        ps,
        containerPointer
    ])
    if (values.length > 1) {
        throw new Error("you can't have more that one hook returning a container")
    }
    return values[0] || containerPointer
}

/**
 * @param ps
 * @param componentPointer The component to check its parent
 * @returns The pointer of the parent in case one is found, undefined otherwise
 */
function getParentForSlottedComps(ps: PS, componentPointer: Pointer): CompRef {
    return ps.extensionAPI.slots.getPluginParent(componentPointer)
}

function getParent(ps: PS, componentPointer: Pointer): CompRef {
    return getParentForSlottedComps(ps, componentPointer) || ps.pointers.components.getParent(componentPointer)
}

function getAncestors(
    ps: PS,
    componentPointer: Pointer,
    getParentMethod: (ps: PS, componentPointer: Pointer) => Pointer
) {
    let parentRef = getParentMethod(ps, componentPointer)
    const ancestors: Pointer[] = []

    while (parentRef !== null) {
        ancestors.push(parentRef)
        parentRef = getParentMethod(ps, parentRef)
    }

    return ancestors
}

const getAncestorsFromDisplayed = (ps: PS, componentPointer: Pointer) => getAncestors(ps, componentPointer, getParent)
const getAncestorsFromFull = (ps: PS, componentPointer: Pointer) => {
    return ps.extensionAPI.components.getAncestors(componentPointer, true)
}

function getAllJsonChildren(ps: PS, parentCompPointer: Pointer) {
    return getChildComponents(ps, parentCompPointer, false)
}

function getTpaChildComponents(ps: PS, parentCompPointer: Pointer) {
    const childrenArr = ps.pointers.full.components.getChildrenRecursively(parentCompPointer)
    return _.filter(childrenArr, function (childComp) {
        const componentType = getComponentType(ps, childComp)
        return tpaUtils.isTpaByCompType(componentType)
    })
}

function getBlogChildComponents(ps: PS, parentCompPointer: Pointer) {
    const childrenArr = ps.pointers.components.getChildrenRecursively(parentCompPointer)
    return _.filter(childrenArr, function (childComp) {
        const componentType = getComponentType(ps, childComp)
        return componentType === 'wixapps.integration.components.AppPart'
    })
}

function isPageComponent(ps: PS, compPointer: Pointer) {
    return ps.pointers.components.isPage(compPointer)
}

function getChildComponents(ps: PS, parentCompPointer: Pointer, isRecursive = false) {
    return isRecursive
        ? ps.pointers.components.getChildrenRecursively(parentCompPointer)
        : ps.pointers.components.getChildren(parentCompPointer)
}

function getChildComponentsWithScopes(ps: PS, compPointer: Pointer, isRecursive = false) {
    const {enableRepeatersInScopes} = ps.config
    if (enableRepeatersInScopes && repeaterUtils.isRepeater(ps, compPointer)) {
        return []
    }

    return getChildComponents(ps, compPointer, isRecursive)
}

function getChildComponentsFromFull(ps: PS, parentCompPointer: Pointer, isRecursive = false) {
    return ps.extensionAPI.components.getChildren(parentCompPointer, isRecursive)
}

function isRenderedOnSite(ps: PS, componentPointer: Pointer) {
    return ps.siteAPI.isComponentRenderedOnSite(componentPointer)
}

function isContainsCompWithType(ps: PS, parentCompPointer: Pointer, types: string | string[]) {
    types = asArray(types)
    const childrenArr = ps.pointers.components.getChildrenRecursively(parentCompPointer)
    for (let i = 0, childCompType; i < childrenArr.length; i++) {
        childCompType = getComponentType(ps, childrenArr[i])
        if (_.includes(types, childCompType)) {
            return true
        }
    }
    return false
}

function isComponentExist(ps: PS, compPointer: Pointer) {
    return ps.dal.isExist(compPointer)
}

const buildDefaultComponentStructure = (ps: PS, componentType: string) =>
    ps.extensionAPI.components.buildDefaultComponentStructure(componentType)

function getAncestorByPredicate(ps: PS, compPointer: Pointer, predicate: (parentPtr: Pointer) => boolean) {
    return ps.pointers.components.getAncestorByPredicate(compPointer, predicate)
}

export default {
    buildDefaultComponentStructure,
    /**
     * returns the type/"class" of a component.
     *
     * @function
     * @memberof documentServices.components.component
     *
     * @param {Pointer} componentReference the reference to the component to get its type.
     * @returns {string} the name of the component Type/"class". 'null' if no corresponding component was found.
     *
     *      @example
     *      const photoType = documentServices.components.getType(myPhotoReference);
     */
    getType: getComponentType,
    /**
     * Returns the Definition of the component by componentType
     *
     * @returns {componentDefinition} the definition schema of the component
     *
     *      @example
     *      const buttonContainerCompRef = documentServices.components.getDefinition('wysiwyg.viewer.components.StripContainerSlideShow');
     * @param string componentType
     */
    getDefinition: getComponentDefinition,
    /**
     *
     */
    getPropertiesItem: getComponentProperties,
    /**
     * Returns the parent Component of a component.
     *
     * @returns {CompRef} the page that the component is in
     * @throws an error in case the '<i>componentReference</i>' is invalid.
     *
     *      @example
     *      const buttonContainerCompRef = documentServices.components.layout.getParent(buttonComponentRef);
     * @param {ps} ps
     * @param {CompRef} compPointer
     */
    getPage(ps: PS, compPointer: CompRef) {
        return ps.pointers.full.components.getPageOfComponent(compPointer)
    },
    /**
     * this should be used in every method that adds a component to a container,
     * some containers have other containers in them that the component should be added to
     * @param {ps} privateServices
     * @param {Pointer} containerPointer the container that we want to add a component to
     * @returns {Pointer} a pointer to the container
     */
    getContainerToAddComponentTo,
    /**
     * Returns the parent Component of a component.
     *
     * @param {CompRef} componentReference a Component Reference corresponding a Component in the document.
     * @returns {CompRef} the Component Reference of the parent component, or null if no parent component (example - for page)
     * @throws an error in case the '<i>componentReference</i>' is invalid.
     *
     *      @example
     *      const buttonContainerCompRef = documentServices.components.layout.getParent(buttonComponentRef);
     */
    getContainer: getParent,
    /**
     * Returns all parent Components of a component.
     *
     * @param {Pointer} componentReference a Component Reference corresponding a Component in the document.
     * @returns [{Pointer}] array of the Components Reference of the parent components, or empty array when there is no parent component (example - for page)
     * @throws an error in case the '<i>componentReference</i>' is invalid.
     *
     *      @example
     *      const buttonContainerCompRef = documentServices.components.getAncestors(buttonComponentRef);
     */
    getAncestors: getAncestorsFromDisplayed,
    getAncestorsFromFull,
    /**
     * Returns the Siblings array of a component.
     *
     * @param {Pointer} compReference a Component Reference corresponding a component in the document.
     * @returns {Pointer[]} an array of <i>'ComponentReference'</i>s of the Component's siblings.
     * @throws an Error in case the compReference isn't valid.
     */
    getSiblings,
    /**
     * Returns an array of the repeated items of a component.
     *
     * @param {Pointer} compReference a Component Reference corresponding a component in the document.
     * @returns {Pointer[]} an array of <i>'ComponentReference'</i>s of the Component's repeated components.
     * @throws an Error in case the compReference isn't valid.
     */
    getRepeatedComponents,
    /**
     * returns the children components of a parent component, that should be displayed on render.
     * If a site exists, these are the currently rendered children.
     *
     * @param {Pointer} parentCompReference a ComponentReference of a corresponding Component containing children.
     * taken from the parent's reference or from the current view mode of the document.
     * @returns {Pointer[]} an array of the Component's Children (Component) References.
     * @throws an error in case the <i>parentCompReference</i> is invalid.
     *
     *      @example
     *      const mainPageChildren = documentServices.components.getChildren(mainPageRef);
     */
    getChildren: getChildComponents,
    getChildrenWithScopes: getChildComponentsWithScopes,
    /**
     * returns the children components of a parent component, that should be displayed on render.
     * If a site exists, these are the currently rendered children.
     *
     * @param {Pointer} parentCompReference a ComponentReference of a corresponding Component containing children.
     * taken from the parent's reference or from the current view mode of the document.
     * @returns {Pointer[]} an array of the Component's Children (Component) References.
     * @throws an error in case the <i>parentCompReference</i> is invalid.
     *
     *      @example
     *      const mainPageChildren = documentServices.components.getChildren(mainPageRef);
     */
    getChildrenFromFull: getChildComponentsFromFull,
    /**
     * returns the children components of a parent component.
     *
     * @param {Pointer} parentCompReference a ComponentReference of a corresponding Component containing children.
     * taken from the parent's reference or from the current view mode of the document.
     * @returns {Pointer[]} an array of the Component's Children (Component) References.
     * @throws an error in case the <i>parentCompReference</i> is invalid.
     *
     *      @example
     *      const mainPageChildren = documentServices.components.getAllJsonChildren(mainPageRef);
     */
    getAllJsonChildren,
    /**
     * returns the children tpa components recurse of a parent component.
     *
     * @param {Pointer} parentCompReference a ComponentReference of a corresponding Component containing children.
     * @param {string} [viewMode] is the view mode of the document (DESKTOP|MOBILE), if not specified will be
     * taken from the parent's reference or from the current view mode of the document.
     * @returns {Pointer[]} an array of the Component's Children (Component) References.
     * @throws an error in case the <i>parentCompReference</i> is invalid.
     *
     *      @example
     *      const viewMode = 'DESKTOP'; // This is optional
     *      const mainPageChildren = documentServices.components.layout.getChildComponents(mainPageRef, viewMode);
     */
    getTpaChildren: getTpaChildComponents,
    getBlogChildren: getBlogChildComponents,
    getAncestorByPredicate,
    isRenderedOnSite,
    isContainsCompWithType,
    isExist: isComponentExist,
    isPageComponent
}
