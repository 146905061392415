import _ from 'lodash'

const getMockMetaSiteData = (overrides = {}) =>
    _.merge(
        {
            useOnboarding: true,
            externalUriMappings: [
                {fromExternalUri: 'from_url_different_key', toWixUri: 'old_to_url'},
                {fromExternalUri: 'from_url_same_key_should_override', toWixUri: 'old_to_url'}
            ],
            indexable: false,
            suppressTrackingCookies: false,
            thumbnail: 'thumbnailUrl',
            favicon: 'favIcon',
            metaTags: [
                {name: 'description', value: '', isProperty: false},
                {name: 'keywords', value: '', isProperty: false}
            ],
            title: ''
        },
        overrides
    )
export {getMockMetaSiteData}
