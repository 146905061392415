import type {Layout, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import dataModel from '../../dataModel/dataModel'
import componentStylesAndSkinsAPI from '../../component/componentStylesAndSkinsAPI'
import component from '../../component/component'

const MIN_GALLERY_HEIGHT = 70

function beforeLayout(ps: PS, compPointer: Pointer, newLayout: Layout) {
    if (_.isUndefined(newLayout.height)) {
        return
    }
    const newHeight = Math.max(MIN_GALLERY_HEIGHT, newLayout.height)
    const currentLayout = component.layout.get(ps, compPointer)
    const currentHeight = _.get(currentLayout, 'height')
    if (_.isEqual(currentHeight, newHeight) && _.isEqual(currentLayout.width, newLayout.width)) {
        return
    }
    const compProp = dataModel.getPropertiesItem(ps, compPointer)
    const compData = dataModel.getDataItem(ps, compPointer)

    const skinExports = componentStylesAndSkinsAPI.skin.getComponentSkinExports(ps, compPointer)
    const heightDiff = skinExports?.heightDiff ?? 0
    const numberOfRows = santaCoreUtils.matrixCalculations.getAvailableRowsNumber(
        compProp.maxRows,
        compProp.numCols,
        compData.items.length
    )

    const itemHeight = santaCoreUtils.matrixCalculations.getItemHeight(
        compProp.margin,
        newHeight,
        numberOfRows,
        heightDiff
    )
    component.properties.update(ps, compPointer, {itemHeight})
}

export default {
    before: beforeLayout
}
