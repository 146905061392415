import _ from 'lodash'

const getRangeSize = (range: number[]) => range[1] - range[0] + 1

const swapGroups = <T>(arr: T[], firstGroupRange: [number, number], secondGroupRange: [number, number]): T[] => {
    const firstGroupStart = firstGroupRange[0]
    const firstGroupEnd = firstGroupRange[1]
    const secondGroupStart = secondGroupRange[0]
    const secondGroupEnd = secondGroupRange[1]

    return [
        ...arr.slice(0, firstGroupStart),
        ...arr.slice(secondGroupStart, secondGroupEnd + 1),
        ...arr.slice(firstGroupEnd + 1, secondGroupStart),
        ...arr.slice(firstGroupStart, firstGroupEnd + 1),
        ...arr.slice(secondGroupEnd + 1)
    ]
}

const moveGroupToIndex = <T>(arr: T[], groupRange, to): T[] => {
    const newArr = _.clone(arr)
    newArr.splice(to, 0, ...newArr.splice(groupRange[0], getRangeSize(groupRange)))
    return newArr
}

const moveGroupToEndOfArray = <T>(arr: T[], groupRang) =>
    moveGroupToIndex(arr, groupRang, arr.length - getRangeSize(groupRang))

const moveGroupToStartOfArray = <T>(arr: T[], groupRang) => moveGroupToIndex(arr, groupRang, 0)

const findNext = <T>(arr: T[], predicate: (i: T) => boolean, index: number): number =>
    _.findIndex(arr, predicate, index + 1)

const findPrev = <T>(arr: T[], predicate: (i: T) => boolean, index: number): number => {
    const prevIndex = index - 1

    if (prevIndex === -1) {
        return prevIndex
    }

    return _.findLastIndex(arr, predicate, prevIndex)
}

const isInRange = (index: number, [startIndex, endIndex]: [number, number]) => index >= startIndex && index <= endIndex

const isBeforeRange = (index: number, [startIndex]: [number]) => index < startIndex

const getStartIndex = ([startIndex]: [number]) => startIndex

const getEndIndex = ([, endIndex]: [number, number]) => endIndex

const findNearestValidIndex = (array: any[], index: number, validIndexRanges) => {
    validIndexRanges = _.isEmpty(validIndexRanges) ? [[0, array.length - 1]] : validIndexRanges
    const sortedRanges = _.sortBy(validIndexRanges, ([start]) => start)
    for (let i = 0; i < sortedRanges.length; i++) {
        const currRange = sortedRanges[i]
        if (isInRange(index, currRange)) {
            return index
        }

        if (isBeforeRange(index, currRange)) {
            if (i === 0) {
                return getStartIndex(currRange)
            }

            const prevRange = sortedRanges[i - 1]
            return getEndIndex(prevRange)
        }
    }

    return getEndIndex(_.last(sortedRanges))
}

export default {
    swapGroups,
    moveGroupToIndex,
    moveGroupToEndOfArray,
    moveGroupToStartOfArray,
    findNearestValidIndex,
    findNext,
    findPrev
}
