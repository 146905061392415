import _ from 'lodash'
import {Extension, ExtensionAPI, CreateExtensionArgument, deepCompare, DmApis} from '@wix/document-manager-core'
import type {ViewItem, ViewNamespace, ViewsStore, ViewsResult, ViewsServer} from './viewsTypes'
import {createImmutableProxy} from '@wix/wix-immutable-proxy'
import type {DocumentServicesModelExtApi} from '../documentServicesModel'
import type {RMApi} from '../rendererModel'
import {ChannelEvents} from '../channelUtils/duplexer'
import {extractViewsFromResponse} from './viewsUtils'
import {ReportableError, Deferred, taskWithRetries} from '@wix/document-manager-utils'
type Callback = () => void

interface ViewsAPI extends ExtensionAPI {
    get(namespace: string, id: string): ViewItem
    getAll(namespace: string, ids?: string[]): ViewNamespace
    registerViewsUpdateCallback(callback: Callback): void
    isSynced(): Promise<void>
}

interface ViewsExtensionAPI extends ExtensionAPI {
    views: ViewsAPI
}

const channelName = 'dm_metadata'
const DUPLEXER_RETRY_TIME = 150
const VIEWS_DUPLEXER_INIT_RETRY_NUMBER = 2

/**
 * @returns {Extension}
 */
const createExtension = ({environmentContext, dsConfig}: CreateExtensionArgument): Extension => {
    const deferredIsSynced: Deferred<void> = new Deferred()
    const viewsStore: ViewsStore = {}
    const viewsUpdateCallbacks: Callback[] = []

    const invokeViewsUpdateCallbacks = () => {
        _.invokeMap(viewsUpdateCallbacks, Function.prototype.call)
    }

    const registerViewsUpdateCallback = (callback: Callback) => {
        viewsUpdateCallbacks.push(callback)
    }
    const update = (viewsData: ViewsResult) => {
        let shouldInvokeUpdateCallbacks = false
        _.forEach(extractViewsFromResponse(viewsData), (data: ViewNamespace, namespace: string) => {
            _.forEach(data, (item: ViewItem, id: string) => {
                if (!deepCompare(viewsStore[namespace]?.[id], item)) {
                    _.setWith(viewsStore, [namespace, id], item, Object)
                    shouldInvokeUpdateCallbacks = true
                }
            })
        })

        if (shouldInvokeUpdateCallbacks) {
            invokeViewsUpdateCallbacks()
        }
    }
    const createExtensionAPI = (): ViewsExtensionAPI => {
        const getAll = (namespace: string, ids?: string[]) => {
            if (ids) {
                return _.pick(viewsStore[namespace], ids)
            }
            return viewsStore[namespace] ?? {}
        }

        const isSynced = () => deferredIsSynced.promise

        return {
            views: {
                get: (namespace, id) => createImmutableProxy(viewsStore[namespace]?.[id]),
                getAll: (namespace, ids) => createImmutableProxy(getAll(namespace, ids)),
                registerViewsUpdateCallback,
                isSynced
            }
        }
    }

    const initializeChannelSubscriptions = async ({extensionAPI, coreConfig}: DmApis) => {
        const reportAndThrow = (e: any, errorType: string) => {
            const err = e as Error
            const {logger} = coreConfig
            logger.captureError(
                new ReportableError({
                    errorType,
                    message: err.message
                })
            )
            throw e
        }

        const initViewsInternal = async () => {
            if (environmentContext.serverFacade) {
                const server: ViewsServer = environmentContext.serverFacade
                const {siteAPI: dsSiteApi} = extensionAPI as DocumentServicesModelExtApi
                const {siteAPI: rmSiteApi} = extensionAPI as RMApi
                try {
                    const duplexer = server.createDuplexer(
                        () => rmSiteApi.getInstance(),
                        dsConfig.origin,
                        coreConfig.logger,
                        dsSiteApi.getBranchId()
                    )
                    duplexer.on(ChannelEvents.viewsUpdate, (payload: ViewsResult) => {
                        update(payload)
                    })
                    duplexer.on(ChannelEvents.outOfSync, async () => {
                        const {pagesMetadata} = await server.getViews()
                        if (pagesMetadata) {
                            update(pagesMetadata)
                        }
                        deferredIsSynced.resolve()
                    })
                    await duplexer.subscribe(channelName)
                } catch (e) {
                    reportAndThrow(e, 'duplexerViewsCreationError')
                }
            }
        }

        try {
            await taskWithRetries(initViewsInternal, undefined, VIEWS_DUPLEXER_INIT_RETRY_NUMBER, DUPLEXER_RETRY_TIME)
        } catch (e) {
            reportAndThrow(e, 'duplexerViewsCreationErrorAfterRetry')
        }
    }

    return {
        name: 'views',
        createExtensionAPI,
        initializeChannelSubscriptions
    }
}

export {createExtension, ViewsExtensionAPI, ViewsAPI}
