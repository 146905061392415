import type {SerializedPageBackground, PagesData, Pointer, PS, ValueOf} from '@wix/document-services-types'
import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import dataModel from '../dataModel/dataModel'
import pageUtils from './pageUtils'
import popupUtils from './popupUtils'
import constants from '../constants/constants'
import routersGetters from '../routers/routersGetters'
import hooks from '../hooks/hooks'
import language from '../siteMetadata/language'
import menuUtils from '../menu/menuUtils'
import mlUtils from '../utils/multilingual'
import dsUtils from '../utils/utils'
import type {PageExtensionAPI} from '@wix/document-manager-extensions'
import documentModeInfo from '../documentMode/documentModeInfo'

const {DATA_TYPES} = santaCoreUtils.constants
const DEVICES = ['desktop', 'mobile'] as const

export type DeviceType = ValueOf<typeof DEVICES>

function getFilteredPagesList(ps: PS, includeMasterPage: boolean, getOnlyPopups: boolean) {
    const allPagesPointers = ps.pointers.page.getNonDeletedPagesPointers(includeMasterPage)
    const whatIsPage = function (pageId: string) {
        const isPopup = popupUtils.isPopup(ps, pageId)
        return getOnlyPopups ? isPopup : !isPopup
    }

    return _(allPagesPointers).map('id').filter(whatIsPage).value()
}

function getPagesAndPopupsList(ps: PS, includeMasterPage: boolean) {
    const allPagesPointers = ps.pointers.page.getNonDeletedPagesPointers(includeMasterPage)
    return _.map(allPagesPointers, 'id')
}

function getPageDataById(ps: PS, pageId: string, deleteIds: boolean, useOriginalLanguage = false) {
    const pageDataItemPointer = ps.pointers.data.getDataItemFromMaster(pageId)
    return dataModel.serializeDataItem(ps, DATA_TYPES.data, pageDataItemPointer, deleteIds, useOriginalLanguage)
}

function isDuplicatePageUriSeo(ps: PS, excludePageId: string, pageUriSEO: string) {
    const langCode = mlUtils.getLanguageByUseOriginal(ps, false)
    return ps.extensionAPI.page.isDuplicatePageUriSeo(excludePageId, pageUriSEO, langCode)
}

function getDuplicatePageTitle(ps: PS, excludePageId: string, pageUriSEO: string) {
    const routerRef = routersGetters.get.byPrefix(ps, pageUriSEO)
    if (routerRef) {
        return pageUriSEO
    }

    const pageIds = pageDataModule.getPagesList(ps, false)
    const duplicatePageId = _(pageIds)
        .pull(excludePageId)
        .find(pageId => (ps.extensionAPI as PageExtensionAPI).page.getPageUriSEO(pageId) === pageUriSEO)

    if (duplicatePageId) {
        return getPageInnerItem(ps, duplicatePageId, 'title')
    }
}

function isForbiddenPageUriSeo(ps: PS, pageId: string, pageUriSEO: string) {
    const forbiddenWords = pageDataModule.getForbiddenPageUriSEOs(ps)
    const currentPageUriSEO = (ps.extensionAPI as PageExtensionAPI).page.getPageUriSEO(pageId)

    delete forbiddenWords[currentPageUriSEO]

    return forbiddenWords.hasOwnProperty(pageUriSEO.toLowerCase())
}

function isPageUriSeoTooLong(pageUriSEO: string, marginForError: number = 0) {
    marginForError = marginForError || 0
    return _.size(pageUriSEO) > constants.URLS.MAX_LENGTH - marginForError
}

function hasIllegalChars(pageUriSEO: string) {
    return pageUtils.uriHasIllegalCharacters(pageUriSEO)
}

function getPairsCheckValue(pairs: [(s: string) => boolean, string][], pageUriSEO: string): string {
    return _.chain(pairs)
        .find(pair => pair[0](pageUriSEO))
        .get(1, '')
        .value() as string
}

function getPageUriSeoContentErrorMessage(ps: PS, pageId: string, pageUriSEO: string) {
    return getPairsCheckValue(
        [
            [isDuplicatePageUriSeo.bind(null, ps, pageId), 'pageUriSEO is invalid: not unique across site'],
            [isForbiddenPageUriSeo.bind(null, ps, pageId), 'pageUriSEO is invalid: reserved word']
        ],
        pageUriSEO
    )
}

function getPageUriSeoFormatErrorMessage(pageUriSEO: string) {
    return getPairsCheckValue(
        [
            [isPageUriSeoTooLong, `pageUriSEO is invalid: over ${constants.URLS.MAX_LENGTH} chars`],
            [hasIllegalChars, 'pageUriSEO is invalid: must only be alphanumeric or hyphen']
        ],
        pageUriSEO
    )
}

function getPageUriSeoErrorMessage(ps: PS, pageId: string, pageUriSEO: string) {
    const urlFormatPointer = ps.pointers.general.getUrlFormat()
    const urlFormat = ps.dal.get(urlFormatPointer)

    if (urlFormat === santaCoreUtils.siteConstants.URL_FORMATS.HASH_BANG) {
        return ''
    }

    return getPageUriSeoContentErrorMessage(ps, pageId, pageUriSEO) || getPageUriSeoFormatErrorMessage(pageUriSEO)
}

const isValidPageUriSeo = (ps: PS, pageId: string, pageUriSEO: string) => {
    const error = getPageUriSeoErrorMessage(ps, pageId, pageUriSEO)

    return _.isEmpty(error)
}
function getValidPageUriSEO(ps: PS, pageId: string, initialPageUriSEO: string) {
    const langCode = mlUtils.getLanguageByUseOriginal(ps, false)
    return ps.extensionAPI.page.getValidPageUriSEO(pageId, initialPageUriSEO, langCode)
}

function getDeserializedPageData(ps: PS, pageId: string, useOriginalLanguage = false) {
    const useLanguage = mlUtils.getLanguageByUseOriginal(ps, useOriginalLanguage)
    const pagePointer = ps.pointers.data.getDataItemFromMaster(pageId)
    return ps.dal.get({...pagePointer, useLanguage})
}
const newCopyPageTranslations = (ps: PS, oldPageId: string, newPageId: string) => {
    const oldPageCompPointer = ps.pointers.components.getPage(oldPageId, constants.VIEW_MODES.DESKTOP)
    const newPageCompPointer = ps.pointers.components.getPage(newPageId, constants.VIEW_MODES.DESKTOP)
    const newPageRootData = getDeserializedPageData(ps, newPageId, true)
    _.forEach(language.getTranslationLanguageCodes(ps), languageCode => {
        const dataInLang = dataModel.multilingual.get(ps, languageCode, oldPageCompPointer, true)
        if (dataInLang) {
            dataModel.multilingual.update(ps, languageCode, newPageCompPointer, {
                ...dataInLang,
                pageUriSEO: newPageRootData.pageUriSEO
            })
        }
    })
}

/**
 * Copies translation data from one page to another (keeps it in the original page)
 * @param ps
 * @param pageId
 * @param newPageId
 * @param isPopup
 */
function copyTranslationData(ps: PS, pageId: string, newPageId: string, isPopup = false) {
    if (!language.multilingual.isEnabled(ps)) {
        return
    }

    newCopyPageTranslations(ps, pageId, newPageId)

    if (!isPopup) {
        copyPageMenuItemTranslationData(ps, pageId, newPageId)
    }
}
function copyPageMenuItemTranslationData(ps: PS, pageId: string, newPageId: string) {
    const menuItemPointer = menuUtils.getPageMenuItemPointer(ps, pageId)
    const newMenuItemPointer = menuUtils.getPageMenuItemPointer(ps, newPageId)
    const newTranslationData = {
        id: newMenuItemPointer.id,
        link: menuUtils.getLinkIdByMenuItemId(ps, newMenuItemPointer.id)
    }
    language.copyDataItemTranslations(
        ps,
        constants.MASTER_PAGE_ID,
        menuItemPointer.id,
        constants.MASTER_PAGE_ID,
        newTranslationData
    )
}

function getPageInnerItem(ps: PS, pageId: string, key: string) {
    const pageDataItemPointer = ps.pointers.data.getDataItemFromMaster(pageId)
    const pageInnerItemPointer = ps.pointers.getInnerPointer(pageDataItemPointer, key)
    return ps.dal.get(pageInnerItemPointer)
}

/** @class documentServices.pages*/

const pageDataModule = {
    getStaticPagesCount(ps: PS) {
        const dynamicPagesIdMap = {}
        _.values(routersGetters.get.all(ps)).forEach(r => {
            _.values(r.pages).forEach(pageId => {
                dynamicPagesIdMap[pageId] = true
            })
        })
        const pageIds = pageDataModule.getPagesList(ps, false)
        let staticPageCount = 0
        pageIds.forEach(pageId => {
            const pointer = ps.pointers.data.getDataItemFromMaster(pageId)
            const pageData = ps.dal.full.getNoClone(pointer)
            const isStaticPage =
                !dynamicPagesIdMap[pageData.id] &&
                !pageData.isPopup &&
                !pageData.tpaApplicationId &&
                !pageData.managingAppDefId
            if (isStaticPage) {
                ++staticPageCount
            }
        })
        return staticPageCount
    },

    getPagesDataItems(ps: PS) {
        const pageIds = pageDataModule.getPagesList(ps, false)
        return _.map(pageIds, pageId => pageDataModule.getPageData(ps, pageId))
    },

    getPopupsDataItems(ps: PS) {
        return pageDataModule.getPopupsList(ps).map(popupId => pageDataModule.getPageData(ps, popupId))
    },

    getNumberOfPages(ps: PS) {
        return pageDataModule.getPagesList(ps, false).length
    },

    getPagesList(ps: PS, includeMasterPage?: boolean, includingPopups?: boolean) {
        if (includingPopups) {
            return getPagesAndPopupsList(ps, includeMasterPage)
        }
        return getFilteredPagesList(ps, includeMasterPage, false)
    },

    getPopupsList(ps: PS) {
        return getFilteredPagesList(ps, false, true)
    },

    doesPageExist(ps: PS, pageId: string) {
        const pagePointer = ps.pointers.page.getPagePointer(pageId)
        return Boolean(pagePointer)
    },

    addPageData(ps: PS, pageId: string, data, shouldAddMenuItem = true) {
        const pagePointer = ps.pointers.components.getPage(pageId, documentModeInfo.getViewMode(ps))
        const langCode = mlUtils.getLanguageByUseOriginal(ps, false)
        ps.extensionAPI.page.data.add(pagePointer, data, shouldAddMenuItem, langCode)
    },

    setPageData(
        ps: PS,
        pageId: string,
        data: Partial<PagesData>,
        useOriginalLanguage = false,
        applyChangeToAllLanguages = false
    ) {
        const pagePointer = ps.pointers.components.getPage(pageId, documentModeInfo.getViewMode(ps))
        const langCode = mlUtils.getLanguageByUseOriginal(ps, useOriginalLanguage)
        ps.extensionAPI.page.data.update(pagePointer, data, applyChangeToAllLanguages, langCode)
    },

    getPageData(ps: PS, pageId: string, useOriginalLanguage = false) {
        return getPageDataById(ps, pageId, false, useOriginalLanguage)
    },

    getDeserializedPageData,
    getPageDataWithoutIds(ps: PS, pageId: string, useOriginalLanguage = false) {
        return getPageDataById(ps, pageId, true, useOriginalLanguage)
    },

    getBgDataItem(ps: PS, pageId: string, device: DeviceType) {
        if (!_.includes(DEVICES, device)) {
            throw new Error('unknown device for background')
        }
        const pageData = pageDataModule.getPageData(ps, pageId)
        return _.get(pageData, ['pageBackgrounds', device as string])
    },

    updateBgDataItem(ps: PS, pageId: string, bgData: SerializedPageBackground, device: DeviceType) {
        if (!_.includes(DEVICES, device)) {
            throw new Error('unknown device for background')
        }
        const data: Partial<PagesData> = {pageBackgrounds: {} as any}
        //@ts-ignore this is a temporary fix till the editor fixes their presets
        if (bgData.ref?.imageOverlay === '') {
            delete bgData.ref.imageOverlay
        }
        data.pageBackgrounds[device as string] = _.cloneDeep(bgData)
        pageDataModule.setPageData(ps, pageId, data as PagesData)

        hooks.executeHook(hooks.HOOKS.SITE_BACKGROUND_UPDATE.AFTER, null, [ps, pageId, device])
    },

    removePageBackgrounds(ps: PS, pagePointer: Pointer) {
        const {id: pageId} = pagePointer
        const dataPointer = ps.pointers.data.getDataItemFromMaster(pageId)
        const pageDataItem = ps.dal.get(dataPointer)

        if (pageDataItem.pageBackgrounds) {
            _.forEach(DEVICES, function (device) {
                const deviceBackground = pageDataItem.pageBackgrounds[device]
                if (deviceBackground?.ref) {
                    const innerDataPointer = ps.pointers.data.getDataItem(
                        dsUtils.stripHashIfExists(deviceBackground.ref),
                        pageId
                    )
                    dataModel.removeItemRecursivelyByType(ps, innerDataPointer)
                }
            })
        }
    },

    getForbiddenPageUriSEOs(ps: PS) {
        const forbiddenWordsPointer = ps.pointers.general.getForbiddenPageUriSEOs()
        return ps.dal.get(forbiddenWordsPointer) || {}
    },

    isDuplicatePageUriSeo,
    getDuplicatePageTitle,
    isForbiddenPageUriSeo,
    getValidPageUriSEO,
    isPageUriSeoTooLong,
    hasIllegalChars,
    isValidPageUriSeo,
    copyTranslationData,
    convertPageNameToUrl: pageUtils.convertPageNameToUrl
}

export default pageDataModule
