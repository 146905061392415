import {DAL, Extension, pointerUtils} from '@wix/document-manager-core'
import type {CreateViewerExtensionArgument} from '../types'
import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import {PAGE_DATA_TYPES, VIEW_MODES, VIEWER_DATA_TYPES} from '../constants/constants'
import {dataRefTypes, getRefPointerType, isRefPointer, structureRefTypes} from '../utils/refStructureUtils'

const {getPointer} = pointerUtils

const DISPLAYED_ONLY_TYPE_CANDIDATE = _.invert(VIEWER_DATA_TYPES)

const DATA_TYPES_TO_OVERRIDE_GETTERS = {
    ...VIEW_MODES,
    ...PAGE_DATA_TYPES
}

const REFERRED_DATA_TYPES = [...structureRefTypes, ...dataRefTypes]

const createExtension = ({viewerManager, dsConfig}: CreateViewerExtensionArgument): Extension => {
    const getter = ({get}: DAL, pointer: Pointer) => {
        if (pointer.noRefFallbacks) {
            return get(pointer)
        }

        if (isRefPointer(pointer)) {
            const newPointer = getRefPointerType(pointer)
            return viewerManager.dal.get(newPointer) || get(pointer)
        }

        const value = get(pointer)

        if (_.isUndefined(value) && DISPLAYED_ONLY_TYPE_CANDIDATE[pointer.type]) {
            const newPointer = getRefPointerType(pointer)
            return viewerManager.dal.get(newPointer)
        }

        return value
    }

    const viewerManagerGetter = ({}, pointer: Pointer) => viewerManager.dal.get(pointer)

    const createGetters = () => {
        const refDataGetters = _(REFERRED_DATA_TYPES)
            .keyBy()
            .mapValues(() => viewerManagerGetter)
            .value()
        const overridenGetters = _.mapValues(DATA_TYPES_TO_OVERRIDE_GETTERS, () => getter)

        return {
            ...refDataGetters,
            ...overridenGetters
        }
    }

    const getReferredStructurePointers = (): any => {
        return {
            getGhostRefComponents(compPrtId: string) {
                const pointer = {
                    type: 'ghostRefComps',
                    id: compPrtId
                }
                return viewerManager.dal.get(pointer)
            },
            getInternallyReferredComponents(compPtr: Pointer) {
                const pointer = getPointer(compPtr.id, 'internalyReferredComps')
                return viewerManager.dal.get(pointer)
            },
            getDisplayedConnectionOverrides(compId: string) {
                const pointer = getPointer(compId, 'displayedConnectionOverrides')
                return viewerManager.dal.get(pointer)
            },
            getRemoteOverrides(rootCompId: string, innerRefHostId: string) {
                const pointer = getPointer(rootCompId, 'remoteOverrides', {innerPath: [innerRefHostId]})
                return viewerManager.dal.get(pointer)
            }
        }
    }

    const createPointersMethods = () => ({
        referredStructure: getReferredStructurePointers(),
        full: {
            referredStructure: getReferredStructurePointers()
        }
    })

    const extension: Extension = {
        name: 'refComponents',
        createPointersMethods,
        dependencies: new Set(['refOverrides'])
    }

    if (!dsConfig.enableScopes) {
        // @ts-expect-error
        extension.createGetters = createGetters
    }

    return extension
}

export {createExtension}
