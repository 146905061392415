import type {WixCodeConnectionItem} from '@wix/document-services-types'
import _ from 'lodash'

export const getWixCodeConnectionItem = (currentConnections: any): WixCodeConnectionItem | undefined => {
    return _.find(currentConnections, {type: 'WixCodeConnectionItem'})
}
export const findConnectionByContext = (compConnections: Record<string, any>, context?: Record<string, any>) => {
    return context
        ? _.find(compConnections, {controllerRef: {id: context.id}})
        : getWixCodeConnectionItem(compConnections)
}
export const getNicknameFromConnectionList = (
    compConnections: any,
    context?: Record<string, any>
): string | undefined => {
    const connectionItem = findConnectionByContext(compConnections, context)
    if (connectionItem) {
        return connectionItem.role
    }
}
