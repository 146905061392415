import _ from 'lodash'

const presetIdsToPresetData = presetIds => ({
    type: 'PresetData',
    ...presetIds
})

/**
 * Create RefComponent structure template
 * Insert app and widget data for the component to fetch its data from remote app
 *
 * @param appDefinitionId
 * @param widgetId
 * @param variationId
 * @param options
 * @returns Object RefComponent structure
 */
const generateRefComponentStructure = (
    appDefinitionId: string,
    widgetId: string,
    variationId: string,
    options = {}
) => {
    const {presets, scopedPresets, layout, layouts, overriddenData, mobileHints, mobileStructure, props}: any = options
    const widgetRefStructure = {
        componentType: 'wysiwyg.viewer.components.RefComponent',
        custom: {overriddenData},
        layout: {rotationInDegrees: 0, width: 100, height: 100, scale: 1, x: 0, y: 0, fixedPosition: false},
        type: 'RefComponent',
        style: 'ref1',
        data: {
            type: 'WidgetRef',
            appDefinitionId,
            widgetId,
            variationId
        }
    }

    return {
        ...widgetRefStructure,
        ...(presets ? {presets: presetIdsToPresetData(presets)} : {}),
        ...(scopedPresets ? {scopedPresets: _.mapValues(scopedPresets, presetIdsToPresetData)} : {}),
        ...(layout ? {layout} : {}),
        ...(layouts ? {layouts} : {}),
        ...(mobileHints ? {mobileHints} : {}),
        ...(mobileStructure ? {mobileStructure} : {}),
        ...(props ? {props} : {})
    }
}

export default {
    generateRefComponentStructure
}
