import _ from 'lodash'

const SCOPE = {
    APP: 'APP',
    COMPONENT: 'COMPONENT'
}

function getTpaDataContent(siteData, tpaDataQuery, pageId) {
    const tpaData = siteData.getDataByQuery(tpaDataQuery, pageId)
    if (tpaData?.content) {
        return JSON.parse(tpaData.content)
    }
    return null
}

function getKeyValueObj(siteData, key, tpaDataQuery, pageId) {
    if (!_.isEmpty(tpaDataQuery)) {
        const tpaDataContent = getTpaDataContent(siteData, tpaDataQuery, pageId)
        if (tpaDataContent) {
            return _.pick(tpaDataContent, key)
        }
    }
    return null
}

function getValue(siteData, msg, rootOfComp, compData) {
    let result
    if (!compData) {
        result = {}
    } else {
        const {key, scope} = msg.data
        if (scope === SCOPE.APP) {
            result = getKeyValueObj(siteData, key, `tpaData-${compData.applicationId}`, 'masterPage')
        } else {
            result = getKeyValueObj(siteData, key, compData.tpaData, rootOfComp)
        }
        if (_.isEmpty(result)) {
            result = {
                error: {
                    message: `key ${key} not found in ${scope} scope`
                }
            }
        }
    }
    return result
}

export default {
    SCOPE,
    getValue
}
