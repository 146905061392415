import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'
import constants from '../../constants/constants'
import metaDataUtils from '../../componentsMetaData/metaDataUtils'
import componentApi from '../../component/component'
import connections from '../../connections/connections'
import type {DsFixer} from '../dsDataFixers'

const FORM_BUILDER_PLUGIN = 'form-builder'
const MULTI_STEP_PLUGIN = 'multi-step-form'
const GET_SUBSCRIBERS_PLUGIN = 'get-subscribers'
const REGISTRATION_FORM_PLUGIN = 'registration-form'

const REGISTRATION_FORM_CONTROLLER = 'registrationForm'
const GET_SUBSCRIBERS_CONTROLLER = 'getSubscribers'
const WIX_FORMS_CONTROLLER = 'wixForms'
const MULTI_STEP_FORM_CONTROLLER = 'multiStepForm'

const CONTROLLER_COMPONENT_TYPE = 'platform.components.AppController'
const APP_WIDGET_COMPONENT_TYPE = 'platform.components.AppWidget'
const FORM_ROLE = 'form_Role'
const FORMS_APP_ID = '14ce1214-b278-a7e4-1373-00cebd1bef7c'

const OLD_CONTROLLER_TYPE = 'singlePostController'

const isController = (compType: string) =>
    compType === CONTROLLER_COMPONENT_TYPE || compType === APP_WIDGET_COMPONENT_TYPE

const isFormControllerToFix = (ps: PS) => (compPointer: Pointer) => {
    if (!isController(metaDataUtils.getComponentType(ps, compPointer))) {
        return false
    }
    const compData = componentApi.data.get(ps, compPointer)
    return compData && compData.applicationId === FORMS_APP_ID && compData.controllerType === OLD_CONTROLLER_TYPE
}

const pluginToControllerType = (formPlugin: string) =>
    ({
        [FORM_BUILDER_PLUGIN]: WIX_FORMS_CONTROLLER,
        [MULTI_STEP_PLUGIN]: MULTI_STEP_FORM_CONTROLLER,
        [REGISTRATION_FORM_PLUGIN]: REGISTRATION_FORM_CONTROLLER,
        [GET_SUBSCRIBERS_PLUGIN]: GET_SUBSCRIBERS_CONTROLLER
    }[formPlugin])

const inferControllerTypeByPreset = formConnection => {
    const formPreset = _.get(formConnection, 'config.preset')
    if (_.includes(formPreset, 'registration-form')) {
        return REGISTRATION_FORM_CONTROLLER
    }
    if (_.includes(formPreset, 'multi-step')) {
        return MULTI_STEP_FORM_CONTROLLER
    }
    if (_.includes(formPreset, 'subscribers')) {
        return GET_SUBSCRIBERS_CONTROLLER
    }
    return null
}

const fixFormController = (ps: PS) => (controllerRef: Pointer) => {
    const connectedFormRef = _.find(connections.getConnectedComponents(ps, controllerRef), compPointer => {
        const compPrimaryConnection = connections.getPrimaryConnection(ps, compPointer)
        return _.get(compPrimaryConnection, 'role') === FORM_ROLE
    })

    const formPrimaryConnection = connections.getPrimaryConnection(ps, connectedFormRef)
    const formPlugin = _.get(formPrimaryConnection, 'config.plugins[0].id')

    const newControllerType =
        pluginToControllerType(formPlugin) || inferControllerTypeByPreset(formPrimaryConnection) || WIX_FORMS_CONTROLLER

    componentApi.data.update(ps, controllerRef, {controllerType: newControllerType})
}

const fixer: DsFixer = {
    exec(ps: PS) {
        const formControllersToFix = componentDetectorAPI.getAllComponentsFromFull(
            ps,
            null,
            isFormControllerToFix(ps),
            constants.VIEW_MODES.DESKTOP
        )
        const uniqueFormControllers = _.uniqBy(formControllersToFix, 'id')
        _.forEach(uniqueFormControllers, fixFormController(ps))
    },
    name: 'formsControllerTypeDataFixer',
    version: 1
}
export default fixer
