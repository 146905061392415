import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import component from '../../component/component'
import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'
import type {DsFixer} from '../dsDataFixers'

function fixOrphanSlide(ps: PS, slidesContainerType: string, slideType: string) {
    const allSlidesPointers = componentDetectorAPI.getComponentByType(ps, slideType)

    _.forEach(allSlidesPointers, slidePointer => {
        const slideParent = ps.dal.full.get(ps.pointers.full.components.getParent(slidePointer))
        const isSlideParentIllegal = _.get(slideParent, 'componentType') !== slidesContainerType

        if (isSlideParentIllegal) {
            if (ps.dal.isExist(slidePointer)) {
                component.remove(ps, slidePointer)
            } else {
                component.removeFromFull(ps, slidePointer)
            }
        }
    })
}

function exec(ps: PS) {
    fixOrphanSlide(
        ps,
        'wysiwyg.viewer.components.StripContainerSlideShow',
        'wysiwyg.viewer.components.StripContainerSlideShowSlide'
    )
    fixOrphanSlide(ps, 'wysiwyg.viewer.components.BoxSlideShow', 'wysiwyg.viewer.components.BoxSlideShowSlide')
}

const fixer: DsFixer = {
    exec,
    name: 'orphanSlideDataFixer',
    version: 1
}
export default fixer
