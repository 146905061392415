import _ from 'lodash'
import systemFolders from './systemFolders'

function isUndoableField(fieldDef) {
    return fieldDef.type === 'pagelink' && fieldDef.plugin === 'dataBinding'
}

function assembleFieldsInSameOrder(nonUndoableFields, undoableFields) {
    const undoableFieldNamesToKeep = _.keys(undoableFields)
    const assembledFields = _.assign({}, nonUndoableFields, undoableFields)
    return _.omitBy(assembledFields, function (fieldDef, fieldName) {
        return isUndoableField(fieldDef) && !_.includes(undoableFieldNamesToKeep, fieldName)
    })
}

function assembleSchema(loadedContent, modifiedContent, undoableModifiedContent) {
    const parsedLoadedContent = JSON.parse(loadedContent || '{}')
    const nonUndoableSource = modifiedContent ? JSON.parse(modifiedContent) : parsedLoadedContent
    const undoableSource = undoableModifiedContent ? JSON.parse(undoableModifiedContent) : parsedLoadedContent
    const undoableFields = _.pickBy(undoableSource.fields, isUndoableField)

    return JSON.stringify(
        _.assign({}, nonUndoableSource, {
            fields: assembleFieldsInSameOrder(nonUndoableSource.fields, undoableFields)
        })
    )
}

function isSchemaFile(filePath: string) {
    return _.startsWith(filePath, `${systemFolders.SCHEMAS}/`)
}

function isSchemasDirectory(directoryPath: string) {
    return directoryPath === `${systemFolders.SCHEMAS}/`
}

function isValidContent(content) {
    try {
        const parsedContent = JSON.parse(content)
        return _.isObject(parsedContent)
    } catch (e) {
        return false
    }
}

function getSchemaIdFromFilePath(schemaPath: string) {
    const fromIndex = `${systemFolders.SCHEMAS}/`.length
    const toIndex = schemaPath.length - '.json'.length
    const schemaId = schemaPath.substring(fromIndex, toIndex) // the ID is between the path to schemas and the file extension
    return schemaId
}

export default {
    assembleSchema,
    isSchemaFile,
    isSchemasDirectory,
    isValidContent,
    getSchemaIdFromFilePath
}
