import type {AppDefinitionId, Pointer} from '@wix/document-services-types'
import {deepClone} from '@wix/wix-immutable-proxy'
import _ from 'lodash'
import type {PlatformSharedState} from '../platformSharedState'
import type {DataModelExtensionAPI} from '../dataModel/dataModel'
import {DATA_TYPES, MASTER_PAGE_ID} from '../../constants/constants'
import {getComponentType} from '../../utils/dalUtils'
import {guidUtils} from '@wix/santa-core-utils'
import type {CreateExtArgs} from '@wix/document-manager-core'
import type {TPAExtensionAPI} from '../tpa'
const MAX_SIZE_FOR_APP = 1000
const MAX_SIZE_FOR_COMP = 400
const MAX_SIZE_FOR_SUPER_APP_COMP = 4000
const MAX_SIZE_FOR_SUPER_APP = 2000
const PREFIX_TPA_DATA_ID = 'tpaData-'
const ONLY_TPA_COMPS_SUPPORTED =
    'tpa.data APIs can only be used with TPA components (TPAWidget, TPASection, TPAMultiSection, TPAGluedWidget)'
const SCOPE = {
    APP: 'APP',
    COMPONENT: 'COMPONENT'
} as const
export const getTpaAppDataId = ({pointers, dal}: CreateExtArgs, appDefinitionId: AppDefinitionId) => {
    const csmEntryPointer = pointers.rendererModel.getClientSpecMapEntryByAppDefId(appDefinitionId)
    const csmEntry = dal.get(csmEntryPointer)
    if (csmEntry) {
        const {applicationId} = csmEntry
        return PREFIX_TPA_DATA_ID + applicationId
    }
    return ''
}
export const getTpaAppData = (createExtArgs: CreateExtArgs, appDefinitionId: AppDefinitionId) => {
    const {dal, pointers} = createExtArgs
    const appTpaDataId = getTpaAppDataId(createExtArgs, appDefinitionId)
    const dataPointer = pointers.data.getDataItem(appTpaDataId, 'masterPage')
    const tpaData = deepClone(dal.get(dataPointer))
    if (tpaData) {
        tpaData.content = tpaData.content ? JSON.parse(tpaData.content) : {}
    }
    return tpaData
}
const getDefaultTpaAppData = (tpaDataId: string) => ({
    type: 'TPAGlobalData',
    id: tpaDataId,
    content: {}
})

const getContentLimit = ({dal, pointers}: CreateExtArgs, appDefinitionId: AppDefinitionId, scope: string) => {
    const csmEntryPointer = pointers.rendererModel.getClientSpecMapEntryByAppDefId(appDefinitionId)
    const appData = dal.get(csmEntryPointer)
    if (_.get(appData, ['isWixTPA'])) {
        return scope === SCOPE.APP ? MAX_SIZE_FOR_SUPER_APP : MAX_SIZE_FOR_SUPER_APP_COMP
    }

    return scope === SCOPE.APP ? MAX_SIZE_FOR_APP : MAX_SIZE_FOR_COMP
}

const isValidSize = (tpaDataContent: string, maxSize: number) => {
    try {
        return tpaDataContent.length <= maxSize
    } catch (e) {
        return false
    }
}

const setAndReturnConfig = (
    {extensionAPI}: CreateExtArgs,
    tpaDataId: string,
    tpaData: any,
    pageId: string,
    config: Record<string, any>,
    maxSize: number,
    appDefinitionId: string,
    langCode?: string | undefined
) => {
    const currentContent = tpaData.content
    const tpaDataContentAsString = JSON.stringify({...tpaData.content, ...config})
    if (!_.isEqual(currentContent, JSON.parse(tpaDataContentAsString))) {
        const {platformSharedState} = extensionAPI as PlatformSharedState
        platformSharedState.notifyPlatformAPIWasCalled(appDefinitionId)
    }
    if (!isValidSize(tpaDataContentAsString, maxSize)) {
        return null
    }
    tpaData.content = tpaDataContentAsString
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    dataModel.addItem(tpaData, 'data', pageId, tpaData.id, langCode)
    return config
}

export const setAppValueByAppDefId = (
    createExtArgs: CreateExtArgs,
    appTpaData: any,
    appDefinitionId: AppDefinitionId,
    config: Record<string, any>,
    langCode?: string | undefined
) => {
    const tpaDataId = appTpaData ? appTpaData.id : getTpaAppDataId(createExtArgs, appDefinitionId)

    if (!appTpaData) {
        appTpaData = getDefaultTpaAppData(tpaDataId)
    }
    appTpaData.appDefinitionId = appDefinitionId
    const limit = getContentLimit(createExtArgs, appDefinitionId, SCOPE.APP)
    const keyValue = setAndReturnConfig(
        createExtArgs,
        tpaDataId,
        appTpaData,
        MASTER_PAGE_ID,
        config,
        limit,
        appDefinitionId,
        langCode
    )
    if (!keyValue) {
        return {success: false, reason: `Your app has exceeded the provided ${limit} bytes storage space`}
    }
    return {success: true, keyValue}
}

export const setAppValue = (
    createExtArg: CreateExtArgs,
    appDefinitionId: AppDefinitionId,
    key: string,
    value: any,
    langCode?: string
) => {
    const appTpaData = getTpaAppData(createExtArg, appDefinitionId)
    const res = setAppValueByAppDefId(createExtArg, appTpaData, appDefinitionId, {[key]: value}, langCode)
    if (res.success) {
        return res.keyValue
    }
}

export const setComponentValue = (
    createExtArgs: CreateExtArgs,
    componentTpaData: Record<string, any> | undefined,
    compPointer: Pointer,
    config: Record<string, any>,
    langCode?: string
) => {
    const {dal, pointers, extensionAPI} = createExtArgs
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const {tpa} = extensionAPI as TPAExtensionAPI
    const componentType = getComponentType(dal, compPointer)
    if (!tpa.isTpaByCompType(componentType)) {
        //technically appController works for appScope, so this check is only for component scope
        return {success: false, reason: ONLY_TPA_COMPS_SUPPORTED}
    }

    const pageId = pointers.structure.getPageOfComponent(compPointer).id
    const compDataDeserialized = dataModel.components.getItem(compPointer, DATA_TYPES.data)
    const tpaDataId = componentTpaData ? componentTpaData.id : guidUtils.getUniqueId(PREFIX_TPA_DATA_ID, undefined)
    const limit = getContentLimit(createExtArgs, compDataDeserialized.appDefinitionId, SCOPE.COMPONENT)

    if (!componentTpaData) {
        componentTpaData = {
            type: 'TPAData',
            id: tpaDataId,
            content: {}
        }
    }
    const appDefinitionId = _.get(compDataDeserialized, ['appDefinitionId'])

    const keyValue = setAndReturnConfig(
        createExtArgs,
        tpaDataId,
        componentTpaData,
        pageId,
        config,
        limit,
        appDefinitionId!,
        langCode
    )

    if (!keyValue) {
        return {success: false, reason: `Your app has exceeded the provided ${limit} chars storage space`}
    }

    compDataDeserialized.tpaData = `#${tpaDataId}`
    dataModel.components.addItem(compPointer, DATA_TYPES.data, compDataDeserialized, langCode)
    return {success: true, compDataDeserialized, keyValue}
}
