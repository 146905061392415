import type {Pointer, PS} from '@wix/document-services-types'

export function getMetaSiteId(ps: PS): string {
    const pointer = ps.pointers.general.getMetaSiteId()
    return ps.dal.get(pointer)
}

export function getIfExists(ps: PS, pointer: Pointer, defaultValue: any): any {
    return ps.dal.isExist(pointer) ? ps.dal.get(pointer) : defaultValue
}

export function removeIfExists(ps: PS, pointer: Pointer): void {
    if (ps.dal.isExist(pointer)) {
        ps.dal.remove(pointer)
    }
}
