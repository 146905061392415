import dataModel from '../dataModel/dataModel'
import _ from 'lodash'
import {constants} from '@wix/santa-core-utils'
import type {PS, Pointer} from '@wix/document-services-types'

const {data: DATA} = constants.DATA_TYPES

const DATA_NODE_BASIC_PROPS = ['id', 'type', 'metaData']
const getA11ySchema = (ps: PS, compPointer: Pointer): null | any => {
    const dataItem = dataModel.getDataItem(ps, compPointer)
    const dataType = dataItem ? dataItem.type : dataModel.getFirstValidDataType(ps, compPointer)
    const refs = ps.extensionAPI.schemaAPI.extractReferenceFieldsInfo(DATA, dataType)
    const a11yReference = _.find(refs, {jsonPointer: '/a11y'})
    if (a11yReference) {
        const a11Type = a11yReference.refTypes[0]
        const a11TypeSchema = ps.extensionAPI.schemaAPI.getSchema(DATA, a11Type)
        return a11TypeSchema
            ? {
                  properties: _.omit(a11TypeSchema.properties, DATA_NODE_BASIC_PROPS),
                  type: a11Type
              }
            : null
    }
    return null
}

export default {
    getA11ySchema
}
