import type {PS, Pointer} from '@wix/document-services-types'
import component from '../../component/component'

export interface TabsProperties {
    itemsOrientation: 'vertical' | 'horizontal'
}

const handleTabsPropertiesChange = (ps: PS, compPointer: Pointer, updatedProperties: TabsProperties) => {
    const currentProperties = component.properties.get(ps, compPointer) as TabsProperties

    if (currentProperties.itemsOrientation === 'vertical' && updatedProperties.itemsOrientation === 'horizontal') {
        const [singleTabRef] = component.getChildren(ps, compPointer)
        const {width} = component.layout.get(ps, singleTabRef)
        component.layout.update(ps, compPointer, {width})
    }
}

export default {
    handleTabsPropertiesChange
}
