import type {AppData, BIEvent, PS} from '@wix/document-services-types'
import {guidUtils} from '@wix/santa-core-utils'
import _ from 'lodash'
import tpaErrors from '../bi/errors'
import appMarketService from '../services/appMarketService'

const cacheAppMarketDataAfterProvision = function (ps: PS, appData: AppData) {
    // eslint-disable-next-line promise/prefer-await-to-then
    appMarketService.getAppMarketDataAsync(ps, appData.appDefinitionId).catch(() => {
        ps.siteAPI.reportBI(tpaErrors.FAIL_TO_GET_APP_MARKET_DATA as BIEvent)
    })
}

const generateAppFlowsLargestAppId = function (currentLargest: number) {
    // eslint-disable-next-line no-mixed-operators
    const randomNumber = (guidUtils.random() * 999 + 1 + guidUtils.random() * 99 + 1) | 0
    return _.max([currentLargest, 999]) + randomNumber
}

export default {
    cacheAppMarketDataAfterProvision,
    generateAppFlowsLargestAppId
}
