import type {PossibleViewModes, PS} from '@wix/document-services-types'
import _ from 'lodash'
import mobileUtils from '../mobileUtilities/mobileUtilities'
import mobileFacadeValidationWrapper from './mobileFacadeValidationWrapper'
import mobileConversion from './modules/mobileConversion'
import mobilePresetsHandler from './modules/mobilePresets/mobilePresetsHandler'
import * as mobileConversionUtils from './modules/utils'

const {isMobileSupported} = mobileUtils

const mobileConversionFacade = {
    runMobileConversionOnPage(ps: PS, page) {
        if (!isMobileSupported(ps)) {
            return
        }

        mobileConversion.mobileConversion({ps}).runMobileConversionOnPage(page)
    },
    runMobileConversionOnAllPages(ps: PS, options) {
        if (!isMobileSupported(ps)) {
            return
        }
        const heuristicStrategy = _.get(options, 'heuristicStrategy')
        return heuristicStrategy === 'minimal'
            ? mobileConversion.mobileConversion({ps}).syncMobileSite()
            : mobileConversion
                  .mobileConversion({
                      ps,
                      heuristicStrategy
                  })
                  .runMobileConversionOnAllPages()
    },
    runMobileMergeOnPage(ps: PS, desktopPage, mobilePage, settings) {
        if (!isMobileSupported(ps)) {
            return
        }
        mobileConversion.mobileConversion({ps}).runMobileMergeOnPage(desktopPage, mobilePage, settings)
    },
    addMobileOnlyComponent(ps: PS, compId: string, postConversionParameters) {
        if (!isMobileSupported(ps)) {
            return
        }
        mobileConversion.mobileConversion({ps}).addMobileOnlyComponent(compId, postConversionParameters)
    },
    syncMobileSite(ps: PS, options) {
        if (!isMobileSupported(ps)) {
            return
        }
        mobileConversion.mobileConversion({ps}).syncMobileSite(options)
    },
    runPartialConversionAllPages(ps: PS, commitConversionResults = true) {
        if (!isMobileSupported(ps)) {
            return
        }
        if (ps.config.disableMobileConversion) {
            mobileConversion.mobileConversion({ps}).syncMobileSite()
            return
        }
        mobileConversion.mobileConversion({ps}).runMobileMergeOnAllPages(commitConversionResults)
    },
    /**
     * Method to run mobile merge algorithm only for the current page
     * should be used for getting the result for mobile page programmatically
     * without saving it, only with committing merge result to dal
     * @param {ps} ps
     * @param {string} pageId
     * @param {object|undefined} settings
     * @returns
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    runMobileMergeOnPageById(ps: PS, pageId: string, settings) {
        if (!mobileConversionUtils.shouldRunMerge(ps)) {
            return
        }
        mobileConversion.mobileConversion({ps}).runMobileMergeOnPageById(pageId)
    },
    getComponentsOrder(ps: PS, pageId: string, viewMode: PossibleViewModes) {
        if (!isMobileSupported(ps)) {
            return
        }
        return mobileConversion.mobileConversion({ps}).getComponentsOrder(pageId, viewMode)
    },
    runMobileMergeOnAllPagesWithoutLayoutOverwrite(ps: PS) {
        if (!mobileConversionUtils.shouldRunMerge(ps)) {
            return
        }
        mobileConversion.mobileConversion({ps}).runMobileMergeOnAllPagesWithoutLayoutOverwrite()
    },
    shouldCreateMobilePreset: mobilePresetsHandler.shouldCreateMobilePreset,
    createMobilePreset: mobilePresetsHandler.createMobilePreset,
    createMobileHintsForPageComponents: mobilePresetsHandler.createMobileHintsForPageComponents
}

export default mobileFacadeValidationWrapper.wrap(mobileConversionFacade, [
    'shouldCreateMobilePreset',
    'getComponentsOrder'
])
