'use strict'

const {createHelperAndMigratePage} = require('../helpers/CompsMigrationHelper')

function getEmptyBackground(uniqueIdGenerator) {
    return {
        type: 'MediaContainerDesignData',
        id: uniqueIdGenerator.getUniqueId('dataItem', '-'),
        background: {
            id: uniqueIdGenerator.getUniqueId('dataItem', '-'),
            type: 'BackgroundMedia',
            mediaRef: null,
            alignType: 'center',
            fittingType: 'fill',
            colorOpacity: 0,
            colorOverlay: '',
            colorOverlayOpacity: 1,
            scrollType: 'none',
            color: '#FFFFFF'
        }
    }
}

function handleNoDesignDataInColumns(pageJson, cache, mobileView, comp, uniqueIdGenerator) {
    if (!comp.designQuery && !mobileView) {
        comp.layout.width = 980
        const designDataWithEmptyBackground = getEmptyBackground(uniqueIdGenerator)
        this.setComponentDesignData(pageJson, comp, designDataWithEmptyBackground, cache)
    }
}

const MIGRATION_MAP = {
    'wysiwyg.viewer.components.StripColumnsContainer': handleNoDesignDataInColumns,
    'wysiwyg.viewer.components.Column': handleNoDesignDataInColumns
}

module.exports = {
    name: 'stripColumnsContainerNoDesignFixer',
    version: 2,
    exec: createHelperAndMigratePage(MIGRATION_MAP)
}
