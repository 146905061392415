import type {SnapshotDal} from '@wix/document-manager-core'
import type {PS} from '@wix/document-services-types'
import experiment from 'experiment-amd'
import {doesHaveBranches} from '../../branches/siteBranchesUtils'
import constants from '../../platform/common/constants'

const shouldAvoidRevoking = async (
    {ps, snapshotDal}: {ps?: PS; snapshotDal?: SnapshotDal},
    {intent = null} = {}
): Promise<boolean> => {
    if (experiment.isOpen('dm_avoidRevokingApps')) {
        return true
    }
    if (intent !== constants.Intents.USER_ACTION) {
        const hasBranches = await doesHaveBranches({ps, snapshotDal})
        return hasBranches
    }
    return false
}

export default {
    shouldAvoidRevoking
}
