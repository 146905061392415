import type {ComponentExport, RawComponentExport, WMLFiles} from '@wix/document-services-types'
import {ReportableError} from '@wix/document-manager-utils'
import {importFromUrl} from '@wix/document-manager-dynamic-import'
import {parse} from 'json5'
import * as wmlConverterModule from '@wix/document-management-wml-converter'
import * as jsonConverterModule from '@wix/document-management-json-converter'
import {getVersion as getWmlVersion, type WmlConverterAPI} from '@wix/document-management-wml-converter'
import {getVersion as getJsonVersion, type JsonConverterAPI} from '@wix/document-management-json-converter'

type ConverterType = 'wml' | 'json'
type ConverterAPI<T extends ConverterType> = T extends 'json' ? JsonConverterAPI : WmlConverterAPI

const staticConverters: {
    [K in ConverterType]: {version: string; getPackageUrl(version: string): string; converterModule: ConverterAPI<K>}
} = {
    json: {
        version: getJsonVersion(),
        getPackageUrl: (version: string) =>
            `https://static.parastorage.com/unpkg/@wix/document-management-json-converter@${version}`,
        converterModule: jsonConverterModule
    },
    wml: {
        version: getWmlVersion(),
        getPackageUrl: (version: string) =>
            `https://static.parastorage.com/unpkg/@wix/document-management-wml-converter@${version}`,
        converterModule: wmlConverterModule
    }
}

const getDmConverterSourceQuery = (): string | undefined => {
    try {
        return new URLSearchParams(window.location.search).get('DmConverterSource') ?? undefined
    } catch (e) {
        /* not browser */
    }
}

const loadConverterByUrl = async <T extends ConverterType>(url: string): Promise<ConverterAPI<T>> => {
    try {
        return await importFromUrl<ConverterAPI<T>>(url)
    } catch (error: any) {
        throw new ReportableError({
            errorType: 'cannotGetConverterPackage',
            message: `could not get document management converter package from url`,
            extras: {url, error: error?.message ?? error?.code}
        })
    }
}

const getConverterModule = async <T extends ConverterType>(
    converterType: T,
    version?: string
): Promise<ConverterAPI<T>> => {
    if (!version) {
        throw new ReportableError({
            errorType: 'missingImportExportVersion',
            message: 'ImportExport version is missing'
        })
    }

    const converterSourceQuery = getDmConverterSourceQuery()

    if (converterSourceQuery && !converterSourceQuery.startsWith('http://localhost:')) {
        throw new ReportableError({
            errorType: 'INVALID_DM_CONVERTER_SOURCE',
            message: `DM converter source must be a localhost url`,
            extras: {converterSourceQuery}
        })
    }

    const staticConverter = staticConverters[converterType]
    if (!converterSourceQuery && version === staticConverter.version) {
        return staticConverter.converterModule
    }

    const url = converterSourceQuery ?? staticConverter.getPackageUrl(version)
    return loadConverterByUrl<T>(url)
}

export const convertWmlToRawComponentExport = async (componentExport: WMLFiles): Promise<RawComponentExport> => {
    const {version} = parse(componentExport['data.json5'])
    const {wml} = await getConverterModule('wml', version)
    return wml.unpack(componentExport)
}

export const convertJsonToRawComponentExport = async (
    componentExport: ComponentExport
): Promise<RawComponentExport> => {
    const {version} = componentExport
    const {json} = await getConverterModule('json', version)
    return json.unpack(componentExport)
}
