'use strict'
const _ = require('lodash')
const {CompsMigrationHelper} = require('../helpers/CompsMigrationHelper')

const INPUTS_WITH_PLACEHOLDERS = [
    'wysiwyg.viewer.components.inputs.TextInput',
    'wixapps.integration.components.inputs.TextArea',
    'wysiwyg.viewer.components.inputs.TextAreaInput',
    'wysiwyg.viewer.components.inputs.ComboBoxInput',
    'wysiwyg.viewer.components.inputs.DatePicker'
]

const exec = (pageJson, pageIdsArray, magicObject) => {
    function fixInputPlaceholder(innerPageJson, cache, mobileView, comp) {
        const placeholder = _.get(this.getComponentProperties(innerPageJson, comp), 'placeholder')
        const compData = this.getComponentData(innerPageJson, comp)

        if (!compData) {
            return
        }

        const newCompData = _.merge({placeholder}, compData)
        this.setComponentData(innerPageJson, comp, newCompData, cache)
    }

    const migrationMap = _.mapValues(_.keyBy(INPUTS_WITH_PLACEHOLDERS), () => fixInputPlaceholder)

    const {uniqueIdGenerator} = magicObject.dataFixerUtils
    const migrationHelper = new CompsMigrationHelper(migrationMap, uniqueIdGenerator)
    migrationHelper.migratePage(pageJson)
}

/**
 * @exports utils/dataFixer/plugins/inputsPlaceholderDataFixer
 * @type {{exec: function}}
 */
module.exports = {
    name: 'inputsPlaceholderDataFixer',
    version: 1,
    exec,
    fixerRequiresReruns: true
}
