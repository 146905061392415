import type {Extension, ExtensionAPI, CreateExtensionArgument} from '@wix/document-manager-core'
import _ from 'lodash'

export interface EnvironmentExtensionAPI extends ExtensionAPI {
    environment: {
        isDebugMode(): boolean
    }
    siteAPI: {
        isDebugMode(): boolean
        isExperimentOpen(experimentName: string): boolean
    }
}

/**
 * Creates the environment extension it will define some utility or contextual data
 */
const createExtension = ({dsConfig, experimentInstance}: CreateExtensionArgument): Extension => {
    const isDebugMode = _.constant(dsConfig.isDebugMode ?? false)
    const createExtensionAPI = (): EnvironmentExtensionAPI => ({
        environment: {
            isDebugMode
        },
        siteAPI: {
            isDebugMode,
            isExperimentOpen: experimentInstance.isOpen
        }
    })

    return {
        name: 'environment',
        createExtensionAPI
    }
}

export {createExtension}
