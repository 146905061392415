import type {StyleRef} from '@wix/document-services-types'
import experiment from 'experiment-amd'

const getComponentStylableName = (compType: string) => compType.split('.').pop()

const STYLABLE_SKIN_NAME = 'wixui.skins.Skinless'
const createEmptyStylableStylesheet = (compType: string) => {
    const compName = getComponentStylableName(compType)
    return `:import {
    -st-from: 'wix-ui-santa/index.st.css';
    -st-named: ${compName};
}
.root{
    -st-extends: ${compName};
}`
}

const getStylableSkinName = () => STYLABLE_SKIN_NAME

const createEmptyStylableStyleItem = (componentType: string): Partial<StyleRef> => ({
    skin: getStylableSkinName(),
    style: {
        properties: {
            '$st-css': createEmptyStylableStylesheet(componentType)
        }
    } as any
})

const shouldApplySkinTheme = (compType: string, definition: {isStylableComp?: boolean}) =>
    !definition?.isStylableComp ||
    (compType === 'wixui.StylableButton' && experiment.isOpen('dm_migrateStylableButtonToNonStylable')) ||
    (compType === 'wixui.CollapsibleText' && experiment.isOpen('dm_migrateCollapsibleTextToNonStylable'))

export default {
    createEmptyStylableStyleItem,
    createEmptyStylableStylesheet,
    getComponentStylableName,
    getStylableSkinName,
    shouldApplySkinTheme
}
