import type {PublicMethodUtils} from '@wix/document-services-types'
import {saveAPI} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    const {save, publish, saveAsTemplate, canUserPublish, initMethod, getLastSaveInfo, registerToDeploymentStatus} =
        saveAPI
    return {
        initMethod,
        methods: {
            /**
             * Saves the site
             * @member documentServices
             * @param {onSuccess} [onSuccess]
             * @param {onError} [onError]
             * @param {boolean} isFullSave - whether to perform a full save or not
             * @param {object} options
             * @return {Promise} a promise that the save will be performed. This is for syntactic sugar for success/error.
             */
            save: publicMethodUtils.defineDataManipulationAction(save),
            /**
             * Publishes the last saved version of the site. If you want to save the site first, you should call save and then publish.
             * @member documentServices
             * @param {onSuccess} [onSuccess]
             * @param {onError} [onError]
             * @param {boolean} isFullSave - whether to perform a full save or not
             * @return {Promise} a promise that the save will be performed. This is for syntactic sugar for success/error.
             */
            publish: publicMethodUtils.defineDataManipulationAction(publish),

            saveAsTemplate: publicMethodUtils.defineDataManipulationAction(saveAsTemplate),

            /**
             * Returns true if the user is allowed to publish according to server, false otherwise
             * @instance
             * @param {ps} privateServices
             * @returns {boolean}
             */
            canUserPublish: publicMethodUtils.defineGetter(canUserPublish),

            getLastSaveInfo: publicMethodUtils.defineGetter(getLastSaveInfo),

            registerToDeploymentStatus: publicMethodUtils.defineDataManipulationAction(registerToDeploymentStatus)
        }
    }
}
