import _ from 'lodash'
import type {CreateExtArgs} from '@wix/document-manager-core'
import type {CompRef, Pointer} from '@wix/document-services-types'
import {getComponentType} from '../../../utils/dalUtils'

const allowedMediaPlayerChildrenTypeList = [
    'wysiwyg.viewer.components.MediaControls',
    'wysiwyg.viewer.components.MediaOverlayControls'
]

export const mediaPlayerMetadata = {
    isRepeatable: ({coreConfig}: CreateExtArgs) => coreConfig.experimentInstance.isOpen('dm_mediaPlayerInRepeater'),
    enforceContainerChildLimitsByWidth: false,
    enforceContainerChildLimitsByHeight: false,
    canContain: ({dal}: CreateExtArgs, compPointer: Pointer, potentialChildPointer: CompRef) => {
        const compType = getComponentType(dal, potentialChildPointer)
        return _.includes(allowedMediaPlayerChildrenTypeList, compType)
    }
}
