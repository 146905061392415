import type {CompRef, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import baseInputMetadata from './baseInputMetadata'
import {getMeasureMapValue} from '../../utils/utils'

const datePickerMetadata = {
    layoutLimits(ps: PS, compPointer: Pointer) {
        return {
            minWidth: getMeasureMapValue(
                ps,
                compPointer as CompRef,
                'minWidth',
                santaCoreUtils.siteConstants.COMP_SIZE.MIN_WIDTH
            ),
            minHeight: getMeasureMapValue(
                ps,
                compPointer as CompRef,
                'minHeight',
                santaCoreUtils.siteConstants.COMP_SIZE.MIN_HEIGHT
            )
        }
    }
}

export default _.merge(datePickerMetadata, baseInputMetadata)
