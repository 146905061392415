import {componentStylesAndSkinsAPI} from '@wix/document-services-implementation'
import type {PublicMethodUtils} from '@wix/document-services-types'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            components: {
                style: {
                    getId: publicMethodUtils.defineGetter(componentStylesAndSkinsAPI.style.getId),
                    get: publicMethodUtils.defineGetter(componentStylesAndSkinsAPI.style.get),
                    update: publicMethodUtils.defineDataManipulationAction(componentStylesAndSkinsAPI.style.update),
                    connectToTheme: publicMethodUtils.defineDataManipulationAction(
                        componentStylesAndSkinsAPI.style.connectToThemeStyle
                    ),
                    scoped: {
                        remove: publicMethodUtils.defineDataManipulationAction(
                            componentStylesAndSkinsAPI.style.removeScoped
                        )
                    },
                    fork: publicMethodUtils.defineDataManipulationAction(componentStylesAndSkinsAPI.style.fork)
                }
            }
        }
    }
}
