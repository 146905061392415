import type {PS, PublicMethodUtils} from '@wix/document-services-types'
import {connections} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            /** @class documentServices.platform*/
            platform: {
                /** @class documentServices.platform.controllers*/
                controllers: {
                    /** @class documentServices.platform.controllers.connections*/
                    connections: {
                        /**
                         * Connects the passed component to the passed controller with the passed role
                         * @member documentServices.platform.controllers.connections
                         * @param {AbstractComponent} fromRef - the component to connect to controllerRef
                         * @param {AbstractComponent} controllerRef - the controller to connect the component to
                         * @param {string} role - the role this component is connected to the controller with
                         */
                        connect: publicMethodUtils.defineAction(connections.connect),
                        /**
                         * Disconnects the component role from the passed controller
                         * @member documentServices.platform.controllers.connections
                         * @param {AbstractComponent} fromRef - the component to disconnect from controllerRef and role
                         * @param {AbstractComponent} controllerRef - the controller to disconnect the component from
                         * @param {string} role - the role to disconnect the component from
                         */
                        disconnect: publicMethodUtils.defineAction(connections.disconnect),
                        /**
                         * Returns the component connection array
                         * @member documentServices.platform.controllers.connections
                         * @param {AbstractComponent} compRef - the component whose connections are requested
                         * @returns {ConnectionItem[]} - an array of all component connections
                         */
                        get: publicMethodUtils.defineGetter(connections.getPlatformAppConnections),

                        /**
                         * Returns components array of all the connected components of the given controller
                         * @member documentServices.platform.controllers.connections
                         * @param {AbstractComponent} controllerRef - the controller whose connected components are requested
                         * @param {boolean} isRecursive - if true the function will recursively return an array of all connected components, meaning an inner widget's connected components will be included.
                         * @returns {AbstractComponent[]} - an array of all connected component
                         */
                        getConnectedComponents: publicMethodUtils.defineGetter(connections.getConnectedComponents),

                        /**
                         * Returns an array of all the connections of the given controller
                         * @member documentServices.platform.controllers.connections
                         * @param {AbstractComponent} controllerRef - the controller whose connected components are requested
                         * @returns {AbstractComponent[]} - an array of all connection with the connected component
                         */
                        getControllerConnections: publicMethodUtils.defineGetter(connections.getControllerConnections),

                        /**
                         * Returns the primary connection of a component if it exists
                         * @member documentServices.platform.controllers.connections
                         * @param {AbstractComponent} componentRef - the component whose primary connection is requested
                         * @returns {ConnectionItem|undefined} - the primary connection, or undefined if there is none
                         */
                        getPrimaryConnection: publicMethodUtils.defineGetter(connections.getPrimaryConnection)
                    },
                    /**
                     * Returns whether the component acts as a controller (AppWidget/AppController)
                     * @member documentServices.platform.controllers.isControllerType
                     * @param {string} componentType - component type to validate
                     * @returns {boolean} - a boolean indicates if the component is a controller
                     */
                    isControllerType: publicMethodUtils.defineGetter(
                        (_ps: PS, ...args: Parameters<typeof connections.isControllerType>) =>
                            connections.isControllerType(...args)
                    ),

                    /**
                     * Returns whether the component acts as an OOI controller
                     * @member documentServices.platform.controllers.isOOIController
                     * @param {string} componentType - component type to validate
                     * @returns {boolean} - a boolean indicates if the component is an OOI controller
                     */
                    isOOIController: publicMethodUtils.defineGetter(
                        (ps: PS, ...args: Parameters<typeof connections.isOOIController>) =>
                            connections.isOOIController(...args)
                    )
                }
            }
        }
    }
}
