'use strict'

const _ = require('lodash')

function getQuickActionBar(masterPage) {
    return _.find(masterPage.structure.mobileComponents, {id: 'QUICK_ACTION_BAR'})
}

function addQuickActionBarStructure(masterPage) {
    masterPage.structure.mobileComponents.push({
        type: 'Container',
        id: 'QUICK_ACTION_BAR',
        componentType: 'wysiwyg.viewer.components.QuickActionBar',
        components: [],
        styleId: 'quickActionBarStyle',
        layout: {
            fixedPosition: true
        }
    })
}

function addQuickActionBarStyle(masterPage) {
    getQuickActionBar(masterPage).styleId = 'quickActionBarStyle'
    _.assign(masterPage.data.theme_data, {
        quickActionBarStyle: {
            type: 'TopLevelStyle',
            id: 'quickActionBarStyle',
            metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false
            },
            skin: 'wysiwyg.viewer.skins.quickActionBar.floatingSkin',
            style: {
                properties: {
                    'alpha-controlButtonBG': '1',
                    'alpha-controlButtonFG': '1',
                    'alpha-controlButtonBorderColor': '1',
                    'alpha-actionsBorderColor': '1',
                    'boxShadowToggleOn-actionsSHD': 'true',
                    'boxShadowToggleOn-controlButtonSHD': 'true',
                    controlButtonBG: '#ffffff',
                    controlButtonFG: '#4682b4',
                    controlButtonBorderSize: '0',
                    controlButtonBorderColor: '#ffffff',
                    actionsBorderSize: '0',
                    actionsBorderColor: '#ffffff',
                    colorScheme: 'brand inv',
                    controlButtonSHD: '0px 1px 4px 0px rgba(0,0,0,0.6)',
                    actionsSHD: '0px 1px 4px 0px rgba(0,0,0,0.6)'
                },
                propertiesSource: {
                    controlButtonBG: 'value',
                    controlButtonFG: 'value',
                    controlButtonBorderSize: 'value',
                    controlButtonBorderColor: 'value',
                    actionsBorderSize: 'value',
                    actionsBorderColor: 'value',
                    colorScheme: 'value',
                    controlButtonSHD: 'value',
                    actionsSHD: 'value'
                },
                groups: {}
            },
            componentClassName: 'wysiwyg.viewer.components.QuickActionBar',
            pageId: '',
            compId: '',
            styleType: 'custom'
        }
    })
}

function addQuickActionBarProperties(masterPage) {
    getQuickActionBar(masterPage).propertyQuery = 'quickActionBarProps'
    _.assign(masterPage.data.component_properties, {
        quickActionBarProps: {
            type: 'QuickActionBarProperties',
            metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false
            },
            colorScheme: 'brand',
            invertColors: true,
            alignment: 'right',
            hideText: true
        }
    })
}

function hasQuickActionBar(masterPage) {
    return !!getQuickActionBar(masterPage)
}

function hasTPAWithQuickActions(clientSpecMap) {
    const chatAppDefId = '14517e1a-3ff0-af98-408e-2bd6953c36a2'
    const chatApp = _.find(clientSpecMap, {appDefinitionId: chatAppDefId})
    const isChatAppInstalled = !_.isUndefined(chatApp) && !_.get(chatApp, 'permissions.revoked')
    return isChatAppInstalled
}

function fixQuickActionBarStructure(masterPage) {
    const quickActionBarStructure = _.find(masterPage.structure.mobileComponents, {id: 'QUICK_ACTION_BAR'})
    if (_.isUndefined(quickActionBarStructure.components)) {
        quickActionBarStructure.components = []
    }
    quickActionBarStructure.type = 'Container'
    if (_.isUndefined(masterPage.data.component_properties[quickActionBarStructure.propertyQuery])) {
        addQuickActionBarProperties(masterPage)
    }
}

function fixQuickActionBarStyleProperties(masterPage) {
    const quickActionBarStructure = _.find(masterPage.structure.mobileComponents, {id: 'QUICK_ACTION_BAR'})
    const styleProperties = _.get(masterPage.data.theme_data, [quickActionBarStructure.styleId, 'style', 'properties'])
    _.transform(
        ['controlButtonBorderColor', 'actionsBorderColor'],
        (styleProps, prop) => {
            if (/^rgb/.test(styleProps[prop])) {
                styleProps[prop] = '#ffffff'
            }
        },
        styleProperties
    )
}

function fixQuickActionBarItemsData(masterPage) {
    _(masterPage.data.document_data)
        .filter(dataNode => dataNode.type === 'QuickActionBarItem' && _.isString(dataNode.text))
        .forEach(dataNode => {
            dataNode.text = dataNode.text.slice(0, 40)
        })
}

/**
 * @exports utils/dataFixer/plugins/quickActionBarDataFixer
 * @type {{exec: function}}
 */
module.exports = {
    name: 'quickActionBarDataFixer',
    version: 1,
    exec(pageJson, pageIdsArray, magicObject) {
        if (pageJson.structure.id !== 'masterPage') {
            return
        }

        fixQuickActionBarItemsData(pageJson)

        if (hasQuickActionBar(pageJson)) {
            fixQuickActionBarStructure(pageJson)
            fixQuickActionBarStyleProperties(pageJson)
            return
        }

        const shouldAddQuickActionBar =
            hasTPAWithQuickActions(magicObject.clientSpecMap) &&
            !hasQuickActionBar(pageJson) &&
            !magicObject.quickActionsMenuEnabled

        if (shouldAddQuickActionBar) {
            addQuickActionBarStructure(pageJson)
            addQuickActionBarStyle(pageJson)
            addQuickActionBarProperties(pageJson)
        }
    },
    fixerRequiresReruns: true
}
