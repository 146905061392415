import type {BaseDsData, ValueOf} from './basicTypes'
import type {
    BreakpointData,
    BreakpointItem,
    ResolvedStyleBreakpointRelation,
    ResolvedStyleRefArray
} from './breakpointTypes'
import type {MeshData} from './componentsObject'
import type {CssStyle} from './cssTypes'
import type {Point} from './types'
import type {ResolvedStyleVariantRelation, VariantDataItem, VariantsDataMap} from './variantTypes'
import type {AspectRatio, HorizontalAlignment, LayoutData, LegacyDirection, SingleLayoutItem} from './layoutData'
import type {TransformationDataItem, TransformationsDataMap} from './transformationTypes'
import type {TransitionDataItem, TransitionsDataMap} from './transitionTypes'
import type {MetaData} from './structure'
import type {Link} from './link'
import type {ColorLayerData} from './gradients'

export interface BaseInternalDataItem<Type extends string = string> {
    type: Type
}

export interface BaseDataItem<Type extends string = string> extends BaseInternalDataItem<Type> {
    id: string
}

export interface RuntimeDataItemOverride extends BaseDataItem {
    [fieldName: string]: any
}

export type DataMap<T = BaseDataItem> = Record<string, T>

export interface BaseInput {
    name?: string
    formGroup?: string
}
export interface BaseTextInput extends BaseInput {
    value?: string
}

export interface DisableableProperties extends DefaultProperties {
    isDisabled?: boolean
}
export interface InputProperties extends DisableableProperties {
    label?: string
    required?: boolean
    autoFocus?: boolean
    readOnly?: boolean
    tabIndex?: number
    inputHeight?: number | null
    inputHeightMobile?: number | null
    labelMargin?: number
    labelPadding?: number
    labelMobileFontSize?: number
    inputMobileFontSize?: number
}

export interface ObsoleteBehaviorsList extends BaseDataItem<'ObsoleteBehaviorsList'> {
    items: string
}

export interface SPXReferenceDefinition {
    refWidth: number
    resolverType: 'scale' | 'fixed'
}

export interface OverridableTheme {
    spx: SPXReferenceDefinition
}

export type FontDisplayType = 'auto' | 'block' | 'swap' | 'fallback' | 'optional'
export interface ThemeData extends BaseDataItem<'WFlatTheme'> {
    id: 'THEME_DATA'
    font: string[]
    fontColor?: string[]
    color: string[]
    theme?: OverridableTheme
    fontDisplay?: FontDisplayType
}

export interface Style {
    properties: Record<string, any>
    propertiesSource: Record<string, any>
    propertiesOverride?: Record<string, any>
    groups?: any
}

export interface StyleRef extends BaseDsData {
    type: 'ComponentStyle' | 'TopLevelStyle'
    style: Style
    componentClassName: string
    pageId?: string
    compId?: string
    styleType: string
    skin: string
}

export const ConnectionTypes = {
    WixCodeConnectionItem: 'WixCodeConnectionItem',
    ConnectionItem: 'ConnectionItem'
} as const

export type ConnectionType = ValueOf<typeof ConnectionTypes>

export interface IConnectionItem extends BaseDsData {
    type: ConnectionType
    role: string
}

export type ConnectionItem = ControllerConnectionItem | WixCodeConnectionItem

export interface ControllerConnectionItem extends IConnectionItem {
    type: 'ConnectionItem'
    controllerId: string
    config?: string
    subRole?: string
    isPrimary: boolean
}

export interface WixCodeConnectionItem extends IConnectionItem {
    type: 'WixCodeConnectionItem'
}

export interface ConnectionList extends BaseDataItem<'ConnectionList'> {
    items: IConnectionItem[]
    metaData?: MetaData
}

export interface AnchorDataItem extends BaseDataItem<'Anchor'> {
    type: 'Anchor'
    name: string
    compId: string
}

export interface TPAWidgetItem extends BaseDataItem<'TPAWidget'> {
    compId: string
    applicationId: string
    appDefinitionId: string
    widgetId: string
    referenceId: string
}

export interface BackgroundMediaDesignData extends BaseDataItem<'BackgroundMedia'> {
    color: string
    colorOpacity: number
    alignType: string
    fittingType: string
    scrollType: string
    colorOverlay: string
    colorOverlayOpacity: number
    mediaRef?: string
    colorLayers?: ColorLayerData[]
}

export type MediaContainerDesignData = BaseDsData &
    BaseDataItem<'MediaContainerDesignData'> & {
        background: BackgroundMediaDesignData
        charas?: string
        cssStyle?: Record<string, any>
    }

export type BackgroundMediaDataWithVideoItem = BackgroundMediaDesignData & {
    mediaRef: string
    imageOverlay: object
}

export interface MediaPlayerDesignDataItem extends BaseDataItem<'MediaPlayerDesignData'> {
    background: BackgroundMediaDataWithVideoItem
    charas?: string
    cssStyle: CssStyle
    themeMappings?: Record<string, string>
    posterImageRef: Image
    mediaRef: WixVideo
    imageOverlay: Image
}

export interface Image {
    link?: string
    title?: string
    name?: string
    uri?: string
    description?: string
    height?: number
    width?: number
    borderSize?: string
    alt?: string
    /**
     * Original Image before edit
     */
    originalImageDataRef?: string | null
    opacity?: number
    crop?: null | {
        x?: number
        y?: number
        width?: number
        height?: number
        /**
         * Defines an svg uri for a mask inside the crop boundaries
         */
        svgId?: string
        /**
         * Defines whether the mask is flipped on the v or h axis
         */
        flip?: 'x' | 'y' | 'xy' | 'none'
        /**
         * Defines a rotation angle for the mask
         */
        rotate?: number
        [k: string]: unknown
    }
    /**
     * used for upscaling method when building an image url, expected values default|super
     */
    upscaleMethod?: string
    focalPoint?: null | {
        x?: number
        y?: number
        [k: string]: unknown
    }
    artist?: {
        name?: string
        id?: string
        [k: string]: unknown
    }
    [k: string]: unknown
}

export interface WixVideo {
    title?: string
    alt?: string
    videoId?: string
    generatedPosters?: string[]
    qualities?: {
        quality?: string
        width?: number
        height?: number
        /**
         * Deprecated, replaced by 'format' and 'url' since we have a unique url for each quality
         */
        formats?: string[]
        format?: 'mp4' | 'webm'
        url?: string
        [k: string]: unknown
    }[]
    adaptiveVideo?:
        | {
              format?: 'hls' | 'dash'
              url?: string
              [k: string]: unknown
          }[]
        | null
    posterImageRef?: string | null
    duration?: number
    loop?: boolean
    autoplay?: boolean
    playbackSpeed?: number
    fps?: string
    preload?: 'auto' | 'metadata' | 'none'
    controls?: 'none' | 'full'
    /**
     * deprecated, moved to properties
     */
    mute?: boolean
    /**
     * video has no audio
     */
    hasAudio?: boolean
    opacity?: number
    artist?: {
        name?: string
        id?: string
        [k: string]: unknown
    }
    /**
     * Annotate which features does this video have, for example 'media is a video with alpha'
     */
    mediaFeatures?: null | ('alpha' | '360')[]
    [k: string]: unknown
}

export interface Artist {
    name: string
    id: string
}

interface Created {
    quality: string
    width: number
    height: number
    format: string
}

export interface WixVideoDataItem extends BaseDataItem<'WixVideo'> {
    title: string
    videoId: string
    qualities: [Created]
    posterImageRef: string
    opacity: number
    duration: number
    loop: boolean
    autoplay: boolean
    preload: string
    controls: boolean
    mute: boolean
    artist: Artist
}

export interface MenuDataItem extends BaseDataItem<'Menu'> {
    items?: [] | null
}

export interface StyledTextA11y {
    label?: string
    live?: 'polite' | 'assertive'
    level?: number
    role?: 'heading' | 'status' | 'alert'
    atomic?: boolean
    hidden?: boolean
    current?: 'step' | 'page' | 'true' | 'false'
}

export interface StyledTextDataItem extends BaseDataItem<'StyledText'> {
    text?: string
    stylesMapId?: string
    linkList?: Link[] | null
    a11y?: StyledTextA11y | null
}

export interface WRichTextProperties extends BaseDataItem<'WRichTextProperties'> {
    packed?: boolean
    brightness?: number
    overrideAlignment?: string
    overrideColor?: string
    minHeight?: number
    minWidth?: number
    verticalText?: boolean
}

export type SliderGalleryPropertiesDataItem = BaseDataItem<'SliderGalleryProperties'> & {
    imageMode?: 'clipImage' | 'flexibleWidth'
    margin?: number
    maxSpeed?: number
    aspectRatio?: number
    aspectRatioPreset?: AspectRatio
    loop?: boolean
    showCounter?: boolean
    expandEnabled?: boolean
    goToLinkText?: string | null
    galleryImageOnClickAction?: 'disabled' | 'zoomMode' | 'goToLink'
    autoplayInterval?: number | null
    transDuration?: number | null
    showAutoplay?: boolean | null
    autoplay?: boolean | null
}

export interface DefaultProperties {
    isHidden?: boolean
    isCollapsed?: boolean
    ghost?: 'COLLAPSED'
    fontScale?: number
}

export type BoxSlideShowProperties = BaseDataItem<'BoxSlideShowProperties'> &
    DefaultProperties & {
        transition?: 'NoTransition' | 'SlideVertical' | 'SlideHorizontal' | 'CrossFade' | 'OutIn'
        autoPlay?: boolean
        autoPlayInterval?: number
        direction?: LegacyDirection
        pauseAutoPlayOnMouseOver?: boolean
        transDuration?: number
        shouldHideOverflowContent?: boolean
        flexibleBoxHeight?: boolean
        showNavigationButton?: boolean
        navigationButtonSize?: number
        navigationButtonMargin?: number
        showNavigationDots?: boolean
        navigationDotsAlignment?: HorizontalAlignment
        navigationDotsSize?: number
        navigationDotsMargin?: number
        navigationDotsGap?: number
    }

export type LinkableButtonDataItem = BaseDataItem<'LinkableButton'> & {
    label?: string
    link?: Link | string
    a11y?: string | null
}

export type SvgShapeDataItem = BaseDataItem<'SvgShape'> & {
    link?: Link
    alt?: string
}

export type CropMaskFlipArray = ['x', 'y', 'xy', 'none']
export type CropMaskFlip = CropMaskFlipArray[number]

export interface CropMask {
    svgId: string
    flip?: CropMaskFlip
    rotate?: number
}

type Crop = MeshData & CropMask

export type ImageDataItem = BaseDataItem<'Image'> & {
    link?: Link
    title?: string
    name?: string
    uri?: string
    description?: string
    height?: number
    width?: number
    borderSize?: string
    alt?: string
    originalImageDataRef?: string
    opacity?: number
    crop?: Crop
    upscaleMethod: string
    focalPoint?: Point
    artist: Artist
    metaData?: MetaData
}

export type UrlFormat = 'hashBang' | 'slash'

export type WFacebookLikeDataItem = BaseDataItem<'WFacebookLike'> & {
    isHttpsEnabled?: boolean
    urlFormat: UrlFormat
}

export type SvgShapeProperties = BaseDataItem<'SvgShapeProperties'> &
    DefaultProperties & {
        maintainAspectRatio?: boolean
    }

export type HoverBoxProperties = BaseDataItem<'HoverBoxProperties'> &
    DefaultProperties &
    VideoBackgroundProperties & {
        mobileDisplayedModeId?: string | null
    }

export interface VideoBackgroundProperties {
    enableBackgroundVideo?: boolean
}

export type VectorImageDataItem = BaseDataItem<'VectorImage'> & {
    link?: Link
    alt?: string
    title?: string
    svgId?: string
}

export type LanguageSelectorProperties = BaseDataItem<'LanguageSelectorProperties'> &
    DefaultProperties & {
        displayMode?: 'horizontal' | 'dropdown'
        iconType?: 'none' | 'circle' | 'square' | 'rounded'
        iconSize?: number | null
        hasArrow?: boolean
        itemFormat?: 'shortName' | 'fullName' | 'iconOnly' | 'fullLocalizedName'
        alignTabs?: HorizontalAlignment
        languagesOrder?: string[]
    }
export type StripColumnsContainerProperties = BaseDataItem<'StripColumnsContainerProperties'> &
    DefaultProperties &
    VideoBackgroundProperties & {
        columnsMargin?: number
        frameMargin?: number
        fullWidth?: boolean
        rowMargin?: number
        siteMargin?: number
    }

export type PopupContainerProperties = BaseDataItem<'PopupContainerProperties'> &
    DefaultProperties &
    VideoBackgroundProperties & {
        horizontalAlignment?: 'left' | 'center' | 'right'
        verticalAlignment?: 'top' | 'center' | 'bottom'
        alignmentType?: 'nineGrid' | 'fullHeight' | 'fullWidth'
        horizontalOffset?: number
        verticalOffset?: number
    }

export type TPAGluedProperties = BaseDataItem<'TPAGluedProperties'> &
    DefaultProperties & {
        placement:
            | 'BOTTOM_RIGHT'
            | 'BOTTOM_LEFT'
            | 'TOP_LEFT'
            | 'TOP_RIGHT'
            | 'TOP_CENTER'
            | 'CENTER_RIGHT'
            | 'CENTER_LEFT'
            | 'BOTTOM_CENTER'
        verticalMargin: string | number
        horizontalMargin: string | number
    }

export type ColumnProperties = BaseDataItem<'ColumnProperties'> &
    DefaultProperties &
    VideoBackgroundProperties & {
        columnsMargin?: number
        background?: string | null
        alignment?: number
        mediaBackgroundPadding?: null | {top: number | null} | {bottom: number | null}
    }

export interface VectorImageShadow {
    x?: number
    y?: number
    spreadRadius?: number
    blurRadius?: number
    opacity?: number
    color?: string
}

interface VectorImageShapeStyle {
    opacity?: number
    strokeWidth?: number
    fill?: string
    fillOpacity?: number
    stroke?: string
    strokeOpacity?: number
    enableStroke?: boolean
}

type OverrideColor = String | null

interface VectorImageOverrideColors {
    color1?: OverrideColor
    color2?: OverrideColor
    color3?: OverrideColor
    color4?: OverrideColor
    color5?: OverrideColor
    color6?: OverrideColor
    color7?: OverrideColor
    color8?: OverrideColor
}

export type VectorImageDesignData = BaseDataItem<'VectorImageDesignData'> & {
    shadow?: VectorImageShadow | null
    shapeStyle?: VectorImageShapeStyle
    overrideColors?: VectorImageOverrideColors | null
}

export type CheckboxInputDataItem = BaseDataItem<'CheckboxInput'> &
    BaseTextInput & {
        checked?: boolean
        indeterminate?: boolean
        label?: string
        linkLabel?: string
        link?: Link
    }

export type CheckboxProperties = BaseDataItem<'CheckboxProperties'> &
    InputProperties & {
        alignment?: 'left' | 'right'
        buttonSize?: number
        spacing?: number
    }

export type MediaControlsProperties = BaseDataItem<'MediaControlsProperties'> &
    DefaultProperties & {
        showStoryboard?: 'videoAndTime' | 'time' | 'none'
        playerId?: string
    }

export interface AnchorsData {
    [anchorId: string]: AnchorDataItem
}

export interface SelectOption extends BaseDataItem<'SelectOption'> {
    value: string
    text: string
    selectedText: string
    disabled: boolean
    description: string
}

export interface SelectableList extends BaseDataItem<'SelectableList'> {
    value?: string
    options: string[]
}

export type StyleDataItem =
    | StyleRef
    | ResolvedStyleBreakpointRelation
    | ResolvedStyleVariantRelation
    | ResolvedStyleRefArray

export interface Data {
    theme_data: {
        THEME_DATA: ThemeData
    } & {[styleId: string]: StyleDataItem}
    design_data: DataMap
    document_data: DataMap
    layout_data: LayoutData
    component_properties: DataMap
    connections_data: {[connectionId: string]: ConnectionList}
    mobile_hints: DataMap
    breakpoints_data: BreakpointData
    behaviors_data: {
        [behaviorId: string]: ObsoleteBehaviorsList
    }
    transformations_data: TransformationsDataMap
    transitions_data: TransitionsDataMap
    variants_data: VariantsDataMap
    anchors_data: AnchorsData
}

export interface TPAGlobalData extends BaseDataItem<'TPAGlobalData'> {
    appDefinitionId: string
    content: string
}

export interface TPAGlobalDataReturnVal extends BaseDataItem<'TPAGlobalData'> {
    appDefinitionId: string
    content: Record<string, any>
}

export type DataMapName = keyof Data

export type PossibleDataItems =
    | TransitionDataItem
    | VariantDataItem
    | AnchorDataItem
    | TransformationDataItem
    | ObsoleteBehaviorsList
    | BreakpointItem
    | ConnectionList
    | StyleDataItem
    | SingleLayoutItem
    | ThemeData
    | Partial<BaseDataItem>
