import {fullAPI} from './index'
import {createPublicMethodUtils} from './apiUtils/publicMethods'
import {deprecationUtil} from './apiUtils/deprecation'
import _ from 'lodash'

function initPublicModules(modulesList?: string[]) {
    const publicMethodUtils = createPublicMethodUtils()
    const deprecation = deprecationUtil(publicMethodUtils)
    modulesList = modulesList ?? Object.keys(fullAPI)
    return _.map(modulesList, moduleName => {
        const moduleInitializer = fullAPI[moduleName]
        if (moduleInitializer) {
            return moduleInitializer(publicMethodUtils, deprecation)
        }
        throw new Error(`Cannot find public module "${moduleName}".`)
    })
}

export {initPublicModules, fullAPI}
