import type {DocumentServicesDal, Pointer, Pointers, PS} from '@wix/document-services-types'
import {coreUtils} from '@wix/santa-ds-libs'
const {objectUtils} = coreUtils

function setRenderFlag(ps: PS, flagName: string, value) {
    const renderFlagPointer = ps.pointers.general.getRenderFlag(flagName)
    ps.dal.set(renderFlagPointer, value)
}

const hideComp = ({pointers, dal}: {pointers: Pointers; dal: DocumentServicesDal}, compPointer: Pointer) => {
    const viewMode = pointers.components.getViewMode(compPointer)
    const isCompHiddenPointer = pointers.general.getIsCompHiddenPointer(compPointer.id, viewMode)
    dal.set(isCompHiddenPointer, true)
}

const showComp = ({pointers, dal}: {pointers: Pointers; dal: DocumentServicesDal}, compPointer: Pointer) => {
    const viewMode = pointers.components.getViewMode(compPointer)
    const isCompHiddenPointer = pointers.general.getIsCompHiddenPointer(compPointer.id, viewMode)
    if (dal.isExist(isCompHiddenPointer)) {
        dal.remove(isCompHiddenPointer)
    }
}

const isCompHidden = ({pointers, dal}: {pointers: Pointers; dal: DocumentServicesDal}, compPointer: Pointer) =>
    !!dal.get(pointers.general.getIsCompHiddenPointer(compPointer.id, pointers.components.getViewMode(compPointer)))

/**
 * Check value to siteData.compToShowOnTop
 * @param ps
 * @param compIds
 * @returns Is compId different from current siteData.compToShowOnTop
 */
function checkCompsToShowOnTop(ps: PS, compIds): boolean {
    const compsToShowOnTopPointer = ps.pointers.general.getRenderRealtimeConfigItem('compsToShowOnTop')
    return objectUtils.isDifferent(compIds, ps.dal.get(compsToShowOnTopPointer))
}

/**
 * Set value to siteData.compToShowOnTop
 * @param ps
 * @param compIds
 */
function setCompsToShowOnTop(ps: PS, compIds) {
    if (checkCompsToShowOnTop(ps, compIds)) {
        const compsToShowOnTopPointer = ps.pointers.general.getRenderRealtimeConfigItem('compsToShowOnTop')
        ps.dal.set(compsToShowOnTopPointer, compIds)
    }
}

function getCompsToShowOnTop(ps: PS) {
    const compsToShowOnTopPointer = ps.pointers.general.getRenderRealtimeConfigItem('compsToShowOnTop')
    return ps.dal.get(compsToShowOnTopPointer)
}
/**
 * Set value to siteData.compToShowOnTop
 * @param ps
 * @param compIds
 * @param opacity
 */
function setCompsToShowWithOpacity(ps: PS, compIds, opacity) {
    const compsToShowWithOpacity = ps.pointers.general.getRenderRealtimeConfigItem('compsToShowWithOpacity')
    ps.dal.set(compsToShowWithOpacity, {
        opacity,
        compIds
    })
}

/**
 * @param ps
 * @param compId
 * @returns Is compId different from current siteData.hideTextComponent
 */
function checkHideTextComponent(ps: PS, compId: string): boolean {
    const hideTextComponentPointer = ps.pointers.general.getRenderRealtimeConfigItem('hideTextComponent')
    return objectUtils.isDifferent(compId, ps.dal.get(hideTextComponentPointer))
}

/**
 * Set value to siteData.hideTextComponent
 * @param ps
 * @param compId
 */
function setHideTextComponent(ps: PS, compId: string) {
    if (checkHideTextComponent(ps, compId)) {
        const hideTextComponentPointer = ps.pointers.general.getRenderRealtimeConfigItem('hideTextComponent')
        ps.dal.set(hideTextComponentPointer, compId)
    }
}

/**
 * Set value to siteData.renderFlags.allowSiteOverflow
 * @param ps
 * @param allow
 */
function allowSiteOverflow(ps: PS, allow: boolean) {
    setRenderFlag(ps, 'allowSiteOverflow', allow)
}

/**
 * Set value to siteData.renderFlags.extraSiteHeight
 * @param ps
 * @param extraSiteHeight
 */
function setExtraSiteHeight(ps: PS, extraSiteHeight: number) {
    setRenderFlag(ps, 'extraSiteHeight', extraSiteHeight)
}

function setErrorPagesPopUp(ps: PS, callback) {
    ps.siteAPI.setErrorPagesPopUp(callback)
}

function setPreviewTooltipCallback(ps: PS, callback) {
    ps.siteAPI.setPreviewTooltipCallback(callback)
}

function setBlockingLayer(ps: PS, style) {
    setRenderFlag(ps, 'blockingLayer', style)
}

function setBlockingPopupLayer(ps: PS, style) {
    setRenderFlag(ps, 'blockingPopupLayer', style)
}

function setPremiumNavigationHandler(ps: PS, callback) {
    ps.siteAPI.setPremiumNavigationHandler(callback)
}

export default {
    setCompsToShowWithOpacity,
    setCompsToShowOnTop,
    getCompsToShowOnTop,
    hideComp,
    showComp,
    isCompHidden,
    setHideTextComponent,
    allowSiteOverflow,
    setExtraSiteHeight,
    setPreviewTooltipCallback,
    setErrorPagesPopUp,
    setBlockingLayer,
    setBlockingPopupLayer,
    setPremiumNavigationHandler
}
