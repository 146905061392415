import _ from 'lodash'
import seedrandom from 'seedrandom/lib/alea'

const GUID_FORMAT = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
const _state = {lastGeneratedId: undefined, counter: 0}

const stringHash = str => {
    let hash = 5381
    let i = str.length

    while (i) {
        hash = (hash * 33) ^ str.charCodeAt(--i)
    }
    return hash >>> 0
}

function randOrSeed(max: number, bucket: string) {
    return Seed.shouldUseSeed ? getSeedNextInteger(bucket) % max : (Math.random() * max) | 0
}

function randomizeChar(c: string, {bucket}: {bucket?: string} = {}) {
    const r = randOrSeed(16, bucket)
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
}

// @ts-ignore
function getRandomLowercaseLetter({bucket} = {}) {
    return String.fromCharCode(97 + randOrSeed(26, bucket))
}

function _getUniqueId(
    state: {lastGeneratedId?: string | number; counter: number},
    prefix: string | undefined,
    prefixDelimiter: string | undefined,
    dateNow: number
) {
    prefix = prefix || ''
    prefixDelimiter = prefixDelimiter || ''
    const value = dateNow
    if (value === state.lastGeneratedId) {
        state.counter++
    } else {
        state.lastGeneratedId = value
        state.counter = 0
    }
    return prefix + prefixDelimiter + Number(state.lastGeneratedId).toString(36) + (state.counter || '')
}

const Seed = {
    initialSeed: 0,
    shouldUseSeed: false,
    currentVal: 0,
    // eslint-disable-next-line new-cap
    srand: new seedrandom(0)
}

function getSeedNextInteger(bucket: string) {
    if (bucket) {
        if (!_.get(Seed, [bucket, 'currentVal'])) {
            _.set(Seed, [bucket, 'currentVal'], stringHash(bucket))
        }
    }

    return Seed.initialSeed + (bucket ? ++Seed[bucket].currentVal : ++Seed.currentVal)
}

function getUniqueId(
    prefix: string | undefined,
    prefixDelimiter: string | undefined,
    {bucket}: {bucket?: string} = {}
) {
    return _getUniqueId(_state, prefix, prefixDelimiter, Seed.shouldUseSeed ? getSeedNextInteger(bucket) : Date.now())
}

function getGUID() {
    return GUID_FORMAT.replace(/[xy]/g, c => randomizeChar(c, {bucket: 'getGUID'}))
}

function generateNewPageId(existingPageIds: string[]): string {
    const pageIdBucket = 'newPageId'
    const generatedId =
        getRandomLowercaseLetter({bucket: pageIdBucket}) +
        randOrSeed(36, pageIdBucket).toString(36) +
        randOrSeed(36, pageIdBucket).toString(36) +
        randOrSeed(36, pageIdBucket).toString(36) +
        randOrSeed(36, pageIdBucket).toString(36)
    if (_.includes(existingPageIds, generatedId)) {
        return this.generateNewPageId(existingPageIds)
    }
    return generatedId
}

function generateNewPopupId(existingPageIds: string[], popupIds: string[]) {
    const lastId = _(popupIds).sortBy().last()
    let newPopupId: string

    if (lastId) {
        newPopupId = lastId
        do {
            newPopupId = increaseId(newPopupId)
        } while (_.includes(existingPageIds, newPopupId))
        return newPopupId
    }
    return this.generateNewPageId(existingPageIds)

    function increaseId(id: string, charPos?: number) {
        charPos = _.isUndefined(charPos) ? id.length - 1 : charPos
        let charCode = id.charCodeAt(charPos)

        if (charCode > 121) {
            id = increaseId(id, charPos - 1)
            charCode = 47
        } else if (charCode > 56 && charCode < 96) {
            charCode = 96
        }

        return id.substring(0, charPos) + String.fromCharCode(charCode + 1) + id.substring(charPos + 1)
    }
}

function random() {
    return Seed.shouldUseSeed ? Seed.srand() : Math.random()
}

function getInitialSeed() {
    return Seed.initialSeed
}

function setInitialSeed(initialSeed?: number) {
    if (initialSeed === undefined) {
        Seed.shouldUseSeed = false
        return
    }

    Object.keys(Seed).forEach(key => delete Seed[key]) //reset all buckets
    Seed.initialSeed = initialSeed
    Seed.currentVal = 0
    Seed.shouldUseSeed = true
    Seed.srand = seedrandom(initialSeed.toString())
}

export default {
    random,
    getUniqueId,
    getGUID,
    generateNewPageId,
    generateNewPopupId,
    getInitialSeed,
    setInitialSeed
}
