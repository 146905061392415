/**
 * Created by Dan_Shappir on 7/13/15.
 */
import _ from 'lodash'

const hasWindow = typeof window !== 'undefined'

const isParamTrue = (param: string) => hasWindow && _.invoke(window, ['queryUtil', 'isParameterTrue'], param)

const timeSinceNavigate = () => {
    if (hasWindow) {
        return Math.round(_.invoke(window, ['performance', 'now']) ?? 0)
    }
    return 0
}

const create = () => {
    if (!isParamTrue('suppressbi')) {
        let newrelic
        try {
            // @ts-ignore
            newrelic = hasWindow && window.parent.newrelic // Use parent for preview in editor
        } catch (e) {
            // @ts-ignore
            newrelic = hasWindow && window.newrelic
        }
        if (newrelic) {
            return {
                setCustomAttribute: newrelic.setCustomAttribute.bind(newrelic),
                addPageAction(name, attributes) {
                    const tsn = timeSinceNavigate()
                    attributes = _.assign({}, attributes, {timeSinceNavigate: tsn})
                    return newrelic.addPageAction(name, attributes)
                },
                finished() {
                    const tsn = timeSinceNavigate()
                    if (tsn) {
                        this.setCustomAttribute('timeSinceNavigate', timeSinceNavigate())
                    }
                    return newrelic.finished.apply(newrelic, arguments)
                }
            }
        }
    }
    return {
        setCustomAttribute: _.noop,
        addPageAction: _.noop,
        finished: _.noop
    }
}

const exportValue = create()

export default exportValue
