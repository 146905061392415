import type {PublicMethodUtils} from '@wix/document-services-types'
import {autosave} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    const {deprecate} = publicMethodUtils
    return {
        initMethod: autosave.initMethod,
        methods: {
            /**
             * performs an autosave
             * @member documentServices
             * @param {ps} privateServices
             * @param {onSuccess} onSuccess
             * @param {onError} onError
             * @param {string} triggerName
             */
            autosave: deprecate(publicMethodUtils.defineAction(autosave.autosave), 'autosave is deprecated'),

            /**
             * Checks if autosave should be performed
             * @member documentServices
             * @return {boolean}
             */
            canAutosave: deprecate(publicMethodUtils.defineGetter(autosave.canAutosave), 'autosave is deprecated'),

            /**
             * Returns documentServicesModel.autoSaveInfo
             * @member documentServices
             * @return {boolean}
             */
            getAutoSaveInfo: publicMethodUtils.defineGetter(autosave.getAutoSaveInfo),

            /**
             * Initializes Auto-Save by passing configuration object
             * @member documentServices
             * @param {object} configuration object
             */
            initAutosave: publicMethodUtils.defineAction(autosave.init)
        }
    }
}
