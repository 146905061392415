import {ai} from '@wix/document-services-implementation'
import type {PublicMethodUtils} from '@wix/document-services-types'

export default function (publicMethodUtils: PublicMethodUtils) {
    const {
        extensionPublicAPI: {getter, dataManipulation},
        defineAction,
        defineDataManipulationAction
    } = publicMethodUtils

    return {
        methods: {
            ai: {
                content: {
                    updatePageContent: defineDataManipulationAction(ai.applyOutline, {
                        asyncPreDataManipulation: ai.getSuggestedOutline,
                        isAsyncOperation: true
                    }),
                    updateSiteContent: defineDataManipulationAction(ai.applyOutlines, {
                        asyncPreDataManipulation: ai.getSuggestedSiteOutlines,
                        isAsyncOperation: true
                    }),
                    getContentByStructure: defineDataManipulationAction(ai.applyOutlineToStructure, {
                        asyncPreDataManipulation: ai.getSuggestedOutlineByStructure,
                        isAsyncOperation: true
                    }),
                    getContentByPageStructure: defineDataManipulationAction(ai.applyOutlineToPageStructure, {
                        asyncPreDataManipulation: ai.getSuggestedOutlineByPageStructure,
                        isAsyncOperation: true
                    }),
                    updateContentByPageStructure: defineDataManipulationAction(ai.applyOutlineToPage, {
                        asyncPreDataManipulation: ai.getSuggestedOutlineByPageStructure,
                        isAsyncOperation: true
                    }),
                    getPageOutline: getter('ai.content.getPageOutline'),
                    applyOutline: dataManipulation('ai.content.applyOutline')
                },
                conversation: {
                    sendMessage: defineAction(ai.conversation.sendMessage),
                    registerTool: defineAction(ai.conversation.registerTool)
                }
            }
        },
        initMethod: ai.initAITools
    }
}
