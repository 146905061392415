import type {PagesStructure} from '@wix/document-services-types'
import {responsiveAppWidgetStructure} from '../commonConfigs/responsiveAppWidgetConfig'

const pageStructure: PagesStructure = {
    components: [],
    componentType: 'mobile.core.components.Page',
    type: 'Page',
    skin: 'wysiwyg.viewer.skins.page.ResponsivePageWithColorBG',
    layouts: {
        type: 'SingleLayoutData',
        componentLayout: {
            type: 'ComponentLayout',
            height: {
                type: 'auto'
            }
        },
        itemLayout: {},
        containerLayout: {
            type: 'GridContainerLayout',
            rows: [
                {
                    type: 'auto'
                }
            ],
            columns: [
                {
                    type: 'MinMaxSize',
                    min: {
                        type: 'px',
                        value: 0
                    },
                    max: {
                        type: 'fr',
                        value: 1
                    }
                }
            ]
        }
    },
    style: {
        type: 'ComponentStyle',
        style: {
            properties: {
                'alpha-bg': '1',
                bg: 'color_11'
            },
            propertiesSource: {
                'alpha-bg': 'value',
                bg: 'theme'
            },
            groups: {}
        },
        componentClassName: 'mobile.core.components.Page',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.page.ResponsivePageWithColorBG'
    },
    activeModes: {},
    referredVariants: {},
    activeVariants: []
} as any

const getPageStructure = () => pageStructure

const getDashboardStructure = () => ({
    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
    componentType: 'mobile.core.components.Container',
    type: 'Container',
    layout: {
        x: 0,
        y: 0,
        fixedPosition: false,
        width: 160,
        height: 90,
        scale: 1,
        rotationInDegrees: 0
    },
    components: [
        {
            type: 'Container',
            skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
            componentType: 'wixui.MultiStateBox',
            layout: {
                x: 0,
                y: 0,
                fixedPosition: false,
                width: 160,
                height: 90,
                scale: 1,
                rotationInDegrees: 0
            },
            components: [
                {
                    type: 'Container',
                    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
                    componentType: 'mobile.core.components.Container',
                    layout: {
                        x: 0,
                        y: 0,
                        fixedPosition: false,
                        width: 160,
                        height: 90,
                        scale: 1,
                        rotationInDegrees: 0
                    },
                    components: [
                        {
                            type: 'Container',
                            skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
                            componentType: 'mobile.core.components.Container',
                            layout: {
                                x: 0,
                                y: 0,
                                fixedPosition: false,
                                width: 160,
                                height: 90,
                                scale: 1,
                                rotationInDegrees: 0
                            },
                            components: [
                                {
                                    type: 'Container',
                                    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
                                    componentType: 'mobile.core.components.Container',
                                    layout: {
                                        x: 0,
                                        y: 0,
                                        fixedPosition: false,
                                        width: 160,
                                        height: 90,
                                        scale: 1,
                                        rotationInDegrees: 0
                                    },
                                    components: [
                                        {
                                            type: 'Container',
                                            skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
                                            componentType: 'mobile.core.components.Container',
                                            layout: {
                                                x: 0,
                                                y: 0,
                                                fixedPosition: false,
                                                width: 160,
                                                height: 90,
                                                scale: 1,
                                                rotationInDegrees: 0
                                            },
                                            components: [
                                                {
                                                    type: 'Container',
                                                    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
                                                    componentType: 'mobile.core.components.Container',
                                                    layout: {
                                                        x: 0,
                                                        y: 0,
                                                        fixedPosition: false,
                                                        width: 160,
                                                        height: 90,
                                                        scale: 1,
                                                        rotationInDegrees: 0
                                                    },
                                                    components: [],
                                                    naming: {
                                                        name: 'CardContent',
                                                        type: 'naming'
                                                    },
                                                    layouts: {
                                                        componentLayout: {
                                                            minHeight: {
                                                                type: 'px',
                                                                value: 0
                                                            },
                                                            hidden: false,
                                                            height: {
                                                                type: 'auto'
                                                            },
                                                            minWidth: {
                                                                type: 'px',
                                                                value: 0
                                                            },
                                                            type: 'ComponentLayout',
                                                            width: {
                                                                type: 'auto'
                                                            }
                                                        },
                                                        itemLayout: {
                                                            alignSelf: 'stretch',
                                                            margins: {
                                                                left: {
                                                                    type: 'px',
                                                                    value: 0
                                                                },
                                                                right: {
                                                                    type: 'px',
                                                                    value: 0
                                                                },
                                                                top: {
                                                                    type: 'px',
                                                                    value: 0
                                                                },
                                                                bottom: {
                                                                    type: 'px',
                                                                    value: 0
                                                                }
                                                            },
                                                            gridArea: {
                                                                rowStart: 3,
                                                                rowEnd: 4,
                                                                columnStart: 1,
                                                                columnEnd: 2
                                                            },
                                                            justifySelf: 'stretch',
                                                            type: 'GridItemLayout'
                                                        },
                                                        containerLayout: {
                                                            columnGap: {
                                                                type: 'px',
                                                                value: 24
                                                            },
                                                            rowGap: {
                                                                type: 'px',
                                                                value: 24
                                                            },
                                                            columns: [
                                                                {
                                                                    type: 'fr',
                                                                    value: 2
                                                                },
                                                                {
                                                                    type: 'fr',
                                                                    value: 1
                                                                }
                                                            ],
                                                            padding: {
                                                                top: {
                                                                    type: 'px',
                                                                    value: 24
                                                                },
                                                                right: {
                                                                    type: 'px',
                                                                    value: 24
                                                                },
                                                                bottom: {
                                                                    type: 'px',
                                                                    value: 24
                                                                },
                                                                left: {
                                                                    type: 'px',
                                                                    value: 24
                                                                }
                                                            },
                                                            type: 'GridContainerLayout',
                                                            rows: [
                                                                {
                                                                    type: 'auto'
                                                                }
                                                            ]
                                                        },
                                                        type: 'SingleLayoutData'
                                                    },
                                                    style: {
                                                        type: 'ComponentStyle',
                                                        style: {
                                                            propertiesOverride: {},
                                                            properties: {
                                                                shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
                                                                rd: '0px 0px 8px 8px',
                                                                'alpha-brd': '1',
                                                                'alpha-bg': '1',
                                                                bg: '#FFFFFF',
                                                                brw: '0px',
                                                                brd: '#324158',
                                                                'boxShadowToggleOn-shd': 'false'
                                                            },
                                                            propertiesSource: {
                                                                shd: 'value',
                                                                rd: 'value',
                                                                'alpha-brd': 'value',
                                                                'alpha-bg': 'value',
                                                                bg: 'value',
                                                                brw: 'value',
                                                                brd: 'value',
                                                                'boxShadowToggleOn-shd': 'value'
                                                            },
                                                            groups: {}
                                                        },
                                                        componentClassName: 'mobile.core.components.Container',
                                                        pageId: '',
                                                        compId: '',
                                                        styleType: 'custom',
                                                        skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin'
                                                    }
                                                },
                                                {
                                                    type: 'Component',
                                                    skin: 'wysiwyg.viewer.skins.line.SolidLine',
                                                    componentType: 'wysiwyg.viewer.components.FiveGridLine',
                                                    layout: {
                                                        x: 0,
                                                        y: 0,
                                                        fixedPosition: false,
                                                        width: 160,
                                                        height: 90,
                                                        scale: 1,
                                                        rotationInDegrees: 0
                                                    },
                                                    props: {
                                                        type: 'FiveGridLineProperties',
                                                        fullScreenModeOn: false
                                                    },
                                                    naming: {
                                                        name: 'Divider',
                                                        type: 'naming'
                                                    },
                                                    layouts: {
                                                        componentLayout: {
                                                            type: 'ComponentLayout',
                                                            width: {
                                                                type: 'percentage',
                                                                value: 100
                                                            },
                                                            height: {
                                                                type: 'px',
                                                                value: 5
                                                            },
                                                            hidden: false
                                                        },
                                                        itemLayout: {
                                                            alignSelf: 'start',
                                                            margins: {
                                                                left: {
                                                                    type: 'percentage',
                                                                    value: 0
                                                                },
                                                                right: {
                                                                    type: 'percentage',
                                                                    value: 0
                                                                },
                                                                top: {
                                                                    type: 'px',
                                                                    value: 0
                                                                },
                                                                bottom: {
                                                                    type: 'px',
                                                                    value: 0
                                                                }
                                                            },
                                                            gridArea: {
                                                                rowStart: 2,
                                                                rowEnd: 3,
                                                                columnStart: 1,
                                                                columnEnd: 2
                                                            },
                                                            justifySelf: 'end',
                                                            type: 'GridItemLayout'
                                                        },
                                                        containerLayout: {},
                                                        type: 'SingleLayoutData'
                                                    },
                                                    style: {
                                                        type: 'ComponentStyle',
                                                        style: {
                                                            propertiesOverride: {},
                                                            properties: {
                                                                lnw: '1',
                                                                brd: '#DFE5EB',
                                                                'alpha-brd': '1'
                                                            },
                                                            propertiesSource: {
                                                                lnw: 'value',
                                                                brd: 'value',
                                                                'alpha-brd': 'value'
                                                            },
                                                            groups: {}
                                                        },
                                                        componentClassName: 'wysiwyg.viewer.components.FiveGridLine',
                                                        pageId: '',
                                                        compId: '',
                                                        styleType: 'custom',
                                                        skin: 'wysiwyg.viewer.skins.line.SolidLine'
                                                    }
                                                },
                                                {
                                                    type: 'Container',
                                                    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
                                                    componentType: 'mobile.core.components.Container',
                                                    layout: {
                                                        x: 0,
                                                        y: 0,
                                                        fixedPosition: false,
                                                        width: 160,
                                                        height: 90,
                                                        scale: 1,
                                                        rotationInDegrees: 0
                                                    },
                                                    components: [
                                                        {
                                                            type: 'Component',
                                                            skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
                                                            componentType: 'wysiwyg.viewer.components.WRichText',
                                                            layout: {
                                                                x: 0,
                                                                y: 0,
                                                                fixedPosition: false,
                                                                width: 160,
                                                                height: 90,
                                                                scale: 1,
                                                                rotationInDegrees: 0
                                                            },
                                                            data: {
                                                                type: 'StyledText',
                                                                text: '<h3 class="font_3"><span>Card title</span></h3>',
                                                                stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                                                linkList: []
                                                            },
                                                            props: {
                                                                type: 'WRichTextProperties',
                                                                brightness: 1,
                                                                packed: true,
                                                                verticalText: false
                                                            },
                                                            layouts: {
                                                                componentLayout: {
                                                                    type: 'ComponentLayout',
                                                                    width: {
                                                                        type: 'percentage',
                                                                        value: 100
                                                                    },
                                                                    height: {
                                                                        type: 'auto'
                                                                    },
                                                                    hidden: false
                                                                },
                                                                itemLayout: {
                                                                    type: 'StackItemLayout',
                                                                    order: 1,
                                                                    alignSelf: 'center',
                                                                    margins: {
                                                                        left: {
                                                                            type: 'percentage',
                                                                            value: 0
                                                                        },
                                                                        right: {
                                                                            type: 'percentage',
                                                                            value: 0
                                                                        },
                                                                        top: {
                                                                            type: 'px',
                                                                            value: 0
                                                                        },
                                                                        bottom: {
                                                                            type: 'px',
                                                                            value: 0
                                                                        }
                                                                    }
                                                                },
                                                                containerLayout: {},
                                                                type: 'SingleLayoutData'
                                                            },
                                                            style: {
                                                                type: 'ComponentStyle',
                                                                style: {
                                                                    properties: {
                                                                        textDecoration: 'none',
                                                                        textAlign: 'left',
                                                                        fontSize: '18px',
                                                                        fontFamily:
                                                                            'madefor-text-bold,helveticaneuew01-65medi,helveticaneuew02-65medi,helveticaneuew10-65medi,sans-serif'
                                                                    },
                                                                    propertiesSource: {
                                                                        textDecoration: 'value',
                                                                        textAlign: 'value',
                                                                        fontSize: 'value',
                                                                        fontFamily: 'value'
                                                                    },
                                                                    groups: {}
                                                                },
                                                                componentClassName:
                                                                    'wysiwyg.viewer.components.WRichText',
                                                                pageId: '',
                                                                compId: '',
                                                                styleType: 'custom',
                                                                skin: 'wysiwyg.viewer.skins.WRichTextNewSkin'
                                                            }
                                                        },
                                                        {
                                                            type: 'Component',
                                                            skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
                                                            componentType: 'wysiwyg.viewer.components.WRichText',
                                                            layout: {
                                                                x: 0,
                                                                y: 0,
                                                                fixedPosition: false,
                                                                width: 160,
                                                                height: 90,
                                                                scale: 1,
                                                                rotationInDegrees: 0
                                                            },
                                                            data: {
                                                                type: 'StyledText',
                                                                text: '<p class="font_7">Subtitle</p>',
                                                                stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                                                linkList: []
                                                            },
                                                            props: {
                                                                type: 'WRichTextProperties',
                                                                brightness: 1,
                                                                packed: true,
                                                                verticalText: false
                                                            },
                                                            layouts: {
                                                                componentLayout: {
                                                                    type: 'ComponentLayout',
                                                                    width: {
                                                                        type: 'percentage',
                                                                        value: 100
                                                                    },
                                                                    height: {
                                                                        type: 'auto'
                                                                    },
                                                                    hidden: false
                                                                },
                                                                itemLayout: {
                                                                    type: 'StackItemLayout',
                                                                    order: 2,
                                                                    alignSelf: 'center',
                                                                    margins: {
                                                                        left: {
                                                                            type: 'percentage',
                                                                            value: 0
                                                                        },
                                                                        right: {
                                                                            type: 'percentage',
                                                                            value: 0
                                                                        },
                                                                        top: {
                                                                            type: 'px',
                                                                            value: 0
                                                                        },
                                                                        bottom: {
                                                                            type: 'px',
                                                                            value: 0
                                                                        }
                                                                    }
                                                                },
                                                                containerLayout: {},
                                                                type: 'SingleLayoutData'
                                                            },
                                                            style: {
                                                                type: 'ComponentStyle',
                                                                style: {
                                                                    properties: {
                                                                        textDecoration: 'none',
                                                                        color: '#333853',
                                                                        textAlign: 'left',
                                                                        fontFamily:
                                                                            'madefor-text,helveticaneuew01-45ligh,helveticaneuew02-45ligh,helveticaneuew10-45ligh,sans-serif',
                                                                        lineHeight: '1.5em',
                                                                        fontSize: '16px'
                                                                    },
                                                                    propertiesSource: {
                                                                        textDecoration: 'value',
                                                                        color: 'value',
                                                                        textAlign: 'value',
                                                                        fontFamily: 'value',
                                                                        lineHeight: 'value',
                                                                        fontSize: 'value'
                                                                    },
                                                                    groups: {}
                                                                },
                                                                componentClassName:
                                                                    'wysiwyg.viewer.components.WRichText',
                                                                pageId: '',
                                                                compId: '',
                                                                styleType: 'custom',
                                                                skin: 'wysiwyg.viewer.skins.WRichTextNewSkin'
                                                            }
                                                        }
                                                    ],
                                                    naming: {
                                                        name: 'CardHeader',
                                                        type: 'naming'
                                                    },
                                                    layouts: {
                                                        componentLayout: {
                                                            type: 'ComponentLayout',
                                                            width: {
                                                                type: 'percentage',
                                                                value: 100
                                                            },
                                                            height: {
                                                                type: 'maxContent'
                                                            },
                                                            hidden: false
                                                        },
                                                        itemLayout: {
                                                            alignSelf: 'start',
                                                            margins: {
                                                                left: {
                                                                    type: 'percentage',
                                                                    value: 0
                                                                },
                                                                right: {
                                                                    type: 'percentage',
                                                                    value: 0
                                                                },
                                                                top: {
                                                                    type: 'percentage',
                                                                    value: 0
                                                                },
                                                                bottom: {
                                                                    type: 'percentage',
                                                                    value: 0
                                                                }
                                                            },
                                                            gridArea: {
                                                                rowStart: 1,
                                                                rowEnd: 2,
                                                                columnStart: 1,
                                                                columnEnd: 2
                                                            },
                                                            justifySelf: 'center',
                                                            type: 'GridItemLayout'
                                                        },
                                                        containerLayout: {
                                                            type: 'StackContainerLayout',
                                                            direction: 'column',
                                                            padding: {
                                                                top: {
                                                                    type: 'px',
                                                                    value: 18
                                                                },
                                                                right: {
                                                                    type: 'px',
                                                                    value: 24
                                                                },
                                                                bottom: {
                                                                    type: 'px',
                                                                    value: 18
                                                                },
                                                                left: {
                                                                    type: 'px',
                                                                    value: 24
                                                                }
                                                            }
                                                        },
                                                        type: 'SingleLayoutData'
                                                    },
                                                    style: {
                                                        type: 'ComponentStyle',
                                                        style: {
                                                            properties: {
                                                                shd: '0px 0px 0px 0px rgba(0,0,0,0.6)',
                                                                rd: '0px',
                                                                'alpha-brd': '0',
                                                                'alpha-bg': '0',
                                                                bg: '#FFFFFF',
                                                                brw: '0px',
                                                                'boxShadowToggleOn-shd': 'false'
                                                            },
                                                            propertiesSource: {
                                                                shd: 'value',
                                                                rd: 'value',
                                                                'alpha-brd': 'value',
                                                                'alpha-bg': 'value',
                                                                bg: 'value',
                                                                brw: 'value',
                                                                'boxShadowToggleOn-shd': 'value'
                                                            },
                                                            groups: {}
                                                        },
                                                        componentClassName: 'mobile.core.components.Container',
                                                        pageId: '',
                                                        compId: '',
                                                        styleType: 'custom',
                                                        skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin'
                                                    }
                                                }
                                            ],
                                            naming: {
                                                name: 'Card',
                                                type: 'naming'
                                            },
                                            layouts: {
                                                componentLayout: {
                                                    type: 'ComponentLayout',
                                                    width: {
                                                        type: 'auto'
                                                    },
                                                    height: {
                                                        type: 'auto'
                                                    },
                                                    minWidth: {
                                                        type: 'px',
                                                        value: 0
                                                    },
                                                    minHeight: {
                                                        type: 'px',
                                                        value: 0
                                                    },
                                                    hidden: false
                                                },
                                                itemLayout: {
                                                    type: 'GridItemLayout',
                                                    gridArea: {
                                                        rowStart: 1,
                                                        rowEnd: 2,
                                                        columnStart: 1,
                                                        columnEnd: 2
                                                    },
                                                    margins: {
                                                        left: {
                                                            type: 'px',
                                                            value: 0
                                                        },
                                                        right: {
                                                            type: 'px',
                                                            value: 0
                                                        },
                                                        top: {
                                                            type: 'px',
                                                            value: 0
                                                        },
                                                        bottom: {
                                                            type: 'px',
                                                            value: 0
                                                        }
                                                    },
                                                    justifySelf: 'stretch',
                                                    alignSelf: 'stretch'
                                                },
                                                containerLayout: {
                                                    type: 'GridContainerLayout',
                                                    rows: [
                                                        {
                                                            type: 'minContent'
                                                        },
                                                        {
                                                            type: 'px',
                                                            value: 1
                                                        },
                                                        {
                                                            type: 'auto'
                                                        }
                                                    ],
                                                    columns: [
                                                        {
                                                            type: 'fr',
                                                            value: 1
                                                        }
                                                    ]
                                                },
                                                type: 'SingleLayoutData'
                                            },
                                            style: {
                                                type: 'ComponentStyle',
                                                style: {
                                                    propertiesOverride: {},
                                                    properties: {
                                                        shd: '0px 0px 0px 0px rgba(0,0,0,0.6)',
                                                        rd: '8px 8px 8px 8px',
                                                        'alpha-brd': '0',
                                                        'alpha-bg': '1',
                                                        bg: 'color_11',
                                                        brw: '0px',
                                                        'boxShadowToggleOn-shd': 'false'
                                                    },
                                                    propertiesSource: {
                                                        shd: 'value',
                                                        rd: 'value',
                                                        'alpha-brd': 'value',
                                                        'alpha-bg': 'value',
                                                        bg: 'theme',
                                                        brw: 'value',
                                                        'boxShadowToggleOn-shd': 'value'
                                                    },
                                                    groups: {}
                                                },
                                                componentClassName: 'mobile.core.components.Container',
                                                pageId: '',
                                                compId: '',
                                                styleType: 'custom',
                                                skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin'
                                            }
                                        }
                                    ],
                                    manifest: {
                                        type: 'Manifest',
                                        behavior: {
                                            preventHide: true
                                        }
                                    },
                                    naming: {
                                        name: 'PageContent',
                                        type: 'naming'
                                    },
                                    layouts: {
                                        containerLayout: {
                                            type: 'GridContainerLayout',
                                            rows: [
                                                {
                                                    type: 'fr',
                                                    value: 1
                                                }
                                            ],
                                            columns: [
                                                {
                                                    type: 'fr',
                                                    value: 1
                                                }
                                            ],
                                            padding: {
                                                left: {
                                                    type: 'px',
                                                    value: 0
                                                },
                                                right: {
                                                    type: 'px',
                                                    value: 0
                                                },
                                                top: {
                                                    type: 'px',
                                                    value: 0
                                                },
                                                bottom: {
                                                    type: 'px',
                                                    value: 0
                                                }
                                            }
                                        },
                                        componentLayout: {
                                            minHeight: {
                                                type: 'px',
                                                value: 0
                                            },
                                            hidden: false,
                                            height: {
                                                type: 'auto'
                                            },
                                            minWidth: {
                                                type: 'px',
                                                value: 0
                                            },
                                            type: 'ComponentLayout',
                                            width: {
                                                type: 'auto'
                                            }
                                        },
                                        itemLayout: {
                                            alignSelf: 'stretch',
                                            margins: {
                                                left: {
                                                    type: 'px',
                                                    value: 0
                                                },
                                                right: {
                                                    type: 'px',
                                                    value: 0
                                                },
                                                top: {
                                                    type: 'px',
                                                    value: 0
                                                },
                                                bottom: {
                                                    type: 'px',
                                                    value: 0
                                                }
                                            },
                                            gridArea: {
                                                rowStart: 2,
                                                rowEnd: 3,
                                                columnStart: 2,
                                                columnEnd: 3
                                            },
                                            justifySelf: 'stretch',
                                            type: 'GridItemLayout'
                                        },
                                        type: 'SingleLayoutData'
                                    },
                                    style: {
                                        type: 'ComponentStyle',
                                        style: {
                                            propertiesOverride: {},
                                            properties: {
                                                shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
                                                rd: '0px',
                                                'alpha-brd': '1',
                                                'alpha-bg': '0',
                                                bg: '#FFFFFF',
                                                brw: '0px',
                                                brd: '#324158',
                                                'boxShadowToggleOn-shd': 'false'
                                            },
                                            propertiesSource: {
                                                shd: 'value',
                                                rd: 'value',
                                                'alpha-brd': 'value',
                                                'alpha-bg': 'value',
                                                bg: 'value',
                                                brw: 'value',
                                                brd: 'value',
                                                'boxShadowToggleOn-shd': 'value'
                                            },
                                            groups: {}
                                        },
                                        componentClassName: 'mobile.core.components.Container',
                                        pageId: '',
                                        compId: '',
                                        styleType: 'custom',
                                        skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin'
                                    }
                                },
                                {
                                    type: 'Container',
                                    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
                                    componentType: 'mobile.core.components.Container',
                                    layout: {
                                        x: 0,
                                        y: 0,
                                        fixedPosition: false,
                                        width: 160,
                                        height: 90,
                                        scale: 1,
                                        rotationInDegrees: 0
                                    },
                                    components: [
                                        {
                                            type: 'Container',
                                            skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
                                            componentType: 'mobile.core.components.Container',
                                            layout: {
                                                x: 0,
                                                y: 0,
                                                fixedPosition: false,
                                                width: 160,
                                                height: 90,
                                                scale: 1,
                                                rotationInDegrees: 0
                                            },
                                            components: [
                                                {
                                                    type: 'Component',
                                                    skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
                                                    componentType: 'wysiwyg.viewer.components.WRichText',
                                                    layout: {
                                                        x: 0,
                                                        y: 0,
                                                        fixedPosition: false,
                                                        width: 160,
                                                        height: 90,
                                                        scale: 1,
                                                        rotationInDegrees: 0
                                                    },
                                                    data: {
                                                        type: 'StyledText',
                                                        text: '<h2 class="font_2">Page&nbsp;01</h2>',
                                                        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                                        linkList: []
                                                    },
                                                    props: {
                                                        type: 'WRichTextProperties',
                                                        brightness: 1,
                                                        packed: true,
                                                        verticalText: false
                                                    },
                                                    layouts: {
                                                        containerLayout: {},
                                                        componentLayout: {
                                                            height: {
                                                                type: 'auto'
                                                            },
                                                            width: {
                                                                type: 'percentage',
                                                                value: 100
                                                            },
                                                            type: 'ComponentLayout',
                                                            hidden: false
                                                        },
                                                        itemLayout: {
                                                            type: 'StackItemLayout',
                                                            order: 1,
                                                            alignSelf: 'center',
                                                            margins: {
                                                                left: {
                                                                    type: 'px',
                                                                    value: 0
                                                                },
                                                                right: {
                                                                    type: 'px',
                                                                    value: 0
                                                                },
                                                                top: {
                                                                    type: 'px',
                                                                    value: 0
                                                                },
                                                                bottom: {
                                                                    type: 'px',
                                                                    value: 0
                                                                }
                                                            }
                                                        },
                                                        type: 'SingleLayoutData'
                                                    },
                                                    style: {
                                                        type: 'ComponentStyle',
                                                        style: {
                                                            properties: {
                                                                textDecoration: 'none',
                                                                color: '#000624',
                                                                fontFamily:
                                                                    'wfont_1f4e5a_8631ad30ea9042b09e3563510c17dfa4,wf_8631ad30ea9042b09e3563510,orig_wix_madefor_text_bold',
                                                                lineHeight: '1.3em',
                                                                fontSize: '28px'
                                                            },
                                                            propertiesSource: {
                                                                textDecoration: 'value',
                                                                color: 'value',
                                                                fontFamily: 'value',
                                                                lineHeight: 'value',
                                                                fontSize: 'value'
                                                            },
                                                            groups: {}
                                                        },
                                                        componentClassName: 'wysiwyg.viewer.components.WRichText',
                                                        pageId: '',
                                                        compId: '',
                                                        styleType: 'custom',
                                                        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin'
                                                    }
                                                },
                                                {
                                                    type: 'Component',
                                                    skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
                                                    componentType: 'wysiwyg.viewer.components.WRichText',
                                                    layout: {
                                                        x: 0,
                                                        y: 0,
                                                        fixedPosition: false,
                                                        width: 160,
                                                        height: 90,
                                                        scale: 1,
                                                        rotationInDegrees: 0
                                                    },
                                                    data: {
                                                        type: 'StyledText',
                                                        text: '<p class="font_7"><span>Subtitle</span></p>',
                                                        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                                        linkList: []
                                                    },
                                                    props: {
                                                        type: 'WRichTextProperties',
                                                        brightness: 1,
                                                        packed: true,
                                                        verticalText: false
                                                    },
                                                    naming: {
                                                        name: 'Page Subtitle',
                                                        type: 'naming'
                                                    },
                                                    layouts: {
                                                        containerLayout: {},
                                                        componentLayout: {
                                                            height: {
                                                                type: 'auto'
                                                            },
                                                            width: {
                                                                type: 'percentage',
                                                                value: 100
                                                            },
                                                            type: 'ComponentLayout',
                                                            hidden: false
                                                        },
                                                        itemLayout: {
                                                            type: 'StackItemLayout',
                                                            order: 2,
                                                            alignSelf: 'center',
                                                            margins: {
                                                                left: {
                                                                    type: 'px',
                                                                    value: 0
                                                                },
                                                                right: {
                                                                    type: 'percentage',
                                                                    value: 0
                                                                },
                                                                top: {
                                                                    type: 'px',
                                                                    value: 0
                                                                },
                                                                bottom: {
                                                                    type: 'px',
                                                                    value: 0
                                                                }
                                                            }
                                                        },
                                                        type: 'SingleLayoutData'
                                                    },
                                                    style: {
                                                        type: 'ComponentStyle',
                                                        style: {
                                                            properties: {
                                                                textDecoration: 'none',
                                                                color: '#333853',
                                                                fontFamily:
                                                                    'wfont_1f4e5a_79bca1b6131343a3ac863df1ad352a95,wf_79bca1b6131343a3ac863df1a,orig_wix_madefor_text_regular',
                                                                lineHeight: '1.5em',
                                                                fontSize: '16px'
                                                            },
                                                            propertiesSource: {
                                                                textDecoration: 'value',
                                                                color: 'value',
                                                                fontFamily: 'value',
                                                                lineHeight: 'value',
                                                                fontSize: 'value'
                                                            },
                                                            groups: {}
                                                        },
                                                        componentClassName: 'wysiwyg.viewer.components.WRichText',
                                                        pageId: '',
                                                        compId: '',
                                                        styleType: 'custom',
                                                        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin'
                                                    }
                                                }
                                            ],
                                            naming: {
                                                name: 'TitleContainer',
                                                type: 'naming'
                                            },
                                            layouts: {
                                                containerLayout: {
                                                    type: 'StackContainerLayout',
                                                    direction: 'column'
                                                },
                                                componentLayout: {
                                                    type: 'ComponentLayout',
                                                    width: {
                                                        type: 'percentage',
                                                        value: 100
                                                    },
                                                    height: {
                                                        type: 'maxContent'
                                                    },
                                                    hidden: false
                                                },
                                                itemLayout: {
                                                    alignSelf: 'start',
                                                    margins: {
                                                        left: {
                                                            type: 'percentage',
                                                            value: 0
                                                        },
                                                        right: {
                                                            type: 'percentage',
                                                            value: 0
                                                        },
                                                        top: {
                                                            type: 'px',
                                                            value: 0
                                                        },
                                                        bottom: {
                                                            type: 'px',
                                                            value: 0
                                                        }
                                                    },
                                                    gridArea: {
                                                        rowStart: 1,
                                                        rowEnd: 2,
                                                        columnStart: 1,
                                                        columnEnd: 2
                                                    },
                                                    justifySelf: 'end',
                                                    type: 'GridItemLayout'
                                                },
                                                type: 'SingleLayoutData'
                                            },
                                            style: {
                                                type: 'ComponentStyle',
                                                style: {
                                                    properties: {
                                                        shd: '0px 0px 0px 0px rgba(0,0,0,0.6)',
                                                        rd: '0px',
                                                        'alpha-brd': '0',
                                                        'alpha-bg': '0',
                                                        bg: '#FFFFFF',
                                                        brw: '0px',
                                                        'boxShadowToggleOn-shd': 'false'
                                                    },
                                                    propertiesSource: {
                                                        shd: 'value',
                                                        rd: 'value',
                                                        'alpha-brd': 'value',
                                                        'alpha-bg': 'value',
                                                        bg: 'value',
                                                        brw: 'value',
                                                        'boxShadowToggleOn-shd': 'value'
                                                    },
                                                    groups: {}
                                                },
                                                componentClassName: 'mobile.core.components.Container',
                                                pageId: '',
                                                compId: '',
                                                styleType: 'custom',
                                                skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin'
                                            }
                                        },
                                        {
                                            type: 'Container',
                                            skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
                                            componentType: 'mobile.core.components.Container',
                                            layout: {
                                                x: 0,
                                                y: 0,
                                                fixedPosition: false,
                                                width: 160,
                                                height: 90,
                                                scale: 1,
                                                rotationInDegrees: 0
                                            },
                                            components: [
                                                {
                                                    type: 'Component',
                                                    skin: 'wixui.skins.Skinless',
                                                    componentType: 'wixui.StylableButton',
                                                    layout: {
                                                        x: 0,
                                                        y: 0,
                                                        fixedPosition: false,
                                                        width: 160,
                                                        height: 90,
                                                        scale: 1,
                                                        rotationInDegrees: 0
                                                    },
                                                    data: {
                                                        type: 'StylableButton',
                                                        label: 'Main Action',
                                                        svgId: '11062b_da022a6235f14308ba1a1bbc9b138fa2.svg'
                                                    },
                                                    props: {
                                                        type: 'StylableButtonProperties',
                                                        isMaxContent: true,
                                                        isWrapText: false,
                                                        iconCollapsed: false
                                                    },
                                                    layouts: {
                                                        containerLayout: {},
                                                        componentLayout: {
                                                            minHeight: {
                                                                type: 'px',
                                                                value: 0
                                                            },
                                                            hidden: false,
                                                            height: {
                                                                type: 'auto'
                                                            },
                                                            type: 'ComponentLayout',
                                                            width: {
                                                                type: 'px',
                                                                value: 101.21875
                                                            }
                                                        },
                                                        itemLayout: {
                                                            alignSelf: 'start',
                                                            margins: {
                                                                left: {
                                                                    type: 'percentage',
                                                                    value: 0
                                                                },
                                                                right: {
                                                                    type: 'percentage',
                                                                    value: 0
                                                                },
                                                                top: {
                                                                    type: 'px',
                                                                    value: 0
                                                                },
                                                                bottom: {
                                                                    type: 'px',
                                                                    value: 0
                                                                }
                                                            },
                                                            gridArea: {
                                                                rowStart: 1,
                                                                rowEnd: 2,
                                                                columnStart: 2,
                                                                columnEnd: 3
                                                            },
                                                            justifySelf: 'end',
                                                            type: 'GridItemLayout'
                                                        },
                                                        type: 'SingleLayoutData'
                                                    },
                                                    style: {
                                                        type: 'ComponentStyle',
                                                        style: {
                                                            properties: {
                                                                '$st-css':
                                                                    ":import{-st-from: 'wix-ui-santa/index.st.css';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;background: #116DFF;border-radius: 18px;border: 1px solid #116DFF;padding-top: 5px;padding-bottom: 5px;padding-left: 23px;padding-right: 23px}.root:hover {\n    background: #0F62E6;\n    border: 1px solid #0F62E6;\n}.root:hover::icon {\n    transform: rotate(2deg);\n    fill: rgba(255,255,255,0.7);}.root:hover::label {\n    text-shadow: 1px 0 rgba(255,255,255,0), -1px 0 rgba(255,255,255,0), 0 1px rgba(255,255,255,0), 0 -1px rgba(255,255,255,0);\n    color: #FFFFFF;\n}.root:disabled{\n    background: value(site_1_3)}.root:disabled::label{\n    color: value(site_1_1)}.root:disabled::icon{\n    fill: value(site_1_1)}.root::container{transition: inherit;flex-direction: row-reverse;justify-content: center;align-items: center}.root::label{transition: inherit;margin: 0px 0px 0px 4px;letter-spacing: 0em;text-align: center;text-shadow: 1px 0 rgba(255,255,255,0), -1px 0 rgba(255,255,255,0), 0 1px rgba(255,255,255,0), 0 -1px rgba(255,255,255,0);background-color: rgba(255,255,255,0);margin-left: 2px;display: initial;-st-mixin: font_8;font-family: wfont_1f4e5a_4158a6f551d840f48e881c1db6eecae6,wf_4158a6f551d840f48e881c1db,orig_wix_madefor_text_medium;font-size: 16px;line-height: 1.5em;color: #FFFFFF}.root::icon{transition: inherit;margin: 0px 4px 0px 0px;fill: value(site_1_1);margin-right: 2px;width: 23px;height: 23px;display: none}"
                                                            },
                                                            propertiesSource: {
                                                                '$st-css': 'value'
                                                            }
                                                        },
                                                        componentClassName: 'wixui.StylableButton',
                                                        pageId: '',
                                                        compId: '',
                                                        styleType: 'custom',
                                                        skin: 'wixui.skins.Skinless'
                                                    }
                                                },
                                                {
                                                    type: 'Component',
                                                    skin: 'wixui.skins.Skinless',
                                                    componentType: 'wixui.StylableButton',
                                                    layout: {
                                                        x: 0,
                                                        y: 0,
                                                        fixedPosition: false,
                                                        width: 160,
                                                        height: 90,
                                                        scale: 1,
                                                        rotationInDegrees: 0
                                                    },
                                                    data: {
                                                        type: 'StylableButton',
                                                        label: 'Secondary Action',
                                                        svgId: '11062b_da022a6235f14308ba1a1bbc9b138fa2.svg'
                                                    },
                                                    props: {
                                                        type: 'StylableButtonProperties',
                                                        isMaxContent: true,
                                                        isWrapText: false,
                                                        iconCollapsed: false
                                                    },
                                                    layouts: {
                                                        containerLayout: {},
                                                        componentLayout: {
                                                            minHeight: {
                                                                type: 'px',
                                                                value: 36
                                                            },
                                                            hidden: false,
                                                            height: {
                                                                type: 'auto'
                                                            },
                                                            type: 'ComponentLayout',
                                                            width: {
                                                                type: 'px',
                                                                value: 101.21875
                                                            }
                                                        },
                                                        itemLayout: {
                                                            alignSelf: 'end',
                                                            margins: {
                                                                left: {
                                                                    type: 'percentage',
                                                                    value: 0
                                                                },
                                                                right: {
                                                                    type: 'percentage',
                                                                    value: 0
                                                                },
                                                                top: {
                                                                    type: 'px',
                                                                    value: 0
                                                                },
                                                                bottom: {
                                                                    type: 'px',
                                                                    value: 0
                                                                }
                                                            },
                                                            gridArea: {
                                                                rowStart: 1,
                                                                rowEnd: 2,
                                                                columnStart: 1,
                                                                columnEnd: 2
                                                            },
                                                            justifySelf: 'start',
                                                            type: 'GridItemLayout'
                                                        },
                                                        type: 'SingleLayoutData'
                                                    },
                                                    style: {
                                                        type: 'ComponentStyle',
                                                        style: {
                                                            properties: {
                                                                '$st-css':
                                                                    ":import{-st-from: 'wix-ui-santa/index.st.css';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 18px;padding-top: 5px;padding-bottom: 5px;padding-left: 23px;padding-right: 23px;background: value(site_1_1);border: 1px solid #D6E6FE}.root:hover {\n    background: #0F62E6;\n    border: 1px solid #0F62E6;\n}.root:hover::icon {\n    transform: rotate(2deg);\n    fill: rgba(255,255,255,0.7);}.root:hover::label {\n    text-shadow: 1px 0 rgba(255,255,255,0), -1px 0 rgba(255,255,255,0), 0 1px rgba(255,255,255,0), 0 -1px rgba(255,255,255,0);\n    color: #FFFFFF;\n}.root:disabled{\n    background: value(site_1_3)}.root:disabled::label{\n    color: value(site_1_1)}.root:disabled::icon{\n    fill: value(site_1_1)}.root::container{transition: inherit;flex-direction: row-reverse;justify-content: center;align-items: center}.root::label{transition: inherit;margin: 0px 0px 0px 4px;letter-spacing: 0em;text-align: center;text-shadow: 1px 0 rgba(255,255,255,0), -1px 0 rgba(255,255,255,0), 0 1px rgba(255,255,255,0), 0 -1px rgba(255,255,255,0);background-color: rgba(255,255,255,0);margin-left: 2px;display: initial;-st-mixin: font_8;font-family: wfont_1f4e5a_4158a6f551d840f48e881c1db6eecae6,wf_4158a6f551d840f48e881c1db,orig_wix_madefor_text_medium;font-size: 16px;line-height: 1.5em;color: #116DFF}.root::icon{transition: inherit;margin: 0px 4px 0px 0px;fill: value(site_1_1);margin-right: 2px;width: 23px;height: 23px;display: none}"
                                                            },
                                                            propertiesSource: {
                                                                '$st-css': 'value'
                                                            }
                                                        },
                                                        componentClassName: 'wixui.StylableButton',
                                                        pageId: '',
                                                        compId: '',
                                                        styleType: 'custom',
                                                        skin: 'wixui.skins.Skinless'
                                                    }
                                                }
                                            ],
                                            naming: {
                                                name: 'ActionsContainer',
                                                type: 'naming'
                                            },
                                            layouts: {
                                                containerLayout: {
                                                    type: 'GridContainerLayout',
                                                    rows: [
                                                        {
                                                            type: 'auto'
                                                        }
                                                    ],
                                                    columns: [
                                                        {
                                                            type: 'auto'
                                                        },
                                                        {
                                                            type: 'auto'
                                                        }
                                                    ],
                                                    columnGap: {
                                                        type: 'px',
                                                        value: 12
                                                    }
                                                },
                                                componentLayout: {
                                                    minHeight: {
                                                        type: 'px',
                                                        value: 0
                                                    },
                                                    hidden: false,
                                                    height: {
                                                        type: 'px',
                                                        value: 36
                                                    },
                                                    type: 'ComponentLayout',
                                                    width: {
                                                        type: 'percentage',
                                                        value: 99.9906704490376
                                                    }
                                                },
                                                itemLayout: {
                                                    alignSelf: 'start',
                                                    margins: {
                                                        left: {
                                                            type: 'percentage',
                                                            value: 0
                                                        },
                                                        right: {
                                                            type: 'percentage',
                                                            value: 0
                                                        },
                                                        top: {
                                                            type: 'px',
                                                            value: 0
                                                        },
                                                        bottom: {
                                                            type: 'px',
                                                            value: 0
                                                        }
                                                    },
                                                    gridArea: {
                                                        rowStart: 1,
                                                        rowEnd: 2,
                                                        columnStart: 2,
                                                        columnEnd: 3
                                                    },
                                                    justifySelf: 'stretch',
                                                    position: 'relative',
                                                    type: 'GridItemLayout',
                                                    top: {
                                                        type: 'px',
                                                        value: 0
                                                    }
                                                },
                                                type: 'SingleLayoutData'
                                            },
                                            style: {
                                                type: 'ComponentStyle',
                                                style: {
                                                    propertiesOverride: {},
                                                    properties: {
                                                        shd: '0px 0px 0px 0px rgba(0,0,0,0.6)',
                                                        rd: '0px',
                                                        'alpha-brd': '0',
                                                        'alpha-bg': '0',
                                                        bg: 'color_11',
                                                        brw: '0px',
                                                        'boxShadowToggleOn-shd': 'false'
                                                    },
                                                    propertiesSource: {
                                                        shd: 'value',
                                                        rd: 'value',
                                                        'alpha-brd': 'value',
                                                        'alpha-bg': 'value',
                                                        bg: 'theme',
                                                        brw: 'value',
                                                        'boxShadowToggleOn-shd': 'value'
                                                    },
                                                    groups: {}
                                                },
                                                componentClassName: 'mobile.core.components.Container',
                                                pageId: '',
                                                compId: '',
                                                styleType: 'custom',
                                                skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin'
                                            }
                                        }
                                    ],
                                    naming: {
                                        name: 'PageHeader',
                                        type: 'naming'
                                    },
                                    layouts: {
                                        containerLayout: {
                                            columnGap: {
                                                type: 'px',
                                                value: 24
                                            },
                                            columns: [
                                                {
                                                    type: 'fr',
                                                    value: 0.9999611959410957
                                                },
                                                {
                                                    type: 'auto'
                                                }
                                            ],
                                            padding: {
                                                top: {
                                                    type: 'px',
                                                    value: 6
                                                },
                                                bottom: {
                                                    type: 'px',
                                                    value: 6
                                                }
                                            },
                                            type: 'GridContainerLayout',
                                            rows: [
                                                {
                                                    type: 'auto'
                                                }
                                            ]
                                        },
                                        componentLayout: {
                                            minHeight: {
                                                type: 'px',
                                                value: 0
                                            },
                                            hidden: false,
                                            height: {
                                                type: 'auto'
                                            },
                                            minWidth: {
                                                type: 'px',
                                                value: 0
                                            },
                                            type: 'ComponentLayout',
                                            width: {
                                                type: 'auto'
                                            }
                                        },
                                        itemLayout: {
                                            alignSelf: 'stretch',
                                            margins: {
                                                left: {
                                                    type: 'px',
                                                    value: 0
                                                },
                                                right: {
                                                    type: 'px',
                                                    value: 0
                                                },
                                                top: {
                                                    type: 'px',
                                                    value: 18
                                                },
                                                bottom: {
                                                    type: 'px',
                                                    value: 0
                                                }
                                            },
                                            gridArea: {
                                                rowStart: 1,
                                                rowEnd: 2,
                                                columnStart: 2,
                                                columnEnd: 3
                                            },
                                            justifySelf: 'stretch',
                                            position: 'sticky',
                                            type: 'GridItemLayout',
                                            top: {
                                                type: 'px',
                                                value: 0
                                            }
                                        },
                                        type: 'SingleLayoutData'
                                    },
                                    style: {
                                        type: 'ComponentStyle',
                                        style: {
                                            propertiesOverride: {},
                                            properties: {
                                                shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
                                                rd: '0px',
                                                'alpha-brd': '1',
                                                'alpha-bg': '1',
                                                bg: '#ECEFF3',
                                                brw: '0px',
                                                brd: '#324158',
                                                'boxShadowToggleOn-shd': 'false'
                                            },
                                            propertiesSource: {
                                                shd: 'value',
                                                rd: 'value',
                                                'alpha-brd': 'value',
                                                'alpha-bg': 'value',
                                                bg: 'value',
                                                brw: 'value',
                                                brd: 'value',
                                                'boxShadowToggleOn-shd': 'value'
                                            },
                                            groups: {}
                                        },
                                        componentClassName: 'mobile.core.components.Container',
                                        pageId: '',
                                        compId: '',
                                        styleType: 'custom',
                                        skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin'
                                    }
                                }
                            ],
                            naming: {
                                name: 'Page',
                                type: 'naming'
                            },
                            layouts: {
                                containerLayout: {
                                    rowGap: {
                                        type: 'px',
                                        value: 18
                                    },
                                    columns: [
                                        {
                                            type: 'MinMaxSize',
                                            min: {
                                                type: 'px',
                                                value: 48
                                            },
                                            max: {
                                                type: 'fr',
                                                value: 1
                                            }
                                        },
                                        {
                                            type: 'MinMaxSize',
                                            min: {
                                                type: 'px',
                                                value: 864
                                            },
                                            max: {
                                                type: 'px',
                                                value: 1248
                                            }
                                        },
                                        {
                                            type: 'MinMaxSize',
                                            min: {
                                                type: 'px',
                                                value: 48
                                            },
                                            max: {
                                                type: 'fr',
                                                value: 1
                                            }
                                        }
                                    ],
                                    padding: {
                                        bottom: {
                                            type: 'px',
                                            value: 48
                                        }
                                    },
                                    type: 'GridContainerLayout',
                                    rows: [
                                        {
                                            type: 'minContent'
                                        },
                                        {
                                            type: 'fr',
                                            value: 1
                                        }
                                    ]
                                },
                                componentLayout: {
                                    minHeight: {
                                        type: 'px',
                                        value: 0
                                    },
                                    hidden: false,
                                    height: {
                                        type: 'auto'
                                    },
                                    minWidth: {
                                        type: 'px',
                                        value: 0
                                    },
                                    type: 'ComponentLayout',
                                    width: {
                                        type: 'auto'
                                    }
                                },
                                itemLayout: {
                                    alignSelf: 'stretch',
                                    margins: {
                                        left: {
                                            type: 'px',
                                            value: 0
                                        },
                                        right: {
                                            type: 'px',
                                            value: 0
                                        },
                                        top: {
                                            type: 'px',
                                            value: 0
                                        },
                                        bottom: {
                                            type: 'px',
                                            value: 0
                                        }
                                    },
                                    gridArea: {
                                        rowStart: 1,
                                        rowEnd: 2,
                                        columnStart: 1,
                                        columnEnd: 2
                                    },
                                    justifySelf: 'stretch',
                                    position: 'relative',
                                    type: 'GridItemLayout',
                                    top: {
                                        type: 'px',
                                        value: 0
                                    }
                                },
                                type: 'SingleLayoutData'
                            },
                            style: {
                                type: 'ComponentStyle',
                                style: {
                                    propertiesOverride: {},
                                    properties: {
                                        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
                                        rd: '0px',
                                        'alpha-brd': '1',
                                        'alpha-bg': '1',
                                        bg: '#ECEFF3',
                                        brw: '0',
                                        brd: 'color_13',
                                        'boxShadowToggleOn-shd': 'false'
                                    },
                                    propertiesSource: {
                                        shd: 'value',
                                        rd: 'value',
                                        'alpha-brd': 'value',
                                        'alpha-bg': 'value',
                                        bg: 'value',
                                        brw: 'value',
                                        brd: 'theme',
                                        'boxShadowToggleOn-shd': 'value'
                                    },
                                    groups: {}
                                },
                                componentClassName: 'mobile.core.components.Container',
                                pageId: '',
                                compId: '',
                                styleType: 'custom',
                                skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin'
                            }
                        }
                    ],
                    naming: {
                        name: 'box67',
                        type: 'naming'
                    },
                    layouts: {
                        containerLayout: {
                            columns: [
                                {
                                    type: 'fr',
                                    value: 1
                                }
                            ],
                            rows: [
                                {
                                    type: 'fr',
                                    value: 1
                                }
                            ],
                            type: 'GridContainerLayout'
                        },
                        componentLayout: {
                            type: 'ComponentLayout',
                            height: {
                                type: 'auto'
                            },
                            minHeight: {
                                type: 'px',
                                value: 300
                            },
                            width: {
                                type: 'percentage',
                                value: 100
                            }
                        },
                        itemLayout: {
                            margins: {
                                left: {
                                    type: 'auto'
                                }
                            },
                            gridArea: {
                                rowStart: 1,
                                rowEnd: 2,
                                columnStart: 1,
                                columnEnd: 2
                            },
                            justifySelf: 'center',
                            position: 'relative',
                            type: 'GridItemLayout'
                        },
                        type: 'SingleLayoutData'
                    },
                    style: {
                        type: 'ComponentStyle',
                        style: {
                            propertiesOverride: {},
                            properties: {
                                shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
                                rd: '0px',
                                'alpha-bg': '0',
                                bg: 'color_31',
                                alphaBg: '1',
                                brw: '0px',
                                brd: '#e8e6e6',
                                'boxShadowToggleOn-shd': 'false',
                                alphaBrd: '0.18'
                            },
                            propertiesSource: {
                                shd: 'value',
                                rd: 'value',
                                'alpha-bg': 'value',
                                bg: 'theme',
                                alphaBg: 'value',
                                brw: 'value',
                                brd: 'value',
                                'boxShadowToggleOn-shd': 'value',
                                alphaBrd: 'value'
                            },
                            groups: {}
                        },
                        componentClassName: 'mobile.core.components.Container',
                        pageId: '',
                        compId: '',
                        styleType: 'custom',
                        skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin'
                    }
                },
                {
                    type: 'Container',
                    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
                    componentType: 'mobile.core.components.Container',
                    layout: {
                        x: 0,
                        y: 0,
                        fixedPosition: false,
                        width: 160,
                        height: 90,
                        scale: 1,
                        rotationInDegrees: 0
                    },
                    components: [
                        {
                            type: 'Container',
                            skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
                            componentType: 'mobile.core.components.Container',
                            layout: {
                                x: 0,
                                y: 0,
                                fixedPosition: false,
                                width: 160,
                                height: 90,
                                scale: 1,
                                rotationInDegrees: 0
                            },
                            components: [
                                {
                                    type: 'Container',
                                    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
                                    componentType: 'mobile.core.components.Container',
                                    layout: {
                                        x: 0,
                                        y: 0,
                                        fixedPosition: false,
                                        width: 160,
                                        height: 90,
                                        scale: 1,
                                        rotationInDegrees: 0
                                    },
                                    components: [
                                        {
                                            type: 'Container',
                                            skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
                                            componentType: 'mobile.core.components.Container',
                                            layout: {
                                                x: 0,
                                                y: 0,
                                                fixedPosition: false,
                                                width: 160,
                                                height: 90,
                                                scale: 1,
                                                rotationInDegrees: 0
                                            },
                                            components: [
                                                {
                                                    type: 'Container',
                                                    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
                                                    componentType: 'mobile.core.components.Container',
                                                    layout: {
                                                        x: 0,
                                                        y: 0,
                                                        fixedPosition: false,
                                                        width: 160,
                                                        height: 90,
                                                        scale: 1,
                                                        rotationInDegrees: 0
                                                    },
                                                    components: [],
                                                    naming: {
                                                        name: 'CardContent',
                                                        type: 'naming'
                                                    },
                                                    layouts: {
                                                        componentLayout: {
                                                            minHeight: {
                                                                type: 'px',
                                                                value: 0
                                                            },
                                                            hidden: false,
                                                            height: {
                                                                type: 'auto'
                                                            },
                                                            minWidth: {
                                                                type: 'px',
                                                                value: 0
                                                            },
                                                            type: 'ComponentLayout',
                                                            width: {
                                                                type: 'auto'
                                                            }
                                                        },
                                                        itemLayout: {
                                                            alignSelf: 'stretch',
                                                            margins: {
                                                                left: {
                                                                    type: 'px',
                                                                    value: 0
                                                                },
                                                                right: {
                                                                    type: 'px',
                                                                    value: 0
                                                                },
                                                                top: {
                                                                    type: 'px',
                                                                    value: 0
                                                                },
                                                                bottom: {
                                                                    type: 'px',
                                                                    value: 0
                                                                }
                                                            },
                                                            gridArea: {
                                                                rowStart: 3,
                                                                rowEnd: 4,
                                                                columnStart: 1,
                                                                columnEnd: 2
                                                            },
                                                            justifySelf: 'stretch',
                                                            type: 'GridItemLayout'
                                                        },
                                                        containerLayout: {
                                                            columnGap: {
                                                                type: 'px',
                                                                value: 24
                                                            },
                                                            rowGap: {
                                                                type: 'px',
                                                                value: 24
                                                            },
                                                            columns: [
                                                                {
                                                                    type: 'fr',
                                                                    value: 2
                                                                },
                                                                {
                                                                    type: 'fr',
                                                                    value: 1
                                                                }
                                                            ],
                                                            padding: {
                                                                top: {
                                                                    type: 'px',
                                                                    value: 24
                                                                },
                                                                right: {
                                                                    type: 'px',
                                                                    value: 24
                                                                },
                                                                bottom: {
                                                                    type: 'px',
                                                                    value: 24
                                                                },
                                                                left: {
                                                                    type: 'px',
                                                                    value: 24
                                                                }
                                                            },
                                                            type: 'GridContainerLayout',
                                                            rows: [
                                                                {
                                                                    type: 'auto'
                                                                }
                                                            ]
                                                        },
                                                        type: 'SingleLayoutData'
                                                    },
                                                    style: {
                                                        type: 'ComponentStyle',
                                                        style: {
                                                            propertiesOverride: {},
                                                            properties: {
                                                                shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
                                                                rd: '0px 0px 8px 8px',
                                                                'alpha-brd': '1',
                                                                'alpha-bg': '1',
                                                                bg: '#FFFFFF',
                                                                brw: '0px',
                                                                brd: '#324158',
                                                                'boxShadowToggleOn-shd': 'false'
                                                            },
                                                            propertiesSource: {
                                                                shd: 'value',
                                                                rd: 'value',
                                                                'alpha-brd': 'value',
                                                                'alpha-bg': 'value',
                                                                bg: 'value',
                                                                brw: 'value',
                                                                brd: 'value',
                                                                'boxShadowToggleOn-shd': 'value'
                                                            },
                                                            groups: {}
                                                        },
                                                        componentClassName: 'mobile.core.components.Container',
                                                        pageId: '',
                                                        compId: '',
                                                        styleType: 'custom',
                                                        skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin'
                                                    }
                                                },
                                                {
                                                    type: 'Component',
                                                    skin: 'wysiwyg.viewer.skins.line.SolidLine',
                                                    componentType: 'wysiwyg.viewer.components.FiveGridLine',
                                                    layout: {
                                                        x: 0,
                                                        y: 0,
                                                        fixedPosition: false,
                                                        width: 160,
                                                        height: 90,
                                                        scale: 1,
                                                        rotationInDegrees: 0
                                                    },
                                                    props: {
                                                        type: 'FiveGridLineProperties',
                                                        fullScreenModeOn: false
                                                    },
                                                    naming: {
                                                        name: 'Divider',
                                                        type: 'naming'
                                                    },
                                                    layouts: {
                                                        componentLayout: {
                                                            type: 'ComponentLayout',
                                                            width: {
                                                                type: 'percentage',
                                                                value: 100
                                                            },
                                                            height: {
                                                                type: 'px',
                                                                value: 5
                                                            },
                                                            hidden: false
                                                        },
                                                        itemLayout: {
                                                            alignSelf: 'start',
                                                            margins: {
                                                                left: {
                                                                    type: 'percentage',
                                                                    value: 0
                                                                },
                                                                right: {
                                                                    type: 'percentage',
                                                                    value: 0
                                                                },
                                                                top: {
                                                                    type: 'px',
                                                                    value: 0
                                                                },
                                                                bottom: {
                                                                    type: 'px',
                                                                    value: 0
                                                                }
                                                            },
                                                            gridArea: {
                                                                rowStart: 2,
                                                                rowEnd: 3,
                                                                columnStart: 1,
                                                                columnEnd: 2
                                                            },
                                                            justifySelf: 'end',
                                                            type: 'GridItemLayout'
                                                        },
                                                        containerLayout: {},
                                                        type: 'SingleLayoutData'
                                                    },
                                                    style: {
                                                        type: 'ComponentStyle',
                                                        style: {
                                                            propertiesOverride: {},
                                                            properties: {
                                                                lnw: '1',
                                                                brd: '#DFE5EB',
                                                                'alpha-brd': '1'
                                                            },
                                                            propertiesSource: {
                                                                lnw: 'value',
                                                                brd: 'value',
                                                                'alpha-brd': 'value'
                                                            },
                                                            groups: {}
                                                        },
                                                        componentClassName: 'wysiwyg.viewer.components.FiveGridLine',
                                                        pageId: '',
                                                        compId: '',
                                                        styleType: 'custom',
                                                        skin: 'wysiwyg.viewer.skins.line.SolidLine'
                                                    }
                                                },
                                                {
                                                    type: 'Container',
                                                    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
                                                    componentType: 'mobile.core.components.Container',
                                                    layout: {
                                                        x: 0,
                                                        y: 0,
                                                        fixedPosition: false,
                                                        width: 160,
                                                        height: 90,
                                                        scale: 1,
                                                        rotationInDegrees: 0
                                                    },
                                                    components: [
                                                        {
                                                            type: 'Component',
                                                            skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
                                                            componentType: 'wysiwyg.viewer.components.WRichText',
                                                            layout: {
                                                                x: 0,
                                                                y: 0,
                                                                fixedPosition: false,
                                                                width: 160,
                                                                height: 90,
                                                                scale: 1,
                                                                rotationInDegrees: 0
                                                            },
                                                            data: {
                                                                type: 'StyledText',
                                                                text: '<h3 class="font_3"><span>Card title</span></h3>',
                                                                stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                                                linkList: []
                                                            },
                                                            props: {
                                                                type: 'WRichTextProperties',
                                                                brightness: 1,
                                                                packed: true,
                                                                verticalText: false
                                                            },
                                                            layouts: {
                                                                componentLayout: {
                                                                    type: 'ComponentLayout',
                                                                    width: {
                                                                        type: 'percentage',
                                                                        value: 100
                                                                    },
                                                                    height: {
                                                                        type: 'auto'
                                                                    },
                                                                    hidden: false
                                                                },
                                                                itemLayout: {
                                                                    type: 'StackItemLayout',
                                                                    order: 1,
                                                                    alignSelf: 'center',
                                                                    margins: {
                                                                        left: {
                                                                            type: 'percentage',
                                                                            value: 0
                                                                        },
                                                                        right: {
                                                                            type: 'percentage',
                                                                            value: 0
                                                                        },
                                                                        top: {
                                                                            type: 'px',
                                                                            value: 0
                                                                        },
                                                                        bottom: {
                                                                            type: 'px',
                                                                            value: 0
                                                                        }
                                                                    }
                                                                },
                                                                containerLayout: {},
                                                                type: 'SingleLayoutData'
                                                            },
                                                            style: {
                                                                type: 'ComponentStyle',
                                                                style: {
                                                                    properties: {
                                                                        textDecoration: 'none',
                                                                        textAlign: 'left',
                                                                        fontSize: '18px',
                                                                        fontFamily:
                                                                            'madefor-text-bold,helveticaneuew01-65medi,helveticaneuew02-65medi,helveticaneuew10-65medi,sans-serif'
                                                                    },
                                                                    propertiesSource: {
                                                                        textDecoration: 'value',
                                                                        textAlign: 'value',
                                                                        fontSize: 'value',
                                                                        fontFamily: 'value'
                                                                    },
                                                                    groups: {}
                                                                },
                                                                componentClassName:
                                                                    'wysiwyg.viewer.components.WRichText',
                                                                pageId: '',
                                                                compId: '',
                                                                styleType: 'custom',
                                                                skin: 'wysiwyg.viewer.skins.WRichTextNewSkin'
                                                            }
                                                        },
                                                        {
                                                            type: 'Component',
                                                            skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
                                                            componentType: 'wysiwyg.viewer.components.WRichText',
                                                            layout: {
                                                                x: 0,
                                                                y: 0,
                                                                fixedPosition: false,
                                                                width: 160,
                                                                height: 90,
                                                                scale: 1,
                                                                rotationInDegrees: 0
                                                            },
                                                            data: {
                                                                type: 'StyledText',
                                                                text: '<p class="font_7">Subtitle</p>',
                                                                stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                                                linkList: []
                                                            },
                                                            props: {
                                                                type: 'WRichTextProperties',
                                                                brightness: 1,
                                                                packed: true,
                                                                verticalText: false
                                                            },
                                                            layouts: {
                                                                componentLayout: {
                                                                    type: 'ComponentLayout',
                                                                    width: {
                                                                        type: 'percentage',
                                                                        value: 100
                                                                    },
                                                                    height: {
                                                                        type: 'auto'
                                                                    },
                                                                    hidden: false
                                                                },
                                                                itemLayout: {
                                                                    type: 'StackItemLayout',
                                                                    order: 2,
                                                                    alignSelf: 'center',
                                                                    margins: {
                                                                        left: {
                                                                            type: 'percentage',
                                                                            value: 0
                                                                        },
                                                                        right: {
                                                                            type: 'percentage',
                                                                            value: 0
                                                                        },
                                                                        top: {
                                                                            type: 'px',
                                                                            value: 0
                                                                        },
                                                                        bottom: {
                                                                            type: 'px',
                                                                            value: 0
                                                                        }
                                                                    }
                                                                },
                                                                containerLayout: {},
                                                                type: 'SingleLayoutData'
                                                            },
                                                            style: {
                                                                type: 'ComponentStyle',
                                                                style: {
                                                                    properties: {
                                                                        textDecoration: 'none',
                                                                        color: '#333853',
                                                                        textAlign: 'left',
                                                                        fontFamily:
                                                                            'madefor-text,helveticaneuew01-45ligh,helveticaneuew02-45ligh,helveticaneuew10-45ligh,sans-serif',
                                                                        lineHeight: '1.5em',
                                                                        fontSize: '16px'
                                                                    },
                                                                    propertiesSource: {
                                                                        textDecoration: 'value',
                                                                        color: 'value',
                                                                        textAlign: 'value',
                                                                        fontFamily: 'value',
                                                                        lineHeight: 'value',
                                                                        fontSize: 'value'
                                                                    },
                                                                    groups: {}
                                                                },
                                                                componentClassName:
                                                                    'wysiwyg.viewer.components.WRichText',
                                                                pageId: '',
                                                                compId: '',
                                                                styleType: 'custom',
                                                                skin: 'wysiwyg.viewer.skins.WRichTextNewSkin'
                                                            }
                                                        }
                                                    ],
                                                    naming: {
                                                        name: 'CardHeader',
                                                        type: 'naming'
                                                    },
                                                    layouts: {
                                                        componentLayout: {
                                                            type: 'ComponentLayout',
                                                            width: {
                                                                type: 'percentage',
                                                                value: 100
                                                            },
                                                            height: {
                                                                type: 'maxContent'
                                                            },
                                                            hidden: false
                                                        },
                                                        itemLayout: {
                                                            alignSelf: 'start',
                                                            margins: {
                                                                left: {
                                                                    type: 'percentage',
                                                                    value: 0
                                                                },
                                                                right: {
                                                                    type: 'percentage',
                                                                    value: 0
                                                                },
                                                                top: {
                                                                    type: 'percentage',
                                                                    value: 0
                                                                },
                                                                bottom: {
                                                                    type: 'percentage',
                                                                    value: 0
                                                                }
                                                            },
                                                            gridArea: {
                                                                rowStart: 1,
                                                                rowEnd: 2,
                                                                columnStart: 1,
                                                                columnEnd: 2
                                                            },
                                                            justifySelf: 'center',
                                                            type: 'GridItemLayout'
                                                        },
                                                        containerLayout: {
                                                            type: 'StackContainerLayout',
                                                            direction: 'column',
                                                            padding: {
                                                                top: {
                                                                    type: 'px',
                                                                    value: 18
                                                                },
                                                                right: {
                                                                    type: 'px',
                                                                    value: 24
                                                                },
                                                                bottom: {
                                                                    type: 'px',
                                                                    value: 18
                                                                },
                                                                left: {
                                                                    type: 'px',
                                                                    value: 24
                                                                }
                                                            }
                                                        },
                                                        type: 'SingleLayoutData'
                                                    },
                                                    style: {
                                                        type: 'ComponentStyle',
                                                        style: {
                                                            properties: {
                                                                shd: '0px 0px 0px 0px rgba(0,0,0,0.6)',
                                                                rd: '0px',
                                                                'alpha-brd': '0',
                                                                'alpha-bg': '0',
                                                                bg: '#FFFFFF',
                                                                brw: '0px',
                                                                'boxShadowToggleOn-shd': 'false'
                                                            },
                                                            propertiesSource: {
                                                                shd: 'value',
                                                                rd: 'value',
                                                                'alpha-brd': 'value',
                                                                'alpha-bg': 'value',
                                                                bg: 'value',
                                                                brw: 'value',
                                                                'boxShadowToggleOn-shd': 'value'
                                                            },
                                                            groups: {}
                                                        },
                                                        componentClassName: 'mobile.core.components.Container',
                                                        pageId: '',
                                                        compId: '',
                                                        styleType: 'custom',
                                                        skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin'
                                                    }
                                                }
                                            ],
                                            naming: {
                                                name: 'Card',
                                                type: 'naming'
                                            },
                                            layouts: {
                                                componentLayout: {
                                                    type: 'ComponentLayout',
                                                    width: {
                                                        type: 'auto'
                                                    },
                                                    height: {
                                                        type: 'auto'
                                                    },
                                                    minWidth: {
                                                        type: 'px',
                                                        value: 0
                                                    },
                                                    minHeight: {
                                                        type: 'px',
                                                        value: 0
                                                    },
                                                    hidden: false
                                                },
                                                itemLayout: {
                                                    type: 'GridItemLayout',
                                                    gridArea: {
                                                        rowStart: 1,
                                                        rowEnd: 2,
                                                        columnStart: 1,
                                                        columnEnd: 2
                                                    },
                                                    margins: {
                                                        left: {
                                                            type: 'px',
                                                            value: 0
                                                        },
                                                        right: {
                                                            type: 'px',
                                                            value: 0
                                                        },
                                                        top: {
                                                            type: 'px',
                                                            value: 0
                                                        },
                                                        bottom: {
                                                            type: 'px',
                                                            value: 0
                                                        }
                                                    },
                                                    justifySelf: 'stretch',
                                                    alignSelf: 'stretch'
                                                },
                                                containerLayout: {
                                                    type: 'GridContainerLayout',
                                                    rows: [
                                                        {
                                                            type: 'minContent'
                                                        },
                                                        {
                                                            type: 'px',
                                                            value: 1
                                                        },
                                                        {
                                                            type: 'auto'
                                                        }
                                                    ],
                                                    columns: [
                                                        {
                                                            type: 'fr',
                                                            value: 1
                                                        }
                                                    ]
                                                },
                                                type: 'SingleLayoutData'
                                            },
                                            style: {
                                                type: 'ComponentStyle',
                                                style: {
                                                    propertiesOverride: {},
                                                    properties: {
                                                        shd: '0px 0px 0px 0px rgba(0,0,0,0.6)',
                                                        rd: '8px 8px 8px 8px',
                                                        'alpha-brd': '0',
                                                        'alpha-bg': '1',
                                                        bg: 'color_11',
                                                        brw: '0px',
                                                        'boxShadowToggleOn-shd': 'false'
                                                    },
                                                    propertiesSource: {
                                                        shd: 'value',
                                                        rd: 'value',
                                                        'alpha-brd': 'value',
                                                        'alpha-bg': 'value',
                                                        bg: 'theme',
                                                        brw: 'value',
                                                        'boxShadowToggleOn-shd': 'value'
                                                    },
                                                    groups: {}
                                                },
                                                componentClassName: 'mobile.core.components.Container',
                                                pageId: '',
                                                compId: '',
                                                styleType: 'custom',
                                                skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin'
                                            }
                                        }
                                    ],
                                    manifest: {
                                        type: 'Manifest',
                                        behavior: {
                                            preventHide: true
                                        }
                                    },
                                    naming: {
                                        name: 'PageContent',
                                        type: 'naming'
                                    },
                                    layouts: {
                                        containerLayout: {
                                            type: 'GridContainerLayout',
                                            rows: [
                                                {
                                                    type: 'fr',
                                                    value: 1
                                                }
                                            ],
                                            columns: [
                                                {
                                                    type: 'fr',
                                                    value: 1
                                                }
                                            ],
                                            padding: {
                                                left: {
                                                    type: 'px',
                                                    value: 0
                                                },
                                                right: {
                                                    type: 'px',
                                                    value: 0
                                                },
                                                top: {
                                                    type: 'px',
                                                    value: 0
                                                },
                                                bottom: {
                                                    type: 'px',
                                                    value: 0
                                                }
                                            }
                                        },
                                        componentLayout: {
                                            minHeight: {
                                                type: 'px',
                                                value: 0
                                            },
                                            hidden: false,
                                            height: {
                                                type: 'auto'
                                            },
                                            minWidth: {
                                                type: 'px',
                                                value: 0
                                            },
                                            type: 'ComponentLayout',
                                            width: {
                                                type: 'auto'
                                            }
                                        },
                                        itemLayout: {
                                            alignSelf: 'stretch',
                                            margins: {
                                                left: {
                                                    type: 'px',
                                                    value: 0
                                                },
                                                right: {
                                                    type: 'px',
                                                    value: 0
                                                },
                                                top: {
                                                    type: 'px',
                                                    value: 0
                                                },
                                                bottom: {
                                                    type: 'px',
                                                    value: 0
                                                }
                                            },
                                            gridArea: {
                                                rowStart: 2,
                                                rowEnd: 3,
                                                columnStart: 2,
                                                columnEnd: 3
                                            },
                                            justifySelf: 'stretch',
                                            type: 'GridItemLayout'
                                        },
                                        type: 'SingleLayoutData'
                                    },
                                    style: {
                                        type: 'ComponentStyle',
                                        style: {
                                            propertiesOverride: {},
                                            properties: {
                                                shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
                                                rd: '0px',
                                                'alpha-brd': '1',
                                                'alpha-bg': '0',
                                                bg: '#FFFFFF',
                                                brw: '0px',
                                                brd: '#324158',
                                                'boxShadowToggleOn-shd': 'false'
                                            },
                                            propertiesSource: {
                                                shd: 'value',
                                                rd: 'value',
                                                'alpha-brd': 'value',
                                                'alpha-bg': 'value',
                                                bg: 'value',
                                                brw: 'value',
                                                brd: 'value',
                                                'boxShadowToggleOn-shd': 'value'
                                            },
                                            groups: {}
                                        },
                                        componentClassName: 'mobile.core.components.Container',
                                        pageId: '',
                                        compId: '',
                                        styleType: 'custom',
                                        skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin'
                                    }
                                },
                                {
                                    type: 'Container',
                                    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
                                    componentType: 'mobile.core.components.Container',
                                    layout: {
                                        x: 0,
                                        y: 0,
                                        fixedPosition: false,
                                        width: 160,
                                        height: 90,
                                        scale: 1,
                                        rotationInDegrees: 0
                                    },
                                    components: [
                                        {
                                            type: 'Container',
                                            skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
                                            componentType: 'mobile.core.components.Container',
                                            layout: {
                                                x: 0,
                                                y: 0,
                                                fixedPosition: false,
                                                width: 160,
                                                height: 90,
                                                scale: 1,
                                                rotationInDegrees: 0
                                            },
                                            components: [
                                                {
                                                    type: 'Component',
                                                    skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
                                                    componentType: 'wysiwyg.viewer.components.WRichText',
                                                    layout: {
                                                        x: 0,
                                                        y: 0,
                                                        fixedPosition: false,
                                                        width: 160,
                                                        height: 90,
                                                        scale: 1,
                                                        rotationInDegrees: 0
                                                    },
                                                    data: {
                                                        type: 'StyledText',
                                                        text: '<h2 class="font_2">Page&nbsp;02</h2>',
                                                        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                                        linkList: []
                                                    },
                                                    props: {
                                                        type: 'WRichTextProperties',
                                                        brightness: 1,
                                                        packed: true,
                                                        verticalText: false
                                                    },
                                                    layouts: {
                                                        containerLayout: {},
                                                        componentLayout: {
                                                            height: {
                                                                type: 'auto'
                                                            },
                                                            width: {
                                                                type: 'percentage',
                                                                value: 100
                                                            },
                                                            type: 'ComponentLayout',
                                                            hidden: false
                                                        },
                                                        itemLayout: {
                                                            type: 'StackItemLayout',
                                                            order: 1,
                                                            alignSelf: 'center',
                                                            margins: {
                                                                left: {
                                                                    type: 'px',
                                                                    value: 0
                                                                },
                                                                right: {
                                                                    type: 'px',
                                                                    value: 0
                                                                },
                                                                top: {
                                                                    type: 'px',
                                                                    value: 0
                                                                },
                                                                bottom: {
                                                                    type: 'px',
                                                                    value: 0
                                                                }
                                                            }
                                                        },
                                                        type: 'SingleLayoutData'
                                                    },
                                                    style: {
                                                        type: 'ComponentStyle',
                                                        style: {
                                                            properties: {
                                                                textDecoration: 'none',
                                                                color: '#000624',
                                                                fontFamily:
                                                                    'wfont_1f4e5a_8631ad30ea9042b09e3563510c17dfa4,wf_8631ad30ea9042b09e3563510,orig_wix_madefor_text_bold',
                                                                lineHeight: '1.3em',
                                                                fontSize: '28px'
                                                            },
                                                            propertiesSource: {
                                                                textDecoration: 'value',
                                                                color: 'value',
                                                                fontFamily: 'value',
                                                                lineHeight: 'value',
                                                                fontSize: 'value'
                                                            },
                                                            groups: {}
                                                        },
                                                        componentClassName: 'wysiwyg.viewer.components.WRichText',
                                                        pageId: '',
                                                        compId: '',
                                                        styleType: 'custom',
                                                        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin'
                                                    }
                                                },
                                                {
                                                    type: 'Component',
                                                    skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
                                                    componentType: 'wysiwyg.viewer.components.WRichText',
                                                    layout: {
                                                        x: 0,
                                                        y: 0,
                                                        fixedPosition: false,
                                                        width: 160,
                                                        height: 90,
                                                        scale: 1,
                                                        rotationInDegrees: 0
                                                    },
                                                    data: {
                                                        type: 'StyledText',
                                                        text: '<p class="font_7"><span>Subtitle</span></p>',
                                                        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                                        linkList: []
                                                    },
                                                    props: {
                                                        type: 'WRichTextProperties',
                                                        brightness: 1,
                                                        packed: true,
                                                        verticalText: false
                                                    },
                                                    naming: {
                                                        name: 'Page Subtitle',
                                                        type: 'naming'
                                                    },
                                                    layouts: {
                                                        containerLayout: {},
                                                        componentLayout: {
                                                            height: {
                                                                type: 'auto'
                                                            },
                                                            width: {
                                                                type: 'percentage',
                                                                value: 100
                                                            },
                                                            type: 'ComponentLayout',
                                                            hidden: false
                                                        },
                                                        itemLayout: {
                                                            type: 'StackItemLayout',
                                                            order: 2,
                                                            alignSelf: 'center',
                                                            margins: {
                                                                left: {
                                                                    type: 'px',
                                                                    value: 0
                                                                },
                                                                right: {
                                                                    type: 'percentage',
                                                                    value: 0
                                                                },
                                                                top: {
                                                                    type: 'px',
                                                                    value: 0
                                                                },
                                                                bottom: {
                                                                    type: 'px',
                                                                    value: 0
                                                                }
                                                            }
                                                        },
                                                        type: 'SingleLayoutData'
                                                    },
                                                    style: {
                                                        type: 'ComponentStyle',
                                                        style: {
                                                            properties: {
                                                                textDecoration: 'none',
                                                                color: '#333853',
                                                                fontFamily:
                                                                    'wfont_1f4e5a_79bca1b6131343a3ac863df1ad352a95,wf_79bca1b6131343a3ac863df1a,orig_wix_madefor_text_regular',
                                                                lineHeight: '1.5em',
                                                                fontSize: '16px'
                                                            },
                                                            propertiesSource: {
                                                                textDecoration: 'value',
                                                                color: 'value',
                                                                fontFamily: 'value',
                                                                lineHeight: 'value',
                                                                fontSize: 'value'
                                                            },
                                                            groups: {}
                                                        },
                                                        componentClassName: 'wysiwyg.viewer.components.WRichText',
                                                        pageId: '',
                                                        compId: '',
                                                        styleType: 'custom',
                                                        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin'
                                                    }
                                                }
                                            ],
                                            naming: {
                                                name: 'TitleContainer',
                                                type: 'naming'
                                            },
                                            layouts: {
                                                containerLayout: {
                                                    type: 'StackContainerLayout',
                                                    direction: 'column'
                                                },
                                                componentLayout: {
                                                    type: 'ComponentLayout',
                                                    width: {
                                                        type: 'percentage',
                                                        value: 100
                                                    },
                                                    height: {
                                                        type: 'maxContent'
                                                    },
                                                    hidden: false
                                                },
                                                itemLayout: {
                                                    alignSelf: 'start',
                                                    margins: {
                                                        left: {
                                                            type: 'percentage',
                                                            value: 0
                                                        },
                                                        right: {
                                                            type: 'percentage',
                                                            value: 0
                                                        },
                                                        top: {
                                                            type: 'px',
                                                            value: 0
                                                        },
                                                        bottom: {
                                                            type: 'px',
                                                            value: 0
                                                        }
                                                    },
                                                    gridArea: {
                                                        rowStart: 1,
                                                        rowEnd: 2,
                                                        columnStart: 1,
                                                        columnEnd: 2
                                                    },
                                                    justifySelf: 'end',
                                                    type: 'GridItemLayout'
                                                },
                                                type: 'SingleLayoutData'
                                            },
                                            style: {
                                                type: 'ComponentStyle',
                                                style: {
                                                    properties: {
                                                        shd: '0px 0px 0px 0px rgba(0,0,0,0.6)',
                                                        rd: '0px',
                                                        'alpha-brd': '0',
                                                        'alpha-bg': '0',
                                                        bg: '#FFFFFF',
                                                        brw: '0px',
                                                        'boxShadowToggleOn-shd': 'false'
                                                    },
                                                    propertiesSource: {
                                                        shd: 'value',
                                                        rd: 'value',
                                                        'alpha-brd': 'value',
                                                        'alpha-bg': 'value',
                                                        bg: 'value',
                                                        brw: 'value',
                                                        'boxShadowToggleOn-shd': 'value'
                                                    },
                                                    groups: {}
                                                },
                                                componentClassName: 'mobile.core.components.Container',
                                                pageId: '',
                                                compId: '',
                                                styleType: 'custom',
                                                skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin'
                                            }
                                        },
                                        {
                                            type: 'Container',
                                            skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
                                            componentType: 'mobile.core.components.Container',
                                            layout: {
                                                x: 0,
                                                y: 0,
                                                fixedPosition: false,
                                                width: 160,
                                                height: 90,
                                                scale: 1,
                                                rotationInDegrees: 0
                                            },
                                            components: [
                                                {
                                                    type: 'Component',
                                                    skin: 'wixui.skins.Skinless',
                                                    componentType: 'wixui.StylableButton',
                                                    layout: {
                                                        x: 0,
                                                        y: 0,
                                                        fixedPosition: false,
                                                        width: 160,
                                                        height: 90,
                                                        scale: 1,
                                                        rotationInDegrees: 0
                                                    },
                                                    data: {
                                                        type: 'StylableButton',
                                                        label: 'Main Action',
                                                        svgId: '11062b_da022a6235f14308ba1a1bbc9b138fa2.svg'
                                                    },
                                                    props: {
                                                        type: 'StylableButtonProperties',
                                                        isMaxContent: true,
                                                        isWrapText: false,
                                                        iconCollapsed: false
                                                    },
                                                    layouts: {
                                                        containerLayout: {},
                                                        componentLayout: {
                                                            minHeight: {
                                                                type: 'px',
                                                                value: 0
                                                            },
                                                            hidden: false,
                                                            height: {
                                                                type: 'auto'
                                                            },
                                                            type: 'ComponentLayout',
                                                            width: {
                                                                type: 'px',
                                                                value: 101.21875
                                                            }
                                                        },
                                                        itemLayout: {
                                                            alignSelf: 'start',
                                                            margins: {
                                                                left: {
                                                                    type: 'percentage',
                                                                    value: 0
                                                                },
                                                                right: {
                                                                    type: 'percentage',
                                                                    value: 0
                                                                },
                                                                top: {
                                                                    type: 'px',
                                                                    value: 0
                                                                },
                                                                bottom: {
                                                                    type: 'px',
                                                                    value: 0
                                                                }
                                                            },
                                                            gridArea: {
                                                                rowStart: 1,
                                                                rowEnd: 2,
                                                                columnStart: 2,
                                                                columnEnd: 3
                                                            },
                                                            justifySelf: 'end',
                                                            type: 'GridItemLayout'
                                                        },
                                                        type: 'SingleLayoutData'
                                                    },
                                                    style: {
                                                        type: 'ComponentStyle',
                                                        style: {
                                                            properties: {
                                                                '$st-css':
                                                                    ":import{-st-from: 'wix-ui-santa/index.st.css';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;background: #116DFF;border-radius: 18px;border: 1px solid #116DFF;padding-top: 5px;padding-bottom: 5px;padding-left: 23px;padding-right: 23px}.root:hover {\n    background: #0F62E6;\n    border: 1px solid #0F62E6;\n}.root:hover::icon {\n    transform: rotate(2deg);\n    fill: rgba(255,255,255,0.7);}.root:hover::label {\n    text-shadow: 1px 0 rgba(255,255,255,0), -1px 0 rgba(255,255,255,0), 0 1px rgba(255,255,255,0), 0 -1px rgba(255,255,255,0);\n    color: #FFFFFF;\n}.root:disabled{\n    background: value(site_1_3)}.root:disabled::label{\n    color: value(site_1_1)}.root:disabled::icon{\n    fill: value(site_1_1)}.root::container{transition: inherit;flex-direction: row-reverse;justify-content: center;align-items: center}.root::label{transition: inherit;margin: 0px 0px 0px 4px;letter-spacing: 0em;text-align: center;text-shadow: 1px 0 rgba(255,255,255,0), -1px 0 rgba(255,255,255,0), 0 1px rgba(255,255,255,0), 0 -1px rgba(255,255,255,0);background-color: rgba(255,255,255,0);margin-left: 2px;display: initial;-st-mixin: font_8;font-family: wfont_1f4e5a_4158a6f551d840f48e881c1db6eecae6,wf_4158a6f551d840f48e881c1db,orig_wix_madefor_text_medium;font-size: 16px;line-height: 1.5em;color: #FFFFFF}.root::icon{transition: inherit;margin: 0px 4px 0px 0px;fill: value(site_1_1);margin-right: 2px;width: 23px;height: 23px;display: none}"
                                                            },
                                                            propertiesSource: {
                                                                '$st-css': 'value'
                                                            }
                                                        },
                                                        componentClassName: 'wixui.StylableButton',
                                                        pageId: '',
                                                        compId: '',
                                                        styleType: 'custom',
                                                        skin: 'wixui.skins.Skinless'
                                                    }
                                                },
                                                {
                                                    type: 'Component',
                                                    skin: 'wixui.skins.Skinless',
                                                    componentType: 'wixui.StylableButton',
                                                    layout: {
                                                        x: 0,
                                                        y: 0,
                                                        fixedPosition: false,
                                                        width: 160,
                                                        height: 90,
                                                        scale: 1,
                                                        rotationInDegrees: 0
                                                    },
                                                    data: {
                                                        type: 'StylableButton',
                                                        label: 'Secondary Action',
                                                        svgId: '11062b_da022a6235f14308ba1a1bbc9b138fa2.svg'
                                                    },
                                                    props: {
                                                        type: 'StylableButtonProperties',
                                                        isMaxContent: true,
                                                        isWrapText: false,
                                                        iconCollapsed: false
                                                    },
                                                    layouts: {
                                                        containerLayout: {},
                                                        componentLayout: {
                                                            minHeight: {
                                                                type: 'px',
                                                                value: 36
                                                            },
                                                            hidden: false,
                                                            height: {
                                                                type: 'auto'
                                                            },
                                                            type: 'ComponentLayout',
                                                            width: {
                                                                type: 'px',
                                                                value: 101.21875
                                                            }
                                                        },
                                                        itemLayout: {
                                                            alignSelf: 'end',
                                                            margins: {
                                                                left: {
                                                                    type: 'percentage',
                                                                    value: 0
                                                                },
                                                                right: {
                                                                    type: 'percentage',
                                                                    value: 0
                                                                },
                                                                top: {
                                                                    type: 'px',
                                                                    value: 0
                                                                },
                                                                bottom: {
                                                                    type: 'px',
                                                                    value: 0
                                                                }
                                                            },
                                                            gridArea: {
                                                                rowStart: 1,
                                                                rowEnd: 2,
                                                                columnStart: 1,
                                                                columnEnd: 2
                                                            },
                                                            justifySelf: 'start',
                                                            type: 'GridItemLayout'
                                                        },
                                                        type: 'SingleLayoutData'
                                                    },
                                                    style: {
                                                        type: 'ComponentStyle',
                                                        style: {
                                                            properties: {
                                                                '$st-css':
                                                                    ":import{-st-from: 'wix-ui-santa/index.st.css';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 18px;padding-top: 5px;padding-bottom: 5px;padding-left: 23px;padding-right: 23px;background: value(site_1_1);border: 1px solid #D6E6FE}.root:hover {\n    background: #0F62E6;\n    border: 1px solid #0F62E6;\n}.root:hover::icon {\n    transform: rotate(2deg);\n    fill: rgba(255,255,255,0.7);}.root:hover::label {\n    text-shadow: 1px 0 rgba(255,255,255,0), -1px 0 rgba(255,255,255,0), 0 1px rgba(255,255,255,0), 0 -1px rgba(255,255,255,0);\n    color: #FFFFFF;\n}.root:disabled{\n    background: value(site_1_3)}.root:disabled::label{\n    color: value(site_1_1)}.root:disabled::icon{\n    fill: value(site_1_1)}.root::container{transition: inherit;flex-direction: row-reverse;justify-content: center;align-items: center}.root::label{transition: inherit;margin: 0px 0px 0px 4px;letter-spacing: 0em;text-align: center;text-shadow: 1px 0 rgba(255,255,255,0), -1px 0 rgba(255,255,255,0), 0 1px rgba(255,255,255,0), 0 -1px rgba(255,255,255,0);background-color: rgba(255,255,255,0);margin-left: 2px;display: initial;-st-mixin: font_8;font-family: wfont_1f4e5a_4158a6f551d840f48e881c1db6eecae6,wf_4158a6f551d840f48e881c1db,orig_wix_madefor_text_medium;font-size: 16px;line-height: 1.5em;color: #116DFF}.root::icon{transition: inherit;margin: 0px 4px 0px 0px;fill: value(site_1_1);margin-right: 2px;width: 23px;height: 23px;display: none}"
                                                            },
                                                            propertiesSource: {
                                                                '$st-css': 'value'
                                                            }
                                                        },
                                                        componentClassName: 'wixui.StylableButton',
                                                        pageId: '',
                                                        compId: '',
                                                        styleType: 'custom',
                                                        skin: 'wixui.skins.Skinless'
                                                    }
                                                }
                                            ],
                                            naming: {
                                                name: 'ActionsContainer',
                                                type: 'naming'
                                            },
                                            layouts: {
                                                containerLayout: {
                                                    type: 'GridContainerLayout',
                                                    rows: [
                                                        {
                                                            type: 'auto'
                                                        }
                                                    ],
                                                    columns: [
                                                        {
                                                            type: 'auto'
                                                        },
                                                        {
                                                            type: 'auto'
                                                        }
                                                    ],
                                                    columnGap: {
                                                        type: 'px',
                                                        value: 12
                                                    }
                                                },
                                                componentLayout: {
                                                    minHeight: {
                                                        type: 'px',
                                                        value: 0
                                                    },
                                                    hidden: false,
                                                    height: {
                                                        type: 'px',
                                                        value: 36
                                                    },
                                                    type: 'ComponentLayout',
                                                    width: {
                                                        type: 'percentage',
                                                        value: 99.9906704490376
                                                    }
                                                },
                                                itemLayout: {
                                                    alignSelf: 'start',
                                                    margins: {
                                                        left: {
                                                            type: 'percentage',
                                                            value: 0
                                                        },
                                                        right: {
                                                            type: 'percentage',
                                                            value: 0
                                                        },
                                                        top: {
                                                            type: 'px',
                                                            value: 0
                                                        },
                                                        bottom: {
                                                            type: 'px',
                                                            value: 0
                                                        }
                                                    },
                                                    gridArea: {
                                                        rowStart: 1,
                                                        rowEnd: 2,
                                                        columnStart: 2,
                                                        columnEnd: 3
                                                    },
                                                    justifySelf: 'stretch',
                                                    position: 'relative',
                                                    type: 'GridItemLayout',
                                                    top: {
                                                        type: 'px',
                                                        value: 0
                                                    }
                                                },
                                                type: 'SingleLayoutData'
                                            },
                                            style: {
                                                type: 'ComponentStyle',
                                                style: {
                                                    propertiesOverride: {},
                                                    properties: {
                                                        shd: '0px 0px 0px 0px rgba(0,0,0,0.6)',
                                                        rd: '0px',
                                                        'alpha-brd': '0',
                                                        'alpha-bg': '0',
                                                        bg: 'color_11',
                                                        brw: '0px',
                                                        'boxShadowToggleOn-shd': 'false'
                                                    },
                                                    propertiesSource: {
                                                        shd: 'value',
                                                        rd: 'value',
                                                        'alpha-brd': 'value',
                                                        'alpha-bg': 'value',
                                                        bg: 'theme',
                                                        brw: 'value',
                                                        'boxShadowToggleOn-shd': 'value'
                                                    },
                                                    groups: {}
                                                },
                                                componentClassName: 'mobile.core.components.Container',
                                                pageId: '',
                                                compId: '',
                                                styleType: 'custom',
                                                skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin'
                                            }
                                        }
                                    ],
                                    naming: {
                                        name: 'PageHeader',
                                        type: 'naming'
                                    },
                                    layouts: {
                                        containerLayout: {
                                            columnGap: {
                                                type: 'px',
                                                value: 24
                                            },
                                            columns: [
                                                {
                                                    type: 'fr',
                                                    value: 0.9999611959410957
                                                },
                                                {
                                                    type: 'auto'
                                                }
                                            ],
                                            padding: {
                                                top: {
                                                    type: 'px',
                                                    value: 6
                                                },
                                                bottom: {
                                                    type: 'px',
                                                    value: 6
                                                }
                                            },
                                            type: 'GridContainerLayout',
                                            rows: [
                                                {
                                                    type: 'auto'
                                                }
                                            ]
                                        },
                                        componentLayout: {
                                            minHeight: {
                                                type: 'px',
                                                value: 0
                                            },
                                            hidden: false,
                                            height: {
                                                type: 'auto'
                                            },
                                            minWidth: {
                                                type: 'px',
                                                value: 0
                                            },
                                            type: 'ComponentLayout',
                                            width: {
                                                type: 'auto'
                                            }
                                        },
                                        itemLayout: {
                                            alignSelf: 'stretch',
                                            margins: {
                                                left: {
                                                    type: 'px',
                                                    value: 0
                                                },
                                                right: {
                                                    type: 'px',
                                                    value: 0
                                                },
                                                top: {
                                                    type: 'px',
                                                    value: 18
                                                },
                                                bottom: {
                                                    type: 'px',
                                                    value: 0
                                                }
                                            },
                                            gridArea: {
                                                rowStart: 1,
                                                rowEnd: 2,
                                                columnStart: 2,
                                                columnEnd: 3
                                            },
                                            justifySelf: 'stretch',
                                            position: 'sticky',
                                            type: 'GridItemLayout',
                                            top: {
                                                type: 'px',
                                                value: 0
                                            }
                                        },
                                        type: 'SingleLayoutData'
                                    },
                                    style: {
                                        type: 'ComponentStyle',
                                        style: {
                                            propertiesOverride: {},
                                            properties: {
                                                shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
                                                rd: '0px',
                                                'alpha-brd': '1',
                                                'alpha-bg': '1',
                                                bg: '#ECEFF3',
                                                brw: '0px',
                                                brd: '#324158',
                                                'boxShadowToggleOn-shd': 'false'
                                            },
                                            propertiesSource: {
                                                shd: 'value',
                                                rd: 'value',
                                                'alpha-brd': 'value',
                                                'alpha-bg': 'value',
                                                bg: 'value',
                                                brw: 'value',
                                                brd: 'value',
                                                'boxShadowToggleOn-shd': 'value'
                                            },
                                            groups: {}
                                        },
                                        componentClassName: 'mobile.core.components.Container',
                                        pageId: '',
                                        compId: '',
                                        styleType: 'custom',
                                        skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin'
                                    }
                                }
                            ],
                            naming: {
                                name: 'Page',
                                type: 'naming'
                            },
                            layouts: {
                                containerLayout: {
                                    rowGap: {
                                        type: 'px',
                                        value: 18
                                    },
                                    columns: [
                                        {
                                            type: 'MinMaxSize',
                                            min: {
                                                type: 'px',
                                                value: 48
                                            },
                                            max: {
                                                type: 'fr',
                                                value: 1
                                            }
                                        },
                                        {
                                            type: 'MinMaxSize',
                                            min: {
                                                type: 'px',
                                                value: 864
                                            },
                                            max: {
                                                type: 'px',
                                                value: 1248
                                            }
                                        },
                                        {
                                            type: 'MinMaxSize',
                                            min: {
                                                type: 'px',
                                                value: 48
                                            },
                                            max: {
                                                type: 'fr',
                                                value: 1
                                            }
                                        }
                                    ],
                                    padding: {
                                        bottom: {
                                            type: 'px',
                                            value: 48
                                        }
                                    },
                                    type: 'GridContainerLayout',
                                    rows: [
                                        {
                                            type: 'minContent'
                                        },
                                        {
                                            type: 'fr',
                                            value: 1
                                        }
                                    ]
                                },
                                componentLayout: {
                                    minHeight: {
                                        type: 'px',
                                        value: 0
                                    },
                                    hidden: false,
                                    height: {
                                        type: 'auto'
                                    },
                                    minWidth: {
                                        type: 'px',
                                        value: 0
                                    },
                                    type: 'ComponentLayout',
                                    width: {
                                        type: 'auto'
                                    }
                                },
                                itemLayout: {
                                    alignSelf: 'stretch',
                                    margins: {
                                        left: {
                                            type: 'px',
                                            value: 0
                                        },
                                        right: {
                                            type: 'px',
                                            value: 0
                                        },
                                        top: {
                                            type: 'px',
                                            value: 0
                                        },
                                        bottom: {
                                            type: 'px',
                                            value: 0
                                        }
                                    },
                                    gridArea: {
                                        rowStart: 1,
                                        rowEnd: 2,
                                        columnStart: 1,
                                        columnEnd: 2
                                    },
                                    justifySelf: 'stretch',
                                    position: 'relative',
                                    type: 'GridItemLayout',
                                    top: {
                                        type: 'px',
                                        value: 0
                                    }
                                },
                                type: 'SingleLayoutData'
                            },
                            style: {
                                type: 'ComponentStyle',
                                style: {
                                    propertiesOverride: {},
                                    properties: {
                                        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
                                        rd: '0px',
                                        'alpha-brd': '1',
                                        'alpha-bg': '1',
                                        bg: '#ECEFF3',
                                        brw: '0',
                                        brd: 'color_13',
                                        'boxShadowToggleOn-shd': 'false'
                                    },
                                    propertiesSource: {
                                        shd: 'value',
                                        rd: 'value',
                                        'alpha-brd': 'value',
                                        'alpha-bg': 'value',
                                        bg: 'value',
                                        brw: 'value',
                                        brd: 'theme',
                                        'boxShadowToggleOn-shd': 'value'
                                    },
                                    groups: {}
                                },
                                componentClassName: 'mobile.core.components.Container',
                                pageId: '',
                                compId: '',
                                styleType: 'custom',
                                skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin'
                            }
                        }
                    ],
                    naming: {
                        name: 'Page 02',
                        type: 'naming'
                    },
                    layouts: {
                        containerLayout: {
                            columns: [
                                {
                                    type: 'fr',
                                    value: 1
                                }
                            ],
                            rows: [
                                {
                                    type: 'fr',
                                    value: 1
                                }
                            ],
                            type: 'GridContainerLayout'
                        },
                        componentLayout: {
                            type: 'ComponentLayout',
                            height: {
                                type: 'auto'
                            },
                            minHeight: {
                                type: 'px',
                                value: 300
                            },
                            width: {
                                type: 'percentage',
                                value: 100
                            }
                        },
                        itemLayout: {
                            margins: {
                                left: {
                                    type: 'auto'
                                }
                            },
                            gridArea: {
                                rowStart: 1,
                                rowEnd: 2,
                                columnStart: 1,
                                columnEnd: 2
                            },
                            justifySelf: 'center',
                            position: 'relative',
                            type: 'GridItemLayout'
                        },
                        type: 'SingleLayoutData'
                    },
                    style: {
                        type: 'ComponentStyle',
                        style: {
                            propertiesOverride: {},
                            properties: {
                                shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
                                rd: '0px',
                                'alpha-bg': '0',
                                bg: 'color_31',
                                alphaBg: '1',
                                brw: '0px',
                                brd: '#e8e6e6',
                                'boxShadowToggleOn-shd': 'false',
                                alphaBrd: '0.18'
                            },
                            propertiesSource: {
                                shd: 'value',
                                rd: 'value',
                                'alpha-bg': 'value',
                                bg: 'theme',
                                alphaBg: 'value',
                                brw: 'value',
                                brd: 'value',
                                'boxShadowToggleOn-shd': 'value',
                                alphaBrd: 'value'
                            },
                            groups: {}
                        },
                        componentClassName: 'mobile.core.components.Container',
                        pageId: '',
                        compId: '',
                        styleType: 'custom',
                        skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin'
                    }
                }
            ],
            data: {
                defaultStateId: 'comp-ljy79glv',
                type: 'MultiStateBoxData',
                selectedStateId: 'comp-ljy79glv'
            },
            layouts: {
                containerLayout: {
                    columns: [
                        {
                            type: 'fr',
                            value: 1
                        }
                    ],
                    rows: [
                        {
                            type: 'fr',
                            value: 1
                        }
                    ],
                    type: 'GridContainerLayout'
                },
                componentLayout: {
                    minHeight: {
                        type: 'px',
                        value: 0
                    },
                    hidden: false,
                    height: {
                        type: 'auto'
                    },
                    minWidth: {
                        type: 'px',
                        value: 0
                    },
                    type: 'ComponentLayout',
                    width: {
                        type: 'auto'
                    }
                },
                itemLayout: {
                    alignSelf: 'stretch',
                    margins: {
                        left: {
                            type: 'px',
                            value: 0
                        },
                        right: {
                            type: 'px',
                            value: 0
                        },
                        top: {
                            type: 'px',
                            value: 0
                        },
                        bottom: {
                            type: 'px',
                            value: 0
                        }
                    },
                    gridArea: {
                        rowStart: 1,
                        rowEnd: 2,
                        columnStart: 1,
                        columnEnd: 2
                    },
                    justifySelf: 'stretch',
                    position: 'relative',
                    type: 'GridItemLayout'
                },
                type: 'SingleLayoutData'
            },
            style: {
                type: 'ComponentStyle',
                style: {
                    properties: {
                        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
                        rd: '0px',
                        'alpha-bg': '0',
                        bg: 'color_11',
                        alphaBg: '1',
                        brw: '0px',
                        brd: '#e8e6e6',
                        'boxShadowToggleOn-shd': 'false',
                        alphaBrd: '0.18'
                    },
                    propertiesSource: {
                        shd: 'value',
                        rd: 'value',
                        'alpha-bg': 'value',
                        bg: 'theme',
                        alphaBg: 'value',
                        brw: 'value',
                        brd: 'value',
                        'boxShadowToggleOn-shd': 'value',
                        alphaBrd: 'value'
                    },
                    groups: {}
                },
                componentClassName: 'wixui.MultiStateBox',
                pageId: '',
                compId: '',
                styleType: 'custom',
                skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin'
            }
        }
    ],
    layouts: {
        containerLayout: {
            type: 'GridContainerLayout',
            columns: [
                {
                    type: 'fr',
                    value: 1
                }
            ],
            rows: [
                {
                    type: 'fr',
                    value: 1
                }
            ]
        },
        componentLayout: {
            minHeight: {
                type: 'px',
                value: 768
            },
            hidden: false,
            height: {
                type: 'auto'
            },
            minWidth: {
                type: 'px',
                value: 0
            },
            type: 'ComponentLayout',
            width: {
                type: 'percentage',
                value: 100
            }
        },
        itemLayout: {
            alignSelf: 'stretch',
            margins: {
                left: {
                    type: 'px',
                    value: 0
                },
                right: {
                    type: 'px',
                    value: 0
                },
                top: {
                    type: 'px',
                    value: 0
                },
                bottom: {
                    type: 'px',
                    value: 0
                }
            },
            gridArea: {
                rowStart: 1,
                rowEnd: 2,
                columnStart: 1,
                columnEnd: 2
            },
            justifySelf: 'stretch',
            type: 'GridItemLayout'
        },
        type: 'SingleLayoutData'
    },
    style: 'c1',
    activeModes: {},
    referredVariants: {},
    activeVariants: [],
    siteWidth: 980
})

export default {
    appWidgetStructure: responsiveAppWidgetStructure,
    getPageStructure,
    getDashboardStructure
}
