import type {SiteAPI} from '@wix/document-services-types'
import _ from 'lodash'
import screenWidthPlugin from './screenWidthPlugin'

function pluginFn(siteAPI: SiteAPI, compLayoutRelativeToStructure) {
    return _.assign(screenWidthPlugin.call(this, siteAPI, compLayoutRelativeToStructure), {
        height: Math.max(compLayoutRelativeToStructure.height, siteAPI.getScreenHeight())
    })
}

export default pluginFn
