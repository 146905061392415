import _ from 'lodash'

function create(isMergingOnSet: boolean) {
    let cacheEntries: Record<string, unknown> = {}
    let lockedEntries: Record<string, number> = {}

    const get = function (cacheKey: string) {
        return cacheEntries[cacheKey]
    }

    const set = function (cacheKey: string, value: any) {
        const cached = get(cacheKey)
        if (cached && isMergingOnSet) {
            cacheEntries[cacheKey] = _.merge({}, cached, value)
        } else {
            cacheEntries[cacheKey] = value
        }
        return cacheEntries[cacheKey]
    }

    const lock = function (cacheKey: string) {
        lockedEntries[cacheKey] = 1
    }

    const isLocked = function (cacheKey: string) {
        return lockedEntries[cacheKey]
    }

    const unlock = function (cacheKey: string) {
        delete lockedEntries[cacheKey]
    }

    const keys = function () {
        return (cacheEntries && _.keys(cacheEntries)) || []
    }

    const clear = function () {
        cacheEntries = {}
        lockedEntries = {}
    }

    return {
        get,
        set,
        lock,
        unlock,
        isLocked,
        keys,
        clear
    }
}

export default {
    create
}
