const _ = require('lodash')

// @ts-ignore
const enumFromSchemaToProto = enumItem => {
    if (_.isNil(enumItem)) {
        return null
    }
    const startWithNumber = _.isNumber(enumItem) || /^\d/.test(enumItem)
    let itemName = startWithNumber ? `_${enumItem}` : enumItem
    itemName = itemName.replace(/\s|\)|\(|\.|-|:|\//g, '_')
    return _.toUpper(itemName)
}
module.exports = {
    enumFromSchemaToProto
}
