import type {Layout, Pointer, PS} from '@wix/document-services-types'
import component from '../../component/component'

export default function (ps: PS, compPointer: Pointer, layout: Layout) {
    let siteWidth, siteCenterX, menuOrientation
    if (layout.docked) {
        menuOrientation = layout.docked.right ? 'right' : 'left'
    } else {
        siteWidth = ps.siteAPI.getSiteWidth()
        siteCenterX = siteWidth / 2
        menuOrientation = layout.x + layout.width / 2 > siteCenterX ? 'right' : 'left' // eslint-disable-line no-mixed-operators
    }
    const propertiesToUpdate: any = {orientation: menuOrientation}
    propertiesToUpdate.itemsAlignment = menuOrientation
    component.properties.update(ps, compPointer, propertiesToUpdate)
}
