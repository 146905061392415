import type {Pointer, PS} from '@wix/document-services-types'

function isContainable(ps: PS, compPointer?: Pointer, containerPointer?: Pointer) {
    if (!containerPointer) {
        return false
    }

    return ps.pointers.components.isMasterPage(containerPointer)
}

export default {
    anchors: {
        to: false,
        from: false
    },
    moveDirections: [],
    resizableSides: [],
    dockable: false,
    alignable: false,
    groupable: false,
    canContainByStructure: true,
    hiddenable: false,
    containable: isContainable
}
