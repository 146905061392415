/**
 * Created by noamr on 14/03/2017.
 */
import _ from 'lodash'

const objects = []
function freeze(object) {
    objects.push(object)
}

function start() {
    _.forEach(objects, Object.freeze)
}

export default {
    freeze,
    start
}
