import type {PS} from '@wix/document-services-types'
import constants from '../../constants/constants'

export default {
    canBeStretched: true,
    defaultMobileProperties(ps: PS, comp, desktopProps) {
        return {
            numCols: 2,
            margin: desktopProps.margin <= 3 ? desktopProps.margin : 15,
            textMode:
                {titleAndDescription: 'titleOnly', descriptionOnly: 'noText'}[desktopProps.textMode] ||
                desktopProps.textMode
        }
    },
    resizableSides: [constants.RESIZE_SIDES.LEFT, constants.RESIZE_SIDES.RIGHT]
}
