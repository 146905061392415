import _ from 'lodash'
import type {DAL, ExtensionAPI} from '@wix/document-manager-core'
import type {
    CompRef,
    ExternalsMap,
    Pointers,
    RawComponentExport,
    RawComponentExportStructure,
    SchemaService
} from '@wix/document-services-types'
import {STRUCTURE_PROPERTIES_TO_KEEP, INCLUDED_NAMESPACES} from '../constants'
import {mergePageBreakpoints} from './utils/breakpoints'
import {updateChildren} from './utils/children'
import {updateComponentDataItem} from './utils/dataItems'
import {updateOverrides} from './utils/overrides'
import {isSystemDataItem} from '../utils'

const updateDataItems = (
    componentPointer: CompRef,
    component: RawComponentExportStructure,
    externals: ExternalsMap,
    dal: DAL,
    pointers: Pointers,
    extensionAPI: ExtensionAPI,
    schemaService: SchemaService
) => {
    _.forOwn(INCLUDED_NAMESPACES, (query: string, namespace: string) => {
        const dataItem = component.data[namespace]

        if (isSystemDataItem(component.structure[query], namespace, extensionAPI)) {
            return
        }

        updateComponentDataItem(
            componentPointer,
            dataItem,
            namespace,
            externals,
            dal,
            pointers,
            extensionAPI,
            schemaService
        )
    })
}

const updateComponentStructure = (
    componentPointer: CompRef,
    componentStructure: RawComponentExportStructure['structure'],
    dal: DAL
) => {
    const origStructure = dal.get(componentPointer)
    const newStructure = {
        ...componentStructure,
        ..._.pick(origStructure, ...STRUCTURE_PROPERTIES_TO_KEEP)
    }

    if (origStructure.components === undefined) {
        delete newStructure.components
    }

    dal.set(componentPointer, newStructure)
}

const updateMobileStructure = (componentPointer: CompRef, dal: DAL, pointers: Pointers) => {
    const mobilePointer = pointers.structure.getMobilePointer(componentPointer)
    const origMobileStructure = dal.get(mobilePointer)
    if (origMobileStructure) {
        const componentStructure = dal.get(componentPointer)

        const newStructure = {
            ...componentStructure,
            components: []
        }

        if (origMobileStructure.components === undefined) {
            delete newStructure.components
        }

        if (origMobileStructure.components) dal.set(mobilePointer, newStructure)
    }
}
const addAppControllers = (componentExport: RawComponentExport): void => {
    const {components, externalRefs, appControllers} = componentExport

    for (const [id, {externalRef, parent, appController}] of Object.entries(appControllers)) {
        if (externalRef) {
            externalRefs[id] = externalRef
        } else if (parent) {
            const {structure} = components[parent]
            structure.components ??= []
            structure.components.push(appController.structure.id)
            components[appController.structure.id] = appController
        }
    }
}

export const importAllComponents = (
    componentId: RawComponentExport['rootComponent'],
    componentExport: RawComponentExport,
    componentToReplace: CompRef,
    externals: ExternalsMap,
    dal: DAL,
    pointers: Pointers,
    extensionAPI: ExtensionAPI,
    schemaService: SchemaService
) => {
    const {components} = componentExport
    const component = components[componentId]
    externals.externalRefs[componentId] = componentToReplace.id

    updateComponentStructure(componentToReplace, component.structure, dal)
    updateDataItems(componentToReplace, component, externals, dal, pointers, extensionAPI, schemaService)
    updateOverrides(componentToReplace, component, externals, dal, pointers, extensionAPI, schemaService)
    updateChildren(
        componentToReplace,
        component.structure,
        componentExport,
        externals,
        dal,
        pointers,
        extensionAPI,
        schemaService
    )
    updateMobileStructure(componentToReplace, dal, pointers)
}

export const importRawComponent = (
    componentId: RawComponentExport['rootComponent'],
    componentExport: RawComponentExport,
    componentToReplace: CompRef,
    dal: DAL,
    pointers: Pointers,
    extensionAPI: ExtensionAPI,
    schemaService: SchemaService
) => {
    const {components, externalRefs, appControllers} = componentExport
    const component = components[componentId]

    const externals: ExternalsMap = {
        externalRefs,
        appControllers
    }

    addAppControllers(componentExport)
    mergePageBreakpoints(componentToReplace, component, componentExport, dal, pointers, extensionAPI, schemaService)
    importAllComponents(
        componentId,
        componentExport,
        componentToReplace,
        externals,
        dal,
        pointers,
        extensionAPI,
        schemaService
    )
}
