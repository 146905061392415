import _ from 'lodash'
import type {DataModelExtensionAPI} from '../dataModel/dataModel'
import {ReportableError, constants} from '@wix/document-manager-utils'
import {convertPageNameToUrl, isPageUriSeoTooLong, uriHasIllegalCharacters} from './pageUtils'
import {getLanguageByUseOriginal, getTranslationLanguageCodes, patchPageTranslations} from './language'
import {isPopup} from './popupUtils'
import type {CreateExtArgs, DalValue} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import {DATA_TYPES} from '../../constants/constants'
import {deepClone} from '@wix/wix-immutable-proxy'

const doesRouterPrefixExist = ({pointers, dal}: CreateExtArgs, routerPrefix: string) => {
    const routersConfigMapPointer = pointers.routers.getRoutersConfigMapPointer()
    const allRouters = dal.get(routersConfigMapPointer)
    const routerRef = _.findKey(allRouters, {prefix: routerPrefix})
    return !!routerRef
}

const getFilteredPagesList = (dataAccessArgs: CreateExtArgs, includeMasterPage: boolean, getOnlyPopups: boolean) => {
    const allPagesPointers = dataAccessArgs.pointers.page.getNonDeletedPagesPointers(includeMasterPage)
    const whatIsPage = (pageId: string) => {
        const isPopupPage = isPopup(dataAccessArgs, pageId)
        return getOnlyPopups ? isPopupPage : !isPopupPage
    }

    return _(allPagesPointers).map('id').filter(whatIsPage).value()
}

const getPagesAndPopupsList = ({pointers}: CreateExtArgs, includeMasterPage: boolean) => {
    const allPagesPointers = pointers.page.getNonDeletedPagesPointers(includeMasterPage)
    return _.map(allPagesPointers, 'id')
}

const getPagesList = (dataAccessArgs: CreateExtArgs, includeMasterPage: boolean, includingPopups: boolean) => {
    if (includingPopups) {
        return getPagesAndPopupsList(dataAccessArgs, includeMasterPage)
    }
    return getFilteredPagesList(dataAccessArgs, includeMasterPage, false)
}

const getPageInnerItem = (dataAccessArgs: CreateExtArgs, pageId: string, key: string) => {
    const pageDataItemPointer = dataAccessArgs.pointers.data.getDataItemFromMaster(pageId)
    const pageData = dataAccessArgs.dal.get(pageDataItemPointer)
    return _.get(pageData, key)
}

const getPageUriSEO = (dataAccessArgs: CreateExtArgs, pageId: string, languageCode?: string) => {
    const enableMLSlug = dataAccessArgs.coreConfig.experimentInstance.isOpen('dm_enableMLSlug')
    if (enableMLSlug && languageCode) {
        const pageDataItemPointer = dataAccessArgs.pointers.data.getDataItemFromMaster(pageId)
        const translationPointer = dataAccessArgs.pointers.multilingualTranslations.getTranslation(
            pageDataItemPointer,
            languageCode
        )
        const pageData = dataAccessArgs.dal.get(translationPointer)
        if (pageData) {
            return _.get(pageData, ['pageUriSEO'])
        }
    }
    return getPageInnerItem(dataAccessArgs, pageId, 'pageUriSEO')
}

const isDuplicatePageUriSeoForLanguage = (
    dataAccessArgs: CreateExtArgs,
    excludePageId: string,
    pageUriSEO: string,
    languageCode?: string
) => {
    const pageIds = getPagesList(dataAccessArgs, false, true)
    return _(pageIds)
        .pull(excludePageId)
        .some(pageId => getPageUriSEO(dataAccessArgs, pageId, languageCode) === pageUriSEO)
}

const isDuplicatePageUriSeoForSecondaryLanguages = (
    dataAccessArgs: CreateExtArgs,
    excludePageId: string,
    pageUriSEO: string,
    languageCode?: string
) => {
    if (languageCode && languageCode !== getLanguageByUseOriginal(dataAccessArgs, true)) {
        return false
    }
    //if the language is the main language - take care of uniqueness for the secondary languages without uri seo at the edited page
    return getTranslationLanguageCodes(dataAccessArgs).some((translationLanguageCode: string) =>
        isDuplicatePageUriSeoForLanguage(dataAccessArgs, excludePageId, pageUriSEO, translationLanguageCode)
    )
}

const isDuplicatePageUriSeo = (
    dataAccessArgs: CreateExtArgs,
    excludePageId: string,
    pageUriSEO: string,
    languageCode?: string
) => {
    if (doesRouterPrefixExist(dataAccessArgs, pageUriSEO)) {
        return true
    }

    return (
        isDuplicatePageUriSeoForLanguage(dataAccessArgs, excludePageId, pageUriSEO, languageCode) ||
        isDuplicatePageUriSeoForSecondaryLanguages(dataAccessArgs, excludePageId, pageUriSEO, languageCode)
    )
}

const getPairsCheckValue = (pairs: any, pageUriSEO: string, languageCode?: string) => {
    return (
        _.chain(pairs)
            .find(pair => pair[0](pageUriSEO, languageCode))
            // @ts-expect-error
            .get(['1'], '')
            .value()
    )
}

const getForbiddenPageUriSEOs = ({dal, pointers}: CreateExtArgs) => {
    const forbiddenWordsPointer = pointers.general.getForbiddenPageUriSEOs()
    return dal.get(forbiddenWordsPointer) || {}
}

const isForbiddenPageUriSeo = (dataAccessArgs: CreateExtArgs, pageId: string, pageUriSEO: string) => {
    const forbiddenWords = getForbiddenPageUriSEOs(dataAccessArgs)
    const currentPageUriSEO = getPageUriSEO(dataAccessArgs, pageId)
    if (currentPageUriSEO === pageUriSEO) {
        return false
    }
    return forbiddenWords.hasOwnProperty(pageUriSEO.toLowerCase())
}
const hasIllegalChars = (pageUriSEO: string) => {
    return uriHasIllegalCharacters(pageUriSEO)
}

const getPageUriSeoFormatErrorMessage = (pageUriSEO: string) => {
    return getPairsCheckValue(
        [
            [isPageUriSeoTooLong, `pageUriSEO is invalid: over ${constants.URLS.MAX_LENGTH} chars`],
            [hasIllegalChars, 'pageUriSEO is invalid: must only be alphanumeric or hyphen']
        ],
        pageUriSEO
    )
}

const getPageUriSeoContentErrorMessage = (
    dataAccessArgs: CreateExtArgs,
    pageId: string,
    pageUriSEO: string,
    languageCode?: string
) => {
    return getPairsCheckValue(
        [
            [isDuplicatePageUriSeo.bind(null, dataAccessArgs, pageId), 'pageUriSEO is invalid: not unique across site'],
            [isForbiddenPageUriSeo.bind(null, dataAccessArgs, pageId), 'pageUriSEO is invalid: reserved word']
        ],
        pageUriSEO,
        languageCode
    )
}

const getValidPageUriSEO = (
    dataAccessArgs: CreateExtArgs,
    pageId: string,
    initialPageUriSEO: string,
    languageCode?: string
) => {
    initialPageUriSEO = convertPageNameToUrl(initialPageUriSEO)
    if (isPageUriSeoTooLong(initialPageUriSEO, constants.SAFE_PADDING_FOR_URI_LENGTH)) {
        initialPageUriSEO = initialPageUriSEO.slice(
            0,
            constants.URLS.MAX_LENGTH - constants.SAFE_PADDING_FOR_URI_LENGTH
        ) //allow extra space for duplicates so we can append an index
    }
    let pageUriSEO = initialPageUriSEO
    for (let index = 1; getPageUriSeoContentErrorMessage(dataAccessArgs, pageId, pageUriSEO, languageCode); index++) {
        pageUriSEO = `${initialPageUriSEO}-${index}`
    }
    return pageUriSEO
}

const getPageUriSeoErrorMessage = (
    dataAccessArgs: CreateExtArgs,
    pageId: string,
    pageUriSEO: string,
    languageCode?: string
) => {
    const urlFormatPointer = dataAccessArgs.pointers.general.getUrlFormat()
    const urlFormat = dataAccessArgs.dal.get(urlFormatPointer)

    if (urlFormat === constants.URL_FORMATS.HASH_BANG) {
        return ''
    }

    return (
        getPageUriSeoContentErrorMessage(dataAccessArgs, pageId, pageUriSEO, languageCode) ||
        getPageUriSeoFormatErrorMessage(pageUriSEO)
    )
}

const validatePageUriSeoAllowed = (
    dataAccessArgs: CreateExtArgs,
    pageId: string,
    pageUriSEO: string,
    languageCode?: string
) => {
    const error = getPageUriSeoErrorMessage(dataAccessArgs, pageId, pageUriSEO, languageCode)

    if (!_.isEmpty(error)) {
        throw new ReportableError({
            message: error,
            errorType: 'PageURISeoValidationFailed'
        })
    }
}

const isPartiallyUpdatingBackgroundRef = (newBg: any, oldBg: any) =>
    newBg && oldBg?.type && (oldBg.type === newBg.type || !newBg.type)
const updatePageBackgrounds = (
    {extensionAPI}: CreateExtArgs,
    pagePointer: Pointer,
    data: Record<string, any>,
    languageCode?: string
) => {
    if (data.pageBackgrounds) {
        const {dataModel} = extensionAPI as DataModelExtensionAPI
        const pageDataItem = dataModel.components.getItem(pagePointer, DATA_TYPES.data, languageCode)
        const dataToUpdate = deepClone(data)
        const newBg = dataToUpdate.pageBackgrounds
        const oldBg = pageDataItem?.pageBackgrounds
        if (newBg && oldBg) {
            _.forEach(constants.DEVICES, device => {
                const newRef = newBg[device]?.ref
                const oldRef = oldBg[device]?.ref
                if (isPartiallyUpdatingBackgroundRef(newRef, oldRef)) {
                    _.set(newBg, [device, 'ref'], _.assign(oldRef, newRef))
                }
            })
        }
        return {
            ...dataToUpdate,
            pageBackgrounds: {...(pageDataItem?.pageBackgrounds || {}), ...dataToUpdate.pageBackgrounds}
        }
    }
    return data
}

const getValidatedAndUpdatedPageData = (
    dataAccessArgs: CreateExtArgs,
    pagePointer: Pointer,
    data: Record<string, any>,
    languageCode?: string
) => {
    const pageId = pagePointer.id
    if (_.isString(data.pageUriSEO) && _.isEmpty(data.pageUriSEO)) {
        data.pageUriSEO = getValidPageUriSEO(dataAccessArgs, pageId, data.title, languageCode) //convertPageNameToUrl handles blank titles
    }
    if (data.pageUriSEO) {
        validatePageUriSeoAllowed(dataAccessArgs, pageId, data.pageUriSEO, languageCode)
        if (!_.get(data, ['translationData', 'uriSEOTranslated'])) {
            data.translationData = {
                uriSEOTranslated: false
            }
        }
    }
    return updatePageBackgrounds(dataAccessArgs, pagePointer, data, languageCode)
}

const updatePageDataItem = (
    dataAccessArgs: CreateExtArgs,
    pagePointer: Pointer,
    data: Record<string, any>,
    languageCodeToUse?: string
) => {
    const pageId = pagePointer.id
    data = getValidatedAndUpdatedPageData(dataAccessArgs, pagePointer, data, languageCodeToUse)

    const UNTRANSLATABLE_FIELDS = ['managingAppDefId', 'parentPageId']

    const disableMLSlug = !dataAccessArgs.coreConfig.experimentInstance.isOpen('dm_enableMLSlug')
    if (disableMLSlug) {
        UNTRANSLATABLE_FIELDS.push('pageUriSEO')
    }

    const patch = UNTRANSLATABLE_FIELDS.filter(field => _.has(data, [field])).reduce((acc, field) => {
        acc[field] = data[field]
        return acc
    }, {})

    if (!_.isEmpty(patch)) {
        patchPageTranslations(dataAccessArgs, pageId, (translationDataItem: DalValue) =>
            _.assign(translationDataItem, patch)
        )
    }

    const {extensionAPI} = dataAccessArgs
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const rootDataItemPointer = dataModel.addItemWithRefReuse(data, DATA_TYPES.data, pageId, pageId, languageCodeToUse)

    //move only the root page data item that was updated to the masterPage
    const {dal} = dataAccessArgs
    dal.modify(rootDataItemPointer, (pageDataAfterUpdate: DalValue) => ({
        ...pageDataAfterUpdate,
        metaData: {...pageDataAfterUpdate.metaData, pageId: 'masterPage'}
    }))
}

export {updatePageDataItem, getValidPageUriSEO, isDuplicatePageUriSeo}
