import appStudioDataModel from './appStudioDataModel'
import type {PS} from '@wix/document-services-types'

const AppType = {
    WIDGETS: 'WIDGETS',
    CODE_PACKAGE: 'CODE_PACKAGE'
}

function getAppType(ps: PS) {
    const widgets = appStudioDataModel.getAllWidgets(ps)
    if (widgets.length > 0) {
        return AppType.WIDGETS
    }
    return AppType.CODE_PACKAGE
}

export default {
    AppType,
    getAppType
}
