import type {Extension, CreateExtensionArgument, CreateExtArgs} from '@wix/document-manager-core'
import _ from 'lodash'
import {PROPERTY_NAMES} from '../metadata/metadataUtils'
import {ERRORS, VALIDATIONS, VALIDATIONS_REGEX} from './constants'
import {hasEmojis} from '@wix/document-manager-utils'

type SuccessCB = (res?: {success: true}) => void
type FailureCB = (err: any) => void

const createExtension = ({environmentContext}: CreateExtensionArgument): Extension => {
    const {fetchFn} = environmentContext
    const createExtensionAPI = ({dal, pointers}: CreateExtArgs) => {
        function setProperty(param: string, value: any) {
            const pointer = pointers.metadata.getSiteMetaDataPointer(param)
            dal.set(pointer, value)
        }

        function getProperty(param: string) {
            const pointer = pointers.metadata.getSiteMetaDataPointer(param)
            return dal.get(pointer)
        }

        function validateTitle(title: any) {
            if (!_.isString(title)) {
                return {success: false, errorCode: ERRORS.TEXT_IS_NOT_STRING}
            }

            if (title.length > VALIDATIONS.TITLE_MAX_LENGTH) {
                return {success: false, errorCode: ERRORS.TEXT_TOO_LONG, validLength: VALIDATIONS.TITLE_MAX_LENGTH}
            }

            return getCharacterValidation(title)
        }

        function validateDescription(description: any) {
            if (!_.isString(description)) {
                return {success: false, errorCode: ERRORS.TEXT_IS_NOT_STRING}
            }

            if (description.length > VALIDATIONS.DESCRIPTION_MAX_LENGTH) {
                return {
                    success: false,
                    errorCode: ERRORS.TEXT_TOO_LONG,
                    validLength: VALIDATIONS.DESCRIPTION_MAX_LENGTH
                }
            }

            return getCharacterValidation(description)
        }

        function validateKeywords(keywords: any) {
            if (!_.isString(keywords)) {
                return {success: false, errorCode: ERRORS.TEXT_IS_NOT_STRING}
            }

            if (keywords.length > VALIDATIONS.KEYWORDS_MAX_LENGTH) {
                return {success: false, errorCode: ERRORS.TEXT_TOO_LONG, validLength: VALIDATIONS.KEYWORDS_MAX_LENGTH}
            }

            const invalidChars = getInvalidKeywordChars(keywords)

            if (invalidChars?.length) {
                return {success: false, errorCode: ERRORS.KEYWORDS_INVALID_CHARS, errorContent: invalidChars}
            }

            return {success: true}
        }

        function clientSideValidation(metaTags: string, onSuccess: SuccessCB, onFailure: FailureCB) {
            return _validateCustomHeadTags(metaTags, onSuccess, onFailure)
        }

        function _validateCustomHeadTags(metaTags: string, onSuccess: SuccessCB, onFailure: FailureCB) {
            if (!_.isString(metaTags)) {
                onFailure({success: false, errorCode: ERRORS.TEXT_IS_NOT_STRING})
                return false
            }

            if (_.isEmpty(metaTags)) {
                onSuccess({success: true})
                return true
            }

            if (metaTags.length > VALIDATIONS.METATAGS_MAX_LENGTH) {
                onFailure({
                    success: false,
                    errorCode: ERRORS.TEXT_TOO_LONG,
                    validLength: VALIDATIONS.METATAGS_MAX_LENGTH
                })
                return false
            }

            if (!isLegalMetaTag(metaTags)) {
                onFailure({success: false, errorCode: ERRORS.METATAGS_INVALID_FORMAT})
                return false
            }

            if (_.includes(metaTags, '”')) {
                onFailure({success: false, errorCode: ERRORS.METATAGS_INVALID_CHARS})
                return false
            }

            return true
        }

        function validateCustomHeadTags(metaTags: string, onSuccess: SuccessCB, onFailure: FailureCB) {
            if (_validateCustomHeadTags(metaTags, onSuccess, onFailure)) {
                validateMetaTagsOnServer(metaTags, onSuccess, onFailure)
            }
        }

        function onMetaTagsServerValidationSuccess(onSuccess: SuccessCB, onFailure: FailureCB, response: any) {
            if (response.success) {
                onSuccess({success: true})
            } else {
                const {errorCode, errorDescription} = response
                let formattedErrorCode = ''
                let formattedErrorContent

                if (errorCode === 0) {
                    formattedErrorCode = ERRORS.SERVER_VALIDATION_TIMEOUT
                } else {
                    //todo Shimi_Liderman 1/4/15 17:37 Talk to server to separate these to 2 different errorCodes, and to
                    //return the invalid tag as an extra parameter instead of us having to parse the description
                    const matchedErrors = errorDescription.match(/'(.*)' tags are not allowed/)
                    const invalidPartIndicator = matchedErrors && _.size(matchedErrors) >= 1 ? matchedErrors[1] : ''
                    switch (invalidPartIndicator) {
                        case '':
                        case '#text':
                            formattedErrorCode = ERRORS.METATAGS_SERVER_INVALID_CODE
                            break
                        default:
                            formattedErrorCode = ERRORS.METATAGS_SERVER_INVALID_TAG
                            formattedErrorContent = invalidPartIndicator
                            break
                    }
                }
                onFailure({success: false, errorCode: formattedErrorCode, errorContent: formattedErrorContent})
            }
        }

        function onMetaTagsServerValidationError(callback: FailureCB) {
            callback({success: false})
        }

        async function validateMetaTagsOnServer(metaTags: string, onSuccess: SuccessCB, onFailure: FailureCB) {
            try {
                const response = await fetchFn('/html/head-tags/validate', {
                    method: 'POST',
                    headers: {'Content-Type': 'text/plain; charset=utf-8'},
                    body: metaTags
                })
                const responseAsJson = await response.json()
                onMetaTagsServerValidationSuccess(onSuccess, onFailure, responseAsJson)
            } catch (e) {
                console.log('seo validation failed: ', e)
                onMetaTagsServerValidationError(onFailure)
            }
        }

        function validateRedirectUrls(uriMappings: any) {
            if (!_.isObject(uriMappings)) {
                return {success: false, errorCode: ERRORS.REDIRECT_URI_MAPPING_IS_NOT_OBJECT}
            }

            let errorResult: any = null
            _.forEach(uriMappings, (toUri, fromUri) => {
                if (!_.isString(fromUri) || !_.isString(toUri)) {
                    errorResult = errorResult ?? {success: false, errorCode: ERRORS.REDIRECT_MAPPING_URIS_NOT_STRING}
                    return
                }
                if (fromUri.length > VALIDATIONS.REDIRECT_MAX_LENGTH) {
                    errorResult = errorResult ?? {
                        success: false,
                        errorCode: ERRORS.TEXT_TOO_LONG,
                        validLength: VALIDATIONS.REDIRECT_MAX_LENGTH
                    }
                    return
                }

                const invalidFromUriChars = getInvalidUriChars(fromUri)

                if (invalidFromUriChars?.length) {
                    errorResult = errorResult ?? {
                        success: false,
                        errorCode: ERRORS.REDIRECT_INVALID_CHARS,
                        errorContent: invalidFromUriChars
                    }
                    return
                }
            })

            return errorResult ?? {success: true}
        }

        function getInvalidUriChars(str: string) {
            return _.uniq(str.match(VALIDATIONS_REGEX.URI_CHARS))
        }

        function getInvalidHTMLChars(str: string) {
            return _.uniq(str.match(VALIDATIONS_REGEX.HTML_CHARS))
        }

        function getCharacterValidation(str: string) {
            const invalidHTMLChars = getInvalidHTMLChars(str)

            if (invalidHTMLChars?.length) {
                return {success: false, errorCode: ERRORS.TEXT_INVALID_CHARS, errorContent: invalidHTMLChars}
            }

            if (hasEmojis(str)) {
                return {success: false, errorCode: ERRORS.METATAGS_EMOJIS_NOT_SUPPORTED}
            }

            return {success: true}
        }

        function getInvalidKeywordChars(str: string) {
            return _.uniq(str.match(VALIDATIONS_REGEX.KEYWORD_CHARS))
        }

        function isLegalMetaTag(str: string) {
            return !_.isEmpty(str) && VALIDATIONS_REGEX.META_TAG.test(str)
        }

        function enableIndexing(isEnabled: any) {
            if (!_.isBoolean(isEnabled)) {
                throw new Error(ERRORS.SE_ENABLE_INDEX_PARAM_IS_NOT_BOOLEN)
            }
            setProperty(PROPERTY_NAMES.SE_INDEXABLE, isEnabled)
        }

        function isIndexingEnabled() {
            return getProperty(PROPERTY_NAMES.SE_INDEXABLE)
        }

        function setTitle(title: string) {
            const result = validateTitle(title)
            if (!result.success) {
                throw new Error(result.errorCode)
            }
            setProperty(PROPERTY_NAMES.SEO_TITLE, title)
        }

        function getTitle() {
            return getProperty(PROPERTY_NAMES.SEO_TITLE)
        }

        function createSeoMetaTag(tagName: string) {
            return {
                name: tagName,
                value: '',
                isProperty: false //seo meta tags are <meta name= ... > and not <meta property= ... >
            }
        }

        function updateSeoMetaTag(tagName: string, value: any) {
            const metaTags = _.cloneDeep(getProperty(PROPERTY_NAMES.META_TAGS))
            let seoMetaTag = _.find(metaTags, {name: tagName})
            if (!seoMetaTag) {
                seoMetaTag = createSeoMetaTag(tagName)
                metaTags.push(seoMetaTag)
            }

            seoMetaTag.value = value
            setProperty(PROPERTY_NAMES.META_TAGS, metaTags)
        }

        function setDescription(description: string) {
            const result = validateDescription(description)
            if (!result.success) {
                throw new Error(result.errorCode)
            }
            updateSeoMetaTag('description', description)
        }

        function getDescription() {
            const metaTags = getProperty(PROPERTY_NAMES.META_TAGS)
            const description = _.find(metaTags, {name: 'description'})
            return description?.value || ''
        }

        function setKeywords(keywords: string) {
            const result = validateKeywords(keywords)
            if (!result.success) {
                throw new Error(result.errorCode)
            }

            updateSeoMetaTag('keywords', keywords)
        }

        function getKeywords() {
            const metaTags = getProperty(PROPERTY_NAMES.META_TAGS)
            const keywords = _.find(metaTags, {name: 'keywords'})
            return keywords?.value || ''
        }

        function setCustomHeadTags(metaTags: string, onSuccess: () => void, onError: (err: any) => void) {
            validateCustomHeadTags(
                metaTags,
                () => {
                    setProperty(PROPERTY_NAMES.CUSTOM_HEAD_TAGS, metaTags)
                    onSuccess()
                },
                (validationResult: any) => {
                    onError(validationResult.errorCode)
                }
            )
        }

        function getCustomHeadTags() {
            return getProperty(PROPERTY_NAMES.CUSTOM_HEAD_TAGS)
        }

        function updateRedirectUrls(uriMappings: any) {
            const result = validateRedirectUrls(uriMappings)
            if (!result.success) {
                throw new Error(result.errorCode)
            }

            const currentMappings = getProperty(PROPERTY_NAMES.EXTERNAL_URI_MAPPINGS)
            const formattedCurrentMappings = {}
            _.forEach(currentMappings, mapping => {
                const fromUri = mapping.fromExternalUri
                const toUri = mapping.toWixUri

                formattedCurrentMappings[fromUri] = toUri
            })
            const mergedMappings = _.mergeWith(formattedCurrentMappings, uriMappings, (oldToUrl, newToUrl) =>
                _.isEmpty(newToUrl) ? oldToUrl : newToUrl
            )
            const formattedMergedMappings = _.map(mergedMappings, (toUri, fromUri) => ({
                fromExternalUri: fromUri,
                toWixUri: toUri
            }))

            setProperty(PROPERTY_NAMES.EXTERNAL_URI_MAPPINGS, formattedMergedMappings)
        }

        function getRedirectUrls() {
            const existingUrls = getProperty(PROPERTY_NAMES.EXTERNAL_URI_MAPPINGS)
            const formattedUrls = {}
            _.forEach(existingUrls, mapping => {
                formattedUrls[mapping.fromExternalUri] = mapping.toWixUri
            })
            return formattedUrls
        }

        function removeRedirectUrls(fromUris: any) {
            if (!_.isArray(fromUris)) {
                throw new Error(ERRORS.REDIRECT_FROM_URIS_IS_NOT_ARRAY)
            }

            const currentMappings = getProperty(PROPERTY_NAMES.EXTERNAL_URI_MAPPINGS)
            const newMappings = _.filter(currentMappings, mapping => {
                const fromUri = mapping.fromExternalUri
                return !_.includes(fromUris, fromUri)
            })
            setProperty(PROPERTY_NAMES.EXTERNAL_URI_MAPPINGS, newMappings)
            return true
        }

        return {
            seo: {
                indexing: {
                    enable: enableIndexing,
                    isEnabled: isIndexingEnabled
                },
                title: {
                    set: setTitle,
                    get: getTitle,
                    validate: validateTitle
                },
                description: {
                    set: setDescription,
                    get: getDescription,
                    validate: validateDescription
                },
                keywords: {
                    set: setKeywords,
                    get: getKeywords,
                    validate: validateKeywords
                },
                headTags: {
                    set: setCustomHeadTags,
                    get: getCustomHeadTags,
                    validate: validateCustomHeadTags,
                    clientSideValidation
                },
                redirectUrls: {
                    update: updateRedirectUrls,
                    remove: removeRedirectUrls,
                    get: getRedirectUrls,
                    validate: validateRedirectUrls
                }
            }
        }
    }

    return {
        name: 'seo',
        createExtensionAPI
    }
}

export {createExtension}
