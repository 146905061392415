import type {CreateExtArgs} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import type {DalPointers} from '../../../types'
import type {AppControllerAPI} from '../../appController'
import type {DataModelExtensionAPI} from '../../dataModel/dataModel'

const WIDGET_TO_NICKNAMES = {
    '142bb34d-3439-576a-7118-683e690a1e0d': 'gallery',
    '14517f3f-ffc5-eced-f592-980aaa0bbb5c': 'wixChat'
}

const getWidgetData = ({dal, pointers}: DalPointers, compData: Record<string, any>) => {
    if (!_.has(compData, ['applicationId']) || !_.has(compData, ['widgetId'])) {
        return {}
    }
    const clientSpecMapPtr = pointers.general.getClientSpecMap()
    const csm = dal.get(clientSpecMapPtr)

    const result = _.get(csm, [compData.applicationId, 'widgets', compData.widgetId]) || {}
    return _.cloneDeep(result)
}

export const baseTpaMetaData = {
    nickname: (createExtArgs: CreateExtArgs, compPointer: Pointer) => {
        const {extensionAPI} = createExtArgs
        const {appController} = extensionAPI as AppControllerAPI
        const nicknameFromStageData = appController.getNicknameFromStageData(compPointer)
        if (nicknameFromStageData) {
            return nicknameFromStageData
        }
        const {dataModel} = extensionAPI as DataModelExtensionAPI
        const compData = dataModel.components.getItem(compPointer, 'data')
        const widgetData = getWidgetData(createExtArgs, compData)
        let nickname = ''
        const widgetId = _.get(widgetData, ['widgetId'])

        if (WIDGET_TO_NICKNAMES.hasOwnProperty(widgetId)) {
            nickname = WIDGET_TO_NICKNAMES[widgetId]
        } else {
            nickname = _.camelCase(widgetData.tpaWidgetId)
        }

        return nickname
    },
    isRepeatable: false
}
