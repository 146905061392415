import * as verticalDividersConversion from './verticalDividersConversion'
import * as virtualGroupHandler from './virtualGroupHandler'
import {getAllCompsInStructure, isDockingAllowed} from './conversionUtils'
import {isMobileOnlyComponent} from './mobileOnlyComponents/mobileOnlyComponentsUtils'
import _ from 'lodash'
import type {ObjMap, DeepStructure, ConversionSettings} from '../types'

export function postProcessStructure(component: DeepStructure, children: DeepStructure[], settings: ConversionSettings): void {
    verticalDividersConversion.postprocess(component)
    virtualGroupHandler.flattenGroups(component, children, settings)
}

function removeConversionData(comp: DeepStructure): DeepStructure {
    if (comp.layout) {
        delete comp.layout.anchors
        if (!isMobileOnlyComponent(comp) && !isDockingAllowed(comp)) {
            delete comp.layout.docked
        }
    }
    delete comp.conversionData
    return <DeepStructure>comp
}

export function removeConversionDataRecursively(component: DeepStructure): DeepStructure {
    const allDescendants = getAllCompsInStructure(component) as ObjMap<DeepStructure>
    _.forEach(allDescendants, removeConversionData)
    return removeConversionData(component)
}
