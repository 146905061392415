/* eslint-disable @typescript-eslint/naming-convention */
import type {CreateExtArgs, Extension, ExtensionAPI, InitializeExtArgs} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import type {RoutersAPI} from './routers'
import _ from 'lodash'

interface Replacer {
    role: string
    active: boolean
}

interface Rule {
    name: string
    pageRole: string
    predicate: string
    conditions: any[]
}

interface ReplacerData extends Replacer {
    originalRole: string
}

type Replacers = Replacer[]

type ReplacersMap = Record<string, Replacers>
type RulesMap = Record<string, Rule>
type RoleVariationsMap = Record<string, string[]>
export interface PlatformAppReflowAPI extends ExtensionAPI {
    platformAppReflow: {
        removePageFromRouter(routerPtr: Pointer, pageRoles: string[]): void
    }
}
const wrapValuesWithKeyIfNotEmpty = (valuesToValidate: Record<string, any> | any[], key: string) => {
    const arrayToValidate = Array.isArray(valuesToValidate) ? valuesToValidate : Object.keys(valuesToValidate)
    return arrayToValidate.length ? {[key]: valuesToValidate} : {}
}
const createExtension = (): Extension => {
    const createExtensionAPI = ({extensionAPI}: CreateExtArgs): PlatformAppReflowAPI => {
        const {routers} = extensionAPI as RoutersAPI

        const removePageFromReplacers = (replacers: ReplacersMap, pageRole: string) => {
            return Object.entries(replacers).reduce((acc, [currPageRole, replacersByPageRole]) => {
                const updatedReplacersByPageRole = replacersByPageRole.filter(replacer => pageRole !== replacer.role)
                return {
                    ...acc,
                    ...wrapValuesWithKeyIfNotEmpty(updatedReplacersByPageRole, currPageRole)
                }
            }, {})
        }

        const removeRules = (rules: RulesMap, pageRole: string) => {
            const ruleIds: string[] = []
            const updatedRules = Object.entries(rules).reduce((acc, [currRuleId, ruleData]) => {
                if (ruleData.pageRole === pageRole) {
                    ruleIds.push(currRuleId)
                    return acc
                }
                return {
                    ...acc,
                    [currRuleId]: ruleData
                }
            }, {})
            return {excludedRuleIds: ruleIds, rules: updatedRules}
        }

        const updateRoleVariations = (roleVariations: RoleVariationsMap, excludedRuleIds: string[]) => {
            return Object.entries(roleVariations).reduce((acc, [pageId, pageRuleIds]) => {
                const updatedPageRuleIds = pageRuleIds.filter(id => !excludedRuleIds.includes(id))
                return {
                    ...acc,
                    ...wrapValuesWithKeyIfNotEmpty(updatedPageRuleIds, pageId)
                }
            }, {})
        }

        const getPageReplacerData = (pageRole: string, replacersMap?: ReplacersMap): ReplacerData | undefined => {
            let replacerData: ReplacerData | undefined
            Object.entries(replacersMap || {}).forEach(([currPageRole, replacers]) => {
                const replacer = replacers.find(currReplacer => currReplacer.role === pageRole)
                if (replacer) {
                    replacerData = {...replacer, originalRole: currPageRole}
                }
            })
            return replacerData
        }

        const removeReplacerPage = (pageRole: string, routerConfig: Record<string, any>, routerPtr: Pointer) => {
            const updatedReplacers = removePageFromReplacers(routerConfig.replacers, pageRole)
            const restOfRouterConfig = _.omit(routerConfig, 'replacers')
            routers.updateRouter(routerPtr, {
                config: {
                    ...restOfRouterConfig,
                    ...wrapValuesWithKeyIfNotEmpty(updatedReplacers, 'replacers')
                }
            })
        }

        const removeVariantPage = (pageRole: string, routerConfig: Record<string, any>, routerPtr: Pointer) => {
            const filteredRules = removeRules(routerConfig.rules, pageRole)
            const updatedRoleVariations = updateRoleVariations(
                routerConfig.roleVariations,
                filteredRules.excludedRuleIds
            )
            const restOfConfig = _.omit(routerConfig, ['rules', 'roleVariations'])
            routers.updateRouter(routerPtr, {
                config: {
                    ...restOfConfig,
                    ...wrapValuesWithKeyIfNotEmpty(filteredRules.rules, 'rules'),
                    ...wrapValuesWithKeyIfNotEmpty(updatedRoleVariations, 'roleVariations')
                }
            })
        }
        const getPageVariations = (
            pageRole: string,
            replacersMap?: ReplacersMap,
            roleVariations?: Record<string, string[]>,
            rules?: RulesMap
        ) => {
            const replacer = replacersMap?.[pageRole]?.[0]?.role
            const ruleIds = roleVariations?.[pageRole] ?? []
            const variants = ruleIds.map(id => rules?.[id].pageRole)
            if (replacer) {
                variants.push(replacer)
            }
            return variants
        }

        const removeAllPageVariations = (
            pageRole: string,
            routerConfig: Record<string, any>,
            routerPtr: Pointer,
            routerPages: Record<string, string>
        ) => {
            const variationsByPageRole = getPageVariations(
                pageRole,
                routerConfig?.replacers,
                routerConfig?.roleVariations,
                routerConfig?.rules
            )
            variationsByPageRole.forEach(variation => {
                if (variation) {
                    const _pageId = routerPages[variation]
                    routers.pages.remove(routerPtr, _pageId)
                }
            })
        }

        const getPageVariantData = (pageRole: string, rules?: RulesMap): Rule[] | [] => {
            const variantData: Rule[] = []
            Object.entries(rules ?? {}).forEach(([, ruleData]) => {
                if (ruleData.pageRole === pageRole) {
                    variantData.push(ruleData)
                }
            })
            return variantData
        }

        const removePageFromRouter = (routerPtr: Pointer, pageRoles: string[]) => {
            const routerData = routers.getRouterByPointer(routerPtr)
            if (!routerData) {
                throw new Error(`router with pointer ${routerPtr} not found`)
            }

            if (pageRoles.length === 1) {
                const pageRole = pageRoles[0]
                const routerConfig = routerData.config
                const replacerData = getPageReplacerData(pageRole, routerConfig?.replacers)
                // if pageRole is a replacer page
                if (routerConfig.replacers && replacerData) {
                    removeReplacerPage(pageRole, routerConfig, routerPtr)
                    // if pageRole is variant page
                } else if (
                    routerConfig.roleVariations &&
                    routerConfig.rules &&
                    getPageVariantData(pageRole, routerConfig?.rules).length
                ) {
                    removeVariantPage(pageRole, routerConfig, routerPtr)
                    // if pageRole is the original page
                } else {
                    removeAllPageVariations(pageRole, routerConfig, routerPtr, routerData.pages)
                }
            }
        }

        return {platformAppReflow: {removePageFromRouter}}
    }

    const initialize = async ({eventEmitter, EVENTS, extensionAPI}: InitializeExtArgs) => {
        const {platformAppReflow} = extensionAPI as PlatformAppReflowAPI
        eventEmitter.on(EVENTS.ROUTERS.ON_REMOVE_PAGE, platformAppReflow.removePageFromRouter)
    }

    return {
        name: 'platformAppReflow',
        dependencies: new Set(['routers']),
        createExtensionAPI,
        initialize
    }
}

export {createExtension}
