import _ from 'lodash'

import * as activeVariantsPointers from './activeVariants'
import * as renderFlagsPointers from './renderFlags'
import * as svgShapesPointers from './svgShapesPointer'
import * as realTimeConfigPointers from './realTimeConfig'
import * as runtimePointers from './runtime'
import * as multilingualPointers from './multilingual'
import * as ghostablesPointers from './ghostables'
import * as activeModesPointers from './activeModes'
import * as customElementsPointers from './customElements'
import * as displayedOnlyComponentsPointers from './displayedOnlyComponents'
import * as blocksPointers from './blocks'

export default function () {
    return _.merge(
        {},
        activeVariantsPointers.createPointersMethods(),
        renderFlagsPointers.createPointersMethods(),
        svgShapesPointers.createPointersMethods(),
        realTimeConfigPointers.createPointersMethods(),
        runtimePointers.createPointersMethods(),
        multilingualPointers.createPointersMethods(),
        ghostablesPointers.createPointersMethods(),
        activeModesPointers.createPointersMethods(),
        customElementsPointers.createPointersMethods(),
        displayedOnlyComponentsPointers.createPointersMethods(),
        blocksPointers.createPointersMethods()
    )
}
