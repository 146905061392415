import _ from 'lodash'
import {wixappsCore} from '@wix/santa-ds-libs'
import dataModel from '../../../dataModel/dataModel'
import pathUtils from '../pathUtils'
import type {PS} from '@wix/document-services-types'

export default {
    componentsClasses: ['wixapps.integration.components.AppPart2'],
    collect(ps: PS, comp) {
        function getViewDef(descriptor: any, viewName: string, type: string, formatName: string) {
            const idWithFormat = [type, viewName, formatName].join('|')
            const idWithoutFormat = [type, viewName].join('|')

            return descriptor.views[idWithFormat] || descriptor.views[idWithoutFormat]
        }

        function getPartViews(descriptor: any, partDefinition: any, formatName: string) {
            const compViews = {
                Array: getViewDef(descriptor, partDefinition.viewName, 'Array', formatName)
            }
            compViews[partDefinition.type] = getViewDef(
                descriptor,
                partDefinition.viewName,
                partDefinition.type,
                formatName
            )
            return compViews
        }

        const collectedStyleIds: string[] = []
        const data = dataModel.getDataItem(ps, comp)
        const descriptor = ps.wixappsDAL.getByPath(pathUtils.getRepoPath())
        const partDefinition = ps.wixappsDAL.getByPath(pathUtils.getPartPath(data.appPartName))
        const views = partDefinition && getPartViews(descriptor, partDefinition, '*')

        if (views) {
            _.forEach(views, viewDef => {
                wixappsCore.viewsUtils.traverseViews(viewDef, currentViewDef => {
                    if (currentViewDef) {
                        const styleId = _.get(currentViewDef, ['comp', 'style'])
                        if (styleId) {
                            collectedStyleIds.push(styleId)
                        }
                    }
                })
            })
        }
        return collectedStyleIds
    }
}
