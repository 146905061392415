import type {CreateExtArgs, CreateExtensionArgument, Extension, ExtensionAPI, DmApis} from '@wix/document-manager-core'
import type {ClientSpecMap} from '@wix/document-services-types'
import type {RMApi} from './rendererModel'
import {ReportableError} from '@wix/document-manager-utils'

export interface MiniSiteExtensionAPI extends ExtensionAPI {
    miniSite: {
        loadCSM(msId: string): Promise<void>
        getLoadedCSM(msId: string): ClientSpecMap | void
        updateCSMForMSID(msId: string, csm: ClientSpecMap): void
    }
}

const getPublicCSMEndpoint = (msId: string) =>
    `/_api/public-csm-server/v1/client-spec-map/public/${msId}?doNotMutate=true&https=true`

const createExtension = ({environmentContext}: CreateExtensionArgument): Extension => {
    let currentMSID: string
    const loadedCSM: Map<string, ClientSpecMap> = new Map()
    const {fetchFn} = environmentContext

    const createExtensionAPI = ({extensionAPI, dal, pointers, coreConfig}: CreateExtArgs): MiniSiteExtensionAPI => {
        const {rendererModel} = extensionAPI as RMApi

        const fetchCSMForMSID: (msId: string) => Promise<ClientSpecMap> = async (msId: string) => {
            let response: Response
            const interaction = 'MiniSitesFetchCsmFromPublic'
            try {
                coreConfig.logger.interactionStarted(interaction)
                response = await fetchFn(getPublicCSMEndpoint(msId))
                const result = await response!.json()
                coreConfig.logger.interactionEnded(interaction)
                return result
            } catch (e) {
                const err = new ReportableError({
                    message: 'Cannot fetch client spec map from public',
                    errorType: 'FETCH_PUBLIC_CSM_FOR_MINISITES_FAILURE',
                    extras: {
                        //@ts-ignore
                        status: response?.status
                    }
                })
                coreConfig.logger.captureError(err)
                throw err
            }
        }
        const loadCSM = async (msId: string) => {
            if (currentMSID === msId) {
                return
            }
            const currentCSM = dal.get(pointers.general.getClientSpecMap())
            loadedCSM.set(currentMSID, currentCSM)
            if (!loadedCSM.has(msId)) {
                const csm = await fetchCSMForMSID(msId)
                loadedCSM.set(msId, csm)
            }
            if (loadedCSM.has(msId)) {
                currentMSID = msId
                dal.set(pointers.general.getClientSpecMap(), loadedCSM.get(msId))
            }
        }

        const getLoadedCSM = (msId: string) => {
            if (loadedCSM.has(msId)) {
                return loadedCSM.get(msId)
            }
            console.error(`Tried to get loaded CSM for msid ${msId} from miniSite but the CSM has not been loaded yet`)
        }

        const updateCSMForMSID = (msId: string, csm: ClientSpecMap) => {
            if (!currentMSID) {
                currentMSID = rendererModel.getMetaSiteId()
            }
            if (currentMSID === msId) {
                dal.set(pointers.general.getClientSpecMap(), csm)
            }
            loadedCSM.set(msId, csm)
        }

        return {
            miniSite: {
                loadCSM,
                getLoadedCSM,
                updateCSMForMSID
            }
        }
    }

    const createPublicAPI = ({extensionAPI}: DmApis) => {
        const {miniSite} = extensionAPI as MiniSiteExtensionAPI
        return {
            miniSite: {
                loadCSM: miniSite.loadCSM
            }
        }
    }

    return {
        name: 'miniSite',
        dependencies: new Set(['rendererModel']),
        createExtensionAPI,
        createPublicAPI
    }
}

export {createExtension}
