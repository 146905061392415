import anchorsGenerator from './anchorsGenerator'
import skinAnchorsMetaData from './skinAnchorsMetaData'

/**
 * @class layoutAnchorsUtils
 */
export default {
    createPageAnchors: anchorsGenerator.createPageAnchors,
    createMobileTightMasterPageAnchors: anchorsGenerator.createMobileTightMasterPageAnchors,
    createMobileTightSectionsAnchors: anchorsGenerator.createMobileTightSectionsAnchors,
    createChildrenAnchors: anchorsGenerator.createChildrenAnchors,
    getNonAnchorableHeightForSkin: skinAnchorsMetaData.getNonAnchorableHeightForSkin
}
