/*eslint lodash/prefer-lodash-typecheck:0, lodash/prefer-lodash-method:0*/
const TARGET = Symbol('TARGET')

/**
 * @param {string} key
 * @param {string} operation
 * @returns {string}
 */
function getErrorMsg(key: string, operation: string) {
    return `Attempt to ${operation} key "${key}" on immutable object. Clone using deepClone() and then modify.`
}

/**
 * @param {*} obj
 * @returns {boolean}
 */
const isObject = <T>(obj: T | undefined | null): obj is T => typeof obj === 'object' && obj !== null

class DefaultProxyHandler implements ProxyHandler<any> {
    /**
     * @param {*} target
     * @param {*} key
     * @returns {*}
     */
    get(target: any, key: any /*, receiver*/): any {
        if (key === TARGET) {
            return target
        }
        const obj = target[key]
        if (isObject(obj)) {
            return new Proxy(obj, this)
        }
        return obj
    }

    /**
     * @param {*} target
     * @param {string} key
     */
    set(target: any, key: string): boolean {
        throw new Error(getErrorMsg(key as string, 'set'))
    }

    /**
     * @param {*} target
     * @param {string} key
     * @returns {boolean}
     */
    deleteProperty(target: any, key: string) {
        if (key in target) {
            throw new Error(getErrorMsg(key, 'delete'))
        }
        return false
    }

    /**
     * @param {*} target
     * @param {*} key
     */
    defineProperty(target: any, key: string): boolean {
        throw new Error(getErrorMsg(key, 'define'))
    }
}

const proxyHandler = new DefaultProxyHandler()

class ProxyHandlerForMap extends DefaultProxyHandler {
    /**
     * @param {*} target
     * @param {*} key
     * @returns {*}
     */
    get(target: any, key: any /*, receiver*/): any {
        if (key === TARGET) {
            return target
        }
        const obj = target.get(key)
        if (isObject(obj)) {
            return new Proxy(obj, proxyHandler)
        }
        return obj
    }

    ownKeys(target: Map<string, any>) {
        return [...target.keys()]
    }

    has(target: Map<string, any>, name: string) {
        return target.has(name)
    }

    getPrototypeOf() {
        return Object.prototype
    }

    getOwnPropertyDescriptor(target: any, p: string | symbol): PropertyDescriptor | undefined {
        if (!target.has(p)) {
            return undefined
        }
        const val = this.get(target, p)

        return {
            writable: false,
            configurable: true,
            enumerable: true,
            value: val
        }
    }
}

const proxyHandlerForMap = new ProxyHandlerForMap()

class ProxyHandlerForMapOfMaps extends ProxyHandlerForMap {
    /**
     * @param {*} target
     * @param {*} key
     * @returns {*}
     */
    get(target: any, key: any /*, receiver*/): any {
        if (key === TARGET) {
            return target
        }
        const obj = target.get(key)
        if (isObject(obj)) {
            return new Proxy(obj, proxyHandlerForMap)
        }
        return obj
    }
}
const proxyHandlerForMapOfMaps = new ProxyHandlerForMapOfMaps()

const lastProxy: {
    rawObject: any | undefined
    proxy: any | undefined
} = {
    rawObject: undefined,
    proxy: undefined
}

const creator =
    (handler: object) =>
    <T extends object | undefined>(obj: T): T => {
        if (isObject(obj)) {
            // The same object is often requested more than once in succession from the Dal, so this
            // optimisation reduces the number of proxies that are created
            if (lastProxy.rawObject === obj) {
                return lastProxy.proxy
            }

            // if not already a proxy
            if (obj && !obj[TARGET]) {
                lastProxy.rawObject = obj
                lastProxy.proxy = new Proxy(obj as object, handler)
                return lastProxy.proxy
            }
        }

        return obj
    }

/**
 * Returns a proxy that provides read only access to the underlying nested object
 * An attempt to modify the underlying object will result in an exception being thrown
 * @param {*} obj
 * @returns {*}
 */
const createImmutableProxy = creator(proxyHandler)

/**
 * Returns an immutable proxy for a map such that the entire data structure is viewed externally as a plain javascript object
 * An attempt to modify the underlying object will result in an exception being thrown
 * @param {*} obj
 * @returns {*}
 */
const createImmutableProxyForMap = creator(proxyHandlerForMap) as (
    obj: Map<string, any> | undefined
) => Record<any, any>

/**
 * Returns an immutable proxy for a map of maps such that the entire data structure is viewed externally as a plain javascript object
 * An attempt to modify the underlying object will result in an exception being thrown
 * @param {*} obj
 * @returns {*}
 */
const createImmutableProxyForMapOfMaps = creator(proxyHandlerForMapOfMaps) as (
    obj: Map<string, any> | undefined
) => Record<any, any>

function deepCloneDataArray<T>(obj: T[]): T[] {
    const copy = obj.slice(0)
    for (let i = 0; i < copy.length; ++i) {
        const val = obj[i]
        if (typeof val === 'object' && val !== null) {
            copy[i] = deepCloneDataObject(val)
        }
    }
    return copy
}

function deepCloneDataObject(obj: any): any {
    if (Array.isArray(obj)) {
        return deepCloneDataArray(obj)
    }
    if (obj instanceof Map) {
        const copy = {}
        for (const [key, value] of obj) {
            if (typeof value === 'object' && value !== null) {
                copy[key] = deepCloneDataObject(value)
            } else {
                copy[key] = value
            }
        }
        return copy
    }
    const copy = {...obj}
    // eslint-disable-next-line guard-for-in
    for (const key in obj) {
        const val = obj[key]
        if (typeof val === 'object' && val !== null) {
            copy[key] = deepCloneDataObject(val)
        }
    }
    return copy
}

/**
 * @param {*} obj
 * @returns {*}
 */
function deepClone<T>(obj: T): T {
    if (isObject(obj)) {
        // This is a performance optimization for proxies to prevent the entire nested object being proxied.
        // It's typically only needed for the root object of a proxy so we do it here
        // instead of in the recursive clone function for every nested object
        const proxyTarget = obj[TARGET]
        return deepCloneDataObject(proxyTarget || obj)
    }
    return obj
}

/**
 * @param {*} a
 * @returns {*}
 */
const getRef = (a: any) => (isObject(a) ? a[TARGET] || a : a)

/**
 * @param {*} a
 * @param {*} b
 * @returns {boolean}
 */
function referenceCompare(a: any, b: any) {
    a = getRef(a)
    b = getRef(b)
    return a === b
}

function isImmutableProxy(obj: any) {
    return isObject(obj) && !!obj[TARGET]
}

export {
    createImmutableProxy,
    createImmutableProxyForMap,
    createImmutableProxyForMapOfMaps,
    deepClone,
    referenceCompare,
    isImmutableProxy
}
