import type {SnapshotDal} from '@wix/document-manager-core'
import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import type {ImmutableSnapshot} from '../types'

export default function (
    currentImmutableSnapshot: ImmutableSnapshot,
    metaSiteResponse,
    currentSnapshotDal: SnapshotDal
) {
    const clientSpecMap = currentSnapshotDal
        ? currentSnapshotDal.getValue({type: 'rendererModel', id: 'clientSpecMap'})
        : currentImmutableSnapshot.getIn(['rendererModel', 'clientSpecMap']).toJS()

    return [
        {
            path: ['rendererModel', 'clientSpecMap'],
            value: _.assign({}, clientSpecMap, metaSiteResponse.payload.clientSpecMap)
        },
        {
            path: ['rendererModel', 'clientSpecMapCacheKiller'],
            value: {cacheKiller: santaCoreUtils.guidUtils.getGUID()}
        }
    ]
}
