import type {Pointer, PS} from '@wix/document-services-types'
import * as santaCoreUtils from '@wix/santa-core-utils'
import dataModel from '../dataModel/dataModel'

const TYPE_OF_MAIN_CONTAINER_INSIDE_POPUP = 'wysiwyg.viewer.components.PopupContainer'

function getPopupContainer(ps: PS, popupPagePointer: Pointer) {
    const childrenPointers = ps.pointers.components.getChildren(popupPagePointer)

    if (childrenPointers.length > 1) {
        throw new Error(
            `More than one child of the popup page was found. A popup page must have only single direct child of type ${TYPE_OF_MAIN_CONTAINER_INSIDE_POPUP}`
        )
    }
    if (childrenPointers.length < 1) {
        throw new Error('No direct child of the popup page was found')
    }

    const candidatePointer = childrenPointers[0]
    const candidateType = ps.dal.get(ps.pointers.getInnerPointer(candidatePointer, 'componentType'))

    if (candidateType !== TYPE_OF_MAIN_CONTAINER_INSIDE_POPUP) {
        throw new Error(
            `Direct child of the popup is of type ${candidateType}. Expected type: ${TYPE_OF_MAIN_CONTAINER_INSIDE_POPUP}`
        )
    }

    return candidatePointer
}

function isPopupContainer(componentType: string) {
    return componentType === santaCoreUtils.constants.POPUP.POPUP_CONTAINER.COMPONENT_TYPE
}

function isPopupFullWidth(ps: PS, popupPointer: Pointer) {
    const popupContainerPointer = getPopupContainer(ps, popupPointer)
    const popupContainerProps = dataModel.getPropertiesItem(ps, popupContainerPointer)
    return popupContainerProps.alignmentType === 'fullWidth'
}

function isPopup(ps: PS, pageId: string) {
    if (pageId === 'masterPage') {
        return false
    }
    const pageDataItemPointer = ps.pointers.data.getDataItemFromMaster(pageId)
    return ps.dal.get(ps.pointers.getInnerPointer(pageDataItemPointer, 'isPopup'))
}

export default {
    getPopupContainer,
    isPopupContainer,
    isPopupFullWidth,
    isPopup
}
