import type {PS} from '@wix/document-services-types'
import appStudioDataModel from './appStudioDataModel'

const setAppSSRCache = (ps: PS, ssrCache: boolean) => {
    const appStudioData = appStudioDataModel.getAppStudioData(ps)
    appStudioDataModel.updateAppStudioMetaData(ps, {...appStudioData, ssrCache})
}
const getAppSSRCache = (ps: PS) => {
    const appStudioData = appStudioDataModel.getAppStudioData(ps)
    return appStudioData.ssrCache
}
export default {
    set: setAppSSRCache,
    get: getAppSSRCache
}
