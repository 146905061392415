import type {Pointer, PossibleViewModes, PS} from '@wix/document-services-types'
import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import constants from '../constants/constants'
import page from '../page/page'
import component from '../component/component'
import componentBehaviors from '../component/componentBehaviors'
import documentMode from '../documentMode/documentMode'
import meshLayoutReadyUtils from '../documentServicesDataFixer/fixers/utils/meshLayoutReadyUtils'
import soapOrderFixer from '../documentServicesDataFixer/fixers/soapOrderFixer'
import mobileConversionFacade from '../mobileConversion/mobileConversionFacade'
import mobileUtil from '../mobileUtilities/mobileUtilities'
import layoutSettingsUtils from './utils/layoutSettingsUtils'

const COMPS_WITH_SLIDES = [
    'wysiwyg.viewer.components.BoxSlideShow',
    'wysiwyg.viewer.components.StripContainerSlideShow'
]

const MIGRATION_STATUS = {
    CANCELLED: 'CANCELLED',
    DONE: 'DONE',
    INIT: 'INIT',
    RUNNING: 'RUNNING'
}

let migrationStatus = MIGRATION_STATUS.INIT

const createNextSlideAsync = (ps: PS, compRef: Pointer, slideIndex: number) =>
    new Promise<void>(resolve => {
        componentBehaviors.executeBehavior(ps, compRef, 'moveToSlide', {slide: slideIndex}, resolve)
    })

async function executeSequentially(ps: PS, compRef: Pointer, slidesIndexes): Promise<number> {
    const x = await createNextSlideAsync(ps, compRef, slidesIndexes.pop())
    if (_.isEmpty(slidesIndexes)) {
        // @ts-expect-error
        return x
    }
    return await executeSequentially(ps, compRef, slidesIndexes)
}

async function navigateToAllSlides(ps: PS, compInfo) {
    const slidesIndexes = compInfo.slides
    if (!_.isEmpty(slidesIndexes)) {
        return executeSequentially(ps, compInfo.pointer, slidesIndexes)
    }
}

const getSiteStructureAnchors = (ps: PS, isMigrationForced = false) =>
    _.reduce(
        page.getPageIdList(ps, false, true),
        (anchorsPerPage, pageId) => {
            mobileUtil.getSupportedViewModes(ps).forEach(viewMode => {
                const pagePointer = ps.pointers.components.getPage(pageId, viewMode)
                const compsWithAnchors = getCompsWithAnchorsOnPage(ps, pagePointer, isMigrationForced)
                if (!_.isEmpty(compsWithAnchors)) {
                    anchorsPerPage[viewMode][pageId] = compsWithAnchors
                }
            })
            return anchorsPerPage
        },
        {
            [constants.VIEW_MODES.DESKTOP]: {},
            [constants.VIEW_MODES.MOBILE]: {}
        }
    )

const getCompsWithAnchorsOnPage = (ps: PS, pagePointer: Pointer, isMigrationForced: boolean) => {
    const childrenPointers = ps.pointers.components.getChildrenRecursively(pagePointer)
    const compsWithAnchors = isMigrationForced
        ? childrenPointers
        : childrenPointers.filter(_.partial(doesCompHaveAnchorsInStructure, ps))
    if (!_.isEmpty(compsWithAnchors)) {
        return compsWithAnchors.map(compPointer => {
            const hasSlides = doesComponentHaveSlides(ps, compPointer)
            return {pointer: compPointer, hasSlides, slides: hasSlides ? getSlidesWithAnchors(ps, compPointer) : null}
        })
    }
    return []
}

const doesComponentHaveSlides = (ps: PS, compPointer: Pointer) =>
    _.includes(COMPS_WITH_SLIDES, component.getType(ps, compPointer))

const getSlidesWithAnchors = (ps: PS, compPointer: Pointer) => {
    const slides = ps.pointers.components.getChildren(compPointer)
    const slidesWithAnchors = _.map(slides, _.partial(doesCompHaveAnchorsInStructure, ps))
    return getTruthyIndexes(slidesWithAnchors)
}

const getTruthyIndexes = (array: any[]): number[] =>
    _.reduce(
        array,
        (acc, val, idx) => {
            if (val) {
                acc.push(idx)
            }
            return acc
        },
        []
    )

const doesCompHaveAnchorsInStructure = (ps: PS, compPointer: Pointer): boolean => {
    const compLayoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
    const compLayout = ps.dal.full.get(compLayoutPointer)
    return (
        !_.isEmpty(_.get(compLayout, 'anchors')) ||
        _.some(ps.pointers.components.getChildren(compPointer), _.partial(doesCompHaveAnchorsInStructure, ps))
    )
}

const navigateThroughCompsWithSlides = (ps: PS, compsWithSlidesToNavigate) =>
    Promise.all(compsWithSlidesToNavigate.map(navigateToAllSlides.bind(null, ps)))

const waitForChangesToApply = (ps: PS) => new Promise<void>(res => ps.setOperationsQueue.waitForChangesApplied(res))

async function navToPages(ps: PS, pageToCompsAnchors, updateCallback) {
    const navigateSerially = async (totalNumOfPages: number, pageIds: string[]) => {
        await promisifiedNavigateToPage(ps, _.head(pageIds))
        await navigateThroughCompsWithSlides(ps, getCompPointersWithSlides(pageToCompsAnchors[_.head<string>(pageIds)]))
        await updateCallback({status: 'page-migrated', pageId: _.head(pageIds)})
        await waitForChangesToApply(ps)
        pageIds.shift()
        checkMigrationStatus()
        if (!_.isEmpty(pageIds)) {
            await navigateSerially(totalNumOfPages, pageIds)
        }
    }
    if (!_.isEmpty(pageToCompsAnchors)) {
        await navigateSerially(_.size(pageToCompsAnchors), _.keys(pageToCompsAnchors))
    }
}

const getCompPointersWithSlides = pageCompsWithAnchorsInfo => _.filter(pageCompsWithAnchorsInfo, {hasSlides: true})

const checkMigrationStatus = () => {
    if (migrationStatus !== MIGRATION_STATUS.RUNNING) {
        throw migrationStatus
    }
}

function promisifiedNavigateToPage(ps: PS, pageId: string) {
    ps.setOperationsQueue.runSetOperation(page.navigateTo, [ps, pageId], {
        methodName: 'page.navigateTo',
        waitingForTransition: true,
        noBatchingAfter: true
    })
    return waitForChangesToApply(ps)
}

async function navigateToAllPages(ps: PS, viewMode: PossibleViewModes, sitePagesWithAnchors, updateCallback) {
    ps.setOperationsQueue.runSetOperation(documentMode.setViewMode, [ps, viewMode])
    await waitForChangesToApply(ps)
    checkMigrationStatus()
    await updateCallback({status: 'view-mode', viewMode})
    await waitForChangesToApply(ps)
    await navToPages(ps, sitePagesWithAnchors, updateCallback)
}

async function navigateToPageOnViewMode(ps: PS, {pageId, viewMode}: {pageId: string; viewMode: PossibleViewModes}) {
    ps.setOperationsQueue.runSetOperation(documentMode.setViewMode, [ps, viewMode])
    await waitForChangesToApply(ps)
    await promisifiedNavigateToPage(ps, pageId)
}

async function cleanSiteAnchors(ps: PS, sitePagesWithAnchors, updateCallback) {
    await updateCallback({
        status: 'init',
        maxValue:
            _.size(sitePagesWithAnchors[constants.VIEW_MODES.DESKTOP]) +
            _.size(sitePagesWithAnchors[constants.VIEW_MODES.MOBILE]),
        value: 0
    })
    await navigateToAllPages(
        ps,
        constants.VIEW_MODES.DESKTOP,
        sitePagesWithAnchors[constants.VIEW_MODES.DESKTOP],
        updateCallback
    )
    await navigateToAllPages(
        ps,
        constants.VIEW_MODES.MOBILE,
        sitePagesWithAnchors[constants.VIEW_MODES.MOBILE],
        updateCallback
    )
    await waitForChangesToApply(ps)
}

const getLayoutSettings = (ps: PS) => layoutSettingsUtils.getLayoutSettings(ps)

const setTpasRenderFlags = (ps: PS, isEnabled: boolean) => {
    documentMode.enableRenderTPAsIframe(ps, isEnabled)
    return waitForChangesToApply(ps)
}

const disableTPAs = (ps: PS) => setTpasRenderFlags(ps, false)
const enableTPAs = (ps: PS) => setTpasRenderFlags(ps, true)

const setTransitionsRenderFlags = (ps: PS, isEnabled: boolean) => {
    documentMode.enableComponentTransitions(ps, isEnabled)
    documentMode.enableStubifyComponents(ps, isEnabled)
    documentMode.enablePlaying(ps, isEnabled)
    documentMode.enablePageTransitions(ps, isEnabled)
    return waitForChangesToApply(ps)
}

const disableTransitions = (ps: PS) => setTransitionsRenderFlags(ps, false)
const enableTransitions = (ps: PS) => setTransitionsRenderFlags(ps, true)

const setEnableFetchDynamicPageInnerRoutesFlag = (ps: PS, isEnabled: boolean) => {
    documentMode.enableFetchDynamicPageInnerRoutes(ps, isEnabled)
    return waitForChangesToApply(ps)
}

const disableFetchDynamicPageInnerRoutes = (ps: PS) => setEnableFetchDynamicPageInnerRoutesFlag(ps, false)
const enableFetchDynamicPageInnerRoutes = (ps: PS) => setEnableFetchDynamicPageInnerRoutesFlag(ps, true)

const isSiteLayoutMechanismMesh = (ps: PS) =>
    _.get(getLayoutSettings(ps), 'mechanism') === santaCoreUtils.constants.LAYOUT_MECHANISMS.MESH
const shouldRunMeshMigrator = (ps: PS) => !isSiteLayoutMechanismMesh(ps)

const resetRenderFlags = async (ps: PS) => {
    await enableTPAs(ps)
    await enableTransitions(ps)
    await enableFetchDynamicPageInnerRoutes(ps)
}

const handleError = async (ps: PS, pageToNavTo: {pageId: string; viewMode: PossibleViewModes}, error, onFail) => {
    await navigateToPageOnViewMode(ps, pageToNavTo)
    await resetRenderFlags(ps)
    await onFail(error)
}

const getStartingPage = (ps: PS) => {
    const viewMode = constants.VIEW_MODES.DESKTOP
    const pageId = ps.siteAPI.getFocusedRootId()
    return {
        viewMode,
        pageId
    }
}

const cancel = () => {
    migrationStatus = MIGRATION_STATUS.CANCELLED
    return true
}

const runSOAPOrderFixer = (ps: PS) => soapOrderFixer.exec(ps)

const fixTinyMenuInMobile = (ps: PS) => {
    const masterPagePointer = ps.pointers.full.components.getMasterPage(constants.VIEW_MODES.MOBILE)
    const tinyMenuPointer =
        masterPagePointer &&
        ps.pointers.full.components.getComponent(constants.MOBILE_ONLY_COMPONENTS.TINY_MENU, masterPagePointer)
    const tinyMenuParentPointer = tinyMenuPointer && ps.pointers.full.components.getParent(tinyMenuPointer)
    const headerPointer = ps.pointers.full.components.getHeader(constants.VIEW_MODES.MOBILE)
    const shouldReparent =
        ps.dal.full.isExist(tinyMenuPointer) &&
        tinyMenuParentPointer &&
        headerPointer &&
        !_.isEqual(tinyMenuParentPointer, headerPointer)
    if (shouldReparent) {
        // I cannot use structure.setContainer(ps, tinyMenuPointer, tinyMenuPointer, headerPointer) since TINY_MENU is not 'containable' by its componentMetaData
        // the only way to do it is directly via dal.remove & dal.push
        const siblingComponents = ps.pointers.full.components.getChildrenContainer(headerPointer)
        const tinyMenuStructure = ps.dal.full.get(tinyMenuPointer)
        ps.dal.full.remove(tinyMenuPointer)
        ps.dal.full.push(siblingComponents, tinyMenuStructure, tinyMenuPointer)
    }
}
const fixInvalidSOAPInMobile = (ps: PS) => {
    if (!documentMode.isMobileView(ps)) {
        fixTinyMenuInMobile(ps)
        mobileConversionFacade.runPartialConversionAllPages(ps, true)
    }
}

const runMeshReadyFixer = (ps: PS) =>
    new Promise((res, rej) => {
        meshLayoutReadyUtils.meshLayoutReadySiteDataFixerAfterMeshWizard(ps, res, rej)
    })

const validateSiteMigratedToMesh = (ps: PS) => {
    if (!isSiteLayoutMechanismMesh(ps)) {
        throw new Error('Failed to migrate to mesh')
    }
}

const runMeshMigrationAsync = async (
    ps: PS,
    startingPage: {pageId: string; viewMode: PossibleViewModes},
    onSuccess,
    onFail,
    isMigrationForced: boolean,
    updateCallback
) => {
    migrationStatus = MIGRATION_STATUS.RUNNING
    // console.time('migrateSiteToMesh');
    ps.extensionAPI.logger.interactionStarted(constants.INTERACTIONS.MESH_SITE_MIGRATION)
    const sitePagesWithAnchors = getSiteStructureAnchors(ps, isMigrationForced)
    await disableTransitions(ps)
    await disableTPAs(ps)
    await disableFetchDynamicPageInnerRoutes(ps)
    await cleanSiteAnchors(ps, sitePagesWithAnchors, updateCallback)
    await navigateToPageOnViewMode(ps, startingPage)
    runSOAPOrderFixer(ps)
    await fixInvalidSOAPInMobile(ps)
    await resetRenderFlags(ps)
    await runMeshReadyFixer(ps)
    await validateSiteMigratedToMesh(ps)
    await updateCallback({status: 'done'})
    // await console.timeEnd('migrateSiteToMesh'))
    await ps.extensionAPI.logger.interactionEnded(constants.INTERACTIONS.MESH_SITE_MIGRATION)
    migrationStatus = MIGRATION_STATUS.DONE
}

const runMeshMigration = (
    ps: PS,
    startingPage: {pageId: string; viewMode: PossibleViewModes},
    onSuccess,
    onFail,
    isMigrationForced: boolean,
    updateCallback
) => {
    // eslint-disable-next-line promise/prefer-await-to-then
    runMeshMigrationAsync(ps, startingPage, onSuccess, onFail, isMigrationForced, updateCallback).then(
        () => onSuccess('migration finished successfully'),
        err => {
            handleError(ps, startingPage, err, onFail)
        }
    )
}

function migrateSiteToMesh(
    ps: PS,
    onSuccess,
    onFail,
    {
        isMigrationForced = undefined,
        updateCallback = _.noop
    }: {isMigrationForced?: boolean; updateCallback?(): void} = {}
) {
    if (migrationStatus === MIGRATION_STATUS.RUNNING) {
        return
    }
    const startingPage = getStartingPage(ps)
    try {
        if (shouldRunMeshMigrator(ps) || isMigrationForced) {
            runMeshMigration(ps, startingPage, onSuccess, onFail, isMigrationForced, updateCallback)
        } else {
            onSuccess('site is already mesh or mesh eligible')
        }
    } catch (err) {
        handleError(ps, startingPage, err, onFail)
    }
}

export default {
    migrateSiteToMesh,
    cancel
}
