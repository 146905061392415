import type {DocumentManager} from '@wix/document-manager-core'
import type {CompStructure, Pointer, PS, RoutersDefinition} from '@wix/document-services-types'

type DsHooks = typeof import('@wix/document-services-implementation').hooks

export function bridgeDMEventsToHooks(ps: PS, documentManager: DocumentManager, dsHooks: DsHooks) {
    const {EVENTS, eventEmitter, experimentInstance} = documentManager
    const undoRedoEventsToTriggerAutoSave = [
        EVENTS.UNDO_REDO.TAKE_SNAPSHOT,
        EVENTS.UNDO_REDO.UNDO,
        EVENTS.UNDO_REDO.REDO
    ]

    const undoRedoEventsToTriggerAfterApplySnapShot = [EVENTS.UNDO_REDO.UNDO, EVENTS.UNDO_REDO.REDO]

    const notifyAutosave = () => dsHooks.executeHook(dsHooks.HOOKS.AUTOSAVE.ACTION, null!, [ps])

    const notifyAfterApplySnapshot = () =>
        dsHooks.executeHook(dsHooks.HOOKS.UNDO_REDO.AFTER_APPLY_SNAPSHOT, null!, [ps])

    dsHooks.registerHook(dsHooks.HOOKS.SAVE.SITE_SAVED, (_scopedPs: PS, isFirstSave: boolean) => {
        if (EVENTS.CSAVE) {
            eventEmitter.emit(EVENTS.CSAVE.SITE_SAVED, isFirstSave)
        }
    })
    if (EVENTS.CSAVE) {
        eventEmitter.on(EVENTS.CSAVE.NON_RECOVERABLE_ERROR, () => {
            dsHooks.executeHook(dsHooks.HOOKS.AUTOSAVE.CSAVE_NON_RECOVERABLE_ERROR, null!, [ps])
        })
    }

    eventEmitter.on(EVENTS.ROUTERS.BEFORE_UPDATE, (routerPtr: Pointer, routerData: RoutersDefinition) => {
        dsHooks.executeHook(dsHooks.HOOKS.ROUTER.BEFORE_UPDATE, 'router', [ps, routerPtr, routerData])
    })
    eventEmitter.on(EVENTS.ROUTERS.AFTER_ADD, (routerPtr: Pointer, routerData: RoutersDefinition) => {
        dsHooks.executeHook(dsHooks.HOOKS.ROUTER.AFTER_ADD, 'router', [ps, routerPtr, routerData])
    })

    eventEmitter.on(EVENTS.ROUTERS.BEFORE_REMOVE, (routerPtr: Pointer) => {
        dsHooks.executeHook(dsHooks.HOOKS.ROUTER.BEFORE_REMOVE, 'router', [ps, routerPtr])
    })
    eventEmitter.on(EVENTS.ROUTERS.ON_DISCONNECT, (routerPtr: Pointer, pageId: string) => {
        dsHooks.executeHook(dsHooks.HOOKS.ROUTER.ON_DISCONNECT, 'router', [ps, routerPtr, pageId])
    })
    eventEmitter.on(EVENTS.ROUTERS.AFTER_ADD, (routerPtr: Pointer, routerData: RoutersDefinition) => {
        dsHooks.executeHook(dsHooks.HOOKS.ROUTER.AFTER_ADD, 'router', [ps, routerPtr, routerData])
    })

    eventEmitter.on(EVENTS.COMPONENTS.AFTER_REMOVE, (compPointer: Pointer, compType: string, ...extraArgs: any[]) => {
        dsHooks.executeHook(dsHooks.HOOKS.REMOVE.AFTER!, compType, [ps, compPointer, ...extraArgs])
    })

    undoRedoEventsToTriggerAutoSave.forEach(ev => eventEmitter.on(ev, notifyAutosave))
    undoRedoEventsToTriggerAfterApplySnapShot.forEach(ev => eventEmitter.on(ev, notifyAfterApplySnapshot))

    eventEmitter.on(EVENTS.IMPORT_EXPORT.AFTER_IMPORT, () => {
        dsHooks.executeHook(dsHooks.HOOKS.IMPORT_EXPORT.AFTER_IMPORT, null!, [ps])
    })

    if (experimentInstance.isOpen('dm_useExtBeforeAddRootHook')) {
        dsHooks.registerHook(
            dsHooks.HOOKS.ADD_ROOT.BEFORE,
            (
                _scopedPs: PS,
                componentToAddRef: Pointer,
                containerReference: Pointer,
                componentDefinition: CompStructure,
                optionalCustomId?: string,
                optionalIndex?: number
            ) => {
                eventEmitter.emit(
                    EVENTS.COMPONENTS.BEFORE_ADD_ROOT,
                    componentToAddRef,
                    containerReference,
                    componentDefinition,
                    optionalCustomId,
                    optionalIndex
                )
            }
        )
    }
    if (experimentInstance.isOpen('dm_moveAfterAddComponentHook')) {
        dsHooks.registerHook(
            dsHooks.HOOKS.ADD.AFTER,
            (_scopedPs: PS, compToAddPointer: Pointer, componentDefinition: CompStructure) => {
                eventEmitter.emit(EVENTS.COMPONENTS.AFTER_ADD, compToAddPointer, componentDefinition)
            }
        )
    }
}
