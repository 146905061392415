import type {
    LoadAndStoreComponentStageData,
    MobileAlgoPlugin,
    MobileAlgoPluginInitializationArgs,
    PluginHeuristicsRegistry
} from '../../types'
import type {VariantsExtensionAPI} from '../../../variants/variants'
import type {DataModelExtensionAPI} from '../../../dataModel/dataModel'

export const createPlugin = ({
    stages,
    extensionAPI,
    pointers
}: MobileAlgoPluginInitializationArgs): MobileAlgoPlugin => {
    const name = 'loadRefs'
    const variantsApi = () => (extensionAPI as VariantsExtensionAPI).variants
    const dataModel = () => (extensionAPI as DataModelExtensionAPI).dataModel
    const loadRefs = ({componentStructure}: LoadAndStoreComponentStageData) => {
        if (componentStructure.layoutQuery) {
            const compPointer = pointers.getPointer(componentStructure.id!, 'DESKTOP')
            componentStructure.layouts = variantsApi().getComponentItemConsideringVariants(compPointer, 'layout')
            componentStructure.data = dataModel().components.getItem(compPointer, 'data')
        }
    }
    const register = (registry: PluginHeuristicsRegistry) => {
        registry.register(stages.LOAD, loadRefs)
    }
    return {register, name}
}
