import {cookieUtils} from '../../utils/basic-index'
import {languages} from '../../warmupUtils/index'
import _ from 'lodash'

const LANGUAGE_COOKIE = 'wixLanguage'
const HELPER_COOKIE = 'wixClient'
function getClientParams(cookieStr: string) {
    const paramsCookie = cookieUtils.parseCookieString(cookieStr)[HELPER_COOKIE]

    if (paramsCookie) {
        const params = paramsCookie.split('|')
        return {
            userName: params[0],
            email: params[1],
            mailStatus: params[2],
            permissions: params[3],
            isSessionNew: params[4],
            isSessionValid: params[5],
            userID: params[6]
        }
    }

    return null
}

function getLanguageFromCookie(cookieStr: string) {
    return cookieUtils.parseCookieString(cookieStr)[LANGUAGE_COOKIE]
}

function getLanguageFromBrowser() {
    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
        return null
    }

    const lang = window.navigator.languages ? window.navigator.languages[0] : null
    // @ts-ignore
    return lang ?? (window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage)
}

export const wixUserApi = {
    /**
     * Get wix user's language
     * @param {string} cookieStr
     * @param {Object.<string, *>} parsedUrl
     * @param {string} langFromModel
     * @returns {string} user language (default 'en')
     */
    getLanguage(cookieStr: string, parsedUrl, langFromModel: string) {
        const fromCookie = getLanguageFromCookie(cookieStr || '')
        const fromUrl = parsedUrl.query?.lang
        const fromDomain = () => {
            const parts = parsedUrl.host.split('.')
            return parts[0].length === 2 ? parts[0] : null
        }

        let lng = fromUrl || fromDomain() || fromCookie || getLanguageFromBrowser() || langFromModel
        if (lng) {
            lng = lng.substring(0, 2).toLowerCase()
        }

        return _.includes(languages, lng) ? lng : 'en'
    },
    /**
     * Check if the wix user is logged in with a valid session cookie
     * @param {string} cookieStr
     * @returns {boolean}
     */
    isSessionValid(cookieStr: string) {
        return !!getClientParams(cookieStr)
    }
}
