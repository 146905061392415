import type {DocumentManager} from '@wix/document-manager-core'
import {constants} from '@wix/document-manager-extensions'
import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import {runOnPageComponents} from './utils/migrationUtil'
import type {PageMigrator} from '../dataMigrationRunner'

const isNegative = (x: number) => x < 0

const getNewValue = (value: number, allowNegative: boolean, fallbackValue: number) => {
    if (_.isNil(value) || _.isNaN(value) || !_.isNumber(value) || (!allowNegative && isNegative(value))) {
        return fallbackValue
    }

    return value
}

const fixProperty = (
    documentManager: DocumentManager,
    pageId: string,
    pointer: Pointer,
    prop: string,
    allowNegative: boolean,
    fallbackValue: number
) => {
    const {dal} = documentManager

    const propPointer = documentManager.pointers.getInnerPointer(pointer, ['layout', prop])
    const propValue = dal.get(propPointer)
    const correctedValue = getNewValue(propValue, allowNegative, fallbackValue)
    if (!_.isEqual(correctedValue, propValue)) {
        dal.set(propPointer, correctedValue)
    }
}

const fixProperties = (documentManager: DocumentManager, pageId: string, pointer: Pointer) => {
    if (pointer.id === 'masterPage') {
        return
    }

    fixProperty(documentManager, pageId, pointer, 'x', true, 0)
    fixProperty(documentManager, pageId, pointer, 'y', true, 0)
    fixProperty(documentManager, pageId, pointer, 'height', false, 1)
    fixProperty(documentManager, pageId, pointer, 'width', false, 1)
}

const migratePage = (documentManager: DocumentManager, pageId: string) => {
    if (documentManager.experimentInstance.isOpen('dm_meshLayout')) {
        return
    }

    _.forEach(constants.VIEW_MODES, viewMode => {
        runOnPageComponents(documentManager, pageId, viewMode, fixProperties)
    })
}

const name = 'fixBrokenLayout'
const version = 1

export const fixBrokenLayout: PageMigrator = {
    migratePage,
    name,
    version,
    fixerRequiresReruns: true
}
