import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'
import constants from '../../constants/constants'
import dataModel from '../../dataModel/dataModel'
import hooks from '../../hooks/hooks'
import clientSpecMap from '../../siteMetadata/clientSpecMap'
import theme from '../../theme/theme'
import classicsUtils from '../utils/classicsUtils'
import blogUtils from './blogUtils'
import {coreUtils} from '@wix/santa-ds-libs/src/basic-index'
const {blogAppPartNames} = coreUtils

/**
 * Gets a list of all appPart components
 * @param {ps} ps Private Services
 * @param packageName
 */
function getAllAppPartComps(ps: PS, packageName: string) {
    const appId = packageName && getApplicationId(ps, packageName)
    if (_.isNull(appId)) {
        return []
    }
    const appPartPointers = componentDetectorAPI.getComponentByType(ps, 'wixapps.integration.components.AppPart')
    return appPartPointers
        .map(appPartPointer => dataModel.getDataItem(ps, appPartPointer))
        .filter(appPartData => appPartData.appInnerID === appId || !appId)
}

function reloadApp(privateServices: PS, packageName: string) {
    privateServices.siteAPI.reloadWixappsPackage(packageName)
}

function getApplicationId(ps: PS, packageName: string): string | null {
    const clientSpecMapPointer = ps.pointers.general.getClientSpecMap()
    const clientSpecMapData = ps.dal.get(clientSpecMapPointer)
    const blogAppId = _.findLast(clientSpecMapData, {packageName})

    return blogAppId ? `${blogAppId.applicationId}` : null
}

function getDataStoreId(ps: PS, packageName: string) {
    return clientSpecMap.getAppData(ps, getApplicationId(ps, packageName)).datastoreId
}
function canDeleteComp(ps: PS, compPointer: Pointer) {
    const appPart = dataModel.getDataItem(ps, compPointer)
    const undeletableAppParts = [blogAppPartNames.FEED, blogAppPartNames.SINGLE_POST]
    if (_.includes(undeletableAppParts, appPart.appPartName)) {
        return false
    }
}

function registerHooks() {
    hooks.registerHook(hooks.HOOKS.REMOVE.IS_OPERATION_ALLOWED, canDeleteComp, 'wixapps.integration.components.AppPart')
    hooks.registerHook(
        hooks.HOOKS.DUPLICATE.IS_OPERATION_ALLOWED,
        canDeleteComp,
        'wixapps.integration.components.AppPart'
    )
}

const createStyleItemToFork = (ps: PS, styleValue: any) => {
    const defaultFields = {
        styleType: constants.STYLES.TYPES.CUSTOM,
        type: constants.STYLES.TOP_LEVEL_STYLE
    }
    // @ts-expect-error
    const styleDefToAdd = _(styleValue).omit(['id']).assign(defaultFields).value()
    return theme.styles.createStyleItemToAdd(ps, styleDefToAdd)
}

const classics = {
    blog: {
        isSinglePost: blogUtils.isSinglePost
    },
    getAllAppPartComps,
    getDataStoreId,
    getPackageName: classicsUtils.getPackageName,
    isBlogPage: blogUtils.isBlogPage,
    registerHooks,
    reloadApp,
    style: {
        createItemToFork: createStyleItemToFork
    }
}
export default classics
