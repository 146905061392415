import {createComponentToContainerMigrator} from './utils/componentMigrationHelper'
import type {PageMigrator} from '../dataMigrationRunner'

const {migratePage, version, name} = createComponentToContainerMigrator('migrateStylableMenuToContainer', [
    'wixui.StylableHorizontalMenu'
])

export const migrateStylableMenuToContainer: PageMigrator = {
    migratePage,
    name,
    version
}
