import type {Pointer, PS} from '@wix/document-services-types'
import * as santaCoreUtils from '@wix/santa-core-utils'
import componentsMetaData from '../../componentsMetaData/componentsMetaData'

const CONTROLLER_TYPE = 'platform.components.AppController'

const isFixedPosition = (ps: PS, compPointer: Pointer) => {
    const isFixedPointer = ps.pointers.getInnerPointer(compPointer, 'layout.fixedPosition')
    return !!ps.dal.get(isFixedPointer)
}

const isController = (ps: PS, compPointer: Pointer) =>
    componentsMetaData.getComponentType(ps, compPointer) === CONTROLLER_TYPE

const isCompArrangeable = (ps: PS, compPointer: Pointer) => {
    if (ps.pointers.components.isPage(compPointer)) {
        return false
    }

    if (isController(ps, compPointer)) {
        return false
    }

    if (santaCoreUtils.displayedOnlyStructureUtil.isRefPointer(compPointer)) {
        return false
    }

    const parentPointer = ps.pointers.components.getParent(compPointer)
    const isRepeatedComponent = santaCoreUtils.displayedOnlyStructureUtil.isRepeatedComponent(compPointer.id)
    const isParentRepeatedComponent =
        parentPointer && santaCoreUtils.displayedOnlyStructureUtil.isRepeatedComponent(parentPointer.id)

    if (isRepeatedComponent && !isParentRepeatedComponent) {
        return false
    }

    return true
}

const canSwapComponents = (ps: PS, source: Pointer, target: Pointer) =>
    isCompArrangeable(ps, target) && isFixedPosition(ps, source) === isFixedPosition(ps, target)

export default {
    isCompArrangeable,
    canSwapComponents
}
