import _ from 'lodash'
import type {PS, Pointer} from '@wix/document-services-types'
import slots from '../../slots/slots'
import constants from '../../constants/constants'

const {OOI_HOST_COMPONENT_TYPES} = constants
const BLOCKS_HOST_COMPONENT_TYPE = 'wysiwyg.viewer.components.RefComponent'
const BLOCKS_DEFAULT_SLOT_ROLE = 'slot'

function cleanupCompsInSlots(ps: PS, compPointer: Pointer): void {
    if (compPointer) {
        const parentPointer = ps.pointers.components.getParent(compPointer)
        const hostCompType = parentPointer && ps.dal.get(parentPointer)?.componentType

        if (OOI_HOST_COMPONENT_TYPES.includes(hostCompType)) {
            const slotsData = slots.getSlotsData(ps, parentPointer)

            if (!_.isEmpty(slotsData)) {
                for (const slotRole in slotsData) {
                    if (slotsData[slotRole].id === compPointer.id) {
                        slots.removeInternal(ps, parentPointer, slotRole, true)
                        return
                    }
                }
            }
        }

        if (hostCompType === BLOCKS_HOST_COMPONENT_TYPE) {
            // In case of Blocks, actual parent isn't RefComponent, but a SlotPlaceholder
            const slotPlaceholderPointer = ps.extensionAPI.slots.getPluginParent(compPointer)

            if (slotPlaceholderPointer) {
                slots.removeInternal(ps, slotPlaceholderPointer, BLOCKS_DEFAULT_SLOT_ROLE, true)
            }
        }
    }
}

function cleanupCompsInSlotsInRemoval(ps: PS, compPointer: Pointer, deletingParent: boolean): void {
    if (deletingParent) {
        return
    }

    return cleanupCompsInSlots(ps, compPointer)
}

export default {cleanupCompsInSlots, cleanupCompsInSlotsInRemoval}
