import _ from 'lodash'
import clientSpecMapService from './clientSpecMapService'
import tpaUtils from '../utils/tpaUtils'
import type {AppDefinitionId} from '@wix/document-services-types'

const getPageAppIds = visitableData => {
    const allAppIds = new Set<string>()
    const installedNonPlatformApps = new Set<string>()
    visitableData.accept((get, data) => {
        const type = get(data, 'type')
        if (tpaUtils.isTpaByDataType(type) || tpaUtils.isUnifiedComponentData(type)) {
            installedNonPlatformApps.add(get(data, 'appDefinitionId'))
        }
        const appDefinitionIdPropertyNames = ['applicationId', 'appDefinitionId', 'appDefId'] //applicationId is used for AppController ONLY
        appDefinitionIdPropertyNames.forEach(property => allAppIds.add(get(data, property)))
    })
    return {
        installedNonPlatformApps: _.compact(Array.from(installedNonPlatformApps)),
        allAppIds: _(Array.from(allAppIds)).compact().keyBy().value()
    }
}

const appHasRouter = function ({appDefinitionId}, routerConfigMap) {
    return _.some(routerConfigMap, {appDefinitionId})
}

const isPlatformAppActive = function (app, allAppIds, routerConfigMap) {
    return (
        clientSpecMapService.hasEditorPlatformPart(app) &&
        (appHasRouter(app, routerConfigMap) ||
            allAppIds.hasOwnProperty(app.applicationId) ||
            allAppIds.hasOwnProperty(app.appDefinitionId) ||
            clientSpecMapService.isDashboardAppOnly(app))
    )
}

const getInstalledPlatformApps = function (clientSpecMap, routerConfigMap, allAppIds): AppDefinitionId[] {
    const retVal = _.reduce(
        clientSpecMap,
        function (platformApps, app) {
            if (isPlatformAppActive(app, allAppIds, routerConfigMap)) {
                platformApps.add(app.appDefinitionId)
            }
            return platformApps
        },
        new Set<string>()
    )

    return Array.from(retVal)
}

const getAllAppIdsInstalledOnPages = (visitableData, clientSpecMap?, routerConfigMap?): AppDefinitionId[] => {
    const {installedNonPlatformApps, allAppIds} = getPageAppIds(visitableData)
    const installedPlatformApps = getInstalledPlatformApps(clientSpecMap, routerConfigMap, allAppIds)
    return _.union(installedNonPlatformApps, installedPlatformApps)
}

export default {
    getAllAppIdsInstalledOnPages,
    isPlatformAppActive: (app, visitableData, routerConfigMap) =>
        isPlatformAppActive(app, getPageAppIds(visitableData).allAppIds, routerConfigMap)
}
