const _ = require('lodash')
const dataUtils = require('../../helpers/dataUtils')
const {stripHashIfExists, variantRelation, refArray} = require('../../helpers/dataUtils')

const styleItemTypes = {
    DEFAULT: 'default',
    BREAKPOINT: 'breakpoint'
}

/**
 *
 * @param {object} params
 * @param {object} params.styles - styles object
 * @param {object} params.variants - variants object
 * @param {string} params.styleId - style id
 * @returns {Array<import('./index').StyleItemWithMetadata>}
 * @description - returns all style items of a given style id with metadata object {min, max, type - default or breakpoint}
 */
function getAllStyleItems({styles, variants, styleId}) {
    const styleItems = []
    function _getAllStyleItems(currentStyleId, metadata = {min: undefined, max: undefined}) {
        const styleItem = styles[currentStyleId]
        if (!styleItem) {
            return []
        }

        if (!refArray.isRefArray(styleItem) && !variantRelation.isVariantRelation(styleItem)) {
            styleItems.push({
                ...styleItem,
                metadata: {
                    ...metadata,
                    type: metadata.min && metadata.max ? styleItemTypes.BREAKPOINT : styleItemTypes.DEFAULT
                }
            })
            return
        }

        if (refArray.isRefArray(styleItem)) {
            const references = refArray.extractValuesWithoutHash(styleItem)

            references.forEach(reference => {
                _getAllStyleItems(reference, metadata)
            })
        }

        if (variantRelation.isVariantRelation(styleItem)) {
            const compVariantStyleId = styleItem.to

            if (!compVariantStyleId) {
                return
            }

            const [variantId] = styleItem.variants

            if (variantId) {
                const variant = variants[stripHashIfExists(variantId)]
                _getAllStyleItems(stripHashIfExists(compVariantStyleId), {
                    min: variant?.min,
                    max: variant?.max
                })
                return
            }

            _getAllStyleItems(stripHashIfExists(compVariantStyleId), metadata)
        }
    }

    _getAllStyleItems(styleId)

    return styleItems
}

/**
 * @param {object} params
 * @param {import('./index').ComponentStructure} params.component - component structure
 * @param {object} params.styles - styles object
 * @param {object} params.variants - variants object
 * @param {object} params.mobileOnlyComponentsIds - array of mobile only components ids
 * @returns {Array<import('./index').StyleItemWithMetadata>}
 * @description - returns all style items of a given component with metadata object {min, max, type - default or breakpoint}
 */
function extractComponentStyleItems({component, styles, variants, mobileOnlyComponentsIds}) {
    const {styleId} = component
    const compStyleItem = styles[styleId]
    const isMobileOnly = _.includes(mobileOnlyComponentsIds, component.id)

    if (dataUtils.refArray.isRefArray(compStyleItem)) {
        /**
         * if styleId refers to a refArray, this means we are in a responsive site. We do not expect
         * mobile styles in media queries, instead overrides in breakpoints are stored in variants by the editor.
         * If user has made no overrides, styles in breakpoints will contain minimal stylesheet, for example:
         * :import {
         *     -st-from: 'wix-ui-santa/index.st.css';
         *     -st-named: CollapsibleText;
         * }
         *  .root{
         *     -st-extends: CollapsibleText;
         * }
         */
        const allStyleVariants = getAllStyleItems({
            styles,
            variants,
            styleId
        }).map(styleItem => ({...styleItem, metadata: {...styleItem.metadata, isMobileOnly}}))

        if (_.every(allStyleVariants, variant => _.get(variant, 'style.properties.$st-css') === undefined)) {
            // no $st-css in any of breakpoints - component does not require migration
            return []
        }
        return allStyleVariants
    }

    if (!_.get(compStyleItem, 'style.properties.$st-css')) {
        // no $st-css in default style - component does not require migration
        return []
    }

    return [{...compStyleItem, metadata: {min: undefined, max: undefined, isMobileOnly}}]
}

module.exports = {extractComponentStyleItems, styleItemTypes}
