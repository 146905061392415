import type {PublicMethodUtils} from '@wix/document-services-types'
import {effects} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            components: {
                effects: {
                    add: publicMethodUtils.defineDataManipulationAction(effects.addEffect, {
                        getReturnValue: effects.getEffectToAddPointer
                    }),
                    get: publicMethodUtils.defineGetter(effects.getEffect),
                    list: publicMethodUtils.defineGetter(effects.getComponentEffectList),
                    update: publicMethodUtils.defineDataManipulationAction(effects.updateEffectData),
                    remove: publicMethodUtils.defineDataManipulationAction(effects.removeEffect),
                    usesNewAnimations: publicMethodUtils.defineGetter(effects.usesNewAnimations)
                }
            }
        }
    }
}
