import {namespaceMapping} from '@wix/document-services-json-schemas'
const {VIEWER_PAGE_DATA_TYPES: PAGE_DATA_TYPES} = namespaceMapping

const REPEATER_DELIMITER = '__'
const REF_DELIMITER = '_r_'
const SHARED_PARTS_PREFIX = 'sharedParts'

const REF_COMPONENT_TYPE = 'wysiwyg.viewer.components.RefComponent'

const VIEW_MODES = {
    DESKTOP: 'DESKTOP',
    MOBILE: 'MOBILE'
}

const MODELS_TYPES = {
    DOCUMENT_SERVICES_MODEL: 'documentServicesModel',
    RENDERER_MODEL: 'rendererModel'
}

const POINTER_TYPES = {
    PLATFORM: 'platform',
    PAGES_PLATFORM_APPLICATIONS: 'pagesPlatformApplications',
    MULTILINGUAL_TRANSLATIONS: 'multilingualTranslations',
    WIX_CODE: 'wixCode',
    SVG_SHAPES: 'svgShapes',
    RENDER_FLAGS: 'renderFlags',
    ACTIVE_VARIANT: 'activeVariants',
    RENDER_REAL_TIME_CONFIG_POINTER: 'renderRealTimeConfigPointer',
    RUNTIME: 'runtime',
    MULTILINGUAL: 'multilingual',
    GHOST_STRUCTURE: 'ghostStructure',
    GHOST_CONTROLLERS: 'ghostControllers',
    BLOCKS: 'blocksPreviewData',
    ACTIVE_MODES: 'activeModes',
    CUSTOM_ELEMENTS: 'customElementsPointer',
    DISPLAYED_ONLY_COMPONENTS: 'displayedOnlyComponents',
    PAGE: 'page'
}

const BY_REF_POINTER_TYPES = {
    GHOST_REF_COMPS: 'ghostRefComps',
    INTERNALLY_REFERRED_COMPS: 'internalyReferredComps',
    DISPLAYED_CONNECTIONS_OVERRIDES: 'displayedConnectionOverrides',
    REMOTE_OVERRIDES: 'remoteOverrides'
}

export {
    VIEW_MODES,
    PAGE_DATA_TYPES,
    MODELS_TYPES,
    POINTER_TYPES,
    BY_REF_POINTER_TYPES,
    REF_DELIMITER,
    REPEATER_DELIMITER,
    REF_COMPONENT_TYPE,
    SHARED_PARTS_PREFIX
}
