import type {Layout, PS} from '@wix/document-services-types'
import _ from 'lodash'
import {layoutUtils, constants} from '@wix/santa-core-utils'
import componentLayout from './utils/componentLayout'
import {coreUtils} from '@wix/santa-ds-libs'

const NON_MOVABLE_DATA_ITEMS = ['MAIN_MENU', 'CUSTOM_MAIN_MENU', 'CUSTOM_MENUS', 'masterPage']

const canMoveData = ({id}: {id: string}) => !_.includes(NON_MOVABLE_DATA_ITEMS, id)
const canMoveStyle = ({styleType}: {styleType: string}) => styleType !== 'system'

const canMoveDataItemToAnotherPage = (dataItem, dataType: string) => {
    switch (dataType) {
        case constants.DATA_TYPES.data:
            return canMoveData(dataItem)
        case constants.DATA_TYPES.theme:
            return canMoveStyle(dataItem)
    }
    return true
}

function getLayoutFromBoundingLayout(ps: PS, _boundingLayout: Layout, currentRotationInDegrees: number) {
    return coreUtils.boundingLayoutUtils.getLayoutFromBoundingLayout(_boundingLayout, currentRotationInDegrees)
}

function getChildrenToPreserveProportionsByType(
    ps: PS,
    componentsMetaData,
    structureChildren,
    ignoreChildren: string[]
) {
    return _.reject(structureChildren, function (child) {
        const compType = componentsMetaData.getComponentType(ps, child.component)
        return _.includes(ignoreChildren, compType)
    })
}

function ensureWithinLimits(value: number, min: number, max: number) {
    min = _.isUndefined(min) ? value : min
    max = _.isUndefined(max) ? value : max
    if (min > max) {
        throw new Error('min limit is greater than max limit')
    }

    if (value < min) {
        return min
    }
    if (value > max) {
        return max
    }

    return value
}

/**
 *  @exports documentServices/structure/structureUtils
 */
export default {
    isHorizontallyDocked: layoutUtils.isHorizontallyDocked,
    isVerticallyDocked: layoutUtils.isVerticallyDocked,
    getBoundingY: coreUtils.boundingLayoutUtils.getBoundingY,
    getBoundingX: coreUtils.boundingLayoutUtils.getBoundingX,
    getBoundingHeight: coreUtils.boundingLayoutUtils.getBoundingHeight,
    getBoundingWidth: coreUtils.boundingLayoutUtils.getBoundingWidth,
    getBoundingLayout: componentLayout.getBoundingLayout,
    getLayoutFromBoundingLayout,
    canMoveDataItemToAnotherPage,
    ensureWithinLimits,
    getChildrenToPreserveProportionsByType,
    getComponentLayout: componentLayout.getComponentLayout,
    getPositionAndSize: componentLayout.getPositionAndSize
}
