import type {PublicMethodUtils} from '@wix/document-services-types'
import {triggers, utils} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            components: {
                triggers: {
                    add: publicMethodUtils.defineDataManipulationAction(triggers.addTrigger, {
                        isUpdatingAnchors: utils.DONT_CARE,
                        getReturnValue: triggers.getTriggerVariantToAddRef
                    }),
                    remove: publicMethodUtils.defineDataManipulationAction(triggers.removeComponentTrigger),
                    get: publicMethodUtils.defineGetter(triggers.getTrigger),
                    getAll: publicMethodUtils.defineGetter(triggers.getAllTriggers)
                }
            }
        }
    }
}
