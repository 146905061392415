import type {PublicMethodUtils} from '@wix/document-services-types'
import {transformations, utils} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            components: {
                transformations: {
                    update: publicMethodUtils.defineDataManipulationAction(transformations.updateTransformationsData, {
                        isUpdatingAnchors: utils.NO
                    }),
                    get: publicMethodUtils.defineGetter(transformations.getTransformationsData),
                    remove: publicMethodUtils.defineDataManipulationAction(transformations.removeTransformationsData),
                    getAllHiddenComponents: publicMethodUtils.defineGetter(
                        transformations.getAllHiddenComponentsByVariants
                    ),
                    getAllHiddenComponentsByDefault: publicMethodUtils.defineGetter(
                        transformations.getAllHiddenComponentsByDefault
                    ),
                    setPropsToIgnore: publicMethodUtils.defineAction(transformations.setPropsToIgnore),
                    setGlobalPropsToIgnore: publicMethodUtils.defineAction(transformations.setGlobalPropsToIgnore),
                    setPropsToIgnoreForType: publicMethodUtils.defineAction(transformations.setPropsToIgnoreForType)
                }
            }
        }
    }
}
