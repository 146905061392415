import type {AppDefinitionId, ApplicationId, PS} from '@wix/document-services-types'
import _ from 'lodash'
import {platformInit} from '@wix/santa-ds-libs'
import clientSpecMapService from '../../tpa/services/clientSpecMapService'
import platformUtils from './platformUtils'
import dsConstants from '../../constants/constants'

const callbackTypes = {
    manifestAdded: 'manifestAdded',
    publicApiSet: 'publicApiSet',
    publicApiUnset: 'publicApiUnset',
    privateApiSet: 'privateApiSet',
    privateApiUnset: 'privateApiUnset'
}

const callbacks: Record<string, Function[]> = {}

function addCallback(type: string, cb: Function) {
    const cbs = callbacks[type] || []
    callbacks[type] = [...cbs, cb]
}

function executeCallback(cb: Function, ...args: any[]) {
    try {
        cb(...args)
    } catch (e) {
        console.error(e)
    }
}

function executeCallbacks(type: string, ...args: any[]) {
    const cbs = callbacks[type] || []
    cbs.forEach(cb => executeCallback(cb, ...args))
}

function resolveEditorScriptUrl(ps: PS, appData) {
    const serviceTopology = ps.dal.getNoClone(ps.pointers.general.getServiceTopology())
    return platformInit.specMapUtils.resolveEditorScriptUrl(appData, {clientSpec: appData, serviceTopology})
}

function getAppDataByAppDefId(ps: PS, appDefId: AppDefinitionId) {
    const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefId)
    return resolveEditorScriptUrl(ps, appData)
}

function getAppDataByApplicationId(ps: PS, applicationId: ApplicationId) {
    const appData = clientSpecMapService.getAppData(ps, applicationId)
    return resolveEditorScriptUrl(ps, appData)
}

function registerToManifestAdded(ps: PS, cb: Function) {
    addCallback(callbackTypes.manifestAdded, cb)
    const appManifests = getAppManifests(ps)
    if (!_.isEmpty(appManifests)) {
        appManifests.forEach(appManifest => executeCallback(cb, appManifest.manifest, appManifest.appData))
    }
}

function getApiNames(ps: PS, getNameFn) {
    const apps = clientSpecMapService.getAppsDataWithPredicate(ps, csm =>
        _.filter(csm, app => hasAppManifest(ps, app.appDefinitionId))
    )
    return _.map(apps, (app: any) => ({
        appDefinitionId: app.appDefinitionId,
        apiName: getNameFn(ps, app.appDefinitionId)
    }))
}

function getAppPublicApiName(ps: PS, appDefinitionId: AppDefinitionId) {
    const appPublicApiNamePointer = ps.pointers.platform.appPublicApiNamePointer(appDefinitionId)
    return ps.dal.get(appPublicApiNamePointer)
}

function getAppPrivateApiName(ps: PS, appDefinitionId: AppDefinitionId) {
    const appPrivateApiNamePointer = ps.pointers.platform.appPrivateApiNamePointer(appDefinitionId)
    return ps.dal.get(appPrivateApiNamePointer)
}

function getAppEditorApiName(ps: PS, appDefinitionId: AppDefinitionId) {
    const appEditorApiNamePointer = ps.pointers.platform.appEditorApiNamePointer(appDefinitionId)
    return ps.dal.get(appEditorApiNamePointer)
}

function setAppPublicAPI(ps: PS, appDefinitionId: AppDefinitionId, apiName: string) {
    const publicApiNamePointer = ps.pointers.platform.appPublicApiNamePointer(appDefinitionId)
    ps.dal.set(publicApiNamePointer, apiName)
    executeCallbacks(callbackTypes.publicApiSet, {appDefinitionId, apiName})
}

function unsetAppPublicAPI(ps: PS, appDefinitionId: AppDefinitionId) {
    const apiName = getAppPublicApiName(ps, appDefinitionId)
    if (apiName) {
        const publicApiNamePointer = ps.pointers.platform.appPublicApiNamePointer(appDefinitionId)
        ps.dal.remove(publicApiNamePointer)
        executeCallbacks(callbackTypes.publicApiUnset, {appDefinitionId, apiName})
    }
}

function setAppPrivateAPI(ps: PS, appDefinitionId: AppDefinitionId, apiName: string) {
    const privateApiNamePointer = ps.pointers.platform.appPrivateApiNamePointer(appDefinitionId)
    ps.dal.set(privateApiNamePointer, apiName)
    executeCallbacks(callbackTypes.privateApiSet, {appDefinitionId, apiName})
}

function unsetAppPrivateAPI(ps: PS, appDefinitionId: AppDefinitionId) {
    const apiName = getAppPrivateApiName(ps, appDefinitionId)
    if (apiName) {
        const privateApiNamePointer = ps.pointers.platform.appPrivateApiNamePointer(appDefinitionId)
        ps.dal.remove(privateApiNamePointer)
        executeCallbacks(callbackTypes.privateApiUnset, {appDefinitionId, apiName})
    }
}

function setAppEditorAPI(ps: PS, appDefinitionId: AppDefinitionId, apiName: string) {
    const editorApiNamePointer = ps.pointers.platform.appEditorApiNamePointer(appDefinitionId)
    ps.dal.set(editorApiNamePointer, apiName)
}

function unsetAppEditorAPI(ps: PS, appDefinitionId: AppDefinitionId) {
    const editorApiNamePointer = ps.pointers.platform.appEditorApiNamePointer(appDefinitionId)
    ps.dal.remove(editorApiNamePointer)
}

function registerToPublicApiSet(ps: PS, cb) {
    addCallback(callbackTypes.publicApiSet, cb)
    const apiNames = getApiNames(ps, getAppPublicApiName)
    if (!_.isEmpty(apiNames)) {
        apiNames.forEach(apiNameObj => executeCallback(cb, apiNameObj))
    }
}

function registerToPublicApiUnset(ps: PS, cb) {
    addCallback(callbackTypes.publicApiUnset, cb)
}

function registerToPrivateApiSet(ps: PS, cb) {
    addCallback(callbackTypes.privateApiSet, cb)
    const apiNames = getApiNames(ps, getAppPrivateApiName)
    if (!_.isEmpty(apiNames)) {
        apiNames.forEach(apiNameObj => executeCallback(cb, apiNameObj))
    }
}

function registerToPrivateApiUnset(ps: PS, cb) {
    addCallback(callbackTypes.privateApiUnset, cb)
}

function getAppManifest(ps: PS, appDefinitionId: AppDefinitionId) {
    const appManifestPointer = ps.pointers.platform.getAppManifestPointer(appDefinitionId)
    const r = ps.dal.get(appManifestPointer)
    return _.isEmpty(r) ? undefined : r
}

function hasAppManifest(ps: PS, appDefinitionId: AppDefinitionId) {
    const appManifestPointer = ps.pointers.platform.getAppManifestPointer(appDefinitionId)
    return ps.dal.isExist(appManifestPointer)
}

function getAppManifests(ps: PS) {
    const apps = clientSpecMapService.getAppsDataWithPredicate(ps, csm =>
        _.filter(csm, app => hasAppManifest(ps, app.appDefinitionId))
    )
    return _(apps)
        .map((app: any) => ({appData: app, manifest: getAppManifest(ps, app.appDefinitionId)}))
        .filter(appManifest => !_.isNil(appManifest.manifest))
        .value()
}

function setManifest(ps: PS, appDefinitionId: AppDefinitionId, manifest) {
    ps.extensionAPI.logger.interactionStarted(dsConstants.PLATFORM_INTERACTIONS.SET_MANIFEST, {
        extras: {appDefinitionId}
    })
    const appManifestPointer = ps.pointers.platform.getAppManifestPointer(appDefinitionId)
    const appData = getAppDataByAppDefId(ps, appDefinitionId)
    ps.dal.set(appManifestPointer, manifest)
    executeCallbacks(callbackTypes.manifestAdded, manifest, appData)
    ps.extensionAPI.logger.interactionEnded(dsConstants.PLATFORM_INTERACTIONS.SET_MANIFEST, {extras: {appDefinitionId}})
}

function isPlatformAppInstalled(ps: PS, appDefinitionId: AppDefinitionId) {
    const pagesPlatformApplicationsPointer = ps.pointers.platform.getPagesPlatformApplicationsPointer()
    const platformApps = ps.dal.get(pagesPlatformApplicationsPointer)

    const appData = getAppDataByAppDefId(ps, appDefinitionId)

    return platformUtils.isPlatformAppInstalled(platformApps, appData)
}

function setAppExportedAPIs(ps: PS, appDefinitionId: AppDefinitionId, apisNames: any = {}) {
    setAppPublicAPI(ps, appDefinitionId, apisNames.public)
    setAppPrivateAPI(ps, appDefinitionId, apisNames.private)
    setAppEditorAPI(ps, appDefinitionId, apisNames.editor)
}

function unsetAppExportedAPIs(ps: PS, appDefinitionId: AppDefinitionId) {
    unsetAppPublicAPI(ps, appDefinitionId)
    unsetAppPrivateAPI(ps, appDefinitionId)
    unsetAppEditorAPI(ps, appDefinitionId)
}

export default {
    registerToManifestAdded,
    setManifest,
    getAppManifest,
    getAppDataByAppDefId,
    getAppDataByApplicationId,
    isPlatformAppInstalled,
    hasAppManifest,
    registerToPublicApiSet,
    registerToPrivateApiSet,
    registerToPublicApiUnset,
    registerToPrivateApiUnset,
    setAppExportedAPIs,
    unsetAppExportedAPIs,
    getAppPublicApiName,
    getAppPrivateApiName,
    getAppEditorApiName
}
