import {Extension, CreateExtArgs, ExtensionAPI, DmApis, pointerUtils} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import type {GridLayoutAPI, MappedLayoutComponent} from '../gridLayout'
import {reorderSectionsByGridArea, reorderSectionByGridArea} from './sectionOrderPure'

export interface SectionOrderAPI extends ExtensionAPI {
    sectionOrderAPI: {
        isValidChildrenOrder(pagePointer: Pointer): boolean
        orderSection(compPointer: Pointer): void
        orderSections(pagePointer: Pointer): void
    }
}

interface ChildrenSeprationObject {
    childrenWithGridLayout: MappedLayoutComponent[]
    childrenWithFixedLayout: MappedLayoutComponent[]
}

/**
 * Creates the section order extension that will provide automatic reordering of page sections based on grid area
 * to solve inconsistency between DOM order and grid-area
 */
const createExtension = (): Extension => {
    const createExtensionAPI = ({dal, pointers, extensionAPI}: CreateExtArgs): SectionOrderAPI => {
        const gridLayout = () => extensionAPI.gridLayout as GridLayoutAPI

        const getPageChildren = (pagePointer: Pointer) => {
            const children = pointers.structure.getChildren(pagePointer).map(child => child.id)
            const childrenWithLayout = children
                .map((child: string) => gridLayout().componentLayoutMapper(child))
                .filter(x => x)

            const {childrenWithGridLayout, childrenWithFixedLayout} =
                childrenWithLayout.reduce<ChildrenSeprationObject>(
                    (acc, current) => {
                        if (current?.itemLayout?.type === 'FixedItemLayout') {
                            acc.childrenWithFixedLayout.push(current)
                        }

                        if (current?.itemLayout?.type === 'GridItemLayout') {
                            acc.childrenWithGridLayout.push(current)
                        }

                        return acc
                    },
                    {
                        childrenWithGridLayout: [],
                        childrenWithFixedLayout: []
                    }
                )

            return {
                originalChildren: children,
                childrenWithGridLayout,
                childrenWithFixedLayout
            }
        }

        const getPagePointer = (pointer: Pointer): Pointer => {
            const isCurrentPointerPage = pointers.structure.isPage(pointer)
            return isCurrentPointerPage ? pointer : pointers.structure.getPageOfComponent(pointer)
        }

        // check if the grid has responsive layout
        // TODO : this will break when classic would have responsive layout, I need to filter gridContainerLayout item
        const isGridPage = (pointer: Pointer) => {
            const layout = gridLayout().componentLayoutMapper(pointer.id)
            return !!layout
        }

        const updateDALwithChildren = (
            pagePointer: Pointer,
            children: Pointer[],
            pageChildren: ReturnType<typeof getPageChildren>
        ) => {
            // fixed position children are rendered last in the DOM by the viewer, so we add them last as well.
            const fixedPositionPointers = pageChildren.childrenWithFixedLayout
                .filter((x): x is MappedLayoutComponent => !!x)
                .map(x => x?.componentPointer)

            // TODO: compare previous and current and see if we need to update
            dal.set(
                pointerUtils.getInnerPointer(pagePointer, 'components'),
                children.concat(fixedPositionPointers).map(x => x.id)
            )
        }

        const orderSections: SectionOrderAPI['sectionOrderAPI']['orderSections'] = compPointer => {
            const pagePointer = getPagePointer(compPointer)
            const pageChildren = getPageChildren(pagePointer)
            const orderedChildren = reorderSectionsByGridArea(pageChildren.childrenWithGridLayout)
            updateDALwithChildren(pagePointer, orderedChildren, pageChildren)
        }

        const orderSection: SectionOrderAPI['sectionOrderAPI']['orderSection'] = compPointer => {
            const pagePointer = getPagePointer(compPointer)
            const pageChildren = getPageChildren(pagePointer)
            const orderedChildren = reorderSectionByGridArea(pageChildren.childrenWithGridLayout, compPointer)
            updateDALwithChildren(pagePointer, orderedChildren, pageChildren)
        }

        const isValidChildrenOrder: SectionOrderAPI['sectionOrderAPI']['isValidChildrenOrder'] = pointer => {
            const pagePointer = getPagePointer(pointer)
            const children = pointers.structure.getChildren(pagePointer).map(child => child.id)
            const pageChildren = getPageChildren(pagePointer)
            const orderedChildren = reorderSectionsByGridArea(pageChildren.childrenWithGridLayout).map(
                child => child.id
            )

            const orderedChildrenWithFixed = orderedChildren.concat(
                pageChildren.childrenWithFixedLayout.map(child => child.componentPointer.id)
            )

            if (children.length === orderedChildrenWithFixed.length) {
                const isSameOrder = children.every((child, index) => {
                    return orderedChildrenWithFixed.indexOf(child) === index
                })

                return isSameOrder
            }

            return false
        }

        // run checks before running the functions
        const wrapper = (functionToRun: any) => {
            return (...args: any) => {
                const pagePointer = getPagePointer(args[0])

                if (isGridPage(pagePointer)) {
                    return functionToRun(...args)
                }
            }
        }

        return {
            sectionOrderAPI: {
                isValidChildrenOrder: wrapper(isValidChildrenOrder),
                orderSection: wrapper(orderSection),
                orderSections: wrapper(orderSections)
            }
        }
    }

    return {
        name: 'sectionOrder',
        createExtensionAPI,
        createPublicAPI: ({extensionAPI}: DmApis) => {
            const {sectionOrderAPI} = extensionAPI as SectionOrderAPI

            return {
                components: {
                    responsiveLayout: {
                        reorderGridPageSection: sectionOrderAPI.orderSection,
                        reorderGridPageSections: sectionOrderAPI.orderSections,
                        isGridPageChildrenOrderValid: sectionOrderAPI.isValidChildrenOrder
                    }
                }
            }
        },
        dependencies: new Set(['structure', 'dataModel', 'page', 'gridLayout'])
    }
}

export {createExtension}
