import type {PlatformMetaDataTemplate} from '@wix/document-services-types'

const CONTROLLER_DATA_TYPE = 'AppController'

export function getPlatformData(compData: any): PlatformMetaDataTemplate | undefined {
    if (compData?.type !== CONTROLLER_DATA_TYPE) {
        return
    }

    return {
        appDefinitionId: compData.applicationId,
        controllerType: compData.controllerType
    }
}
