import type {CompRef, PS} from '@wix/document-services-types'
import _ from 'lodash'
import constants from '../constants/constants'
import variantsUtils from './variantsUtils'
import {updateConsideringOverrides} from '../overrides/overrides'

const {DATA_TYPES} = constants

const updateTransitionsData = (ps: PS, componentVariantsPointer: CompRef, transitions) => {
    if (_.isEmpty(transitions)) {
        console.warn('please pass non empty object, for full delete use transitions.remove api')
        return
    }

    const transitionsWithType = _.defaults({}, {type: 'TransitionData'}, transitions)
    return updateConsideringOverrides(ps, componentVariantsPointer, transitionsWithType, DATA_TYPES.transitions)
}

const getTransitionsData = (ps: PS, componentVariantsPointer: CompRef) =>
    variantsUtils.getComponentDataConsideringVariants(ps, componentVariantsPointer, DATA_TYPES.transitions)

const removeTransitionsData = (ps: PS, componentVariantsPointer: CompRef) =>
    variantsUtils.removeComponentDataConsideringVariants(ps, componentVariantsPointer, DATA_TYPES.transitions)

const hasTransitionsData = (ps: PS, componentVariantsPointer: CompRef) =>
    !!variantsUtils.getComponentDataConsideringVariants(ps, componentVariantsPointer, DATA_TYPES.transitions)

const setEnableVariantsTransitionsInEditor = (ps: PS, shouldEnableTransitions: boolean) =>
    ps.siteAPI.setEnableVariantsTransitionsInEditor(shouldEnableTransitions)

export default {
    updateTransitionsData,
    getTransitionsData,
    setEnableVariantsTransitionsInEditor,
    removeTransitionsData,
    hasTransitionsData
}
