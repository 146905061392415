import clientSpecMapUtils from './clientSpecMapUtils'
import errors from './errors'
import type {PS} from '@wix/document-services-types'

function _validateArgument(argName: string, methodName: string, argValue: string, expected: string) {
    if (!argValue) {
        throw new errors.ArgumentError(argName, methodName, argValue, expected)
    }
}

export interface CodeAppInfo {
    baseUrl: string
    editorRootUrl: string
    signedInstance: string
    instanceId: string
    appId: string
}

/**
 * @description Creates a codeAppInfo object from the given parameters
 * param {string} baseUrl The base URL for the app, usually taken from service topology
 * param {string} appId The app ID
 * param {string} signedInstance The signed instance, usually taken from the client spec entry
 * param {string} instanceId The instance ID, usually taken from the client spec entry
 */
function createCodeAppInfo({baseUrl, appId, signedInstance, instanceId, editorRootUrl}: CodeAppInfo) {
    _validateArgument('baseUrl', 'createCodeAppInfo', baseUrl, 'string')
    _validateArgument('appId', 'createCodeAppInfo', appId, 'string')
    _validateArgument('signedInstance', 'createCodeAppInfo', signedInstance, 'string')
    _validateArgument('instanceId', 'createCodeAppInfo', instanceId, 'string')
    _validateArgument('editorRootUrl', 'createCodeAppInfo', editorRootUrl, 'string')

    return {
        baseUrl,
        editorRootUrl,
        signedInstance,
        instanceId,
        appId
    }
}

function getCodeAppInfoFromPS(ps: PS) {
    const wixCodeApp = clientSpecMapUtils.getExistingWixCodeAppFromPS(ps)
    const gridAppId = ps.extensionAPI.wixCode.getEditedGridAppId()
    const serviceTopology = ps.dal.get(ps.pointers.general.getServiceTopology())

    const baseUrl = serviceTopology.wixCodeIdeServerUrl
    const {editorRootUrl} = serviceTopology

    return createCodeAppInfo({
        baseUrl,
        editorRootUrl,
        appId: gridAppId,
        signedInstance: wixCodeApp.instance,
        instanceId: wixCodeApp.instanceId
    })
}

export default {
    createCodeAppInfo,
    getCodeAppInfoFromPS
}
