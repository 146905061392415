import _ from 'lodash'
import dsUtils from '../../utils/utils'
import type {CompStructure, PS} from '@wix/document-services-types'
import dataModel from '../../dataModel/dataModel'

function shouldStretchInMobile(ps: PS, compData: Record<string, any>) {
    const clientSpecMapPointer = ps.pointers.general.getClientSpecMap()
    const clientSpecMap = ps.dal.getNoClone(clientSpecMapPointer)
    const appData = _.find(clientSpecMap, {appDefinitionId: compData.appDefinitionId})

    return appData?.widgets?.[compData.widgetId]?.shouldBeStretchedByDefaultMobile ?? true
}

function getCompData(component: CompStructure, ps: PS, pageId: string) {
    const dataQuery = dsUtils.stripHashIfExists(component.dataQuery)
    return dataModel.getDataItemById(ps, dataQuery, pageId)
}

export default {
    shouldAutoSetNickname: false,
    mobileConversionConfig: {
        stretchHorizontally(ps: PS, component: CompStructure, pageId: string) {
            const compData = getCompData(component, ps, pageId)
            if (compData?.type !== 'WidgetRef' || !compData?.appDefinitionId) {
                return true
            }
            return shouldStretchInMobile(ps, compData)
        }
    },
    canContainByStructure: true,
    canBeStretched: true
}
