import _ from 'lodash'
import {isTextComponent, isGraphicComponent, getXOverlap} from '../conversionUtils'
import {getOrderedChildren} from './structureConverterUtils'
import {rescaleProportionally, rescaleLayout, rescaleText} from './structureConverter'
import {MAX_SCALE_FACTOR, TEXT_SCALE_FACTOR, GRAPHIC_MARKER_MIN_WIDTH} from './structureConverterConstants'
import type {ConversionSettings, DeepStructure} from '../../types'

const rescaleTexts = (components: DeepStructure[], settings) =>
    _(components)
        .filter(isTextComponent)
        .forEach(component => rescaleText(component, TEXT_SCALE_FACTOR, settings))
const findContent = (components: DeepStructure[]): DeepStructure => _.maxBy(components, 'conversionData.textLength')

const getRightX = (component: DeepStructure): number => component.layout.x + component.layout.width

function stretchRowContent(rowContainer: DeepStructure, orderedChildren: DeepStructure[]): void {
    const content = findContent(orderedChildren)
    const currentChild = orderedChildren[0]
    const nextChild = orderedChildren[1]
    const rightX = nextChild ? getRightX(nextChild) : 0
    const rowContentDeltaWidth = rowContainer.layout.width - rightX

    content.layout.width += rowContentDeltaWidth
    if (currentChild === content && nextChild) {
        nextChild.layout.x += rowContentDeltaWidth
    }
}

function convertRow(rowContainer: DeepStructure, rowContainerScaleFactor: number, settings: ConversionSettings): void {
    const orderedChildren = getOrderedChildren(rowContainer)
    rescaleTexts(orderedChildren, settings)

    if (rowContainerScaleFactor >= MAX_SCALE_FACTOR) {
        stretchRowContent(rowContainer, orderedChildren)
        return
    }

    const content = findContent(orderedChildren)
    const indexOfMarker = orderedChildren.indexOf(content) ? 0 : 1
    const marker = orderedChildren[indexOfMarker]
    // hot fix for https://jira.wixpress.com/browse/WEED-24037
    // TODO: investigate rules how row rules applying
    if (!marker) {
        return
    }
    const markerMinWidth = isGraphicComponent(marker) ? GRAPHIC_MARKER_MIN_WIDTH : marker.layout.width
    if (marker.layout.width * rowContainerScaleFactor >= markerMinWidth) {
        rescaleLayout(marker, rowContainerScaleFactor)
        rescaleLayout(content, rowContainerScaleFactor)
        return
    }

    const marginX = -getXOverlap(marker, content)
    const markerTargetWidth = marker.layout.width < markerMinWidth ? marker.layout.width : markerMinWidth
    const markerScaleFactor = markerTargetWidth / marker.layout.width
    const contentScaleFactor = (rowContainer.layout.width - markerTargetWidth - marginX) / (getRightX(orderedChildren[1]) - marker.layout.width - marginX)

    const dimsToRescale = ['width', 'height']
    rescaleLayout(marker, markerScaleFactor, dimsToRescale)
    rescaleLayout(content, Math.abs(contentScaleFactor), dimsToRescale)
    orderedChildren[1].layout.x = getRightX(orderedChildren[0]) + marginX
}

function convertLogo(logoContainer: DeepStructure, scaleFactor: number, settings: ConversionSettings): void {
    _.forEach(logoContainer.components, component => rescaleProportionally(component as DeepStructure, _.min([scaleFactor, MAX_SCALE_FACTOR]), settings))
}

export {convertLogo, convertRow}
