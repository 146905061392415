/*eslint import/no-extraneous-dependencies:0*/
import generatedEditorSkinsData from '@wix/wix-santa/dist/editorSkinsData'
import _ from 'lodash'
import {getComponentsEditorParams as getEditorElementsComponentsEditorParams} from '@wix/editor-elements-design-panel-params/componentsEditorParams'

let componentsEditorParams: Record<string, any> = null

export const getComponentsEditorParams = () => {
    if (!componentsEditorParams) {
        componentsEditorParams = _.merge(generatedEditorSkinsData, getEditorElementsComponentsEditorParams())
    }

    return componentsEditorParams
}
