import type {Experiment, ServiceTopology} from '@wix/document-services-types'
import {joinURL} from './joinURL'

/**
 * builds an unpkg url e.g.
 * https://static.parastorage.com/unpkg/lodash@4.17.10/lodash.min.js
 * https://unpkg.parastorage.com/wix-base-ui@2.192.0/dist/base-ui.js
 */
function unpkg(pkg: string, v: string, p: string): string {
    return `https://static.parastorage.com/unpkg/${pkg}@${v}/${p}`
}

function unpkgObj(pkg: string, v: string, min: string, debug: string): PkgObj {
    return {
        min: unpkg(pkg, v, min),
        source: unpkg(pkg, v, debug)
    }
}

// /**
//  * @param {string} scriptsUrl
//  * @param {string} pkg
//  * @param {string} v
//  * @param {string} min
//  * @param {string} debug
//  * @returns {{min: string, source: string}}
//  */
// function thirdObjUrl(scriptsUrl, pkg, v, min, debug) {
//     return {
//         min: third(scriptsUrl, pkg, v, min),
//         source: third(scriptsUrl, pkg, v, debug)
//     }
// }

function check(v: string, name: string) {
    if (typeof v === 'undefined') {
        throw new Error(`error ${name} is '${v}'`)
    }
}

function third(scriptsLocation: string, pkg: string, version: string, path: string): string {
    check(scriptsLocation, 'scriptsLocation')
    check(pkg, 'pkg')
    check(version, 'version')
    check(path, 'path')
    return joinURL(scriptsLocation, 'services', 'third-party', pkg, version, path)!
}

const node = (m: string, p: string): string => `node_modules/${m}/${p}`

function unpkgOrNode(versions: any, local: boolean, m: string, p: string): string {
    const actualLocal = isLocal(versions, local, m)
    return actualLocal ? node(m, p) : unpkg(m, versions[m], p)
}

function isLocal(versions: Record<string, string>, local: boolean, m: string) {
    return local || versions[m] === 'link'
}

export interface PkgObj {
    min: string
    source: string
}

function unpkgOrNodeObj(versions: any, local: boolean, m: string, min: string, debug: string): PkgObj {
    const actualLocal = isLocal(versions, local, m)
    return actualLocal ? nodeObj(m, min, debug) : unpkgObj(m, versions[m], min, debug)
}

function nodeObj(m: string, min: string, debug: string): PkgObj {
    return {min: node(m, min), source: node(m, debug)}
}

function mergeInto(dst: Record<string, string>, src: Record<string, string>) {
    Object.keys(src).forEach(k => {
        dst[k] = dst[k] || src[k]
    })
}

function scriptLocation(serviceTopology: ServiceTopology, name: string, fallback?: string) {
    return serviceTopology?.scriptsLocationMap[name] || fallback
}

function isExperimentOpen(experimentInstance: Experiment, name: string) {
    return experimentInstance && typeof experimentInstance.isOpen === 'function' && experimentInstance.isOpen(name)
}

export {unpkgOrNode, unpkgOrNodeObj, unpkg, unpkgObj, third, node, scriptLocation, mergeInto, isExperimentOpen}
