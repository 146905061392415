import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import appStudioAppDataUtils from './appStudioAppDataUtils'

const PLATFORM_COMPONENT = 'PLATFORM'
const APP_STUDIO_COMPONENT = 'STUDIO'

const getComponent = (appData, compType: string) => _.find(appData.components, {compType})
const getPlatformComponnet = appData => getComponent(appData, PLATFORM_COMPONENT)
const getAppStudioComponent = appData => getComponent(appData, APP_STUDIO_COMPONENT)

const isBobAppData = appData => !!getPlatformComponnet(appData) && !!getAppStudioComponent(appData)

const isBobApp = async (ps: PS, appDefinitionId: string) => {
    const appData = await appStudioAppDataUtils.fetchAppData(ps, appDefinitionId)
    return isBobAppData(appData)
}

export default {
    isBobApp,
    isBobAppData,
    getPlatformComponnet
}
