import type {ViewerManager} from '@wix/viewer-manager-adapter'
import {host as dmHost, Host} from '@wix/document-manager-host-common'
import _ from 'lodash'
import {loadViewerExtensions} from './extensionLoader'
export type {FetchPagesToDalFunction} from '@wix/document-manager-host-common'

export const {createHost} = dmHost

export interface HostWithViewerManager extends Host {
    viewerManager: ViewerManager
}

/**
 *
 * @param {Host} host
 * @param {ViewerManager} viewerManager
 * @returns {Host}
 */
export const extendHostWithViewerManager = (host: Host, viewerManager: ViewerManager): HostWithViewerManager => {
    const hostWithViewerManager = _.assign({}, host, {viewerManager})
    loadViewerExtensions(hostWithViewerManager)
    return hostWithViewerManager
}
