/**
 * @param {Array<import('./index').ParsedStyleItem>} styleItems
 * @param {string} newSkin
 * @returns {Array<import('./index').ParsedStyleItem>}
 */
function migrateStyleItemsSkin(styleItems, newSkin) {
    return styleItems.map(styleItem => ({
        ...styleItem,
        skin: newSkin
    }))
}

/**
 * @param {import('./index').ComponentStructure} component
 * @param {string} newSkin
 * @returns {import('./index').ComponentStructure}
 */
function migrateComponentSkin(component, newSkin) {
    return {
        ...component,
        skin: newSkin
    }
}

module.exports = {
    migrateStyleItemsSkin,
    migrateComponentSkin
}
