import type {
    RendererModelFromServer,
    PagesPlatformApplications,
    ClientSpecMap,
    SiteExtensionClientSpecMapEntry,
    PagesPlatformApplicationsFromServer
} from '@wix/document-services-types'
import _ from 'lodash'
import {deepClone} from '@wix/wix-immutable-proxy'

function getWixCodeAppDefId(clientSpecMap: ClientSpecMap): string | null {
    for (const spec in clientSpecMap) {
        if (clientSpecMap[spec].type === 'siteextension') {
            return (clientSpecMap[spec] as SiteExtensionClientSpecMapEntry).appDefinitionId.toString()
        }
    }
    return null
}
export const getPagesPlatformApplications = (rendererModel: RendererModelFromServer): PagesPlatformApplications => {
    const wixCodeAppDefId = getWixCodeAppDefId(rendererModel.clientSpecMap)
    const {pagesPlatformApplications} = rendererModel
    const result = {}
    for (const pageId in pagesPlatformApplications) {
        if (pagesPlatformApplications.hasOwnProperty(pageId)) {
            pagesPlatformApplications[pageId].forEach((appDefId: string) => {
                const isWixCodeApp = appDefId === wixCodeAppDefId
                if (isWixCodeApp) {
                    appDefId = 'wixCode'
                }
                result[appDefId] = result[appDefId] || {}
                result[appDefId][pageId] = true
            })
        }
    }
    return result
}

export const manipulateRendererModel = (
    rendererModel: RendererModelFromServer,
    runningExperimentsWithQueryOverrides: Record<string, any>
): any => {
    const {siteMetaData, runningExperiments} = rendererModel
    runningExperimentsWithQueryOverrides = runningExperimentsWithQueryOverrides ?? runningExperiments
    const {isResponsive} = siteMetaData
    const pagesPlatformApplications = getPagesPlatformApplications(rendererModel)
    const currentAdaptiveMobileOn = _.get(rendererModel, ['siteMetaData', 'adaptiveMobileOn'])
    const adaptiveMobileOn = isResponsive ? false : siteMetaData.adaptiveMobileOn
    if (
        !_.isEqual(runningExperiments, runningExperimentsWithQueryOverrides) ||
        !_.isEqual(pagesPlatformApplications, rendererModel.pagesPlatformApplications) ||
        currentAdaptiveMobileOn !== adaptiveMobileOn
    ) {
        const clonedRendererModel = deepClone(rendererModel)
        clonedRendererModel.pagesPlatformApplications =
            pagesPlatformApplications as unknown as PagesPlatformApplicationsFromServer
        clonedRendererModel.siteMetaData.adaptiveMobileOn = adaptiveMobileOn
        clonedRendererModel.runningExperiments = runningExperimentsWithQueryOverrides
        return clonedRendererModel
    }
    return rendererModel
}
