import _ from 'lodash'

const getMockClientSpecMap = (overrides = {}) =>
    _.merge(
        {
            1: {
                appDefinitionId: '111-111',
                appDefinitionName: 'mock',
                appFields: {},
                appRequirements: {},
                appType: 'Hybrid',
                applicationId: 1,
                demoMode: false,
                expirationDate: '',
                instance: '',
                instanceId: '',
                isWixTPA: true,
                openPremiumPage: false,
                permissions: {
                    revoke: false
                },
                widgets: {}
            }
        },
        overrides
    )

const getMockRoutersMap = (overrides = {}) =>
    _.merge(
        {
            'router-1': {
                appDefinitionId: '111',
                config: '',
                pages: {
                    role1: 'c1dmp'
                },
                prefix: 'test'
            }
        },
        overrides
    )

const getMockWixCodeModel = () => ({
    appData: {
        codeAppId: 'c472e430-856c-4b39-9dcd-9086076b4bd6'
    },
    signedAppRenderInfo: 'dddd'
})

const getMockMultilingualInfo = (overrides = {}) =>
    _.merge(
        {
            languageCode: 'en',
            originalLanguage: {
                languageCode: 'en',
                locale: 'en-us',
                countryCode: 'USA',
                resolutionMethod: 'QueryParam',
                url: ''
            },
            translationLanguages: []
        },
        overrides
    )

const getMockPagesPlatformApplication = (overrides = {}) =>
    _.merge(
        {
            wixCode: {c1dmp: true}
        },
        overrides
    )

const getMockSitePropertiesInfo = (overrides = {}) =>
    _.merge(
        {
            currency: 'ILS',
            language: 'en',
            locale: 'en-us',
            multilingualInfo: getMockMultilingualInfo(),
            siteDisplayName: 'Mysite 3768',
            timeZone: 'Asia/Jerusalem'
        },
        overrides
    )

const getMockSiteInfo = (overrides = {}) =>
    _.merge(
        {
            applicationType: 'HtmlWeb',
            documentType: 'TEMPLATE',
            siteId: '94e00115-0ae7-486e-bbe2-287f8fe899fb',
            siteTitleSEO: ''
        },
        overrides
    )

const getMockSiteMetaData = (overrides = {}) =>
    _.merge(
        {
            adaptiveMobileOn: true,
            contactInfo: {companyName: '', phone: '', fax: '', email: '', address: ''},
            preloader: {uri: '', enabled: false},
            quickActions: {
                socialLinks: [],
                colorScheme: 'dark',
                configuration: {
                    addressEnabled: false,
                    emailEnabled: false,
                    navigationMenuEnabled: true,
                    phoneEnabled: false,
                    quickActionsMenuEnabled: false,
                    socialLinksEnabled: false
                }
            },
            renderHints: {containsTPA: false, isMeshReady: true, containsAppPart: false, containsAppPart2: false}
        },
        overrides
    )

export {
    getMockClientSpecMap,
    getMockRoutersMap,
    getMockWixCodeModel,
    getMockMultilingualInfo,
    getMockPagesPlatformApplication,
    getMockSitePropertiesInfo,
    getMockSiteInfo,
    getMockSiteMetaData
}
