import type {PS} from '@wix/document-services-types'
import {coreUtils, warmupUtils, utils} from '@wix/santa-ds-libs'
const {urlUtils} = coreUtils

const {requestsUtil} = warmupUtils
const {cookieUtils} = utils

const headers = {
    'Content-Type': 'application/json;charset=utf-8',
    Accept: 'application/json, text/plain, */*',
    'X-XSRF-TOKEN': cookieUtils.getCookie('XSRF-TOKEN')
}

const buildAppsServiceBaseUrl = (ps: PS) => {
    const serviceTopologyPointer = ps.pointers.general.getServiceTopology()
    const editorServerRootPointer = ps.pointers.getInnerPointer(serviceTopologyPointer, 'editorServerRoot')
    const editorServerRoot = ps.dal.get(editorServerRootPointer)
    return `${urlUtils.baseUrl(editorServerRoot)}/_api`
}

const fetchAppData = async (ps: PS, appId: string) => {
    const res = await requestsUtil.fetchJson(
        `${buildAppsServiceBaseUrl(ps)}/app-components-service/v1/components/app/${appId}?appId=${appId}`,
        {
            credentials: 'include',
            method: 'GET',
            headers
        }
    )

    return {
        // @ts-ignore
        components: res.components.map(component => {
            return {
                compData: component.data,
                compId: component.id,
                compName: component.name,
                compType: component.type
            }
        })
    }
}

const postComponentData = async (ps: PS, appId: string, componentData) => {
    const response: any = await requestsUtil.fetchJson(
        `${buildAppsServiceBaseUrl(ps)}/app-components-service/v1/components`,
        {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                component: {
                    appId,
                    data: componentData.compData,
                    type: componentData.compType,
                    name: componentData.compName
                }
            }),
            headers
        }
    )
    return response.component.id
}

export default {
    fetchAppData,
    postComponentData
}
