import type {CreateExtArgs} from '@wix/document-manager-core'
import type {CompRef, CompStructure, Pointer} from '@wix/document-services-types'
import {getComponentType} from '../../../utils/dalUtils'
import type {DataModelExtensionAPI} from '../../dataModel/dataModel'
import {DATA_TYPES} from '../../../constants/constants'

const COLUMN_COMPONENT_TYPE = 'wysiwyg.viewer.components.Column'

export const stripColumnsMetaData = {
    canContain({dal}: CreateExtArgs, compPointer: Pointer, potentialChildCompPtr: CompRef) {
        const childType = getComponentType(dal, potentialChildCompPtr)
        return childType === COLUMN_COMPONENT_TYPE
    },
    fullWidthByStructure: ({}: CreateExtArgs, compStructure: CompStructure) => !!compStructure.props.fullWidth,
    fullWidth: ({extensionAPI}: CreateExtArgs, compPointer: Pointer) => {
        const {dataModel} = extensionAPI as DataModelExtensionAPI

        const propertiesItem = dataModel.components.getItem(compPointer, DATA_TYPES.prop)
        return propertiesItem.fullWidth
    }
}
