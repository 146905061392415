import {scrollAnchors} from '@wix/document-services-implementation'
import type {PublicMethodUtils} from '@wix/document-services-types'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        initMethod: scrollAnchors.init,
        methods: {
            components: {
                anchor: {
                    get: publicMethodUtils.defineGetter(scrollAnchors.get),
                    update: publicMethodUtils.defineDataManipulationAction(scrollAnchors.update),
                    remove: publicMethodUtils.defineDataManipulationAction(scrollAnchors.remove)
                }
            },
            pages: {
                anchors: {
                    getPageAnchors: publicMethodUtils.defineGetter(scrollAnchors.getPageAnchors)
                }
            }
        }
    }
}
